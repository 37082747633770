

angular.module('vibrent-external-survey-parser')
    .service('TextTypeParser', function (
        VibrentFormStructureConstantService, CommonParserUtilService,
        RedcapParserUtilService, RedcapFormStructureConstantService
    ) {
        let constantService = VibrentFormStructureConstantService;
        let commonParser = CommonParserUtilService;
        let redcapParser = RedcapParserUtilService;
        let redcapConstantService = RedcapFormStructureConstantService;

        /**
         * Return two form fields:
         *  one text label or rich text label (if field_label has rich text)
         *  text input field with required validation if needed
         */
        function parseTextInput(surveyQuestion, localIdGenerator, fields) {
            //  create text label
            const textLabelField = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            fields.push(textLabelField);

            //  create text input field
            const inputTextField = constantService.initFormFieldTextInput(localIdGenerator);
            redcapParser.parseAndAddValidation(surveyQuestion, inputTextField);

            fields.push(inputTextField);
        }
        /**
         * Return two form fields:
         *  one text label or rich text label (if field_label has rich text)
         *  text input field with email regex + required validation if needed
         */
        function parseEmail(surveyQuestion, localIdGenerator, fields) {
            //  create text label
            const textLabelField = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            fields.push(textLabelField);

            //  create text input field and add email regex validation
            const inputTextField = constantService.initFormFieldTextInput(localIdGenerator);
            const validationDto = constantService.initValidationDtoSpanishMessage('PATTERN', 'Please enter a valid email', 'Por favor introduzca una dirección de correo electrónico válida');
            validationDto.validationValue = '[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}';
            inputTextField.validationRules.push(validationDto);
            redcapParser.parseAndAddValidation(surveyQuestion, inputTextField);

            fields.push(inputTextField);
        }

        /**
         * Return three form fields:
         *  one text or rich text label
         *  two input fields: date + time (time field's title will not be set)
         *  both input fields have min/max and required validations if needed
         */
        function parseDateTime(surveyQuestion, localIdGenerator, fields) {
            const fieldType = commonParser.getValueFromObject(surveyQuestion, 'text_validation_type_or_show_slider_number');

            //  create day picker - this input field has a title subfield
            const dayPickerInputField = constantService.initFormFieldDayPicker(localIdGenerator);

            //  set title subfield from field_label
            const titleSubField = dayPickerInputField;
            if(titleSubField) {
                titleSubField.fieldValue.labelText = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                //  set Spanish translation if available
                if(surveyQuestion.language && surveyQuestion.language.ES) {
                    titleSubField.fieldValue.localizationMap.es =
                        { 'labelText' : commonParser.getValueFromObject(surveyQuestion.language.ES, 'field_label') };
                }
            }

            redcapParser.parseAndAddValidation(surveyQuestion, dayPickerInputField);

            //  we are given date in this format: 1900-11-30 23:13
            const minValidations = commonParser.getValueFromObject(surveyQuestion, 'text_validation_min').split(' ');
            const maxValidations = commonParser.getValueFromObject(surveyQuestion, 'text_validation_max').split(' ');

            //  parse and set date validation (format 1900-01-01)
            if (minValidations[0]) {
                const minDate = minValidations[0].split('-');
                //  set minimum date validation
                const minDateValidation = (new Date()).setFullYear(minDate[0], minDate[1] - 1, minDate[2]);
                const formattedMinDate = `${minDate[1]}/${minDate[2]}/${minDate[0]}`;
                const englishMessage = `Select date after ${formattedMinDate}`;
                const spanishMessage = `Seleccionar fecha después ${formattedMinDate}`;
                const validationDto = constantService.initValidationDtoSpanishMessage('MIN_DATE', englishMessage, spanishMessage);
                validationDto.validationValue = minDateValidation;
                validationDto.options.VALUE_TYPE = 'ABSOLUTE';
                validationDto.options.INCLUDE_EQUAL = 'true';
                dayPickerInputField.validationRules.push(validationDto);
            }
            if (maxValidations[0]) {
                const maxDate = maxValidations[0].split('-');
                //  set maximum date validation
                const maxDateValidation = (new Date()).setFullYear(maxDate[0], maxDate[1] - 1, maxDate[2]);
                const formattedMaxDate = `${maxDate[1] }/${ maxDate[2] }/${ maxDate[0]}`;
                const englishMessage = `Select date before ${formattedMaxDate}`;
                const spanishMessage = `Seleccionar fecha antes ${formattedMaxDate}`;
                const validationDto = constantService.initValidationDtoSpanishMessage('MAX_DATE', englishMessage, spanishMessage);
                validationDto.validationValue = maxDateValidation;
                validationDto.options.VALUE_TYPE = 'ABSOLUTE';
                validationDto.options.INCLUDE_EQUAL = 'true';
                dayPickerInputField.validationRules.push(validationDto);
            }

            //  check if title subfield has rich text and create separate rich text label if needed
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fields, dayPickerInputField);

            if (fieldType !== redcapConstantService.redcapTextValidationTypes.DATE_DMY &&
                fieldType !== redcapConstantService.redcapTextValidationTypes.DATE_MDY &&
                fieldType !== redcapConstantService.redcapTextValidationTypes.DATE_YMD) {

                //  create time picker input field - this input field has a title subfield that
                //  we will leave empty and only populate day picker's title
                const timeInputField = constantService.initFormFieldTimePicker(localIdGenerator);

                //  note: time picker form field is created with advanced padding around whole form field + text and time picker subfields
                //  in this case we want to create a date picker followed closely by a time picker
                //  this is why for title subfield we remove the advanced padding and reset back to SYSTEM - so that date and time picker
                //  render close to each other time picker subfield needs right and left padding but none on top or bottom.
                timeInputField.subFields[0].displayConfig.padding.configType = 'SYSTEM';

                setTimeValidations(timeInputField, minValidations[1], maxValidations[1]);
                redcapParser.parseAndAddValidation(surveyQuestion, timeInputField);

                fields.push(timeInputField);
            }
        }

        /**
         * Return two form fields:
         *  one text or rich text label
         *  one time picker input field - with min/max and required validations if needed
         */
        function parseTimeInput(surveyQuestion, localIdGenerator, fields) {
            //  create time picker input field - this input field has a title subfield
            const timeInputField = constantService.initFormFieldTimePicker(localIdGenerator);

            //  set title subfield from field_label
            const titleSubField = timeInputField.subFields[0];
            if(titleSubField) {
                titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                //  set Spanish translation if available
                redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
            }

            const minTime = commonParser.getValueFromObject(surveyQuestion, 'text_validation_min');
            const maxTime = commonParser.getValueFromObject(surveyQuestion, 'text_validation_max');
            setTimeValidations(timeInputField, minTime, maxTime);

            //  check if title subfield has rich text and create separate rich text label if needed
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fields, timeInputField);

            redcapParser.parseAndAddValidation(surveyQuestion, timeInputField);
        }

        /**
         * Return two form fields:
         *  one text or rich text label
         *  one number input field with min/max and required validations if needed
         * Redcap supports inclusive min/max values
         */
        function parseNumericField(surveyQuestion, localIdGenerator, fields) {
            //  create text label
            const textLabelField = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            fields.push(textLabelField);

            //  create text input field and add optional min and max validations
            const inputNumberField = constantService.initFormFieldNumberInput(localIdGenerator);
            const min = commonParser.getValueFromObject(surveyQuestion, 'text_validation_min');
            const max = commonParser.getValueFromObject(surveyQuestion, 'text_validation_max');

            if (min) {
                const englishMessage = `Please enter value greater or equal to ${ min}`;
                const spanishMessage = `Por favor ingrese un valor mayor o igual a ${ min}`;
                const validationDto = constantService.initValidationValueLanguage('MIN', englishMessage, spanishMessage, min);
                inputNumberField.validationRules.push(validationDto);
            }
            if (max) {
                const englishMessage = `Please enter value less or equal to ${ max}`;
                const spanishMessage = `Por favor ingrese un valor menor o igual a ${ max}`;
                const validationDto = constantService.initValidationValueLanguage('MAX', englishMessage, spanishMessage, max);
                inputNumberField.validationRules.push(validationDto);
            }
            redcapParser.parseAndAddValidation(surveyQuestion, inputNumberField);

            fields.push(inputNumberField);
        }

        /**
         * Return two form fields:
         *  one text or rich text label
         *  one phone number input with required validation if needed
         */
        function parsePhoneNumber(surveyQuestion, localIdGenerator, fields) {
            //  create text label
            const textLabelField = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            fields.push(textLabelField);

            //  create phone number input field and add required validation if needed
            const phoneNumberField = constantService.initFormFieldPhoneNumber(localIdGenerator);
            redcapParser.parseAndAddValidation(surveyQuestion, phoneNumberField);

            fields.push(phoneNumberField);
        }

        /**
         * Return two form fields:
         *  one text or rich text label
         *  text input field with zipcode regex + required validation if needed
         */
        function parseZipcode(surveyQuestion, localIdGenerator, fields) {
            //  create text label
            const textLabelField = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            fields.push(textLabelField);

            //  create text input field and add zipcode regex validation
            const inputZipCodeField = constantService.initFormFieldTextInput(localIdGenerator);
            const validationDto = constantService.initValidationDtoSpanishMessage('PATTERN', 'Please enter valid zipcode', 'Por favor ingrese un código postal válido');
            validationDto.validationValue = '^([0-9]{5})$';
            inputZipCodeField.validationRules.push(validationDto);
            redcapParser.parseAndAddValidation(surveyQuestion, inputZipCodeField);

            fields.push(inputZipCodeField);
        }

        /* **************
         * Private methods
         ************** */

        /**
         * Helper method to set a time input form field's min and max time validations
         */
        function setTimeValidations(timeInputField, minTime, maxTime) {
            //  set minimum time validation
            timeInputField.subFields[1].fieldValue.minTime = minTime ? minTime : '0:0';
            //  set maximum time validation
            timeInputField.subFields[1].fieldValue.maxTime = maxTime ? maxTime : '23:59';
        }

        return {
            parseTextInput,
            parseEmail,
            parseDateTime,
            parseTimeInput,
            parseNumericField,
            parsePhoneNumber,
            parseZipcode,
        };
    });
