'use strict';

/**
 * drc related services
 */
(function () {
    angular.module('acadiamasterApp').factory('PropertySearch', function ($http, localWeb) {
        var baseUrl = 'api/propertys';

        return {
            searchByName: function (name, exactMatch) {
                return $http.get(localWeb.createUrl(baseUrl + "/name/" + name + '?exactMatch=' + exactMatch));
            },
            get: function(searchData) {
                var finalUrl = baseUrl + '/search?searchString=' + searchData.searchString;
                finalUrl += ('&orderBy=' + searchData.orderBySelected);
                finalUrl += ('&ascending=' + searchData.ascending);
                finalUrl += ('&size=' + searchData.pageSizeSelected);
                finalUrl += ('&page=' + searchData.page);

                return $http.get(finalUrl);
            }
        };
    })
})();
