/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .controller('ProgramSelectionController', function($scope, $state, ngDialog, ProgramWithoutProfileForm) {
            $scope.getProgramsWithoutProfileForm = function() {
                ProgramWithoutProfileForm.query({}, function(result, headers) {
                    $scope.programs = result;
                });
            };
            $scope.getProgramsWithoutProfileForm();
            $scope.programSelected = function(programId) {
                $scope.confirm();
                $state.go('form.newprofileform', { 'programId': programId });
            };
        });

})();

