/**
 * directive for configuring the template content value of html type
 */
angular.module('acadiamasterApp').directive('templateValueHtmlConfig', (LocaleConstantsService) => ({
    link($scope) {
        $scope.LocaleConstantsService = LocaleConstantsService;
    },
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
        'templateValue.html.config.html',
}));

