/**
 * common directive to configure a business rule action or PTF action of the type of
 * "Evaluate Personalized Container Item"
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('actionEvaluatePersonalizedContainerItem', function () {
            return {
                templateUrl: 'admin-templates/site/common/action/personalizedContainer/evaluatePersonalizedContainerItem.html',
                restrict: 'E',
                scope: {
                    action: '<',
                },
                link: function ($scope) {
                    $scope.containerOptions = {
                        getContainer: () => $scope.action.getContainer(),
                        setContainer: (container) => $scope.action.setContainer(container),
                        programId: $scope.action.getProgramId(),
                        filterFunction: (container) => {
                            // filter out container that doesn't have container items
                            return container!=null && container.containerItems!=null && container.containerItems.length > 0;
                        }
                    };

                    $scope.containerItemOptions = {
                        getContainerItem: () => $scope.action.getContainerItem(),
                        setContainerItem: (containerItem) => $scope.action.setContainerItem(containerItem),
                        programId: $scope.action.getProgramId(),
                        getContainerId : () => {
                            const container = $scope.action.getContainer();
                            return container == null ? null : container.id;
                        },
                    };

                    $scope.shouldShowContainerItemSelection = function() {
                        const container = $scope.action.getContainer();
                        return container!=null && container.containerItems!=null && container.containerItems.length>0;
                    };
                }
            };
        });
})();
