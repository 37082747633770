'use strict';

angular.module('acadiamasterApp')
    .factory('DataTypeCategory', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/dataTypeCategorys/:id'), {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'lookup': {
                url: localWeb.createUrl('/api/dataTypeCategorys/lookup'),
                method: 'GET',
                isArray: true
            },
            'update': { method:'PUT' },
            'queryDetails': function() {
                var url = localWeb.createUrl('/api/dataTypeCategorys/details');
                return [];
            }
        });
    })
    .factory('DataTypeCategoryByName', function ($http,localWeb) {
        var url = localWeb.createUrl('api/dataTypeCategorys/nameAvailable/');
        return {
            'get' : function(dataTypeCategoryName, dataTypeCategoryId) {
                var finalUrl = url + dataTypeCategoryName;
                if (dataTypeCategoryId!=null) {
                    finalUrl += "?dataTypeCategoryId=" + dataTypeCategoryId;
                }
                return $http.get(finalUrl);
            }
        };
    });
