import ContainerItemType from '../const/ContainerItemType.const';

/**
 *
 * directive for configuring visible and unlocked condition
 */
angular.module('acadiamasterApp').directive('visibleUnlockConditionConfig', ExpressionConstantsService => ({
    link ($scope) {
        $scope.expConstants = ExpressionConstantsService;
        $scope.containerItemTypes = ContainerItemType;
    },
    restrict : 'E',
    scope    : {
        element : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/visibleUnlockConditionConfig.html',
}));
