'use strict';

angular.module('acadiamasterApp')
    .factory('UnitCategory', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/unitCategorys/:id'), {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    })
    .factory('UnitCategoriesLookup', function ($http,localWeb, $q) {
        var unitCategories = [];
        var callBackFunctions = [];
        var states = {
            ready : 1,
            loading : 2,
            error : 3,
            uninitialized : 4
        };
        var state = states.uninitialized;

        function loadUnitCategories() {

            state = states.loading;
            return $http({
                method: 'GET',
                url: localWeb.createUrl('/api/unitCategorys/loadAllNoPage')
            }).then(function (response) {
                unitCategories = response.data;
                state = states.ready;
                return unitCategories;
            }, function() {
                state = states.error;
                return $q.reject('Error while receiving unit category');
            });

        }

        return {
            /**
             * initialization function that will load all the units from server
             */
            init : function () {
                return loadUnitCategories();
            },

            /**
             * this will trigger an reload call from server
             */
            updateUnitCategories : function() {
                return loadUnitCategories();
            },

            isDataReady : function () {
                return state==states.ready;
            },

            addListener : function(callBackSuccess, callBackError) {

                var callBacks = {
                    onSuccess : null,
                    onError : null
                };

                if ($.isFunction(callBackSuccess)) {
                    callBacks.onSuccess = callBackSuccess;
                }

                if ($.isFunction(callBackError)) {
                    callBacks.onError = callBackError;
                }

                callBackFunctions.push(callBacks);
            },

            getUnitCategories : function() {
                return unitCategories;
            }
        };

    })

    .factory('UnitSystemCount', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/unitSystemCounts'), {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    });
