/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('searchFilterCron', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/searchFilter/fields/cron/searchFilterCron.template.html',
                scope: {
                    value: '='
                },
                controller: function ($scope) {
                    var isInvalid = $scope.value && $scope.value.hasOwnProperty('isInvalid') ? $scope.value.isInvalid : false;
                    $scope.data = {
                        enabled: $scope.value && $scope.value.hasOwnProperty('enabled') ? $scope.value.enabled : true,
                        expression: $scope.value && $scope.value.expression || null,
                        recurrence: $scope.value && $scope.value.recurrence || 'DAILY'
                    };

                    // if the user saved the filter with an invalid selection, change it slightly to avoid empty fields.
                    if(isInvalid) {
                        $scope.data.expression = '0 0 12 * * ? *';
                        $scope.data.recurrence = 'WEEKLY';
                    }

                    // when data changes, save the result to $scope.value
                    $scope.$watch('data', function (nv, ov) {
                        if (angular.equals(nv, ov) || !nv.expression) {
                            return;
                        }
                        setValue(nv);
                    }, true);

                    function setValue(data) {
                        isInvalid = data.recurrence === 'WEEKLY' &&
                            (data.expression.split(' ')[5] === '?' || data.expression === '0 * * * * * *');
                        var value = {
                            expression: data.expression,
                            recurrence: data.recurrence,
                            name: window.cronstrue.toString(data.expression),
                            enabled: data.enabled,
                            isInvalid: isInvalid
                        };
                        $scope.value = value;
                    }
                }
            };
        });
})();
