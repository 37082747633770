/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola on 10/06/2017
 */
(function () {
    angular.module('acadiamasterApp')
        .controller('FeaturesController', ($scope, FeaturesService, ConfigService, AlertService, $state) => {
            $scope.loading = false;
            $scope.error = false;
            $scope.saveFeatures = saveFeatures;
            $scope.enableFlag = false;
            init();

            /**
             * Loads feature settings
             */
            function init () {
                $scope.loading = true;
                FeaturesService.get().then(
                    function success (response) {
                        $scope.features = response.data;
                        $scope.enableFlag = $scope.features && $scope.features[10] && $scope.features[10].enabled;
                    }, function onError (error) {
                        console.error(error);
                        $scope.error = true;
                    },
                ).finally(() => {
                    $scope.loading = false;
                });
            }

            /**
             * Saves feature toggles to the server
             */
            function saveFeatures () {
                FeaturesService.update($scope.features).then(
                    function success () {
                        AlertService.success('Features saved successfully');
                        // once features are saved, pull down the new config
                        ConfigService.init().then(
                            () => {
                                // once the config is updated, return home
                                $state.go('home');
                            }, error => {
                                console.error(error);
                            });
                    }, function onError (error) {
                        console.error(error);
                    },
                );
            }
        });
}());
