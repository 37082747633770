'use strict';

(function () {
    angular.module('acadiamasterApp').directive('programList', function (ProgramService, vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/programList.html',
            scope: {
                programId: '=',
                isRequired: '='
            },
            link: function ($scope) {
                vbrCommonUtil.translate.addPartsAndRefresh([
                    'global'
                ]);
                $scope.programs = ProgramService.query();
            }
        };
    });
})();