(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('embeddedFieldLink', function (FormModelFieldService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/embeddedFieldLink.html',
                scope: {
                    closeFunction: '=',
                    data: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.embeddedFieldSettings = {
                        name: 'New Embedded Text Field',
                        englishHintText: 'Hint text',
                        spanishHintText:'',
                        numberOfLines: 1
                    };

                    $scope.addNewEmbeddedField = function () {
                        var component = $scope.data.component;
                        var newField = FormModelFieldService.getEmbeededTextInputFieldHolder().newInstance(component);

                        newField.fillBasicDetails($scope.embeddedFieldSettings);

                        component.formComponentFields.push(newField);
                        component.findParentMode().updateLookupMaps();
                        $scope.data.optionValue.embeddedTextInputLocalId = newField.localId;

                        $scope.closeFunction();

                    };

                    $scope.filterOutEmbeddedFields = function (listToFilterFrom) {
                        var embeddedTextInputs = [];
                        if (listToFilterFrom != null) {
                            for (var i = 0; i < listToFilterFrom.length; i++) {
                                if (listToFilterFrom[i].type == FormConstants.fieldsType.EMBEDDED_TEXT_INPUT &&
                                    listToFilterFrom[i]._parent.localId == $scope.data.component.localId &&
                                    !listToFilterFrom[i].isLinked()) {
                                    embeddedTextInputs.push(listToFilterFrom[i]);
                                }
                            }
                        }
                        return embeddedTextInputs;
                    };
                }
            };
        });


})();
