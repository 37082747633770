'use strict';

(function(){
    angular.module('acadiamasterApp').
    directive('formId', function (Form,vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/formId.html',
            scope : {
                formId : '=',
                isRequired:'='
            },
            link: function ($scope) {
                vbrCommonUtil.translate.addPartsAndRefresh([
                    'global'
                ]);
                $scope.getFormName = function (id){
                    var formData = Form.get({
                        id: id
                    });
                    formData.$promise.then(function (data) {
                        if(data) {
                            $scope.isValid = true;
                            $scope.formName = data.name;
                        }
                    })
                        .catch(function(){
                            $scope.isValid = false;
                            $scope.formName = 'Invalid form Id: '+ $scope.formId;
                            $scope.formId = null;
                        });
                };
            }
        };
    });
})();