(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('FormHelpController', function ($scope) {
            $scope.selectedEntity = 'forms';
            $scope.changeHelp = function (selectedEntity) {
                $scope.selectedEntity = selectedEntity;
            };

            $scope.tabStatus = {
                form: {
                    intro: false,
                    displayConfig: false,
                    marginPadding: false,
                    font: false,
                    rendering: false,
                    validation: false,
                    mode: false,
                    states: false,
                    section: false,
                    componentMapping: false,
                    categories: false
                },
                component: {
                    intro: false,
                    fields: false,
                    subFields: false,
                    commonFieldValue: false,
                    fieldTypes: {
                        label: false,
                        image: false,
                        divider: false,
                        takeAPicture: false,
                        viewTakeAPicture: false,
                        programInfo: false,
                        profileImage: false,
                        videoPlayer: false,

                        textInput: false,
                        numberInput: false,
                        daySelector: false,
                        viewDaySelector: false,
                        slider: false,
                        radioSelection: false,
                        viewSelection: false,

                        submit: false,
                        hidden: false
                    }
                }
            };

            $scope.renderingRules = [
                {
                    name: 'Gravity & Orientation',
                    rules: [
                        {
                            name: 'Layout Orientation (layoutOrientation)',
                            rules: [
                                createRule('Any Element that has children can have layout orientation, this include Form Modes, Sections, Components', true, true),
                                createRule('Layout Orientation is not optional for the elements that can have it', true, true),
                                createRule('Possible value for Layout Orientation are VERTICAL and HORIZONTAL', true, true),
                                createRule('Default value for Layout Orientation is VERTICAL', true, true)
                            ]
                        },
                        {
                            name: 'Layout Gravity (layoutGravity)',
                            rules: [
                                createRule('Any Element that can specify Layout Orientation, can also specify Layout Gravity', true, true),
                                createRule('Layout Gravity is not optional for the elements that can have it', true, true),
                                createRule('Layout Gravity is defined in Gravity Model', true, true)
                            ]
                        },
                        {
                            name: 'Content Gravity (gravity)',
                            rules: [
                                createRule('Most field element or subField element can have content gravity (see field/subfield document for more detail)', true, true),
                                createRule('Content Gravity is not optional for the elements that can have it', true, true),
                                createRule('Content Gravity is defined in Gravity Model', true, true)
                            ]
                        }
                    ]
                },
                {
                    name: 'Box Model',
                    rules: [
                        {
                            name: 'Width (width)',
                            rules: [
                                createRule('Width can not be a zero or negative number', true, true),
                                createRule('Width can be specified either in % or in DP (device independent pixel)', true, true),
                                createRule('If width is NULL, then width = 100 % of the Parent.', true, true)
                            ]
                        },
                        {
                            name: 'Height (height)',
                            rules: [
                                createRule('Height can not be a zero or negative number', true, true),
                                createRule('Height can be specified either in % or in DP (device independent pixel)', true, true),
                                createRule('if Parent is WRAP_CONTENT then height must be in DP or WRAP_CONTENT', true, true),
                                createRule('If height is NULL, then height = WRAP_CONTENT (height of the content or children add together)', true, true)
                            ]
                        },
                        {
                            name: 'Padding (padding)',
                            rules: [
                                createRule('Padding can be specified at all levels', true, true),
                                createRule('Padding can not be negative value', true, true),
                                createRule('Padding value is specified in MarginPaddingModel', true, true),
                                createRule('Padding value can be specified either in % or in DP (device independent pixel)', true, true),
                                createRule('Any % value for padding , is the % of the element itself.', true, true),
                                createRule('When Padding value type is SYSTEM, it inherit the property from system default', true, true),
                                createRule('If combined verticalPadding >= field height then verticalPadding = 0;', true, false),
                                createRule('If combined horizontalPadding >= field width then horizontalPadding = 0;', true, false)
                            ]
                        },
                        {
                            name: 'Margin (margin)',
                            rules: [
                                createRule('Margin can be specified at all levels', true, true),
                                createRule('Margin can not be negative value', true, true),
                                createRule('Margin value is specified in MarginPaddingModel', true, true),
                                createRule('Margin value can be specified either in % or in DP (device independent pixel)', true, true),
                                createRule('Any % value for margin, is the % of the parent.', true, true),
                                createRule('When margin value type is SYSTEM, it inherit the property from system default (0 dp)', true, true),
                                createRule('if Parent is WRAP_CONTENT then vertical margin must be in DP', true, true),
                                createRule('If combined verticalMargin >= parent height then verticalMargin = 0;', true, false),
                                createRule('If combined horizontalMargin >= parent width then horizontalMargin = 0;', true, false)
                            ]
                        }
                    ]
                },
                {
                    name: 'Font Rule',
                    rules: [
                        {
                            name: 'Over All',
                            rules: [
                                createRule('font properties can be specified at any level with children', true, true),
                                createRule('when font property is not specified, it inherits from parent', true, true),
                                createRule('system will provide default font properties when there is no parent', true, true)
                            ]
                        }
                    ]
                },
                {
                    name: 'Color Rule',
                    rules: [
                        {
                            name: 'Over All',
                            rules: [
                                createRule('color properties can be specified at any level', true, true),
                                createRule('when color property is not specified and it is needed, it inherits from parent', true, true),
                                createRule('system will provide default color properties when there is no parent', true, true),
                                createRule('system default for foreground or font color is black', true, true),
                                createRule('system default for background color is white', true, true)
                            ]
                        },
                        {
                            name: 'Color Value',
                            rules: [
                                createRule('color value can be specified as a string that matches one of the 147 system colors', true, true),
                                createRule('color value can be specified as a 6 digit hex code starting with #, ie. #FFFFFF for white', true, true),
                                createRule('color value is case insensitive', true, true)
                            ]
                        }
                    ]
                },
                {
                    name: 'Sequence Number Rule',
                    rules: [
                        {
                            name: 'Over All',
                            rules: [
                                createRule('sequence number will be specified to help order the child elements', true, true),
                                createRule('sequence number can not be negative', true, true),
                                createRule('sequence number can not be duplicated within the same parent', true, true)

                            ]
                        }
                    ]
                }
            ];

            $scope.data = {
                isReady: false,
                allCounts: null
            };

            function createRule(description, iConfig, iPreview) {
                return {
                    description: description,
                    implementation: {
                        config: iConfig,
                        preview: iPreview
                    }
                };
            }
        });

})();
