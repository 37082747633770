(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('FormEntryFieldValueUpdateController', function ($scope, $http, localWeb) {

            $scope.data = {
                adminUserId                    : getAdminUserId($scope.ngDialogData),
                formEntryId                    : getFormEntryId($scope.ngDialogData),
                formFieldId                    : getFormFieldId($scope.ngDialogData),
                formFieldEntryId               : getFormFieldEntryId($scope.ngDialogData),
                fieldEntryValueDTOList         : getFormFieldEntryValues($scope.ngDialogData),
                ticketLinked                   : null,
                reasonForChange                : null,
                autoSyncDrc                    : true,
                recalculateContainerConditions : true,
            };

            function getAdminUserId(ngDialogData){
                return ngDialogData.adminUserId;
            }

            function getFormEntryId(ngDialogData) {
                return ngDialogData.entryField.formEntryId;
            }

            function getFormFieldId(ngDialogData) {
                return ngDialogData.entryField.formFieldId;
            }

            function getFormFieldEntryId(ngDialogData) {
                return ngDialogData.entryField.id;
            }

            function getFormFieldEntryValues(ngDialogData) {
                return angular.copy(ngDialogData.entryField.formFieldEntryValues);
            }

            $scope.select = {};
            $scope.select.inputType = null;
            $scope.isMultiSelect = false;
            $scope.inputOptions = ['BOOLEAN', 'FILE', 'NUMBER', 'STRING'];

            function setInputField(){
                if($scope.data.fieldEntryValueDTOList.length > 1){
                    $scope.isMultiSelect = true;
                    if($scope.data.fieldEntryValueDTOList[0].valueAsString){
                        $scope.select.inputType = 'STRING';
                    } else if($scope.data.fieldEntryValueDTOList[0].valueAsNumber){
                        $scope.select.inputType = 'NUMBER';
                    }
                } else {
                    if($scope.data.fieldEntryValueDTOList[0].valueAsBoolean != null) {
                        $scope.select.inputType = 'BOOLEAN';
                    } else if($scope.data.fieldEntryValueDTOList[0].valueAsFile) {
                        $scope.select.inputType = 'FILE';
                    } else if($scope.data.fieldEntryValueDTOList[0].valueAsNumber) {
                        $scope.select.inputType = 'NUMBER';
                    } else if($scope.data.fieldEntryValueDTOList[0].valueAsString) {
                        $scope.select.inputType = 'STRING';
                    }
                }
            }

            setInputField();

            $scope.inputChanged = function (){
                if($scope.data.fieldEntryValueDTOList.length > 1){
                    for(let i=0; i < $scope.data.fieldEntryValueDTOList.length; i++){
                        $scope.data.fieldEntryValueDTOList[i].valueAsString = null;
                        $scope.data.fieldEntryValueDTOList[i].valueAsNumber = null;
                    }
                } else {
                    $scope.data.fieldEntryValueDTOList[0].valueAsString = null;
                    $scope.data.fieldEntryValueDTOList[0].valueAsNumber = null;
                    $scope.data.fieldEntryValueDTOList[0].valueAsBoolean = false;
                    $scope.data.fieldEntryValueDTOList[0].valueAsFile = null;
                }
            };

            $scope.setMultiSelectInputValue = function (index, value){
                if($scope.select.inputType === 'NUMBER'){
                    $scope.data.fieldEntryValueDTOList[index].valueAsNumber = value;
                } else if($scope.select.inputType === 'STRING'){
                    $scope.data.fieldEntryValueDTOList[index].valueAsString = value;
                }
            };

            $scope.disableSave = function (){
                if($scope.isMultiSelect){
                    if($scope.select.inputType === 'NUMBER'){
                        for(let i=0; i < $scope.data.fieldEntryValueDTOList.length; i++){
                            if (($scope.data.fieldEntryValueDTOList[i].valueAsNumber == null) ||
                                ($scope.data.fieldEntryValueDTOList[i].valueAsNumber == '')){
                                return true;
                            }
                        }
                    } else if($scope.select.inputType === 'STRING') {
                        for(let i=0; i < $scope.data.fieldEntryValueDTOList.length; i++){
                            if (($scope.data.fieldEntryValueDTOList[i].valueAsString == null) ||
                                ($scope.data.fieldEntryValueDTOList[i].valueAsString == '')) {
                                return true;
                            }
                        }
                    }
                } else if( $scope.data.fieldEntryValueDTOList[0].valueAsNumber == null &&
                    $scope.data.fieldEntryValueDTOList[0].valueAsString == null &&
                    $scope.data.fieldEntryValueDTOList[0].valueAsBoolean == null &&
                    $scope.data.fieldEntryValueDTOList[0].valueAsFile == null) {
                    return true;
                }
                return ($scope.data.ticketLinked == null ||
                    $scope.data.reasonForChange == null);
            };

            $scope.update = function () {
                if($scope.select.inputType !== 'BOOLEAN') {
                    $scope.data.fieldEntryValueDTOList[0].valueAsBoolean = null;
                }
                $http({
                    url     : localWeb.createUrl('api/forms/fieldValueChange/'),
                    method  : 'POST',
                    headers : {'Content-Type' : 'application/json'},
                    data    : JSON.stringify($scope.data),
                }).then( () => {
                    $scope.ngDialogData.entryField.formFieldEntryValues = $scope.data.fieldEntryValueDTOList;
                }).catch(err=> {
                    console.error(err);
                });
            };
        });
})();
