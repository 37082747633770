/* eslint-disable no-use-before-define */
import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 *
 * Model for an container display config with content from external resource through url
 */

angular.module('acadiamasterApp')
    .factory('ContainerContentDisplayConfigImpl', ModelServiceBase => {
        const { classes } = ProgramContainerConstantsService;

        ContainerContentDisplayConfigImpl.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
        /*
         * @constructor
         */
        function ContainerContentDisplayConfigImpl (parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

            this.height = null;
            this.url = null;
        }

        /*
         * convert the current UI model to dto format
         */
        ContainerContentDisplayConfigImpl.prototype.toDto = function () {
            const dto = {};
            dto[classes.classReferenceKey] = classes.ContainerContentDisplayConfigImpl;

            dto.height = this.height;
            dto.url = this.url;

            return dto;
        };

        /*
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerContentDisplayConfigImpl.prototype.fromDto = function (dto) {
            if (dto == null) {
                return;
            }

            this.height = dto.height;
            this.url = dto.url;
        };

        /*
         * validate the entity
         */
        ContainerContentDisplayConfigImpl.prototype._validateSelf = function () {
            this.clearError();
            let errMsg = null;
            if (this.url == null || this.url.length === 0) {
                errMsg = 'Valid URL is required';
            }
            if (errMsg != null) {
                this.setErrorMessage(errMsg);
                if (this._parent != null && this._parent._parent != null && !this._parent._parent.hasError()) {
                    // set container error message to if there are no other error message at container level
                    // this is hack for UI display because this object itself doesn't have top level display
                    this._parent.setErrorMessage(errMsg);
                }
            }
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerContentDisplayConfigImpl;
    });
