import { columns, formatData } from './detailDialog.constants';

angular.module('acadiamasterApp').controller('DetailDialogController',
    function (data, loadOrders, ngDialog, ParticipantProfileService, AlertService, $rootScope) {
        const vm = this;

        vm.columns = columns;
        vm.orderDetails = formatData(data.order, data.cancelation);
        vm.selectedOption = null;
        vm.loadOrderDetail = (id, programName) => {
            ParticipantProfileService.getOrderDetailsById(id)
                .then(response => {
                    vm.orderDetails = formatData({
                        ...response.data,
                        programName,
                    },
                    data.cancelation);
                }).catch(() => {
                    AlertService.error('Failed to retrieve order details for given user');
                });
        };
        vm.refresh = (id, programName) => {
            vm.loadOrderDetail(id, programName);
            loadOrders();
        };
        vm.showCancelReason = () => {
            ngDialog.openConfirm({
                appendTo   : '#cancel-order',
                className  : 'ngdialog-theme-plain cancellation-dialog',
                controller : [ function () {
                    let ocr = this;
                    ocr.data = data.cancelation;
                } ],
                controllerAs     : 'ocr',
                preCloseCallback : () => {
                    $rootScope.dialogCancelHasJustClosed = true;
                    return true;
                },
                templateUrl : 'admin-templates/site/participant/profile/experience/orderDetails/detailDialog/cancelDialog.template.html',
            }).then(selectedOption => {
                // Call API cancel order
                ParticipantProfileService.cancelOrder(data.order.id, {
                    cancellationReason : selectedOption.cancellationReason,
                    cancellationType   : selectedOption.cancellationType,
                }).then(() => {
                    AlertService.success('Cancel Order Request completed successfully!. Order has been marked as cancelled.');
                    vm.refresh(data.order.id, data.selectOrder.programName);
                }, () => {
                    AlertService.error('Cancel Order Request Failed. Oops! There was a problem in cancelling the order. Please try again after sometime.');
                });
            });
        };
        return vm;
    });
