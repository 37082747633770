(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('previewIfAssertCondition', function (ProgramTestConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/' +
            'preview/previewIfAssertCondition.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.isGroupForNotOperator = function() {
                    return $scope.condition.joinCondition == ProgramTestConstantsService.joinConditions.NOT;
                };

                $scope.hasSubItems = function() {
                    var condition = $scope.condition;
                    return condition!=null && condition.subItems!=null && condition.subItems.length > 0;
                };
            }
        };
    });
})();