/* eslint-disable no-use-before-define */
/**
 * directive for configuring the template and its value
 */
angular.module('acadiamasterApp').directive('containerTemplateConfig', (TemplateCacheService, AlertService) => ({
    link ($scope) {
        $scope.data = {
            isOpen       : false,
            ready        : false,
            template     : null,
            templateKeys : null,
        };

        /*
         * Get the maximum index from an array of container items,
         * @param containerItemArray - item array
         * @returns {number} - the number of the last item whose template to follow
         */
        function getDisplayOrders (containerItemArray) {
            const displayOrders = containerItemArray.reduce((acc, containerItem, index) => {
                if ($scope.element.type === containerItem.type && containerItem.templateKey) {
                    acc.push(index);
                }
                return acc;
            }, []);
            return Math.max(...displayOrders);
        }

        /**
         * If the current templateKey has value, do nothing;
         * if the current templateKey does not have value, set it to the one from the largest indexed item of the same type.
         */
        function setLastSelectedTemplateKey () {
            if ($scope.element.templateKey) {
                return;
            }
            const containerItemArray = $scope.element._parent.containerItems
                .filter(containerItem => $scope.element.type === containerItem.type);
            const maxDisplayOrder = getDisplayOrders(containerItemArray);
            containerItemArray.forEach((containerItem, index) => {
                if (index === maxDisplayOrder) {
                    $scope.element.templateKey = containerItem.templateKey;
                }
            });
        }

        init($scope.data);

        /*
         * initialize data needed for the directive include list of template keys and the template itself
         * @param data - data object used to store the information
         */
        function init (data) {
            data.ready = false;
            TemplateCacheService.loadTemplateKeys(false).then(result => {
                data.templateKeys = result;
                data.ready = true;
            }, error => {
                AlertService.error(error);
                data.ready = false;
            });

            setLastSelectedTemplateKey();
        }
    },
    restrict : 'E',
    scope    : {
        containerList : '<',
        element       : '=',
        isPreview     : '<?',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/containerTemplateConfig.html',
}));
