'use strict';

(function () {

    angular.module('acadiamasterApp').directive('hasBannerByTypeConfig', function (RuleConstantsService, ProgramTestConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/hasBannerNotificationByType.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.cs = RuleConstantsService;
                $scope.pc = ProgramTestConstantsService;
            }
        };
    });
})();