
angular.module('acadiamasterApp')
    .controller('ConfigFilesAddEditController', function ($scope, $state, $stateParams, DeepLinkService, ProgramService) {
        const vm = this;
        $scope.config = {
            androidIds  : [],
            appleAppIds : [],
            fingerprint : '',
        };
        $scope.addAppleList = function () {
            $scope.index = $scope.appleIdList.length;
            let newItemNo = ++$scope.index;
            $scope.appleIdList.push({ id : newItemNo, name : '' });
        };

        $scope.addAndroidList = function () {
            $scope.androidList.push({ packageName : '' });
        };

        $scope.removeAppleId = function (id) {
            $scope.appleIdList = $scope.appleIdList.filter(item => item.id !== id);
        };

        $scope.removeAndroidId = function (index) {
            if (index > -1) {
                $scope.androidList.splice(index, 1); // 2nd parameter means remove one item only
            }
        };

        $scope.save = function () {
            if ($scope.appleIdList.length > 0) {
                $scope.appleIdList.forEach(item => {
                    if (item.name !== '') {
                        $scope.config.appleAppIds.push(item.name);
                    }
                });
            }
            if ($scope.androidList.length) {
                $scope.androidList.forEach((item, index) => {
                    if (item.packageName !== '') {
                        $scope.config.androidIds[index] = $scope.androidList[index];
                    }
                });
            }
            DeepLinkService.createConfigFile($stateParams.id, $scope.config).then(res => {
                $state.go('program.manage.deepLinks');
            });
        };

        vm.$onInit = () => {
            DeepLinkService.getProgramConfig($stateParams.id).then(res => {
                if (res.data) {
                    $scope.program = res.data.name;
                }
                if (res.data.programDeepLinkConfig) {
                    let deepLinkConfig = res.data.programDeepLinkConfig;
                    $scope.config.fingerprint = deepLinkConfig.fingerprint;
                    if (deepLinkConfig.appleAppIds.length) {
                        let refArr = [];
                        deepLinkConfig.appleAppIds.forEach((item, index) => {
                            refArr.push({ id : index + 1, name : item });
                        });
                        $scope.appleIdList = refArr;
                    } else {
                        $scope.appleIdList = [ { id : 1, name : '' } ];
                    }
                    if (deepLinkConfig.androidIds.length) {
                        $scope.androidList = deepLinkConfig.androidIds;
                    } else {
                        $scope.androidList = [ { packageName : '' } ];
                    }
                } else {
                    $scope.appleIdList = [ { id : 1, name : '' } ];
                    $scope.androidList = [ { packageName : '' } ];
                }
            });
        };
    });
