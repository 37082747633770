(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('testCasePreview', function (ProgramTestService, AlertService,
                                                                             ProgramTestModelService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/' +
            'preview/testCasePreview.html',
            scope: {
                testCaseId: '=',
                closeFunction: '=?'
            },
            link: function ($scope) {
                // a list of flags to control the open/collapse of the panels
                $scope.flags = {
                    preCondition : true,
                    ifCondition : true,
                    assertCondition : true,
                    correctiveAction : true
                };

                $scope.getCount = function(inputArray) {
                    if (_.isArray(inputArray)) {
                        return inputArray.length;
                    }

                    return 0;
                };

                $scope.getFlagClass = getFlagClass;

                $scope.testCase = null;

                init($scope);
            }
        };

        // ------------------ private functions -----------------

        /**
         * initialization function for the directive
         * @param $scope
         */
        function init($scope) {
            // load full program test now
            ProgramTestService.getTestCase($scope.testCaseId).then(function(response) {
                $scope.testCase = ProgramTestModelService.loadModelFromDto(response.data);
            }).catch(function(error) {
                AlertService.error('unable to load program test by id : ' + $scope.testCaseId, null, error);
            });
        }

        /**
         * getting the class for a specific boolean value
         * @param booleanValue - value that should be true or false, null is treated as false
         * @return classes name for an icon indicate something is selected or not
         */
        function getFlagClass(booleanValue) {
            if (booleanValue) {
                return "glyphicon-check text-success";
            }

            return "glyphicon-remove text-danger";
        }
    });
})();