/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * service for all the operations
     */
        .service('FixedNodeService',
            function (RuleUtilService) {
                return {
                    removeTrigger: removeTrigger,
                    removeFunctionalExpression: removeFunctionalExpression,
                    removeSQLExpressionHolder: removeSQLExpressionHolder,
                };

                /**
                 * Remove trigger
                 * @param fixedTriggerNode
                 * @param trigger
                 * @param index
                 * @param selector
                 */
                function removeTrigger(fixedTriggerNode, trigger, index, selector) {
                    // Remove trigger from trigger list
                    RuleUtilService.removeItemFromList(fixedTriggerNode.triggers, trigger, index, selector);
                }

                /**
                 * Remove expression
                 * @param fixedExpressionNode
                 * @param expression
                 * @param index
                 * @param selector
                 */
                function removeFunctionalExpression(fixedExpressionNode, expression, index, selector) {
                    // remove action from removed expression
                    _.forEach(expression.actionHolders, function (actionHolder) {
                        expression.getRule().removeActionUsage(actionHolder.action);
                    });

                    // Remove expression from expression list
                    RuleUtilService.removeItemFromList(fixedExpressionNode.expressions, expression, index, selector);
                }

                function removeSQLExpressionHolder(parent, holder, index, selector) {
                    // remove action from removed expression
                    if(holder.actionHolderFixedNode){
                        _.forEach(holder.actionHolderFixedNode.actionHolders, function (actionHolder) {
                            actionHolder.getRoot().removeActionUsage(actionHolder.action);
                        });
                    }

                    // Remove holder from list
                    RuleUtilService.removeItemFromList(parent.expressionActionHolders, holder, index, selector);
                }

            });
})();

