import {
    fieldDisplayConfig,
} from './textLabel.preset.config';

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('textLabelSelectPresetConfig', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/presetHelp/textLabel.preset.config.html',
                scope: {},
                link: function ($scope) {
                    $scope.fieldDisplayConfig = fieldDisplayConfig;
                }
            };
        });

})();

