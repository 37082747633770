/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-eq-null */
/* eslint-disable no-use-before-define */

(function () {
    // eslint-disable-next-line angular/no-service-method
    angular.module('acadiamasterApp')
        .service('RuleUtilService', function (vbrCommonUtil, RuleConstantsService, Form,
            BusinessRule, VbrTreeUtilService, VbrSelectorService, FormConstants) {
            let nodeTypeMap = createNodeTypeMap();

            return {
                addFormSearchOptions : addFormSearchOptions,
                businessRuleService  : BusinessRule,

                colorUtil : vbrCommonUtil.colorUtil,

                convertValueFromSeconds : convertValueFromSeconds,

                convertValueToSeconds : convertValueToSeconds,

                findActionHolderByCode : findActionHolderByCode,

                findElementByCode : findElementByCode,

                findElementById : findElementById,

                formConstant : FormConstants,

                formDataService : Form,

                fromDateToUTCTimestamp : fromDateToUTCTimestamp,

                fromUTCTimestampToDate : fromUTCTimestampToDate,

                getFormById : getFormById,

                getFormIdFromFormSearchOptions : getFormIdFromFormSearchOptions,

                isScheduleRule : function (model) {
                    if (model == null) {
                        return false;
                    }
                    return model.getRoot().ruleType === RuleConstantsService.ruleType.PROGRAM_SCHEDULE_RULE;
                },

                localCodeGenerator : {
                    _latestLocalIds : {
                        action    : this._localIdStart,
                        condition : this._localIdStart,
                    },
                    _localIdStart : 1,
                    getNextCode   : function (type) {
                        if (this._latestLocalIds[type.type] == null) {
                            this._latestLocalIds[type.type] = this._localIdStart;
                        } else {
                            this._latestLocalIds[type.type]++;
                        }
                        return type.prefix + this._latestLocalIds[type.type];
                    },

                    // Method to reset the localId
                    resetCode : function (type, code) {
                        if (type == null) {
                            return;
                        }
                        if (code == null) {
                            // Reset to 1 if nothing is specified
                            this._latestLocalIds[type.type] = null;
                            return;
                        }
                        let extractedId = code.replace(type.prefix, '');

                        if (this._latestLocalIds[type.type] < extractedId) {
                            this._latestLocalIds[type.type] = extractedId;
                        }
                    },
                },

                removeItemFromList : removeItemFromList,

                ruleConstants : RuleConstantsService,

                ruleTreeOptions : {
                    accept  : accept,
                    dropped : dropped,
                },
                selector : VbrSelectorService,

            };

            /** ****************************************************
             * private functions after this
             ******************************************************/

            function fromUTCTimestampToDate (timestamp) {
                if (timestamp) {
                    let date = new Date(timestamp);
                    date.setTime(timestamp + date.getTimezoneOffset() * 60000);
                    return date;
                }
                return null;
            }

            function fromDateToUTCTimestamp (date) {
                if (date) {
                    let timestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
                        date.getHours(), date.getMinutes(), date.getSeconds());
                    return timestamp;
                }
                return null;
            }

            /*
             * Method to fetch form from id
             * @param formId
             * @returns {*}
             */
            function getFormById (formId) {
                if (formId == null) {
                    return null;
                }

                let form = Form.get({ id : formId, projection : Form.projections.NONE });
                form.$promise.then(() => {
                    // Form success
                });
                return form;
            }

            /*
             * Method to add form search options in specified model
             * @param model
             */
            function addFormSearchOptions (model) {
                if (model == null) {
                    return;
                }

                model.form = {};
                model.form.options = {
                    form     : null,
                    title    : 'Form',
                    viewOnly : false,
                };
            }

            /*
             * Method to get form id from form model
             * Form and form options are added to use search-form directive in
             * form action element config
             * @param model
             * @returns {*}
             */
            function getFormIdFromFormSearchOptions (model) {
                if (model.form == null || model.form.options == null || model.form.options.form == null) {
                    return null;
                }
                return model.form.options.form.id;
            }

            /*
             * Method to convert value of given unit into seconds
             * @param value
             * @param unit
             */
            function convertValueToSeconds (value, unit) {
                if (value == null || unit == null || unit.multiplier == null) {
                    return null;
                }
                return value * unit.multiplier;
            }

            /*
             * Method to convert specified value in seconds to biggest possible unit, return object { convertedValue, unit }
             * @param timeInSeconds
             * @param units
             * @returns {*}
             */
            function convertValueFromSeconds (timeInSeconds, units) {
                if (timeInSeconds == null) {
                    return null;
                }

                // sort so we iterate over increasing unit size
                const unit = Object.values(units || RuleConstantsService.timeUnits)
                    .sort((a, b) => b.multiplier - a.multiplier)
                    .find(timeUnit => timeInSeconds % timeUnit.multiplier === 0);

                if (unit) {
                    return {
                        unit,
                        value : timeInSeconds / unit.multiplier,
                    };
                }
                return null;
            }

            function findElementByCode (list, code) {
                if (code == null || list == null) {
                    return null;
                }
                return _.find(list, { code : code });
            }

            function findElementById (list, id) {
                if (id == null || list == null) {
                    return null;
                }
                return _.find(list, { id : id });
            }

            function findActionHolderByCode (list, code) {
                if (code == null || list == null) {
                    return null;
                }

                return _.find(list, holder => {
                    return holder.action.code == code;
                });
            }

            /*
             * Method to remove item from specified list
             * @param list
             * @param item
             * @param index
             * @param selector
             */
            function removeItemFromList (list, item, index, selector) {
                if (index == null) {
                    index = _.findIndex(list, m => {
                        return m == item;
                    });
                }

                if (index != null && index >= 0) {
                    list.splice(index, 1);
                    if (selector != null) {
                        selector.itemRemoved(item);
                    }
                }
            }

            /** *****************************
             * tree option functions
             *****************************/
            /*
             * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
             * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
             * @param sourceNodeScope
             * @param destNodeScope
             * @returns {boolean}
             */
            function accept (sourceNodeScope, destNodeScope) {
                let sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
                let destType = VbrTreeUtilService.getParentTypeFromNodeScope(destNodeScope);
                if (nodeTypeMap[sourceType].typesCanMoveUnder && nodeTypeMap[sourceType].typesCanMoveUnder.indexOf(destType) != -1) {
                    if (sourceType == RuleConstantsService.nodeType.ACTION) {
                        return handleActionNodeDrop;
                    }
                    return true;
                }

                return false;
            }

            /*
             * Method to handle action node drop.
             * Parent node should not accept action node if it already has action with same action code
             * @param sourceNodeScope
             * @param destNodeScope
             * @returns {boolean}
             */
            function handleActionNodeDrop (sourceNodeScope, destNodeScope) {
                let existingAction = findElementByCode(destNodeScope.$nodeScope.$modelValue.actions, sourceNodeScope.action.code);
                return !(existingAction != null && existingAction._parent != sourceNodeScope.action._parent);
            }

            /*
             * event dropped is called after node moves it's position
             * @param event
             */
            function dropped (event) {
                let sourceModel = event.source.nodeScope.$modelValue;
                let destModel = event.dest.nodesScope.$nodeScope.$modelValue;
                if (sourceModel._parent != destModel) {
                    sourceModel._parent = destModel;
                }
            }

            function createNodeTypeMap () {
                let map = {};
                map[RuleConstantsService.nodeType.TRIGGER] = createNodeTypeItem(RuleConstantsService.nodeType.TRIGGER, [ RuleConstantsService.nodeType.TRIGGER_FIXED_NODE ]);
                map[RuleConstantsService.nodeType.ACTION] = createNodeTypeItem(RuleConstantsService.nodeType.ACTION, [ RuleConstantsService.nodeType.EXPRESSION, RuleConstantsService.nodeType.ACTION_HOLDER_FIXED_NODE ]);
                map[RuleConstantsService.nodeType.TRIGGER_FIXED_NODE] = createNodeTypeItem(RuleConstantsService.nodeType.TRIGGER_FIXED_NODE, null);
                map[RuleConstantsService.nodeType.FUNCTIONAL_EXPRESSION_FIXED_NODE] = createNodeTypeItem(RuleConstantsService.nodeType.FUNCTIONAL_EXPRESSION_FIXED_NODE, null);
                map[RuleConstantsService.nodeType.CONDITION] = createNodeTypeItem(RuleConstantsService.nodeType.CONDITION, null);
                map[RuleConstantsService.nodeType.EXPRESSION] = createNodeTypeItem(RuleConstantsService.nodeType.EXPRESSION, [ RuleConstantsService.nodeType.FUNCTIONAL_EXPRESSION_FIXED_NODE ]);
                map[RuleConstantsService.nodeType.WORKFLOW] = createNodeTypeItem(RuleConstantsService.nodeType.WORKFLOW, [ RuleConstantsService.nodeType.RULE ]);
                map[RuleConstantsService.nodeType.SQL_EXPRESSION_ACTION_HOLDER_FIXED_NODE] = createNodeTypeItem(RuleConstantsService.nodeType.SQL_EXPRESSION_ACTION_HOLDER_FIXED_NODE, [ RuleConstantsService.nodeType.SQL_EXPRESSION_FIXED_NODE ]);
                return map;
            }

            function createNodeTypeItem (nodeType, typesCanMoveUnder) {
                return {
                    nodeType          : nodeType,
                    typesCanMoveUnder : typesCanMoveUnder,
                };
            }
        });
}());
