(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programPreCondition', function (ProgramService, ProgramModelService) {
        return {
            restrict: 'E',
            template: '<program-selection is-required="true" ng-if="programOptions" ' +
            'program-options="programOptions" title="Program"></program-selection>',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {

                /**
                 * program options used for program search directive
                 * @type {{getProgram: getProgram, setProgram: setProgram}}
                 */
                $scope.programOptions = {
                    getProgram: function () {
                        return $scope.preCondition.getProgram();
                    },
                    setProgram: function (program) {
                        $scope.preCondition.setProgram(program);
                    }
                };
            }
        };
    });
})();