/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola on 02/19/2020
 *
 * controller for managing deep links on the program or system level
 */

angular.module('acadiamasterApp')
    .controller('DeepLinkMgmtController', function ($translate, $timeout, AlertService, DeepLinkConstants,
        DeepLinkModel, DeepLinkService, PageSelectorService, PageModel, ngDialog, SEARCH_FILTER_CONSTANTS,
        $state, vbrCommonUtil, SessionStorageService, CONFIG, FormSearch) {
        const vm = this;
        vm.isReadOnlyServer = CONFIG.readOnlyServer;

        vm.cs = DeepLinkConstants;
        vm.fields = [
            vm.cs.FIELDS.ID,
            vm.cs.FIELDS.CODE,
            vm.cs.FIELDS.TARGET_TYPE,
            vm.cs.FIELDS.PAGE,
            vm.cs.FIELDS.DETAILS,
            vm.cs.FIELDS.UPDATED_ON,
            vm.cs.FIELDS.UPDATED_BY_ID,
            vm.cs.FIELDS.ENABLED,
            vm.cs.FIELDS.ACTIONS,
        ];

        vm.detailFields = [
            vm.cs.FIELDS.FORM_ID,
            vm.cs.FIELDS.CONTAINER_ID,
            vm.cs.FIELDS.CONTAINER_ITEM_ID,
            vm.cs.FIELDS.PAGE_ID,
            vm.cs.FIELDS.WORKFLOW_NAME,
            vm.cs.FIELDS.PARAMETER,
        ];

        vm.filteredFields = [
            SEARCH_FILTER_CONSTANTS.FIELDS.ENABLED,
            SEARCH_FILTER_CONSTANTS.FIELDS.DEEP_LINK_TARGET_TYPE,
        ];

        vm.data = {
            containers     : [],
            editLink       : null,
            filteredLinks  : [],
            filteredValues : {},
            forms          : [],
            isLoading      : true,
            links          : [],
            loadingError   : false,
            numFilters     : 0,
            pageMap        : {},
        };

        vm.searchData = {
            ascending : false,
            orderBy   : vm.cs.FIELDS.UPDATED_ON.id,
        };

        vm.storageDomain = $state.current.name;

        vm.filterLink = link => {
            let result = true;
            Object.keys(vm.data.filteredValues).forEach(key => {
                if (!result) {
                    return;
                }
                const value = vm.data.filteredValues[key];
                const type = typeof value;
                if (type === 'boolean' || type === 'number') {
                    result = link[key] === value;
                    return;
                }
                if (type === 'string') {
                    const values = value.split(',');
                    result = values.some(val => link[key] === val);
                    return;
                }
            });
            return result;
        };

        vm.setOrderBy = fieldId => {
            if (vm.searchData.orderBy === fieldId) {
                vm.searchData.ascending = !vm.searchData.ascending;
            } else {
                vm.searchData.orderBy = fieldId;
                vm.searchData.ascending = true;
            }
        };

        /*
         * Creates a temporary element, appends it to the DOM, and uses it to copy text to the
         * user's clipboard.
         */
        vm.copyLinkCode = code => {
            let element = document.createElement('textarea');
            document.body.appendChild(element);
            element.value = code;
            element.select();
            element.setSelectionRange(0, 99999); /* For mobile devices*/
            document.execCommand('copy');
            document.body.removeChild(element);
            AlertService.success(
                $translate.instant('deepLinks.alerts.codeCopied', {
                    code,
                }),
            );
        };

        /*
         * download / export one deep link dto by id
         * @param deepLinkId - deep link id
         */
        vm.download = deepLinkId => {
            // get deep link promise
            DeepLinkService.getDeepLink(deepLinkId)
                .then(response => {
                    // create file name
                    const fileName = `deep_link_${deepLinkId}.json`;
                    // download as file in browser
                    vbrCommonUtil.downloadAsFile(fileName, response.data);
                }, error => {
                    console.error('Error getting a deep link from API', error);
                });
        };

        /*
         * import one deep link by uploading .json file
         */
        vm.importDeepLinks = () => {
            ngDialog.openConfirm({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : [ '$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        loadData(vm.entity.id);
                    };
                } ],
                plain    : true,
                size     : 'md',
                template : '<upload-to-configuration-server title="Upload Deep Link" ws-url="/api/configuration/deepLink/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
            }).then(() => {
                loadData(vm.entity.id);
            });
        };

        /*
         * Opens the Deep Link edit dialog. Allows the user to edit and save the link's properties.
         */
        vm.openEditDialog = link => {
            vm.data.editLink = new DeepLinkModel();
            const editLink = vm.data.editLink;
            if (link) {
                editLink.fromDto(link.toDto());
                editLink.form = editLink.formId
                    ? vm.data.forms.find(form => form.value === editLink.formId)
                    : null;
                editLink.container = editLink.containerId
                    ? vm.data.containers.find(container => container.value === editLink.containerId)
                    : null;
            }
            editLink.programId = vm.entity.id;
            ngDialog.open({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : [ '$scope', function ($scope) {
                    $scope.dlc = vm;
                    $scope.isNew = link === undefined;

                    $scope.selectForm = () => {
                        editLink.formId = editLink.form
                            ? editLink.form.value
                            : null;
                    };
                    $scope.selectContainer = () => {
                        editLink.containerId = editLink.container
                            ? editLink.container.value
                            : null;
                    };
                    $scope.selectPage = page => {
                        editLink.pageId = page?.id || null;
                    };
                    $scope.parameter = editLink.parameter;
                    $scope.saveLink = () => {
                        if (link) {
                            link.fromDto(editLink.toDto(), true);
                            link.update().then(() => {
                                ngDialog.close();
                            });
                        } else {
                            editLink.create().then(() => {
                                vm.data.links.push(editLink);
                                ngDialog.close();
                            });
                        }
                    };
                } ],
                templateUrl : 'admin-templates/site/deepLinks/editDialog/deepLinkEditor.template.html',
            });
        };

        vm.onFiltersUpdated = () => {
            vm.data.numFilters = Object.keys(vm.data.filteredValues).length;
        };

        vm.editLink = linkId => {
            if (vm.isReadOnlyServer) {
                return;
            }
            $state.go(
                 'program.manage.deepLinks.edit',
                { linkId });
        };

        vm.addConfigFile = () => {
            $state.go('program.manage.deepLinks.addEditConfig');
        };

        /*
         * Loads links and pages from the server
         * @param {String} programId
         */
        function loadData (programId) {
            vm.data.isLoading = true;
            const promises = [ DeepLinkService.loadLinks(programId) ];
            if (programId) {
                promises.push(PageSelectorService.getPages(programId));
            }
            Promise.all(promises)
                .then(responses => {
                    $timeout(() => {
                        vm.data.links = responses[0].data.map(dto => new DeepLinkModel().fromDto(dto));
                        if (responses[1]) {
                            vm.data.pageMap = {};
                            responses[1].data.forEach(dto => {
                                const pageModel = new PageModel().fromDto(dto);
                                vm.data.pageMap[pageModel.id] = pageModel;
                            });
                        }
                        updateLinkFormData();
                        updateLinkContentContainerData();
                    });
                }, error => {
                    vm.data.loadingError = true;
                    console.error('Error getting deep links from API', error);
                }).finally(() => {
                    vm.data.isLoading = false;
                });
        }

        // load form data from the API and get it ready for display in a typeahead field
        function loadForms () {
            FormSearch.query({
                size: 500,
                programId: $state.params.id,
            }).then(response => {
                    vm.data.forms = response.data.map(form => ({
                        name  : `${form.id } - ${ form.name}`,
                        value : form.id,
                    }));
                    updateLinkFormData();
                }, error => {
                    console.error(error);
                });
        }

        function updateLinkFormData () {
            // only do this once both links and forms have loaded.
            if (vm.data.links.length === 0 || vm.data.forms.length === 0) {
                return;
            }
            // update each link with form data
            vm.data.links.forEach(link => {
                link.form = vm.data.forms.find(form => form.value === link.formId);
            });

            // if the user is currently editing, update the temporary editable link
            const editLink = vm.data.editLink;
            if (editLink && editLink.formId) {
                editLink.form = vm.data.forms.find(form => form.value === editLink.formId);
            }

            // cache search params for reload and browser history
            SessionStorageService.setItem(
                vm.storageDomain,
                {
                    searchData : vm.searchData,
                },
                vm.storageDomain,
            );
        }

        // load container data from the API and get it ready for display in a typeahead field
        function loadContentContainers (programId) {
            DeepLinkService.getContentContainers(programId)
                .then(response => {
                    vm.data.containers = response.data.map(container => ({
                        name  : `${container.id } - ${ container.name}`,
                        value : container.id,
                    }));
                    updateLinkContentContainerData();
                }, error => {
                    console.error(error);
                });
        }

        function updateLinkContentContainerData () {
            // only do this once both links and containers have loaded.
            if (vm.data.links.length === 0 || vm.data.containers.length === 0) {
                return;
            }
            // update each link with container data and page (default to Learning Center for Content Containers)
            vm.data.links.forEach(link => {
                link.container = vm.data.containers.find(container => container.value === link.containerId);
            });

            // if the user is currently editing, update the temporary editable link
            const editLink = vm.data.editLink;
            if (editLink && editLink.containerId) {
                editLink.container = vm.data.containers.find(container => container.value === editLink.containerId);
            }
            // cache search params for reload and browser history
            SessionStorageService.setItem(
                vm.storageDomain,
                {
                    searchData : vm.searchData,
                },
                vm.storageDomain,
            );
        }

        vm.$onInit = () => {
            // load data from session storage service
            let data = SessionStorageService.getItem($state.current.name);
            if (data) {
                vm.searchData = data.searchData;
            }

            // if a program exists, load data based on the programId.
            if (vm.entity) {
                vm.entity.$promise
                    .then(response => {
                        vm.entity = response;
                        loadData(vm.entity.id);
                        loadContentContainers(vm.entity.id);
                    }, error => {
                        console.error('Error loading program entity.', error);
                        vm.data.loadingError = true;
                    });
            }
            loadForms();
        };
    });
