(function () {
    'use strict';

    /***************************************************************
     * utility for loading rule from server, the input object should have the following property and prototype function
     *  -- ruleId (property, a number)
     *  -- getRule()
     *  -- setRule(rule)
     ***************************************************************/

    angular.module('acadiamasterApp').factory('RuleLoadUtil', function (BusinessRule, AlertService, RuleModelService) {

        return {
            loadRule: loadRule
        };

        /**
         * load rule from server if needed for the container object
         * @param containerWithRule - container object that contains a field for rule id and function for get/set rule
         */
        function loadRule(containerWithRule) {
            if (requiredFunctionNotDefined(containerWithRule)) {
                AlertService.error('container with rule is not valid', null, containerWithRule);
                return;
            }

            var ruleId = containerWithRule.ruleId;
            var rule = containerWithRule.getRule();

            if (ruleId == null) {
                // nothing to load
                return;
            }

            if (rule != null && rule.id === ruleId) {
                // already loaded, do nothing
                return;
            }

            BusinessRule.get({id : ruleId}).$promise.then(function (data) {
                var ruleModel = new RuleModelService.RuleModel(null, null);
                ruleModel.fromDto(data);

                containerWithRule.setRule(ruleModel);
            }).catch(function (error) {
                AlertService.error('failed to load rule by id : ' + ruleId, null, error);
            });
        }

        function requiredFunctionNotDefined(container) {
            return !_.isFunction(container.getRule) || !_.isFunction(container.setRule);
        }

    });
})();
