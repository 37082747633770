/**
 * An AngularJS directive for Dropzone.js, http://www.dropzonejs.com/
 *
 * Usage:
 *
 * <div ng-app="app" ng-controller="SomeCtrl">
 *   <button dropzone="dropzoneConfig">
 *     Drag and drop files here or click to upload
 *   </button>
 * </div>
 */

// todo: below code doesn't work, it depends on global variable Dropzone which isn't defined globally in drop zone library
// // Modifications to their original library code
// // (pulled this out of our dropzone.js code that was in jslib/dropzone)
// // we don't need much of it and this code needs to be looked at to see if its necessary
// Dropzone.prototype.accept = function(file, done) {
//     if (file.size > this.options.maxFilesize * 1024 * 1024) {
//         return done(this.options.dictFileTooBig.replace("{{filesize}}", Math.round(file.size / 1024 / 10.24) / 100).replace("{{maxFilesize}}", this.options.maxFilesize));
//     } else if ((this.options.maxFiles != null) && this.getAcceptedFiles().length >= this.options.maxFiles) {
// //        done(this.options.dictMaxFilesExceeded.replace("{{maxFiles}}", this.options.maxFiles));
//         return this.emit("maxfilesexceeded", file);
//     } else if (!Dropzone.isValidFile(file, this.options.acceptedFiles)) {
//         return done(this.options.dictInvalidFileType);
//     } else {
//         return this.options.accept.call(this, file, done);
//     }
// };
//
// Dropzone.prototype.getErrorFiles = function() {
//     return this.getFilesWithStatus(Dropzone.ERROR);
// };
//
//

angular.module('dropzone', [])
    .directive('dropzone', function () {
        return function (scope, element, attrs) {
            var config, dropzone;

            config = scope[attrs.dropzone];

            // create a Dropzone for the element with the given options
            dropzone = new Dropzone(element[0], config.options);

            // bind the given event handlers
            angular.forEach(config.eventHandlers, function (handler, event) {
                dropzone.on(event, handler);
            });


            scope.dropzone = dropzone;
        };
    });

/*angular.module('app', ['dropzone']);

angular.module('app').controller('SomeCtrl', function ($scope) {
  $scope.dropzoneConfig = {
    'options': { // passed into the Dropzone constructor
      'url': 'upload.php'
    },
    'eventHandlers': {
      'sending': function (file, xhr, formData) {
      },
      'success': function (file, response) {
      }
    }
  };
});*/