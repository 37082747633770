(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('expressionInformation', function (ExpressionInformationService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/util/expression/expressionInformation.html',
                scope: {
                    model: '=',
                    openByDefault: '=',
                    form: '='
                },
                link: function ($scope) {
                    $scope.fieldDetails = {};
                    $scope.propertyDetails = {};
                    $scope.localIdDetails = {};

                    $scope.collectInformation = async function () {
                        var collectedInformation = await ExpressionInformationService.collectInformation($scope.model,
                            $scope.fieldDetails, $scope.propertyDetails, $scope.form);

                        // update view
                        $scope.$apply(function () {
                            $scope.fieldDetails = collectedInformation.fieldDetails;
                            $scope.propertyDetails = collectedInformation.propertyDetails;
                            $scope.localIdDetails = collectedInformation.localIdDetails;
                        });
                    };
                    $scope.collectInformation();


                    $scope.$watch(function () {
                        return $scope.model;
                    }, function (nv, ov) {
                        if (nv !== ov) {
                            $scope.collectInformation();
                        }
                    });

                    $scope.isEmpty = function(obj) {
                        for(var prop in obj) {
                            if(obj.hasOwnProperty(prop)) {
                                return false;
                            }
                        }
                        return true;
                    };

                    /**
                     * getting element type information
                     * @param element - element
                     */
                    $scope.getElementType = function (element) {
                        var type = element.type;
                        if (element.type != null) {
                            if (type.name) {
                                return type.name;
                            }
                            else {
                                return type;
                            }
                        }
                        return null;
                    };
                }
            };
        });


})();
