(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for FieldValueComparisonCondition model
     */
    app.factory('FieldValueComparisonConditionModel', function (QObjectModel, ProgramTestConstantsService,
                                                                FormLoadUtil, ValueTypeUtil, Form) {
        /***************************************************************
         * private functions
         **************************************************************/

        FieldValueComparisonConditionModel.inheritsFrom(QObjectModel);

        function FieldValueComparisonConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.FIELD_VALUE_COMPARISON);

            //initialized the variables
            this.formId = null;
            this.fieldId = null;
            this.fieldValues = [''];
            this.comparator = ProgramTestConstantsService.operators.EQ;

            this._form = null;
            this._field = null;
            this._allInputFields = null;  // this is list of all possible input fields in the edit mode
        }

        /**
         * convert the current UI model to dto format
         */
        FieldValueComparisonConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.formId = this.formId;
            dto.comparator = this.comparator==null ? null : this.comparator.name;
            dto.fieldValues = this.fieldValues;
            dto.fieldId = this.fieldId;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        FieldValueComparisonConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.formId = dto.formId;
            this.comparator = ProgramTestConstantsService.getObjectByName(ProgramTestConstantsService.operators, dto.comparator);
            this.fieldValues = dto.fieldValues;
            this.fieldId = dto.fieldId;


            FormLoadUtil.loadForm(this, undefined, undefined, Form.projections.ACTIVE_ONLY);
        };

        FieldValueComparisonConditionModel.prototype.getForm = function () {
            return this._form;
        };

        FieldValueComparisonConditionModel.prototype.setForm = function (form) {
            this._form = form;
            this.formId = form == null ? null : form.id;

            this.updateFormField();
        };

        FieldValueComparisonConditionModel.prototype.getField = function () {
            return this._field;
        };

        FieldValueComparisonConditionModel.prototype.setField = function (field) {
            this._field = field;
            this.fieldId = field == null ? null : field.formField.id;
        };

        FieldValueComparisonConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        FieldValueComparisonConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.fieldValues = null;
            }
            else if (this.fieldValues == null) {
                this.fieldValues = [''];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN) {
                if (this.fieldValues.length>1) {
                    // just use the first value
                    this.fieldValues = [this.fieldValues[0]];
                }
            }
        };

        /**
         * updating the form field, this function is usually called after form is being loaded
         */
        FieldValueComparisonConditionModel.prototype.updateFormField = function() {
            // find the field by id
            this._allInputFields = this.gatherAllInputFields();

            var formFields = this.getAllInputFields();

            var fieldId = this.fieldId;

            var formField = null;
            if (fieldId != null && formFields!=null) {
                formField = _.find(formFields, function(f) {
                    return f.formField.id == fieldId;
                });
            }

            this.setField(formField);
        };

        /**
         * load all the form fields into the allInputFields property
         */
        FieldValueComparisonConditionModel.prototype.gatherAllInputFields = function() {
            var inputFields = [];

            if (this._form == null || this._form.formVersions==null || this._form.formVersions.length==0) {
                return null;
            }

            var editMode = this._form.formVersions[0].editMode;

            _.forEach(editMode.pages, function(page) {
                _.forEach(page.sections, function(section) {
                    _.forEach(section.formComponents, function(component) {
                        _.forEach(component.formComponentFields, function(field) {
                            if (field.isInputField()) {
                                // to enable type-ahead search, need to add a shortDescription field
                                field.shortDescription = getDescription(field);
                                inputFields.push(field);
                            }
                        });
                    });
                });
            });

            return inputFields;
        };

        function getDescription(field) {
            return field.formField.id + ' | ' + (field.name ? field.name : 'no name') + ' | ' + field.type.name;
        }

        FieldValueComparisonConditionModel.prototype.getAllInputFields = function () {
            return this._allInputFields;
        };

        /**
         * setting the field value and convert it to a String
         * @param fieldValues - field values
         */
        FieldValueComparisonConditionModel.prototype.setFieldValues = function(fieldValues) {
            this.fieldValues = ValueTypeUtil.valueListConvertedToStringList(fieldValues);
        };

        /**
         * getting field value as it is
         * @returns {*}
         */
        FieldValueComparisonConditionModel.prototype.getFieldValues = function() {
            return this.fieldValues;
        };

        /**
         * getting field value as a field typed object depending on the value type of the field
         * ie: number, boolean, string, etc
         * @returns {*}
         */
        FieldValueComparisonConditionModel.prototype.getFieldValuesAsProperType = function() {
            if (this._field==null || this.fieldValues==null) {
                return null;
            }

            return ValueTypeUtil.stringValueListConvertedToType(this.fieldValues, this._field.getValueTypeName());
        };

        FieldValueComparisonConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            var fieldMsg = 'Field (' + (this.fieldId == null ? 'no id' : this.fieldId) + ' | ' +
                (this._field == null ? 'no name' : this._field.name) + ')';

            var formMsg = 'Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                (this._form == null ? 'no name' : this._form.name) + ')';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var fieldValueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : this.fieldValues;

            return baseMsg + ' ' + fieldMsg + ' of ' + formMsg + comparatorMsg + fieldValueMsg;
        };

        FieldValueComparisonConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.formId == null) {
                this.setErrorMessage('form id is required');
            }

            if (this.fieldId == null) {
                this.setErrorMessage('field id is required');
            }

            if (this.comparator==null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.fieldValues == null || this.fieldValues.length==0)) {
                this.setErrorMessage('field value is required except when comparator is "is null"');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return FieldValueComparisonConditionModel;

    });
})();
