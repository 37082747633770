import cs from "../../const/programContainer.constants.service";
/**
 * Created by Jason Cao on 08/12/2019
 *
 * directive for configuring an state with
 * 1. center icon
 * 2. a title
 * 3. a message
 *
 * everything is optional
 * ie: if title is null, then don't render it
 * if everything is null, then render a black container
 */
angular.module('acadiamasterApp').directive(
    'containerDisplayConfigStateIconWithMessage',
    (LocaleConstantsService) => ({
        restrict: 'E',
        scope: {
            // @Required - display config state should be of type : ContainerIconMessageDisplayConfig
            displayConfigState: '=',

            // parent object of the hide flag field boolean
            parentObject: '=',
            // key of the hide flag field boolean
            hideFlagFieldKey: '@',
            // on change function for hide flag
            hideFlagOnChange: '=',


            // a string used to display next to the hide field
            hideFieldTitle: '@',

            // preview url: nullable
            previewUrl: '@',

            // warning message function, optional
            getWarningMessageFunc: '=',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
            + 'config/containerDisplayConfig/' +
            'containerDisplayConfigStateIconWithMessage.html',
        link: ($scope) => {
            $scope.lcs = LocaleConstantsService;

            $scope.imageDimensions = {
                imageSize: {
                    w: $scope.parentObject && $scope.parentObject._parent ? getWidth($scope.parentObject._parent.containerType) : cs.emptyStateImageDimensions.defaultWidth,
                    h: $scope.parentObject && $scope.parentObject._parent ? getHeight($scope.parentObject._parent.containerType) : cs.emptyStateImageDimensions.defaultHeight,
                },
                aspectRatio: $scope.parentObject && $scope.parentObject._parent ? getWidth($scope.parentObject._parent.containerType)/getHeight($scope.parentObject._parent.containerType) : cs.emptyStateImageDimensions.defaultWidth/cs.emptyStateImageDimensions.defaultHeight,
            };

            $scope.shouldNotHide = () => {
                if ($scope.parentObject==null || $scope.parentObject[$scope.hideFlagFieldKey]==null) {
                    return true;
                }

                return !$scope.parentObject[$scope.hideFlagFieldKey];
            };

            $scope.onFlagFieldChanged = (value) => {
                $scope.parentObject[$scope.flagFieldKey] = value;
                if ($scope.hideFlagOnChange) {
                    $scope.hideFlagOnChange(value);
                }
            };

            $scope.getImageUrl = () => {
                return $scope.displayConfigState.getImageUrl();
            };

            $scope.getImageUri = () => {
                return $scope.displayConfigState.getImageUri();
            };

            $scope.imageRemoved = () => {
                return $scope.displayConfigState.imageRemoved();
            };

            $scope.imageCropped = (imageUri, imageFile) => {
                return $scope.displayConfigState.imageCropped(imageUri, imageFile);
            };

            function getHeight(containerType) {
                if (containerType === 'APPOINTMENTS') {
                    return cs.emptyStateImageDimensions.appointmentsHeight;
                } else {
                    return cs.emptyStateImageDimensions.defaultHeight;
                }
            }

            function getWidth(containerType) {
                if (containerType === 'APPOINTMENTS') {
                    return cs.emptyStateImageDimensions.appointmentsWidth;
                } else {
                    return cs.emptyStateImageDimensions.defaultWidth;
                }

            }

        },
    }));
