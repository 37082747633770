/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        /**
         *
         */
        .directive('ruleElementConfigBasic', function (RuleUtilService, vbrCommonUtil, ProgramTestConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/elementConfig.basic.html',
                scope: {
                    element: '=configModel',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.programTestConstant = ProgramTestConstantsService;
                }
            };
        });

})();
