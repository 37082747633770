/**
 * Created by moustafabaiou on 6/13/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('DonationSiteModel',
            function () {

                return siteModel;

                function siteModel() {
                    this.name = '';
                    this.organization = '';
                    this.organizationDisplayName = '';
                    this.address = new AddressModel();
                    this.hours = []; //an array of HoursOfOperationModels
                    this.contact = {
                        phone: '',
                        email: '',
                        emails: [],
                        url: ''
                    };
                    this.notes = '';
                }

                function AddressModel() {
                    this.street = '';
                    this.unit = '';
                    this.city = '';
                    this.state = '';
                    this.postalCode = '';
                }

            });

})();

