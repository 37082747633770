'use strict';

(function () {
    angular.module('acadiamasterApp')

    /**
     * service to store all the comparison operators
     */
        .factory('ComparisonOperatorService', function (ValueType) {
            var operators = {
                EQ: {name: 'EQ', symbol: ' = ', suitableValueTypes: null, supportPossibleValues: true},
                LT: {name: 'LT', symbol: ' < ', suitableValueTypes: [ValueType.NUMBER, ValueType.DATETIME], supportPossibleValues: true},
                LTE: {name: 'LTE', symbol: ' <= ', suitableValueTypes: [ValueType.NUMBER, ValueType.DATETIME], supportPossibleValues: true},
                GT: {name: 'GT', symbol: ' > ', suitableValueTypes: [ValueType.NUMBER, ValueType.DATETIME], supportPossibleValues: true},
                GTE: {name: 'GTE', symbol: ' >= ', suitableValueTypes: [ValueType.NUMBER, ValueType.DATETIME], supportPossibleValues: true},
                CONTAINS: {
                    name: 'CONTAINS',
                    symbol: ' LIKE ',
                    helpHtml: '<div><i><b>use <span class="badge">%</span> as the wildcard key, here are some examples</b></i></div>\n' +
                    '<ul class="simpleList">\n' +
                    '    <li><kbd>%abc%</kbd> -- anything that contains "abc" in the string</li>\n' +
                    '    <li><kbd>%@example.com</kbd> -- anything that ends with "@example.com"</li>\n' +
                    '    <li><kbd>abc%</kbd> -- anything that start with "abc"</li>\n' +
                    '    <li><kbd>abc%ddd%</kbd> -- anything that start with "abc" and also contains "ddd" </li>\n' +
                    '</ul>',
                    suitableValueTypes: [ValueType.STRING, ValueType.FILE],
                    supportPossibleValues: false
                },
                IN : {
                    name: 'IN',
                    symbol: ' in ',
                    suitableValueTypes: null,
                    supportPossibleValues: true
                },
                IS_NULL: {
                    name: 'IS_NULL',
                    symbol: ' is null ',
                    helpHtml: 'is null does not match against empty string or just white spaces, those are not considered as null value.  ' +
                    'Is null match against value that\'s really null or no record at all',
                    suitableValueTypes: null,
                    supportPossibleValues: true
                }
            };

            /**
             * operator by value type map, used to improve performance
             * @type {{}}
             */
            var operatorByTypeMap = {};

            /**
             * loading operators by type on demand
             * @param valueType - value type to match, not null
             * @param supportPossibleValues - whether this should support possible values
             * @returns {Array} - a list of suitable operators for this value type
             */
            function loadOperatorsByType(valueType, supportPossibleValues) {
                var suitableOperators = [];
                _.forEach(operators, function(o) {
                    var suitableTypes = o.suitableValueTypes;
                    var typeMatch = suitableTypes==null || _.indexOf(suitableTypes, valueType)!=-1;
                    var possibleValueMatch = !supportPossibleValues || o.supportPossibleValues;

                    if (typeMatch && possibleValueMatch) {
                        suitableOperators.push(o);
                    }
                });

                return suitableOperators;
            }

            /**
             * getting the list of operator that's suitable for a specific value type
             * @param valueType - value type of the value
             * @param supportPossibleValues - whether this should support possible values
             * @returns {*}
             */
            function getOperatorsByType(valueType, supportPossibleValues) {
                if (valueType == null) {
                    // no value type specified, return all operators
                    return operators;
                }

                if (supportPossibleValues == null) {
                    supportPossibleValues = false;
                }

                var key = valueType + supportPossibleValues;
                if (operatorByTypeMap[key] == null) {
                    // lazy loading
                    operatorByTypeMap[key] = loadOperatorsByType(valueType, supportPossibleValues);
                }

                return operatorByTypeMap[key];
            }

            return {
                operators: operators,
                getOperatorsByType: getOperatorsByType
            };
        });
})();


