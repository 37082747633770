(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('UpdateFontToMontSerratService', function (
        ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {


        var fsa = FormScriptActionUtil;
        UpdateFontToMontSerratService.inheritsFrom(ScriptActionBaseModel);

        function UpdateFontToMontSerratService() {
            this.name = 'Update font to mont serrat for fields and rich text label';
            this.ticketId = 'AC-74847';
            this.steps = [
                createStep1(),
                createStep2(),
                createStep3(),
                createStep4(),
                createStep5(),
                createStep6(),
                createStep7()
            ];
            this.description = this.collectDescriptionHTMLFromSteps();
        }

        function applyFontChanges(target) {
            if (fsa.isDisplayConfigHavingFontExact(target.displayConfig, '"Noto Sans", sans-serif')) {
                fsa.updateFont(target.displayConfig, 'Noto Sans', 'Montserrat-Medium');
            }
            if (fsa.isDisplayConfigHavingFontExact(target.displayConfig, '"Noto Sans Bold", sans-serif')) {
                fsa.updateFont(target.displayConfig, 'Noto Sans Bold', 'Montserrat-Bold');
            }
        }

        /**
         * function for creating the first step of the action for rich text label
         */
        function createStep1() {
            var description = 'find all rich text fields that have NotoSans font and changed it to montserrat<br>';
            function containsFontValue(fieldValue) {
                return _.includes(fieldValue.value, 'font-family:&quot;Noto Sans&quot;,sans-serif') || _.includes(fieldValue.value, 'font-family:&quot;Noto Sans Bold&quot;,sans-serif');
            };

            function searchFunction(formMode) {
                var targets = FormScriptActionUtil.findFieldsByType(formMode, FormConstants.fieldsType.RICH_TEXT_LABEL);
                targets = _.filter(targets, function (s) {
                    return containsFontValue(s.fieldValue);
                });
                return targets;
            };

            function changeFunction(target) {
                if (_.includes(target.fieldValue.value, 'font-family:&quot;Noto Sans&quot;,sans-serif')) {
                    target.fieldValue.value = _.replace(target.fieldValue.value, new RegExp('font-family:&quot;Noto Sans&quot;,sans-serif', 'g'), 'font-family:&quot;Montserrat-Medium&quot;,sans-serif');
                } else if (_.includes(target.fieldValue.value, 'font-family:&quot;Noto Sans Bold&quot;,sans-serif')) {
                    target.fieldValue.value = _.replace(target.fieldValue.value, new RegExp('font-family:&quot;Noto Sans Bold&quot;,sans-serif', 'g'), 'font-family:&quot;Montserrat-Bold&quot;,sans-serif');
                }
            }
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }

        /**
         * function for creating the second step of the action for mode
         */
        function createStep2() {
            var description = 'Set mode NotoSans font to montserrat<br>';

            function searchFunction(formMode) {
                var targets = [];
                if (fsa.isDisplayConfigHavingFont(formMode.displayConfig, 'Noto Sans')) {
                    targets.push(formMode);
                }
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }
        /**
         * function for creating the third step of the action for page
         */
        function createStep3() {
            var description = 'find all pages that have NotoSans font and changed it to montserrat<br>';

            function searchFunction(formMode) {
                var targets = formMode.pages;
                targets = _.filter(targets, function (p) {
                    return fsa.isDisplayConfigHavingFont(p.displayConfig, 'Noto Sans');
                });
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }
        /**
         * function for creating the fourth step of the action for section
         */
        function createStep4() {
            var description = 'find all sections that have NotoSans font and changed it to montserrat<br>';

            function searchFunction(formMode) {
                var targets = [];
                _.forEach(formMode.pages, function (page) {
                    _.forEach(page.sections, function (section) {
                        if (fsa.isDisplayConfigHavingFont(section.displayConfig, 'Noto Sans')) {
                            targets.push(section);
                        }
                    });
                });
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }
        /**
         * function for creating the fifth step of the action for component
         */
        function createStep5() {
            var description = 'find all components that have NotoSans font and changed it to montserrat<br>';

            function searchFunction(formMode) {
                var targets = [];
                _.forEach(formMode.pages, function (page) {
                    _.forEach(page.sections, function (section) {
                        _.forEach(section.formComponents, function (component) {
                            if (fsa.isDisplayConfigHavingFont(component.displayConfig, 'Noto Sans')) {
                                targets.push(component);
                            }
                        });
                    });
                });
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }
        /**
         * function for creating the sixth step of the action for fields
         */
        function createStep6() {
            var description = 'find all components fields that have NotoSans font and changed it to montserrat<br>';

            function searchFunction(formMode) {
                var targets = [];
                _.forEach(formMode.pages, function (page) {
                    _.forEach(page.sections, function (section) {
                        _.forEach(section.formComponents, function (component) {
                            _.forEach(component.formComponentFields, function (field) {
                                if (fsa.isDisplayConfigHavingFont(field.displayConfig, 'Noto Sans')) {
                                    targets.push(field);
                                }
                            });
                        });
                    });
                });
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }
        /**
         * function for creating the seventh step of the action for sub fields
         */
        function createStep7() {
            var description = 'find all sub fields that have NotoSans font and changed it to montserrat<br>';

            function searchFunction(formMode) {
                var targets = [];
                _.forEach(formMode.pages, function (page) {
                    _.forEach(page.sections, function (section) {
                        _.forEach(section.formComponents, function (component) {
                            _.forEach(component.formComponentFields, function (field) {
                                if (field.subFields != null) {
                                    _.forEach(field.subFields, function (sf) {
                                        if (fsa.isDisplayConfigHavingFont(sf.displayConfig, 'Noto Sans')) {
                                            targets.push(sf);
                                        }
                                    });
                                }
                            });
                        });
                    });
                });
                return targets;
            };

            function changeFunction(target) {
                applyFontChanges(target);
            };
            return {
                description: description,
                searchFunction: searchFunction,
                changeFunction: changeFunction
            };
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateFontToMontSerratService;
    });

})();
