/**
 * Created by Jason Cao on 10/21/2017
 * comparison service for form version
 */
(function () {
    // eslint-disable-next-line angular/no-service-method
    angular.module('acadiamasterApp').service('CompareResultFormVersionService', (CompareResultUtilService, CompareResultFormModeService) => {
        /*
         * compare and combine the two form versions
         * @param version1 - form version 1
         * @param version2 - form version 2
         * @returns {*} - a combined version with sections and state rules
         */
        function compare (version1, version2) {
            let versionDetail = {};

            // comparing version detail
            versionDetail.id = CompareResultUtilService.comparePrimitives(version1.id, version2.id);
            versionDetail.isActive = CompareResultUtilService.comparePrimitives(version1.isActive, version2.isActive);
            versionDetail.questionnaireId = CompareResultUtilService.comparePrimitives(version1.questionnaireId, version2.questionnaireId);
            versionDetail.pdfEnabled = CompareResultUtilService.comparePrimitives(version1.pdfEnabled, version2.pdfEnabled);
            versionDetail.createdByLogin = CompareResultUtilService.comparePrimitives(version1.createdByLogin, version2.createdByLogin);
            versionDetail.createdOn = CompareResultUtilService.compareDateTimes(version1.createdOn, version2.createdOn);
            versionDetail.commentVersion = CompareResultUtilService.comparePrimitives(version1.commentVersion, version2.commentVersion);

            versionDetail._status = CompareResultUtilService.collectStatus(versionDetail);

            let result = {
                _title     : 'Form Version',
                detail     : versionDetail,
                editMode   : CompareResultFormModeService.compare(version1.editMode, version2.editMode, 'Edit Mode'),
                promptMode : CompareResultFormModeService.compare(version1.promptMode, version2.promptMode, 'Prompt Mode'),
                viewMode   : CompareResultFormModeService.compare(version1.viewMode, version2.viewMode, 'View Mode'),
            };

            result._status = CompareResultUtilService.collectStatus(result);

            return result;
        }

        return {
            compare : compare,
        };
    });
}());
