import ProgramContainerConstantsService from '../../../const/programContainer.constants.service';
import template from './addContainerItemsMulti.html';
/**
 * Created by Jason Cao on 08/13/2019
 *
 * directive for configuring multiple form
 */
angular.module('acadiamasterApp').directive('addContainerItemsMultiForm', $state => ({
    link ($scope) {
        $scope.programId = $state?.params?.id;
        $scope.ITEM_TITLES = 'Add Container Items';
        $scope.ITEM_TITLE = 'Add New Container Item';
        $scope.containerTypeNames = ProgramContainerConstantsService.containerTypeNames;

        $scope.hasIconClass = () => {
            return $scope.iconClass && $scope.iconClass.length > 0;
        };

        $scope.getIconClass = () => {
            return $scope.hasIconClass() ? $scope.iconClass : '';
        };

        /**
         * form options for form search directive, multi-form support
         */
        $scope.formOptions = {
            allowMultiple : true,
            canSelectFunc : function (form) {
                return !$scope.container.isFormAlreadySelected(form);
            },
            customLabel      : 'Add A Primary Form',
            form             : null,
            formCategory     : $scope.formCategory,
            iconClass        : $scope.iconClass,
            onChangeCallBack : function () {
                if (this.form) {
                    $scope.container.addForm(this.form);
                }
            },
            title    : 'Add New Container Item',
            viewOnly : false,
        };

        /**
         * action options for action item directive, support multi-container-item
         */
        $scope.actionOptions = {
            action           : null,
            iconClass        : $scope.iconClass,
            onChangeCallBack : function () {
                if (this.action) {
                    $scope.container.addActionItem(this.action);
                }
            },
            title : 'Add Action',
        };

        /**
         * viewSharedEHR options for form search directive, multi-form support
         */
        $scope.viewSharedEHROptions = {
            allowMultiple : false,
            canSelectFunc : function (form) {
                return !$scope.container.isFormAlreadySelected(form);
            },
            customLabel      : 'Add View Shared EHR',
            form             : null,
            formCategory     : $scope.formCategory,
            iconClass        : $scope.iconClass,
            onChangeCallBack : function () {
                if (this.form) {
                    $scope.container.addViewSharedEHR(this.form);
                }
            },
            title    : 'Add New Container Item',
            viewOnly : false,
        };

        /*
         * method called when the plus button is clicked for adding a new container item
         * @param $event
         */
        $scope.onPopoverOpened = function ($event) {
            $event.stopPropagation();
            $scope.data.isPopupOpen = true;
        };

        // set the updated formCategory in formOptions anytime it changes
        $scope.$watch('formCategory', value => {
            $scope.formOptions.formCategory = value;
        });
    },
    restrict : 'E',
    scope    : {
        container    : '=',
        formCategory : '=',
        iconClass    : '<',
        selector     : '=',
    },
    template : template,
}));
