/**
 * Created by Danny on 4/18/2016.
 */
'use restrict';

angular.module('acadiamasterApp').directive('vbrDataTypeMasterSearchByFilter', function(ParseLinks, SearchWithFilter) {

    return {
        restrict: 'E',
        templateUrl: 'admin-templates/site/dataTypeMaster/dataTypeMasterSearchByFilter.html',
        scope: {
            options: '=',
        },
        link: function(scope) {

       
            scope.searchFilter = {
                name: null,
                usedFor: null,
                exactMatch: false,
                pageNum: 0,
                pageSize: 20,
                entityType: scope.options.entityType
            };

            scope.usedFor = {
                DATAFORM: 'DATAFORM',
                PROFILEFORM: 'PROFILEFORM',
                ALL: 'ALL'
            };

            scope.changedUsedFor = scope.usedFor.ALL;
            scope.previousUsedFor = scope.usedFor.ALL;



            scope.searchByFilter = function() {
                queryWithFilter(scope.searchFilter);
            };

            scope.clearSearchFilter = function() {
                scope.searchFilter.name = null;
                scope.searchFilter.usedFor = scope.usedFor.ALL;
                scope.searchFilter.exactMatch = false;
                scope.changedUsedFor = scope.usedFor.ALL;
                scope.loadAll();
            };

            scope.usedForFilterChanged = function(){
                if (scope.previousUsedFor != scope.changedUsedFor)
                {
                    queryWithFilter(scope.searchFilter);
                }
                scope.previousUsedFor = scope.changedUsedFor;
            };

            function queryWithFilter(searchFilter) {
                searchFilter.usedFor = (scope.changedUsedFor==scope.usedFor.ALL)? null :scope.changedUsedFor ;
                scope.options.page = searchFilter.pageNum;
                SearchWithFilter.query(searchFilter).then(function(result){
                    console.log(result);
                    var newLinks = ParseLinks.parse(result.headers('link'));
                    var currentLinks = scope.options.links;
                    currentLinks.splice(0, currentLinks.length);
                    _.forEach(newLinks, function(l) {
                        currentLinks.push(l);
                    });

                    // scope.options.links = ParseLinks.parse(result.headers('link'));
                    scope.options.entity = result.data;
                })
            }

        }
    };
});
