'use strict';

angular.module('acadiamasterApp')
    .controller('ResetFinishController', function ($scope, $stateParams, $timeout, Auth, $translate, tmhDynamicLocale) {

        $scope.keyMissing = $stateParams.key === undefined;
        $scope.doNotMatch = null;

        $scope.resetAccount = {};
        $timeout(function (){angular.element('[ng-model="resetAccount.password"]').focus();});

        if($stateParams.lang){
        	$translate.use($stateParams.lang);
        	tmhDynamicLocale.set($stateParams.lang);
        }
        
        $scope.finishReset = function() {
            if ($scope.resetAccount.password !== $scope.confirmPassword) {
                $scope.doNotMatch = 'ERROR';
            } else {
                $scope.doNotMatch = false;
                Auth.resetPasswordFinish({key: $stateParams.key, newPassword: $scope.resetAccount.password}).then(function () {
                    $scope.success = 'OK';
                }).catch(function (response) {
                    $scope.success = null;
                    $scope.error = 'ERROR';

                });
            }

        };
    });
