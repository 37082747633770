(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * service for rule model
         */
        .service('RuleModelService',
            function (RuleConstantsService, RuleActionModelService, RuleWorkflowModelService, vbrCommonUtil, BusinessRule, ModelServiceBase, ProgramService) {
                /***************************************************************
                 * private functions
                 **************************************************************/
                /**
                 * Method to convert action model to dto structure and set it in action list in rule dto
                 */
                function setActionsToDto(dto, ruleModel, files) {
                    _.forEach(ruleModel.actions, function (action) {
                        if (action.usageCount > 0) {
                            dto.actions.push(action.toDto(files));
                        }
                    });
                }

                /**
                 * Method to convert workflow model to dto structure and set it in workflow list in rule dto
                 * @param dto
                 * @param ruleModel
                 */
                function setWorkflowsToDto(dto, ruleModel) {
                    _.forEach(ruleModel.workflows, function (workflow, index) {
                        var workflowDto = workflow.toDto();
                        workflowDto.sequenceNo = index + 1;
                        dto.workflows.push(workflowDto);
                    });
                }

                /**
                 * Method to create action model from action dto and set it in rule model
                 * @param ruleModel
                 * @param dto
                 */
                function setActionsFromDto(ruleModel, dto) {
                    _.forEach(dto.actions, function (action) {
                        var actionModel = new RuleActionModelService.createActionModelFromDto(action, ruleModel);
                        ruleModel.actions.push(actionModel);
                    });
                }

                /**
                 * Method to create workflow model from workflow dto and set it in rule model
                 * @param ruleModel
                 * @param dto
                 */
                function setWorkflowsFromDto(ruleModel, dto) {
                    _.forEach(dto.workflows, function (workflow) {
                        var workflowModel = new RuleWorkflowModelService.WorkflowModel(ruleModel);
                        workflowModel.fromDto(workflow);
                        ruleModel.workflows.push(workflowModel);
                    });
                }


                /***************************************************************
                 * Rule Model
                 ***************************************************************/
                RuleModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function RuleModel(type, programId) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, null);
                    // Add nodeType for tree structure
                    this.nodeType = RuleConstantsService.nodeType.RULE;

                    // Add rule dto related fields
                    this.id = null;
                    this.name = 'New Rule';
                    this.title = 'Title';
                    this.isActive = true;
                    this.enabled = true;
                    this.isGlobal = true;
                    this.isArchivable = false;
                    this.ruleType = type;
                    this.actions = [];
                    this.workflows = [];
                    this.programId = programId;

                    var _this = this;
                    if (programId != null) {
                        ProgramService.get({ id: programId }, function (result) {
                            _this.program = result;
                        });
                    }

                    // Init audit fields
                    this.createdOn = null;
                    this.updatedOn = null;
                    this.createdById = null;
                    this.updatedById = null;

                    // Add one workflow
                    this.addWorkflow();
                }

                /**
                 * convert the current UI model to dto format
                 */
                RuleModel.prototype.toDto = function (files) {
                    var dto = {};

                    dto.id = this.id;
                    dto.name = this.name;
                    dto.title = this.title;
                    dto.isActive = this.isActive;
                    dto.enabled = this.enabled;
                    dto.isGlobal = this.isGlobal;
                    dto.isArchivable = this.isArchivable;
                    dto.ruleType = this.ruleType;

                    dto.actions = [];
                    setActionsToDto(dto, this, files);

                    dto.workflows = [];
                    setWorkflowsToDto(dto, this);

                    dto.programId = this.programId;

                    // Set audit fields
                    dto.createdOn = this.createdOn;
                    dto.updatedOn = this.updatedOn;
                    dto.createdById = this.createdById;
                    dto.updatedById = this.updatedById;

                    return dto;
                };

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 * @param dto
                 */
                RuleModel.prototype.fromDto = function (dto) {
                    this.id = dto.id;
                    this.name = dto.name;
                    this.title = dto.title;
                    this.isActive = dto.isActive;
                    this.enabled = dto.enabled;
                    this.isGlobal = dto.isGlobal;
                    this.isArchivable = dto.isArchivable;
                    this.ruleType = dto.ruleType;
                    this.programId = dto.programId;

                    // set program entity
                    var _this = this;
                    if (dto.programId != null) {
                        ProgramService.get({ id: dto.programId }, function (result) {
                            _this.program = result;
                        });
                    }

                    this.actions = [];
                    setActionsFromDto(this, dto);

                    this.workflows = [];
                    setWorkflowsFromDto(this, dto);

                    // Set audit fields
                    this.createdOn = dto.createdOn;
                    this.updatedOn = dto.updatedOn;
                    this.createdById = dto.createdById;
                    this.updatedById = dto.updatedById;
                };


                /**
                 * validate the entity
                 */
                RuleModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.name == null || this.name.length === 0) {
                        this.setErrorMessage('No rule name mentioned');
                    } else if (this.name.length < 2) {
                        this.setErrorMessage('Rule name should be at least 2 characters');
                    } else if (this.name.length > 512) {
                        this.setErrorMessage('Rule name should not be larger than 512 characters');
                    } else if (this.title !== null && this.title.length > 0) {
                        if (this.title.length < 2) {
                            this.setErrorMessage('Title should be at least 2 characters');
                        } else if (this.title.length > 512) {
                            this.setErrorMessage('Title should not be larger than 512 characters');
                        }
                    }
                };

                /**
                 * Method to add action in action list
                 * @param action
                 */
                RuleModel.prototype.addActionUsage = function (action) {
                    var existingAction = _.find(this.actions, { 'code': action.code });

                    if (existingAction == null) {
                        this.actions.push(action);
                        action.usageCount++;
                    } else {
                        existingAction.usageCount++;
                    }
                };

                /**
                 * Method to remove action in action list
                 * @param action
                 */
                RuleModel.prototype.removeActionUsage = function (action) {
                    var existingAction = _.find(this.actions, { 'code': action.code });
                    existingAction.usageCount--;
                    if (existingAction.usageCount === 0) {
                        _.remove(this.actions, existingAction);
                    }
                };

                /**
                 * Method to add condition
                 * @param condition
                 */
                RuleModel.prototype.addConditionUsage = function (condition) {
                    var existingCondition = _.find(this.conditions, { 'code': condition.code });

                    if (existingCondition == null) {
                        this.conditions.push(condition);
                    } else {
                        existingCondition.usageCount++;
                    }

                };

                RuleModel.prototype.addWorkflow = function () {
                    var workflowModel = new RuleWorkflowModelService.WorkflowModel(this);

                    this.workflows.push(workflowModel);

                    this.collapsed = false;
                };

                RuleModel.prototype.removeWorkflow = function (workflow, index, selector) {
                    if (index == null) {
                        index = _.findIndex(this.workflows, function (m) {
                            return m == workflow;
                        });
                    }

                    if (index != null && index >= 0) {
                        this.workflows.splice(index, 1);
                        if (selector != null) {
                            selector.itemRemoved(workflow);
                        }
                    }
                };

                RuleModel.prototype.cloneWorkflow = function (workflow, selector, treeSource) {
                    var newWorkflow = null;
                    if (workflow != null) {
                        newWorkflow = workflow.customClone();
                    }

                    if (newWorkflow != null) {
                        var addAfterIndex = this.findWorkflowIndex(workflow);
                        if (addAfterIndex == null || addAfterIndex >= this.workflows.length - 1) {
                            this.workflows.push(newWorkflow);
                        }
                        else {
                            this.workflows.splice(addAfterIndex + 1, 0, newWorkflow);
                        }

                        if (selector && selector.selectItem) {
                            selector.selectItem(newWorkflow, false, false, treeSource);
                        }
                    }
                };

                RuleModel.prototype.findWorkflowIndex = function (workflow) {
                    return _.findIndex(this.workflows, function (w) {
                        return w == workflow;
                    });
                };


                RuleModel.prototype.getDescriptionAsHtml = function () {
                    var message = '<span data-nodrag class="badge badge-warning">Rule</span>';
                    message += ' <i>' + this.name + '</i>';
                    message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                    return message;
                };


                /***************************************
                 * save operations
                 ***************************************/
                function saveRule(files, rule, callBack) {
                    if (files == null) {
                        files = [];
                    }

                    var ruleDto = rule.toDto(files);
                    BusinessRule.saveOrUpdate(files, ruleDto, callBack);
                }

                /***************************************
                 * service return call
                 ***************************************/

                return {
                    RuleModel: RuleModel,

                    saveRule: saveRule
                };
            });

})();
