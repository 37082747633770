'use strict';

angular.module('acadiamasterApp')
    .controller('ParticipantProfileController', function ($scope, ParseLinks, Language, $state,
        ParticipantProfileService, AlertService, SessionStorageService) {

        $scope.users = [];
        $scope.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_PROVIDER', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT','ROLE_ATA'];
        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });

        $scope.cs = {
            LANGUAGES: {
                'en': 'English',
                'es': 'Spanish'
            }
        };

        $scope.searchData = {
            searchString: '',
            searchedString: '',
            searchResultCount: '0',
            pageSizeChoices: [20, 50, 100],
            pageSizeSelected: 20,
            page: 0
        };
        $scope.roleCategory = {
            SUBSCRIBER: 'SUBSCRIBER'
        };

        $scope.loadPage = function (page) {
            $scope.searchData.page = page;
            $scope.loadUsers();
        };

        $scope.changedRoleCategory = $scope.roleCategory.SUBSCRIBER;

        $scope.shouldAllowAuthKeyConfig = function (user) {
            var authorities = user.authorities;
            if (authorities != null) {
                for (var i = 0; i < authorities.length; i++) {
                    if (authorities[i] === 'ROLE_ADMIN') {
                        return true;
                    }
                }

            }
            return false;
        };

        $scope.loadUsers = function () {
            // require search params. Alert user if none exist.
            if (!$scope.searchData.searchString) {
                AlertService.error('You must enter search parameters')
                return;
            }

            SessionStorageService.setItem(
                $state.current.name,
                $scope.searchData,
                $state.current.name);

            ParticipantProfileService.search({
                page: $scope.searchData.page,
                size: $scope.searchData.pageSizeSelected,
                searchString: $scope.searchData.searchString
            }).then(function (response) {
                $scope.links = ParseLinks.parse(response.headers('link'));
                $scope.users = response.data;
                $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                $scope.searchData.searchedString = $scope.searchData.searchString;
            });
        }

        $scope.markTestUser = function (id) {
            $scope.testUserId=id;
            $('#markTestUserConfirmation').modal('show');
        };

        $scope.confirmMarkTestUser = function (id) {
            ParticipantProfileService.setTestUser(id);
            $('#markTestUserConfirmation').modal('hide');
        };

        function init() {
            // load $scope.data from session storage service
            var data = SessionStorageService.getItem($state.current.name);

            if (data && data.searchedString) {
                data.searchString = data.searchedString;
                $scope.searchData = data;
                $scope.loadUsers();
            }
        }

        init();
    });
