/**
 * Created by Jamie Nola on 06/26/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('greenStripedProgressBar', function ($timeout, easing) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/progressBars/greenStriped/greenStripedProgressBar.template.html',
                scope: {
                    hasFailed: '=',
                    progress: '='
                },
                controller: function ($scope) {
                    init($scope);
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.FORWARD_DURATION_MS = 500;
                $scope.REVERSE_DURATION_MS = 400;

                $scope.data = {
                    display: {
                        progress: $scope.progress,
                        hasFailed: $scope.hasFailed,
                        clipPath: 'inset(0 ' + ((1 - $scope.progress) * 100) + '% 0 0)',
                    },
                    tween: {
                        currentTime: 0,
                        startTime: 0,
                        start: 0,
                        end: 0,
                        delta: 0,
                        batchId: 0,
                        duration: $scope.FORWARD_DURATION_MS
                    }
                };

                $scope.$watch('hasFailed', function(newValue, oldValue) {
                    if(newValue === oldValue) {
                        return;
                    }
                    if(newValue) {
                        $timeout(function() {
                            $scope.data.display.hasFailed = $scope.hasFailed;
                        }, $scope.data.tween.duration);
                    } else {
                        $scope.data.display.hasFailed = $scope.hasFailed;
                    }
                });

                $scope.$watch('progress', function (newValue, oldValue) {
                    if (newValue === oldValue) {
                        return;
                    }
                    $scope.data.tween.duration = newValue < oldValue ? $scope.REVERSE_DURATION_MS : $scope.FORWARD_DURATION_MS;
                    startTween($scope, newValue, $scope.data.display.progress);
                });
            }

            function startTween($scope, newValue, oldValue) {
                $scope.data.tween.currentTime = 0;
                $scope.data.tween.startTime = new Date().getTime();
                $scope.data.tween.start = oldValue;
                $scope.data.tween.end = newValue;
                $scope.data.tween.delta = newValue - oldValue;
                $scope.data.tween.batchId += 1;
                tween($scope, $scope.data.tween.batchId);
            }

            function tween($scope, batch) {
                // avoid multiple tweens at once by ignoring old tweens
                if (batch !== $scope.data.tween.batchId) {
                    return;
                }

                // update current tween time
                $scope.data.tween.currentTime = Math.min(new Date().getTime() - $scope.data.tween.startTime, $scope.data.tween.duration);

                // get tweened value (always between 0 and 1)
                var value = Math.max(0, Math.min(
                    easing.easeOutSine(
                        $scope.data.tween.currentTime,
                        $scope.data.tween.start,
                        $scope.data.tween.delta,
                        $scope.data.tween.duration
                    ), 1));

                // if we aren't at the target value yet, tween again
                if (value !== $scope.data.tween.end) {
                    $timeout(function () {
                        tween($scope, batch);
                    });
                }

                // update the displayed value
                $scope.data.display.progress = value;
                $scope.data.display.clipPath = 'inset(0 ' + ((1 - value) * 100) + '% 0 0)';
            }
        });
})();
