'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeRootCategoryDetailController', function ($location, $uibModal, $scope, $rootScope, $stateParams, entity,
                                                                  DataTypeRootCategory, DataTypeMasterRecordCount, $state) {

        var modalInstance = null;
        var dataTypeRootCategory = null;

        $scope.usedFor = {
            DATAFORM: 'DATAFORM',
            PROFILEFORM: 'PROFILEFORM',
            ALL: 'ALL'
        };

        $scope.dataReady = false;
        $scope.dataTypeRootCategory = entity;
        dataTypeRootCategory = $scope.dataTypeRootCategory;

        $scope.hasProperty = false;

        $scope.dataTypeRootCategory.$promise.then(function() {
            $scope.dataReady = true;
        });

        $scope.load = function (id) {
            DataTypeRootCategory.get({id: id}, function(result) {
                $scope.dataTypeRootCategory = result;
            });
        };
        $rootScope.$on('acadiamasterApp:dataTypeRootCategoryUpdate', function(event, result) {
            $scope.dataTypeRootCategory = result;
        });

        $scope.showDeleteConfirm = function (id) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'deleteConfirmation.html',
                scope: $scope,
                controller: function($scope) {
                    $scope.delete = function (id) {
                        DataTypeRootCategory.delete({id: dataTypeRootCategory.id},
                            function (result) {
                                modalInstance.close(result);
                                DataTypeMasterRecordCount.updateCounts();
                                $state.go('dataTypeRootCategories');
                            });
                    };

                    $scope.closeConfirmation = function() {
                        modalInstance.dismiss('cancel');
                    }

                },
                size: 'md',
                resolve: {
                    item: function () {
                        return DataTypeRootCategory.get({id: id});
                    }
                }
            });
        };


    });
