/**
 * Created by Ryan Berry on 12/9/22
 *
 * Controller for configuring form entry data references.
 */

import cs from '../../const/programContainer.constants.service';

angular.module('acadiamasterApp')
    .controller('DataReferenceConfigController', function ($timeout, DataReferenceConfigService) {
        const vm = this;

        // flag to indicate if the configuration should be collapsed
        vm.collapsed = false;

        vm.formDataTypes = cs.formDataTypes;
        vm.inclusionExclusionTypes = cs.inclusionExclusionTypes;

        vm.isOpen = () => !vm.collapsed;

        vm.toggle = () => {
            vm.collapsed = !vm.collapsed;
        };

        vm.formDataTypeChanged = () => {
            if (vm.container.formEntryFilter.formDataType) {
                vm.loadForms();
            } else {
                vm.data.selectedForm = null;
                vm.data.selectedForms = [];
                vm.container.formEntryFilter.formIds = [];
            }
        };

        vm.useAdvancedFormFilteringChanged = newValue => {
            vm.data.useAdvancedFormFiltering = newValue;

            if (vm.data.useAdvancedFormFiltering) {
                vm.container.formEntryFilter.advanceInclusionExclusion = vm.inclusionExclusionTypes[0]; // default
                vm.loadForms();
            } else {
                vm.data.selectedForm = null;
                vm.data.selectedForms = [];
                vm.container.formEntryFilter.formIds = [];
                vm.container.formEntryFilter.advanceInclusionExclusion = null;
            }
        };

        vm.loadForms = () => {
            if (!vm.container.formEntryFilter.formDataType
                || !vm.data.useAdvancedFormFiltering
            ) {
                vm.data.forms = null;
                return;
            }
            vm.data.isLoading = true;

            DataReferenceConfigService.getForms(vm.container.formEntryFilter.formDataType)
                .then(response => {
                    vm.data.forms = [];
                    for (let [ key, value ] of Object.entries(response.data)) {
                        let id = parseInt(key, 10);
                        vm.data.forms.push(
                            {
                                displayName : `${id} | ${value}`,
                                id          : id,
                                name        : value,
                            },
                        );
                    }
                    vm.updateSelectedForms();
                }, error => {
                    vm.data.loadingError = true;
                    console.error('Error getting forms from API', error);
                }).finally(() => {
                    vm.data.isLoading = false;
                });
        };

        vm.selectForm = () => {
            if (!vm.container.formEntryFilter.formIds) {
                vm.container.formEntryFilter.formIds = [];
            }
            if (vm.container.formEntryFilter.formIds.indexOf(vm.data.selectedForm.id) === -1) {
                vm.container.formEntryFilter.formIds.push(vm.data.selectedForm.id);
                vm.data.selectedForms.push(vm.data.selectedForm);
            }
            vm.data.selectedForm = '';
        };

        vm.removeForm = form => {
            // remove from selected forms
            let index = vm.data.selectedForms.indexOf(form);
            if (index > -1) {
                vm.data.selectedForms.splice(index, 1);
            }

            // remove from form ids
            index = vm.container.formEntryFilter.formIds.indexOf(form.id);
            if (index > -1) {
                vm.container.formEntryFilter.formIds.splice(index, 1);
            }
        };

        // populate selectedForms based on formIds
        vm.updateSelectedForms = () => {
            vm.data.selectedForms = [];
            vm.data.forms.forEach(form => {
                const index = vm.container.formEntryFilter.formIds.indexOf(form.id);
                if (index > -1) {
                    vm.data.selectedForms.push(form);
                }
            });

            vm.updateSelectedFormIds();
        };

        // populate formIds based on selectedForms
        vm.updateSelectedFormIds = () => {
            vm.container.formEntryFilter.formIds = [];
            vm.data.selectedForms.forEach(form => {
                vm.container.formEntryFilter.formIds.push(form.id);
            });
        };

        vm.data = {
            forms                    : null,
            isLoading                : false,
            loadingError             : false,
            selectedForm             : null,
            selectedForms            : [],
            useAdvancedFormFiltering : false,
        };

        vm.$onInit = () => {
            if (!vm.container.formEntryFilter) {
                vm.container.formEntryFilter = {};
            }
            if (!vm.container.formEntryFilter.formIds) {
                vm.container.formEntryFilter.formIds = [];
            }
            // Assign to false if advanceInclusionExclusion not define or null
            vm.data.useAdvancedFormFiltering = vm.container.formEntryFilter.advanceInclusionExclusion ?? false;

            vm.loadForms();
        };
    });
