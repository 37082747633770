
angular.module('acadiamasterApp')
    .factory('AgreementsService', ($http, localWeb, Upload) => {
        function loadAgreements(params) {
            return $http.get(localWeb.createUrl(`/api/admin/program/${params.programId}/agreements`));
        }
        function reorderAgreements(params) {
            return $http.put(`/api/admin/program/${params.programId}/agreements/reorder`, params.data);
        }
        function getAgreement(params) {
            return $http.get(`/api/admin/program/agreements/${params.agreementId}`);
        }
        function createAgreement(data, files) {
            // send them all together for HTML5 browsers:
            return Upload.upload({
                url: localWeb.createUrl('/api/admin/program/agreements'),
                arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                data: {
                    file: files,
                    data: JSON.stringify(data)
                }
            });
        }
        function updateAgreement(data, files) {
            // send them all together for HTML5 browsers:
            return Upload.upload({
                url: localWeb.createUrl('/api/admin/program/agreements'),
                arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                method: 'PUT',
                data: {
                    file: files,
                    data: JSON.stringify(data)
                }
            });
        }
        function getAgreementTypeCapabilities() {
            return $http.get(localWeb.createUrl('/api/admin/program/agreements/typeCapabilities'));
        }
        function deleteAgreement(agreementId) {
            return $http.delete(localWeb.createUrl(`/api/admin/program/agreements/${agreementId}`));
        }

        return {
            createAgreement,
            deleteAgreement,
            getAgreementTypeCapabilities,
            loadAgreements,
            reorderAgreements,
            getAgreement,
            updateAgreement
        };
    });
