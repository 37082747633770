(function () {
    'use strict';

    /**
     * script action to update all sections to be visible
     */
    angular.module('acadiamasterApp').factory('UpdateSectionToVisibleScriptAction', function (ScriptActionBaseModel, FormScriptActionUtil) {

        // defining some local variable to make things easier
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /**
         * apply visibility to true on the target
         * @param target - target
         */
        function applyVisibleToTrue(target) {
            if (target && target.displayConfig) {
                fsa.updateVisibility(target.displayConfig, true);
            }
        }


        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateSectionToVisibleScriptAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateSectionToVisibleScriptAction() {
            this.name = 'Update all sections to be visible';
            this.ticketId = 'AC-27757';

            this.steps = [
                {
                    description: 'find all section with visibility = false --> ' + 'visibility : true',
                    searchFunction: function (formMode) {
                        var targets = fsa.findSectionsInMode(formMode);

                        // filter out object that's already has font name = null
                        targets = _.filter(targets, function (f) {
                            return !fsa.isVisible(f.displayConfig);
                        });

                        return targets;
                    },
                    changeFunction: applyVisibleToTrue
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateSectionToVisibleScriptAction;
    });

})();
