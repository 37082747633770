/**
 * Created by Jamie Nola on 10/18/2018
 *
 * Handles updating the breadcrumb controllers whenever the page successfully changes.
 * Allows any number of controllers to subscribe to the page change event, and when the
 * controller is destroyed, this service knows to stop calling its update function.
 */

'use strict';

angular.module('acadiamasterApp')
    .factory('BreadcrumbService', function ($rootScope) {
        var breadcrumbScope = $rootScope.$new(true);
        var eventName = 'update-breadcrumbs';
        var statePath;

        // allows a breadcrumb controller to subscribe to the page change event. When the controller
        // is destroyed, this stops calling it
        function subscribe(scope, callback) {
            var handler = breadcrumbScope.$on(eventName, function() {
                callback(statePath);
            });
            scope.$on('$destroy', handler);
        }

        // sends update event
        function notify() {
            breadcrumbScope.$emit(eventName);
        }

        // adds Home to the path and notifies the controllers
        function onStateChangeSuccess(path) {
            path.unshift({
                abstract: false,
                name: 'home'
            });
            statePath = path;
            notify();
        }

        function getPath() {
            return statePath;
        }

        return {
            onStateChangeSuccess: onStateChangeSuccess,
            subscribe: subscribe,
            getPath: getPath
        };
    });
