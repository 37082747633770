import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an container display config with a single item (ie: MOOD, EDUCATION)
 */

angular.module('acadiamasterApp')
    .factory('ContainerSingleItemDisplayConfigImpl', (ContainerWithButtonDisplayConfig,
                                                      ModelServiceBase) => {

        const {classes} = ProgramContainerConstantsService;

        ContainerSingleItemDisplayConfigImpl.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
        /**
         * @constructor
         */
        function ContainerSingleItemDisplayConfigImpl(parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

            this.hideWhenEmpty = true;
            this.initialState = new ContainerWithButtonDisplayConfig(this);
            this.formCompletionState = new ContainerWithButtonDisplayConfig(this);
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerSingleItemDisplayConfigImpl.prototype.toDto = function (files) {
            const dto = {};
            dto[classes.classReferenceKey] = classes.ContainerSingleItemDisplayConfigImpl;

            dto.hideWhenEmpty = this.hideWhenEmpty;
            dto.initialState = this.initialState.toDto(files);
            dto.formCompletionState = this.formCompletionState.toDto(files);

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerSingleItemDisplayConfigImpl.prototype.fromDto = function (dto) {
            if (dto==null) {
                return;
            }

            this.hideWhenEmpty = dto.hideWhenEmpty;
            this.initialState.fromDto(dto.initialState);
            this.formCompletionState.fromDto(dto.formCompletionState);
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerSingleItemDisplayConfigImpl;
    });
