'use strict';

angular.module('acadiamasterApp').controller('PropertyDialogController',
        function($scope, $stateParams, $uibModalInstance, entity, Property, Unit, User, PropertiesLookup, UnitCategory, DataTypeMasterRecordCount,
                 CONFIG) {
        $scope.CONFIG = CONFIG;
        $scope.property = entity;
        $scope.units = Unit.query();
        $scope.users = User.query();
        $scope.unitcategorys = UnitCategory.query();

        $scope.valueType = {
            NUMBER: 'NUMBER',
            STRING: 'STRING',
            DATETIME: 'DATETIME',
            FILE: 'FILE'
        };

        $scope.checkValueType = function(property) {
            if (property.valueType!='NUMBER') {
                property.unitCategoryId = null;
                property.units = [];
            }
        };

        $scope.load = function(id) {
            Property.get({id : id}, function(result) {
                $scope.property = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:propertyUpdate', result);
            DataTypeMasterRecordCount.updateCounts();
            PropertiesLookup.updateProperties();
            $uibModalInstance.close(result);

        };


        $scope.save = function () {
            if(!$scope.property.transmitToVXP){
                $scope.property.transmitToVXP=false;
            }
            if ($scope.property.id != null) {
                Property.update($scope.property, onSaveFinished);
            } else {
                $scope.property.isUsed = false;
                Property.save($scope.property, onSaveFinished);
            }
            // This will update the lookup after property is added/updated
            PropertiesLookup.updateProperties();
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    })
    .directive('vbrPropertyAvailable', ['PropertyByName', '$q', function (PropertyByName, $q) {
        function isNameAvailable(name, currentId) {
            var promise = PropertyByName.get(name, currentId);
            return promise.then(function (response) {
                if (!response.data) {
                    return $q.reject("Name is already taken");
                }
                else {
                return true;
                }
            });
        }

        return {
            require: 'ngModel',
            scope: {
                currentId: '=vbrPropertyAvailable'
            },
            link: function (scope, elm, attr, model) {
                model.$asyncValidators.vbrPropertyAvailable = function (modelValue) {
                    return isNameAvailable(modelValue, scope.currentId);
                };
            }
        }
    }]);
