
/**
 * directive for display a pre condition list with a "+" sign that can create a number of
 * new pre conditions
 */
angular.module('acadiamasterApp').directive('treeNodePreConditionList',
    (PreconditionCreationUtil, $timeout, ngDialog) => ({
        controller: ($scope) => {
            $scope.allPreconditionCreators = PreconditionCreationUtil.getAllPreconditionCreators();

            $scope.states = {
                isAddNewPopupOpen: false,
            };

            $scope.showAddPreconditionDialog = ($event) => {
                $scope.states.isAddNewPopupOpen = true;
                ngDialog.openConfirm({
                    className: 'ngdialog-theme-plain custom-width-small',
                    preCloseCallback: () => {
                        $timeout(() => {
                            $scope.states.isAddNewPopupOpen = false;
                        });
                    },
                    scope: $scope,
                    templateUrl: 'admin-templates/site/components/treeEditorDialogs/addPrecondition.template.html',
                }).then((creator) => {
                    creator.createNew($scope.testCase, $scope.selector);
                });
                $event.stopPropagation();
            };
        },
        restrict: 'E',
        scope: {
            selector: '=',
            testCase: '=',
        },
        templateUrl: 'admin-templates/site/programTests/programTest/treeNode/preConditionList.html',
    }));
