(function () {
    'use strict';

    /**
     * directive for selecting container
     */
    angular.module('acadiamasterApp').directive('containerSelection', function (ContainerCacheService, AlertService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/containerSelection.html',
            scope: {
                /**
                 * container options will have the following fields and properties
                 * 1. getContainer() -- function that returns the container currently selected
                 * 2. setContainer(container) -- function that should be invoked when container is selected
                 * 3. programId -- program id for which to select container from
                 * 4. filterFunction -- optional: a filter function for the container that is selectable, this can be used to add additional function such as
                 *                      only allow container be selected once, allow only container with container item, etc
                 */
                containerOptions: '<',
                isRequired: '<',
                title: '@',
            },
            link: function ($scope) {
                $scope.data = {
                    // a list of filtered containers to choose from
                    allContainersFiltered : null,
                    // the container currently selected
                    container: $scope.containerOptions.getContainer(),
                    // whether data is ready, the directive should be showing loading message while data is not ready
                    ready: false,
                };

                init($scope);

                addWatches($scope);
            }
        };

        function init($scope) {
            const programId = $scope.containerOptions.programId;

            // this is not a force reload of all the containers in a specific program
            ContainerCacheService.loadContainersByProgramId(programId, false).then(function (data) {
                if (!_.isFunction($scope.containerOptions.filterFunction)) {
                    $scope.data.allContainersFiltered = data;
                }
                else {
                    $scope.data.allContainersFiltered = _.filter(data, (container) => {
                        return $scope.containerOptions.filterFunction(container);
                    });
                }
            }).catch(function (error) {
                AlertService.error('failed to load container list by program id of ', programId, error);
            });
        }

        function addWatches($scope) {
            $scope.$watch("data.container", function(newValue, oldValue) {
                if (newValue==oldValue) {
                    return;
                }

                $scope.containerOptions.setContainer(newValue);
            });
        }
    });
})();