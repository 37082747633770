'use strict';

angular.module('acadiamasterApp')
    .controller('PropertyDetailController', function ($location, $state, $scope, $uibModal,  $rootScope, $stateParams, entity, Property, DataTypeMasterRecordCount) {

        var modalInstance = null;
        var property = null;

        $scope.property = entity;
        property = $scope.property;
        $scope.load = function (id) {

            Property.get({id: id}, function(result) {
                $scope.property = result;
            });

        };

        $scope.valueType = {
            NUMBER: 'NUMBER',
            STRING: 'STRING',
            DATETIME: 'DATETIME',
            FILE: 'FILE'
        };

        $scope.formData = {
            dataTypeRootCategories: [],
            dataTypeCategories: [],
            dataTypes: []
        };

        if ($scope.property.$promise) {
            $scope.dataReady = true;
            $scope.formData.dataTypeCategories = [];
            $scope.formData.dataTypeRootCategories = [];
            $scope.formData.dataTypes = [];

            $scope.property.$promise.then(function () {
                for (var i=0; i < $scope.property.propertyMappings.length; i ++) {
                   var mapping = $scope.property.propertyMappings[i];
                    if (mapping.dataTypeRootCategoryName != null) {
                        $scope.formData.dataTypeRootCategories.push(mapping);
                    } else if (mapping.dataTypeCategoryName != null) {
                        $scope.formData.dataTypeCategories.push(mapping);
                    } else if (mapping.dataTypeName != null) {
                        $scope.formData.dataTypes.push(mapping);
                    }
                }
            });

        }

        $scope.showDeleteConfirm = function (id) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'deleteConfirmation.html',
                scope: $scope,
                controller: function($scope) {
                    $scope.delete = function () {
                        Property.delete({id: property.id},
                            function (result) {
                                DataTypeMasterRecordCount.updateCounts();
                                modalInstance.close(result);
                                $state.go('/properties');
                            });
                    };

                    $scope.closeConfirmation = function() {
                        modalInstance.dismiss('cancel');
                    }

                },
                size: 'md',
                resolve: {
                    item: function () {
                        return Property.get({id: id});
                    }
                }
            });
        };

        $rootScope.$on('acadiamasterApp:propertyUpdate', function(event, result) {
            $scope.property = result;
        });
    });
