import template from './exportWizardSelectedEntities.html';
angular.module('acadiamasterApp')
    .directive('exportWizardSelectedEntities', ngDialog => {
        /**
         * main init function for the directive
         * @param $scope
         */
        return {
            controller : 'exportWizardSelectedEntitiesController',
            restrict   : 'E',
            scope      : {
                openedInDialog : '=',
                selectedData   : '=',
                viewOnly       : '=',
            },
           template: template,
        };
    });
