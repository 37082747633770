/**
 * model for personalized container item
 */
angular.module('acadiamasterApp').factory('PersonalizedContainerItemModel', (
    ModelServiceBase, ContainerItemModel) => {
    /** *************************************************************
     * dto for personalized container item model
     ************************************************************** */
    PersonalizedContainerItemModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

    /**
     * @constructor
     *
     * parent should be a personalized container
     */
    function PersonalizedContainerItemModel(parent) {
        ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

        this.id = null;

        this.visible = true;
        this.unlocked = true;

        this.containerItem = null;

        this.userContainerFormRecords = [];
        this.userContainerFormRecordAggregate = [];
        this.personalizedContainerItemStatusEssentialDTO = null;

        // some private variables storing calculated values
        this._isLatestEntryCompleted = null;
    }

    /**
     * reset private variables that are calculated, used at the beginning of fromDto()
     */
    PersonalizedContainerItemModel.prototype.reset = function() {
        this._isLatestEntryCompleted = null;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    PersonalizedContainerItemModel.prototype.fromDto = function (dto) {
        // reset private variables
        this.reset();

        // simple properties
        this.id = dto.id;

        this.visible = dto.visible;
        this.unlocked = dto.unlocked;
        this.userContainerFormRecords = dto.userContainerFormRecords;
        this.userContainerFormRecordAggregate = dto.userContainerFormRecordAggregate;
        this.personalizedContainerItemStatusEssentialDTO = dto.personalizedContainerItemStatusEssentialDTO;

        // load container item as an object with prototype
        if (dto.containerItem) {
            this.containerItem = new ContainerItemModel();
            this.containerItem.fromDto(dto.containerItem);
        }
        else {
            this.containerItem = null;
        }
    };


    /** *************************************
     * calculated functions on model
     ************************************** */
    /**
     * check if the container item has any form entry, and if the latest form entry is completed
     * @return {boolean} true if the latest entry exist and is completed, false otherwise
     */
    PersonalizedContainerItemModel.prototype.isLatestEntryCompleted = function() {
        if (this._isLatestEntryCompleted == null) {
            this._isLatestEntryCompleted = isLatestEntryCompleted(this);
        }
        return this._isLatestEntryCompleted;
    };

    /**
     * Check if the personalized container item is dismissed
     * @return {boolean} true if the container item is dismissed, false otherwise
     */
    PersonalizedContainerItemModel.prototype.isDismissed = function() {
        if (!this.personalizedContainerItemStatusEssentialDTO) {
            return false;
        }
        return this.personalizedContainerItemStatusEssentialDTO.dismissed;
    };

    /**
     * get the display order
     * @return {int} the display order of the container item that is associated to this, return -1 if unexpected happened
     */
    PersonalizedContainerItemModel.prototype.getDisplayOrder = function() {
        if (this.containerItem == null || this.containerItem.displayOrder==null) {
            return -1;
        }

        return this.containerItem.displayOrder;
    };

    PersonalizedContainerItemModel.prototype.getFormId = function() {
        if (this.containerItem == null || this.containerItem.form==null) {
            return null;
        }

        return this.containerItem.form.id;
    };

    PersonalizedContainerItemModel.prototype.getFormName = function() {
        if (this.containerItem == null || this.containerItem.form==null) {
            return null;
        }

        return this.containerItem.form.name;
    };

    PersonalizedContainerItemModel.prototype.getItemType = function () {
        if (!this.containerItem || !this.containerItem.type) {
            return null;
        }

        return this.containerItem.type;
    };

    PersonalizedContainerItemModel.prototype.getEntryCount = function() {
        if (this.userContainerFormRecords == null) {
            return 0;
        }

        return this.userContainerFormRecords.length;
    };


    /** *************************************
     * private functions
     ************************************** */

    function isLatestEntryCompleted(personalizedContainerItem) {
        if (personalizedContainerItem == null || personalizedContainerItem.userContainerFormRecords == null || personalizedContainerItem.userContainerFormRecords.length < 1) {
            return false;
        }
        var draftRecords = personalizedContainerItem.userContainerFormRecords;
        var timeArray = draftRecords.map(function (item) {
            return item.timestamp;
        });
        timeArray.sort(function sortNumber(a, b) {
            return a - b;
        });
        var theLatest = timeArray.pop();

        var draftLatest = draftRecords.filter(function (item) {
            return item.timestamp === theLatest;
        });

        if (draftLatest == null) {
            return false;
        }
        return !draftLatest[0].draft;
    }


    /** *************************************
     * service return call
     ************************************** */

    return PersonalizedContainerItemModel;
});
