angular.module('acadiamasterApp')
    .config($stateProvider => {
        function loadTranslatePartial ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('agreements');
            return $translate.refresh();
        }

        $stateProvider
            .state('program.manage.agreements', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'agreements.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                 : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader) ],
                },
                url   : '/agreements',
                views : {
                    'content@' : {
                        bindToController : true,
                        controller       : 'agreementListController',
                        controllerAs     : 'agc',
                        templateUrl      : 'admin-templates/site/agreements/listPage/agreementList.template.html',
                    },
                },
            })
            .state('program.manage.agreements.new', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'agreements.title',
                },
                parent  : 'program.manage.agreements',
                resolve : {
                    entity                 : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader) ],
                },
                url   : '/new',
                views : {
                    'content@' : {
                        template : `<program-agreement-config is-new="true"
                                state-after-cancel-save="program.manage.agreements">
                            </program-agreement-config>`,
                    },
                },
            })
            .state('program.manage.agreements.edit', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'agreements.title',
                },
                parent  : 'program.manage.agreements',
                resolve : {
                    entity : [ '$stateParams', 'AgreementsService',
                        ($stateParams, AgreementsService) => AgreementsService.getAgreement({ agreementId : $stateParams.agreementId }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader) ],
                },
                url   : '/edit/{agreementId}',
                views : {
                    'content@' : {
                        controller : ($scope, entity) => {
                            $scope.agreement = entity.data;
                        },
                        template : `<program-agreement-config is-new="false"
                                agreement="agreement"
                                state-after-cancel-save="program.manage.agreements">
                            </program-agreement-config>`,
                    },
                },
            });
    });
