/**
 * Created by Jamie Nola on 08/07/2018
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('programDetailView', function ($timeout, PROGRAM_DETAIL_VIEW_CONSTANTS,
            ProgramDetailViewService, ProgramUtilConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/programDetail/directive/programDetailView.template.html',
                scope: {
                    programId: '='
                },
                controller: function($scope) {
                    $scope.cs = PROGRAM_DETAIL_VIEW_CONSTANTS;
                    $scope.data = {
                        isLoading: true,
                        programData: null,
                        categories: [
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.PROGRAM,
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.DATA_TYPE,
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.FORMS,
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.FORM_ASSOCIATION,
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.RULES,
                            PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORIES.RULE_ASSOCIATION
                        ]
                    };

                    $scope.syncDevicesOptions = ProgramUtilConstantsService.syncDevicesOptions;

                    // sets or inverts the sort in one category
                    $scope.sortCategory = function(category, header) {
                        if(header.sortable) {
                            category.orderByAscending = (category.orderBy === header.id) ? !category.orderByAscending : true;
                            category.orderBy = header.id;
                        }
                    };

                    // hook for resetting the category sort in the HTML
                    $scope.resetCategorySort = function(category) {
                        if(!category.isOpen) {
                            resetCategorySort(category);
                        }
                    };

                    // sets up basic attributes on categories (before data is loaded)
                    function setupCategory(category) {
                        category.isOpen = true;
                        var defaultSort = category.defaultSort || PROGRAM_DETAIL_VIEW_CONSTANTS.TABLE_HEADER.ID;
                        category.defaultOrderBy = defaultSort.id;
                        var index = category.index ||
                            PROGRAM_DETAIL_VIEW_CONSTANTS.TABLE_HEADER.ID;
                        category.indexHeader = index.id;
                        resetCategorySort(category);
                    }

                    // resets back to the default sort for a category
                    function resetCategorySort(category) {
                        // if we're looking at form associations, we need to reset each sub-group's order
                        if(category.id === PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORY_ID.FORM_ASSOCIATION &&
                            $scope.data.programData) {
                            resetGroupSort($scope.data.programData[PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORY_ID.FORM_ASSOCIATION], category);
                        }
                        category.orderBy = category.defaultOrderBy;
                        category.orderByAscending = true;
                    }

                    // resets back to the default sort for a sub-group of a category
                    function resetGroupSort(groups, category) {
                        groups.forEach(function(group) {
                            group.orderBy = category.defaultOrderBy;
                            group.orderByAscending = true;
                        });
                    }

                    // Makes sure that every programData entity we need is wrapped in an array so Angular can sort it
                    function setupProgramData(programData) {
                        $scope.data.categories.forEach(function(category) {
                            var entity = programData[category.id];
                            if(category.titleParams) {
                                var titleParams = {};
                                category.titleParams.forEach(function(param) {
                                    titleParams[param.id] = entity[param.id];
                                });
                                entity.titleParams = titleParams;
                            }
                            if(category.id === PROGRAM_DETAIL_VIEW_CONSTANTS.CATEGORY_ID.FORM_ASSOCIATION) {
                                resetGroupSort(entity, category);
                            }
                            // loop through and process all entries that need to be resolved
                            if(category.headers) {
                                category.headers.forEach(function(header) {
                                    if(header.resolve) {
                                        entity.forEach(function(row) {
                                            var entry = row[header.id];
                                            if(header.resolve[entry]) {
                                                row[header.id] = header.resolve[entry];
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        return programData;
                    }

                    // loads program data from the API, based on program ID
                    function loadProgramData(programId) {
                        $scope.data.isLoading = true;
                        ProgramDetailViewService.getProgramData(programId).then(
                            function(response) {
                                console.log(response.data);
                                $scope.data.programData = setupProgramData(response.data);
                            },
                            function(error) {
                                console.error(error);
                            }).finally(function() {
                                $scope.data.isLoading = false;
                            });
                    }

                    function init() {
                        $scope.data.categories.forEach(function(category) {
                            setupCategory(category);
                        });
                        loadProgramData($scope.programId);
                    }

                    // timeout allows spinning icon to show on the page
                    $timeout(init);
                }
            };
        });
})();
