(function () {
    /**
     * container association directive
     */
    angular.module('acadiamasterApp').directive('exportWizardStepFormAssociation', () => {
        return {
            link : function ($scope) {
                // to be updated later
            },
            restrict : 'E',
            scope    : {
                program        : '=',
                selectedData   : '=',
                wizardConstant : '=',
            },
            templateUrl : 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepFormAssociation.html',
        };

        /** ****************************************************************************
         * private functions
         ******************************************************************************/
    });
}());
