
angular.module('acadiamasterApp')
    .controller('NavbarController', ($rootScope, $scope, $location, $state, Principal, Auth, TILE_CONSTANTS, SSOService) => {
        $scope.Principal = Principal;
        $scope.$state = $state;
        $scope.inProduction = process.env.ENV === 'prod';

        $scope.data = {
            tileCollectionId : TILE_CONSTANTS.ID.DASHBOARD,
            tileView         : TILE_CONSTANTS.VIEW.DROPDOWN,
            isUserAdmin      : false,
        };

        $rootScope.$on('$stateChangeSuccess', checkIsAdmin);
        checkIsAdmin();

        $scope.logout = function () {
            Auth.logout();
        };

        $scope.changePassword = function () {
            if(SSOService.isEnable()){
                var url = `${window.keycloakAuth.authServerUrl}/realms/${window.keycloakAuth.realm}/account/password`;
                window.open(url,"_self");
            } else {
                $state.go('password');
            }
        };

        // TODO: FIX THIS

        const server = {
            LOCALHOST   : { name : 'localhost', title : 'Acadia Development' },
            INTEGRATION : { name : '54.174.223.105', title : 'Acadia Integration' },
            QA          : { name : '52.23.231.172', title : 'Acadia QA' },
            AUTOMATION  : { name : '182.72.250.221', title : 'Acadia Automation' },
            PMI         : { name : 'pmi', title : 'Precision Medicine Initiative' },
            ACADIA_PROD : { name : 'vibrent.vibrenthealth', title : 'Acadia Production' },
            DEMO        : { name : '54.208.207.12', title : 'Acadia Demo' },
        };

        $scope.server = server;

        function checkIsAdmin() {
            Principal.identity()
                .then(account => {
                    if (account) {
                        $scope.data.isUserAdmin = Principal.ifAnyAuthority(['ROLE_ADMIN']);
                    }
                });
        }

        function getCurrentServer(url) {
            let title = 'Acadia';
            if (url.includes(server.LOCALHOST.name)) {
                title = server.LOCALHOST;
            } else if (url.includes(server.INTEGRATION.name)) {
                title = server.INTEGRATION;
            } else if (url.includes(server.QA.name)) {
                title = server.QA;
            } else if (url.includes(server.AUTOMATION.name)) {
                title = server.AUTOMATION;
            } else if (url.includes(server.PMI.name)) {
                title = server.PMI;
            } else if (url.includes(server.ACADIA_PROD.name)) {
                title = server.ACADIA_PROD;
            } else if (url.includes(server.DEMO.name)) {
                title = server.DEMO;
            }

            return title;
        }

        $scope.currentServer = getCurrentServer($location.absUrl());
    });
