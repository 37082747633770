/**
 * Created by shekhar.sukhadeve on 3/16/2017.
 */
'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('external', {
                parent: 'entity',
                url: '/external',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'External Server Logs'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/externalServerLogs/externalLogs.html',
                        controller: 'ExternalServerLogsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('externalLogs');
                        return $translate.refresh();
                    }]
                }
            })
            .state('external.detail', {
                parent: 'external',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'External Server log Audit Details'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/externalServerLogs/externalLogDetails.html',
                        controller: 'ExternalLogDetailController',
                        size: 'lg',
                        resolve: {
                            entity: ['ExternalServerLogs', function(ExternalServerLogs) {
                                return ExternalServerLogs.get({id : $stateParams.id});
                            }]
                        },
                        windowClass: 'modal-content-display-inline-table',
                    }).result.then(function(result) {
                        $state.go('external', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    });
