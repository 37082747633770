export const ASSET_TYPES = [
    {
        name: 'USER_ACCOUNT_LOCK_EMAIL',
        text:'User Account Lock Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'LOGIN_VERIFICATION_CODE_EMAIL',
        text:'Login Verification Code Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'PROGRAM_SUBSCRIPTION_EMAIL',
        text:'Program Subscription Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'VERIFICATION_CODE_EMAIL',
        text:'Onboarding User Verification Code Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'ONBOARDING_EMAIL',
        text:'User Onboarding Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'WITHDRAW_EMAIL',
        text:'User Withdraw Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'RESET_PASSWORD_EMAIL',
        text:'PMT/Admin Participant Reset Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'USER_RESET_PASSWORD_EMAIL',
        text:'User-Initiated Password Reset Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'WELCOME_EMAIL',
        text:'Welcome Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_REMINDER_1',
        text:'Appointment Reminder One',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_REMINDER_2',
        text:'Appointment Reminder Two',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_REMINDER_3',
        text:'Appointment Reminder Three',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_CONFIRMATION',
        text:'Appoinment Confirmation',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_CANCELLED',
        text:'Appointment Cancelled',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_USER_CANCELLED',
        text:'Appointment User Cancelled',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_RESCHEDULED',
        text:'Appointment Rescheduled',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'APPOINTMENT_USER_RESCHEDULED',
        text:'Appointment User Rescheduled',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'SCHEDULE_APPOINTMENT_INVITE',
        text:'Schedule Appointment Invite',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'DIGITAL_AGREEMENT_BASE_CONSENT_EMAIL',
        text:'Digital Agreement Base Consent Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'DIGITAL_AGREEMENT_EHR_CONSENT_EMAIL',
        text:'Digital Agreement EHR Consent Email',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'DIGITAL_AGREEMENT_EHR_CONSENT_EMAIL_WITH_DECLINE_TO_SHARE_DATA',
        text:'Digital Agreement EHR Consent Email With Decline To Share Data',
        extensionType:'.zip',
        mimeType:'application/zip'
    },
    {
        name: 'TERMS_OF_SERVICE',
        text:'Terms Of Service',
        extensionType:'.html',
        mimeType:'text/html'
    },
    {
        name: 'PRIVACY_POLICY',
        text:'Privacy Policy',
        extensionType:'.html',
        mimeType:'text/html'
    },
    {
        name: 'SUPPORT_PAGE',
        text: 'Support Page',
        extensionType:'.html',
        mimeType:'text/html'
    }
];

