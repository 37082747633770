'use strict';
(function () {
    angular.module('acadiamasterApp').factory('ruleLinkageService', function ($http, localWeb) {
        var baseUrl = 'api/businessRules';
        return {

            /**
             * Finds out rules which use the given form id as either trigger or action
             * @param formId Id of the form
             *
             */
            getRulesLinkedToForm: function (formId) {
                return $http.get(localWeb.createUrl(baseUrl + "/form/" + formId));
            },
        };
    })
})();
