/**
 *
 * directive for configuring an state with
 * 1. the height of the content container
 * 2. url for the external content
 * both fields are required
 */
angular.module('acadiamasterApp').directive('containerDisplayConfigStateContent', () => ({
        restrict: 'E',
        scope: {
            displayConfig: '<',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/containerDisplayConfig/containerDisplayConfigStateContent.html',
    }));
