import template from './singleSelection.html';
angular.module('acadiamasterApp')
    .directive('configSingleSelection', () => {
        const configSingleSelectController = function ($scope, $timeout) {
            const vm = this;
            function setSelection (option) {
                vm.data.options.forEach(item => {
                    item.selected = item.value === option.value;
                });
                $timeout(exportSelection);
            }

            vm.data = {
                options             : [],
                possibleSelections  : [],
                selectionHasChanged : false,
            };

            /**
                 * Resets the current changed selection
                 */
            vm.resetCurrentSelectionChanged = function () {
                const selectedValue = vm.exportObject ? vm.currentSelection.name : vm.currentSelection;
                vm.data.options.forEach(option => {
                    option.selected = option.value === selectedValue;
                });
                exportSelection();
            };

            /**
                 * Resets the current selection
                 */
            vm.resetCurrentSelection = function () {
                vm.data.options.forEach(option => {
                    option.selected = false;
                });
                exportSelection();
            };

            /**
                 * Toggles the selected state of a given option
                 * @param {object} option
                 */
            vm.selectOption = function (option) {
                // escape if option is disabled or already selected
                if (option.disabled || option.selected || vm.disabled) {
                    return;
                }

                if (vm.confirmSelectionPromise) {
                    vm.confirmSelectionPromise()
                        .then(() => {
                            setSelection(option);
                        });
                } else {
                    setSelection(option);
                }
            };

            /**
                 * Checks to see if the current selection has changed from
                 * its original value
                 * @param {object || string} current
                 * @param {object || string} changed
                 * @param {boolean} isObject
                 */function getHasSelectionChanged (current, changed, isObject) {
                if (!changed) {
                    return false;
                }
                if (isObject) {
                    return current.name !== changed.name;
                }
                return current !== changed;
            }

            /**
                 * Creates the internal model used by this directive for a single option
                 * @param {object/string} data
                 * @param {string} selectedValue
                 * @param {array} avoidList
                 * @param {boolean} isDefault
                 * @returns {object} the newly-created option model
                 */
            function createOption (data, selectedValue, avoidList, isDefault) {
                let option = {};

                // some are objects and some are strings. This maps them to the same structure
                if (typeof data === 'object') {
                    option = {
                        icon  : data.icon,
                        name  : data.text,
                        value : data.name,
                    };
                } else if (typeof data === 'string' || typeof data === 'number') {
                    option = {
                        name  : data,
                        value : data,
                    };
                }

                // these values are the same for objects or strings.
                option.disabled = avoidList && isInAvoidList(avoidList, option) || false;
                option.isDefault = isDefault || false;
                option.selected = selectedValue === option.value && !option.disabled;

                return option;
            }

            /**
                 * Sets value back to vm.currentSelection and optionally to a callback function
                 */
            function exportSelection () {
                const oldSelection = vm.currentSelection;

                // Get the value of the current selection
                const selected = vm.data.options.find(option => option.selected) || {};
                const selectedValue = selected.value || null;

                // get the matching original possible selection
                let option = null;
                if (selectedValue !== null) {
                    option = vm.data.possibleSelections.find(selection => {
                        const value = typeof selection === 'object'
                            ? selection.name
                            : selection;
                        return selectedValue === value;
                    });
                    if (!vm.exportObject && typeof option === 'object') {
                        option = option.name;
                    }
                }

                // if we're using currentSelectionChanged, set it to null
                // if it is the same as currentSelection.
                if (vm.currentSelectionChanged !== undefined) {
                    vm.currentSelectionChanged = option;
                    if (vm.exportObject) {
                        if (vm.currentSelection !== null && vm.currentSelectionChanged !== null
                                && vm.currentSelectionChanged.name === vm.currentSelection.name) {
                            vm.currentSelectionChanged = null;
                        }
                    } else if (vm.currentSelectionChanged === vm.currentSelection) {
                        vm.currentSelectionChanged = null;
                    }
                } else {
                    vm.currentSelection = option;
                }

                // call the onChange callback function with the new value
                if (vm.onChange) {
                    if (option != oldSelection) {
                        vm.onChange(option, oldSelection);
                    }
                }

                // update the flag for showing the green background
                if (vm.currentSelectionChanged !== undefined) {
                    vm.data.selectionHasChanged = selectedValue === null || getHasSelectionChanged(
                        vm.currentSelection,
                        vm.currentSelectionChanged,
                        vm.exportObject,
                    );
                }
            }

            /**
                 * Parses the possible selections and creates option models to match the values
                 * @param {array || object} possibleSelections
                 */
            function setupOptions (possibleSelections) {
                // Get the value of the current selection
                let selectedValue;
                if (vm.currentSelection) {
                    selectedValue = vm.exportObject ? vm.currentSelection.name : vm.currentSelection;
                }

                if (angular.isArray(possibleSelections)) {
                    vm.data.possibleSelections = possibleSelections;
                } else {
                    // Sometimes possibleSelections is an object instead of an array,
                    // so we need to convert it to an array.
                    vm.data.possibleSelections = Object.keys(possibleSelections).map(key => possibleSelections[key]);
                }

                // iterate through the possible selections and create an internal model for each.
                vm.data.options = vm.data.possibleSelections.map(option => createOption(option, selectedValue, vm.selectionListToAvoid));

                // send updated value to the directive's parent
                exportSelection();
            }

            /**
                 * Updates options based on the newly updated avoid list
                 * @param {array} avoidList
                 */
            function updateAvoidList (avoidList) {
                let needsExport = false;
                vm.data.options.forEach(option => {
                    option.disabled = avoidList && isInAvoidList(avoidList, option) || false;
                    if (option.disabled && option.selected) {
                        option.selected = false;
                        needsExport = true;
                    }
                });

                if (needsExport) {
                    exportSelection();
                }
            }

            function currentSelectionChanged (newSelection) {
                vm.data.options.forEach(option => {
                    if (newSelection != null
                        && (newSelection == option.value || newSelection.name == option.value)) {
                        setSelection(option);
                    }
                });
            }

            vm.$onInit = () => {
                // every time the list of possible selections changes, rebuild the options
                $scope.$watchCollection(() => vm.possibleSelections, setupOptions);
                // If the avoid list updates, update the options
                $scope.$watchCollection(() => vm.selectionListToAvoid, updateAvoidList);
                $scope.$watch(() => vm.currentSelection, currentSelectionChanged);
            };

            function isInAvoidList (avoidList, option) {
                for (let i = 0; i < avoidList.length; i++) {
                    if (avoidList[i] == option.value || avoidList[i].name == option.value) {
                        return true;
                    }
                }
                return false;
            }
        };

        return {
            bindToController : true,
            controller       : configSingleSelectController,
            controllerAs     : 'css',
            restrict         : 'E',
            scope            : {
                confirmSelectionPromise : '<?',
                currentSelection        : '=',
                currentSelectionChanged : '=?',
                disabled                : '<?',
                displayVerticalList     : '<?',
                exportObject            : '<?',
                infoText                : '@?',
                isScrollable            : '<?',
                nullable                : '<?',
                onChange                : '<?',
                possibleSelections      : '<',
                selectionListToAvoid    : '<?',
                titleText               : '@?',
            },
            template : template,
        };
    });
