import template from './elementConfig.trigger.logEntry.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerLogEntry', function (RuleUtilService, vbrCommonUtil) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    element: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.programId = $scope.element.getRoot().programId;
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.formConstants = RuleUtilService.formConstant;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.formPreviewOpened = false;
                    $scope.isReady = false;

                    $scope.form = {};
                    $scope.form.options = {
                        form: $scope.element.form,
                        formCategory: $scope.element.formCategory.name,
                        title: 'Form',
                        viewOnly: false
                    };
                    $scope.isReady = true;

                    $scope.$watch('form.options.form', function (newForm) {
                        $scope.element.form = newForm;
                        if(newForm!=null){
                            $scope.previouslySelectedForm = angular.copy(newForm);
                        }
                    });

                    // Preview options
                    $scope.previewOptions = {
                        hideSimulationBar: true,
                        hideInfoSection: false,
                        showAsThumbnail: false
                    };

                    $scope.formEntryChange = function (newSelection) {
                        $scope.previouslySelectedEntryStatus = angular.copy($scope.element.formEntryStatus);
                        if($scope.element.formCategory.name !== "DATA_FORM" && newSelection.name === "EDITED"){
                            $scope.element.formEntryStatus = $scope.previouslySelectedEntryStatus;
                        } 
                    };

                    $scope.previouslySelectedForm = angular.copy($scope.element.form);
                    $scope.formCategoryChange = function (newSelection) {
                        $scope.previouslySelected = angular.copy($scope.element.formCategory);
                        if(newSelection.name !== "DATA_FORM" && $scope.element.formEntryStatus.name === "EDITED"){
                            $scope.element.formCategory = $scope.previouslySelected;
                        } else {
                            $scope.form.options.formCategory = newSelection.name;
                            if($scope.previouslySelectedForm.category===newSelection.name){
                                $scope.form.options.form = $scope.previouslySelectedForm;
                            } else {
                                $scope.form.options.form = null;
                            }
                        }
                    };             
                }
            };
        });
})();