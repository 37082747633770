(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * directive for form field type ahead selector
         */
        .directive('formFieldConfigFieldValueExternalRichTextLabel', function (FormConstants, $document,
            LocaleConstantsService, ExpressionConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/externalRichTextLabel.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.constants = FormConstants;
                    $scope.expConstants = ExpressionConstantsService;
                }
            };
        });
})();

