import template from './elementConfig.action.in.app.notification.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('ruleElementConfigActionInAppNotification',
            function (LocaleConstantsService) {
                return {
                    restrict: 'E',
                    template: template,
                    scope: {
                        action: '=',
                        parentForm: '=',
                    },
                    link: function ($scope) {
                        $scope.programId = $scope.action.getRoot().programId;
                        $scope.lcs = LocaleConstantsService;
                        $scope.form = {};
                        $scope.form.options = {
                            form: $scope.action ? $scope.action.form : null,
                            viewOnly: false
                        };
                        $scope.$watch('form.options.form', function (newForm) {
                            if ($scope.action) {
                                $scope.action.form = newForm;
                            }
                        });
                    },
                };
            }
        );
})();
