/**
 * Created by Jamie Nola on 02/19/2020
 */
angular.module('acadiamasterApp')
    .constant('DeepLinkConstants', function () {
        const CONTEXT_CLASSES = {
            FORM : 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkFormContext',
            PAGE : 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkPageContext',
            CONTAINER: 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkContainerContext',
            CONTAINER_ITEM: 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkContainerItemContext',
            WORKFLOW: 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkWorkFlowContext',
            CONTENT_CONTAINER: 'com.vibrent.acadia.web.rest.dto.deeplink.DeepLinkContentContainerContext'
        };

        const FIELDS = {
            ACTIONS: {
                id: 'actions',
                isActions: true,
                hideHeader: true,
            },
            CODE: {
                id: 'code',
            },
            CONTAINER: {
                id: 'container',
            },
            CONTAINER_ID: {
                parentFieldId: 'container',
                id: 'containerId',
            },
            CONTAINER_ITEM_ID: {
                parentFieldId: 'containerItem',
                id: 'containerItemId',
            },
            CREATED_BY_ID: {
                id: 'createdById',
            },
            CREATED_ON: {
                id: 'createdOn',
                isDate: true,
            },
            DETAILS: {
                id: 'details',
            },
            ENABLED: {
                id: 'enabled',
            },
            EXPRESSION: {
                id: 'expression',
            },
            AVAILABILITY_EXPRESSION: {
                id: 'availabilityExpression',
                isExpression: true,
            },
            FORM: {
                id: 'form',
            },
            FORM_ID: {
                parentFieldId: 'form',
                id: 'formId',
            },
            ID: {
                id: 'id',
                sortable: true,
                isIndex: true,
            },
            PAGE: {
                id: 'page',
                sortable: true,
            },
            PAGE_ID: {
                id: 'pageId',
            },
            PROGRAM_ID: {
                id: 'programId',
            },
            TARGET_TYPE: {
                id: 'targetType',
                sortable: true,
            },
            UPDATED_BY_ID: {
                id: 'updatedById',
                sortable: true,
            },
            UPDATED_ON: {
                id: 'updatedOn',
                sortable: true,
                isDate: true,
            },
            WORKFLOW_NAME: {
                id: 'workFlowName',
            },
            PARAMETER: {
                id: 'parameter',
            }
        };

        const TARGET_TYPE_IDS = {
            CONTAINER: 'CONTAINER',
            CONTAINER_ITEM: 'CONTAINER_ITEM',
            CONTENT_CONTAINER: 'CONTENT_CONTAINER',
            FORM: 'FORM',
            PAGE: 'PAGE',
            WORKFLOW: 'WORKFLOW',
        };

        const PAGES = [
            'AGREEMENTS',
            'DASHBOARD',
            'MY_DATA',
            'NOTIFICATIONS',
            'SETTINGS',
            'SUPPORT',
            'SYNC_DEVICES',
            'LEARNING_CENTER',
            'SHARE_MY_DATA',
            'STORIES'
        ];

        const TARGET_TYPES = [
            'FORM',
            'PAGE',
            'CONTENT_CONTAINER'
        ];

        const WORKFLOW = 'SCHEDULING';

        return {
            CONTEXT_CLASSES,
            FIELDS,
            PAGES,
            TARGET_TYPES,
            TARGET_TYPE_IDS,
            WORKFLOW,
        };
    }());
