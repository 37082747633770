/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp').directive('bannerList', (ParticipantProfileService,
        AlertService, ParseLinks) => {
        return {
            link : function ($scope) {
                $scope.bannerCount = 0;
                $scope.searchData = {
                    ascending : false,
                    orderBy   : 'id',
                    page      : 0,
                    size      : 20,
                };

                function setOrderBy (id) {
                    if (id === $scope.searchData.orderBy) {
                        $scope.searchData.ascending = !$scope.searchData.ascending;
                    } else {
                        $scope.searchData.ascending = true;
                        $scope.searchData.orderBy = id;
                    }
                    getBanners(0);
                }

                function getBanners (page) {
                    if (page !== undefined) {
                        $scope.searchData.page = page;
                    }
                    ParticipantProfileService.getBanners($scope.userId, $scope.searchData)
                        .then(response => {
                            $scope.banners = response.data;
                            $scope.links = ParseLinks.parse(response.headers('link'));
                            $scope.bannerCount = response.headers('X-Total-Count');
                        }).catch(() => {
                            AlertService.error('Failed to retrieve banners for given user');
                        });
                }

                function init () {
                    getBanners();
                }

                $scope.setOrderBy = setOrderBy;
                $scope.loadBanners = getBanners;

                init();
            },
            restrict : 'E',
            scope    : {
                userId : '=',
            },
            templateUrl : 'admin-templates/site/participant/profile/experience/banners/banners.html',
        };
    });
}());
