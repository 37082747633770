/*
ProgramSelectionList
*/
function ProgramSelectionListController ($scope, AlertService, FormVersionTwoUtilityService, ProgramSearch) {
    $scope.sortByProgramItems = [];
    $scope.searchProgram = '';
    $scope.errMsg = false;
    $scope.defaultSelectionCode = $scope.selectedProgramCode;
    $scope.limitTo = 16;

    function getAllPrograms () {
        ProgramSearch.query({
            page : 0,
            size : 1000,
        })
            .then(programs => {
                let dataDisplay = programs.data.map((program, index) => {
                    let text = program.code;
                    if (program.code.length > 10) {
                        text = `${program.code.slice(0, 9)}...`;
                    }
                    return {
                        code        : program.code,
                        idElement   : `program-item${index}`,
                        programId   : program.id,
                        programName : program.name,
                        text        : text,
                    };
                });
                $scope.sortByProgramItems = dataDisplay;
                $scope.errMsg = false;
            })
            .catch(error => {
                $scope.errMsg = true;
                AlertService.error(`failed to load properties list : ${error}`);
            });
    }

    $scope.getAllPrograms = getAllPrograms;

    getAllPrograms();

    $scope.prevent = function (event) {
        if (!event) {
            return;
        }
        event.stopPropagation();
    };
    $scope.toggleDropdown = function (event, element) {
        if ($scope.isEnabled) {
            FormVersionTwoUtilityService.toggleDropdown(event, element);
        }
    };

    $scope.displayPrograms = function () {
        const programs = $scope.sortByProgramItems.filter(program => {
            return (
                program.text.toUpperCase().includes($scope.searchProgram.toUpperCase()) || program.programId == $scope.searchProgram
            );
        });
        return programs;
    };

    $scope.onProgramSelection = function (value) {
        $scope.selectedProgram = value;
        $scope.selectedProgramCode = value.text ? value.text : $scope.defaultSelectionCode;
        document.getElementById($scope.selectedProgram.idElement).checked = true;
        if ($scope.onSelection){
            $scope.onSelection($scope.selectedProgram);
        }
    };
}

export default ProgramSelectionListController;
