(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * caching service used for various places where you need to select program by name
     */
    app.factory('ProgramCacheService', function (ProgramService, $q) {
        // private variables that holds various states that we don't want to give direct access
        var _programs;
        var _isLoaded = false;

        /**
         * process programs received from server, the result will be stored in
         * @param data
         */
        function processPrograms(data) {
            var programs = data;
            _.sortBy(programs, ['name']);
            _.forEach(programs, function(p) {
                p.shortDescription = p.name + ' | id (' + p.id + ')';
            });
            return programs;
        }

        /**
         * reset function used in both init
         */
        function reset() {
            _programs = [];
            _isLoaded = false;
        }

        /**
         * load programs from server or from local variable
         * @param forceReload set to true if we always want to load the full list from server
         * @return a promise that on success will provide a list of sorted programs
         */
        function loadPrograms(forceReload) {
            var deferred = $q.defer();

            if (forceReload===true) {
                reset();
            }

            if (_isLoaded) {
                deferred.resolve(_programs);
            }
            else {
                ProgramService.query().$promise.then(function(data) {
                    _programs = processPrograms(data);
                    _isLoaded = true;
                    deferred.resolve(_programs);
                }, function () {
                    deferred.reject("unable to load programs from server");
                });
            }

            return deferred.promise;
        }

        /**
         * load program by id from server or from local variable
         * @param forceReload set to true if we always want to load the full list from server
         * @param id - id of the program to be loaded
         * @return a promise that on success will provide a list of sorted programs
         */
        function loadProgramById(id, forceReload) {
            var deferred = $q.defer();

            if (forceReload===true) {
                reset();
            }

            loadPrograms(forceReload).then(function(programs) {
                var program = _.find(programs, function(p) {
                    return p.id === id;
                });

                deferred.resolve(program);
            });

            return deferred.promise;
        }

        reset();

        /**************************************************************
         * all the public accessible programs for the service
         **************************************************************/
        return {
            loadPrograms : loadPrograms,
            loadProgramById : loadProgramById
        };
    })
})();
