(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * forms and container association directive
         */
        .directive('exportWizardStepForms', function (FormSearch, AlertService, ParseLinks, FormPreviewService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepForms.html',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '='
                },
                controller: function ($scope) {
                    init($scope);
                    $scope.selectAll = function () {
                        if ($scope.data.selectAllValue) {
                            // Add displayed forms to the selected form array if they do not already exist there
                            var selectedIds = $scope.selectedData.forms.map(function (selectedForm) {
                                return selectedForm.id;
                            });
                            if ($scope.data.forms) {
                                $scope.data.forms.forEach(function (form) {
                                    if (selectedIds.indexOf(form.id) === -1) {
                                        $scope.selectedData.forms.push(form);
                                    }
                                });
                            }
                        } else {
                            // Remove any forms that are currently displayed from the selected form array
                            var formIds = $scope.data.forms.map(function (form) {
                                return form.id;
                            });
                            var selectedForms = [];
                            if ($scope.selectedData.forms) {
                                $scope.selectedData.forms.forEach(function (form) {
                                    if (formIds.indexOf(form.id) === -1) {
                                        selectedForms.push(form);
                                    }
                                });
                            }
                            $scope.selectedData.forms = selectedForms;
                        }
                    };

                    $scope.sortBy = function (name) {
                        $scope.searchData.ascending = ($scope.searchData.orderBy === name) ? !$scope.searchData.ascending : true;
                        $scope.searchData.orderBy = name;
                        $scope.searchData.page = 0;
                        loadAll($scope);
                    };

                    $scope.loadPage = function (page) {
                        $scope.searchData.page = page || 0;
                        loadAll($scope);
                    };

                    $scope.$watch('selectedData.forms.length', function () {
                        $scope.data.selectAllValue = areAllDisplayedFormsSelected($scope);
                    });

                    $scope.preview = function (form) {
                        var f = {
                            id: form.id
                        };

                        FormPreviewService.preview(f);
                    };
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.orderByChoices = {
                    ID: 'id',
                    NAME: 'name',
                    DISPLAY_NAME: 'displayName',
                    UPDATED_ON: 'updatedOn'
                };

                $scope.formCategory = {
                    DATA_FORM: 'DATA_FORM',
                    PROFILE_FORM: 'PROFILE_FORM',
                    ALL: 'ALL',
                    CONSENT_FORM: 'CONSENT_FORM',
                    SNAP_QUESTION_FORM: 'SNAP_QUESTION_FORM'
                };
                $scope.searchData = {
                    ascending: false,
                    category: $scope.formCategory.ALL,
                    orderBy: $scope.orderByChoices.UPDATED_ON,
                    page: 0,
                    pageSize: 20,
                    pageSizeChoices: [20, 50, 100],
                    searchString: "",
                    previousSearchString: "",
                    searchResultCount: "loading..."
                };
                $scope.data = {
                    selectAllValue: false,
                    forms: [],
                    links: []
                };

                loadAll($scope);
            }

            function areAllDisplayedFormsSelected($scope) {
                var selectedIds = $scope.selectedData.forms.map(function (selectedForm) {
                    return selectedForm.id;
                });
                if (!$scope.data.forms || !$scope.data.forms.length) {
                    return false;
                }
                for (var i = 0; i < $scope.data.forms.length; i++) {
                    var formId = $scope.data.forms[i].id;
                    if (selectedIds.indexOf(formId) === -1) {
                        return false;
                    }
                }
                return true;
            }

            function loadAll($scope) {
                var programId = $scope.program.id;
                // load program specific data type which will have properties used in profile values

                var searchDto = {
                    page: $scope.searchData.page,
                    size: $scope.searchData.pageSize,
                    ascending: $scope.searchData.ascending,
                    orderBy: $scope.searchData.orderBy,
                    searchString: $scope.searchData.searchString,
                    formCategory: $scope.searchData.category === $scope.formCategory.ALL ? null : $scope.searchData.category,
                    programId: programId
                };

                FormSearch.query(searchDto).then(function (response) {
                    if (response == null || response.data == null || response.status !== 200) {
                        AlertService.error("Failed to load program specific form data for Program Id: " + programId);
                        return;
                    }

                    $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                    $scope.searchData.previousSearchString = searchDto.searchString;
                    $scope.data.links = ParseLinks.parse(response.headers('link'));
                    $scope.data.forms = response.data;
                    $scope.data.selectAllValue = areAllDisplayedFormsSelected($scope);

                    if (response.data.length === 0) {
                        AlertService.warning("No forms were found");
                    }
                });
            }
        });
})();
