(function () {
    let app = angular.module('acadiamasterApp');

    /**
     * service for business rule action config model
     */
    app.factory(
        'RuleActionConfigModelService',
        (
            RuleConstantsService, RuleUtilService, RuleActionBaseModelService,
            ContainerItemModel, ActionConfigUserAccountUpdateListModel, ActionConfigTrackChangeModel,
            ActionConfigLevelChangeModel, ActionConfigBannerNotificationModel,
            ActionConfigModalNotificationModel, ActionConfigWithdrawModel, ActionConfigCreateOrderModel,
            ActionConfigCancelOrderModel, ActionConfigEvaluatePersonalizedContainerModel,
            ActionConfigEvaluatePersonalizedContainerItemModel, vxpEventModelService,
            ActionConfigInAppNotificationModel, ActionConfigMobileAppPushNotificationModel,
            ActionConfigCreateChildAccountListModel, ActionConfigPciChangeDismissStatusModel,
        ) => {
            let ActionModelBase = RuleActionBaseModelService.ActionModelBase;
            let ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            /** *************************************************************
             * private functions
             **************************************************************/

            /** *************************************************************
             * Action config email notification model
             ***************************************************************/
            ActionConfigEmailOrSMSNotificationModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigEmailOrSMSNotificationModel (parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.EMAIL_OR_SMS_NOTIFICATION;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigEmailOrSMSNotificationModel;
                this.emailOrSMSNotificationPreference = RuleConstantsService.emailOrSMSNotificationPreference.EMAIL;
                this.attachments = [];
                this.subject = null;
                this.sendToProvider = false;
                this.sendTo = null;
                this.message = null;
                this.templateFile = null;
                this.templateFileName = null;
                this.templateFileUrl = null;
                this.templateFileSize = null;
                this.templateAspectRatio = null;
                this.emailNotificationCategory = null;
                this.localizationMap = {};
                this.attachConsent = false;
                this.consentForm = null;
                this.notificationTemplateType = RuleConstantsService.notificationTemplateType.MESSAGE;
                this.smsContent = null;
                this.communicationCategories = [];
            }

            ActionConfigEmailOrSMSNotificationModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                let actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    if (actionConfig.emailOrSMSNotificationPreference) {
                        this.emailOrSMSNotificationPreference = RuleConstantsService.getObjectByName(
                            RuleConstantsService.emailOrSMSNotificationPreference,
                            actionConfig.emailOrSMSNotificationPreference,
                        );
                    } else {
                        this.emailOrSMSNotificationPreference = RuleConstantsService.emailOrSMSNotificationPreference.EMAIL;
                    }
                    if (actionConfig.communicationCategories != null && actionConfig.communicationCategories.length > 0) {
                        this.communicationCategories = actionConfig.communicationCategories;
                    }
                    this.smsContent = actionConfig.smsContent;
                    this.subject = actionConfig.subject;
                    this.sendTo = actionConfig.sendTo;
                    this.sendToProvider = actionConfig.sendToProvider;
                    this.message = actionConfig.message;
                    this.templateFileName = actionConfig.templateFileName;
                    this.templateFileUrl = actionConfig.templateFileUrl;
                    this.templateFileSize = actionConfig.templateFileSize;
                    this.templateAspectRatio = actionConfig.templateAspectRatio;

                    if (actionConfig.notificationTemplateType && actionConfig.notificationTemplateType !== null) {
                        this.notificationTemplateType = RuleConstantsService.getObjectByName(
                            RuleConstantsService.notificationTemplateType,
                            actionConfig.notificationTemplateType,
                        );
                    } else if (this.templateFileUrl && this.templateFileUrl !== null) {
                        this.notificationTemplateType = RuleConstantsService.notificationTemplateType.TEMPLATE;
                    } else {
                        this.notificationTemplateType = RuleConstantsService.notificationTemplateType.MESSAGE;
                    }
                    let attachments = [];
                    if (actionConfig.attachments != null) {
                        _.forEach(actionConfig.attachments, attachment => {
                            attachments.push(convertDtoToAttachments(attachment));
                        });
                    }
                    this.attachments = attachments;
                    this.attachConsent = actionConfig.attachConsent != null ? actionConfig.attachConsent : false;
                    if (actionConfig.consentFormId != null) {
                        this.consentForm = RuleUtilService.getFormById(actionConfig.consentFormId);
                    }
                    this.localizationMap = actionConfig.localizationMap != null ? actionConfig.localizationMap : {};
                }
            };

            ActionConfigEmailOrSMSNotificationModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let actionConfig = dto.actionConfig;

                // Set notification related fields
                actionConfig.emailOrSMSNotificationPreference = this.emailOrSMSNotificationPreference.name;
                if (this.communicationCategories != null && this.communicationCategories.length > 0) {
                    actionConfig.communicationCategories = this.communicationCategories;
                }
                if (actionConfig.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.EMAIL.name) {
                    // set email specific fields
                    setEmailConfigToDto(actionConfig, files, this);
                } else if (this.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.SMS.name) {
                    // set email specific fields
                    setSMSConfigToDto(actionConfig, this);
                } else {
                    // set both email and sms specific fields
                    setEmailConfigToDto(actionConfig, files, this);
                    setSMSConfigToDto(actionConfig, this);
                }

                return dto;
            };

            ActionConfigEmailOrSMSNotificationModel.prototype.fromDtoForUnauthenticatedTask = function (dto) {
                if (dto.notificationModel != null) {
                    this[RuleConstantsService.classes.classReferenceKey] = dto.notificationModel[RuleConstantsService.classes.classReferenceKey];
                }
                let notificationModel = dto.notificationModel;
                if (notificationModel != null) {
                    if (notificationModel.emailOrSMSNotificationPreference) {
                        this.emailOrSMSNotificationPreference = RuleConstantsService.getObjectByName(
                            RuleConstantsService.emailOrSMSNotificationPreference,
                            notificationModel.emailOrSMSNotificationPreference,
                        );
                    } else {
                        this.emailOrSMSNotificationPreference = RuleConstantsService.emailOrSMSNotificationPreference.EMAIL;
                    }
                    this.smsContent = notificationModel.smsContent;
                    this.subject = notificationModel.subject;
                    this.sendTo = notificationModel.sendTo;
                    this.sendToProvider = notificationModel.sendToProvider;
                    this.message = notificationModel.message;
                    this.templateFileName = notificationModel.templateFileName;
                    this.templateFileUrl = notificationModel.templateFileUrl;
                    this.templateFileSize = notificationModel.templateFileSize;
                    this.templateAspectRatio = notificationModel.templateAspectRatio;

                    if (notificationModel.notificationTemplateType && notificationModel.notificationTemplateType !== null) {
                        this.notificationTemplateType = RuleConstantsService.getObjectByName(
                            RuleConstantsService.notificationTemplateType,
                            notificationModel.notificationTemplateType,
                        );
                    } else if (this.templateFileUrl && this.templateFileUrl !== null) {
                        this.notificationTemplateType = RuleConstantsService.notificationTemplateType.TEMPLATE;
                    } else {
                        this.notificationTemplateType = RuleConstantsService.notificationTemplateType.MESSAGE;
                    }
                    let attachments = [];
                    if (notificationModel.attachments != null) {
                        _.forEach(notificationModel.attachments, attachment => {
                            attachments.push(convertDtoToAttachments(attachment));
                        });
                    }
                    this.attachments = attachments;
                    this.attachConsent = notificationModel.attachConsent != null ? notificationModel.attachConsent : false;
                    if (notificationModel.consentFormId != null) {
                        this.consentForm = RuleUtilService.getFormById(notificationModel.consentFormId);
                    }
                    this.localizationMap = notificationModel.localizationMap != null ? notificationModel.localizationMap : {};
                }
            };

            ActionConfigEmailOrSMSNotificationModel.prototype.toDtoForUnauthenticatedTask = function (files) {
                let notificationModel = {};
                if (this[RuleConstantsService.classes.classReferenceKey] != null) {
                    notificationModel[RuleConstantsService.classes.classReferenceKey] = this[RuleConstantsService.classes.classReferenceKey];
                }
                // Set notification related fields
                notificationModel.emailOrSMSNotificationPreference = this.emailOrSMSNotificationPreference.name;
                if (notificationModel.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.EMAIL.name) {
                    // set email specific fields
                    setEmailConfigToDto(notificationModel, files, this);
                } else if (this.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.SMS.name) {
                    // set email specific fields
                    setSMSConfigToDto(notificationModel, this);
                } else {
                    // set both email and sms specific fields
                    setEmailConfigToDto(notificationModel, files, this);
                    setSMSConfigToDto(notificationModel, this);
                }

                return notificationModel;
            };

            function setEmailConfigToDto (actionConfig, files, model) {
                // clear sms config

                actionConfig.subject = model.subject;
                actionConfig.sendToProvider = model.sendToProvider;

                // set sendTo to null if sendToProvider is true, this makes server process less error prone
                if (actionConfig.sendToProvider) {
                    actionConfig.sendTo = null;
                } else {
                    actionConfig.sendTo = model.sendTo;
                }

                actionConfig.localizationMap = model.localizationMap;
                actionConfig.message = model.message;
                actionConfig.notificationTemplateType = model.notificationTemplateType.name;
                actionConfig.templateFileName = model.templateFileName;
                actionConfig.templateFileUrl = model.templateFileUrl;
                actionConfig.templateFileSize = model.templateFileSize;
                actionConfig.templateAspectRatio = model.templateAspectRatio;
                actionConfig.consentFormId = model.consentForm != null ? model.consentForm.id : null;
                actionConfig.attachConsent = model.attachConsent;

                if (model.templateFile != null) {
                    actionConfig.templateFileName = model.templateFile.name;
                    actionConfig.templateFileSize = model.templateFile.size;
                    files.push(model.templateFile);
                }

                let attachments = [];
                if (model.attachments != null) {
                    _.forEach(model.attachments, attachment => {
                        if (attachment instanceof NotificationConfigFileAttachment) {
                            attachments.push(attachment.toDto(files));
                        } else {
                            attachments.push(attachment);
                        }
                    });
                }
                actionConfig.attachments = attachments;
                // This code is for getting files from localization map of action notification model.
                if (actionConfig.localizationMap != null && actionConfig.localizationMap != undefined) {
                    let localization = actionConfig.localizationMap;
                    for (let key in localization) {
                        if (localization.hasOwnProperty(key)) {
                            let object = localization[key];
                            setLocaleSpecificTemplateValues(object, files);
                        }
                    }
                }
            }

            function setSMSConfigToDto (actionConfig, model) {
                actionConfig.smsContent = model.smsContent;
            }

            function setLocaleSpecificTemplateValues (object, files) {
                let file = object.templateFile;
                if (file != null) {
                    object.templateFileName = file.name;
                    object.templateFileSize = file.size;
                    files.push(file);
                    delete object.templateFile;
                }
            }

            ActionConfigEmailOrSMSNotificationModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                message = `${message } | ${ this.type.name
                } | ${ this.emailOrSMSNotificationPreference.name
                } | ${ this.subject ? `Email Subject: ${this.subject} | ` : '' }${this.smsContent ? `SMS: ${this.smsContent.substring(0, 50)}` : ''}`;
                return message;
            };

            ActionConfigEmailOrSMSNotificationModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.EMAIL) {
                    validateEmailConfig(this);
                } else if (this.emailOrSMSNotificationPreference === RuleConstantsService.emailOrSMSNotificationPreference.SMS) {
                    validateSMSConfig(this);
                } else {
                    validateEmailConfig(this);
                    validateSMSConfig(this);
                }

                let emptyArrayLength = 0;
                if (this.communicationCategories != undefined) {
                    let arrLength = this.communicationCategories.length;
                    if (arrLength <= emptyArrayLength) {
                        this.setErrorMessage('Communication Categories not Selected');
                    }
                }
            };

            function validateEmailConfig (model) {
                if (model.emailNotificationCategory === RuleConstantsService.emailNotificationCategory.PUSH_NOTIFICATION) {
                    return;
                }

                if (model.subject == null || model.subject === '') {
                    model.setErrorMessage('Subject is not specified');
                }
                if (model.notificationTemplateType === RuleConstantsService.notificationTemplateType.TEMPLATE) {
                    if ((!model.templateFileUrl || model.templateFileUrl == null) && (!model.templateFile && model.templateFile == null)) {
                        model.setErrorMessage('Template is not uploaded');
                    }
                } else if (model.message == null || model.message === '') {
                    model.setErrorMessage('Message is not specified');
                }
            }

            function validateSMSConfig (model) {
                if (model.smsContent == null || model.smsContent === '') {
                    model.setErrorMessage('SMS content is not specified');
                }
            }

            /** *************************************************************
             * Action config notification file model
             ***************************************************************/
            function convertDtoToAttachments (dto) {
                let atm = new NotificationConfigFileAttachment();
                atm.fromDto(dto);
                return atm;
            }

            function NotificationConfigFileAttachment () {
                this.file = null;
                this.fileName = null;
                this.fileSize = null;
                this.localizationMap = {};
            }

            NotificationConfigFileAttachment.prototype.fromDto = function (dto) {
                if (dto == null) {
                    return;
                }
                this.fileSize = dto.fileSize;
                this.fileName = dto.fileName;
                this.fileUrl = dto.fileUrl;
                this.aspectRatio = dto.aspectRatio;
                this.localizationMap = dto.localizationMap != null ? dto.localizationMap : {};
            };

            NotificationConfigFileAttachment.prototype.toDto = function (files) {
                let dto = {};
                dto.fileSize = this.fileSize;
                dto.fileName = this.fileName;
                dto.fileUrl = this.fileUrl;
                dto.aspectRatio = this.aspectRatio;
                if (this.file != null) {
                    dto.fileName = this.file.name;
                    dto.fileSize = this.file.size;
                    files.push(this.file);
                }
                // This code is for getting the file from the localization map of notification config file attachment.
                if (this.localizationMap != null && this.localizationMap != undefined) {
                    let localization = this.localizationMap;
                    for (let key in localization) {
                        if (localization.hasOwnProperty(key)) {
                            let object = localization[key];
                            setLocaleSpecificAttachmentValues(object, files);
                        }
                    }
                }
                dto.localizationMap = this.localizationMap;
                return dto;
            };

            function setLocaleSpecificAttachmentValues (object, files) {
                let file = object.file;
                if (file != null) {
                    object.fileName = file.name;
                    object.fileSize = file.size;
                    files.push(file);
                    delete object.file;
                }
            }

            function initdelayValueModel (triggerModel, delayInSeconds) {
                if (delayInSeconds == null || delayInSeconds <= 0) {
                    return;
                }

                let delayModel = RuleUtilService.convertValueFromSeconds(
                    delayInSeconds,
                    RuleConstantsService.shortTimeUnits,
                );
                if (delayModel != null) {
                    triggerModel.delayValue = delayModel.value;
                    triggerModel.delayValueUnit = delayModel.unit;
                }
            }

            /** *************************************************************
             * Action config personalized form model
             * todo: deprecated, and to be removed soon
             ***************************************************************/
            ActionConfigPersonalizedFormModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigPersonalizedFormModel (parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.PERSONALIZED_FORM;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigPersonalizedFormModel;
                // Initialize default action type
                this.actionType = RuleConstantsService.personalizedFormActionType.ADD_FORM;
                this.containerFormMapping = new ContainerItemModel(this);
            }

            ActionConfigPersonalizedFormModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                let actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.actionType = RuleConstantsService.getObjectByName(RuleConstantsService.personalizedFormActionType, actionConfig.actionType);

                    if (actionConfig.containerFormMapping != null) {
                        this.containerFormMapping.fromDto(actionConfig.containerFormMapping);
                    }
                }
            };

            ActionConfigPersonalizedFormModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let actionConfig = dto.actionConfig;
                actionConfig.actionType = this.actionType.name;
                actionConfig.containerFormMapping = this.containerFormMapping.toDto();
                actionConfig.containerFormMapping.formId = this.containerFormMapping._form != null ? this.containerFormMapping._form.id : null;
                actionConfig.containerFormMapping.formName = this.containerFormMapping._form != null ? this.containerFormMapping._form.name : null;
                return dto;
            };

            ActionConfigPersonalizedFormModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                let form = this.containerFormMapping.form;

                if (form) {
                    message = `${message } | ${ form.name }(${ form.id }) added to `;
                    message = message + this.containerFormMapping.getDescriptionAsHtml();
                } else {
                    message = `${message } | **** NO FORM SELECTED YET!! ****`;
                }

                return message;
            };

            ActionConfigPersonalizedFormModel.prototype._validateSelf = function () {
                this.clearError();
                if (!this.containerFormMapping.form) {
                    this.setErrorMessage('Form is not selected');
                } else if (this.containerFormMapping.programId == null) {
                    this.setErrorMessage('Program is not selected');
                } else if (this.containerFormMapping.containerId == null) {
                    this.setErrorMessage('Dashboard is not selected');
                }
            };
            /** *************************************************************
             * Action config unauthenticated task model
             ***************************************************************/
            ActionConfigUnauthenticatedTaskModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigUnauthenticatedTaskModel (parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.UNAUTHENTICATED_TASK;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigUnauthenticatedTaskModel;
                this.unauthenticatedTaskId = null;
                this.sendNotification = false;
                this.notificationModel = null;
            }

            ActionConfigUnauthenticatedTaskModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                let actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.unauthenticatedTaskId = actionConfig.unauthenticatedTaskId;
                    this.sendNotification = actionConfig.sendNotification;
                    if (actionConfig.notificationModel) {
                        this.notificationModel = new ActionConfigEmailOrSMSNotificationModel();
                        // We do not need communication preferences for unauthenticated task action
                        // Deleting these explicitly will avoid to perform validation on them in ActionConfigEmailOrSMSNotificationModel
                        delete this.notificationModel.communicationCategories;
                        this.notificationModel.fromDtoForUnauthenticatedTask(dto.actionConfig);
                    }
                }
            };

            ActionConfigUnauthenticatedTaskModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let actionConfig = dto.actionConfig;
                actionConfig.unauthenticatedTaskId = this.unauthenticatedTaskId;
                actionConfig.sendNotification = this.sendNotification;
                if (this.notificationModel != null) {
                    actionConfig.notificationModel = this.notificationModel.toDtoForUnauthenticatedTask(files);
                }
                return dto;
            };

            ActionConfigUnauthenticatedTaskModel.prototype.getDescriptionAsHtml = function () {
                return `Add unauthenticated ('task, 'id: ${ this.unauthenticatedTaskId })`;
            };
            ActionConfigUnauthenticatedTaskModel.prototype._validateSelf = function () {
                this.clearError();
                if (!this.unauthenticatedTaskId) {
                    this.setErrorMessage('Please set unauthenticated task id');
                }
            };

            /** *************************************************************
             * Action config Fire VXP Event model
             ***************************************************************/

            ActionConfigFireVXPEventModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigFireVXPEventModel (parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.FIRE_VXP_EVENT;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigFireVXPEventModel;
                this.vxpEventName = RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_REQUEST;
                this.createTrackOrderRequestActionDTO = new vxpEventModelService.CreateTrackOrderRequestActionModel();
                this.trackDeliveryRequestActionDTO = new vxpEventModelService.TrackDeliveryRequestActionModel();
                this.userMilestoneEventActionDTO = new vxpEventModelService.UserMilestoneEventActionModel();
                this.fulfillmentRequestEventActionDTO = new vxpEventModelService.FulfillmentRequestActionModel();
                this.fulfillmentReturnTrackingEventActionDTO = new vxpEventModelService.FulfilmentReturnTrackingModel();
            }

            ActionConfigFireVXPEventModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                let actionConfig = dto.actionConfig;
                if (actionConfig) {
                    this.vxpEventName = actionConfig.vxpEventName ? actionConfig.vxpEventName
                        : RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_REQUEST;
                }
                switch (this.vxpEventName) {
                    case RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_REQUEST:
                        this.createTrackOrderRequestActionDTO = new vxpEventModelService.CreateTrackOrderRequestActionModel();
                        this.createTrackOrderRequestActionDTO.fromCreateTrackOrderRequestActionDto(dto.actionConfig);
                        break;
                    case RuleConstantsService.VXPEvents.TRACK_DELIVERY_REQUEST:
                        this.trackDeliveryRequestActionDTO = new vxpEventModelService.TrackDeliveryRequestActionModel();
                        this.trackDeliveryRequestActionDTO.fromTrackDeliveryRequestActionModel(dto.actionConfig);
                        break;
                    case RuleConstantsService.VXPEvents.USER_MILESTONE_EVENT:
                        this.userMilestoneEventActionDTO = new vxpEventModelService.UserMilestoneEventActionModel();
                        this.userMilestoneEventActionDTO.fromUserMilestoneEventActionModel(dto.actionConfig);
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_REQUEST_EVENT:
                        this.fulfillmentRequestEventActionDTO = new vxpEventModelService.FulfillmentRequestActionModel();
                        this.fulfillmentRequestEventActionDTO.fromFulfillmentRequestActionModel(dto.actionConfig);
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_RETURN_TRACKING_EVENT:
                        this.fulfillmentReturnTrackingEventActionDTO = new vxpEventModelService.FulfilmentReturnTrackingModel();
                        this.fulfillmentReturnTrackingEventActionDTO.fromFulfilmentReturnTrackingModel(dto.actionConfig);
                        break;
                    default:
                        break;
                }
            };

            ActionConfigFireVXPEventModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let actionConfig = dto.actionConfig;
                actionConfig.vxpEventName = this.vxpEventName;
                switch (actionConfig.vxpEventName) {
                    case RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_REQUEST:
                        if (this.createTrackOrderRequestActionDTO != null) {
                            actionConfig.createTrackOrderRequestActionDTO
                                = this.createTrackOrderRequestActionDTO.toCreateTrackOrderRequestActionDto(files);
                            this.trackDeliveryRequestActionDTO = null;
                            this.userMilestoneEventActionDTO = null;
                            this.fulfillmentRequestEventActionDTO = null;
                            this.fulfillmentReturnTrackingEventActionDTO = null;
                        }
                        break;
                    case RuleConstantsService.VXPEvents.TRACK_DELIVERY_REQUEST:
                        if (this.trackDeliveryRequestActionDTO != null) {
                            actionConfig.trackDeliveryRequestActionDTO
                                = this.trackDeliveryRequestActionDTO.toTrackDeliveryRequestActionModel(files);
                            this.createTrackOrderRequestActionDTO = null;
                            this.userMilestoneEventActionDTO = null;
                            this.fulfillmentRequestEventActionDTO = null;
                            this.fulfillmentReturnTrackingEventActionDTO = null;
                        }
                        break;
                    case RuleConstantsService.VXPEvents.USER_MILESTONE_EVENT:
                        if (this.userMilestoneEventActionDTO != null) {
                            actionConfig.userMilestoneEventActionDTO = this.userMilestoneEventActionDTO.toUserMilestoneEventActionModel(files);
                            this.trackDeliveryRequestActionDTO = null;
                            this.createTrackOrderRequestActionDTO = null;
                            this.fulfillmentRequestEventActionDTO = null;
                            this.fulfillmentReturnTrackingEventActionDTO = null;
                        }
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_REQUEST_EVENT:
                        if (this.fulfillmentRequestEventActionDTO != null) {
                            actionConfig.fulfillmentRequestEventActionDTO = this.fulfillmentRequestEventActionDTO.toFulfillmentRequestActionModel(files);
                            this.trackDeliveryRequestActionDTO = null;
                            this.createTrackOrderRequestActionDTO = null;
                            this.userMilestoneEventActionDTO = null;
                            this.fulfillmentReturnTrackingEventActionDTO = null;
                        }
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_RETURN_TRACKING_EVENT:
                        if (this.fulfillmentReturnTrackingEventActionDTO != null) {
                            actionConfig.fulfillmentReturnTrackingEventActionDTO = this.fulfillmentReturnTrackingEventActionDTO.toFulfilmentReturnTrackingModel(files);
                            this.trackDeliveryRequestActionDTO = null;
                            this.createTrackOrderRequestActionDTO = null;
                            this.userMilestoneEventActionDTO = null;
                            this.fulfillmentRequestEventActionDTO = null;
                        }
                        break;
                    default:
                        break;
                }
                return dto;
            };

            ActionConfigFireVXPEventModel.prototype._validateSelf = function () {
                this.clearError();
                switch (this.vxpEventName) {
                    case RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_REQUEST:
                        if (!this.createTrackOrderRequestActionDTO.item.itemId
                            || !this.createTrackOrderRequestActionDTO.item.itemName
                            || !this.createTrackOrderRequestActionDTO.item.quantity) {
                            this.setErrorMessage('Please fill all the details related to item');
                        }
                        break;
                    case RuleConstantsService.VXPEvents.TRACK_DELIVERY_REQUEST:
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_RETURN_TRACKING_EVENT:
                        break;
                    case RuleConstantsService.VXPEvents.USER_MILESTONE_EVENT:
                        if (this.userMilestoneEventActionDTO.eventType == null) {
                            this.setErrorMessage('The Event Type is required.');
                        }
                        break;
                    case RuleConstantsService.VXPEvents.FULFILLMENT_REQUEST_EVENT:
                        if (this.fulfillmentRequestEventActionDTO.orderTypeCode == null) {
                            this.setErrorMessage('The orderType Code is required.');
                        }
                        if (this.fulfillmentRequestEventActionDTO.productCode == null) {
                            this.setErrorMessage('The product Code is required.');
                        }
                        if (this.fulfillmentRequestEventActionDTO.trackings.length !== 0) {
                            this.fulfillmentRequestEventActionDTO.trackings.every(item => {
                                if (item.trackingType === null) {
                                    this.setErrorMessage('The trackingType is required.');
                                    return false;
                                } else if (item.carrierCode === null) {
                                    this.setErrorMessage('The carrierCode is required.');
                                    return false;
                                }
                                return true;
                            });
                        }
                        break;
                    default:
                        break;
                }
            };

            /** *************************************************************
             * Action config trigger rule model
             ***************************************************************/
            ActionConfigTriggerRuleModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigTriggerRuleModel (parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.TRIGGER_RULE;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigTriggerRuleModel;

                this.businessRuleId = null;
                this.workflowId = null;
                this.skipEvaluation = false;
                this.executionTime = null;
                this.delayType = RuleConstantsService.delayTypes.NONE;
                this.delayValue = 1;
                this.delayValueUnit = RuleConstantsService.shortTimeUnits.HOUR;
            }

            ActionConfigTriggerRuleModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                let actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.businessRuleId = actionConfig.businessRuleId;
                    this.workflowId = actionConfig.workflowId;
                    this.skipEvaluation = actionConfig.skipEvaluation;
                    this.rule = RuleUtilService.businessRuleService.get({ id : actionConfig.businessRuleId });
                    if (actionConfig.delayType) {
                        this.delayType = RuleConstantsService.delayTypes[actionConfig.delayType];
                    }
                    this.executionTime = RuleUtilService.fromUTCTimestampToDate(actionConfig.executionTime);
                    if (actionConfig.delayValue && this.delayType.name === RuleConstantsService.delayTypes.RELATIVE_DELAY.name) {
                        initdelayValueModel(this, actionConfig.delayValue);
                    }
                }
            };

            ActionConfigTriggerRuleModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let actionConfig = dto.actionConfig;
                actionConfig.businessRuleId = this.rule != null ? this.rule.id : null;
                actionConfig.workflowId = this.workflowId;
                actionConfig.skipEvaluation = this.skipEvaluation;

                actionConfig.delayType = this.delayType.name;
                if (this.delayType.name === RuleConstantsService.delayTypes.SPECIFIC_DATE_TIME.name) {
                    actionConfig.executionTime = RuleUtilService.fromDateToUTCTimestamp(this.executionTime);
                } else if (this.delayType.name === RuleConstantsService.delayTypes.RELATIVE_DELAY.name) {
                    actionConfig.delayValue = this.delayValue * this.delayValueUnit.multiplier;
                }

                return dto;
            };

            ActionConfigTriggerRuleModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.rule == null) {
                    this.setErrorMessage('Rule is not selected');
                } else if (this.workflowId == null) {
                    this.setErrorMessage('Workflow is not selected');
                }
                if (!this.executeImmediately
                    && this.delayType.name === RuleConstantsService.delayTypes.RELATIVE_DELAY.name) {
                    if (this.delayValue <= 0) {
                        this.setErrorMessage('Delay Time must be greater than zero');
                    } else if (this.delayValue !== Math.floor(this.delayValue)) {
                        this.setErrorMessage('Delay Time must be a whole number');
                    }
                }
            };

            ActionConfigTriggerRuleModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                let rule = this.rule;

                if (rule) {
                    if (rule.name) {
                        // add first 25 chars of rule name
                        message = `${message } | rule: ${ rule.name.length > 25 ? `${rule.name.substring(0, 25) }...` : rule.name}`;
                    }
                    message = `${message }(${ rule.id })`;
                } else {
                    message = `${message } | **** NO RULE SELECTED YET!! ****`;
                }
                if (this.delayType.name === RuleConstantsService.delayTypes.SPECIFIC_DATE_TIME.name
                    && this.executionTime) {
                    message = `${message } | ${ this.executionTime}`;
                } else if (this.delayType.name === RuleConstantsService.delayTypes.RELATIVE_DELAY.name) {
                    message = `${message } | ${ this.delayValue } ${ this.delayValueUnit.text}`;
                }

                return message;
            };

            /** *****************************************
             * Action Config Banner Notification Model
             *******************************************/
            /** Constructor */

            /** *************************************
             * service return call
             ***************************************/
            return {
                ActionConfigBannerNotificationModel                : ActionConfigBannerNotificationModel,
                ActionConfigCancelOrderModel                       : ActionConfigCancelOrderModel,
                ActionConfigCreateOrderModel                       : ActionConfigCreateOrderModel,
                ActionConfigEmailOrSMSNotificationModel            : ActionConfigEmailOrSMSNotificationModel,
                ActionConfigEvaluatePersonalizedContainerItemModel : ActionConfigEvaluatePersonalizedContainerItemModel,
                ActionConfigEvaluatePersonalizedContainerModel     : ActionConfigEvaluatePersonalizedContainerModel,
                ActionConfigFireVXPEventModel                      : ActionConfigFireVXPEventModel,
                ActionConfigInAppNotificationModel                 : ActionConfigInAppNotificationModel,
                ActionConfigLevelChangeModel                       : ActionConfigLevelChangeModel,
                ActionConfigMobileAppPushNotificationModel         : ActionConfigMobileAppPushNotificationModel,
                ActionConfigModalNotificationModel                 : ActionConfigModalNotificationModel,
                ActionConfigPersonalizedFormModel                  : ActionConfigPersonalizedFormModel,
                ActionConfigTrackChangeModel                       : ActionConfigTrackChangeModel,
                ActionConfigTriggerRuleModel                       : ActionConfigTriggerRuleModel,
                ActionConfigUnauthenticatedTaskModel               : ActionConfigUnauthenticatedTaskModel,
                ActionConfigUserAccountUpdateListModel             : ActionConfigUserAccountUpdateListModel,
                ActionConfigWithdrawModel                          : ActionConfigWithdrawModel,
                NotificationConfigFileAttachment                   : NotificationConfigFileAttachment,
                ActionConfigCreateChildAccountListModel            : ActionConfigCreateChildAccountListModel,
                ActionConfigPciChangeDismissStatusModel            : ActionConfigPciChangeDismissStatusModel,
            };
        },
    );
}());
