(function () {
    'use strict';

    /**
     * Method to modify components which include title with 22 dp followed by multi-select field
     * Modify these kind of sets to update the display config of both fields and their sub-field
     */
    angular.module('acadiamasterApp').factory('UpdateTitleMultiselectComboAction', function (ScriptActionBaseModel, FormScriptActionUtil,
                                                                                             FormModelServiceGravity, FormModelServiceValueWithUnit, FormConstants) {

        // defining some local variable to make things easier
        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateTitleParagraphTextInputComboAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateTitleParagraphTextInputComboAction() {
            this.name = 'Update display settings in case we detect 22dp titles followed by multi-select field';
            this.steps = [
                {
                    ticketId: 'AC-24426',
                    description: 'Find a sequence where a 22dp title is followed by a multi-select field --> '
                    + ' Update the settings to those mentioned in AC',
                    searchFunction: findTitleMultiselectCombo,
                    changeFunction: updateDisplaySettings
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /**
         * Find components that contain the required combination
         * Save component as well as the index of first title field in target object
         */
        function findTitleMultiselectCombo(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

            var targets = [];
            // Get all components
            var components = fsa.findComponentsInMode(formMode);

            _.forEach(components, function (component) {
                // If component has less than 2 fields skip it
                if (component == null || component.formComponentFields == null || component.formComponentFields.length < 2) {
                    return;
                }
                _.forEach(component.formComponentFields, function (field, index) {
                    // See if the title config matches
                    var titleMatched = isTitleMatch(field);
                    if (titleMatched) {
                        // if title config matches then try to match multi-select as next field
                        var multiselectMatched = component.formComponentFields.length > index + 1 && isMultiselectMatch(component.formComponentFields[index + 1]);
                        if (multiselectMatched) {
                            // If we get a match then add the component as target with index of title
                            var target = {
                                component: component,
                                titleIndex: index,
                                getDescriptionAsHtml: function () {
                                    return component.getDescriptionAsHtml();
                                }
                            };
                            targets.push(target);
                        }
                    }
                });


            });

            return targets;
        }

        function isTitleMatch(field) {
            return field.type == constants.fieldsType.TEXT_LABEL && fsa.isFontSizeEqual(field.displayConfig, 22);
        }

        function isMultiselectMatch(field) {
            return field.type == constants.fieldsType.MULTI_SELECTOR;
        }

        /**
         * Update the settings
         */
        function updateDisplaySettings(target) {
            var component = target.component;

            // Modify display for text label title
            var titleDc = component.formComponentFields[target.titleIndex].displayConfig;
            fsa.updateColor(titleDc, '#262261');
            fsa.updateBgColor(titleDc, 'white');
            fsa.updateFontSize(titleDc, 14);
            fsa.updateFontName(titleDc, fsa.fontNames.notoSans);
            fsa.updateFontStyle(titleDc, false, false, true, false);
            fsa.updatePadding(titleDc, 43, 15, 30, 30);
            fsa.updateMarginPadding(titleDc.margin, 0, 0, 0, 0);
            fsa.updateGravity(titleDc, constants.gravity.CENTER, constants.gravity.MIDDLE);

            // Remove margin padding at field level (It is added at sub-field level)
            var multiselectField = component.formComponentFields[target.titleIndex + 1];
            var multiSelectFieldDc = multiselectField.displayConfig;
            fsa.updateMarginPadding(multiSelectFieldDc.margin, 0, 0, 0, 0);
            fsa.updateMarginPadding(multiSelectFieldDc.padding, 0, 0, 0, 0);

            // Update settings for question label (Multi select subfield)
            var multiselectTitle = multiselectField.subFields[0];
            var multiselectTitleDc = multiselectTitle.displayConfig;
            fsa.updateColor(multiselectTitleDc, '#333333');
            fsa.updateBgColor(multiselectTitleDc, 'white');
            fsa.updateGravity(multiselectTitleDc, constants.gravity.LEFT, constants.gravity.MIDDLE);
            fsa.updatePadding(multiselectTitleDc, 0, 30, 30, 30);

            // Update display settings for multiselect options
            var multiselectOptions =  multiselectField.subFields[1];
            var multiselectOptionsDc = multiselectOptions.displayConfig;
            fsa.updateFontSize(multiselectOptionsDc, 12);
            fsa.updateFontName(multiselectOptionsDc, fsa.fontNames.notoSans);
            fsa.updatePadding(multiselectOptionsDc, 8, 20, 30, 30);
            fsa.updateMarginPadding(multiselectOptionsDc.margin, 0, 0, 0, 0);
            fsa.updateGravity(multiselectOptionsDc, constants.gravity.LEFT, constants.gravity.MIDDLE);
            fsa.updateFontStyle(multiselectOptionsDc, false, false, false, false);
            fsa.updateWidth(multiselectOptionsDc, 100, '%');

            // Update field value settings for multiselect options
            var optionsFieldValue = multiselectOptions.fieldValue;
            fsa.updateMarginPadding(optionsFieldValue.imageMargin, 20, 20, 0, 20);
            fsa.updateMarginPadding(optionsFieldValue.textMargin, 23, 20, 0, 0);
            optionsFieldValue.includeSeparator = true;
            optionsFieldValue.cellBorderLayout = false;
            optionsFieldValue.imagePosition = constants.position.LEFT;
            optionsFieldValue.textPosition = constants.position.RIGHT;
            optionsFieldValue.textGravity = new FormModelServiceGravity.GravityModel(constants.gravity.LEFT, constants.gravity.MIDDLE);
            optionsFieldValue.imageType = constants.imageTypes.CIRCLE;
            optionsFieldValue.imageWidth = new FormModelServiceValueWithUnit.ValueWithUnitModel(20, 'dp');
            optionsFieldValue.imageHeight = new FormModelServiceValueWithUnit.ValueWithUnitModel(20, 'dp');

        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateTitleParagraphTextInputComboAction;
    });

})();
