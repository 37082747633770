'use strict';

angular.module('acadiamasterApp')
    .controller('ParticipantExperienceController', function ($scope, $stateParams,
                                                             ParticipantProfileService, UserAddressUtil) {

        const defaultAccountType = 'N/A';
        $scope.userId = $stateParams.id;
        $scope.childAccountType = defaultAccountType;
        function getChildAccountType( linkedAccounts ) {
            for(let account of linkedAccounts ) {
                if(account.childId == $scope.userId) {
                    return account.childAccountType;
                }
            }
            return defaultAccountType;
        }

        $scope.getAccountPhoneNumber = function() {
            const address = UserAddressUtil.getAccountAddress($scope.user);
            if (address) {
                return address.phoneNumber;
            }
            return null;
        };

        ParticipantProfileService.getParticipant($scope.userId)
            .then(function (result) {
                $scope.user = result.data;
            });

        ParticipantProfileService.getUserProfile($scope.userId)
            .then(function (result) {
                $scope.userProfile = result.data;
            });

        ParticipantProfileService.getSecondaryContactsForUser($scope.userId)
            .then(function (result) {
                $scope.userSecondaryContacts = {
                    contact1: null,
                    contact2: null
                };
                if(result.data) {
                    result.data.forEach((contact) => {
                        if(contact.contactType === 'CONTACT_ONE') {
                            $scope.userSecondaryContacts.contact1 = contact;
                        } else {
                            $scope.userSecondaryContacts.contact2 = contact;
                        }
                    });
                }
            });

        $scope.getLinkedAccounts = () => {
            ParticipantProfileService.getLinkedAccounts($scope.userId)
                .then(function (result) {
                    $scope.childAccountType = getChildAccountType(result.data);
                });
        }

        function init() {
            $scope.getLinkedAccounts();
        }

        init();
    });
