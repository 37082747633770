/**
 * Created by arao on 2/27/2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     */
        .directive('pdfConfiguration', function (LocaleConstantsService, TemplateFieldModelService, $uibModal,ExpressionConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/pdfConfig/pdfConfig.html',
                scope: {
                    element: '=configModel',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.removeField = function (index) {
                        $scope.element.templateFields.splice(index, 1);
                    };

                    $scope.openPdfPreviewWindow = function(){
                        var templateFields = $scope.element.templateFields;
                        var versionId = $scope.element._parent.id;
                        var fileURL=$scope.element.fileURL;
                        $scope.previewWindow = $uibModal.open({
                            animation: true,
                            templateUrl: 'admin-templates/site/forms/configPanel/pdfConfig/pdfPreviewWindow.html',
                            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                                $scope.templateFields = templateFields;
                                $scope.versionId = versionId;
                                $scope.fileURL=fileURL;
                                $scope.ok = function () {
                                    $uibModalInstance.close();
                                };
                            }],
                            size: 'lg'
                        });
                    };
                }
            };
        });

})();

