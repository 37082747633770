(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('associatedProgramTest', function (ProgramTestSuiteModelService,ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTestSuite/directive/associatedProgramTest.directive.html',
                scope: {
                    associatedTestCases: '=',
                    programId: "=",
                    testSuite: "="
                },
                link: function ($scope) {
                    $scope.addNewMapping = function () {
                            ngDialog.openConfirm({
                                template: '<div class="row form-row-buffer"><program-test-search associated-test-cases="associatedTestCases" program-id="programId"></program-test-search></div>',
                                className: 'ngdialog-theme-default large-dialog-width',
                                plain: true,
                                scope: $scope
                            }).then(function (data) {
                                if(data.length>0){
                                    _.forEach(data, function (selectedContent) {
                                        var index=($scope.associatedTestCases?$scope.associatedTestCases.length:0);
                                        $scope.testSuite.addNewMapping(index,selectedContent);
                                    });
                                }
                            });
                    };

                    $scope.removeMapping = function (index) {
                        ProgramTestSuiteModelService.removeMapping($scope.associatedTestCases, index);
                    };

                    $scope.moveMappingUp = function (index) {
                        ProgramTestSuiteModelService.moveMappingUp($scope.associatedTestCases, index);
                    };

                    $scope.moveMappingDown = function (index) {
                        ProgramTestSuiteModelService.moveMappingDown($scope.associatedTestCases, index);
                    };

                }
            };
        });

})();

