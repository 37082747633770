/**
 * Created by Jamie Nola on 10/19/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('participantUserEvents', function (SEARCH_FILTER_CONSTANTS, ParticipantProfileService,
            ParseLinks) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/participant/profile/experience/userEvents/participantUserEvents.template.html',
                scope: {
                    userId: '<'
                },
                controller: function ($scope) {
                    $scope.data = {
                        ascending: false,
                        categories: [
                            { name: 'id', isSortable: true },
                            { name: 'date', isDate: true },
                            { name: 'eventType' },
                            { name: 'programId' },
                            { name: 'details' },
                            { name: 'osType'},
                            { name: 'osVersion'},
                            { name: 'browserInfo'}
                        ],
                        events: [],
                        filteredFields: [
                            SEARCH_FILTER_CONSTANTS.FIELDS.USER_EVENT_ACTION_TYPE,
                            SEARCH_FILTER_CONSTANTS.FIELDS.USER_EVENT_TIMESTAMP
                        ],
                        filteredValues: {},
                        isLoading: true,
                        links: {},
                        orderBy: 'id',
                        page: 0,
                        size: 50,
                        sizeOptions: [10, 25, 50, 100],
                        searchResultCount: 0
                    };

                    $scope.sortUserEventsById = function (id) {
                        if (!$scope.data.isLoading) {
                            $scope.data.ascending = ($scope.data.orderBy === id) ? !$scope.data.ascending : true;
                            $scope.data.orderBy = id;
                            $scope.loadAll(0);
                        }
                    };

                    $scope.loadAll = function(page) {
                        if(page != undefined) {
                            $scope.data.page = page;
                        }
                        var params = {
                            ascending: $scope.data.ascending,
                            orderBy: $scope.data.orderBy,
                            page: $scope.data.page,
                            size: $scope.data.size,
                            userId: $scope.userId
                        };
                        Object.keys($scope.data.filteredValues).forEach(function(key) {
                            params[key] = $scope.data.filteredValues[key];
                        });

                        ParticipantProfileService.getUserEvents(params)
                            .then(function (result) {
                                $scope.data.links = ParseLinks.parse(result.headers('link'));
                                $scope.data.searchResultCount = result.headers('X-Total-Count');
                                $scope.data.events = result.data;
                            }, function(error) {
                                $scope.data.searchResultCount = 0;
                                $scope.data.events = [];
                                $scope.data.links = {};
                                console.error(error);
                            })
                            .finally(function() {
                                $scope.data.isLoading = false;
                            });
                    };
                }
            };
        });
})();
