angular.module('acadiamasterApp')
    .controller('exportWizardSelectedEntitiesController', ($scope, ngDialog) => {
        function init ($scope) {
            const data = $scope.selectedData;
            const hasPropertyData = data.includeDataType
                || data.properties.length > 0;
            const hasRuleData = data.rules.length > 0;
            const hasFormData = data.forms.length > 0;
            const hasFormAssociation = data.includeFormAssociation;
            const hasCommunicationCategory = data.communicationCategories.length > 0
                || data.includeCommunicationCategoryAssociation;
            const hasTestSuiteData = data.testSuites.length > 0;
            const hasProgramBasicsData = data.includeProgramBasics;
            const hasStringData = data.includeProgramString;
            const hasExpressionConditionData = data.expressionConditions.length > 0;
            const hasAgreementData = data.includeProgramAgreements;
            const hasDeepLinkData = data.includeDeepLinks;
            const hasAssetsData = data.includeProgramAssets;
            const hasUnauthenticatedTaskData = data.includeUnauthenticatedTasks;

            $scope.data = {
                accordionStatus : {
                    agreements              : hasAgreementData,
                    assets                  : hasAssetsData,
                    communicationCategories : hasCommunicationCategory,
                    deepLinks               : hasDeepLinkData,
                    expressionConditions    : hasExpressionConditionData,
                    forms                   : hasFormData,
                    formAssociation: hasFormAssociation,
                    programBasics           : hasProgramBasicsData,
                    properties              : hasPropertyData,
                    rules                   : hasRuleData,
                    strings                 : hasStringData,
                    testSuite               : hasTestSuiteData,
                    unauthenticatedTasks    : hasUnauthenticatedTaskData,
                },
            };
        }
        $scope.closePopup = () => {
            ngDialog.close(null, 0);
        };

        $scope.export = () => {
            ngDialog.close(null, 1);
        };

        $scope.removeSelectedProperty = id => {
            const properties = [];
            $scope.selectedData.properties.forEach(property => {
                if (property.id !== id) {
                    properties.push(property);
                }
            });
            $scope.selectedData.properties = properties;
        };

        $scope.removeSelectedForm = id => {
            const forms = [];
            $scope.selectedData.forms.forEach(form => {
                if (form.id !== id) {
                    forms.push(form);
                }
            });
            $scope.selectedData.forms = forms;
        };

        $scope.removeSelectedRule = id => {
            const rules = [];
            $scope.selectedData.rules.forEach(rule => {
                if (rule.id !== id) {
                    rules.push(rule);
                }
            });
            $scope.selectedData.rules = rules;
        };

        $scope.removeSelectedCommunicationCategory = id => {
            const communicationCategories = [];
            $scope.selectedData.communicationCategories.forEach(category => {
                if (category.id !== id) {
                    communicationCategories.push(category);
                }
            });
            $scope.selectedData.communicationCategories = communicationCategories;
        };

        $scope.removeSelectedTestSuite = id => {
            const testSuites = [];
            $scope.selectedData.testSuites.forEach(suite => {
                if (suite.id !== id) {
                    testSuites.push(suite);
                }
            });
            $scope.selectedData.testSuites = testSuites;
        };

        $scope.removeSelectedExpressionCondition = id => {
            const conditions = [];
            $scope.selectedData.expressionConditions.forEach(condition => {
                if (condition.id !== id) {
                    conditions.push(condition);
                }
            });
            $scope.selectedData.expressionConditions = conditions;
        };

        $scope.selectAllAgreements = () => {
            if ($scope.selectedData.includeProgramAgreements && $scope.data.agreements) {
                $scope.selectedData.agreements = $scope.data.agreements;
            } else {
                $scope.selectedData.agreements = [];
            }
        };

        $scope.selectAllDeepLinks = () => {
            if ($scope.selectedData.includeDeepLinks && $scope.data.deepLinks) {
                $scope.selectedData.deepLinks = $scope.data.deepLinks;
            } else {
                $scope.selectedData.deepLinks = [];
            }
        };

        $scope.selectAllUnauthenticatedTask = () => {
            if ($scope.selectedData.includeUnauthenticatedTasks && $scope.data.unauthenticatedTasks) {
                $scope.selectedData.unauthenticatedTasks = $scope.data.unauthenticatedTasks;
            } else {
                $scope.selectedData.unauthenticatedTasks = [];
            }
        };

        $scope.isExportDisabled = () => {
            const data = $scope.selectedData;
            const hasPropertyData = data.includeDataType
                || data.properties.length > 0;
            const hasRuleData = data.rules.length > 0;
            const hasFormData = data.forms.length > 0;
            const hasFormAssociation = data.includeFormAssociation;
            const hasTestSuiteData = data.testSuites.length > 0;
            const hasProgramBasicsData = data.includeProgramBasics;
            const hasStringData = data.includeProgramString;
            const hasExpressionConditionData = data.expressionConditions.length > 0;
            const hasAgreementData = data.includeProgramAgreements;
            const hasDeepLinkData = data.includeDeepLinks;
            const hasAssetsData = data.includeProgramAssets;
            const hasCommunicationCategoriesData = data.communicationCategories.length > 0
                || data.includeCommunicationCategoryAssociation;
            const hasUnauthenticatedTasksData = data.unauthenticatedTasks.length > 0
                || data.includeUnauthenticatedTasks;

            const hasAnyData = hasPropertyData
                || hasRuleData
                || hasFormData
                || hasFormAssociation
                || hasTestSuiteData
                || hasProgramBasicsData
                || hasStringData
                || hasExpressionConditionData
                || hasAgreementData
                || hasDeepLinkData
                || hasAssetsData
                || hasCommunicationCategoriesData
                || hasUnauthenticatedTasksData
                || hasCommunicationCategoriesData;
            return !hasAnyData;
        };
        init($scope);
    });
