(function () {

    angular.module('acadiamasterApp').controller(
        'externalDataTaskTrackController',
        function ($scope,$state,$stateParams,ExternalDataImportService,ParseLinks,ngDialog) {

            $scope.taskId = $stateParams.id;
            $scope.page = 0;

            function init(){
                ExternalDataImportService.getTaskStatus($scope.taskId,{page: $scope.page, pageSize: 50}).then(function(response){
                    $scope.links = ParseLinks.parse(response.headers('link'));
                    $scope.taskAuditDetails = response.data;
                }).catch(function(error) {
                    console.error(error);
                });
            }

            $scope.loadPage = function (page) {
                $scope.page = page;
                init();
            };

            $scope.showImportLogs = function (logs){
               
                ngDialog.open({
                    templateUrl: 'admin-templates/site/userImport/logging/importLogs.html',
                    className: 'ngdialog-theme-plain custom-width-medium',
                    data: {
                      importLogs:logs,
                    },
                    controller : ['$scope', function ($scope) {
                        $scope.closeAndReload = function() {
                            ngDialog.close();
                            window.location.reload();
                        };
                    }],
                });
            };

         
            $scope.showMessageDialog = function (importObject,title) {
               
                let importObjectParsed  = JSON.parse(importObject);
              
                 ngDialog.open({
                     template: 'userImport-object-dialog',
                     className: 'ngdialog-theme-plain custom-width-fix40',
                     data: {
                       importObj:importObjectParsed,
                       header:title
                     },
                 });
             };

             
            $scope.showErrorDialog = function (errorStack,title) {
                 ngDialog.open({
                     template: 'userImport-error-dialog',
                     className: 'ngdialog-theme-plain custom-width-fix40',
                     data: {
                       error:errorStack,
                       header:title
                     },
                 });
             };

             $scope.refreshTaskAudit =function() {
                init();
            };

            init();
        });
}());

