(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    /**
     * directive to display a single work flow in a business rule, this directive include
     * options for workflow selection, which would be useful in selecting workflow for chain triggered rules
     */
    app.directive('vbrWorkflowDetail', function (RuleUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/ruleDetail/workflow-detail.html',
            scope: {
                workflow: '=',
                autoOpen: '=?',
                isReadOnly: '=?',
                detailedView: '=',
                // this is a work id function that should have two field functions, getId(), setId(id)
                workIdFunctions: '='
            },
            link: function ($scope) {
                $scope.selector = RuleUtilService.selector.createSelector();
                $scope.ruleUtil = RuleUtilService;

                if ($scope.workIdFunctions == null) {
                    $scope.isReadOnly = null;
                }

                if ($scope.autoOpen && $scope.workflow) {
                    $scope.workflow.isOpen = true;
                }

                /**
                 * check if we should be showing the selection or not
                 * @returns {boolean} - true if this is not read only and the current selected id is
                 * not the same as the current work flow id, false otherwise
                 */
                $scope.shouldShowSelection = function () {
                    return $scope.workIdFunctions &&
                        !$scope.isReadOnly &&
                        $scope.workflow.id !== $scope.workIdFunctions.getId();
                };
            }
        };
    });

})();
