'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('unitCategory', {
                parent: 'unitSystem',
                url: '/category',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.unitCategory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/unitSystem/unitCategory/unitCategory.html',
                        controller: 'UnitCategoryController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('unitCategory.detail', {
                parent: 'unitCategory',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.unitCategory.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/unitSystem/unitCategory/unitCategoryDetail.html',
                        controller: 'UnitCategoryDetailController'
                    }

                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitCategory');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UnitCategory', function($stateParams, UnitCategory) {
                        return UnitCategory.get({id : $stateParams.id});
                    }]
                }
            })
            .state('unitCategory.new', {
                parent: 'unitCategory',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/unitSystem/unitCategory/unitCategoryDialog.html',
                        controller: 'UnitCategoryDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {name: null, id: null};
                            },
                            baseunits: function () {
                                return null;
                            },
                            isNew : function() { return true;},

                        }
                    }).result.then(function(result) {
                        $state.go('unitCategory', null, { reload: true });
                    }, function() {
                        $state.go('unitCategory');
                    });
                }]
            })
            .state('unitCategory.edit', {
                parent: 'unitCategory',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/unitSystem/unitCategory/unitCategoryDialog.html',
                        controller: 'UnitCategoryDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['UnitCategory', function(UnitCategory) {
                                return UnitCategory.get({id : $stateParams.id});
                            }],
                            baseunits: ['UnitByCategory', function(UnitByCategory) {
                                return UnitByCategory.get({categoryId : $stateParams.id});
                            }],
                            isNew : function() { return false;}
                        }
                    }).result.then(function(result) {
                        $state.go('unitCategory', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    });
