(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for RemoveMultiSub model
     */
    app.factory('CorrectiveActionRemoveMultiSubModel', function (CorrectiveActionBaseModel, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        CorrectiveActionRemoveMultiSubModel.inheritsFrom(CorrectiveActionBaseModel);

        function CorrectiveActionRemoveMultiSubModel(parent) {
            CorrectiveActionBaseModel.call(this, parent,
                ProgramTestConstantsService.correctiveActionEnum.REMOVE_MULTIPLE_SUBSCRIPTION);
        }

        /**
         * convert the current UI model to dto format
         */
        CorrectiveActionRemoveMultiSubModel.prototype.toDto = function () {
            var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any format you have on the current object
         * @param dto
         */
        CorrectiveActionRemoveMultiSubModel.prototype.fromDto = function (dto) {
            CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);
        };

        CorrectiveActionRemoveMultiSubModel.prototype.getDescriptionAsHtml = function () {
            return 'Remove multiple subscriptions';
        };

        CorrectiveActionRemoveMultiSubModel.prototype._validateSelf = function () {
            this.clearError();
        };

        /***************************************
         * service return call
         ***************************************/
        return CorrectiveActionRemoveMultiSubModel;

    });
})();
