'use strict';

angular.module('acadiamasterApp')
    .factory('MonitoringService', function ($rootScope, $http,localWeb) {
        return {
            getMetrics: function () {
                return $http.get(localWeb.createUrl('metrics/metrics')).then(function (response) {
                    return response.data;
                });
            },

            threadDump: function () {
                return $http.get(localWeb.createUrl('dump')).then(function (response) {
                    return response.data;
                });
            }
        };
    });
