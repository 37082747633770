(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('OrganizationDialogController', function ($scope, $stateParams, $uibModalInstance, entity, isNew, Organization, User, CONFIG) {
            $scope.CONFIG = CONFIG;
            $scope.organization = entity;
            $scope.isNew = isNew;
            $scope.users = User.query();
            $scope.load = function (id) {
                Organization.get({id: id}, function (result) {
                    $scope.organization = result;
                });
            };

            var onSaveFinished = function (result) {
                console.log('on save finished: ', result);
                $scope.$emit('acadiamasterApp:organizationUpdate', result);
                $uibModalInstance.close(result);
            };

            $scope.save = function () {
                if ($scope.organization.id != null) {
                    Organization.update($scope.organization, onSaveFinished);
                } else {
                    Organization.save($scope.organization, onSaveFinished);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };
        });


})();
