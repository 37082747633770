/**
 * Created by moustafabaiou on 2/15/17
 */
angular.module('acadiamasterApp')
    .directive('treeNodeTriggerFixedNode', (RuleUtilService, FixedNodeModelService,RuleModelService, ngDialog, $timeout) => ({
        link : $scope => {
            $scope.ruleUtil = RuleUtilService;
            $scope.allTriggerTypes = FixedNodeModelService.getTriggerTypes();

            $scope.states = {
                isAddFieldPopupOpen : false,
            };

            $scope.showAddTriggerDialog = $event => {
                $scope.states.isAddFieldPopupOpen = true;
                ngDialog.openConfirm({
                    className        : 'ngdialog-theme-plain custom-width-small',
                    preCloseCallback : () => {
                        $timeout(() => {
                            $scope.states.isAddFieldPopupOpen = false;
                        });
                    },
                    scope       : $scope,
                    templateUrl : 'admin-templates/site/components/treeEditorDialogs/addTrigger.template.html',
                }).then(triggerType => {
                    $scope.triggerFixedNode.addTrigger(triggerType, $scope.selector);
                });
                $event.stopPropagation();
            };

            $scope.shouldShowTriggerType = triggerType => {
                // hide schedule trigger for non SCHEDULE RULE
                if(!RuleUtilService.isScheduleRule($scope.triggerFixedNode) && triggerType.name == RuleUtilService.ruleConstants.triggerType.SCHEDULE.name){
                    return false;
                }
                return true;
            };

        },
        restrict : 'E',
        scope    : {
            selector         : '=',
            triggerFixedNode : '=',
        },
        templateUrl : 'admin-templates/site/businessRule/treeNode/triggerFixedNode.html',
    }));
