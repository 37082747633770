(function () {
    angular.module('acadiamasterApp')
        .factory('ProgramBranding', [ '$http', 'localWeb', 'Upload', function ($http, localWeb, Upload) {
            return {

                getBrandingDetails : function (programId) {
                    return $http.get(localWeb.createUrl(`api/programbranding/${programId}`));
                },
                setBrandingDetails : function (files, data) {
                    // send them all together for HTML5 browsers:
                    return Upload.upload({
                        arrayKey : '',
                        // to allow files array to upload with param name file instead of file[i]
                        data     : {
                            data : JSON.stringify(data),
                            file : files,
                        },
                        url : localWeb.createUrl('api/programbranding/'),
                    });
                },
            };
        } ]);
}());
