(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrFormSearch', function (ParseLinks, ngDialog, FormSearch, Form,
                                              FormUtilService, FormPreviewService) {
            function generateRandomName() {
                const randomNumber = Math.floor(Math.random() * 1000);
                return 'formSearchField_' + randomNumber;
            }

            return {
                restrict: 'E',
                scope: {
                    // todo: form search options is getting really complex,
                    // we should build a class for it so it is clear which properties
                    // are there, quite tedious to change though because it is used
                    // in a lot of places
                    options: '=',
                    isRequired: '=',
                    // show warning message when the page is just loaded and the field is required
                    showRequiredMsg: '<?',
                    // flag indicate if we want to allow multiple search or not
                    allowMultiple: '=',
                    // a optional function to call to check if a form can be selected
                    canSelectFunc: '=',
                    parentForm: '=',
                    showOnlyButton: '=',
                    programId: '<',
                    showOnlySystemForms: '<',
                },
                templateUrl: 'admin-templates/site/forms/form/formSearch.html',
                link(scope) {
                    scope.formFieldName = generateRandomName();

                    scope.setDirty = function(newDirtyValue) {
                        if (scope.parentForm && scope.parentForm[scope.formFieldName]) {
                            scope.parentForm[scope.formFieldName].$dirty = newDirtyValue;
                        }
                    };

                    scope.search = {
                        forms: [],
                        searchComplete: false,
                        formCategory: scope.options.formCategory,
                        label: scope.options.customLabel != null ? scope.options.customLabel : 'Add Form',
                        searchString: '',
                        exactMatch: false,
                        page: 0,
                        pageSize: getPageSize(scope.options),
                        searchLabel: getSearchLabel(scope.options),
                        changeLabel: getChangeLabel(scope.options),
                        links: null,
                        formSearchType: scope.options.formSearchType
                    };

                    scope.getSearchOrChangeTitle = function() {
                        return (scope.options.form==null) ?
                            getSearchLabel(scope.options) :
                            getChangeLabel(scope.options);
                    };

                    scope.searchForm = function () {
                        // Set form category in search object
                        if (scope.search.formCategory != scope.options.formCategory) {
                            scope.search.formCategory = scope.options.formCategory;
                            scope.search.forms = [];
                            scope.search.links = null;
                            scope.search.searchComplete = false;
                        }

                        ngDialog.openConfirm({
                            template: 'formSearchDialog',
                            controller: ['$scope', function ($scope) {
                                $scope.search = scope.search;
                                $scope.data = scope.data;
                                $scope.controls = scope.controls;
                            }],
                            className: 'ngdialog-theme-plain custom-width-large'
                        });
                    };

                    scope.hasTitle = function () {
                        return (scope.options && scope.options.title && scope.options.title.length > 0);
                    };

                    scope.getTitle = () => {
                        return scope.hasTitle() ? scope.options.title : '';
                    };

                    scope.hasIconClass = () => {
                        return (scope.options && scope.options.iconClass && scope.options.iconClass.length > 0);
                    };

                    scope.getIconClass = () => {
                        return scope.hasIconClass() ? scope.options.iconClass : '';
                    };

                    scope.isShowOnlyButton = function () {
                        return scope.showOnlyButton;
                    };

                    scope.controls = {
                        parent: scope,
                        loadPage: function (page) {
                            return loadPage(this.parent, page);
                        },
                        searchForms: function () {
                            scope.search.page = 0;
                            return searchForms(this.parent);
                        },
                        selectForm: function (form) {
                            scope.setDirty(true);
                            return selectForm(this.parent, form);
                        },
                        clearForm: function() {
                            scope.options.form = null;
                            scope.setDirty(true);
                            completeChange(scope.options);
                        },
                        isFormSelectable : function(form) {
                            if (scope.canSelectFunc==null) {
                                // if no can select function was passed into the directive, then assume
                                // every form is selectable
                                return true;
                            }

                            return scope.canSelectFunc(form);
                        }
                    };

                    scope.preview = function (form) {
                        FormPreviewService.preview(form);
                    };
                },
            };

            /** ***********************************************************************************
             * private functions
             */

            /**
             * complete the change action for form selection or deletion
             * @param options - options object that holds the form and the on change call back function
             */
            function completeChange(options) {
                if (options && _.isFunction(options.onChangeCallBack)) {
                    options.onChangeCallBack();
                }
            }

            function selectForm(scope, form) {
                scope.options.form = form;

                if (!scope.allowMultiple) {
                    scope.search.searchComplete = false;
                    scope.search.searchString = '';
                    scope.search.forms = [];
                    scope.search.page = 0;
                    scope.search.links = null;
                    ngDialog.close(form);
                }

                completeChange(scope.options);
            }

            function loadPage(scope, page) {
                scope.search.page = page;
                searchForms(scope);
            }

            function searchForms(scope) {
                scope.search.searchComplete = false;

                let searchFilter = {
                    exactMatch: scope.search.exactMatch,
                    searchString: scope.search.searchString,
                    formCategory: scope.search.formCategory,
                    formSearchType: scope.search.formSearchType,
                    page: scope.search.page,
                    size: scope.search.pageSize,
                    programId: scope.programId,
                    showOnlySystemForms: scope.showOnlySystemForms ? true : false,
                };
                let promise = FormSearch.query(searchFilter);
                return promise.then((result) => {
                    scope.search.forms = [];
                    if (result!=null) {
                        scope.search.links = ParseLinks.parse(result.headers('link'));
                        var data = result.data;
                        if (data != null) {
                            for (var i = 0; i < data.length; i++) {
                                scope.search.forms[i] = data[i];
                            }
                        }
                    }
                    scope.search.searchComplete = true;
                });
            }

            function getPageSize(options) {
                if (options == null || options.pageSize == null || !_.isNumber(options.pageSize)) {
                    return 10;
                }
                return options.pageSize;

            }

            function getSearchLabel(options) {
                if (options == null || options.searchLabel == null || options.searchLabel.length == 0) {
                    return 'Search';
                }
                return options.searchLabel;

            }

            function getChangeLabel(options) {
                if (options == null || options.changeLabel == null || options.changeLabel.length == 0) {
                    return 'Change';
                }
                return options.changeLabel;

            }
        });
})();
