/**
 * Created by moustafabaiou on 4/26/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .value('CONFIG', {}) // for setting the values provided by server for configuration of the application
        .value('ORDER_TYPES', {}); // for storing Salivary order types
})();
