(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for value comparison model
     */
    app.factory('ValueComparisonOptionModel', function () {

        /***************************************************************
         * private functions
         **************************************************************/

        function ValueComparisonOptionModel() {
            //initialized the variables
            this.canConfigureOperator = true;
            this.isRequired = true;

            // used in string type of input with no available value list
            // if it's not set to null, the regex error message will be displayed
            // if it's defined
            this.regexPattern = null;
            this.regexErrorMsg = null;

            /*
                list of functions to support
             */
            this.functions = {
                getValueType : null,

                getOperator : null,
                setOperator : null,

                setValues : null,
                getValues : null,

                // if we are using possible values, then those possible values must be a list of objects with
                // a name field and text field in each object, this is the requirement of the multi-select library we are using
                getPossibleValues : null,
            };
        }

        ValueComparisonOptionModel.prototype.setRegex = function (pattern, errorMsg) {
            this.regexPattern = pattern;
            this.regexErrorMsg = errorMsg;
        };

        ValueComparisonOptionModel.prototype.hasRegex = function () {
            return this.regexPattern != null && this.regexPattern.length>0;
        };

        ValueComparisonOptionModel.prototype.supportGetPossibleValues = function () {
            return _.isFunction(this.functions.getPossibleValues);
        };

        ValueComparisonOptionModel.prototype.getPossibleValues = function () {
            if (_.isFunction(this.functions.getPossibleValues)) {
                return this.functions.getPossibleValues();
            }
            return null;
        };

        /**
         * pass through method for get value type
         */
        ValueComparisonOptionModel.prototype.getValueType = function () {
            if (_.isFunction(this.functions.getValueType)) {
                return this.functions.getValueType();
            }
            return null;
        };

        ValueComparisonOptionModel.prototype.supportSetOperator = function () {
            return _.isFunction(this.functions.setOperator);
        };

        /**
         * pass through method for set comparison operator
         * @param newOperator - new comparison operator to set to
         */
        ValueComparisonOptionModel.prototype.setOperator = function (newOperator) {
            if (this.supportSetOperator()) {
                this.functions.setOperator(newOperator);
            }
        };

        /**
         * pass through method for get current comparison operator
         */
        ValueComparisonOptionModel.prototype.getOperator = function () {
            if (_.isFunction(this.functions.getOperator)) {
                return this.functions.getOperator();
            }
            return null;
        };


        ValueComparisonOptionModel.prototype.supportSetValues = function () {
            return _.isFunction(this.functions.setValues);
        };

        /**
         * pass through method for set value
         * @param newValue - new value to set to, this value could be a date time, number, string, or boolean
         */
        ValueComparisonOptionModel.prototype.setValues = function (newValue) {
            if (this.supportSetValues()) {
                this.functions.setValues(newValue);
            }
        };

        /**
         * pass through method for get value
         * @return value returned is of the specific type, can be date time, number, string or boolean
         */
        ValueComparisonOptionModel.prototype.getValues= function () {
            if (_.isFunction(this.functions.getValues)) {
                return this.functions.getValues();
            }
            return null;
        };

        /***************************************
         * service return call
         ***************************************/
        return ValueComparisonOptionModel;

    });
})();
