/**
 * Created by pravin.gayal on 26-04-2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('uploadToConfigurationServer', function (ngDialog, ConfigurationServer) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/configurationServer/uploadToConfigurationServer.html',
                scope: {
                    title: "@",
                    wsUrl: "@",
                    onSuccessCallback: '='
                },
                link: function ($scope) {

                    $scope.data = {
                        file: null,
                        popupTitle: $scope.title,
                        showConfirmation: false,
                        isSuccess: false,
                        accordionOpen: false
                    };

                    $scope.closePopup = function () {
                        ngDialog.close();
                    };
                    $scope.uploadFile = function () {
                        ConfigurationServer.uploadFile($scope.wsUrl, $scope.data.file,
                            function (success) {
                                $scope.data.isSuccess = true;
                                $scope.data.showConfirmation = true;
                            }, function (error) {
                                $scope.data.response = error;
                                $scope.data.popupTitle = $scope.data.popupTitle + " Error";
                                $scope.data.showConfirmation = true;
                            });
                    };

                    $scope.confirm = function () {
                        $scope.closePopup();
                        if($scope.data.isSuccess){
                            $scope.onSuccessCallback();
                        }
                    };
                }
            };
        });
})();