/**
 * System info service
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('SystemInfoService', function ($http) {
            return {
                getSystemInfo: function() {
                    return $http.get('/api/systemInfo/');
                }
            }

        });

})();
