'use strict';

angular.module('acadiamasterApp')
    .controller('PropertyPossibleValueController', function ($scope, PropertyPossibleValue, ParseLinks) {
        $scope.propertyPossibleValues = [];
        $scope.page = 0;
        $scope.loadAll = function() {
            PropertyPossibleValue.query({page: $scope.page, size: 20}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.propertyPossibleValues = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            PropertyPossibleValue.get({id: id}, function(result) {
                $scope.propertyPossibleValue = result;
                $('#deletePropertyPossibleValueConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            PropertyPossibleValue.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deletePropertyPossibleValueConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.propertyPossibleValue = {value: null, createdOn: null, updatedOn: null, id: null};
        };
    });
