(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('ProgramAssociationService', ['$http', 'localWeb', function ($http, localWeb) {
            return {
                associateRules: function (associationDTO) {
                    return $http.post(localWeb.createUrl('api/programs/associateBusinessRule'), associationDTO);
                },
                associateConsentForm: function (programId, formId) {
                    var data={
                        "programId":programId,
                        "formId":formId
                    };
                    return $http.post(localWeb.createUrl('api/programs/associateconsentform'), data);
                }
            };
        }]);
})();
