(function () {
    'use strict';
    angular.module('acadiamasterApp')
        .service('NavigationNodeExport', function () {
            return {
                exportAllNodes: exportAllNodes
            };

            function exportAllNodes(formMode) {
                var navNodes = formMode.navigationNodes;
                var navNodesToExport = [];
                if (navNodes != null) {
                    for (var i = 0; i < navNodes.length; i++) {
                        var navNode = navNodes[i];
                        var navNodeToExport = {
                            nodeLocalId: navNode.localId,
                            pageLocalId: navNode.page != null ? navNode.page.localId : null,
                            pageName: navNode.page != null ? navNode.page.name : navNode.name,
                            originatesFrom: calculateFromEdges(navNode._edgesTo, true),
                            proceedsTo: calculateFromEdges(navNode._edgesFrom, false),
                            labels: calculateLabels(navNode),
                        };
                        navNodesToExport.push(navNodeToExport);

                    }
                }
                return navNodesToExport;
            }

            function calculateFromEdges(edges, addFromNode) {
                var linkedNodes = '';
                if (edges != null) {
                    for (var i = 0; i < edges.length; i++) {
                        var edge = edges[i];
                        var node = addFromNode ? edge.nodeFrom : edge.nodeTo;
                        linkedNodes += 'N:' + node.localId;
                        linkedNodes += node.page != null ? ' P:' + node.page.localId : '';
                        if (i != edges.length - 1) {
                            linkedNodes += ' | ';
                        }
                    }
                }
                return linkedNodes;
            }

            function calculateLabels(navNode) {
                var labels = '';
                if (navNode.isStartNode()) {
                    labels += 'Start Page';
                }
                if (navNode.isEndNode() === 'yes') {
                    if (labels.length != 0) {
                        labels += ' | ';
                    }
                    labels += 'End Page';
                }
                if (navNode.isEndNode() === 'maybe') {
                    if (labels.length != 0) {
                        labels += ' | ';
                    }
                    labels += 'Potential End Page';
                }
                if (navNode.isPureNavNode()) {
                    if (labels.length != 0) {
                        labels += ' | ';
                    }
                    labels += 'Navigation Branching Page';
                }
                if (labels.length == 0) {
                    labels += navNode._edgesFrom != null && navNode._edgesFrom.length > 1 ? 'Branching Page' : 'Simple Page';
                }
                return labels;
            }
        });
})();

