
angular.module('acadiamasterApp')
    .directive('authenticationKey', SecurityService => ({
        restrict    : 'E',
        templateUrl : 'admin-templates/account/authenticationKey/authenticationKey.html',
        scope       : {
            authenticationKey : '=',
            email             : '=',
            userId            : '=',
        },
        link($scope) {
            $scope.getAuthenticationKeyUrl = () => {
                return `otpauth://totp/Admin_Portal:${$scope.email}?secret=${
                    $scope.authenticationKey
                }&issuer=Admin_Portal&algorithm=SHA1&digits=6&period=30`;
            };

            $scope.resetAuthenticationKey = () => {
                SecurityService.resetAuthenticationKey($scope.userId).then(response => {
                    const newCode = response.data.newKey;
                    if (newCode != null) {
                        $scope.authenticationKey = newCode;
                    }
                });
            };

            $scope.deleteAuthenticationKey = () => {
                SecurityService.deleteAuthenticationKey($scope.userId).then(() => {
                    $scope.authenticationKey = null;
                });
            };
        },
    }));
