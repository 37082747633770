/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for radio selector, drop down and slider
     */
        .directive('formFieldConfigFieldValueCommonSelector', function (AlertService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/commonSelector.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.fieldEntryValueType = {
                        "NUMBER": {name: "NUMBER", icon: 'flaticon-number-circle', text: 'As Number Value'},
                        "STRING": {name: "STRING", icon: 'flaticon-text-small', text: 'As String Value'}
                    };
                    $scope.titlePosition = {
                        "TOP": {name: "TOP", icon: 'flaticon-padding-top', text: 'Top'},
                        "BOTTOM": {name: "BOTTOM", icon: 'flaticon-padding-bottom', text: 'Bottom'}
                    };
                    if ($scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueRadioSelectorModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueDropdownModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueApiDropdownModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueTypeAheadModel) {
                        $scope.position = $scope.constants.position;

                    } else if ($scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueSliderModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueMultiSelectModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueWheelModel
                        || $scope.fieldValue[$scope.constants.classes.classReferenceKey] == $scope.constants.classes.FieldValueImageContainerModel) {
                        $scope.position = $scope.titlePosition;
                    }
                    $scope.valueTypeUpdated = function (valueType) {
                        $scope.fieldElement.possibleFieldValueType = [valueType.name];
                        if (valueType == $scope.constants.fieldEntryValueType.NUMBER) {
                            // perform number check on all the current option values and alert error message on screen if needed
                            var optionValues = $scope.fieldElement.subFields[1].fieldValue.values;

                            var invalidValues = [];
                            _.forEach(optionValues, function (ov) {
                                if (!isNumeric(ov.value)) {
                                    invalidValues.push(ov.value);
                                }
                            });

                            if (invalidValues.length > 0) {
                                AlertService.warning("some of the field values of radio selection are no longer valid<br>" +
                                    invalidValues.join(","), null);

                            }
                        }

                    };
                }
            };

            function isNumeric(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }
        });

})();

