(function () {
    "use strict";
    angular.module("acadiamasterApp").factory("FormEntryAnswerService", function (FieldEntryService) {
        function handleSelectedAnswers(entryField, view) {
            const FIRST_ROW = 60;
            const SECOND_ROW = 150;
            const fieldArray = FieldEntryService.createListFieldEntry(entryField);
            let fieldString = '';
            let index = 0;
            view.showViewMore = false;
            view.showViewLess = false;
            fieldArray.forEach((_field, _index) => {
                if (fieldString.length < FIRST_ROW) {
                    index = _index;
                }
                fieldString += _index > 0 ? `, ${_field}` : _field;
            });

            if (fieldString.length > FIRST_ROW && view.oneLine) {
                view.showViewMore = true;
                return `${fieldString.slice(0, FIRST_ROW)}...`;
            }

            const firstRowItems = fieldArray.slice(0, index + 1);
            const firstRowText = _.join(firstRowItems, ', ');
            if (!view.oneLine && fieldString.length > FIRST_ROW) {
                view.showViewLess = true;
                return firstRowText + '<br>' + '<span class="form__entry__content__selected__answer__second__row">' +
                    fieldString.slice(firstRowText.length, SECOND_ROW) + (fieldString.length > SECOND_ROW ? '...' : '') + '</span>';
            }
            return !fieldString ? '<span class="form__entry__content__not__selected__answer">No Answer selected</span>' : fieldString; 
        }

        return { handleSelectedAnswers };
    });
})();
