(function () {
    'use strict';

    /**
     * directive for link pdf configuration from one version to another
     */
    angular.module('acadiamasterApp').directive('formVersionPdfReplace', function (FormUtilService, Form, AlertService,
        ngDialog, $state) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/pdfReplace/formPDFReplace.html',
            scope: {
                formId: '=',
                versionId: '='
            },
            link: function ($scope) {
                $scope.data = {
                    formWithAllVersions : null, // all the form versions loaded from form id
                    fvFromId : null, // form version where we will copy the pdf configuration from, name only
                    isVersionsLoaded : false, // indicate if all the name only versions of the form has been loaded
                };

                $scope.getFormVersionById = function(versionId) {
                    return _.find($scope.data.formWithAllVersions.formVersions, function (fv) {
                        return fv.id == versionId;
                    });
                };

                $scope.showConfirmation = function() {
                    // validate if the versions are selected
                    if ($scope.data.fvFromId==null) {
                        AlertService.error("form version to copy pdf configuration from is not selected");
                        return;
                    }

                    if ($scope.data.fvFromId === $scope.versionId) {
                        AlertService.error("form version to copy from is the same as the form version to copy to");
                        return;
                    }

                    const confirmData = {
                        versionIdTo: $scope.versionId,
                        versionIdFrom: $scope.data.fvFromId
                    };

                    ngDialog.openConfirm({
                        template: 'admin-templates/site/forms/form/pdfReplace/replaceConfirmation.html',
                        controller: 'FormVersionPDFReplaceConfirmController',
                        data: confirmData
                    }).then(function() {
                        $state.go('^');
                    });
                };

                init($scope);
            }
        };

        /**
         * init function
         * @param $scope
         */
        function init($scope) {
            // load form versions
            var formDetail = Form.get({
                id: $scope.formId, projection: Form.projections.OVERVIEW
            });

            formDetail.$promise.then(function(data) {
                var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                formObject.fromDto(data);
                $scope.data.formWithAllVersions = formObject;

                $scope.data.isVersionsLoaded = true;
            });
        }
    });
})();

