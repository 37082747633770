/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    angular.module('acadiamasterApp')
        .controller('ProgramTestSuiteDeleteController', ($scope, ngDialog, ProgramTestSuiteService) => {
            $scope.delete = function () {
                ProgramTestSuiteService.deleteTestSuite($scope.ngDialogData.id)
                    .then(() => {
                        $scope.confirm($scope.ngDialogData.id);
                    });
            };
        });
}());
