(function () {
    'use strict';

    /**
     * directive for value config option creation
     */
    angular.module('acadiamasterApp').factory('ValueConfigOptionService', function () {
        return {
            createValueConfigOption: createValueConfigOption
        };

        /**
         * create a value config option from parent field value and subfield's field value
         * @param parentFieldValue - parent field value include value type information (we don't pass in the
         *    value type directly because it might be changed, but the field value object won't be changed)
         * @param subFieldFieldValue - subfield's field value
         * @returns {{getValueType: ValueConfigOptionService.getValueType, getValue: ValueConfigOptionService.getValue, setValue: ValueConfigOptionService.setValue}}
         */
        function createValueConfigOption(parentFieldValue, subFieldFieldValue) {
            return {
                getValueType: function () {
                    return parentFieldValue.valueType;
                },

                getValue: function (index) {
                    var fv = subFieldFieldValue;
                    var optionValue = fv.values[index];

                    if (optionValue == null) {
                        return null;
                    }

                    return optionValue.value;
                },

                setValue: function (index, newValue) {
                    var fv = subFieldFieldValue;
                    var optionValue = fv.values[index];

                    if (optionValue == null) {
                        console.log('unable to find option value with index : ' + index);
                        return;
                    }

                    optionValue.value = newValue;
                }
            };
        }
    });
})();