/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for display config
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultDisplayConfigService', function (CompareResultUtilService, vbrCommonUtil) {

        /**
         * convert a font object to String
         * @param font - font object
         * @return {string} - string representation of the font
         */
        function fontToString(font) {
            if (vbrCommonUtil.isUnavailableOnly(font)) {
                return "N/A";
            }

            if (font==null) {
                return null;
            }

            var styles = [];

            if (!vbrCommonUtil.isNullOrUnavailable(font.fontName) && font.fontName.trim().length > 0) {
                styles.push(font.fontName);
            }

            if (!vbrCommonUtil.isNullOrUnavailable(font.fontSize) && font.fontSize > 0) {
                styles.push('<span class="badge">' + font.fontSize + 'pt</span>');
            }

            if (!vbrCommonUtil.isNullOrUnavailable(font.fontStyle)) {
                if (!font.fontStyle.inherit) {
                    if (font.fontStyle.bold) {
                        styles.push('<b>bold</b>');
                    }
                    if (font.fontStyle.italic) {
                        styles.push('<i>italic</i>');
                    }
                }
            }

            if (styles.length==0) {
                return "DEFAULT FONT";
            }
            else {
                return styles.join(", ");
            }
        }


        /**
         * compare two display config object
         * @param dc1 - display config 1
         * @param dc2 - display config 2
         * @returns {{CompareResult}} - returning a map of Compare Result object
         */
        function compare(dc1, dc2) {
            // some basic error handling to avoid null pointer exception
            if (vbrCommonUtil.isNullOrUnavailable(dc1)) {
                dc1 = {};
            }

            if (vbrCommonUtil.isNullOrUnavailable(dc2)) {
                dc2 = {};
            }

            var result = {};

            var sizeToString = CompareResultUtilService.sizeToString;
            var boxModelToString = CompareResultUtilService.boxModelToString;
            var gravityToString = CompareResultUtilService.gravityToString;

            // compare visibility
            CompareResultUtilService.comparePrimitivesByKey(dc1, dc2, "visible", result);

            // compare editable
            CompareResultUtilService.comparePrimitivesByKey(dc1, dc2, "editable", result);

            // compare gravity
            if (!vbrCommonUtil.isNullOrUnavailable(dc1.gravity) || !vbrCommonUtil.isNullOrUnavailable(dc2.gravity)) {
                result.gravity = CompareResultUtilService.comparePrimitives(gravityToString(dc1.gravity), gravityToString(dc2.gravity));
            }

            // compare layout orientation
            CompareResultUtilService.compareNamedObjectsByKey(dc1, dc2, "layoutOrientation", result);

            // compare layout gravity
            if (!vbrCommonUtil.isNullOrUnavailable(dc1.layoutGravity) || !vbrCommonUtil.isNullOrUnavailable(dc2.layoutGravity)) {
                result.layoutGravity = CompareResultUtilService.comparePrimitives(gravityToString(dc1.layoutGravity), gravityToString(dc2.layoutGravity));
            }

            // compare color
            CompareResultUtilService.comparePrimitivesByKey(dc1, dc2, "color", result);

            // compare background color
            CompareResultUtilService.comparePrimitivesByKey(dc1, dc2, "bgColor", result);

            // -- none generic comparisons on more complex objects

            // compare font
            if (!vbrCommonUtil.isUnavailable(dc1.font) || !vbrCommonUtil.isUnavailable(dc2.font)) {
                result.font = CompareResultUtilService.comparePrimitives(fontToString(dc1.font), fontToString(dc2.font));
            }

            // compare size
            if (!vbrCommonUtil.isUnavailable(dc1.width) || !vbrCommonUtil.isUnavailable(dc2.width) || !vbrCommonUtil.isUnavailable(dc1.height) || !vbrCommonUtil.isUnavailable(dc2.height)) {
                result.size = CompareResultUtilService.comparePrimitives(sizeToString(dc1.width, dc1.height), sizeToString(dc2.width, dc2.height));
            }

            // compare margin
            if (!vbrCommonUtil.isUnavailable(dc1.margin) || !vbrCommonUtil.isUnavailable(dc2.margin)) {
                result.margin = CompareResultUtilService.comparePrimitives(boxModelToString(dc1.margin), boxModelToString(dc2.margin));
            }

            // compare padding
            if (!vbrCommonUtil.isUnavailable(dc1.padding) || !vbrCommonUtil.isUnavailable(dc2.padding)) {
                result.padding = CompareResultUtilService.comparePrimitives(boxModelToString(dc1.padding), boxModelToString(dc2.padding));
            }

            result._status = CompareResultUtilService.collectStatus(result);

            return result;
        }


        return {
            compare: compare
        };
    });

})();

