/**
 * utility service for navigation configuration
 */
(function () {

    'use strict';
    angular.module('acadiamasterApp').service('FormNavigationConfigUtil',
        function (ExpressionConstantsService, ngDialog, FormNavigationEdgeModel, VbrSelectorService) {

            /*********************************************************************************************************************
             * Private functions
             *********************************************************************************************************************/

            /**
             * move edge up in the evaluation order
             * @param edge
             */
            function moveEdgeUp(edge) {
                var edgeList = edge.nodeFrom._edgesFrom;

                var index = _.findIndex(edgeList, function(e) {
                    return e==edge;
                });

                var edgeAbove = edgeList[index-1];
                var temp = edge.evaluationOrder;
                edge.evaluationOrder = edgeAbove.evaluationOrder;
                edgeAbove.evaluationOrder = temp;

                edge.nodeFrom._edgesFrom = _.sortBy(edgeList, ['evaluationOrder']);
            }


            /**
             * move edge down in the evaluation order
             * @param edge
             */
            function moveEdgeDown(edge) {
                var edgeList = edge.nodeFrom._edgesFrom;

                var index = _.findIndex(edgeList, function(e) {
                    return e==edge;
                });

                var edgeBelow = edgeList[index+1];
                var temp = edge.evaluationOrder;
                edge.evaluationOrder = edgeBelow.evaluationOrder;
                edgeBelow.evaluationOrder = temp;

                edge.nodeFrom._edgesFrom = _.sortBy(edgeList, ['evaluationOrder']);
            }


            /**
             * show a window to add navigation node with some preset value
             * @param nodeFrom - node from preset
             * @param nodeTo - node to preset
             * @param formModeWrapper - form mode wrapper
             */
            function showAddNavigationEdgeWindow(nodeFrom, nodeTo, formModeWrapper) {
                var template = '<navigation-edge-edit edge="navigationEdge" nodes="nodes" close-function="closeFunction"></navigation-edge-edit>';

                // build an object to hold reference, otherwise, we might have issue with null objects
                var navigationEdge = new FormNavigationEdgeModel(formModeWrapper);
                // set this to a very high number so it is always added at the end
                navigationEdge.evaluationOrder = 10000;
                navigationEdge.nodeFrom = nodeFrom;
                navigationEdge.nodeTo = nodeTo;

                // open modal window and pass in all the scope variables
                var dialog = ngDialog.open({
                    showClose: false,
                    closeByEscape : false,
                    closeByDocument : false,
                    template: template,
                    plain: true,
                    controller : ['$scope', function($scope) {
                        $scope.navigationEdge = navigationEdge;
                        $scope.nodes = formModeWrapper.navigationNodes;

                        $scope.closeFunction = function (needToSave) {
                            if (needToSave) {
                                // add edge to model
                                formModeWrapper.addNavigationEdge(navigationEdge);
                            }
                            dialog.close();
                        };
                    }],
                    className : 'ngdialog-theme-plain custom-width-medium'
                });
            }

            /**
             * editing an edge
             * @param edge - edge to be edited
             * @param formModeWrapper - form mode wrapper
             */
            function showEditNavigationEdgeWindow(edge, formModeWrapper) {
                var template = '<navigation-edge-edit edge="navigationEdge" nodes="nodes" close-function="closeFunction"></navigation-edge-edit>';

                // save the node from and to before they are modified
                var nodeFrom = edge.nodeFrom;
                var nodeTo = edge.nodeTo;

                // open modal window and pass in all the scope variables
                var dialog = ngDialog.open({
                    showClose: false,
                    closeByEscape : false,
                    closeByDocument : false,
                    template: template,
                    plain: true,
                    className : 'ngdialog-theme-plain custom-width-medium',
                    controller : ['$scope', function($scope) {
                        $scope.navigationEdge = edge;
                        $scope.nodes = formModeWrapper.navigationNodes;

                        $scope.closeFunction = function (needToSave) {
                            if (needToSave && (nodeFrom != edge.nodeFrom || nodeTo !=edge.nodeTo)) {
                                // update edge if nodeFrom has changed and need to save
                                formModeWrapper.updateNavigationEdge(edge);
                            }
                            dialog.close();
                        };
                    }]
                });
            }


            /**
             * get edge description starting, something like
             * * first edge -- if (xxx)
             * * non first and non last -- else if
             * * last one -- else or else if depending on if there is a condition
             * * non last one with no condition -- red else with warning
             * @param edge - edge to configure
             * @param totalEdges - total edges
             * @returns {*}
             */
            function getEdgeDescriptionStart(edge, totalEdges) {
                var prefix;

                var hasCondition = edge.hasCondition();
                var isFirst = edge.evaluationOrder==0;
                var isLast = edge.evaluationOrder==totalEdges-1;
                var edgeName = edge.name;
                var divClass = '';

                if (edgeName==null || edgeName.trim().length==0) {
                    edgeName = hasCondition ? 'Condition ' + edge.evaluationOrder : '**TRUE**';
                }

                // if first edge
                if (isFirst) {
                    prefix = "if";
                }
                else if (hasCondition) {
                    prefix = 'else if';
                }
                else { // is last or no condition
                    prefix = 'else';
                }

                var errorMessage = '';

                if (!hasCondition && !isLast) {
                    // no condition and not the last node, something is wrong
                    errorMessage = '<br><div class="alert alert-danger"> this edge has no condition, it should be the last one in the evaluation order</div>';
                    divClass += 'badge-danger';
                }
                else if (hasCondition) {
                    divClass += 'badge-info';
                }
                else {
                    divClass += 'badge-success';
                }

                return '<span class="badge ' + divClass + '">' + prefix + ' (' + edgeName + ')</span>' + errorMessage;
            }


            /**
             * get the end of the edge description
             * @param edge - edge to get description from
             * @returns {string} - a string describe where the edge will go, ie: goto Node 3
             */
            function getEdgeDescriptionEnd(edge) {
                var nodeTo = edge.nodeTo;
                return "<div>Goto ***" +
                    nodeTo.getName() +
                    "***</div>" ;
            }

            /**
             * goto node to in the edge
             * @param edge -- edge object
             * @param selector - selector
             * @param formMode - form mode
             */
            function goToEdgeNodeTo(edge, selector, formMode) {
                gotoNode(edge.nodeTo, selector, formMode);
            }

            /**
             * goto node from in the edge
             * @param edge -- edge object
             * @param selector - selector
             * @param formMode - form mode
             */
            function goToEdgeNodeFrom(edge, selector, formMode) {
                gotoNode(edge.nodeFrom, selector, formMode);
            }

            function gotoNode(node, selector, formMode) {
                var target = null;
                // if the edge is not coming from anything at all, return

                if (node!=null) {
                    if (node.page==null) {
                        // no page associated with it, it's a conditional node, go to the node listing section
                        target = formMode._nodesAndEdges;
                    }
                    else {
                        target = node.page;
                    }
                }

                if (target!=null) {
                    selector.selectItem(target, true, true);

                    if (node.page==null) {
                        // scroll to the node item, check for dom existence every 300 ms and up to 100 times
                        // (up to 30 seconds)
                        VbrSelectorService.scrollToItem(node, 300, 100);
                    }
                }
            }

            /***************************************
             * service return call
             ***************************************/

            return {
                moveEdgeUp : moveEdgeUp,
                moveEdgeDown : moveEdgeDown,
                showAddNavigationEdgeWindow : showAddNavigationEdgeWindow,
                showEditNavigationEdgeWindow : showEditNavigationEdgeWindow,
                getEdgeDescriptionStart : getEdgeDescriptionStart,
                getEdgeDescriptionEnd : getEdgeDescriptionEnd,
                goToEdgeNodeTo : goToEdgeNodeTo,
                goToEdgeNodeFrom : goToEdgeNodeFrom
            };
        });
})();