(function () {
    'use strict';

    /**
     * service for a number of utility functions for form scripts
     */
    angular.module('acadiamasterApp').factory('FormScriptActionUtil', function (FormModelServiceValueWithUnit,
                                                                                FormModelServiceFontStyle, FormModelServiceFont,
                                                                                vbrCommonUtil, FormModelServiceGravity, FormConstants) {
        var constants = FormConstants;
        return {
            // commonly used colors
            colors: {
                colorBlack80: 'black80', // #333333
                colorBluper: '#262261',
                colorWhite: 'white'
            },

            // common used font names
            fontNames: {
                notoSans : '"Noto Sans", sans-serif',
                notoSansBold : '"Noto Sans Bold", sans-serif',
                montserratMedium: '"Montserrat-Medium", sans-serif',
                montserratBold: '"Montserrat-Bold", sans-serif'
            },

            // -- random functions
            findFieldBefore: findFieldBefore,
            findSubFieldsByTypeHasTextLabelAbove: findSubFieldsByTypeHasTextLabelAbove,

            // -- find by type functions
            findFieldsByType: findFieldsByType,
            findSubFieldsByType: findSubFieldsByType,
            findComponentsInMode: findComponentsInMode,
            findSectionsInMode: findSectionsInMode,

            // -- update functions
            updatePadding: updatePadding,
            updateMarginPadding: updateMarginPadding,
            updateFontStyle: updateFontStyle,
            updateFontName: updateFontName,
            updateFontSize: updateFontSize,
            updateColor: updateColor,
            updateBgColor: updateBgColor,
            updateGravity: updateGravity,
            updateStyleType: updateStyleType,
            updateWidth: updateWidth,
            updateVisibility: updateVisibility,

            // -- check visibility
            isVisible: isVisible,

            // -- check font info
            isFontStyleBold: isFontStyleBold,
            isFontStyleNormal: isFontStyleNormal,
            isFontStyleInherit: isFontStyleInherit,
            isFontSizeEqual: isFontSizeEqual,
            isFontNameEqual: isFontNameEqual,

            // -- check padding info
            isPaddingTopEqual: isPaddingTopEqual,
            isPaddingBottomEqual: isPaddingBottomEqual,
            isPaddingLeftEqual: isPaddingLeftEqual,
            isPaddingRightEqual: isPaddingRightEqual,
            isMarginPaddingEqual: isMarginPaddingEqual,


            // -- check gravity
            isGravityV: isGravityV,
            isGravityH: isGravityH,

            // -- check color or bg color info
            isColorEqual: isColorEqual,
            isBgColorEqual: isBgColorEqual,

            // -- Style type functions
            isStyleTypeEqual: isStyleTypeEqual,


            // -- check field value info
            isReferenceFieldDisplayStyleEqual : isReferenceFieldDisplayStyleEqual,

            // -- check for font
            isDisplayConfigHavingFont: isDisplayConfigHavingFont,
            updateFont: updateFont,
            isDisplayConfigHavingFontExact: isDisplayConfigHavingFontExact,
        };

        /***************************************************************
         * private functions
         **************************************************************/
        /**
         * find all the fonts with contains
         */
        function isDisplayConfigHavingFont(displayConfig, fontName) {
            if (displayConfig && displayConfig.font && displayConfig.font.fontName) {
                return _.includes(displayConfig.font.fontName, fontName);
            }
            return false;
        }

        function isDisplayConfigHavingFontExact(displayConfig, fontName) {
            if (displayConfig && displayConfig.font && displayConfig.font.fontName) {
                return displayConfig.font.fontName === fontName;
            }
            return false;
        }

        function updateFont(displayConfig, fontName, fontToBeUpdated) {
            if (displayConfig && displayConfig.font && displayConfig.font.fontName) {
                displayConfig.font.fontName = _.replace(displayConfig.font.fontName, new RegExp(fontName, 'g'), fontToBeUpdated);
            }
        }
        /**
         * find all the fields in a form mode by field type
         * @param formMode - form mode object
         * @param fieldType - field type we are looking for, if this is null, then all the fields will be returned
         * @returns {Array} - list of the fields in the form mode that matches the field type
         */
        function findFieldsByType(formMode, fieldType) {
            var fields = [];

            if (formMode == null) {
                return [];
            }

            _.forEach(formMode.pages, function (page) {
                _.forEach(page.sections, function (section) {
                    _.forEach(section.formComponents, function (component) {
                        _.forEach(component.formComponentFields, function (field) {
                            if (fieldType == null || field.type == fieldType) {
                                fields.push(field);
                            }
                        });
                    });
                });
            });


            return fields;
        }

        /**
         * Get all components in form mode
         */
        function findComponentsInMode(formMode) {
            var components = [];

            if (formMode == null) {
                return [];
            }
            _.forEach(formMode.pages, function (page) {
                _.forEach(page.sections, function (section) {
                    _.forEach(section.formComponents, function (component) {
                        components.push(component);
                    });
                });
            });
            return components;
        }

        /**
         * Get all sections in form mode
         */
        function findSectionsInMode(formMode) {
            var sections = [];

            if (formMode == null) {
                return [];
            }
            _.forEach(formMode.pages, function (page) {
                _.forEach(page.sections, function (section) {
                    sections.push(section);
                });
            });
            return sections;
        }

        /**
         * find all the sub fields in a form mode by sub field type
         * @param formMode - form mode object
         * @param subFieldType - sub field type we are looking for, if this is null, then all the sub fields will be returned
         * @returns {Array} - list of the sub fields in the form mode that matches the field type
         */
        function findSubFieldsByType(formMode, subFieldType) {
            var subFields = [];

            if (formMode == null) {
                return [];
            }

            _.forEach(formMode.pages, function (page) {
                _.forEach(page.sections, function (section) {
                    _.forEach(section.formComponents, function (component) {
                        _.forEach(component.formComponentFields, function (field) {
                            if (field.subFields != null) {
                                _.forEach(field.subFields, function (sf) {
                                    if (subFieldType == null || sf.type == subFieldType) {
                                        subFields.push(sf);
                                    }
                                });
                            }
                        });
                    });
                });
            });


            return subFields;
        }


        /**
         * check if the font size matches
         * @param displayConfig - font object
         * @param fontSize - font size, null == 14
         * @returns {boolean}
         */
        function isFontSizeEqual(displayConfig, fontSize) {
            // no display config or no color specified
            if (displayConfig == null || displayConfig.font == vbrCommonUtil.UNAVAILABLE || displayConfig.font.fontSize == null) {
                return fontSize == null || fontSize == 14;
            }

            if (fontSize == null) {
                return displayConfig.font.fontSize == 14;
            }

            return displayConfig.font.fontSize == fontSize;
        }

        /**
         * find the field before current field under the same component
         * @param currentField - current field
         * @returns {*}  the field before current field under the same component, if it can't be found, return null
         */
        function findFieldBefore(currentField) {
            if (currentField == null) {
                return null;
            }

            var component = currentField._parent;
            var index = _.findIndex(component.formComponentFields, function (f) {
                return f === currentField;
            });

            if (index <= 0) {
                return null;
            }
            else {
                return component.formComponentFields[index - 1];
            }
        }

        /**
         * find all the sub fields that its parent field has a text label field just above it
         * @param formMode - form mode
         * @param subFieldType - sub field type
         * @param hasTextLabelAbove - whether we require a text label above the sub field's parent
         * @returns {Array} - a list of sub fields that satisfied the condition
         */
        function findSubFieldsByTypeHasTextLabelAbove(formMode, subFieldType, hasTextLabelAbove) {
            var subFields = findSubFieldsByType(formMode, subFieldType);

            subFields = _.filter(subFields, function (sf) {
                var fieldBefore = findFieldBefore(sf._parent);

                // has to have a text label above
                if (hasTextLabelAbove) {
                    return fieldBefore != null && fieldBefore.type == constants.fieldsType.TEXT_LABEL;
                }
                // can not have a text label above
                else {
                    return fieldBefore == null || fieldBefore.type != constants.fieldsType.TEXT_LABEL;
                }

            });

            return subFields;
        }


        function isVisible(displayConfig) {
            return displayConfig && displayConfig.visible;
        }

        /**
         * check if the font style is bold or not
         * @param displayConfig
         * @returns {*|string[]|string|string}
         */
        function isFontStyleBold(displayConfig) {
            return displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && displayConfig.font.fontStyle.bold;
        }

        /**
         * check if the font style is normal or not
         * @param displayConfig
         * @returns {*|string[]|string|string}
         */
        function isFontStyleNormal(displayConfig) {
            return displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && displayConfig.font.fontStyle.normal;
        }

        /**
         * check if the font style is inherit or not
         * @param displayConfig
         * @returns {*|string[]|string|string}
         */
        function isFontStyleInherit(displayConfig) {
            return displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && displayConfig.font.fontStyle.inherit;
        }

        /**
         * check if the display config's top padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         */
        function isPaddingTopEqual(displayConfig, size) {
            return isMarginPaddingEqual(displayConfig.padding, 'top', size);
        }

        /**
         * check if the display config's bottom padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         */
        function isPaddingBottomEqual(displayConfig, size) {
            return isMarginPaddingEqual(displayConfig.padding, 'bottom', size);
        }

        /**
         * check if the display config's left padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         */
        function isPaddingLeftEqual(displayConfig, size) {
            return isMarginPaddingEqual(displayConfig.padding, 'left', size);
        }

        /**
         * check if the display config's right padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         */
        function isPaddingRightEqual(displayConfig, size) {
            return isMarginPaddingEqual(displayConfig.padding, 'right', size);
        }

        /**
         * check if the color in display config matches the colorToMatch
         * @param displayConfig - display config object
         * @param colorToMatch - color to match for, black80 == #333333 == null
         */
        function isColorEqual(displayConfig, colorToMatch) {
            function isDefaultColor(color) {
                if (color==null) {
                    return true;
                }

                var lowerCaseColor = color.toLowerCase();
                return lowerCaseColor === 'black80' || lowerCaseColor === '#333333';
            }

            // if it's default color
            if (isDefaultColor(colorToMatch)) {
                return isDefaultColor(displayConfig.color);
            }

            return displayConfig.color == colorToMatch;
        }

        /**
         * check if the background color in display config matches the colorToMatch
         * @param displayConfig - display config object
         * @param colorToMatch - color to match for
         */
        function isBgColorEqual(displayConfig, colorToMatch) {
            function isDefaultBgColor(color) {
                if (color==null) {
                    return true;
                }

                var lowerCaseColor = color.toLowerCase();
                return lowerCaseColor === 'white' || lowerCaseColor === '#ffffff';
            }

            // if it's default color
            if (isDefaultBgColor(colorToMatch)) {
                return isDefaultBgColor(displayConfig.bgColor);
            }

            return displayConfig.bgColor == colorToMatch;
        }

        /**
         * check if font name is equal to a specific font name
         */
        function isFontNameEqual(displayConfig, fontName) {
            function isDefaultFontName(fontName) {
                if (fontName==null) {
                    return true;
                }

                return fontName === '"Noto Sans", Sans-serif';
            }

            // if it's default color
            if (isDefaultFontName(fontName)) {
                return isDefaultFontName(displayConfig.font.fontName);
            }

            return displayConfig.font.fontName == fontName;
        }

        /**
         * check if the reference field display style in field value is equal to the one provided
         * @param fieldValue - field value object
         * @param referenceDisplayStyle - referenceDisplayStyle value
         */
        function isReferenceFieldDisplayStyleEqual(fieldValue, referenceDisplayStyle) {
            if (fieldValue == null || fieldValue.referenceFieldDisplayStyle==null) {
                return referenceDisplayStyle == null;
            }

            return fieldValue.referenceFieldDisplayStyle == referenceDisplayStyle;
        }

        /**
         * check if the vertical gravity defined in the display config is the same as the gravity value input
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function isGravityV(displayConfig, gravityValue) {
            if (displayConfig==null || displayConfig.gravity==null || displayConfig.gravity.vertical==null) {
                return gravityValue == null;
            }

            return gravityValue == displayConfig.gravity.vertical;
        }

        /**
         * check if the horizontal gravity defined in the display config is the same as the gravity value input
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function isGravityH(displayConfig, gravityValue) {
            if (displayConfig==null || displayConfig.gravity==null || displayConfig.gravity.horizontal==null) {
                return gravityValue == null;
            }

            return gravityValue == displayConfig.gravity.horizontal;
        }

        /**
         * Check if the style type in field value matches the required style type
         * @param fieldValue - field value object
         * @param styleType - style type value
         * @returns {boolean}
         */
        function isStyleTypeEqual(fieldValue, styleType) {
            return fieldValue.styleType != null && fieldValue.styleType == styleType;
        }

        // ------------------------------------- update functions ----------------------------------
        /**
         * update font name to a new font name
         */
        function updateFontName(displayConfig, newFontName) {
            if (displayConfig && displayConfig.font) {
                displayConfig.font.fontName = newFontName;
            }
        }

        /**
         * update font size to a new value
         */
        function updateFontSize(displayConfig, newFontSize) {
            if (displayConfig && displayConfig.font) {
                displayConfig.font.fontSize = newFontSize;
            }
        }

        /**
         * updating the padding value, if any of the value is null, then assume it is unchanged
         * @param displayConfig - display config object
         * @param top - top value
         * @param bottom - bottom value
         * @param left - left value
         * @param right - right value
         */
        function updatePadding(displayConfig, top, bottom, left, right) {
            if (displayConfig == null) {
                return;
            }
            return updateMarginPadding(displayConfig.padding, top, bottom, left, right);
        }

        function updateMarginPadding(currentModel, top, bottom, left, right) {
            if (currentModel == null || currentModel == vbrCommonUtil.UNAVAILABLE) {
                return;
            }

            // create a existing config to be filled in later
            var existingConfig = {
                left: new FormModelServiceValueWithUnit.ValueWithUnitModel(0, 'dp'),
                right: new FormModelServiceValueWithUnit.ValueWithUnitModel(0, 'dp'),
                top: new FormModelServiceValueWithUnit.ValueWithUnitModel(0, 'dp'),
                bottom: new FormModelServiceValueWithUnit.ValueWithUnitModel(0, 'dp')
            };

            if (currentModel.simpleConfig != null) {
                // copy over the value to each one
                copyValueWithUnit(existingConfig.left, currentModel.simpleConfig);
                copyValueWithUnit(existingConfig.right, currentModel.simpleConfig);
                copyValueWithUnit(existingConfig.top, currentModel.simpleConfig);
                copyValueWithUnit(existingConfig.bottom, currentModel.simpleConfig);
            }
            else if (currentModel.advancedConfig != null) {
                copyValueWithUnit(existingConfig.left, currentModel.advancedConfig.left);
                copyValueWithUnit(existingConfig.right, currentModel.advancedConfig.right);
                copyValueWithUnit(existingConfig.top, currentModel.advancedConfig.top);
                copyValueWithUnit(existingConfig.bottom, currentModel.advancedConfig.bottom);
            }

            // setting the config style to be advanced and fill in the value as necessary
            currentModel.configType = FormConstants.marginPaddingType.ADVANCED;
            currentModel.simpleConfig = null;

            currentModel.advancedConfig = {
                left: left == null ? existingConfig.left : new FormModelServiceValueWithUnit.ValueWithUnitModel(left, 'dp'),
                right: right == null ? existingConfig.right : new FormModelServiceValueWithUnit.ValueWithUnitModel(right, 'dp'),
                top: top == null ? existingConfig.top : new FormModelServiceValueWithUnit.ValueWithUnitModel(top, 'dp'),
                bottom: bottom == null ? existingConfig.bottom : new FormModelServiceValueWithUnit.ValueWithUnitModel(bottom, 'dp')
            };
        }


        /**
         * update font style of a display config
         * @param displayConfig - display config to modify
         * @param inherit - flag for inherit
         * @param normal - flag for normal
         * @param bold - flag for bold
         * @param italic - flag for italic
         */
        function updateFontStyle(displayConfig, inherit, normal, bold, italic) {
            // no display config or no color specified
            if (displayConfig == null) {
                return false;
            }

            if (displayConfig.font == null || displayConfig.font == vbrCommonUtil.UNAVAILABLE) {
                displayConfig.font = new FormModelServiceFont.FontModel();
            }

            if (displayConfig.font.fontStyle == null) {
                displayConfig.font.fontStyle = new FormModelServiceFontStyle.FontStyleModel();
            }

            var fontStyle = displayConfig.font.fontStyle;

            fontStyle.inherit = inherit;
            fontStyle.normal = normal;
            fontStyle.bold = bold;
            fontStyle.italic = italic;
        }

        /**
         * Update color in display config
         * @param displayConfig - display config to modify
         * @param color - color to set
         */
        function updateColor(displayConfig, color) {
            if (displayConfig == null) {
                return false;
            }
            displayConfig.color = color;
        }

        /**
         * Update visibility in display config
         * @param displayConfig - display config to modify
         * @param newVisibility {bool} - new visibility, true or false
         */
        function updateVisibility(displayConfig, newVisibility) {
            if (displayConfig == null) {
                return false;
            }
            displayConfig.visible = !!newVisibility;
        }

        /**
         * Update background color in display config
         * @param displayConfig - display config to modify
         * @param bgColor - color to set
         */
        function updateBgColor(displayConfig, bgColor) {
            if (displayConfig == null) {
                return false;
            }
            displayConfig.bgColor = bgColor;
        }

        /**
         * Update gravity in display config
         * @param displayConfig - display config to modify
         * @param hGravity - horizontal gravity (use values from constant file)
         * @param vGravity - vertical gravity (use values from constant file)
         */
        function updateGravity(displayConfig, hGravity, vGravity) {
            if (displayConfig == null) {
                return false;
            }
            displayConfig.gravity = new FormModelServiceGravity.GravityModel(hGravity, vGravity);

        }

        /**
         * Update style type in field value
         * @param fieldValue - field value object to modify
         * @param styleType - style type (use values from constant file)
         */
        function updateStyleType(fieldValue, styleType) {
            if (fieldValue == null) {
                return false;
            }
            fieldValue.styleType = styleType;
        }

        /**
         * Update width of the display config
         * @param displayConfig - display config to modify
         * @param widthValue - Value for width
         * @param widthUnit - Unit for width (Use dp or percent)
         */
        function updateWidth(displayConfig, widthValue, widthUnit) {
            if (displayConfig == null) {
                return false;
            }
            displayConfig.width = new FormModelServiceValueWithUnit.ValueWithUnitModel(widthValue, widthUnit);

        }



        // -------------------------------------- private functions --------------------------------

        /**
         * check if the value in the value with unit object matches the value to match
         * @param valueWithUnitObject
         * @param valueToMatch
         */
        function isValueEqual(valueWithUnitObject, valueToMatch) {
            if (valueWithUnitObject == null || valueWithUnitObject.value == null || valueWithUnitObject.value == 0) {
                return valueToMatch == null || valueToMatch == 0;
            }

            return valueWithUnitObject.value == valueToMatch;
        }

        /**
         * is margin/padding equal to a specific value
         * ps. null == 0
         * @param marginPaddingModel - Margin/Padding model
         * @param paddingDirection - padding direction: left|right|top|bottom
         * @param size - size, null == 0 here
         * @returns {*} - true if the padding value specified in the model is equal to the input size
         */
        function isMarginPaddingEqual(marginPaddingModel, paddingDirection, size) {
            if (marginPaddingModel == null || marginPaddingModel == vbrCommonUtil.UNAVAILABLE) {
                return size == null || size == 0;
            }

            if (marginPaddingModel.simpleConfig != null) {
                return isValueEqual(marginPaddingModel.simpleConfig, size);
            }
            else if (marginPaddingModel.advancedConfig != null) {
                return isValueEqual(marginPaddingModel.advancedConfig[paddingDirection], size);
            }
            else {
                return size == null;
            }
        }



        /**
         * copy the value and unit from copyFrom to copyTo
         * @param copyTo - copyTo object
         * @param copyFrom - copyFrom object
         */
        function copyValueWithUnit(copyTo, copyFrom) {
            if (copyTo == null || copyFrom == null) {
                return;
            }
            copyTo.value = copyFrom.value;
            copyTo.unit = copyFrom.unit;
        }


    });

})();
