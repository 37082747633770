(function () {
    'use strict';

    /**
     * script action to update font type to inherit
     */
    angular.module('acadiamasterApp').factory('UpdateFontTypeToInheritScriptAction', function (ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        // defining some local variable to make things easier

        var fsa = FormScriptActionUtil;
        var constants = FormConstants;

        /***************************************************************
         * private function
         ***************************************************************/
        /**
         * check if font name is null
         * @param displayConfig
         * @returns {boolean}
         */
        function isFontNameNull(displayConfig) {
            return displayConfig==null || displayConfig.font==null ||
                displayConfig.font.fontName==null;
        }

        /**
         * apply font name to null
         * @param target - target
         */
        function applyFontNameToNull(target) {
            if (target && target.displayConfig) {
                fsa.updateFontName(target.displayConfig, null);
            }
        }


        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateFontTypeToInheritScriptAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateFontTypeToInheritScriptAction() {
            this.name = 'Update Font Type To Inherit For all text label and title sub field';
            this.ticketId = 'AC-24073';

            this.steps = [
                {
                    description: 'find all text field and font name not null --> ' + 'font name : null',
                    searchFunction: function (formMode) {
                        var targets = fsa.findFieldsByType(formMode, constants.fieldsType.TEXT_LABEL);

                        // filter out object that's already has font name = null
                        targets = _.filter(targets, function (f) {
                            return !isFontNameNull(f.displayConfig);
                        });

                        return targets;
                    },
                    changeFunction: applyFontNameToNull
                },
                {
                    description: 'find all title sub field and font name not null --> ' + 'font name : null',
                    searchFunction: function (formMode) {
                        var targets = fsa.findSubFieldsByType(formMode, constants.subFieldsType.TITLE);

                        // filter out object that's already has font name = null
                        targets = _.filter(targets, function (f) {
                            return !isFontNameNull(f.displayConfig);
                        });

                        return targets;
                    },
                    changeFunction: applyFontNameToNull
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateFontTypeToInheritScriptAction;
    });

})();
