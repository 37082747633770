
angular.module('acadiamasterApp')
    .factory('AuditsService', ($http, localWeb) => ({
        findAll () {
            return $http.get(localWeb.createUrl('api/audits/')).then(response => response.data);
        },
        findByUserId (userId) {
            const params = {
                userId,
            };

            return $http.get('api/audits/loginEvent', { params }).then(response => response.data);
        },
        findByDates (fromDate, toDate, page) {
            const formatDate = function (dateToFormat) {
                if (dateToFormat !== undefined && !angular.isString(dateToFormat)) {
                    return `${dateToFormat.getYear()}-${dateToFormat.getMonth()}-${dateToFormat.getDay()}`;
                }
                return dateToFormat;
            };

            return $http.get(localWeb.createUrl('api/audits/'), {
                params : {
                    fromDate : formatDate(fromDate),
                    toDate   : formatDate(toDate),
                    page,
                },
            }).then(response => response);
        },
    }));
