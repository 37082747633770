(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('programTestElementConfigMain', function (ProgramTestConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/programTestConfigMain.html',
                scope: {
                    element: '='
                },
                link: function ($scope) {
                    $scope.constant = ProgramTestConstantsService;
                    $scope.testCase = $scope.element;
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/

        });

})();



