/**
 * Created by Jamie Nola on 04/19/2019
 */
(function () {
    angular.module('acadiamasterApp').directive('correctiveActionCreateOrder',
        () => {
            return {
                bindToController : true,
                controller       : 'createOrderConfigController',
                controllerAs     : 'co',
                restrict         : 'E',
                scope            : {
                    correctiveAction : '<',
                },
                templateUrl :
                    'admin-templates/site/programTests/programTest/directive/correctiveActionDirective/correctiveActionCreateOrder.html',
            };
        });
}());
