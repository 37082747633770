/* eslint-disable angular/controller-as */

angular.module('acadiamasterApp')
    .controller('LanguageController', ($scope, $translate, Language, tmhDynamicLocale) => {
        $scope.changeLanguage = languageKey => {
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
        };

        Language.getAll().then(languages => {
            $scope.languages = languages;
        });
    })
    .filter('findLanguageFromKey', () => lang => ({
        ca      : 'Català',
        da      : 'Dansk',
        de      : 'Deutsch',
        en      : 'English',
        es      : 'Español',
        fr      : 'Français',
        hu      : 'Magyar',
        it      : 'Italiano',
        ja      : '日本語',
        ko      : '한국어',
        nl      : 'Nederlands',
        pl      : 'Polski',
        'pt-br' : 'Português (Brasil)',
        'pt-pt' : 'Português',
        ro      : 'Română',
        ru      : 'Русский',
        sv      : 'Svenska',
        tr      : 'Türkçe',
        'zh-cn' : '中文（简体）',
        'zh-tw' : '繁體中文',
    }[lang]));
