
/**
 * created by Jason Cao at 12/05/2019
 * container item type specific config directive, this is just a top level directive that
 * will invoke other type specific configuration.
 */
angular.module('acadiamasterApp').directive('containerItemTypeSpecificConfigFormCommon', () => ({
    link($scope) {

        /* valid choices for max entry and entry as complete */
        $scope.validChoices = [
            { text: 'unlimited', value: -1 },
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
            { text: '9', value: 9 },
            { text: '10', value: 10 },
        ];

        /**
             * invoked when the max entry allowed has changed
             */
        $scope.maxEntryAllowedChanged = function () {
            const { config } = $scope;

            if (config.maxEntryAllowed != null && config.maxEntryAllowed > 0
                    && (config.maxEntryAllowed < config.numEntryAsComplete || config.numEntryAsComplete <= 0)) {
                // number of entry as complete should not be greater than max entry allowed, set it to
                // max entry allowed if it is the case
                config.numEntryAsComplete = config.maxEntryAllowed;
            }
        };

        $scope.filterByMaxEntryAllowed = function (maxEntryAllowed) {
            return function (item) {
                if (maxEntryAllowed == null || maxEntryAllowed === -1) {
                    // max entry is unlimited, we can always return true
                    return true;
                }

                if (item.value <= 0) {
                    // max entry is not unlimited and number of entry as complete should not be unlimited
                    return false;
                }

                // number of entry as complete should be less than or equal to max entry allowed
                return maxEntryAllowed >= item.value;
            };
        };
    },
    restrict: 'E',
    scope: {
        config: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement'
            + '/config/containerItem/typeSpecific/containerItemTypeSpecificConfigFormCommon.html',
}));
