/**
 * created by Jason.Cao on Nov 2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for flow chart for form using ng-flow-chart
     */
        .directive('formFlowChartNgflowchart', function (flowchartConstants, Modelfactory) {
            var connectorId = 0;
            // some variable controlling how to size the nodes with gap
            var itemMinWidth = 250;
            var itemXGap = 150;
            var itemMinHeight = 60;
            var itemYGap = 70;

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/navigation/formFlowChart.ngFlowChart.html',
                scope: {
                    formMode: '=',
                    target : '=',
                    cssStyles : '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.flowChartSelected = [];

                    // this is a very strange way of passing callbacks to the flow chart, not well designed for sure
                    $scope.callbacks = {
                        nodeCallbacks : {
                            doubleClick : function(node) {
                                if ($scope.selector!=null) {
                                    var navNode = _.find($scope.formMode.navigationNodes, function(n) {
                                        return n.localId === node.id;
                                    });

                                    var selectItem = navNode==null ? null : (navNode.page==null)
                                        ? navNode._parent._nodesAndEdges : navNode.page;

                                    if (selectItem!=null) {
                                        $scope.selector.selectItem(selectItem, true);
                                    }
                                }
                            }
                        }
                    };

                    updateModel($scope);

                    // todo: current this directive does not auto update when change occurs, this is to improve the performance,
                    // the library has major performance issue, fix it later
                    // $scope.$watchGroup(['formMode.navigationNodes', 'formMode.navigationEdges'], function() {
                    //     updateModel($scope);
                    // });
                }
            };

            function updateModel($scope) {
                $scope.model = createNodeModel($scope.formMode.navigationNodes, $scope.target);
                $scope.modelservice = Modelfactory($scope.model, $scope.flowChartSelected);
            }

            /**
             * converting navigation node to a flow chart node
             * @param node - navigation node
             * @param level - current level
             * @returns {{name: *|any, id, level: *, connectors: Array, isNavigationOnly: boolean, _node: *}}
             */
            function convertNodeModel(node, level) {
                var connectors = [];

                _.forEach(node._edgesFrom, function() {
                    connectors.push({
                        type : flowchartConstants.bottomConnectorType,
                        id : connectorId++,
                        used : false
                    });
                });

                _.forEach(node._edgesTo, function() {
                    connectors.push({
                        type : flowchartConstants.topConnectorType,
                        id : connectorId++,
                        used : false
                    });
                });

                return {
                    name : node.getName(),
                    id : node.localId,
                    level : level,
                    connectors: connectors,
                    isNavigationOnly : node.page==null,
                    _node : node
                };

            }


            /**
             * building a node map to start all the flow nodes with its level, then from there, we will build the nodes with position
             * @param currentNodeList - list of current navigation nodes to be processed at this level
             * @param nodesResultMap - nodes result map used to draw the flow chart, converted result will store here, this does not
             *                      calculate the x-y coordinates yet, it just determines which level the node is in
             * @param currentLevel - current node level
             */
            function buildingNodeMap(currentNodeList, nodesResultMap, currentLevel) {
                if (currentNodeList==null || currentNodeList.length===0) {
                    return;  // exit condition, no more nodes to be processed
                }

                var newNodeList = [];

                // go through each node in current node list, put its child into the the new node list
                _.forEach(currentNodeList, function(navNode) {
                    var fcNode;
                    if (nodesResultMap[navNode.localId] == null) {
                        // not a node that has already been process

                        // process the node itself
                        fcNode = convertNodeModel(navNode, currentLevel);
                        nodesResultMap[fcNode.id] = fcNode;
                    }
                    else {
                        // this node has already been processed at a earlier level, need to lower its level
                        fcNode = nodesResultMap[navNode.localId];
                        fcNode.level = currentLevel;
                    }

                    // add all the nodes that can be reached from this node into the newNodeList
                    // console.log('*** buildingNodeMap, level ' + currentLevel + ", " + navNode.getName() + ", edgesFrom : ", navNode._edgesFrom);

                    _.forEach(navNode._edgesFrom, function(e) {
                        var nodeTo = e.nodeTo;
                        if (newNodeList.indexOf(nodeTo) === -1) {
                            // if not added already, add it into the list to be processed the next round
                            newNodeList.push(e.nodeTo);
                        }
                    });
                });

                buildingNodeMap(newNodeList, nodesResultMap, currentLevel + 1);
            }

            /**
             * building the node map for target nodes
             * @param nodesResultMap - node map to be updated
             * @param targetNodes - target node list
             * @param levelsAbove - number of levels to look up from
             * @param levelsBelow - number of levels to look down from target
             * @param currentLevel - current node level
             */
            function buildNodeMapForTargetNodes(nodesResultMap, targetNodes, levelsAbove, levelsBelow, currentLevel) {
                if (targetNodes==null || targetNodes.length===0) {
                    return;  // exit condition, no more nodes to be processed
                }

                // if not specified, we display 1 level above and 1 level below
                if (levelsAbove==null) {
                    levelsAbove = 1;
                }

                if (levelsBelow == null) {
                    levelsBelow = 1;
                }

                if (currentLevel==null) {
                    // this is the first call, we will set current level as levelsAbove + 1. ie: if we need to lookup 2 levels above, then the current level is 3
                    currentLevel = levelsAbove + 1;
                }

                var nodesAboveOneLevel = [];
                var nodesBelowOneLevel = [];

                // go through each node in current node list, put its child into the the new node list
                _.forEach(targetNodes, function(navNode) {
                    var fcNode;
                    if (nodesResultMap[navNode.localId] == null) {
                        // not a node that has already been process

                        // process the node itself
                        fcNode = convertNodeModel(navNode, currentLevel);
                        nodesResultMap[fcNode.id] = fcNode;
                    }

                    // if we need to add parents of the current level
                    if (levelsAbove > 0) {
                        // add all the nodes that can be reached from this node into the newNodeList
                        _.forEach(navNode._edgesTo, function(e) {
                            var nodeFrom = e.nodeFrom;
                            if (nodesAboveOneLevel.indexOf(nodeFrom) === -1) {
                                // if not added already, add it into the list to be processed the next round
                                nodesAboveOneLevel.push(e.nodeFrom);
                            }
                        });

                    }

                    // if we need to check children of current level
                    if (levelsBelow > 0) {
                        // add all the nodes that can be reached from this node into the newNodeList
                        _.forEach(navNode._edgesFrom, function(e) {
                            var nodeTo = e.nodeTo;
                            if (nodesBelowOneLevel.indexOf(nodeTo) === -1) {
                                // if not added already, add it into the list to be processed the next round
                                nodesBelowOneLevel.push(e.nodeTo);
                            }
                        });
                    }
                });

                // recursive call to other levels
                if (levelsAbove > 0) {
                    buildNodeMapForTargetNodes(nodesResultMap, nodesAboveOneLevel, levelsAbove - 1, 0, currentLevel - 1);
                }

                if (levelsBelow > 0) {
                    buildNodeMapForTargetNodes(nodesResultMap, nodesBelowOneLevel, 0, levelsBelow - 1, currentLevel + 1);
                }
            }

            /**
             * calculate the node width by using the connectors
             * @param node - navigation node
             */
            function calculateNodeWidth(node) {
                var countTopConnectors = 0;
                var countBottomConnectors = 0;

                _.forEach(node.connectors, function(connector) {
                    if (connector.type===flowchartConstants.topConnectorType) {
                        countTopConnectors++;
                    }
                    else {
                        countBottomConnectors++;
                    }
                });

                return Math.max(countBottomConnectors, countTopConnectors) * 70;
            }

            /**
             * calculate the width of each node in a level, and put the combined width into totalWidth of the level
             * @param nodesInLevel - nodes in level object, it contains some top level property as well as a list of nodes
             */
            function calculateWidth(nodesInLevel) {
                var totalWidth = 0;
                _.forEach(nodesInLevel.nodes, function(node) {
                    node.width = Math.max(calculateNodeWidth(node), itemMinWidth);
                    totalWidth += itemXGap + node.width;
                });

                nodesInLevel.totalWidth = totalWidth;
            }

            /**
             * position the nodes in a single level
             * @param nodesInLevel - nodes in a level
             * @param maxWidthAllLevel - max width often all the level, used to calculate centering the nodes
             */
            function positionNodes(nodesInLevel, maxWidthAllLevel) {
                var currentPosition = (maxWidthAllLevel - nodesInLevel.totalWidth) / 2;
                var yPosition = (nodesInLevel.level-1) * itemMinHeight + nodesInLevel.level * itemYGap;

                // console.log('level #' + nodesInLevel.level + ', starting position : ' + currentPosition + ', ' + yPosition, nodesInLevel);

                _.forEach(nodesInLevel.nodes, function(node) {
                    node.x = currentPosition + itemXGap;
                    node.y = yPosition;
                    currentPosition += itemXGap + node.width;
                    // console.log('     node with id ' + node.id + "(" + node.x + ", " + node.y + ")");
                });
            }

            /**
             * converting a node map into a list of nodes with the x/y position for the node
             * this is a special case function where we know there are 3 levels that we need to worry about, the middle one is the
             * target node
             * @param fcNodes - a list of flow chart nodes including connectors
             * @param fcEdges - a list of flow chart edges
             * @param targetNodeId - id of the target node
             */
            function updateNodesWithTarget(fcNodes, fcEdges, targetNodeId) {
                var nodesAtParentLevel = [];
                var targetNode = _.find(fcNodes, function(n) {
                    return n.id===targetNodeId;
                });
                var nodesAtChildLevel = [];

                // go through the nodes and pick out things into parent level and child level
                _.forEach(fcNodes, function(fcn) {
                    if (fcn.level === 1) {
                        nodesAtParentLevel.push(fcn);
                    }
                    else if (fcn.level=== 2) {
                        targetNode = fcn;
                    }
                    else if (fcn.level === 3) {
                        nodesAtChildLevel.push(fcn);
                    }
                });

                // find out the max width of parent and child level
                var targetNodeWidth = itemXGap + targetNode.width;
                var parentLevelWidth = 0;
                _.forEach(nodesAtParentLevel, function(n) {
                    parentLevelWidth += itemXGap + n.width;
                });

                var childLevelWidth = 0;
                _.forEach(nodesAtChildLevel, function(n) {
                    childLevelWidth += itemXGap + n.width;
                });

                var maxWidth = Math.max(targetNodeWidth, parentLevelWidth, childLevelWidth) + itemXGap;

                // adjust target node to position it to the middle
                targetNode.x = (maxWidth - targetNode.width) / 2;
            }

            function convertMapToArrayListByLevel(nodeFcMap) {
                var levelList = [];

                _.forEach(nodeFcMap, function(node) {
                    var level = node.level;
                    var levelInList = levelList[level-1];
                    if (levelInList== null) {
                        levelInList = {
                            level : level,
                            nodes : []
                        };
                        levelList[level-1] = levelInList;
                    }

                    levelInList.nodes.push(node);
                });

                levelList = _.filter(levelList, function(n) {
                    return n!=null;
                });

                // sort the lists in each level
                _.forEach(levelList, function(nodesInLevel) {
                    nodesInLevel.nodes.sort(function(n1, n2) {
                        return n1.x - n2.x;
                    });
                });

                return levelList;
            }


            function findAvailableConnector(fcNode, connectorType) {
                return _.find(fcNode.connectors, function(connector) {
                    return !connector.used && connector.type === connectorType;
                });
            }

            function createEdgeAndMarkConnectorUsed(fcNodeFrom, fcNodeTo, navEdge) {
                var connectorFrom = findAvailableConnector(fcNodeFrom, flowchartConstants.bottomConnectorType);
                var connectorTo = findAvailableConnector(fcNodeTo, flowchartConstants.topConnectorType);

                connectorFrom.used = true;
                connectorTo.used = true;

                var edgeReason = '';

                if (navEdge.name!=null && navEdge.name.trim().length>0) {
                    edgeReason += '<strong>Name: </strong>' + navEdge.name + "<br>";
                }

                if (navEdge.conditionText!=null && navEdge.conditionText.trim().length>0) {
                    edgeReason += '<pre class="code">' + navEdge.conditionText + '</pre><br>';
                }

                return {
                    name : navEdge.name,
                    reason : edgeReason,
                    source : connectorFrom.id,
                    destination : connectorTo.id
                };
            }

            /**
             * add a node to a list of nodes if not already in the list
             * @param nodeList - list of nodes
             * @param node - the node to be added
             */
            function addToListIfNotAlreadyAdded(nodeList, node) {
                if (nodeList.indexOf(node) === -1) {
                    nodeList.push(node);
                }
            }

            /**
             * build edges between two levels, and re-arrange items in the next level if necessary
             * @param currentLevel - current level of nodes
             * @param nextLevel - next level of nodes
             * @param fcNodesMap - map of fc nodes
             * @param hasTargetNode - whether this is a graph for target node or not
             * @return [] - a list of edges connecting current level and next level
             */
            function buildEdgesBetweenLevel(currentLevel, nextLevel, fcNodesMap, hasTargetNode) {
                var edges = [];

                // re-arranged list of nodes for the next level
                var nodesRearranged = [];

                // turn current level into a map for quick lookup
                var currentLevelMap = {};
                _.forEach(currentLevel.nodes, function(node) {
                    currentLevelMap[node.id] = node;
                });

                // turn next level into a map for quick lookup
                var nextLevelMap = {};
                _.forEach(nextLevel.nodes, function(node) {
                    nextLevelMap[node.id] = node;
                });


                // go through current level nodes and create edges, move the connected next level nodes into the re-arranged list
                _.forEach(currentLevel.nodes, function(node) {
                    var edgesFrom = node._node._edgesFrom;

                    _.forEach(edgesFrom, function(edge) {
                        var fcNodeTo = fcNodesMap[edge.nodeTo.localId];

                        // find a node being pointed by current level nodes
                        if (fcNodeTo != null && nextLevelMap[fcNodeTo.id] != null) {
                            // if in the next level map, then process it
                            var fcEdge = createEdgeAndMarkConnectorUsed(node, fcNodeTo, edge);
                            edges.push(fcEdge);

                            addToListIfNotAlreadyAdded(nodesRearranged, fcNodeTo);
                        }
                    });
                });

                // move nodes that are connected from above the current level at the end of the list
                _.forEach(nextLevel.nodes, function(node) {
                    var edgesTo = node._node._edgesTo;

                    _.forEach(edgesTo, function(edge) {
                        var fcNodeFrom = fcNodesMap[edge.nodeFrom.localId];

                        // found a node going to next level nodes and not not from current level
                        if (fcNodeFrom != null && currentLevelMap[fcNodeFrom.id] == null && !hasTargetNode) {
                            // if not in the current 2 level map, then it's from a node above current level,
                            // edge that jumps over more than 1 level found
                            // note: only way to get a nextLevel -> nextLevel link is in the targeted node graph where we do not
                            // re-apply the levels of the nodes, those edge should just be ignored in that type of graph
                            edges.push(createEdgeAndMarkConnectorUsed(fcNodeFrom, node, edge));

                            addToListIfNotAlreadyAdded(nodesRearranged, node);
                        }
                    });
                });


                nextLevel.nodes = nodesRearranged;

                return edges;
            }


            /**
             * building edges for the navigation edges
             * @param nodeFcMap - node map for flow chart, used for lookup purpose and keep track with connector has been used already
             * @param hasTargetNode - whether this is a graph for target node or not
             * @returns {*} - flow chart model with nodes and edges
             */
            function buildModelWithEdgeAndPosition(nodeFcMap, hasTargetNode) {
                var edges = [];

                var levelList = convertMapToArrayListByLevel(nodeFcMap);

                var currentLevel, nextLevel;

                for (var i=0; i<levelList.length - 1; i++) {
                    currentLevel = levelList[i];
                    nextLevel = levelList[i+1];

                    edges = edges.concat(buildEdgesBetweenLevel(currentLevel, nextLevel, nodeFcMap, hasTargetNode));
                }

                // now all the edges are build, and nodes are ordered, we need to calculate the total width in each level
                var maxWidth = 0;
                _.forEach(levelList, function(nodesInLevel) {
                    calculateWidth(nodesInLevel);
                    maxWidth = Math.max(maxWidth, nodesInLevel.totalWidth);
                });

                // go through all the levels and center all the nodes
                var nodes = [];

                _.forEach(levelList, function(nodesInLevel) {
                    // console.log('about to position nodes, nodes in level = ', nodesInLevel);
                    positionNodes(nodesInLevel, maxWidth);

                    // flatten the list as we are done with the levels
                    _.forEach(nodesInLevel.nodes, function(node) {

                        nodes.push(node);
                        // remove the heavy object as they are no longer needed
                        node._node = null;
                    })
                });

                return {
                    edges : edges,
                    nodes : nodes
                };
            }

            /**
             * the main function for creating the flow chart node model from navigation nodes and edges
             * @param nodes - navigation nodes
             * @param targetNode - node that we are targeting, can be null
             * @returns {{nodes: Array, edges: Array}}
             */
            function createNodeModel(nodes, targetNode) {
                // find starting node
                var startNode = _.find(nodes, function(n) {
                    return n._edgesTo==null || n._edgesTo.length==0;
                });

                var model;

                var nodeForFlowChartMap = {};

                if (targetNode!=null) {
                    buildNodeMapForTargetNodes(nodeForFlowChartMap, [targetNode]);
                }
                else {
                    buildingNodeMap([startNode], nodeForFlowChartMap, 1);
                }

                var hasTargetNode = targetNode!=null;
                // building the model with everything in there
                model = buildModelWithEdgeAndPosition(nodeForFlowChartMap, hasTargetNode);


                if (hasTargetNode) {
                    // for each nodes in the list, start positioning them
                    updateNodesWithTarget(model.nodes, model.edges, targetNode.localId);
                }

                return model;
            }


        });

})();

