/**
 * navigation builder template configuration model, used to hold various configuration data
 * specific to the template of MULTI_CHOICE_SELECTION
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('NavTemplateMultiChoiceModel', function (NavTemplateBaseModel, NavUtilService,
                                                         FormNavigationNodeModel, FormConstants) {

        /***************************************
         *  Model definition/construction
         ***************************************/
        NavTemplateMultiChoiceModel.inheritsFrom(NavTemplateBaseModel);

        /**
         * constructor for multi choice template config
         * @param parent - parent nav build config model that holds the information on starting page, etc
         * @constructor
         */
        function NavTemplateMultiChoiceModel(parent) {
            NavTemplateBaseModel.call(this, parent);

            this.endPageLocalId = null;
            this.multiSelectLocalId = null;
            this.answersWithTarget = [];
        }


        /**
         * filter function for multi selector
         * @returns {function}
         */
        NavTemplateMultiChoiceModel.prototype.getFilterFunction = function () {
            // return the filter function that can be used to filter the target that we want
            return filterForMultiChoiceField;
        };

        /**
         * check if the configuration is valid or not
         * valid configuration means
         * 1. at least one page is selected on the starting page
         * 2. for any end page, there must be a start page for the same answer
         * @returns {boolean} - true if config is valid, false otherwise
         */
        NavTemplateMultiChoiceModel.prototype.isValid = function () {
            var answersWithTarget = this.answersWithTarget;

            if (answersWithTarget==null || answersWithTarget.length == 0) {
                return false;
            }

            var onePageSelected = false;

            for (var i = 0; i<answersWithTarget.length; i++) {
                var answer = answersWithTarget[i];
                if (answer.targetPageStart != null) {
                    onePageSelected = true;
                }

                // if end page is selected and start page is not selected --> not valid, return false
                if (answer.targetPageEnd != null && answer.targetPageStart == null) {
                    return false;
                }
            }

            // no issue in the list, just need to check
            return onePageSelected;
        };

        /**
         * reset answer list using multi-select local id
         */
        NavTemplateMultiChoiceModel.prototype.resetAnswersList = function() {
            var formMode = this.getParent().formMode;
            var multiSelectField = formMode.findFirstByLocalIdInLookupMap(this.multiSelectLocalId);

            this.answersWithTarget = [];
            var answersWithTarget = this.answersWithTarget;

            if (multiSelectField != null) {
                // found the target, now build the basic structure with answers and targets
                var optionValues = multiSelectField.subFields[1].fieldValue.values;

                _.forEach(optionValues, function(ov) {
                    answersWithTarget.push({
                        value : ov.value,
                        text : ov.text,
                        targetPageStart : null,
                        targetPageEnd : null
                    });
                });
            }
        };

        /**
         * process configuration
         */
        NavTemplateMultiChoiceModel.prototype.processConfig = function () {
            var configModel = this.getParent();
            var formMode = configModel.formMode;

            var startingPageLocalId = configModel.sourcePage.localId;
            var endPageLocalId = this.endPageLocalId;
            var startingPageNode = formMode.findNodeByPageLocalId(startingPageLocalId);

            // nodes configuration object that holds various values, used as input/output variable
            // to various functions that needs to return multiple values
            var nodesConfig = {
                startingPageLocalId : startingPageLocalId,
                endPageLocalId : endPageLocalId,
                currentPageNode : startingPageNode,
                currentNavNode : null,
                nextNavNode : null,
                endPageNode : formMode.findNodeByPageLocalId(endPageLocalId),
                groupAnswerList : []
            };

            var multiSelectField = formMode.findFirstByLocalIdInLookupMap(this.multiSelectLocalId);

            // pre-processing to manage linking between starting node to end node and cleaning up
            // the starting node if needed
            preProcessingConfig(configModel, nodesConfig, multiSelectField, this.answersWithTarget);

            // processing all the answers, by the end of this processing, the current nav node and page
            // should be set at the last answer that has outgoing targets and the end target that answer
            // points to (if end target is not specified for the answer, then the starting target)
            mainProcessingConfig(configModel, nodesConfig, multiSelectField);

            // post process for cleaning up some edges for the last page and nav node and link them to the
            // ending page node if it's specified
            postProcessingConfig(configModel, nodesConfig, multiSelectField);
        };


        /* -------------------------------------------------------------------------------------------
            private function
         * ------------------------------------------------------------------------------------------- */

        /**
         * post-processing of the configs to clean up some lose ends and link to the ending page node if needed
         * 1. clear out edges from last page node from main processing
         * 2. add an edges towards ending page node if it's specified from last page node and last nav node
         * @param configModel - config model, mainly used for logging messages
         * @param nodesConfig - nodes config with various internal variables
         * @param multiSelectField - multi select field where all the answers are coming from
         */
        function postProcessingConfig(configModel, nodesConfig, multiSelectField) {
            configModel.addMessage('Post Processing ... ', 0);

            // clean up and link the end page if it exists
            if (nodesConfig.endPageNode!=null) {
                configModel.addMessage('about to add edges to end node if needed', 1);
                NavUtilService.clearEdgesFrom(nodesConfig.currentPageNode, configModel, 2);
                NavUtilService.addEdgeBetween(nodesConfig.currentPageNode, nodesConfig.endPageNode, null, null, configModel, 2);
                NavUtilService.addEdgeBetween(nodesConfig.currentNavNode, nodesConfig.endPageNode, null, null, configModel, 2);
            }
        }

        /**
         * pre-processing of the configs
         * 1. clear out edges from source node
         * 2. add an edge between source node and end node if end node is specified for no answer selected as a shortcut
         * @param configModel - config model, mainly used for logging messages
         * @param nodesConfig - nodes config with various internal variables
         * @param multiSelectField - multi select field where all the answers are coming from
         * @param answersWithTarget - answers with target configured in UI
         */
        function preProcessingConfig(configModel, nodesConfig, multiSelectField, answersWithTarget) {
            configModel.addMessage('Pre Processing ... ', 0);

            // clean source page outgoing edges
            configModel.addMessage('Clean source page outgoing edges', 1);
            NavUtilService.clearEdgesFrom(nodesConfig.currentPageNode, configModel, 2);

            // add link between start and end if end page is specified
            configModel.addMessage('About to add link from source to end node if needed', 1);
            NavUtilService.addEdgeBetweenWithNoAnswer(nodesConfig.currentPageNode, nodesConfig.endPageNode, multiSelectField,
                configModel, 2);

            // re-order the answers to merge multiple answers together if they are pointing to the same starting page
            nodesConfig.groupAnswerList = answersToAnswerGroup(answersWithTarget);
        }

        /**
         * convert answers to answer groups
         * @param answersWithTarget
         * @returns {Array} - return an array of array (ie: list of answer groups)
         */
        function answersToAnswerGroup(answersWithTarget) {
            var answerGroups = [];

            _.forEach(answersWithTarget, function(answer) {
                var answerGroup = findMatchingAnswerGroup(answerGroups, answer.targetPageStart);
                if (answerGroup != null) {
                    // there is a match for this target page start, merge this answer with previous answers that pointing to
                    // the same target pages
                    answerGroup.push(answer);
                }
                else {
                    // create new group with the current answer and add to the answer group list
                    answerGroup = [answer];
                    answerGroups.push(answerGroup);
                }
            });

            return answerGroups;
        }

        /**
         * find the answer group with matching target page start
         * @param answerGroups - answer groups
         * @param targetPageStart - target page start to look for, if it's null, then return null
         */
        function findMatchingAnswerGroup(answerGroups, targetPageStart) {
            if (targetPageStart == null) {
                return null;
            }

            return _.find(answerGroups, function(ag) {
                return ag!=null && ag.length>0 && ag[0].targetPageStart == targetPageStart;
            });
        }

        /**
         * main processing unit for the configuration
         * @param configModel - config model used mainly for logging
         * @param nodesConfig - nodes config with various internal variables
         * @param multiSelectField - multi select field where all the answers are coming from
         */
        function mainProcessingConfig(configModel, nodesConfig, multiSelectField) {
            configModel.addMessage('Processing Answers By Group', 0);

            // go through the answer groups one group at a time
            _.forEach(nodesConfig.groupAnswerList, function(answerGroup) {
                // get the first answer, multiple answer should all be pointing to the same starting page,
                // they have to be using the exact same configuration, so we will only use the first answer
                // targets, if the other answers has different ending page target, they will be ignored as
                // error handling
                var answer = answerGroup[0];

                // has a start page and it is different than the overall end page, create a nav node for that start page
                // ps. overall end page is treated like
                if (answer.targetPageStart != null && answer.targetPageStart != nodesConfig.endPageLocalId) {
                    // processing the answer will auto advance the next/current page and nav node
                    processAnswerWithTarget(nodesConfig, answerGroup, configModel, multiSelectField, 1);
                }
                else {
                    // no target is always done one at a time, just use the first answer is fine
                    processAnswerWithNoTarget(answer, configModel, 1);
                }
            });
        }

        /**
         * process an answer with no valid target
         * @param answer - answer to process
         * @param configModel - config model used to log messages
         * @param messageLevel - logging level for messages
         */
        function processAnswerWithNoTarget(answer, configModel, messageLevel) {
            configModel.addMessage('Processing Answer (No Outgoing Link) : ' + wrapFormat(answer.value), messageLevel);

            // no target from this answer, do cleanup and add the answer to no outgoing link answer list
            var nodeName = getCheckingNodeNameFromAnswerValue(answer.value);

            var navNodeForCheckingCurrentAnswer = findNodeByName(configModel.formMode, nodeName);
            if (navNodeForCheckingCurrentAnswer != null && navNodeForCheckingCurrentAnswer.isPureNavNode()) {
                configModel.addMessage('removing pure nav node : ' + navNodeForCheckingCurrentAnswer.name, messageLevel + 1);

                // remove this node and it will also remove edges in and out of the node
                configModel.formMode.removeNavigationNode(navNodeForCheckingCurrentAnswer);
            }
            else {
                configModel.addMessage('nothing to do', messageLevel + 1);
            }
        }

        /**
         * adding some highlight to the input string to make it more visible
         * @param inputString - input string
         */
        function wrapFormat(inputString) {
            return '<kbd>' + inputString + '</kbd>';
        }

        /**
         * adding answer value string for a group of answers, if the group of answer contains only 1
         * answer, then simple return the answer value as a string, else, return it as an array string
         * @param answerGroup {Array} - answer group, not null, contain at least 1 answer
         */
        function getAnswerValuesString(answerGroup) {
            if (answerGroup.length==1) {
                return answerGroup[0].value;
            }
            else {
                var answerValues = [];
                _.forEach(answerGroup, function(answer) {
                    answerValues.push(answer.value);
                });

                return JSON.stringify(answerValues);
            }
        }

        /**
         * process one answer group with common outgoing target
         * @param nodesConfig - list of nodes configuration used for processing
         * @param answerGroup - current answer group to process
         * @param configModel - config model used to log messages
         * @param multiSelectField - multi-select field
         * @param messageLevel - logging level for messages
         */
        function processAnswerWithTarget(nodesConfig, answerGroup, configModel, multiSelectField, messageLevel) {
            var answerValuesString = getAnswerValuesString(answerGroup);
            var nextLevel = messageLevel + 1;

            configModel.addMessage('Processing Answer : ' + wrapFormat(answerValuesString), messageLevel);

            nodesConfig.nextNavNode = cleanUpAndRecreateNavNode(configModel.formMode, answerGroup, configModel, nextLevel);

            // clear the edges from current page node unless this is the starting source
            if (nodesConfig.currentPageNode.page.localId != nodesConfig.startingPageLocalId) {
                NavUtilService.clearEdgesFrom(nodesConfig.currentPageNode, configModel, nextLevel);
            }

            // add link between current node and nav node
            NavUtilService.addEdgeBetween(nodesConfig.currentPageNode, nodesConfig.nextNavNode, null, null, configModel, nextLevel);

            // add link from nav node to starting page
            var firstAnswer = answerGroup[0];

            var startPageNode = configModel.formMode.findNodeByPageLocalId(firstAnswer.targetPageStart);

            var name = getEdgeNameByAnswerGroup(answerGroup);
            var conditionText = getConditionTextByAnswerGroup(answerGroup, multiSelectField.formField.id);

            NavUtilService.addEdgeBetween(nodesConfig.nextNavNode, startPageNode, name, conditionText, configModel, nextLevel);

            // if current nav node is not null, add a link between current node to next node with no condition and no name
            NavUtilService.addEdgeBetween(nodesConfig.currentNavNode, nodesConfig.nextNavNode, null, null, configModel, nextLevel);

            // set last page and last nav node
            nodesConfig.currentNavNode = nodesConfig.nextNavNode;
            nodesConfig.currentPageNode = configModel.formMode.findNodeByPageLocalId(
                (firstAnswer.targetPageEnd != null) ? firstAnswer.targetPageEnd : firstAnswer.targetPageStart) ;
        }

        /**
         * getting the edge name by answer group
         * @param answerGroup - answer group with at least one answer, @NotNull
         */
        function getEdgeNameByAnswerGroup(answerGroup) {
            var name = 'selection include ';
            name += '"' + answerGroup[0].value + '"';
            if (answerGroup.length > 1) {
                for (var i=1; i<answerGroup.length; i++) {
                    name += ', "' + answerGroup[i].value + '"';
                }
            }

            return name;
        }

        /**
         * getting condition text for a specific answer group
         * @param answerGroup - answer group with at least one answer, @NotNull
         * @param formFieldId - form field id used to build the condition text
         */
        function getConditionTextByAnswerGroup(answerGroup, formFieldId) {
            var conditionText = getContainValueCondition(formFieldId, answerGroup[0].value);

            // if there are more than one answer in the group, then change the conditionText to something like
            // contains([fieldEntryContainValue(formFieldId, answer1), fieldEntryContainValue(formFieldId, answer1), ...], true)
            // ie: any one of those value is contained in the field entry
            if (answerGroup.length > 1) {
                var fieldEntryContainValueArray = [];
                fieldEntryContainValueArray.push(conditionText);

                for (var i=1; i<answerGroup.length; i++) {
                    fieldEntryContainValueArray.push(getContainValueCondition(formFieldId, answerGroup[i].value));
                }

                conditionText = "contains(\n" +
                    "  [\n" +
                    '      ' + fieldEntryContainValueArray.join(",\n") +
                    "  ],\n" +
                    "  true\n" +
                    ")";
            }

            return conditionText;
        }

        /**
         * get a condition value condition using field id and answer value
         * @param formFieldId - field id
         * @param answerValue - answer value
         */
        function getContainValueCondition(formFieldId, answerValue) {
            return 'contains(toStringList(fieldEntryValueInCurrentView(' +formFieldId + ')), "' +
                answerValue + '")';
        }
        /**
         * recreate new nav node for a specific answer group
         * it will also use name match to clear out existing nodes for each answer
         * @param formMode - form mode
         * @param answerGroup - answer group
         * @param configModel - config model used to log messages
         * @param messageLevel - logging level for messages
         */
        function cleanUpAndRecreateNavNode(formMode, answerGroup, configModel, messageLevel) {
            // find individual nodes for each answer in the answer group and remove them if they are
            // just pure navigation nodes
            _.forEach(answerGroup, function(answer) {
                var nodeName = getCheckingNodeNameFromAnswerValue(answer.value);
                removeNavigationNodeByName(nodeName, formMode, configModel, messageLevel);
            });

            // trying to find old pure nav nodes by combined name, if found, remove them
            var groupName = getCheckingNodeNameFromAnswerGroup(answerGroup);
            removeNavigationNodeByName(groupName, formMode, configModel, messageLevel);

            var node = formMode.addNavigationNode();
            node.name = groupName;

            configModel.addMessage('new nav node added, name: ' + groupName, messageLevel);
            return node;
        }

        /**
         * removing navigation node by name
         * @param nodeName - node name
         * @param formMode - form mode
         * @param configModel - config model used to log messages
         * @param messageLevel - message level
         */
        function removeNavigationNodeByName(nodeName, formMode, configModel, messageLevel) {
            var node = findNodeByName(formMode, nodeName);
            if (node != null && node.isPureNavNode()) {
                configModel.addMessage('removing old nav node : ' + node.name, messageLevel);

                // remove edges in and out of the node
                formMode.removeNavigationNode(node);
            }
        }

        function getCheckingNodeNameFromAnswerGroup(answerGroup) {
            if (answerGroup.length == 1) {
                return getCheckingNodeNameFromAnswerValue(answerGroup[0].value);
            }

            var answerValues = _.map(answerGroup, function(answer) {
                return answer.value;
            });

            return 'Checking ' + answerValues.join(', ');
        }

        function getCheckingNodeNameFromAnswerValue(answerValue) {
            return 'Checking ' + answerValue;
        }

        function findNodeByName(formMode, nodeName) {
            var nodes = formMode.navigationNodes;
            return _.find(nodes, function(n) {
                return n.name == nodeName;
            });
        }

        /**
         * a filter function to filter a list of fields that can support multiple answers selections
         * note: currently only multi-select with option of singleline = false satisfy this condition
         * @param fields - list of fields to be filtered
         * @returns {Array} - a list of fields that can support multiple answers
         */
        function filterForMultiChoiceField(fields) {
            var filtered = [];

            _.forEach(fields, function(f) {
                if (f.type !== FormConstants.fieldsType.MULTI_SELECTOR) {
                    return;
                }

                var multiSelectChoiceSubField = f.subFields[1];
                var supportSingleLineOnly = multiSelectChoiceSubField.fieldValue.singleLine;

                if (!supportSingleLineOnly) {
                    filtered.push(f);
                }
            });

            return filtered;
        }

        /***************************************
         * service return call
         ***************************************/

        return NavTemplateMultiChoiceModel;
    });
})();
