const ContainerItemType = {
    ACTION : {
        ACTION_APPOINTMENT     : 'ACTION_APPOINTMENT',
        ACTION_NO_SUPPORT_DATA : 'ACTION_NO_SUPPORT_DATA',
        ACTION_VIEW_PMB        : 'ACTION_VIEW_PMB',
    },
    AGREEMENT            : 'AGREEMENT',
    APPOINTMENT          : 'APPOINTMENT',
    CONTENT              : 'CONTENT',
    EXTERNAL_INTEGRATION : 'EXTERNAL_INTEGRATION',
    FORM                 : 'FORM',
    MEASUREMENTS         : 'MEASUREMENTS',
    VIEW_SHARED_EHR      : 'VIEW_SHARED_EHR',
};

export default ContainerItemType;
