(function () {
    'use strict';

    /**
     * util class for creating any pre condition by type
     */
    angular.module('acadiamasterApp').factory('CorrectiveActionCreationUtil',
        function (CorrectiveActionSetPropertyModel, CorrectiveActionTriggerRuleModel,
            ProgramTestConstantsService, CorrectiveActionCreatorModel,
            CorrectiveActionDismissBannerModel, CorrectiveActionAddBannerModel,
            CorrectiveActionSetPropertyFromFieldValueModel,
            CorrectiveActionCreateOrderModel, CorrectiveActionRemoveMultiSubModel,
            CorrectiveActionRecalculationPersonalizedContainer,
            CorrectiveActionRecalculationPersonalizedContainerItem,
            CorrectiveActionUnauthenticatedTaskModel) {
            // two local variable that holds the creator list and creator map
            var caList = [];
            var caMap = {};

            init();

            function init() {
                var actionType = ProgramTestConstantsService.correctiveActionEnum;

                addCreator(CorrectiveActionSetPropertyModel, actionType.SET_PROPERTY);
                addCreator(CorrectiveActionTriggerRuleModel, actionType.TRIGGER_RULE);
                addCreator(CorrectiveActionAddBannerModel, actionType.ADD_BANNER);
                addCreator(CorrectiveActionDismissBannerModel, actionType.DISMISS_BANNER);
                addCreator(CorrectiveActionSetPropertyFromFieldValueModel, actionType.SET_PROPERTY_FROM_FIELD_VALUE);
                addCreator(CorrectiveActionCreateOrderModel, actionType.CREATE_ORDER);
                addCreator(CorrectiveActionRemoveMultiSubModel, actionType.REMOVE_MULTIPLE_SUBSCRIPTION);
                addCreator(CorrectiveActionRecalculationPersonalizedContainer, actionType.EVALUATE_PERSONALIZED_CONTAINER);
                addCreator(CorrectiveActionRecalculationPersonalizedContainerItem, actionType.EVALUATE_PERSONALIZED_CONTAINER_ITEM);
                addCreator(CorrectiveActionUnauthenticatedTaskModel, actionType.UNAUTHENTICATED_TASK);
            }

            function addCreator(modelClass, modelType) {
                var creator = new CorrectiveActionCreatorModel(modelClass, modelType);
                caList.push(creator);
                caMap[modelType.name] = creator;
            }

            function createByType(typeName, parent) {
                var creator = caMap[typeName];

                if (creator == null) {
                    console.error('cant find creator for type name : ', typeName);
                    return null;
                }

                return creator.createNew(parent);
            }

            /***************************************
             * service return call
             ***************************************/
            return {
                getAllCreators: function () {
                    return caList;
                },
                createByType: createByType,
            };

        });
})();
