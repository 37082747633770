(function() {
    'use strict';

    angular.module('acadiamasterApp')

        .controller('ProgramTestSuiteController', function($scope, ProgramTestService, ngDialog, $stateParams, CONFIG, $state) {
            $scope.CONFIG = CONFIG;
            $scope.loadAll = {};

            $scope.programSelection = function () {
                ngDialog.openConfirm({
                    template: 'admin-templates/site/common/directive/programSelectionPopup.html',
                    controller: 'ProgramSelectionPopupController',
                    data: {
                        description: 'Choose the program for which you want to create a test suite'
                    }
                }).then(function (programId) {
                    $state.go('programTests.pstSuite.new', {'programId': programId});
                });
            };

            $scope.refresh = function() {
                $scope.loadAll();
            };
        });
})();