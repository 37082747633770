(function () {
    'use strict';

    /**
     * directive for Form Comparison
     */
    angular.module('acadiamasterApp').directive('formVersionCompare', function (FormUtilService, Form, CompareResultFormVersionService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/formVersionCompare.html',
            scope: {
                formId: '=?'
            },
            link: function ($scope) {
                $scope.showChangeOnlyOptions = [
                    {name : 'Show Change Only', value : true},
                    {name : 'Full Result (v slow)', value : false}
                ];

                $scope.data = {
                    formWithAllVersions : null, // all the form versions loaded from form id
                    fvId1 : null, // form version 1, name only
                    fvId2 : null, // form version 2, name only
                    fvFull1 : null, // form version 1 full structure
                    fvFull2 : null, // form version 2 full structure
                    isVersionsLoaded : false, // indicate if all the name only versions of the form has been loaded
                    comparingStatus : null,
                    combinedFormVersion : null,
                    showChangeOnly : true,

                    isIdSelected : function() {
                        return this.fvId1!=null && this.fvId2!=null;
                    },
                    isComparing : function() {
                        return this.comparingStatus != null;
                    },
                    isFullVersionReady: function() { // check if we are ready to do comparison
                        return this.fvFull1!=null && this.fvFull2!=null;
                    },
                    resetFormVersions : function() {
                        this.fvFull1 = null;
                        this.fvFull2 = null;
                        this.comparingStatus = null;
                        this.combinedFormVersion = null;
                    },
                    setFvFull1 : function(fv) {
                        this.fvFull1 = fv;
                    },
                    setFvFull2 : function(fv) {
                        this.fvFull2 = fv;
                    }
                };

                $scope.loadAndCompareVersions = function() {
                    loadAndCompareVersions($scope);
                };

                init($scope);
            }
        };

        /**
         * init function
         * @param $scope
         */
        function init($scope) {
            // load form versions
            var formDetail = Form.get({
                id: $scope.formId, projection: Form.projections.OVERVIEW
            });

            formDetail.$promise.then(function(data) {
                var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                formObject.fromDto(data);
                $scope.data.formWithAllVersions = formObject;

                $scope.data.isVersionsLoaded = true;
            });
        }

        /**
         * loading a form by form id and version id
         * @param formId - form id
         * @param formVersionId - version id
         * @return {promise} - promise that contains the form object with full detail on one version
         */
        function loadFormByVersionId(formId, formVersionId) {
            return Form.get({
                id : formId,
                projection: Form.projections.SPECIFIC_VERSION,
                formVersionId: formVersionId}).$promise;
        }

        /**
         * start comparing the full versions if possible
         * @param $scope
         */
        function startComparingIfPossible($scope) {
            if (!$scope.data.isFullVersionReady()) {
                return;
            }

            $scope.data.combinedFormVersion = CompareResultFormVersionService.compare($scope.data.fvFull1, $scope.data.fvFull2);

            $scope.data.comparingStatus = "Comparison Done";
        }

        /**
         * compare form versions
         * @param $scope
         */
        function loadAndCompareVersions($scope) {
            $scope.data.resetFormVersions();
            $scope.data.status = "Loading Form Versions ...";

            // load form version 1 detail
            loadFormByVersionId($scope.formId, $scope.data.fvId1).then(function(data) {
                var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                formObject.fromDto(data);
                $scope.data.setFvFull1(formObject.formVersions[0]);

                startComparingIfPossible($scope);
            });

            // load form version 2 detail
            loadFormByVersionId($scope.formId, $scope.data.fvId2).then(function(data) {
                var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                formObject.fromDto(data);
                $scope.data.setFvFull2(formObject.formVersions[0]);

                startComparingIfPossible($scope);
            });

        }


    });

})();

