'use strict';

angular.module('acadiamasterApp')
    .factory('ConfigurationServer', ['$resource', 'Upload', '$http', 'localWeb', '$q', function ($resource, Upload, $http, localWeb, $q) {
        var service = {};
        service.uploadFile = function createEdit(url, files, onSuccessCallback, onErrorCallback) {
            // send them all together for HTML5 browsers:
            Upload.upload({
                url: localWeb.createUrl(url),
                arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                data: {
                    file: files
                }
            }).then(function (resp) {
                if (_.isFunction(onSuccessCallback)) {
                    onSuccessCallback(resp);
                }
            }, function (error) {
                if (_.isFunction(onErrorCallback)) {
                    onErrorCallback(error);
                }
            });
        };


        service.getDeletedForms = function () {
            var deferred = $q.defer();

            var success = function (response) {
                var deletedForms = response.data;
                deferred.resolve(deletedForms);
            };

            const error = function (err) {
                deferred.reject(err);
            };

            $http.get(localWeb.createUrl('/api/configuration/forms/exportDeleted')).then(success).catch(error);

            return deferred.promise;
        };

        service.exportCompleteProgram = function (requestData) {
            var deferred = $q.defer();

            var success = function (response) {
                var resp = {};
                resp.data = response.data;
                resp.headers = response.headers;
                if (response.headers('content-type') != null && response.headers('content-type') != undefined) {
                    resp.contentType = response.headers('content-type').split(';')[0];
                }
                if (response.headers('filename') != null && response.headers('filename') != undefined) {
                    resp.filename = response.headers('filename');
                } else {
                    resp.filename = `complete_program_${requestData.programId}.zip`;
                }

                resp.status = status;
                deferred.resolve(resp);
            };

            const error = function (err) {
                deferred.reject(err);
            };

            $http.post(localWeb.createUrl('/api/configuration/programExportAsZip'), requestData,
                {responseType : 'arraybuffer'}).then(success).catch(error);

            return deferred.promise;
        };

        service.cloneProgram = function (data) {
            return $http.post(localWeb.createUrl('/api/configuration/cloneProgram'), data);
        };

        service.importSnapQuestions = function (zipFile, programId) {
            return Upload.upload({
                url : localWeb.createUrl('/api/importSnapQuestions'),
                data : {
                    zipFile : zipFile,
                    programId : programId,
                },
            });
        };


        /**
         * GET api/configuration/exportContainerItemAsZip/{containerItemId}
         * export a container item with file assets given a container item id
         *
         * @param containerItemId - container item id
         */
        service.exportContainerItem = containerItemId => {
            return service.export(containerItemId, '/api/configuration/exportContainerItemAsZip/');
        };

        /**
         * GET api/configuration/exportCompleteProgramAsZip/{programCode}
         * export complete program with file assets given programCode
         *
         * @param programCode
         */
        service.exportCompleteProgramAsZip = programCode => {
            return service.export(programCode, 'api/configuration/exportCompleteProgramAsZip/');
        };


        service.export = function (id, url) {
            const deferred = $q.defer();

            const success = function (response) {
                let resp = {};
                resp.data = response.data;
                resp.headers = response.headers;
                resp.status = response.status;
                if (response.headers('content-type')) {
                    resp.contentType = response.headers('content-type').split(';')[0];
                }
                deferred.resolve(resp);
            };

            const error = function (err) {
                deferred.reject(err);
            };

            $http.get(
                localWeb.createUrl(`${url}${id}`),
                {responseType : 'arraybuffer'}
            ).then(success).catch(error);

            return deferred.promise;
        };

        return service;
    }]);
