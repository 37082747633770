(function () {
    angular.module('acadiamasterApp')
        .config($stateProvider => {
            function loadTranslatePartial ($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('form');
                $translatePartialLoader.addPart('formComponent');
                $translatePartialLoader.addPart('dataType');
                $translatePartialLoader.addPart('formComponentField');
                $translatePartialLoader.addPart('property');
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('sharedUtilities');
                $translatePartialLoader.addPart('agreements');
                return $translate.refresh();
            }

            $stateProvider
                .state('form', {
                    data : {
                        authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                        pageTitle   : 'acadiamasterApp.form.home.title',
                    },
                    params  : { defaultFormCategory : null },
                    parent  : 'entity',
                    resolve : {
                        translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return loadTranslatePartial($translate, $translatePartialLoader);
                        } ],
                    },
                    url   : '/forms',
                    views : {
                        'content@' : {
                            controller  : 'FormController',
                            templateUrl : 'admin-templates/site/forms/form/forms.html',
                        },
                    },
                })
                .state('form.help', {
                    data : {
                        authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                        pageTitle   : 'acadiamasterApp.form.home.help.title',
                    },
                    parent  : 'form',
                    resolve : {
                        translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return loadTranslatePartial($translate, $translatePartialLoader);
                        } ],
                    },
                    url   : '/help',
                    views : {
                        'content@' : {
                            controller  : 'FormHelpController',
                            templateUrl : 'admin-templates/site/forms/help/formHelp.template.html',
                        },
                    },
                })
                .state('form.detail', {
                    data : {
                        authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                        pageTitle   : 'acadiamasterApp.form.detail.title',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ '$stateParams', 'Form', function ($stateParams, Form) {
                            return Form.get({ id : $stateParams.id, projection : Form.projections.ACTIVE_ONLY });
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return loadTranslatePartial($translate, $translatePartialLoader);
                        } ],
                    },
                    url   : '/detail/{id}',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity) {
                                $scope.form = entity;
                            },
                            template : '<form-detail-view form="form"></form-detail-view>',
                        },
                    },
                })
                .state('form.preview', {
                    parent: 'form', url: '/newPreview?formId&versionId', data: { pageTitle: 'Form Preview' },
                    resolve : {
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    views: {
                        'content@': {
                            template: '<form-preview form-model="formModel"></form-preview>',
                            controller: function ($scope, $window) {
                                var navbar = document.querySelector('div[ui-view="navbar"]');
                                navbar.style.display = 'none';
                                $scope.formModel = $window.formModel;
                            }}}
                })
                .state('form.new', {
                    data : {
                        authorities : [ 'ROLE_ADMIN' ],
                        pageTitle   : 'acadiamasterApp.form.home.createLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ 'FormUtilService', 'FormConstants', function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.DATA_FORM);
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/new',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity) {
                                $scope.form = entity;
                            },
                            template : '<form-configuration category="DATA_FORM" is-new="true" form="form" state-after-save="\'form\'" state-after-cancel="\'form\'" hide-import-button="false"></form-configuration>',
                        },
                    },
                })
                .state('form.newSnapQuestion', {
                    data : {
                        authorities : [ 'ROLE_ADMIN' ],
                        pageTitle   : 'acadiamasterApp.form.home.createLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ 'FormUtilService', 'FormConstants', function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.SNAP_QUESTION_FORM);
                        } ], translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            return loadTranslatePartial($translate, $translatePartialLoader);
                        } ],
                    }, url   : '/newSnapQuestion',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity) {
                                $scope.form = entity;
                            },
                            template : '<form-configuration category="SNAP_QUESTION_FORM" is-new="true" form="form" state-after-save="\'form\'" state-after-cancel="\'form\'" hide-import-button="false"></form-configuration>',
                        },
                    },
                })
                .state('form.newConsent', {
                    data : {
                        authorities : [ 'ROLE_ADMIN' ],
                        pageTitle   : 'acadiamasterApp.form.home.createConentLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ 'FormUtilService', 'FormConstants', function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.CONSENT_FORM);
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/newConsent',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity) {
                                $scope.form = entity;
                            },
                            template : '<form-configuration category="CONSENT_FORM" is-new="true" form="form" state-after-save="\'form\'" state-after-cancel="\'form\'" hide-import-button="false"></form-configuration>',
                        },
                    },
                })
                .state('form.newprofileform', {
                    data : {
                        authorities : [ 'ROLE_ADMIN' ],
                        pageTitle   : 'acadiamasterApp.form.home.createLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ 'FormUtilService', 'FormConstants', function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.PROFILE_FORM);
                        } ],
                        programId : [ '$stateParams', function ($stateParams) {
                            return $stateParams.programId;
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/newProfileForm/{programId}',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity, programId) {
                                $scope.form = entity;
                                $scope.programId = programId;
                            },
                            template : '<form-configuration program-id={{programId}} category="PROFILE_FORM" is-new="true" form="form" state-after-save="\'form\'"state-after-cancel="\'form\'" hide-import-button="false"></form-configuration>',
                        },
                    },
                })
                .state('form.edit', {
                    data : {
                        authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                        pageTitle   : 'acadiamasterApp.form.home.editLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        entity : [ 'Form', '$stateParams', function (Form, $stateParams) {
                            let formVersionId = $stateParams.versionId;
                            if (formVersionId == null) {
                                return Form.get({
                                    id         : $stateParams.id,
                                    projection : Form.projections.ACTIVE_ONLY,
                                });
                            }

                            return Form.get({
                                formVersionId : formVersionId,
                                id            : $stateParams.id,
                                projection    : Form.projections.SPECIFIC_VERSION,
                            });
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/edit/{id}?versionId',
                    views : {
                        'content@' : {
                            controller : function ($scope, entity) {
                                $scope.form = entity;
                            },
                            template : '<form-configuration is-new="false" form="form" state-after-save="\'form\'" state-after-cancel="\'form\'"></form-configuration>',
                        },
                    },
                })
                .state('form.compare', {
                    data : {
                        authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                        pageTitle   : 'acadiamasterApp.form.home.compareLabel',
                    },
                    parent  : 'form',
                    resolve : {
                        formId : [ '$stateParams', function ($stateParams) {
                            return $stateParams.formId;
                        } ],
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/compare?formId',
                    views : {
                        'content@' : {
                            controller : function ($scope, formId) {
                                $scope.formId = formId;
                            },
                            template : '<form-version-compare form-id="formId"></form-version-compare>',
                        },
                    },
                })
                .state('form.replacePDF', {
                    data : {
                        authorities : [ 'ROLE_ADMIN' ],
                        pageTitle   : 'Replace PDF Configuration',
                    },
                    parent  : 'form',
                    resolve : {
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            },
                        ],
                    },
                    url   : '/replacePDF/{formId}/{versionId}',
                    views : {
                        'content@' : {
                            controller : function ($scope, $stateParams) {
                                $scope.formId = $stateParams.formId;
                                $scope.versionId = $stateParams.versionId;
                            },
                            template : '<form-version-pdf-replace form-id="formId" version-id="versionId"></form-version-pdf-replace>',
                        },
                    },
                });
        });
}());
