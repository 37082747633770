(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('importWizardProgramImportModal', function (ngDialog, $timeout, IMPORT_WIZARD_CONSTANTS, AlertService, ImportWizardService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/importWizard/programImportModal/importWizardProgramImportModal.template.html',
                scope: {
                    programId: '='
                },
                controller: function ($scope) {
                    $scope.closePopup = function () {
                        ngDialog.close(null, { status: IMPORT_WIZARD_CONSTANTS.UPLOAD_STATUS.CANCEL });
                    };

                    $scope.startImport = function (file) {
                        beginImport($scope, file);
                    };

                    $scope.isFileValid = function (file) {
                        const pattern = '^[^,]+.zip$';
                        return !!(file && file.name.match(pattern));
                    };

                    init($scope);
                },
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            function init($scope) {
                $scope.data = {
                    isOpen: true,
                    importInProgress: false,
                    isSendingFile: false,
                    serverError: false
                };

                // detect when dialog closes so we can turn off the inProgress check
                $scope.$on('ngDialog.closing', function () {
                    $scope.data.isOpen = false;
                });
            }

            // make one final check for in-progress imports, then begin sending the zip file
            function beginImport($scope, file) {
                $scope.data.isSendingFile = true;
                $scope.data.serverError = false;
                ImportWizardService.checkForInProgressImport($scope.programId).then(
                    function (response) {
                        $scope.data.importInProgress = response.data.importInProgress;
                        if (!$scope.data.importInProgress) {
                            sendZipFile($scope, file);
                        }
                    }, function (error) {
                        $scope.data.isSendingFile = false;
                        console.error(error);
                    });
            }

            // upload the file and close this modal with a success status
            function sendZipFile($scope, file) {
                // check
                ImportWizardService.importZipFile($scope.programId, file).then(
                    function (response) {
                        AlertService.success("Your zip file has been uploaded");
                        ngDialog.close(null, {
                            id: response.data.id,
                            status: IMPORT_WIZARD_CONSTANTS.UPLOAD_STATUS.SUCCESS
                        });
                    }, function (error) {
                        console.error(error);
                        $scope.data.serverError = true;
                    }).finally(function () {
                        $scope.data.isSendingFile = false;
                    });
            }
        });
})();
