'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('form');
            $translatePartialLoader.addPart('businessRule');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('programTest');
            $translatePartialLoader.addPart('expressionCondition');
            return $translate.refresh();
        }

        $stateProvider
            .state('businessRule', {
                parent: 'ruleDashboard',
                url: '/businessRules',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.businessRule.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/rule/businessRules.html',
                        controller: 'BusinessRuleController',
                        controllerAs: 'rCtrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            })
            .state('businessRule.detail', {
                parent: 'businessRule',
                url: '/details/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.businessRule.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/rule/businessRuleDetail.html',
                        controller: 'BusinessRuleDetailPageController',
                        controllerAs: 'rdCtrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            })
            .state('businessRule.newProgramRule', {
                parent: 'businessRule',
                url: '/newProgramRule/{programId}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.businessRule.home.createLabel'
                },
                views: {
                    'content@': {
                        template: '<rule-configuration program-id={{programId}} type="{{rc.ruleType.PROTOCOL}}" is-new="true" rule="rule" state-after-save="\'businessRule\'" state-after-cancel="\'businessRule\'"></rule-configuration>',
                        controller: function ($scope, entity, programId, RuleConstantsService) {
                            $scope.rule = entity;
                            $scope.programId = programId;
                            $scope.rc = RuleConstantsService;
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    entity: ['RuleUtilService', function () {
                        return {};
                    }],
                    programId : ['$stateParams', function ($stateParams) {
                        return $stateParams.programId;
                    }]
                }
            })

            .state('businessRule.newScheduleRule', {
                parent: 'businessRule',
                url: '/newScheduleRule/{programId}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.businessRule.home.createLabel'
                },
                views: {
                    'content@': {
                        template: '<rule-configuration program-id={{programId}} type="{{rc.ruleType.PROGRAM_SCHEDULE_RULE}}" is-new="true" rule="rule" state-after-save="\'businessRule\'" state-after-cancel="\'businessRule\'"></rule-configuration>',
                        controller: function ($scope, entity, programId, RuleConstantsService) {
                            $scope.rule = entity;
                            $scope.programId = programId;
                            $scope.rc = RuleConstantsService;
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    entity: ['RuleUtilService', function () {
                        return {};
                    }],
                    programId : ['$stateParams', function ($stateParams) {
                        return $stateParams.programId;
                    }]
                }
            })

            .state('businessRule.edit', {
                parent: 'businessRule',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.businessRule.home.editLabel'
                },
                views: {
                    'content@': {
                        template: '<rule-configuration type="PROTOCOL" is-new="false" rule="rule" state-after-save="\'businessRule\'" state-after-cancel="\'businessRule\'"></rule-configuration>',
                        controller : function($scope, entity) {
                            $scope.rule = entity;
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    entity: ['BusinessRule', '$stateParams', function(BusinessRule, $stateParams) {
                        return BusinessRule.get({id : $stateParams.id});
                    }],
                    delay: function($q, $timeout, OrderTypeService){
                        var delay = $q.defer();
                        if(!OrderTypeService.hasLoaded()){
                            $timeout(delay.resolve, 5000);
                        } else {
                            $timeout(delay.resolve, 0);
                        }
                        return delay.promise;
                    }
                }
            })
            .state('businessRule.clone', {
                parent: 'businessRule',
                url: '/clone/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.businessRule.home.cloneLabel'
                },
                views: {
                    'content@': {
                        template: '<rule-configuration type="PROTOCOL" is-cloned="true" is-new="false" rule="rule" state-after-save="\'businessRule\'" state-after-cancel="\'businessRule\'"></rule-configuration>',
                        controller : function($scope, entity) {
                            $scope.rule = entity;
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    entity: ['BusinessRule', '$stateParams', function(BusinessRule, $stateParams) {
                        return BusinessRule.get({id : $stateParams.id});
                    }]
                }
            })
            .state('businessRule.manualTrigger', {
                parent: 'businessRule',
                url: '/manualTrigger/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.businessRule.home.manualTriggerLabel'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/rule/rule-manual-trigger.html',
                        controller : 'RuleManualTriggerController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    rule: ['BusinessRule', '$stateParams', function(BusinessRule, $stateParams) {
                        return BusinessRule.get({id : $stateParams.id});
                    }],
                }
            });

    });
