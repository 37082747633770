(function () {
    'use strict';

    /**
     * form search service, all the business logic is here
     */
    angular.module('acadiamasterApp').factory('FormLinkingElementService', function (FormNavigationEdgeModel) {

        /***************************************
         * service return call
         ***************************************/

        return {
            gotoLinkedElement: gotoLinkedElement,
            isNavEdge: isNavEdge
        };

        /***************************************************************
         * service return functions (exposed in the return statement)
         ***************************************************************/

        function gotoLinkedElement(selector, resultObject) {
            var linkedElement;

            if (isNavEdge(resultObject)) {
                var nodeFrom = resultObject.nodeFrom;

                // no page associated with it
                if (nodeFrom.page == null) {
                    // set linked element to the navigation edge list
                    linkedElement = nodeFrom._parent._nodesAndEdges;
                }
                else {
                    // linked element is the page of the node
                    linkedElement = nodeFrom.page;
                }
            } else {
                linkedElement = resultObject;
            }

            if (linkedElement != null) {
                selector.setElementParentKeyValue(linkedElement, '_parent', ['collapsed', 'sectionCollapsed', 'stateRuleCollapsed'], false);
                selector.selectItem(linkedElement, true, true);
            }
        }

        /**
         * check if the result is an navigation edge or not
         * @param result - result object
         * @return {Boolean} - true if the result is a navigation edge, false otherwise
         */
        function isNavEdge(result) {
            return result != null && result instanceof FormNavigationEdgeModel;
        }

        /***************************************************************
         * private functions
         **************************************************************/


    });
})();
