
/**
 * Created by Jamie Nola on 02/20/2020
 *
 * Model for an individual deep link
 */

angular.module('acadiamasterApp')

    .factory('DeepLinkModel', ($q, $translate, AlertService, ModelServiceBase, DeepLinkContextModel,
        DeepLinkService, ModelServiceConditionExpression) => {
        /**
         * @constructor
         */
        function DeepLinkModel() {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false);
            this.availabilityExpression = null;
            this.availabilityExpressionTree = null;
            this.code = null;
            this.container = null;
            this.containerId = null;
            this.context = new DeepLinkContextModel(this);
            this.containerItem = null;
            this.containerItemId = null;
            this.createdById = null;
            this.createdOn = null;
            this.enabled = true;
            this.form = null;
            this.formId = null;
            this.id = null;
            this.lastSavedDto = null;
            this.page = this.context.page;
            this.pageId = this.context.pageId;
            this.programId = null;
            this.targetType = 'PAGE';
            this.updatedOn = null;
            this.updatedById = null;
            this.parameter = null;
        }

        /**
         * Convert the current UI model to DTO format
         */
        DeepLinkModel.prototype.toDto = function toDto() {
            const dto = {};
            dto.context = {
                ...this.context.toDto(this.targetType),
                formId: this.formId,
                containerId: this.containerId,
                containerItemId: this.containerItemId,
                page: this.page,
                pageId: this.pageId,
                parameter: this.parameter,
            };
            // only send availability expression if it is not null (default is empty string)
            if (this.availabilityExpression) {
                dto.availabilityExpression = this.availabilityExpression;
                dto.availabilityExpressionTree = this.availabilityExpressionTree
                    ? this.availabilityExpressionTree.toDto()
                    : null;
            }
            dto.enabled = this.enabled;
            dto.programId = this.programId;
            dto.targetType = this.targetType;

            return dto;
        };

        /**
         * Convert the DTO object into current object.
         * This function will wipe out any information you have on the current object
         * @param dto
         */
        DeepLinkModel.prototype.fromDto = function fromDto(dto, temporary) {
            this.context.fromDto(dto.context);
            this.formId = this.context.formId;
            this.containerId = this.context.containerId;
            this.containerItemId = this.context.containerItemId;
            this.workFlowName = this.context.workFlowName;
            this.page = this.context.page;
            this.pageId = this.context.pageId;
            this.availabilityExpression = dto.availabilityExpression;
            this.availabilityExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
            this.availabilityExpressionTree.fromDto(dto.availabilityExpressionTree);
            this.code = dto.code;
            this.createdById = dto.createdById;
            this.createdOn = dto.createdOn;
            this.enabled = dto.enabled;
            this.id = dto.id;
            this.programId = dto.programId;
            this.targetType = dto.targetType;
            this.updatedOn = dto.updatedOn;
            this.updatedById = dto.updatedById;
            this.parameter = this.context.parameter;

            if (!temporary) {
                this.lastSavedDto = dto;
            }

            return this;
        };

        /**
         * Reverts the object back to its previously saved state
         */
        DeepLinkModel.prototype.revert = function () {
            if (this.lastSavedDto) {
                this.fromDto(this.lastSavedDto);
            }
        };

        /**
         * Send the DTO to create a new deep link on the server
         */
        DeepLinkModel.prototype.create = function create() {
            const deferred = $q.defer();
            DeepLinkService.createNewLink(this.toDto())
                .then((response) => {
                    this.fromDto(response.data);
                    AlertService.success(
                        $translate.instant('deepLinks.alerts.createdLink', {
                            id: this.id,
                        }),
                    );
                    deferred.resolve(this);
                }, (error) => {
                    console.error('Error creating deep link', error);
                    this.revert();
                    deferred.reject();
                });
            return deferred.promise;
        };

        /**
         * Save the updated DTO to the server
         */
        DeepLinkModel.prototype.update = function update() {
            const deferred = $q.defer();
            DeepLinkService.updateExistingLink(this.id, this.toDto())
                .then((response) => {
                    this.fromDto(response.data);
                    AlertService.success(
                        $translate.instant('deepLinks.alerts.updatedLink', {
                            id: this.id,
                        }),
                    );
                    deferred.resolve(this);
                }, (error) => {
                    console.error('Error updating deep link', error);
                    this.revert();
                    deferred.reject();
                });
            return deferred.promise;
        };

        /****************************************
         * service return call
         ****************************************/
        return DeepLinkModel;
    });
