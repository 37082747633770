/**
 * Created by moustafabaiou on 2/15/17
 */
import template from './booleanSelection.html';
angular.module('acadiamasterApp')

    /**
     * directive for config a boolean flag
     */
    .directive('configBooleanSelection', () => ({
        link($scope) {
            $scope.hasChangeModel = $scope.flagFieldChange !== undefined;

            $scope.toggleSelection = () => {
                if($scope.isEditable !== undefined && !$scope.isEditable) {
                    return;
                }
                if ($scope.forDisplayOnly()) {
                    return;
                }
                if ($scope.hasChangeModel) {
                    if ($scope.flagFieldChange == null) {
                        $scope.flagFieldChange = !$scope.flagField;
                    } else {
                        $scope.flagFieldChange = !$scope.flagFieldChange;
                        if ($scope.flagFieldChange == $scope.flagField) {
                            $scope.flagFieldChange = null;
                        }
                    }
                } else {
                    $scope.flagField = !$scope.flagField;
                    if (_.isFunction($scope.changeCallBack)) {
                        $scope.changeCallBack($scope.flagField);
                    }
                }
            };

            $scope.isEnabled = () => {
                if ($scope.hasChangeModel && $scope.flagFieldChange != null) {
                    return $scope.flagFieldChange;
                }

                return $scope.flagField;
            };

            $scope.forDisplayOnly = () => $scope.displayOnly != null &&
                _.isFunction($scope.displayOnly) ? $scope.displayOnly() : $scope.displayOnly;

            $scope.resetChange = ($event) => {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.flagFieldChange = null;
            };
        },
        restrict: 'E',
        scope: {
            changeCallBack: '<?',
            description: '@?',
            displayOnly: '<?',
            displayStyle: '@',
            flagField: '=',
            flagFieldChange: '=?',
            iconSize: '@?',
            isRequired: '<?',
            title: '@?',
            isEditable: '<?',
        },
        template: template,
    }));
