'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('externalUserImport', {
                parent: 'entity',
                url: '/userimports',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.unauthenticated.title',
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/userImport/userImport.html',
                        controller: 'UserImportController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('externalImports');
                        return $translate.refresh();
                    }]
                }
            })

            .state('externalUserImport.new', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.externalUserImport.title',
                },
                parent: 'externalUserImport',
                url: '/new',
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/userImport/userImportCreateTask.html',
                        controller: 'UserImportCreateTaskController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function() {
                        return {

                        };
                    }],
                    isNew: [ function() {
                        return true;
                    }]
                }
            })
            .state('externalUserImport.track', {
                parent: 'externalUserImport',
                url: '/track/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.externalUserImport.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/userImport/userImportTaskTrack.html',
                        controller: 'UserImportTaskTrackController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        return $translate.refresh();
                    }],
                    taskId: ['$stateParams', function() {
                        return {

                        };
                    }],

                }
            });

    });
