/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for config of value with unit property
     */
        .directive('configValueWithUnitSummary', function (FormUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/valueWithUnitSummary.html',
                scope: {
                    valueModel: '='
                },
                link: function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;
                }
            };

        });

})();

