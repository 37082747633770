/**
 * constant used in Operation Status for current status
 */
(function () {
    'use strict';

    /*
        ERROR, STARTING, RUNNING, FAILED, COMPLETED, STOPPING, STOPPED, NOT_STARTED
     */
    angular.module('acadiamasterApp').constant('OperationStatusType', {
        'STARTING': 'STARTING',
        'ERROR': 'ERROR',
        'RUNNING': 'RUNNING',
        'FAILED': 'FAILED',
        'COMPLETED': 'COMPLETED',
        'STOPPING': 'STOPPING',
        'STOPPED': 'STOPPED',
        'NOT_STARTED': 'NOT_STARTED'
    });

})();

