import template from './elementConfig.action.banner.notification.html';
angular.module('acadiamasterApp')
    .directive('ruleElementConfigActionBannerNotification',
        (RuleUtilService, LocaleConstantsService, $timeout, ngDialog, vbrCommonUtil) => ({
            controller: ($scope) => {
                $scope.programId = $scope.action.getRoot().programId;
                $scope.cs = RuleUtilService.ruleConstants;
                $scope.lcs = LocaleConstantsService;
                $scope.isReady = false;
                $scope.vcu = vbrCommonUtil;

                $scope.states = {
                    isPopupOpen: false,
                };

                $scope.showAddTargetTypeDialog = ($event) => {
                    $scope.states.isPopupOpen = true;
                    ngDialog.openConfirm({
                        className: 'ngdialog-theme-plain custom-width-small',
                        preCloseCallback: () => {
                            $timeout(() => {
                                $scope.states.isPopupOpen = false;
                            });
                        },
                        scope: $scope,
                        templateUrl: 'admin-templates/site/components/treeEditorDialogs/addTargetType.template.html',
                    }).then((key) => {
                        $scope.addBannerNotificationTargetArea(key);
                    });
                    $event.stopPropagation();
                };

                $scope.form = {};
                $scope.form.options = {
                    form: $scope.action.form,
                    title: 'Form',
                    viewOnly: false,
                };

                $scope.$watch('form.options.form', (newForm) => {
                    $scope.action.form = newForm;
                });

                /**     Methods     */
                $scope.isTargetAreaExists = (key) => {
                    const currentTargetAreas = $scope.action.bannerNotificationTargetAreas;
                    if (currentTargetAreas) {
                        for (let i = 0; i < currentTargetAreas.length; i++) {
                            const targetArea = currentTargetAreas[i];
                            if (targetArea.bannerDisplayTargetArea.name === key) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                $scope.addBannerNotificationTargetArea = (key) => {
                    $scope.action.addBannerNotificationTargetArea($scope.cs.bannerDisplayTargetArea[key]);
                };

                $scope.isImageSupportedBannerType = () => {
                    const { bannerType } = $scope.action;
                    const { cs } = $scope;
                    return bannerType && (bannerType.name === cs.bannerType.OPEN_FORM.name
                        || bannerType.name === cs.bannerType.INFO.name
                        || bannerType.name === cs.bannerType.MANUAL_SCHEDULING_FORM.name);
                };

                $scope.isFormSupportedBannerType = () => $scope.action.bannerType && ($scope.action.bannerType.name === $scope.cs.bannerType.OPEN_FORM.name || $scope.action.bannerType.name === $scope.cs.bannerType.MANUAL_SCHEDULING_FORM.name);


                $scope.onBannerTypeChange = (bannerType) => {
                    if (!bannerType || ($scope.action.bannerType && bannerType.name === $scope.action.bannerType.name)) {
                        return;
                    }

                    if (bannerType.name === $scope.cs.bannerType.OPEN_FORM.name
                        || bannerType.name === $scope.cs.bannerType.MANUAL_SCHEDULING_FORM.name) {
                        $scope.action.bannerSpecificData.title = 'Banner Title';
                        $scope.action.bannerSpecificData.message = 'Banner Message';
                        $scope.action.bannerSpecificData.buttonText = 'Open';
                    } else if (bannerType.name === $scope.cs.bannerType.SCHEDULE_PHYSICAL_MEASUREMENT.name) {
                        $scope.action.bannerSpecificData.title = vbrCommonUtil.UNAVAILABLE;
                        $scope.action.bannerSpecificData.message = vbrCommonUtil.UNAVAILABLE;
                        $scope.action.bannerSpecificData.buttonText = vbrCommonUtil.UNAVAILABLE;
                    } else if (bannerType.name === $scope.cs.bannerType.INFO.name) {
                        $scope.action.bannerSpecificData.title = 'Banner Title';
                        $scope.action.bannerSpecificData.message = 'Banner Message';
                        $scope.action.bannerSpecificData.buttonText = vbrCommonUtil.UNAVAILABLE;
                    }
                };
            },
            restrict: 'E',
            scope: {
                action: '=',
                parentForm: '=',
            },

            template: template,
        }));
