import cs from '../const/programContainer.constants.service';

angular.module('acadiamasterApp').factory('ContainerItemLinkedEntityModel', (ModelServiceBase) => {
    /** *************************************************************
     * dto for container item linked enity model
     ************************************************************** */
    ContainerItemLinkedEntityModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

    /**
     * @constructor
     */
    function ContainerItemLinkedEntityModel(parent) {
        ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
        this.id = null;
        this.containerItemId = null;
        this.entityType = null;
        this.entityId = null;
        this.primary = false;
        this.contentValueKey = null;
    }

    /**
     * set entity data when a form or agreement is added to a container item as the primary entity
     * @param containerItemModel - container item
     */
    ContainerItemLinkedEntityModel.prototype.setPrimaryEntity = function (containerItemModel) {
        if (!containerItemModel) {
            return;
        }
        if (containerItemModel.form) {
            this.containerItemId = containerItemModel.id;
            this.entityId = containerItemModel.form.id;
            this.entityType = cs.containerItemLinkedEntityType.FORM;
            this.primary = true;
        }
        if (containerItemModel.agreement) {
            this.containerItemId = containerItemModel.id;
            this.entityId = containerItemModel.agreement.id;
            this.entityType = cs.containerItemLinkedEntityType.AGREEMENT;
            this.primary = true;
        }
    };

    /**
     * convert the current UI model to dto format
     * @param linkedEntityModel - container item linked entity
     * @param containerItemModel - container item
     */
    ContainerItemLinkedEntityModel.prototype.toDto = function (linkedEntityModel, containerItemModel) {
        const dto = {};
        if (!linkedEntityModel) {
            return null;
        }

        dto.id = linkedEntityModel.id;
        dto.containerItemId = containerItemModel.id;
        dto.entityId = linkedEntityModel.entityId;
        dto.entityType = linkedEntityModel.entityType;
        dto.primary = linkedEntityModel.primary;
        dto.contentValueKey = linkedEntityModel.contentValueKey;

        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto - linkedEntityDTO
     */
    ContainerItemLinkedEntityModel.prototype.fromDto = function (linkedEntityDTO) {
        this.id = linkedEntityDTO.id;
        this.containerItemId = linkedEntityDTO.containerItemId;
        this.entityType = linkedEntityDTO.entityType;
        this.entityId = linkedEntityDTO.entityId;
        this.primary = linkedEntityDTO.primary;
        this.contentValueKey = linkedEntityDTO.contentValueKey;
    };

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemLinkedEntityModel;
});

