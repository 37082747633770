/**
 * created by Jason.Cao on Nov 2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for navigation nodes and edges configuration
     */
        .directive('formElementConfigNavigation', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/navigation/elementConfig.navigation.html',
                scope: {
                    formMode: '=', // this is the form mode
                    selector: '='
                },
            };

        });

})();

