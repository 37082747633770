import cs from '../../const/programContainer.constants.service';

angular.module('acadiamasterApp').factory('ContainerItemExternalIntegrationConfig', (
    ContainerItemTypeSpecificConfig, LocalizedStringModel, ContainerIconModel,
) => {
    const classKey = cs.classes.classReferenceKey;
    ContainerItemExternalIntegrationConfig.inheritsFrom(ContainerItemTypeSpecificConfig);

    /**
     * @constructor
     */
    function ContainerItemExternalIntegrationConfig(parent) {
        ContainerItemTypeSpecificConfig.call(this, parent);

        this[classKey] = cs.classes.ContainerItemExternalIntegrationConfig;

        this.externalURL = null;
        this.title = new LocalizedStringModel();
        this.description = new LocalizedStringModel();
        this.externalLinkButtonLabel = new LocalizedStringModel();
        this.icon = new ContainerIconModel();
    }
    /**
     * convert the current UI model to dto format
     */
    ContainerItemExternalIntegrationConfig.prototype.toDto = function (files) {
        const dto = ContainerItemTypeSpecificConfig.prototype.toDto.call(this);
        dto.externalURL = this.externalURL;
        dto.title = this.title.toDto();
        dto.description = this.description.toDto();
        dto.externalLinkButtonLabel = this.externalLinkButtonLabel.toDto();
        dto.icon = this.icon.toDto(files);
        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemExternalIntegrationConfig.prototype.fromDto = function (dto) {
        ContainerItemTypeSpecificConfig.prototype.fromDto.call(this, dto);
        this.externalURL = dto.externalURL;
        this.title.fromDto(dto.title);
        this.description.fromDto(dto.description);
        this.externalLinkButtonLabel.fromDto(dto.externalLinkButtonLabel);
        this.icon.fromDto(dto.icon);
    };


    ContainerItemExternalIntegrationConfig.prototype.getDescriptionAsHtml = function () {
        const navLabel = getNavLabel(this.externalURL);
        const titleLabel = getTitleLabel(this.title.en);
        return `${titleLabel} | ${navLabel}`;
    };


    ContainerItemExternalIntegrationConfig.prototype._validateSelf = function () {
        let errMsg = null;
        if (this.title &&
            (this.title.en == null || this.title.en.length === 0)) {
            this._parent.setErrorMessage('Title is required');
        }
        else if (this.description &&
            (this.description.en == null || this.description.en.length === 0)) {
            this._parent.setErrorMessage('Description is required');
        }
        else if (this.externalLinkButtonLabel &&
            (this.externalLinkButtonLabel.en == null || this.externalLinkButtonLabel.en.length === 0)) {
            this._parent.setErrorMessage('External Nav button label is required');
        }
        else if ( this.externalURL == null || this.externalURL.length == 0) {
            this._parent.setErrorMessage('External URL is required');
        }
        return errMsg;
    };


    /** *************************************
     * private function call
     ************************************** */

    function getNavLabel(url) {
        if (!url) {
            return `<span class="label label-warning"">No URL</span>`;
        } else {
            return '<span class="label label-success" title="' + url + '">URL</span>';
        }
    }

    function getTitleLabel(title) {
        if (!title) {
            return `No title added`;
        } else {
            return shortenString(title, 30);
        }
    }

    function shortenString(input, maxLength) {
        if (input == null || maxLength == null || maxLength <= 3 || input.length <= maxLength) {
            return input;
        }

        return input.substring(0, maxLength - 3) + '...';
    }

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemExternalIntegrationConfig;
});
