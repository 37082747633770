'use strict';

angular.module('acadiamasterApp').controller('UnitGroupDialogController',
        function ($scope, $stateParams, $uibModalInstance, entity, isNew, UnitGroup, Unit, UnitCategory, UnitByCategory) {

            $scope.unitGroup = entity;
            $scope.unitcategorys = UnitCategory.query();
            $scope.isNew = isNew;

            if (!isNew) {
                $scope.unitGroup.$promise.then(function () {
                    loadUnits($scope.unitGroup.unitCategoryId);
                });
            }

            $scope.load = function (id) {
                UnitGroup.get({id: id}, function (result) {
                    $scope.unitGroup = result;
                });
            };

            var onSaveFinished = function (result) {
                $scope.$emit('acadiamasterApp:unitGroupUpdate', result);
                $uibModalInstance.close(result);
            };

            $scope.save = function () {
                if ($scope.unitGroup.id != null) {
                    UnitGroup.update($scope.unitGroup, onSaveFinished);
                } else {
                    UnitGroup.save($scope.unitGroup, onSaveFinished);
                }
            };

            $scope.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.onUnitCategoryChange = function () {
                loadUnits($scope.unitGroup.unitCategoryId);
            };

            function loadUnits(unitCategoryId) {
                if (unitCategoryId != null) {
                    $scope.units = UnitByCategory.get({categoryId: unitCategoryId});
                } else {
                    $scope.units = {};
                }
            }
        });
