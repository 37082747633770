(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');
    /**
     * custom pagination directive that should be used in almost every listing page
     */
    app.directive('vbrPagination', function($timeout) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/util/pagination.html',
            scope : {
                links : '=',
                page: '=',
                size: '=',
                loadPage : '=',
                searchResultCount : '=',
                displayPageCount: '=',
                noMargin: '='
            },
            controller: function($scope) {
                $scope.data = {
                    pageJumpTarget: null
                };

                $scope.onShowPopover = function() {
                    $scope.data.pageJumpTarget = null;
                    $timeout(function() {
                        var element = document.getElementById('paginationJumpToPageInput');
                        var input = angular.element(element);
                        input.focus();
                    }, 100);
                };

                $scope.isPageTargetInvalid = function() {
                    var target = $scope.data.pageJumpTarget;
                    var result = !target ||
                    target === $scope.page + 1 ||
                    target < 1 ||
                    target > $scope.pageCount;
                    return result;
                };

                $scope.jumpToPage = function(target) {
                    if(!$scope.isPageTargetInvalid()) {
                        // fake a click to force the popover to close (trust me, this was the best way.)
                        document.body.click();
                        $scope.loadPage(target - 1);
                    }
                };

                $scope.getPageCount = function() {
                    return Math.max(1, Math.ceil($scope.searchResultCount / $scope.size));
                }
            }
        };
    });

})();
