/**
 * Created by moustafabaiou on 2/15/17.
 */
'use strict';

angular.module('acadiamasterApp')
    /**
     * take in a number and convert it in a more readable format
     */
    .filter('fileSizeDisplayString', function () {
        return function (size) {
            return getFileSizeDisplayString(size);
        }

        function getFileSizeDisplayString(size) {
            var cutoff, i, selectedSize, selectedUnit, unit, units, _i, _len;
            selectedSize = 0;
            selectedUnit = "b";
            var filesizeBase = 1000;
            if (size > 0) {
                units = ['TB', 'GB', 'MB', 'KB', 'b'];
                for (i = _i = 0, _len = units.length; _i < _len; i = ++_i) {
                    unit = units[i];
                    cutoff = Math.pow(filesizeBase, 4 - i) / 10;
                    if (size >= cutoff) {
                        selectedSize = size / Math.pow(filesizeBase, 4 - i);
                        selectedUnit = unit;
                        break;
                    }
                }
                selectedSize = Math.round(10 * selectedSize) / 10;
            }
            return selectedSize + " " + selectedUnit;
        }
    });