/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 * drc sync user directive
 */
(function () {
    angular.module('acadiamasterApp').directive('vbrDrcSyncUserAndForm', (
        drcService, RegistrationCountHolder, OperationStatus, AlertService, drcSyncUtilService, Principal, FormLoadUtil, $translate, FormUtilService) => {
        /** *************************************
         * factory return call
         ***************************************/

        return {
            link : function ($scope) {
                // check status every few seconds
                $scope.checkStatusInterval = 10000;

                // flag to kept track if modal window is open
                $scope.windowOpen = false;

                $scope.data = {
                    isUserAdmin : false,
                };

                Principal.identity()
                    .then(account => {
                        if (account) {
                            let isAdmin = Principal.ifAnyAuthority([ 'ROLE_ADMIN' ]);
                            $scope.data.isUserAdmin = isAdmin;
                            if (isAdmin) {
                                checkFormCounts($scope);
                            } else {
                                $scope.formData.isReady = true;
                            }

                            checkUserCounts($scope);
                            checkFormEntryCounts($scope);
                        }
                    });

                $scope.partialFormOptions = {
                    form             : null,
                    formCategory     : null,
                    onChangeCallBack : function () {
                        if (this.form) {
                            $scope.partialFormEntries.formIdSyncPartial = this.form.id;
                        } else {
                            $scope.partialFormEntries.formIdSyncPartial = null;
                        }
                    },
                    title    : $translate.instant('agreements.create.entries.form.hint'),
                    viewOnly : false,
                };
                // all the user sync data are stored here
                $scope.userData = {
                    countHolder     : new RegistrationCountHolder(),
                    hasUnregistered : false,
                    isReady         : false,
                    operationId     : null,
                    operationStatus : new OperationStatus(),
                    userIdsToSync   : null,
                    userSyncStatus  : {
                        addFailedId : function (id) {
                            this.failedIds.push(this.remainingIds[0]);
                            this.remainingIds.shift();
                        },
                        addSuccessId : function () {
                            this.successIds.push(this.remainingIds[0]);
                            this.remainingIds.shift();
                        },
                        failedIds         : [],
                        lastOneUserStatus : null,

                        remainingIds : [],

                        reset : function (remainingIds) {
                            this.lastOneUserStatus = null;
                            this.successIds = [];
                            this.failedIds = [];
                            this.remainingIds = remainingIds == null ? [] : remainingIds;
                        },

                        successIds : [],
                    },
                };

                // all the form sync data are stored here
                $scope.formData = {
                    countHolder     : new RegistrationCountHolder(),
                    formIdsToSync   : null,
                    formSyncStatus  : null,
                    hasUnregistered : false,
                    isReady         : false,
                    operationId     : null,
                    operationStatus : new OperationStatus(),
                };

                // all the form entry sync data are stored here
                $scope.formEntryData = {
                    countHolder         : new RegistrationCountHolder(),
                    formEntrySyncStatus : null,
                    hasUnregistered     : false,
                    isReady             : false,
                    operationId         : null,
                    operationStatus     : new OperationStatus(),
                };

                // partialFormEntries sync data are stored here
                $scope.partialFormEntries = {
                    checkRemaingFlag    : true,
                    formEntrySyncStatus : null,
                    formIdSyncPartial   : null,
                    hasUnregistered     : false,
                    includePartial      : false,
                    operationId         : null,
                    operationStatus     : new OperationStatus(),
                    userIdsToSync       : null,
                };

                $scope.syncUsers = function () {
                    syncUsers($scope);
                };

                $scope.syncUsersById = function () {
                    syncUsersById($scope);
                };

                $scope.syncForms = function () {
                    syncForms($scope);
                };

                $scope.syncFormsById = function () {
                    syncFormsById($scope);
                };

                $scope.syncFormEntries = function () {
                    syncFormEntries($scope);
                };

                $scope.partialFormEntriesSync = function () {
                    partialFormEntriesSync($scope);
                };
            },
            restrict    : 'E',
            scope       : {},
            templateUrl : 'admin-templates/site/drc/dataSync/drc.syncUserAndForm.html',
        };

        function checkFormCounts ($scope) {
            drcSyncUtilService.checkCount($scope.formData, drcService.syncFormCheckCount);
        }

        function checkFormEntryCounts ($scope) {
            drcSyncUtilService.checkCount($scope.formEntryData, drcService.syncFormEntryCheckCount);
        }

        function checkUserCounts ($scope) {
            drcSyncUtilService.checkCount($scope.userData, drcService.syncUserCheckCount);
        }

        /*
         * start the sync form call
         * @param $scope scope object
         */
        function syncForms ($scope) {
            drcSyncUtilService.openModelWindow($scope, $scope.formData, 'Sync Form Status', checkFormCounts);
            drcService.syncAllForms()
                .then(response => {
                    $scope.formData.operationId = response.data?.operationId;
                    drcSyncUtilService.checkAndShowStatus($scope, $scope.formData);
                });
        }

        /*
         * start the sync form entry call
         * @param $scope scope object
         */
        function syncFormEntries ($scope) {
            drcSyncUtilService.openModelWindow($scope, $scope.formEntryData, 'Sync Form Entries Status', checkFormEntryCounts);

            // we use the same sync user call here because we need to register the user first if the user is not
            // registered, and sync user already handle sync form entry
            drcService.syncUser(null)
                .then(response => {
                    $scope.formEntryData.operationId = response.data?.operationId;
                    drcSyncUtilService.checkAndShowStatus($scope, $scope.formEntryData);
                });
        }

        /*
         * start the sync user call
         * @param $scope scope object
         */
        function syncUsers ($scope) {
            drcSyncUtilService.openModelWindow($scope, $scope.userData, 'Sync User Status', checkUserCounts);
            drcService.syncUser(null)
                .then(response => {
                    $scope.userData.operationId = response.data?.operationId;
                    drcSyncUtilService.checkAndShowStatus($scope, $scope.userData);
                });
        }

        /*
         * run data sync for a list of users
         * @param $scope scope object
         */
        function syncUsersById ($scope) {
            let commaSeparatedIds = $scope.userData.userIdsToSync;
            let startUserIds = commaSeparatedIds.split(/[\s,]+/);

            if (startUserIds == null || startUserIds.length == 0) {
                AlertService.warning('At least one user id should be synced');
                return;
            }

            let userIds = [];
            _.forEach(startUserIds, userId => {
                if (userId == null) {
                    return;
                }

                userId = userId.trim();
                if (userId.length == 0) {
                    return;
                }

                if (parseInt(userId) != userId) {
                    AlertService.warning(`${userId } is not a valid id`);
                    return;
                }

                userIds.push(parseInt(userId));
            });

            $scope.userData.userSyncStatus.reset(userIds);

            syncUsersByIdHelper($scope.userData.userSyncStatus);
        }
        function resetpartialFormData ($scope) {
            $scope.partialFormEntries.includePartial = false;
            $scope.partialFormEntries.formIdSyncPartial = null;
            $scope.partialFormEntries.userIdsToSync = null;
            $scope.partialFormOptions.form = null;
        }
        function partialFormEntriesSync ($scope) {
            let commaSeparatedIds = $scope.partialFormEntries.userIdsToSync;
            if (commaSeparatedIds != null) {
                // eslint-disable-next-line vars-on-top
                var startUserIds = commaSeparatedIds.split(/[\s,]+/);
            }
            let userIds = [];
            _.forEach(startUserIds, userId => {
                if (userId == null) {
                    return;
                }

                userId = userId.trim();
                if (userId.length == 0) {
                    return;
                }

                if (parseInt(userId) != userId) {
                    AlertService.warning(`${userId } is not a valid id`);
                    return;
                }

                userIds.push(parseInt(userId));
            });
            let partialFormEntriesObj = {
                formId                 : $scope.partialFormEntries.formIdSyncPartial,
                syncFormEntrySelectDTO :
                {
                    includePartial : true,
                },
                userIds : userIds,
            };

            drcSyncUtilService.openModelWindow($scope, $scope.partialFormEntries, 'Partial Form Entries Sync Status', checkUserCounts);
            drcService.syncPartialForms(partialFormEntriesObj)
                .then(response => {
                    $scope.partialFormEntries.operationId = response.data?.operationId;
                    drcSyncUtilService.checkAndShowStatus($scope, $scope.partialFormEntries);
                });

            resetpartialFormData($scope);
        }
        /*
         * helper function that should be called recursively for syncing user
         * @param userSyncStatus - user sync status object
         */
        function syncUsersByIdHelper (userSyncStatus) {
            if (userSyncStatus == null || userSyncStatus.remainingIds.length == 0) {
                AlertService.success('all users have been synced');
                return;
            }

            drcService.syncUser(userSyncStatus.remainingIds[0])
                .then(response => {
                    AlertService.success(`user sync for ${ userSyncStatus.remainingIds[0] } is successful, ${
                        userSyncStatus.remainingIds.length - 1 } users remaining`);
                    userSyncStatus.lastOneUserStatus = response.status;

                    userSyncStatus.addSuccessId();

                    // recursively call itself and sync the next user
                    syncUsersByIdHelper(userSyncStatus);
                }).catch(errResponse => {
                    console.error(errResponse);
                    AlertService.warning(`user sync for ${ userSyncStatus.remainingIds[0] } has failed, ${
                        userSyncStatus.remainingIds.length - 1 } users remaining, reason: ${ errResponse.statusText } / ${ errResponse.status}`);

                    userSyncStatus.addFailedId();

                    // recursively call itself and sync the next user
                    syncUsersByIdHelper(userSyncStatus);
                });
        }

        /*
        * Run data sync on list of form ids
        * @param $scope scope object
        */
        function syncFormsById ($scope) {
            let commaSeparatedIds = $scope.formData.formIdsToSync;
            let formIds = commaSeparatedIds.split(',');

            if (formIds == null || formIds.length == 0) {
                AlertService.warning('At least one form id should be synced');
                return;
            }

            for (let i = 0; i < formIds.length; i++) {
                let formId = formIds[i];
                if (formId == null || formId.length == 0) {
                    AlertService.warning('Form ids are not provided properly in comma separated way');
                    return;
                }

                if (parseInt(formId) != formId) {
                    AlertService.warning(`${formId } is not a valid id`);
                    return;
                }
            }

            drcService.syncFormsById(formIds)
                .then(response => {
                    AlertService.success('Form sync successful');
                    $scope.formData.formSyncStatus = response.status;
                });
        }
    });
}());
