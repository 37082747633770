'use strict';

angular.module('acadiamasterApp')
    .controller('ScheduleRuleExecutionPageAuditsController',
        function ($scope, $stateParams, ScheduleRuleExecutionAuditsService, ParseLinks, SCHEDULE_RULE_EXECUTION_AUDIT_CONSTANTS,
                  SEARCH_FILTER_CONSTANTS) {
            $scope.auditId = $stateParams.auditId;
            $scope.cs = SCHEDULE_RULE_EXECUTION_AUDIT_CONSTANTS;
            $scope.fields = [
                $scope.cs.FIELDS.ID,
                $scope.cs.FIELDS.PAGE_NUMBER,
                $scope.cs.FIELDS.TOTAL_NUMBER_OF_USERS,
                $scope.cs.FIELDS.NUMBER_OF_FAILED_USERS,
                $scope.cs.FIELDS.PUBLISHED_ON,
                $scope.cs.FIELDS.EXECUTION_STARTED_ON,
                $scope.cs.FIELDS.EXECUTION_FINISHED_ON,
                $scope.cs.FIELDS.VIEW_WORKFLOW_INSTANCE_AUDITS,
            ];

            $scope.pageSizeOptions = [
                50, 100, 200
            ];

            $scope.searchData = {
                page: 0,
                size: $scope.pageSizeOptions[0],
                orderBy: $scope.cs.FIELDS.ID.id,
                ascending: false,
                searchResultCount: 0
            };

            $scope.filteredFields = [
                SEARCH_FILTER_CONSTANTS.FIELDS.ID,
                SEARCH_FILTER_CONSTANTS.FIELDS.PAGE_NUMBER,
                SEARCH_FILTER_CONSTANTS.FIELDS.FAILED_PAGE_ONLY
            ];
            $scope.filteredValues = {};

            $scope.setOrderBy = function (newOrderBy) {
                if ($scope.searchData.orderBy == newOrderBy) {
                    $scope.searchData.ascending = !$scope.searchData.ascending;
                }
                else {
                    $scope.searchData.ascending = true;
                    $scope.searchData.orderBy = newOrderBy;
                }
                $scope.loadAll(0);
            };

            $scope.loadAll = function (page) {
                $scope.isLoading = true;
                $scope.searchData.page = page || 0;
                const params = {
                    page: $scope.searchData.page,
                    size: $scope.searchData.size,
                    orderBy: $scope.searchData.orderBy,
                    ascending: $scope.searchData.ascending
                };
                Object.keys($scope.filteredValues).forEach(function (key) {
                    params[key] = $scope.filteredValues[key];
                });

                ScheduleRuleExecutionAuditsService.getPageAudits($scope.auditId, params).then((response) => {
                    const dataArray = response.data;
                    if(dataArray) {
                        $scope.pageAudits = dataArray;
                        $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                        $scope.links = ParseLinks.parse(response.headers('link'));
                    }

                }, (error) => {
                    console.error('Error loading schedule rule execution page audits', error);
                }).finally(() => {
                    $scope.isLoading = false;
                });

            };

        });
