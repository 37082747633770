/**
 * directive for list container nav list
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('listContainerNavList', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/listContainer/listContainer.navList.html',
            scope: {
                listContainer: '='
            }
        };
    });
})();

