(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of a list of state rules
     */
        .directive('treeNodeStateRuleList', function (ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/stateRuleList.html',
                scope: {
                    mode: '=',
                    selector: '=',
                    currentTreeName : '=',
                    treeSelection : '='
                },
                link: function ($scope) {
                    $scope.delete = function() {
                        ngDialog.openConfirm({
                            template: 'admin-templates/site/forms/treeNode/deleteAllStateRules.html',
                            controller: 'StateRuleDeleteController',
                        }).then(function() {
                            $scope.mode.removeAllStateRules($scope.selector);
                        });
                    };
                }
            };
        });
})();

