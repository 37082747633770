/**
 * common directive to configure a business rule action or PTF action of the type of
 * "Evaluate Personalized Container"
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('actionEvaluatePersonalizedContainer', function () {
            return {
                templateUrl: 'admin-templates/site/common/action/personalizedContainer/evaluatePersonalizedContainer.html',
                restrict: 'E',
                scope: {
                    action: '<',
                },
                link: function ($scope) {
                    $scope.containerOptions = {
                        getContainer: () => $scope.action.getContainer(),
                        setContainer: (container) => $scope.action.setContainer(container),
                        programId: $scope.action.getProgramId(),
                    };
                }
            };

        });
})();
