/* eslint-disable no-use-before-define */
import cs from '../../const/programContainer.constants.service';

angular.module('acadiamasterApp').factory('ContainerItemContentConfig', (ContainerItemTypeSpecificConfig, LocalizedStringModel) => {
    const classKey = cs.classes.classReferenceKey;
    ContainerItemContentConfig.inheritsFrom(ContainerItemTypeSpecificConfig);

    /*
     * @constructor
     */
    function ContainerItemContentConfig (parent) {
        ContainerItemTypeSpecificConfig.call(this, parent);

        this[classKey] = cs.classes.ContainerItemContentConfig;

        this.header = new LocalizedStringModel();
        this.url = null;
        this.showHeader = false;
        this.enableCommunicationPreference = false;
        this.addBackingData = false;
        this.sourceId = null;
        this.dataSourceType = null;
        this.formId = null;
        this.fullHeightConfigured = false;
        this.fixedHeight = new Map();
    }

    /*
     * convert the current UI model to dto format
     */
    ContainerItemContentConfig.prototype.toDto = function (files) {
        const dto = ContainerItemTypeSpecificConfig.prototype.toDto.call(this);
        dto.url = this.url;
        dto.header = this.showHeader ? this.header.toDto() : null;
        dto.enableCommunicationPreference = this.enableCommunicationPreference;
        dto.addBackingData = this.addBackingData;

        if (dto.enableCommunicationPreference) {
            dto.addBackingData = false;
        } else {
            dto.addBackingData = this.addBackingData;
        }

        if (dto.addBackingData) {
            dto.sourceId = this.sourceId;
            dto.dataSourceType = this.dataSourceType;
            dto.formId = this.formId;
        }

        dto.fullHeightConfigured = this.fullHeightConfigured;
        if (!dto.fullHeightConfigured) {
            dto.fixedHeight = this.fixedHeight;
        }
        return dto;
    };

    /*
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemContentConfig.prototype.fromDto = function (dto) {
        ContainerItemTypeSpecificConfig.prototype.fromDto.call(this, dto);

        this.url = dto.url;
        this.header.fromDto(dto.header);
        this.showHeader = this.header.hasValue();
        this.enableCommunicationPreference = dto.enableCommunicationPreference;
        this.addBackingData = dto.addBackingData;
        this.sourceId = dto.sourceId;
        this.dataSourceType = dto.dataSourceType;
        this.formId = dto.formId;
        this.fullHeightConfigured = dto.fullHeightConfigured;
        this.fixedHeight = dto.fixedHeight;

        // ensure backwards compatibility with old height configuration
        if (this.fixedHeight == null) {
            this.fixedHeight = new Map();
        }
        if (!this.fullHeightConfigured) {
            if (this.fixedHeight[cs.platformsObj.web] == null) {
                this.fixedHeight[cs.platformsObj.web] = this.height;
            }
            if (this.fixedHeight[cs.platformsObj.mobile] == null) {
                this.fixedHeight[cs.platformsObj.mobile] = this.height;
            }
        }
    };

    ContainerItemContentConfig.prototype.getDescriptionAsHtml = function () {
        const headerLabel = getHeaderLabel(this.header.en);
        const navLabel = getNavLabel(this.url);
        return `${headerLabel} | ${navLabel}`;
    };

    ContainerItemContentConfig.prototype._validateSelf = function () {
        let errMsg = null;
        this.clearError();
        if (this.showHeader && (this.header == null || this.header.en == null || this.header.en.length === 0)) {
            this._parent.setErrorMessage('header is required');
        }
        if (!this.enableCommunicationPreference && this.addBackingData && !this.dataSourceType) {
            this._parent.setErrorMessage('external source is required');
        }
        if (this.url == null || this.url.length === 0) {
            this._parent.setErrorMessage('Valid URL is required');
        }
        // validate to make sure form is selected if datasource is fhir base type
        if (this.addBackingData && cs.hasFHIRBaseExternalSource(this.dataSourceType) && this.formId == null) {
            this._parent.setErrorMessage('form id is required');
        }
        if (!this.fullHeightConfigured
            && (this.fixedHeight == null
            || this.fixedHeight[cs.platformsObj.web] == null
            || this.fixedHeight[cs.platformsObj.web].length === 0
            || this.fixedHeight[cs.platformsObj.mobile] == null
            || this.fixedHeight[cs.platformsObj.mobile].length === 0)
        ) {
            this._parent.setErrorMessage('valid height is required');
        }

        return errMsg;
    };

    /** *************************************
     * private function call
     ************************************** */

    function getNavLabel (url) {
        if (!url) {
            return '<span class="label label-warning"">No URL</span>';
        }
        return `<span class="label label-success" title="${ url }">URL</span>`;
    }

    function getHeaderLabel (header) {
        if (!header) {
            return 'No header added';
        }
        return shortenString(header, 30);
    }

    function shortenString (input, maxLength) {
        if (input == null || maxLength == null || maxLength <= 3 || input.length <= maxLength) {
            return input;
        }

        return `${input.substring(0, maxLength - 3) }...`;
    }

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemContentConfig;
});
