/* eslint-disable angular/window-service */
/**
 * AuthServer Provider - TODO: DOCUMENTATION
 */

angular.module('acadiamasterApp')
    .factory('AuthServerProvider', ($http, localWeb, localStorageService, ModalQueueService, sessionCache, $rootScope, $cookies, CookieService, $window, SSOService) => {
        function logUserOut () {
            localStorageService.clearAll();
            sessionCache.removeAll();
            ModalQueueService.clear();
            $cookies.remove('token');
            // Think we can just reload on base window
            $window.location.href = '/';
        }

        return {
            getToken () {
                return localStorageService.get('token');
            },
            hasValidToken () {
                const token = this.getToken();
                return token && token.expires && token.expires > new Date().getTime();
            },
            login (credentials, isWebUser) {
                let data = `username=${encodeURIComponent(credentials.username)}&password=${
                    encodeURIComponent(credentials.password)}`;

                if (credentials.twoFactorAuthCode != null) {
                    data = `${data }&twoFactorAuthCode=${encodeURIComponent(credentials.twoFactorAuthCode)}`;
                }

                const headers = {
                    'Accept'       : 'application/json',
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=utf-8',
                };

                if (isWebUser) {
                    headers.appTypeId = 1;
                }

                return $http.post(localWeb.createUrl('api/authenticate'), data, {
                    headers,
                }).then(response => {
                    localStorageService.clearAll();
                    sessionCache.removeAll();
                    localStorageService.set('token', response.data?.token);
                    localStorageService.set('user', response.data?.baseUserDTO);
                    localStorageService.set('subscriptions', response.data?.activeProgramSubscriptions);
                    CookieService.refresh('token', response.data?.sessionTimeout - new Date().getTime());

                    $cookies.put('token', response.data?.token, { secure : true });
                    return response.data;
                });
            },
            logout (isWebUser) {
                if (SSOService.isEnable()) {
                    window.keycloakAuth.logout();
                    delete window.CONFIG;
                    delete window.keycloakAuth;
                }
                sessionStorage.clear();
                return $http({
                    data    : '',
                    headers : {
                        'appTypeId'    : isWebUser ? 1 : null,
                        'Content-Type' : 'application/json; charset=utf-8',
                    },
                    method : 'POST',
                    url    : localWeb.createUrl('api/logout'),
                }).then(response => {
                    logUserOut();
                    $rootScope.$broadcast('logout');
                    return response.data;
                }).catch(() => {
                    logUserOut();
                    console.log('ERROR: User could not be logged out');
                });
            },
        };
    });
