angular.module('acadiamasterApp')
    .controller('ProgramReplayDetailConfirmController', function ($scope, ProgramVXPService, AlertService) {
        $scope.program = $scope.ngDialogData;
        $scope.includeTenant = true;

        $scope.replay = function () {
            ProgramVXPService.replayProgramDetail($scope.program.id, $scope.includeTenant).then(function (resp) {
                AlertService.success(getMessageFromResponse(resp.data) +
                    '<br>You can verify at <a href="/#/external"><b>External Log</b></a> (it can be delayed up to 30 seconds)');
                $scope.confirm(resp.data);
            });
        };

        /**
         * convert status map into human readable format
         * @param statusMap -  a name and timestamp for operation done
         */
        function getMessageFromResponse(statusMap) {
            let msg = '';
            _.forEach(statusMap, (k, v) => {
                msg += `<strong>${v}</strong> performed on ${new Date(k)}<br>`;
            });
            
            return msg;
        }
    });
