
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('InnerManageConfigModel', function () {

        function InnerManageConfigModel(manageOption) {
            this.type = null;
            this.buttonLabel = null;
            this.textLabel = null;
            this.primaryFlag = null;
            this.showInContainer = false;
            this.orderNumber = null;

            if (manageOption) {
                this.fromDto(manageOption);
            }
        }

        InnerManageConfigModel.prototype.toDto = function () {
            let dto = {};
            dto.type = this.type;
            dto.buttonLabel = this.buttonLabel;
            dto.textLabel = this.textLabel;
            dto.primaryFlag = this.primaryFlag;
            dto.orderNumber = this.orderNumber;
            dto.showInContainer = this.showInContainer;
            return  dto;
        };

        InnerManageConfigModel.prototype.fromDto = function (dto) {
            this.type = dto.type;
            this.buttonLabel = dto.buttonLabel;
            this.textLabel = dto.textLabel;
            this.primaryFlag = dto.primaryFlag;
            this.orderNumber = dto.orderNumber;
            this.showInContainer = dto.showInContainer;
        };

        return InnerManageConfigModel;
    });

})();