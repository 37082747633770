/**
 * Created by Jason Cao on 08/12/2019
 *
 * directive for configuring an state with non completed messages
 * 1. non complete message
 */
angular.module('acadiamasterApp').directive(
    'containerDisplayConfigStateNonCompleteMessage',
    (LocaleConstantsService) => ({
        link: ($scope) => {
            $scope.lcs = LocaleConstantsService;
        },
        restrict: 'E',
        scope: {
            // @Required - display config state
            displayConfigState: '=',
            previewUrl: '@',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
            + 'config/containerDisplayConfig/' +
            'containerDisplayConfigStateNonCompleteMessage.html',
    }));
