(function () {
    'use strict';
    var app = angular.module('acadiamasterApp');

    /**
     * utility service for view mode generator that's used exclusively for admin portal
     */
    app.factory('ViewModeGeneratorService', function (AlertService, FormModelServiceComponent, FormScriptActionUtil,
                                                      ViewModeGeneratorUtilService, FieldWithCondition, FormConstants) {
        return {
            generateViewMode: generateViewMode
        };

        /**
         * generate the view mode
         * @param formVersion - form version object
         * @param config - config object for generator
         */
        function generateViewMode(formVersion, config) {
            // we assume the pages are sorted before the function is called so they are already
            // in the order that user wants for view mode
            var editModePages = formVersion.editMode.pages;

            // clear all the pages in exist view mode
            var currentViewPage = ViewModeGeneratorUtilService.resetModeWithSinglePage(formVersion.viewMode, 'main page for view mode');

            generateViewModeOnPage(currentViewPage, editModePages, config);
        }

        /**
         * generate view mode on a specific view page
         * @param currentViewPage - current view page
         * @param editModePages - edit mode pages
         * @param config - config objects
         */
        function generateViewModeOnPage(currentViewPage, editModePages, config) {
            var pagesPerSection;
            var totalSections;

            // getting a list of all components, each component represent one page or a number of related pages
            var generatedComponents = generateViewModeComponents(editModePages, config.includeRegularLabels);

            var totalPages = generatedComponents.length;

            if (config.groupPagesIntoSections) {
                pagesPerSection = config.numOfPagesPerSection;
                totalSections = (totalPages - totalPages % pagesPerSection) / pagesPerSection;
                if (totalPages % pagesPerSection > 0) {
                    totalSections++;
                }
            }
            else {
                pagesPerSection = totalPages;
                totalSections = 1;
            }
            // create the list of sections and put pages in edit mode into components in view mode
            for (var i = 0; i < totalSections; i++) {
                // create the new section and clear out the components
                var newSection = currentViewPage.addSection();
                newSection.formComponents = [];

                var startGeneratedComponentIndex = pagesPerSection * i;
                var endGeneratedComponentIndex = startGeneratedComponentIndex + pagesPerSection;
                endGeneratedComponentIndex = Math.min(totalPages, endGeneratedComponentIndex);
                newSection.name = 'section for page ' + startGeneratedComponentIndex + ' to ' +
                    (endGeneratedComponentIndex - 1);

                for (var j=startGeneratedComponentIndex; j<endGeneratedComponentIndex; j++) {
                    var component = generatedComponents[j];
                    component._parent = newSection;
                    newSection.addComponent(component);
                }
            }
        }

        /**
         * generate view mode components for the edit mode pages from a list of edit mode pages
         * @param editModePages - edit mode pages
         * @param includeRegularLabel - flag to indicate if regular label should be included to not,
         *                              if it is missing, assuming it is false
         * @return [] - a list of view mode components, each component represent 1 page or a list of related pages
         * (with same title) in edit mode
         */
        function generateViewModeComponents(editModePages, includeRegularLabel) {
            var fieldsToAdd = getFieldsToAddFromPage(editModePages, includeRegularLabel);

            var fieldsWithCondition = fieldsToFieldsWithCondition(fieldsToAdd);

            return fieldsWithConditionToComponents(fieldsWithCondition);
        }

        /**
         * convert a list of fields into fields with condition
         * @param fields - fields to be converted
         * @returns [] - a list of fieldsWithCondition objects which is a simple wrapper around a field with visibility condition
         */
        function fieldsToFieldsWithCondition(fields) {
            if (fields==null || fields.length==0) {
                return null;
            }

            var fieldsWithCondition = [];
            var lastTextLabelFieldWithCondition = null;

            var skipNext = false;
            for (var i=0; i<fields.length; i++) {
                if (skipNext) {
                    skipNext = false;
                } else {
                    var field = fields[i];
                    if (ViewModeGeneratorUtilService.isLabelField(field)) {
                        // label field is always followed by a input field, we just need to know if this label field is the
                        // same as last one

                        if (lastTextLabelFieldWithCondition == null || !lastTextLabelFieldWithCondition.isTextEqual(field)) {
                            // can't reuse the last text label field, need to create new text label field
                            lastTextLabelFieldWithCondition = new FieldWithCondition(field);
                            fieldsWithCondition.push(lastTextLabelFieldWithCondition);
                        }

                        // add the next field directly, it should always be there
                        if (fields[i + 1] != null) {
                            lastTextLabelFieldWithCondition.addTarget(fields[i + 1]);
                            fieldsWithCondition.push(new FieldWithCondition(fields[i + 1]));
                            skipNext = true;
                        }
                    } else {
                        lastTextLabelFieldWithCondition = null;
                        fieldsWithCondition.push(new FieldWithCondition(field));
                    }
                }
            }

            return fieldsWithCondition;
        }


        /**
         * convert fieldWithCondition object to component, any field in the same page
         * are grouped into the same component
         * @param fieldsWithCondition - fields with condition list
         */
        function fieldsWithConditionToComponents(fieldsWithCondition) {
            if (fieldsWithCondition==null || fieldsWithCondition.length == 0) {
                return null;
            }
            var components = [];
            var pageId = null;
            var fieldsForPage = [];
            // a map of the fields that has already been reference before by a label
            // this map is used to determine if we should add divider above or not
            var referenceFieldIdMap = {};


            for (var i = 0; i < fieldsWithCondition.length; i++) {
                var fc = fieldsWithCondition[i];
                if (fc.getPageLocalId() != pageId) {
                    // starting a new page

                    // add old component to components first if it's not null
                    if (fieldsForPage.length > 0) {
                        components.push(createComponentWithFields(fieldsForPage, referenceFieldIdMap));
                    }

                    fieldsForPage = [];
                    pageId = fc.getPageLocalId();
                }

                fieldsForPage.push(fc);
            }

            if (fieldsForPage.length > 0) {
                components.push(createComponentWithFields(fieldsForPage, referenceFieldIdMap));
            }

            return components;
        }

        /**
         * creating a view mode component for a list of form fields with conditions
         * we assume all the form fields are from the same page
         * @param fieldsWithConditions - edit mode page
         * @param referenceFieldIdMap - a map of field ids that has already been included in the condition of a label
         *
         * @return {*} a component with a number of reference fields to the fields in the field list
         */
        function createComponentWithFields(fieldsWithConditions, referenceFieldIdMap) {
            var component = new FormModelServiceComponent.ComponentModel();

            var page = fieldsWithConditions[0].getPage();
            var pageName = page.name;
            var componentName = 'component for ';
            if (pageName == null || pageName == '') {
                componentName += 'page (' + page.localId + ')';
            }
            else {
                componentName += 'page (' + pageName + ')';
            }

            component.name = componentName;

            _.forEach(fieldsWithConditions, function(fc) {
                // add a reference field for each component
                if(fc.field.nodeType !== 'component'){
                    fc.addReferenceToComponent(component, referenceFieldIdMap);
                }
                else{
                    fc.addMatrixResponseField(component, referenceFieldIdMap);
                }

            });

            return component;
        }


        /**
         * getting a list of fields that needs to be moved to or referenced from view mode from pages in edit mode
         * @param editModePages - edit mode pages
         * @param includeRegularLabels - include regular label or not
         * @returns {Array} - a list of fields that needs to be moved/referenced to view mode
         */
        function getFieldsToAddFromPage(editModePages, includeRegularLabels) {
            var fields = [];

            _.forEach(editModePages, function(page) {
                _.forEach(page.sections, function (s) {
                    _.forEach(s.formComponents, function (c) {
                        if(c.componentType === FormConstants.componentType.MATRIX_QUESTIONNAIRE){
                            fields.push(c);
                        }
                        _.forEach(c.formComponentFields, function (field) {
                            if (field.isInputField()) {
                                addFieldsIfNeeded(fields, field, includeRegularLabels);
                            }
                        });
                    });
                });
            });

            return fields;
        }

        /**
         * adding the fields (label field + input field) into the fields list
         * @param fields - fields list
         * @param inputField - input field to be processed
         * @param includeRegularLabels - flag to indicate if we should include a regular text label before the input field or not
         *            note: we only consider fields within the same component as the field before at this point
         */
        function addFieldsIfNeeded(fields, inputField, includeRegularLabels) {
            if (inputField == null || inputField.type == FormConstants.fieldsType.EMBEDDED_TEXT_INPUT || inputField.type == FormConstants.fieldsType.MATRIX_QUESTION) {
                return;
            }

            // include regular label is set to true and the input field already have subfield for label,
            // note, if input field does not have subfield, then the label above it is automatically merged
            // in as subfield when we generate the view mode later, so don't need to add it now
            if (includeRegularLabels && ViewModeGeneratorUtilService.isInputFieldWithSubField(inputField)) {
                var fieldBefore = FormScriptActionUtil.findFieldBefore(inputField);
                if (ViewModeGeneratorUtilService.isLabelField(fieldBefore)) {
                    fields.push(fieldBefore);
                }
            }

            fields.push(inputField);
        }




    });
})();
