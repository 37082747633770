(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('addressInput', function (AddressVerificationSetup, LocaleConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/addressVerification/addressInput.html',
                scope: {
                    parentForm: '=',
                    page: '='
                },
                link: function ($scope) {
                    $scope.ready = false;

                    $scope.lcs = LocaleConstantsService;

                    $scope.imageField = AddressVerificationSetup.getAddressInputImageField($scope.page);
                    $scope.labelField = AddressVerificationSetup.getAddressInputLabelField($scope.page);

                    $scope.firstNameField = AddressVerificationSetup.getAddressInputFirstNameField($scope.page);
                    $scope.middleInitialField = AddressVerificationSetup.getAddressInputMiddleInitialField($scope.page);
                    $scope.lastNameField = AddressVerificationSetup.getAddressInputLastNameField($scope.page);
                    $scope.streetOneField = AddressVerificationSetup.getAddressInputStreetOneField($scope.page);
                    $scope.streetTwoField = AddressVerificationSetup.getAddressInputStreetTwoField($scope.page);
                    $scope.cityField = AddressVerificationSetup.getAddressInputCityField($scope.page);
                    $scope.stateField = AddressVerificationSetup.getAddressInputStateField($scope.page);
                    $scope.zipField = AddressVerificationSetup.getAddressInputZipField($scope.page);

                    $scope.ready = true;
                }
            };
        });

})();


