/**
 * Created by Jamie Nola on 05/21/2020
 * Container Item Template Configuration Data
 */

const t800 = {
    "key": "L4_R6_FORM_TEMPLATE",
    "description": "40% left side + 60% right image template used to display form item, with lock/pause enabled button",
    "screens": {
        "desktop": {
            "height": 214,
            "width": 440,
            "layouts": {
                "defaultState": {
                    "rowCount": 1,
                    "colCount": 55,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "colspan": 24,
                                    "layout": {
                                        "rowCount": 7,
                                        "colCount": 1,
                                        "rows": [
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlMain",
                                                        "valign": "TOP"
                                                    }
                                                ],
                                                "rowspan": 5
                                            },
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "btnMain",
                                                        "valign": "BOTTOM"
                                                    }
                                                ],
                                                "rowspan": 2
                                            }
                                        ]
                                    }
                                },
                                {
                                    "keyRef": "imageMain",
                                    "colspan": 31,
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        }
                    ]
                },
                "completedState": {
                    "rowCount": 1,
                    "colCount": 55,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "colspan": 24,
                                    "layout": {
                                        "rowCount": 7,
                                        "colCount": 1,
                                        "rows": [
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlCompleted",
                                                        "valign": "TOP"
                                                    }
                                                ],
                                                "rowspan": 5
                                            },
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlCompletedLink",
                                                        "valign": "BOTTOM"
                                                    }
                                                ],
                                                "rowspan": 2
                                            }
                                        ]
                                    }
                                },
                                {
                                    "keyRef": "imageMain",
                                    "colspan": 31,
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        }
                    ]
                },
                "pausedState": {
                    "rowCount": 1,
                    "colCount": 55,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "colspan": 24,
                                    "layout": {
                                        "rowCount": 7,
                                        "colCount": 1,
                                        "rows": [
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlContinue",
                                                        "valign": "TOP"
                                                    }
                                                ],
                                                "rowspan": 5
                                            },
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "btnMain",
                                                        "valign": "BOTTOM"
                                                    }
                                                ],
                                                "rowspan": 2
                                            }
                                        ]
                                    }
                                },
                                {
                                    "keyRef": "imageMain",
                                    "colspan": 31,
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        }
                    ]
                },
                "lockedNoPreviewState": {
                    "rowCount": 1,
                    "colCount": 55,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "colspan": 24,
                                    "layout": {
                                        "rowCount": 7,
                                        "colCount": 1,
                                        "rows": [
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlLocked",
                                                        "valign": "TOP"
                                                    }
                                                ],
                                                "rowspan": 5,
                                                "opacity": 0.5
                                            },
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "btnMain",
                                                        "valign": "BOTTOM"
                                                    }
                                                ],
                                                "rowspan": 2
                                            }
                                        ]
                                    }
                                },
                                {
                                    "keyRef": "imageMain",
                                    "colspan": 31,
                                    "opacity": 0.5,
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMainState": {
                    "rowCount": 1,
                    "colCount": 55,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "colspan": 24,
                                    "layout": {
                                        "rowCount": 7,
                                        "colCount": 1,
                                        "rows": [
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "htmlLocked",
                                                        "valign": "TOP"
                                                    }
                                                ],
                                                "rowspan": 5,
                                                "opacity": 0.5
                                            },
                                            {
                                                "cols": [
                                                    {
                                                        "halign": "LEFT",
                                                        "keyRef": "btnMain",
                                                        "valign": "BOTTOM"
                                                    }
                                                ],
                                                "rowspan": 2
                                            }
                                        ]
                                    }
                                },
                                {
                                    "keyRef": "imageMain",
                                    "colspan": 31,
                                    "opacity": 0.5,
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMessageState": {
                    "colCount": 1,
                    "rowCount": 7,
                    "rows": [
                        {
                            "cols": [
                                {
                                    "keyRef": "lockedMessage"
                                }
                            ],
                            "rowspan": 5
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "btnOk"
                                }
                            ],
                            "rowspan": 2
                        }
                    ]
                }
            }
        },
        "mobile": {
            "height": 232,
            "width": 148,
            "layouts": {
                "defaultState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 12,
                            "cols": [
                                {
                                    "keyRef": "imageMainMobile",
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        },
                        {
                            "rowspan": 11,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlMainMobile",
                                    "valign": "TOP"
                                }
                            ]
                        },
                        {
                            "rowspan": 6,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "btnMain",
                                    "valign": "BOTTOM"
                                }
                            ]
                        }
                    ]
                },
                "completedState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 12,
                            "cols": [
                                {
                                    "keyRef": "imageMainMobile",
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        },
                        {
                            "rowspan": 11,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlCompletedMobile",
                                    "valign": "TOP"
                                }
                            ]
                        },
                        {
                            "rowspan": 6,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlCompletedLinkMobile",
                                    "valign": "BOTTOM"
                                }
                            ]
                        }
                    ]
                },
                "pausedState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 12,
                            "cols": [
                                {
                                    "keyRef": "imageMainMobile",
                                    "gradient": "FORM_GRADIENT"
                                }
                            ]
                        },
                        {
                            "rowspan": 11,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlContinueMobile",
                                    "valign": "TOP"
                                }
                            ]
                        },
                        {
                            "rowspan": 6,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "btnMain",
                                    "valign": "BOTTOM"
                                }
                            ]
                        }
                    ]
                },
                "lockedNoPreviewState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 12,
                            "cols": [
                                {
                                    "keyRef": "imageMainMobile",
                                    "gradient": "FORM_GRADIENT"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "rowspan": 11,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlLockedMobile",
                                    "valign": "TOP"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "rowspan": 6,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "btnMain",
                                    "valign": "BOTTOM"
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMainState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 12,
                            "cols": [
                                {
                                    "keyRef": "imageMainMobile",
                                    "gradient": "FORM_GRADIENT"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "rowspan": 11,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "htmlLockedMobile",
                                    "valign": "TOP"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "rowspan": 6,
                            "cols": [
                                {
                                    "halign": "LEFT",
                                    "keyRef": "btnMain",
                                    "valign": "BOTTOM"
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMessageState": {
                    "rowCount": 29,
                    "colCount": 1,
                    "rows": [
                        {
                            "rowspan": 23,
                            "cols": [
                                {
                                    "keyRef": "lockedMessageMobile"
                                }
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "colspan": 3,
                                    "keyRef": "btnOk"
                                }
                            ],
                            "rowspan": 6
                        }
                    ]
                }
            }
        }
    },
    "definitions": [
        {
            "key": "imageMain",
            "description": "main image on default layout",
            "type": "IMAGE",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.ImageValidation",
                "required": true,
                "desiredWidth": 248,
                "desiredHeight": 214,
                "maxSize": 1000000
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateImageValue",
                "altText": {
                    "en": "{{FORM_NAME}}",
                    "es": "{{FORM_NAME}}"
                },
                "imageRef": "FORM_ICON"
            }
        },
        {
            "key": "imageMainMobile",
            "description": "main image on mobile layout",
            "type": "IMAGE",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.ImageValidation",
                "required": true,
                "desiredWidth": 85,
                "desiredHeight": 77,
                "maxSize": 1000000
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateImageValue",
                "altText": {
                    "en": "{{FORM_NAME}}",
                    "es": "{{FORM_NAME}}"
                },
                "imageRef": "FORM_ICON"
            }
        },
        {
            "key": "htmlMain",
            "description": "html for the default state title message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:16px;\"><strong>{{FORM_NAME}}</strong></div><div style=\"font-size:12px; color:#566774; margin-top:6px;\">{{FORM_ESTIMATED_COMPLETION_TIME}}</div></div>"
                }
            }
        },
        {
            "key": "htmlLocked",
            "description": "html for the locked state title message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:16px;\">{{FORM_NAME}}</div><div style=\"font-size:12px; color:#566774; margin-top:6px;\">{{FORM_ESTIMATED_COMPLETION_TIME}}</div></div>"
                }
            }
        },
        {
            "key": "htmlCompleted",
            "description": "html for the completed state title message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left\"><div style=\"color:#262261; font-size:16px\">{{FORM_NAME}}</div><div style=\"font-size:14px; color:#00836d; margin-top:15px; white-space: nowrap\"><span style=\"vertical-align: middle; display:inline-block; height:25px\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\"><g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#F1FAF7\"/><path stroke=\"#00836D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M6.963 13.081L10.427 16.5 17.006 9.022\"/></g></svg></span><span style=\"vertical-align: middle; margin-left: 4px; font-weight: 600\">Completed!</span></div></div>"
                }
            }
        },
        {
            "key": "htmlCompletedLink",
            "description": "html for the completed state link",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<a style=\"font-size: 14px; color:#0098db\" href=\"{{MY_DATA}}\">View Results <span><strong>&gt;</strong></span></a>"
                }
            }
        },
        {
            "key": "htmlContinue",
            "description": "html for the default state title message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:16px;\"><strong>{{FORM_NAME}}</strong></div><div style=\"font-size:12px; color:#566774; margin-top:6px;\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"117\" height=\"4\" viewBox=\"0 0 117 4\"><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"117\" height=\"4\" fill=\"#99DCC3\" rx=\"2\"/><rect width=\"34\" height=\"4\" fill=\"#00836D\" rx=\"2\"/></g></svg></div></div>"
                }
            }
        },
        {
            "key": "htmlMainMobile",
            "description": "html for the default state title message (mobile)",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:14px;\"><strong>{{FORM_NAME}}</strong></div><div style=\"font-size:12px; color:#566774; margin-top:6px;\">{{FORM_ESTIMATED_COMPLETION_TIME}}</div></div>"
                }
            }
        },
        {
            "key": "htmlLockedMobile",
            "description": "html for the locked state title message (mobile)",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:14px;\">{{FORM_NAME}}</div><div style=\"font-size:12px; color:#566774; margin-top:6px;\">{{FORM_ESTIMATED_COMPLETION_TIME}}</div></div>"
                }
            }
        },
        {
            "key": "htmlCompletedMobile",
            "description": "html for the completed state title message (mobile)",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left\"><div style=\"color:#262261; font-size:14px;\">{{FORM_NAME}}</div><div style=\"font-size:14px; color:#00836d; margin-top:10px; white-space: nowrap\"><span style=\"vertical-align: middle; display:inline-block; height:25px;\"><svg height=\"24\" viewbox=\"0 0 24 24\" width=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"12\" cy=\"12\" fill=\"#F1FAF7\" r=\"12\"></circle><path d=\"M6.963 13.081L10.427 16.5 17.006 9.022\" stroke=\"#00836D\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"></path></g></svg></span><span style=\"vertical-align: middle; margin-left: 4px; font-weight: 600\">Completed!</span></div></div>"
                }
            }
        },
        {
            "key": "htmlCompletedLinkMobile",
            "description": "html for the completed state link",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<a style=\"font-size: 14px; color:#0098db\" href=\"{{MY_DATA}}\">View Results <span><strong>&gt;</strong></span></a>"
                }
            }
        },
        {
            "key": "htmlContinueMobile",
            "description": "html for the default state title message (mobile)",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left;\"><div style=\"color:#262261; font-size:14px;\"><strong>{{FORM_NAME}}</strong></div><div style=\"font-size:12px; color:#566774; margin-top:6px;\"><svg height=\"4\" viewbox=\"0 0 117 4\" width=\"117\" xmlns=\"http://www.w3.org/2000/svg\"><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#99DCC3\" height=\"4\" rx=\"2\" width=\"117\"></rect><rect fill=\"#00836D\" height=\"4\" rx=\"2\" width=\"34\"></rect></g></svg></div></div>"
                }
            }
        },
        {
            "key": "lockedMessageMobile",
            "description": "subtitle text for why it is locked message, bolded",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: left; width: 100%\">Please complete the preview task to unlock this activity</div>",
                    "es": "<div style=\"text-align: left; width: 100%\">Complete la tarea de vista previa para desbloquear esta actividad</div>"
                }
            }
        },
        {
            "key": "lockedMessage",
            "description": "subtitle text for why it is locked message, bolded",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: center; width: 100%; padding-top: 5%\"><div style=\"max-width: 60%; margin: 0 auto\">Please complete the preview task to unlock this activity</div></div>",
                    "es": "<div style=\"text-align: center; width: 100%; padding-top: 5%\"><div style=\"max-width: 60% margin: 0 auto\">Complete la tarea de vista previa para desbloquear esta actividad</div></div>"
                }
            }
        },
        {
            "key": "btnOk",
            "description": "an ok button that can be clicked to get back to main state, hard coded for now",
            "type": "BUTTON_WARN",
            "allowOverwrite": false,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                "text": {
                    "en": "OK",
                    "es": "OKAY"
                }
            }
        },
        {
            "key": "btnMain",
            "description": "the main button for this container item, it includes information for all the states",
            "type": "BUTTON_LINK_PAUSE_LOCK_COMPLETE_ENABLED",
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueAllStates",
                "defaultState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "text": {
                        "en": "Start"
                    }
                },
                "pausedState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "text": {
                        "en": "Continue"
                    }
                },
                "lockedWithPreviewMainState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "iconName": "fa-locked",
                    "text": {
                        "en": "Why Locked?"
                    }
                },
                "lockedNoPreviewState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "iconName": "fa-locked",
                    "text": {
                        "en": "Locked"
                    }
                }
            }
        }
    ]
};

const centerIconWithTitleAndButton = {
    "key": "Center_Icon_With_Title_And_Button",
    "description": "Center icon, with a title below it, and a stateful button",
    "screens": {
        "desktop": {
            "height": 268,
            "width": 268,
            "layouts": {
                "defaultState": {
                    "rowCount": 4,
                    "colCount": 3,
                    "rows": [
                        {
                            "rowspan": 2,
                            "cols": [
                                null,
                                {
                                    "keyRef": "imageIcon"
                                },
                                null
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "titleText",
                                    "colspan": 3,
                                    "textAlign": "CENTER"
                                }
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "btnMain",
                                    "colspan": 3
                                }
                            ]
                        }
                    ]
                },
                "completedState": {
                    "rowCount": 4,
                    "colCount": 3,
                    "bgColor": "#f7fcff",
                    "rows": [
                        {
                            "rowspan": 2,
                            "cols": [
                                null,
                                {
                                    "keyRef": "imageIcon"
                                },
                                null
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "htmlCompletedTitle",
                                    "colspan": 3
                                }
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "htmlCompletedLink",
                                    "colspan": 3
                                }
                            ]
                        }
                    ]
                },
                "lockedNoPreviewState": {
                    "rowCount": 4,
                    "colCount": 3,
                    "rows": [
                        {
                            "rowspan": 2,
                            "cols": [
                                null,
                                {
                                    "keyRef": "imageIcon"
                                },
                                null
                            ],
                            "opacity": 0.5
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "titleText",
                                    "colspan": 3,
                                    "textAlign": "CENTER"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "btnMain",
                                    "colspan": 3
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMainState": {
                    "rowCount": 4,
                    "colCount": 3,
                    "rows": [
                        {
                            "rowspan": 2,
                            "cols": [
                                null,
                                {
                                    "keyRef": "imageIcon"
                                },
                                null
                            ],
                            "opacity": 0.5
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "titleText",
                                    "colspan": 3,
                                    "textAlign": "CENTER"
                                }
                            ],
                            "opacity": 0.5
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "btnMain",
                                    "colspan": 3
                                }
                            ]
                        }
                    ]
                },
                "lockedWithPreviewMessageState": {
                    "rowCount": 4,
                    "colCount": 3,
                    "rows": [
                        {
                            "rowspan": 2,
                            "cols": [
                                null,
                                {
                                    "keyRef": "iconLocked"
                                },
                                null
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "titleTextLocked",
                                    "colspan": 3
                                }
                            ]
                        },
                        {
                            "cols": [
                                {
                                    "keyRef": "btnOk",
                                    "colspan": 3
                                }
                            ]
                        }
                    ]
                }
            }
        }
    },
    "definitions": [
        {
            "key": "iconLocked",
            "description": "locked icon, with fixed icon and can not be overwritten",
            "type": "CIRCULAR_ICON_WITH_OVERLAY",
            "allowOverwrite": false,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateIconValue",
                "iconName": "fa-locked"
            }
        },
        {
            "key": "imageIcon",
            "description": "circular image displayed within a circle overlay with states information",
            "type": "CIRCULAR_IMAGE_PAUSE_LOCK_ENABLED",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.ImageValidation",
                "required": true,
                "desiredWidth": 600,
                "desiredHeight": 600,
                "maxSize": 1000000
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateImageValue",
                "imageRef": "FORM_ICON",
                "altText": {
                    "en": "Form Icon",
                    "es": "Icono de formulario"
                }
            }
        },
        {
            "key": "titleText",
            "description": "title text, bolded",
            "type": "TITLE_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateTextValue",
                "text": {
                    "en": "{{FORM_NAME}}",
                    "es": "{{FORM_NAME}}"
                }
            }
        },
        {
            "key": "titleTextLocked",
            "description": "title text for why it is locked message, bolded",
            "type": "TITLE_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateTextValue",
                "text": {
                    "en": "Please complete the preview task to unlock this activity",
                    "es": "Complete la tarea de vista previa para desbloquear esta actividad"
                }
            }
        },
        {
            "key": "htmlCompletedTitle",
            "description": "html for the completed state title message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: center; color:#262261\">You've completed<br>'<strong>{{FORM_NAME}}</strong>'</div>",
                    "es": "<div style=\"text-align: center; color:#262261\">Has completado<br>'<strong>{{FORM_NAME}}</strong>'</div>"
                }
            }
        },
        {
            "key": "htmlCompletedLink",
            "description": "html for the completed state link message",
            "type": "HTML_TEXT",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateHtmlValue",
                "html": {
                    "en": "<div style=\"text-align: center; font-size: 13px; color:#262261\">Please go to the <a href=\"{{MY_DATA}}\">My Data</a> page to view your responses or insights.</div>",
                    "es": "<div style=\"text-align: center; font-size: 13px;color:#262261\">Vaya a la página <a href=\"{{MY_DATA}}\">Los Datos</a> para ver sus respuestas o ideas</div>"
                }
            }
        },
        {
            "key": "btnMain",
            "description": "an main button that can support multiple states",
            "type": "BUTTON_PAUSE_LOCK_ENABLED",
            "validation": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.definition.TextValidation",
                "required": true
            },
            "allowOverwrite": true,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueLockPauseEnabled",
                "defaultState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "text": {
                        "en": "Start",
                        "es": "Comienzo"
                    }
                },
                "pausedState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "text": {
                        "en": "Continue",
                        "es": "Seguir"
                    }
                },
                "lockedWithPreviewMainState": {
                    "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                    "text": {
                        "en": "Why locked?",
                        "es": "¿Por qué bloqueado?"
                    },
                    "iconName": "fa-locked"
                }
            }
        },
        {
            "key": "btnOk",
            "description": "an ok button that can be clicked to get back to main state, hard coded for now",
            "type": "BUTTON_OUTLINE_PRIMARY",
            "allowOverwrite": false,
            "defaultValue": {
                "@class": "com.vibrent.acadia.web.rest.dto.container.template.data.TemplateButtonValueSimple",
                "text": {
                    "en": "Ok",
                    "es": "Okay"
                }
            }
        }
    ]
};

angular.module('acadiamasterApp')
    .constant('ContainerItemTemplateConfigs', (() => [
        {
            config: centerIconWithTitleAndButton,
            key: 'PMI (Current Design)',
        },
        {
            config: t800,
            key: 'Summer 2020 Redesign',
        },
    ])());
