/**
 * Program Detail View Constants
 * Created by Jamie Nola on 08/07/2018
 *
 * Instructions:
 * Each Category has the following attributes:
 *   hasCustomUi (optional) - If true, this category needs custom HTML, and will not use the standard table layout.
 *   headers - An array containing references to TABLE_HEADER entries. This determines which columns show up in the table.
 *   defaultSort (optional) - Needs to be a TABLE_HEADER. Normally the default sort column is ID, but this attribute can be used to change it to another header
 *   index (optional) - Needs to be a TABLE_HEADER. This column will contain the link back to the forms/rules/properties/etc pages. If not present, the index will default to ID.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .constant('PROGRAM_DETAIL_VIEW_CONSTANTS', function () {
            var CATEGORY_ID = {
                DATA_TYPE: 'dataType',
                FORMS: 'forms',
                FORM_ASSOCIATION: 'formAssociation',
                PROGRAM: 'program',
                PROPERTIES: 'properties',
                RULES: 'rules',
                RULE_ASSOCIATION: 'ruleAssociation'
            };

            var TABLE_HEADER = {
                BLANK: {
                    id: 'blank'
                },
                BUSINESS_RULE_ID: {
                    id: 'businessRuleId',
                    sortable: true
                },
                BUSINESS_RULE_CREATED_ON: {
                    id: 'createdOn',
                    displayId: 'businessRuleCreatedOn',
                    sortable: true,
                    isDate: true
                },
                BUSINESS_RULE_NAME: {
                    id: 'businessRuleName',
                    sortable: true,
                    hyphenate: true
                },
                BUSINESS_RULE_UPDATED_ON: {
                    id: 'businessRuleUpdatedOn',
                    sortable: true,
                    isDate: true
                },
                CATEGORY: {
                    id: 'category',
                    sortable: true
                },
                CREATED_BY_ID: {
                    id: 'createdById',
                    sortable: true
                },
                CREATED_ON: {
                    id: 'createdOn',
                    sortable: true,
                    isDate: true
                },
                FORM_ID: {
                    id: 'formId',
                    sortable: true
                },
                FORM_NAME: {
                    id: 'formName',
                    sortable: true,
                    hyphenate: true
                },
                FORM_UPDATED_ON: {
                    id: 'formUpdatedOn',
                    sortable: true,
                    isDate: true
                },
                FORM_BEHAVIOR_TEMPLATE_TYPE: {
                    id: 'formBehaviorTemplateType',
                    sortable: true
                },
                ID: {
                    id: 'id',
                    sortable: true
                },
                IS_DIRECTLY_ASSOCIATED: {
                    id: 'isDirectlyAssociated',
                    sortable: true,
                    resolve: {
                        true: "Directly Associated",
                        false: "Not Directly Associated"
                    }
                },
                NAME: {
                    id: 'name',
                    sortable: true,
                    hyphenate: true
                },
                UPDATED_BY_ID: {
                    id: 'updatedById',
                    sortable: true
                },
                UPDATED_ON: {
                    id: 'updatedOn',
                    sortable: true,
                    isDate: true
                },
                VALUE_TYPE: {
                    id: 'valueType',
                    sortable: true
                }
            };

            var CATEGORIES = {
                DATA_TYPE: {
                    id: CATEGORY_ID.DATA_TYPE,
                    hasCustomUi: true,
                    headers: [
                        TABLE_HEADER.ID,
                        TABLE_HEADER.NAME,
                        TABLE_HEADER.VALUE_TYPE,
                        TABLE_HEADER.CREATED_ON,
                        TABLE_HEADER.UPDATED_ON,
                        TABLE_HEADER.CREATED_BY_ID,
                        TABLE_HEADER.UPDATED_BY_ID,
                    ],
                    titleParams: [
                        TABLE_HEADER.NAME
                    ]
                },
                FORMS: {
                    id: CATEGORY_ID.FORMS,
                    headers: [
                        TABLE_HEADER.ID,
                        TABLE_HEADER.NAME,
                        TABLE_HEADER.CATEGORY,
                        TABLE_HEADER.IS_DIRECTLY_ASSOCIATED,
                        TABLE_HEADER.UPDATED_ON
                    ]
                },
                FORM_ASSOCIATION: {
                    id: CATEGORY_ID.FORM_ASSOCIATION,
                    hasCustomUi: true,
                    headers: [
                        TABLE_HEADER.FORM_ID,
                        TABLE_HEADER.FORM_NAME,
                        TABLE_HEADER.FORM_UPDATED_ON,
                        TABLE_HEADER.BLANK
                    ],
                    defaultSort: TABLE_HEADER.FORM_ID,
                    index: TABLE_HEADER.FORM_ID
                },
                PROGRAM: {
                    id: CATEGORY_ID.PROGRAM,
                    hasCustomUi: true
                },
                RULES: {
                    id: CATEGORY_ID.RULES,
                    headers: [
                        TABLE_HEADER.ID,
                        TABLE_HEADER.NAME,
                        TABLE_HEADER.CREATED_ON,
                        TABLE_HEADER.UPDATED_ON,
                        TABLE_HEADER.IS_DIRECTLY_ASSOCIATED,
                    ]
                },
                RULE_ASSOCIATION: {
                    id: CATEGORY_ID.RULE_ASSOCIATION,
                    headers: [
                        TABLE_HEADER.BUSINESS_RULE_ID,
                        TABLE_HEADER.BUSINESS_RULE_NAME,
                        TABLE_HEADER.BUSINESS_RULE_CREATED_ON,
                        TABLE_HEADER.BUSINESS_RULE_UPDATED_ON
                    ],
                    defaultSort: TABLE_HEADER.BUSINESS_RULE_ID,
                    index: TABLE_HEADER.BUSINESS_RULE_ID
                }
            };

            return {
                CATEGORY_ID: CATEGORY_ID,
                CATEGORIES: CATEGORIES,
                TABLE_HEADER: TABLE_HEADER
            };
        }());
})();

