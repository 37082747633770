(function () {
    angular.module('acadiamasterApp')
        .controller('FormVersionPDFReplaceConfirmController', ($scope, FormVersion, AlertService) => {
            $scope.replacePDF = function () {
                const data = $scope.ngDialogData;

                FormVersion.replacePDF(data.versionIdFrom, data.versionIdTo)
                    .then(() => {
                        AlertService.success('Form Version PDF Configuration Has Been Successfully Transferred');
                        $scope.confirm();
                    });
            };
        });
}());
