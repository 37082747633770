(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ProgramCategoryDetailController', function ($scope, $rootScope, $state, $stateParams, $uibModal, $location, entity, ProgramCategory) {

            var modalInstance = null;
            var programCategory = null;

            $scope.programCategory = entity;
            programCategory = $scope.programCategory;

            $scope.load = function (id) {
                ProgramCategory.get({id: id}, function(result) {
                    $scope.programCategory = result;
                });
            };
            $rootScope.$on('acadiamasterApp:programCategoryUpdate', function(event, result) {
                $scope.programCategory = result;
            });



            $scope.showDeleteConfirm = function (id) {
                console.log(id);
                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'deleteConfirmation.html',
                    scope: $scope,
                    controller: function($scope) {
                        $scope.delete = function (id) {
                            ProgramCategory.delete({id: programCategory.id},
                                function (result) {
                                    modalInstance.close(result);
                                    $state.go('programCategory');
                                });
                        };

                        $scope.closeConfirmation = function() {
                            modalInstance.dismiss('cancel');
                        }

                    },
                    size: 'md',
                    resolve: {
                        item: function () {
                            return ProgramCategory.get({id: id});
                        }
                    }
                });
            };

        });

})();
