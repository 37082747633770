
angular.module('acadiamasterApp').directive('correctiveActionAddBanner', (RuleUtilService, LocaleConstantsService, $timeout, ngDialog, vbrCommonUtil) => ({
    controller: ($scope) => {
        $scope.cs = RuleUtilService.ruleConstants;
        $scope.lcs = LocaleConstantsService;
        $scope.isReady = false;
        $scope.vcu = vbrCommonUtil;

        $scope.states = {
            isPopupOpen: false,
        };

        $scope.showAddTargetTypeDialog = ($event) => {
            $scope.states.isPopupOpen = true;
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-small',
                preCloseCallback: () => {
                    $timeout(() => {
                        $scope.states.isPopupOpen = false;
                    });
                },
                scope: $scope,
                templateUrl: 'admin-templates/site/components/treeEditorDialogs/addTargetType.template.html',
            }).then((key) => {
                $scope.addBannerNotificationTargetArea(key);
            });
            $event.stopPropagation();
        };
        $scope.formOptions = {
            form: $scope.action.getForm(),
            onChangeCallBack: () => {
                $scope.action.setForm($scope.formOptions.form);
            },
            title: 'Select Form',
            viewOnly: false,
        };

        $scope.isTargetAreaExists = (key) => {
            const currentTargetAreas = $scope.action.bannerNotificationTargetAreas;
            if (currentTargetAreas) {
                for (let i = 0; i < currentTargetAreas.length; i += 1) {
                    const targetArea = currentTargetAreas[i];
                    if (targetArea.bannerDisplayTargetArea.name === key) {
                        return true;
                    }
                }
            }
            return false;
        };

        $scope.addBannerNotificationTargetArea = (key) => {
            $scope.action.addBannerNotificationTargetArea($scope.cs.bannerDisplayTargetArea[key]);
        };

        $scope.isFormSupportedBannerType = () => $scope.action.bannerType == $scope.cs.bannerType.OPEN_FORM
            || $scope.action.bannerType == $scope.cs.bannerType.MANUAL_SCHEDULING_FORM;


        $scope.onBannerTypeChange = (bannerType, oldSelection) => {
            if(bannerType.name === oldSelection.name){
                // return if value not changed
                return;
            }

            if (bannerType === $scope.cs.bannerType.OPEN_FORM
                || bannerType === $scope.cs.bannerType.MANUAL_SCHEDULING_FORM) {
                $scope.action.bannerSpecificData.title = 'Banner Title';
                $scope.action.bannerSpecificData.message = 'Banner Message';
                $scope.action.bannerSpecificData.buttonText = 'Open';
            } else if (bannerType === $scope.cs.bannerType.SCHEDULE_PHYSICAL_MEASUREMENT) {
                $scope.action.bannerSpecificData.title = vbrCommonUtil.UNAVAILABLE;
                $scope.action.bannerSpecificData.message = vbrCommonUtil.UNAVAILABLE;
                $scope.action.bannerSpecificData.buttonText = vbrCommonUtil.UNAVAILABLE;
            } else if (bannerType === $scope.cs.bannerType.INFO) {
                $scope.action.bannerSpecificData.title = 'Banner Title';
                $scope.action.bannerSpecificData.message = 'Banner Message';
                $scope.action.bannerSpecificData.buttonText = vbrCommonUtil.UNAVAILABLE;
            }
        };

        $scope.isReady = true;
        $scope.programId = $scope.action._parent.programId;
    },
    restrict: 'E',
    scope: {
        action: '=',
    },
    templateUrl: 'admin-templates/site/programTests/programTest/directive/'
        + 'correctiveActionDirective/correctiveActionAddBanner.html',
}));
