/**
 * @author bobbybounvichit on 01/30/2019
 *
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('SMSToggleService', function ($http, localWeb) {
            return {
                getSMSEnabled: function (id) {
                    return $http.get(localWeb.createUrl('api/programs/' + id + '/sms'), '');
                },
                setSMSEnabled: function(id) {
                    return $http.post(localWeb.createUrl('api/programs/' + id + '/sms'), '');
                }
            };
        });
})();
