'use strict';

(function(){
    angular.module('acadiamasterApp').
    directive('featureList', function (vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/featureList.html',
            scope : {
              feature : '=',
              isRequired:'='
            },
            link: function ($scope) {
                vbrCommonUtil.translate.addPartsAndRefresh([
                    'global'
                ]);
                $scope.featureEnum = {
                    EHR: {name: 'EHR',text: 'EHR'},
                    INSIGHTS: {name: 'INSIGHTS', text: 'Insights'},
                };
            }
        };
    });
})();