'use strict';

angular.module('acadiamasterApp')
    .factory('Activate', ['$resource', 'localWeb', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/activate'), {}, {
            'get': { method: 'GET', params: {}, isArray: false}
        });
    }]);


