/**
 * basic log out service to handle logout events
 */

const LOGOUT = 'logout';

// eslint-disable-next-line angular/function-type
angular.module('acadiamasterApp')
    .factory('LogoutService', ($rootScope, $state, SessionStorageService) => {
        $rootScope.$on(LOGOUT, () => {
            SessionStorageService.clear();
            localStorage.removeItem('rosettaStone');

            $state.go('home');
        });

        return {
            LOGOUT,
        };
    });
