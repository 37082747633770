'use strict';

angular.module('acadiamasterApp')
    .factory('ProgramAssetManagement', ['$resource', 'Upload', '$http', 'localWeb', function($resource, Upload, $http,localWeb) {
        var programAssets = $resource(localWeb.createUrl('api/serverasset/email/:id'), {}, {
             'query': { method: 'GET', isArray: true},
             'get': {
                 method: 'GET',
                 transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                 }
             },
             'update': { method:'PUT' }
         });
        programAssets.postContent = function(programId, files,  data, onSaveFinished) {
            createEdit('/api/serverasset/email/'+programId, files, data, onSaveFinished);
        };

        function createEdit(url, files, data, onSaveFinished){
            Upload.upload({
                url: localWeb.createUrl(url),
                arrayKey: '',
                data: {
                    file: files,
                    data: JSON.stringify(data)
                }
            }).then(function (resp) {
                if (_.isFunction(onSaveFinished)) {
                    onSaveFinished(resp);
                }
            });
        }
         return programAssets;
    }]);


