/**
 * Created by Jason Cao on 08/12/2019
 *
 * Model for localized String model, very simple key/value pair
 */
angular.module('acadiamasterApp').factory('LocalizedStringModel', (LocaleConstantsService) => {
    // todo: might not be correct, there are a number of those locale constants
    const locales = LocaleConstantsService.localeArray.langs;

    /** *************************************************************
     * constructor for container behavior model
     ************************************************************** */
    function LocalizedStringModel() {
        const _this = this;

        // initialize every key's value to null
        locales.forEach(k => _this[k] = null);
    }

    /**
     * convert the current UI model to dto format
     */
    LocalizedStringModel.prototype.toDto = function () {
        const dto = {};

        let hasValue = false;
        const _this = this;
        locales.forEach(k => {
            dto[k] = _this[k];

            if (dto[k]) {
                hasValue = true;
            }
        });

        // if there is no value, then just return null to make it more efficient processing on server side
        return hasValue ? dto : null;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    LocalizedStringModel.prototype.fromDto = function (dto) {
        if (dto==null) {
            dto = {};
        }

        const _this = this;
        locales.forEach(k => _this[k] = dto[k]);
    };

    /**
     * check if the model has any non-null value on any language
     */
    LocalizedStringModel.prototype.hasValue = function() {
        const _this = this;
        const valueFound = _.find(locales, (k) => {
            const v = _this[k];
            return v!=null && v.trim().length>0;
        });

        return valueFound!=null;
    };

    /** *************************************
     * private function call
     ************************************** */


    /** *************************************
     * service return call
     ************************************** */

    return LocalizedStringModel;
});
