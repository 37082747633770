/* eslint-disable no-prototype-builtins */
/* eslint-disable vars-on-top */
/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    angular.module('acadiamasterApp')
        .directive('searchFilterField', ($translate, SEARCH_FILTER_CONSTANTS, TagService,
            RuleConstantsService, ProgramService, SearchFilterService, DeepLinkConstants,
            FormConstants, ParticipantProfileService, StringManagementService, ContainerDpLinkConstants, FormSearch) => {
            return {
                controller : function ($scope) {
                    $scope.cs = SEARCH_FILTER_CONSTANTS;
                    $scope.data = {
                        isLoading : false,
                    };

                    let filter = $scope.filter;
                    let filterId = $scope.filter.id;
                    let formSearchFilter = {
                        programId : $scope.programId,
                        size: 1000,
                    };

                    // Set source destinations into this constant to avoid repeating code below
                    let SOURCES = {};
                    // api sources
                    SOURCES[$scope.cs.OPTION_SOURCE.API_EVENT_TYPE] = SearchFilterService.getEventTypes;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_EVENT_SOURCE] = SearchFilterService.getEventSource;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_FORM] = FormSearch.query;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_PROGRAM] = ProgramService.query;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_PROPERTY] = SearchFilterService.getProperties;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_RULE] = SearchFilterService.getRules;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_SERVICE_TYPE] = SearchFilterService.getServiceTypes;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_TAG] = TagService.getTags;
                    SOURCES[$scope.cs.OPTION_SOURCE.API_USER_EVENT_ACTION_TYPE] = ParticipantProfileService.getUserEventTypes;

                    // local sources
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_DEEP_LINK_PAGE] = DeepLinkConstants.PAGES;
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_DEEP_LINK_TARGET_TYPE] = DeepLinkConstants.TARGET_TYPES;
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_FORM] = FormConstants[filterId];
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_RULE] = RuleConstantsService[filterId];
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_STRING_FEATURE] = StringManagementService.getFeatures;
                    SOURCES[$scope.cs.OPTION_SOURCE.LOCAL_CONTAINER_DEPENDENCY_LINK_DEPENDENCY_TYPE] = ContainerDpLinkConstants.DEPENDENCY_TYPES;

                    /*
                     * Formats options from a simple object
                     * @param {Object} data
                     */
                    function formatOptionsFromSimpleObject (data) {
                        return Object.keys(data).map(key => {
                            return { name : data[key], value : data[key] };
                        });
                    }

                    /*
                     * Formats options to display a name only
                     * @param {Array} data
                     */
                    function formatOptionsToShowName (data) {
                        return data.map(item => {
                            return { name : item.name, value : item.id };
                        });
                    }

                    /*
                     * Formats options to display an id and name
                     * @param {Array} data
                     */
                    function formatOptionsToShowIdName (data) {
                        return data.map(item => {
                            return {
                                name : `${item.id } - ${ item.name}`, value : item.id,
                            };
                        });
                    }

                    /*
                     * Formats options to display an id, name, and code
                     * @param {Array} data
                     */
                    function formatOptionsToShowIdNameCode (data) {
                        return data.map(item => {
                            return { name : `${item.id } - ${ item.name } (${ item.code })`, value : item.id };
                        });
                    }

                    /*
                     * Formats options with category data
                     * @param {Object} data
                     */
                    function formatOptionsWithCategoriesFromApiData (data) {
                        let categoryMap = {};
                        let categoryValues = [];
                        // make temporary map for getting names and values array for sorting
                        data.categories.map(category => {
                            categoryMap[category.value] = category.name;
                            categoryValues.push(category.value);
                        });
                        let options = data.options.map(item => {
                            item.categoryName = categoryMap[item.category];
                            return item;
                        });

                        // keep the original category order intact
                        options.sort((a, b) => {
                            let indexA = categoryValues.indexOf(a.category);
                            let indexB = categoryValues.indexOf(b.category);

                            if (indexA > indexB) {
                                return 1;
                            }
                            if (indexA < indexB) {
                                return -1;
                            }
                            return 0;
                        });

                        return options;
                    }

                    // saves options on the scope, given a filter ID
                    function setOptions (filterId, options) {
                        // set empty option (optional, based on filter's config)
                        if ($scope.filter.hasEmptyOption) {
                            let emptyOptionName = $translate.instant(`searchFilter.emptyOption.${ filterId}`);
                            options.unshift({ name : emptyOptionName, value : $scope.cs.EMPTY_OPTION_VALUE });
                        }

                        $scope.options[filterId] = options;
                    }

                    /*
                     * Called when options have been loaded (or failed to load)
                     */
                    function onLoaded ($scope) {
                        delete $scope.isFilterLoadingOptions[filterId];
                        $scope.data.isLoading = false;
                    }

                    /**
                     * Loads options from associated services/APIs and transform them to match the name/value pattern
                     *
                     * Instructions:
                     * This function handles enumerable fields that need to have their options loaded from an external source. If you need to access an API that is not already in use, add it to searchFilter.service.js and reference it in the SOURCES constant here.
                     * These lookups happen exactly once for each field when it is first accessed. If the field is a subfield, this will not happen until it becomes visible in the modal. Once we have data for a given field, it remains until the modal is closed and opened again, at which point it will grab fresh data from the appropriate location, either locally or from an API.
                     *
                     **/
                    $scope.getFieldOptions = function () {
                        // ignore filters that have options set OR are already loading their options
                        if ($scope.options[filterId].length > 0 || $scope.isFilterLoadingOptions[filterId]) {
                            return;
                        }

                        let source = SOURCES[filter.optionSource];
                        $scope.isFilterLoadingOptions[filterId] = true;
                        $scope.data.isLoading = true;

                        switch (filter.optionSource) {
                        /* --------------------------------------------------
                            *  Local Sources
                            *--------------------------------------------------*/
                        case $scope.cs.OPTION_SOURCE.LOCAL_DEEP_LINK_PAGE:
                        case $scope.cs.OPTION_SOURCE.LOCAL_DEEP_LINK_TARGET_TYPE:
                        case $scope.cs.OPTION_SOURCE.LOCAL_FORM:
                        case $scope.cs.OPTION_SOURCE.LOCAL_RULE:
                        case $scope.cs.OPTION_SOURCE.LOCAL_STRING_FEATURE:
                        case $scope.cs.OPTION_SOURCE.LOCAL_CONTAINER_DEPENDENCY_LINK_DEPENDENCY_TYPE:
                            var localSource = typeof source === 'function' ? source() : source;
                            var options = Object.keys(localSource).map(id => {
                                let item = localSource[id];
                                const result = {};
                                if (typeof item === 'string') {
                                    result.name = item;
                                    result.value = item;
                                } else {
                                    result.name = item.text;
                                    result.value = item.name;
                                }

                                // handles translation strings from the constants file
                                const prefix = filter.field.optionNameTranslationPrefix || '';
                                const suffix = filter.field.optionnameTranslationSuffix || '';
                                if (prefix || suffix) {
                                    result.name = $translate.instant(
                                        `${prefix}${result.name}${suffix}`,
                                    );
                                }

                                return result;
                            });
                            setOptions(filterId, options);
                            onLoaded($scope);
                            break;


                        case $scope.cs.OPTION_SOURCE.API_TAG:
                            // 99999 for page size
                            source(99999).then(
                                data => {
                                    setOptions(
                                        filterId,
                                        formatOptionsToShowName(data.codes),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                            // service returns promise. response.data is an array
                        case $scope.cs.OPTION_SOURCE.API_FORM:
                            source(formSearchFilter).then(
                                response => {
                                    setOptions(
                                        filterId,
                                        formatOptionsToShowIdName(response.data),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                        case $scope.cs.OPTION_SOURCE.API_PROPERTY:
                        case $scope.cs.OPTION_SOURCE.API_RULE:
                            source().then(
                                response => {
                                    setOptions(
                                        filterId,
                                        formatOptionsToShowIdName(response.data),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                            // options with categories
                        case $scope.cs.OPTION_SOURCE.API_EVENT_TYPE:
                            source().then(
                                response => {
                                    filter.optionFilterId = response.data.id;
                                    setOptions(
                                        filterId,
                                        formatOptionsWithCategoriesFromApiData(response.data),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                            // already in { name:x, value:x } format
                        case $scope.cs.OPTION_SOURCE.API_SERVICE_TYPE:
                            source().then(
                                response => {
                                    setOptions(filterId, response.data);
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                        case $scope.cs.OPTION_SOURCE.API_EVENT_SOURCE:
                            source().then(
                                response => {
                                    setOptions(filterId, response.data);
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                            // service returns data without response wrapper
                        case $scope.cs.OPTION_SOURCE.API_PROGRAM:
                            source().$promise.then(
                                data => {
                                    setOptions(
                                        filterId,
                                        formatOptionsToShowIdName(data),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                            // response.data is simple object
                        case $scope.cs.OPTION_SOURCE.API_USER_EVENT_ACTION_TYPE:
                            source().then(
                                response => {
                                    setOptions(
                                        filterId,
                                        formatOptionsFromSimpleObject(response.data),
                                    );
                                }, error => {
                                    console.error(error);
                                }).finally(() => {
                                onLoaded($scope);
                            });
                            break;
                        default:
                            onLoaded($scope);
                            console.error('ERROR:', filterId, 'filter has no OPTIONS and no OPTION_SOURCE. It must have one or the other.');
                            break;
                        }
                    };

                    /*
                     * Check to see if options have successfully been loaded. Returns false if they haven't started loaded or if they errored out while loading.
                     */
                    $scope.areOptionsLoaded = function () {
                        return !$scope.values.hasOwnProperty(filterId)
                            || $scope.options[filterId].length > 0;
                    };

                    /*
                     * Checks to see if any explicit subfilters are visible, which is used to determine if the last ANY_SELECTION subfilter is the last subfilter out of them all.
                     */
                    $scope.areAnyManualSubfiltersVisible = function () {
                        let subfilterValues = $scope.filter.subfilters[$scope.values[$scope.filter.id].value];
                        return !subfilterValues || subfilterValues.length === 0;
                    };

                    /*
                     * Clamps the size value for multi-select fields. Prevents the element from growing or shrinking too much
                     * @param {Number} numOptions The number of options
                     */
                    $scope.getMultiSelectSize = function (numOptions) {
                        let minSize = 1;
                        let maxSize = 8;
                        return Math.min(maxSize, Math.max(minSize, numOptions));
                    };

                    /*
                     * Filters options based on another filter's value
                     */
                    $scope.filterOptions = function (filter) {
                        if (!filter.optionFilterId || !$scope.values[filter.optionFilterId]) {
                            return function () {
                                return true;
                            };
                        }
                        return function (item) {
                            return $scope.values[filter.optionFilterId]
                                    && $scope.values[filter.optionFilterId].value === item.category
                                    || item.value === '';
                        };
                    };

                    /*
                     * Gets the option group to group options on. If there is a group but the optionFilterId's field has a value, groups are not shown.
                     */
                    $scope.getOptionGroup = function (filter, option) {
                        if (filter.optionFilterId && $scope.values[filter.optionFilterId]) {
                            return undefined;
                        }
                        return option.categoryName || undefined;
                    };

                    /*
                     * Normally disables field if no options are present, but if the options are being filtered by another filter's value, disables the field when there are no options for that value.
                     */
                    $scope.isFilterDisabled = function (filter, filteredOptions) {
                        if (filter.optionFilterId) {
                            return filteredOptions.length <= (filter.hasEmptyOption ? 1 : 0);
                        }
                        return $scope.options[filter.id].length === 0;
                    };

                    /*
                     * as new hint text is added, need to check if it is defined in global.json else return the field name
                     *
                     * @param fieldId
                     * @returns {*}
                     */
                    $scope.getHintText = function (fieldId) {
                        if (fieldId == null) {
                            return '';
                        }

                        let hintextKey = `searchFilter.hintText.${ fieldId}`;
                        let v = $translate.instant(hintextKey);
                        if (v === hintextKey) {
                            // if no hinttext defined, return field name
                            return $translate.instant(`searchFilter.field.${ fieldId}`);
                        }
                        return v;
                    };

                    // sometimes, the selected value is an array. In these cases, we need to be
                    // able to show subfilters that match ANY of the array results.
                    $scope.getActiveSubfilters = function (subfilters, values) {
                        let result = [];
                        if (!subfilters || !values) {
                            return result;
                        }

                        if (angular.isArray(values)) {
                            // iterate through active values and determine if any of them should
                            // enable a subfield. Also make sure that there are no duplicates if
                            // more than one option are selected and both have the same subfield.
                            values.forEach(value => {
                                // subfilters matching this value
                                const valueSubfilters = subfilters[value.value];
                                // if any match, handle them individually
                                if (valueSubfilters) {
                                    valueSubfilters.forEach(valueSubfilter => {
                                        // If we aren't already handling this subfilter id, add it
                                        if (!result.some(subfilter => subfilter.id === valueSubfilter.id)) {
                                            result.push(valueSubfilter);
                                        }
                                    });
                                }
                            });
                        } else {
                            result = subfilters[values.value];
                        }
                        return result;
                    };
                },
                restrict : 'E',
                scope    : {
                    filter                 : '=',
                    isFilterLoadingOptions : '=',
                    isLast                 : '=',
                    onValueChange          : '=',
                    options                : '=',
                    programId              : '<',
                    values                 : '=',
                },
                templateUrl : 'admin-templates/site/components/searchFilter/fields/searchFilterField.template.html',
            };
        });
}());
