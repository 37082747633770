import ProgramContainerConstantsService from '../../../const/programContainer.constants.service';
/**
 *
 * directive for configuring basic info for container items
 */
angular.module('acadiamasterApp').directive('basicInfoConfig', () => ({
    link($scope) {
        const cs = ProgramContainerConstantsService;
        $scope.isAppTypeSupported = containerItem => {
            if (!containerItem || !containerItem._parent || !containerItem._parent.containerType) {
                return false;
            }
            // check if container item support appType
            return cs.hasAppTypeSupportedByLvl(containerItem._parent.containerType, false);
        };
    },
    restrict : 'E',
    scope    : {
        containerItem : '<',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/' +
        'config/containerItem/basicInfo/basicInfoConfig.html',
}));
