angular.module('acadiamasterApp')

    /**
     * expression conditions directive
     */
    .directive('exportWizardStepExpressionConditions', (ExpressionConditionService, $timeout,
        AlertService) => {
        function areAllDisplayedConditionsSelected($scope) {
            const selectedIds = $scope.selectedData.expressionConditions.map(
                selectedCondition => selectedCondition.id,
            );
            if (!$scope.data.visibleExpressionConditions || !$scope.data.visibleExpressionConditions.length) {
                return false;
            }
            return $scope.data.visibleExpressionConditions.every(
                condition => selectedIds.indexOf(condition.id) !== -1,
            );
        }

        function checkSelectedDependencies($scope) {
            const selectedConditions = [];
            const selectedIds = $scope.selectedData.expressionConditions.map(selectedCondition => selectedCondition.id);
            const usedByMap = {};

            // iterate through all expression conditions
            $scope.data.expressionConditions.forEach((condition) => {
                // check to see if this condition is used by any selected conditions
                const activeUses = condition.usedBy.filter(id => selectedIds.indexOf(id) !== -1);
                usedByMap[condition.id] = activeUses;

                // if we find active (selected) uses
                if (activeUses.length > 0) {
                    // select this condition and disable the checkbox
                    condition.forceSelection = true;
                    if (selectedIds.indexOf(condition.id) === -1) {
                        selectedIds.push(condition.id);
                    }
                } else {
                    // otherwise, enable the checkbox but don't change the selected state
                    condition.forceSelection = false;
                }

                // return the updated condition to the selected array if needed
                if (selectedIds.indexOf(condition.id) !== -1) {
                    selectedConditions.push(condition);
                }
            });

            // save the new selections to the selectedData object
            $scope.selectedData.expressionConditions = selectedConditions;
            $scope.selectedData.expressionConditionUsageMap = usedByMap;
            $scope.data.selectAllValue = areAllDisplayedConditionsSelected($scope);
        }

        function updateLinks($scope) {
            const total = $scope.data.filteredExpressionConditions.length;
            const { page, pageSize } = $scope.searchData;
            const lastPage = Math.ceil(total / pageSize) - 1;

            // build the list of links manually since we aren't getting it from the server
            const links = {
                first: 0,
                last: -1,
            };
            if (page > 0) {
                links.prev = page - 1;
            }
            if (page < lastPage) {
                links.next = page + 1;
            }
            if (total > pageSize) {
                links.last = lastPage;
            }
            $scope.data.links = links;
            $scope.searchData.searchResultCount = total;
        }

        function loadAll($scope) {
            const programId = $scope.program.id;
            // load program specific data type which will have properties used in profile values

            const searchDto = {
                ascending: $scope.searchData.ascending,
                orderBy: $scope.searchData.orderBy,
                page: 0,
                programId,
                searchString: $scope.searchData.searchString,
                simpleResponse: true,
                size: 999999,
            };

            ExpressionConditionService.loadConditions(searchDto).then((response) => {
                if (response == null || response.data == null || response.status !== 200) {
                    AlertService.error(
                        `Failed to load expression condition data for Program Id: ${programId}`,
                    );
                    return;
                }

                // iterate through each condition's dependencies and map the values
                const usedBy = {};
                response.data.forEach((condition) => {
                    condition.dependencies.forEach((dependency) => {
                        const depId = dependency.dependentConditionId;
                        if (!usedBy[depId]) {
                            usedBy[depId] = [condition.id];
                        } else if (usedBy[depId].indexOf(condition.id) === -1) {
                            usedBy[depId].push(condition.id);
                        }
                    });
                });

                // save usedBy values to each condition that needs them
                const maxLength = 100;
                $scope.data.expressionConditions = response.data.map(condition => ({
                    ...condition,
                    description: condition.description.length > maxLength
                        ? `${condition.description.substring(0, maxLength).trim()}...`
                        : condition.description,
                    forceSelection: false,
                    usedBy: usedBy[condition.id] || [],
                }));

                checkSelectedDependencies($scope);
                $timeout(() => {
                    updateLinks($scope);
                });

                if (response.data.length === 0) {
                    AlertService.warning('No expression conditions were found');
                }
            });
        }

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            $scope.orderByChoices = {
                ID: 'id',
                NAME: 'name',
                UPDATED_ON: 'updatedOn',
            };

            $scope.searchData = {
                ascending: false,
                orderBy: $scope.orderByChoices.UPDATED_ON,
                page: 0,
                pageSize: 20,
                pageSizeChoices: [20, 50, 100],
                previousSearchString: '',
                searchResultCount: 'loading...',
                searchString: '',
            };
            $scope.data = {
                expressionConditions: [],
                filteredExpressionConditions: [],
                links: [],
                selectAllValue: false,
                visibleExpressionConditions: [],
            };

            loadAll($scope);
        }

        return {
            controller: ($scope) => {
                init($scope);

                $scope.selectAll = () => {
                    const selectedIds = $scope.selectedData.expressionConditions.map(
                        selectedCondition => selectedCondition.id,
                    );
                    if ($scope.data.selectAllValue) {
                        // Add displayed expression conditions to the selected expression condition
                        // array if they do not already exist there
                        if ($scope.data.visibleExpressionConditions) {
                            $scope.data.visibleExpressionConditions.forEach((expressionCondition) => {
                                if (selectedIds.indexOf(expressionCondition.id) === -1) {
                                    $scope.selectedData.expressionConditions.push(expressionCondition);
                                }
                            });
                        }
                    } else {
                        // Remove any expression conditions that are currently displayed from the
                        // selected expression condition array
                        const displayedConditionIds = $scope.data.visibleExpressionConditions.map(
                            expressionCondition => expressionCondition.id,
                        );

                        const conditions = $scope.selectedData.expressionConditions;
                        $scope.selectedData.expressionConditions = conditions.filter(
                            (condition) => {
                                // if the condition is not currently visible, keep it.
                                if (displayedConditionIds.indexOf(condition.id) === -1) {
                                    return true;
                                }
                                // if a condition is currently visible, only deselect it if all of
                                // the selected conditions using it are currently visible AND
                                // selected.
                                return condition.usedBy && condition.usedBy.length > 0 && condition.usedBy.some(
                                    id => displayedConditionIds.indexOf(id) === -1
                                        && selectedIds.indexOf(id) !== -1,
                                );
                            },
                        );
                    }
                    $scope.data.selectAllValue = areAllDisplayedConditionsSelected($scope);
                };

                $scope.sortBy = (name) => {
                    $scope.searchData.ascending = ($scope.searchData.orderBy === name)
                        ? !$scope.searchData.ascending
                        : true;
                    $scope.searchData.orderBy = name;
                    $scope.searchData.page = 0;
                };

                $scope.loadPage = (page) => {
                    $scope.searchData.page = page || 0;
                    $timeout(() => {
                        updateLinks($scope);
                        $scope.data.selectAllValue = areAllDisplayedConditionsSelected($scope);
                    });
                };

                $scope.$watch('selectedData.expressionConditions.length', (newValue, oldValue) => {
                    if (newValue !== oldValue) {
                        checkSelectedDependencies($scope);
                    }
                });
            },
            restrict: 'E',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '=',
            },
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepExpressionConditions.html',
        };
    });
