/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable valid-jsdoc */
import cs from '../const/programContainer.constants.service';

import expressionUtil from '../util/expression.util';
import ContainerItemType from '../const/ContainerItemType.const';

/**
 * Created by Jamie Nola on 08/02/2019
 *
 * Model for an individual container
 */
angular.module('acadiamasterApp')
    .factory('ContainerModel', (
        ModelServiceBase, ContainerItemModel, ProgramFormConstantsService,
        LocalizedStringModel, ContainerDisplayConfigUtil, ModelServiceConditionExpression, ContainerIconModel,
        ConditionWithMessageModel, ContainerItemTemplateConstants, TemplateCacheService, ConditionalContentValue) => {
        /**
         * @constructor
         */
        function ContainerModel (parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
            this.nodeType = cs.nodeType.CONTAINER;

            this.collapsed = false;

            this.id = null;
            this.name = null;
            this.displayOrder = null;
            this.containerType = null;
            this.verticalStack = false;
            this.programId = null;
            this.isDeleted = false;
            this.updatedOn = null;
            this.pageId = cs.defaultTab.id;
            this.containerHeader = new LocalizedStringModel();
            this.containerDescription = new LocalizedStringModel();
            this.icon = new ContainerIconModel();
            this.associatedContainerId = null;
            this.containerItems = [];
            this.containerBehavior = null;
            this.targetArea = null;

            this.isVisibleExpression = null;
            this.isVisibleExpressionTree = null;

            this.isUnlockedExpression = null;
            this.isUnlockedExpressionTree = null;

            this.showHeader = true;
            this.lockForCati = false;
            this.hasLockPreview = false;
            this.defaultLockedMessage = new LocalizedStringModel();
            this.lockedMessages = [];

            this._templateKey = null;
            this.templateValues = {};

            this.formEntryFilter = {};

            // allows this container to be referenced before it has been given an ID.
            // This value is never saved to the server.
            // todo: this doesn't work as server doesn't understand the reference, we
            // should disallow reference to container that doesn't exist on server yet
            // proper way of fixing it would be to modify server code to support this type
            // of reference and handle it correctly, but that would be done in a later phase
            this.uniqueId = Math.random();

            // container type specific settings used for a number of functions, it should be updated
            // when container type changes
            this._settings = null;
            // All app types are supported by default
            this.isWebSupported = true;
            this.isAndroidSupported = true;
            this.isIosSupported = true;
        }

        ContainerModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        /**
         * getting a short description in plain text (ie: non html)
         */
        Object.defineProperty(ContainerModel.prototype, 'shortDescription', {
            get : function () {
                const container = this;
                return `${container.id} | ${container.name} | ${container.containerType}`;
            },
        });

        /**
         * create getter and setter for template key so when key is set, related template object will be loaded
         */
        Object.defineProperty(ContainerModel.prototype, 'templateKey', {
            get : function () {
                return this._templateKey;
            },
            set : function (templateKey) {
                if (this._templateKey == templateKey) {
                    return;
                }

                this._templateKey = templateKey;
                this.templateValues = {};

                if (templateKey == null) {
                    this._template = null;
                    return;
                }

                const _this = this;

                TemplateCacheService.loadTemplateByKey(templateKey).then(templateModel => {
                    _this._template = templateModel;
                });
            },
        });

        /**
         * convert the current UI model to dto format
         */
        ContainerModel.prototype.toDto = function toDto (files) {
            const dto = {};

            dto.id = this.id;
            dto.name = this.name;
            dto.displayOrder = this.displayOrder;
            dto.programId = this.programId;
            dto.isDeleted = this.isDeleted;
            dto.updatedOn = this.updatedOn;
            dto.pageId = this.pageId === cs.defaultTab.id ? null : this.pageId;
            dto.containerType = this.containerType;
            dto.verticalStack = this.verticalStack;
            dto.containerHeader = this.showHeader ? this.containerHeader.toDto() : null;
            dto.displayHeader = this.showHeader;
            dto.containerDescription = this.containerDescription.toDto();
            dto.icon = this.icon.toDto(files);
            // handle when user select draft page. We may remove this in phase 2/3
            dto.targetArea = null;

            // export the expression fields
            expressionUtil.toDto(this, dto);

            if (!this.shouldIncludeUnlockedFlag()) {
                dto.isUnlockedExpression = null;
                dto.isUnlockedExpressionTree = null;
            }

            if (!this.shouldIncludeVisibleFlag()) {
                dto.isVisibleExpression = null;
                dto.isVisibleExpressionTree = null;
            }

            const type = cs.findTypeByName(this.containerType);
            if (type && type.sections) {
                if (type.displayConfigClass != null && this.containerBehavior != null) {
                    dto.containerBehavior = this.containerBehavior.toDto(files);
                }

                if (type.sections) {
                    if (type.sections.indexOf(cs.sections.containerReferences) > -1) {
                        dto.associatedContainerId = this.associatedContainerId;
                    }

                    if (type.sections.indexOf(cs.sections.formAssociations) > -1) {
                        dto.containerItems = this.containerItems.map((containerItem, index) => {
                            containerItem.displayOrder = index;
                            containerItem.programId = this.programId;
                            return containerItem.toDto(files);
                        }).filter(mapping => {
                            return mapping.form != null && mapping.form.id != null;
                        });
                    }

                    if (type.sections.indexOf(cs.sections.hybridAssociations) > -1) {
                        dto.containerItems = this.containerItems.map((mapping, index) => {
                            mapping.displayOrder = index;
                            mapping.programId = this.programId;
                            return mapping.toDto(files);
                        });
                    }

                    if (type.sections.indexOf(cs.sections.appointmentAssociation) > -1) {
                        dto.containerItems = this.containerItems.map((mapping, index) => {
                            mapping.displayOrder = index;
                            mapping.programId = this.programId;
                            return mapping.toDto(files);
                        });
                    }

                    if (type.sections.indexOf(cs.sections.actionAssociation) > -1) {
                        dto.containerItems = this.containerItems.map((mapping, index) => {
                            mapping.displayOrder = index;
                            mapping.programId = this.programId;
                            return mapping.toDto(files);
                        });
                    }

                    if (type.sections.indexOf(cs.sections.viewSharedEHRAssociation) > -1) {
                        dto.containerItems = this.containerItems.map((mapping, index) => {
                            mapping.displayOrder = index;
                            mapping.programId = this.programId;
                            return mapping.toDto(files);
                        });
                    }
                }
            }
            dto.pageId = isNaN(this.pageId) ? null : parseInt(this.pageId);
            dto.lockForCati = this.lockForCati;
            dto.hasLockPreview = this.hasLockPreview;
            dto.defaultLockedMessage = this.hasLockPreview ? this.defaultLockedMessage.toDto() : null;
            if (this.hasLockPreview && this.lockedMessages != null) {
                dto.lockedMessages = [];
                this.lockedMessages.forEach(cwm => {
                    dto.lockedMessages.push(cwm.toDto());
                });
            }
            dto.templateKey = this.templateKey;
            if (dto.templateKey) {
                dto.templateValues = templateValuesToDto(this.templateValues, files);
            }
            dto.formEntryFilter = this.formEntryFilter;
            dto.webSupported = this.isWebSupported;
            dto.androidSupported = this.isAndroidSupported;
            dto.iosSupported = this.isIosSupported;
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerModel.prototype.fromDto = function fromDto (dto) {
            this.id = dto.id;
            this.name = dto.name;
            this.displayOrder = dto.displayOrder;
            this.programId = dto.programId;
            this.isDeleted = dto.isDeleted;
            this.updatedOn = dto.updatedOn;
            this.isWebSupported = dto.webSupported;
            this.isAndroidSupported = dto.androidSupported;
            this.isIosSupported = dto.iosSupported;
            this.pageId = dto.pageId || cs.defaultTab.id;
            this.targetArea = dto.targetArea || null;

            const containerType = dto.containerType == null ? getDefaultContainerTypeByName(dto) : dto.containerType;
            this.setContainerType(containerType, true);
            const containerTypeConstant = cs.findTypeByName(containerType);

            this.verticalStack = dto.verticalStack;

            this.containerBehavior = ContainerDisplayConfigUtil.getDisplayConfigFromDto(
                dto.containerBehavior, this.containerType, this,
            );
            this.containerHeader.fromDto(dto.containerHeader);
            this.containerDescription.fromDto(dto.containerDescription);
            this.associatedContainerId = dto.associatedContainerId;
            this.icon.fromDto(dto.icon);

            // import the expression fields
            expressionUtil.fromDto(this, dto, ModelServiceConditionExpression.ConditionExpression);

            if (!this.shouldIncludeUnlockedFlag()) {
                this.isUnlockedExpression = null;
                this.isUnlockedExpressionTree = null;
            }

            if (!this.shouldIncludeVisibleFlag()) {
                this.isVisibleExpression = null;
                this.isVisibleExpressionTree = null;
            }

            this.containerItems = [];
            if (dto.containerItems != null) {
                dto.containerItems.forEach(g => {
                    const newMapping = new ContainerItemModel(this);
                    newMapping.fromDto(g);
                    this.containerItems.push(newMapping);
                });

                this.containerItems.sort((v1, v2) => v1.displayOrder - v2.displayOrder);
            }

            this.showHeader = this.containerHeader.hasValue() || !containerTypeConstant.optionalHeader;
            this.lockForCati = dto.lockForCati;
            this.hasLockPreview = dto.hasLockPreview;
            this.defaultLockedMessage.fromDto(dto.defaultLockedMessage);
            if (dto.lockedMessages != null) {
                this.lockedMessages = [];
                dto.lockedMessages.forEach(cwm => {
                    const newCwm = new ConditionWithMessageModel(this);
                    newCwm.fromDto(cwm);
                    this.lockedMessages.push(newCwm);
                });
            }
            this.templateKey = dto.templateKey;
            this.templateValues = dtoToTemplateValues(dto.templateValues);
            this.formEntryFilter = dto.formEntryFilter;
        };

        /**
         * setting container type, if the type is the same as current type, then
         * nothing happens
         * @param newType - new container type
         * @param disableValidation - if we should disable the validation at the end
         *      (usually only used in fromDTO when the data isn't ready yet)
         */
        ContainerModel.prototype.setContainerType = function (newType, disableValidation) {
            if (this.containerType === newType) {
                return;
            }

            this.containerType = newType;

            // erase any existing containerItems when the type changes
            this.containerItems = [];

            this.containerBehavior = ContainerDisplayConfigUtil.getDisplayConfigFromDto(
                null, this.containerType, this,
            );

            this.showHeader = this.containerHeader.hasValue() || !this.containerType.optionalHeader;

            this._settings = cs.findTypeByName(newType);

            if (!disableValidation) {
                this.validate();
            }
        };

        /**
         * check if we can add item or not to the container
         * @returns {boolean} return true if item can still be added to the current container, false otherwise
         */
        ContainerModel.prototype.canAddItem = function (sourceContainerItem) {
            const settings = this._settings;
            if (settings === null) {
                return false;
            }

            const canAddItem = settings.canAddItem;
            if (!canAddItem) {
                return false;
            }

            // If move between containers that are different in app type support, make sure all three flags are true after being moved
            if (sourceContainerItem && sourceContainerItem._parent && sourceContainerItem._parent.containerType) {
                const type = cs.findTypeByName(sourceContainerItem._parent.containerType);
                const sourceAppTypeSupported = type.appTypeSupportedLevel.containerItem;
                if (sourceAppTypeSupported && !settings.appTypeSupported || !sourceAppTypeSupported && settings.appTypeSupported) {
                    sourceContainerItem.webSupported = true;
                    sourceContainerItem.androidSupported = true;
                    sourceContainerItem.iosSupported = true;
                }
            }

            const maxNumberOfChild = settings.maxNumberOfChild;
            if (maxNumberOfChild == null) {
                return true;
            }

            return this.containerItems.length < maxNumberOfChild;
        };

        /**
         * remove a message from the list
         * @param messageToBeRemoved - message to be removed
         */
        ContainerModel.prototype.removeLockedMessage = function (messageToBeRemoved) {
            if (this.lockedMessages == null || this.lockedMessages.length == 0) {
                console.warn('nothing to remove, list is empty', messageToBeRemoved);
                return;
            }

            let foundIndex = this.lockedMessages.indexOf(messageToBeRemoved);
            if (foundIndex != -1) {
                this.lockedMessages.splice(foundIndex, 1);
            }
        };

        /**
         * insert a new message into the list after a specific message, if it's null, then insert at the end of the list
         * @param messageToInsertAfter - insert new message after this message, nullable
         */
        ContainerModel.prototype.addLockedMessageAfter = function (messageToInsertAfter) {
            if (this.lockedMessages == null) {
                this.lockedMessages = [];
            }

            let foundIndex = this.lockedMessages.indexOf(messageToInsertAfter);

            const newMessageWithCondition = new ConditionWithMessageModel(this);

            if (foundIndex != -1) {
                this.lockedMessages.splice(foundIndex + 1, 0, newMessageWithCondition);
            } else {
                this.lockedMessages.push(newMessageWithCondition);
            }
        };

        /**
         * getting the form model by item index
         * @param index - item index
         * @returns {*} - form model associated with that item by index, can be null
         */
        ContainerModel.prototype.getFormByIndex = function (index) {
            const cfm = this.containerItems;

            if (cfm == null || cfm.length == 0 || index < 0) {
                return null;
            }

            const containerItem = cfm[index];

            if (containerItem) {
                return containerItem.getForm();
            }

            return null;
        };

        /**
         * setting a single form item, if the item doesn't already exist, then create a new one
         * @param formModel - form model
         * @returns {*} - newly added the container item
         */
        ContainerModel.prototype.setSingleForm = function (formModel) {
            if (this.containerItems == null) {
                this.containerItems = [];
            }

            // add empty object if needed
            if (this.containerItems.length == 0) {
                this.containerItems.push(new ContainerItemModel(this));
            }

            const containerItem = this.containerItems[0];

            containerItem.setForm(formModel);

            return containerItem;
        };

        /**
         * check if we should hide form count or not
         * @returns {boolean} true if we should hide form count in gui, false otherwise
         */
        ContainerModel.prototype.isHideFormCount = function () {
            const settings = this._settings;

            if (settings == null) {
                return false;
            }

            return settings.hideFormCount;
        };

        /**
         * add a single form item, if the item doesn't already exist, then create a new one
         * @param formModel - form model
         */
        ContainerModel.prototype.addForm = function addForm (formModel) {
            if (formModel == null || this.isFormAlreadySelected(formModel)) {
                // already exist or model is null, not going to add
                return;
            }

            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);

            containerItem.setForm(formModel);
        };

        /**
         * add a single form item, if the item doesn't already exist, then create a new one
         * @param formModel - form model for VIEW_SHARED_EHR
         */
        ContainerModel.prototype.addViewSharedEHR = function addForm (formModel) {
            if (formModel == null || this.isFormAlreadySelected(formModel)) {
                // already exist or model is null, not going to add
                return;
            }

            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);

            containerItem.setViewSharedEHR(formModel);
        };

        /**
         * add a container item with an action type
         * @param actionModel - container item type
         */
        ContainerModel.prototype.addActionItem = function addActionItem (actionModel) {
            if (actionModel === null) {
                return;
            }

            if (this.containerItems === null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            containerItem.setAction(actionModel);
            this.containerItems.push(containerItem);
        };

        ContainerModel.prototype.addAgreement = function addAgreement (agreementModel) {
            if (agreementModel == null) {
                // already exist or model is null, not going to add
                return;
            }

            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);

            containerItem.setAgreement(agreementModel);
        };

        ContainerModel.prototype.addExternalIntegration = function addExternalIntegration () {
            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);
            containerItem.setExternalIntegration();
        };
        ContainerModel.prototype.addContent = function addContent () {
            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);
            containerItem.setContent();
        };

        ContainerModel.prototype.addAppointment = function addAppointment () {
            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);
            containerItem.setAppointment();
        };

        ContainerModel.prototype.addMeasurements = function addMeasurements () {
            if (this.containerItems == null) {
                this.containerItems = [];
            }

            const containerItem = new ContainerItemModel(this);
            this.containerItems.push(containerItem);
            containerItem.setMeasurements();
        };

        /**
         * check if a form already exist (using the form id to check)
         * @param formModel - form model
         * @returns {boolean} true if the form is already in the mapping list, false otherwise
         */
        ContainerModel.prototype.isFormAlreadySelected = function isFormAlreadySelected (formModel) {
            if (formModel == null || formModel.id == null || this.containerItems == null) {
                return false;
            }

            const containerItem = _.find(this.containerItems,
                item => item != null && item.form != null && item.form.id === formModel.id);

            return containerItem != null;
        };

        ContainerModel.prototype.isAgreementAlreadySelected = function
        isAgreementAlreadySelected (agreementModel) {
            if (agreementModel == null || agreementModel.id == null
                || this.containerItems == null) {
                return false;
            }
            const containerItem = _.find(this.containerItems,
                item => item != null && item.agreement != null
                    && item.agreement.id === agreementModel.id);
            return containerItem != null;
        };
        /**
         * remove a container item
         * @param containerItem - container item to be removed
         * @param selector - selector used by UI, can be null
         */
        ContainerModel.prototype.removeContainerItem = function removeContainerItem (containerItem, selector) {
            const index = this.containerItems.indexOf(containerItem);

            if (index == -1) {
                return;
            }

            this.containerItems.splice(index, 1);
            if (selector != null) {
                selector.itemRemoved(containerItem);
            }
        };

        /**
         * getting the form count
         * in addition to action count, if action item type
         * note: form count does not always equal container mapping count, in some cases we might have container mapping that
         * isn't associated with a form yet
         * @returns {number} - total item count
         */
        ContainerModel.prototype.getItemCount = function () {
            let containerItems = this.containerItems;

            if (containerItems == null) {
                return 0;
            }

            let count = 0;

            _.forEach(containerItems, ci => {
                if (ci.form != null && ci.form.id != null) {
                    count++;
                }
                if (Object.values(ContainerItemType.ACTION).includes(ci.type)) {
                    count++;
                }
            });

            return count;
        };

        ContainerModel.prototype.shouldIncludeVisibleFlag = function () {
            return findFlagEnabled(this.containerType, 'visible');
        };

        ContainerModel.prototype.shouldIncludeUnlockedFlag = function () {
            return findFlagEnabled(this.containerType, 'unlocked');
        };

        ContainerModel.prototype.shouldDisableImageIcon = function () {
            return findFlagEnabled(this.containerType, 'imageDisabled');
        };

        /**
         * check if item completion over write should be enabled or not in any container item inside this container
         * @returns {boolean} - true if item completion is supported, false otherwise
         */
        ContainerModel.prototype.isItemCompletionOverwriteSupported = function () {
            const type = cs.findTypeByName(this.containerType);
            if (type.enabledFlags) {
                return type.isItemCompletionOverwriteSupported;
            }
            return undefined;
        };

        /** *************************************
         * validation functions
         ************************************** */

        /**
         * validate the entity
         */
        ContainerModel.prototype._validateSelf = function () {
            this.clearError();
            if (this.containerType == null) {
                this.setErrorMessage('container type is required');
            } else if (this.containerType == 'SINGLE_FORM' && this.getItemCount() > 1) {
                this.setErrorMessage('single form container must have 0 or one form associated with it');
            } else if (isContainerReferenceRequired(this.containerType) && this.associatedContainerId == null) {
                this.setErrorMessage(`associated container is required for this container type: ${ this.containerType}`);
            } else if (this.name == null || this.name.length === 0) {
                this.setErrorMessage('container name is required');
            } else if (this.showHeader && (this.containerHeader == null || this.containerHeader.en == null
                || this.containerHeader.en.length === 0)) {
                this.setErrorMessage('container header is required');
            }

            // defaultLockedMessage is required if hasLockPreview is true
            if (this.hasLockPreview && (!this.defaultLockedMessage || !this.defaultLockedMessage.hasValue())) {
                this.setErrorMessage('container default locked message is required');
            }
            if (this.containerType) {
                const type = cs.findTypeByName(this.containerType);
                // if the container is app supported, then each of the three flags should not be false
                if (type?.appTypeSupportedLevel?.container && (!this.isAndroidSupported && !this.isIosSupported && !this.isWebSupported)) {
                    this.setErrorMessage('At least one supported app type should be configured.');
                }
            }
            // NOTE: temporarily commenting out this subpage template requirement (AC-128828)
            // if (this.containerType === cs.containerTypeNames.subPage && !this.templateKey) {
            //     this.setErrorMessage('Template is required for SubPage containers');
            // }
            if (this.containerType === cs.containerTypeNames.dataReferenceROR && !this.templateKey) {
                this.setErrorMessage('Template is required for Data Reference ROR containers');
            }
            validateDataReferenceRORContainer(this);
        };

        /**
         * validate the container name to check its uniqueness
         * @param nameCounts - a map of name and its count, anytime the count is great than 1 means the
         * name occurred more than once
         */
        ContainerModel.prototype.validateUniqueName = function (nameCounts) {
            if (this.name == null || this.name.length == 0 || nameCounts == null || nameCounts[this.name] == null) {
                return;
            }

            if (nameCounts[this.name] > 1) {
                this.setErrorMessage(`container name "${ this.name
                }" occurred more than once, total count: ${ nameCounts[this.name]}`);
            }
        };

        ContainerModel.prototype.validateContainerItems = function (containerLinkList) {
            let containerItems = this.containerItems;
            if (containerItems == null) {
                return;
            }
            containerItems.forEach(containerItem => {
                // validate template values
                let templateValues = containerItem.templateValues;
                if (templateValues != null) {
                    Object.keys(templateValues).forEach(key => {
                        templateValues[key].forEach(conditionalValue => {
                            let contentValue = conditionalValue.contentValue;
                            if (contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.CONTAINER_NAVIGATION) {
                                if (isLinkToDraftContainer(contentValue.containerId, containerLinkList)) {
                                    this.setErrorMessage(`Detect container navigator link to the draft container ${contentValue.containerId}`);
                                }
                            }
                        });
                    });
                }

                validateContentContainerItem(this, containerItem);
            });
        };

        ContainerModel.prototype.validateContainerItemTemplateHeights = function (containerLinkList) {
            let containerItems = this.containerItems;
            if (containerItems === null) {
                return;
            }
            containerItems.forEach(containerItem => {
                let templateHeights = containerItem.templateHeights;
                if (templateHeights !== null) {
                    Object.keys(templateHeights).forEach(key => {
                        let height = templateHeights[key];
                        if (height !== null && height !== undefined && (height < 30 || height > 2000)) {
                            this.setErrorMessage('Container Item height should be from 30 - 2000px');
                        }
                    });
                }
            });
        };

        /** *************************************
         * private function call
         ************************************** */
        /**
         * Check if container link to draft container.
         * @param containerId link container
         * @param containerLinkList list of container which hold container id and container pageId
         * @returns true if link container link to one of those draft container in containerLinkList
         */
        function isLinkToDraftContainer (containerId, containerLinkList) {
            if (containerLinkList && containerLinkList.length > 0) {
                let foundContainer = containerLinkList.filter(container => container.id === containerId && container.pageId === cs.defaultTab.id);
                return foundContainer.length > 0;
            }
            return false;
        }

        function isContainerReferenceRequired (containerType) {
            const type = cs.findTypeByName(containerType);
            if (type && type.sections) {
                return type.sections.indexOf(cs.sections.containerReferences) > -1;
            }

            return false;
        }

        function findFlagEnabled (containerType, flagName) {
            const type = cs.findTypeByName(containerType);
            if (type && type.enabledFlags) {
                return type.enabledFlags[flagName];
            }

            return false;
        }

        /**
         * temporary function on admin portal side to make existing container work, it should be removed
         * once server side migration is done properly (ie: the proper container types are populated already)
         * @param dto - container dto from server
         * @returns {string} -- container type, mostly by looking at name of the container
         * todo: remove this later when server migration is done so every container should have a type already
         */
        function getDefaultContainerTypeByName (dto) {
            const nameTypeMapping = {
                EDUCATIONAL    : 'SINGLE_FORM',
                MOOD           : 'SINGLE_FORM',
                SNAP_QUESTIONS : 'SINGLE_QUESTION_FORM',
            };

            const defaultType = 'MULTI_FORM';

            const containerType = nameTypeMapping[dto.name];

            return containerType == null ? defaultType : containerType;
        }

        /**
         * convert template values map from object format to simple dto
         * @param templateValues - template values in a mapped object format
         * @param files - files array to insert new file into it
         * @returns {*} - simple dto for the mapped object
         */
        function templateValuesToDto (templateValues, files) {
            if (templateValues == null) {
                return null;
            }

            const dtoValues = {};
            let hasValue = false;

            _.forEach(templateValues, (valueListModel, key) => {
                if (valueListModel != null && valueListModel.length > 0) {
                    const valueListDto = [];
                    _.forEach(valueListModel, conditionalValue => {
                        valueListDto.push(conditionalValue.toDto(files));
                    });
                    dtoValues[key] = valueListDto;
                    hasValue = true;
                }
            });

            return hasValue ? dtoValues : null;
        }

        function dtoToTemplateValues (templateValuesDto) {
            if (templateValuesDto == null) {
                return {};
            }

            const valueModels = {};
            _.forEach(templateValuesDto, (valueListDto, key) => {
                const valueListModel = [];
                _.forEach(valueListDto, vDto => {
                    const conditionalValue = new ConditionalContentValue();
                    conditionalValue.fromDto(vDto);
                    valueListModel.push(conditionalValue);
                });
                valueModels[key] = valueListModel;
            });

            return valueModels;
        }

        /**
         * Validates the given content container item against the container it's in.
         * @param container container
         * @param containerItem content container item
         */
        function validateContentContainerItem (container, containerItem) {
            if (containerItem.type !== ContainerItemType.CONTENT) {
                return;
            }

            // validate full height config
            if ([ cs.containerTypeNames.subPage, cs.containerTypeNames.multiForm ].includes(container.containerType)
                && containerItem.typeSpecificConfig?.fullHeightConfigured
                && container.containerItems.length > 1
            ) {
                container.setErrorMessage(`${container.name} container cannot have multiple container items when full height is configured.`);
                return;
            }

            // validate multiForm content container items
            // only allow one container item if there is a content container item in the list
            if (container.containerType === cs.containerTypeNames.multiForm
                && container.containerItems.length > 1
            ) {
                container.setErrorMessage(`${container.name} container cannot have multiple container items when one is a content container item.`);
                return;
            }
        }

        /**
         * Validates that the given data reference ror container is configured correctly.
         * @param container container
         */
        function validateDataReferenceRORContainer (container) {
            if (container.containerType !== cs.containerTypeNames.dataReferenceROR) {
                return;
            }
            if (container.containerItems != null && container.containerItems.length > 0) {
                container.setErrorMessage(`${container.name} Data Reference ROR containers are not allowed to have container items.`);
            }
            if (!container.formEntryFilter.formDataType) {
                container.setErrorMessage(`${container.name} container must have a data reference type selected.`);
            }
            if (container.formEntryFilter.advanceInclusionExclusion) {
                if (!container.formEntryFilter.formIds || container.formEntryFilter.formIds.length === 0) {
                    container.setErrorMessage(`${container.name} container using advanced config must select form(s).`);
                }
            } else if (container.formEntryFilter.formIds && container.formEntryFilter.formIds.length !== 0) {
                container.setErrorMessage(`${container.name} container without advanced config must not reference form(s).`);
            }
        }

        /** *************************************
         * service return call
         ************************************** */

        return ContainerModel;
    });
