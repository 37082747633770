
export function capitalized (value) {
    if (value) {
        const words = value.toLowerCase().split('_');
        return words.map(word => {
            return word[0].toUpperCase() + word.substring(1);
        }).join(' ');
    }
    return null;
}

export function capitalizedArray (array) {
    return array.map(x => {
        return capitalized(x);
    });
}
