(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * program rule association directive
         */
        .directive('exportWizardStepRules', function (ParseLinks, ProgramService, AlertService, BusinessRule) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepRules.html',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '='
                },
                link: function ($scope) {
                    init($scope);

                    $scope.selectAll = function () {
                        if ($scope.data.selectAllValue) {
                            // Add displayed rules to the selected rule array if they do not already exist there
                            var selectedIds = $scope.selectedData.rules.map(function (selectedRule) {
                                return selectedRule.id;
                            });
                            if ($scope.data.rules) {
                                $scope.data.rules.forEach(function (rule) {
                                    if (selectedIds.indexOf(rule.id) === -1) {
                                        $scope.selectedData.rules.push(rule);
                                    }
                                });
                            }
                        } else {
                            // Remove any rules that are currently displayed from the selected rule array
                            var ruleIds = $scope.data.rules.map(function (rule) {
                                return rule.id;
                            });
                            var selectedRules = [];
                            if ($scope.selectedData.rules) {
                                $scope.selectedData.rules.forEach(function (rule) {
                                    if (ruleIds.indexOf(rule.id) === -1) {
                                        selectedRules.push(rule);
                                    }
                                });
                            }
                            $scope.selectedData.rules = selectedRules;
                        }
                    }

                    $scope.loadPage = function (page) {
                        $scope.searchData.page = page || 0;
                        loadAll($scope);
                    };

                    $scope.onRuleSelect = function (rule) {
                        $scope.selectedRule = rule;
                    };

                    $scope.$watch('selectedData.rules.length', function () {
                        $scope.data.selectAllValue = areAllDisplayedRulesSelected($scope);
                    });
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.businessRules = [];
                $scope.orderByChoices = {
                    ID: 'id',
                    NAME: 'name',
                    UPDATED_ON: 'updatedOn',
                    RULE_TYPE: 'ruleType',
                };

                $scope.searchData = {
                    searchString: '',
                    previousSearchString: '',
                    orderBy: $scope.orderByChoices.UPDATED_ON,
                    ascending: false,
                    pageSizeChoices: [20, 50, 100],
                    pageSizeSelected: 20,
                    page: 0,
                    searchResultCount: 'loading ...',
                    setOrderBy: function (newOrderBy) {
                        if (this.orderBy === newOrderBy) {
                            this.ascending = !this.ascending;
                        }
                        else {
                            this.ascending = true;
                            this.orderBy = newOrderBy;
                        }
                        $scope.loadPage(0);
                    }
                };

                $scope.data = {
                    selectAllValue: false,
                    rules: [],
                    links: [],
                };

                $scope.onRuleCheckboxClicked = function(event) {
                    event.stopPropagation();
                }

                loadAll($scope);
            }

            function areAllDisplayedRulesSelected($scope) {
                var selectedIds = $scope.selectedData.rules.map(function (selectedRule) {
                    return selectedRule.id;
                });
                if (!$scope.data.rules || !$scope.data.rules.length) {
                    return false;
                }
                for (var i = 0; i < $scope.data.rules.length; i++) {
                    var id = $scope.data.rules[i].id;
                    if (selectedIds.indexOf(id) === -1) {
                        return false;
                    }
                }
                return true;
            }

            function loadAll($scope) {
                $scope.selectedRule = null;
                var programId = $scope.program.id;
                var searchDto = {
                    programId: programId,
                    page: $scope.searchData.page,
                    size: $scope.searchData.pageSizeSelected,
                    orderBy: $scope.searchData.orderBy,
                    ascending: $scope.searchData.ascending,
                    searchString: $scope.searchData.searchString || null
                };

                BusinessRule.query(searchDto,function (data, headers, status) {
                    if (data == null || status !== 200) {
                        AlertService.error("Failed to load program specific form data for Program Id: " + programId);
                        return;
                    }

                    if (data.length === 0) {
                        AlertService.warning("No rules were found");
                    }

                    $scope.searchData.searchResultCount = headers('X-Total-Count');
                    $scope.searchData.previousSearchString = searchDto.searchString;
                    $scope.data.links = ParseLinks.parse(headers('link'));
                    $scope.data.rules = data;
                    $scope.data.selectAllValue = areAllDisplayedRulesSelected($scope);
                });
            }

        });


})();
