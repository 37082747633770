(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigLevelChangeModel',
        function (RuleConstantsService, RuleActionBaseModelService) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigLevelChangeModel.inheritsFrom(ActionConfigBaseModel);
            function ActionConfigLevelChangeModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.LEVEL_CHANGE;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigLevelChangeModel;

                this.trackId = null;
                this.levelId = null;
            }

            ActionConfigLevelChangeModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.levelId = actionConfig.levelId;
                    this.trackId = actionConfig.trackId;
                }
            };

            ActionConfigLevelChangeModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.trackId = this.trackId;
                actionConfig.levelId = this.levelId;
                return dto;
            };

            ActionConfigLevelChangeModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.trackId == null) {
                    this.setErrorMessage("Track is not selected");
                } else if (this.levelId == null) {
                    this.setErrorMessage("Level is not selected");
                }
            };

            ActionConfigLevelChangeModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                if (this.levelId && this.trackId) {
                    message += " | " + " Set track " + this.trackId + " to level " + this.levelId;
                } else {
                    message += " | **** NO TRACK AND LEVEL SELECTED YET!! ****";
                }
                return message;
            };

            return ActionConfigLevelChangeModel;
        }
    );
})();