/**
 * Created by Jamie Nola on 06/26/2018
 */
angular.module('acadiamasterApp')
    .controller('ImportWizardController', ($scope, $timeout, ngDialog, ParseLinks, IMPORT_WIZARD_CONSTANTS, ImportWizardService, entity) => {
        $scope.cs = IMPORT_WIZARD_CONSTANTS;
        $scope.isReady = false;

        // resolve program entity
        entity.$promise.then(() => {
            init(entity);
        });

        $scope.sortBy = function (id) {
            if (!$scope.data.isLoading) {
                $scope.searchData.ascending = ($scope.searchData.orderBy === id) ? !$scope.searchData.ascending : true;
                $scope.searchData.orderBy = id;
                $scope.loadEntities(0);
            }
        };

        $scope.loadEntities = function (page) {
            $scope.data.isLoading = true;
            ImportWizardService.checkForInProgressImport($scope.data.program.id).then(
                (response) => {
                    $scope.data.importInProgress = response.data.importInProgress;
                    loadPage($scope, page);
                }, (error) => {
                    console.error(error);
                    $scope.data.isLoading = false;
                }
);
        };

        // check to see if an import is currently in progress. If so, open the session detail modal instead.
        $scope.openImportModal = function () {
            ngDialog.openConfirm({
                plain: true,
                template: '<import-wizard-program-import-modal program-id="' + $scope.data.program.id + '"></import-wizard-program-import-modal>',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width-small',
                size: 'md',
            }).then(null,
                (response) => {
                    // show most recent entry in the detail modal if needed
                    if (response.status && response.status === IMPORT_WIZARD_CONSTANTS.UPLOAD_STATUS.SUCCESS) {
                        $scope.openSessionDetailModal(response.id);
                    }
                    $timeout($scope.loadEntities, 500);
                },);
        };

        $scope.openSessionDetailModal = function (sessionId) {
            ngDialog.openConfirm({
                plain: true,
                template: '<import-wizard-session-detail-modal session-id="' + sessionId + '"></import-wizard-session-detail-modal>',
                scope: $scope,
                className: 'ngdialog-theme-plain custom-width-large',
                size: 'md',
            }).then(null,
                () => {
                    $timeout(function () {
                        $scope.loadEntities($scope.searchData.page);
                    }, 500);
                },);
        };

        $scope.refreshEntities = function () {
            $scope.data.isRefreshing = true;
            $scope.loadEntities();
        };

        /** ********************************************************************
         * ************************** Private Methods **************************
         * ******************************************************************** */

        function init(entity) {
            $scope.data = {
                program: entity,
                isLoading: false,
                sessions: [],
                importInProgress: true,
                isRefreshing: false,
                links: [],
                searchResultCount: 0,
            };

            $scope.searchData = {
                ascending: false,
                orderBy: IMPORT_WIZARD_CONSTANTS.LIST.SORT_CATEGORY.IMPORTED_ON,
                page: 0,
                programId: entity.id,
                size: 20,
            };

            $scope.loadEntities();
            $scope.isReady = true;
        }

        function loadPage($scope, page) {
            $scope.searchData.page = page || 0;
            ImportWizardService.getImportLogList($scope.searchData).then((response) => {
                $scope.data.sessions = response.data;
                $scope.data.searchResultCount = response.headers('X-Total-Count');
                $scope.data.links = ParseLinks.parse(response.headers('link'));
            }, (error) => {
                console.error(error);
            }).finally(() => {
                $scope.data.isLoading = false;
                $scope.data.isRefreshing = false;
            });
        }
    });
