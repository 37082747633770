/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form field's field value
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultFieldValueService',
        function (CompareResultUtilService, vbrCommonUtil) {
            /**
             * compare two field value object
             * @param fv1 - field value 1
             * @param fv2 - field value 2
             * @returns {{CompareResult}} - returning a map of Compare Result object
             */
            function compare(fv1, fv2) {

                var result;

                var reverse = true;

                // some basic error handling to avoid null pointer exception
                if (vbrCommonUtil.isNullOrUnavailable(fv1)) {
                    fv1 = {};
                    // it's too complex to do the field value comparison in here as they are vastly different
                    // based on type, the actual comparison function is implemented in the field value model

                    // setting the reverse flag to true so we know the first value is not the current object
                    result = fv2.compare(fv1, reverse);
                }
                else if (vbrCommonUtil.isNullOrUnavailable(fv2)) {
                    fv2 = {};
                    result = fv1.compare(fv2, false);
                }
                else {
                    result = fv1.compare(fv2, false);
                }

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

