(function () {
    'use strict';

    let app = angular.module('acadiamasterApp');

    app.factory('ActionConfigPciChangeDismissStatusModel',
        function (RuleConstantsService, RuleActionBaseModelService, ContainerItemLoadUtil) {
            const ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigPciChangeDismissStatusModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigPciChangeDismissStatusModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.PCI_CHANGE_DISMISS_STATUS;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigPciChangeDismissStatusModel;
                this.containerItemId = null;
                this.containerId = null;
                this.pciStatusChangeEnum = RuleConstantsService.PCIDissmissEnum.UNDISMISSED;


                this._container = null;
                this._containerItem = null;
            }

            ActionConfigPciChangeDismissStatusModel.prototype.getContainerItem = function() {
                return this._containerItem;
            };

            ActionConfigPciChangeDismissStatusModel.prototype.setContainerItem = function(newContainerItem) {
                this._containerItem = newContainerItem;
                this.containerItemId = newContainerItem == null ? null : newContainerItem.id;
                this.formId = newContainerItem == null || newContainerItem.form == null ? null : newContainerItem.form.id;
                if (this._containerItem && this._container !== this._containerItem._parent) {
                    this._container = this._containerItem._parent;
                }
            };

            ActionConfigPciChangeDismissStatusModel.prototype.setDismissStatus = function (pciStatusChangeEnum) {
                this.pciStatusChangeEnum = pciStatusChangeEnum;
            };

            ActionConfigPciChangeDismissStatusModel.prototype.getContainer = function() {
                return this._container;
            };

            ActionConfigPciChangeDismissStatusModel.prototype.setContainer = function(newContainer) {
                this._container = newContainer;
                this.containerId = newContainer ? newContainer.id : null;
                if (this._containerItem && this._container !== this._containerItem._parent) {
                    // we are changing container, we should clear the container item if it's not for the same parent
                    this._containerItem = null;
                    this.containerItemId = null;
                }
            };

            ActionConfigPciChangeDismissStatusModel.prototype.getProgramId = function() {
                const rootElement = this.getRoot();
                return rootElement==null ? null : rootElement.programId;
            };

            ActionConfigPciChangeDismissStatusModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                const actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.containerItemId = actionConfig.containerItemId;
                    this.containerId = actionConfig.containerId;
                    this.pciStatusChangeEnum = this.pciDissmissEnumFromDto(actionConfig.pciStatusChangeEnum);
                } else {
                    this.containerItemId =null;
                    this.containerId = null;
                    this.pciStatusChangeEnum = RuleConstantsService.PCIDissmissEnum.UNDISMISSED;
                }

                ContainerItemLoadUtil.loadContainerItem(this);
            };

            ActionConfigPciChangeDismissStatusModel.prototype.pciDissmissEnumFromDto = function(pciDismissStatus) {
                var pciStatus = RuleConstantsService.PCIDissmissEnum;
                    if (pciDismissStatus === pciStatus.UNDISMISSED.name) {
                        return pciStatus.UNDISMISSED;
                    } else {
                        return pciStatus.DISMISSED;
                    }
            };

            ActionConfigPciChangeDismissStatusModel.prototype.toDto = function (files) {
                const dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                const actionConfig = dto.actionConfig;
                actionConfig.containerItemId = this.containerItemId;
                actionConfig.containerId = this.containerId;
                actionConfig.pciStatusChangeEnum = this.pciStatusChangeEnum.name;
                return dto;
            };

            ActionConfigPciChangeDismissStatusModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let desc = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                if (this._containerItem) {
                    desc += ' | ' + this._containerItem.shortDescription;
                }
                else if (this.containerItemId) {
                    desc += ' | container item id: ' + this.containerItemId;
                }

                return desc;
            };

            ActionConfigPciChangeDismissStatusModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.containerItemId == null) {
                    this.setErrorMessage('Container Item should be selected');
                }
            };

            return ActionConfigPciChangeDismissStatusModel;
        }
    );
})();
