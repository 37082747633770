/**
 * Created by ashmitakumar on 7/27/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('TagService', function ($q, $http, ParseLinks, localWeb) {

            var baseApi = localWeb.createUrl('api/tag');

            return {
                getTags: function (pageSize) {
                    var deferred = $q.defer();

                    var success = function (response) {
                        var data = response.data;
                        var links = ParseLinks.parse(response.headers('link'));

                        var resolved = {
                            codes: data,
                            links: links
                        };
                        deferred.resolve(resolved);
                    };

                    var error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http.get(baseApi, { params: { size: pageSize }}).then(success).catch(error);

                    return deferred.promise;
                }
            }
        });
})();
