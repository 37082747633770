'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypePropertyMappingDetailController', function ($scope, $rootScope, $stateParams, entity, DataTypePropertyMapping, Property, DataType, DataTypeCategory, DataTypeRootCategory, PropertyPossibleValue) {
        $scope.dataTypePropertyMapping = entity;
        $scope.load = function (id) {
            DataTypePropertyMapping.get({id: id}, function(result) {
                $scope.dataTypePropertyMapping = result;
            });
        };
        $rootScope.$on('acadiamasterApp:dataTypePropertyMappingUpdate', function(event, result) {
            $scope.dataTypePropertyMapping = result;
        });
    });
