/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for radio options
     */
        .directive('formFieldConfigFieldValueRadioOptions', function (FormUtilService, FormModelServiceFieldValue,
                                                                      LocaleConstantsService, ImagePresetConstants,
                                                                      ValueConfigOptionService, FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/radioOptions.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.constants = FormConstants;
                    $scope.valueConfigOption = ValueConfigOptionService.createValueConfigOption(
                        $scope.fieldElement._parent.fieldValue, $scope.fieldValue);

                    $scope.ImagePresetConstants = ImagePresetConstants;

                    //initialized the object to store local data.
                    $scope.data = {};

                    //Decides possible selections option depending on cell border layout flag.
                    if ($scope.fieldValue.cellBorderLayout) {
                        $scope.optionsDisplayMode = [$scope.constants.optionsDisplayModes.VERTICAL];
                    } else {
                        $scope.optionsDisplayMode = $scope.constants.optionsDisplayModes;
                    }


                    $scope.hasChangeModel = $scope.fieldValueChange != null;
                    if ($scope.hasChangeModel) {
                        initChangeModelValuesIfNeeded($scope);
                    }

                    $scope.numberOfColumnsOptions = createNumberOfColumnOptions($scope);

                    $scope.getOptionsDisplayMode = function () {
                        return getPropertyValue($scope, 'optionsDisplayMode');
                    };

                    $scope.addNewOption = function (index) {
                        FormUtilService.addNewOption($scope.fieldValue.values, index);
                    };

                    $scope.removeOption = function (index) {
                        FormUtilService.removeOption($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionUp = function (index) {
                        FormUtilService.moveOptionUp($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionDown = function (index) {
                        FormUtilService.moveOptionDown($scope.fieldValue.values, index);
                    };

                    $scope.getOptionValueEnabled = function (index) {
                        if ($scope.fieldValueChange == null || $scope.fieldValueChange.values == null ||
                            $scope.fieldValueChange.values[index] == null || $scope.fieldValueChange.values[index].enabled == null) {
                            return $scope.fieldValue.values[index].enabled;
                        }
                        else {
                            return $scope.fieldValueChange.values[index].enabled;
                        }
                    };

                    $scope.getFieldValuePattern = function () {
                        var valueType = $scope.fieldElement._parent.fieldValue.valueType;
                        return FormUtilService.validationUtil.getFieldValuePattern(valueType);
                    };

                    $scope.includeBorderLayoutChanged = function (newBorderLayout) {
                        includeBorderLayoutChanged($scope, newBorderLayout);
                    };
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function initChangeModelValuesIfNeeded($scope) {
                if ($scope.fieldValueChange.values == null) {
                    $scope.fieldValueChange.values = [];
                }

                for (var i = 0; i < $scope.fieldValue.values.length; i++) {
                    if ($scope.fieldValueChange.values[i] == null) {
                        $scope.fieldValueChange.values[i] = {
                            enabled: null
                        };
                    }
                }
            }

            /**
             * generic function to get property value by key while using hasChangeModel property
             * @param $scope
             * @param key
             */
            function getPropertyValue($scope, key) {
                var r = null;
                if ($scope.hasChangeModel) {
                    r = $scope.fieldValueChange[key];
                }

                if (r == null) {
                    r = $scope.fieldValue[key];
                }

                return r;
            }

            function createNumberOfColumnOptions($scope) {
                if (vbrCommonUtil.isNullOrUnavailable($scope.fieldValue.numberOfColumns)) {
                    return null;
                }

                return {
                    from: 1, to: 3, step: 1, skin: 'blue'
                };
            }

            function includeBorderLayoutChanged($scope, newBorderLayout) {
                if (newBorderLayout) {
                    //getting previous values into local data object in case admin just toggle the button on configuration page of border layout.
                    $scope.data.optionsDisplayMode = $scope.fieldValue.optionsDisplayMode;
                    $scope.data.includeRadioButton = $scope.fieldValue.includeRadioButton;

                    //setting the values for cell layout
                    $scope.fieldValue.optionsDisplayMode = $scope.constants.optionsDisplayModes.VERTICAL;
                    $scope.fieldValue.includeRadioButton = false;

                    //minimize the possible selections for options display mode for UI only.
                    $scope.optionsDisplayMode = [$scope.constants.optionsDisplayModes.VERTICAL];
                } else {
                    //maximize the possible selections for options display mode for UI only.
                    $scope.optionsDisplayMode = $scope.constants.optionsDisplayModes;

                    //restoring previous values from local data object if in case admin just toggle the button on configuration page of border layout or set values to defaults.
                    $scope.fieldValue.includeRadioButton = ($scope.data.includeRadioButton != undefined ? $scope.data.includeRadioButton : true);
                    $scope.fieldValue.optionsDisplayMode = ($scope.data.optionsDisplayMode ? $scope.data.optionsDisplayMode : $scope.constants.optionsDisplayModes.VERTICAL)
                }
            }

        });
})();