import template from './participantFormEntries.template.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('participantFormEntries', function (ParticipantProfileService, Form, RenderUtil) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    userId: '<',
                    timezone: '='
                },
                link: function ($scope) {
                    $scope.data = {
                        ascending: false,
                        categories: [
                            { name: 'id', isLink: true },
                            { name: 'externalId' },
                            { name: 'formId' },
                            { name: 'formVersionId' },
                            { name: 'formName' },
                            { name: 'createdById' , isUser: true},
                            { name: 'updatedById', isUser: true },
                            { name: 'createdOn', isDate: true },
                            { name: 'updatedOn', isDate: true },
                            { name: 'draft', isDraft: true },
                            {name: 'modified', isEdit: true},
                            { name: 'originalSubmissionTime', isDate: true },
                            { name: 'lastSubmissionTime', isDate: true }
                        ],
                        formEntries: [],
                        isLoading: true,
                        orderBy: 'id',
                        resultCount: 0,
                        selectedId: null,
                        formModel: null,
                        index: 0,
                        pdfMetaData: null,
                    };

                    function entryClicked(id, index) {
                        $scope.data.index = index;
                        $scope.data.selectedId = id;
                        formEntrySelected(index);
                    }

                    function formEntrySelected(i) {
                        var formEntries = $scope.data.formEntries;

                        Form.get({
                            id: formEntries[i].formId,
                            projection: Form.projections.SPECIFIC_VERSION,
                            formVersionId: formEntries[i].formVersionId
                        }).$promise.then(function (result) {
                            $scope.data.formModel = RenderUtil.createFormModel(result);
                        });
                        ParticipantProfileService.getPdfMetadata(formEntries[i].id).then(function (result) {
                            if(result.data) {
                                $scope.data.pdfMetaData = result.data;
                            }
                            else {
                                $scope.data.pdfMetaData = null;
                            }
                        }, function (error) {
                            $scope.data.resultCount = 0;
                            console.error(error);
                        }).finally(function () {
                            $scope.data.isLoading = false;
                        });
                    }

                    function loadAll() {
                        ParticipantProfileService.getFormEntries($scope.userId).then(function (result) {
                            var data = result.data;
                            $scope.data.formEntries = data;
                            $scope.data.resultCount = result.data.length;
                            getCatiUser();
                        }, function (error) {
                            $scope.data.resultCount = 0;
                            console.error(error);
                        }).finally(function () {
                            $scope.data.isLoading = false;
                        });
                    }

                    function getCatiUser(){
                        angular.forEach($scope.data.formEntries, function(value, key) {
                            if(value.createdById && value.userId !== value.createdById){
                                var resultCreatedPromise =   checkCatiUser(value.createdById);
                                resultCreatedPromise.then(function(resultCreatedFlag){
                                    value.createdById = resultCreatedFlag ? value.createdById + ' ' + 'CATI_INTERVIEWER' : value.createdById;
                               });
                            }
                            if(value.updatedById && value.userId !== value.updatedById){
                                var resultUpdatedPromise =   checkCatiUser(value.updatedById);
                                resultUpdatedPromise.then(function(resultUpdatedFlag){
                                    value.updatedById = resultUpdatedFlag ? value.updatedById + ' ' + 'CATI_INTERVIEWER' : value.updatedById;
                                });
                            }
                        });
                    }
                    function checkCatiUser(userId){
                        return Promise.resolve(
                           ParticipantProfileService.getCatiUser(userId).then(
                               function (result) {return result.data;},
                               function (error) {console.error(error);}
                            ).finally(function () {$scope.data.isLoading = false;}
                            )
                        )
                    }

                    function init() {
                        loadAll();
                    }

                    $scope.entryClicked = entryClicked;
                    $scope.loadAll = loadAll;
                    $scope.getCatiUser = getCatiUser;
                    $scope.checkCatiUser = checkCatiUser;
                    init();
                }
            };
        });
})();
