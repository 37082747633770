'use strict';
angular.module('acadiamasterApp').controller('InterstitialController', function($scope, ParseLinks, Interstitial, toastr, localWeb, entity) {
	$scope.uploadFile = uploadFile;
	$scope.removeFile = removeFile;
	entity.$promise.then(
		// On Success.
		function(response) {
			$scope.FileURL=response.data;
			if($scope.FileURL!=null){
				$scope.isFilePresent=true;
				$scope.url = localWeb.createUrl($scope.FileURL).replace("/ws/","/");
				$scope.zipurl=$scope.url.replace("html","zip");
			}else{
				$scope.isFilePresent=false;
			}
			return;
		},
		// On Failure.
		function(error) {
			console.log(error);
			toastr.error("Error occured while getting file, try again later!");
			return;
		});

	function removeFile() {
		Interstitial.removeFile().$promise.then(
			// On Success.
			function(response) {
				toastr.success("File has been remove succssfully");
				$scope.isFilePresent = false;
				return;
			},
			// On Failure.
			function(error) {
				console.log(error);
				toastr.error("Error occured while removing file, try again later!");
				return;
			});
	}

	function uploadFile() {
		var file = $scope.$$childHead.zipFile;
		var fdata = new FormData();
		fdata.append('file', file);
		Interstitial.uploadFile(fdata).$promise.then(
			// On Success.
			function(response) {
				toastr.success("Success");
				$scope.isFilePresent = true;
				return;
			},
			// On Failure.
			function(error) {
				console.log(error);
				toastr.error("Error occured while uploading file, try again later!");
				return;
			});
	};
	//as of now we will not be using this method we are hard coding the URL This is why the method is commented we will use this later
	/*   $scope.showFile=function(){
		   $scope.getFileData.htmlType="INTERSTITIAL";
		   Interstitial.showFile($scope.getFileData).$promise.then(
	             // On Success.
	     		function(response) {
	    			console.log("res: ", response);
	    			var type=null;
	    			var file = new Blob([response.data], {type: 'html'});
	    			if([response.status]==204){
	    				toastr.info("No data on the server.");
	    				return;
	    			}
	    			var objectUrl = URL.createObjectURL(file);
					console.log("File: ", file);
					var filename="index.html";
				    var a         = document.createElement('a');
				    a.href        = objectUrl;
				    a.target      = '_blank';
				    a.download    = filename;
				    a.click();
	 			},
	 		// On Failure.
	 			function(error) {
	 				console.log(error);
	 				toastr.error("Error occured while fetching report, try again later!");
	 				return;
	 			}
	         )
	   };*/
});