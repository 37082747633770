(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for dropdown  selector
     */
        .directive('formFieldConfigFieldValueApiDropDownSelector', function (FormConstants, $document) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/apiDropDownSelector.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.cs = FormConstants;
                    $scope.listToAvoid = [FormConstants.presetTypes.AoURP_HEADER];
                    $scope.presetChanged = function () {
                        $scope.fieldElement.presetChanged();
                    };

                    $scope.states = {
                        isPopupOpen: false
                    };

                    $scope.onPopoverOpened = function ($event) {
                        $document[0].body.click();
                        $event.stopPropagation();
                    };
                }
            };
        });
})();

