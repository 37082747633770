'use strict';

angular.module('vibrent-external-survey-parser')
    .service('RedcapParserUtilService', (CommonParserUtilService, VibrentFormStructureConstantService) => {
        const commonParser = CommonParserUtilService;
        const constantService = VibrentFormStructureConstantService;

        function getRedcapQuestionId(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion)) {
                return null;
            }
            return commonParser.getValueFromObject(surveyQuestion, 'field_name');
        }

        function getRedcapQuestionType(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion)) {
                return 'NONE';
            }
            const questionType = commonParser.getValueFromObject(surveyQuestion, 'field_type');
            if (commonParser.isEmpty(questionType)) {
                return 'NONE';
            }
            return questionType;
        }

        function getRedcapValidationType(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion)) {
                return 'NONE';
            }
            const value = commonParser.getValueFromObject(surveyQuestion, 'text_validation_type_or_show_slider_number');
            if (commonParser.isEmpty(value)) {
                return 'NONE';
            }
            return value;
        }

        function parseChoices(choicesString) {
            if (!choicesString) {
                return [];
            }

            const choices = [];

            choicesString.split('|').forEach((choiceWithIndex) => {
                const key = choiceWithIndex.substr(0, choiceWithIndex.indexOf(','));
                const value = choiceWithIndex.substr(choiceWithIndex.indexOf(',') + 1);

                if (key && value) {
                    choices.push({
                        key: key.trim(),
                        value: value.trim(),
                    });
                }
            });

            return choices;
        }

        /*
         * Method to parse choices to selector options in required order
         * @param choicesString - choice to be parse
         * @param spanishChoicesString - spanish choices
         * @returns [] array
         */
        function parseChoicesInExpectedOrder(choicesString, spanishChoicesString) {
            if (!choicesString) {
                return [];
            }

            const options = [];
            const choices = parseChoices(choicesString);
            const spanishChoices = parseChoices(spanishChoicesString);
            _.forEach(choices, (choice, index) => {
                const option = constantService.initSelectorOptionValue(commonParser.removeHtmlTagsIfRequired(choice.value), choice.key);

                if (spanishChoices && spanishChoices[index]) {
                    const spanishText = spanishChoices[index];
                    commonParser.addSpanishValueToMap(option.localizationMap, 'text', spanishText.value);
                    commonParser.addSpanishValueToMap(option.localizationMap, 'contentDescription', spanishText.value);
                }
                options.push(option);
            });
            return options;
        }

        function isNumeric(value) {
            return /^-{0,1}\d+$/.test(value);
        }

        /*
         * Given a list of field options, check if the keys are all numeric. If so return 'NUMBER' otherwise return 'STRING'
         * @param values array of values
         */
        function getKeyType(values) {
            let keyType = 'NUMBER';
            if (values) {
                for (let i = 0; i < values.length; i += 1) {
                    const valueItem = values[i];
                    if (!isNumeric(valueItem.value)) {
                        keyType = 'STRING';
                        break;
                    }
                }
            }

            return keyType;
        }

        function parseAndAddValidation(surveyQuestion, inputField, englishOverride, spanishOverride) {
            if (surveyQuestion == null || inputField == null) {
                return;
            }
            if (!inputField.validationRules) {
                inputField.validationRules = [];
            }

            const english = englishOverride || 'Please answer this question';
            const spanish = spanishOverride || 'Por favor responde esta pregunta';

            const requiredField = commonParser.getValueFromObject(surveyQuestion, 'required_field');
            if (requiredField === 'y') {
                const validationDto = constantService.initValidationDtoSpanishMessage('REQUIRED', english, spanish);
                inputField.validationRules.push(validationDto);
            }
        }

        function replaceLineFeedsIfRequired(value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<p>');
        }

        /**
         * Helper method to return text label or rich text label depending on field_label value.
         * Sets Spanish translation if surveyQuestion has language.ES
         */
        function createTextOrRichTextLabel(surveyQuestion, localIdGenerator) {
            const questionText = commonParser.getValueFromObject(surveyQuestion, 'field_label');

            let textLabel;
            if (/<\/?[a-z][\s\S]*>/i.test(questionText)) {
                //  create rich text field
                textLabel = constantService.initRichTextLabelForSelectorFieldTitle(localIdGenerator);
                textLabel.fieldValue.value = commonParser.removeCssFontTagsIfRequired(questionText);
            } else {
                //  create text label field
                textLabel = constantService.initFormFieldTextLabel(localIdGenerator, true);
                textLabel.fieldValue.value = questionText;
            }
            //  check if this survey question has Spanish translation
            if (surveyQuestion.language && surveyQuestion.language.ES) {
                textLabel.fieldValue.localizationMap.es = { 'value': commonParser.getValueFromObject(surveyQuestion.language.ES, 'field_label') };
            }
            return textLabel;
        }

        /**
         * Helper method to set Spanish translation of title subfield
         */
        function setSpanishTitleSubField(surveyQuestion, titleSubField, sourceKey) {
            const key = sourceKey || 'field_label';
            if(surveyQuestion.language && surveyQuestion.language.ES) {
                titleSubField.fieldValue.localizationMap.es = { 'value': commonParser.getValueFromObject(surveyQuestion.language.ES, key) };
            }
        }

        function getSpanishValueFromObject(surveyQuestion, key) {
            if (!commonParser.isObject(surveyQuestion) || commonParser.isEmpty(surveyQuestion)) {
                return '';
            }
            if (surveyQuestion.language && surveyQuestion.language.ES) {
                return commonParser.getValueFromObject(surveyQuestion.language.ES, key);
            }
            return '';
        }
        function getRedCapBranchingLogic(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion)) {
                return '';
            }
            const branchingLogic = commonParser.getValueFromObject(surveyQuestion, 'branching_logic');
            if (commonParser.isEmpty(branchingLogic)) {
                return '';
            }
            return branchingLogic;
        }

        return {
            getRedcapQuestionId,
            getRedcapQuestionType,
            getRedcapValidationType,
            parseAndAddValidation,
            isNumeric,
            getKeyType,
            parseChoicesInExpectedOrder,
            replaceLineFeedsIfRequired,
            createTextOrRichTextLabel,
            setSpanishTitleSubField,
            getSpanishValueFromObject,
            getRedCapBranchingLogic,
        };
    })

    .directive('fileModel', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                let model = $parse(attrs.fileModel);
                let modelSetter = model.assign;
                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            },
        };
    }]);
