/**
 * directive for configuring filter on a list of properties
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.directive('propertyListFilter', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/advancedPanel/propertyListFilter.html',
            scope: {
                pList: '=',
                formName: '='
            },
            link: function ($scope) {
                $scope.isPropertyOn = function(property) {
                    var keyValue = property.filter;
                    return keyValue && keyValue.enabled;
                };

                $scope.toggleProperty = toggleProperty;

                $scope.shouldShowUnits = function(property) {
                    return property.choices==null && property.isNumber &&
                        property.unitsKey!=null && property.unitsKey.length>0;
                }
            }
        };

        /******************************************************************************
         * private functions
         ******************************************************************************/

        /**
         * toggle property by the key
         * @param property - property object
         */
        function toggleProperty(property) {
            var keyValue = property.filter;
            if (keyValue.enabled) {
                keyValue.disable();
            }
            else {
                keyValue.enable();
            }
        }

    });
})();

