/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * simple filter for checking if option is selectable
     */
        .filter('filterSelectableOptions', function(FormConstants) {

            function isOptionSelectable(option, formVersion) {
                var mode = null;
                if (option.name=='EDIT_MODE_PAGES' || option.name=='EDIT_MODE_STATE_RULES') {
                    mode = formVersion.editMode;
                }
                else if (option.name=='PROMPT_MODE_PAGES' || option.name=='PROMPT_MODE_STATE_RULES') {
                    mode = formVersion.promptMode;
                }
                else if (option.name=='VIEW_ENTRY_MODE_PAGES' || option.name=='VIEW_ENTRY_MODE_STATE_RULES') {
                    mode = formVersion.viewMode;
                }

                if (mode!=null) {
                    return mode.templateType == FormConstants.templateType.CUSTOM;
                }
                else {
                    return true;
                }
            }

            function filterSelectableOptions(options, formVersion) {
                var filtered = [];
                _.forEach(options, function(option) {
                    if (isOptionSelectable(option, formVersion)) {
                        filtered.push(option);
                    }
                });

                return filtered;
            }

            return filterSelectableOptions;
        });
})();

