'use strict';

(function () {
    angular.module('acadiamasterApp').directive('queryGroupConfig', function (ProgramTestConstantsService) {
        return {
            restrict: 'E',
            scope: {
                group: '='
            },
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/' +
            'queryGroupConfig.html',
            link: function ($scope) {
                $scope.cs = ProgramTestConstantsService;
            }
        };
    });
})();