/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for display config
     */
        .directive('formDisplayConfig', function (vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/displayConfig.html',
                scope: {
                    element: '=configModel',
                    displayConfigChange : '='
                },
                link: function ($scope) {
                    $scope.vcu = vbrCommonUtil;

                    $scope.colorBackgroundNeeded = function () {
                        if ($scope.element!=null && $scope.element.displayConfig!=null) {
                            var displayConfig = $scope.element.displayConfig;
                            return  (!vbrCommonUtil.isUnavailable(displayConfig.color) ||
                            !vbrCommonUtil.isUnavailable(displayConfig.bgColor) ||
                            !vbrCommonUtil.isUnavailable(displayConfig.backgroundImage));
                        }
                        return false;
                    };

                    $scope.isCustomPreset = function() {
                        if (_.isFunction($scope.element.isCustomPreset)) {
                            return $scope.element.isCustomPreset();
                        } else {
                            return true;
                        }
                    };
                }
            }
        });

})();

