import cs from '../../../const/programContainer.constants.service';
import template from './containerItemTypeSpecificConfigContent.html';

angular.module('acadiamasterApp')
    .directive('containerItemTypeSpecificConfigContent', (LocaleConstantsService, FormSearch, $state) => ({
        link ($scope) {
            $scope.lcs = LocaleConstantsService;
            $scope.cs = cs;
            $scope.platformsObj = cs.platformsObj;

            $scope.data = {
                forms          : null,
                selectedForm   : null,
                selectedSource : null,
            };

            const externalSourcesMap = [
                {
                    dataSourceType : 'BCTD_MODULE',
                    display        : 'Exploring the Mind',
                    sourceId       : '9aa89c0e-fcf8-4838-a2c6-96e0e7f10a26',
                }, {
                    dataSourceType : 'FHIR_FORM_ENTRY_LATEST',
                    display        : 'FHIR Form Entry (Latest)',
                    sourceId       : null,
                }, {
                    dataSourceType : 'FHIR_FORM_ENTRY_ALL',
                    display        : 'FHIR Form Entry (All)',
                    sourceId       : null,
                }, {
                    dataSourceType : 'PMB_RESULT',
                    display        : 'Physical Measurements Results',
                    sourceId       : null,
                },
            ];

            let currSource = null;
            $scope.externalSourcesDisplayList = [];
            externalSourcesMap.forEach(source => {
                if (source.dataSourceType === $scope.config.dataSourceType) {
                    currSource = source;
                }
                $scope.externalSourcesDisplayList.push(source);
            });
            $scope.data.selectedSource = currSource;

            $scope.externalSourceUpdate = function (source) {
                $scope.config.sourceId = source?.sourceId;
                $scope.config.dataSourceType = source?.dataSourceType;
                $scope.config.formId = null;
                $scope.data.selectedForm = null;
                $scope.data.selectedSource = source;
            };
            $scope.selectForm = () => {
                $scope.config.formId = $scope.data.selectedForm.value;
                $scope.config.sourceId = null;
            };
            $scope.loadForms = () => {
                FormSearch.query({
                    programId : $state.params.id,
                    size      : 500,
                }).then(response => {
                    $scope.data.forms = [];
                    $scope.data.forms = response.data.map(form => {
                        const formModel = {
                            displayName : `${form.id}|${form.name}`,
                            value       : form.id,
                        };
                        if (formModel.value === $scope.config.formId) {
                            $scope.data.selectedForm = formModel;
                        }
                        return formModel;
                    });
                }, error => {
                    console.error('Error getting forms from API', error);
                });
            };
            $scope.loadForms();
        },
        restrict : 'E',
        scope    : {
            config    : '=',
            container : '=',
        },
        template : template,
    }));
