/**
 * directive for display a tree node view of one container item entry
 */

angular.module('acadiamasterApp')
    .directive('treeNodeContainerItem', (FormPreviewService, $sce) => ({
        link($scope) {
            $scope.$sce = $sce;

            $scope.preview = () => {
                const form = $scope.containerItem.form;
                FormPreviewService.preview(form);
            };

            $scope.selectItem = () => {
                $scope.selector.selectItem($scope.containerItem);
            };
        },
        restrict: 'E',
        scope: {
            containerItem: '=',
            parent: '=',
            selector: '=',
            showTemplatePreview: '=',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/treeNode/containerItem.treeNode.html',
    }));
