(function () {
    'use strict';
    /**
     * directive to show a list of pre condition execution logs
     */
    angular.module('acadiamasterApp').directive('preConditionExecutionLogs', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/testRun/preConditionExecutionLogs.html',
            scope: {
                // pre condition execution info objects
                logs: '='
            },
            link: function ($scope) {
                // empty for now
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/



    });

})();



