
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for display config of font information
     */
        .directive('formDisplayConfigFont', function (FormUtilService, FormFontService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/font.html',
                scope: {
                    displayConfig: '=',
                    displayConfigChange: '=',
                    element : '='
                },
                link: function ($scope) {
                    $scope.fontOptions = FormFontService.getFontOptions();
                    $scope.fontSizeOptions = {
                        from : 6, to: 60, step : 1, skin: "blue"
                    };

                    $scope.lineHeightOptions = new FormUtilService.vus.ValueUnitModelOption('Line Height', ['%'],
                        null, null, new FormUtilService.vus.ValueWithUnitModel(null, "%"));

                    $scope.styleClicked = function (target) {
                        var styleCheckedValue = $scope.displayConfig.font.fontStyle[target];
                        if (target == 'normal') {
                            if (styleCheckedValue) {
                                uncheckOtherStyles($scope.displayConfig.font.fontStyle);
                            }
                        }
                        else {
                            if (styleCheckedValue && $scope.displayConfig.font.fontStyle.normal) {
                                $scope.displayConfig.font.fontStyle.normal = false;
                            }
                        }
                    };

                    $scope.switchFontSizeStyle = function() {
                        if ($scope.displayConfig.font.fontSize==null) {
                            $scope.displayConfig.font.fontSize = FormConstants.systemDefaults.font.fontSize;
                        }
                        else {
                            $scope.displayConfig.font.fontSize = null;
                        }
                    };

                    $scope.switchFontNameInheritance = function() {
                        if ($scope.displayConfig.font.fontName==null) {
                            $scope.displayConfig.font.fontName = FormConstants.systemDefaults.font.fontName;
                        }
                        else {
                            $scope.displayConfig.font.fontName = null;
                        }
                    }
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function uncheckOtherStyles(fontStyle) {
                fontStyle.bold = false;
                fontStyle.italic = false;
            }

        });
})();
