'use strict';

angular.module('acadiamasterApp')
    .config(($stateProvider) => {
        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('businessRule');
            return $translate.refresh();
        }

        $stateProvider
            .state('scheduleRuleExecutionAudits', {
                parent: 'ruleDashboard',
                url: '/scheduleRuleExecutionAudits',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'scheduleRule.execution.audit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/scheduleRuleAudit/scheduleRuleExecutionAudits.html',
                        controller: 'ScheduleRuleExecutionAuditsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)]
                }
            })
            .state('scheduleRuleExecutionPageAudits', {
                parent: 'scheduleRuleExecutionAudits',
                url: '/{auditId}/pages',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'scheduleRule.execution.pageAudit.title'
                },
                views: {
                    'content@': {
                        bindToController: true,
                        templateUrl: 'admin-templates/site/businessRule/scheduleRuleAudit/scheduleRuleExecutionPageAudits.html',
                        controller: 'ScheduleRuleExecutionPageAuditsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)]
                }
            })
            .state('workflowInstanceAudits', {
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'scheduleRule.execution.workflowInstanceAudit.title',
                },
                parent: 'scheduleRuleExecutionPageAudits',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/{pageAuditId}/workflowInstanceAudits',
                views: {
                    'content@': {
                        bindToController: true,
                        templateUrl: 'admin-templates/site/businessRule/scheduleRuleAudit/workflowInstanceAudits.html',
                        controller: 'WorkflowInstanceAuditsController'
                    },
                },
            });
    });
