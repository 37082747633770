/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')


    /**
     * directive for configuration of changes on validation rules
     */
        .directive('formConfigChangeValidationRules', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/changeConfig.validationRules.html',
                scope: {
                    targetElement: '=',
                    validationRuleChanges : '='
                },
            };
        });
})();

