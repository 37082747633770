(function () {
    'user strict';

    angular.module('acadiamasterApp')
        .factory('RoleService', function ($q, $http, localWeb) {
            var baseApi = localWeb.createUrl('api/v2/roles');

            return {
                getRoles: function() {
                    var deferred = $q.defer();

                    var success = function(response) {
                        var data = response.data;

                        var resolved = {
                            roles: data
                        };
                        deferred.resolve(resolved);
                    };

                    var error = function(err) {
                        deferred.reject(err);
                    };

                    $http.get(baseApi).then(success).catch(error);

                    return deferred.promise;
                }
            };
        });
})();
