'use strict';

angular.module('acadiamasterApp')
    .controller('UnitController', function ($scope, Unit, ParseLinks, ngDialog) {
        $scope.units = [];
        $scope.page = 0;
        $scope.loadAll = function() {
            Unit.query({page: $scope.page, size: 20}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.units = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function(id) {
            Unit.get({
                id: id
            }, function(result) {
                $scope.unit = result;
                ngDialog.openConfirm({
                    template: 'admin-templates/site/unitSystem/unit/unitDelete.html',
                    controller: 'UnitDeleteController',
                    data: $scope.unit
                }).then(function(value) {
                    $scope.refresh();
                });
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.unit = {symbol: null, name: null, conversionFormula: null, conversionRatioAgainstBase: null, id: null};
        };
    });
