(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for config Image preset
     */
        .directive('formFieldConfigImagePreset', function (ImagePresetConstants) {
            // init image dir map
            var imageDirMap = {};
            imageDirMap[ImagePresetConstants.CHECK_BOX_BLUE] = 'check_box_blue';
            imageDirMap[ImagePresetConstants.CIRCULAR_BLUE] = 'radio_blue';

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/imagePresetConfig.html',
                scope: {
                    fieldValue: '='
                },
                link: function ($scope) {
                    $scope.ImagePresetConstants = ImagePresetConstants;

                    $scope.imagePresetChangedCallBack = function(newPreset) {
                        $scope.fieldValue.imagePresetChanged(newPreset);
                    };

                    /**
                     * getting the image url for a specific type
                     * @param typeName - type name, ie: 'selected'
                     * @returns {string} - url to get the image, ie: /images/imagePresets/radio_blue/selected.png
                     */
                    $scope.getImageUrl = function(typeName) {
                        var dirName = imageDirMap[$scope.fieldValue.imagePreset];
                        return '/assets/images/imagePresets/' + dirName + '/' + typeName + '.png';
                    }
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
        });

})();