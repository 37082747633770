/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for action configuration
     */
        .directive('formElementConfigAction', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfig.action.html',
                scope: {
                    element: '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.updateFieldsBasedOnPageChangeType = function(newPageChangeType) {
                        $scope.element.updateFieldsBasedOnPageChangeType(newPageChangeType);
                    };

                    $scope.targetTypeChanged = function(newTargetType) {
                        $scope.element.targetTypeChanged(newTargetType);
                    };

                    $scope.gotoLinkedElement = function() {
                        var linkedElement = $scope.element.findLinkedElement();
                        if (linkedElement!=null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, "_parent", ['collapsed', 'sectionCollapsed'], false);
                        }
                    };

                    $scope.filterOutFieldTypes = function (listToFilterFrom) {
                        var eligibleFields = [];
                        if (listToFilterFrom != null) {
                            for (var i = 0; i < listToFilterFrom.length; i++) {
                                switch ($scope.element.actionType) {
                                    case FormConstants.stateRuleActionType.RESET_CONFIG:
                                    case FormConstants.stateRuleActionType.CHANGE_CONFIG:
                                        if (listToFilterFrom[i].type != FormConstants.fieldsType.CALCULATED_SCORE) {
                                            eligibleFields.push(listToFilterFrom[i]);
                                        }
                                        break;
                                    default:
                                        eligibleFields.push(listToFilterFrom[i]);
                                        break;

                                }
                            }
                        }
                        return eligibleFields;
                    };
                }
            };
        });
})();