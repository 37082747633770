/**
 * Created by moustafabaiou on 4/26/17.
 */
(function () {
    angular.module('acadiamasterApp')
        .controller('DonationSiteController', ($scope, ngDialog, DonationSiteService) => {
            $scope.selectedProgramCode = 'Choose Program';

            $scope.isEnabled = true;
            $scope.sites = [];
            $scope.searchData = {};
            $scope.page = 0;
            $scope.filterData = { ascending : false, jumpTo : null, orderByChoices : [], orderBySelected : 'updatedOn', pageSizeChoices : [ 20, 50, 100 ], pageSizeSelected : 20, searchResultCount : 'loading ...', searchString : '' };
            function loadSites (programId) {
                let programIdForFilter = programId;
                if ($scope.selectedProgram && !programId) {
                    programIdForFilter = $scope.selectedProgram.programId;
                }
                DonationSiteService.getSites($scope.page, $scope.filterData.pageSizeSelected, programIdForFilter)
                    .then(data => {
                        $scope.sites = data.sites;
                        $scope.links = data.links;
                        $scope.filterData.searchResultCount = data.totalCount;
                    })
                    .catch(error => {
                        console.error('ERROR loading codes: ', error);
                    });
            }

            $scope.filterByProgramId = program => {
                if (program.programId) {
                    loadSites(program.programId);
                }
            };

            loadSites();

            $scope.resetFillter = () => {
                $scope.selectedProgramCode = 'Choose Program';
                $scope.selectedProgram = null;
                loadSites();
            };
            $scope.loadAll = function () {
                loadSites();
            };
            $scope.loadPage = function (page, pageSize, jumpNumber) {
                $scope.page = page;
                if (pageSize) {
                    $scope.filterData.pageSizeSelected = pageSize;
                }
                if (jumpNumber >= 0) {
                    $scope.page = jumpNumber;
                }
                loadSites();
            };

            $scope.refresh = function () {
                $scope.page = 0;
                $scope.loadAll();
            };

            $scope.exportCodes = function () {
                return DonationSiteService.exportSites($scope.searchData.searchResultCount);
            };
        });
}());

