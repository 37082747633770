(() => {
    angular.module('acadiamasterApp')
        .config(($stateProvider) => {
            const loadTranslatePartial = ($translate, $translatePartialLoader) => {
                $translatePartialLoader.addPart('programTest');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            };

            $stateProvider
                .state('programTests.programTestSuiteExecutionAudits', {
                    parent: 'programTests',
                    url: '/test-suite-execution-audits{params}',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                        pageTitle: 'Program Test Suite Execution Audit',
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/programTests/programTestSuiteExecutionAudit/programTestSuiteExecutionAudit.html',
                            controller: 'ProgramTestSuiteExecutionAuditController',
                            controllerAs: 'ac',
                        },
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader',
                            ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                    },
                });
        });
})();
