/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for display config of color and background properties
     */
        .directive('formDisplayConfigColorBackground', function (vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/color.and.background.html',
                scope: {
                    displayConfig: '=',
                    displayConfigChange: '=',
                    element : '='
                },
                link: function ($scope) {
                    $scope.vcu = vbrCommonUtil;
                }
            };
        });


})();

