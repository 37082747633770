/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
/* eslint-disable no-eq-null */
/* eslint-disable no-param-reassign */
/* eslint-disable valid-jsdoc */
import cs from '../const/programContainer.constants.service';
angular.module('acadiamasterApp').factory('PlatformModel', () => {
    const platforms = cs.platforms;

    /** *************************************************************
     * constructor for container behavior model
     ************************************************************** */
    function PlatformModel () {
        const _this = this;

        // initialize every key's value to null
        platforms.forEach(k => _this[k] = null);
    }

    /**
     * convert the current UI model to dto format
     */
    PlatformModel.prototype.toDto = function () {
        const dto = {};

        let hasValue = false;
        const _this = this;
        platforms.forEach(k => {
            dto[k] = _this[k];

            if (dto[k]) {
                hasValue = true;
            }
        });

        // if there is no value, then just return null to make it more efficient processing on server side
        return hasValue ? dto : null;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    PlatformModel.prototype.fromDto = function (dto) {
        if (dto === null || dto === undefined) {
            dto = {};
        }

        const _this = this;
        platforms.forEach(k => _this[k] = dto[k]);
    };

    /**
     * check if the model has any non-null value on any language
     */
    PlatformModel.prototype.hasValue = function () {
        const _this = this;
        const valueFound = _.find(platforms, k => {
            const v = _this[k];
            return v != null && v.trim().length > 0;
        });

        return valueFound != null;
    };

    /** *************************************
     * private function call
     ************************************** */

    /** *************************************
     * service return call
     ************************************** */

    return PlatformModel;
});
