/**
 * Created by ashmitakumar on 7/27/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('tag', {
                    parent: 'site',
                    url: '/tag',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/inviteCodes/inviteCodes.html',
                            controller: 'InviteCodesController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('tag');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        });
})();
