'use strict';

(function () {

    angular.module('acadiamasterApp')
        .directive('isProgramDayInRangeConfig', function ($translate, ProgramTestConstantsService, TimeComparisonOperatorService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/' +
            'isProgramDayInRange.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.BETWEEN = TimeComparisonOperatorService.BETWEEN.name;
                $scope.ProgramTestConstantsService = ProgramTestConstantsService;
                $scope.TimeComparisonOperatorService = TimeComparisonOperatorService;

                /**
                 * getting a quick description of the condition, this is slightly different than the tree node display
                 * @returns {string}
                 */
                $scope.getDescription = function() {
                    let condition = $scope.condition;
                    let description = condition.comparator == null ? '?' : condition.comparator.description;

                    if(condition.comparator.name === $scope.BETWEEN) {
                        let timeMsgBegin = timeMsgBuilder(condition.valueBeginAt);
                        let timeMsgEnd = timeMsgBuilder(condition.valueEndAt);
                        return $translate.instant('acadiamasterApp.ifCondition.joinCondition.programDay.between',{description: description, timeBegin: timeMsgBegin, timeEnd: timeMsgEnd});
                    }

                    let timeMsg = timeMsgBuilder(condition.value);
                    return $translate.instant('acadiamasterApp.ifCondition.joinCondition.programDay.not-between',{description: description, timeAgo: timeMsg});
                };
            }
        };

        /***************************************
         * private function
         ***************************************/
        function timeMsgBuilder(value) {
            return value == null ? '?' : value;
        }
    });
})();
