/**
 * Created by pravin.gayal on 05-06-2017.
 */

const pageTypeNameOptions = {
    about         : 'ABOUT',
    agreements    : 'AGREEMENTS',
    appointments  : 'APPOINTMENTS',
    containerPage : 'CONTAINER_PAGE',
    notifications : 'NOTIFICATIONS',
    settings      : 'SETTINGS',
    support       : 'SUPPORT',
    syncDevices   : 'SYNC_DEVICES',
};

angular.module('acadiamasterApp')
    .service('ProgramUtilConstantsService', () => ({
        nodeType : {
            PROGRAM : 'program',
        },
        durationTypes : {
            DAY     : { name : 'DAY', text : 'DAY' },
            WEEK    : { name : 'WEEK', text : 'WEEK' },
            MONTH   : { name : 'MONTH', text : 'MONTH' },
            YEAR    : { name : 'YEAR', text : 'YEAR' },
            NOLIMIT : { name : 'NOLIMIT', text : 'NOLIMIT' },
        },
        consentType : {
            EHR       : 'EHR',
            PII       : 'PII',
            REFRESHER : 'REFRESHER',
        },
        syncDevicesOptions : {
            FITBIT : {
                displayText : 'Enable Fitbit Sync',
                name        : 'FITBIT',
            },
            HEALTHKIT_BYOD : {
                name        : 'HEALTHKIT_BYOD',
                displayText : 'Enable Apple HealthKit Sync',
            },
            OMRON : {
                name        : 'OMRON',
                displayText : 'Enable Omron Sync',
            },
            HEALTHKIT_EHR : {
                displayText : 'Enable Apple HealthKit EHR Sync',
                name        : 'HEALTHKIT_EHR',
            },
            BLUE_BUTTON : {
                displayText : 'Enable Claims & EHR Data Sync',
                name        : 'BLUE_BUTTON',
            },
            SAMSUNG_HEALTH : {
                displayText : 'Enable Samsung Health Sync',
                name        : 'SAMSUNG_HEALTH',
            },
            OURA : {
                displayText : 'Enable Oura Sync',
                name        : 'OURA',
            },
        },
        tabBarItemMap : {
            CONTAINER_PAGE : {
                icon : 'fa-pencil', name : 'CONTAINER_PAGE', required : false, serverKey : 'CONTAINER_PAGE', used : false, pageType : 'CONTAINER_PAGE',
            },
            ABOUT : {
                name : 'ABOUT', icon : 'fa-group', required : false, serverKey : 'ABOUT', used : false, pageType : 'ABOUT',
            },
            AGREEMENTS : {
                icon      : 'fa-handshake-o', name      : 'AGREEMENTS', required  : false,
                serverKey : 'AGREEMENTS', used      : false, pageType  : 'AGREEMENTS',
            },
            DASHBOARD : {
                icon : 'fa-pencil', name : 'DASHBOARD', required : false, serverKey : 'DASHBOARD', used : false, pageType : 'CONTAINER_PAGE',
            },
            MY_DATA : {
                icon : 'fa-bar-chart', name : 'MY DATA', required : false, serverKey : 'MY_DATA', used : false, pageType : 'CONTAINER_PAGE',
            },
            NOTIFICATIONS : {
                icon      : 'fa-bell', name      : 'NOTIFICATIONS', required  : false,
                serverKey : 'NOTIFICATIONS', used      : false, pageType : 'NOTIFICATIONS',
            },
            SETTINGS : {
                icon : 'fa-gear', name : 'SETTINGS', required : false, serverKey : 'SETTINGS', used : false, pageType : 'SETTINGS',
            },
            SUPPORT : {
                icon : 'fa-comments-o', name : 'SUPPORT', required : false, serverKey : 'SUPPORT', used : false, pageType : 'SUPPORT',
            },
            SYNC_DEVICES : {
                icon      : 'fa-refresh', name      : 'SYNC DEVICES', required  : false,
                serverKey : 'SYNC_DEVICES', used      : false, pageType : 'SYNC_DEVICES',
            },
            APPOINTMENTS : {
                icon      : 'fa-calendar', name      : 'APPOINTMENTS', required  : false,
                serverKey : 'APPOINTMENTS', used      : false, pageType : 'APPOINTMENTS',
            },
            LEARNING_CENTER : {
                icon      : 'fa-leanpub', name      : 'LEARNING CENTER', required  : false,
                serverKey : 'LEARNING_CENTER', used      : false, pageType : 'CONTAINER_PAGE',
            },
            SHARE_MY_DATA : {
                icon      : 'fa-refresh', name      : 'SHARE MY DATA', required  : false,
                serverKey : 'SHARE_MY_DATA', used      : false, pageType : 'CONTAINER_PAGE',
            },
            STORIES : {
                icon      : 'fa-leanpub', name      : 'STORIES', required  : false,
                serverKey : 'STORIES', used      : false, pageType : 'CONTAINER_PAGE',
            },
        },
        pageTypeNameOptions,
        pageTypeNameOptionIds: Object.values(pageTypeNameOptions),
    }));
