/**
 * Created by Jason Cao on 10/21/2017
 * comparison result form mode service
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultFormModeService',
        function (CompareResultUtilService, CompareResultDisplayConfigService, CompareResultPageService, CompareResultStateRuleService,
                  CompareResultNavigationNodeService) {

            /**
             * compare two form modes and build a combined version
             * @param fm1 - form mode 1
             * @param fm2 - form mode 2
             * @param title - title of the mode
             */
            function compare(fm1, fm2, title) {
                var formModeDetail = {
                    templateType: CompareResultUtilService.comparePrimitives(fm1.templateType.name, fm2.templateType.name)
                };

                formModeDetail._status = CompareResultUtilService.collectStatus(formModeDetail);

                var result = {
                    _isOpen : true,
                    _title : title,
                    detail: formModeDetail,
                    displayConfig : CompareResultDisplayConfigService.compare(fm1.displayConfig, fm2.displayConfig),
                    pages: CompareResultUtilService.compareArrays(fm1.pages, fm2.pages, title, CompareResultPageService.compare),
                    navigationNodes: CompareResultUtilService.compareArrays(fm1.navigationNodes, fm2.navigationNodes, title,
                        CompareResultNavigationNodeService.compare),
                    stateRules: CompareResultUtilService.compareArrays(fm1.stateRules, fm2.stateRules, title,
                        CompareResultStateRuleService.compare)
                };

                result._pagesStatus = CompareResultUtilService.collectStatus(result.pages);
                result._navigationNodesStatus = CompareResultUtilService.collectStatus(result.navigationNodes);
                result._stateRulesStatus = CompareResultUtilService.collectStatus(result.stateRules);
                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

