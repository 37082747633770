import cs from '../../const/programContainer.constants.service';
/**
 * container item form configuration
 * note: form id is intentionally left at the higher level configuration for search purpose
 */
angular.module('acadiamasterApp').factory('ContainerItemFormConfig', (
    ContainerItemTypeSpecificConfig, LocalizedStringModel, FormConstants,
) => {
    const classKey = cs.classes.classReferenceKey;
    ContainerItemFormConfig.inheritsFrom(ContainerItemTypeSpecificConfig);

    /**
     * @constructor
     */
    function ContainerItemFormConfig(parent) {
        ContainerItemTypeSpecificConfig.call(this, parent);

        this[classKey] = cs.classes.ContainerItemFormConfig;

        this.maxEntryAllowed = 1;
        this.numEntryAsComplete = 1;
        this.supportPausing = true;
        this.useCustomButtonConfig = false;
        this.buttonLabel = new LocalizedStringModel();
        this.buttonType = FormConstants.submitButton.buttonType.BUTTON_PRIMARY.name;
    }
    /**
     * convert the current UI model to dto format
     */
    ContainerItemFormConfig.prototype.toDto = function () {
        const dto = ContainerItemTypeSpecificConfig.prototype.toDto.call(this);
        dto.maxEntryAllowed = this.maxEntryAllowed;
        dto.numEntryAsComplete = this.numEntryAsComplete;
        dto.supportPausing = this.supportPausing;
        dto.useCustomButtonConfig = this.useCustomButtonConfig;
        dto.buttonLabel = this.buttonLabel.toDto();
        dto.buttonType = this.buttonType;
        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemFormConfig.prototype.fromDto = function (dto) {
        ContainerItemTypeSpecificConfig.prototype.fromDto.call(this, dto);
        this.maxEntryAllowed = dto.maxEntryAllowed;
        this.numEntryAsComplete = dto.numEntryAsComplete;
        this.supportPausing = dto.supportPausing;
        this.useCustomButtonConfig = dto.useCustomButtonConfig;
        this.buttonLabel.fromDto(dto.buttonLabel);
        this.buttonType = dto.buttonType;
    };

    ContainerItemFormConfig.prototype.getDescriptionAsHtml = function () {
        const maxEntryInfo = getBadgeNumber(this.maxEntryAllowed, "Max entry allowed");
        const numOfEntryAsCompleteInfo = getBadgeNumber(this.numEntryAsComplete, "Number of entry count as completed");
        const supportPausingInfo = getPauseableLabel(this.supportPausing);

        return `${maxEntryInfo}/${numOfEntryAsCompleteInfo} | ${supportPausingInfo}`;
    };


    /** *************************************
     * private function call
     ************************************** */

    function getBadgeNumber(inputNumber, title) {
        return `<span class="badge badge-info" title="${title}">${inputNumber}</span>`;
    }

    function getPauseableLabel(enabled) {
        if (enabled) {
            return `<span class="label label-success" title="Pause Enabled">P</span>`;
        }
        return `<span class="label label-warning" title="Pause Disabled">NP</span>`;
    }

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemFormConfig;
});
