(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrDrcPairingManagement', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/drc/pairingManagement/drc.pairingManagement.html',
                controller: function($scope, TILE_CONSTANTS) {
                    $scope.data = {
                        tileCollectionId: TILE_CONSTANTS.ID.DRC_PARTICIPANT_PAIRING_MANAGEMENT
                    };
                }
            };

        });

})();
