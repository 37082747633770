(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }

            $stateProvider.state('drcPairingHistory', {
                parent: 'drcPairingManagement',
                url: '/history',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'Pairing History'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-pairing-history></vbr-drc-pairing-history>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            $stateProvider.state('drcPairingSearch', {
                parent: 'drcPairingManagement',
                url: '/search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'Search'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-pairing-search></vbr-drc-pairing-search>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
        });
})();
