/**
 * directive for displaying a boolean value as yes or no with icon
 */
angular.module('acadiamasterApp').directive('booleanDisplayAsYesNo', (FormPreviewService) => {
    return {
        restrict: 'E',
        scope: {
            value: '='
        },
        templateUrl: 'admin-templates/util/smallDisplay/booleanDisplayAsYesNo.html',
    }
});
