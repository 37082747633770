(function () {
    angular.module('acadiamasterApp')

        .factory('CountService', ($http, localWeb) => {
            return {
                getAccessControlCounts : function () {
                    return $http.get(localWeb.createUrl('api/count/accessControl'));
                },

                getAllCounts : function () {
                    return $http.get(localWeb.createUrl('api/count/all'));
                },

                getDataTypeCounts : function () {
                    return $http.get(localWeb.createUrl('/api/dataTypeMaster/dataTypeMasterCount/'));
                },

                getFormCounts : function () {
                    return $http.get(localWeb.createUrl('api/count/form'));
                },

                getProgramCounts : function () {
                    return $http.get(localWeb.createUrl('api/count/program'));
                },
                getProgramTestCounts : function () {
                    return $http.get(localWeb.createUrl('api/programTest'));
                },

                getRuleCounts : function () {
                    return $http.get(localWeb.createUrl('api/count/rule'));
                },
                getUnitCounts : function () {
                    return $http.get(localWeb.createUrl('api/unitSystemCounts'));
                },
            };
        });
}());
