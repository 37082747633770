(function () {
    'use strict';
    /**
     * directive to show a list of logging messages with logging level and timestamp
     */
    angular.module('acadiamasterApp').directive('loggingMessageList', function () {
        // current list of filtered messages, this is needed for angular so it doesn't think every time
        // function is called, value is always changing
        var filteredMessages = [];
        var LEVELS = {
            TRACE : {level : 0, name: 'TRACE'},
            DEBUG : {level : 1, name: 'DEBUG'},
            INFO : {level : 2, name: 'INFO'},
            WARN : {level : 3, name: 'WARN'},
            ERROR : {level : 4, name: 'ERROR'},
            FATAL : {level : 5, name: 'FATAL'},
        };

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/logging/loggingMessageList.html',
            scope: {
                // list of logging messages
                logs: '='
            },
            link: function ($scope) {
                /*
                    levels constants
                 */
                $scope.LEVELS = LEVELS;

                $scope.data = {
                    // message count, default to last 50
                    messageCount: -50,
                    level: $scope.LEVELS.DEBUG,
                    availableOptions: [
                        {name: 'All', value: 0},
                        {name: 'Last 50', value: -50},
                        {name: 'Last 100', value: -100},
                        {name: 'Last 250', value: -250},
                        {name: 'First 50', value: 50},
                        {name: 'First 100', value: 100},
                        {name: 'First 250', value: 250}
                    ],
                };

                $scope.getFilteredLogs = function () {
                    return getFilteredLogs($scope.logs, $scope.data.messageCount, $scope.data.level);
                };

                $scope.hasMessages = function () {
                    return $scope.logs && $scope.logs.length > 0;
                };
            }
        };

        /***************************************************************
         * private functions
         ************************************************************** */

        /**
         * check if two list of messages are identical or not
         * ps. 1. we are doing one level shallow comparison, element vs element is reference check
         * 2. empty list and null list are the same
         * @param messages1 - message 1
         * @param messages2 - message 2
         * @returns {boolean} - true if both list are the same, false otherwise.
         */
        function messageListIdentical(messages1, messages2) {
            // check size first
            var size1 = messages1==null ? 0 : messages1.length;
            var size2 = messages2==null ? 0 : messages2.length;

            if (size1 !== size2) {
                // size not equal, so definitely different list
                return false;
            }

            if (size1 == 0) {
                // size is equal and both are 0, they are the same
                return true;
            }

            // now size are the same, let's check if every element in the list is the same (order matters here)
            for (var i=0; i<messages1.length; i++) {
                if (messages1[i] != messages2[i]) {
                    return false;
                }
            }

            return true;
        }

        /**
         * getting a list of messages that has been filtered
         * @param logs - all the logs
         * @param targetLevel - level to filter for
         * @param messageCount - message count, 0 means all, negative number means last n messages, positive number means first n messages
         * @returns {*}
         */
        function getFilteredLogs(logs, messageCount, targetLevel) {
            if (messageCount == 0) {
                return logs;
            }

            var localFilteredMessage = [];
            var startIndex, index;
            var total = 0;
            var totalItem = Math.abs(messageCount);
            if (messageCount < 0) {
                // last n messages
                startIndex = logs.length - 1;
                for (index = startIndex; index >=0 && total<totalItem; index-- ) {
                    if (shouldMessageBeDisplayed(logs[index], targetLevel)) {
                        localFilteredMessage.push(logs[index]);
                        total++;
                    }
                }
            }
            else {
                // first n messages
                for (index = 0; index < logs.length && total<totalItem; index++ ) {
                    if (shouldMessageBeDisplayed(logs[index], targetLevel)) {
                        localFilteredMessage.push(logs[index]);
                        total++;
                    }
                }
            }

            if (!messageListIdentical(localFilteredMessage, filteredMessages)) {
                filteredMessages = localFilteredMessage;
            }

            return filteredMessages;
        }


        /**
         * check if the message's level is matching the level we are suppose to display, this include level higher than
         * the level that we need to display
         * @param message - message to be checked
         * @param targetLevel - level for which we should be displaying (ie: anything higher or equal this)
         * @returns {boolean} - true if message should be displayed, false otherwise
         */
        function shouldMessageBeDisplayed(message, targetLevel) {
            if (message == null || message.level==null || LEVELS[message.level]==null) {
                // null message or message with no level, don't display it
                return false;
            }

            if (targetLevel == null) {
                // no level specified, always display
                return true;
            }

            var levelNumber = LEVELS[message.level].level;

            return levelNumber >= targetLevel.level;
        }

    });

})();



