/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */

import cs from '../../../const/programContainer.constants.service';
/**
 * directive for configuring the template content value of simple button type
 */
angular.module('acadiamasterApp').directive(
    'templateValueButtonSimpleConfig',
    ($state, LocaleConstantsService, TemplateConstantsCacheService, ContainerItemTemplateConstants, LocalizedStringModel) => ({
        link ($scope) {
            $scope.LocaleConstantsService = LocaleConstantsService;
            $scope.data = {
                actionTargets          : [],
                buttonTypes            : [],
                externalURL            : '',
                isReady                : false,
                navigationContainer    : null,
                onDismissInputDisabled : false,
                selectedFormIdList     : getSelectedLinkedFormIds(),
            };

            $scope.programId = $state?.params?.id;

            function init () {
                $scope.containerDtoList = $scope.parseContainerUIList();
                TemplateConstantsCacheService.loadActionTargets(false).then(actionTargetMap => {
                    const containerType = cs.findTypeByName($scope.containerType);
                    const actionTargets = Object.values(actionTargetMap).sort();
                    // Remove sub page action from the list for the following two scenarios:
                    // 1. All container types except (Subpage and Data Reference ROR)
                    // 2. All container item level, even on sub page container item level.
                    const isSubpageActionEnabled = containerType && (containerType.text
                        === cs.subPageContainerType.text || containerType.text
                        === cs.dataReferenceRORContainerType.text);
                    if (!isSubpageActionEnabled || $scope.nodeType !== cs.nodeType.CONTAINER) {
                        $scope.data.actionTargets = actionTargets.filter(target => target !== cs.subPageContainerType.actionTarget);
                    } else {
                        $scope.data.actionTargets = actionTargets;
                    }
                    $scope.data.externalURL = '';
                }, error => {
                    console.error('Error occurred in loading action targets', error);
                    $scope.isReady = false;
                });

                TemplateConstantsCacheService.loadButtonTypes(false).then(buttonTypeMap => {
                    const buttonTypes = [];
                    Object.keys(buttonTypeMap).forEach(buttonType => {
                        // we have deprecated WARN type of button in the action button,
                        // but the enum in api will not be changed due to other usage potentially, so filter it out here
                        if (buttonType !== ContainerItemTemplateConstants.buttonType.warn) {
                            buttonTypes.push(buttonType);
                        }
                    });
                    $scope.data.buttonTypes = buttonTypes;
                }, error => {
                    console.error('Error occurred in loading button types', error);
                    $scope.isReady = false;
                });

                $scope.selectPage = page => {
                    $scope.contentValue.pageId = page?.id || null;
                };

                $scope.showExternalURLConfig = () => {
                    return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.EXTERNAL_URL;
                };

                $scope.showContainerNavigationConfig = () => {
                    return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.CONTAINER_NAVIGATION;
                };

                $scope.showPageSelection = () => {
                    return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.PAGE;
                };

                $scope.getContainerLabel = container => {
                    if (container) {
                        return `${ container.id } - ${ container.name } (${
                            container.pageId === cs.defaultTab.id
                                ? 'DRAFT'
                                : container.pageId
                        })`;
                    }
                    return '';
                };

                $scope.data.isReady = true;

                // watch form
                $scope.$watch('contentValue._form', (newValue, oldValue) => {
                    if (newValue == oldValue) {
                        return;
                    }
                    if (newValue != null) {
                        $scope.formOptions.form = newValue;
                    }
                });
                $scope.$watch('data.navigationContainer', newValue => {
                    if (newValue == null) {
                        $scope.contentValue.containerId = null;
                    }
                });

                $scope.$watch('contentValue.buttonType', newValue => {
                    if (newValue === 'DISMISS') {
                        $scope.data.onDismissInputDisabled = true;
                        $scope.contentValue.iconName = null;
                        $scope.contentValue.externalURL = null;
                        if ($scope.contentValue.text) {
                            let emptyLocalizedString = new LocalizedStringModel();
                            Object.keys($scope.contentValue.text).forEach(locale => {
                                emptyLocalizedString[locale] = null;
                            });
                            $scope.contentValue.text = emptyLocalizedString;
                        }
                    } else {
                        $scope.data.onDismissInputDisabled = false;
                    }
                });
            }

            $scope.parseContainerUIList = () => {
                const dto = [];
                $scope.data.navigationContainer = null;
                if ($scope.containerList != null) {
                    Object.keys($scope.containerList).forEach(key => {
                        $scope.containerList[key]
                            .filter(container => container && container.id && container.pageId !== cs.defaultTab.id)
                            .sort((a, b) => a.id - b.id)
                            .forEach(container => {
                                if (container.id === $scope.contentValue.containerId) {
                                    $scope.data.navigationContainer = container;
                                }
                                dto.push({
                                    displayName : `${container.id} | ${container.name}`,
                                    id          : container.id,
                                    name        : container.name,
                                    pageId      : container.pageId,
                                });
                            });
                    });
                }
                return dto;
            };

            $scope.showFormIdConfig = () => {
                return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.FORM;
            };

            $scope.selectContainer = () => {
                $scope.contentValue.containerId = $scope.data.navigationContainer
                    ? $scope.data.navigationContainer.id
                    : null;
            };

            /**
             * Get a list of linked entity form id, there would be only the primary form in the $scope.linkedEntities for now
             * @returns {*[]|T} - a list of integers
             */
            function getSelectedLinkedFormIds () {
                const linkedEntities = $scope.linkedEntities;
                if (!linkedEntities || linkedEntities.length < 1) {
                    return [];
                }
                return linkedEntities.reduce((acc, entity) => {
                    if (entity && entity.entityType === cs.containerItemLinkedEntityType.FORM && entity.entityId) {
                        acc.push(entity.entityId);
                    }
                    return acc;
                }, []);
            }

            function isFormAlreadySelected (form, selectedFormIdList) {
                if (!form || !form.id || !selectedFormIdList) {
                    return false;
                }

                const found = selectedFormIdList.find(formId => formId === form.id);
                return found === form.id;
            }

            $scope.formOptions = $scope.hasActionTarget ? {
                canSelectFunc : function (form) {
                    // form is any of the search result form
                    return !isFormAlreadySelected(form, $scope.data.selectedFormIdList);
                },
                form             : $scope.showFormIdConfig() ? $scope.contentValue.getForm() : null,
                onChangeCallBack : function () {
                    $scope.contentValue.setForm(this.form);
                },
                title    : 'Select Target Form',
                viewOnly : false,
            } : null;

            init();
        },
        restrict : 'E',
        scope    : {
            containerList   : '<',
            containerType   : '<?',
            contentValue    : '=',
            definition      : '=',
            hasActionTarget : '<?',
            linkedEntities  : '<?',
            nodeType        : '<?',
        },
        templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/'
        + 'templateValue.buttonSimple.config.html',
    }),
);
