'use strict';

angular.module('vibrent-external-survey-parser')
    .service('QualtricsSurveyParser', function (VibrentFormStructureConstantService, CommonParserUtilService, vbrLocalIdGeneratorService, AlertService,
                                                QualtricsSurveyQuestionParser, QualtricsParserUtilService, QualtricSkippingLogicService) {
            let constantService = VibrentFormStructureConstantService;
            let localIdGenerator = new vbrLocalIdGeneratorService();
            let pageLocalIdToNavigationNodeLocalIdMap = {};

            let fieldParser = QualtricsSurveyQuestionParser;
            let qualtricsParser = QualtricsParserUtilService;
            let commonParser = CommonParserUtilService;
            let qualtricSkipLogicParser = QualtricSkippingLogicService;
            let isSkipLogicIncluded = false;

            /**
             * Store skip logic flag
             * @param flag true if user select skip logic.
             */
            function setSkipLogic(flag){
                  isSkipLogicIncluded = flag;
                  fieldParser.setSkipLogic(flag);
                }
            function parseToVibrentForm(qualtricsSurveyJson) {
                if (qualtricsSurveyJson == null || qualtricsSurveyJson.length <= 0) {
                    // return null as qualtrics format is null or empty
                    return null;
                }

                try {
                    let formDto = constantService.initBaseFormDto();

                    // set form basic information
                    setFormBasicInformation(formDto, qualtricsSurveyJson);

                    // set form version containing edit, view and prompt mode including pages, nodes and navigation edges
                    setFormVersion(formDto, qualtricsSurveyJson);
                    logMessage(`----Parsing qualtric with skipping logic: ${isSkipLogicIncluded}`);
                    return formDto;
                } catch (e) {
                    console.log(e);
                    return null;
                }
            }


            /** ************************************************************/
            /** ********************* Private Functions ********************/

            /** ************************************************************/

            /**
             * method to return form dto base structure
             */
            function setFormBasicInformation(formDto, qualtricsSurveyJson) {
                let surveyEntry = qualtricsSurveyJson.SurveyEntry;
                if (!surveyEntry) {
                    logMessage('Missing survey entry object in survey', true, true);
                }

                // set form name
                let formName = commonParser.getValueFromObject(surveyEntry, 'SurveyName')
                    + ' | survey id: ' + commonParser.getValueFromObject(surveyEntry, 'SurveyID');

                commonParser.setValueInObject(formDto, 'name', formName);
                commonParser.setValueIfNotNull(surveyEntry, 'SurveyName', formDto, 'displayName');

                logMessage(`Parsing qualtrics survey ${formName} to Vibrent form structure`);
            }

            function setFormVersion(formDto, qualtricsSurveyJson) {
                let formVersion = constantService.initBaseFormVersionDto();

                // set edit mode
                setEditMode(formVersion, qualtricsSurveyJson);

                formDto.formVersions = [formVersion];
            }

            function setEditMode(formVersion, qualtricsSurveyJson) {
                let editMode = formVersion.editMode;

                // add form pages
                addOnePagePerSurveyBlock(editMode.pages, editMode.navigationNodes, editMode.navigationEdges, qualtricsSurveyJson);
            }

            function addOnePagePerSurveyBlock(pages, navigationNodes, navigationEdges, qualtricsSurveyJson) {
                let surveyElements = qualtricsSurveyJson.SurveyElements;
                if (surveyElements == null || surveyElements.length <= 0) {
                    logMessage("Missing survey elements object in survey", true, true);
                    return;
                }

                // get flow object
                let surveyFlow = qualtricsParser.findOneElementByType(surveyElements, 'FL');
                if (surveyFlow == null) {
                    logMessage("Missing survey flow object in survey", true, true);
                    return;
                }

                // get block options
                let blockLogic = qualtricsParser.findOneElementByType(surveyElements, 'BL');
                if (blockLogic == null) {
                    logMessage("Missing block logic object in survey", true, true);
                    return;
                }
                let branchLogicNavigations = [];
                let surveyFlowPayload = surveyFlow.Payload;
                // sort block options based on defined survey flow
                let sortedBlockOptions = sortBlockOptionsBasedOnFlow(blockLogic.Payload, surveyFlowPayload);

                // get survey questions from the survey elements and create map of question id and question object,
                // these questions are not parsed
                let questionIdToQuestionMap = createQuestionIdAndSurveyQuestionMap(surveyElements);

                // generate local id for first page
                let pageLocalId = localIdGenerator.getNextId();
                // This is used to create navigation edges
                let prevPageLocalId = null
                // Page no to sort things using display order
                let pageNo = 1;
                _.forEach(sortedBlockOptions, function (blockOption, blockIndex) {
                    if (skipBlockOption(blockOption)) {
                        return;
                    }

                    // a block option can have page break and we need to create new page in form for those fields
                    // this method return array of array, internal array has list of fields on a page(it can be whole block option or fields till page break)
                    let fieldsPerPageBreak = collectQuestionsPerPageBreak(blockOption);
                    logMessage(`---------Construct block for page ${pageLocalId} ${JSON.stringify(blockOption)}------`);

                    let branchLogic = blockOption.BranchLogic;
                    let firstBranchNavigationEdge;
                    // add new page per page break array item
                    _.forEach(fieldsPerPageBreak, function (questionsInPage, pageIndex) {

                        let isLastPage = (blockIndex === sortedBlockOptions.length - 1) && (pageIndex === fieldsPerPageBreak.length - 1);
                        let pageDto = constantService.initBasePageDto(pageLocalId, isLastPage, pageNo);

                        // append block id to page name - using main block option
                        setPageName(pageDto, blockOption,pageNo);

                        // add section in page
                        addSection(pageDto, pageLocalId, questionsInPage, questionIdToQuestionMap);
                        pages.push(pageDto);

                        // add navigation node and navigation edge
                        addNavigationNodeAndEdgeWithPreviousNode(navigationNodes, navigationEdges, pageDto, prevPageLocalId);
                        if(pageIndex === 0 && isSkipLogicIncluded && !(_.isEmpty(navigationEdges))){
                            firstBranchNavigationEdge = _.last(navigationEdges);
                            //0 is highest order.  need to pump up order so that new logic has more priority.
                            firstBranchNavigationEdge.evaluationOrder = 1;
                        }
                        // set local id to prev page so next time nav edge can be created
                        prevPageLocalId = pageLocalId;
                        // Generate local id for next page
                        pageLocalId = localIdGenerator.getNextId();
                        pageNo++;
                        logMessage('-----------------------------------------');
                    });
                    logMessage(`---------Complete Construct block which ends ${pageLocalId}------`);
                    if(isSkipLogicIncluded){
                        let branchNavigation = {
                            firstNode  : firstBranchNavigationEdge,
                            lastNode   : _.last(navigationEdges),
                            branchLogic : branchLogic,
                            blockId: blockOption.ID
                        };
                        branchLogicNavigations.push(branchNavigation);
                    }
                });

                if(isSkipLogicIncluded){
                    console.log(`---->Navigation Edge before-> ${JSON.stringify(navigationEdges)}`);
                    rebuildNavigationEdge(navigationEdges,branchLogicNavigations,surveyFlowPayload);
                    console.log(`---->Navigation Edge After-> ${JSON.stringify(navigationEdges)}`);
                }

            }
            //Re-built navigation edge for branch logic
            function rebuildNavigationEdge(navigationEdges,branchLogicNavigations, surveyFlowPayload){
                //Check qualtric flow again to construct navigation
                let lastNavigation = null;
                let flows = _.get(surveyFlowPayload,'Flow',[]);
                let isBlock = false;
                let flowIndex =0;
                _.each(flows, function (flow){
                    let type = commonParser.getValueFromObject(flow, 'Type');
                    console.log(`--------> Detect type ${type} and isBlock flag ${isBlock}`);
                    //need to find last link before branch
                    if (type === constantService.surveyFlowTypes.STANDARD
                        || type === constantService.surveyFlowTypes.BLOCK){
                        let blockId = commonParser.getValueFromObject(flow, 'ID');
                        console.log(`--------> blockid ${blockId} for type ${type}`);

                        let blockNav = _.find(branchLogicNavigations, function(navigation) { return navigation.blockId===blockId; });
                        console.log(`--------> Detect ${type} for blockid ${blockId} and blockNav ${JSON.stringify(blockNav)}`);
                        if (blockNav !== undefined){
                            //need to link previous block to the next block which has branch in the middle
                            //link to next block and pump priority level so that else can be the last one
                            if(lastNavigation !== null && lastNavigation !== undefined){
                                let lastNode = (lastNavigation.firstNode !== undefined ? lastNavigation.firstNode: lastNavigation.lastNode);
                                let nodeFromLocalId = lastNode.nodeToLocalId;
                                let nodeToLocalId = blockNav.firstNode.nodeToLocalId;
                                //no need to do anything if we detect duplicate
                                let oldEdge = _.find(navigationEdges, function(edge) {
                                    return (edge.nodeToLocalId===nodeToLocalId && (edge.nodeFromLocalId===nodeFromLocalId));
                                });
                                if(oldEdge === undefined){
                                    //find navigation to increase priority and block doesn't have any logic
                                    let branchEdge = constantService.initNavigationEdge(nodeFromLocalId, nodeToLocalId, `Block ${nodeFromLocalId}_${nodeToLocalId}`);
                                    let sameEdge = _.find(navigationEdges, function(edge) {
                                        return (edge.nodeFromLocalId===nodeFromLocalId);
                                    });
                                    console.log(`--------> Detect Block again and trying to link ${JSON.stringify(branchEdge)} and size ${_.size(sameEdge)}`);
                                    branchEdge.evaluationOrder = _.size(sameEdge)+1;
                                    navigationEdges.push(branchEdge);
                                } else {
                                    console.log(`--------> Detect duplicate in this block ${blockId} and skip construct navigation`);
                                }


                            }
                            //--end of find next block
                            lastNavigation = blockNav;
                        }

                        isBlock = true;
                    } else if (type === constantService.surveyFlowTypes.BRANCH){
                        isBlock = false;
                        let branchLogic = flow.BranchLogic;
                        let blockId = commonParser.getValueFromObject(flow.Flow[0], 'ID');
                        let blockNav = _.find(branchLogicNavigations, function(navigation) { return navigation.blockId===blockId; });
                        console.log(`-->blockNav ${JSON.stringify(blockNav)}`);
                        console.log(`-->lastnavigation ${JSON.stringify(lastNavigation)}`);
                        if (blockNav !== undefined){
                            //unlink if next flow is branch.
                            let nextFlowType = getNextFlowType(flows,flow,flowIndex,constantService.surveyFlowTypes.BRANCH);
                            console.log(`---> Branch Type and next flow is Branch ${JSON.stringify(nextFlowType)}`);
                            let nextFlowNavigationEdge;
                            if (nextFlowType!=null) {
                                let nextFlowNavigationEdge = findNavigationNode(navigationEdges,branchLogicNavigations,nextFlowType);
                                unlinkNavigationNode(navigationEdges,nextFlowNavigationEdge);
                            }
                            //link to next block
                            let nextBlock = getNextFlowType(flows,flow,flowIndex,constantService.surveyFlowTypes.BLOCK);
                            nextBlock = nextBlock != null ? nextBlock : getNextFlowType(flows,flow,flowIndex,constantService.surveyFlowTypes.STANDARD);
                            console.log(`---> Branch Type and next flow is Block ${JSON.stringify(nextBlock)}`);
                            if(nextBlock !=null){
                                //this branch need to link block to complete the circular
                                nextFlowNavigationEdge = findNavigationNode(navigationEdges,branchLogicNavigations,nextBlock);
                                console.log(`--->nextFlowNavigationEdge ${JSON.stringify(nextFlowNavigationEdge)}`);
                                let toNodeId = nextFlowNavigationEdge.nodeToLocalId;
                                let fromNodeId = blockNav.lastNode.nodeToLocalId;
                                let newLink = constantService.initNavigationEdge(fromNodeId, toNodeId, `Branch_Block ${toNodeId}_${fromNodeId}`);
                                linkNavigationNode(navigationEdges,newLink);
                            }
                            console.log(`Branching logic before ${JSON.stringify(branchLogic)}`);
                            let conditionText = qualtricSkipLogicParser.transformLogic(branchLogic);
                            console.log(`Branching logic after ${JSON.stringify(conditionText)}`);
                            // need to bump priority to higher so its priority is lower.
                            //find the old nav
                            let oldEdge = _.find(navigationEdges, function(edge) {
                                return (edge.nodeToLocalId===blockNav.firstNode.nodeToLocalId && (edge.nodeFromLocalId===blockNav.firstNode.nodeFromLocalId));
                            });
                            if(oldEdge!==undefined){
                                oldEdge.evaluationOrder= oldEdge.evaluationOrder+1;
                            }
                            let nodeToLocalId = blockNav.firstNode.nodeToLocalId;
                            let lastNode = (lastNavigation.firstNode !== undefined ? lastNavigation.firstNode: lastNavigation.lastNode);
                            let nodeFromLocalId = lastNode.nodeToLocalId;
                            let branchEdge = constantService.initNavigationEdge(nodeFromLocalId, nodeToLocalId, `Branch ${nodeFromLocalId}_${nodeToLocalId}`);


                            if(conditionText !== null){
                                branchEdge.conditionText = conditionText;
                                linkNavigationNode(navigationEdges,branchEdge);
                            } else {
                                logMessage(`Parser Limitation(Unsupported Logic) for branching from ${nodeFromLocalId} to ${nodeToLocalId}`,true);
                            }

                        }
                    } else {
                        console.log(`UNSUPPORTED TYPE ${type}`);
                    }
                    flowIndex++;
                });
            }
            function findNavigationNode(navigationEdges,branchLogicNavigations,blockElement){
                let blockType = _.get(blockElement,'Type');
                let blockId;
                if(blockType === constantService.surveyFlowTypes.BRANCH){
                    blockId = commonParser.getValueFromObject(blockElement.Flow[0], 'ID');
                } else {
                    blockId = commonParser.getValueFromObject(blockElement, 'ID');
                }
                let blockNav = _.find(branchLogicNavigations, function(navigation) { return navigation.blockId===blockId; });
                if (blockNav !== undefined){
                    let oldEdge = _.find(navigationEdges, edge => {
                        return (edge.nodeToLocalId===blockNav.firstNode.nodeToLocalId && (edge.nodeFromLocalId===blockNav.firstNode.nodeFromLocalId));
                    });
                   return oldEdge;
                }
                return null;
            }
            function unlinkNavigationNode(navigations, unlinkNode){
                console.log(`--> unlink navigation Node ${JSON.stringify(unlinkNode)}`);
                if(unlinkNode !== null && unlinkNode !== undefined){
                    _.remove(navigations,function(edge){
                        let fromID = edge.nodeFromLocalId;
                        let toId = edge.nodeToLocalId;
                        return unlinkNode.nodeFromLocalId === fromID && unlinkNode.nodeToLocalId === toId;
                    });
                }

            }
            function linkNavigationNode(navigations, linkNode){
                console.log(`--> Link navigation Node ${JSON.stringify(linkNode)}`);
                //remove duplicate if any
                unlinkNavigationNode(navigations,linkNode);
                navigations.push(linkNode);
            }
            //retrieve next flow type. For example, if BL,BR,BR,BL
            function getNextFlowType(flows, curentFlow, currentFlowIndex, targetFlowType){
                let type = _.get(curentFlow,'Type');
                let result = null;
                if(type !== undefined){
                    let remainFlows = _.slice(flows,currentFlowIndex+1);
                    _.each(remainFlows, flow =>{
                        let targetType = _.get(flow,'Type');
                        if(targetFlowType === targetType){
                            console.log(`--->found in ${JSON.stringify(flow)}`);
                            result = flow;
                            //false to break the loop and can not return result in here
                            return false;
                        }
                    });
                    return result;
                } else {
                    return result;
                }
            }

            function setPageName(pageDto, blockOption,pageNo) {
                let blockName = commonParser.getValueFromObject(blockOption, 'Description');

                pageDto.name = blockName + ' | Page: ' + pageNo;
                logMessage(pageDto.name);
            }

            function addNavigationNodeAndEdgeWithPreviousNode(navigationNodes, navigationEdges, pageDto, previousPageLocalId) {
                // create navigation node and add new navigation node local id to map, it will be used in setting up edges
                let navigationNode = constantService.initNavigationNode(localIdGenerator, pageDto.localId, pageDto.name);
                navigationNodes.push(navigationNode);
                pageLocalIdToNavigationNodeLocalIdMap[pageDto.localId] = navigationNode.localId;

                if (previousPageLocalId != null) {
                    // add navigation from last indexed node to this node
                    // previous page local Id = this page id minus 1 as page local ids are sequential
                    let nodeFromLocalId = pageLocalIdToNavigationNodeLocalIdMap[previousPageLocalId];
                    let nodeToLocalId = navigationNode.localId;
                    let edgeName = `Page ${previousPageLocalId} => Page ${pageDto.localId}`;

                    // create navigation edge and add to edges list
                    let navigationEdge = constantService.initNavigationEdge(nodeFromLocalId, nodeToLocalId, edgeName);
                    navigationEdges.push(navigationEdge);
                }
            }

            function addSection(pageDto, pageLocalId, questionsInPage, questionIdToQuestionMap) {
                let sectionDto = constantService.initBaseSectionDto(localIdGenerator, pageLocalId);
                logMessage('Adding section, localId ' + sectionDto.localId);

                // add form component in section
                addFormComponent(sectionDto, pageLocalId, questionsInPage, questionIdToQuestionMap);

                pageDto.sections.push(sectionDto);
            }

            function addFormComponent(sectionDto, pageLocalId, questionsInPage, questionIdToQuestionMap) {
                let componentList = sectionDto.formComponents;
                if (questionsInPage != null && questionsInPage.length > 0) {
                    let totalQuestions = questionsInPage.length;
                    _.forEach(questionsInPage, function (question) {
                        let componentDto = constantService.initBaseComponentDto(localIdGenerator, pageLocalId);
                        if (commonParser.getValueFromObject(question, 'Type') === 'Question') {
                            let questionId = commonParser.getValueFromObject(question, 'QuestionID');
                            let surveyQuestion = questionIdToQuestionMap[questionId];

                            if (!skipQuestion(surveyQuestion) && isMatrixQuestion(surveyQuestion)) {
                                    componentDto.componentType='MATRIX_QUESTIONNAIRE';
                                    componentDto = fieldParser.parseMatrixComponent(componentDto,surveyQuestion, questionIdToQuestionMap);
                            }

                            const formFieldsForQuestion = parseSurveyQuestion(surveyQuestion, questionIdToQuestionMap);

                            if (formFieldsForQuestion != null && formFieldsForQuestion.length > 0) {
                                if (isSkipLogicIncluded){
                                    //resolve blank page if this is only one question and has skiplogic
                                    let displayLogic = qualtricSkipLogicParser.getQualtricDisplayLogic(surveyQuestion);
                                    let vibrentLogic = qualtricSkipLogicParser.transformDisplayLogic(surveyQuestion);
                                    let name = commonParser.getValueFromObject(surveyQuestion.Payload, 'DataExportTag');
                                    if (vibrentLogic === null && displayLogic !== undefined){
                                        logMessage(`Parser Limitation(Unsupported Logic) for question Name ${name} on page ${pageLocalId}`,true);
                                    }
                                    setAdvanceVisibilityConfig(totalQuestions === 1 ? sectionDto: componentDto, vibrentLogic);
                                }

                                _.forEach(formFieldsForQuestion, function (f, i) {
                                    // set current index as display order
                                    f.displayOrder = componentDto.formComponentFields.length + 1;
                                    componentDto.formComponentFields.push(f);
                                });
                                if (componentDto.formComponentFields.length > 0) {
                                    logMessage(`Adding component, localId  ${componentDto.localId}`);
                                    componentList.push(componentDto);
                                }
                            }
                        }
                    });
                } else {
                    logMessage('No questions on page ' + pageLocalId);
                }
            }

        /**
         * set component display configuration visibility logic
         * @param component vibrent component
         * @param vibrentLogic vibrent logic
         */
            function setAdvanceVisibilityConfig(component, vibrentLogic){
                if(vibrentLogic !== null ){
                    component.displayConfig.advancedVisibilityConfig = {
                        conditionText : `${vibrentLogic}`
                    };
                }
            }

            /**
             * Method to parse survey question and return one or more form fields
             * @param surveyQuestion - survey question to be parsed
             */
            function parseSurveyQuestion(surveyQuestion, questionIdToQuestionMap) {
                if (skipQuestion(surveyQuestion)) {
                    return null;
                }

                let questionType = qualtricsParser.getQuestionType(surveyQuestion);
                let questionId = qualtricsParser.getQuestionId(surveyQuestion);
                let name = commonParser.getValueFromObject(surveyQuestion.Payload, 'DataExportTag');
                logMessage(`Parsing field, type: ${questionType} for question id: ${questionId} and name ${name}`);
                let hasBothSpanish = qualtricsParser.hasBothSpanishFromQualtricsLanguage(surveyQuestion.Payload);

                // one question can be parsed to one or more form fields
                let fields = [];
                switch (questionType) {
                    case constantService.qualtricsSurveyQuestionTypes.TEXT_AND_GRAPHICS:
                        fields = fieldParser.parseTextGraphicQuestion(surveyQuestion, localIdGenerator);
                        break;
                    case constantService.qualtricsSurveyQuestionTypes.MATRIX:
                        fields = fieldParser.parseMatrixQuestion(surveyQuestion, localIdGenerator, questionIdToQuestionMap);
                        break;
                    case constantService.qualtricsSurveyQuestionTypes.MULTIPLE_CHOICE:
                        fields = fieldParser.parseMultipleChoiceQuestion(surveyQuestion, localIdGenerator);
                        break;
                    case constantService.qualtricsSurveyQuestionTypes.TEXT_ENTRY:
                        fields = fieldParser.parseTextEntryQuestion(surveyQuestion, localIdGenerator);
                        break;
                    case constantService.qualtricsSurveyQuestionTypes.SLIDER:
                        fields = fieldParser.parseSliderQuestion(surveyQuestion, localIdGenerator);
                        break;
                    default:
                        logMessage(`Question Type ${questionType} is not supported, Question Id: ${qualtricsParser.getQuestionId(surveyQuestion)}`, true);
                        break;
                }

                if (fields && fields.length > 0) {
                    if (hasBothSpanish){
                       //AC-99258: give warning to user if detects both spanish language
                       AlertService.warning(`Detect multiple spanish (EU and Latin America) languages on question ${name}`);
                    }
                    // set field name for each field
                    _.forEach(fields, function (f) {
                        //Since qualtric hides questionID from end-user, we use name as a way to correlate to our UI.
                        //However, it uses questionID for skipping logic.  Therefore, we will use questionID temporary and swap latter
                        if (isSkipLogicIncluded){
                            f.name = questionId;
                            //will remove once we save the form
                            f.contentDescription = name;
                        } else {
                            f.name = name;
                        }
                    });
                }

                return fields;
            }

            /**
             * method to skip block option if block type is Trash or if block is empty
             * @param blockOption
             * @returns {boolean}
             */
            function skipBlockOption(blockOption) {
                return blockOption.Type === 'Trash';
            }

            /** Testing function, should be deleted at the end *****/
            function skipQuestion(surveyQuestion) {
                let questionType = qualtricsParser.getQuestionType(surveyQuestion);
                return questionType !== constantService.qualtricsSurveyQuestionTypes.TEXT_AND_GRAPHICS
                    && questionType !== constantService.qualtricsSurveyQuestionTypes.MULTIPLE_CHOICE
                    && questionType !== constantService.qualtricsSurveyQuestionTypes.TEXT_ENTRY
                    && questionType !== constantService.qualtricsSurveyQuestionTypes.MATRIX
                    && questionType !== constantService.qualtricsSurveyQuestionTypes.SLIDER
                    ;
            }

            function isMatrixQuestion(surveyQuestion) {
                let questionType = qualtricsParser.getQuestionType(surveyQuestion);

                if (questionType === constantService.qualtricsSurveyQuestionTypes.MATRIX) {
                    return true;
                }
                return false;
            }

            /**
             * Method to get survey questions from the qualtrics object
             * @param surveyElements
             */
            function createQuestionIdAndSurveyQuestionMap(surveyElements) {
                // get all the survey questions(where 'Element == SQ') from the qualtrics json,
                let surveyQuestions = qualtricsParser.findElementsByType(surveyElements, 'SQ');

                if (!surveyQuestions || surveyQuestions.length <= 0) {
                    logMessage("Missing survey questions in survey", true, true);
                    return;
                }
                logMessage(`Found ${surveyQuestions.length} survey questions`);
                logMessage('Parsing survey questions');
                qualtricsParser.printSurveyMetadata(surveyQuestions);

                let map = {};
                _.forEach(surveyQuestions, function (surveyQuestion) {
                    let questionId = qualtricsParser.getQuestionId(surveyQuestion);
                    map[questionId] = surveyQuestion;
                });

                return map;
            }

            /**
             * Method to sort block options based on survey flow
             * @param blockOptions
             * @param surveyFlow
             * @returns {[]}
             */
            function sortBlockOptionsBasedOnFlow(blockOptions, surveyFlow) {
                let sortedBlockOptions = [];
                if (surveyFlow == null) {
                    return sortedBlockOptions;
                }
                let flows = surveyFlow.Flow;
                if (flows == null || flows.length <= 0) {
                    return sortedBlockOptions;
                }

                // create map of block id and block option map
                let blockIdAndBlockOptionMap = {};
                _.forEach(blockOptions, function (blockOption) {
                    let blockId = commonParser.getValueFromObject(blockOption, 'ID');
                    blockIdAndBlockOptionMap[blockId] = blockOption;
                });

                // create map of flow id and flow object map
                let flowIdAndFlowObjectMap = {};
                _.forEach(blockOptions, function (flowObj) {
                    let flowId = commonParser.getValueFromObject(flowObj, 'FlowID');
                    flowIdAndFlowObjectMap[flowId] = flowObj;
                });

                _.forEach(flows, function (flow) {
                    let type = commonParser.getValueFromObject(flow, 'Type');
                    if (type === constantService.surveyFlowTypes.STANDARD
                        || type === constantService.surveyFlowTypes.BLOCK) {

                        let blockId = commonParser.getValueFromObject(flow, 'ID');
                        let blockOption = blockIdAndBlockOptionMap[blockId];
                        if (blockOption) {
                            sortedBlockOptions.push(blockOption);
                        }
                    } else if (type === constantService.surveyFlowTypes.BRANCH && isSkipLogicIncluded) {
                        // add to sortedBlock array so that we can build question and reconstruct navigation map later
                        let blockId = commonParser.getValueFromObject(flow.Flow[0], 'ID');
                        console.log(`Construct branching logic-->${blockId}`);
                        let blockOption = blockIdAndBlockOptionMap[blockId];
                        if (blockOption) {
                            // Copy Branching Logic to blockOption so that we can use to reconstruct navigation map.  We will remove it later when we save the form
                            if (_.has(flow,'BranchLogic')) {
                                blockOption.BranchLogic = flow.BranchLogic;
                                console.log(`Construct branching logic 2-->${JSON.stringify(blockOption)}`);
                                sortedBlockOptions.push(blockOption);
                            }
                        }
                    } else if (type === constantService.surveyFlowTypes.BRANCH) {
                        let blockId = commonParser.getValueFromObject(flow.Flow[0], 'ID');
                        let blockOption = blockIdAndBlockOptionMap[blockId];
                        if (blockOption) {
                            sortedBlockOptions.push(blockOption);
                        }
                    } else if (type === constantService.surveyFlowTypes.EMBEDDED_DATA) {
                        // todo: implement if required
                    }
                });

                return sortedBlockOptions;
            }

            /**
             * Method to check page breaks in given block option and return array of field in each page
             * @param blockOption
             */
            function collectQuestionsPerPageBreak(blockOption) {
                let fieldsPerPage = [];
                let blockElements = commonParser.getValueFromObject(blockOption, 'BlockElements');
                if (blockElements == null || blockElements.length <= 0) {
                    // return empty array
                    fieldsPerPage.push([]);
                    return fieldsPerPage;
                }
                let fields = [];
                _.forEach(blockElements, function (element) {
                    let type = commonParser.getValueFromObject(element, 'Type');
                    if (type === 'Question') {
                        // this is question, push to fields array
                        fields.push(element);
                    } else if (type === 'Page Break') {
                        // this is page break, push fields to main array and init fields array to new array
                        fieldsPerPage.push(fields);
                        fields = [];
                    }
                });

                // add last page to main array
                fieldsPerPage.push(fields);

                return fieldsPerPage;
            }

            function logMessage(message, showAlert, throwError) {
                if (showAlert) {
                    AlertService.warning(message);
                }
                console.log('*** ' + message);

                if (throwError) {
                    throw message;
                }
            }

            function getParserInformationAsHtml() {
                // TODO: This should be converted to an object and then render as an HTML, no time to do it right now
                const s = '<p>This utility can be used to import <strong>Qualtrics Survey</strong> as <strong>Vibrent form.</strong></p>\n'
                    + '\n'
                    + '<p>You will need to upload the \'<strong>.qsf</strong>\' file which contains Qualtrics survey definition and can be\n'
                    + '   exported from Qualtrics <a href="https://login.qualtrics.com/login?lang=en"\n'
                    + '                              target="_blank">website</a>. Export instruction are\n'
                    + '    <a href="https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/import-and-export-surveys/"\n'
                    + '       target="_blank">here</a></p>\n'
                    + '\n'
                    + '<p>This uses the <strong>Vibrent - Qualtrics parser(custom library)</strong> to convert Qualtrics survey to vibrent\n'
                    + '   form.&nbsp;</p>\n'
                    + '\n'
                    + '<p></p>\n'
                    + '\n'
                    + '<p><strong>Qualtrics Question Type to Vibrent Field Type Mapping</strong>:</p>\n'
                    + '\n'
                    + '<div>\n'
                    + '    <table class="table table-bordered">\n'
                    + '        <thead>\n'
                    + '        <tr>\n'
                    + '            <th scope="col">Qualtrics Question Type</th>\n'
                    + '            <th scope="col">Mapped Vibrent Field Type</th>\n'
                    + '            <th scope="col">Details</th>\n'
                    + '        </tr>\n'
                    + '        </thead>\n'
                    + '        <tbody>\n'
                    + '        <tr>\n'
                    + '            <td>Text and Graphics (DB)</td>\n'
                    + '            <td>Rich Text Label</td>\n'
                    + '            <td>As Qualtrics supports HTML label, which can only be displayed through Rich Text Label on our side.</td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Text Entry (TE)</td>\n'
                    + '            <td>Text Input</td>\n'
                    + '            <td>One Qualtrics TE question can be configured with one or more(as form) text input boxes which gets parsed\n'
                    + '                to individual Text Input and text label becomes in hint text on our side.\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Multiple Choice (MC)</td>\n'
                    + '            <td>\n'
                    + '                <p>Multi Selector (with Single or Multiple selection view)</p>\n'
                    + '\n'
                    + '                <p>Or</p>\n'
                    + '\n'
                    + '                <p>Dropdown</p>\n'
                    + '            </td>\n'
                    + '            <td>\n'
                    + '                <p>Qualtrics Multiple Choice can be configured as Single Selector, Muilple Selector or Dropdown.</p>\n'
                    + '\n'
                    + '                <p>It gets mapped to respective field type on our side.</p>\n'
                    + '\n'
                    + '                <p><strong>Note:</strong>&nbsp;Single Selector question type is parsed to Multi Selector field with\n'
                    + '                                         single select = true.</p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Slider</td>\n'
                    + '            <td>Slider(with only Number value support)</td>\n'
                    + '            <td>Qualtrics only supports incremental slider which gets parsed to number valid slider with incremental\n'
                    + '                support on our side.\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Matrix</td>\n'
                    + '            <td>\n'
                    + '                <p>Custom Matrix View(for Matrix question&nbsp;up to 5 selection options) using multiple components and\n'
                    + '                   Radio Selectors</p>\n'
                    + '\n'
                    + '                <p>Or</p>\n'
                    + '\n'
                    + '                <p>Horizontal Radio Selectors(for question with more than 5 selection option)</p>\n'
                    + '            </td>\n'
                    + '            <td>\n'
                    + '                <p>Qualtrics supports a special Matrix field which is not supported in our system.</p>\n'
                    + '\n'
                    + '                <p>Currently parser converts this field to 2 different views based on the number of selection options\n'
                    + '                   are configured in Matrix questions.</p>\n'
                    + '\n'
                    + '                <p>&nbsp; &nbsp;1. <strong>Up to 5 selection options</strong> - matrix field view is achieved using 3\n'
                    + '                   components,</p>\n'
                    + '\n'
                    + '                <p>&nbsp; &nbsp;&nbsp; &nbsp;a. Component 1 - to hold the main question text&nbsp;</p>\n'
                    + '\n'
                    + '                <p>&nbsp; &nbsp;&nbsp; &nbsp;b. Component 2 - to hold the answer texts as title of radio optio column,\n'
                    + '                   this components is configured with&nbsp;horizontal orientation</p>\n'
                    + '\n'
                    + '                <p>&nbsp; &nbsp;&nbsp; &nbsp;c. Component 3 - to hold horizontally aligned radio selector fields with\n'
                    + '                   sub-question&nbsp;text on left side of radio options</p>\n'
                    + '\n'
                    + '                <p>&nbsp; &nbsp;2. <strong>More than 5 selection options</strong> - this matrix question is converted to\n'
                    + '                   individual horizontal radio selectors with sub-question text on top of radio options</p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        </tbody>\n'
                    + '    </table>\n'
                    + '\n'
                    + '    <p></p>\n'
                    + '\n'
                    + '    <p><strong>Other Supported Items</strong>:</p>\n'
                    + '\n'
                    + '    <table class="table table-bordered">\n'
                    + '        <thead>\n'
                    + '        <tr>\n'
                    + '            <th scope="col">Item</th>\n'
                    + '            <th scope="col">Details</th>\n'
                    + '        </tr>\n'
                    + '        </thead>\n'
                    + '        <tbody>\n'
                    + '        <tr>\n'
                    + '            <td>Validation</td>\n'
                    + '            <td>Only REQUIRED validation is parsed from Qualtrics surveys. The respective field will be marked as\n'
                    + '                mandatory with error message same as qualtrics error message.\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Common field details</td>\n'
                    + '            <td>\n'
                    + '                <p>1. Every field name is "question tag from Qualtrics question". Name is also used as Codebook value so appropriated codebook value should be\n'
                    + '                   verified/updated by Implementation team</p>\n'
                    + '\n'
                    + '                <p>2. All selector fields(Multi selector, Radio selector and Dropdown) has value type as String.\n'
                    + '                   Currently field value stored in form entry and display string both are having same values. The field\n'
                    + '                   values needs to be updated as our mobile client doesn\'t support field values containing space in it.&nbsp;</p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        </tbody>\n'
                    + '    </table>\n'
                    + '\n'
                    + '    <p></p>\n'
                    + '\n'
                    + '    <p><strong>Parser Limitations (Unsupported Items)</strong>:</p>\n'
                    + '\n'
                    + '    <table class="table table-bordered">\n'
                    + '        <thead>\n'
                    + '        <tr>\n'
                    + '            <th scope="col">Item</th>\n'
                    + '            <th scope="col">Details</th>\n'
                    + '        </tr>\n'
                    + '        </thead>\n'
                    + '        <tbody>\n'
                    + '        <tr>\n'
                    + '            <td>Qualtrics questions types not supported in Vibrent forms</td>\n'
                    + '            <td>\n'
                    + '                <p>Qualtrics supports few additional question types which are NOT supported in Vibrent forms at all. Parser will simply skip these question types from "Standard Questions" section, &nbsp;</p>\n'
                    + '                <p>&nbsp;&nbsp;&nbsp;1. Rank Order <br> &nbsp;&nbsp;&nbsp;2. Side by Side</p>\n'
                    + '                <p>All the question types from "Specialty Questions" and "Advanced" are not supported either </p>\n'
                    + '                <p></p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Question Skip logic</td>\n'
                    + '            <td>Question skip logic is very difficult to parse as conditions are store as HTML. Currently question skip\n'
                    + '                logic is not parsed and it manually needs to be configured by comparing imported form with Qualtrics\n'
                    + '                survey\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Page Skip logic</td>\n'
                    + '            <td>\n'
                    + '                <p>Page skip logic is also not parsed due same reason as of question skip logic</p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Input textbox in Selector field</td>\n'
                    + '            <td>\n'
                    + '                <p>Qualtrics Multi Choice type supports the feature to configure input text in last option(example,\n'
                    + '                   \'None of above, please specify\') to accept user\'s input. Currently this is not parsed.&nbsp;</p>\n'
                    + '\n'
                    + '                <p>Our system can support this by using reference field but it needs to be manually\n'
                    + '                   configured.&nbsp;</p>\n'
                    + '\n'
                    + '                <p></p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>Text alignment in Custom Matrix field</td>\n'
                    + '            <td>\n'
                    + '                <p>Custom Matrix view ia achieved by using 3 components with fixed width for every field. Sometime the answer text(in column title) '
                    + '                   or sub-question text can go beyond the given fixed width and gets miss align.&nbsp;</p>\n'
                    + '\n'
                    + '                <p>Whoever is importing a form is supposed to verify alignment issues and fix it manually.</p>\n'
                    + '\n'
                    + '                <p></p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        <tr>\n'
                    + '            <td>File Upload</td>\n'
                    + '            <td>\n'
                    + '                <p>Any question type expecting file upload is also not supported by current parser</p>\n'
                    + '            </td>\n'
                    + '        </tr>\n'
                    + '        </tbody>\n'
                    + '    </table>\n'
                    + '</div>\n';

                return s;
            }

            return {
                setSkipLogic               : setSkipLogic,
                parseToVibrentForm         : parseToVibrentForm,
                getParserInformationAsHtml : getParserInformationAsHtml
            };
        }
    );
