/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config for margin or padding
     */
        .directive('formDisplayConfigMarginOrPadding', function (FormUtilService, FormConstants) {

            var configTypes = FormConstants.marginPaddingType;

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/boxModel.marginOrPadding.html',
                scope: {
                    modelOptions: '=modelOptions',
                    element : '='
                },
                link: function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;
                    //$scope.processModel = processModelInput($scope.modelOptions.model);
                    $scope.configTypes = configTypes;

                    $scope.configTypeChanged = function () {
                        configTypeChanged($scope.modelOptions.model);
                    };

                    var unitsNormal = ['dp', '%'];
                    var unitsParentIsWrapContent = ['dp', '%'];

                    $scope.simpleConfigOptions = createConfigOptions(unitsNormal, unitsParentIsWrapContent,
                        $scope.modelOptions.careAboutParentWrapContent, false);
                    $scope.advancedConfigOptions = {
                        left: createConfigOptions(unitsNormal, unitsParentIsWrapContent,
                            $scope.modelOptions.careAboutParentWrapContent, true),
                        right: createConfigOptions(unitsNormal, unitsParentIsWrapContent,
                            $scope.modelOptions.careAboutParentWrapContent, true),
                        top: createConfigOptions(unitsNormal, unitsParentIsWrapContent,
                            $scope.modelOptions.careAboutParentWrapContent, true),
                        bottom: createConfigOptions(unitsNormal, unitsParentIsWrapContent,
                            $scope.modelOptions.careAboutParentWrapContent, true)
                    }
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function createConfigOptions(unitsNormal, unitsParentIsWrapContent, careAboutParentWrapContent,
                                         noHigherThan100PercentCombined) {
                return {
                    unitsNormal : unitsNormal,
                    unitsParentIsWrapContent : unitsParentIsWrapContent,
                    careAboutParentWrapContent : careAboutParentWrapContent,
                    required : false,
                    mustBePositive : false,
                    mustNotBeNegative : true,
                    noHigherThan100Percent : true,
                    noHigherThan100PercentCombined : noHigherThan100PercentCombined
                };
            }

            function configTypeChanged(model) {
                if (model.configType == configTypes.SIMPLE) {
                    if (model.simpleConfig == null) {
                        model.simpleConfig = FormUtilService.vus.createModelWithDefaultValue(new FormUtilService.vus.ValueWithUnitModel(0, "dp"));
                    }
                }
                else if (model.configType == configTypes.ADVANCED) {
                    if (model.advancedConfig == null) {
                        model.advancedConfig = {
                            left: FormUtilService.vus.createModelWithDefaultValue(model.simpleConfig==null ? new FormUtilService.vus.ValueWithUnitModel(0, "dp") : model.simpleConfig),
                            right: FormUtilService.vus.createModelWithDefaultValue(model.simpleConfig==null ? new FormUtilService.vus.ValueWithUnitModel(0, "dp") : model.simpleConfig),
                            top: FormUtilService.vus.createModelWithDefaultValue(model.simpleConfig==null ? new FormUtilService.vus.ValueWithUnitModel(0, "dp") : model.simpleConfig),
                            bottom: FormUtilService.vus.createModelWithDefaultValue(model.simpleConfig==null ? new FormUtilService.vus.ValueWithUnitModel(0, "dp") : model.simpleConfig)
                        };
                    }
                }
            }

        });

})();

