'use strict';

angular.module('acadiamasterApp').controller('PropertyPossibleValueGroupDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'PropertyPossibleValueGroup', 'User',
        function($scope, $stateParams, $uibModalInstance, entity, PropertyPossibleValueGroup, User) {

        $scope.propertyPossibleValueGroup = entity;
        $scope.users = User.query();
        $scope.load = function(id) {
            PropertyPossibleValueGroup.get({id : id}, function(result) {
                $scope.propertyPossibleValueGroup = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:propertyPossibleValueGroupUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.propertyPossibleValueGroup.id != null) {
                PropertyPossibleValueGroup.update($scope.propertyPossibleValueGroup, onSaveFinished);
            } else {
                PropertyPossibleValueGroup.save($scope.propertyPossibleValueGroup, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
