/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola on 02/19/2020
 *
 * controller for managing deep links on the program or system level
 */

angular.module('acadiamasterApp')
    .controller('DeepLinkEditController', function ($state, $stateParams, $translate, AlertService,
        DeepLinkConstants, DeepLinkModel, DeepLinkService, ExpressionConstantsService, FormSearch) {
        const vm = this;

        vm.cs = DeepLinkConstants;
        vm.expConstants = ExpressionConstantsService;
        vm.fields = [
            vm.cs.FIELDS.ID,
            vm.cs.FIELDS.PROGRAM_ID,
            vm.cs.FIELDS.CREATED_ON,
            vm.cs.FIELDS.CREATED_BY_ID,
            vm.cs.FIELDS.UPDATED_ON,
            vm.cs.FIELDS.UPDATED_BY_ID,
            vm.cs.FIELDS.CODE,
            vm.cs.FIELDS.TARGET_TYPE,
            vm.cs.FIELDS.FORM,
            vm.cs.FIELDS.CONTAINER,
            vm.cs.FIELDS.PARAMETER,
            vm.cs.FIELDS.PAGE,
            vm.cs.FIELDS.EXPRESSION,
            vm.cs.FIELDS.ENABLED,
        ];

        vm.detailFields = [
            vm.cs.FIELDS.FORM_ID,
            vm.cs.FIELDS.CONTAINER_ID,
            vm.cs.FIELDS.PARAMETER,
        ];

        vm.data = {
            containers   : [],
            forms        : [],
            isLoading    : true,
            level        : null,
            link         : null,
            linkId       : null,
            loadingError : false,
            programId    : null,
        };

        /*
         * Creates a temporary element, appends it to the DOM, and uses it to copy text to the
         * user's clipboard.
         */
        vm.copyLinkCode = code => {
            let element = document.createElement('textarea');
            document.body.appendChild(element);
            element.value = code;
            element.select();
            element.setSelectionRange(0, 99999); /* For mobile devices*/
            document.execCommand('copy');
            document.body.removeChild(element);
            AlertService.success(
                $translate.instant('deepLinks.alerts.codeCopied', {
                    code,
                }),
            );
        };

        vm.selectForm = () => {
            vm.data.link.formId = vm.data.link.form
                ? vm.data.link.form.value
                : null;
        };

        vm.selectContainer = () => {
            vm.data.link.containerId = vm.data.link.container
                ? vm.data.link.container.value
                : null;
        };

        vm.selectTargetType = targetType => {
            vm.fields.find(field => field.id === 'form').isDisabled = targetType !== 'FORM';
            vm.fields.find(field => field.id === 'container').isDisabled = targetType !== 'CONTENT_CONTAINER';
            vm.fields.find(field => field.id === 'parameter').isDisabled = targetType !== 'CONTENT_CONTAINER';
        };

        vm.selectPage = page => {
            vm.data.link.pageId = page?.id || null;
        };

        vm.goBack = () => {
            $state.go('program.manage.deepLinks');
        };

        vm.saveLink = () => {
            vm.data.link.update()
                .then(vm.goBack);
        };

        /**
         * Loads string data from the server. Can either load at a system or program level
         */
        function loadLink () {
            vm.data.isLoading = true;
            DeepLinkService.loadLinks(vm.data.programId)
                .then(response => {
                    const link = response.data.find(dto => dto.id.toString() === vm.data.linkId);
                    if (link) {
                        vm.data.link = new DeepLinkModel().fromDto(link);
                    }
                    vm.selectTargetType(link.targetType);
                    updateLinkFormData();
                    updateLinkContentContainerData();
                }, error => {
                    vm.data.loadingError = true;
                    console.error('Error getting deep links from API', error);
                }).finally(() => {
                    vm.data.isLoading = false;
                });
        }

        // load form data from the API and get it ready for display in a typeahead field
        function loadForms () {
            FormSearch.query({
                size: 500,
                programId: vm.data.programId,
            }).then(response => {
                    vm.data.forms = response.data.map(form => ({
                        name  : `${form.id } - ${ form.name}`,
                        value : form.id,
                    }));
                    updateLinkFormData();
                }, error => {
                    console.error(error);
                });
        }

        function updateLinkFormData () {
            // only do this once both links and forms have loaded.
            if (!vm.data.link || vm.data.forms.length === 0) {
                return;
            }
            // update the link with form data
            vm.data.link.form = vm.data.forms.find(form => form.value === vm.data.link.formId);
        }

        // load container data from the API and get it ready for display in a typeahead field
        function loadContentContainers () {
            DeepLinkService.getContentContainers(vm.data.programId)
                .then(response => {
                    vm.data.containers = response.data.map(container => ({
                        name  : `${container.id } - ${ container.name}`,
                        value : container.id,
                    }));
                    updateLinkContentContainerData();
                }, error => {
                    console.error(error);
                });
        }

        function updateLinkContentContainerData () {
            // only do this once both links and containers have loaded.
            if (!vm.data.link || vm.data.containers.length === 0) {
                return;
            }
            // update the link with container data
            vm.data.link.container = vm.data.containers.find(container => container.value === vm.data.link.containerId);
        }

        vm.$onInit = () => {
            vm.data.programId = $stateParams.id;
            vm.data.linkId = $stateParams.linkId;
            loadLink();
            loadForms();
            loadContentContainers();
        };
    });
