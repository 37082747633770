/**
 * directive for configure the template content values
 * this is a top level directive, it will branch out into conditional or simple config depending on the
 * actual configuration
 */
angular.module('acadiamasterApp').directive('templateValuesConfigOverall', (ContentValueUtil) => ({
    link($scope) {
        /**
         * holder of some directive level data
         * @type {{forcedAdvanceMode: boolean}}
         */
        $scope.data = {
            forcedAdvanceMode : false,
        };

        /**
         * getting the configuration value for the key associated with definition
         * @returns Array{ConditionalContentValue} - a list of conditional content value, could be null
         */
        $scope.getConfig = function() {
            return $scope.templateValues[$scope.definition.key];
        };

        /**
         * check if the definition key actually has configuration or not
         * @returns {boolean}
         */
        $scope.hasConfig = function() {
            const config = $scope.getConfig();
            return config != null;
        };

        /**
         * remove the configuration associated with the key in definition
         */
        $scope.removeConfig = function() {
            delete $scope.templateValues[$scope.definition.key];
        };

        /**
         * initialize configuration for the content key.  If default value is provided in the content definition,
         * then a copy of it will be used as the initial value
         */
        $scope.initConfig = function() {
            $scope.templateValues[$scope.definition.key] = ContentValueUtil.createInitialValueList($scope.definition);
        };

        /**
         * check if the advanced mode is on or not
         * @returns {boolean} - true if there is configuration for the key and either
         *    1. we already defined condition for it, or
         *    2. user has selected force advance mode config, or
         *    3. there are more than one conditional content value configured
         *    - false otherwise
         */
        $scope.isAdvanceModeOn = () => $scope.hasConfig() &&
            ($scope.data.forcedAdvanceMode || $scope.hasConditionDefined() || $scope.getConfig().length>1);

        /**
         * check if the simple/advance mode toggle should be shown
         * @returns {boolean} - true if the configured value is a simple value (ie: not an array, do not have condition)
         */
        $scope.shouldShowModeToggle = () => $scope.hasConfig() &&
            !$scope.hasConditionDefined() && $scope.getConfig().length==1;

        /**
         * getting the first content value associated with the key
         * @returns {ContentValue} - first content value associated with the key, ie: [{contentValue: V1}, {contentValue: V2}] -> V1
         */
        $scope.getFirstValueConfig = () => {
            const configArray = $scope.getConfig();
            if (configArray!=null && configArray.length>0) {
                return configArray[0].contentValue;
            }
            return null;
        };

        /**
         * check if there are condition defined for the first conditional content value
         * @returns {boolean} - true if config is there and first conditional content value as non null condition, false otherwise
         */
        $scope.hasConditionDefined = function() {
            const configArray = $scope.getConfig();
            if (configArray==null || configArray.length==0) {
                return false;
            }
            const firstConfig = configArray[0];
            return !!(firstConfig && firstConfig.expression && firstConfig.expressionTree);
        };

        /**
         * adding a new conditional value after the index
         * note: if the index is invalid (ie: outside of the config array list), then this function won't do anything except for
         * printing some error message to the log
         * @param index - position of the condition value to be inserted after. ie: if index = 2, then the new value should be
         *                inserted between the 2nd and 3rd element in the array.
         */
        $scope.addConditionalValue = function(index) {
            const configList = $scope.getConfig();
            if (configList==null || configList.length <= index) {
                console.error(`unable to add conditional value at index : ${index}, current config : `, configList);
                return;
            }

            const conditionalValue = ContentValueUtil.createConditionalContentValueByDefinition($scope.definition);
            configList.splice(index+1, 0, conditionalValue);
        };

        /**
         * remove the conditional value at specific index
         * @param index - index position of the conditional value to be deleted
         */
        $scope.removeConditionalValue = function(index) {
            const configList = $scope.getConfig();
            if (configList==null || configList.length <= index) {
                console.error(`unable to remove conditional value at index : ${index}, current config : `, configList);
                return;
            }

            configList.splice(index, 1);

            if (configList.length==0) {
                // after deleting the current configuration, there are no more configuration, just remove
                // the whole array
                $scope.removeConfig();
            }
        };

        /**
         * check if the element at index can be moved up
         * @returns {boolean} - true if it's not the first element, false otherwise
         */
        $scope.canMoveUp = index => index>0;

        /**
         * check if the element at index can be moved down
         * @returns {boolean} - true if it's not the last element, false otherwise
         */
        $scope.canMoveDown = index => {
            const configList = $scope.getConfig();
            return configList!=null && configList.length > index + 1;
        };

        /**
         * move the element at index up one spot, ie: switch the element at index with element at index - 1
         * @param index - index of the element to be moved up
         */
        $scope.moveConditionalValueUp = index => {
            const configList = $scope.getConfig();
            if (configList==null || configList.length <= index) {
                return;
            }
            const tobeMoved = configList[index];
            configList[index] = configList[index-1];
            configList[index-1] = tobeMoved;
        };

        /**
         * move the element at index down one spot, ie: switch the element at index with element at index + 1
         * @param index - index of the element to be moved down
         */
        $scope.moveConditionalValueDown = index => {
            const configList = $scope.getConfig();
            if (configList==null || configList.length <= index + 1) {
                return;
            }
            const tobeMoved = configList[index];
            configList[index] = configList[index + 1];
            configList[index + 1] = tobeMoved;
        };

        $scope.shortenString = (input, maxLength) => {
            if (input === null || maxLength === null || maxLength <= 10 || input.length <= maxLength) {
                return input;
            }

            return `${input.substring(0, maxLength)}...`;
        };


    },
    restrict: 'E',
    scope: {
        templateValues: '=',
        containerType: '<?',
        definition: '=',
        linkedEntities: '<?',
        containerList: '<',
        nodeType: '<?',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/' +
        'templateValuesConfigOverall.html',
}));

