/**
 * Created by moustafabaiou on 4/26/17.
 */
(function () {
    angular.module('acadiamasterApp')
        .factory('DonationSiteService', ($q, $http, ParseLinks) => {
            let baseApi = '/api/donationSite/';

            return {
                exportSites : function (pageSize) {
                    let deferred = $q.defer();

                    let params = {
                        page : 0,
                        pageSize : pageSize,
                    };

                    let success = function (response) {
                        let data = response.data;
                        let sites = [];
                        data.forEach(site => {
                            site.address = JSON.stringify(site.address);
                            site.contact = JSON.stringify(site.contact);
                            site.hours = JSON.stringify(site.hours);
                            site.coords = JSON.stringify(site.coords);
                            sites.push(site);
                        });
                        deferred.resolve(sites);
                    };

                    let error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http.get(baseApi, { params : params }).then(success).catch(error);

                    return deferred.promise;
                },
                getSites : function (page, pageSize, programId) {
                    let deferred = $q.defer();

                    let params = {
                        page : page,
                        pageSize : pageSize,
                    };
                    if (programId) {
                        params.programId = programId;
                    }

                    let success = function (response) {
                        let data = response.data;
                        let links = ParseLinks.parse(response.headers('link'));
                        let totalCount = response.headers('x-total-count');
                        let resolved = {
                            links      : links,
                            sites      : data,
                            totalCount : totalCount,
                        };
                        deferred.resolve(resolved);
                    };

                    let error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http.get(baseApi, { params : params }).then(success).catch(error);

                    return deferred.promise;
                },

                getZipCodes : function (requestBody) {
                    return $http.get(`${baseApi }homeVisitWhiteList/search?${ $.param(requestBody)}`);
                },

                removeZipCodes : function (zipCodesList) {
                    return $http.post(`${baseApi }homeVisitWhiteList/removeZipCodes`, zipCodesList);
                },

                uploadZipCodes : function (zipCodesList) {
                    return $http.post(`${baseApi }homeVisitWhiteList/uploadZipCodes`, zipCodesList);
                },
            };
        });
}());

