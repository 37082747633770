/**
 * created by Jason.Cao on Nov 2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for navigation nodes and edges configuration
     */
        .directive('formElementConfigBasicPage', function (FormNavigationConfigUtil, ExpressionConstantsService,
                                                           LocaleConstantsService, ngDialog, NavBuilderConfigModelService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/elementConfig.basic.page.html',
                scope: {
                    page: '=', // this is the form page
                    selector: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.getEdgeDescriptionStart = FormNavigationConfigUtil.getEdgeDescriptionStart;
                    $scope.getEdgeDescriptionEnd = FormNavigationConfigUtil.getEdgeDescriptionEnd;

                    $scope.node = $scope.page._parent.findNodeByPageLocalId($scope.page.localId);

                    $scope.expConstants = ExpressionConstantsService;

                    $scope.moveEdgeUp = FormNavigationConfigUtil.moveEdgeUp;
                    $scope.moveEdgeDown = FormNavigationConfigUtil.moveEdgeDown;

                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.listToAvoid = [FormConstants.submitButton.buttonType.BUTTON_DEFAULT, FormConstants.submitButton.buttonType.BUTTON_INFO,FormConstants.submitButton.buttonType.BUTTON_SUCCESS,FormConstants.submitButton.buttonType.BUTTON_WARNING,FormConstants.submitButton.buttonType.LINK];
                    /**
                     * show a window to add navigation node with some preset value
                     * @param nodeFrom - node from preset
                     * @param nodeTo - node to preset
                     */
                    $scope.showAddNavigationEdgeWindow = function(nodeFrom, nodeTo) {
                        FormNavigationConfigUtil.showAddNavigationEdgeWindow(nodeFrom, nodeTo, $scope.page._parent);
                    };

                    /**
                     * editing an edge
                     * @param edge - edge to be edited
                     */
                    $scope.showEditNavigationEdgeWindow = function(edge) {
                        FormNavigationConfigUtil.showEditNavigationEdgeWindow(edge, $scope.page._parent);
                    };

                    /**
                     * goto node to in the edge
                     * @param edge
                     */
                    $scope.goToEdgeNodeTo = function(edge) {
                        FormNavigationConfigUtil.goToEdgeNodeTo(edge, $scope.selector, $scope.page._parent);
                    };

                    /**
                     * goto node from in the edge
                     * @param edge
                     */
                    $scope.goToEdgeNodeFrom = function(edge) {
                        FormNavigationConfigUtil.goToEdgeNodeFrom(edge, $scope.selector, $scope.page._parent);
                    };


                    $scope.showNavTemplateWindow = function () {
                        var template = '<nav-builder nav-data="data" close-function="closeFunction"></nav-builder>';
                        var _scope = $scope;

                        // open modal window and pass in all the scope variables
                        var dialog = ngDialog.open({
                            showClose: false,
                            closeByEscape: false,
                            closeByDocument: false,
                            template: template,
                            plain: true,
                            controller: ["$scope", function ($scope) {
                                $scope.data = new NavBuilderConfigModelService.NavBuilderConfigModel(_scope.page);
                                $scope.closeFunction = closeDialog;
                            }],
                            height: '90%',
                            className: 'ngdialog-theme-plain custom-width-large'
                        });

                        function closeDialog() {
                            dialog.close();
                        }
                    }
                }

            };

        });

})();

