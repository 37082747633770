angular.module('acadiamasterApp')
    .config(($stateProvider) => {
        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('unauthenticatedTasks');
            return $translate.refresh();
        }

        $stateProvider
            .state('program.manage.unauthenticated', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'unauthenticated.title',
                },
                parent: 'program.manage',
                resolve: {
                    entity: ['$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id: $stateParams.id })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/unauthenticated',
                views: {
                    'content@': {
                        bindToController: true,
                        controller: 'taskListController',
                        controllerAs: 'utc',
                        templateUrl: 'admin-templates/site/unauthenticatedTask/listPage/taskList.template.html',
                    },
                },
            })
            .state('program.manage.unauthenticated.new', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'unauthenticated.title',
                },
                parent: 'program.manage.unauthenticated',
                resolve: {
                    entity: ['$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id: $stateParams.id })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/new',
                views: {
                    'content@': {
                        template: `<program-task-config is-new="true"
                                state-after-cancel-save="program.manage.unauthenticated">
                            </program-task-config>`,
                    },
                },
            })
            .state('program.manage.unauthenticated.edit', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'unauthenticated.title',
                },
                parent: 'program.manage.unauthenticated',
                resolve: {
                    entity: ['$stateParams', 'UnauthenticatedTaskService',
                        ($stateParams, UnauthenticatedTaskService) => UnauthenticatedTaskService.getTaskById($stateParams.taskId)],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/edit/{taskId}',
                views: {
                    'content@': {
                        controller: ($scope, entity) => {
                            $scope.task = entity.data;
                        },
                        template: `<program-task-config is-new="false"
                                task="task"
                                state-after-cancel-save="program.manage.unauthenticated">
                            </program-task-config>`,
                    },
                },
            });
    });
