
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for communication category model
     */
        .factory('CommunicationCategoryModelService',
            function () {
                /***************************************************************
                 * communication category model
                 ***************************************************************/
                function convertDtoToCommunicationCategoryModel(dto) {
                    var p = new CommunicationCategoryModel();
                    p.fromDto(dto);
                    return p;
                }

                function CommunicationCategoryModel() {
                    this.id=null;
                    this.name = null;
                    this.description=null;
                    this.isEditable=null;
                }

                CommunicationCategoryModel.prototype.fromDto = function (dto) {
                    this.id=dto.id;
                    this.name = dto.name;
                    this.description=dto.description;
                    this.isEditable=dto.isEditable;
                };

                CommunicationCategoryModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id=this.id;
                    dto.name = this.name;
                    dto.description=this.description;
                    dto.isEditable=this.isEditable;
                    return dto;
                };
                /***************************************
                 * service return call
                 ***************************************/

                return {
                    CommunicationCategoryModel:CommunicationCategoryModel,
                    convertDtoToCommunicationCategoryModel:convertDtoToCommunicationCategoryModel
                };
            });

})();
