/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

angular.module('acadiamasterApp')
    .factory('AddAssociation', ($http, localWeb) => {
        let url = localWeb.createUrl('/api/programCategory/addAssociation');

        return {
            query : function (data) {
                return $http({
                    data    : JSON.stringify(data),
                    headers : { 'Content-Type' : 'application/json' },
                    method  : 'POST',
                    url     : url,
                }).then(response => {
                    return response.data;
                }, error => {
                    return error;
                });
            },
        };
    })
    .factory('DeleteAssociation', ($http, localWeb) => {
        let url = localWeb.createUrl('/api/programCategory/deleteAssociation');

        return {
            query : function (data) {
                return $http({
                    data    : JSON.stringify(data),
                    headers : { 'Content-Type' : 'application/json' },
                    method  : 'delete',
                    url     : url,
                }).then(response => {
                    return response.data;
                }, error => {
                    return error;
                });
            },
        };
    })
    .factory('GetPrograms', ($http, localWeb) => {
        let url = localWeb.createUrl('/api/programCategory/getPrograms/');

        return {
            query : function (id) {
                return $http({
                    headers : { 'Content-Type' : 'application/json' },
                    method  : 'get',
                    url     : url + id,
                }).then(response => {
                    return response.data;
                }, error => {
                    return error;
                });
            },
        };
    })
    .factory('GetAllPrograms', ($http, localWeb) => {
        let url = localWeb.createUrl('/api/programs/');

        return {
            query : function (id) {
                return $http({
                    headers : { 'Content-Type' : 'application/json' },
                    method  : 'get',
                    url     : url,
                }).then(response => {
                    return response.data;
                }, error => {
                    return error;
                });
            },
        };
    });
