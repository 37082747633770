(function () {
    'use strict';

    angular.module('acadiamasterApp').controller('UserImportCreateTaskController',
        function ($scope,$state,UserImportService, ProgramService) {


            function init(){

                $scope.taskData = {
                    taskName : '',
                    externalSource : '',
                    programCode: '',
                    userProfileDataCsvPath:'',

                };

                $scope.fileData = {
                    userCsvFile : '',
                };

                $scope.programs = ProgramService.query();
            }

            $scope.importFormFromEntity = {
                REDCAP: 'REDCAP'
            };



            $scope.files = [];


            $scope.save = function() {

              $scope.taskData.userProfileDataCsvPath =  $scope.fileData.userCsvFile.name;


              $scope.files.push($scope.fileData.userCsvFile);


                UserImportService.createUserImportTask($scope.taskData,$scope.files)
                .then(function (response) {
                    $state.go('externalUserImport');
                 }, function (error) {
                    console.log(error);
                    $scope.files= [];
                 });
            };

            init();
    });

})();
