/* eslint-disable no-use-before-define */
(function () {
    let app = angular.module('acadiamasterApp');

    /**
     * service for ReuseExpressionCondition model
     */
    // eslint-disable-next-line angular/module-getter
    app.factory('ReuseExpressionConditionModel', (QObjectModel, ProgramTestConstantsService, ExpressionConditionLoadUtil, ExpressionConditionService) => {
        /** *************************************************************
         * private functions
         **************************************************************/

        ReuseExpressionCondition.inheritsFrom(QObjectModel);

        function ReuseExpressionCondition (parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.REUSE_EXPRESSION_CONDITION);

            // initialized the variables
            this.conditionId = null;
            this._condition = null;
        }

        /*
         * convert the current UI model to dto format
         */
        ReuseExpressionCondition.prototype.toDto = function () {
            let dto = QObjectModel.prototype.toDto.call(this);
            dto.conditionId = this.conditionId;
            return dto;
        };

        /*
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ReuseExpressionCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);
            this.conditionId = dto.conditionId;

            ExpressionConditionLoadUtil.loadExpressionCondition(this);
        };

        ReuseExpressionCondition.prototype.setExpressionCondition = function (condition) {
            this._condition = condition;
            this.conditionId = condition == null ? null : condition.id;
            validateForCircularDependency(this, condition);
        };

        ReuseExpressionCondition.prototype.getExpressionCondition = function () {
            return this._condition;
        };

        ReuseExpressionCondition.prototype.getDescriptionAsHtml = function () {
            let baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            let msg = `Expression (${ this.conditionId == null ? 'no id' : this.conditionId } | ${
                this._condition == null ? 'no name' : this._condition.name })`;
            return `${baseMsg } ${ msg}`;
        };

        ReuseExpressionCondition.prototype._validateSelf = function () {
            this.clearError();

            if (this.conditionId == null) {
                this.setErrorMessage('reusable expression should be selected');
            }
            if (this.hasCircularDependency) {
                this.setErrorMessage('selected expression will cause circular dependency with parent expression');
            }
        };

        ReuseExpressionCondition.prototype.getParentExpressionCondition = function () {
            if (this._parent) {
                if (this._parent instanceof QObjectModel) {
                    return this._parent.getParentExpressionCondition();
                }
                return this._parent;
            }
            return null;
        };

        function validateForCircularDependency (model, newValue) {
            // reset previous error
            model.hasCircularDependency = false;

            if (newValue == null) {
                return;
            }
            let parent = model.getParentExpressionCondition();
            if (parent == null || parent.id == null || parent.conditionTree == null) {
                return;
            }

            ExpressionConditionService.checkCircularDependency(parent.conditionTree.toDto(), parent.id)
                .then(response => {
                    model.hasCircularDependency = response.data?.hasCircularDependency;
                    model.validate(true);
                });
        }

        /** *************************************
         * service return call
         ***************************************/
        return ReuseExpressionCondition;
    });
}());
