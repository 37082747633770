/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for trigger configuration
     */
        .directive('formElementConfigTrigger', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfig.trigger.html',
                scope: {
                    element: '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.targetTypeChanged = function(newTargetType) {
                        $scope.element.targetTypeChanged(newTargetType);
                    };

                    /**
                     * function to pass to filter out unwanted item from the list of reference
                     * @param listToFilterFrom
                     * @returns {Array}
                     */
                    $scope.filterOutTargets = function (listToFilterFrom) {
                        var filteredList = [];
                        if (isAccordionTrigger($scope.element)) {
                            // if element is accordion related, then only return the accordion enabled fields
                            if (listToFilterFrom != null) {
                                for (var i = 0; i < listToFilterFrom.length; i++) {
                                    if (isAccordionEnabledTarget(listToFilterFrom[i])) {
                                        filteredList.push(listToFilterFrom[i]);
                                    }
                                }
                            }
                        } else if (isOnClickTrigger($scope.element)) {
                            if (listToFilterFrom != null) {
                                for (var i = 0; i < listToFilterFrom.length; i++) {
                                    if (isOnClickEnabledTarget(listToFilterFrom[i])) {
                                        filteredList.push(listToFilterFrom[i]);
                                    }
                                }
                            }
                        } else if (isValueChangeTrigger($scope.element)) {
                            if (listToFilterFrom != null) {
                                for (var i = 0; i < listToFilterFrom.length; i++) {
                                    if (isValueChangeEnabledTarget(listToFilterFrom[i])) {
                                        filteredList.push(listToFilterFrom[i]);
                                    }
                                }
                            }
                        } else {
                            // filter out nothing
                            filteredList = listToFilterFrom;
                        }
                        return filteredList;
                    };

                    $scope.gotoLinkedElement = function() {
                        var linkedElement = $scope.element.findLinkedElement();
                        if (linkedElement!=null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, "_parent", ['collapsed', 'sectionCollapsed'], false);
                        }
                    }
                }
            };

            function isValueChangeTrigger(trigger){
                return trigger != null && trigger.type != null && trigger.type == FormConstants.triggerTypeConst.ON_VALUE_CHANGE;
            }
            function isValueChangeEnabledTarget(targetElement) {
                if (targetElement==null) {
                    return false;
                }
                return FormConstants.fieldsListByType.INPUT.includes(targetElement.type.name);
            }

            function isOnClickTrigger(trigger) {
                return trigger != null && trigger.type != null && trigger.type == FormConstants.triggerTypeConst.ON_CLICK;
            }

            function isOnClickEnabledTarget(targetElement) {
                if (targetElement==null) {
                    return false;
                }
                return FormConstants.fieldsListByType.NON_INPUT.includes(targetElement.type.name);
            }

            /**
             * check if the trigger is related to accordion
             * @param trigger - trigger dto
             * @returns {boolean}
             */
            function isAccordionTrigger(trigger) {
                return trigger!=null && trigger.type!=null &&
                    (trigger.type == FormConstants.triggerTypeConst.ACCORDION_STATE_EXPANDED ||
                        trigger.type == FormConstants.triggerTypeConst.ACCORDION_STATE_COLLAPSED);
            }


            /**
             * check if the target element is enabled with accordion
             * @param targetElement - target element
             * @return {boolean} - true if the target is enabled with accordion, false otherwise
             */
            function isAccordionEnabledTarget(targetElement) {
                if (targetElement==null) {
                    return false;
                }

                // currently only multi-select with the option enabled is eligible
                if (targetElement.type == FormConstants.fieldsType.MULTI_SELECTOR) {
                    var fieldValue = targetElement.fieldValue;
                    return fieldValue.showAccordion != null && fieldValue.showAccordion;
                }

                return false;
            }
        });

})();

