(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for template detail model
     */
        .factory('TemplateDetailModelService', function (vbrCommonUtil, ModelServiceBase, ProgramFormConstantsService, TemplateMessageService) {

            /**
             * helper function to create field model from dto, this is needed because javascript
             * serialization can not carry class information, and we can't easily over load the constructor
             * without creating confusing behavior
             * @param dto -- plain dto for any FormBehaviorTemplate
             * @returns {*}
             */
            function createModelFromDto(dto) {
                var model = null;
                if (dto != null) {
                    var fullClass = dto[ProgramFormConstantsService.classes.classReferenceKey];

                    if (fullClass === ProgramFormConstantsService.classes.FormBehaviorTemplateOneTimeSurvey) {
                        model = new FormBehaviorTemplateOneTimeSurvey();
                    } else if (fullClass === ProgramFormConstantsService.classes.FormBehaviorTemplate) {
                        model = new FormBehaviorTemplate();
                    }

                    if (model != null) {
                        model.fromDto(dto);
                        model[ProgramFormConstantsService.classes.classReferenceKey] = fullClass;
                    }
                }

                return model;
            }

            /***************************************************************
             * dto for template detail model, it is the base model of many templates
             ***************************************************************/
            FormBehaviorTemplate.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            /**
             * @constructor
             */
            function FormBehaviorTemplate() {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, null);
                this[ProgramFormConstantsService.classes.classReferenceKey] = ProgramFormConstantsService.classes.FormBehaviorTemplate;
                this.supportPausing = true;
            }

            /**
             * convert the current UI model to dto format
             */
            FormBehaviorTemplate.prototype.toDto = function () {
                var dto = {};
                dto.supportPausing = this.supportPausing;
                dto[ProgramFormConstantsService.classes.classReferenceKey] = this[ProgramFormConstantsService.classes.classReferenceKey];

                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            FormBehaviorTemplate.prototype.fromDto = function (dto) {
                this.supportPausing = dto.supportPausing;
                this[ProgramFormConstantsService.classes.classReferenceKey] = dto[ProgramFormConstantsService.classes.classReferenceKey];
            };


            /***************************************************************
             * template model for one-time survey
             ***************************************************************/
            FormBehaviorTemplateOneTimeSurvey.inheritsFrom(FormBehaviorTemplate);
            function FormBehaviorTemplateOneTimeSurvey() {
                FormBehaviorTemplate.call(this, null);
                this[ProgramFormConstantsService.classes.classReferenceKey] = ProgramFormConstantsService.classes.FormBehaviorTemplateOneTimeSurvey;

                this.timeSensitive = false;
                this.start = null;
                this.end = null;
                this.templateMessages = [
                    new TemplateMessageService.TemplateMessage(ProgramFormConstantsService.templateMessageType.AFTER_COMPLETION),
                    new TemplateMessageService.TemplateMessage(ProgramFormConstantsService.templateMessageType.BEFORE_EXPIRE),
                    new TemplateMessageService.TemplateMessage(ProgramFormConstantsService.templateMessageType.AFTER_ADDED),
                    new TemplateMessageService.TemplateMessage(ProgramFormConstantsService.templateMessageType.AFTER_EXPIRED),
                    new TemplateMessageService.TemplateMessage(ProgramFormConstantsService.templateMessageType.AFTER_PAUSED)
                ];
            }

            FormBehaviorTemplateOneTimeSurvey.prototype.fromDto = function (dto) {
                FormBehaviorTemplate.prototype.fromDto.call(this, dto);
                this.timeSensitive = dto.timeSensitive;
                this.start = dto.start==null ? null : getTimeConfigurationModelFromDto(dto.start);
                this.end = dto.end==null ? null : getTimeConfigurationModelFromDto(dto.end);

                this.templateMessages = dto.templateMessages == null ? [] : dto.templateMessages;
            };

            FormBehaviorTemplateOneTimeSurvey.prototype.toDto = function () {
                var dto = FormBehaviorTemplate.prototype.toDto.call(this);
                dto.timeSensitive = this.timeSensitive;
                dto.start = this.start == null ? null : this.start.toDto();
                dto.end = this.end == null ? null : this.end.toDto();

                dto.templateMessages = TemplateMessageService.loadMessages(this.templateMessages);
                return dto;
            };

            /***************************************************************
             * helper class TimeConfigurationModel for template model for one-time survey
             ***************************************************************/
            TimeConfiguration.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function getTimeConfigurationModelFromDto(dto) {
                var model = null;

                if (dto!=null) {
                    model = new TimeConfiguration();
                    model.fromDto(dto);
                }

                return model;
            }

            /**
             * @constructor
             */
            function TimeConfiguration() {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, null);

                this.type = ProgramFormConstantsService.timeConfigurationType.NONE;
                this.value = false;
            }

            /**
             * convert the current UI model to dto format
             */
            TimeConfiguration.prototype.toDto = function () {
                var dto = {};

                dto.type = this.type.name;
                dto.value = this.value;

                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            TimeConfiguration.prototype.fromDto = function (dto) {
                this.type = ProgramFormConstantsService.timeConfigurationType[dto.type];
                this.value = dto.value;
            };

            /***************************************
             * private function call
             ***************************************/


            /***************************************
             * service return call
             ***************************************/

            return {
                createModelFromDto : createModelFromDto,

                FormBehaviorTemplateOneTimeSurvey: FormBehaviorTemplateOneTimeSurvey,
                FormBehaviorTemplate: FormBehaviorTemplate
            };
        });
})();
