/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionForm', (RuleUtilService, Form, FormUtilService) => {
            return {
                link : function ($scope) {
                    $scope.programId = $scope.action.getRoot().programId;
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.showWarningMessage = false;

                    // Add form search option in model to use form-search directive
                    // RuleUtilService.addFormSearchOptions($scope);
                    $scope.isReady = false;

                    $scope.form = {};
                    $scope.form.options = {
                        form     : $scope.action.form,
                        title    : 'Form',
                        viewOnly : false,
                    };
                    $scope.isReady = true;

                    $scope.$watch('form.options.form', newForm => {
                        $scope.action.form = newForm;
                        if ($scope.action.form) {
                            let formDetail = Form.get({
                                id         : $scope.action.form.id,
                                projection : Form.projections.ACTIVE_ONLY,
                            });
                            formDetail.$promise.then(data => {
                                let formObject = new FormUtilService.FormModelServiceForm.FormModel();
                                formObject.fromDto(data);

                                // todo: fix later, this is a simplified solution while doing clean up, navigationNodes > 1 doesn't always mean multiple pages actually
                                $scope.showWarningMessage = formObject.formVersions[0].editMode.navigationNodes.length > 1 && $scope.action.displayType.name === $scope.cs.formDisplayTypes.SHOW_FORM_IN_WALL.name;
                            });
                        } else {
                            $scope.showWarningMessage = false;
                        }
                    });
                },
                restrict : 'E',
                scope    : {
                    action     : '=',
                    parentForm : '=',
                },
                templateUrl : 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.form.html',
            };
        });
}());
