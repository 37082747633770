import template from './businessRuleCorrectiveAction.html';
(function () {
    'use strict';

    /**
     * business rule corrective action configuration directive
     */
    angular.module('acadiamasterApp').directive('businessRuleCorrectiveAction', function (ProgramTestConstantsService, RuleModelService, BusinessRule, BusinessRuleCorrectiveAction) {
        return {
            restrict: 'E',
            template: template,
            scope: {
                correctiveAction: '='
            },
            link: function ($scope) {

                $scope.maxUsers = -1;
                $scope.programId = $scope.correctiveAction.getRoot().programId;
                /**
                 * get the maxUsers value from http call, and set it to $scope.maxUsers
                 */
                BusinessRuleCorrectiveAction.getMaxUsers()
                    .then( response => {
                        $scope.maxUsers = response.data;
                    } );

                /**
                 * list of rule options used for rule search directive
                 * @type {{getRule: getRule, setRule: setRule, title: string, viewOnly: boolean}}
                 */
                $scope.ruleOptions = {
                    getRule: function () {
                        return $scope.correctiveAction.getRule();
                    },
                    setRule: function (rule) {
                        if (rule == null) {
                            $scope.correctiveAction.setRule(null);
                        }
                        else {
                            // load the full rule from server so we can set workflow data
                            BusinessRule.get({id: rule.id}).$promise.then(function (data) {
                                var ruleModel = new RuleModelService.RuleModel(null, null);
                                ruleModel.fromDto(data);

                                $scope.correctiveAction.setRule(ruleModel);
                            });
                        }
                    },
                    title: 'Business Rule',
                    viewOnly: false
                };

                /**
                 * work flow functions for work flow selection directive
                 * @type {{getId: getId, setId: setId}}
                 */
                $scope.workIdFunctions = {
                    getId: function () {
                        var workflow = $scope.correctiveAction.getWorkflow();
                        return workflow == null ? null : workflow.id;
                    },
                    setId: function (id) {
                        if (id == null || $scope.correctiveAction.getRule() == null) {
                            $scope.correctiveAction.setWorkflow(null);
                        }
                        else {
                            var workflow = _.find($scope.correctiveAction.getRule().workflows, function (wf) {
                                return id === wf.id;
                            });

                            $scope.correctiveAction.setWorkflow(workflow);
                        }
                    }
                };

                /**
                 * function for checking if work flow is selected
                 * @param workflow - workflow to check
                 * @returns {boolean} - true if the workflow's id matches the current corrective action's workflow id, false otherwise
                 */
                $scope.isWorkflowSelected = function (workflow) {
                    var wf = $scope.correctiveAction.getWorkflow();
                    return wf!=null && wf.id == workflow.id;
                };
            }
        };


    });
})();
