(function () {
    'use strict';

    /**
     * directive for comparing values
     */
    angular.module('acadiamasterApp').directive('formCompareValue', function (CompareResultModelService, CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/common/formCompareValue.html',
            scope: {
                value : '=',
                key : '=',
                showChangeOnly : '=',
                keyPrefix: '@'
            },
            link: function ($scope) {
                $scope.compareResultStatus = CompareResultModelService.compareResultStatus;
                $scope.util = CompareResultUtilService;

                $scope.charLimit = 300;

                $scope.overTheLimit = function(s) {
                    // not null AND is a string AND first character not equal '<' AND length is greater than char limit
                    return (s!=null && (typeof s === 'string') && s.charAt(0)!='<' && s.length > $scope.charLimit);
                };

                $scope.getKeyWithPrefix = function() {
                    return ($scope.keyPrefix==null ? "" : $scope.keyPrefix) + $scope.key;
                };
            }
        };
    });

})();
