import _ from 'lodash';

(function () {
    'use strict';

    angular.module('acadiamasterApp').service('ExpirationConfigModel', function ( ExpirationConfigDataListModel ) {

        function ExpirationConfigModel() {
            this.window = null;
            this.expiry = false;
            this.expirationConfigurationDataList = [];
        }

        ExpirationConfigModel.prototype.toDto = function () {
            let dto = {};
            dto.expiry = this.expiry;
            dto.window = null;
            dto.expirationConfigurationDataList = [];
            if(this.expiry) {
                dto.window = this.window;
                _.forEach(this.expirationConfigurationDataList, (expirationConfigurationData) => {
                    dto.expirationConfigurationDataList.push(expirationConfigurationData.toDto());
                });
            }
            return dto;
        };

        ExpirationConfigModel.prototype.fromDto = function (dto) {
            if(dto.window){
                this.window = dto.window;
            }
            this.expiry = dto.expiry;
            this.expirationConfigurationDataList = [];
            const _this = this;
            _.forEach(dto.expirationConfigurationDataList, (expirationConfigurationData) => {
                const expirationConfigDataListModel = new ExpirationConfigDataListModel(_this);
                _this.expirationConfigurationDataList.push(expirationConfigDataListModel);
                expirationConfigDataListModel.fromDto(expirationConfigurationData);
            });
        };

        ExpirationConfigModel.prototype.addComponent = function() {
            const expirationConfigurationData = new ExpirationConfigDataListModel();
            this.expirationConfigurationDataList.push(expirationConfigurationData);
        };

        ExpirationConfigModel.prototype.removeComponent = function (index) {
            this.expirationConfigurationDataList.splice(index, 1);
        };

        return ExpirationConfigModel;

    });


})();
