(function () {
    'use strict';

    /**
     * service to manipulate large list of objects in display, this service will create a
     * list container object and allow user to only deal with limited amount of data on screen
     */
    angular.module('acadiamasterApp').factory('ListContainerService', function () {

        /**
         * creating sets of pages on mode that has too many pages
         * @param listContainer - list container
         * @param resetIndex - whether we should resetIndex or not, if not provided, assume false
         */
        function createPageSetsIfNeeded(listContainer, resetIndex) {
            var items = listContainer.items;
            var totalItemCount = items == null ? 0 : items.length;

            if (resetIndex || listContainer.currentPageSize * listContainer.currentPageNum >= totalItemCount) {
                listContainer.currentPageNum = 0;
            }

            buildPageSets(listContainer);
        }

        /**
         * building the page sets arrays
         * @param listContainer - list container
         */
        function buildPageSets(listContainer) {
            var items = listContainer.items;
            var totalItemCount = items == null ? 0 : items.length;

            // we use 2 arrays to keep track of things
            // array #1 : pages to be displayed
            // array #2 : list of set indexes used to generate the pagination line

            if (items!=null && listContainer.currentPageSize < totalItemCount) {
                // we support page sets and there are more than 1 sets of pages

                // build the list of pages to be displayed
                var startIndex = listContainer.currentPageSize * listContainer.currentPageNum;

                if (startIndex >= totalItemCount) {
                    // something wrong with the starting index, move it to 0 and reset the page
                    listContainer.currentPageNum = 0;
                    startIndex = 0;
                }

                var endIndex = startIndex + listContainer.currentPageSize;
                if (endIndex > totalItemCount) {
                    endIndex = totalItemCount;
                }
                listContainer.itemsToBeDisplayed = items.slice(startIndex, endIndex);

                // building the actual page indexes
                listContainer.pageIndexes = [];

                var numOfPages = Math.floor(totalItemCount / listContainer.currentPageSize);
                if (totalItemCount % listContainer.currentPageSize > 0) {
                    numOfPages++;
                }

                for (var i = 0; i<numOfPages; i++) {
                    listContainer.pageIndexes.push(i);
                }
            }
            else {
                // display set is the same as the original items
                listContainer.itemsToBeDisplayed = items;
                // there is only 1 page index as there is only 1 page of item
                listContainer.pageIndexes = [0];
            }
        }


        /**
         * create watches to monitor list changes and react to it
         * @param listContainer - list container
         */
        function createWatches(listContainer) {
            var $scope = listContainer.$scope;
            var varName = listContainer.scopeVarName;

            // add watch on the collection so page set will be updated if items are moved/added/deleted
            $scope.$watchCollection(varName, function() {
                createPageSetsIfNeeded(listContainer, false);
            });

            // add watch on change of selected item change
            if (listContainer.selector != null) {
                $scope.$watch('selector.selectedItem', function(itemOld, itemNew) {
                    if (itemOld !== itemNew) {
                        listContainer.updatePageIndexBySelector();
                    }
                });
            }
        }


        /**
         * get the node type of the item in the array, we will basically just get the first item and
         * check its property 'nodeType'
         * @param arrayItems - a list of items, nullable
         * @returns {null} - node type of the first item in the list or null if list is empty or null
         */
        function getNodeTypeInArray(arrayItems) {
            if (arrayItems==null || arrayItems.length == 0 || arrayItems[0]==null) {
                return null;
            }

            return arrayItems[0].nodeType;
        }

        /**
         * find ancestor from source item that matches the node type
         * @param sourceItem - source item to start the search up the ancestor tree
         * @param nodeType - node type
         * @returns {*} - an ancestor that matches the node type of null, note: an item is considered an ancestor of its own
         */
        function findAncestorFrom(sourceItem, nodeType) {
            if (sourceItem == null || nodeType == null) {
                return null;
            }

            if (sourceItem.nodeType == nodeType) {
                // same node type, return the source item directly
                return sourceItem;
            }

            return findAncestorFrom(sourceItem._parent, nodeType);
        }

        return {
            /**
             * use a function inside service to create container so a single page can have multiple containers
             * active at the same time
             * @param $scope - scope object used to set watches
             * @param selector - selector object
             * @param treeName - tree name
             * @param originalList - an array of objects that we need to build pagination around
             * @param scopeVarName - variable name in the scope, needed to set watches
             * @param defaultPageSize - optional, default page size, if not provided, use 20
             * @param availableSizes - optional, if not provided, use [20, 50, 100]
             */
            createListContainer: function ($scope, selector, treeName,
                                           originalList, scopeVarName, defaultPageSize, availableSizes) {
                return {
                    // this is the original list of items that we build the container for
                    items : originalList,
                    $scope : $scope,
                    selector : selector,
                    treeName : treeName,
                    scopeVarName : scopeVarName,
                    currentPageSize : defaultPageSize==null ? 20 : defaultPageSize,
                    availableSizes : availableSizes==null ? [20, 50, 100] : availableSizes,
                    currentPageNum : 0,

                    // this is the display array for GUI pagination, it should be a subset of the original list
                    itemsToBeDisplayed: [],
                    pageIndexes : [0],


                    /**
                     * initialization function for page listing
                     */
                    init : function() {
                        // divide list of pages into sets of pages, this is only used when the list of pages are very large
                        createPageSetsIfNeeded(this, true);

                        // create some watches to monitor changes to the list
                        createWatches(this);
                    },

                    hasMultiplePages : function() {
                        return this.pageIndexes!=null && this.pageIndexes.length > 1;
                    },

                    /**
                     * check if the page index is the current page on the list container
                     * @param pageIndex - page index to check
                     */
                    isCurrentPageIndex : function(pageIndex) {
                        return this.currentPageNum === pageIndex;
                    },

                    /**
                     * change the page index
                     * ps. if the page index is not valid (<0 or bigger the number of pages available), it won't do anything
                     * @param pageIndex - new page index to be set, 0-based number, valid number is 0 to total pages - 1
                     */
                    changePage : function(pageIndex) {
                        if (pageIndex >=0 && pageIndex < this.pageIndexes.length && this.currentPageNum != pageIndex) {
                            this.currentPageNum = pageIndex;
                            buildPageSets(this);
                        }
                    },

                    /**
                     * this function should be invoked if the page size has changed
                     */
                    pageSizeChanged : function() {
                        createPageSetsIfNeeded(this, false);
                    },

                    /**
                     * item the page index
                     */
                    updatePageIndexBySelector : function() {
                        // if tree name does not match, skip
                        if (this.treeName != this.selector.getCurrentSource()) {
                            return;
                        }

                        // find an ancestor at the same level as any item in the current item array
                        var ancestorAtSameLevel = findAncestorFrom(this.selector.selectedItem, getNodeTypeInArray(this.items));

                        // if selectedItem is not something that's in the container item or as a decedent of an item in
                        // container, skip
                        if (ancestorAtSameLevel == null) {
                            return;
                        }

                        // find the item in container that contains/matches the selected item
                        var foundIndex = _.findIndex(this.items, function(item) {
                            return ancestorAtSameLevel == item;
                        });

                        // can't find it in the list, skip
                        if (foundIndex == -1) {
                            return;
                        }

                        // calculate the new page index, update it
                        var newPageIndex = Math.floor(foundIndex / this.currentPageSize);

                        this.changePage(newPageIndex);
                    }
                };
            }
        };

    });
})();


