(function () {
    angular.module('acadiamasterApp')

    /**
     * program communication category association directive
     */
        .directive('exportWizardStepCommunicationCategories', () => {
            return {
                link($scope) {
                    init($scope);

                    $scope.selectAll = function () {
                        if ($scope.data.selectAllValue) {
                            // Add displayed communication category to the selected communication category if they do not already exist there
                            const selectedIds = $scope.selectedData.communicationCategories.map(selectedCategory => selectedCategory.id);
                            if ($scope.data.communicationCategories) {
                                $scope.data.communicationCategories.forEach((category) => {
                                    if (selectedIds.indexOf(category.id) === -1) {
                                        $scope.selectedData.communicationCategories.push(category);
                                    }
                                });
                            }
                        } else {
                            // Remove any communication category that are currently displayed from the selected communication category
                            const categoryIds = $scope.data.communicationCategories.map(category => category.id);
                            const selectedCategories = [];
                            if ($scope.selectedData.communicationCategories) {
                                $scope.selectedData.communicationCategories.forEach((category) => {
                                    if (categoryIds.indexOf(category.id) === -1) {
                                        selectedCategories.push(category);
                                    }
                                });
                            }
                            $scope.selectedData.communicationCategories = selectedCategories;
                        }
                    };
                    $scope.$watch('selectedData.communicationCategories.length', () => {
                        $scope.data.selectAllValue = areAllDisplayedRulesSelected($scope);
                    });
                },
                restrict: 'E',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '=',
                },
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepCommunicationCategoryManagement.html',
            };

            /** ****************************************************************************
             * private functions
             ***************************************************************************** */

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.data = {
                    selectAllValue: false,
                    communicationCategories: [],
                };
                $scope.program.communicationCategoryMappings.forEach((mapping) => {
                    $scope.data.communicationCategories.push(mapping.communicationCategory);
                });

                $scope.onCategoryCheckboxClicked = function(event) {
                    event.stopPropagation();
                }
            }

            function areAllDisplayedRulesSelected($scope) {
                const selectedIds = $scope.selectedData.communicationCategories.map(selectedCategory => selectedCategory.id);
                if (!$scope.data.communicationCategories || !$scope.data.communicationCategories.length) {
                    return false;
                }
                for (let i = 0; i < $scope.data.communicationCategories.length; i++) {
                    const { id } = $scope.data.communicationCategories[i];
                    if (selectedIds.indexOf(id) === -1) {
                        return false;
                    }
                }
                return true;
            }
        });
}());
