(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');
    /**
     * communication category detail directive
     */
    app.directive('vbrCommunicationCategoryDetail', function() {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/communicationCategory/communicationCategory-details.html',
            scope : {
                selectedCommunicationCategory : '='
            }
        };
    });

})();
