/**
 * created by Jason.Cao on Dec 2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for flow chart using Kmap
     */
        .directive('formFlowChartKmap', function ($window, FlowChartKmapModelService, FlowChartKmapService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/navigation/formFlowChart.kmap.html',
                scope: {
                    formMode: '=',
                    target: '=',
                    selector: '=',
                    showRenderingOptions: '=',
                    allowMultipleWindows: '='
                },
                link: function ($scope) {
                    $scope.newWindow = null;

                    var renderDirectionChoices = [
                        {name: 'Top To Bottom', value: 'TB'},
                        {name: 'Left To Right', value: 'LR'}
                    ];

                    $scope.kmapSettings = {
                        renderDirectionChoices: renderDirectionChoices,
                        renderDirection: renderDirectionChoices[0],
                        showRenderingOptions: $scope.showRenderingOptions != null ? $scope.showRenderingOptions : true,
                        allowMultipleWindows: $scope.allowMultipleWindows != null ? $scope.allowMultipleWindows : false
                    };

                    $scope.openFlowChartWindow = function () {
                        if ($scope.formMode.newWindow == null || $scope.formMode.newWindow.closed) {
                            var url = '/assets/kmap/formMap/form.html';

                            $scope.formMode.newWindow = $window.open(url, $scope.kmapSettings.allowMultipleWindows ?
                                "_blank" : 'form_flow_chart_window');
                        }

                        var targetNodeId;
                        // add focus on the target
                        if ($scope.target != null) {
                            targetNodeId = 'node_' + $scope.target.localId;
                        }

                        // create a selector to pass it to the 2nd window
                        var selector = function (itemIdString) {
                            if (itemIdString != null) {
                                var itemId = Number.parseInt(itemIdString.substr(5));
                                var nodeSelected = _.find($scope.formMode.navigationNodes, function (n) {
                                    return n.localId == itemId;
                                });

                                if (nodeSelected != null) {
                                    var pageSelected = nodeSelected.page;
                                    if (pageSelected != null) {
                                        $scope.selector.selectItem(pageSelected, true);
                                    }
                                    else {
                                        $scope.selector.selectItem(nodeSelected._parent._nodesAndEdges, true);
                                    }

                                    $scope.$apply();
                                }
                            }
                        };

                        FlowChartKmapModelService.createKmapModel($scope.formMode.navigationNodes).then(kmapModel => {
                            FlowChartKmapService.loadModel($scope.formMode.newWindow, kmapModel, $scope.kmapSettings.renderDirection.value, targetNodeId, selector);
                        });
                    };
                }
            };
        });

})();

