/**
 * Created by Jamie Nola on 08/10/2018
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .constant('EXTERNAL_LOGS_CONSTANTS', function () {
            var FIELDS = {
                DESCRIPTION: {
                    id: 'description'
                },
                EVENT_TYPE: {
                    id: 'eventType',
                    sortable: true
                },
                EXTERNAL_ID: {
                    id: 'externalId',
                    sortable: true
                },
                HTTP_METHOD: {
                    id: 'httpMethod',
                    sortable: true
                },
                ID: {
                    id: 'id',
                    sortable: true,
                    isIndex: true
                },
                INTERNAL_ID: {
                    id: 'internalId',
                    sortable: true
                },
                REQUEST_TIMESTAMP: {
                    id: 'requestTimestamp',
                    sortable: true,
                    isDate: true
                },
                RESPONSE_CODE: {
                    id: 'responseCode',
                    sortable: true
                },
                RESPONSE_TIMESTAMP: {
                    id: 'responseTimestamp',
                    sortable: true,
                    isDate: true
                },
                SERVICE: {
                    id: 'service',
                    sortable: true
                },
                EVENT_SOURCE: {
                    id: 'eventSource',
                    sortable: true
                },
            };


            return {
                FIELDS: FIELDS
            }
        }());
})();
