(function () {
    'use strict';

    /**
     * session management service
     */
    angular.module('acadiamasterApp').factory('SessionService', function (VbrLoggingDialogService,
        $timeout, $rootScope, $state, localWeb, Auth, SSOService) {
        // constants used for broadcasting
        var cs = {
            SHOW_DIALOG: 'sessionMonitor.showDialog',
            TIMER_UPDATED: 'sessionMonitor.timerUpdated'
        };

        // api urls important to this service
        var apis = {
            keepAliveApi: 'api/keepSessionAlive',
            loginApi: 'api/authenticate',
            unauthorizedApiList: [
                'api/programCategorys',
                'api/appConfig',
                'api/invitecode/isAvailable',
                'api/security/isTwoFactorAuthEnabled',
                'api/registerDevice',
                'api/users/isEmailAvailable',
                'api/register',
                'api/logout',
                'api/account/reset_password/init',
                'api/account/reset_password/finish',
                'api/activate',
                'api/file',
                'api/icon',
                'api/unsubscribeEmail',
                'api/account/reset_password/check_link_expire',
                'api/systemInfo'
            ]
        };

        // warning interval to show the time down clock
        var warningInterval = 5 * 60 * 1000; // 5 minutes

        // timer for session warning
        var sessionWarningTimer = null;

        // timestamp of when last session refresh was called
        var sessionRefreshTimeStamp = null;

        var sessionExpired = true;

        // current session timeout interval received from server, if this value is null, then we
        // can't run any session related timer because we don't know when to show it
        var currentSessionTimeoutInterval = null;

        /**
         * called when session is refreshed
         * @param sessionTimeoutInterval - current session timeout interval, may be null
         */
        function sessionRefreshed(sessionTimeoutInterval) {
            var interval = sessionTimeoutInterval - new Date().getTime();
            VbrLoggingDialogService.addDebug(
                'session refreshed with timeout interval : ' + interval
            );

            sessionExpired = false;
            sessionRefreshTimeStamp = new Date().getTime();

            // start or update session warning timer
            currentSessionTimeoutInterval = interval || currentSessionTimeoutInterval;

            // start new timer
            startWarningTimer();
        }

        function sessionHasExpired() {
            sessionExpired = true;
            sessionRefreshTimeStamp = null;
            $rootScope.$broadcast(cs.SHOW_DIALOG);
        }

        function logoutOccurred() {
            sessionExpired = true;
            sessionRefreshTimeStamp = null;
            // cancel current timeout if it's there
            if (sessionWarningTimer != null) {
                $timeout.cancel(sessionWarningTimer);
            }
        }

        /**
         * start warning timer
         */
        function startWarningTimer() {
            // cancel current timeout if it's there
            if (sessionWarningTimer != null) {
                $timeout.cancel(sessionWarningTimer);
            }

            // if we don't know the timeout interval, don't do anything
            if (currentSessionTimeoutInterval == null || currentSessionTimeoutInterval <= 0) {
                return;
            }

            // set delay to 5 minutes before the session timeout
            // if delay is too short (less than 1 minute), use delay as 80% of the session timeout
            // interval
            var delay = currentSessionTimeoutInterval < warningInterval ?
                currentSessionTimeoutInterval * 0.8 :
                currentSessionTimeoutInterval - warningInterval;

            VbrLoggingDialogService.addDebug('setting up timer with delay : ' + delay);
            // build timer with proper delay
            sessionWarningTimer = $timeout(function () {
                VbrLoggingDialogService.addDebug('timer expired');
                // broadcast message when timer expires
                // actual session monitor screen will be shown using a different directive
                $rootScope.$broadcast(cs.SHOW_DIALOG);
            }, delay);

            // broadcast timer updated event, might be useful
            $rootScope.$broadcast(cs.TIMER_UPDATED);
        }

        /**
         * check if session is alive or not based on session refresh timestamp
         */
        function isSessionAlive() {
            if (!sessionExpired) {
                if (sessionRefreshTimeStamp == null || currentSessionTimeoutInterval == null) {
                    sessionExpired = true;
                }

                // if session expire time is later than now
                var currentTime = new Date().getTime();
                var sessionExpireTime = sessionRefreshTimeStamp + currentSessionTimeoutInterval;

                if (sessionExpireTime <= currentTime) {
                    sessionExpired = true;
                }
            }

            return !sessionExpired;
        }

        /**
         * get session remaining time in milliseconds
         * @returns {number} session remaining time in ms
         */
        function getRemainingTime() {
            if (!isSessionAlive()) {
                return -1;
            }

            var currentTime = new Date().getTime();
            var sessionExpireTime = sessionRefreshTimeStamp + currentSessionTimeoutInterval;

            return sessionExpireTime - currentTime;
        }

        /**
         * register a list of listeners for system broadcasts
         */
        function registerListeners() {
            var SUCCESS = 'networkRequest.success';
            var ERROR_401 = 'networkRequest.error.401';
            var LOGOUT = 'logout';
            // not too sure about what to do with 403 at this point, this should only happen
            // if the logic in our code is wrong
            // var ERROR_403 = 'networkRequest.error.403';

            // todo: convert those logs to vbrLogger later, they are still useful for now

            $rootScope.$on(ERROR_401, function (event, responseData) {
                var requestUrl = responseData.config.url;

                if (requestUrl.startsWith('/')) {
                    requestUrl = requestUrl.substring(1);
                }

                if (isLoginRequest(requestUrl) || $state.current.name === ''
                    || $state.current.name === localWeb.getLoginState()
                    || $state.current.name === 'home') {
                    // is login request we don't care, or if current name is nothing which indicate
                    // user has typed in the url directly
                    VbrLoggingDialogService.addDebug('401 from login or home screen, log user out');
                    Auth.logout(!localWeb.getIsAdmin());
                    return;
                }

                VbrLoggingDialogService.addDebug(
                    '401 detected, fromState current name: '
                    + $state.current.name + ', url='
                    + $state.current.url);
                sessionHasExpired();
            });

            $rootScope.$on(SUCCESS, function (event, responseData) {
                processResponseData(responseData);
            });

            $rootScope.$on(LOGOUT, function () {
                VbrLoggingDialogService.addDebug('i detected logout');
                logoutOccurred();
            });

        }

        /**
         * process response data for success network calls to see if it should affect this session
         * service
         * @param resp
         */
        function processResponseData(resp) {
            var requestUrl = resp.config.url;

            if (requestUrl.startsWith('/')) {
                requestUrl = requestUrl.substring(1);
            }

            // there is a v minor issue with this algorithm, even the non-authenticated api request
            // would still refresh the token on server, but we are ignoring them here, but including
            // them would cause a bigger issue where non-authenticated api will always return 200
            // even when session has already ended
            if (isUnauthorizedRequest(requestUrl)) {
                return;
            }

            var sessionTimeout = null;
            if (SSOService.isEnable()) {
                sessionTimeout = window.keycloakAuth.tokenParsed.exp * 1000;
            } else if (isLoginRequest(requestUrl) || isKeepAliveRequest(requestUrl)) {
                sessionTimeout = resp.data.sessionTimeout;
            }
            sessionRefreshed(sessionTimeout);
        }

        /**
         * check if the url is a request that doesn't need authorization
         * @param url - url of the request
         * @returns {boolean} true if the request doesn't need authorization, false otherwise
         */
        function isUnauthorizedRequest(url) {
            if (url == null) {
                return true;
            }

            var indexFound = apis.unauthorizedApiList.findIndex(function (api) {
                return url.startsWith(api);
            });

            return indexFound >= 0;
        }


        function isLoginRequest(url) {
            if (url == null) {
                return true;
            }

            return url.startsWith(apis.loginApi);
        }

        function isKeepAliveRequest(url) {
            if (url == null) {
                return true;
            }

            return url.startsWith(apis.keepAliveApi);
        }

        /************************************************************
         * service initialization and return section
         ************************************************************/

        registerListeners();

        return {
            // share the constants
            cs: cs,
            getRemainingTime: getRemainingTime,
            isSessionAlive: isSessionAlive,
            sessionRefreshed: sessionRefreshed,
        };
    });
})();
