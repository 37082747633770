'use strict';

(function () {

    angular.module('acadiamasterApp').directive('reuseExpressionConditionConfig', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/reuseExpressionCondition.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.expressionOptions = {
                    expressionIdsToAvoid: [$scope.condition.getParentExpressionCondition().id],
                    programId: $scope.condition.getRoot().programId,
                    showSelectedExpressionDetails: true,
                    getExpressionCondition : function() {
                        return $scope.condition.getExpressionCondition();
                    },
                    setExpressionCondition : function(exp) {
                        $scope.condition.setExpressionCondition(exp);
                    }
                };
            }
        };
    });
})();