/* eslint-disable */

/**
 * Created by Jamie Nola on 03/19/2018
 *
 * For instructions, see tiles.constants.js
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        /**
         * tiles directive
         */
        .directive('tiles', function (CountService, drcService, CONFIG, Principal,
            $state, TILE_CONSTANTS, PairingService, FeaturesService, $cookies) {
            return {
                templateUrl: "admin-templates/site/components/tiles/tiles.template.html",
                scope: {
                    tileCollectionId: '@',
                    tileRouteParams: '=?',
                    tileCustomCountsFunction: '=?',
                    tileView: '@?'
                },
                compile: function (element, attrs) {
                    // scope defaults
                    if (!attrs.tileView) {
                        attrs.tileView = TILE_CONSTANTS.VIEW.GRID;
                    }
                },
                controller: function ($scope) {
                    $scope.data = {
                        counts: [],
                        drcInitialized: false,
                        hideHeader: false,
                        isReady: false,
                        totalCountsId: '',
                        view: TILE_CONSTANTS.VIEW
                    };

                    loadUser($scope);
                }
            };

            /* Adds requirement to tile */
            function addRequirement(tile, requirement) {
                if (tile.requirements.indexOf(requirement) === -1) {
                    tile.requirements.push(requirement);
                    tile.enabled = false;
                }
            }

            /* Removes requirement from tile and enables tile if possible */
            function removeRequirement(tile, requirement) {
                var index = tile.requirements.indexOf(requirement);
                if (index !== -1) {
                    tile.requirements.splice(index, 1);
                    if (tile.requirements.length === 0) {
                        tile.enabled = true;
                    }
                }
            }

            function getIsDataMigrationDisable(features) {
                var isDisable = false;
                features.forEach(function (feature) {
                    if (!feature.enabled && feature.name == TILE_CONSTANTS.ID.DATA_MIGRATION) {
                        isDisable = true;
                    }
                });
                return isDisable;
            }

            function removeDataMigrationFromTiles($scope) {
                $scope.tiles.forEach(function (tile, index) {
                    if (tile.id == TILE_CONSTANTS.ID.DATA_MIGRATION) {
                        $scope.tiles.splice(index, 1);
                    }
                });
            }

            /* Iterates through the collection's tile id array and builds the corresponding tiles */
            function createTiles(ids, $scope) {
                var tiles = [];
                var requiresDrc = false;
                var requireToggle = false;
                var requiresCounts = false;

                ids.forEach(function (id) {
                    var tile = TILE_CONSTANTS.TILE[id];
                    tile.id = id;
                    tile.requirements = [];
                    tile.enabled = true;

                    // Only allow tiles that the user's role can access.
                    var baseUrl = tile.routeId.split('(')[0];

                    if (baseUrl.indexOf('ehrUsers') > -1) {
                        $scope.ehrScreen = true;

                        // eslint-disable-next-line angular/window-service
                        $scope.token = $cookies.get('token');
                    }

                    var authorities = $state.get(baseUrl).data.authorities;

                    if (Principal.ifAnyAuthority(authorities)) {
                        // Check requirements for feature tiles
                        if (tile.requiresFeatures) {
                            tile.requiresFeatures.forEach(function (feature) {
                                if (!CONFIG.featureToggles || !CONFIG.featureToggles[feature]) {
                                    addRequirement(tile, TILE_CONSTANTS.REQUIREMENT.FEATURE + '_' + feature);
                                }
                            });
                        }

                        // check for requireToggle of Data Migration
                        if (tile.requireToggle) {
                            requireToggle = true;
                        }

                        // Tile requires configuration server
                        if (tile.requiresConfigurationServer && !CONFIG.configurationServer) {
                            addRequirement(tile, TILE_CONSTANTS.REQUIREMENT.CONFIGURATION_SERVER);
                        }

                        // Tile requires read only server
                        if (tile.requiresReadOnlyServer && !CONFIG.readOnlyServer) {
                            addRequirement(tile, TILE_CONSTANTS.REQUIREMENT.READ_ONLY_SERVER);
                        }

                        // tile requires either read only server or configuration server
                        if (tile.requiresConfigurationOrReadOnlyServer && !CONFIG.readOnlyServer && !CONFIG.configurationServer) {
                            addRequirement(tile, TILE_CONSTANTS.REQUIREMENT.READ_ONLY_OR_CONFIGURATION_SERVER);
                        }

                        // only check DRC if needed
                        if (tile.requiresDrc) {
                            requiresDrc = true;
                            addRequirement(tile, TILE_CONSTANTS.REQUIREMENT.DRC);
                        }

                        if (tile.countId && $scope.tileView === $scope.data.view.GRID) {
                            requiresCounts = true;
                        }

                        tiles.push(tile);
                    }
                });

                // check DRC if a tile exists that needs DRC AND the tile is available to the user role
                if (requiresDrc) {
                    verifyDrcInitialization($scope);
                }
                // check for requireToggle of Data Migration
                if (requireToggle) {
                    checkDataMigration($scope);
                }

                // if counts are needed, hit the counts API
                if (requiresCounts) {
                    loadCounts($scope);
                }

                return tiles;
            }

            /* Sorts out various API calls for tile counts. */
            function loadCounts($scope) {
                var callFunction;
                var totalCountsId = 'totalCount';

                switch ($scope.tileCollectionId) {
                    case TILE_CONSTANTS.ID.ACCESS_CONTROL:
                        callFunction = CountService.getAccessControlCounts;
                        break;
                    case TILE_CONSTANTS.ID.DATA_TYPE:
                    callFunction = CountService.getDataTypeCounts;
                        totalCountsId = 'dataTypeMasterTotalCount';
                        break;
                    case TILE_CONSTANTS.ID.FORM:
                        callFunction = CountService.getFormCounts;
                        break;
                    case TILE_CONSTANTS.ID.PROGRAM:
                        callFunction = CountService.getProgramCounts;
                        break;
                    case TILE_CONSTANTS.ID.RULE:
                        callFunction = CountService.getRuleCounts;
                        break;
                    case TILE_CONSTANTS.ID.UNIT:
                        callFunction = CountService.getUnitCounts;
                        break;
                    case TILE_CONSTANTS.ID.PROGRAM_MANAGE:
                        callFunction = $scope.tileCustomCountsFunction;
                        break;
                    case TILE_CONSTANTS.ID.PROGRAM_TEST:
                        callFunction = $scope.getProgramTestCounts;
                        break;
                    case TILE_CONSTANTS.ID.DRC_PARTICIPANT_PAIRING_MANAGEMENT:
                        callFunction = PairingService.historyCount;
                        break;

                    default:
                        callFunction = CountService.getAllCounts;
                }

                callFunction()
                    .then(function (response) {
                        $scope.data.counts = response.data;
                        $scope.data.isReady = true;
                        $scope.data.totalCountsId = totalCountsId;
                });
            }

            /* Checks to see if DRC has been initialized. If not, hides tiles that need DRC. */
            function verifyDrcInitialization($scope) {
                drcService.checkDrcInitialized()
                    .then(function (response) {
                        $scope.data.drcInitialized = response.data;
                        if ($scope.tiles) {
                            $scope.tiles.forEach(function (tile) {
                                if (tile.requiresDrc && response.data) {
                                    removeRequirement(tile, TILE_CONSTANTS.REQUIREMENT.DRC);
                                }
                            });
                        }
                    });
            }
            // check for Data Migration is enable/disable in feature list
            function checkDataMigration($scope) {
                FeaturesService.get().then(
                    function success(response) {
                        $scope.features = response.data;
                        var isDataMigrationDisable = getIsDataMigrationDisable($scope.features);
                        if (isDataMigrationDisable) {
                            removeDataMigrationFromTiles($scope);
                        }
                    }, function onError(error) {
                        console.error(error);
                        $scope.error = true;
                    }
                ).finally(function () {
                    //Nothing to do
                });
            }

            /* Grabs user data to check ROLE permissions */
            function loadUser($scope) {
                Principal.identity()
                    .then(function (account) {
                        var collection = TILE_CONSTANTS.COLLECTION[$scope.tileCollectionId];
                        $scope.data.hideHeader = collection.hideHeader;
                        $scope.tiles = createTiles(collection.tiles, $scope);
                    },
                        function (err) {
                            console.error(err);
                        });
            }
        });

})();

/* eslint-enable */
