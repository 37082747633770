/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    angular.module('acadiamasterApp')

        .directive('localizedTextInput', (LocaleConstantsService, vbrCommonUtil, LocalizationService, $document) => {
            /*
             *
             * @param $scope
             */
            function init ($scope) {
                // Create list of locale object
                $scope.data = { isPopupOpen : false };
                $scope.availableLocale = [];

                for (let prop in LocaleConstantsService.locale) {
                    // your code
                    let object = LocaleConstantsService.locale[prop];
                    $scope.availableLocale.push(object);
                }

                if ($scope.localizationMap != null) {
                    initUIList($scope);
                }

                if ($scope.rows === undefined) {
                    $scope.rows = 2;
                }
            }

            /*
             *
             * @param $scope
             */
            function initUIList ($scope) {
                $scope.list = [];
                for (let key in $scope.localizationMap) {
                    let object = $scope.localizationMap[key];
                    let locale = vbrCommonUtil.getObjectByName(LocaleConstantsService.locale, key);
                    let text = object[$scope.fieldKey];

                    // Add in UI list
                    LocalizationService.addLocaleObject($scope.list, locale, text, null);

                    // Remove added locale from available list
                    updateAvailableLocaleList($scope, locale, null, false);
                }
            }

            function addWatchOnList ($scope) {
                $scope.$watch('list', (newValue, oldValue) => {
                    if ($scope.localizationMap == null) {
                        $scope.localizationMap = {};
                    }

                    // Process old list
                    _.forEach(oldValue, oldObj => {
                        if (oldObj != null && oldObj.selectedLocale != null) {
                            // Set old locale value to null
                            LocalizationService.addValueToMap($scope.localizationMap, oldObj.selectedLocale.name, $scope.fieldKey, null);
                        }
                    });

                    // Process new value
                    _.forEach(newValue, newObj => {
                        if (newObj != null && newObj.selectedLocale != null
                            && newObj.localizedText != null && ($scope.inputType !== $scope.lcs.inputType.NUMBER && newObj.localizedText.length > 0 || $scope.inputType === $scope.lcs.inputType.NUMBER)) {
                            // Add new value to map
                            LocalizationService.addValueToMap($scope.localizationMap, newObj.selectedLocale.name, $scope.fieldKey, newObj.localizedText);
                        }
                    });
                }, true);
            }

            function addWatchOnLocalizationMap ($scope) {
                $scope.$watch('localizationMap', (newValue, oldValue) => {
                    initUIList($scope);
                });
            }

            function updateAvailableLocaleList ($scope, locale, localeIndex, isAddLocale) {
                if ($scope.availableLocale == null) {
                    return;
                }
                if (isAddLocale) {
                    // Add locale to available list
                    $scope.availableLocale.push(locale);
                } else {
                    // Remove locale from available list
                    let index = localeIndex;
                    if (localeIndex == null && locale != null) {
                        index = _.findIndex($scope.availableLocale, { name : locale.name });
                    }
                    if (index !== -1) {
                        $scope.availableLocale.splice(index, 1);
                    }
                }
            }

            return {
                link : function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    init($scope);
                    // Watch localizedText and modify value in localizationMap
                    addWatchOnList($scope);
                    addWatchOnLocalizationMap($scope);
                    $scope.addLocaleObject = function (locale, localeIndex) {
                        LocalizationService.addLocaleObject($scope.list, locale, null, null);

                        // Remove added locale from available list
                        updateAvailableLocaleList($scope, locale, localeIndex, false);
                    };
                    $scope.removeLocaleObject = function (index, locale) {
                        LocalizationService.removeLocaleObject($scope.list, index);

                        // Add removed locale in available list
                        updateAvailableLocaleList($scope, locale, null, true);
                    };
                    $scope.getSupportedLangCount = function () {
                        return LocalizationService.getSupportedLangCount();
                    };

                    $scope.localeChanged = function (localeObj) {
                        _.forEach($scope.list, obj => {
                            if (obj.selectedLocale == localeObj.selectedLocale) {
                                obj.localizedText = localeObj.localizedText;
                                return;
                            }
                        });
                    };

                    $scope.closePopup = function () {
                        $scope.data.isPopupOpen = false;
                    };

                    $scope.onPopoverOpened = function ($event) {
                        $document[0].body.click();
                        $event.stopPropagation();
                        $scope.data.isPopupOpen = true;

                        if (LocalizationService.getSupportedLangCount() == 1) {
                            $scope.addLocaleObject($scope.availableLocale[0], 0);
                        }
                    };
                },
                restrict : 'E',
                scope    : {
                    disabled        : '=',
                    fieldKey        : '@',
                    inputType       : '=',
                    localizationMap : '=',
                    maxLength       : '@?',
                    minLength       : '=',
                    rows            : '<?',
                },
                templateUrl : 'admin-templates/util/localization/textInput.html',
            };
        });
}());
