

angular.module('vibrent-external-survey-parser').service(
    'QualtricsSurveyQuestionParser',
    (
        VibrentFormStructureConstantService,
        CommonParserUtilService,
        QualtricsParserUtilService,
        QualtricSkippingLogicService,
        AlertService
    ) => {
        const constantService = VibrentFormStructureConstantService;
        const commonParser = CommonParserUtilService;
        const qualtricsParser = QualtricsParserUtilService;
        const qualtricSkipLogicParser = QualtricSkippingLogicService;
        let isSkipLogicIncluded = false;
        /**
         * Store skip logic flag
         * @param flag true if user select skip logic.
         */
        function setSkipLogic(flag){
            isSkipLogicIncluded = flag;
        }
        /**
         * This is method to parse text label Qualtrics field and return RICH_TEXT_LABEL field on our side
         * @param surveyQuestion
         * @param localIdGenerator
         * @returns form field dto for rich text label field type
         */
        function parseTextGraphicQuestion(surveyQuestion, localIdGenerator) {
            const payload = surveyQuestion.Payload;
            const field = constantService.initFormFieldRichTextLabel(localIdGenerator);
            field.fieldValue.value = commonParser.removeCssFontTagsIfRequired(commonParser.getValueFromObject(payload, 'QuestionText'));

            // parse Spanish question type
            qualtricsParser.addSpanishText(payload, field.fieldValue.localizationMap, 'QuestionText', 'value');

            return [field];
        }

        function getChoices(surveyQuestion, questionIdToQuestionMap) {
            const payload = surveyQuestion.Payload;
            let choices = commonParser.getValueFromObject(payload, 'Choices');

            if (Array.isArray(choices) && choices.length === 0) {
                //  No choices found, so see if this is a dynamic choice list instead...
                const dynamicChoices = commonParser.getValueFromObject(payload, 'DynamicChoices');
                const locator = commonParser.getValueFromObject(dynamicChoices, 'Locator');
                const choiceQuestionIdArray = locator.match(/QID[0-9]+/);

                if (Array.isArray(choiceQuestionIdArray) && choiceQuestionIdArray.length) {
                    const choiceQuestionId = choiceQuestionIdArray[0];
                    const dynamicChoicesQuestion = commonParser.getValueFromObject(questionIdToQuestionMap, choiceQuestionId);
                    if (dynamicChoicesQuestion) {
                        const dynamicChoicesQuestionPayload = dynamicChoicesQuestion.Payload;
                        if (dynamicChoicesQuestionPayload) {
                            choices = commonParser.getValueFromObject(dynamicChoicesQuestionPayload, 'Choices');
                        }
                    }
                }
            }
            return choices;
        }

        function parseMatrixComponent(componentDto,surveyQuestion, questionIdToQuestionMap){
            const payload = surveyQuestion.Payload;
            const questionText = commonParser.getValueFromObject(payload, 'QuestionText');
            const selector = commonParser.getValueFromObject(payload, 'Selector');
            const subSelector = commonParser.getValueFromObject(payload, 'SubSelector');

            let choices=getChoices(surveyQuestion, questionIdToQuestionMap);
            if (choices) {
                componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.title= commonParser.removeCssFontTagsIfRequired(questionText);
                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.localizationMap, 'QuestionText', 'value');

                const answers = commonParser.getValueFromObject(payload, 'Answers');
                const spanishAnswers = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Answers');
                let options = [];
                if (answers) {
                    const answerOrder = commonParser.getValueFromObject(payload, 'AnswerOrder');
                    options = qualtricsParser.parseChoicesInExpectedOrder(answers, spanishAnswers, answerOrder);
                }

                if (options && options.length > 0) {
                    // clear existing values
                    componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values.length=0;

                    // add choices as an options
                    _.forEach(options, (o, key) => {
                        componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values.push(o);
                    });
                }
            }

            if (selector === constantService.questionSelector.LIKERT && subSelector === constantService.questionSubSelector.MULTIPLE_ANSWER) {
                    componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.singleSelect=false;
            }

            return componentDto;
        }

        /**
         * Method to parse Matrix question type and return array of RADIO SELECT fields
         *
         * @param surveyQuestion
         * @param localIdGenerator
         * @param questionIdToQuestionMap
         * @returns {null}
         */
        function parseMatrixQuestion(surveyQuestion, localIdGenerator, questionIdToQuestionMap) {
            const payload = surveyQuestion.Payload;
            const fields = [];

            let choices = commonParser.getValueFromObject(payload, 'Choices');
            let choiceOrder = commonParser.getValueFromObject(payload, 'ChoiceOrder');
            let spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Choices');

            if (Array.isArray(choices) && choices.length === 0) {
                //  No choices found, so see if this is a dynamic choice list instead...
                const dynamicChoices = commonParser.getValueFromObject(payload, 'DynamicChoices');
                const locator = commonParser.getValueFromObject(dynamicChoices, 'Locator');
                const choiceQuestionIdArray = locator.match(/QID[0-9]+/);

                if (Array.isArray(choiceQuestionIdArray) && choiceQuestionIdArray.length) {
                    const choiceQuestionId = choiceQuestionIdArray[0];
                    const dynamicChoicesQuestion = commonParser.getValueFromObject(questionIdToQuestionMap, choiceQuestionId);
                    if (dynamicChoicesQuestion) {
                        const dynamicChoicesQuestionPayload = dynamicChoicesQuestion.Payload;
                        if (dynamicChoicesQuestionPayload) {
                            choices = commonParser.getValueFromObject(dynamicChoicesQuestionPayload, 'Choices');
                            choiceOrder = commonParser.getValueFromObject(dynamicChoicesQuestionPayload, 'ChoiceOrder');
                            spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(dynamicChoicesQuestionPayload, 'Choices');
                        }
                    }
                }
            }

            if (choices) {
                // for each choice
                const sortedChoices = qualtricsParser.sortChoicesInExpectedOrder(choices, choiceOrder);

                _.forEach(sortedChoices, choice => {
                    let field = null;
                    const titleText = choice.value.Display;
                    let spanishTitleText = null;
                    if (spanishChoices && spanishChoices[choice.key]) {
                        spanishTitleText = spanishChoices[choice.key].Display;
                    }

                    field = commonParser.createMatrixQuestionField(titleText, spanishTitleText, localIdGenerator);

                    if (field) {
                        // parse validation and add to field
                        parseAndAddValidation(payload, field);
                        fields.push(field);
                    }
                });
            }

            return fields;
        }

        /**
         * This is method to parse Multi Choice Qualtrics field and map it to MULTI_SELECTOR on our side
         *
         * todo: Qualtrics multi select supports the embedded text input which is not parsed in this parser,
         *
         * @param surveyQuestion
         * @param localIdGenerator
         * @returns form field dto for multi selector field type
         */
        function parseMultipleChoiceQuestion(surveyQuestion, localIdGenerator) {
            const fields = [];
            const payload = surveyQuestion.Payload;

            // get selector from the payload
            const selector = commonParser.getValueFromObject(payload, 'Selector');
            let questionID = commonParser.getValueFromObject(surveyQuestion, 'PrimaryAttribute');
            commonParser.logMessage(`===MC selector type==  ${selector} on question id: ${questionID}`);
            let field = null;
            if (selector === constantService.questionSelector.DROPDOWN_LIST) {
                field = createDropdownFieldForMultipleChoiceType(payload, localIdGenerator);
            } else if (selector === constantService.questionSelector.SINGLE_ANSWER_HORIZONTAL) {
                field = createRadioOptionalFieldForMultipleChoiceType(payload, localIdGenerator);
            } else {
                field = createMultiSelectFieldForMultipleChoiceType(payload, localIdGenerator);
            }

            // parse validation and add to field
            parseAndAddValidation(payload, field);

            // hide title subfield and add rich text label field before selector field to show as title
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fields, field);

            let addEmbeddedTexEntry = (embeddedLocaleId, localIdGenerator, fields) => {
                const embeddedField = constantService.initEmbeddedTextEntry(embeddedLocaleId, localIdGenerator);
                console.log(`embeddedField ${JSON.stringify(embeddedField)}`);
                fields.push(embeddedField);

            };
            //check field to see if we have embeddedLocalId. if yes, create new one and add to fieldComponentArray
            //AC-100431
            const optionValues = field.subFields[1].fieldValue.values;
            _.forEach(optionValues, option => {
                let embeddedLocaleId = _.get(option, 'embeddedTextInputLocalId');
                console.log(`embeddedField id: ${embeddedLocaleId}`);
                if (embeddedLocaleId !== undefined) {
                    addEmbeddedTexEntry(embeddedLocaleId, localIdGenerator, fields);
                }
            });
            //--end of checking
            return fields;
        }


        /**
         * Method to create Dropdown field for multiple choice question type with 'DL' as selelctor
         * @param payload
         * @param localIdGenerator
         */
        function createDropdownFieldForMultipleChoiceType(payload, localIdGenerator) {
            const field = constantService.initFormFieldDropdown(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.getValueFromObject(payload, 'QuestionText');
                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, titleSubField.fieldValue.localizationMap, 'QuestionText', 'value');
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            const choices = commonParser.getValueFromObject(payload, 'Choices');
            const spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Choices');
            if (optionsSubField && choices) {
                // clear existing values
                optionsSubField.fieldValue.values.length = 0;

                // parse options
                const choiceOrder = commonParser.getValueFromObject(payload, 'ChoiceOrder');
                optionsSubField.fieldValue.values = qualtricsParser.parseChoicesInExpectedOrder(choices, spanishChoices, choiceOrder);

                optionsSubField.fieldValue.placeholder = '';
            }
            return field;
        }
        /**
         * Create Radio field for multiple choice question type
         * @param payload
         * @param localIdGenerator
         */
        function createRadioOptionalFieldForMultipleChoiceType(payload, localIdGenerator) {
            // get radio selector template
            const field = constantService.initFormFieldRadioSelector(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.removeHtmlTagsIfRequired(commonParser.getValueFromObject(payload, 'QuestionText'));
                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, titleSubField.fieldValue.localizationMap, 'QuestionText', 'value');
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            const choices = commonParser.getValueFromObject(payload, 'Choices');
            const spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Choices');
            if (optionsSubField && choices) {
                // clear existing values
                optionsSubField.fieldValue.values.length = 0;
                // parse options
                const choiceOrder = commonParser.getValueFromObject(payload, 'ChoiceOrder');
                optionsSubField.fieldValue.values = qualtricsParser.parseChoicesInExpectedOrder(choices, spanishChoices, choiceOrder);
                optionsSubField.fieldValue.singleLine = true;
                optionsSubField.fieldValue.imagePreset = 'CIRCULAR_BLUE';

            }
            return field;
        }

        /**
         * Create multiselect field for multiple choice question type
         * @param payload
         * @param localIdGenerator
         */
        function createMultiSelectFieldForMultipleChoiceType(payload, localIdGenerator) {
            // get multi selector template
            const field = constantService.initFormFieldMultiSelector(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.removeHtmlTagsIfRequired(commonParser.getValueFromObject(payload, 'QuestionText'));
                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, titleSubField.fieldValue.localizationMap, 'QuestionText', 'value');
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            const choices = commonParser.getValueFromObject(payload, 'Choices');
            const spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Choices');
            if (optionsSubField && choices) {
                // clear existing values
                optionsSubField.fieldValue.values.length = 0;

                // parse options
                const choiceOrder = commonParser.getValueFromObject(payload, 'ChoiceOrder');
                optionsSubField.fieldValue.values = qualtricsParser.parseChoicesInExpectedOrder(choices, spanishChoices, choiceOrder, localIdGenerator);

                // check if single select or multi select
                const selector = commonParser.getValueFromObject(payload, 'Selector');
                if (selector === constantService.questionSelector.MULTIPLE_ANSWER_HORIZONTAL
                    || selector === constantService.questionSelector.MULTIPLE_ANSWER_VERTICAL) {
                    optionsSubField.fieldValue.singleLine = false;
                    optionsSubField.fieldValue.imagePreset = 'CHECK_BOX_BLUE';
                } else {
                    optionsSubField.fieldValue.singleLine = true;
                    optionsSubField.fieldValue.imagePreset = 'CIRCULAR_BLUE';
                }
            }
            return field;
        }

        /**
         * Creates a single line text field of the appropriate type for the input.
         *
         * Supported specific types are:
         *  - us phone number
         *  - ca phone number
         *  - date (will always be mm/dd/yyyy regardless of Qualtrics format)
         *  - number
         *
         *  Any other Qualtrics single line field will be created as a simple VRP single line input.
         * @param payload - the payload object extracted from the Qualtrics item
         * @param localIdGenerator  - id generator
         * @returns single line text input
         */
        function createSingleLineTextField(payload, localIdGenerator) {
            let singleLineTextField;
            const validation = commonParser.getValueFromObject(payload, 'Validation');
            const fieldSettings =  commonParser.getValueFromObject(validation, 'Settings');
            switch (commonParser.getValueFromObject(fieldSettings, 'ContentType')) {
            case constantService.singleLineContentTypes.VALID_PHONE:
                switch (commonParser.getValueFromObject(fieldSettings, 'ValidPhoneType')) {
                case constantService.phoneTypes.VALID_US_PHONE:
                case constantService.phoneTypes.VALID_CA_PHONE:
                    singleLineTextField = constantService.initFormFieldPhoneNumber(localIdGenerator);
                    break;
                default:
                    singleLineTextField = constantService.initFormFieldTextInput(localIdGenerator);
                }
                break;
            case constantService.singleLineContentTypes.VALID_DATE:
                singleLineTextField = constantService.initFormFieldDayPicker(localIdGenerator);
                break;
            case constantService.singleLineContentTypes.VALID_NUMBER:
                singleLineTextField = constantService.initFormFieldNumberInput(localIdGenerator);
                const validNumber = commonParser.getValueFromObject(fieldSettings, 'ValidNumber');
                const min = commonParser.getValueFromObject(validNumber, 'Min');
                const max = commonParser.getValueFromObject(validNumber, 'Max');
                if (min) {
                    const englishMessage = `Please enter value greater or equal to ${ min}`;
                    const spanishMessage = `Por favor ingrese un valor mayor o igual a ${ min}`;
                    const validationDto = constantService.initValidationValueLanguage('MIN', englishMessage, spanishMessage, min);
                    singleLineTextField.validationRules.push(validationDto);
                }
                if (max) {
                    const englishMessage = `Please enter value less or equal to ${ max}`;
                    const spanishMessage = `Por favor ingrese un valor menor o igual a ${ max}`;
                    const validationDto = constantService.initValidationValueLanguage('MAX', englishMessage, spanishMessage, max);
                    singleLineTextField.validationRules.push(validationDto);
                }
                break;
            default:
                singleLineTextField = constantService.initFormFieldTextInput(localIdGenerator);
            }
            return singleLineTextField;
        }

        /**
         * Method to parse text entry questions type and return TEXT_INPUT form field
         * @param surveyQuestion
         * @param localIdGenerator
         * @returns form field with type TEXT_INPUT
         */
        function parseTextEntryQuestion(surveyQuestion, localIdGenerator) {
            const payload = surveyQuestion.Payload;
            const fields = [];

            // create text label field if required
            const questionText = commonParser.getValueFromObject(payload, 'QuestionText');
            if (questionText) {
                const textLabelField = constantService.initFormFieldRichTextLabel(localIdGenerator);
                textLabelField.fieldValue.value = commonParser.removeCssFontTagsIfRequired(questionText);

                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, textLabelField.fieldValue.localizationMap, 'QuestionText', 'value');

                fields.push(textLabelField);
            }

            const selector = payload.Selector;
            switch (selector) {
            case constantService.questionSelector.SINGLE_LINE:
                const singleLineTextField = createSingleLineTextField(payload, localIdGenerator);
                // parse validation and add to field
                parseAndAddValidation(payload, singleLineTextField);
                fields.push(singleLineTextField);
                break;
            case constantService.questionSelector.MULTIPLE_LINES:
                const multiLineTextField = constantService.initFormFieldTextInput(localIdGenerator);
                multiLineTextField.fieldValue.numberOfLines = 3;

                // parse validation and add to field
                parseAndAddValidation(payload, multiLineTextField);

                fields.push(multiLineTextField);
                break;
            case constantService.questionSelector.FORM:
                const choices = commonParser.getValueFromObject(payload, 'Choices');
                const spanishChoices = qualtricsParser.getSpanishObjectFromQualtricsLanguage(payload, 'Choices');
                if (choices) {
                    const choiceOrder = commonParser.getValueFromObject(payload, 'ChoiceOrder');
                    _.forEach(choiceOrder, (orderValue, orderKey) => {
                        _.forEach(choices, (choiceValue, choiceKey) => {
                            if (orderValue == choiceKey) {
                                const label = constantService.initFormFieldTextLabel(localIdGenerator, true);
                                label.fieldValue.value = commonParser.removeHtmlTagsIfRequired(commonParser.getValueFromObject(choiceValue, 'Display'));
                                if (spanishChoices && spanishChoices[choiceKey]) {
                                    commonParser.addSpanishValueToMap(label.fieldValue.localizationMap, 'value', spanishChoices[choiceKey].Display);
                                }
                                fields.push(label);

                                const inputTextField = constantService.initFormFieldTextInput(localIdGenerator);
                                //AC-98776: handle displayLogic under choice section instead of payload
                                let choiceDisplayLogic = commonParser.getValueFromObject(choiceValue,'DisplayLogic');
                                if(choiceDisplayLogic != null && isSkipLogicIncluded){
                                    let vibrentLogic = qualtricSkipLogicParser.transformLogic(choiceDisplayLogic);
                                    let name = commonParser.getValueFromObject(surveyQuestion.Payload, 'DataExportTag');
                                    console.log(`Detect Choice DisplayLogic in question ${name}`);
                                    label.displayConfig.advancedVisibilityConfig = {conditionText : `${vibrentLogic}`};
                                    inputTextField.displayConfig.advancedVisibilityConfig = {conditionText : `${vibrentLogic}`};
                                }
                                // parse validation and add to field
                                parseAndAddValidation(payload, inputTextField);

                                fields.push(inputTextField);
                            }
                        });
                    });
                }
                break;
            default:
                AlertService.warning(`Invalid selector ${selector} found for question ${payload.QuestionText} - importing as single line text.`);

                const unknownTextField = constantService.initFormFieldTextInput(localIdGenerator);
                // parse validation and add to field

                parseAndAddValidation(payload, unknownTextField);
                fields.push(unknownTextField);
                break;
            }

            return fields;
        }

        /**
         * Method to parse slider question to numeric slider
         * @param surveyQuestion
         * @param localIdGenerator
         * @returns {[]}
         */
        function parseSliderQuestion(surveyQuestion, localIdGenerator) {
            const payload = surveyQuestion.Payload;
            const fields = [];

            const selector = commonParser.getValueFromObject(payload, 'Selector');

            const questionText = commonParser.getValueFromObject(payload, 'QuestionText');

            const choices = commonParser.getValueFromObject(payload, 'Choices');
            if (choices) {
                // parse answers to create multi select options
                const configuration = commonParser.getValueFromObject(payload, 'Configuration');
                const sliderOptions = {};
                if (configuration) {
                    sliderOptions.minValue = commonParser.getValueFromObject(configuration, 'CSSliderMin');
                    sliderOptions.maxValue = commonParser.getValueFromObject(configuration, 'CSSliderMax');

                    // get number of grid lines
                    let numOfGrids = commonParser.getValueFromObject(configuration, 'GridLines');
                    numOfGrids = numOfGrids || 1;
                    // make sure at least one grid line is there
                    let totalElements = sliderOptions.maxValue - sliderOptions.minValue;

                    if (sliderOptions.minValue < 0 && sliderOptions.maxValue > 0) {
                        // if slider is from negative number to positive number then increment total count to include 0 as value
                        totalElements += 1;
                    }
                    sliderOptions.displayIncrementalValue = Math.round(totalElements / numOfGrids);
                }

                // for each choice
                _.forEach(choices, choice => {
                    const field = createSliderFieldForSliderChoice(payload, selector, questionText, choice.Display, sliderOptions, localIdGenerator);

                    // parse validation and add to field
                    parseAndAddValidation(payload, field);

                    // hide title subfield and add rich text label field before selector field to show as title
                    commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fields, field);
                });

                return fields;
            }
        }

        function createSliderFieldForSliderChoice(payload, selector, questionText, choice, sliderOptions, localIdGenerator) {
            // create multi select field with single select option
            const field = constantService.initFormFieldSlider(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = `${questionText}\n\n${choice}`;

                // parse Spanish question type
                qualtricsParser.addSpanishText(payload, titleSubField.fieldValue.localizationMap, 'QuestionText', 'value');
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            optionsSubField.fieldValue.minValue = sliderOptions.minValue;
            optionsSubField.fieldValue.maxValue = sliderOptions.maxValue;
            optionsSubField.fieldValue.displayIncrementalValue = sliderOptions.displayIncrementalValue;


            // return multi select field
            return field;
        }

        /**
         * Method to parse validation and add it to specified field
         *
         * @param payload - payload
         * @param field - field
         */
        function parseAndAddValidation(payload, field) {
            if (payload == null || field == null) {
                return;
            }
            const validation = commonParser.getValueFromObject(payload, 'Validation');
            const settings = commonParser.getValueFromObject(validation, 'Settings');

            if (settings.ForceResponse === 'ON') {
                const validationDto = constantService.initValidationDto('REQUIRED', 'Please answer this question');
                field.validationRules.push(validationDto);
            }
        }

        /** *********************************************************** */
        /** ********************* Private Functions ******************* */
        /** *********************************************************** */


        return {
            parseTextGraphicQuestion,
            parseMatrixQuestion,
            parseMatrixComponent,
            parseMultipleChoiceQuestion,
            parseTextEntryQuestion,
            parseSliderQuestion,
            setSkipLogic,
        };
    }
);
