(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigMobileAppPushNotificationModel',
        function (RuleConstantsService, RuleActionBaseModelService) {

            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigMobileAppPushNotificationModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigMobileAppPushNotificationModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION;
                this[RuleConstantsService.classes.classReferenceKey] =
                    RuleConstantsService.classes.ActionConfigMobileAppPushNotificationModel;
                this.title = 'sample title';
                this.message = 'sample message';
                this.navigationLink = null;
                this.imageFile = null;
                this.imageFileName = null;
                this.imageFileUrl = null;
                this.imageFileSize = null;
                this.imageAspectRatio = null;
                this.localizationMap = {};
                return this;
            }

            ActionConfigMobileAppPushNotificationModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.message = actionConfig.message;
                    this.title = actionConfig.title;
                    this.navigationLink = actionConfig.navigationLink;
                    this.imageFileSize = actionConfig.imageFileSize;
                    this.imageFileName = actionConfig.imageFileName;
                    this.imageFileUrl = actionConfig.imageFileUrl;
                    this.imageAspectRatio = actionConfig.imageAspectRatio;
                    this.localizationMap = actionConfig.localizationMap;
                }
            };

            ActionConfigMobileAppPushNotificationModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.message = this.message;
                actionConfig.title = this.title;
                actionConfig.navigationLink= this.navigationLink;
                actionConfig.imageFileSize = this.imageFileSize;
                actionConfig.imageFileName = this.imageFileName;
                actionConfig.imageFileUrl = this.imageFileUrl;
                actionConfig.imageAspectRatio = this.imageAspectRatio;
                actionConfig.localizationMap = this.localizationMap;

                if (this.imageFile !== null) {
                    actionConfig.imageFileName = this.imageFile.name;
                    actionConfig.imageFileSize = this.imageFile.size;
                    files.push(this.imageFile);
                }
                return dto;
            };

            ActionConfigMobileAppPushNotificationModel.prototype.getDescriptionAsHtml = function () {
                return `Add Notification ('Mobile App Push Notification, 'Subject: ${ this.title })`;
            };

            return ActionConfigMobileAppPushNotificationModel;
        }
    );
})();
