/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for form field value for button size
     */
        .directive('formConfigFieldValueButtonSize', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/submitButton.buttonSize.html',
                scope: {
                    inputModel: '=',
                    changeModel: '='
                },
                link: function ($scope) {
                    const sizeName = {
                        'DEFAULT': 'btn-regular',
                        'LARGE': 'btn-large',
                        'SMALL': 'btn-small',
                        'EXTRA_SMALL': 'btn-mini',
                    };

                    $scope.constants = FormConstants;
                    $scope.hasChangeModel = $scope.changeModel !== undefined;

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        inputModel: $scope.inputModel,
                        changeModel: $scope.changeModel,
                        makingChange: $scope.hasChangeModel && $scope.changeModel != null
                    };

                    $scope.$watch("data.inputModel", function (newValue) {
                        $scope.inputModel = newValue;
                    });

                    $scope.$watch("data.changeModel", function (newValue) {
                        $scope.changeModel = newValue;
                    });

                    $scope.resetChange = function () {
                        $scope.data.makingChange = false;
                        $scope.data.changeModel = null;
                    };

                    $scope.startChange = function () {
                        $scope.data.makingChange = true;
                    };

                    $scope.getVbrSizeClass = function (size) {
                        return sizeName[size] || '';
                    };

                    $scope.getButtonSize = function () {
                        return $scope.changeModel == null ? $scope.inputModel : $scope.changeModel;
                    };
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
        });

})();

