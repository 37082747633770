/* eslint-disable eqeqeq */
import template from './elementConfig.action.fire.vxp.event.html';

export function getOrderData ($scope, $http, localWeb) {
    let orderTypeDetails = $http.get(localWeb.createUrl('/api/fulfillment/v1/ordertype'));
    orderTypeDetails.then(response => {
        $scope.orderTypeDetails = response.data;
    });
}

export function getProductData ($scope, $http, localWeb) {
    let productDetails = $http.get(localWeb.createUrl('/api/fulfillment/v1/products'));
    productDetails.then(response => {
        $scope.productDetails = response.data;
    });
}

(function () {
    angular.module('acadiamasterApp')
        .directive(
            'ruleElementConfigActionFireVxpEvent',
            ($http, localWeb, ExpressionConstantsService, RuleUtilService, vxpEventModelService) => {
                function init ($scope) {
                    $scope.milestoneEventDetails = {};
                    $scope.orderTypeDetails = {};
                    $scope.productDetails = {};
                }

                function getMilestoneData ($scope) {
                    let milestoneEventDetails = $http.get(localWeb.createUrl('/api/milestoneEventDetails'));
                    milestoneEventDetails.then(response => {
                        $scope.milestoneEventDetails = response.data;
                    });
                }

                return {
                    link : function ($scope) {
                        $scope.expConstants = ExpressionConstantsService;
                        $scope.data = {
                            CarrierCodeEnum  : RuleUtilService.ruleConstants.CarrierCodeEnum,
                            ProviderEnum     : RuleUtilService.ruleConstants.ProviderEnum,
                            TrackingTypeEnum : RuleUtilService.ruleConstants.TrackingTypeEnum,
                            VXPEvents        : RuleUtilService.ruleConstants.VXPEvents,
                            TrackingActionType: RuleUtilService.ruleConstants.TrackingActionType,
                        };
                        $scope.showList = true;
                        $scope.valueChanged = function () {
                            for (let i = 0; i < $scope.milestoneEventDetails.length; i++) {
                                if ($scope.action.userMilestoneEventActionDTO.eventType == $scope.milestoneEventDetails[i].eventType) {
                                    if ($scope.milestoneEventDetails[i].metadataKeysList.length == 0) {
                                        $scope.action.userMilestoneEventActionDTO.metadataKeyValueList = [];
                                        $scope.showList = false;
                                    }
                                    for (let j = 0; j < $scope.milestoneEventDetails[i].metadataKeysList.length; j++) {
                                        $scope.action.userMilestoneEventActionDTO.metadataKeyValueList[j]
                                            = new vxpEventModelService.MilestoneEventMetadataModel();
                                        $scope.action.userMilestoneEventActionDTO.metadataKeyValueList[j].keyName
                                            = $scope.milestoneEventDetails[i].metadataKeysList[j];
                                        $scope.showList = true;
                                    }
                                }
                            }
                        };

                        $scope.displayTracking = function () {
                            const trackingModel = new vxpEventModelService.FulfillmentTrackingModel();
                            $scope.action.fulfillmentRequestEventActionDTO.trackings.push(trackingModel);
                        };

                        $scope.removeTracking = function (index) {
                            $scope.action.fulfillmentRequestEventActionDTO.trackings.splice(index, 1);
                        };

                        $scope.$watch('action.vxpEventName ', () => {
                            if ($scope.action.vxpEventName === $scope.data.VXPEvents.USER_MILESTONE_EVENT) {
                                getMilestoneData($scope);
                            }
                            if ($scope.action.vxpEventName === $scope.data.VXPEvents.FULFILLMENT_REQUEST_EVENT) {
                                getOrderData($scope, $http, localWeb);
                                getProductData($scope, $http, localWeb);
                            }
                        });

                        init($scope);
                    },
                    restrict : 'E',
                    scope    : {
                        action : '=',
                    },
                    template : template,
                };
            },
        );
}());
