(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitSystemController',
            function ($scope, TILE_CONSTANTS) {
                $scope.data = {
                    tileCollectionId: TILE_CONSTANTS.ID.UNIT
                };
            });
})();
