'use strict';

angular.module('acadiamasterApp')
    .factory('notificationInterceptor', function ($q, AlertService) {
        return {
            response: function(response) {
                var alertKey = response.headers('X-acadiamasterApp-alert');
                if (angular.isString(alertKey) && !alertKey.includes('/api/programCategory/filter')) {
                    AlertService.success(alertKey, { param : response.headers('X-acadiamasterApp-params')});
                }
                return response;
            }
        };
    });
