(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for setting property value from field value
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionSetPropertyFromFieldValueModel',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, PropertyLoadUtil, FormLoadUtil, FormConstants) {

            CorrectiveActionSetPropertyFromFieldValueModel.inheritsFrom(CorrectiveActionBaseModel);

            function CorrectiveActionSetPropertyFromFieldValueModel(parent) {
                CorrectiveActionBaseModel.call(this, parent,
                    ProgramTestConstantsService.correctiveActionEnum.SET_PROPERTY_FROM_FIELD_VALUE);

                this.propertyId = null;
                this.formId = null;
                this.fieldId = null;

                // property value use get/set function for them
                this._property = null;
                this._form = null;
                this._field = null;
                this._allEligibleFields = null;
            }

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.propertyId = dto.propertyId;
                this.formId = dto.formId;
                this.fieldId = dto.fieldId;

                PropertyLoadUtil.loadProperty(this);
                FormLoadUtil.loadForm(this);
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.toDto = function () {
                var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.propertyId = this.propertyId;
                dto.formId = this.formId;
                dto.fieldId = this.fieldId;

                return dto;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.setProperty = function (property) {
                this._property = property;
                this.propertyId = property == null ? null : property.id;

                if (this._form != null) {
                    this.updateFormField();
                }

            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.getProperty = function () {
                return this._property;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.getForm = function () {
                return this._form;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form == null ? null : form.id;

                this.updateFormField();
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.getField = function () {
                return this._field;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.setField = function (field) {
                this._field = field;
                this.fieldId = field == null ? null : field.formField.id;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.updateFormField = function () {
                // find the field by id
                this._allEligibleFields = this.gatherAllEligibleFields();

                var formFields = this.getAllEligibleFields();

                var fieldId = this.fieldId;

                var formField = null;
                if (fieldId != null && formFields != null) {
                    formField = _.find(formFields, function (f) {
                        return f.formField.id == fieldId;
                    });
                }

                this.setField(formField);
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.gatherAllEligibleFields = function () {
                var eligibleFields = [];

                if (this._form == null || this._form.formVersions == null || this._form.formVersions.length == 0
                    || this._property == null) {
                    return null;
                }

                var valueType = this._property.valueType;

                var editMode = this._form.formVersions[0].editMode;

                _.forEach(editMode.pages, function (page) {
                    _.forEach(page.sections, function (section) {
                        _.forEach(section.formComponents, function (component) {
                            _.forEach(component.formComponentFields, function (field) {
                                if (field.isInputField() && fieldMatchesValueType(field, valueType)) {

                                    field.shortDescription = getDescription(field);
                                    eligibleFields.push(field);
                                }
                            });
                        });
                    });
                });

                return eligibleFields;
            };

            function getDescription(field) {
                return field.formField.id + ' | ' + (field.name ? field.name : 'no name') + ' | ' + field.type.name;
            }

            function fieldMatchesValueType(field, valueType) {

                switch (field.type.name) {
                    /****************** Text inputs *********************/
                    case FormConstants.fieldsType.TEXT_INPUT.name:
                    case FormConstants.fieldsType.EMBEDDED_TEXT_INPUT.name:
                    case FormConstants.fieldsType.PHONE_INPUT.name:
                    case FormConstants.fieldsType.TIME_PICKER.name:
                        return valueType === 'STRING';

                    /****************** Date time inputs *********************/
                    case FormConstants.fieldsType.DAY_SELECTOR.name:
                        return valueType === 'NUMBER' || valueType === 'DATETIME';

                    /****************** Number inputs *********************/
                    case FormConstants.fieldsType.NUMBER_INPUT.name:
                    case FormConstants.fieldsType.VIDEO_PLAYER.name:
                    case FormConstants.fieldsType.WHEEL.name:
                        return valueType === 'NUMBER';

                    /****************** Boolean inputs *********************/
                    case FormConstants.fieldsType.BOOLEAN_SELECTOR.name:
                        return valueType === 'BOOLEAN';

                    /*************** Inputs based on value type************/
                    case FormConstants.fieldsType.RADIO_SELECTOR.name:
                    case FormConstants.fieldsType.SLIDER.name:
                    case FormConstants.fieldsType.DROPDOWN.name:
                    case FormConstants.fieldsType.MULTI_SELECTOR.name:
                        return valueType === field.fieldValue.valueType.name;

                    default:
                        return false;
                }
            }

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.getAllEligibleFields = function () {
                return this._allEligibleFields;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var propertyMsg = 'Property (' + (this.propertyId == null ? 'no id' : this.propertyId) + ' | ' +
                    (this._property == null ? 'no name' : this._property.name) + ')';

                var valueMsg = '<span class="badge"> ' + this.fieldId + ' </span>';

                return typeMsg + 'value of ' + propertyMsg + ' --> ' + valueMsg;
            };

            CorrectiveActionSetPropertyFromFieldValueModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.propertyId == null) {
                    this.setErrorMessage('property id is required');
                    return;
                }

                if (this.formId == null) {
                    this.setErrorMessage('form id is required');
                    return;
                }

                if (this.fieldId == null) {
                    this.setErrorMessage('field id is required');
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionSetPropertyFromFieldValueModel;
        });
})();
