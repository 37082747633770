/**
 * directive for display a tree node view of one locked message with condition
 */

angular.module('acadiamasterApp').directive('treeNodeLockedMessageWithCondition', (
    ExpressionConstantsService, LocaleConstantsService) => ({
    restrict: 'E',
    scope: {
        lockedMessageWithCondition: '=',
        parent: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/treeNode/' +
        'lockMessageWithCondition.treeNode.html',
    link($scope) {
        $scope.ExpressionConstantsService = ExpressionConstantsService;
        $scope.LocaleConstantsService = LocaleConstantsService;
    },
}));
