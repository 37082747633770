import ProgramContainerConstantsService from "../../const/programContainer.constants.service";

/**
 * Created by Jason Cao on 08/27/2019
 *
 * directive for configuring a container's display config
 * note: this is a top level directive that will redirect to other directives
 */
angular.module('acadiamasterApp').directive('containerDisplayConfig', () => {

    const dcs = ProgramContainerConstantsService.displayConfigStates;

    function init($scope) {
        resetState($scope);
        setupWatch($scope);
    }

    function resetState($scope) {
        const states = $scope.getStates();
        if (states!=null && states.length>0) {
            $scope.selectState(states[0]);
        }
    }

    function setupWatch($scope) {
        // when display config is changed outside (usually due to container type change), reset the selected state
        $scope.$watch('displayConfig', function() {
            resetState($scope);
        });
    }

    return {
        restrict: 'E',
        scope: {
            displayConfig: '=',
            container: '=',
        },
        templateUrl: 'admin-templates/site/programManagement/program/' +
            'containerManagement/config/containerDisplayConfig/' +
            'containerDisplayConfig.html',
        link: function ($scope) {

            $scope.data = {
                isOpen: true,
                selectedState: null,
            };

            $scope.isOpen = () => {
                return $scope.data.isOpen;
            };

            /**
             * toggle the accordion
             */
            $scope.toggle = () => {
                $scope.data.isOpen = !$scope.data.isOpen;
            };

            $scope.getStates = () => {
                const typeObj = ProgramContainerConstantsService.findTypeByName(
                    $scope.container.containerType);
                return typeObj != null ? typeObj.displayConfigStates : null;
            };

            $scope.isStateSelected = (newState) => {
                return $scope.data.selectedState === newState;
            };

            $scope.selectState = (newState) => {
                if ($scope.data.selectedState == newState) {
                    return;
                }

                // validate the current state before leaving the tab
                $scope.validateCurrentDisplayState();

                $scope.data.selectedState = newState;

                // validate the current state before entering the current tab
                $scope.validateCurrentDisplayState();
            };

            $scope.validateCurrentDisplayState = () => {
                let displayConfigState = $scope.getDisplayConfigState($scope.data.selectedState);

                if (displayConfigState != null && _.isFunction(displayConfigState.validate)) {
                    displayConfigState.validate();
                }
            };

            $scope.getDisplayConfigState = (state) => {
                if (state==null) {
                    return null;
                }

                let displayConfig = $scope.displayConfig;

                if (state==dcs.singleItemInitialState) {
                    return displayConfig.initialState;
                }

                if (state==dcs.singleItemAfterFormCompletionState) {
                    return displayConfig.formCompletionState;
                }

                if (state==dcs.multiItemEmptyState) {
                    return displayConfig.emptyStateConfig;
                }

                if (state==dcs.multiItemAllCompletedState) {
                    return displayConfig.allCompletedStateConfig;
                }

                if (state==dcs.multiItemCompletedItemState) {
                    return displayConfig.itemCompletionStateConfig;
                }

                if (state==dcs.nonCompletedItemState || state==dcs.singleItemEmptyState) {
                    return null;
                }

                return null;
            };

            $scope.hasError = (stateObj) => {
                return stateObj!=null && _.isFunction(stateObj.hasError) && stateObj.hasError();
            };

            $scope.getErrorMessage = (stateObj) => {
                if ($scope.hasError(stateObj)) {
                    return stateObj.errorInfo.errorMessage;
                }
                return "";
            };

            $scope.getSelectState = () => {
                return $scope.data.selectedState;
            };

            // initializing
            init($scope);
        }
    };
});
