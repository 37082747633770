/**
 * Created by Ryan Berry on 10/27/22
 *
 * Directive for an input box to specify pixel height
 */

import template from './heightConfigInput.template.html';

angular.module('acadiamasterApp')
    .directive('heightConfigInput', () => {
        return {
            restrict : 'E',
            scope    : {
                disabled  : '=',
                heightMax : '<',
                heightMin : '<',
                label     : '@?',
                model     : '=',
            },
            template,
        };
    });
