/**
 * Created by moustafabaiou on 2/13/17.
 */
(function () {
    'use strict';

    angular
        .module('acadiamasterApp')
        /**
         * directive to show glyphicon list in a popup window
         */
        .directive('showGlyphiconWindow', ['$uibModal', 'vbrCommonUtil', function ($uibModal, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/maybeShared/showIconButton.html', //ONLY IN ADMIN APP
                scope: {
                    buttonClass: "@",
                    buttonLabel: "@",
                    buttonTitle: "@"
                },
                link: function ($scope) {
                    var sortedNames = _.sortBy(vbrCommonUtil.faicons.names);
                    var sortedIcons = [];
                    _.forEach(sortedNames, function (name) {
                        sortedIcons.push({name: name, icon: 'fa fa-' + name});
                    });

                    $scope.showIconWindow = function () {
                        $uibModal.open({
                            animation: true,
                            templateUrl: 'admin-templates/maybeShared/iconListing.html', //ONLY IN ADMIN APP
                            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                                $scope.icons = sortedIcons;
                                $scope.filterName = null;
                                $scope.ok = function () {
                                    $uibModalInstance.close();
                                };
                            }],
                            size: 'lg'
                        });
                    };
                }
            };

        }]);

})();

