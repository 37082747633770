/**
 * Created by pravin.gayal on 29-07-2016.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .service('LocalizationService', function (LocaleConstantsService) {
            function getSupportedLangCount() {
                return Object.keys(LocaleConstantsService.locale).length;
            }


            function addLocaleObject(list, selectedLocale, localizedText, localizedImage) {
                var obj = {
                    selectedLocale: selectedLocale,
                    localizedText: localizedText,
                    localizedImage: localizedImage
                };
                list.push(obj)
            }

            /**
             *
             * @param $scope
             * @param index
             */
            function removeLocaleObject(list, index) {
                list.splice(index, 1);
            }

            function addValueToMap(map, objKey, fieldKey, value) {
                // Get object from map
                var localeObj = map[objKey];
                if (localeObj == null) {
                    localeObj = {};
                }

                // Set field value
                if (value == null) {
                    delete localeObj[fieldKey];
                } else {
                    localeObj[fieldKey] = value;
                }
                // Set object in map
                map[objKey] = localeObj;
            }

            return {
                addLocaleObject: addLocaleObject,
                removeLocaleObject: removeLocaleObject,
                addValueToMap: addValueToMap,
                getSupportedLangCount: getSupportedLangCount
            }
        });

})();
