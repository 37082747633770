(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultAddressPageService',
        function (CompareResultUtilService, AddressVerificationSetup) {

            function compareAddressVerificationProperties(page1, page2) {
                var formPageMetadata1 = page1.formPageMetadata;
                var formPageMetadata2 = page2.formPageMetadata;
                return {
                    primaryAddressSource: CompareResultUtilService.comparePrimitives(formPageMetadata1.primaryAddressSource,
                        formPageMetadata2.primaryAddressSource),
                    secondaryAddressSource: CompareResultUtilService.comparePrimitives(formPageMetadata1.secondaryAddressSource,
                        formPageMetadata2.secondaryAddressSource),
                    displayFullNameInputFields: CompareResultUtilService.comparePrimitives(formPageMetadata1.displayFullNameInputFields,
                        formPageMetadata2.displayFullNameInputFields),
                    prepopulateFullNameInputFields: CompareResultUtilService.comparePrimitives(formPageMetadata1.prepopulateFullNameInputFields,
                        formPageMetadata2.prepopulateFullNameInputFields),
                    confirmationPageText: CompareResultUtilService.comparePrimitives(getConfirmationPageText(page1), getConfirmationPageText(page2)),
                    confirmationPageImage: CompareResultUtilService.comparePrimitives(getConfirmationPageImage(page1), getConfirmationPageImage(page2)),
                    inputPageText: CompareResultUtilService.comparePrimitives(getInputPageText(page1), getInputPageText(page2)),
                    inputPageImage: CompareResultUtilService.comparePrimitives(getInputPageImage(page1), getInputPageImage(page2)),
                    suggestedPageText: CompareResultUtilService.comparePrimitives(getSuggestedPageText(page1), getSuggestedPageText(page2)),
                    invalidPageText: CompareResultUtilService.comparePrimitives(getInvalidPageText(page1), getInvalidPageText(page2)),
                    unavailablePageText: CompareResultUtilService.comparePrimitives(getUnavailablePageText(page1), getUnavailablePageText(page2)),

                    firstNameCode: CompareResultUtilService.comparePrimitives(getFirstNameCode(page1), getFirstNameCode(page2)),
                    middleInitialCode: CompareResultUtilService.comparePrimitives(getMiddleInitialCode(page1), getMiddleInitialCode(page2)),
                    lastNameCode: CompareResultUtilService.comparePrimitives(getLastNameCode(page1), getLastNameCode(page2)),
                    addressOneCode: CompareResultUtilService.comparePrimitives(getAddressOneCode(page1), getAddressOneCode(page2)),
                    addressTwoCode: CompareResultUtilService.comparePrimitives(getAddressTwoCode(page1), getAddressTwoCode(page2)),
                    cityCode: CompareResultUtilService.comparePrimitives(getCityCode(page1), getCityCode(page2)),
                    stateCode: CompareResultUtilService.comparePrimitives(getStateCode(page1), getStateCode(page2)),
                    zipCode: CompareResultUtilService.comparePrimitives(getZipCode(page1), getZipCode(page2)),

                };
            }

            function getConfirmationPageText(page) {
                return getValue(AddressVerificationSetup.getAddressConfirmationLabelField, page);
            }

            function getConfirmationPageImage(page) {
                return getValue(AddressVerificationSetup.getAddressConfirmationImageField, page);
            }

            function getInputPageText(page) {
                return getValue(AddressVerificationSetup.getAddressInputLabelField, page);
            }

            function getInputPageImage(page) {
                return getValue(AddressVerificationSetup.getAddressInputImageField, page);
            }

            function getSuggestedPageText(page) {
                return getValue(AddressVerificationSetup.getSuggestedAddressLabelField, page);
            }

            function getInvalidPageText(page) {
                return getValue(AddressVerificationSetup.getInvalidAddressLabelField, page);
            }

            function getUnavailablePageText(page) {
                return getValue(AddressVerificationSetup.getAddressValidationUnavaialbleLabelField, page);
            }

            function getValue(func, page) {
                return func(page).fieldValue.value;
            }

            function getFirstNameCode(page) {
                return getName(AddressVerificationSetup.getAddressInputFirstNameField, page);
            }

            function getMiddleInitialCode(page) {
                return getName(AddressVerificationSetup.getAddressInputMiddleInitialField, page);
            }

            function getLastNameCode(page) {
                return getName(AddressVerificationSetup.getAddressInputLastNameField, page);
            }

            function getAddressOneCode(page) {
                return getName(AddressVerificationSetup.getAddressInputStreetOneField, page);
            }

            function getAddressTwoCode(page) {
                return getName(AddressVerificationSetup.getAddressInputStreetTwoField, page);
            }

            function getCityCode(page) {
                return getName(AddressVerificationSetup.getAddressInputCityField, page);
            }

            function getStateCode(page) {
                return getName(AddressVerificationSetup.getAddressInputStateField, page);
            }

            function getZipCode(page) {
                return getName(AddressVerificationSetup.getAddressInputZipField, page);
            }

            function getName(func, page) {
                return func(page).name;
            }

            return {
                compareAddressVerificationProperties: compareAddressVerificationProperties
            };
        });

})();

