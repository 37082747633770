import ContainerItemType from '../../../const/ContainerItemType.const';

(function () {
    'use strict';
    angular.module('acadiamasterApp')
        .directive('actionItemCreation', function (ngDialog) {

            return {
                restrict : 'E',
                scope    : {
                    options : '=',
                },
                templateUrl : 'admin-templates/site/programManagement/program/containerManagement' +
                    '/config/containerItem/util/actionItemCreation.html',
                link(scope) {
                    scope.ContainerItemType = ContainerItemType;
                    scope.data = {
                        actionItemTypes : getContainerItemActionTypes(),
                        selectedType    : null,
                    };

                    scope.hasTitle = function () {
                        return (scope.options && scope.options.title && scope.options.title.length > 0);
                    };

                    scope.getTitle = () => {
                        return scope.hasTitle() ? scope.options.title : '';
                    };

                    scope.openDialogSelectActionItems = () => {
                        ngDialog.openConfirm({
                            template   : 'selectActionItemsDialog',
                            controller : ['$scope', function ($scope) {
                                $scope.data = scope.data;
                                $scope.controls = scope.controls;
                            }],
                            className : 'ngdialog-theme-plain custom-width-large',
                        });
                    };

                    scope.controls = {
                        parent               : scope,
                        selectActionItemType : function (actionItemType) {
                            return setActionItemType(this.parent, actionItemType);
                        },
                    };
                },
            };

            /** ***********************************************************************************
             * private functions
             */

            /**
             * set container item type by the selected type
             * @param scope - parent scope
             * @param actionItemType - selected container item type (action_appointment or action_PMB)
             */
            function setActionItemType(scope, actionItemType) {
                scope.options.action = actionItemType;
                ngDialog.close(actionItemType);
                completeChange(scope.options);
            }

            /**
             * complete the change action for selection
             * @param options - options object that holds the action container item type
             * and the on change call back function
             */
            function completeChange(options) {
                if (options && options.onChangeCallBack) {
                    options.onChangeCallBack();
                }
            }

            /**
             * get a list of action container item types
             * @returns {(string)[]} - a list of action container item types
             */
            function getContainerItemActionTypes() {
                return Object.values(ContainerItemType.ACTION);
            }

        });
})();
