(function () {
    'use strict';


    /**
     * form search model, holds the basic search data, contains data for
     * 1. basic search data
     * 2. advanced search data
     * 3. search results
     */
    angular.module('acadiamasterApp').factory('FormSearchModel', function (FormSearchDisplayConfigModel,
                                                                           FormUtilService) {

        /***************************************************************
         * private functions
         **************************************************************/

        /***************************************************************
         * Form Search Models
         ***************************************************************/
        function FormSearchModel() {
            // basic flags for result selection

            // select all flag
            this.selectAll = false;

            // a map of <localId, boolean>, the boolean is to indicate whether the item is selected or not
            this.selected = {};
            this.selectionCount = 0;

            // basic search model
            this.searchString = '';
            this.searchType = null;
            this.fieldType = null;
            this.subFieldType = null;
            this.searchMode = null;

            // internal flag for showing advanced choices
            this._showAdvancedChoices = false;

            // advanced search models, not shown by default
            this.displayConfigFilter = null;

            // advanced replacement model
            this.displayConfigReplaceFilter = null;

            // replacement string
            this.replacementString = '';

            // a list of items found
            this.searchResults = [];
        }

        /**
         * event handler for select all flag when its value is changed
         */
        FormSearchModel.prototype.selectAllChanged = function () {
            var searchData = this;
            var value = searchData.selectAll;

            this.selectionCount = value ? searchData.searchResults.length : 0;

            _.forEach(searchData.searchResults, function(result) {
                searchData.selected[result.localId] = value;
            });
        };

        /**
         * event handler for a result selection change, this will
         * update the selection count accordingly
         */
        FormSearchModel.prototype.resultSelectionUpdated = function (localId) {
            var itemSelected = this.selected[localId];
            this.selectionCount += itemSelected ? 1 : -1;

            // some error handling for defensive programming
            if (this.selectionCount < 0) {
                this.selectionCount = 0;
            }
            else if (this.selectionCount > this.searchResults.length) {
                this.selectionCount = this.searchResults.length;
            }
        };

        /**
         * resetting the results
         */
        FormSearchModel.prototype.resetResults = function () {
            this.selectAll = false;
            this.selected = {};
            this.searchResults = [];
            this.selectionCount = 0;
        };

        /**
         * finalize the results
         */
        FormSearchModel.prototype.finalizeResults = function () {
            var searchData = this;

            // set all item's selected flag to be false
            _.forEach(searchData.searchResults, function(result) {
                searchData.selected[result.localId] = false;
            });

            this.searchResults = FormUtilService.filterOutAddressPageItems(this.searchResults);
            this.selectionCount = 0;
        };

        /**
         * check if advanced choice enabled
         */
        FormSearchModel.prototype.isAdvancedChoiceEnabled = function () {
            return this._showAdvancedChoices;
        };

        /**
         * enable advanced choice
         */
        FormSearchModel.prototype.enableAdvancedChoice = function () {
            this._showAdvancedChoices = true;
            this.displayConfigFilter = new FormSearchDisplayConfigModel();
            this.displayConfigReplaceFilter = new FormSearchDisplayConfigModel();
        };

        /**
         * disable advanced choice
         */
        FormSearchModel.prototype.disableAdvancedChoice = function () {
            this._showAdvancedChoices = false;
            this.displayConfigFilter = null;
            this.displayConfigReplaceFilter = null;
        };

        /***************************************
         * service return call
         ***************************************/

        return FormSearchModel;
    });

})();
