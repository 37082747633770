(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('correctiveActionRemoveMultiSub', function () {
        return {
            restrict: 'E',
            template:
                '<div class="alert alert-warning">This corrective action could only be used with CHECK_MULTIPLE_SUBSCRIPTION, otherwise it will be automaticlly removed</div>',
            scope: {
                correctiveAction: '='
            },
            link: function ($scope) {
            }
        };
    });
})();
