/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form component
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultComponentService',
        function (CompareResultDisplayConfigService, CompareResultUtilService, CompareResultFieldService,vbrCommonUtil) {

            function compareComponentDetail(component1, component2) {
                var result = {
                    localId: CompareResultUtilService.comparePrimitives(component1.localId, component2.localId),
                    name: CompareResultUtilService.comparePrimitives(component1.name, component2.name),
                    displayOrder: CompareResultUtilService.comparePrimitives(component1.displayOrder, component2.displayOrder),
                    description: CompareResultUtilService.comparePrimitives(component1.description, component2.description),
                };

                result.componentType = CompareResultUtilService.comparePrimitives(component1.componentType, component2.componentType);
                if(!vbrCommonUtil.isNullOrUnavailable(component1.formComponentMetaDataDTO) &&
                    !vbrCommonUtil.isNullOrUnavailable(component2.formComponentMetaDataDTO)){
                    result.title = CompareResultUtilService.comparePrimitives(
                        component1.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.title,
                        component2.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.title
                    );
                    result.valueType = CompareResultUtilService.compareNamedObjects(
                        component1.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.valueType,
                        component2.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.valueType
                    );
                    result.singleSelect = CompareResultUtilService.comparePrimitives(
                        component1.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.singleSelect,
                        component2.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.singleSelect
                    );
                    var values = compareValueArray(
                        component1.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values,
                        component2.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values
                    );

                    for(var i=0; i< values.length ; i++){
                        result['answer'+(i+1)+'Value']= values[i].value;
                        result['answer'+(i+1)+'Text']=values[i].text;
                        result['answer'+(i+1)+'Score']=values[i].score;
                    }
                }

                result._status = CompareResultUtilService.collectStatus(result);
                return result;

            }

            function compareValueArray(fv1,fv2) {

                var answerResult = [];
                var answerLength1 = fv1 == null ? 0 : fv1.length;
                var answerLength2 = fv2 == null ? 0 : fv2.length;

                // go through answers that occur on both side
                var i;
                for (i = 0; i < answerLength1 && i < answerLength2; i++) {
                    if(!vbrCommonUtil.isNullOrUnavailable(fv1) && !vbrCommonUtil.isNullOrUnavailable(fv2)){
                        answerResult.push(fv1[i].compare(fv2[i], false));
                    }
                }

                // if first value has more answer
                for (; i < answerLength1; i++) {
                    if(!vbrCommonUtil.isNullOrUnavailable(fv1)) {
                        answerResult.push(fv1[i].compare(null, false));
                    }
                }

                // if second value has more answer
                for (; i < answerLength2; i++) {
                    if(!vbrCommonUtil.isNullOrUnavailable(fv2)){
                        answerResult.push(fv2[i].compare(null, true));
                    }
                }
                return answerResult;
            }


            function compare(component1, component2, parentTitle) {
                var title;
                if (component1 == null) {
                    component1 = {};
                    title = "Component " + component2.localId;
                }
                else {
                    title = "Component " + component1.localId;
                }

                if (parentTitle!=null){
                    title += " - " + parentTitle;
                }
                if (component2 == null) {
                    component2 = {};
                }

                var fields1 = component1.formComponentFields;
                var fields2 = component2.formComponentFields;

                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareComponentDetail(component1, component2),
                    displayConfig: CompareResultDisplayConfigService.compare(
                        component1.displayConfig, component2.displayConfig),
                    fields: CompareResultUtilService.compareArrays(fields1, fields2, title, CompareResultFieldService.compare)
                };

                result._status = CompareResultUtilService.collectStatus(result);
                return result;
            }

            return {
                compare: compare
            };
        });

})();

