/* eslint-disable prefer-regex-literals */
/* eslint-disable no-undef */
/* eslint-disable angular/no-service-method */
/* eslint-disable no-eq-null */

angular.module('vibrent-external-survey-parser')
    .service('CommonParserUtilService', (AlertService, VibrentFormStructureConstantService) => {
        const constantService = VibrentFormStructureConstantService;

        function isEmpty (value) {
            return value == null || value.length <= 0;
        }

        function isObject (object) {
            return object && typeof object === 'object';
        }

        function getValueFromObject (sourceObject, sourceKey) {
            if (!isObject(sourceObject) || isEmpty(sourceKey)) {
                return undefined;
            }
            return sourceObject[sourceKey];
        }

        function setValueIfNotNull (sourceObject, sourceKey, targetObject, targetKey) {
            const value = getValueFromObject(sourceObject, sourceKey);
            if (isEmpty(value) || targetObject == null || isEmpty(targetKey)) {
                return;
            }
            targetObject[targetKey] = value;
        }

        function setValueInObject (targetObject, targetKey, value) {
            if (targetObject == null || isEmpty(targetKey)) {
                return;
            }
            targetObject[targetKey] = value;
        }

        /*
         * Method to remove html tags from the specified text
         * @param text
         * @returns {*}
         */
        function removeHtmlTagsIfRequired (text) {
            if (text == null) {
                return '';
            }

            return text.replace(/<[^>]+>/g, '').replace(/[\u2018\u2019]/g, '\'').replace(/[\u201C\u201D]/g, '"').replace('&nbsp;', ' ');
        }

        /*
         * Method to remove font-size and font-style css tags from the specified text as well as
         * replace any smart quotes with regular quotes

         * @param text
         * @returns {*}
         */
        function removeCssFontTagsIfRequired (text) {
            if (text == null) {
                return '';
            }

            const re = new RegExp('font-family:[^;\']*(;)|font-size:[^;\']*(;)', 'g');
            return text.replace(/[\u2018\u2019]/g, '\'').replace(/[\u201C\u201D]/g, '"').replace(re, '');
        }

        function logMessage (message, showAlert, throwError) {
            if (showAlert) {
                AlertService.warning(message);
            }
            console.log(`*** ${message}`);

            if (throwError) {
                throw message;
            }
        }

        function hideSelectorTitleAndPrependRichTextLabelAsTitle (localIdGenerator, fieldArray, selectorField) {
            if (fieldArray == null || selectorField == null) {
                return;
            }

            // get title text from selector field
            const titleSubField = selectorField.subFields[0];
            if (titleSubField) {
                // check if current title has HTML tags
                if ((/<\/?[a-z][\s\S]*>/i).test(titleSubField.fieldValue.value)) {
                    // title has html tags

                    // hide the title subfield
                    titleSubField.displayConfig.visible = false;

                    const richTextTitle = constantService.initRichTextLabelForSelectorFieldTitle(localIdGenerator);
                    richTextTitle.fieldValue.value = removeCssFontTagsIfRequired(titleSubField.fieldValue.value);
                    richTextTitle.fieldValue.localizationMap = titleSubField.fieldValue.localizationMap;

                    // push rich text label as title
                    fieldArray.push(richTextTitle);

                    // Remove HTML tags from title sub-field
                    titleSubField.fieldValue.value = removeHtmlTagsIfRequired(titleSubField.fieldValue.value);
                }
            }

            // push actual field to array
            fieldArray.push(selectorField);
        }

        function addSpanishValueToMap (map, key, value) {
            if (map == null || key == null || value == null) {
                return;
            }
            if (map[constantService.vibrentLanguage.SPANISH] == null) {
                map[constantService.vibrentLanguage.SPANISH] = {};
            }
            map[constantService.vibrentLanguage.SPANISH][key] = value;
        }

        function setFieldWidth (field, width) {
            if (!field || !width) {
                return;
            }

            field.displayConfig.width.value = width;
            field.displayConfig.width.unit = '%';
        }

        function setTitleAndOptionMatrixSubSelector (field, titleText, spanishTitleText, options) {
            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = removeHtmlTagsIfRequired(titleText);
                if (spanishTitleText) {
                    addSpanishValueToMap(titleSubField.fieldValue.localizationMap, 'value', removeHtmlTagsIfRequired(spanishTitleText));
                }
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            if (options && options.length > 0) {
                // clear existing values
                optionsSubField.fieldValue.values.length = 0;

                // add choices as an options
                _.forEach(options, (o, key) => {
                    optionsSubField.fieldValue.values.push(o);
                });
            }
        }

        function createMatrixQuestionField (titleText, spanishTitleText, localIdGenerator) {
            const field = constantService.initFormFieldMatrixQuestion(localIdGenerator);

            if (field) {
                field.fieldValue.value = titleText;
                if (spanishTitleText) {
                    addSpanishValueToMap(field.fieldValue.localizationMap, 'value', spanishTitleText);
                }
            }
            return field;
        }

        return {
            addSpanishValueToMap,
            createMatrixQuestionField,
            getValueFromObject,
            hideSelectorTitleAndPrependRichTextLabelAsTitle,
            isEmpty,
            isObject,
            logMessage,
            removeCssFontTagsIfRequired,
            removeHtmlTagsIfRequired,
            setFieldWidth,
            setTitleAndOptionMatrixSubSelector,
            setValueIfNotNull,
            setValueInObject,
        };
    })

    .directive('fileModel', [ '$parse', function ($parse) {
        return {
            link : function (scope, element, attrs) {
                const model = $parse(attrs.fileModel);
                const modelSetter = model.assign;
                element.bind('change', () => {
                    scope.$apply(() => {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            },
            restrict : 'A',
        };
    } ]);
