/* eslint-disable max-len */
/* eslint-disable no-useless-concat */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 2/17/17.
 */
import _ from 'lodash';

(function () {
    // eslint-disable-next-line angular/controller-name
    angular.module('acadiamasterApp')

    /**
     * directive for form detail display
     */
        .directive('formDetailView', () => {
            return {
                controller : 'formDetailController',
                restrict   : 'E',
                scope      : {
                    form           : '=',
                    reloadFormList : '=',
                    selectForm     : '=',
                },
                templateUrl : 'admin-templates/site/forms/form/formDetail.html',
            };
        })
        .controller('formDetailController', ($scope, vbrCommonUtil, FormUtilService, ngDialog, Form, CONFIG, ruleLinkageService, FormVersion,
            FlowChartKmapModelService, $window, RenderUtil, FlowChartKmapService,
            FormPreviewService, NavigationNodeExport, ProgramLinkageService, CodeBook, drcService, localStorageService, $sce, $timeout) => {
            let incrementSize = 50;

            $scope.vbrCommonUtil = vbrCommonUtil;
            $scope.allUndeletedVersions = [];
            $scope.formVersions = [];
            $scope.deletedVersions = [];
            $scope.projections = Form.projections;
            $scope.CONFIG = CONFIG;
            $scope.associatedRules = [];
            $scope.associatedPrograms = [];
            $scope.rulesLoaded = false;
            $scope.programsLoaded = false;
            $scope.newWindow = null;
            $scope.manageFormFormVersions = [];
            $scope.filterData = { jumpTo : null, pageSizeChoices : [ 20, 50, 100 ], pageSizeSelected : 20, searchResultCount : '', searchString : '' };
            $scope.manageDeletedFormVersions = [];
            $scope.viewMoreDeletedVersions = false;
            $scope.deleteRestoreVersionAlert = null;
            $scope.page = 0; $scope.sort = { activeColumn : '', status : { asc : true } }; $scope.hasDRC = $scope.form.externalTargetMappings.includes('DRC');

            $scope.onMouseEnter = function (formVersion) {
                formVersion.shouldShowMenuIcon = true;
            };

            $scope.onMouseLeave = function (formVersion) {
                formVersion.shouldShowMenuIcon = false;
                formVersion.shouldShowMenu = false;
            };

            $scope.activateFormVersion = function (formId, formVersionId) {
                ngDialog.openConfirm({
                    controller : 'FormVersionActivateController',
                    data       : {
                        formId        : formId,
                        formVersionId : formVersionId,
                    },
                    template : 'admin-templates/site/forms/form/formVersionActivate.html',
                }).then(() => {
                    reloadFormManager();
                });
            };

            $scope.preview = async function (form, versionId) {
                await FormPreviewService.previewTab(form.id, versionId);
            };

            $scope.download = function (form, versionId, formProjection) {
                FormUtilService.download(form, versionId, formProjection);
            };

            $scope.loadMoreVersions = function () {
                let currentSize = $scope.formVersions.length;
                let finalSize = currentSize + incrementSize;
                let totalSize = $scope.allUndeletedVersions.length;
                finalSize = Math.min(finalSize, totalSize);

                for (let i = currentSize; i < finalSize; i++) {
                    $scope.formVersions.push($scope.allUndeletedVersions[i]);
                }

                $scope.manageFormFormVersions = getManageFormVersionsPagination($scope.allUndeletedVersions, $scope.filterData.pageSizeSelected, $scope.page);
                updateManageDeletedFormVersions();
            };

            $scope.openFlowChartWindow = function (formVersion) {
                let formDetail = Form.get({
                    formVersionId : formVersion.id,
                    id            : $scope.form.id,
                    projection    : Form.projections.SPECIFIC_VERSION,
                });
                formDetail.$promise.then(async data => {
                    let editMode = RenderUtil.createFormModel(data).formVersions[0].editMode;
                    if (editMode.newWindow == null || editMode.newWindow.closed) {
                        let url = '/assets/kmap/formMap/form.html';
                        editMode.newWindow = $window.open(url, 'form_flow_chart_window');
                    }
                    let kmapModel = await FlowChartKmapModelService.createKmapModel(editMode.navigationNodes);
                    FlowChartKmapService.loadModel(editMode.newWindow, kmapModel, 'TB', null, null);
                });
            };

            /*
             * download flow chart as a zip
             * @param formVersion
             */
            $scope.downloadFlowChartZip = function (formVersion) {
                let formDetail = Form.get({
                    formVersionId : formVersion.id,
                    id            : $scope.form.id,
                    projection    : Form.projections.SPECIFIC_VERSION,
                });
                formDetail.$promise.then(async data => {
                    const editMode = RenderUtil.createFormModel(data).formVersions[0].editMode;
                    const kmapModel = await FlowChartKmapModelService.createKmapModel(editMode.navigationNodes, true);

                    const formName = $scope.form.name;
                    const fileName = `formFlowChart-${ $scope.form.id }-${
                        formName == null ? '' : formName
                    }-v${ formVersion.id }.zip`;

                    FlowChartKmapService.downloadFormChart(kmapModel, fileName);
                });
            };

            $scope.separateOutDeletedVersions = function () {
                let totalSize = $scope.form.formVersions.length;
                for (let i = 0; i < totalSize; i++) {
                    let formVersion = $scope.form.formVersions[i];
                    if (formVersion.isDeleted) {
                        $scope.deletedVersions.push(formVersion);
                    } else {
                        $scope.allUndeletedVersions.push(formVersion);
                    }
                }
            };

            $scope.deleteFormVersion = function (formVersion) {
                ngDialog.openConfirm({
                    controller : 'FormVersionDeleteController',
                    data       : formVersion,
                    template   : 'admin-templates/site/forms/form/formVersionDelete.html',
                }).then(() => {
                    reloadFormManager();
                });
            };

            $scope.deleteFormVersionV2 = function (formVersion) {
                FormVersion.delete(formVersion.id).then(() => {
                    reloadFormManager();
                    const message = `Version <span class="version-number">V${ formVersion.versionId } ${ formVersion.id }</span> Deleted successfully`;
                    localStorageService.set('deleteRestoreVersionAlert', message);
                });
            };

            $scope.restoreFormVersion = function (formVersionId) {
                FormVersion.restore(formVersionId).then(() => {
                    reloadFormManager();
                });
            };

            $scope.restoreFormVersionV2 = function (formVersion) {
                FormVersion.restore(formVersion.id).then(() => {
                    reloadFormManager();
                    const message = `Version <span class="version-number">V${ formVersion.versionId } ${ formVersion.id }</span> Restored successfully`;
                    localStorageService.set('deleteRestoreVersionAlert', message);
                });
            };

            $scope.updateFormVersionComment = function (formVersion) {
                ngDialog.openConfirm({
                    controller : 'FormVersionCommentUpdateController',
                    data       : formVersion,
                    template   : 'admin-templates/site/forms/form/formVersionCommentUpdate.html',
                }).then(() => {
                    reloadFormManager();
                });
            };

            $scope.syncToDrc = function (formVersion) {
                drcService.syncFormVersionById(formVersion.id).then(() => {
                    reloadFormManager();
                });
            };

            $scope.exportNodes = function (formVersion) {
                return Form.get({
                    formVersionId : formVersion.id,
                    id            : $scope.form.id,
                    projection    : Form.projections.SPECIFIC_VERSION,
                }).$promise.then(data => {
                    let editMode = RenderUtil.createFormModel(data).formVersions[0].editMode;
                    return NavigationNodeExport.exportAllNodes(editMode);
                });
            };

            $scope.exportCodeBook = function (formVersionId) {
                return CodeBook.exportCodeBook(formVersionId)
                    .then(response => {
                        let a = document.createElement('a');
                        let file = new Blob([ response.data ], { type : response.contentType });
                        a.href = URL.createObjectURL(file);
                        a.download = 'CodeBookReport.csv';
                        a.click();
                    });
            };

            $scope.separateOutDeletedVersions();
            $scope.loadMoreVersions();

            updateDeleteRestoreVersionAlert();

            $scope.loadAssociatedRules = function () {
                $scope.rulesLoaded = true;
                ruleLinkageService.getRulesLinkedToForm($scope.form.id).then(response => {
                    $scope.associatedRules = response.data;
                });
            };

            $scope.loadAssociatedPrograms = function () {
                $scope.programsLoaded = false;
                ProgramLinkageService.getProgramsLinkedToForm($scope.form.id).then(
                    response => {
                        $scope.associatedPrograms = response.data;
                        $scope.programsLoaded = true;
                    },
                    error => {
                        console.error(error);
                    });
            };

            /* Handle close Manage Form pop up when click icon close */
            $scope.removeMangeForm = function () {
                $scope.selectForm(null);
                $scope.closeDeleteRestoreVersionAlert();
            };

            /* Handle function loadPage for <form-pagination /> directive */
            $scope.loadPageForManageVersion = function (page, pageSize, jumpNumber) {
                $scope.page = page;
                if (pageSize) {
                    $scope.filterData.pageSizeSelected = pageSize;
                }
                if (jumpNumber >= 0) {
                    $scope.page = jumpNumber;
                }
                $scope.manageFormFormVersions = getManageFormVersionsPagination($scope.allUndeletedVersions, $scope.filterData.pageSizeSelected, $scope.page);
            };

            /* Handle Search current version number in Manage Form */
            $scope.handleSearchCurrentVersionNumber = function () {
                $scope.page = 0;
                $scope.manageFormFormVersions = getManageFormVersionsPagination($scope.allUndeletedVersions, $scope.filterData.pageSizeSelected, $scope.page);
            };

            /* Handle action when click button Activate or Activated in Manage Form table */
            $scope.toggleConfirm = function (id, name) {
                $scope.displayElement(`${name}-${id}`);
            };

            /* Handle action when click confirm dialog for Activate a version */
            $scope.activated = function (formId, formVersionId) {
                const formDetail = FormVersion.activate(formId, formVersionId);
                formDetail.then(() => {
                    reloadFormManager();
                });
            };

            /* Handle clear all actions when mouse leave an <td /> */
            $scope.clearAllWhenMouseleave = function (formVersion) {
                formVersion.shouldShowMenu = false;
                const popupDisplay = document.querySelectorAll('[ng-model="formVersion.confirmActivated"]');
                for (let x = 0; x < popupDisplay.length; x = x + 1) {
                    popupDisplay[x].style.display = 'none';
                }
            };

            /* Sort by column */
            $scope.handleSorting = function (column) {
                $scope.sort.activeColumn = column;
                $scope.sort[column].asc = !$scope.sort[column].asc;
                $scope.manageFormFormVersions = getManageFormVersionsPagination($scope.allUndeletedVersions, $scope.filterData.pageSizeSelected, $scope.page);
            };

            $scope.hoverCreator = function (creatorId) {
                const popupDisplay = document.getElementById(`creator-${creatorId}`);
                popupDisplay.style.display = 'block';
            };

            /* Handle view more and less records */
            $scope.handleViewMore = function () {
                if ($scope.viewMoreDeletedVersions) {
                    $scope.viewMoreDeletedVersions = false;
                } else {
                    $scope.viewMoreDeletedVersions = true;
                }

                updateManageDeletedFormVersions();
            };

            /* Close alert */
            $scope.closeDeleteRestoreVersionAlert = function () {
                localStorageService.set('deleteRestoreVersionAlert', null);
                $scope.deleteRestoreVersionAlert = null;
                const element = document.getElementById('delete-restore-version-alert');
                element.style.display = 'none';
            };

            $scope.displayElement = function (elementId) {
                const element = document.getElementById(elementId);
                if (element.style.display === 'none' || element.style.display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
            };

            /* Get manage version list follow by pagination, search input */
            function getManageFormVersionsPagination (data, pageSize, pageNumber) {
                $scope.filterData.searchResultCount = data.length;
                let total = Math.ceil(data.length / pageSize);
                total = total === 0 ? 1 : total;
                const links = {
                    first : 0,
                    last  : total - 1,
                };

                if (pageNumber < total - 1) {
                    links.next = pageNumber + 1;
                }
                if (pageNumber > 0) {
                    links.prev = pageNumber - 1;
                }
                $scope.links = links;

                // Filter data by search input
                if ($scope.filterData.searchString.trim() !== '') {
                    data = data.filter(dt => ('V' + `${dt.versionId}`).includes($scope.filterData.searchString) || dt.id.toString().includes($scope.filterData.searchString));
                }

                // Sort data by column
                if ($scope.sort.activeColumn !== '') {
                    switch ($scope.sort.activeColumn) {
                    case 'status':
                        data = $scope.sort.status.asc ? _.orderBy(data, 'isActive', 'asc') : _.orderBy(data, 'isActive', 'desc');
                        break;
                    }
                }

                return data.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
            }

            /* Update deleted versions list */
            function updateManageDeletedFormVersions () {
                if ($scope.viewMoreDeletedVersions) {
                    $scope.manageDeletedFormVersions = $scope.deletedVersions;
                } else {
                    $scope.manageDeletedFormVersions = $scope.deletedVersions.slice(0, 1);
                }
            }

            function updateDeleteRestoreVersionAlert () {
                $scope.deleteRestoreVersionAlert = $sce.trustAsHtml(localStorageService.get('deleteRestoreVersionAlert'));
                if ($scope.deleteRestoreVersionAlert !== null) {
                    // Display the alert if it's available.
                    const element = document.getElementById('delete-restore-version-alert');
                    element.style.display = 'block';

                    // Auto close the alert after 5s.
                    $timeout(() => {
                        $scope.closeDeleteRestoreVersionAlert();
                    }, 5000);
                }
            }

            /* Reload Form Manager */
            function reloadFormManager () {
                $scope.reloadFormList();
                $scope.selectForm($scope.form);
            }
        });
}());
