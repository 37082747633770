(function () {
    'use strict';

    /**
     * base model for script actions
     */
    angular.module('acadiamasterApp').factory('ScriptActionBaseModel', function () {

        /***************************************
         * private functions
         ***************************************/
        /**
         * getting ticket id link from ticket id
         * @param ticketId - ticket id, might be null
         * @return {string} - empty string or a link string to the ticket id
         */
        function getTicketIdLink(ticketId) {
            if (ticketId!=null) {
                return '<h5><a href="https://agile.vignetcorp.com:8085/jira/browse/' + ticketId + '">' +
                    'JIRA Ticket Link (' + ticketId + ')</a> </h5>';
            }

            return "";
        }

        /***************************************
         * class functions
         ***************************************/

        /**
         * constructor
         * @constructor
         */
        function ScriptActionBaseModel() {
            this.name = null;
            this.ticketId = null;
            this.steps = [];
            this.description = [];

            this.formMode = null;
        }

        /**
         * collecting all the descriptions from steps
         * @returns {string} - descriptions joined with <br> tag
         */
        ScriptActionBaseModel.prototype.collectDescriptionHTMLFromSteps = function () {
            var output = '';

            output += getTicketIdLink(this.ticketId);

            _.forEach(this.steps, function (step, index) {
                output += getTicketIdLink(step.ticketId);
                output += (index + 1) + ' : ' + step.description + '<br>\n';
            });

            return output;
        };

        /**
         * apply the action to a form mode
         * @param formMode - form mode object
         * @returns {{message: string, hasError: boolean}} -- status object
         */
        ScriptActionBaseModel.prototype.applyAction = function (formMode) {
            this.formMode = formMode;  // set this in case the apply change function actually need the form mode as well

            var statusObject = {
                message: '',
                hasError: false
            };

            // go through each steps to apply the changes
            _.forEach(this.steps, function (step, index) {
                // find targets
                var targets = step.searchFunction(formMode);
                statusObject.message += '<strong>' + (index + 1) + ' : ' + step.description + '</strong><br>';
                statusObject.message += '-- found <span class="badge">' + targets.length + '</span> items that matches the condition <br>';

                // apply change to each target
                _.forEach(targets, function (item) {
                    statusObject.message += 'applying change to : ' + item.getDescriptionAsHtml() + '<br>';
                    step.changeFunction(item);
                });

                statusObject.message += '<br><br>******** final check, make sure everything has been changed <br>';
                // find targets again, now we expect item returned to be 0
                targets = step.searchFunction(formMode);
                if (targets==null || targets.length == 0) {
                    statusObject.message += '<div class="text-success">Change has been made to all qualifying item</div>'
                }
                else {
                    statusObject.message += '<div class="text-danger">Change has NOT been made to some qualifying items: (' + targets.length + ')</div>';

                    _.forEach(targets, function (item) {
                        statusObject.message += 'item not fixed : ' + item.getDescriptionAsHtml() + '<br>';
                    });
                }

                statusObject.message += '<br>';
            });

            this.formMode = null;

            return statusObject;
        };

        /***************************************
         * service return call
         ***************************************/

        return ScriptActionBaseModel;
    });

})();
