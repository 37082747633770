/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for listing data type properties
     */
    .directive('formConfigDataTypePropertyListing', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/elementConfig.basic.datatype.propertyListing.html',
            scope: {
                type : '=',
                propertyList : '='
            },
        };
    });
})();

