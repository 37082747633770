(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueViewImageSelection', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/viewImageSelection.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    selector: '=',
                    field: '='
                },
                controller: function ($scope) {
                    $scope.cs = FormConstants;
                    $scope.editMode = $scope.field.findParentMode()._parent.editMode;

                    $scope.filterOutTargets = function (listToFilterFrom) {
                        var filteredList = [];
                        if (listToFilterFrom != null) {
                            for (var i = 0; i < listToFilterFrom.length; i++) {
                                if (listToFilterFrom[i].type == $scope.cs.fieldsType.IMAGE_CONTAINER) {
                                    filteredList.push(listToFilterFrom[i]);
                                }
                            }
                        }
                        return filteredList;
                    };
                }
            };

        });

})();

