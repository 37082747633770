/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
import ProgramContainerConstantsService from '../const/programContainer.constants.service';

/**
 * Created by Jamie Nola on 08/02/2019
 *
 * Model for a container of containers
 */
angular.module('acadiamasterApp')

    /**
     * service for a program's containers model
     */
    .factory('ProgramContainerGroupModel',
        (ModelServiceBase, ContainerModel) => {
            /**
             * program form association constructor
             * @constructor
             */
            function ProgramContainerGroupModel () {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false, null);
                this.containers = null;
                this.programId = null;
            }

            /**
             * program dto for program containers model
             */
            ProgramContainerGroupModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            /*
             * convert the current UI model to dto format
             */
            ProgramContainerGroupModel.prototype.toDto = function toDto (files) {
                const dto = [];
                if (this.containers != null) {
                    Object.keys(this.containers).forEach(key => {
                        const containerGroup = this.containers[key];
                        containerGroup.forEach((container, index) => {
                            const containerDto = container.toDto(files);
                            containerDto.displayOrder = index;
                            containerDto.programId = this.programId;
                            dto.push(containerDto);
                        });
                    });
                }
                return dto;
            };

            /*
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            ProgramContainerGroupModel.prototype.fromDto = function fromDto (dto) {
                this.programId = dto.programId;
                const containers = {};
                const defaultTabId = ProgramContainerConstantsService.defaultTab.id;

                if (dto.containers != null) {
                    dto.tabs.forEach(tab => {
                        containers[tab.id] = [];
                    });
                    dto.containers.forEach(container => {
                        const containerModel = new ContainerModel(this);
                        containerModel.fromDto(container);
                        // split containers into groups based on their page id
                        const pageId = containerModel.pageId || defaultTabId;
                        if (!containers[pageId]) {
                            containers[pageId] = [];
                        }
                        containers[pageId].push(containerModel);
                    });
                    // sort each group by its displayOrder
                    Object.keys(containers).forEach(key => {
                        containers[key] = containers[key]
                            .sort((a, b) => a.displayOrder - b.displayOrder);
                    });
                }
                this.containers = containers;
            };

            ProgramContainerGroupModel.prototype.addContainer = function addContainer (group, pageId, selector) {
                let container;
                if (group == null) {
                    container = new ContainerModel(this);
                    container.name = 'Undefined container';
                    container.pageId = pageId;
                    container.programId = this.programId;
                } else {
                    container = group;
                    container._parent = this;
                }

                if (!this.containers[pageId]) {
                    this.containers[pageId] = [];
                }
                this.containers[pageId].push(container);
                selector.selectItem(container);
                return container;
            };

            ProgramContainerGroupModel.prototype.removeContainer = function removeContainer (removedItem, selector) {
                Object.keys(this.containers).forEach(key => {
                    const group = this.containers[key];
                    this.containers[key] = group.filter(container => container.uniqueId !== removedItem.uniqueId);
                });
                if (selector != null) {
                    selector.itemRemoved(removedItem);
                }
            };

            ProgramContainerGroupModel.prototype.moveContainer = function moveContainer (container, pageId) {
                const group = this.containers[container.pageId];
                if (group) {
                    this.containers[container.pageId] = group.filter(item => item.uniqueId !== container.uniqueId);
                }

                container.pageId = pageId;
                this.containers[pageId].push(container);
            };

            /**
             * validate the entity
             */
            ProgramContainerGroupModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.programId == null) {
                    this.setErrorMessage('programId must not be null');
                }
            };

            /*
             * overwrite the base class validate function because containers is not an simple array
             * @param validateChildModels - boolean to indicate if we want to validate the children as well
             */
            ProgramContainerGroupModel.prototype.validate = function (validateChildModels) {
                this._validateSelf();
                if (validateChildModels && this.containers != null) {
                    const _this = this;
                    const nameCounts = {};
                    let containerLinkList = [];

                    Object.keys(this.containers).forEach(key => {
                        const containerGroup = _this.containers[key];
                        containerGroup.forEach(container => {
                            if (container.name != null && container.name.length > 0) {
                                nameCounts[container.name] = nameCounts[container.name] == null ? 1 : nameCounts[container.name] + 1;
                            }
                            let containerLink = {
                                containerPageId : container.pageId,
                                id              : container.id,
                            };
                            containerLinkList.push(containerLink);
                        });
                    });

                    Object.keys(this.containers).forEach(key => {
                        const containerGroup = _this.containers[key];
                        containerGroup.forEach(container => {
                            container.validate(validateChildModels);
                            // note: this validation framework is designed for self validation, it has
                            // trouble handling the case of unique name check across multiple container
                            // we are hacking it by calling a secondary validate function
                            container.validateUniqueName(nameCounts);
                            container.validateContainerItems(containerLinkList);
                            container.validateContainerItemTemplateHeights(containerLinkList);
                        });
                    });
                }
            };

            /*
             * @overwrite overwrite base implementation to cover the case of containers
             * check if an object has error, this search is done on the element itself as well as all the children
             */
            ProgramContainerGroupModel.prototype.isValid = function () {
                if (this.hasError()) { // check self first for quick exit
                    return false;
                }

                if (this.containers == null) {
                    return true;
                }

                let isValid = true;
                // eslint-disable-next-line guard-for-in
                for (let key in this.containers) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (this.containers.hasOwnProperty(key)) {
                        isValid = isValidContainerList(this.containers[key]);
                    }

                    if (!isValid) {
                        break;
                    }
                }

                return isValid;
            };

            /* *************************************
             * utility functions
             ************************************** */
            function loadModelFromDto (dto) {
                const model = new ProgramContainerGroupModel();
                model.fromDto(dto);
                return model;
            }

            function isValidContainerList (containerList) {
                if (containerList == null || containerList.length == 0) {
                    return true;
                }

                for (let i = 0; i < containerList.length; i++) {
                    if (!containerList[i].isValid()) {
                        return false;
                    }
                }

                return true;
            }

            /** *************************************
             * service return call
             ************************************** */

            return {
                loadModelFromDto,
                ProgramContainerGroupModel,
            };
        });
