/**
 * Created by Jamie Nola on 12/07/2018
 */

(function () {
    'use strict';

    /**
     * directive for managing strings on the program or system level
     */
    angular.module('acadiamasterApp')
        .directive('stringManagement', function ($translate, StringManagementConstants, ngDialog,
            StringManagementService, StringResourceKeyModelService, SEARCH_FILTER_CONSTANTS,
            $timeout, CONFIG, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/stringManagement/stringManagement.template.html',
                scope: {
                    level: '@',
                    entity: '=',
                    isServerString: '='
                },
                controller: function ($scope) {
                    // resolve program entity
                    $scope.cs = StringManagementConstants;
                    $scope.isBaseLevel = $scope.level === $scope.cs.LEVEL.SYSTEM;
                    $scope.sfc = SEARCH_FILTER_CONSTANTS;
                    $scope.filteredFields = [
                        SEARCH_FILTER_CONSTANTS.FIELDS.STRING_FEATURE,
                        SEARCH_FILTER_CONSTANTS.FIELDS.STRING_KEY
                    ];
                    $scope.data = {
                        allFeaturesHidden: true,
                        features: [],
                        filteredValues: {},
                        filtersActive: false,
                        selectedString: null,
                        selectedStringId: null,
                        selectedFeatureId: null,
                        locales: [],
                        isConfigServer: false,
                        isReadOnlyServer: false,
                        isServerString: false,
                    };

                    /**
                     * Responds to a user clicking the left panel. Sets a given string as the target
                     * for the main detail panel
                     */
                    $scope.selectString = function (string, featureName) {
                        $scope.data.selectedString = string;
                        $scope.data.selectedStringId = string.keyName;
                        $scope.data.selectedFeatureId = featureName;
                        $scope.data.locales.forEach(function (locale) {
                            locale.isOpen = true;
                        });
                    };

                    /**
                     * Translates feature names so that they can be sorted by ng-repeat
                     */
                    $scope.translateFeatureName = function (feature) {
                        return $translate.instant(feature.name);
                    };

                    /**
                     * Checks to see if a given feature is currently visible or hidden due to filters
                     */
                    $scope.featureIsFiltered = function (feature) {
                        var filteringFeatures = $scope.data.filteredValues.hasOwnProperty(SEARCH_FILTER_CONSTANTS.FIELDS.STRING_FEATURE.id);
                        if (!filteringFeatures) {
                            return true;
                        }
                        var filteredFeatures = $scope.data.filteredValues[SEARCH_FILTER_CONSTANTS.FIELDS.STRING_FEATURE.id].split(',');

                        return filteredFeatures.indexOf(feature.name) !== -1;
                    };

                    /**
                     * Called every time the search query changes. Filters through all features and strings, updates selection, and expands/collapses panels accordingly.
                     */
                    $scope.filterStrings = function (avoidCollapse) {
                        // keeps track of whether or not a given feature/string has been removed
                        var selectedFeatureRemoved = false;
                        var selectedStringRemoved = false;

                        // shorten these long strings for readability
                        var FEATURE = SEARCH_FILTER_CONSTANTS.FIELDS.STRING_FEATURE.id;
                        var STRING = SEARCH_FILTER_CONSTANTS.FIELDS.STRING_KEY.id;

                        // pull out filtered features and/or strings from the search query
                        var filteredFeatures = $scope.data.filteredValues[FEATURE] ?
                            $scope.data.filteredValues[FEATURE].split(',') :
                            [];
                        var filteredString = $scope.data.filteredValues[STRING];

                        // update our flag for active filters
                        $scope.data.filtersActive = Object.keys($scope.data.filteredValues).length > 0;
                        var allFeaturesHidden = true;

                        // iterate through all features and update properties after a search
                        $scope.data.features.forEach(function (feature) {
                            var isFeatureVisible = feature.filteredStrings.length > 0 &&
                                (!$scope.data.filteredValues.hasOwnProperty(FEATURE) ||
                                    filteredFeatures.indexOf(feature.name) > -1
                                );
                            if (isFeatureVisible) {
                                allFeaturesHidden = false;
                            }
                            // collapse this feature if it is visible and we aren't explicitly keeping it open (this is done if a search is active and the feature includes positive search results)
                            if (!avoidCollapse) {
                                feature.isOpen = $scope.data.filtersActive && isFeatureVisible;
                            }

                            // flag for turning the feature header green. If a child string is custom, this becomes true.
                            var hasCustomStrings = false;

                            // determine if this feature should be hidden due to the search results
                            if (filteredFeatures.length > 0 && !selectedFeatureRemoved && $scope.data.selectedFeatureId === feature.name) {
                                selectedFeatureRemoved = filteredFeatures.indexOf(feature.name) === -1;
                            }

                            // iterate through this feature's strings and update their properties accordingly
                            feature.strings.forEach(function (stringModel) {
                                stringModel.updateIsCustomFlag();
                                var isStringVisible = !filteredString ||
                                    stringModel.keyName.toLowerCase().indexOf(filteredString.toLowerCase()) !== -1;

                                if (isStringVisible) {
                                    // update this feature's hasCustomStrings flag if this string is visible and contains custom values
                                    if (!hasCustomStrings &&
                                        stringModel.isCustom[$scope.level]) {
                                        hasCustomStrings = true;
                                    }
                                }

                                // if this string is the same as the selected one, do some housekeeping with it
                                if ($scope.data.selectedStringId === stringModel.keyName) {
                                    // if we're filtering and this string isn't visible, set a flag so that we can reset the selection at the end of this function
                                    if (filteredString) {
                                        selectedStringRemoved = !isStringVisible;
                                    }

                                    // if not filtering and the selected string is not filtered out, expand the feature
                                    if (!selectedFeatureRemoved &&
                                        !$scope.data.filtersActive) {
                                        scrollFeaturePanelToStringLocation(stringModel.keyName);
                                        feature.isOpen = true;
                                    }
                                    $scope.data.selectedString = stringModel;
                                }
                            });
                            $scope.data.allFeaturesHidden = allFeaturesHidden;
                            // turn the feature's header green if any of its strings contain custom values
                            feature.hasCustomStrings = hasCustomStrings;
                        });

                        // reset the selection if the new search does not contain the currently selected string or feature
                        if (selectedFeatureRemoved || selectedStringRemoved) {
                            resetSelectedString();
                        }
                    };

                    /**
                     * Opens the edit dialog for a given locale within the currently selected stringModel. Allows the user to edit and save the string values
                     */
                    $scope.openEditDialog = function (locale, isServerString) {
                        var parentScope = $scope;
                        // data needs to be accessed outside of the dialog's scope for preCloseCallback
                        var stringModel = $scope.data.selectedString;
                        var data = {
                            showConfirm: false,
                            isSaveDisabled: false,
                            isRevertDisabled: true,
                            isServerString: isServerString
                        };
                        var element;

                        // show edit dialog
                        var dialog = ngDialog.open({
                            templateUrl: 'admin-templates/site/stringManagement/editDialog/stringManagementEditDialog.template.html',
                            className: 'ngdialog-theme-plain custom-width-medium string-mgmt-edit-dialog',
                            controller: ['$scope', function ($scope) {
                                // store these values in local scope so they can be accessed in the edit dialog's HTML
                                $scope.locale = locale;
                                $scope.level = parentScope.level;
                                $scope.isBaseLevel = parentScope.isBaseLevel;
                                $scope.data = data;
                                $scope.valueModel = stringModel.getValueModel(locale.id, parentScope.level);
                                $scope.systemValueModel = stringModel.getValueModel(locale.id, parentScope.cs.LEVEL.SYSTEM);
                                $scope.isServerString = isServerString;
                                $scope.save = function () {
                                    // after we save the string, this directive needs to update the current selection and check again for custom values.
                                    $scope.valueModel.save()
                                        .then(function () {
                                            reloadSelectedString(true);
                                            data.showConfirm = false;
                                            dialog.close();
                                        }, function (error) {
                                            console.error(error);
                                        });
                                };

                                $scope.confirmRevert = function () {
                                    $scope.valueModel.revertToSystemValue();
                                    $scope.save();
                                };

                                // when showConfirm changes, update the className for the confirm modal
                                $scope.$watch('data.showConfirm', function (showConfirm) {
                                    if (!element) {
                                        return;
                                    }
                                    if (showConfirm) {
                                        element.classList.remove('custom-width-medium');
                                        element.classList.add('custom-width-small');
                                    } else {
                                        element.classList.remove('custom-width-small');
                                        element.classList.add('custom-width-medium');
                                    }
                                });
                            }],
                            onOpenCallback: function () {
                                element = document.getElementById(dialog.id);
                            },
                            preCloseCallback: function () {
                                // if we are in revert confirm mode, just switch back instead of closing
                                if (data.showConfirm) {
                                    $timeout(function () {
                                        data.showConfirm = false;
                                    });
                                    return false;
                                }
                                return true;
                            },
                            showClose: false
                        });
                    };

                    /**
                     * allows the user to export all string values as a CSV file
                     */
                    $scope.exportToCsv = function (programId) {
                        return StringManagementService.exportToCsv(programId, $scope.isServerString)
                            .then(function (response) {
                                var strings = response.data;
                                var csvList = [];
                                strings.forEach(function (string) {
                                    var row = {
                                        'FEATURE': string.feature,
                                        'KEY_NAME': string.keyName,
                                        'DEFAULT_ENGLISH': string.defaultEnglish,
                                        'DEFAULT_ENGLISH_SOURCE': string.defaultEnglishSource,
                                        'DEFAULT_SPANISH': string.defaultSpanish,
                                        'DEFAULT_SPANISH_SOURCE': string.defaultSpanishSource,
                                        'WEB_ENGLISH': string.webEnglish,
                                        'WEB_ENGLISH_SOURCE': string.webEnglishSource,
                                        'WEB_SPANISH': string.webSpanish,
                                        'WEB_SPANISH_SOURCE': string.webSpanishSource,
                                        'MOBILE_ENGLISH': string.mobileEnglish,
                                        'MOBILE_ENGLISH_SOURCE': string.mobileEnglishSource,
                                        'MOBILE_SPANISH': string.mobileSpanish,
                                        'MOBILE_SPANISH_SOURCE': string.mobileSpanishSource,
                                        'HAS_HTML': string.hasHtml
                                    };
                                    csvList.push(row);
                                });
                                return csvList;
                            }, function (error) {
                                console.error('Error Exporting CSV', error);
                            });
                    };

                    $scope.exportToJSON = function () {
                        StringManagementService.loadStrings(undefined, true,undefined, $scope.isServerString)
                            .then(function (response) {
                                var dateStr = new Date().toISOString().split('T')[0];
                                vbrCommonUtil.downloadAsFile('system_specific_string_' + dateStr + '.json', response.data);
                            }, function (error) {
                                console.error('Error Exporting CSV', error);
                            });
                    };

                    /**
                     * allows the user to select a json file to import
                     */
                    $scope.openImportStringResourceModal = function () {
                        ngDialog.openConfirm({
                            template: '<string-management-system-import-modal></string-management-system-import-modal>',
                            plain: true,
                            scope: $scope,
                            size: 'md',
                            className: 'ngdialog-theme-plain custom-width-small'
                        }).then(function () {
                            loadStrings(undefined, undefined, $scope.isServerString);
                        });
                    };

                    function reloadSelectedString(requiresFilter) {
                        if (!$scope.data.selectedString) {
                            return;
                        }
                        var featureName = $scope.data.selectedFeatureId;
                        var stringModel = $scope.data.selectedString;
                        resetSelectedString();
                        $timeout(function () {
                            $scope.selectString(stringModel, featureName);
                            if (requiresFilter) {
                                $scope.filterStrings(true);
                            }
                        });
                    }

                    /**
                     * Scrolls the feature panel to the location of a specified string
                     * @param {String} keyName
                     */
                    function scrollFeaturePanelToStringLocation(keyName) {
                        var featureMainEl = document.getElementById('string-management-feature-panel-main');
                        var stringEl = document.getElementById('string-management-string-' + keyName);

                        if (featureMainEl && stringEl) {
                            featureMainEl.scrollTop = stringEl.offsetTop - 5;
                        }
                    }

                    /**
                     * Resets the currently selected string and feature
                     */
                    function resetSelectedString() {
                        delete $scope.data.selectedString;
                        delete $scope.data.selectedStringId;
                        delete $scope.data.selectedFeatureId;
                    }

                    /**
                     * Parses through the raw data from the API and sets up the features and strings
                     * arrays
                     */
                    function parseStrings(data, programId) {
                        var featureMap = {};
                        data.forEach(function (stringData) {
                            var stringModel = new StringResourceKeyModelService.StringResourceKeyModel();
                            stringModel.fromDto(stringData, programId);
                            var featureName = stringModel.feature;
                            var feature = featureMap[featureName] ||
                                {
                                    name: featureName,
                                    displayName: $scope.translateFeatureName({ name: featureName }),
                                    strings: [],
                                    filteredStrings: [],
                                    isOpen: false
                                };
                            feature.strings.push(stringModel);
                            featureMap[featureName] = feature;
                        });

                        $scope.data.features = [];
                        var searchFilterFeatures = [];
                        // sets up features once they have all been identified
                        Object.keys(featureMap).forEach(function (key) {
                            var feature = featureMap[key];
                            $scope.data.features.push(feature);
                            searchFilterFeatures.push({
                                text: feature.displayName,
                                name: feature.name
                            });
                        });

                        // sort search filter features by translated text. Angular does this for the UI, but not for the search filters.
                        searchFilterFeatures.sort(function (a, b) {
                            var aText = a.text.toLowerCase();
                            var bText = b.text.toLowerCase();
                            if (aText < bText) {
                                return -1;
                            }
                            if (aText > bText) {
                                return 1;
                            }
                            return 0;
                        });

                        StringManagementService.setFeatures(searchFilterFeatures);
                        $scope.filterStrings();
                    }

                    /**
                     * Gets all locales from the config, then adds them to the locales array.
                     */
                    function loadLocales() {
                        $scope.data.locales = [];
                        var configLocales = CONFIG.systemLocales;
                        configLocales.forEach(function (locale) {
                            var id = locale.locale;
                            $scope.data.locales.push({
                                id: id,
                                name: locale.name,
                                isOpen: true,
                            });
                        });
                    }

                    /**
                     * Loads string data from the server. Can either load at a system or program level
                     * @param {String} programId (optional) The current program's ID
                     * @param {Boolean} clearValueIds (optional) If true the value ids in the response will be set to null
                     */
                    function loadStrings(programId, clearValueIds, isServerString) {
                        return StringManagementService.loadStrings(programId, clearValueIds,undefined, isServerString)
                            .then(function (response) {
                                parseStrings(response.data, programId);
                            }, function (error) {
                                console.error('Error getting strings from API', error);
                            });
                    }

                    function init() {
                        $scope.data.locales = [];
                        CONFIG.systemLocales.forEach(function (locale) {
                            var id = locale.locale;
                            $scope.data.locales.push({
                                id: id,
                                name: $translate.instant('locale.' + id),
                                isOpen: true
                            });
                        });
                        // hide edit button on read only servers
                        $scope.data.isReadOnlyServer = CONFIG.readOnlyServer;
                        $scope.data.isServerString = $scope.isServerString;
                        // show Export to JSON button on config servers
                        // hide Import button on non-config servers
                        $scope.data.isConfigServer = CONFIG.configurationServer;
                        // if a program exists, load data based on the programId. Otherwise, just load the system level strings
                        if ($scope.entity) {
                            $scope.entity.$promise.then(function (program) {
                                $scope.program = program;
                                loadLocales();
                                loadStrings(program.id, undefined,$scope.isServerString);
                            });
                        } else {
                            loadLocales();
                            loadStrings(undefined, undefined,$scope.isServerString);
                        }
                    }

                    // timeout ensures that the CONFIG has loaded
                    $timeout(init);
                }
            };
        });
})();
