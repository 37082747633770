(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one rule
     */
        .directive('treeNodeTestCase', function (ProgramTestTreeOptionsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/treeNode/testCase.html',
                scope: {
                    testCase: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.treeOptions = ProgramTestTreeOptionsService;
                    $scope.selector.selectItem($scope.testCase);
                }
            };
        });

})();

