/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 4/26/17
 */
(function () {
    /**
     * this controller require an input of allTags
     */
    angular.module('acadiamasterApp').controller('CreateCodesController',
        function ($scope, InviteCodesService, ProgramSearch, allTags, PropertyCacheService, AlertService, LocaleConstantsService, FormConstants) {
            let vm = this;
            vm.lcs = LocaleConstantsService;
            $scope.constants = FormConstants;
            // getting tags from injection
            vm.allTags = allTags;
            vm.extensionAllowed = '.png';
            vm.allProperties = null;
            vm.loadPrograms = loadPrograms;

            vm.isReady = {
                allReady : function () {
                    return this.programCodeLoaded && this.propertyLoaded;
                },
                programCodeLoaded : false,
                propertyLoaded    : false,
            };

            vm.availableUsageTypes = {
                PROGRAM_SUBSCRIPTION : 'PROGRAM_SUBSCRIPTION',
                REGISTRATION         : 'REGISTRATION',
            };

            vm.data = {
                codeExpirationDate : null,
                currentTag         : null,
                localizationMap    : { es : { logoName : null } },
                metaData           : {
                    profilePropertyValues : [],
                },
                numberOfCodes           : 1,
                programCode : null,
                tags                    : [],
                timesAllowed            : 1,
                usageType               : vm.availableUsageTypes.REGISTRATION,
            };

            vm.addNewPropertyValue = function () {
                vm.data.metaData.profilePropertyValues.push({
                    property      : null,
                    propertyValue : null,
                });
            };

            vm.removePropertyValue = function (index) {
                // console.log('about to remove property value from index : ' + index);
                vm.data.metaData.profilePropertyValues.splice(index, 1);
            };

            vm.addTag = function ($item) {
                addTag(vm, $item);
            };

            vm.removeTag = function (index) {
                vm.data.tags.splice(index, 1);
            };

            vm.createCodes = createCodes;

            vm.createAndAddNewTag = function () {
                createAndAddNewTag(vm);
            };

            /* ---------------------------------------------------------
             private functions
             * --------------------------------------------------------- */
            function validateTag (vm, tag) {
                let tagExists = false;

                vm.data.tags.forEach(t => {
                    if (t.name === tag.name) {
                        tagExists = true;
                        return tagExists;
                    }
                    return undefined;
                });
                return tagExists;
            }

            /*
             * add a tag from lookahead
             * @param vm - controller object
             * @param item - new tag to be added
             */
            function addTag (vm, item) {
                if (validateTag(vm, item) === false) {
                    vm.data.tags.push(item);
                }
                vm.data.currentTag = null;
            }

            function createAndAddNewTag (vm) {
                let newTag = {
                    id   : null,
                    name : vm.data.currentTag,
                };

                if (validateTag(vm, newTag) === false) {
                    vm.data.tags.push(createNewTag(vm.data.currentTag));
                }

                vm.data.currentTag = null;
            }

            function createNewTag (tagName) {
                return {
                    id   : null,
                    name : tagName,
                };
            }

            function updateMetaData (requestData) {
                let hasMetaData = false;

                let ppvs = requestData.metaData.profilePropertyValues;

                ppvs = _.filter(ppvs, ppv => {
                    return ppv.property != null && ppv.property.id != null;
                });

                _.forEach(ppvs, ppv => {
                    hasMetaData = true;
                    ppv.propertyId = ppv.property.id;
                });

                if (!hasMetaData) {
                    requestData.metaData = null;
                }
            }

            function createCodes () {
                let requestData = angular.copy(vm.data);
                if (requestData.codeExpirationDate != null) {
                    requestData.codeExpirationDate = requestData.codeExpirationDate.getTime();
                }
                let files = [];
                if (vm.data.file) {
                    files.push(vm.data.file);
                    requestData.logoName = vm.data.file.name;
                }
                if (vm.data.localizationMap.es.file) {
                    files.push(vm.data.localizationMap.es.file);
                    requestData.localizationMap = {
                        es : {
                            logoName : null,
                        },
                    };
                    requestData.localizationMap.es.logoName = vm.data.localizationMap.es.file.name;
                }
                updateMetaData(requestData);

                return InviteCodesService.createNewCodes(requestData, files).then(list => {
                    $scope.closeThisDialog();
                    let codes = list.data;
                    return InviteCodesService.compressTagNames(codes);
                }, error => {
                    console.error('Error creating invite code', error);
                });
            }

            /*
             load all Program
             */
            function loadPrograms () {
                ProgramSearch.query({
                    page: 0,
                    size: 1000,
                })
                    .then(response => {
                        vm.allPrograms = response.data;
                        vm.isReady.programCodeLoaded = true;

                        console.log('query result : ', response, vm.allPrograms);
                    })
                    .catch(error => {
                        AlertService.error(`ERROR loading program codes: ${ error}`);
                    });
            }

            /*
             load all Properties
             */
            function loadProperties () {
                // this is not a force reload of all the properties, so if properties have changed
                let promise = PropertyCacheService.loadProperties(false);

                promise.then(properties => {
                    vm.allProperties = properties;
                    vm.isReady.propertyLoaded = true;
                }).catch(error => {
                    AlertService.error(`failed to load properties list : ${ error}`);
                });
            }

            loadPrograms();
            loadProperties();
            vm.currentDate = new Date();
        });
}());
