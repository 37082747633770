/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for state rule trigger
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultStateRuleTriggerService', function (CompareResultUtilService) {

        function compare(trigger1, trigger2, parentTitle) {
            var title;
            if (trigger1 == null) {
                trigger1 = {};
                title = "Trigger | " + trigger2.type.name + " | ";
            }
            else {
                title = "Trigger | " + trigger1.type.name + " | ";
            }

            if (trigger2 == null) {
                trigger2 = {};
            }


            // add name to title
            if (trigger2.name != null) {
                title += " (" + trigger2.name + ")";
            }
            else if (trigger1.name != null) {
                title += " (" + trigger1.name + ")";
            }

            if (parentTitle != null)
                title += " - " + parentTitle;

            var result = {
                _title: title,
                _isOpen: true
            };

            CompareResultUtilService.comparePrimitivesByKey(trigger1, trigger2, "localId", result);
            CompareResultUtilService.comparePrimitivesByKey(trigger1, trigger2, "name", result);
            CompareResultUtilService.compareNamedObjectsByKey(trigger1, trigger2, "type", result);
            CompareResultUtilService.compareNamedObjectsByKey(trigger1, trigger2, "targetType", result);
            CompareResultUtilService.comparePrimitivesByKey(trigger1, trigger2, "targetLocalId", result);

            result._status = CompareResultUtilService.collectStatus(result);

            return result;
        }

        return {
            compare: compare
        };
    });

})();

