'use strict';
angular.module('vibrent-external-survey-parser')
    .service('QualtricSkippingLogicService', () => {
        const qualtricComparativeNumberMap = new Map([
            ['LessThan', 'lt'],
            ['LessThanOrEqual','lte'],
            ['GreaterThanOrEqual','gte'],
            ['GreaterThan','gt'],
        ]);
        const qualtricOperatorMap = new Map([
            ['EqualTo', 'eq'],
            ['NotSelected', 'not'],
            ['Selected', 'eq'],
            ['NotEmpty','notempty'],
        ]);

        /**
         * Convert qualtric logic to unresolved vibrent logic.
         * @param qualtricLogic qualtric Logic
         * @return vibrent (string|null) logic
         */
         function transformLogic(qualtricLogic){
            let logicArray = _.keys(qualtricLogic);
            logicArray = logicArray.filter(element => {
                return Number.isInteger(parseInt(element, 10));
            });
            let VBParentLevel = [];
            //Regular expression to detect all ) at the end of string
            const removeLastParenthesisReg = /\)+$/;
            //Parent level
            _.forEach(logicArray, element => {
                let parentLogic = qualtricLogic[element];
                let elementArray = _.keys(parentLogic);
                elementArray = elementArray.filter(element => {
                    return Number.isInteger(parseInt(element, 10));
                });
                let VBLogicArray = [];
                //Child level
                _.forEach(elementArray, nestLevel => {
                    //Do real work
                    let qualtricSkipLogic = qualtricLogic[element][nestLevel];
                    let VBLogic = transformToVibrentLogic(qualtricSkipLogic);
                    if (VBLogic === null){
                        // false (lodash library) to return immediately since we don't need to process any more additional logic
                        // and reset array.
                        VBLogicArray =[];
                        return false;
                    }
                    //processing A AND B OR C by doing the following below
                    //pop last element, push conjunction if exist, push last element, push new element
                    //check for conjunction (and or)
                    let conjunction = qualtricSkipLogic.Conjuction;
                    if (conjunction != null) {
                        console.log(`VBLogicArray size: ${VBLogicArray.length}`);
                        let lastElement = VBLogicArray.pop();
                        console.log(`index ${nestLevel} last element ${lastElement}`);
                        VBLogicArray.push(conjunction.toLowerCase());
                        //try to fix the quote around operation
                        //check if last element has ) if so, we need to remove and insert into the front
                        let isDoubleparenthesis = _.endsWith(lastElement, '))');
                        if (isDoubleparenthesis) {
                            //Replace all ) at the end of String with only 1 )
                            lastElement = lastElement.replace(removeLastParenthesisReg, ')');
                        }
                        console.log(`isDouble ${isDoubleparenthesis} and last element ${lastElement}`);
                        VBLogicArray.push(`(${lastElement}, `);
                        let parentheses = _.times(nestLevel, () => ')');
                        VBLogicArray.push(`${VBLogic}${parentheses.join('')}`);
                    } else {
                        VBLogicArray.push(VBLogic);
                    }
                    console.log(`Array: ${_.join(VBLogicArray, '~~')}`);
                    //end of child level
                });
                //handle unsupported operation
                if (VBLogicArray.length === 0){
                    //return immediately and reset parent array
                    VBParentLevel=[];
                    return false;
                }
                //convert array to string
                let result = VBLogicArray.join('');
                //push parent level array to handle logic set.
                let type = parentLogic.Type;
                console.log(`Is this logic set ${type} and result ${result}`);
                if (type === 'AndIf') {
                    let lasElement = VBParentLevel.pop();
                    VBParentLevel.push(`and (${lasElement}, ${result})`);
                } else if (type === 'ElseIf'){
                    let lasElement = VBParentLevel.pop();
                    VBParentLevel.push(`or (${lasElement}, ${result})`);
                } else {
                    VBParentLevel.push(result);
                }

            }); //end of parent level

            return VBParentLevel.length > 0 ? VBParentLevel.join('') : null;
         }

        /**
         * Retrieve raw qualtric display logic
         * @param surveyQuestion
         * @return qualtricLogic (object|undefine) logic
         */
         function getQualtricDisplayLogic(surveyQuestion){
            let displayLogic = _.get(surveyQuestion.Payload, 'InPageDisplayLogic');
            return displayLogic !== undefined ? displayLogic : _.get(surveyQuestion.Payload, 'DisplayLogic');
         }
        /**
         * transform qualtric display skiplogic to vibrent logic
         * @param surveyQuestion qualtric survey question
         * @return vibrent (string|null)
         */
        function transformDisplayLogic(surveyQuestion){
            let displayLogic = getQualtricDisplayLogic(surveyQuestion);
            let questionName = _.get(surveyQuestion.Payload, 'DataExportTag');
            console.log(`-----Qualtric question Name ${questionName}--displayLogic: ${JSON.stringify(displayLogic)}`);
            let result = transformLogic(displayLogic);
            console.log(`-----Transform for question ${questionName}--displayLogic: ${JSON.stringify(result)}`);
            return result;
        }

        /**
         * Convert core qualtric logic into vibrent form
         * @param qualtricSkipLogic basic qualtric skipping logic
         * @return temporary vibrent skip logic. It is not final logic since we need to retrieve component id in later step
         */
        function transformToVibrentLogic(qualtricSkipLogic){
            let question = qualtricSkipLogic.QuestionID;
            let rightOperand = qualtricSkipLogic.RightOperand;
            let leftOperand = qualtricSkipLogic.LeftOperand;
            let qualtricOperator = qualtricSkipLogic.Operator;
            let choiceType = qualtricSkipLogic.ChoiceLocator;
            let responseCode = (rightOperand !== undefined) ? rightOperand : leftOperand;
            let lastSlashPosition = responseCode.lastIndexOf('/');
            //need to remove / if found. otherwise, use -1 to get whole string
            responseCode = responseCode.substring(lastSlashPosition > 0 ? lastSlashPosition+1 : lastSlashPosition );
            return buildVibrentLogic(question, qualtricOperator, responseCode,choiceType);
        }
        /**
         * Construct vibrent logic
         * @param question questionId
         * @param operator operator. If undefine, it will default to eq
         * @param responseCode response code
         * @returns {string|null} vibrent logic
         */
        function buildVibrentLogic(question, operator, responseCode, choiceType){
            //remove double quote and single quote
            let cleanedResponseCode = responseCode.replace(/"|'/g,'').trim();
            let numOps = qualtricComparativeNumberMap.get(operator);
            let vbOperator = qualtricOperatorMap.get(operator);
            let ops = vbOperator!== undefined ? vbOperator: numOps;
            let result;
            console.log(`Operator ${operator} and numbOps ${numOps} and ops ${ops} -->${choiceType}`);
            if (ops === 'not'){
                result = `${ops} (eq (toString (fieldEntryValueInCurrentView (${question})), "${question}|${cleanedResponseCode}"))`;
            } else if (ops === 'notempty'){
                result = `toString (fieldEntryValueInCurrentView (${question}))`;
            } else {
                if (numOps !== undefined && choiceType.indexOf('ChoiceTextEntryValue') >= 0) {
                    result = `${numOps} (toDouble (toString (fieldEntryValueInCurrentView (${question}))), ${cleanedResponseCode})`;
                } else if (ops !== undefined && choiceType.indexOf('SelectedChoicesCount') >= 0) {
                    result = `${ops} (length (toDoubleList (fieldEntryValueInCurrentView (${question}))), ${cleanedResponseCode})`;
                } else if (ops !== undefined) {
                    result = `${ops} (toString (fieldEntryValueInCurrentView (${question})), "${question}|${cleanedResponseCode}")`;
                } else {
                    result = null;
                }
            }
            return result;
        }
        /**
         * service return
         */
        return {
            transformLogic          : transformLogic,
            transformDisplayLogic   : transformDisplayLogic,
            getQualtricDisplayLogic : getQualtricDisplayLogic,
        };
    });
