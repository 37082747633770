'use strict';

import template from './formEntryState.html';

(function () {

    angular.module('acadiamasterApp').directive('formEntryStateConfig', function () {
        return {
            restrict: 'E',
            template: template,
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.condition.getRoot().programId;
                $scope.formOptions = {
                    form: $scope.condition.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack : function() {
                        $scope.condition.setForm(this.form);
                    }
                };
            }
        };
    });
})();