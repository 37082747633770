/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/**
 * Created by Jeetendra on 07/19/2019
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('communicationPreferenceCategory', CommunicationCategoryService => {
            let communicationCategoryController = function ($scope) {
                let vm = this;
                // Needed for AngularJS upgrade to 1.8 - ng-change requires a model.
                vm.communicationPreferenceArray = [];

                $scope.onCommunicationCategoryChange = category => {
                    if (!vm.communicationCategories.includes(category.id)) {
                        vm.communicationCategories.push(category.id);
                    } else {
                        vm.communicationCategories.splice(vm.communicationCategories.indexOf(category.id), 1);
                    }
                };

                $scope.contains = category => {
                    if (angular.isArray(vm.communicationCategories)) {
                        for (let i = 0; i < vm.communicationCategories.length; i++) {
                            if (category.id == vm.communicationCategories[i]) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                function init () {
                    let promise = CommunicationCategoryService.getAllCategories();
                    promise.then(result => {
                        const programCommunicationsArray = [];
                        result.data.forEach(element => {
                            if (element.id === 100 && element.name.en === 'Program Communications') { // If id and name for program Communications are correct then it will load to the UI
                                programCommunicationsArray.push(element);
                            }
                        });
                        $scope.categories = programCommunicationsArray;
                    });
                }

                init();
            };

            return {
                bindToController : true,
                controller       : communicationCategoryController,
                controllerAs     : 'ccc',
                restrict         : 'E',
                scope            : {
                    communicationCategories : '=',
                    currentSelection        : '=',
                    displayVerticalList     : '<?',
                    titleText               : '@?',
                },
                templateUrl : 'admin-templates/site/communicationCategory/communicationPreferenceCategory.html',
            };
        });
}());
