(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for test case model
     */
        .factory('ProgramTestMappingModelService',
            function (ProgramTestModelService,ModelServiceBase) {
                ProgramTestMappingModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
                function ProgramTestMappingModel(){
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false,null);
                    this.id = null;
                    this.programTestSuite = null;
                    this.programTest = {};
                    this.sequenceNumber = null;
                };
                ProgramTestMappingModel.prototype.toDto = function(){
                    var dto = {};
                    dto.id = this.id;
                    dto.programTestSuite = this.programTestSuite.toDto();
                    dto.programTest = ProgramTestModelService.ProgramTestModel.toDto();
                    dto.sequenceNumber = dto.sequenceNumber;
                    return dto;
                };
                ProgramTestMappingModel.prototype.fromDto = function(dto){
                    this.id = dto.id;
                    this.programTestSuite.fromDto(dto.programTestSuite);
                    this.programTest = ProgramTestModelService.ProgramTestModel.fromDto(dto);
                    this.sequenceNumber = dto.sequenceNumber;
                };
                /***************************************
                 * utility functions
                 ***************************************/
                function loadModelFromDto(dto) {
                    var model = new ProgramTestMappingModel();
                    model.fromDto(dto);
                    return model;
                }

                /***************************************
                 * service return call
                 ***************************************/
                return {
                    loadModelFromDto: loadModelFromDto,
                    ProgramTestMappingModel: ProgramTestMappingModel
                };
            });
})();
