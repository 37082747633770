(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for multi select options
     */
        .directive('formFieldConfigFieldValueMultiSelectOptions', function (FormUtilService, ValueConfigOptionService,
                                                                            FormModelServiceFieldValue,
                                                                            LocaleConstantsService,
                                                                            ImagePresetConstants, ngDialog, FormConstants,
                                                                            vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/multiSelectOptions.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.mode = $scope.fieldElement.findParentMode();
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.ImagePresetConstants = ImagePresetConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.valueConfigOption = ValueConfigOptionService.createValueConfigOption(
                        $scope.fieldElement._parent.fieldValue, $scope.fieldValue);

                    $scope.hasChangeModel = $scope.fieldValueChange != null;
                    if ($scope.hasChangeModel) {
                        initChangeModelValuesIfNeeded($scope);
                    }

                    var units = ['dp', '%'];
                    //width for include image option
                    $scope.widthOptions = createValueWithUnitOptions(null, units);

                    //height for include image option with
                    $scope.heightOptions = createHeightOptions();

                    $scope.marginTextOptions = createMarginPaddingModelOption('Text Margin', 'flaticon-margin',
                        $scope.fieldValue.textMargin, true);
                    $scope.marginImageOptions = createMarginPaddingModelOption('Image Margin', 'flaticon-margin',
                        $scope.fieldValue.imageMargin, true);


                    $scope.getOptionsDisplayMode = function () {
                        return getPropertyValue($scope, 'optionsDisplayMode');
                    };

                    $scope.gotoLinkedElement = function(localId) {
                        var linkedElement = $scope.fieldElement.findParentMode().findLinkedElement($scope.constants.formTargetType.FORM_FIELD,
                            localId);
                        if (linkedElement!=null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, '_parent', ['collapsed', 'sectionCollapsed'], false);
                        }
                    };

                    $scope.addNewOption = function (index) {
                        FormUtilService.addNewOption($scope.fieldValue.values, index,  $scope.constants.groups[1]);
                        if(!$scope.fieldValue.singleLine) {
                            checkAndSetLimitOptions($scope, $scope.fieldValue.values[index].groupNumber);
                        }
                    };

                    $scope.removeOption = function (index) {
                        FormUtilService.removeOption($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionUp = function (index) {
                        FormUtilService.moveOptionUp($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionDown = function (index) {
                        FormUtilService.moveOptionDown($scope.fieldValue.values, index);
                    };

                    $scope.getOptionValueEnabled = function (index) {
                        if ($scope.fieldValueChange == null || $scope.fieldValueChange.values == null ||
                            $scope.fieldValueChange.values[index] == null || $scope.fieldValueChange.values[index].enabled == null) {
                            return $scope.fieldValue.values[index].enabled;
                        }
                        else {
                            return $scope.fieldValueChange.values[index].enabled;
                        }
                    };

                    $scope.getFieldValuePattern = function () {
                        var valueType = $scope.fieldElement._parent.fieldValue.valueType;
                        return FormUtilService.validationUtil.getFieldValuePattern(valueType);
                    };

                    $scope.textPositionChangedCallBack = function (newSelection) {
                        $scope.fieldValue.imagePosition = swapPositions(newSelection);
                    };
                    $scope.imagePositionChangedCallBack = function (newSelection) {
                        $scope.fieldValue.textPosition = swapPositions(newSelection);
                    };

                    $scope.groupChanged=function(newSelection){
                        checkAndSetLimitOptions($scope,newSelection);
                    };

                    $scope.singleSelectChanged=function(newSelection){
                        if(newSelection){
                            initializedLimitOptionValues($scope);
                        }

                        // update image preset value if needed
                        var preset = $scope.fieldValue.imagePreset;
                        if (newSelection && preset == ImagePresetConstants.CHECK_BOX_BLUE){
                            $scope.fieldValue.imagePreset = ImagePresetConstants.CIRCULAR_BLUE;
                        }
                        else if (!newSelection && preset == ImagePresetConstants.CIRCULAR_BLUE) {
                            $scope.fieldValue.imagePreset = ImagePresetConstants.CHECK_BOX_BLUE;
                        }
                    };

                    $scope.limitOptionDisplayOnly = function () {
                        _.forEach($scope.fieldValue.values, function (value) {
                            if(value.groupNumber.name!=$scope.fieldValue.values[0].groupNumber.name){
                                initializedLimitOptionValues($scope);
                                return true;
                            }
                        });
                        return false;
                    };
                    $scope.selectDefaultSelectedOption = function (option) {
                        if ($scope.fieldValue.defaultSelectedOption != option) {
                            $scope.fieldValue.defaultSelectedOption = option;
                        } else {
                            $scope.fieldValue.defaultSelectedOption = null;
                        }
                    };

                    $scope.getIncludeText = function () {
                        return getPropertyValue($scope, 'includeText');
                    };

                    $scope.getSingleLine = function () {
                        return getPropertyValue($scope, 'singleLine');
                    };

                    $scope.getIncludeImage = function () {
                        return getPropertyValue($scope, 'includeImage');
                    };

                    $scope.includeImageChanged = function (newIncludeImage) {
                        includeImageChanged($scope, newIncludeImage);
                    };

                    $scope.includeTextDisplayOnly = function () {
                        return true;
                    };

                    $scope.includeTextChanged = function (newIncludeText) {
                        includeTextChanged($scope, newIncludeText);
                    };

                    $scope.setImagePosition = function (position) {
                        setImagePosition($scope, position);
                    };

                    $scope.setTextPosition = function (position) {
                        setTextPosition($scope, position);
                    };

                    $scope.sortValues = function() {
                        sortValues($scope);
                    };

                    $scope.showEditWindow = function (optionValue) {
                        var template = '<embedded-field-link data="data" close-function="closeFunction"></embedded-field-link>';

                        var data = {
                            mode: $scope.mode,
                            optionValue: optionValue,
                            component: $scope.fieldElement._parent._parent
                        };

                        // open modal window and pass in all the scope variables
                        var dialog = ngDialog.open({
                            showClose: false,
                            closeByEscape : false,
                            closeByDocument : false,
                            template: template,
                            plain: true,
                            controller : ['$scope', function($scope) {
                                $scope.data = data;
                                $scope.closeFunction = closeDialog;
                            }],
                            height : '90%',
                            className : 'ngdialog-theme-plain custom-width-large'
                        });


                        function closeDialog() {
                            optionValue = data.optionValue;
                            dialog.close();
                        }
                    };
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/

            /**
             * sort all the values alphabetically
             * @param $scope
             */
            function sortValues($scope) {
                $scope.fieldValue.values.sort(function(v1, v2) {
                    if(v1.value < v2.value) {
                        return -1;
                    }

                    if(v1.value > v2.value) {
                        return 1;
                    }

                    return 0;
                });
            }

            function checkAndSetLimitOptions($scope,group){
                _.forEach($scope.fieldValue.values, function (value) {
                    if(value.groupNumber.name!=group.name){
                        $scope.fieldValue.limitOptionSelection=false;
                    }
                });
            }
            function initializedLimitOptionValues($scope){
                $scope.fieldValue.limitOptionSelection=false;
                $scope.fieldValue.minOptionSelection=null;
                $scope.fieldValue.maxOptionSelection=null;
            }
            function initChangeModelValuesIfNeeded($scope) {
                if ($scope.fieldValueChange.values == null) {
                    $scope.fieldValueChange.values = [];
                }

                for (var i = 0; i < $scope.fieldValue.values.length; i++) {
                    if ($scope.fieldValueChange.values[i] == null) {
                        $scope.fieldValueChange.values[i] = {
                            enabled: null
                        };
                    }
                }
            }

            function swapPositions(value) {
                var target = null;
                if (value == FormConstants.position.RIGHT) {
                    target = FormConstants.position.LEFT;
                } else if (value == FormConstants.position.LEFT) {
                    target = FormConstants.position.RIGHT;
                }
                return target;
            }

            /**
             * generic function to get property value by key while using hasChangeModel property
             * @param $scope
             * @param key
             */
            function getPropertyValue($scope, key) {
                var r = null;
                if ($scope.hasChangeModel) {
                    r = $scope.fieldValueChange[key];
                }

                if (r == null) {
                    r = $scope.fieldValue[key];
                }

                return r;
            }

            function createValueWithUnitOptions(title, units) {
                return {
                    title: title,
                    units: units
                };
            }

            function includeImageChanged($scope, newIncludeImage) {
                if (newIncludeImage) {
                    setImagePosition($scope, findFirstAvailablePosition(getTextPosition($scope)));
                }
                else {
                    setImagePosition($scope, null);
                }
            }


            function setTextPosition($scope, position) {
                if ($scope.hasChangeModel) {
                    $scope.fieldValueChange.textPosition = position;
                }
                else {
                    $scope.fieldValue.textPosition = position;
                }
            }

            function setImagePosition($scope, newPosition) {
                if ($scope.hasChangeModel) {
                    $scope.fieldValueChange.imagePosition = newPosition;
                }
                else {
                    $scope.fieldValue.imagePosition = newPosition;
                }
            }

            function includeTextChanged($scope, newIncludeText) {
                if (newIncludeText) {
                    setTextPosition($scope, findFirstAvailablePosition(getImagePosition($scope)));
                }
                else {
                    setTextPosition($scope, null);
                }
            }

            function getTextPosition($scope) {
                return getPropertyValue($scope, 'textPosition');
            }

            function getImagePosition($scope) {
                return getPropertyValue($scope, 'imagePosition');
            }

            function findFirstAvailablePosition(unavailablePosition) {
                for (var key in FormConstants.position) {
                    if (FormConstants.position.hasOwnProperty(key) &&
                        FormConstants.position[key].name != unavailablePosition.name) {
                        return FormConstants.position[key];
                    }
                }
            }

            function createMarginPaddingModelOption(title, icon, model, careAboutParentWrapContent) {
                return {
                    title: title,
                    icon: icon,
                    model: model,
                    careAboutParentWrapContent: careAboutParentWrapContent
                };
            }

            function createHeightOptions() {
                return {
                    careAboutParentWrapContent: true,
                    unitsNormal: ['dp', '%'],
                    unitsParentIsWrapContent: ['dp'],
                    required: false,
                    mustBePositive: true,
                    noHigherThan100Percent: true,
                    noHigherThan100PercentCombined: false
                }
            }

        });
})();

