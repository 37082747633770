/**
 * Created by Jamie Nola on 05/21/2020
 * Container Item Template Content Data
 */

const defaults = {
    lang: 'en',
    imageMain: {
        imageRef: 'FORM_ICON',
        gradientRef: 'FORM_GRADIENT',
    },
    imageMainMobile: {
        imageRef: 'FORM_ICON',
        gradientRef: 'FORM_GRADIENT',
    },
    defaultReplacements: {
        SYNC_DEVICES: '#/tools/templates',
        SETTINGS: '#/tools/templates',
        MY_DATA: '#/tools/templates',
        AGREEMENTS: '#/tools/templates',
    }
};

// The Basics
const theBasics = {
    ...defaults,
    defaultReplacements: {
        ...defaults.defaultReplacements,
        FORM_ICON: 'PBVibrent-THVTN3o4Ylh3cGNGTVZTcLW9ThDZDw6SHbjYAqUHy1ttq6EkitbIzoi3C89uCpPvdiE1jgoQrgo9QAOLRgaNfTgeavBJ9Fte2mH9m_K3QCUr5lWZ0dv5qeK5TcPEEn4kgg4oRZKykhdjXS49bRo',
        FORM_NAME: 'The Basics',
        FORM_ESTIMATED_COMPLETION_TIME: '15 minutes',
        FORM_GRADIENT: 'BLUE_PINK_1',
    },
};

// Lifestyle
const lifestyle = {
    ...defaults,
    defaultReplacements: {
        ...defaults.defaultReplacements,
        FORM_ICON: 'PBVibrent-THVTN3o4Ylh3cGNGTVZTcLW9ThDZDw6SHbjYAqUHy1ttq6Eki9HIzoi3C89uCpPvdiE1jgoQrgo9QAOLRgaNfTgeavBJ9Fte2mH9m_K3QCUr5lWZ0dv5qeK5TcPEEn4kggAwUJ6ilS48QEB-e1163Syn9TODhEcGiGpYxKMS4HXkSEKvKDfy-gOWUcAcBKMlecdXAL9kjmuOxA',
        FORM_NAME: 'Lifestyle',
        FORM_ESTIMATED_COMPLETION_TIME: '5 minutes',
        FORM_GRADIENT: 'GREEN_LIGHT_BLUE_1',
    },
};

// Overall Health
const overallHealth = {
    ...defaults,
    defaultReplacements: {
        ...defaults.defaultReplacements,
        FORM_ICON: 'PBVibrent-THVTN3o4Ylh3cGNGTVZTcLW9ThDZDw6SHbjYAqUHy1ttq6EkitbIzoi3C89uCpPvdiE1jgoQrgo9QAOLRgaNfTgeavBJ9Fte2mH9m_K3QCUr5lWZ0dv5qeK5TcPEEn4kggM_U4mwjTtwbWUsbwk6rDaAti7UmFkV32k',
        FORM_NAME: 'Overall Health',
        FORM_ESTIMATED_COMPLETION_TIME: '3 minutes',
        FORM_GRADIENT: 'ORANGE_GREEN_1',
    },
};


angular.module('acadiamasterApp')
    .constant('ContainerItemTemplateContent', (() => [
        {
            content: theBasics,
            group: 'Forms',
            key: 'The Basics',
        },
        {
            content: lifestyle,
            group: 'Forms',
            key: 'Lifestyle',
        },
        {
            content: overallHealth,
            group: 'Forms',
            key: 'Overall Health',
        },
        {
            key: 'None (use default template values)',
        },
    ])());
