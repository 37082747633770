(function () {
    'use strict';

    /***************************************************************
     * Precondition for feature toggle
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionFeatureToggleModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, ProgramLoadUtil) {

            PreConditionFeatureToggleModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for feature toggle with status
             * @constructor
             */
            function PreConditionFeatureToggleModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.FEATURE_TOGGLED_STATUS);

                this.programId = null;
                this.toggleStatus = false;
                this.featureTypeEnum = null;

                this._program = null;
            }

            PreConditionFeatureToggleModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.programId = dto.programId;
                this.toggleStatus = dto.toggleStatus;
                this.featureTypeEnum = dto.featureTypeEnum;

                ProgramLoadUtil.loadProgram(this);
            };

            PreConditionFeatureToggleModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.programId = this.programId;
                dto.toggleStatus = this.toggleStatus;
                dto.featureTypeEnum = this.featureTypeEnum;

                return dto;
            };

            PreConditionFeatureToggleModel.prototype.setProgram = function (program) {
                this._program = program;
                this.programId = program==null ? null : program.id;
            };

            PreConditionFeatureToggleModel.prototype.getProgram = function () {
                return this._program;
            };

            PreConditionFeatureToggleModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);
                var featureTypeMsg = this.featureTypeEnum==null ? '<span class="text-danger">Type Not Defined</span>' :
                    '<span class="badge">' + this.featureTypeEnum + '</span>';

                var programMsg = 'Program (' + (this.programId == null ? 'no id' : this.programId) + ' | ' +
                    (this._program == null ? 'no name' :  this._program.name) + ')';

                var statusMsg = this.toggleStatus ? ' enabled ' : 'disabled';

                return typeMsg + featureTypeMsg + ' of ' + programMsg +  ' should be ' + statusMsg;
            };

            PreConditionFeatureToggleModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.programId == null) {
                    this.setErrorMessage('program id is required');
                    return;
                }

                if (this.featureTypeEnum == null) {
                    this.setErrorMessage('feature type is required');
                    return;
                }

                if (this.toggleStatus == null) {
                    this.setErrorMessage('toggle status is required');
                }
            };


            return PreConditionFeatureToggleModel;
        });
})();
