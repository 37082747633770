(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultNavigationNodeService',
        function (CompareResultUtilService, CompareResultNavigationEdgeService) {

            function compareNodeDetail(node1, node2) {
                var result = {
                    localId: CompareResultUtilService.comparePrimitives(node1.localId, node2.localId),
                    name: CompareResultUtilService.comparePrimitives(node1.name, node2.name),
                    pageName: CompareResultUtilService.comparePrimitives(node1.page != null ? node1.page.name : null,
                        node2.page != null ? node2.page.name : null),
                    pageId: CompareResultUtilService.comparePrimitives(node1.page != null ? node1.page.localId : null,
                        node2.page != null ? node2.page.localId : null)
                };

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            /**
             * main comparison function to compare two nodes
             */
            function compare(node1, node2) {
                var title;
                if (node1 == null) {
                    node1 = {};
                    title = 'Node ' + node2.localId;
                }
                else {
                    title = 'Node ' + node1.localId;
                }

                if (node2 == null) {
                    node2 = {};
                }

                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareNodeDetail(node1, node2),
                    edgesFrom: CompareResultUtilService.compareArrays(node1._edgesFrom, node2._edgesFrom, title,
                        CompareResultNavigationEdgeService.compare, 'evaluationOrder'),
                    edgesTo: CompareResultUtilService.compareArrays(node1._edgesTo, node2._edgesTo, title,
                        CompareResultNavigationEdgeService.compare, 'evaluationOrder')
                };

                result._edgesFromStatus = CompareResultUtilService.collectStatus(result.edgesFrom);
                result._edgesToStatus = CompareResultUtilService.collectStatus(result.edgesTo);
                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

