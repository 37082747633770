/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp')
        .directive('vbrDrcPairingUpdate', (ngDialog, PairingService, AlertService) => {
            return {
                link : function ($scope) {
                    $scope.userPairingCopy = angular.copy($scope.userPairingInfo);
                    $scope.showingConfirmationPart = false;
                    $scope.showResetMessage = false;
                    $scope.showingConfirmationPart = false;
                    $scope.awardees = null;

                    PairingService.getAllAvailableOptions()
                        .then(response => {
                            $scope.awardees = response.data?.awardees;
                            addUnsetOptionsToAll($scope.awardees);
                            let isValid = validate($scope.awardees, $scope.userPairingInfo);
                            if (!isValid) {
                                $scope.showResetMessage = true;
                                $scope.userPairingInfo.awardeeId = 'UNSET';
                                $scope.userPairingInfo.orgId = 'UNSET';
                                $scope.userPairingInfo.siteId = 'UNSET';
                            }
                        });

                    $scope.closePopup = function () {
                        ngDialog.close();
                    };

                    $scope.updatePairing = function () {
                        $scope.showingConfirmationPart = true;
                    };

                    $scope.onAwardeeChange = function () {
                        $scope.userPairingInfo.orgId = 'UNSET';
                        $scope.userPairingInfo.siteId = 'UNSET';
                    };

                    $scope.onOrgChange = function () {
                        $scope.userPairingInfo.siteId = 'UNSET';
                    };

                    $scope.back = function () {
                        $scope.showingConfirmationPart = false;
                    };

                    $scope.confirm = function () {
                        PairingService.update($scope.userPairingInfo)
                            .then(() => {
                                AlertService.success(`Site pairing details updated for user id: ${ $scope.userPairingInfo.userId}`);
                                ngDialog.close();
                                $scope.onSuccessCallback($scope.userPairingInfo);
                            });
                    };

                    $scope.hasChanges = function () {
                        return $scope.userPairingCopy.awardeeId != $scope.userPairingInfo.awardeeId
                            || $scope.userPairingCopy.orgId != $scope.userPairingInfo.orgId
                            || $scope.userPairingCopy.siteId != $scope.userPairingInfo.siteId;
                    };

                    $scope.getAvailableOrgs = function () {
                        for (let i = 0; i < $scope.awardees.length; i++) {
                            if ($scope.awardees[i].id === $scope.userPairingInfo.awardeeId) {
                                return $scope.awardees[i].organizations;
                            }
                        }
                        return [];
                    };

                    $scope.getAvailableSites = function () {
                        for (let i = 0; i < $scope.awardees.length; i++) {
                            if ($scope.awardees[i].id === $scope.userPairingInfo.awardeeId) {
                                let orgs = $scope.awardees[i].organizations;
                                if (orgs != null) {
                                    for (let j = 0; j < orgs.length; j++) {
                                        if (orgs[j].id === $scope.userPairingInfo.orgId) {
                                            return orgs[j].sites;
                                        }
                                    }
                                }
                            }
                        }
                        return [];
                    };
                },
                restrict : 'E',
                scope    : {
                    onSuccessCallback : '=',
                    userPairingInfo   : '=',
                },
                templateUrl : 'admin-templates/site/drc/pairingManagement/searchUpdate/drc.pairingUpdate.html',
            };

            function validate (awardees, userPairingInfo) {
                if (userPairingInfo.awardeeId == null) {
                    return true;
                }
                for (let i = 0; i < awardees.length; i++) {
                    if (awardees[i].id === userPairingInfo.awardeeId) {
                        if (userPairingInfo.orgId == null) {
                            return true;
                        }

                        let orgs = awardees[i].organizations;
                        for (let j = 0; j < orgs.length; j++) {
                            if (orgs[j].id === userPairingInfo.orgId) {
                                if (userPairingInfo.siteId == null) {
                                    return true;
                                }
                                let sites = orgs[j].sites;

                                for (let k = 0; k < sites.length; k++) {
                                    if (sites[k].id === userPairingInfo.siteId) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
                return false;
            }

            function getUnsetOrg () {
                return {
                    displayName : 'No organization set',
                    id          : 'UNSET',
                    sites       : [],
                };
            }

            function getUnsetSite () {
                return {
                    displayName : 'No site set',
                    id          : 'UNSET',
                };
            }

            function addUnsetOptionsToAll (awardees) {
                if (awardees != null) {
                    for (let i = 0; i < awardees.length; i++) {
                        if (awardees[i].organizations == null) {
                            awardees[i].organizations = [];
                        }
                        awardees[i].organizations.push(getUnsetOrg());

                        for (let j = 0; j < awardees[i].organizations.length; j++) {
                            if (awardees[i].organizations[j].sites == null) {
                                awardees[i].organizations[j].sites = [];
                            }
                            awardees[i].organizations[j].sites.push(getUnsetSite());
                        }
                    }
                }
            }
        });
}());
