'use strict';

(function () {
    angular.module('acadiamasterApp')
        .config(function ($stateProvider, $resourceProvider) {
    	// Added this line due to below issue
    	// I was calling a webservice with URL /api/program/nouserprofileforms/ and this no query params
    	// Upon hitting the webservice trailing slash was being stripped and the call was made to /api/program/nouserprofileforms
    	// which meant /api/program/ was called with path variable value as no userprofileform
    	$resourceProvider.defaults.stripTrailingSlashes = false;

        $stateProvider
            .state('home', {
                parent: 'site',
                url: '/',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    hideBreadcrumb: true
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/main.html',
                        controller: 'MainController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('main');
                        return $translate.refresh();
                    }]
                }
            });
    });
})();

