(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for QGroup model, this model has a join condition and one or more subItems
     */
    app.factory('QGroupModel', function (QObjectModel, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        QGroupModel.inheritsFrom(QObjectModel);

        function QGroupModel(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.GROUP_OBJECT);

            //initialized the variables
            this.joinCondition = ProgramTestConstantsService.joinConditions.AND;
            this.subItems = [];
        }

        /**
         * convert the current UI model to dto format
         */
        QGroupModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.joinCondition = this.joinCondition;
            dto.subItems = [];
            _.forEach(this.subItems, function (item) {
                dto.subItems.push(item.toDto());
            });
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        QGroupModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.joinCondition = dto.joinCondition;

            // subItem is being processed outside of this, it will be handled by IfAssertConditionCreatorUtil.createByDto
        };

        QGroupModel.prototype.addCondition = function(newCondition, selector) {
            if (this.subItems==null) {
                this.subItems = [];
            }

            this.subItems.push(newCondition);

            if (selector != null) {
                selector.selectItem(newCondition);
            }
        };

        QGroupModel.prototype.removeCondition = function(condition, selector) {
            if (this.subItems==null || condition==null) {
                return;
            }

            var index = _.findIndex(this.subItems, function(si) {
                return si === condition;
            });

            if (index!=-1) {
                this.subItems.splice(index, 1);
                if (selector != null) {
                    selector.itemRemoved(condition);
                }
            }
        };

        QGroupModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.joinCondition == null) {
                this.setErrorMessage('join condition is required');
                return;
            }

            var itemSize = this.subItems==null ? 0 : this.subItems.length;

            if (this.joinCondition == ProgramTestConstantsService.joinConditions.NOT) {
                if (itemSize !== 1) {
                    this.setErrorMessage('NOT condition should take 1 and only 1 sub item');
                }
            }
            else if (itemSize < 2) {
                this.setErrorMessage('AND/OR condition should take at least 2 sub items');
            }
         };

        QGroupModel.prototype.getDescriptionAsHtml = function () {
            var message = '<span class="badge badge-warning">Join Condition</span> ';

            message += ' <span class="badge badge-info">' + this.joinCondition + '</span>';

            return message;
        };

        QGroupModel.prototype.getDescriptionAsHtmlFullTree = function() {
            var message = this.getDescriptionAsHtml();

            if(this.subItems == null || this.subItems.length == 0){
                return message;
            }
            _.forEach(this.subItems, function (item) {
                if (item.type == ProgramTestConstantsService.QObjectType.GROUP_OBJECT) {
                    message += '<div class="row-indentation"> ' + item.getDescriptionAsHtmlFullTree() + '</div>';
                } else {
                    message += '<div class="row-indentation"> ' + item.getDescriptionAsHtml() + ' </div>';
                }
            });

            return message;
        };



        /***************************************
         * service return call
         ***************************************/
        return QGroupModel;
    });
})();
