/**
 * Created by Jamie Nola on 09/09/2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('FeaturesService', function($http, localWeb) {
            var url = localWeb.createUrl('api/appConfig/features');
            return {
                update: function(data) {
                    return $http.post(url, data);
                },
                get: function () {
                    return $http.get(url);
                }
            };
        });
})();
