/**
 * Created by shekhar.sukhadeve on 2/21/2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for action configuration
     */

        .directive('formElementConfigActionSetFieldValue', function (ExpressionConstantsService, RuleConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfigActionSetFieldValue.html',
                scope: {
                    element: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.rc = RuleConstantsService;
                    $scope.avoidList = [RuleConstantsService.accountInformationField.DOB,
                        RuleConstantsService.accountInformationField.EMAIL,
                        RuleConstantsService.accountInformationField.ADDRESS_UNIT_NUMBER,
                        RuleConstantsService.accountInformationField.PREFERENCE_TIMEZONE,
                        RuleConstantsService.accountInformationField.PREFERENCE_LOCALE,
                        RuleConstantsService.accountInformationField.PREFERENCE_EMAIL_NOTIFICATION,
                        RuleConstantsService.accountInformationField.PREFERENCE_PUSH_NOTIFICATION,
                        RuleConstantsService.accountInformationField.USER_ETHNICITY,
                        RuleConstantsService.accountInformationField.USER_RACE,
                        RuleConstantsService.accountInformationField.USER_GENDER];
                    $scope.expConstants = ExpressionConstantsService;

                }
            };
        });
})();
