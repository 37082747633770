/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for state rule condition
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultStateRuleConditionService',
        function (CompareResultUtilService, CompareResultStateRuleActionService) {

            function compareConditionDetail(condition1, condition2) {
                var result = {};

                CompareResultUtilService.comparePrimitivesByKey(condition1, condition2, "localId", result);
                CompareResultUtilService.comparePrimitivesByKey(condition1, condition2, "name", result);

                CompareResultUtilService.compareCodeBlocksByKey(condition1, condition2, "conditionText", result);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }


            function compare(condition1, condition2, parentTitle) {
                var title;
                if (condition1 == null) {
                    condition1 = {};
                    title = "Condition " + condition2.localId;
                }
                else {
                    title = "Condition " + condition1.localId;
                }

                if (condition2 == null) {
                    condition2 = {};
                }


                // add name to title
                if (condition2.name != null) {
                    title += " (" + condition2.name + ")";
                }
                else if (condition1.name != null) {
                    title += " (" + condition1.name + ")";
                }

                if (parentTitle != null)
                    title += " - " + parentTitle;


                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareConditionDetail(condition1, condition2)
                };

                result.actions = CompareResultUtilService.compareArrays(condition1.actions, condition2.actions, title,
                    CompareResultStateRuleActionService.compare);

                result._actionsStatus = CompareResultUtilService.collectStatus(result.actions);
                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

