/* eslint-disable max-len */
import ContainerItemType from '../../const/ContainerItemType.const';
import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 *
 * directive for configuring a container item
 */

angular.module('acadiamasterApp').directive('programContainerItemConfig', (ngDialog, ConfigurationServer, AlertService, CONFIG) => ({
    link ($scope) {
        const TAB_BASIC = { description : 'Basic Information of a container item', isVisible : true, name : 'Basic Info' };
        const TAB_CONDITIONS = { description : 'Visibility and Unlock Conditions', isVisible : true, name : 'Conditions' };
        const TAB_TYPE_SPECIFIC = { description : 'Type Specific configuration such as isPausable, completion limits, etc', isVisible : true, name : 'Type Specific' };
        const TAB_TEMPLATE = { description : 'Template Configuration', isVisible : true, name : 'Template' };

        const containerTypes = ProgramContainerConstantsService.containerType;

        $scope.tabs = [ TAB_BASIC, TAB_CONDITIONS, TAB_TYPE_SPECIFIC, TAB_TEMPLATE ];

        $scope.selectedTab = $scope.tabs[0];

        $scope.selectTab = function (tab) {
            $scope.selectedTab = tab;
        };

        $scope.isTabSelected = tab => tab === $scope.selectedTab;

        $scope.isBasicInfoSelected = () => $scope.selectedTab === TAB_BASIC;
        $scope.isConditionSelected = () => $scope.selectedTab === TAB_CONDITIONS;
        $scope.isTypeSpecificSelected = () => $scope.selectedTab === TAB_TYPE_SPECIFIC;
        $scope.isTemplateSelected = () => $scope.selectedTab === TAB_TEMPLATE;

        function setExportButtonEnabled () {
            // export button is not enabled for new unsaved container items
            const container = $scope.containerItem._parent;
            if (!container || container.id === null || !CONFIG.configurationServer) {
                return false;
            }

            // enable only for SINGLE_FORM or MULTI_FORM containers
            return container.containerType === containerTypes.SINGLE_FORM || container.containerType === containerTypes.MULTI_FORM;
        }

        $scope.data = {
            isExportButtonEnabled : setExportButtonEnabled(),
        };

        function downloadAsFile (fileName, response) {
            const a = document.createElement('a');
            const file = new Blob([ response.data ], { type : response.contentType });
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }

        $scope.exportContainerItem = () => {
            // show confirmation window
            ngDialog.openConfirm({
                template : 'admin-templates/site/programManagement/program/containerManagement/config/containerItem/export/exportContainerItemModal.template.html',
            }).then(() => {
                // api call
                let containerItemId = $scope.containerItem.id;
                ConfigurationServer.exportContainerItem(containerItemId)
                    .then(response => {
                        const fileName = `container_item_${containerItemId}.zip`;
                        downloadAsFile(fileName, response);
                        AlertService.success(`container item ${containerItemId} downloaded.`);
                    }, error => {
                        console.error('Error getting a container item from API', error);
                        AlertService.error('Error getting a container item from API ', containerItemId, error);
                    });
            });
        };

        /**
         * If VIEW_SHARED_EHR item type, there should not be a type specific config
         * set isVisible to false for TAB_TYPE_SPECIFIC and filter out the TAB_TYPE_SPECIFIC from the array of tabs
         */
        function init () {
            // add container item export
            if ($scope.containerItem && $scope.containerItem.type === ContainerItemType.VIEW_SHARED_EHR) {
                TAB_TYPE_SPECIFIC.isVisible = false;
                $scope.tabs = $scope.tabs.filter(value => value.name !== TAB_TYPE_SPECIFIC.name);
            }
            // disable template for content item
            if ($scope.containerItem && $scope.containerItem.type === ContainerItemType.CONTENT) {
                TAB_TEMPLATE.isVisible = false;
                $scope.tabs = $scope.tabs.filter(value => value.name !== TAB_TEMPLATE.name);
            }
            // disable type specific and template for measurements item
            if ($scope.containerItem && $scope.containerItem.type === ContainerItemType.MEASUREMENTS) {
                TAB_TEMPLATE.isVisible = false;
                $scope.tabs = $scope.tabs.filter(value =>
                    value.name !== TAB_TYPE_SPECIFIC.name
                    && value.name !== TAB_TEMPLATE.name,
                );
            }
        }

        init();
    },
    restrict : 'E',
    scope    : {
        containerItem : '=',
        containerList : '<',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/containerItem/programContainerItemConfig.html',
}));
