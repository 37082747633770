
angular.module('acadiamasterApp')
    .controller('AuditsController', ($scope, $filter, AuditsService, ParseLinks) => {
        let loadAudit = function (fromDate, toDate, page) {
            AuditsService.findByDates(fromDate, toDate, page).then(data => {
                $scope.links = ParseLinks.parse(data.headers('link'));
                $scope.audits = data.data;
            });
        };

        $scope.loadAll = function (page) {
            let dateFormat = 'yyyy-MM-dd';
            let fromDate = $filter('date')($scope.fromDate, dateFormat);
            let toDate = $filter('date')($scope.toDate, dateFormat);

            loadAudit(fromDate, toDate, page);
        };

        $scope.onChangeDate = function () {
            $scope.loadAll(0);
        };

        $scope.page = 0;

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll(page);
        };

        // Date picker configuration
        $scope.today = function () {
            // Today + 1 day - needed if the current day must be included
            let today = new Date();
            $scope.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        };

        $scope.previousMonth = function () {
            let fromDate = new Date();
            if (fromDate.getMonth() === 0) {
                fromDate = new Date(fromDate.getFullYear() - 1, 0, fromDate.getDate());
            } else {
                fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
            }

            $scope.fromDate = fromDate;
        };

        $scope.today();
        $scope.previousMonth();
        $scope.onChangeDate();
    });
