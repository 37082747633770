/**
 * program Branding configuration
 */

(function () {
    angular.module('acadiamasterApp').directive('programBrandingConfig',
        () => {
            return {
                controller   : 'brandingConfigController',
                controllerAs : 'bc',
                restrict     : 'E',
                scope        : {
                    programId    : '@',
                    showReadOnly : '=',
                },
                templateUrl : 'admin-templates/site/programManagement/program/brandingDetails/program.branding.html',
            };
        });
}());
