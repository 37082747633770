(function () {
    'use strict';

    /**
     * simple service for getting app config from server
     */
    angular.module('acadiamasterApp').factory('AppConfigService', function ($http) {

        var apiUrl = '/api/appConfig';

        return {
            getAppConfig: function () {
                return $http.get(apiUrl);
            }
        }
    });
})();
