/**
 * Created by Jamie Nola on 05/13/2019
 */

angular.module('acadiamasterApp')
    .factory('ExpressionConditionService', ($http, localWeb) => {
        // Creates/saves an expression condition
        function createOrUpdateCondition(data, onSaveFinished) {
            return $http({
                data: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                url: localWeb.createUrl('api/expressionCondition'),
            }).then((resp) => {
                if (_.isFunction(onSaveFinished)) {
                    onSaveFinished(resp);
                }
            }, (error) => {
                console.error(error);
            });
        }

        function deleteCondition(conditionId) {
            return $http.delete(localWeb.createUrl(`/api/expressionCondition/${conditionId}`));
        }

        function getConditionById(id) {
            return $http.get(localWeb.createUrl(`/api/expressionCondition/${id}`));
        }

        /**
         * Check if the given name is available
         * @param name Name we are trying to save
         * @param id Id of the case (To avoid comparison with own name during edit)
         */
        function isNameAvailable(name, id) {
            return $http.get(localWeb.createUrl(`api/expressionCondition/isNameAvailable?name=${name}${id != null ? `&id=${id}` : ''}`));
        }

        function loadConditions(params) {
            return $http.get(localWeb.createUrl('/api/expressionCondition/search'), {
                params,
            });
        }

        /**
         * method to check whether selected childId already using parent by in direct or indirect way
         * @param parentId - expression id which we are trying to save
         * @param childId - selected expression id which is to be saved in parent id
         * @return  true -  if these 2 ids will cause circular dependency,
         *                      i.e. E1 is using E2 and we are trying to save E2 with E1
         *                      i.e. E1 using E2, E2 using E3 and we are trying to save E3 with E1
         *          false - if no circular dependency
         */
        function checkCircularDependency(qObject, id) {
            var url = localWeb.createUrl(`api/expressionCondition/checkCircularDependency`);
            var data = {
                id: id,
                conditionTree: qObject
            };
            return $http.post(url, data);
        };

        return {
            createOrUpdateCondition,
            deleteCondition,
            getConditionById,
            isNameAvailable,
            loadConditions,
            checkCircularDependency
        };
    });
