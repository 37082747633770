export const DefaultManageConfig = {
    emailOption: {
        type: 'Email',
        buttonLabel: {
            en: 'Email',
            es: 'Correo electrónico',
        },
        textLabel: {
            en: 'Email a copy of my consent form',
            es: 'Enviar una copia del consentimiento a mi correo electrónico',
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 1,
    },
    viewOption: {
        type: 'View',
        buttonLabel: {
            en: 'View',
            es: 'Ver',
        },
        textLabel: {
            en: 'View a copy of my consent form',
            es: 'Ver una copia de mi consentimiento',
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 2,
    },
    withdrawOption: {
        type: 'Withdraw',
        buttonLabel: {
            en: 'Withdraw',
            es: 'Retirar',
        },
        textLabel: {
            en: 'Withdraw from my consent',
            es: 'Retirar de mi consentimiento',
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 3,
    },
    updateOption: {
        type: 'Update',
        buttonLabel: {
            en: 'Update',
            es: 'Actualizar'
        },
        textLabel: {
            en: 'Update my decision',
            es: 'Actualiza mi decisión',
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 4,
    },
    stopParticipatingOption: {
        type: 'Stop participating',
        buttonLabel: {
            en: 'Stop participating',
            es: 'Dejar de participar',
        },
        textLabel: {
            en: 'Stop participating',
            es: 'Dejar de participar',
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 5,
    },
    reconsentOption: {
        type: 'Reconsent',
        buttonLabel: {
            en: 'Re-consent',
            es: 'Dar consentimiento de nuevo'
        },
        textLabel: {
            en: 'Re-consent',
            es: 'Dar consentimiento de nuevo'
        },
        primaryFlag: false,
        showInContainer: false,
        orderNumber: 0,
    },
};