/**
 * Created by Lulu Wang on 07/19/2019
 */

import template from './programAgreementConfig.template.html';

angular.module('acadiamasterApp')
    .directive('programAgreementConfig', (
        $stateParams, $state, CONFIG, AgreementsService,
        ExpressionConstantsService, LocaleConstantsService,
        ModelServiceConditionExpression, ParameterConfigModelService,
        FormLoadUtil, LocaleJsonService, AlertService, $translate, SimpleFileDTOModel, FormConstants,
        RuleConstantsService, EmailConfigModel, LabelConfigModel, ManageConfigModel, FormUtilService,
        ExpirationConfigModel, ExpirationConfigDataListModel,
    ) => {
        return {
            link : $scope => {
                const AGREEMENTS_TYPE = {
                    DATA_SHARING         : 'DATA_SHARING',
                    DISCLOSURE           : 'DISCLOSURE',
                    MULTI_INSTANCE       : 'MULTI_INSTANCE',
                    PRIMARY_CONSENT      : 'PRIMARY_CONSENT',
                    SUPPLEMENTAL_CONSENT : 'SUPPLEMENTAL_CONSENT',
                };
                $scope.connectedServiceOptions = FormConstants.connectedService;
                $scope.flags = {
                    _agreementListReady : false,
                    _enumReady          : false,
                    isReady             : function () {
                        return this._enumReady && this._agreementListReady;
                    },
                    reset : function () {
                        this._enumReady = false;
                        this._agreementListReady = false;
                    },
                    setAgreementListReady : function (ready) {
                        this._agreementListReady = ready;
                    },
                    setEnumReady : function (ready) {
                        this._enumReady = ready;
                    },
                };
                $scope.allowGenericPrimary = false;
                $scope.lcs = LocaleConstantsService;
                $scope.isReadOnlyServer = CONFIG.readOnlyServer;
                $scope.isWithdrawModeUpdate = false;
                $scope.agreementsType = AGREEMENTS_TYPE;
                $scope.consentFormOptions = {
                    form             : null,
                    formCategory     : FormConstants.formCategories.CONSENT_FORM,
                    onChangeCallBack : function () {
                        if (this.form) {
                            $scope.data.formId = this.form.id;
                        } else {
                            $scope.data.formId = null;
                        }
                    },
                    title    : $translate.instant('agreements.create.entries.form.hint'),
                    viewOnly : false,
                };

                $scope.withdrawFormOptions = {
                    form             : null,
                    formCategory     : 'DATA_FORM',
                    onChangeCallBack : function () {
                        if (this.form && $scope.data.type != 'DISCLOSURE') {
                            $scope.data.withdrawFormId = this.form.id;
                        } else {
                            $scope.data.withdrawFormId = null;
                        }
                    },
                    title    : $translate.instant('agreements.create.entries.withdrawForm.hint'),
                    viewOnly : false,
                };

                $scope.setType = capabilities => {
                    $scope.capabilities = capabilities;
                    if (capabilities) {
                        $scope.data.type = capabilities.type;
                        if ($scope.data.type === $scope.agreementsType.DISCLOSURE) {
                            $scope.data.formId = null;
                            $scope.consentFormOptions.form = null;
                            $scope.data.withdrawFormId = null;
                            $scope.withdrawFormOptions.form = null;
                            $scope.data.allowWithdrawFromSettings = false;
                            if (!$scope.data.documents) {
                                $scope.data.documents = new SimpleFileDTOModel();
                            }
                            $scope.consentFormOptions.formCategory = FormConstants.formCategories.DATA_FORM;
                        } else {
                            $scope.consentFormOptions.formCategory = FormConstants.formCategories.CONSENT_FORM;
                            $scope.data.documents = null;
                        }

                        if (capabilities.templates) {
                            if (capabilities.templates != null && capabilities.templates.length === 1) {
                                $scope.data.templateType = capabilities.templates[0];
                            }
                        } else {
                            $scope.data.templateType = null;
                        }

                        if (capabilities.withdrawModes) {
                            if (capabilities.withdrawModes != null && capabilities.withdrawModes.length === 1) {
                                $scope.data.withdrawMode = capabilities.withdrawModes[0];
                            }
                        } else {
                            $scope.data.withdrawMode = null;
                        }
                    } else {
                        $scope.data.type = null;
                        $scope.data.withdrawMode = null;
                        $scope.data.templateType = null;
                    }
                    if ($scope.data.type === $scope.agreementsType.DATA_SHARING && $scope.data.emailEnabled == null) {
                        $scope.data.emailEnabled = false;
                    }
                };

                $scope.cancel = () => {
                    $state.go($scope.stateAfterCancelSave);
                };

                $scope.save = () => {
                    doSave($scope);
                };

                $scope.hasMoreThanOneChoice = inputArray => {
                    return inputArray != null && inputArray.length != null && inputArray.length > 1;
                };

                $scope.hasPrimaryConsent = false;
                $scope.isDisclosureValid = function (data) {
                    if (data.type !== $scope.agreementsType.DISCLOSURE) {
                        if (data.formId === null) {
                            return true;
                        }
                        return false;
                    }
                    if (data.formId === null
                            && !(data.documents && (data.documents.fileName || data.documents.file))) {
                        return true;
                    }
                    return false;
                };
                $scope.data = {
                    agreementAccountTypes     : [],
                    allowWithdrawFromSettings : false,
                    connectedService          : null,
                    deactivateExpression      : null,
                    deactivateExpressionTree  : null,
                    description               : '',
                    displayTitle              : '',
                    documents                 : new SimpleFileDTOModel(),
                    emailConfig               : {
                        consentDeclined       : new EmailConfigModel(),
                        consented             : new EmailConfigModel(),
                        disclosureEmailConfig : new EmailConfigModel(),
                    },
                    emailEnabled                        : null,
                    expirationConfiguration             : new ExpirationConfigModel(),
                    formId                              : null,
                    icon                                : new SimpleFileDTOModel(),
                    labelConfig                         : new LabelConfigModel($scope.agreement),
                    localizationMapDescription          : {},
                    localizationMapTitle                : {},
                    manageConfig                        : new ManageConfigModel(),
                    multiInstanceConsentExpression      : null,
                    multiInstanceConsentExpressionTree  : null,
                    multiInstanceWithdrawExpression     : null,
                    multiInstanceWithdrawExpressionTree : null,
                    programId                           : $stateParams.id,
                    reconsentExpression                 : null,
                    reconsentExpressionTree             : null,
                    templateType                        : null,
                    type                                : null,
                    unlockedExpression                  : null,
                    unlockedExpressionTree              : null,
                    visibilityExpression                : null,
                    visibilityExpressionTree            : null,
                    withdrawalParameters                : [],
                    withdrawExpression                  : null,
                    withdrawExpressionTree              : null,
                    withdrawFormId                      : null,
                    withdrawMode                        : null,
                };


                $scope.tempData = {
                    hasPdf     : null,
                    pdfChanged : false,
                };
                $scope.onWithdrawModeChange = function (withdrawMode) {
                    $scope.isWithdrawModeUpdate = withdrawMode === 'UPDATE';
                };

                $scope.formTitle = $translate.instant($scope.isNew
                    ? 'agreements.create.new'
                    : 'agreements.create.update');

                $scope.checkManageOption = function (manageOption) {
                    if (manageOption.type === 'Update' && $scope.data.withdrawMode !== 'UPDATE') {
                        return true;
                    } else if (manageOption.type === 'Withdraw' && $scope.data.withdrawMode !== 'WITHDRAW') {
                        return true;
                    } else if (manageOption.type === 'Stop participating' && ($scope.data.withdrawMode !== 'WITHDRAW_DEACTIVATE' || $scope.data.type !== AGREEMENTS_TYPE.PRIMARY_CONSENT)) {
                        return true;
                    }
                    if (manageOption.type === 'Reconsent' && ($scope.data.reconsentExpression === null || $scope.data.reconsentExpression === '' || $scope.data.reconsentExpression === undefined)) {
                        return true;
                    }
                    if (manageOption.type === 'Email' && $scope.data.emailEnabled === false) {
                        return true;
                    }
                };

                $scope.moveOptionUp = function (manageOption, index) {
                    if (!$scope.checkManageOption(manageOption)) {
                        $scope.data.manageConfig.manageConfigArray[index - 1].orderNumber = index;
                        $scope.data.manageConfig.manageConfigArray[index].orderNumber = index - 1;
                        FormUtilService.moveOptionUp($scope.data.manageConfig.manageConfigArray, index);
                    }
                };

                $scope.moveOptionDown = function (manageOption, index) {
                    if (!$scope.checkManageOption(manageOption)) {
                        $scope.data.manageConfig.manageConfigArray[index].orderNumber = index + 1;
                        $scope.data.manageConfig.manageConfigArray[index + 1].orderNumber = index;
                        FormUtilService.moveOptionDown($scope.data.manageConfig.manageConfigArray, index);
                    }
                };

                $scope.addComponent = function () {
                    $scope.data.expirationConfiguration.addComponent();
                };

                $scope.removeComponent = function (index) {
                    $scope.data.expirationConfiguration.removeComponent(index);
                };

                $scope.initExpConfig = function () {
                    if ($scope.data.expirationConfiguration.expiry
                        && $scope.data.expirationConfiguration.expirationConfigurationDataList.length === 0) {
                        $scope.addComponent();
                    }
                };

                $scope.expirationDateOptions = {
                    formatDay        : 'd',
                    formatDayTitle   : 'MMMM yyyy',
                    isDisabledMonths : false,
                    minDate          : new Date(),
                    showButtonBar    : false,
                    showWeeks        : false,
                };

                $scope.openDate = function (param) {
                    param.isDatePopUpOpened = !param.isDatePopUpOpened;
                };

                $scope.avoidAccountTypes = [];
                $scope.possibleAccountTypes = [ 'SIX_AND_BELOW', 'SEVEN_TO_TWELVE', 'TEEN' ];
                $scope.loadAgreementListForPrimaryConsentCheck = loadAgreementListForPrimaryConsentCheck;

                init($scope);
            },
            restrict : 'E',
            scope    : {
                agreement            : '=?',
                isNew                : '=?',
                stateAfterCancelSave : '@',
            },
            template,
        };

        /** ****************************************************************************
         * private functions
         ***************************************************************************** */

        function init ($scope) {
            $scope.initialized = false;
            // constants for documents upload
            $scope.constants = FormConstants;
            // constants for expression conditions
            $scope.expConstants = ExpressionConstantsService;
            $scope.enableEmailOptions = [ { k : 'No', v : false }, { k : 'Yes', v : true } ];

            $scope.dateTypeToAvoid = [ FormConstants.DateTypeEnum.NOW ];

            $scope.flags.reset();
            loadAgreementFields($scope);
            loadAgreementListForPrimaryConsentCheck($scope);
            addWatches($scope);
        }

        function addWatches ($scope) {
            $scope.$watch('data.documents.file', (newValue, oldValue) => {
                if (newValue == oldValue) {
                    return;
                }
                updateHasPdf($scope);
            });

            $scope.$watch('data.documents.fileUrl', (newValue, oldValue) => {
                if (newValue == oldValue) {
                    return;
                }
                updateHasPdf($scope);
            });

            $scope.$watch('data.type', (newValue, oldValue) => {
                if (newValue == oldValue) {
                    return;
                }
                $scope.consentFormOptions.form = null;
                $scope.withdrawFormOptions.form = null;
            });

            $scope.$watch('data.withdrawMode', (newValue, oldValue) => {
                if (newValue == oldValue) {
                    return;
                }
                if (newValue === 'NONE') {
                    $scope.withdrawFormOptions.form = null;
                    $scope.data.allowWithdrawFromSettings = false;
                }
            });
            $scope.$watch('data.defaultAgreement', (newValue, oldValue) => {
                if (newValue === oldValue) {
                    return;
                }

                $scope.isMultiplePrimaryConsentsValid = newValue || $scope.data.agreementAccountTypes.length > 0;
            });

            $scope.$watch('data.agreementAccountTypes', (newValue, oldValue) => {
                if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
                    return;
                }

                $scope.isMultiplePrimaryConsentsValid = $scope.data.defaultAgreement || newValue.length > 0;
            });
        }

        function updateHasPdf ($scope) {
            if ($scope.data.documents === null) {
                $scope.tempData.hasPdf = null;
                $scope.tempData.pdfChanged = true;
                return;
            }
            const enPdf = $scope.data.documents;

            $scope.tempData.hasPdf = enPdf == null || enPdf.file == null && enPdf.fileUrl == null ? null : 'hasPDF';
            $scope.tempData.pdfChanged = true;
        }

        /**
         * save a list of simple file dto objects into a dto map used for saving to server
         * @param data - $scope.data object contains type and documents (objectMap)
         *        objectMap - object map of format <locale_string, <SimpleFileDTOModel>>
         * @param files - file array to store file objects
         * @returns - dto map
         */
        function saveDocumentsToDTO (data, files, $scope) {
            if (data === null) {
                return null;
            }
            let documents = data.documents;
            if (data.type !== $scope.agreementsType.DISCLOSURE) {
                return null;
            }

            if (documents == null) {
                return null;
            }
            return documents.toDto(files);
        }

        function saveWithdrawalParametersToDTO (withdrawalParams) {
            let parameters = [];
            if (withdrawalParams != null) {
                withdrawalParams.forEach(paramConfig => {
                    parameters.push(paramConfig.toDto());
                });
            }
            return parameters;
        }

        /**
         * converting an existing agreement from json into internal data format
         * $scope.agreement --> $scope.data
         * assumption: $scope.capabilities is already loaded
         * @param $scope
         */
        function convertAgreementIntoData ($scope) {
            if (!$scope.agreement || !$scope.typeCapabilities) {
                return;
            }

            const data = $scope.data;
            const ag = $scope.agreement;
            const typeCapabilities = $scope.typeCapabilities;

            // simple properties with no conversion
            data.programId = ag.programId;
            data.id = ag.id;
            data.emailEnabled = ag.emailEnabled;
            data.allowWithdrawFromSettings = ag.allowWithdrawFromSettings;
            // localization related properties
            LocaleJsonService.jsonToLocalizationMap(ag.displayTitle, data, 'displayTitle', 'localizationMapTitle');
            LocaleJsonService.jsonToLocalizationMap(ag.description, data, 'description', 'localizationMapDescription');
            // load form
            data.formId = ag.formId || null;
            if (data.formId != null) {
                FormLoadUtil.loadForm({
                    formId  : data.formId,
                    getForm : function () {
                        return null;
                    },
                    setForm : function (formModel) {
                        $scope.consentFormOptions.form = formModel;
                    },
                });
            }

            data.withdrawFormId = ag.withdrawFormId;
            if (data.withdrawFormId != null) {
                FormLoadUtil.loadForm({
                    formId  : data.withdrawFormId,
                    getForm : function () {
                        return null;
                    },
                    setForm : function (formModel) {
                        $scope.withdrawFormOptions.form = formModel;
                    },
                });
            }

            // type, templateType, withdrawMode -- those are from capabilities
            if (ag.type) {
                $scope.capabilities = typeCapabilities[ag.type];
            }

            data.type = ag.type;
            data.templateType = ag.templateType;
            data.connectedService = ag.connectedService;
            data.withdrawMode = ag.withdrawMode;
            if (data.withdrawMode == 'UPDATE') {
                $scope.isWithdrawModeUpdate = true;
            }

            // load withdrawal parameters
            if (ag.withdrawalParameters != null) {
                ag.withdrawalParameters.forEach(paramConfig => {
                    const paramConfigModel = new ParameterConfigModelService.ParameterConfigModel(data);
                    data.withdrawalParameters.push(paramConfigModel);
                    paramConfigModel.fromDto(paramConfig);
                });
            }

            if (ag.expirationConfiguration && ag.expirationConfiguration.expirationConfigurationDataList != null) {
                ag.expirationConfiguration.expirationConfigurationDataList.forEach(expirationConfigData => {
                    const expirationConfigDataListModel = new ExpirationConfigDataListModel();
                    data.expirationConfiguration.expirationConfigurationDataList.push(expirationConfigDataListModel);
                    expirationConfigDataListModel.fromDto(expirationConfigData);
                });
            }

            // load expressions and trees
            data.visibilityExpression = ag.visibilityExpression;
            data.visibilityExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.visibilityExpressionTree.fromDto(ag.visibilityExpressionTree);

            data.unlockedExpression = ag.unlockedExpression;
            data.unlockedExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.unlockedExpressionTree.fromDto(ag.unlockedExpressionTree);

            data.withdrawExpression = ag.withdrawExpression;
            data.withdrawExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.withdrawExpressionTree.fromDto(ag.withdrawExpressionTree);

            data.deactivateExpression = ag.deactivateExpression;
            data.deactivateExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.deactivateExpressionTree.fromDto(ag.deactivateExpressionTree);

            data.reconsentExpression = ag.reconsentExpression;
            data.reconsentExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.reconsentExpressionTree.fromDto(ag.reconsentExpressionTree);

            data.multiInstanceConsentExpression = ag.multiInstanceConsentExpression;
            data.multiInstanceConsentExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.multiInstanceConsentExpressionTree.fromDto(ag.multiInstanceConsentExpressionTree);

            data.multiInstanceWithdrawExpression = ag.multiInstanceWithdrawExpression;
            data.multiInstanceWithdrawExpressionTree = new ModelServiceConditionExpression.ConditionExpression(data);
            data.multiInstanceWithdrawExpressionTree.fromDto(ag.multiInstanceWithdrawExpressionTree);

            // load documents and emailConfig
            if (data.type === $scope.agreementsType.DISCLOSURE) {
                data.documents.fromDto(ag.documents);
                data.emailConfig.disclosureEmailConfig.fromDto(ag.emailConfig.disclosureEmailConfig);
                $scope.consentFormOptions.formCategory = FormConstants.formCategories.DATA_FORM;
            } else {
                data.emailConfig.consented.fromDto(ag.emailConfig.consented);
                data.emailConfig.consentDeclined.fromDto(ag.emailConfig.consentDeclined);
                $scope.consentFormOptions.formCategory = FormConstants.formCategories.CONSENT_FORM;
            }

            if (ag.icon != null) {
                data.icon.fromDto(ag.icon);
            }

            data.labelConfig.fromDto(ag.labelConfig);
            data.manageConfig.fromDto(ag.manageConfig);
            if (ag.expirationConfiguration) {
                data.expirationConfiguration.fromDto(ag.expirationConfiguration);
            }

            data.defaultAgreement = ag.defaultAgreement;
            data.agreementAccountTypes = ag.agreementAccountTypes.map(type => type.accountType);
        }

        function loadAgreementFields ($scope) {
            AgreementsService.getAgreementTypeCapabilities().then(response => {
                $scope.typeCapabilities = response.data;
                convertAgreementIntoData($scope);
            }, error => {
                console.error('Error loading agreements', error);
            }).finally(() => {
                $scope.flags.setEnumReady(true);
            });
        }

        function loadAgreementListForPrimaryConsentCheck ($scope) {
            $scope.allowGenericPrimary = false;
            $scope.mustBeGenericPrimaryConsent = false;

            // check if there is PRIMARY_CONSENT type
            AgreementsService.loadAgreements($scope.data).then(response => {
                const agreements = response.data;
                if (!agreements) {
                    $scope.mustBeGenericPrimaryConsent = true;
                    $scope.allowGenericPrimary = true;
                    $scope.data.defaultAgreement = true;
                    return;
                }

                const existingPrimaryConsents = agreements.filter(item => {
                    return item.type === $scope.agreementsType.PRIMARY_CONSENT;
                });
                const validPrimaryConsents = agreements.filter(item => {
                    return item.type === $scope.agreementsType.PRIMARY_CONSENT && item.defaultAgreement;
                });
                $scope.validPrimaryConsentPresent = validPrimaryConsents.length > 0;
                const existingGenericPrimaryConsent = existingPrimaryConsents.find(item => {
                    return item.defaultAgreement === true;
                });
                const isCurrentPrimaryConsentDefault = existingPrimaryConsents.find(item => {
                    return item.id === $scope.data.id && item.defaultAgreement === true;
                });
                if (existingGenericPrimaryConsent) {
                    $scope.allowGenericPrimary = false;
                    $scope.showDefaultFlag = false;
                    if ($scope.isNew) {
                        $scope.mustBeGenericPrimaryConsent = false;
                        $scope.data.defaultAgreement = false;
                    }
                    else if (isCurrentPrimaryConsentDefault) {
                        $scope.allowGenericPrimary = true;
                        $scope.showDefaultFlag = true;
                    }
                } else {
                    $scope.allowGenericPrimary = true;
                    $scope.showDefaultFlag = true;
                    if ($scope.isNew) {
                        $scope.mustBeGenericPrimaryConsent = true;
                        $scope.data.defaultAgreement = true;
                    }
                }
            }, error => {
                console.error('Error loading agreements', error);
            }).finally(() => {
                $scope.flags.setAgreementListReady(true);
            });
        }

        /**
         * additional object level validation, if anything is wrong, return the error message
         * @param $scope
         * @returns {string} - error message, if no error were found, return null
         * note: empty string <> null here
         */
          function doValidate ($scope) {
            if ($scope.data.type === $scope.agreementsType.PRIMARY_CONSENT) {
                if ($scope.mustBeGenericPrimaryConsent
                    && $scope.data.defaultAgreement === false) {
                    return $translate.instant('error.first.primary.agreement.of.program.must.be.default.agreement');
                }

                if (!$scope.allowGenericPrimary
                    && $scope.data.defaultAgreement === true) {
                    return $translate.instant('agreements.validation.error');
                }
            }
            return null;
        }

        function doSave ($scope) {
            const msg = doValidate($scope);

            if (msg != null) {
                AlertService.error(msg);
                return;
            }

            const files = [];
            const newAgreement = {
                ...$scope.data,
                deactivateExpressionTree : $scope.data.deactivateExpressionTree ? $scope.data.deactivateExpressionTree.toDto() : null,
                description              : LocaleJsonService.localizationMapToJson($scope.data.description, $scope.data.localizationMapDescription, 'description'),
                displayTitle             : LocaleJsonService.localizationMapToJson($scope.data.displayTitle, $scope.data.localizationMapTitle, 'displayTitle'),
                documents                : saveDocumentsToDTO($scope.data, files, $scope),
                emailConfig              : {
                    consentDeclined       : $scope.data.emailConfig.consentDeclined.toDto(files, $scope.data),
                    consented             : $scope.data.emailConfig.consented.toDto(files, $scope.data),
                    disclosureEmailConfig : $scope.data.emailConfig.disclosureEmailConfig.toDto(files, $scope.data),
                },
                expirationConfiguration             : $scope.data.expirationConfiguration.toDto(),
                icon                                : $scope.data.icon.toDto(files),
                labelConfig                         : $scope.data.labelConfig.toDto(),
                manageConfig                        : $scope.data.manageConfig.toDto(),
                multiInstanceConsentExpressionTree  : $scope.data.multiInstanceConsentExpressionTree ? $scope.data.multiInstanceConsentExpressionTree.toDto() : null,
                multiInstanceWithdrawExpressionTree : $scope.data.multiInstanceWithdrawExpressionTree ? $scope.data.multiInstanceWithdrawExpressionTree.toDto() : null,
                reconsentExpressionTree             : $scope.data.reconsentExpressionTree ? $scope.data.reconsentExpressionTree.toDto() : null,
                unlockedExpressionTree              : $scope.data.unlockedExpressionTree ? $scope.data.unlockedExpressionTree.toDto() : null,
                visibilityExpressionTree            : $scope.data.visibilityExpressionTree ? $scope.data.visibilityExpressionTree.toDto() : null,
                withdrawalParameters                : saveWithdrawalParametersToDTO($scope.data.withdrawalParameters),
                withdrawExpressionTree              : $scope.data.withdrawExpressionTree ? $scope.data.withdrawExpressionTree.toDto() : null,
            };
            delete newAgreement.localizationMapTitle;
            delete newAgreement.localizationMapDescription;

            if (newAgreement.type !== $scope.agreementsType.PRIMARY_CONSENT) {
                newAgreement.defaultAgreement = true;
            }

            if (newAgreement.defaultAgreement) {
                newAgreement.agreementAccountTypes = [];
            }

            if (newAgreement.id === undefined) {
                AgreementsService.createAgreement(newAgreement, files).then(response => {
                    let id = -1;
                    if (response && response.data && response.data.id) {
                        id = response.data.id;
                    }
                    AlertService.success(`agreement has been created (id: ${ id })`);
                    $state.go($scope.stateAfterCancelSave);
                }, error => {
                    console.error('Error creating an Agreement', error);
                });
            } else {
                AgreementsService.updateAgreement(newAgreement, files).then(response => {
                    let id = -1;
                    if (response && response.data && response.data.id) {
                        id = response.data.id;
                    }
                    AlertService.success(`agreement has been updated (id: ${ id })`);
                    $state.go($scope.stateAfterCancelSave);
                }, error => {
                    console.error('Error updating agreements', error);
                });
            }
        }
    });
