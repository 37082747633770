/**
 * Created by Jamie Nola on 10/26/2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value of type phone input
     */
        .directive('formFieldConfigFieldValuePhoneInput', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/phoneInput.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '='
                },
            };
        });

})();
