/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola on 04/18/2019
 */
(function () {
    angular.module('acadiamasterApp').directive('orderStatusConfig',
        ($timeout, ValueType, ValueComparisonOptionModel, OrderTypeService) => {
            let orderStatusConfigController = function () {
                let vm = this;

                /*
                 * Fires when the orderType is updated. Clears out the comparison and updates the UI
                 * but remove any values that are no longer allowed with the new orderType.
                 */
                vm.onOrderTypeUpdated = function (orderTypeName) {
                    vm.condition.orderType = orderTypeName;
                    let statuses = vm.orderTypes[orderTypeName].orderStatusList;

                    // Transform the statuses into the format the comparison directive needs
                    let statusObj = {};
                    statuses.forEach((status, index) => {
                        statusObj[index] = { name : status, text : status };
                    });

                    // Filter out any invalid values currently stored in the comparison, but keep
                    // any valid ones.
                    let validValues;
                    if (vm.valueComparisonOption) {
                        let currentValues = vm.valueComparisonOption.getValues();
                        if (currentValues) {
                            validValues = Object.keys(currentValues).filter(key => {
                                return statuses.indexOf(currentValues[key].name) >= 0;
                            }).map(key => {
                                return currentValues[key];
                            });
                        }
                    }

                    // Create a comparison object with updated (and valid) values.
                    let comparison = createValueComparisonOption(
                        vm.condition,
                        statusObj,
                    );
                    if (validValues) {
                        comparison.setValues(validValues);
                    }

                    // Remove the existing comparison object, then wait one frame and set the new
                    // one. This way Angular updates the UI properly.
                    vm.valueComparisonOption = null;
                    $timeout(() => {
                        vm.valueComparisonOption = comparison;
                    });
                };

                vm.$onInit = () => {
                    vm.orderTypes = OrderTypeService.getOrderTypes();

                    vm.data = {
                        orderType          : vm.condition.orderType || Object.keys(vm.orderTypes)[0],
                        possibleOrderTypes : Object.keys(vm.orderTypes),
                    };

                    if (vm.data.orderType) {
                        vm.onOrderTypeUpdated(vm.data.orderType);
                    }
                };
            };

            /*
             * Creates a comparison object to be used by the UI
             * @param {Object} condition
             * @param {Object} options
             */
            function createValueComparisonOption (condition, options) {
                let valueComparisonOption = new ValueComparisonOptionModel();

                valueComparisonOption.isRequired = true;
                valueComparisonOption.canConfigureOperator = true;

                valueComparisonOption.functions.getValueType = function () {
                    return ValueType.STRING;
                };

                valueComparisonOption.functions.getOperator = function () {
                    return condition.getComparator();
                };

                valueComparisonOption.functions.setOperator = function (newOperator) {
                    condition.setComparator(newOperator);
                };

                valueComparisonOption.functions.getValues = function () {
                    return condition.orderStatusList;
                };

                valueComparisonOption.functions.setValues = function (newValues) {
                    condition.orderStatusList = newValues;
                };

                valueComparisonOption.functions.getPossibleValues = function () {
                    return options;
                };

                return valueComparisonOption;
            }

            return {
                bindToController : true,
                controller       : orderStatusConfigController,
                controllerAs     : 'osc',
                restrict         : 'E',
                scope            : {
                    condition : '<',
                },
                templateUrl :
                    'admin-templates/site/programTests/programTest/directive/conditionsDirectives/orderStatusConfig.html',
            };
        });
}());
