/**
 * program sms toggle and sms program settings
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programSmsEnable',
        function (ProgramService, SMSToggleService, WhitelistService, ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/smsEnable/smsEnable.template.html',
                scope: {
                    programId: '@',
                    showReadOnly: '='
                },
                controller: smsWhitelistingController
            };

            /**
             * main controller function
             */
            function smsWhitelistingController($scope) {
                $scope.program = null;
                $scope.data = {
                    smsEnableCheck: false,
                    smsError: false,
                    smsToggle: false,
                    isReady: false,
                    whitelistToggle: false,
                    showWhitelistNumbers: false,
                    saveError: false,
                    whitelistError: false,
                    newWhitelistNumber: '',
                    whitelistNumbersList: []
                };

                $scope.saveToggles = saveToggles;

                $scope.addWhitelistNumber = function (phoneNumber) {
                    WhitelistService.createWhitelistNumber(phoneNumber)
                        .then(fetchWhitelistNumbers, whitelistFailure);
                };

                $scope.removeWhitelistNumber = function (id) {
                    WhitelistService.deleteWhitelistNumber(id)
                        .then(fetchWhitelistNumbers, whitelistFailure);
                };

                /* ----------------------------------------------------------------------
                 local private functions
                 * ---------------------------------------------------------------------- */

                function whitelistFailure(error) {
                    $scope.data.whitelistError = true;
                    console.error('error performing whitelist operation', error);
                }

                function fetchWhitelistNumbers() {
                    WhitelistService.getWhitelistedNumbers()
                        .then(function (listResponse) {
                            $scope.data.whitelistNumbersList = listResponse.data;
                            $scope.data.whitelistError = false;
                        }, whitelistFailure);
                }

                /**
                 * loading program from server if programId is not null
                 * @param programId program id
                 */
                function loadProgramToggles(programId) {
                    $scope.program = ProgramService.get({id: programId});
                    $scope.program.$promise.then(function () {
                        if ($scope.program.id != null) {
                            /* Is SMS Enabled for this program */
                            SMSToggleService.getSMSEnabled(programId)
                                .then(function (response) {
                                    if (response.data.smsEnabled) {
                                        /* Check the smsToggle Box on the UI */
                                        $scope.data.smsToggle = true;
                                        $scope.data.smsEnableCheck = false;
                                    } else {
                                        /* Uncheck smsToggle Box on UI */
                                        $scope.data.smsToggle = false;
                                        $scope.data.smsEnableCheck = true;
                                        /* response could be malformed ? */
                                    }
                                    $scope.data.isReady = true;
                                    $scope.data.smsError = false;
                                }, function (error) {
                                    $scope.data.smsEnableCheck = false;
                                    $scope.data.smsError = true;
                                    $scope.data.isReady = true;
                                });
                            WhitelistService.getWhitelistingEnabled(programId)
                                .then(function (response) {
                                    $scope.data.whitelistError = false;
                                    if (response.data.whitelistingEnabled) {
                                        $scope.data.showWhitelistNumbers = true;
                                        $scope.data.whitelistToggle = true;
                                        fetchWhitelistNumbers();
                                    } else {
                                        $scope.data.showWhitelistNumbers = false;
                                        $scope.data.whitelistToggle = false;
                                    }
                                }, whitelistFailure);
                        } else {
                            $scope.data.smsEnableCheck = false;
                            $scope.data.smsError = true;
                            $scope.data.isReady = true;
                        }
                    });
                }

                /**
                 * save sms toggle
                 */
                function saveToggles(programId) {
                    if (programId != null) {
                        if ($scope.data.smsEnableCheck && $scope.data.smsToggle) {
                            SMSToggleService.setSMSEnabled(programId).then(function () {
                                $scope.data.smsEnableCheck = false;
                            }, function (error) {
                                $scope.data.smsError = true;
                            });
                        }
                        WhitelistService.toggleWhitelisting({
                            programId: programId,
                            whitelistingEnabled: $scope.data.whitelistToggle
                        }).then(function () {
                            $scope.data.showWhitelistNumbers = $scope.data.whitelistToggle.valueOf();
                        }, whitelistFailure);
                        WhitelistService.getWhitelistedNumbers(programId)
                            .then(function (response) {
                                $scope.data.whitelistError = false;
                                $scope.data.whitelistNumbersList = response.data;
                            }, whitelistFailure);
                    } else {
                        $scope.data.saveError = true;
                    }
                }

                /**
                 * Opens the edit dialog, Allows the user to edit and save the phone number
                 */
                $scope.openEditDialog = function(id, currentWhitelistNumber) {
                    // show edit dialog
                    var dialog = ngDialog.open({
                        templateUrl: 'admin-templates/site/programManagement/program/smsEnable/smsEnableEditDialog.template.html',
                        className: 'ngdialog-theme-plain custom-width-small',
                        controller: ['$scope', function ($scope) {
                            $scope.editData = {
                                updateID: id,
                                updatedWhitelistNumber: currentWhitelistNumber
                            };
                            $scope.editWhitelistNumber = function (id, whitelistNumber) {
                                WhitelistService.updateWhitelistNumber(id, whitelistNumber)
                                    .then(fetchWhitelistNumbers, whitelistFailure);
                                dialog.close();
                            };
                        }],
                        showClose: false
                    });
                };

                /**
                 * main initialization function, load all the data needed from server and set state to be ready
                 */
                function init() {
                    loadProgramToggles($scope.programId);
                }

                // calling the initialization function, don't add any additional scope variable after this
                init();
            }
        });
})();
