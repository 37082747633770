(function () {
    'use strict';

    /**
     * directive for configuration of a single value setting
     */
    angular.module('acadiamasterApp').directive('valueComparisonSingleValueConfig', function (ValueType, ValueComparisonService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/valueComparison/valueComparisonSingleValue.html',
            scope: {
                options: '=',  // a value comparison option object
                data : '=', // data and flags are passed in from parent value comparison directive
                flags : '='
            },
            link: function ($scope) {
                $scope.ValueType = ValueType;

                $scope.noErrorInField = ValueComparisonService.noErrorInField;
                $scope.shouldShowErrorMessageInField = ValueComparisonService.shouldShowErrorMessageInField;

                $scope.getValueType = function() {
                    return $scope.options.getValueType();
                };

                $scope.isValueTypeNumber = function () {
                    var valueType = $scope.getValueType();
                    return valueType == ValueType.NUMBER || valueType == ValueType.DATETIME;
                };
            }
        };

    });
})();
