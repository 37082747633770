import ContainerItemType from '../../const/ContainerItemType.const';

/**
 * Created by Jason C. on 12/03/19
 * service to load container item type specific configurations
 */
angular.module('acadiamasterApp').factory('ContainerItemTypeSpecificConfigUtil', function (ContainerItemFormConfig, ContainerItemExternalIntegrationConfig,ContainerItemContentConfig, ContainerItemAppointmentConfig) {

    /**
     * create a container item config object by container item type
     * @param containerItem - container item object
     * @returns {null|ContainerItemFormConfig|*} - container item config object, should be an object with type extending
     * from ContainerItemTypeSpecificConfig
     */
    function createConfigByType(containerItem) {
        if (containerItem != null) {
            switch (containerItem.type) {
            case ContainerItemType.FORM:
                return new ContainerItemFormConfig(containerItem);
            case ContainerItemType.EXTERNAL_INTEGRATION:
                return new ContainerItemExternalIntegrationConfig(containerItem);
            case ContainerItemType.CONTENT:
                return new ContainerItemContentConfig(containerItem);
            case ContainerItemType.APPOINTMENT:
                return new ContainerItemAppointmentConfig(containerItem);
            default:
                break;
            }
        }
        console.error('unable to create configuration by type : ', ContainerItemType);
        return null;
    }

    /**
     * load a configuration from dto to javascript object with functionality
     * @param dto - dto for container item config, plain json object
     * @param containerItem - container item object
     * @returns {null|ContainerItemFormConfig|*} - container item config object, should be an object with type extending
     * from ContainerItemTypeSpecificConfig
     */
    function loadConfigFromDto(dto, containerItem) {
        if (dto == null || containerItem == null) {
            return null;
        }

        const configObj = createConfigByType(containerItem);
        if (configObj!=null) {
            configObj.fromDto(dto);
        }
        return configObj;
    }

    return {
        loadConfigFromDto,
        createConfigByType,
    };
});
