

angular.module('acadiamasterApp')
    .directive('expressionConditionConfig', ($state, CONFIG, AlertService,
        ProgramTestCaseUtilService, ExpressionConditionModelService, $stateParams,
        ExpressionConditionService, ExpressionConditionConstantsService) => {
        return {
            link: ($scope) => {
                $scope.selector = ProgramTestCaseUtilService.selector.createSelector();
                $scope.CONFIG = CONFIG;

                // States for create/edit controller
                $scope.states = {
                    debugEnabled: false,
                    entityReady: false,
                    isAddFieldPopupOpen: false,
                };

                // Override selector to trigger validation when a new item is selected
                const selectItemOriginal = $scope.selector.selectItem;
                $scope.selector.selectItem = function (...params) {
                    $scope.condition.validate(true);
                    selectItemOriginal.apply(this, params);
                };

                // Controls
                $scope.controls = {
                    cancel() {
                        $state.go($scope.stateAfterCancel);
                    },
                    isValid() {
                        $scope.condition.validate(true);
                        return $scope.condition.isValid();
                    },
                    onSaveFinished() {
                        $state.go($scope.stateAfterSave);
                    },
                    save() {
                        doSave($scope);
                    },
                    validate() {
                        $scope.condition.validate(true);
                        if ($scope.controls.isValid()) {
                            AlertService.success('Your configuration passed all validations and is ready to be saved.');
                        } else {
                            AlertService.warning('There are some validation errors in the expression condition you are trying to save. Please check the highlighted nodes to see what is missing');
                        }
                    },
                };

                init($scope);
            },
            restrict: 'E',
            scope: {
                condition: '=?',
                isCloned: '=?',
                isNew: '=?',
                stateAfterCancel: '@',
                stateAfterSave: '@',
            },
            templateUrl: 'admin-templates/site/expressionCondition/configuration/expressionConditionConfiguration.html',
        };


        /** ****************************************************************************
         * private functions
         ***************************************************************************** */

        /**
         * save object
         * @param $scope
         */
        function doSave($scope) {
            if ($scope.controls.isValid()) {
                const conditionDTO = $scope.condition.toDto();
                ExpressionConditionService.createOrUpdateCondition(conditionDTO, $scope.controls.onSaveFinished);
            } else {
                AlertService.warning('There are some validation errors in the expression condition you are trying to save. '
                    + 'Please check the highlighted nodes to see what is missing');
            }
        }

        function setConditionEntity($scope, dto) {
            $scope.condition = new ExpressionConditionModelService.ExpressionConditionModel(null, true, null, ExpressionConditionConstantsService.usageTypes.EXPRESSION_CONDITION);
            if ($scope.isNew) {
                $scope.condition.programId = $stateParams.id;
            }
            if (dto != null) {
                $scope.condition.fromDto(dto);
                if ($scope.isCloned) {
                    $scope.condition.customCloneReset(true);
                    $scope.condition.id = null;
                    $scope.condition.name = null;
                    $scope.condition.usages = [];
                }
                $scope.conditionDTO = dto;
            }

            $scope.states.entityReady = true;
        }

        function resolveConditionEntity($scope) {
            setConditionEntity($scope, $scope.condition);
        }

        function resolveEntityIfNeeded($scope) {
            if ($scope.isNew && !$scope.isCloned) {
                setConditionEntity($scope, null);
            } else {
                resolveConditionEntity($scope);
            }
        }

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            // Resolve entity
            resolveEntityIfNeeded($scope);
        }
    });
