(function() {
    'use strict';

    const app = angular.module('acadiamasterApp');

    /**
     * caching service used for various places where you need to select container by name or id
     */
    app.factory('ContainerCacheService', function (ContainerMgmtService, ContainerModel, $q) {
        // private variables that holds various states that we don't want to give direct access

        /** a map of containers where key is program id, and value is the list of containers associated with that program id **/
        let _containerMapByProgramId = {};

        /** a map of containers where key is container id, and value is the container associated with that container id **/
        let _containerMapByContainerId = {};

        /** a map of container items where key is container item id, and value is the container item associated with that container item id **/
        let _containerItemMapByItemId = {};

        /**
         * process containers received from server, the result will be stored in
         * @param containersReceived - containers received
         * @param programId - id of the program for the container
         * @return {Array} -  a list of container models
         */
        function processContainers(containersReceived, programId) {
            let containerDTOs = containersReceived;
            _.sortBy(containerDTOs, ['name']);

            const containers = [];

            // convert dto to model and update _containerMapByContainerId
            _.forEach(containerDTOs, containerDTO => {
                const model = new ContainerModel();
                model.fromDto(containerDTO);
                containers.push(model);
                _containerMapByContainerId[model.id] = model;

                // add to container item map
                _.forEach(model.containerItems, ci => {
                    _containerItemMapByItemId[ci.id] = ci;
                });
            });

            // update _containerMapByProgramId
            _containerMapByProgramId[programId] = containers;

            return containers;
        }

        /**
         * reset function used remove cached container for a program
         */
        function reset(programId) {
            const currentContainers = _containerMapByProgramId[programId];
            if (currentContainers==null) {
                return;
            }

            // remove the list from container map by program id
            _containerMapByProgramId[programId] = null;

            // remove them from container map by container id
            currentContainers.forEach(container => {
                // remove item from item map
                _.forEach(container.containerItems, ci => {
                    _containerItemMapByItemId[ci.id] = null;
                });

                _containerMapByContainerId[container.id] = null;
            });
        }

        /**
         * load containers from server or from local copy if forceReload = false and we already have a cached version
         * @param programId - program id to load containers for
         * @param forceReload set to true if we always want to load the full list from server regardless if there is already a
         *        cached version of the containers by that program id
         * @return a promise that on success will provide a list of sorted containers (sorted by name)
         */
        function loadContainersByProgramId(programId, forceReload) {
            const deferred = $q.defer();

            if (programId == null) {
                // error handling
                deferred.resolve(null);
                return deferred.promise;
            }

            if (forceReload) {
                reset(programId);
            }

            let containers = _containerMapByProgramId[programId];

            if (containers) {
                deferred.resolve(containers);
            }
            else {
                ContainerMgmtService.getContainers(programId).then(function(resp) {
                    const containersReceived = processContainers(resp.data, programId);
                    deferred.resolve(containersReceived);
                }, function (error) {
                    console.error('error occurred trying to load container: ', error);
                    deferred.reject(`unable to load containers from server with program id: ${programId}`);
                });
            }

            return deferred.promise;
        }

        /**
         * load container by id from server or from local variable
         * @param forceReload set to true if we always want to load the data from server
         * @param containerId - id of the container to be loaded
         * @param programId - program id for the program
         * @return a promise that on success will provide the container
         */
        function loadContainerById(containerId, programId, forceReload) {
            const deferred = $q.defer();

            if (forceReload) {
                reset(programId);
            }

            const container = _containerMapByContainerId[containerId];

            if (container) {
                deferred.resolve(container);
            }
            else {
                ContainerMgmtService.getContainers(programId).then(function(resp) {
                    processContainers(resp.data, programId);
                    deferred.resolve(_containerMapByContainerId[containerId]);
                }, function (error) {
                    console.error('error occurred trying to load container: ', error);
                    deferred.reject(`unable to load containers from server with program id: ${programId}`);
                });
            }

            return deferred.promise;
        }

        /**
         * load container item by id from server or from local variable
         * @param forceReload set to true if we always want to load the data from server
         * @param containerItemId - id of the container item to be loaded
         * @param programId - program id for the program
         * @return a promise that on success will provide the container item
         */
        function loadContainerItemById(containerItemId, programId, forceReload) {
            const deferred = $q.defer();

            if (forceReload) {
                reset(programId);
            }

            const containerItem = _containerItemMapByItemId[containerItemId];

            if (containerItem) {
                deferred.resolve(containerItem);
            }
            else {
                ContainerMgmtService.getContainers(programId).then(function(resp) {
                    processContainers(resp.data, programId);
                    deferred.resolve(_containerItemMapByItemId[containerItemId]);
                }, function (error) {
                    console.error('error occurred trying to load container: ', error);
                    deferred.reject(`unable to load containers from server with program id: ${programId}`);
                });
            }

            return deferred.promise;
        }

        /**************************************************************
         * all the public accessible programs for the service
         **************************************************************/
        return {
            loadContainersByProgramId,
            loadContainerById,
            loadContainerItemById,
        };
    })
})();
