'use strict';

angular.module('acadiamasterApp')
    .controller('OrganizationController', function ($scope, Organization, ParseLinks, vbrCommonUtil, CONFIG, ngDialog) {
        $scope.CONFIG = CONFIG;
        $scope.organizations = [];
        $scope.page = 0;

        function refresh() {
            $scope.loadAll();
            $scope.clear();
        }

        $scope.loadAll = function () {
            Organization.query({page: $scope.page, size: 20}, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.organizations = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            Organization.get({id: id}, function (result) {
                $scope.organization = result;
                $('#deleteOrganizationConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            Organization.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteOrganizationConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            refresh();
        };

        $scope.clear = function () {
            $scope.organization = {
                orgCode: null,
                orgName: null,
                orgDescription: null,
                contactPerson: null,
                phone: null,
                email: null,
                icon: null,
                id: null
            };
        };

        $scope.download = function (orgId) {
            var orgDetails = Organization.get({
                id: orgId
            });
            orgDetails.$promise.then(function (data) {
                // create file name
                var fileName = 'organization_' + orgId
                    + '.json';
                // download as file in browser
                vbrCommonUtil.downloadAsFile(fileName, data);
            });
        };

        $scope.importOrg = function () {
            ngDialog.openConfirm({
                template: '<upload-to-configuration-server title="Import Organization" ws-url="/api/configuration/organization/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                plain: true,
                size: 'md',
                className : 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        refresh();
                    }
                }]
            }).then(function () {
                $scope.refresh();
            });
        };
    });
