(function () {
  'use strict';

  angular.module('acadiamasterApp')
  .factory('CreateAdminUserService', function (localWeb, $http) {

    const createAdminUser = (dataObj) =>{
      return $http({
        data: JSON.stringify(dataObj),
        method: 'POST',
        url: localWeb.createUrl('/api/accounts/admin'),
      });
    };

    return {
      createAdminUser,
    };
  });
})
();