/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        /**
         * Directive for visible push notification.
         */
        .directive('ruleElementConfigActionPushNotification',
            function (RuleUtilService, LocaleConstantsService, FormUtilService, RuleActionModelService, $document, vbrCommonUtil, $timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.push.notification.html',
                    scope: {
                        action: '=',
                        parentForm: '='
                    },
                    link: function ($scope) {
                        $scope.programId = $scope.action.getRoot().programId;
                        $scope.cs = RuleUtilService.ruleConstants;
                        $scope.lcs = LocaleConstantsService;
                        $scope.vcu = vbrCommonUtil;
                        $scope.programId = $scope.action._parent.programId;
                        $scope.form = {};
                        $scope.form.options = {
                            form: $scope.action.actionPushNotificationConfig ? $scope.action.actionPushNotificationConfig.form : null,
                            viewOnly: false
                        };

                        $scope.onPopoverOpened = function ($event) {
                            $document[0].body.click();
                            $event.stopPropagation();
                        };

                        $scope.titleMaxLength = 70;
                        $scope.urlMaxLength = 500;
                        $scope.messageMaxLength = 1000;

                        $scope.$watch('form.options.form', function (newForm) {
                            if ($scope.action.actionPushNotificationConfig) {
                                $scope.action.actionPushNotificationConfig.form = newForm;
                            }
                        });
                        $scope.$watch('action.actionPushNotificationConfig.actionLinkType', function (newValue, oldValue) {
                            if(newValue === oldValue){
                                return;
                            }
                            $scope.action.actionPushNotificationConfig.postActionNavigationLink = null;
                            $scope.action.actionPushNotificationConfig.actionNavigationLink = null;
                        });

                        $scope.push = $scope.action.actionPushNotificationConfig != null;

                        // don't allow for toggle on push notification
                        $scope.shouldAllowPushToggle = $scope.action.type !== $scope.cs.actionType.PUSH_NOTIFICATION;
                        $scope.listToAvoid = [];

                        $scope.showPushInAppChange = (newValue) => {
                            $scope.listToAvoid = [];
                            if (!newValue) {
                                $scope.listToAvoid.push($scope.cs.notificationActionLinkType.EXTERNAL_NAVIGATION.name);
                                if (!$scope.action.actionPushNotificationConfig || !$scope.action.actionPushNotificationConfig.actionLinkType) {
                                    return;
                                }
                                if ($scope.action.actionPushNotificationConfig.actionLinkType.name === $scope.cs.notificationActionLinkType.EXTERNAL_NAVIGATION.name) {
                                    $timeout(() => {
                                        $scope.action.actionPushNotificationConfig.actionLinkType = $scope.cs.notificationActionLinkType.NONE;
                                    });
                                }
                            }
                        };

                        $scope.pushFlagChangeCallback = function (newPushValue) {
                            $scope.push = newPushValue;
                            if (!newPushValue) {
                                $scope.action.actionPushNotificationConfig = null;
                            } else {
                                if ($scope.action.actionPushNotificationConfig == null) {
                                    $scope.action.actionPushNotificationConfig = new RuleActionModelService.ActionPushNotificationConfig();
                                }
                            }
                            $scope.showPushInAppChange($scope.action.actionPushNotificationConfig && $scope.action.actionPushNotificationConfig.inApp);
                        };

                        $scope.isFormSupportedNotification = function () {
                            return $scope.action.actionPushNotificationConfig.actionLinkType === $scope.cs.notificationActionLinkType.FORM;
                        };

                        $scope.isNavigationSupportNotification = function () {
                            return $scope.action.actionPushNotificationConfig.actionLinkType === $scope.cs.notificationActionLinkType.NAVIGATION;
                        };

                        $scope.showPostActionNavigation = function () {
                            return $scope.action.actionPushNotificationConfig.actionLinkType === $scope.cs.notificationActionLinkType.NONE
                                || $scope.action.actionPushNotificationConfig.actionLinkType === $scope.cs.notificationActionLinkType.FORM;
                        };

                        $scope.showPageSelection = function () {
                            return $scope.action.actionPushNotificationConfig.postActionNavigationLink === $scope.cs.notificationActionNavigationLink.PAGE.name
                            || $scope.action.actionPushNotificationConfig.actionNavigationLink === $scope.cs.notificationActionNavigationLink.PAGE.name;
                        };
                        $scope.selectPage = page => {
                            if ($scope.isNavigationSupportNotification()) {
                                $scope.action.actionPushNotificationConfig.actionPageId = page?.id;
                                $scope.action.actionPushNotificationConfig.actionContainerId = null;
                                $scope.action.actionPushNotificationConfig.actionContainerType = null;
                                $scope.action.actionPushNotificationConfig.actionContainerInPageId = null;
                            } else {
                                $scope.action.actionPushNotificationConfig.postActionPageId = page?.id;
                                $scope.action.actionPushNotificationConfig.postActionContainerId = null;
                                $scope.action.actionPushNotificationConfig.postActionContainerType = null;
                                $scope.action.actionPushNotificationConfig.postActionContainerInPageId = null;
                            }

                        };
                        $scope.showContainerSelection = function () {
                            return $scope.action.actionPushNotificationConfig.postActionNavigationLink === $scope.cs.notificationActionNavigationLink.CONTAINER.name
                             || $scope.action.actionPushNotificationConfig.actionNavigationLink === $scope.cs.notificationActionNavigationLink.CONTAINER.name;
                        };
                        $scope.selectContainer = container => {
                            if ($scope.isNavigationSupportNotification()) {
                                $scope.action.actionPushNotificationConfig.actionContainerId = container?.id;
                                $scope.action.actionPushNotificationConfig.actionContainerType = container?.containerType;
                                $scope.action.actionPushNotificationConfig.actionContainerInPageId = container?.pageId;
                                $scope.action.actionPushNotificationConfig.actionPageId = null;
                            } else {
                                $scope.action.actionPushNotificationConfig.postActionContainerId = container?.id;
                                $scope.action.actionPushNotificationConfig.postActionContainerType = container?.containerType;
                                $scope.action.actionPushNotificationConfig.postActionContainerInPageId = container?.pageId;
                                $scope.action.actionPushNotificationConfig.postActionPageId = null;
                            }
                        };


                        $scope.showURL = function () {
                            return $scope.action.actionPushNotificationConfig.actionLinkType === $scope.cs.notificationActionLinkType.EXTERNAL_NAVIGATION;
                        };

                        $scope.isCharacterLimitExceeds = function (maxLimit, configuration, fieldKey) {
                            if (!maxLimit || !configuration || !fieldKey) {
                                return false;
                            }

                            if (configuration[fieldKey] && configuration[fieldKey].length >= maxLimit) {
                                return true;
                            }

                            for (var langKey in configuration.localizationMap) {
                                if (configuration.localizationMap.hasOwnProperty(langKey)) {
                                    var i18Text = configuration.localizationMap[langKey][fieldKey];
                                    if (i18Text && i18Text.length >= maxLimit) {
                                        return true;
                                    }
                                }
                            }

                            return false;
                        };

                        function init($scope) {
                            $scope.showPushInAppChange($scope.action.actionPushNotificationConfig && $scope.action.actionPushNotificationConfig.inApp);

                            if($scope.programId !=null){
                                $scope.actionNavigationLink = Object.values($scope.cs.notificationActionNavigationLink).sort();
                            } else {
                                $scope.actionNavigationLink = Object.values($scope.cs.notificationActionNavigationLink).sort().filter(link => link.isForProgram ===false);
                            }
                        }

                        init($scope);
                    }
                };
            });
})();
