(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('user.management');
                return $translate.refresh();
            }
            $stateProvider.state('participantManagement', {
                parent: 'entity',
                url: '/participant',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'participantProfile.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/participant/participantManagement.html',
                        controller: 'ParticipantManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
        });
})();
