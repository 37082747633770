(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('testCaseConfig', function (vbrCommonUtil, $state, CONFIG,
            AlertService, ngDialog, ProgramTestCaseUtilService,
            ProgramTestModelService, ProgramTestService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/testCaseConfiguration.html',
                scope: {
                    isNew: '=',
                    stateAfterSave: '@',
                    testCase: '=',
                    programId: '=',
                    stateAfterCancel: '@',
                    isCloned: '=?',
                },
                link: function ($scope) {
                    $scope.selector = ProgramTestCaseUtilService.selector.createSelector();
                    $scope.CONFIG = CONFIG;

                    // States for create/edit controller
                    $scope.states = {
                        entityReady: false,
                        isAddFieldPopupOpen: false,
                        debugEnabled: false
                    };

                    // Override selector to trigger validation when a new item is selected
                    var selectItemOriginal = $scope.selector.selectItem;
                    $scope.selector.selectItem = function () {
                        $scope.testCase.validate(true);
                        selectItemOriginal.apply(this, arguments);
                    };

                    // Controls
                    $scope.controls = {
                        cancel: function () {
                            $state.go($scope.stateAfterCancel);
                        },
                        save: function () {
                            doSave($scope);
                        },
                        onSaveFinished: function () {
                            $state.go($scope.stateAfterSave);
                        },
                        isValid: function () {
                            $scope.testCase.validate(true);
                            return $scope.testCase.isValid();
                        },
                        validate: function () {
                            $scope.testCase.validate(true);
                            if ($scope.controls.isValid()) {
                                AlertService.success('Your configuration passed all validations and is ready to be saved.');
                            } else {
                                AlertService.warning('There are some validation errors in the program test you are trying to save. Please check the highlighted nodes to see what is missing');
                            }
                        },
                        showImportWindow: function () {
                            showImportWindow($scope);
                        },
                    };

                    init($scope);
                }
            };


            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                // Resolve entity
                resolveEntityIfNeeded($scope);

            }

            /**
             * save object
             * @param $scope
             */
            function doSave($scope) {
                if ($scope.controls.isValid()) {
                    var testCaseDTO = $scope.testCase.toDto();
                    ProgramTestService.createOrUpdateCase(testCaseDTO, $scope.controls.onSaveFinished);
                } else {
                    AlertService.warning('There are some validation errors in the program test you are trying to save. ' +
                        'Please check the highlighted nodes to see what is missing');
                }
            }

            function resolveEntityIfNeeded($scope) {
                if ($scope.isNew && !$scope.isCloned) {
                    setTestCaseEntity($scope, null);
                } else {
                    resolveTestCaseEntity($scope);
                }
            }

            function resolveTestCaseEntity($scope) {
                setTestCaseEntity($scope, $scope.testCase.data);
            }

            function setTestCaseEntity($scope, dto) {
                $scope.testCase = new ProgramTestModelService.ProgramTestModel($scope.programId);
                if (dto != null) {
                    $scope.testCase.fromDto(dto);
                    if ($scope.isCloned) {
                        $scope.testCase.customCloneReset(true);
                        $scope.testCase.id = null;
                        $scope.testCase.name = 'Copy of ' + $scope.testCase.name;
                        if ($scope.testCase.tagMappings != null) {
                            for (var i = 0; i < $scope.testCase.tagMappings.length; i++) {
                                // Reset tag related ids
                                $scope.testCase.tagMappings[i].id = null;
                                $scope.testCase.tagMappings[i].programTestId = null;
                                $scope.testCase.tagMappings[i].tag.id = null;
                            }
                        }
                    }
                    $scope.testCaseDTO = dto;
                }
                if($scope.isNew && !$scope.isCloned) {
                    $scope.testCase.ifCondition.isReusable = false;
                    $scope.testCase.assertCondition.isReusable = false;
                }

                $scope.states.entityReady = true;
            }

            /**
             * show import program test window
             * @param $scope
             */
            function showImportWindow($scope) {
                var _scope = $scope;
                ngDialog.openConfirm({
                    template: '<program-test-import-confirmation title="Upload Program Test" on-success-callback="onSuccess"></program-test-import-confirmation>',
                    plain: true,
                    controller: ['$scope', function ($scope) {
                        $scope.onSuccess = function (data) {
                            if (data) {
                                _scope.isCloned = true;
                                setTestCaseEntity(_scope, data);
                            }
                        }
                    }]
                });
            }

        });
})();
