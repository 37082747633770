(function () {
    angular.module('acadiamasterApp')
        .factory('UserImportService', (Upload, localWeb, $http) => {
            const getUserImportTasks = searchFilters => {
                return $http({
                    method : 'GET',
                    url    : localWeb.createUrl('externalimporter/v1/user/listTasks?') + $.param(searchFilters),
                });
            };

            const createUserImportTask = (dataObj, files) => {
                return Upload.upload({
                    arrayKey : '',
                    data     : {
                        data : JSON.stringify(dataObj),
                        file : files,
                    },
                    url : localWeb.createUrl('externalimporter/v1/user/importTask'),
                });
            };

            const startTask = taskId => {
                return $http({
                    method : 'POST',
                    url    : localWeb.createUrl('externalimporter/v1/user/startImport' + `?taskId=${ taskId}`),
                });
            };

            const getTaskStatus = (id, searchFilters) => {
                return $http({
                    method : 'GET',
                    url    : localWeb.createUrl(`externalimporter/v1/user/retrieveTask/${ id }?${ $.param(searchFilters)}`),
                });
            };

            return {
                createUserImportTask,
                getTaskStatus,
                getUserImportTasks,
                startTask,

            };
        });
}());
