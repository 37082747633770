(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {

            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('programTest');
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('expressionCondition');
                return $translate.refresh();
            }

            $stateProvider
                .state('programTests.pstCase', {
                    parent: 'programTests',
                    url: '/programTest',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                        pageTitle: 'acadiamasterApp.programTest.home'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/programTests/programTest/programTest.html',
                            controller: 'ProgramTestController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            }]
                    }
                })
                .state('programTests.pstCase.new', {
                    parent: 'programTests.pstCase',
                    url: '/new/{programId}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'acadiamasterApp.programTest.home'
                    },
                    views: {
                        'content@': {
                            template: '<test-case-config  is-new="true" test-case="testCase" program-id="programId" ' +
                                'state-after-save="programTests.pstCase" state-after-cancel="programTests.pstCase">' +
                                '</test-case-config>',
                            controller: function ($scope, ProgramTestModelService, programId) {
                                $scope.testCase = new ProgramTestModelService.ProgramTestModel();
                                $scope.programId = programId;
                            },
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                    return loadTranslatePartial($translate, $translatePartialLoader);
                                }],
                                programId : ['$stateParams', function ($stateParams) {
                                    return $stateParams.programId;
                                }]
                            }
                        }
                    },
                })
                .state('programTests.pstCase.edit', {
                    parent: 'programTests.pstCase',
                    url: '/edit/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'acadiamasterApp.programTest.home'
                    },
                    views: {
                        'content@': {
                            template: '<test-case-config  is-new="false" test-case="testCase" ' +
                                'state-after-save="programTests.pstCase" state-after-cancel="programTests.pstCase">' +
                                '</test-case-config>',
                            controller: function ($scope, entity) {
                                $scope.testCase = entity;
                            },
                            resolve: {
                                entity: ['ProgramTestModelService', 'ProgramTestService', '$stateParams',
                                    function (ProgramTestModelService, ProgramTestService, $stateParams) {
                                        var id = $stateParams.id;
                                        return ProgramTestService.getTestCase(id);
                                    }]
                            }
                        }
                    }
                })
                .state('programTests.pstCase.clone', {
                    parent: 'programTests.pstCase',
                    url: '/clone/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'programTest.program.cloneLabel'
                    },
                    views: {
                        'content@': {
                            template: '<test-case-config type="PROTOCOL" is-cloned="true" is-new="false" test-case="testCase" ' +
                                'state-after-save="programTests.pstCase" state-after-cancel="programTests.pstCase"></test-case-config>',
                            controller: function ($scope, entity) {
                                $scope.testCase = entity;
                            }
                        }
                    },
                    resolve: {
                        entity: ['ProgramTestModelService', 'ProgramTestService', '$stateParams',
                            function (ProgramTestModelService, ProgramTestService, $stateParams) {
                                var id = $stateParams.id;
                                return ProgramTestService.getTestCase(id);
                            }]
                    }
                })
                .state('programTests.pstCase.run', {
                    parent: 'programTests.pstCase',
                    url: '/run/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'programTest.program.title'
                    },
                    views: {
                        'content@': {
                            template: '<program-test-case-run id="id">' + '</program-test-case-run>',
                            controller: function ($scope, id) {
                                $scope.id = id;
                            },
                            resolve: {
                                id: ['$stateParams', function ($stateParams) {
                                    return $stateParams.id;
                                }]
                            }
                        }
                    }
                });
        });

})();
