(function () {
    'use strict';

    /**
     * directive for Form field Comparison
     */
    angular.module('acadiamasterApp').directive('formCompareField', function (CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/field/formCompare.field.html',
            scope: {
                compareResult: '=',
                showChangeOnly : '='
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;

                // getting the title status using status of detail, displayConfig, and fieldValue
                $scope.getTitleStatus = function() {
                    var fakeObject = {
                        detail : $scope.compareResult.detail,
                        dc : $scope.compareResult.displayConfig,
                        fv : $scope.compareResult.fieldValue
                    };

                    return CompareResultUtilService.collectStatus(fakeObject);
                };
            }
        };
    });

})();

