(function () {


    /**
     * directive for selecting unauthenticated tasks
     */
    angular.module('acadiamasterApp').directive('unauthenticatedTaskSelection', function (UnauthenticatedTaskService, AlertService) {
        return {
            restrict    : 'E',
            templateUrl : 'admin-templates/site/common/directive/unauthenticatedTaskSelection.html',
            scope       : {
                taskOptions : '<',
                isRequired  : '<',
                title       : '@',
            },
            link : function ($scope) {
                $scope.data = {
                    allTasks : null,
                    task     : $scope.taskOptions ? $scope.taskOptions.getTask() : null,
                };

                init($scope);

                addWatches($scope);
            },
        };

        function init($scope) {
            const programId = $scope.taskOptions.programId;

            UnauthenticatedTaskService.getAllTasks(programId).then(function (response) {
                $scope.data.allTasks = response.data;
            }).catch(function (error) {
                AlertService.error('Failed to load unauthenticated task list by program id ', programId, error);
            });
        }

        function addWatches($scope) {
            $scope.$watch('data.task', function (newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }

                $scope.taskOptions.setTask(newValue);
            });
        }
    });
})();
