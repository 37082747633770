/**
 * directive for configuring the template and its value
 */
angular.module('acadiamasterApp').directive('containerSubpageTemplateConfig', (TemplateCacheService, AlertService) => ({
    link($scope) {
        $scope.data = {
            templateKeys : null,
        };

        $scope.collapsed = false;

        $scope.isOpen = () => !$scope.collapsed;

        $scope.toggle = () => {
            $scope.collapsed = !$scope.collapsed;
        };

        init($scope.data);
        /**
         * initialize data needed for the directive include list of template keys and the template itself
         * @param data - data object used to store the information
         */
        function init(data) {
            TemplateCacheService.loadTemplateKeys(false).then(function(result) {
                data.templateKeys = result;
            }, function (error) {
                AlertService.error(error);
            });
        }
    },
    restrict : 'E',
    scope    : {
        container : '<',
        isPreview : '<?',
        containerList: '<',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/containerSubpageTemplateConfig.html',
}));

