/**
 * Created by Jamie Nola on 08/10/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('ProgramDetailViewService', function ($http, localWeb) {
            return {
                getProgramData: function (programId) {
                    return $http.get(localWeb.createUrl('/api/programs/' + programId + '/overview'));
                }
            };
        });
})();
