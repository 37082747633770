/**
 * Created by Jamie Nola on 03/18/2019
 */
angular.module('acadiamasterApp')
    .directive('configMultipleSelection', () => {
        return {
            bindToController : true,
            controller       : 'configMultiSelectController',
            controllerAs     : 'cms',
            restrict         : 'E',
            scope            : {
                currentSelections    : '=',
                defaultOption        : '@?',
                displayVerticalList  : '<?',
                exportObject         : '<?',
                infoText             : '@?',
                limitTo              : '<?',
                onChange             : '<?',
                possibleSelections   : '<',
                selectionListToAvoid : '<?',
                titleText            : '@?',
            },
            templateUrl : 'admin-templates/site/forms/configPanel/common/multipleSelection.html',
        };
    });
