'use strict';

angular.module('acadiamasterApp')
    .controller('RegisterController', function ($scope, $translate, $timeout, Auth, vbrCommonUtil,RegisterConstantService) {
        var vm=this;

        vm.success=null;
        vm.error=null;
        vm.errorUserExists =null;

        vm.registerAccount = {
            image:null,
            userPreferences: {
                locale: null,
                timezone: null
            }
        };
        vm.userRole=RegisterConstantService.roles.ROLE_ADMIN;
        vm.register = function () {
            vm.registerAccount.userPreferences.locale = $translate.use();
            vm.registerAccount.userPreferences.timezone = vbrCommonUtil.timeZone.getBrowserTimezone();
            vm.error = null;
            createUserUsingNgUpload();
        };

        function createUserUsingNgUpload(){
            // Call webservice with ngUpload library
            vm.file = null;
            // Set image name
            if (vm.registerAccount.image != null) {
                vm.file = vm.registerAccount.image;
                vm.registerAccount.profileImageUrl = vm.registerAccount.image.name;
                vm.registerAccount.image = null;
            }

            Auth.registerUser(vm.registerAccount, vm.file).then(function () {
                vm.errorUserExists = null;
                vm.errorEmailExists = null;
                vm.error = null;
                vm.success = 'OK';
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data.description === 'login already in use') {
                    vm.errorUserExists = 'ERROR';
                } else {
                    vm.error = 'ERROR';
                }
            });
        }
    });
