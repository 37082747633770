(function () {
    const app = angular.module('acadiamasterApp');

    /**
     * service for PropertyValueComparisonCondition model
     */
    app.factory('PropertyValueComparisonConditionModel', (QObjectModel, ProgramTestConstantsService,
                                                          PropertyLoadUtil, ValueTypeUtil,
                                                          TimeComparisonOperatorService) => {
        /** *************************************************************
         * private functions
         ************************************************************* */

        PropertyValueComparisonConditionModel.inheritsFrom(QObjectModel);

        function PropertyValueComparisonConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.PROPERTY_VALUE_COMPARISON);

            // initialized the variables
            this.propertyId = null;
            this.propertyValues = [''];
            this.comparator = ProgramTestConstantsService.operators.EQ;
            this.checkWithTime = false;

            // time check related fields
            // check if the time is absolute or not
            this.absolute = false;
            // comparator for the time check
            this.timeComparator = TimeComparisonOperatorService.GTE;
            // this value is either the absolute timestamp value (epoch in millisecond) or the
            // diff value from current timestamp
            this.value = 0;
            this.valueBeginAt = 0;
            this.valueEndAt = 0;
            this.timeUnit = ProgramTestConstantsService.TimeUnit.DAYS;

            this._property = null;
        }

        /**
         * convert the current UI model to dto property
         */
        PropertyValueComparisonConditionModel.prototype.toDto = function () {
            const dto = QObjectModel.prototype.toDto.call(this);

            dto.propertyId = this.propertyId;
            dto.propertyValues = this.propertyValues;
            dto.comparator = this.comparator == null ? null : this.comparator.name;
            dto.checkWithTime = this.checkWithTime;
            // fields for checking time
            dto.absolute = this.absolute;
            dto.timeComparator = this.timeComparator == null ? null : this.timeComparator.name;
            dto.value = convertDateValueToNumberIfNeeded(this.absolute, this.value);
            dto.valueBeginAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueBeginAt);
            dto.valueEndAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueEndAt);
            dto.timeUnit = this.absolute ? null : this.timeUnit;
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any property you have on the current object
         * @param dto
         */
        PropertyValueComparisonConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.propertyId = dto.propertyId;
            this.propertyValues = dto.propertyValues;
            this.comparator = ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator,
            );

            PropertyLoadUtil.loadProperty(this);
            this.checkWithTime = dto.checkWithTime;

            // fields for checking time
            this.absolute = dto.absolute;
            this.timeComparator = ProgramTestConstantsService.getObjectByName(
                TimeComparisonOperatorService, dto.timeComparator,
            );

            this.value = convertNumberValueToDateIfNeeded(dto.absolute, dto.value);
            this.valueBeginAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueBeginAt);
            this.valueEndAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueEndAt);
            this.timeUnit = dto.absolute ? null : dto.timeUnit;
        };

        PropertyValueComparisonConditionModel.prototype.setProperty = function (property) {
            this._property = property;
            this.propertyId = property == null ? null : property.id;
            this._validateSelf();
        };

        PropertyValueComparisonConditionModel.prototype.getProperty = function () {
            return this._property;
        };


        /**
         * setting the property value and convert it to a String
         * @param propertyValues - property value
         */
        PropertyValueComparisonConditionModel.prototype.setPropertyValues = function (propertyValues) {
            this.propertyValues = ValueTypeUtil.valueListConvertedToStringList(propertyValues);
            this._validateSelf();
        };

        /**
         * getting property value as it is
         * @returns {*}
         */
        PropertyValueComparisonConditionModel.prototype.getPropertyValues = function () {
            return this.propertyValues;
        };

        /**
         * getting property value as a property typed object depending on the value type of the property
         * ie: number, boolean, string, etc
         * @returns {*}
         */
        PropertyValueComparisonConditionModel.prototype.getPropertyValuesAsProperType = function () {
            if (this._property == null || this.propertyValues == null) {
                return null;
            }

            return ValueTypeUtil.stringValueListConvertedToType(this.propertyValues, this._property.valueType);
        };

        PropertyValueComparisonConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        PropertyValueComparisonConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.propertyValues = null;
                this.checkWithTime =false;
            } else if (this.propertyValues == null) {
                this.propertyValues = [''];
            } else if (comparator != ProgramTestConstantsService.operators.IN) {
                if (this.propertyValues.length > 1) {
                    // just use the first value
                    this.propertyValues = [this.propertyValues[0]];
                }
            }
            this._validateSelf();
        };

        PropertyValueComparisonConditionModel.prototype.getDescriptionAsHtml = function () {
            const baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            const propertyMsg = `Property (${this.propertyId == null ? 'no id' : this.propertyId} | ${
                this._property == null ? 'no name' : this._property.name})`;
            const comparatorMsg = ` <span class="badge badge-info">${this.comparator == null ? '?' : this.comparator.symbol
            }</span> `;
            const valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : this.propertyValues;

            const timeComparatorMsg = ` added/updated <span class="badge">${this.timeComparator == null ? '?' : this.timeComparator.description}</span> `;
            let timeMsg = timeMsgBuilder(this, this.value);
            const timeMsgBegin = timeMsgBuilder(this, this.valueBeginAt);
            const timeMsgEnd = timeMsgBuilder(this, this.valueEndAt);
            let baseString = baseMsg + propertyMsg + comparatorMsg + valueMsg ;
            if (this.timeComparator!=null && this.timeComparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                timeMsg = `${timeMsgBegin} and ${timeMsgEnd}`;
            } else {
                timeMsg += ' ago';
            }
            return  baseString + (this.checkWithTime === true ? (timeComparatorMsg + timeMsg): '');
        };

        PropertyValueComparisonConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if(this.comparator != ProgramTestConstantsService.operators.IS_NULL
                && (this.propertyValues == null || this.propertyValues.length == 0
                    || (this.propertyValues.length == 1 && this.propertyValues[0] == null ))) {
                this.setErrorMessage('property value is required except when comparator is "is null"');
            }

            if (this.propertyId == null) {
                this.setErrorMessage('property id is required');
            }


            if (this.comparator == null) {
                this.setErrorMessage('comparator is required');
            }


            // if checkWithTime is false, do not need time configuration
            if(!this.checkWithTime) {
                return;
            }

            if((this.timeComparator.name !== 'BETWEEN' && this.value == null)
                || (this.timeComparator.name === 'BETWEEN' && (this.valueBeginAt == null || this.valueEndAt == null))) {
                this.setErrorMessage('Valid time values are required.');
            }

            if (!this.absolute) {
                if (this.timeUnit == null) {
                    this.setErrorMessage('time unit is required');
                }

                if(this.valueBeginAt < this.valueEndAt && this.timeComparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                    this.setErrorMessage('begin time must larger than or equal to end time');
                }

                // relative time, value should be >=0 and time unit is not null
                if (this.value < 0 || this.valueBeginAt < 0 || this.valueEndAt < 0) {
                    this.setErrorMessage('relative value must be 0 or positive');
                }
            } else {
                // as the same variables are used for the relative and absolute time, the use of beginAt and endAt gets reversed.
                // for example,
                //    If absolute = true then beginAt < endAt because these are timestamps
                //    if absolute = false the beginAt > endAt because then days are moved back(ago)
                if(this.valueEndAt < this.valueBeginAt && this.timeComparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                    this.setErrorMessage('begin time must smaller than or equal to end time');
                }
            }

        };

        /**
         * set absolute time flag, and will change the value and time unit if needed
         */
        PropertyValueComparisonConditionModel.prototype.setAbsoluteTime = function (absoluteTime) {
            this.absolute = absoluteTime;

            console.log(`update time called: ${this.value}, ${this.absolute}, ${this.timeUnit}`);
            if (this.value == null) {
                return;
            }

            this.valueBeginAt = null;
            this.valueEndAt = null;

            this.timeUnit = this.absolute ? null : ProgramTestConstantsService.TimeUnit.DAYS;

            if (this.absolute && !(this.value instanceof Date)) {
                // absolute value and not a date, set it to local date now
                this.value = new Date();
            } else if (!this.absolute && (this.value instanceof Date)) {
                this.value = 0;
            }
        };

        /**
         * converting a value into a local date time if necessary
         * @param isDate - flag to indicate if we should convert to date
         * @param numberValue - numerical value of a possible date in GMT timezone, ie: 1970-01-01 00:00 GMT = 0
         * @return {*} - the value back or a date object with value in local timezone
         */
        function convertNumberValueToDateIfNeeded(isDate, numberValue) {
            if (!isDate || numberValue == null) {
                return numberValue;
            }

            if (isNaN(numberValue)) {
                // should never happen
                console.warn('unable to convert value to date', numberValue);
                return numberValue;
            }

            return new Date(numberValue);
        }

        /**
         * convert a possible date value to the time number in GMT
         * @param isDate - flag to indicate if it's date value or not
         * @param dateValue - a normal numeric value or a date object in local time zone
         * @returns {*} - time value of the date value converted into GMT timestamp in epoch
         */
        function convertDateValueToNumberIfNeeded(isDate, dateValue) {
            if (!isDate || dateValue == null) {
                return dateValue;
            }

            return dateValue.getTime();
        }

        function timeMsgBuilder(condition, value) {
            return condition.absolute ? getAbsoluteTime(value) : getRelativeTime(value, condition.timeUnit);
        }

        function getAbsoluteTime(dateValue) {
            if (dateValue == null) {
                return '?';
            }

            if (_.isFunction(dateValue.toUTCString)) {
                return dateValue.toUTCString();
            }

            return '?';
        }

        function getRelativeTime(relativeValue, timeUnit) {
            var msg = relativeValue == null ? '?' : '' + relativeValue;

            msg += ' ' + timeUnit;

            return msg;
        }

        /** *************************************
         * service return call
         ************************************** */
        return PropertyValueComparisonConditionModel;
    });
}());
