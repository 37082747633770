/**
 * Created by Jamie Nola on 02/05/2019
 */
(function () {
    'use strict';

    /**
     * directive for editing string values
     */
    angular.module('acadiamasterApp')
        .directive('stringLocaleDetailTable', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/stringManagement/localeDetailTable/stringLocaleDetailTable.template.html',
                scope: {
                    values: '='
                },
                controller: function ($scope) {
                    // this controller is empty.
                }
            };
        });
})();
