/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
(function () {
    angular.module('acadiamasterApp')
        .directive('vbrDrcPairingSearch', () => {
            return {
                controller : function ($scope, AlertService, PairingService, ngDialog) {
                    $scope.searchData = {
                        searchResults : [],
                        searchString  : '',
                    };

                    $scope.search = function () {
                        let searchString = $scope.searchData.searchString;
                        if (searchString.length == 0) {
                            AlertService.warning('Cannot search with an empty string');
                            return;
                        }
                        let searchArray = searchString.split(',').map(item => {
                            return item.trim();
                        });
                        if (searchArray.length > 3) {
                            AlertService.warning('No more than 3 ids can be searched at a given time');
                            return;
                        }
                        PairingService.search(searchArray)
                            .then(response => {
                                $scope.searchData.searchResults = response.data;
                            }).catch(() => {
                                $scope.searchData.searchResults = [];
                            });
                    };

                    $scope.openUpdatePairingDialog = function (userPairingInfo) {
                        let results = $scope.searchData.searchResults;
                        ngDialog.openConfirm({
                            className       : 'ngdialog-theme-plain custom-width-large',
                            closeByDocument : false,
                            controller      : [ '$scope', function ($scope) {
                                $scope.userPairingInfo = angular.copy(userPairingInfo);
                                $scope.onSuccess = function (updatedUserInfo) {
                                    updateResults(results, updatedUserInfo);
                                };
                            } ],
                            plain    : true,
                            scope    : $scope,
                            size     : 'lg',
                            template : '<vbr-drc-pairing-update user-pairing-info="userPairingInfo" on-success-callback="onSuccess"></vbr-drc-pairing-update>',
                        });
                    };
                },
                restrict    : 'E',
                templateUrl : 'admin-templates/site/drc/pairingManagement/searchUpdate/drc.pairingSearch.html',

            };

            function updateResults (results, updatedUserInfo) {
                if (results != null) {
                    for (let i = 0; i < results.length; i++) {
                        if (results[i].userId === updatedUserInfo.userId) {
                            results[i] = updatedUserInfo;
                        }
                    }
                }
            }
        });
}());
