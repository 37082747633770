(function () {
    // eslint-disable-next-line angular/controller-name
    angular.module('acadiamasterApp').controller(
        'appSettingsConfigController',
        function (
            ProgramBranding, $scope, localWeb, CONFIG, $state, FirebaseProject, ProgramAppSettings,
        ) {
            const vm = this;
            $scope.CONFIG = CONFIG;
            vm.programId = $scope.programId;
            $scope.isDataReady = false;
            FirebaseProject.getProjects(response => {
                $scope.firebaseProjects = response;
            });

            ProgramAppSettings.getAppSettingsDetails(vm.programId).then(response => {
                let result = response.data;
                $scope.appSettings = result;
            });

            $scope.save = function () {
                $scope.appSettings.programId = $scope.programId;
                ProgramAppSettings.setAppSettingsDetails($scope.appSettings).then(response => {
                    if (vm.programId != null) {
                        $state.go('program.manage', { id : vm.programId });
                    } else {
                        $state.go('program');
                    }
                });
            };
        },
    );
}());
