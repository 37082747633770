/**
 * Created by moustafabaiou on 2/17/17
 */

angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one state rule condition
     */
    .directive('treeNodeFormCondition', (FormUtilService, FormConstants, $timeout, ngDialog) => ({
        controller($scope) {
            $scope.cs = FormUtilService.cs;
            $scope.allActionTypes = angular.copy(FormUtilService.formStateActionService.allActionTypes);

            $scope.states = {
                isAddActionPopupOpen: false,
            };

            $scope.showAddActionDialog = ($event) => {
                $scope.states.isAddActionPopupOpen = true;
                ngDialog.openConfirm({
                    className: 'ngdialog-theme-plain custom-width-small',
                    preCloseCallback: () => {
                        $timeout(() => {
                            $scope.states.isAddActionPopupOpen = false;
                        });
                    },
                    scope: $scope,
                    templateUrl: 'admin-templates/site/components/treeEditorDialogs/addActionNew.template.html',
                }).then((actionType) => {
                    $scope.element.addAction(actionType, $scope.selector, $scope.currentTreeName);
                });
                $event.stopPropagation();
            };

            $scope.flags = {
                dropDownIsOpen: false,
            };

            $scope.getActionTypes = () => {
                // get form mode object parent for specified element
                const formMode = $scope.element.getFormMode();

                _.forEach($scope.allActionTypes, (actionType) => {
                    actionType.isHidden = !_.some(actionType.actionType.availableInModes, { name: formMode.nodeType.name });
                });
                return $scope.allActionTypes;
            };

            /**
                         * clone the condition, insert it right below the current one
                         */
            $scope.cloneCondition = () => {
                const condition = $scope.element;
                condition._parent.cloneCondition(condition, $scope.selector, $scope.currentTreeName);
                $scope.flags.dropDownIsOpen = false;
            };

            /**
                         * delete current condition
                         */
            $scope.deleteCondition = () => {
                $scope.element._parent.removeCondition($scope.element, $scope.selector);
                $scope.selector.selectItem($scope.element._parent);
                $scope.flags.dropDownIsOpen = false;
            };

            /**
             * cut the current element, this doesn't make any change to the system until
             * a paste action is made later, the object reference is stored in the selector
             */
            $scope.doCut = () => {
                $scope.selector.cutItem($scope.element);
                $scope.flags.dropDownIsOpen = false;
            };

            /**
             * copy the current element, this doesn't make any change to the system until
             * a paste action is made later, the object reference is stored in the selector
             */
            $scope.doCopy = () => {
                $scope.selector.copyItem($scope.element);
                $scope.flags.dropDownIsOpen = false;
            };

            /**
             * check if the current item in buffer can be pasted as a sibling
             * @returns {boolean}
             */
            $scope.canPasteBelowAsSibling = () => {
                const itemInBuffer = $scope.selector.getItemInBuffer();

                if (itemInBuffer == null) {
                    return false;
                }

                // check for matching type and make sure this is not the same as current condition
                return itemInBuffer.nodeType == FormConstants.nodeType.RULE_CONDITION_WITH_ACTION
                    && itemInBuffer != $scope.element;
            };


            /**
             * paste the last element copied or cut as a sibling
             * ie: this element copied of cut must be a form condition
             */
            $scope.doPasteBelowAsSibling = () => {
                let elementInBuffer = $scope.selector.getItemInBuffer();

                // remove the element from its previous parent
                const oldParent = elementInBuffer._parent;

                if ($scope.selector.isBufferCut) {
                    // cut
                    oldParent.removeCondition(elementInBuffer);
                } else {
                    // make a copy
                    elementInBuffer = elementInBuffer.customClone();
                }

                // link it to the parent of this element
                elementInBuffer._parent = $scope.element._parent;

                // add it to the next spot in the children array
                const conditions = $scope.element._parent.conditionWithActionsList;
                const index = conditions.indexOf($scope.element);
                conditions.splice(index + 1, 0, elementInBuffer);

                // select the new item
                $scope.selector.selectItem(elementInBuffer);

                $scope.selector.itemPasted();

                $scope.flags.dropDownIsOpen = false;
            };

            /**
             * check if the current item in buffer can be pasted as a child
             * @returns {boolean}
             */
            $scope.canPasteAsChild = () => {
                const itemInBuffer = $scope.selector.getItemInBuffer();

                if (itemInBuffer == null) {
                    return false;
                }

                // check for matching type and make sure this is not already a child
                return itemInBuffer.nodeType == FormConstants.nodeType.RULE_ACTION
                    && itemInBuffer._parent != $scope.element;
            };

            /**
             * paste the last element copied or cut as a child
             * ie: this element copied of cut must be a state rule action
             */
            $scope.doPasteAsChild = () => {
                let elementInBuffer = $scope.selector.getItemInBuffer();

                // remove the element from its previous parent
                const oldParent = elementInBuffer._parent;

                if ($scope.selector.isBufferCut) {
                    // cut
                    oldParent.removeComponent(elementInBuffer);
                } else {
                    // make a copy
                    elementInBuffer = elementInBuffer.customClone();
                }

                // link it to this element
                elementInBuffer._parent = $scope.element;

                // add it to the top of the children array
                $scope.element.actions.unshift(elementInBuffer);
                $scope.selector.selectItem(elementInBuffer);

                $scope.selector.itemPasted();

                $scope.flags.dropDownIsOpen = false;
            };
        },
        restrict: 'E',
        scope: {
            currentTreeName: '=',
            element: '=',
            selector: '=',
        },
        templateUrl: 'admin-templates/site/forms/treeNode/formStateRule.condition.html',
    }));
