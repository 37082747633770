/**
 * Created by kjirage on 01/09/18
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('ConfigurationIdsService', function ($http, localWeb) {

            var baseApi = localWeb.createUrl('api/configurationTableIds');
            return {
                getConfigurationIds: function () {
                    return $http.get(baseApi);
                }
            };
        });
})();

