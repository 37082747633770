(function () {
    angular.module('acadiamasterApp')
        .factory('ProgramService', ($resource, localWeb, Upload, $http) => {
            let url = localWeb.createUrl('api/programs/');
            let program = $resource(`${url}:id`,
                {},
                {
                    get : {
                        method            : 'GET',
                        transformResponse : function (data) {
                            // eslint-disable-next-line no-param-reassign
                            data = angular.fromJson(data);
                            return data;
                        },
                    },
                    query  : { isArray : true, method : 'GET' },
                    update : { method : 'POST' },
                });
            program.createUpdate = function (files, data, onSaveFinished, onErrorCallBack) {
                // eslint-disable-next-line no-use-before-define
                createEdit(files, data, onSaveFinished, onErrorCallBack);
            };
            function createEdit (files, data, onSaveFinished, onErrorCallBack) {
                // send them all together for HTML5 browsers:
                Upload.upload({
                    arrayKey : '',
                    // to allow files array to upload with param name file instead of file[i]
                    data     : {
                        data : JSON.stringify(data),
                        file : files,
                    },
                    url : localWeb.createUrl('api/programs/'),
                }).then(resp => {
                    onSaveFinished();
                }, error => {
                    // eslint-disable-next-line no-undef
                    if (_.isFunction(onErrorCallBack)) {
                        onErrorCallBack(error);
                    }
                });
            }

            program.getAllPrograms = function () {
                let url = localWeb.createUrl('api/programs/');
                return $http.get(url);
            };

            program.getAllDependencyLinks = function (id) {
                let url = localWeb.createUrl('api/admin/expressionDependencies/');
                if (id != null) {
                    url = `${url }?programId=${ id}`;
                }
                return $http.get(url);
            };

            program.isNameAvailable = function (programName, id, type) {
                let finalUrl = null;
                switch (type) {
                case 'NAME': finalUrl = localWeb.createUrl('api/programs/nameAvailable/') + programName;
                    if (id != null) {
                        finalUrl = `${finalUrl }?programId=${ id}`;
                    }
                    break;
                case 'CODE':
                    finalUrl = localWeb.createUrl('api/programs/codeAvailable/') + programName;
                    if (id != null) {
                        finalUrl = `${finalUrl }?programId=${ id}`;
                    }
                    break;
                }

                return $http.get(finalUrl);
            };

            program.changeConfigurationCompleteStatus = function (programId, configurationCompleteStatus) {
                let finalUrl = localWeb.createUrl('api/programs/changeConfigurationCompleteStatus');
                let config = {};
                if (programId != null) {
                    config = {
                        params : {
                            configurationCompleteStatus : configurationCompleteStatus,
                            programId                   : programId,
                        },
                    };
                }
                return $http.get(finalUrl, config);
            };

            program.deactivateUsers = function (programId) {
                let finalUrl = localWeb.createUrl('api/programs/deactivateUsers');
                let config = {};
                if (programId != null) {
                    config = {
                        params : {
                            programId : programId,
                        },
                    };
                }
                return $http.get(finalUrl, config);
            };

            program.hasActiveUsers = function (programId) {
                let finalUrl = localWeb.createUrl('api/programs/hasActiveUsers');
                let config = {};
                if (programId != null) {
                    config = {
                        params : {
                            programId : programId,
                        },
                    };
                }
                return $http.get(finalUrl, config);
            };

            program.getDirectIndirectlyAssociatedRules = function (searchDto) {
                let finalUrl = localWeb.createUrl('api/programs/directIndirectlyAssociatedRules');
                let config = {
                    params : searchDto,
                };
                return $http.get(finalUrl, config);
            };

            return program;
        })

        .factory('ProgramWithoutProfileForm', ($resource, localWeb) => {
            return $resource(localWeb.createUrl('api/programs/nouserprofileforms/'), {}, {
                query : { isArray : true, method : 'GET' },
            });
        })
        .factory('ProgramSearch', [ '$http', 'localWeb', function ($http, localWeb) {
            let url = localWeb.createUrl('api/programs/search?');
            return {
                query : function (searchFilter) {
                    return $http({
                        method : 'GET',
                        // eslint-disable-next-line no-undef
                        url    : url + $.param(searchFilter),
                    }).then(response => {
                        return response;
                    }, error => {
                        console.log(error);
                        return error;
                    });
                },
            };
        } ]);
}());
