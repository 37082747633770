/**
 * Created by Jamie Nola on 08/14/2019
 *
 * directive for configuring container items, this is the parent directive that will
 * in term load other directives
 */

angular.module('acadiamasterApp').directive('containerReferenceConfig', () => ({
    controller($scope) {
        // flag to indicate if the configuration should be collapsed
        $scope.collapsed = false;

        $scope.isOpen = () => !$scope.collapsed;

        $scope.toggle = () => {
            $scope.collapsed = !$scope.collapsed;
        };

        $scope.data = {
            containers: [],
        };

        $scope.$watch('container.containerType', (value) => {
            const allowedTypes = [];

            switch (value) {
            case 'MULTI_FORM_RETURN_OF_RESULTS':
                allowedTypes.push('MULTI_FORM');
                break;
            case 'SINGLE_QUESTION_FORM_RETURN_OF_RESULTS':
                allowedTypes.push('SINGLE_QUESTION_FORM');
                break;
            default:
                break;
            }

            // filter containers to only show ones with an ID so we can set the
            // associatedContainerId
            let isValid = false;
            const containers = [];
            Object.keys($scope.containerList).forEach((key) => {
                const group = $scope.containerList[key];
                group.forEach((container) => {
                    if (container.id != null
                        && allowedTypes.indexOf(container.containerType) > -1
                        && container.uniqueId !== $scope.container.uniqueId) {
                        if (container.id == $scope.container.associatedContainerId) {
                            isValid = true;
                        }
                        const model = {
                            id: container.id,
                            name: container.name,
                            label: container.name + ' (id: ' + container.id + ')'
                        };
                        containers.push(model);
                    }
                });

            });

            if (!isValid) {
                $scope.container.associatedContainerId = null;
            }
            $scope.data.containers = containers;
        });
    },
    restrict: 'E',
    scope: {
        container: '=',
        containerList: '<',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
        + 'config/containerReference/containerReferenceConfig.template.html',
}));
