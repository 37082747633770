(function () {
    'use strict';

    /**
     * constant to store all the comparison operators for time
     */

    angular.module('acadiamasterApp').constant('TimeComparisonOperatorService', {
        GTE: {name: 'GTE', description: ' after or at '},
        LTE: {name: 'LTE', description: ' before or at '},
        BETWEEN: {name: 'BETWEEN', description: ' between '},
    });
})();


