angular.module('acadiamasterApp').directive('containerItemTypeSpecificConfigExternalIntegration', (
    LocaleConstantsService,
) => ({
    link($scope) {
        $scope.lcs = LocaleConstantsService;

        $scope.getImageUrl = () => {
            return $scope.config.icon.getImageUrl();
        };

        $scope.getImageUri = () => {
            return $scope.config.icon.getImageUri();
        };

        $scope.imageRemoved = () => {
            return $scope.config.icon.imageRemoved();
        };

        $scope.imageCropped = (imageUri, imageFile) => {
            return $scope.config.icon.imageCropped(imageUri, imageFile);
        };
    },
    restrict: 'E',
    scope: {
        config: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement'
        + '/config/containerItem/typeSpecific/containerItemTypeSpecificConfigExternalIntegration.html',
}));
