
(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('CorrectiveActionUnauthenticatedTaskModel', function (
        CorrectiveActionBaseModel,
        ProgramTestConstantsService,
        UnauthenticatedTaskService,
        AlertService,
    ) {
        /***************************************************************
         * private functions
         **************************************************************/

        CorrectiveActionUnauthenticatedTaskModel.inheritsFrom(CorrectiveActionBaseModel);

        function CorrectiveActionUnauthenticatedTaskModel(parent) {
            CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.UNAUTHENTICATED_TASK);
            this.taskId = null;
        }

        /**
         * convert the current UI model to dto format
         */
        CorrectiveActionUnauthenticatedTaskModel.prototype.toDto = function () {
            const dto = CorrectiveActionBaseModel.prototype.toDto.call(this);
            dto.taskId = this.taskId;
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        CorrectiveActionUnauthenticatedTaskModel.prototype.fromDto = function (dto) {
            CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);
            this.taskId = dto.taskId;

            UnauthenticatedTaskService.getTaskById(this.taskId).then(response => {
                this._task = response.data;
            }).catch(function (error) {
                AlertService.error('Failed to find unauthenticated task by id.', error);
            });
        };

        CorrectiveActionUnauthenticatedTaskModel.prototype.getTask = function () {
            return this._task;
        };

        CorrectiveActionUnauthenticatedTaskModel.prototype.setTask = function (task) {
            this._task = task;
            this.taskId = task? task.id : null;
        };

        CorrectiveActionUnauthenticatedTaskModel.prototype.getProgramId = function () {
            const rootElement = this.getRoot();
            return rootElement==null ? null : rootElement.programId;
        };

        CorrectiveActionUnauthenticatedTaskModel.prototype.getDescriptionAsHtml = function () {
            const baseMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);
            return `${baseMsg} ID ${this.taskId}`;
        };

        CorrectiveActionUnauthenticatedTaskModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.taskId == null) {
                this.setErrorMessage('task id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return CorrectiveActionUnauthenticatedTaskModel;
    });
})();
