/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
(function () {
    /**
     * service for program test model
     */
    angular.module('acadiamasterApp').factory('ProgramTestModelService',
        (ModelServiceBase, PreconditionCreationUtil, CorrectiveActionCreationUtil,
            ProgramTestConstantsService, ProgramTestService, ConditionTreeCreationUtil, ExpressionConditionModelService,
            ExpressionConditionConstantsService) => {
            ProgramTestModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            /*
             * Program Test Model
             * @constructor
             */
            function ProgramTestModel (programId) {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false, null);
                this.id = null;
                this.name = null;
                this.nameAvailable = true;
                this.description = null;
                this.programId = programId;
                this.alertAdminOnPreConditionFailure = false;
                this.alertAdminOnFailure = false;
                this.autoCorrectOnFailure = false;
                this.tagMappings = [];
                this.assertCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.PTF);
                this.ifCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.PTF);
                this.correctiveActions = [];
                this.preConditions = [];
                this.nodeType = ProgramTestConstantsService.nodeType.BASIC_INFO;

                this.preConditionsCollapsed = false;
                this.correctiveActionsCollapsed = false;
            }

            /*
             * convert the current UI model to dto format
             */
            ProgramTestModel.prototype.toDto = function () {
                let dto = {};
                dto.id = this.id;
                dto.name = this.name;
                dto.description = this.description;
                dto.programId = this.programId;
                dto.alertAdminOnPreConditionFailure = this.alertAdminOnPreConditionFailure;
                dto.alertAdminOnFailure = this.alertAdminOnFailure;
                dto.autoCorrectOnFailure = this.autoCorrectOnFailure;
                dto.tagMappings = this.tagMappings;

                dto.assertCondition = this.assertCondition.toDto();
                dto.ifCondition = this.ifCondition.toDto();

                dto.preConditions = [];
                _.forEach(this.preConditions, preCondition => {
                    dto.preConditions.push(preCondition.toDto());
                });

                dto.correctiveActions = [];
                _.forEach(this.correctiveActions, correctiveAction => {
                    dto.correctiveActions.push(correctiveAction.toDto());
                });

                return dto;
            };

            /*
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            ProgramTestModel.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.name = dto.name;
                this.description = dto.description;
                this.programId = dto.programId;
                this.alertAdminOnPreConditionFailure = dto.alertAdminOnPreConditionFailure;
                this.alertAdminOnFailure = dto.alertAdminOnFailure;
                this.autoCorrectOnFailure = dto.autoCorrectOnFailure;
                this.tagMappings = dto.tagMappings;
                this.correctiveActions = dto.correctiveActions;

                this.assertCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.PTF);
                this.assertCondition.fromDto(dto.assertCondition);

                this.ifCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.PTF);
                this.ifCondition.fromDto(dto.ifCondition);

                let preconditionList = [];
                let _this = this;
                _.forEach(dto.preConditions, dto => {
                    let preConditionModel = PreconditionCreationUtil.createPreconditionByType(dto.type, _this);
                    if (preConditionModel != null) {
                        preConditionModel.fromDto(dto);
                        preconditionList.push(preConditionModel);
                    }
                });
                this.preConditions = preconditionList;

                let correctiveActionList = [];
                _.forEach(dto.correctiveActions, correctiveAction => {
                    let correctiveActionModel = CorrectiveActionCreationUtil.createByType(correctiveAction.type, _this);
                    if (correctiveActionModel != null) {
                        correctiveActionModel.fromDto(correctiveAction);
                        correctiveActionList.push(correctiveActionModel);
                    }
                });
                this.correctiveActions = correctiveActionList;
            };

            /*
             * adding a precondition
             * @param preConditionModel
             * @param selector
             */
            ProgramTestModel.prototype.addPreCondition = function (preConditionModel, selector) {
                this.preConditions.push(preConditionModel);
                this.preConditionsCollapsed = false;

                if (selector && selector.selectItem) {
                    selector.selectItem(preConditionModel, true, false);
                }
            };

            /*
             * remove a state rule from the mode
             */
            ProgramTestModel.prototype.removePreCondition = function (preCondition, selector) {
                let index = this.findPreconditionIndex(preCondition);

                if (index != null && index >= 0) {
                    this.preConditions.splice(index, 1);
                    if (selector != null) {
                        selector.itemRemoved(preCondition);
                    }
                }
            };

            ProgramTestModel.prototype.addCorrectiveAction = function (correctiveAction, selector) {
                this.correctiveActions.push(correctiveAction);
                this.correctiveActionsCollapsed = false;

                if (selector && selector.selectItem) {
                    selector.selectItem(correctiveAction, true, false);
                }
            };

            /*
             * remove a state rule from the mode
             */
            ProgramTestModel.prototype.removeCorrectiveAction = function (correctiveAction, selector) {
                let index = this.findCorrectiveActionIndex(correctiveAction);

                if (index != null && index >= 0) {
                    this.correctiveActions.splice(index, 1);
                    if (selector != null) {
                        selector.itemRemoved(correctiveAction);
                    }
                }
            };

            ProgramTestModel.prototype.findPreconditionIndex = function (preCondition) {
                let preConditions = this.preConditions;
                return _.findIndex(preConditions, pc => {
                    return pc == preCondition;
                });
            };

            ProgramTestModel.prototype.findCorrectiveActionIndex = function (correctiveAction) {
                let correctiveActions = this.correctiveActions;
                return _.findIndex(correctiveActions, ca => {
                    return ca == correctiveAction;
                });
            };

            ProgramTestModel.prototype.checkNameAvailability = function () {
                let _this = this;
                ProgramTestService.isNameAvailable(this.name, this.id)
                    .then(response => {
                        _this.nameAvailable = response.data?.isValid;
                    });
            };

            ProgramTestModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.name == null) {
                    this.setErrorMessage('name is required');
                    return;
                }

                if (this.name.length > 255) {
                    this.setErrorMessage('name is too long');
                    return;
                }

                if (this.description == null) {
                    this.setErrorMessage('description is required');
                }

                if (this.description != null && this.description.length > 1024) {
                    this.setErrorMessage('description is too long');
                    return;
                }

                if (this.programId == null) {
                    this.setErrorMessage('program selection is required');
                    return;
                }

                if (!this.nameAvailable) {
                    this.setErrorMessage('test name is not available');
                }

                if (this.ifCondition !== null && this.assertCondition !== null && !isEmpty(this.ifCondition.name) && !isEmpty(this.assertCondition.name) && this.ifCondition.name === this.assertCondition.name) {
                    this.setErrorMessage('If condition and assert condition cannot have the same name.');
                }
            };

            function isEmpty (str) {
                return !str || str.length === 0;
            }

            /* *************************************
             * utility functions
             ***************************************/
            function loadModelFromDto (dto) {
                let model = new ProgramTestModel();
                model.fromDto(dto);
                return model;
            }

            /* *************************************
             * service return call
             ***************************************/
            return {
                loadModelFromDto : loadModelFromDto,
                ProgramTestModel : ProgramTestModel,
            };
        });
}());
