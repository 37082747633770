angular.module('acadiamasterApp')
    .controller('agreementListController', ($scope, $document, entity, AgreementsService, FormPreviewService, ngDialog, CONFIG, DependencyLinkService) => {
        const vm = this;

        function init() {
            vm.isReadOnlyServer = CONFIG.readOnlyServer;
            vm.loadAll();
        }

        entity.$promise.then(
            (response) => {
                vm.entity = response;
                init();
            },
            (error) => {
                console.error('Error loading program entity.', error);
            },
        );

        const IDS = {
            ACTIONS: 'actions',
            ENTITY: 'entity',
            FORMID: 'formId',
            ID: 'id',
            ORDER: 'displayOrder',
            TITLE: 'title',
            TYPE: 'type',
        };

        vm.FIELD_TYPES = {
            ID: 'id',
            ACTIONS: 'actions',
            LINK: 'link',
            ENUM: 'enum',
            TITLE: 'title',
        };

        vm.fields = [
            { colWidth: 1, fieldType: vm.FIELD_TYPES.ID, id: IDS.ID },
            { colWidth: 2, fieldType: vm.FIELD_TYPES.ENUM, id: IDS.TYPE },
            { colWidth: 3, fieldType: vm.FIELD_TYPES.LINK, id: IDS.ENTITY },
            { colWidth: 4, fieldType: vm.FIELD_TYPES.TITLE, id: IDS.TITLE },
            { colWidth: 2, fieldType: vm.FIELD_TYPES.ACTIONS, id: IDS.ACTIONS },
        ];

        vm.data = {
            agreementMap: {},
            agreements: [],
            isLoading: true,
        };

        vm.selectedAgreement = null;

        function doDrop(event) {
            if (event.dest.index !== event.source.index) {
                const params = {
                    data: vm.data.agreements.map((agreement, index) => (
                        {
                            displayOrder: index + 1,
                            id: agreement.id,
                        }
                    )),
                    programId: vm.entity.id,
                };


                AgreementsService.reorderAgreements(params).then((response) => {
                    vm.data.agreements = response.data.map((data) => {
                        vm.data.agreementMap[data.id].displayOrder = data.displayOrder;
                        return vm.data.agreementMap[data.id];
                    });
                }, (error) => {
                    console.error('Error loading agreements', error);
                });

                vm.selectedAgreement = null;
            }
        }

        /**
         * dropped: the action performed after drag and drop
         */
        vm.treeOptions = {
            dropped: doDrop,
        };

        vm.onPopoverOpened = ($event) => {
            $document[0].body.click();
            $event.stopPropagation();
        };

        /**
        * Transforms agreement data into a form that the UI can use
        * @param {array} agreements
        */
        function formatAgreements(agreements) {
            return agreements.map((agreement) => {
                const newAgreement = {
                    isActionPopoverOpen: false,
                };
                if (agreement.formId) {
                    newAgreement[IDS.ENTITY] = `${agreement.formId} - ${agreement.formName} `;
                }
                newAgreement[IDS.ORDER] = agreement.displayOrder;
                newAgreement[IDS.ID] = agreement.id;
                newAgreement[IDS.TITLE] = agreement.displayTitle.en
                    || agreement.displayTitle.es
                    || agreement.displayTitle[Object.keys(agreement.displayTitle)[0]];
                newAgreement[IDS.TYPE] = agreement.type;
                newAgreement[IDS.FORMID] = agreement.formId;
                vm.data.agreementMap[newAgreement[IDS.ID]] = newAgreement;
                return newAgreement;
            }).sort((a, b) => a.displayOrder - b.displayOrder);
        }

        /**
         * preview the active version of the form
         */
        vm.previewForm = (formId) => {
            const form = {
                id: formId,
            };
            FormPreviewService.preview(form, null);
        };

        vm.loadAll = () => {
            vm.data.isLoading = true;
            const params = {
                programId: vm.entity.id,
            };
            AgreementsService.loadAgreements(params).then((response) => {
                vm.data.agreements = formatAgreements(response.data);
            }, (error) => {
                console.error('Error loading agreements', error);
            }).finally(() => {
                vm.data.isLoading = false;
            });
        };

        /**
         * select agreement
         * @param agreement - agreement to be selected
         */
        vm.selectAgreement = (agreement) => {
            vm.selectedAgreement = agreement;
            agreement.isActionPopoverOpen = false;
        };

        /**
         * check if there is an agreement selected
         * @returns {boolean} - true if an agreement is selected, false otherwise
         */
        vm.hasSelectedAgreement = () => vm.selectedAgreement != null;

        /**
         * Delete agreement dialog
         */
        vm.openDeleteDialog = (agreement) => {
            if(vm.isReadOnlyServer){
                return;
            }
            DependencyLinkService.getAgreementDependencyLink(agreement.id).then(
                response => {
                    const dpLinkMap = DependencyLinkService.consolidateDPBySourceType(
                        response.data);
                    if (dpLinkMap.size > 0) {
                        //open dialog to show the list of dependency link table
                        ngDialog.open({
                            className: 'ngdialog-theme-plain custom-width-medium',
                            controller: 'DependencyLinkDialogController',
                            controllerAs: 'dpLink',
                            resolve: {
                                dpType: () => `Agreement ID: ${agreement.id}`,
                                dpLinkMap: () => dpLinkMap,
                            },
                            templateUrl: 'admin-templates/site/dependencyLink/dependencyLinkDialog.template.html',
                        });
                    } else {
                        openAgreementConfirmToDelete(agreement);
                    }
                }).catch(error => console.error(
                'Error getting agreement dependency link information', error));
        };
        /**
         * Open delete agreement confirmation dialog
         * @param agreement
         */
        function openAgreementConfirmToDelete(agreement) {
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-medium',
                controller: 'AgreementDeleteDialogController',
                controllerAs: 'add',
                resolve: {
                    agreement: () => agreement,
                },
                templateUrl: 'admin-templates/site/agreements/deleteDialog/agreementDeleteDialog.template.html',
            }).then(() => {
                AgreementsService.deleteAgreement(agreement.id)
                .then(() => {
                    vm.loadAll();
                    vm.selectedAgreement = null;
                }, (error) => {
                    console.error('Error deleting agreement', error);
                });
            });
        };

        return vm;
    });
