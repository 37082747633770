(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            $stateProvider.state('participantProfile', {
                parent: 'participantManagement',
                url: '/profile',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'participantProfile.home.title'
                },
                params: { defaultRoleCategory: null },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/participant/profile/participantProfile.html',
                        controller: 'ParticipantProfileController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
                }
            })
                .state('participantProfileDetail', {
                    parent: 'participantProfile',
                    url: '/:id',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                        pageTitle: 'participantProfile.detail.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/participant/profile/detail/participantProfileDetail.html',
                            controller: 'ParticipantProfileDetailController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('user.management');
                            return $translate.refresh();
                        }]
                    }
                }).state('participantExperience', {
                    parent: 'participantProfile',
                    url: '/experience/:id',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                        pageTitle: 'participantProfile.experience.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/participant/profile/experience/participantExperience.html',
                            controller: 'ParticipantExperienceController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('user.management');
                            $translatePartialLoader.addPart('participantPMBDetails');
                            $translatePartialLoader.addPart('orderManagement');
                            $translatePartialLoader.addPart('formEntry');
                            return $translate.refresh();
                        }]
                    }
                });
        });
})();
