(function () {
    'use strict';

    /**
     * status type enum for program test running status
     */
    angular.module('acadiamasterApp').constant('WithdrawalReasons',
        {
            UNSET: 'UNSET',
            FRAUDULENT: 'FRAUDULENT',
            DUPLICATE: 'DUPLICATE',
            TEST: 'TEST'
        });
})();
