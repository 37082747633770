(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for PersonalizedContainerItemStateCondition model, this condition is to check the lock/unlock, visible/invisible state of
     * a personalized container item for the user in a program
     */
    app.factory('PersonalizedContainerItemStateConditionModel', function (QObjectModel, ProgramTestConstantsService, ContainerItemLoadUtil) {
        /***************************************************************
         * private functions
         **************************************************************/

        PersonalizedContainerItemStateConditionModel.inheritsFrom(QObjectModel);

        function PersonalizedContainerItemStateConditionModel(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.PERSONALIZED_CONTAINER_ITEM_STATE);

            this.containerItemId = null;
            this.unlocked = true;
            this.visible = true;
            this.programId = null;

            this._container = null;
            this._containerItem = null;
        }

        /**
         * convert the current UI model to dto format
         */
        PersonalizedContainerItemStateConditionModel.prototype.toDto = function () {
            const dto = QObjectModel.prototype.toDto.call(this);

            dto.containerId = this._container.id;
            dto.containerItemId = this.containerItemId;
            dto.unlocked = this.unlocked;
            dto.visible = this.visible;
            dto.programId = this.getProgramId();

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        PersonalizedContainerItemStateConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.containerItemId = dto.containerItemId;
            this.unlocked = dto.unlocked;
            this.visible = dto.visible;
            this.programId = this.getProgramId();

            this._containerItem = null;
            this._container = null;
            ContainerItemLoadUtil.loadContainerItem(this);
        };

        PersonalizedContainerItemStateConditionModel.prototype.setContainerItem = function (newContainerItem) {
            this._containerItem = newContainerItem;
            this.containerItemId = newContainerItem == null ? null : newContainerItem.id;
            this.formId = newContainerItem == null || newContainerItem.form == null ? null : newContainerItem.form.id;
            if (this._containerItem && this._container !== this._containerItem._parent) {
                this._container = this._containerItem._parent;
            }
        };

        PersonalizedContainerItemStateConditionModel.prototype.getContainerItem = function () {
            return this._containerItem;
        };

        PersonalizedContainerItemStateConditionModel.prototype.getProgramId = function () {
            const rootElement = this.getRoot();
            if (!rootElement) {
                return null;
            }
            this.programId = rootElement.programId;
            return this.programId;
        };

        PersonalizedContainerItemStateConditionModel.prototype.getContainer = function () {
            return this._container;
        };

        PersonalizedContainerItemStateConditionModel.prototype.setContainer = function (newContainer) {
            this._container = newContainer;
            this.containerId = this._container ? this._container.id : null;
            if (this._containerItem && this._container !== this._containerItem._parent) {
                // we are changing container, we should clear the container item if it's not for the same parent
                this._containerItem = null;
                this.containerItemId = null;
            }
        };


        PersonalizedContainerItemStateConditionModel.prototype.getDescriptionAsHtml = function () {
            const baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            const containerMsg = 'Personalized Container Item (' + (this.containerItemId == null ? 'no id' : this.containerItemId) + ' | ' +
                (this._containerItem == null ? 'no name' : this._container.name) + ')';

            const lockedMsg = 'is <span class="badge badge-info">' + (this.unlocked ? 'Unlocked' : 'Locked') + '</span>';
            const visibleMsg = ' and is <span class="badge badge-info">' + (this.visible ? 'Visible' : 'Invisible') + '</span>';

            return `${baseMsg} ${containerMsg} should be associated and ${lockedMsg} ${visibleMsg}`;
        };

        PersonalizedContainerItemStateConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (!this.containerItemId) {
                this.setErrorMessage('container item id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return PersonalizedContainerItemStateConditionModel;

    });
})();
