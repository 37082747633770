'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('dataType', {
                parent: 'dataTypeMaster',
                url: '/types',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.dataType.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataTypes.html',
                        controller: 'DataTypeController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataType');
                        $translatePartialLoader.addPart('valueType');
                        $translatePartialLoader.addPart('property');
                        $translatePartialLoader.addPart('dataTypePropertyMapping');
                        $translatePartialLoader.addPart('dataTypeMaster');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('dataType.detail', {
                parent: 'dataType',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.dataType.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataType-detail.html',
                        controller: 'DataTypeDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataType');
                        $translatePartialLoader.addPart('valueType');
                        $translatePartialLoader.addPart('property');
                        $translatePartialLoader.addPart('dataTypePropertyMapping');
                        $translatePartialLoader.addPart('dataTypePropertyValueConversion');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DataType', function($stateParams, DataType) {
                        return DataType.get({id : $stateParams.id});
                    }]
                }
            })
            .state('dataType.new', {
                parent: 'dataType',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataType-createEdit.html',
                        controller: 'DataTypeCreateEditController'
                    }

                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataType');
                        $translatePartialLoader.addPart('valueType');
                        $translatePartialLoader.addPart('property');
                        $translatePartialLoader.addPart('dataTypePropertyMapping');
                        $translatePartialLoader.addPart('dataTypePropertyValueConversion');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DataType', function() {
                        return {name: null, description: null, createdOn: null, updatedOn: null, isDeleted: null, id: null};
                    }],
                    isNew: [ function() {
                        return true;
                    }]
                }
            })
            .state('dataType.edit', {
                parent: 'dataType',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataType-createEdit.html',
                        controller: 'DataTypeCreateEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataType');
                        $translatePartialLoader.addPart('dataTypePropertyValueConversion');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DataType', function($stateParams, DataType) {
                        return DataType.get({id : $stateParams.id});
                    }],
                    isNew: [ function() {
                        return false;
                    }]
                }
            });
    });
