(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('hasAnyAuthority', function (Principal) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {

                    var authorities = attrs.hasAnyAuthority.replace(/\s+/g, '').split(',');
                    if (authorities.length > 0) {
                        defineVisibility(true);
                    }

                     function setVisible() {
                        element.removeClass('hidden');
                    }

                    function setHidden() {
                        element.addClass('hidden');
                    }

                    function defineVisibility(reset) {
                        var result;
                        if (reset) {
                            setVisible();
                        }

                        result = Principal.ifAnyAuthority(authorities);
                        if (result) {
                            setVisible();
                        } else {
                            setHidden();
                        }
                    }
                }
            };
        });
})();
