(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * Value type util service
     */
    app.factory('ValueTypeObjectUtil', function(FormConstants) {

        var ValueType = FormConstants.fieldEntryValueType;

        function isValueTypeNumber(valueTypeObject) {
            if (valueTypeObject == null) {
                return false;
            }

            return valueTypeObject.name == ValueType.NUMBER.name || valueTypeObject.name == ValueType.DATETIME.name;
        }

        function isValueTypeString(valueTypeObject) {
            if (valueTypeObject == null) {
                return false;
            }

            return valueTypeObject.name == ValueType.STRING.name;
        }

        function isValueTypeBoolean(valueTypeObject) {
            if (valueTypeObject == null) {
                return false;
            }

            return valueTypeObject.name == ValueType.BOOLEAN.name;
        }

        return {
            isValueTypeNumber : isValueTypeNumber,
            isValueTypeString : isValueTypeString,
            isValueTypeBoolean : isValueTypeBoolean,
        }
    })
})();