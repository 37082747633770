/**
 * Created by pankaja on 23/12/20
 */
// eslint-disable-next-line import/named
import { getOrderData, getProductData } from '../../../businessRule/configPanel/action/elementConfig.action.fire.vxp.event.directive';
import template from './apiDropdownOptions.html';
(function () {
    angular.module('acadiamasterApp')

    /**
     * directive for form field value for radio options
     */
        .directive('formFieldConfigFieldValueApiDropdownOptions',
            (FormUtilService, FormModelServiceFieldValue, LocaleConstantsService, ngDialog, $templateCache,
                ValueConfigOptionService, FormConstants, $http, localWeb) => {
                /**
                 * Init method
                 * @param $scope
                 */
                function init ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.oldFieldValues = angular.copy($scope.fieldValue?.values);
                    $scope.showReset = false;
                    $scope.isDefaultOptionSelected = false;
                    $scope.hasChangeModel = $scope.fieldValueChange != null && $scope.fieldValueChange != undefined;
                    $scope.constants = FormConstants;
                    $scope.shouldShowOrderCode = false;
                    if($scope.fieldValue.apiDropDownOption == $scope.constants.apiDropDownOption.ORDERS_FULFILLMENT){
                        $scope.shouldShowOrderCode = true;
                    }
                    getOrderData($scope, $http, localWeb);
                    getProductData($scope, $http, localWeb);
                    $scope.onChangeOfOption = function (SelectedValue) {
                        if (SelectedValue == $scope.constants.apiDropDownOption.ORDERS_FULFILLMENT) {
                            $scope.shouldShowOrderCode = true;
                        } else {
                            $scope.shouldShowOrderCode = false;
                            $scope.fieldValue.orderTypeCode = null;
                            $scope.fieldValue.productCode = null;
                            $scope.fieldValue.errorMessage = 'This should be changed';
                           if( $scope.fieldValue.localizationMap?.es && $scope.fieldValue.localizationMap.es?.errorMessage ){
                               $scope.fieldValue.localizationMap.es.errorMessage = null;
                           }
                        }
                    };
                    // Set reference to default selected option
                }

                return {
                    link : function ($scope) {
                        init($scope);
                    },
                    restrict : 'E',
                    scope    : {
                        fieldElement     : '=',
                        fieldValue       : '=',
                        fieldValueChange : '=',
                    },
                    template,
                };
            });
}());

