/**
 * Created by Lulu Wang on 05/20/2019
 */

angular.module('acadiamasterApp')
    .directive('viewExpressionConditionDetails', (ConditionTreeCreationUtil, ExpressionConditionService, AlertService, ExpressionConditionConstantsService) => ({
        link ($scope) {
            const vm = $scope;

            /**
         * isPanelOpen contains flags for the open or closed status of panels
         */
            vm.isPanelOpen = {
                details : true,
                tree    : true,
                usage   : true,
            };

            const IDS = {
                ID            : 'id',
                UPDATED_BY_ID : 'updatedById',
                UPDATED_ON    : 'updatedOn',
                USED_BY       : 'usedBy',
                USED_BY_NAME  : 'usedByName',
                USED_BY_TYPE  : 'usedByType',
            };

            const FIELD_TYPES = {
                DATE      : 'date',
                ID        : 'id',
                SHOW_EDIT : 'showEdit',

            };

            const USAGE_TYPES = ExpressionConditionConstantsService.usageTypes;

            vm.FIELD_TYPES = FIELD_TYPES;
            vm.USAGE_TYPES = USAGE_TYPES;

            /**
         * fields for usages
         */
            vm.usagesFields = [
                { fieldType : FIELD_TYPES.ID, id : IDS.ID, sortable : true },
                { id : IDS.USED_BY_TYPE, sortable : true },
                { fieldType : FIELD_TYPES.SHOW_EDIT, id : IDS.USED_BY, sortable : true },
                { id : IDS.UPDATED_BY_ID },
                { fieldType : FIELD_TYPES.DATE, id : IDS.UPDATED_ON },
            ];

            /*
         * Transforms usage data into the form (name)id
         * @param {array} usages
         */
            function formatUsages (usages) {
                return usages.map(usage => ({
                    ...usage,
                    usedBy : `${usage.usedByName} (${usage.usedById})`,
                }));
            }

            vm.usageData = {
                ascending : true,
                orderBy   : 'id',
                usages    : [],
            };

            vm.sortBy = id => {
                if (vm.usageData.orderBy === id) {
                    vm.usageData.ascending = !vm.usageData.ascending;
                } else {
                    vm.usageData.ascending = true;
                    vm.usageData.orderBy = id;
                }
            };

            function init () {
                if (vm.expression == null && vm.expressionId == null) {
                    AlertService.error('Expression id or expression object is expected');
                    return;
                }

                if (vm.expression) {
                    vm.details = angular.copy(vm.expression);
                    vm.conditionTree = ConditionTreeCreationUtil.createByDto(vm.expression.conditionTree, vm);
                    vm.usageData.usages = formatUsages(vm.expression.usages);
                } else if (vm.expressionId) {
                    ExpressionConditionService.getConditionById(vm.expressionId)
                        .then(response => {
                            vm.details = response.data;
                            vm.conditionTree = ConditionTreeCreationUtil.createByDto(response.data.conditionTree, vm);
                            vm.usageData.usages = formatUsages(response.data.usages);
                        }, error => {
                            console.error('Error loading expression conditions', error);
                        });
                }
            }
            init();
        },
        restrict : 'E',
        scope    : {
            expression   : '=',
            expressionId : '=',
        },
        templateUrl : 'admin-templates/site/expressionCondition/detailPage/viewExpressionConditionDetails.html' }));
