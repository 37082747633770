(function () {
    'use strict';

    /***************************************************************
     * Precondition for form exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionFormExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, FormLoadUtil) {

            PreConditionFormExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for form exist with status
             * @constructor
             */
            function PreConditionFormExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.FORM_EXIST);

                this.formId = null;

                this._form = null;
            }

            PreConditionFormExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.formId = dto.formId;

                FormLoadUtil.loadForm(this);
            };

            PreConditionFormExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.formId = this.formId;

                return dto;
            };


            PreConditionFormExistModel.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form==null ? null : form.id;
            };

            PreConditionFormExistModel.prototype.getForm = function () {
                return this._form;
            };

            PreConditionFormExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var formMsg = 'Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                    (this._form == null ? 'no name' :  this._form.name) + ')';

                return typeMsg + formMsg +  ' should exist ';
            };

            PreConditionFormExistModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.formId == null) {
                    this.setErrorMessage('form id is required');
                }
            };

            return PreConditionFormExistModel;
        });
})();
