(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('BannerSpecificDataModel',
        function (RuleConstantsService) {

            function BannerSpecificDataModel() {
                this.title = 'Banner Title';
                this.message = 'Banner message';
                this.buttonText = 'Submit';
                this.localizationMap = {};

                this.imageFileName = null;
                this.imageFileUrl = null;
                this.imageFileSize = null;

                return this;
            }

            BannerSpecificDataModel.prototype.fromDto = function (dto) {
                this.title = dto.title;
                this.message = dto.message;
                this.buttonText = dto.buttonText;
                this.localizationMap = dto.localizationMap != null ? dto.localizationMap : {};

                this.imageFileName = dto.imageFileName;
                this.imageFileUrl = dto.imageFileUrl;
                this.imageFileSize = dto.imageFileSize;
            };

            BannerSpecificDataModel.prototype.toDto = function (files) {
                var dto = {};
                dto.title = this.title === RuleConstantsService.UNAVAILABLE ? null : this.title;
                dto.message = this.message === RuleConstantsService.UNAVAILABLE ? null : this.message;
                dto.buttonText = this.buttonText === RuleConstantsService.UNAVAILABLE ? null : this.buttonText;
                dto.imageFileSize = this.imageFileSize;
                dto.imageFileName = this.imageFileName;
                dto.imageFileUrl = this.imageFileUrl;
                dto.localizationMap = this.localizationMap;

                if (this.imageFile != null) {
                    dto.imageFileName = this.imageFile.name;
                    dto.imageFileSize = this.imageFile.size;
                    files.push(this.imageFile);
                }
                return dto;
            };

            return BannerSpecificDataModel;
        }
    );
})();