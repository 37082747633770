
'use strict';

(function () {
    /**
     * the model for ConditionWithMessage from api server
     */
    angular.module('acadiamasterApp').factory(
        'ConditionWithMessageModel',
        (   LocalizedStringModel,
            ModelServiceConditionExpression,
            ModelServiceBase,
        ) => {
            ConditionWithMessageModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function ConditionWithMessageModel(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, true, parent);

                this.name = null;
                this.conditionString = null;
                this.conditionNode = null;
                this.localizedMessage = new LocalizedStringModel();
            }

            ConditionWithMessageModel.prototype.toDto = function () {
                const dto = {};
                dto.name = this.name && this.name.length >0 ? this.name : null;
                dto.conditionString = this.conditionString;
                dto.conditionNode = this.conditionNode ? this.conditionNode.toDto() : null;
                dto.localizedMessage = this.localizedMessage ? this.localizedMessage.toDto() : null;
                return dto;
            };

            ConditionWithMessageModel.prototype.fromDto = function (dto) {
                if (dto == null) {
                    return;
                }

                this.name = dto.name;
                this.conditionString = dto.conditionString;
                this.conditionNode = new ModelServiceConditionExpression.ConditionExpression(this);
                this.conditionNode.fromDto(dto.conditionNode);
                // null -> {"en":undefined, "es":undefined}
                this.localizedMessage.fromDto(dto.localizedMessage);
            };


            return ConditionWithMessageModel;
        },
    );
}());
