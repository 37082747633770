(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for BioSpecimenStatusConditionModel model
     */
    app.factory('BioSpecimenStatusConditionModel', function (QObjectModel,
        ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        BioSpecimenStatusConditionModel.inheritsFrom(QObjectModel);

        function BioSpecimenStatusConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.BIO_SPECIMEN_STATUS);

            //initialized the variables
            this.statusTypeList = [];
            this.comparator = ProgramTestConstantsService.operators.EQ;
        }

        /**
         * convert the current UI model to dto format
         */
        BioSpecimenStatusConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            if (this.statusTypeList == null) {
                dto.statusTypeList = null;
            }
            else {
                dto.statusTypeList = [];
                _.forEach(this.statusTypeList, function (s) {
                    if (s && s.name) {
                        dto.statusTypeList.push(s.name);
                    }
                });
            }

            dto.comparator = this.comparator == null ? null : this.comparator.name;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        BioSpecimenStatusConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            if (dto.statusTypeList == null) {
                this.statusTypeList = null;
            }
            else {
                this.statusTypeList = dto.statusTypeList.map(function (stateName) {
                    return { name: stateName, text: stateName };
                });
            }

            this.setComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator));
        };

        BioSpecimenStatusConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        BioSpecimenStatusConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.statusTypeList = null;
            }
            else if (this.statusTypeList == null) {
                this.statusTypeList = [];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN) {
                if (this.statusTypeList.length > 1) {
                    // just use the first value
                    this.statusTypeList = [this.statusTypeList[0]];
                }
            }
        };

        BioSpecimenStatusConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            var stateMsg = 'Bio Specimen Status';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : getStatusTypeListAsString(this.statusTypeList);

            return baseMsg + ' ' + stateMsg + comparatorMsg + valueMsg;
        };

        BioSpecimenStatusConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.comparator == null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.statusTypeList == null || this.statusTypeList.length == 0)) {
                this.setErrorMessage('state value is required except when comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        function getStatusTypeListAsString(statusTypeList) {
            if (statusTypeList == null) {
                return 'null';
            }
            return _.map(statusTypeList, function (state) {
                return state.name;
            }).join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return BioSpecimenStatusConditionModel;

    });
})();
