/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')



    /**
     * directive for display a tree node view of one state rule
     */
        .directive('treeNodeFormStateRule', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/formStateRule.html',
                scope: {
                    element: '=',
                    currentTreeName : '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                }
            }
        });
})();

