(function () {
    'use strict';

    /**
     * program rule association directive
     */
    angular.module('acadiamasterApp').directive('exportWizardStepProgramBasics', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepProgramBasics.html',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '='
            },
            link: function ($scope) {
                // add more later
            }
        };

        /******************************************************************************
         * private functions
         ******************************************************************************/
    });
})();
