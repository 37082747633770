/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for view take a picture
     */
        .directive('formFieldConfigFieldValueViewTakePicture', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/viewTakePicture.html',
                scope: {
                    fieldValue: '=',
                    selector: '=',
                    parentForm: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
            };

        });


})();

