(function () {
    angular.module('acadiamasterApp')

        .directive('textInputLocalizationNew', (LocaleConstantsService, vbrCommonUtil, VbrStringUtil, LocalizationService, $document) => {
            function initAvailableLocaleList () {
                const locales = [];
                for (const prop in LocaleConstantsService.locale) {
                    if (LocaleConstantsService.locale.hasOwnProperty(prop)) {
                        // your code
                        let object = LocaleConstantsService.locale[prop];
                        locales.push(object);
                    }
                }
                return locales;
            }

            function getMapKeys(map) {
                const keys = [];
                for (k in map) {
                    if (map.hasOwnProperty(k) && k !== 'en' && map[k] !== undefined) {
                        keys.push(k);
                    }
                }
                return keys;
            }

            function updateAllAvailableLocaleList ($scope) {
                const mapKeys = getMapKeys($scope.map);
                console.log('map keys = ', mapKeys);
                $scope.availableLocale = initAvailableLocaleList();
                _.forEach(mapKeys, k => {
                    const indexFound = _.findIndex($scope.availableLocale, { name : k });
                    if (indexFound !== -1) {
                        console.log('removing locale', k);
                        $scope.availableLocale.splice(indexFound, 1);
                    }
                });
                console.log('available locales size = ' + $scope.availableLocale.length, $scope.availableLocale, $scope.map);
            }

            /**
             *
             * @param $scope
             */
            function init ($scope) {
                // Create list of locale object
                $scope.data = { isPopupOpen : false };
                $scope.availableLocale = initAvailableLocaleList();

                if ($scope.map != null) {
                    initUIList($scope);
                }
            }

            /**
             *
             * @param $scope
             */
            function initUIList ($scope) {
                $scope.list = [];
                for (let key in $scope.map) {
                    if ($scope.map.hasOwnProperty(key) && key !== 'en') {
                        let text = $scope.map[key];
                        let locale = vbrCommonUtil.getObjectByName(LocaleConstantsService.locale, key);
                        if (!VbrStringUtil.isStringEmpty(text) && $scope.inputType !== $scope.lcs.inputType.NUMBER) {
                            // Add in UI list
                            LocalizationService.addLocaleObject($scope.list, locale, text, null);
                            // Remove added locale from available list
                            updateAvailableLocaleList($scope, locale, null, false);
                        }

                        // if we don't have text for the locale and the available locale does not have this,
                        // we need to add into the list
                        if (VbrStringUtil.isStringEmpty(text) && $scope.availableLocale != null
                            && $scope.availableLocale[locale] == null) {
                            updateAvailableLocaleList($scope, locale, null, true);
                        }
                    }
                }
            }

            function addWatchOnList ($scope) {
                $scope.$watch('list', (newValue, oldValue) => {
                    if ($scope.map == null) {
                        $scope.map = {};
                    }

                    // Process old list
                    _.forEach(oldValue, oldObj => {
                        if (oldObj != null && oldObj.selectedLocale != null) {
                            // Set old locale value to null
                            console.log('remove old value:', oldObj.selectedLocale.name);
                            LocalizationService.addValueToMap($scope.map, oldObj.selectedLocale.name, null);
                            addValueToMap($scope.map, oldObj.selectedLocale.name, null);
                        }
                    });

                    // Process new value
                    _.forEach(newValue, newObj => {
                        if (newObj != null && newObj.selectedLocale != null
                            && newObj.localizedText != null && ($scope.inputType !== $scope.lcs.inputType.NUMBER && newObj.localizedText.length > 0)) {
                            // Add new value to map
                            console.log('adding new value:', newObj.selectedLocale.name, newObj.localizedText);
                            addValueToMap($scope.map, newObj.selectedLocale.name, newObj.localizedText);
                        }
                    });
                }, true);
            }
            function addValueToMap (map, objKey, value) {
                // Set field value
                if (value == null) {
                    delete map[objKey];
                } else {
                    map[objKey] = value;
                }
            }
            function addWatchOnMap ($scope) {
                $scope.$watch('map', (newValue, oldValue) => {
                    initUIList($scope);
                });
            }

            function updateAvailableLocaleList ($scope, locale, localeIndex, isAddLocale) {
                if ($scope.availableLocale == null || locale == null) {
                    return;
                }
                const indexFound = _.findIndex($scope.availableLocale, { name : locale.name });
                if (isAddLocale) {
                    if (indexFound === -1) {
                        // Add locale to available list
                        $scope.availableLocale.push(locale);
                    }
                } else {
                    // Remove locale from available list
                    let index = localeIndex;
                    if (localeIndex === null) {
                        index = indexFound;
                    }
                    if (index !== -1) {
                        $scope.availableLocale.splice(index, 1);
                    }
                }
            }

            return {
                link : function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    init($scope);
                    // Watch localizedText and modify value in map
                    addWatchOnList($scope);
                    addWatchOnMap($scope);
                    $scope.addLocaleObject = function (locale, localeIndex) {
                        console.log('calling add locale ', locale, localeIndex);
                        LocalizationService.addLocaleObject($scope.list, locale, null, null);

                        // Remove added locale from available list
                        updateAvailableLocaleList($scope, locale, localeIndex, false);
                    };
                    $scope.removeLocaleObject = function (index, locale) {
                        LocalizationService.removeLocaleObject($scope.list, index);

                        // Add removed locale in available list
                        updateAvailableLocaleList($scope, locale, null, true);
                    };
                    $scope.getSupportedLangCount = function () {
                        return LocalizationService.getSupportedLangCount();
                    };

                    $scope.localeChanged = function (localeObj) {
                        _.forEach($scope.list, obj => {
                            if (obj.selectedLocale == localeObj.selectedLocale) {
                                obj.localizedText = localeObj.localizedText;
                                return;
                            }
                        });
                    };

                    $scope.closePopup = function () {
                        $scope.data.isPopupOpen = false;
                    };

                    $scope.onPopoverOpened = function ($event) {
                        $document[0].body.click();
                        $event.stopPropagation();
                        updateAllAvailableLocaleList($scope);
                        $scope.data.isPopupOpen = true;
                    };
                },
                restrict : 'E',
                scope    : {
                    disabled  : '=',
                    inputType : '=',
                    map       : '=',
                    maxLength : '=',
                    minLength : '=',
                },
                templateUrl : 'admin-templates/site/common/textInputLocalization.html',
            };
        });
}());
