(function () {
    'use strict';
    angular.module('acadiamasterApp')
        .controller('ProgramSelectionPopupController', function ($scope, $state, ngDialog, ProgramService) {
            ProgramService.query({}, function (result) {
                $scope.programs = result;
            });
            $scope.programSelected = function (programId) {
                $scope.confirm(programId);
            };
        });

})();

