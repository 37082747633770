(function () {
    'use strict';
    angular.module('acadiamasterApp').factory('CommonUseCasesService',
        function (vbrCommonUtil) {
            var modules = {
                'RULES': 'Rules',
                'STATE_RULES': 'State Rules'
            }
            var functions = [
                {
                    description: 'If the user says they are 50 years old in a number input field #5655, send them a specific content item when they submit the form.',
                    examples: [
                        {
                            expression: 'eq(toDouble(fieldValueLatest(null,null,null,null,5655)),50)',
                            notes: '50 is the value with which field value should be compared.<br>' +
                                '5655 is the field Id. Find this from the form.<br>' +
                                'For comparisons like greater than, less than etc. just replace eq<br>' + '' +
                                'with functions like gt,gte,lt,lte without changing anything else.'
                        }
                    ],
                    useCaseName: 'Value of the field equals a given number',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'If the user is presented with a radio selector #5655 asking them to choose their gender, and they answer "male", show them a specific piece of content when they submit the form.',
                    examples: [
                        {
                            expression: 'eq(toString(fieldValueLatest(null,null,null,null,5655)), "male")',
                            notes: '"male" is the string with which field value should be compared.<br>5655 is the field Id. Find this from the form.'
                        }
                    ],
                    useCaseName: 'Value of the field equals a given string',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'If the user is presented with a Boolean selector and selects it, show another form.',
                    exampleNotes: 'These expressions are equivalent to each other. Either can be used.',
                    examples: [
                        {
                            expression: 'toBoolean(fieldValueLatest(null,null,null,null,5655))',
                            notes: '5655 is the field Id. Find this from the form.'
                        },
                        {
                            expression: 'eq(toBoolean(fieldValueLatest(null,null,null,null,5821)), true)',
                            notes: '5821 is the field Id. Find this from the form.'
                        }
                    ],
                    useCaseName: 'Value of the boolean field is true',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'If the user is presented with a Boolean selector and does not select it, show another form.',
                    exampleNotes: 'These expressions are equivalent to each other. Either can be used.',
                    examples: [
                        {
                            expression: 'not(toBoolean(fieldValueLatest(null,null,null,null,5655)))',
                            notes: '5655 is the field Id. Find this from the form.',
                        },
                        {
                            expression: 'eq(toBoolean(fieldValueLatest(null,null,null,null,5821)), false)',
                            notes: '5821 is the field Id. Find this from the form.',
                        }
                    ],
                    useCaseName: 'Value of the boolean field is false',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'Update the user\'s email address in the profile from a form field.',
                    examples: [
                        {
                            expression: 'toString(fieldValueLatest(null,null,null,null,5765))',
                            notes: '5765 is the field Id. Find this from the form.',
                        }
                    ],
                    useCaseName: 'Update profile value',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'If the user is on the 5th day of the program, show them a certain form.',
                    examples: [
                        {
                            expression: 'eq(programDay(), 5)',
                            notes: '5 is the number with which day of program is compared.<br>' +
                                'For comparisons like greater than, less than etc. just replace eq<br>' +
                                'with functions like gt,gte,lt,lte without changing anything else.',
                        }
                    ],
                    useCaseName: 'User is on nth day of program',
                    usedBy: [modules.RULES],
                },
                {
                    description: '',
                    examples: [
                        {
                            expression: 'gte(length(filter(toDoubleList(fieldValueTimePeriod(null,null,null,null,5655,time(null,"NOW",5,"DAY"))), v-> eq(v, 50))),3)',
                            notes: '3 suggest that you are looking for 3 entries matching criteria over the time<br>' +
                                'eq(v, 50) suggests that criteria is log entry value equal to 50<br>' +
                                'For comparisons like greater than, less than etc. just replace eq<br>' +
                                'with functions like gt,gte,lt,lte without changing anything else.<br><br>' +
                                '5655 is the field id<br>' +
                                'time function with parameters null, NOW, 5, DAY suggests that check for<br>' +
                                '5 days of data with 5 day filter\'s end date being current time',
                        }
                    ],
                    useCaseName: 'Value equals X, at least Y times in last Z days',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'In a program, a child must brush their teeth 6 out of 7 days of the program days to accomplish their goal. The child is asked each day if they brushed their teeth via a radio selector/single selector.',
                    examples: [
                        {
                            expression: 'gte(length(filter(toStringList(fieldValueTimePeriod(null,null,null,null,543,time(null,"NOW", 7, "DAY"))),v->eq(v,"yes"))),6)',
                            notes: 'The rule evaluates how many times the answer "yes" (string)<br>was chosen over the past 7 days in a given field, 543,<br>and if that number is greater tha or equal to 6.',
                        }
                    ],
                    useCaseName: 'If 6 or more log entries of "yes" in the past 7 days',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'In a program we need to check for adherence to the program rules by checking the response on 7 different daily forms.',
                    examples: [
                        {
                            expression: 'and(and(and(eq(toString(fieldValueLatest(null,null,null,null,1122)), "yes"),eq(toString(fieldValueLatest(null,null,null,null,1158)), "yes")),and(eq(toString(fieldValueLatest(null,null,null,null,1204)), "yes"),eq(toString(fieldValueLatest(null,null,null,null,1318)), "yes")),),and(and(eq(toString(fieldValueLatest(null,null,null,null,1280)), "yes"),eq(toString(fieldValueLatest(null,null,null,null,1318)), "yes")),eq(toString(fieldValueLatest(null,null,null,null,1356)), "yes")))',
                            notes: 'and() -- need one for every two expressions. and between every two expressions<br>' +
                                '"yes" is the used to test against for adherence to the program<br>' +
                                '1122, 1158, 1204, 1242, 1280, 1318, 1356 are the expression IDs, find these in each of the components',
                        }
                    ],
                    useCaseName: 'Validate response is "yes" across 7 days in 7 different forms',
                    usedBy: [modules.RULES],
                },
                {
                    description: 'If the user says they are 50 years old in a number input field #5655, change the configuration of a hidden section to visible.',
                    examples: [
                        {
                            expression: 'eq(toDouble(fieldEntryValueInCurrentView(5655)),50)',
                            notes: '50 is the value with which field value should be compared <br>' +
                                '5655 is the field Id. Find this from the form.<br>' +
                                'For comparisons like greater than, less than etc. just replace eq<br>' +
                                'with functions like gt,gte,lt,lte without changing anything else.',
                        }
                    ],
                    useCaseName: 'Value of the field equals a given number',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'If the user is presented with a radio selector #5655 asking them to choose their gender, and they answer "male", change the configuration of a hidden section to visible.',
                    examples: [
                        {
                            expression: 'eq(toString(fieldEntryValueInCurrentView(5655)), "male")',
                            notes: '"male" is the string with which field value should be compared.<br>5655 is the field Id. Find this from the form.',
                        }
                    ],
                    useCaseName: 'Value of the field equals a given string',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'If the user resides in Virginia, change the configuration of a hidden section to visible.',
                    examples: [
                        {
                            expression: 'eq(toString(profileValue(5765)),"VA")',
                            notes: '"VA" is the string with which profile value should be compared.<br>' +
                                '5765 is the field Id. Find this from the form.',
                        }
                    ],
                    useCaseName: 'Check if a profile property has a specific value (e.g. state = VA)',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'User selects that they use sedatives, tranquilizers, and painkillers in the form from a list of 9 options',
                    examples: [
                        {
                            expression: 'contain(toStringList(fieldEntryValueInCurrentView(3648)), "sedatives")<p>' +
                                'contain(toStringList(fieldEntryValueInCurrentView(3648)), "tranquilizers")<p>' +
                                'contain(toStringList(fieldEntryValueInCurrentView(3648)), "painkillers")',
                            notes: '3648 is the expression ID. Find this in the form<br>' +
                                'sedatives/tranquilizers/painkillers are the strings with which the field value should be compared<p>' +
                                '<b>TIP</b>: For state rules on multi-select, you will need separate state rules for each of the total options, or 9 in this case.',
                        }
                    ],
                    useCaseName: 'Multi-select options from the same form',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'The user must get the correct answer for 3 out of 4 multiple choice questions.',
                    examples: [
                        {
                            expression: 'gte(length(filter([toDouble(fieldEntryValueInCurrentView(6701)),toDouble(fieldEntryValueInCurrentView(6698)),toDouble(fieldEntryValueInCurrentView(6707)),toDouble(fieldEntryValueInCurrentView(6835)),],x -> eq(x,1))),3)',
                            notes: 'In this example there are 4 multiple select questions with the correct answer always having the numeric value 1.<br>' +
                                'We start by <b>filter</b>ing the values for the fields 6701, 6698, 6707 and 6835 by running each of them in turn through the equation eq(x,1).<br>' +
                                'x is replaced by the actual value for the field, and if the result of the expression is true, the value is added to a list.<br>' +
                                'We then get the <b>length</b> of the list and check the length is greater than or equal to 3, thereby checking the user got at least 3 of 4 questions correct.',
                        }
                    ],
                    useCaseName: 'Scoring a quiz',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'If the user is presented with a list of states and they choose either AZ, CA or PA, change the configuration of a hidden section to visible.',
                    examples: [
                        {
                            expression: 'or(or(eq(toString(fieldEntryValueInCurrentView(9503)), "AZ"),eq(toString(fieldEntryValueInCurrentView(9503)), "CA")),eq(toString(fieldEntryValueInCurrentView(9503)), "PA"))',
                            notes: 'AZ/CA/PA is the field value which is used in the comparison.<br>9503 is the field Id. Find this from the form.',
                        }
                    ],
                    useCaseName: 'Nested OR statements',
                    usedBy: [modules.STATE_RULES],
                },
                {
                    description: 'If a user is presented with a multi select field and chooses entries A and B then send an email',
                    examples: [
                        {
                            expression: 'and(expr1, expr2)',
                            notes: 'expr1 and expr2 can be any expression to retrieve a value from a form field.',
                            unformatted: true,
                        }
                    ],
                    useCaseName: 'If expr1 AND expr2',
                    usedBy: [modules.STATE_RULES, modules.RULES],
                },
                {
                    description: 'If a user is presented with a multi select field and chooses entries A or B then send an email',
                    examples: [
                        {
                            expression: 'or(expr1, expr2)',
                            notes: 'expr1 and expr2 can be any expression to retrieve a value from a form field.',
                            unformatted: true,
                        }
                    ],
                    useCaseName: 'If expr1 OR expr2',
                    usedBy: [modules.STATE_RULES, modules.RULES],
                },
                {
                    description: 'If a user is presented with a multi select field and chooses either entries A and B or entry C then display a specific form',
                    examples: [
                        {
                            expression: 'or(and(expr1, expr2), expr3)',
                            notes: 'expr1, expr2 and expr3 can be any expression to retrieve a value from a form field.',
                            unformatted: true,
                        }
                    ],
                    useCaseName: 'If (expr1 AND expr2) OR expr3',
                    usedBy: [modules.STATE_RULES, modules.RULES],
                },
                {
                    description: 'Check to see if a user has an active banner to trigger the Site Pairing form. If they do not, execute an action to add the Site Pairing banner to their dashboard.',
                    examples: [
                        {
                            expression: 'not(hasBannerByFormId(532, null, ["DASHBOARD"]))',
                        }
                    ],
                    useCaseName: 'To check if participant has already received a banner for a specific form, in order to not issue multiple banners with the same form to a user.',
                    usedBy: [modules.RULES],
                }
            ];

            return {
                functions: functions,
                getObjectByName: vbrCommonUtil.getObjectByName,
                modules: modules,
            }
        }
    )
})();
