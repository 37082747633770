/**
 * Created by moustafabaiou on 2/3/17.
 */
(function () {
    'use strict';

    angular
        .module('acadiamasterApp')
        .factory('formCache',
            function () {

                var cachedForm= {};

                return {
                    saveFormToCache: saveFormToCache,
                    loadCachedForm: loadCachedForm
                };

                ////////////////

                function saveFormToCache(form){
                    angular.copy(form, cachedForm);
                    console.info('caching to formCache -->', cachedForm);
                }

                function loadCachedForm(scope){
                    console.info('loading from formCache -->', cachedForm);
                    angular.copy(cachedForm, scope.form);
                    cachedForm = {};
                    console.info('clearing formCache -->', cachedForm);
                }

            });

})();

