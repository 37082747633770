/**
 * Created by jason.cao on 4/27/17
 */
(function () {
    'use strict';

    /**
     * directive for a tool page with various admin tools
     */
    angular.module('acadiamasterApp')
        .directive('vbrMiscTools', function (TILE_CONSTANTS) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/tools/tools.html',
                controller: function ($scope) {
                    $scope.data = {
                        tileCollectionId: TILE_CONSTANTS.ID.TOOLS
                    };
                }
            };
        });
})();
