'use strict';

angular.module('acadiamasterApp')
    .controller('UnitCategoryController', function ($scope, UnitCategory, UnitGroupByCategory, ParseLinks, $state, ngDialog) {
        $scope.unitCategorys = [];
        $scope.page = 0;
        $scope.loadAll = function () {
            UnitCategory.query({page: $scope.page, size: 20}, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.unitCategorys = result;
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            UnitCategory.get({
                id: id
            }, function (result) {
                $scope.unitCategory = result;
                ngDialog.openConfirm({
                    template: 'admin-templates/site/unitSystem/unitCategory/unitCategoryDelete.html',
                    controller: 'UnitCategoryDeleteController',
                    data: $scope.unitCategory
                }).then(function () {
                    $scope.refresh();
                });
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.unitCategory = {name: null, id: null};
        };
    });
