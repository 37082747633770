(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('accessControl', {
                    parent: 'site',
                    url: '/accessControl',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/admin/accessControl/accessControl.html',
                            controller: 'AccessControlController'
                        }
                    },
                    resolve : {
                        translatePartialLoader : [ '$translate', '$translatePartialLoader',
                            function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            } ]
                    }
                });
        });
})();
