(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for form model
     */
        .factory('TemplateMessageService', function (vbrCommonUtil, ModelServiceBase) {

            /***************************************************************
             * dto for template message model
             ***************************************************************/
            TemplateMessage.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            /**
             * @constructor
             */
            function TemplateMessage(templateMessageType) {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, null);

                this.type = templateMessageType;

                // this message config might get complex in the future, it's just an empty map for now
                this.messageConfig = {};

                // this flag is not stored on server, just used for UI control, toDto will remove it
                this.selected = false;
            }

            /**
             * convert the current UI model to dto format
             */
            TemplateMessage.prototype.toDto = function () {
                if (this.selected==false) {
                    return null;
                }
                var dto = {};

                dto.type = this.type;
                dto.messageConfig = this.messageConfig;

                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            TemplateMessage.prototype.fromDto = function (dto) {
                // todo: maybe this function isn't needed, from dto should be from an array of messages so we can build the
                // list of message models include the unselected ones, individually, there is no way to convert null into
                // a meaningful javascript model object
                this.type = dto.type;
                this.messageConfig = dto.messageConfig;
                this.selected = true;
            };

            /***************************************
             * private function call
             ***************************************/

            /**
             * find the model that matches the dto
             * @param currentModels current list of models
             * @param dto input dto
             * @return first model that matches the dto by name, null if we can't find such a model in the current models list
             */
            function findModel(currentModels, dto) {
                if (currentModels==null || dto==null) {
                    return null;
                }
                return _.find(currentModels, function(model) {
                    return model.type.name===dto.type;
                });
            }

            /***************************************
             * utility functions, those are functions that gets exposed at service level
             ***************************************/

            /**
             * load message dtos into current model list
             * @param currentModels current list of template message models
             * @param dtos list of input dtos from server
             */
            function loadMessages(currentModels, dtos) {
                if (dtos==null || currentModels==null) {
                    return;
                }

                _.forEach(dtos, function(dto) {
                    var model = findModel(currentModels, dto);
                    if (model==null) {
                        console.error("unable to load dto : ", dto, " can not find existing model for it, initialization error");
                    }
                    else {
                        model.selected = true;
                    }
                });
            }

            /***************************************
             * service return call
             ***************************************/

            return {
                TemplateMessage : TemplateMessage,

                loadMessages : loadMessages
            };
        });

})();
