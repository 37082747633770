import {DefaultLabelConfig} from './labelConfig.constants';

(function () {
    'use strict';

    angular.module('acadiamasterApp').service('LabelConfigModel', function (LocaleJsonService) {

        function LabelConfigModel(agreement) {
            this.provideConsentButton = '';
            this.resumeConsentButton = '';
            this.localizationMapProvideConsent = {};
            this.localizationMapResumeConsent = {};
            if (!agreement) {
                this.fromDto(DefaultLabelConfig);
            }
        }

        LabelConfigModel.prototype.toDto = function () {
            let dto = {};
            dto.provideConsentButton = LocaleJsonService.localizationMapToJson(this.provideConsentButton, this.localizationMapProvideConsent,
                'provideConsentButton');
            dto.resumeConsentButton = LocaleJsonService.localizationMapToJson(this.resumeConsentButton, this.localizationMapResumeConsent,
                'resumeConsentButton');
            return dto;
        };

        LabelConfigModel.prototype.fromDto = function (dto) {
            LocaleJsonService.jsonToLocalizationMap(dto.provideConsentButton, this, 'provideConsentButton',
                'localizationMapProvideConsent');
            LocaleJsonService.jsonToLocalizationMap(dto.resumeConsentButton, this, 'resumeConsentButton',
                'localizationMapResumeConsent');
        };

        /***************************************
         * service return call
         ***************************************/

        return LabelConfigModel;

    });


})();
