/**
 * Created by pchaudhary
 */
(function () {
    angular.module('acadiamasterApp')
    /**
     * directive for default value of signature type
     */
        .directive('formFieldConfigFieldValueDecimalOptions', function (FormConstants) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/decimalPointOptions.html',
                scope       : {
                    fieldValue       : '=',
                    fieldValueChange : '=',
                    fieldElement     : '=',
                },
                link : function ($scope) {
                    $scope.cs = FormConstants;
                    $scope.decimalPointOptions = FormConstants.decimalPointOptions;
                    $scope.shouldShowOption = function(){
                        const result = $scope.fieldElement.validationRules.filter(obj=>{ return obj.type.name == 'WHOLE_NUMBERS_ONLY';});
                        return result.length == 0;
                    };
                },
            };

        });

})();

