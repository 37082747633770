(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('ProgramTestExecutionAuditService', function ($http, localWeb) {
        var baseUrl = 'api/programTest/audit';
        return {

            /**
             * getting the latest test audit by program test id
             * @param programTestId - program test id
             * @param summaryOnly - flag to indicate if we want to full data or just the summary
             * @returns {HttpPromise} - promise of the http call
             */
            getLatestTestExecutionAudit: function (programTestId, summaryOnly) {
                if (summaryOnly==null) {
                    summaryOnly = false;
                }
                return $http.get(localWeb.createUrl(baseUrl + '/findOne?programTestId=' + programTestId + '&summaryOnly=' + summaryOnly));
            },

            /**
             * getting the test audit by program test audit id
             * @param programTestExecutionId - program test audit id
             * @param summaryOnly - flag to indicate if we want to full data or just the summary
             * @returns {HttpPromise} - promise of the http call
             */
            getTestExecutionAuditById: function (programTestExecutionId, summaryOnly) {
                if (summaryOnly==null) {
                    summaryOnly = false;
                }
                return $http.get(localWeb.createUrl(baseUrl + "/findOne?programTestExecutionAuditId=" + programTestExecutionId +
                    '&summaryOnly=' + summaryOnly));
            },
        };
    })
})();
