/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value of type image upload
     */
        .directive('formFieldConfigFieldValueImageUpload', function (FormUtilService, FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/imageUpload.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.imageTypeChanged = function (imageType) {
                        imageTypeChanged($scope, imageType);
                    };

                    $scope.maintainAspectRatioChanged = function (maintainAspectRatio) {
                        maintainAspectRatioChanged($scope, maintainAspectRatio);
                    };

                    $scope.aspectRatioDisplayOnly = function () {
                        if ($scope.fieldValueChange != null && $scope.fieldValueChange.imageType != null) {
                            return $scope.fieldValueChange.imageType.name == FormConstants.imageTypes.CIRCLE.name ||
                                $scope.fieldValueChange.imageType.name == FormConstants.imageTypes.SQUARE.name;
                        }
                        else {
                            return $scope.fieldValue.imageType.name == FormConstants.imageTypes.CIRCLE.name ||
                                $scope.fieldValue.imageType.name == FormConstants.imageTypes.SQUARE.name;
                        }
                    };
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function imageTypeChanged($scope, imageType) {
                if (imageType.name == FormConstants.imageTypes.CIRCLE.name || imageType.name == FormConstants.imageTypes.SQUARE.name) {
                    if ($scope.fieldValue) {
                        $scope.fieldElement.displayConfig.height = vbrCommonUtil.UNAVAILABLE;
                    }
                    $scope.fieldValue.maintainAspectRatio = false;
                }
                else {
                    $scope.fieldElement.displayConfig.height = null;
                }
            }

            function maintainAspectRatioChanged($scope, maintainAspectRatio) {
                if (maintainAspectRatio) {
                    $scope.fieldElement.displayConfig.height = vbrCommonUtil.UNAVAILABLE;
                }
                else {
                    if ($scope.fieldElement.displayConfig.height == vbrCommonUtil.UNAVAILABLE) {
                        $scope.fieldElement.displayConfig.height = null;
                    }
                }
            }

        });

})();

