(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for HasUnauthenticatedTaskCondition model
     */
    app.factory('HasUnauthenticatedTaskConditionModel', function (QObjectModel, ProgramTestConstantsService, UnauthenticatedTaskService, AlertService) {
        /***************************************************************
         * private functions
         **************************************************************/

        HasUnauthenticatedTaskConditionModel.inheritsFrom(QObjectModel);

        function HasUnauthenticatedTaskConditionModel(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.HAS_UNAUTHENTICATED_TASK);

            this.taskId = null;

            this._task = null;
        }

        /**
         * convert the current UI model to dto format
         */
        HasUnauthenticatedTaskConditionModel.prototype.toDto = function () {
            const dto = QObjectModel.prototype.toDto.call(this);

            dto.taskId = this.taskId;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        HasUnauthenticatedTaskConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.taskId = dto.taskId;

            UnauthenticatedTaskService.getTaskById(this.taskId).then(response => {
                this._task = response.data;
            }).catch(function (error) {
                AlertService.error('Failed to find unauthenticated task by id.', error);
            });
        };

        HasUnauthenticatedTaskConditionModel.prototype.getTask = function () {
            return this._task;
        };

        HasUnauthenticatedTaskConditionModel.prototype.setTask = function (task) {
            this._task = task;
            this.taskId = task? task.id : null;
        };

        HasUnauthenticatedTaskConditionModel.prototype.getProgramId = function () {
            return this.getRoot() ? this.getRoot().programId : null;
        };


        HasUnauthenticatedTaskConditionModel.prototype.getDescriptionAsHtml = function () {
            const baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            return `${baseMsg} ID ${this.taskId}`;
        };

        HasUnauthenticatedTaskConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.taskId == null) {
                this.setErrorMessage('task id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return HasUnauthenticatedTaskConditionModel;

    });
})();
