/**
 * form edit's directive responsible for the "advanced panel", it includes
 * internal search, edit/nav history, and error checking
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formInternalAdvancedPanel', function (vbrCommonUtil,
                                                          FormLinkingElementService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/form/advancedPanel/formInternalAdvancedPanel.html',
                scope: {
                    formVersion: '=',
                    dropDownOptions: '=',
                    treeSelection: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.tabData = {
                        currentTab: 0,
                        tabs: [
                            {
                                title: 'Search'
                            }, {
                                title: 'History'
                            }, {
                                title: 'Error Checking'
                            }
                        ]
                    };

                    $scope.errorElements = [];

                    $scope.gotoLinkedElement = function (resultObject) {
                        FormLinkingElementService.gotoLinkedElement($scope.selector, resultObject);
                    };

                    $scope.goToHistory = function (linkedElement, historyIndex) {
                        if (linkedElement != null) {
                            $scope.selector.setElementParentKeyValue(linkedElement, '_parent', ['collapsed', 'sectionCollapsed', 'stateRuleCollapsed'], false);
                            $scope.selector.goToHistory(historyIndex);
                        }
                    };

                    $scope.checkFormConfiguration = function () {
                        var form = $scope.formVersion.getRoot();
                        form.validate(true);
                        $scope.errorElements = form.findElementsWithError();
                    };

                    $scope.getIdFromResult = getIdFromResult;
                    $scope.getTypeForResult = getTypeForResult;
                    $scope.getNameForResult = getNameForResult;
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            function isNavEdge(result) {
                return FormLinkingElementService.isNavEdge(result);
            }

            /**
             * getting the local id from the result, if it's an nav edge, just return a label
             * @param result - result object
             * @returns {string}
             */
            function getIdFromResult(result) {
                if (result == null) {
                    return '';
                }
                else if (isNavEdge(result)) {
                    return 'nav edge';
                }
                else {
                    return result.localId;
                }
            }

            /**
             * getting the result type information in html
             * @param result - result object
             * @param constants - form constant class
             * @returns {string} - html string represent result type information, may include styling and formatting in html
             */
            function getTypeForResult(result, constants) {
                var message = '';

                if (isNavEdge(result)) {
                    message = 'Nav Edge';
                }
                else {
                    message = result.nodeType;
                    if (result.nodeType==constants.nodeType.RULE_TRIGGER || result.nodeType==constants.nodeType.FIELD || result.nodeType==constants.nodeType.SUB_FIELD) {
                        message += '| ' + result.type.name + ' <span class="ficon-sm" ng-class="' + result.type.icon + '"></span>'
                    }
                    else if (result.nodeType==constants.nodeType.RULE_ACTION) {
                        message += '| ' + result.actionType.name + ' <span class="ficon-sm" ng-class="' + result.actionType.icon + '"></span>';
                    }
                }

                return message;
            }

            /**
             * getting the name and field value information in html
             * @param result - result object
             * @param constants - form constant class
             * @returns {string} - html string represent result summary information, may include styling and formatting in html
             */
            function getNameForResult(result, constants) {
                var message = '';

                if (isNavEdge(result)) {
                    message = 'edge for node : ' + result.nodeFrom.getName();
                }
                else {
                    message = result.name;
                    if (!vbrCommonUtil.isNullOrUnavailable(result.fieldValue) && !vbrCommonUtil.isNullOrUnavailable(result.fieldValue.value)) {
                        message += '| <span>' + replaceNewLine(result.fieldValue.value) + '</span>';
                    }
                }

                return message;
            }

            /**
             * replace new line character to <br> in the input
             * @param input
             * @returns {string} - html string that converted new line into <br>
             */
            function replaceNewLine(input) {
                if (input==null) {
                    return '';
                }

                var newLineReg = new RegExp("\n", 'g');

                return input.replace(newLineReg, '<br/>');
            }

        });

})();

