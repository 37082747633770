
angular.module('acadiamasterApp')
    .directive('expressionConditionElementConfigMain', ProgramTestConstantsService => ({
        link: ($scope) => {
            $scope.constant = ProgramTestConstantsService;
            $scope.condition = $scope.element;
        },
        restrict: 'E',
        scope: {
            element: '=',
        },
        templateUrl: 'admin-templates/site/expressionCondition/configuration/expressionConditionConfigMain.html',
    }));
