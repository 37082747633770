/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {

    angular.module('acadiamasterApp')

    /**
     * directive for common form element basic configuration
     * this directive can be used for any kind of form element (component, field, section, form)
     */
        .directive('formElementConfigBasic', (LocaleConstantsService, FormConstants, vbrCommonUtil, FormUtilService) => {
            var FormModelServiceFieldValue = FormUtilService.FormModelServiceFieldValue;

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/elementConfig.basic.html',
                scope: {
                    element: '=configModel',
                    parentForm : '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.showExternalFld = true;
                    $scope.lcs = LocaleConstantsService;
                    $scope.vcu = vbrCommonUtil;
                    $scope.constants = FormConstants;
                    $scope.referencedStateRuleElements = createReferencingStateRuleElementList($scope.element);
                    $scope.referencingViewElements = createReferencingViewElementList($scope.element);
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.gotoReferencingElement = function(linkedElement) {
                        if (linkedElement!=null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, "_parent", ['collapsed', 'sectionCollapsed', 'stateRuleCollapsed'], false);
                        }
                    };

                    $scope.nameStartsWithUnderscore = function(element) {
                        if (element.nodeType == FormConstants.nodeType.FIELD) {
                            if (element.name != null && element.name.startsWith('_')) {
                                return true;
                            }
                        }
                        return false;
                    };
                    
                    $scope.element.externalFieldName ? $scope.showExternalFld = true : $scope.showExternalFld = false;

                    $scope.externalFieldNames =$scope.element.getRoot().formVersions[0].editMode.externalSourceFields;

                    $scope.removeExternalFld = function(){
                        $scope.showExternalFld = false; 
                        $scope.element.externalFieldName = '';
                    };

                    $scope.showExternalFieldDrop = function(){
                        $scope.showExternalFld = true;
                    };
                }
            };

            /**
             * create a list of view-* field type that has reference to current field
             * @param element current element
             * @returns {*}
             */
            function createReferencingViewElementList(element) {
                var myLocalId = element.localId;

                if (element.nodeType!=FormConstants.nodeType.FIELD || !element.isInputField()) {
                    return null;
                }

                // this element is an input field, can potentially have view reference in the view mode
                var viewMode = element.getRoot().formVersions[0].viewMode;
                var sections = viewMode.sections;

                var list = [];
                if (sections!=null && sections.length>0) {
                    _.forEach(sections, function(section) {
                        if (section.formComponents!=null) {
                            _.forEach(section.formComponents, function(component) {
                                if (component.formComponentFields!=null) {
                                    _.forEach(component.formComponentFields, function(field) {
                                        var fieldValue = field.fieldValue;

                                        // found a reference field, that is pointed to the current element
                                        if (fieldValue instanceof FormModelServiceFieldValue.FieldValueReferenceFieldBaseModel &&
                                            fieldValue.localId === myLocalId) {
                                            list.push(field);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }

                if (list.length==0) {
                    list = null;
                }

                return list;
            }

            function createReferencingStateRuleElementList(element) {
                var targetType = null, targetLocalId = null;

                if (element.nodeType==FormConstants.nodeType.FORM_VERSION) {
                    targetType = FormConstants.formTargetType.FORM_VERSION;
                }
                else if (element.nodeType==FormConstants.nodeType.MODE.EDIT || element.nodeType==FormConstants.nodeType.MODE.VIEW_ENTRY
                    || element.nodeType==FormConstants.nodeType.MODE.PROMPT) {
                    targetType = FormConstants.formTargetType.FORM_MODE;
                }
                else if (element.nodeType==FormConstants.nodeType.SECTION) {
                    targetType = FormConstants.formTargetType.FORM_SECTION;
                }
                else if (element.nodeType==FormConstants.nodeType.COMPONENT) {
                    targetType = FormConstants.formTargetType.FORM_COMPONENT;
                }
                else if (element.nodeType==FormConstants.nodeType.FIELD) {
                    targetType = FormConstants.formTargetType.FORM_FIELD;
                }
                else if (element.nodeType==FormConstants.nodeType.SUB_FIELD) {
                    targetType = FormConstants.formTargetType.FORM_SUB_FIELD;
                }

                if (targetType==null) {
                    return null;
                }
                else if (targetType!=FormConstants.formTargetType.FORM_MODE && targetType!=FormConstants.formTargetType.FORM_VERSION) {
                    targetLocalId = element.localId;
                }

                var list = [];

                var currentMode;

                if (targetType == FormConstants.formTargetType.FORM_VERSION) {
                    currentMode = element.editMode;
                }
                else if (targetType==FormConstants.formTargetType.FORM_MODE) {
                    currentMode = element;
                }
                else {
                    currentMode = element.findParentMode();
                }

                var stateRules = currentMode.stateRules;

                if (stateRules!=null) {
                    _.forEach(stateRules, function(stateRule) {
                        stateRule.findReference(targetType, targetLocalId, list);
                    });
                }

                if (list.length==0) {
                    list = null;
                }

                return list;
            }

        });
}());
