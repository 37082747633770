/**
 * Created by moustafabaiou on 2/16/17.
 */
 (function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for submit button
     */
        .directive('formFieldConfigFieldValueSubmitButton', function (FormUtilService, FormConstants, vbrCommonUtil) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/submitButton.html',
                scope       : {
                    fieldValue       : '=',
                    fieldValueChange : '=',
                    fieldElement     : '=',
                },
                link : function ($scope) {
                    const sizeName = {
                        'DEFAULT': 'btn-regular',
                        'LARGE': 'btn-large',
                        'SMALL': 'btn-small',
                        'EXTRA_SMALL': 'btn-mini',
                    };
                    const customSizeName = {
                        'DEFAULT': 'btn-regular-idme',
                        'LARGE': 'btn-large-idme',
                        'SMALL': 'btn-small-idme',
                        'EXTRA_SMALL': 'btn-mini-idme',
                    };
                    $scope.constants = FormConstants;
                    $scope.su = FormUtilService.stringUtil;
                    $scope.vcu = vbrCommonUtil;

                    $scope.hasChangeModel = $scope.fieldValueChange != undefined;

                    $scope.inputTypeUpdated = function (inputType) {
                        inputTypeUpdated($scope, inputType);
                    };

                    $scope.getLabelAsHtml = function () {
                        return $scope.su.convertLabel($scope.getValue());
                    };

                    $scope.getClasses = function () {
                        if($scope.getButtonType().btnClass === 'btn-custom-idme'){
                            return `${ $scope.getVbrSizeClass($scope.getSize().name, true)}`;
                        }
                        return `${$scope.getButtonType().btnClass } ${ $scope.getVbrSizeClass($scope.getSize().name)}`;
                    };

                    $scope.getButtonType = function () {
                        return getPropertyValue($scope, 'buttonType');
                    };

                    $scope.getValue = function () {
                        return getPropertyValue($scope, 'value');
                    };

                    $scope.getSize = function () {
                        return getPropertyValue($scope, 'size');
                    };

                    $scope.getVbrSizeClass = function (size, isCustomButton) {
                        if( isCustomButton ) {
                            return customSizeName[size] || '';
                        }
                        return sizeName[size] || '';
                    };

                    $scope.getInputType = function () {
                        return getPropertyValue($scope, 'inputType');
                    };

                    $scope.getInputValue = function () {
                        return getPropertyValue($scope, 'inputValue');
                    };

                    $scope.getInputTypeAsString = function () {
                        var inputType = $scope.getInputType();
                        if (inputType == FormConstants.submitButton.inputType.AS_NUMBER) {
                            return 'number';
                        }
                        else {
                            return 'text';
                        }
                    };
                },
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            /**
             * generic function to get property value by key while using hasChangeModel property
             * @param $scope
             * @param key
             */
            function getPropertyValue($scope, key) {
                var r = null;
                if ($scope.hasChangeModel) {
                    r = $scope.fieldValueChange[key];
                }

                if (r == null) {
                    r = $scope.fieldValue[key];
                }

                return r;
            }

            function inputTypeUpdated($scope, inputType) {
                if ($scope.hasChangeModel) {
                    // todo: need to do something here?
                }
                else {
                    if (inputType == FormConstants.submitButton.inputType.ON_CLICK_TIME) {
                        $scope.fieldValue.inputValue = vbrCommonUtil.UNAVAILABLE;
                    }
                    else if (inputType == FormConstants.submitButton.inputType.AS_NUMBER) {
                        $scope.fieldValue.inputValue = 1;
                    }
                    else if (inputType == FormConstants.submitButton.inputType.AS_STRING) {
                        $scope.fieldValue.inputValue = 'SAVE';
                    }

                    $scope.fieldElement.possibleFieldValueType = [inputType.valueType];
                    $scope.fieldElement.unlinkDataType();
                    $scope.fieldElement.dataTypeProperty = null;
                }

            }
        });

})();
