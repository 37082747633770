'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypePropertyValueConversionDetailController', function ($scope, $rootScope, $stateParams, entity, DataTypePropertyValueConversion, Unit, DataType, Property) {
        $scope.dataTypePropertyValueConversion = entity;
        $scope.load = function (id) {
            DataTypePropertyValueConversion.get({id: id}, function(result) {
                $scope.dataTypePropertyValueConversion = result;
            });
        };
        $rootScope.$on('acadiamasterApp:dataTypePropertyValueConversionUpdate', function(event, result) {
            $scope.dataTypePropertyValueConversion = result;
        });
    });
