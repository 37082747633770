import template from './formCompletionActionDetails.html';
(function () {
    angular.module('acadiamasterApp').directive('formCompletionActionDetails',
        (FormConstants, ContainerCacheService, ProgramCacheService) => {
            return {
                link : function ($scope) {
                    const formModel = $scope.parentForm.getRoot();
                    $scope.programId = formModel.programId ? formModel.programId : null;
                    $scope.showOnlySystemForms = !$scope.programId;
                    $scope.constants = FormConstants;
                    $scope.programOptions = null;
                    $scope.formOptions = {
                        allowMultiple : true,
                        form          : $scope.element != null
                        && $scope.element.actionDetails != null
                            ? $scope.element.actionDetails.form : null,
                        onChangeCallBack : function () {
                            if (this.form) {
                                $scope.element.actionDetails.form = this.form;
                            }
                        },
                        viewOnly : false,
                    };
                    $scope.$watch('element.actionDetails.form', form => {
                        $scope.formOptions.form = form;
                    });
                    if ($scope.element.actionDetails.programId) {
                        ProgramCacheService.loadProgramById($scope.element.actionDetails.programId).then(
                            response => {
                                $scope.programOptions = {
                                    getProgram : function () {
                                        return response;
                                    },
                                    setProgram : program => {
                                        $scope.setProgram(program);
                                    },
                                };
                            },
                            error => {
                                console.error(error);
                            }).finally(() => {
                            $scope.data.isLoading = false;
                        });
                    } else {
                        $scope.programOptions = {
                            getProgram : function () {
                                return $scope.program;
                            },
                            setProgram : program => {
                                $scope.setProgram(program);
                            },
                        };
                    }
                    $scope.selectPage = page => {
                        $scope.element.actionDetails.pageId = page?.id;
                    };
                    $scope.selectContainer = container => {
                        $scope.element.actionDetails.containerId = container?.id;
                        $scope.element.actionDetails.containerType = container?.containerType;
                        $scope.element.actionDetails.pageId = container?.pageId;
                    };
                    $scope.setProgram = function (program) {
                        $scope.element.actionDetails.programId = program?.id;
                        $scope.program = program;
                    };

                    $scope.$watch('element.actionDetails.programId', newValue => {
                        if (!newValue) {
                            $scope.element.actionDetails.pageId = null;
                            $scope.element.actionDetails.containerId = null;
                            $scope.element.actionDetails.containerType = null;
                        }
                    });
                },
                restrict : 'E',
                scope    : {
                    element    : '=configModel',
                    parentForm : '<',
                },
                template : template,
            };
        });
}());
