/**
 * Created by moustafabaiou on 4/26/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('inviteCodes', {
                    parent: 'site',
                    url: '/inviteCodes',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/inviteCodes/inviteCodes.html',
                            controller: 'InviteCodesController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('inviteCodes');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                })
                .state('inviteCodes.usage', {
                    parent: 'inviteCodes',
                    url: '/{code:[0-9a-zA-Z]{7}}',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            template: function($stateParams) {
                                return '<invite-code-usage code="' + $stateParams.code +
                                    '"></invite-code-usage>';
                            }
                        }
                    },
                    resole: {
                    }
                })
                .state('allPrograms', {
                    parent: 'inviteCodes',
                    url: '/programs',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/inviteCodes/inviteCodes.html',
                            controller: 'InviteCodesController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('program');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        });
})();
