(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for AddressStateComparisonCondition model
     */
    app.factory('AddressZipComparisonConditionModel', function (QObjectModel,
        ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        AddressZipComparisonConditionModel.inheritsFrom(QObjectModel);

        function AddressZipComparisonConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.ADDRESS_ZIP_CODE_COMPARISON);

            //initialized the variables
            this.zips = [];
            this.comparator = ProgramTestConstantsService.operators.EQ;
        }

        /**
         * convert the current UI model to dto format
         */
        AddressZipComparisonConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.zips = _.filter(this.zips, function (z) {
                return z != null && z.length == 5;
            });

            dto.comparator = this.comparator == null ? null : this.comparator.name;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        AddressZipComparisonConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.zips = dto.zips;

            this.setComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator));
        };

        AddressZipComparisonConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        AddressZipComparisonConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.zips = null;
            }
            else if (this.zips == null) {
                this.zips = ['00000'];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN) {
                if (this.zips.length > 1) {
                    // just use the first value
                    this.zips = [this.zips[0]];
                }
            }
        };

        AddressZipComparisonConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            var stateMsg = 'Zip in Address';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : this.zips;

            return baseMsg + ' ' + stateMsg + comparatorMsg + valueMsg;
        };

        AddressZipComparisonConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.comparator == null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.zips == null || this.zips.length == 0)) {
                this.setErrorMessage('zip value is required except when comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        /***************************************
         * service return call
         ***************************************/
        return AddressZipComparisonConditionModel;

    });
})();
