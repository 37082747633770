'use strict';

angular.module('acadiamasterApp')
    .factory('ProgramSubscription', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/programSubscriptions/:id'), {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true
            },
            'update': {method: 'PUT'}
        });
    })
    .factory('ProgramSubscriptionActiveUsers', function ($http) {
        return {getActiveUsers: (programCode) => $http.get(`/api/programSubscriptionCount?programCode=${programCode}`)};
    })
;
