'use strict';

angular.module('vibrent-external-survey-parser')
    .service('VibrentFormStructureConstantService', () => {
        const defaultSidePadding = 20;
        const defaultTopPadding = 10;
        const defaultBottomPadding = 20;
        const defaultSliderSidePadding = 35;

        const richTextLabelDefaultPadding = {
            top    : 10,
            left   : 18,
            right  : 10,
            bottom : 0,
        };

        const plainTextLabelDefaultPadding = {
            top    : 10,
            left   : 18,
            right  : 10,
            bottom : 0,
        };

        const multiSelectorComponentDefaultPadding = {
            top    : 10,
            left   : 10,
            right  : 10,
            bottom : 0,
        };

        const multiSelectorLabelDefaultPadding = {
            top    : 10,
            left   : 20,
            right  : 20,
            bottom : 0,
        };

        const multiSelectorImagePadding = {
            top    : 20,
            left   : 0,
            right  : 20,
            bottom : 20,
        };

        const plainTextInputDefaultPadding = {
            top    : 10,
            left   : 32,
            right  : 20,
            bottom : 0,
        };

        const numberInputDefaultPadding = {
            top    : 10,
            left   : 32,
            right  : 20,
            bottom : 0,
        };

        const phoneInputDefaultPadding = {
            top    : 10,
            left   : 32,
            right  : 20,
            bottom : 0,
        };

        const datePickerInputDefaultPadding = {
            top    : 10,
            left   : 32,
            right  : 20,
            bottom : 0,
        };

        const timePickerInputDefaultPadding = {
            top    : 10,
            left   : 32,
            right  : 20,
            bottom : 0,
        };

        const dropDownDefaultPadding = {
            top    : 10,
            left   : 20,
            right  : 10,
            bottom : 5,
        };

        const matrixDefaultPadding = {
            top    : 20,
            left   : 30,
            right  : 20,
            bottom : 0,
        };

        const radioOptionPadding = {
            top    : 0,
            left   : 40,
            right  : 20,
            bottom : 0,
        };

        const surveyFlowTypes = {
            STANDARD      : 'Standard',
            BRANCH        : 'Branch',
            BLOCK         : 'Block',
            EMBEDDED_DATA : 'EmbeddedData',
        };

        const positionTypes = {
            LEFT   : 'LEFT',
            RIGHT  : 'RIGHT',
            TOP    : 'TOP',
            BOTTOM : 'BOTTOM',
            CENTER : 'CENTER',
        };

        const layoutOrientationTypes = {
            HORIZONTAL : 'HORIZONTAL',
            VERTICAL   : 'VERTICAL',
        };

        const qualtricsSurveyQuestionTypes = {
            TEXT_AND_GRAPHICS : 'DB', // Text and Graphics
            TEXT_ENTRY        : 'TE', // Text Entry
            MULTIPLE_CHOICE   : 'MC', // Multiple Choices
            MATRIX            : 'Matrix', // Matrix
            SLIDER            : 'Slider', // Slider,
            SIDE_BY_SIDE      : 'SBS', // Side by Side - Not supported in Vibrent
            RANK_ORDER        : 'RO', // Read Only - NOT supported in Vibrent
        };

        const questionSelector = {
            DROPDOWN_LIST              : 'DL',
            FORM                       : 'FORM',
            HORIZONTAL_SLIDER          : 'HSLIDER',
            LIKERT                     : 'Likert',
            MULTIPLE_LINES             : 'ML',
            MULTIPLE_ANSWER_VERTICAL   : 'MAVR',
            MULTIPLE_ANSWER_HORIZONTAL : 'MAHR',
            PLAIN_TEXT_LABEL           : 'PTB',
            SINGLE_LINE                : 'SL',
            SINGLE_ANSWER_VERTICAL     : 'SAVR',
            SINGLE_ANSWER_HORIZONTAL   : 'SAHR',
            TB                         : 'contacting',
            TEXT_ENTRY                 : 'TE',
        };

        const questionSubSelector = {
            SINGLE_ANSWER   : 'SingleAnswer',
            MULTIPLE_ANSWER : 'MultipleAnswer',
            SHORT           : 'Short',
            MEDIUM          : 'Medium',
            DL              : 'DL',
            TX              : 'TX',
        };

        const qualtricsLanguage = {
            //https://www.qualtrics.com/support/survey-platform/survey-module/survey-tools/translate-survey/#AvailableLanguageCodes
            SPANISH               : 'ES-ES',
            SPANISH_LATIN_AMERICA : 'ES',
        };

        const singleLineContentTypes = {
            VALID_PHONE  : 'ValidPhone',
            VALID_DATE   : 'ValidDate',
            VALID_NUMBER : 'ValidNumber',
        };

        const phoneTypes = {
            VALID_US_PHONE : 'ValidUSPhone',
            VALID_CA_PHONE : 'ValidCAPhone',
        };

        const dateTypes = {
            DATE_WITH_FORMAT : 'DateWithFormat', // mm/dd/yyyy
        };

        const vibrentLanguage = {
            SPANISH : 'es',
        };

        // Going to pass external source for the same so that this column can be saved with exact source name with redcap or qualtrics
        function initBaseFormDto() {
            return {
                name         : 'Vibrent Form Parsed from Qualrtics Survey',
                displayName  : 'Vibrent Form Parsed from Qualrtics Survey',
                category     : 'DATA_FORM',
                formVersions : [],
                formFields   : [],
            };
        }

        function initBaseFormVersionDto() {
            const today = new Date();
            return {
                'isActive': true,
                'versionId': 1,
                'semanticVersion': 'v' + today.getFullYear() + '.' + (today.getMonth() + 1) + '.' + today.getDate(),
                'editMode': {
                    'includeProgressBar': true,
                    'displayConfig': {
                        'font': {
                            'fontName': null,
                            'fontStyle': {
                                'inherit': true,
                                'normal': false,
                                'bold': false,
                                'italic': false,
                            },
                            'fontSize': null,
                            'lineHeight': {
                                'value': null,
                                'unit': '%',
                            },
                        },
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigCommonContainerModel',
                        'color': 'inherit',
                        'bgColor': 'inherit',
                        'backgroundImage': null,
                        'gravity': null,
                        'layoutOrientation': 'VERTICAL',
                        'layoutGravity': {
                            'horizontal': 'CENTER',
                            'vertical': 'MIDDLE',
                        },
                    },
                    'templateType': 'CUSTOM',
                    'pages': [],
                    'navigationNodes': [],
                    'navigationEdges': [],
                    'stateRules': [],
                    'validationRules': [],
                    'externalSourceFields': [],
                },
                'viewMode': {
                    'includeProgressBar': false,
                    'displayConfig': null,
                    'templateType': 'IDENTICAL',
                    'pages': [],
                    'navigationNodes': [],
                    'navigationEdges': [],
                    'stateRules': [],
                    'validationRules': [],
                    'externalSourceFields': [],
                },
                'promptMode': {
                    'includeProgressBar': false,
                    'displayConfig': null,
                    'templateType': 'NONE',
                    'pages': [],
                    'navigationNodes': [],
                    'navigationEdges': [],
                    'stateRules': [],
                    'validationRules': [],
                    'externalSourceFields': [],
                },
            };
        }

        function initBasePageDto(pageLocalId, showSubmitButton, pageNo) {
            return {
                'id': null,
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigCommonContainerModel',
                    'color': 'inherit',
                    'bgColor': 'inherit',
                    'backgroundImage': null,
                    'gravity': null,
                    'layoutOrientation': 'VERTICAL',
                    'layoutGravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'width': null,
                    'height': null,
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'advancedVisibilityConfig': null,
                },
                'name': 'Page ' + pageLocalId,
                'localId': pageLocalId,
                'displayOrder': pageNo,
                'showSubmitButton': showSubmitButton,
                'pageScrollRequired': false,
                'submitButtonLabel': 'Submit',
                'formPageType': 'NORMAL',
                'submitButtonType': 'BUTTON_PRIMARY',
                'sections': [],
                'validationRules': [],
                'formPageMetadata': null,
                'localizationMap': {},
            };
        }

        function initBaseSectionDto(localIdGenerator, pageLocalId) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'name': 'Section in page ' + pageLocalId,
                'sectionType': 'NORMAL',
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigCommonContainerModel',
                    'color': 'inherit',
                    'bgColor': 'inherit',
                    'backgroundImage': null,
                    'gravity': null,
                    'layoutOrientation': 'VERTICAL',
                    'layoutGravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'advancedVisibilityConfig': null,
                },
                'displayOrder': 0,
                'formComponents': [],
                'validationRules': [],
            };
        }

        function initBaseComponentDto(localIdGenerator, pageLocalId) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'name': 'Component in page ' + pageLocalId,
                'description': null,
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigCommonContainerModel',
                    'color': 'inherit',
                    'bgColor': 'inherit',
                    'backgroundImage': null,
                    'gravity': null,
                    'layoutOrientation': 'VERTICAL',
                    'layoutGravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'advancedVisibilityConfig': null,
                },
                'componentType': 'NORMAL',
                'formComponentFields': [],
                'validationRules': [],
                'formComponentMetaDataDTO': {
                    'matrixQuestionnaireMetadataDTO': {
                        'title': null,
                        'valueType': 'STRING',
                        'singleSelect': true,
                        'localizationMap':{},
                        'values' : [
                            initSelectorOptionValue('Option 1', 1),
                            initSelectorOptionValue('Option 2', 2),
                        ],
                    },
                },
            };
        }

        function initNavigationNode(localIdGenerator, pageLocalId, name) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'pageLocalId': pageLocalId,
                'name': name ? name.substring(0, 50) : 'Navigation Node for page: ' + pageLocalId,
            };
        }

        function initNavigationEdge(nodeFromLocalId, nodeToLocalId, name) {
            return {
                'id': null,
                'name': name || null,
                'evaluationOrder': 0,
                'nodeFromLocalId': nodeFromLocalId,
                'nodeToLocalId': nodeToLocalId,
                'conditionText': null,
                'conditionTree': null,
            };
        }

        function initFormFieldRichTextLabel(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'RICH_TEXT_LABEL',
                },
                'type': 'RICH_TEXT_LABEL',
                'name': null,
                'fieldValue': {
                    'value': '',
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueRichTextLabelModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                },
                'displayConfig': {
                    'font': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigRichTextModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': null,
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'advancedConfig': {
                            'left': {
                                'value': richTextLabelDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': richTextLabelDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': richTextLabelDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': richTextLabelDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsEnabled': false,
                'insightsConfig': null,
                'validationRules': [],
                'subFields': null,
                'localizationMap': {},
            };
        }

        function displayConfigBaseModel(modelClass, defaultMargins) {
            return {
                'font': null,
                '@class': modelClass,
                'color': null,
                'bgColor': null,
                'backgroundImage': null,
                'gravity': null,
                'layoutOrientation': null,
                'layoutGravity': null,
                'width': {
                    'value': 100,
                    'unit': '%',
                },
                'height': null,
                'visible': true,
                'editable': true,
                'margin': {
                    'configType': 'SYSTEM',
                    'simpleConfig': null,
                    'advancedConfig': null,
                },
                'padding': {
                    'configType': 'ADVANCED',
                    'advancedConfig': {
                        'left': {
                            'value': defaultMargins.left,
                            'unit': 'dp',
                        },
                        'right': {
                            'value': defaultMargins.right,
                            'unit': 'dp',
                        },
                        'top': {
                            'value': defaultMargins.top,
                            'unit': 'dp',
                        },
                        'bottom': {
                            'value': defaultMargins.bottom,
                            'unit': 'dp',
                        },
                    },
                },
                'advancedVisibilityConfig': null,
            };
        }

        function initSelectorOptionValue(textLabel, value) {
            return {
                'text': textLabel,
                'contentDescription': textLabel,
                'value': value,
                'enabled': true,
                'localizationMap': {},
                'groupNumber': '1',
                'showTooltip': false,
            };
        }

        function initFormFieldMultiSelector(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'MULTI_SELECTOR',
                },
                'type': 'MULTI_SELECTOR',
                'name': 'Multi Selector',
                'fieldValue': {
                    'value': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueMultiSelectModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'questionLabel': null,
                    'eraseDataWhenHidden': false,
                    'titlePosition': 'TOP',
                    'valueType': 'NUMBER',
                    'showAccordion': false,
                    'defaultState': null,
                },
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': multiSelectorComponentDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': multiSelectorComponentDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': multiSelectorComponentDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': multiSelectorComponentDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'validationRules': [],
                'subFields': [
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Pick your answers from the following choices',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'showTooltip': false,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'TOP',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': multiSelectorLabelDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': multiSelectorLabelDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': multiSelectorLabelDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': multiSelectorLabelDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldMultiSelectModel',
                        'type': 'MULTI_SELECT_OPTIONS',
                        'contentDescription': null,
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueMultiSelectOptionsModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                            'imagePreset': 'CIRCULAR_BLUE',
                            'includeImage': true,
                            'includeText': true,
                            'textStyle': 'NORMAL',
                            'imagePosition': 'LEFT',
                            'textPosition': 'RIGHT',
                            'imageType': 'RECTANGLE',
                            'imageWidth': {
                                'value': 20,
                                'unit': 'dp',
                            },
                            'imageHeight': {
                                'value': 20,
                                'unit': 'dp',
                            },
                            'onClickSubmit': false,
                            'values': [
                                initSelectorOptionValue('Option 1', 1),
                                initSelectorOptionValue('Option 2', 2),
                                initSelectorOptionValue('Option 3', 3),
                            ],
                            'singleLine': true,
                            'dropdown': false,
                            'includeSeparator': false,
                            'cellBorderLayout': false,
                            'imageMargin': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': multiSelectorImagePadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': multiSelectorImagePadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': multiSelectorImagePadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': multiSelectorImagePadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'defaultImage': null,
                            'disabledImage': null,
                            'selectedImage': null,
                            'textGravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'MIDDLE',
                            },
                            'textMargin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'styleSize': 'DEFAULT',
                            'optionsDisplayMode': 'VERTICAL',
                            'numberOfColumns': 1,
                            'limitOptionSelection': false,
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneEditableGravityModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': true,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': defaultTopPadding,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                ],
                'localizationMap': {},
            };
        }

        function initFormFieldRadioSelector(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'externalFieldName': null,
                'formField': {
                    'id': null,
                    'type': 'RADIO_SELECTOR',
                },
                'type': 'RADIO_SELECTOR',
                'name': 'Radio Selector',
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': null,
                'fieldValue': {
                    'value': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueRadioSelectorModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'questionLabel': null,
                    'eraseDataWhenHidden': false,
                    'titlePosition': 'TOP',
                    'valueType': 'NUMBER',
                },
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': 0,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': 0,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': 0,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': 0,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsEnabled': false,
                'insightsConfig': {
                    'visualizationMode': 'HORIZONTAL_BAR_CHART',
                    'groupingAttributes': null,
                },
                'validationRules': [],
                'subFields': [
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Pick your answer from the following choices',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'showTooltip': false,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'TOP',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': defaultTopPadding,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldRadioOptionModel',
                        'type': 'RADIO_OPTIONS',
                        'contentDescription': null,
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueRadioOptionsModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                            'imagePreset': 'CIRCULAR_BLUE',
                            'includeImage': true,
                            'includeText': true,
                            'textStyle': 'NORMAL',
                            'imagePosition': 'TOP',
                            'textPosition': 'BOTTOM',
                            'imageType': 'RECTANGLE',
                            'imageWidth': {
                                'value': 20,
                                'unit': 'dp',
                            },
                            'imageHeight': {
                                'value': 20,
                                'unit': 'dp',
                            },
                            'onClickSubmit': false,
                            'values': [
                                initSelectorOptionValue('Option 1', 1),
                                initSelectorOptionValue('Option 2', 2),
                                initSelectorOptionValue('Option 3', 3),
                            ],
                            'optionsDisplayMode': 'HORIZONTAL',
                            'numberOfColumns': 1,
                            'includeRadioButton': false,
                            'includeSeparator': false,
                            'cellBorderLayout': false,
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneEditableGravityModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': true,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': radioOptionPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': radioOptionPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': radioOptionPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': radioOptionPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                ],
                'localizationMap': {},
            };
        }

        function initFormFieldTextInput(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'TEXT_INPUT',
                },
                'type': 'TEXT_INPUT',
                'name': null,
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': null,
                'fieldValue': {
                    'value': '',
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueTextInputModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'questionLabel': null,
                    'eraseDataWhenHidden': false,
                    'numberOfLines': 1,
                    'styleType': 'PRIMARY',
                    'styleSize': 'DEFAULT',
                },
                'displayConfig': displayConfigBaseModel('com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigSimpleInputModel', plainTextInputDefaultPadding),
                'insightsEnabled': false,
                'insightsConfig': null,
                'validationRules': [],
                'subFields': null,
                'localizationMap': {},
            };
        }

        function initFormFieldSlider(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'SLIDER',
                },
                'type': 'SLIDER',
                'name': 'Slider',
                'fieldValue': {
                    'value': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueSliderModel',
                    'localizationMap': {},
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': false,
                    'titlePosition': 'TOP',
                    'valueType': 'NUMBER',
                },
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'advancedVisibilityConfig': null,
                },
                'validationRules': [],
                'subFields': [
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Select your answer',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'TOP',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': defaultTopPadding,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldSliderOptionModel',
                        'type': 'SLIDER_OPTIONS',
                        'contentDescription': null,
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueSliderOptionsModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                            'imagePreset': 'CUSTOM',
                            'includeImage': false,
                            'includeText': true,
                            'textStyle': null,
                            'imagePosition': null,
                            'textPosition': null,
                            'imageType': null,
                            'imageWidth': {
                                'value': 50,
                                'unit': 'dp',
                            },
                            'imageHeight': {
                                'value': 50,
                                'unit': 'dp',
                            },
                            'onClickSubmit': false,
                            'values': [],
                            'showTicks': false,
                            'showSelectionBar': true,
                            'showValue': false,
                            'showLabel': true,
                            'valuePosition': 'TOP',
                            'labelPosition': 'TOP',
                            'showValueOnSelection': false,
                            'showLabelOnSelection': false,
                            'minValue': 0,
                            'maxValue': 30,
                            'displayIncrementalValue': 1,
                            'selectionIncrementalValue': 1,
                            'incrementalSlider': true,
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneEditableGravityModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': true,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': defaultSliderSidePadding,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': defaultSliderSidePadding,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': defaultTopPadding,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                ],
                'localizationMap': {},
            };
        }

        function initFormFieldMatrixQuestion(localIdGenerator) {
            return{
                'id' : null,
                'localId' : localIdGenerator.getNextId(),
                'displayConfig' : {
                    '@class' : 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneModel',
                    'font' : {},
                    'visible' : true,
                    'margin' : {
                        'configType' : 'SYSTEM',
                    },
                    'padding' : {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': matrixDefaultPadding.left,
                                'unit': 'dp'
                            },
                            'right': {
                                'value': matrixDefaultPadding.right,
                                'unit': 'dp'
                            },
                            'top': {
                                'value': matrixDefaultPadding.top,
                                'unit': 'dp'
                            },
                            'bottom': {
                                'value': matrixDefaultPadding.bottom,
                                'unit': 'dp'
                            }
                        }
                    },
                },
                'fieldValue' : {
                    '@class' : 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueMatrixQuestionModel',
                    'value' : null,
                    'localizationMap'      : {},
                    'preset' : 'CUSTOM',
                    'changeDetectionDelay' : 0,
                    'eraseDataWhenHidden' : false,
                },
                'displayOrder' : 0,
                'type' : 'MATRIX_QUESTION',
                'validationRules' : [],
                'formComponentId' : null,
                'formField': {
                    'id' : null,
                    'type' : 'MATRIX_QUESTION',
                    'formId' : null
                },
                'localizationMap':  {},
                'insightsEnabled' : false,
            };
        }

        function initEmbeddedTextEntry(localId, localIdGenerator){
            return {
                'id': null,
                'displayOrder': 0,
                'localId': (localId !== undefined && localId !== null) ? localId : localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'EMBEDDED_TEXT_INPUT',
                },
                'type': 'EMBEDDED_TEXT_INPUT',
                'name': null,
                'fieldValue': {
                    'value': '',
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueEmbeddedTextInputModel',
                    'localizationMap': {},
                    'userAccountInformationLink': false,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': true,
                    'numberOfLines': 1
                },
                'insightsEnabled': false,
                'validationRules': [],
                'localizationMap': {},
            };
        }
        function initFormFieldDropdown(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'DROPDOWN',
                },
                'type': 'DROPDOWN',
                'name': 'Dropdown',
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': null,
                'fieldValue': {
                    'value': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueDropdownModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'questionLabel': null,
                    'eraseDataWhenHidden': false,
                    'titlePosition': 'TOP',
                    'valueType': 'NUMBER',
                },
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'LEFT',
                        'vertical': 'MIDDLE',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': dropDownDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': dropDownDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': dropDownDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': dropDownDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsEnabled': false,
                'insightsConfig': {
                    'visualizationMode': 'HORIZONTAL_BAR_CHART',
                    'groupingAttributes': null,
                },
                'validationRules': [],
                'subFields': [
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Select your answer',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'black80',
                            'bgColor': 'white',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': plainTextLabelDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': plainTextLabelDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': plainTextLabelDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': plainTextLabelDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldDropdownOptionModel',
                        'type': 'DROPDOWN_OPTIONS',
                        'contentDescription': null,
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueDropdownOptionsModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'tooltipText': null,
                            'preset': 'CUSTOM',
                            'imagePreset': 'CUSTOM',
                            'includeImage': false,
                            'includeText': true,
                            'textStyle': null,
                            'imagePosition': null,
                            'textPosition': null,
                            'imageType': null,
                            'imageWidth': {
                                'value': 50,
                                'unit': 'dp',
                            },
                            'imageHeight': {
                                'value': 50,
                                'unit': 'dp',
                            },
                            'onClickSubmit': false,
                            'values': [
                                {
                                    'text': 'Option 1',
                                    'contentDescription': 'Option 1',
                                    'value': 1,
                                    'enabled': true,
                                    'defaultImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'disabledImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'selectedImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'localizationMap': {},
                                    'groupNumber': '1',
                                    'score': null,
                                    'showTooltip': false,
                                    'tooltipText': null,
                                },
                                {
                                    'text': 'Option 2',
                                    'contentDescription': 'Option 2',
                                    'value': 2,
                                    'enabled': true,
                                    'defaultImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'disabledImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'selectedImage': {
                                        'imageSize': null,
                                        'imageName': null,
                                        'aspectRatio': null,
                                    },
                                    'localizationMap': {},
                                    'groupNumber': '1',
                                    'score': null,
                                    'showTooltip': false,
                                    'tooltipText': null,
                                },
                            ],
                            'placeholder': 'Pick an option',
                            'showInline': null,
                        },
                        'displayConfig': {
                            'font': {
                                'fontName': null,
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'value': null,
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigProfileImageModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': defaultSidePadding,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': defaultTopPadding,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                ],
                'localizationMap': {},
            };
        }

        function initRichTextLabelForSelectorFieldTitle(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'RICH_TEXT_LABEL',
                },
                'type': 'RICH_TEXT_LABEL',
                'name': null,
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': null,
                'fieldValue': {
                    'value': 'title text here',
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueRichTextLabelModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                },
                'displayConfig': {
                    'font': null,
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigRichTextModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': null,
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': richTextLabelDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': richTextLabelDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': richTextLabelDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': richTextLabelDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsEnabled': false,
                'insightsConfig': null,
                'validationRules': [],
                'subFields': null,
                'localizationMap': {},
            };
        }

        function initFormFieldTextLabel(localIdGenerator, withPadding) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'TEXT_LABEL',
                },
                'type': 'TEXT_LABEL',
                'name': null,
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': null,
                'fieldValue': {
                    'value': 'New Label',
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                    'localizationMap': {},
                    'showTooltip': false,
                    'tooltipText': null,
                    'preset': 'CUSTOM',
                },
                'displayConfig': {
                    'font': {
                        'fontName': null,
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'fontSize': null,
                        'lineHeight': {
                            'value': null,
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                    'color': 'inherit',
                    'bgColor': 'inherit',
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'LEFT',
                        'vertical': 'TOP',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'LEFT',
                    },
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'advancedConfig': {
                            'left': {
                                'value': withPadding ? plainTextLabelDefaultPadding.left : 0,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': withPadding ? plainTextLabelDefaultPadding.right : 0,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': withPadding ? plainTextLabelDefaultPadding.top : 0,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': plainTextLabelDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsEnabled': false,
                'insightsConfig': null,
                'validationRules': [],
                'subFields': null,
                'localizationMap': {},
            };
        }

        function initFormFieldDayPicker(localIdGenerator) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'displayConfig': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneEditableModel',
                    'font': {
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'lineHeight': {
                            'unit': '%',
                        },
                    },
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'advancedConfig': {
                            'left': {
                                'value': datePickerInputDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': datePickerInputDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': datePickerInputDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': datePickerInputDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                },
                'name': 'New Day Selector',
                'subFields': [
                    {
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'localId': localIdGenerator.getNextId(),
                        'type': 'TITLE',
                        'displayConfig': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'font': {
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'visible': true,
                            'margin': {
                                'configType': 'SYSTEM',
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'advancedConfig': {
                                    'left': {
                                        'value': plainTextLabelDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': plainTextLabelDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': plainTextLabelDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': plainTextLabelDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                        },
                        'localizationMap': {},
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'value': 'Please Select A Date',
                            'localizationMap': {},
                            'preset': 'CUSTOM',
                        },
                    },
                    {
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'localId': localIdGenerator.getNextId(),
                        'type': 'TITLE',
                        'displayConfig': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'font': {
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'visible': false,
                            'margin': {
                                'configType': 'SYSTEM',
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'advancedConfig': {
                                    'left': {
                                        'value': datePickerInputDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': datePickerInputDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': datePickerInputDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': datePickerInputDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                        },
                        'localizationMap': {},
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'value': 'Please Select A Date Subtitle',
                            'localizationMap': {},
                            'preset': 'CUSTOM',
                        },
                    },
                ],
                'fieldValue': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueDatePickerModel',
                    'localizationMap': {},
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': false,
                    'defaultDate': {
                        'dateUnit': 'DAY',
                        'offset': 0,
                        'time': 0,
                        'fixedTime': '0',
                    },
                    'hintText': 'mm/dd/yyyy',
                    'labelText': '',
                    'displayRange': 'ALL',
                },
                'displayOrder': 0,
                'type': 'DAY_SELECTOR',
                'validationRules': [],
                'formComponentId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'DAY_SELECTOR',
                },
                'localizationMap': {},
                'insightsEnabled': false,
            };
        }

        function initFormFieldTimePicker(localIdGenerator) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'displayConfig': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'font': {
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'lineHeight': {
                            'unit': '%',
                        },
                    },
                    'gravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'visible': true,
                    'margin': {
                        'configType': 'SYSTEM',
                    },
                    'padding': {
                        'configType': 'SYSTEM',
                    },
                },
                'name': 'Time Picker',
                'subFields': [
                    {
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'localId': localIdGenerator.getNextId(),
                        'type': 'TITLE',
                        'displayConfig': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'font': {
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'MIDDLE',
                            },
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'visible': true,
                            'margin': {
                                'configType': 'SYSTEM',
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'advancedConfig': {
                                    'left': {
                                        'value': plainTextLabelDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': plainTextLabelDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': plainTextLabelDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': plainTextLabelDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                        },
                        'localizationMap': {},
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'value': '',
                            'localizationMap': {},
                            'preset': 'CUSTOM',
                        },
                    },
                    {
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTimePickerOptionsModel',
                        'localId': localIdGenerator.getNextId(),
                        'type': 'TIME_PICKER_OPTIONS',
                        'displayConfig': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneEditableGravityModel',
                            'font': {
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'MIDDLE',
                            },
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'visible': true,
                            'editable': true,
                            'margin': {
                                'configType': 'SYSTEM',
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'advancedConfig': {
                                    'left': {
                                        'value': timePickerInputDefaultPadding.left,
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': timePickerInputDefaultPadding.right,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': timePickerInputDefaultPadding.top,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': timePickerInputDefaultPadding.bottom,
                                        'unit': 'dp',
                                    },
                                },
                            },
                        },
                        'localizationMap': {},
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueTimePickerOptionsModel',
                            'localizationMap': {},
                            'preset': 'CUSTOM',
                            'changeDetectionDelay': 0,
                            'eraseDataWhenHidden': false,
                            'timeFormat': 'STANDARD_TIME',
                            'showInline': false,
                            'defaultTime': {
                                'dateType': '',
                                'dateUnit': '',
                                'offset': 0,
                                'time': 0,
                                'fixedTime': '',
                            },
                            'minTime': '0:0',
                            'maxTime': '23:59',
                            'hintText': 'Select time',
                        },
                    },
                ],
                'fieldValue': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueTimePickerModel',
                    'localizationMap': {},
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': false,
                },
                'displayOrder': 0,
                'type': 'TIME_PICKER',
                'validationRules': [],
                'formComponentId': 6,
                'formField': {
                    'id': null,
                    'type': 'TIME_PICKER',
                },
                'localizationMap': {},
                'insightsEnabled': false,
            };
        }
        function initFormFieldNumberInput(localIdGenerator) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'displayConfig': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigSimpleInputModel',
                    'font': {},
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'advancedConfig': {
                            'left': {
                                'value': numberInputDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': numberInputDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': numberInputDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': numberInputDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                },
                'fieldValue': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueInputBaseModel',
                    'localizationMap': {},
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': false,
                },
                'displayOrder': 0,
                'type': 'NUMBER_INPUT',
                'validationRules': [],
                'formComponentId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'NUMBER_INPUT',
                },
                'localizationMap': {},
            };
        }

        function initFormFieldPhoneNumber(localIdGenerator) {
            return {
                'id': null,
                'localId': localIdGenerator.getNextId(),
                'displayConfig': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigSimpleInputModel',
                    'font': {},
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'visible': true,
                    'editable': true,
                    'margin': {
                        'configType': 'SYSTEM',
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'advancedConfig': {
                            'left': {
                                'value': phoneInputDefaultPadding.left,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': phoneInputDefaultPadding.right,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': phoneInputDefaultPadding.top,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': phoneInputDefaultPadding.bottom,
                                'unit': 'dp',
                            },
                        },
                    },
                },
                'fieldValue': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValuePhoneInputModel',
                    'value': '',
                    'localizationMap': {},
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'eraseDataWhenHidden': false,
                },
                'displayOrder': 0,
                'type': 'PHONE_INPUT',
                'validationRules': [],
                'formComponentId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'PHONE_INPUT',
                },
                'localizationMap': {},
                'insightsEnabled': false,
            };
        }

        function initFormSignatureInput(localIdGenerator) {
            return {
                'id': null,
                'displayOrder': 0,
                'localId': localIdGenerator.getNextId(),
                'formField': {
                    'id': null,
                    'type': 'SIGNATURE_BOX',
                },
                'type': 'SIGNATURE_BOX',
                'name': 'ExtraConsent_Signature',
                'contentDescription': null,
                'dataType': null,
                'dataTypeProperty': null,
                'units': [],
                'fieldValue': {
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueSignatureBoxModel',
                    'localizationMap': {},
                    'showTooltip': null,
                    'preset': 'CUSTOM',
                    'changeDetectionDelay': 0,
                    'questionLabel': 'Add Question Label Here',
                    'eraseDataWhenHidden': false,
                    'titlePosition': 'TOP',
                    'valueType': 'STRING',
                    'signatureType': 'TEXT',
                },
                'displayConfig': {
                    'font': {
                        'fontStyle': {
                            'inherit': true,
                            'normal': false,
                            'bold': false,
                            'italic': false,
                        },
                        'lineHeight': {
                            'unit': '%',
                        },
                    },
                    '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigBareBoneGravityModel',
                    'color': null,
                    'bgColor': null,
                    'backgroundImage': null,
                    'gravity': {
                        'horizontal': 'CENTER',
                        'vertical': 'MIDDLE',
                    },
                    'layoutOrientation': null,
                    'layoutGravity': null,
                    'width': {
                        'value': 100,
                        'unit': '%',
                    },
                    'height': null,
                    'visible': true,
                    'editable': null,
                    'margin': {
                        'configType': 'SYSTEM',
                        'simpleConfig': null,
                        'advancedConfig': null,
                    },
                    'padding': {
                        'configType': 'ADVANCED',
                        'simpleConfig': null,
                        'advancedConfig': {
                            'left': {
                                'value': defaultSidePadding,
                                'unit': 'dp',
                            },
                            'right': {
                                'value': defaultSidePadding,
                                'unit': 'dp',
                            },
                            'top': {
                                'value': defaultTopPadding,
                                'unit': 'dp',
                            },
                            'bottom': {
                                'value': defaultBottomPadding,
                                'unit': 'dp',
                            },
                        },
                    },
                    'advancedVisibilityConfig': null,
                },
                'insightsConfig': null,
                'validationRules': [],
                'subFields': [
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Please sign below.',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontStyle': {
                                    'inherit': true,
                                    'normal': false,
                                    'bold': false,
                                    'italic': false,
                                },
                                'fontSize': null,
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'LEFT',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'ADVANCED',
                                'simpleConfig': null,
                                'advancedConfig': {
                                    'left': {
                                        'unit': 'dp',
                                    },
                                    'right': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                    'top': {
                                        'value': 16,
                                        'unit': 'dp',
                                    },
                                    'bottom': {
                                        'value': 0,
                                        'unit': 'dp',
                                    },
                                },
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldTitleModel',
                        'type': 'TITLE',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'Sub-title',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueStringModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'preset': 'CUSTOM',
                        },
                        'displayConfig': {
                            'font': {
                                'fontStyle': {
                                    'inherit': false,
                                    'normal': false,
                                    'bold': true,
                                    'italic': false,
                                },
                                'lineHeight': {
                                    'unit': '%',
                                },
                            },
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigTextLabelModel',
                            'color': 'inherit',
                            'bgColor': 'inherit',
                            'backgroundImage': null,
                            'gravity': {
                                'horizontal': 'CENTER',
                                'vertical': 'MIDDLE',
                            },
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': false,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldCanvasModel',
                        'type': 'CANVAS',
                        'contentDescription': null,
                        'fieldValue': {
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueCanvasModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'preset': 'CUSTOM',
                            'strokeColor': '#0C1B6B',
                            'strokeWidth': 5,
                            'canvasStyle': 'PRIMARY',
                            'showSeparator': true,
                            'btnBarAlignment': 'BOTTOM',
                            'okButtonAlignment': 'LEFT',
                            'okButtonTitle': 'Ok Button Title',
                            'okButtonStyle': 'BUTTON_DEFAULT',
                            'okButtonColor': '#000000',
                            'clearButtonTitle': 'Clear Button Title',
                            'clearButtonStyle': 'BUTTON_DANGER',
                            'clearButtonColor': '#000000',
                        },
                        'displayConfig': {
                            'font': null,
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigProfileImageModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': null,
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': null,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                    {
                        'localId': localIdGenerator.getNextId(),
                        '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.subfields.SubFieldInputModel',
                        'type': 'INPUT',
                        'contentDescription': null,
                        'fieldValue': {
                            'value': 'type your full name',
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.fieldValue.FieldValueTextInputModel',
                            'localizationMap': {},
                            'showTooltip': null,
                            'preset': 'CUSTOM',
                            'changeDetectionDelay': 0,
                            'questionLabel': null,
                            'eraseDataWhenHidden': false,
                            'numberOfLines': 1,
                            'styleType': 'PRIMARY',
                            'styleSize': 'DEFAULT',
                        },
                        'displayConfig': {
                            'font': null,
                            '@class': 'com.vibrent.acadia.web.rest.dto.helpers.form.displayConfig.DisplayConfigSimpleInputModel',
                            'color': null,
                            'bgColor': null,
                            'backgroundImage': null,
                            'gravity': null,
                            'layoutOrientation': null,
                            'layoutGravity': null,
                            'width': {
                                'value': 100,
                                'unit': '%',
                            },
                            'height': null,
                            'visible': true,
                            'editable': true,
                            'margin': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'padding': {
                                'configType': 'SYSTEM',
                                'simpleConfig': null,
                                'advancedConfig': null,
                            },
                            'advancedVisibilityConfig': null,
                        },
                        'localizationMap': {},
                    },
                ],
                'localizationMap': {},
            };
        }

        function initValidationDto(type, englishErrorMessage) {
            return initValidationDtoSpanishMessage(type, englishErrorMessage, '');
        }

        function initValidationDtoSpanishMessage(type, englishErrorMessage, spanishErrorMessage) {
            return {
                'type': type,
                'options': {},
                'validationValue': null,
                'enabled': true,
                'errorMessage': englishErrorMessage,
                'localizationMap': {
                    'es' : {
                        'errorMessage' : spanishErrorMessage
                    }
                },
            };
        }


        function initValidationValueLanguage(type, englishErrorMessage, spanishErrorMessage, value) {
            return {
                'type': type,
                'options': {
                    'INCLUDE_EQUAL': true
                },
                'validationValue': value,
                'enabled': true,
                'errorMessage': englishErrorMessage,
                'localizationMap': {
                    'es' : {
                        'errorMessage' : spanishErrorMessage
                    }
                },
            };
        }

        return {
            qualtricsSurveyQuestionTypes,
            questionSelector,
            surveyFlowTypes,
            questionSubSelector,
            qualtricsLanguage,
            vibrentLanguage,
            positionTypes,
            layoutOrientationTypes,
            singleLineContentTypes,
            phoneTypes,
            dateTypes,
            initBaseFormDto,
            initBaseFormVersionDto,
            initBasePageDto,
            initNavigationNode,
            initNavigationEdge,
            initBaseSectionDto,
            initBaseComponentDto,
            initFormFieldRichTextLabel,
            initFormFieldMultiSelector,
            initSelectorOptionValue,
            initFormFieldTextInput,
            initFormFieldTextLabel,
            initFormFieldSlider,
            initFormFieldDropdown,
            initFormFieldMatrixQuestion,
            initRichTextLabelForSelectorFieldTitle,
            initValidationDto,
            initFormFieldRadioSelector,
            initFormFieldDayPicker,
            initFormFieldTimePicker,
            initFormFieldNumberInput,
            initFormFieldPhoneNumber,
            initFormSignatureInput,
            initValidationDtoSpanishMessage,
            initValidationValueLanguage,
            initEmbeddedTextEntry,
        };
    });
