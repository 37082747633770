import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an container display config with multi items
 */
angular.module('acadiamasterApp')
    .factory('ContainerMultipleItemsDisplayConfigImpl', (ContainerIconMessageDisplayConfig,
                ModelServiceBase) => {
        const { classes } = ProgramContainerConstantsService;

        ContainerMultipleItemsDisplayConfigImpl.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        /**
         * @constructor
         */
        function ContainerMultipleItemsDisplayConfigImpl(parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

            // default to hide container when it is empty so we don't need to configure any
            // empty state information
            this.hideContainerWhenEmpty = false;
            this.emptyStateConfig = new ContainerIconMessageDisplayConfig();
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerMultipleItemsDisplayConfigImpl.prototype.toDto = function (files) {
            const dto = {};

            dto[classes.classReferenceKey] = classes.ContainerMultipleItemsDisplayConfigImpl;
            dto.hideContainerWhenEmpty = this.hideContainerWhenEmpty;

            if (!dto.hideContainerWhenEmpty && this.emptyStateConfig) {
                dto.emptyStateConfig = this.emptyStateConfig.toDto(files);
            }

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerMultipleItemsDisplayConfigImpl.prototype.fromDto = function (dto) {
            if (dto == null) {
                return;
            }

            // this will create empty configuration object if needed
            this.setHideContainerWhenEmpty(dto.hideContainerWhenEmpty);

            if (dto.emptyStateConfig) {
                this.emptyStateConfig = new ContainerIconMessageDisplayConfig(this);
                this.emptyStateConfig.fromDto(dto.emptyStateConfig);
            }
        };

        /**
         * setting the hide container when empty boolean to new state value
         * note: if this value is false (ie: do not hide container when it is empty), then we
         * will create a new object for empty state config if it's not initialized already.
         *
         * btw, we do not remove existing object if the input is true, so it can be reused for configuration
         * when user toggle it back and forth on UI.  However, when we send it to server, we will
         * remove the object if value is not needed
         *
         * @param newState - new boolean value to set to for property hideContainerWhenEmpty
         */
        ContainerMultipleItemsDisplayConfigImpl.prototype.setHideContainerWhenEmpty = function (newState) {
            this.hideContainerWhenEmpty = newState;

            if (!this.hideContainerWhenEmpty && this.emptyStateConfig == null) {
                // do not hide container when it is empty and we don't have empty state configuration object
                // it's time to create one
                this.emptyStateConfig = new ContainerIconMessageDisplayConfig();
            }
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerMultipleItemsDisplayConfigImpl;
    });
