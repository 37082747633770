'use strict';

angular.module('acadiamasterApp')
    .controller('BusinessRuleController', function (BusinessRule, CONFIG) {
        var vm = this;

        vm.CONFIG = CONFIG;
        vm.selectedRule = null;

        vm.onRuleSelect = function(rule) {
            var ruleDetail = BusinessRule.get({
                id: rule.id
            });

            ruleDetail.$promise.then(function(data) {
                vm.selectedRule = data;
            });
        };
    });
