/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config of box model properties
     */
        .directive('formDisplayConfigBoxModel', function (vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/boxModel.html',
                scope: {
                    displayConfig: '=',
                    displayConfigChange: '=',
                    element: '='
                },
                link: function ($scope) {
                    $scope.isUnavailableOnly = vbrCommonUtil.isUnavailableOnly;
                    $scope.widthOptions = createWidthOptions();
                    $scope.heightOptions = createHeightOptions();

                    $scope.marginOptions = createMarginPaddingModelOption("Margin", 'flaticon-margin', $scope.displayConfig.margin, true);
                    $scope.paddingOptions = createMarginPaddingModelOption("Padding", 'flaticon-padding', $scope.displayConfig.padding, false);


                    $scope.$watch(function () {
                        return $scope.displayConfig.margin
                    }, function () {
                        $scope.marginOptions = createMarginPaddingModelOption("Margin", 'flaticon-margin', $scope.displayConfig.margin, true);
                    });

                    $scope.$watch(function () {
                        return $scope.displayConfig.padding
                    }, function () {
                        $scope.paddingOptions = createMarginPaddingModelOption("Padding", 'flaticon-padding', $scope.displayConfig.padding, false);
                    });
                    $scope.shouldShowBoxModel = function(){
                        return $scope.displayConfig.margin != vbrCommonUtil.UNAVAILABLE ||
                            $scope.displayConfig.padding != vbrCommonUtil.UNAVAILABLE ||
                            $scope.displayConfig.width != vbrCommonUtil.UNAVAILABLE ||
                            $scope.displayConfig.height != vbrCommonUtil.UNAVAILABLE;
                    }
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function createMarginPaddingModelOption(title, icon, model, careAboutParentWrapContent) {
                return {
                    title: title,
                    icon: icon,
                    model: model,
                    careAboutParentWrapContent: careAboutParentWrapContent
                };
            }

            function createWidthOptions() {
                return {
                    careAboutParentWrapContent: false,
                    unitsNormal: ['dp', '%'],
                    unitsParentIsWrapContent: null,
                    required: false,
                    mustBePositive: true,
                    noHigherThan100Percent: true,
                    noHigherThan100PercentCombined: false
                }
            }

            function createHeightOptions() {
                return {
                    careAboutParentWrapContent: true,
                    unitsNormal: ['dp', '%'],
                    unitsParentIsWrapContent: ['dp'],
                    required: false,
                    mustBePositive: true,
                    noHigherThan100Percent: true,
                    noHigherThan100PercentCombined: false
                }
            }

        });

})();

