/**
 * directive for configuring the template content value of button type that has a number of different states
 */
angular.module('acadiamasterApp').directive('templateValueButtonMultiStatesConfig', () => ({
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
        'templateValue.buttonMultiState.config.html',
}));

