import template from './boxOrderDetail.template.html';
(function () {
    angular.module('acadiamasterApp')
        .directive('boxOrderDetail', () => {
            return {
                restrict : 'E',
                scope    : {
                    data : '=',
                },
                template : template,
            };
        });
}());
