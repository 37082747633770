(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('importWizardStackTraceModal', function() {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/importWizard/stackTraceModal/importWizardStackTraceModal.template.html',
                scope: {
                    entityId: '@',
                    entityName: '@',
                    status: '@',
                    errorMessage: '@',
                    stackTrace: '@'
                },
                controller: function($scope) {
                    $scope.closePopup = function() {
                        $scope.$parent.closeThisDialog();
                    };

                    $scope.data = {
                        stackTrace: $scope.stackTrace.split('\n\t')
                    };
                }
            };
        });
})();
