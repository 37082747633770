(function () {
    'use strict';

    /***************************************************************
     * utility for loading program from server, the input object should have the following property and prototype function
     *  -- programId (property, a number)
     *  -- getProgram()
     *  -- setProgram(program)
     ***************************************************************/

    angular.module('acadiamasterApp').factory('ProgramLoadUtil', function (ProgramCacheService, AlertService) {

        return {
            loadProgram: loadProgram
        };

        /**
         * load program from server if needed for the container object
         * @param containerWithProgram - container object that contains a field for program id and function for get/set program
         */
        function loadProgram(containerWithProgram) {
            if (requiredFunctionNotDefined(containerWithProgram)) {
                AlertService.error('container with program is not valid', null, containerWithProgram);
                return;
            }

            var programId = containerWithProgram.programId;
            var program = containerWithProgram.getProgram();

            if (programId == null) {
                // nothing to load
                return;
            }

            if (program != null && program.id === programId) {
                // already loaded, do nothing
                return;
            }

            ProgramCacheService.loadProgramById(programId).then(function(data) {
                containerWithProgram.setProgram(data);
            }).catch(function(error) {
                AlertService.error('failed to load program by id : ' + programId, null, error);
            });
        }

        function requiredFunctionNotDefined(container) {
            return !_.isFunction(container.getProgram) || !_.isFunction(container.setProgram);
        }

    });
})();
