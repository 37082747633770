(function () {
    'use strict';

    /***************************************************************
     * Precondition for program distribution exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionProgramDistributionExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel) {

            PreConditionProgramDistributionExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for programDistribution exist with status
             * @constructor
             */
            function PreConditionProgramDistributionExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.PROGRAM_DISTRIBUTION_EXIST);

                this.programDistributionId = null;
            }

            PreConditionProgramDistributionExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.programDistributionId = dto.programDistributionId;
            };

            PreConditionProgramDistributionExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.programDistributionId = this.programDistributionId;

                return dto;
            };

            PreConditionProgramDistributionExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                return typeMsg + ' <span class="text-warning">deprecated condition, please remove and do not use</span>';
            };

            PreConditionProgramDistributionExistModel.prototype._validateSelf = function () {
                this.clearError();
            };

            return PreConditionProgramDistributionExistModel;
        });
})();
