/**
 * functional condition evaluation service
 */

(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('FuncEvalService', function ($resource, localWeb) {
            return $resource(localWeb.createUrl('/api/functionalConditionEvaluation/eval'), {}, {
                'eval': {
                    method: 'POST',
                    isArray: true
                },
            });
        });

})();
