/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * service for all the operations
     */
        .service('ExpressionService',
            function (RuleUtilService, RuleActionModelService, ModelServiceBase) {
                return {
                    addNewAction: addNewAction,
                    removeAction: removeAction,
                    addExistingAction: addExistingAction,
                    setActionMappingFromModelInExpressionDto: setActionMappingFromModelInExpressionDto,
                    setActionMappingFromDtoToExpressionModel: setActionMappingFromDtoToExpressionModel,
                    cloneExpressionWithAllActions: cloneExpressionWithAllActions,
                    cloneAndAddActionToExpression: cloneAndAddActionToExpression,
                    expressionHasAsyncAction: expressionHasAsyncAction
                };

                function addNewAction(actionType, expression) {
                    var actionHolder = actionType.newInstance(expression);
                    expression.actionHolders.push(actionHolder);
                    expression.getRule().addActionUsage(actionHolder.action);
                }

                function addExistingAction(expression, action) {
                    var existingHolder = RuleUtilService.findActionHolderByCode(expression.actionHolders, action.code);
                    if (existingHolder == null) {
                        var actionHolder = new RuleActionModelService.ActionHolder(expression, action);
                        expression.actionHolders.push(actionHolder);
                        expression.getRule().addActionUsage(actionHolder.action);
                    }

                }

                function removeAction(expression, actionHolder, index) {
                    // Remove action from action list
                    if (index == null) {
                        index = _.findIndex(expression.actionHolders, function (m) {
                            return m.action.code == actionHolder.action.code;
                        });
                    }
                    if (index != null && index >= 0) {
                        expression.actionHolders.splice(index, 1);
                    }
                    expression.getRule().removeActionUsage(actionHolder.action);
                }

                function setActionMappingFromModelInExpressionDto(dto, parentModel, expressionId) {
                    dto.expressionActionMappings = [];
                    _.forEach(parentModel.actionHolders, function (actionHolder, index) {
                        var mappingDto = {
                            stepNumber: index + 1,
                            actionCode: actionHolder.action.code,
                            actionId: actionHolder.action.id,
                            expressionId: expressionId
                        };
                        dto.expressionActionMappings.push(mappingDto);
                    });
                }

                function setActionMappingFromDtoToExpressionModel(expressionModel, dto) {
                    expressionModel.actionHolders = [];

                    var actions = expressionModel.getRule().actions;
                    _.forEach(dto.expressionActionMappings, function (expressionActionMapping) {
                        var action = RuleUtilService.findElementById(actions, expressionActionMapping.actionId);

                        // At the time of cloning the action Id is null and we will not find the action, this step is then skipped\
                        // These things are explicitly set by overriding the custom clone methods
                        if (action != null) {
                            var actionHolder = new RuleActionModelService.ActionHolder(expressionModel, action);

                            expressionModel.actionHolders.push(actionHolder);
                            expressionModel.getRule().addActionUsage(actionHolder.action);
                        }
                    });
                }

                function cloneExpressionWithAllActions(expressionModel) {
                    var newExpression = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(expressionModel);
                    var newActionHolders = [];
                    _.forEach(expressionModel.actionHolders, function (actionHolder) {
                        var newActionHolder = actionHolder.customClone();
                        newActionHolder._parent = newExpression;
                        newActionHolders.push(newActionHolder);
                    });
                    newExpression.actionHolders = newActionHolders;
                    return newExpression;
                }

                function cloneAndAddActionToExpression(expressionModel, actionTobeCloned, selector) {
                    var newActionHolder = actionTobeCloned.customClone();
                    if (newActionHolder != null) {
                        var addAfterIndex = _.findIndex(expressionModel.actionHolders, function (a) {return a == actionTobeCloned;});

                        if (addAfterIndex == null || addAfterIndex >= expressionModel.actionHolders.length - 1) {
                            expressionModel.actionHolders.push(newActionHolder);
                        }
                        else {
                            expressionModel.actionHolders.splice(addAfterIndex + 1, 0, newActionHolder);
                        }

                        if (selector && selector.selectItem) {
                            selector.selectItem(newActionHolder, false, false);
                        }
                    }
                }

                function expressionHasAsyncAction(expressionModel) {
                    // method to check whether current expression has an async action
                    if (expressionModel.actionHolders && expressionModel.actionHolders.length > 0) {
                        for (var i = 0; i < expressionModel.actionHolders.length; i++) {
                            if (expressionModel.actionHolders[i].action.isAsyncAction) {
                                return true;
                            }
                        }
                    }
                    return false;
                }

            });

})();

