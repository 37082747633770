(function () {
    "use strict";
    angular.module("acadiamasterApp").factory("FieldEntryService", function (FormEntryUtilService) {
        function handleFieldName(field, entryNameLengthLimit) {
            var message = '';
            var startSecondText = field.name.indexOf('_');
            var popup = `<div class="long__name__popup">${field.formField.id} | ${field.name} | ${field.formField.type.text}</div>`;

            if (startSecondText) {
                message += ' | <span class="long__name">' + popup + field.name.slice(0, startSecondText + 1) + '...</span>';
            } else {
                message += ' | <span class="long__name">' + popup + field.name.slice(0, entryNameLengthLimit) + '...</span>';
            }
            return message;
        }

        function createListFieldEntry(entryField) {
            const entryValues = entryField.formFieldEntryValues;
            const fieldEntries = [];
            _.forEach(entryValues, function (pv) {
                const minimumDateValue = 500000000;
                const isDate = pv.valueAsNumber != null && pv.valueAsNumber > minimumDateValue;
                fieldEntries.push(FormEntryUtilService.convertFieldEntryValueToHtml(pv, isDate));
            });
            return fieldEntries;
        }

        return { handleFieldName, createListFieldEntry };
    });
})();
