(function () {

    angular.module('acadiamasterApp').controller(
        'taskListController',
        function (
            $scope, $state, entity,CONFIG, ProgramService, UnauthenticatedTaskService, AlertService,FormPreviewService,ngDialog,vbrCommonUtil) {
            const vm = this;
            vm.isReadOnlyServer = CONFIG.readOnlyServer;
            vm.selectedTask = null;
            entity.$promise.then(
                (response) => {
                    vm.entity = response;
                    init($scope);
                },
                (error) => {
                    console.error('Error loading program entity.', error);
                },
            );

            vm.selectTask = (task) => {
                vm.selectedTask = task;
            };
            vm.hasSelectedTask = () => vm.selectedTask != null;
            vm.previewForm = (formId) => {
                const form = {
                    id: formId,
                };
                FormPreviewService.preview(form, null);
            };
            vm.openDeleteDialog = (task) => {
                if(vm.isReadOnlyServer){
                    return;
                }
                ngDialog.openConfirm({
                    className: 'ngdialog-theme-plain custom-width-medium',
                    controller: 'TaskDeleteDialogController',
                    controllerAs: 'tdd',
                    resolve: {
                        task: () => task,
                    },
                    templateUrl: 'admin-templates/site/unauthenticatedTask/deleteTask/taskDeleteDialog.template.html',
                }).then(() => {
                    UnauthenticatedTaskService.deleteTaskById(task.id)
                        .then(() => {
                            getAllTasks();
                        }, (error) => {
                            console.error('Error deleting task', error);
                        });
                });
            };
            /**
             * download / export one task dto by id
             * @param taskId - task id
             */
            vm.download = (taskId) => {
                // get deep link promise
                UnauthenticatedTaskService.getTaskById(taskId)
                    .then(function (response) {
                        // create file name
                        const fileName = `unauthenticated_task_${taskId}.json`;
                        // download as file in browser
                        vbrCommonUtil.downloadAsFile(fileName, response.data);
                    }, function (error) {
                        console.error('Error getting a task from API', error);
                    });
            };
            function getAllTasks() {
                let promise = UnauthenticatedTaskService.getAllTasks(vm.entity.id);
                promise.then(function (response) {
                    vm.unauthenticatedTasks = response.data;
                }, function (error) {
                    AlertService.error("Error while getting the Unauthenticated Tasks", error);
                });
            }
            function init($scope) {
                vm.programId = $scope.programId;
                vm.CONFIG = CONFIG;
                vm.select = false;
                getAllTasks();
            }
        }
    );
}());
