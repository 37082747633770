(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ruleHomeController', function ($scope, TILE_CONSTANTS) {
            $scope.data = {
                tileCollectionId: TILE_CONSTANTS.ID.RULE
            };
        });
})();

