/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('searchFilterNotification', function (SEARCH_FILTER_CONSTANTS) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/searchFilter/notifications/searchFilterNotification.template.html',
                scope: {
                    filter: '=',
                    removeFunction: '=?',
                    isSubfilter: '=?'
                },
                controller: function ($scope) {
                    $scope.cs = SEARCH_FILTER_CONSTANTS;

                    // sometimes, the selected value is an array. In these cases, we need to be
                    // able to show subfilters that match ANY of the array results.
                    $scope.getActiveSubfilters = function (subfilters, values) {
                        let result = [];
                        if(!subfilters || !values) {
                            return result;
                        }

                        if (angular.isArray(values)) {
                            // iterate through active values and determine if any of them should
                            // enable a subfield. Also make sure that there are no duplicates if
                            // more than one option are selected and both have the same subfield.
                            values.forEach(value => {
                                // subfilters matching this value
                                const valueSubfilters = subfilters[value.value];
                                // if any match, handle them individually
                                if(valueSubfilters) {
                                    valueSubfilters.forEach(valueSubfilter => {
                                        // If we aren't already handling this subfilter id, add it
                                        if(!result.some(subfilter => subfilter.id === valueSubfilter.id)) {
                                            result.push(valueSubfilter);
                                        }
                                    });
                                }
                            });
                        } else {
                            result = subfilters[values.value];
                        }
                        return result;
                    }
                }
            };
        });
})();
