/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerSchedule', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.schedule.html',
                scope: {
                    element: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.isReady = false;

                    init($scope);
                }
            };

            function init($scope){
                $scope.data = {
                    dateRange: {
                        start: $scope.element.startAbsoluteTime ,
                        end: $scope.element.endAbsoluteTime
                    },
                    missFireBooleanDescription: 'This boolean configuration is used to decide whether to trigger the rule(once) for past start time or not.<br><br>'
                    + 'For example, <br>'
                    + '&nbsp;&nbsp;Trigger with cron \'run first day of every month from 1 June to 31 Dec\' suppose to fire on first day of every month.<br>'
                    + '&nbsp;&nbsp;&nbsp;1. If an user subscribes on 20th Sep and this boolean is <label>true</label> then system will immediately run the business rule once for passed time and next scheduled rule will run on 1st Oct.<br>'
                    + '&nbsp;&nbsp;&nbsp;2. If this boolean is <label>false</label> then system will trigger only next scheduled execution on 1st Oct and ignore past triggers.'
                };

                $scope.$watch('data.dateRange', function (newVal) {
                    if(newVal == null){
                        $scope.element.startAbsoluteTime = null;
                        $scope.element.endAbsoluteTime = null;
                        return;
                    }

                    // set start timestamp
                    if(newVal.start == null){
                        $scope.element.startAbsoluteTime = null;
                    } else {
                        $scope.element.startAbsoluteTime = newVal.start;
                    }

                    // set end timestamp
                    if(newVal.end == null){
                        $scope.element.endAbsoluteTime = null;
                    } else {
                        $scope.element.endAbsoluteTime = newVal.end;
                    }
                }, true);

                $scope.cronGeneratorConfig = {
                    showTitle: true,
                    title: "Select Recurrence"
                };

                // set flag
                $scope.isReady = true;
            }
        });

})();

