(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultNavigationEdgeService',
        function (CompareResultUtilService) {

            function compareEdgeDetail(edge1, edge2) {
                var result = {
                    localId: CompareResultUtilService.comparePrimitives(edge1.localId, edge2.localId),
                    name: CompareResultUtilService.comparePrimitives(edge1.name, edge2.name),
                    evaluationOrder: CompareResultUtilService.comparePrimitives(edge1.evaluationOrder, edge2.evaluationOrder)
                };
                CompareResultUtilService.compareCodeBlocksByKey(edge1, edge2, 'conditionText', result);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            /**
             * main comparison function to compare two navigation edges
             */
            function compare(edge1, edge2) {
                var title;
                if (edge1 == null) {
                    edge1 = {};
                    title = 'Edge ' + edge2.name;
                }
                else {
                    title = 'Edge ' + edge1.name;
                }

                if (edge2 == null) {
                    edge2 = {};
                }

                var result = {
                    _title : title,
                    _isOpen : true,
                    detail: compareEdgeDetail(edge1, edge2)
                };

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

