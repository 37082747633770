(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('testCasePreviewIcon', function (ngDialog) {
        return {
            restrict: 'E',
            template: '<span  title="Preview Test Case Summary"><span class="glyphicon glyphicon-eye-open" ng-click="showTestPreviewWindow()"></span></span>',
            scope: {
                programTestId: '<'
            },
            link: function ($scope) {

                $scope.showTestPreviewWindow = function () {
                    var programTestId = $scope.programTestId;
                    var template = '<test-case-preview test-case-id="programTestId" close-function="closeFunction"></test-case-preview>';

                    // open modal window and pass in all the scope variables
                    var dialog = ngDialog.open({
                        showClose: false,
                        closeByEscape: false,
                        closeByDocument: false,
                        template: template,
                        plain: true,
                        controller: ['$scope', function ($scope) {
                            $scope.programTestId = programTestId;
                            $scope.closeFunction = closeDialog;
                        }],
                        height: '90%',
                        className: 'ngdialog-theme-plain custom-width-large'
                    });

                    function closeDialog() {
                        dialog.close();
                    }
                };

            }
        };

    });
})();
