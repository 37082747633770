(function () {
    'use strict';
    /**
     * showing the user audits for a specific test
     */
    angular.module('acadiamasterApp').directive('testRunUserAudits', function (AlertService, LoadingStatus, ParseLinks,
                                                                               ProgramTestExecutionUserAuditService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/userAudit/userAudits.html',
            scope: {
                // test execution audit id
                auditId: '=',
            },
            link: function ($scope) {

                $scope.flags = {
                    showDetail: false,
                };

                $scope.data = {
                    loadStatus: LoadingStatus.WAITING,
                    userAudits: [],
                    page: 0,
                    size: 50,
                    totalItemCount: -1
                };

                $scope.links = {
                    first: 0,
                    last: 25,
                    next: 4,
                    prev: 2
                };

                $scope.loadPage = function (page) {
                    $scope.data.page = page == null ? 0 : page;
                    loadPage($scope);
                };

                $scope.downloadAudits = function () {
                    return ProgramTestExecutionUserAuditService.downloadAudits($scope.auditId)
                        .then(function (response) {
                            var a = document.createElement('a');
                            var file = new Blob([response.data], {type: response.contentType});
                            a.href = URL.createObjectURL(file);
                            a.download = 'ProgramTestUserAudit.csv';
                            a.click();
                        });
                };

                $scope.toggleData = function() {
                    var shouldLoadData = !$scope.flags.showDetail;

                    // need to toggle this first before calling init, otherwise, it will be ignored
                    $scope.flags.showDetail = !$scope.flags.showDetail;

                    if (shouldLoadData) {
                        // trying to show data, load data if needed
                        init($scope);
                    }

                };

                $scope.refresh = function() {
                    refresh($scope);
                };

                init($scope);
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/

        /**
         * initialization function
         */
        function init($scope) {
            resetData($scope.data);
            resetLinks($scope.links);

            refresh($scope);
        }

        function refresh($scope) {
            if ($scope.flags.showDetail) {
                loadPage($scope);
            }
            else {
                refreshCountOnly($scope);
            }
        }

        /**
         * refresh the results, either fresh count
         * @param $scope
         */
        function refreshCountOnly($scope) {
            if ($scope.auditId!=null){
                ProgramTestExecutionUserAuditService.countAudits($scope.auditId)
                    .then(function (response) {
                        $scope.data.totalItemCount = response.data;
                    })
                    .catch(function (error) {
                        AlertService.error('Unable to load counts for user audit data, check console log for more detail');
                        console.error('Unable to load counts for user audit data', error);
                    });
            }
        }

        /**
         * reset the data object with page, size, loading status, etc
         * @param data
         */
        function resetData(data) {
            data.loadStatus = LoadingStatus.WAITING;
            data.userAudits = [];
            data.page = 0;
            data.size = 50;
            data.totalItemCount = -1;
        }

        function resetLinks(links) {
            links.first = -1;
            links.last = -1;
            links.next = -1;
            links.prev = -1;
        }

        /**
         * loading by the current data
         * @param $scope
         */
        function loadPage($scope) {
            // todo: load page here
            $scope.data.loadStatus = LoadingStatus.LOADING;
            ProgramTestExecutionUserAuditService.listAudits($scope.auditId, $scope.data.page, $scope.data.size)
                .then(function (response) {
                    $scope.data.loadStatus = LoadingStatus.LOAD_COMPLETE;
                    $scope.links = ParseLinks.parse(response.headers('link'));
                    $scope.data.totalItemCount = Number.parseInt(response.headers('X-Total-Count'));
                    $scope.data.userAudits = response.data;
                })
                .catch(function (error) {
                    $scope.data.loadStatus = LoadingStatus.LOAD_FAILED;
                    AlertService.error('Unable to load a page of user audit data, check console log for more detail');
                    console.error('Unable to load a page of user audit data', error);
                });
        }

    });

})();



