angular.module('acadiamasterApp')
    .config((localWebProvider, httpRequestInterceptorCacheBusterProvider,
        tmhDynamicLocaleProvider, $translateProvider, $httpProvider, $windowProvider,
        ImageCacheProvider, toastrConfig, $rootScopeProvider, ngDialogProvider,
        FlowChartTemplatePathProvider, localStorageServiceProvider) => {
        // sets the base url for the server
        // TODO: remove localWebProvider
        localWebProvider.setServerIp('');
        localWebProvider.setDefaultState('home');
        localWebProvider.setIsAdmin(true);

        // Cache everything except rest api requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([ /.*api.*/, /.*protected.*/, /.*shared-utilities.*/, /.*external-log.*/, /.*form-entry-log.*/ ], true);

        // set up for translations
        tmhDynamicLocaleProvider.localeLocationPattern(
            'assets/i18n/scripts/angular-locale_{{locale}}.js',
        );
        tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate : 'assets/i18n/{lang}/{part}.json',
        });
        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

        // initiate interceptors
        $httpProvider.interceptors.push('errorHandlerInterceptor');
        // $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('authInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');
        $httpProvider.interceptors.push('networkInterceptor');

        // using ng-image-cache
        ImageCacheProvider.setStorage($windowProvider.$get().localStorage);

        // configure angular-toastr
        angular.extend(toastrConfig, {
            allowHtml             : true,
            autoDismiss           : false,
            containerId           : 'toast-container',
            extendedTimeOut       : 2000,
            maxOpened             : 0,
            newestOnTop           : true,
            positionClass         : 'toast-top-right',
            preventDuplicates     : false,
            preventOpenDuplicates : true,
            progressBar           : true,
            target                : 'body',
            timeOut               : 5000,
        });

        // set ngDialog defaults
        ngDialogProvider.setDefaults({
            className         : 'ngdialog-theme-default',
            closeByNavigation : true,
        });

        // view slider with full control require us to increase the digest cycle limit from
        // 10 to 15. Its UI is too complex with too many watches to finish in 10 cycles
        $rootScopeProvider.digestTtl(15);

        FlowChartTemplatePathProvider.setNodeTemplatePath(
            'admin-templates/site/forms/configPanel/navigation/fc.node.html',
        );
        FlowChartTemplatePathProvider.setCanvasTemplatePath(
            'admin-templates/site/forms/configPanel/navigation/fc.canvas.html',
        );

        // secure localStorage
        localStorageServiceProvider.setStorageCookie(30, '/', true);
    });
