/**
 * creating a html string that contains a span based on the boolean span info object
 * @param booleanSpanInfoObject -- it object includes the following fields
 * 1. valueToCheck -- boolean variable to check the value
 * 2. faIconOnTrue -- fa icon name if boolean variable is true
 * 3. faIconOnNotTrue -- fa icon name if boolean variable is not true (ie: false or null, etc)
 * 4. titleTextOnTrue -- title text on icon if boolean variable is true
 * 5. titleTextOnNotTrue -- title text on if boolean value is not true
 * @return {string} - html string with the span and icon
 */
function createBooleanSpan(booleanSpanInfoObject) {
    if (booleanSpanInfoObject == null) {
        return '';
    }

    const {valueToCheck, faIconOnTrue, faIconOnNotTrue, titleTextOnTrue, titleTextOnNotTrue} = booleanSpanInfoObject;

    const faIconClass = valueToCheck ? faIconOnTrue : faIconOnNotTrue;
    const hoverText = valueToCheck ? titleTextOnTrue : titleTextOnNotTrue;

    return `<span class="fa ${faIconClass}" title="${hoverText}"></span>`;
}

/**
 * creating a html string that contains a span based on the id span info object
 * @param idSpanInfoObject -- it object includes the following fields
 * 1. id -- number to be displayed inside the badge
 * 2. badgeClass -- badge class used to style the number
 * 3. hoverText -- title text on the number
 * @return {string} - html string with the span and icon
 */
function createIdSpan(idSpanInfoObject) {
    if (idSpanInfoObject == null) {
        return '';
    }

    const {id, badgeClass, hoverText} = idSpanInfoObject;

    return `<span class="${badgeClass}" title="${hoverText}">${id}</span>`;
}


export default {
    createBooleanSpan,
    createIdSpan,
};
