(function () {
    'use strict';

    /**
     * script action to update font style to bold if font size is 14
     */
    angular.module('acadiamasterApp').factory('UpdateFonts14AddBoldScriptAction', function (ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        // defining some local variable to make things easier
        var fsa = FormScriptActionUtil;
        var constants = FormConstants;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateFonts14AddBoldScriptAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateFonts14AddBoldScriptAction() {
            this.name = 'Update Font Style to bold for all text where size = 14';
            this.ticketId = 'AC-24073';

            this.steps = [
                {
                    description: 'find text field by font size 14/null and text-style not bold --> ' + 'text-style : bold',
                    searchFunction: function (formMode) {
                        return searchByFontSize(formMode, 14, constants.fieldsType.TEXT_LABEL);
                    },
                    changeFunction: applyFontToBold
                },
                {
                    description: 'find title sub-field field by font size 14/null and text-style not bold --> ' + 'text-style : bold',
                    searchFunction: function (formMode) {
                        return searchByFontSize(formMode, 14, constants.subFieldsType.TITLE);
                    },
                    changeFunction: applyFontToBold
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /**
         * apply font style to bold for the target element
         * @param target - target
         */
        function applyFontToBold(target) {
            if (target && target.displayConfig) {
                fsa.updateFontStyle(target.displayConfig, false, false, true, false);
            }
        }

        /**
         * search by font size and object type
         * ps. there is also an implied condition of filtering out objects that already has
         * font style equal bold
         * @param formMode - form mode to search from
         * @param fontSizeToMatch - font size to match, if it's null, then means to match for inherit
         * @param objectType - object type, TITLE or TEXT_LABEL
         */
        function searchByFontSize(formMode, fontSizeToMatch, objectType) {
            var targets = null;

            if (objectType == constants.fieldsType.TEXT_LABEL) {
                targets = fsa.findFieldsByType(formMode, objectType);
            }
            else if (objectType == constants.subFieldsType.TITLE) {
                targets = fsa.findSubFieldsByType(formMode, objectType);
            }
            else {
                console.warn('invalid object type found, can\'t do anything');
                return null;
            }

            if (targets != null) {
                // try to match font size
                targets = _.filter(targets, function (f) {
                    return fsa.isFontSizeEqual(f.displayConfig, fontSizeToMatch);
                });

                // filter out object that's already has font style = bold
                targets = _.filter(targets, function (f) {
                    return !fsa.isFontStyleBold(f.displayConfig);
                });
            }

            return targets;
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateFonts14AddBoldScriptAction;
    });

})();
