'use strict';

angular.module('acadiamasterApp')
    .controller('PropertyController', function ($scope, Property, PropertySearch, ParseLinks, DataTypeMasterRecordCount, CONFIG, vbrCommonUtil, ngDialog) {
        $scope.CONFIG = CONFIG;
        $scope.properties = [];

        function refresh() {
            $scope.loadAll();
            $scope.clear();
        }

        $scope.searchData = {
            ascending: true,
            searchString: "",
            orderByChoices: ['ID', 'Name', 'Description'],
            orderBySelected: 'Name',
            pageSizeChoices: [20, 50, 100],
            pageSizeSelected: 20,
            page: 0,
        };

        $scope.loadAll = function () {
            PropertySearch.get($scope.searchData)
                .then(function (result) {
                    $scope.links = ParseLinks.parse(result.headers('link'));
                    $scope.properties = result.data;
                });
        };
        $scope.loadPage = function (page) {
            $scope.searchData.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            Property.get({ id: id }, function (result) {
                $scope.property = result;
                $('#deletePropertyConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            Property.delete({ id: id },
                function () {
                    $scope.loadAll();
                    DataTypeMasterRecordCount.updateCounts();
                    $('#deletePropertyConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            refresh();
        };

        $scope.clear = function () {
            $scope.property = { name: null, description: null, createdOn: null, updatedOn: null, isDeleted: null, valueType: null, id: null };
        };

        $scope.download = function (id) {
            var property = Property.get({ id: id });
            property.$promise.then(function (data) {
                var fileName = 'property_' + id + '.json';
                vbrCommonUtil.downloadAsFile(fileName, data);
            });
        };

        $scope.importProperty = function () {
            ngDialog.openConfirm({
                template: '<upload-to-configuration-server title="Import Property" ws-url="/api/configuration/property/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                plain: true,
                size: 'md',
                className: 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        refresh();
                    }
                }]
            }).then(function () {
                $scope.refresh();
            });
        };

    });
