(function () {
    'use strict';

    /**
     * status type enum for program test running status
     */
    angular.module('acadiamasterApp').constant('TestExecutionStatus',
        {
            /* initial status before and during pre condition evaluation */
            STARTING: 'STARTING',

            /* status after pre-condition evaluation */
            FAILED_IN_PRE_CONDITION: 'FAILED_IN_PRE_CONDITION',

            /* evaluation of the IF_ASSERT_CONDITION */
            RUNNING_IF_ASSERT_EVALUATION: 'RUNNING_IF_ASSERT_EVALUATION', // processing the if and assert evaluation
            FAILED_IF_ASSERT_EVALUATION: 'FAILED_IF_ASSERT_EVALUATION',

            /* after evaluation of if_assert */
            // if and assert condition completed with every user in the system passed the test
            COMPLETED_WITH_NO_VIOLATION: 'COMPLETED_WITH_NO_VIOLATION',
            // no corrective action applied
            COMPLETED_NO_CORRECTIVE_ACTION_APPLIED: 'COMPLETED_NO_CORRECTIVE_ACTION_APPLIED',

            /* Running corrective action */
            RUNNING_CORRECTIVE_ACTION: 'RUNNING_CORRECTIVE_ACTION',

            /* after corrective action */
            COMPLETED_CORRECTIVE_ACTION_APPLIED: 'COMPLETED_CORRECTIVE_ACTION_APPLIED',
            FAILED_CORRECTIVE_ACTION: 'FAILED_CORRECTIVE_ACTION',

            /* cancelled by user in the middle of the operation, can happen any step */
            CANCELLED: 'CANCELLED'
        });
})();
