/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for config a text input
     */
        .directive('formConfigTextInput', function (LocaleConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/textInputCommon.html',
                scope: {
                    title: '@',
                    name: '@?',
                    hintText: '@',
                    inputType: '@',
                    includeHelp: '=',
                    inputModel: '=',
                    changeModel: '=',
                    changeModelLocalizationMap: '=',
                    localizationMap: '=',
                    localizationKey: '@',
                    maxLength: '@?', //  this only works for text and text-area, not for rich-text-area
                    disabled: '=',
                    isRequired: '=',
                    parentForm: '=',
                    skipOuterBox: '='
                },
                link: function ($scope) {
                    $scope.hasChangeModel = $scope.changeModel !== undefined;
                    $scope.lcs = LocaleConstantsService;

                    if ($scope.hintText == null) {
                        $scope.hintText = '';
                    }

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        inputModel: $scope.inputModel,
                        changeModel: $scope.changeModel,
                        makingChange: $scope.hasChangeModel && $scope.changeModel != null
                    };

                    $scope.$watch('data.inputModel', function (newValue) {
                        $scope.inputModel = newValue;
                    });

                    $scope.$watch('data.changeModel', function (newValue) {
                        $scope.changeModel = newValue;
                    });


                    switch ($scope.inputType) {
                    case 'text':
                        $scope.localizationInputType = $scope.lcs.inputType.TEXT;
                        break;

                    case 'textArea':
                        $scope.localizationInputType = $scope.lcs.inputType.TEXT_AREA;
                        break;

                    case 'richTextArea':
                        $scope.localizationInputType = $scope.lcs.inputType.RICH_TEXT_EDITOR;
                        break;
                    default:
                        console.log('invalid input type');
                    }


                    $scope.resetChange = function () {
                        $scope.data.makingChange = false;
                        $scope.data.changeModel = null;
                    };

                }
            };
        });

})();

