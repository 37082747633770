angular.module('acadiamasterApp')

    /**
     * service to store all the constants for Condition Usage Type
     */
    .factory('ExpressionConditionConstantsService', () => {

        const usageTypes = {
            BUSINESS_RULE: 'BUSINESS_RULE',
            EXPRESSION_CONDITION: 'EXPRESSION_CONDITION',
            PTF: 'PTF',
        };


        return {
            usageTypes: usageTypes
        };
    });
