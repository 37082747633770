/**
 * Created by Danny on 4/29/2016.
 */
'use strict';

angular.module('acadiamasterApp')
    .factory('BusinessRule', function ($resource, localWeb, Upload, $http, $q) {
        var ruleResource = $resource(localWeb.createUrl('api/businessRules/:id'), {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = data != null && data.length > 0 ? angular.fromJson(data) : null;
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'manualTrigger': {
                url: localWeb.createUrl('api/businessRules/trigger'),
                method: 'POST'
            },
            'testEmail': {
                url: localWeb.createUrl('api/businessRules/testEmailAction'),
                method: 'POST'
            }

        });

        ruleResource.activateDeactivate = (id, enabled) => {
            return $http({
                method            : 'PUT',
                transformResponse : function (data) {
                    return JSON.stringify(data);
                },
                url : localWeb.createUrl(`/api/businessRules/toggleStatus/${id}/${enabled}`),
            });
        };

        ruleResource.saveOrUpdate = function (files, data, onSaveFinished) {
            // send them all together for HTML5 browsers:
            Upload.upload({
                url: localWeb.createUrl('api/businessRules/'),
                arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                data: {
                    file: files,
                    data: JSON.stringify(data)
                }
            }).then(function (resp) {
                if (_.isFunction(onSaveFinished)) {
                    onSaveFinished(resp);
                }
            });
        };

        return ruleResource;
    })

    .factory('BusinessRuleExecutionAudit', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/businessRules/audit/:id'), {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
        });
    });
