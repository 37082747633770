(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('participantRuleExecutions', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/participant/profile/experience/ruleExecution/participantRuleExecutions.template.html',
                scope: {
                    userId: '<'
                }
            };
        });
})();
