'use strict';

(function () {
    angular.module('acadiamasterApp')
        .controller('ImportZipCodesController', function ($scope, vbrCommonUtil, AlertService, DonationSiteService) {

            var zipCodesLabel = 'zip codes';
            $scope.csv = vbrCommonUtil.defaultCSVConfig();

            function parseCsv() {
                var uploadedZipCodesList = null;
                if(!$scope.csv.result || $scope.csv.result.length===0){
                    AlertService.warning('The uploaded CSV file is empty');
                    return uploadedZipCodesList;
                }else{
                    uploadedZipCodesList = [];
                    var invalidZipFound = false;
                    _.forEach($scope.csv.result, function (object) {
                        if(! isNaN(object[zipCodesLabel])) {
                            object[zipCodesLabel] && uploadedZipCodesList.push(object[zipCodesLabel].trim());
                        } else {
                            if(!invalidZipFound) {
                                AlertService.warning('Invalid zip code found: ' + object[zipCodesLabel] + '. Only numbers are allowed');
                                invalidZipFound = true;
                            }
                            uploadedZipCodesList = null;
                            return null;
                        }
                    });
                    if(uploadedZipCodesList && uploadedZipCodesList.length === 0){
                        AlertService.warning('No data available');
                        uploadedZipCodesList = null;
                    }
                }

                return uploadedZipCodesList;
            }

            $scope.uploadZipCodes = function () {
                var uploadedZipCodesList = parseCsv();
                if(uploadedZipCodesList){
                    DonationSiteService.uploadZipCodes(uploadedZipCodesList).then(
                    function onUploadSuccess(response) {
                        AlertService.success('Zip codes uploaded successfully');
                        $scope.closeThisDialog();
                        $scope.loadAll();
                    },
                    function onUploadFail(error) {
                        AlertService.warning(error, 'Error');
                    });
                }
            };
        });
})();
