
angular.module('acadiamasterApp')
    .config(($stateProvider, StringManagementConstants, DeepLinkConstants) => {
        function loadTranslatePartial ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('program');
            $translatePartialLoader.addPart('dynamicPage');
            $translatePartialLoader.addPart('programImportWizard');
            $translatePartialLoader.addPart('form');
            $translatePartialLoader.addPart('businessRule');
            $translatePartialLoader.addPart('deepLinks');
            $translatePartialLoader.addPart('unauthenticatedTasks');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
        }

        $stateProvider
            .state('program', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.home.title',
                },
                parent  : 'programManagement',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('agreements');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('form');
                        return $translate.refresh();
                    } ],
                },
                url   : '/info',
                views : {
                    'content@' : {
                        controller  : 'ProgramController',
                        templateUrl : 'admin-templates/site/programManagement/program/programs.html',
                    },
                },
            })
            .state('program.detail', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.programDetail.title',
                },
                parent  : 'program',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('programDetail');
                        return $translate.refresh();
                    } ],
                },
                url   : '/view/{id}',
                views : {
                    'content@' : {
                        controller  : 'ProgramDetailPageController',
                        templateUrl : 'admin-templates/site/programManagement/program/programDetail/programDetailPage.html',
                    },
                },
            })
            .state('program.manage', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.manage.title',
                },
                parent  : 'program',
                resolve : {
                    entity : [ '$stateParams', 'ProgramService',
                        ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('dynamicPage');
                        return $translate.refresh();
                    } ],
                },
                url   : '/manage/{id}',
                views : {
                    'content@' : {
                        controller  : 'ManageProgramController',
                        templateUrl : 'admin-templates/site/programManagement/program/manageProgram.html',
                    },
                },
            })
            .state('program.manage.new', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'acadiamasterApp.program.new.title',
                },
                parent  : 'program',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/new',
                views : {
                    'content@' : {
                        template : '<program-config-basic></program-config-basic>',
                    },
                },
            })
            .state('program.manage.edit', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.edit.title',
                },
                parent  : 'program.manage',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/edit',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-config-basic program-id="{{programId}}"></program-config-basic>',
                    },
                },
            })
            .state('program.manage.containers', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.container.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity : [ '$stateParams', 'ProgramService',
                        ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }),
                    ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('containers');
                        return $translate.refresh();
                    } ],
                },
                url   : '/containers',
                views : {
                    'content@' : {
                        controller   : 'ContainerMgmtController',
                        controllerAs : 'cmc',
                        templateUrl  : 'admin-templates/site/programManagement/program/containerManagement/containerMgmt.template.html',
                    },
                },
            })
            .state('program.manage.containers.recalculatePersonalized', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.container.recalculatePersonalized.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity : [ '$stateParams', 'ProgramService',
                        ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }),
                    ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        $translatePartialLoader.addPart('containers');
                        return $translate.refresh();
                    } ],
                },
                url   : '/containers/recalculatePersonalized',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<container-recalculate-personalized program-id="{{programId}}"></container-recalculate-personalized>',
                    },
                },
            })
            .state('program.manage.consentForm', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.detail.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                 : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader',
                        ($translate, $translatePartialLoader) => {
                            $translatePartialLoader.addPart('program');
                            return $translate.refresh();
                        },
                    ],
                },
                url   : '/consentForm',
                views : {
                    'content@' : {
                        controller ($scope, entity) {
                            $scope.program = entity;
                        },
                        template : '<program-consent-form-association program="program"></program-consent-form-association>',
                    },
                },
            })
            .state('program.manage.deepLinks', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.deepLinks.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                 : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('deepLinks');
                        return $translate.refresh();
                    } ],
                },
                url   : '/links',
                views : {
                    'content@' : {
                        controller ($scope, entity) {
                            $scope.entity = entity;
                        },
                        template : `<deep-links entity="entity"></deep-links>`,
                    },
                },
            })

            .state('program.manage.deepLinks.addEditConfig', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.deepLinks.title',
                },
                parent  : 'program.manage',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('deepLinks');
                        return $translate.refresh();
                    } ],
                },
                url   : '/config',
                views : {
                    'content@' : {
                        controller   : 'ConfigFilesAddEditController',
                        controllerAs : 'cfc',
                        templateUrl  : 'admin-templates/site/deepLinks/addEditConfig/configFilesAddEdit.template.html',
                    },
                },
            })

            .state('program.manage.deepLinks.edit', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'deepLinks.edit-title',
                },
                parent  : 'program.manage.deepLinks',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('deepLinks');
                        return $translate.refresh();
                    } ],
                },
                url   : '/{linkId}',
                views : {
                    'content@' : {
                        controller   : 'DeepLinkEditController',
                        controllerAs : 'dle',
                        templateUrl  : 'admin-templates/site/deepLinks/editPage/deepLinkEdit.template.html',
                    },
                },
            })

            .state('program.manage.sms', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.sms.title',
                },
                parent  : 'program.manage',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/sms',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-sms-enable program-id="{{programId}}"></program-sms-enable>',
                    },
                },
            })

            .state('program.manage.strings', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'stringManagement.title.page',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                     : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('stringManagement');
                        $translatePartialLoader.addPart('features');
                        return $translate.refresh();
                    } ],
                },
                url   : '/strings',
                views : {
                    'content@' : {
                        controller ($scope, entity) {
                            $scope.entity = entity;
                        },
                        template : `<string-management level="${
                            StringManagementConstants.LEVEL.PROGRAM
                        }" entity="entity" is-server-string="false"></string-management>`,
                    },
                },
            })

            .state('program.manage.server', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'stringManagement.title.server.page',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                     : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    mainTranslatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('stringManagement');
                        $translatePartialLoader.addPart('features');
                        return $translate.refresh();
                    } ],
                },
                url   : '/stringsServer',
                views : {
                    'content@' : {
                        controller ($scope, entity) {
                            $scope.entity = entity;
                        },
                        template : `<string-management level="${
                            StringManagementConstants.LEVEL.PROGRAM
                        }" entity="entity" is-server-string="true"></string-management>`,
                    },
                },
            })

            .state('program.manage.exportWizard', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'Program Export Wizard',
                },
                parent  : 'program.manage',
                resolve : {
                    entity   : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    security : [ 'ConfigService', 'CONFIG', '$state', '$stateParams', '$q',
                        (ConfigService, CONFIG, $state, $stateParams, $q) => {
                            if (Object.hasOwnProperty.call(CONFIG, 'configurationServer')) {
                                // config already exists
                                if (CONFIG.configurationServer) {
                                    return $q.resolve();
                                }
                                return $q.reject();
                            }
                            // config has not loaded, so return a new promise
                            const deferred = $q.defer();
                            ConfigService.init().then(() => {
                                if (CONFIG.configurationServer) {
                                    deferred.resolve();
                                } else {
                                    $state.go('program.manage', { id : $stateParams.id });
                                    deferred.reject();
                                }
                            }, error => {
                                console.error(error);
                                deferred.reject();
                            });
                            return deferred.promise;
                        } ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('stringManagement');
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    } ],
                },
                url   : '/exportWizard',
                views : {
                    'content@' : {
                        controller  : 'ExportWizardController',
                        templateUrl : 'admin-templates/site/programManagement/program/exportWizard/exportWizard.html',
                    },
                },
            })

            .state('program.manage.importWizard', {
                data : {
                    authorities : [ 'ROLE_ADMIN' ],
                    pageTitle   : 'Program Import Wizard',
                },
                parent  : 'program.manage',
                resolve : {
                    entity   : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    security : [ 'ConfigService', 'CONFIG', '$state', '$stateParams', '$q',
                        (ConfigService, CONFIG, $state, $stateParams, $q) => {
                            if (Object.hasOwnProperty.call(CONFIG, 'readOnlyServer')) {
                                // config already exists
                                if (CONFIG.readOnlyServer) {
                                    return $q.resolve();
                                }
                                return $q.reject();
                            }
                            // config has not loaded, so return a new promise
                            const deferred = $q.defer();
                            ConfigService.init().then(() => {
                                if (CONFIG.readOnlyServer) {
                                    deferred.resolve();
                                } else {
                                    $state.go('program.manage', { id : $stateParams.id });
                                    deferred.reject();
                                }
                            }, error => {
                                console.error(error);
                                deferred.reject();
                            });
                            return deferred.promise;
                        } ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader) ],
                },
                url   : '/importWizard',
                views : {
                    'content@' : {
                        controller  : 'ImportWizardController',
                        templateUrl : 'admin-templates/site/programManagement/program/importWizard/importWizard.html',
                    },
                },
            })
            .state('program.manage.programBranding', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.branding.title',
                },
                parent  : 'program.manage',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/programBranding',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-branding-config program-id="{{programId}}"></program-branding-config>',
                    },
                },
            })
            .state('program.manage.programAppSettings', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.appSetting.title',
                },
                parent  : 'program.manage',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/appSettings',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-app-setting-config program-id="{{programId}}"></program-app-setting-config>',
                    },
                },
            })
            .state('program.manage.programAppSettings.iosAssets', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.appSetting.iosAssets.title',
                },
                parent  : 'program.manage.programAppSettings',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('distributionType');
                        return $translate.refresh();
                    } ],
                },
                url   : '/ios/{id}',
                views : {
                    'content@' : {
                        controller  : 'ProgramIosMobileAssetsController',
                        templateUrl : 'admin-templates/site/programManagement/program/appSettings/program.iosMobileAssets.html',
                    },
                },
            })
            .state('program.manage.programAppSettings.androidAssets', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.appSetting.androidAssets.title',
                },
                parent  : 'program.manage.programAppSettings',
                resolve : {
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('distributionType');
                        return $translate.refresh();
                    } ],

                },
                url : '/android/{id}',

                views : {
                    'content@' : {
                        controller  : 'ProgramAndroidAssetsController',
                        templateUrl : 'admin-templates/site/programManagement/program/appSettings/program.androidAssets.html',
                    },
                },
            })
            .state('program.manage.communicationCategory', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.detail.title',
                },
                parent  : 'program.manage',
                resolve : {
                    program : [ '$stateParams', 'ProgramService', function ($stateParams, ProgramService) {
                        return ProgramService.get({ id : $stateParams.id });
                    } ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('programManagement');
                        $translatePartialLoader.addPart('communicationCategory');
                        return $translate.refresh();
                    } ],
                },
                url   : '/communicationCategory',
                views : {
                    'content@' : {
                        controller ($scope, program) {
                            $scope.program = program;
                        },
                        template : '<program-communication-category-association program="program"></program-communication-category-association>',
                    },
                },
            })
            .state('program.manage.tabs', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.detail.title',
                },
                parent  : 'program.manage',
                resolve : {
                    program : [ '$stateParams', 'ProgramService', function ($stateParams, ProgramService) {
                        return ProgramService.get({ id : $stateParams.id });
                    } ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('programManagement');
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/tabs',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-tabs-config program-id="{{programId}}"></program-tabs-config>',
                    },
                },
            })
            .state('program.manage.social', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.detail.title',
                },
                parent  : 'program.manage',
                resolve : {
                    program : [ '$stateParams', 'ProgramService', function ($stateParams, ProgramService) {
                        return ProgramService.get({ id : $stateParams.id });
                    } ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('programManagement');
                        $translatePartialLoader.addPart('program');
                        return $translate.refresh();
                    } ],
                },
                url   : '/social',
                views : {
                    'content@' : {
                        controller ($scope, $stateParams) {
                            $scope.programId = $stateParams.id;
                        },
                        template : '<program-social-config program-id="{{programId}}"></program-social-config>',
                    },
                },
            })
            .state('program.manage.dpLinking', {
                data : {
                    authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ],
                    pageTitle   : 'acadiamasterApp.program.dpLinking.title',
                },
                parent  : 'program.manage',
                resolve : {
                    entity                 : [ '$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id : $stateParams.id }) ],
                    translatePartialLoader : [ '$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('containerDpLinks');
                        return $translate.refresh();
                    } ],
                },
                url   : '/dpLinking',
                views : {
                    'content@' : {
                        controller ($scope, entity) {
                            $scope.entity = entity;
                        },
                        template : '<program-dp-linking entity="entity"></program-dp-linking>',
                    },
                },
            })
        ;
    });
