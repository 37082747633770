/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp')
        .directive('vbrAgreementSearch', (ngDialog, AgreementsService, $stateParams, AlertService, $document) => {
            return {
                link (scope) {
                    scope.search = {
                        agreements : [],
                    };
                    scope.searchAgreement = function () {
                        const params = {
                            programId : scope.options.programId != null ? scope.options.programId : $stateParams.id,
                        };
                        AgreementsService.loadAgreements(params).then(response => {
                            if (response == null || response.data == null
                                || response.status !== 200) {
                                AlertService.error(
                                    `Failed to load agreements data for Program Id: ${params.programId}`,
                                );
                                return;
                            }
                            scope.search.agreements = response.data;
                            if (response.data.length === 0) {
                                AlertService.warning('No agreements were found');
                            }
                        }, error => {
                            console.error('Error loading agreements', error);
                        }).finally(() => {
                            // this is throwing an error - there is no data property on scope.
                            // scope.data.isLoading = false;
                        });

                        ngDialog.openConfirm({
                            className  : 'ngdialog-theme-plain custom-width-large',
                            controller : [ '$scope', function ($scope) {
                                $scope.search = scope.search;
                                $scope.data = scope.data;
                                $scope.controls = scope.controls;
                            } ],
                            template : 'agreementSearchDialog',
                        });
                    };

                    scope.isShowOnlyButton = function () {
                        return scope.showOnlyButton;
                    };

                    scope.hasTitle = function () {
                        return scope.options && scope.options.title && scope.options.title.length > 0;
                    };

                    scope.controls = {
                        clearAgreement : function () {
                            scope.options.agreement = null;
                            completeChange(scope.options);
                        },
                        isAgreementSelectable : function (agreement) {
                            if (scope.canSelectFunc == null) {
                                // if no can select function was passed into the directive, then assume
                                // every form is selectable
                                return true;
                            }

                            return scope.canSelectFunc(agreement);
                        },
                        parent          : scope,
                        selectAgreement : function (agreement) {
                            return selectAgreement(this.parent, agreement);
                        },
                    };
                },
                restrict : 'E',
                scope    : {
                    // flag indicate if we want to allow multiple search or not
                    allowMultiple : '=',

                    // a optional function to call to check if a form can be selected
                    canSelectFunc : '=',

                    options        : '=',
                    showOnlyButton : '=',
                },
                templateUrl : 'admin-templates/site/agreements/agreementSearch.html',
            };

            /*
             * complete the change action for form selection or deletion
             * @param options - options object that holds the form and the on change call back function
             */
            function completeChange (options) {
                if (options && _.isFunction(options.onChangeCallBack)) {
                    options.onChangeCallBack();
                }
            }

            function selectAgreement (scope, agreement) {
                scope.options.agreement = agreement;
                completeChange(scope.options);
                if (!scope.allowMultiple) {
                    ngDialog.close();
                }
            }
        });
}());
