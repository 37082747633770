

angular.module('acadiamasterApp')
    .config(($stateProvider) => {
        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('expressionCondition');
            return $translate.refresh();
        }

        $stateProvider
            .state('expressionCondition', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'expressionConditions.title',
                },
                parent: 'program.manage',
                resolve: {
                    entity: ['$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id: $stateParams.id })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/expression',
                views: {
                    'content@': {
                        bindToController: true,
                        controller: 'expressionConditionListController',
                        controllerAs: 'exc',
                        templateUrl: 'admin-templates/site/expressionCondition/listPage/expressionConditionList.template.html',
                    },
                },
            })

            .state('expressionCondition.new', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'expressionConditions.title',
                },
                parent: 'expressionCondition',
                resolve: {
                    entity: ['$stateParams', 'ProgramService', ($stateParams, ProgramService) => ProgramService.get({ id: $stateParams.id })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('programTest');
                        loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                },
                url: '/new',
                views: {
                    'content@': {
                        template: `<expression-condition-config is-new="true"
                                state-after-save="expressionCondition"
                                state-after-cancel="expressionCondition">
                            </expression-condition-config>`,
                    },
                },
            })

            .state('expressionCondition.edit', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'expressionConditions.title',
                },
                parent: 'expressionCondition',
                resolve: {
                    entity: ['ExpressionConditionService', '$stateParams',
                        (ExpressionConditionService, $stateParams) => ExpressionConditionService.getConditionById($stateParams.expressionId)],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('programTest');
                        loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                },
                url: '/edit/{expressionId}',
                views: {
                    'content@': {
                        controller: ($scope, entity) => {
                            $scope.dto = entity.data;
                        },
                        template: `<expression-condition-config is-new="false"
                                condition="dto"
                                state-after-save="expressionCondition"
                                state-after-cancel="expressionCondition">
                            </expression-condition-config>`,
                    },
                },
            })

            .state('expressionCondition.clone', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'expressionConditions.title',
                },
                parent: 'expressionCondition',
                resolve: {
                    entity: ['ExpressionConditionService', '$stateParams',
                        (ExpressionConditionService, $stateParams) => ExpressionConditionService.getConditionById($stateParams.expressionId)],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('programTest');
                        loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                },
                url: '/clone/{expressionId}',
                views: {
                    'content@': {
                        controller: ($scope, entity) => {
                            $scope.dto = entity.data;
                        },
                        template: `<expression-condition-config is-new="false"
                                is-cloned="true"
                                condition="dto"
                                state-after-save="expressionCondition"
                                state-after-cancel="expressionCondition">
                            </expression-condition-config>`,
                    },
                },
            })

            .state('expressionCondition.details', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'expressionConditions.details.title',
                },
                parent: 'expressionCondition',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => loadTranslatePartial($translate, $translatePartialLoader)],
                },
                url: '/details/:expressionId',
                views: {
                    'content@': {
                        bindToController: true,
                        controller: 'ExpressionConditionDetailsController',
                        controllerAs: 'edc',
                        templateUrl: 'admin-templates/site/expressionCondition/detailPage/expressionConditionDetails.template.html',

                    },
                },
            });
    });
