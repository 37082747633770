/**
 * Created by Jamie Nola on 02/13/2019
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for string value model
     */
        .service('StringResourceValueModelService',
            function (StringManagementService) {
                /***************************************************************
                 * String Model
                 ***************************************************************/
                function StringResourceValueModel(keyName, programId, locale) {
                    this.isCustom = false;
                    this.value = {
                        id: null,
                        keyName: keyName,
                        programId: programId,
                        locale: locale,
                        hasHtml: null,
                        value: {
                            default: null,
                            web: null,
                            mobile: null
                        },
                        use: {
                            default: null,
                            web: null,
                            mobile: null
                        }
                    };
                    this.tempValue = angular.copy(this.value);
                }

                /**
                 * convert a dto into a model that the UI can use
                 */
                StringResourceValueModel.prototype.fromDto = function (dto) {
                    this.value = {
                        id: dto.id,
                        keyName: dto ? dto.keyName : this.value.keyName,
                        programId: dto ? dto.programId : this.value.programId,
                        locale: dto ? dto.locale : this.value.locale,
                        hasHtml: dto.hasHtml,
                        value: {
                            default: dto.defaultValue,
                            web: dto.webValue,
                            mobile: dto.mobileValue,
                        },
                        use: {
                            default: dto.useDefault,
                            web: dto.useWeb,
                            mobile: dto.useMobile
                        }
                    };

                    var useDefault = dto.useDefault && dto.programId !== null;
                    this.isCustom = useDefault || dto.useWeb || dto.useMobile;
                    this.resetTempValue();
                };

                /**
                 * convert the current temp UI model to dto format
                 */
                StringResourceValueModel.prototype.toDto = function () {
                    var temp = this.tempValue;
                    var dto = {
                        id: temp.id,
                        keyName: temp.keyName,
                        programId: temp.programId,
                        locale: temp.locale,
                        hasHtml: temp.hasHtml,
                        defaultValue: temp.use.default ? temp.value.default : '',
                        webValue: temp.use.web ? temp.value.web : '',
                        mobileValue: temp.use.mobile ? temp.value.mobile : '',
                        useDefault: temp.use.default,
                        useWeb: temp.use.web,
                        useMobile: temp.use.mobile
                    }
                    return dto;
                };

                /**
                 * Save the string's values to the API
                 */
                StringResourceValueModel.prototype.save = function () {
                    return StringManagementService.saveString(this.toDto())
                        .then(function (response) {
                            this.fromDto(response.data);
                        }.bind(this), function (error) {
                            console.error('Error saving string values', error);
                        });
                };

                StringResourceValueModel.prototype.resetTempValue = function () {
                    this.tempValue = angular.copy(this.value);
                };

                StringResourceValueModel.prototype.revertToSystemValue = function () {
                    this.tempValue.use.default = false;
                    this.tempValue.use.web = false;
                    this.tempValue.use.mobile = false;
                };

                /***************************************
                 * service return call
                 ***************************************/
                return {
                    StringResourceValueModel: StringResourceValueModel
                };
            });
})();
