(function () {
    angular.module('acadiamasterApp')
        .directive('fieldDeviceDataConfig', (ExpressionConstantsService, FieldDeviceDataMappingService, FormConstants, filterFieldsUtil) => {
            function init ($scope) {
                $scope.addFieldDeviceMap = false;
            }

            function updateMappingList ($scope, index) {
                if ($scope.addFieldDeviceMap === true) {
                    $scope.mappingList.push($scope.param);
                } else {
                    $scope.mappingList.splice(index, 1);
                }
            }

            return {
                link : function ($scope) {
                    $scope.expConstants = ExpressionConstantsService;
                    init($scope);
                    $scope.formTargetType = FormConstants.formTargetType.FORM_FIELD;
                    $scope.filterFieldsUtil = filterFieldsUtil;
                    $scope.editMode = $scope.page.findParentMode()._parent.editMode;
                    $scope.addMapping = function () {
                        $scope.param = new FieldDeviceDataMappingService.FieldDeviceDataMappingModel();
                        $scope.addFieldDeviceMap = true;
                        updateMappingList($scope);
                    };

                    $scope.removeParameter = function (index) {
                        $scope.addFieldDeviceMap = false;
                        updateMappingList($scope, index);
                    };
                },
                restrict : 'E',
                scope    : {
                    data        : '=',
                    mappingList : '=',
                    page        : '=',
                    parentForm  : '=',
                },
                templateUrl : 'admin-templates/site/forms/configPanel/dataCapture/fieldDeviceData.config.html',
            };
        });
}());
