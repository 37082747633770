(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('addressSourceTargetConfiguration', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/addressVerification/addressSourceTargetConfig.html',
                scope: {
                    parentForm: '=',
                    page: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                }
            };
        });

})();


