(function () {
    'use strict';


    angular.module('acadiamasterApp').directive('correctiveActionUnauthenticatedTask', function () {
        return {
            templateUrl : 'admin-templates/site/programTests/programTest/directive/correctiveActionDirective/correctiveActionUnauthenticatedTask.html',
            restrict : 'E',
            scope : {
                action : '<',
            },
            link: function ($scope) {
                $scope.taskOptions = {
                    getTask   : () => $scope.action.getTask(),
                    setTask   : task => $scope.action.setTask(task),
                    programId : $scope.action.getProgramId(),
                };
            },
        };
    });
})();
