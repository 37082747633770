(function () {
    angular.module('acadiamasterApp')

        .controller('AppVersioningController', ($scope, AppVersioningService, ngDialog, ParseLinks) => {
            $scope.appVersionList = null;

            function initController () {
                $scope.orderByChoices = {
                    id   : 'id',
                    name : 'name',
                };

                $scope.page = 0;
                $scope.searchData = {
                    ascending    : false,
                    orderBy      : $scope.orderByChoices.id,
                    os           : $scope.osType,
                    page         : $scope.page,
                    searchString : null,
                    size         : 20,
                };
                // show default list with pagination.
                $scope.loadAll();
            }

            function prepareRequestData () {
                if ($scope.searchData.searchString) {
                    $scope.searchData.page = 0;
                    $scope.page = 0;
                } else {
                    $scope.searchData.page = $scope.page;
                }
                $scope.searchData.os = $scope.osType;
            }

            $scope.loadAll = function () {
                prepareRequestData();
                AppVersioningService.getAppVersions($scope.searchData)
                    .then(response => {
                        $scope.appVersionList = response.data;
                        $scope.links = ParseLinks.parse(response.headers('link'));
                        $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                    });
            };

            $scope.setOrderBy = function (newOrderBy) {
                if ($scope.searchData.orderBy === newOrderBy) {
                    $scope.searchData.ascending = !$scope.searchData.ascending;
                } else {
                    $scope.searchData.ascending = true;
                    $scope.searchData.orderBy = newOrderBy;
                }
                // Reset pagination.
                $scope.searchData.page = 0;
                $scope.searchData.size = 20;
                $scope.loadAll();
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.osTypeChanged = function () {
                $scope.page = 0;
                $scope.loadAll();
            };

            $scope.openAppVersionModal = function (id) {
                let modalInstance = ngDialog.open({
                    controller : 'AppVersioningDetailsController',
                    resolve    : {
                        id : function () {
                            return id;
                        },
                    },
                    template : 'admin-templates/site/appVersioning/appVersioningDetails.html',
                    width    : '50%',
                });

                modalInstance.closePromise.then(() => {
                    $scope.loadAll();
                });
            };

            $scope.openAppVersionViewModal = function (id) {
                let modalInstance = ngDialog.open({
                    controller : 'AppVersioningDetailsController',
                    resolve    : {
                        id : function () {
                            return id;
                        },
                    },
                    template : 'admin-templates/site/appVersioning/appVersioningViewDetails.html',
                    width    : '50%',
                });

                modalInstance.closePromise.then(() => {
                    $scope.loadAll();
                });
            };

            $scope.confirmDeleteAppVersion = function (appVersion) {
                let deleteConformDialog = ngDialog.open({
                    template : 'admin-templates/site/appVersioning/deleteAppVersionConfirmModal.html',
                });

                deleteConformDialog.closePromise
                    .then(response => {
                        // eslint-disable-next-line eqeqeq
                        if (response.value == true) {
                            // eslint-disable-next-line no-use-before-define
                            deleteAppVersion(appVersion.id);
                        }
                    });
            };

            function deleteAppVersion (id) {
                AppVersioningService.deleteAppVersionById(id)
                    .then(() => {
                        $scope.loadAll();
                    },
                    );
            }

            initController();
        });
}());
