(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for HasBannerByTypeCondition model
     */
    app.factory('HasBannerByTypeCondition', function (QObjectModel, ProgramTestConstantsService, RuleConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        HasBannerByTypeCondition.inheritsFrom(QObjectModel);

        function HasBannerByTypeCondition(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.HAS_BANNER_NOTIFICATION_BY_TYPE);

            //initialized the variables
            this.bannerType = RuleConstantsService.bannerType.APPOINTMENT_SCHEDULED;
            this.bannerStatus = ProgramTestConstantsService.BannerStatus.ALL_BANNERS;
        }

        /**
         * convert the current UI model to dto format
         */
        HasBannerByTypeCondition.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.bannerType = this.bannerType == null ? null : this.bannerType.name;
            dto.bannerStatus = this.bannerStatus;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        HasBannerByTypeCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.bannerType = ProgramTestConstantsService.getObjectByName(RuleConstantsService.bannerType,
                dto.bannerType);
            this.bannerStatus = dto.bannerStatus;
        };

        HasBannerByTypeCondition.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var typeMsg = '<span class="badge badge-info">' + (this.bannerType==null ? '' : this.bannerType.name)
                + ' | ' + this.bannerStatus + '</span>';

            return baseMsg + ' has a banner notification with type ' + typeMsg;
        };

        HasBannerByTypeCondition.prototype._validateSelf = function () {
            this.clearError();

            if (this.bannerType == null) {
                this.setErrorMessage('banner type is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return HasBannerByTypeCondition;

    });
})();
