(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one program communication category mapping
     */
        .directive('treeNodeProgramCommunicationCategoryMapping', function (ProgramCommunicationCategoryConstantService,ngDialog,CommunicationCategoryModelService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/communicationCategoryAssociation/treeNode/programCommunicationCatMapping.html',
                scope: {
                    programCommunicationCategoryMapping: '=',
                    programCommunicationCategoryMappingsList : '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.cs = ProgramCommunicationCategoryConstantService;
                    $scope.communicationCategory=CommunicationCategoryModelService.convertDtoToCommunicationCategoryModel($scope.programCommunicationCategoryMapping.communicationCategory);


                    $scope.openDeleteConfirmationWindow = function(){
                        var mapping = $scope.programCommunicationCategoryMapping;
                        var mappings = $scope.programCommunicationCategoryMappingsList;
                        var selector=$scope.selector;
                        $scope.previewWindow = ngDialog.openConfirm({
                            templateUrl: 'admin-templates/site/programManagement/program/communicationCategoryAssociation/programCommunicationCategoryMapping-delete.html',
                            controller: ['$scope', function ($scope) {
                                $scope.mapping = mapping;
                                $scope.selector=selector;
                                $scope.delete = function () {
                                    var index = mappings.indexOf(mapping);
                                    mappings.splice(index, 1);
                                    if (selector!=null) {
                                        selector.itemRemoved(mapping);
                                    }
                                    $scope.confirm();
                                };
                            }]
                        });
                    };
                }
            };

        });
})();
