/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /*
     Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
     They are written in English to avoid character encoding issues (not a perfect solution)
     */
        .constant('LANGUAGES', [
            'en', 'es', 'zh', 'fr', 'ar',
            //JHipster will add new languages here
        ]);
})();

