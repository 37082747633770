/**
 * @author Jason.Cao on 07/11/2017
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('formImportConfirmation', (ngDialog, $window, $state, FormEditImportService, FormModelServiceForm, $location,
                                              QualtricsSurveyParser, AlertService, RedcapSurveyParser, CommonParserUtilService, VibrentFormSkippingLogicService, FormConstants, RedcapZipImportService) => {
            return {
                link : function ($scope) {
                    // list iof
                    let importFormFromEntity = FormConstants.importFormFromEntity;

                    $scope.formTypes = FormConstants.formTypes;
                    $scope.formCategories = FormConstants.formCategories;
                    $scope.qualtricsSurveyParser = QualtricsSurveyParser;
                    $scope.redcapSurveyParser = RedcapSurveyParser;
                    $scope.commonParser = CommonParserUtilService;
                    $scope.data = {
                        englishFile              : null,
                        isQualtricsInfoCollapsed : true,
                        isREDCapInfoCollapsed    : true,
                        isSkipLogicIncluded      : false,
                        isVibrentorInfoCollapsed : true,
                        spanishFile              : null,
                    };
                    $scope.processing = false;
                    $scope.importFormFromEntity = importFormFromEntity;
                    $scope.importSource = importFormFromEntity.VIBRENT;
                    $scope.closePopup = function () {
                        ngDialog.close();
                    };

                    $scope.defaultSelection = 'Choose Program';
                    $scope.selectedProgramCode = $scope.defaultSelection;
                    $scope.selectedProgram = {};
                    $scope.isEnabled = false;
                    $scope.selectedFormType = $scope.formTypes.SYSTEM;
                    $scope.formDetails = {};

                    $scope.checkFormType = function () {
                        if ($scope.selectedFormType === $scope.formTypes.PROGRAM) {
                            return $scope.selectedProgramCode === $scope.defaultSelection;
                        }
                        return false;
                    };

                    $scope.$watch('selectedFormType', (newValue, oldValue) => {
                        if (newValue === oldValue) {
                            return;
                        }
                        if (newValue === $scope.formTypes.SYSTEM) {
                            $scope.isEnabled = false;
                            if ($scope.selectedProgram) {
                                $scope.selectedProgram = {};
                                $scope.selectedProgramCode = $scope.defaultSelection;
                            }
                        } else {
                            $scope.isEnabled = true;
                        }
                    });

                    $scope.importRedCapZip = function () {
                        const redcapRequestDto = {
                            formCategoryString : $scope.category,
                            importSkipLogic    : true,
                            programId          : $scope.selectedProgram.programId,
                        };
                        RedcapZipImportService.importZipFile($scope.data, redcapRequestDto).then(
                            response => {
                                AlertService.info('REDCap zip is imported. <br> Saving the form');
                                $scope.processing = true;
                                RedcapZipImportService.saveZipFileData(response.data, resp => {
                                    if (resp) {
                                        $scope.closePopup();
                                    }
                                    $scope.processing = false;
                                }, error => {
                                    $scope.processing = false;
                                });
                            });
                    };

                    $scope.importFileNow = function () {
                        if ($scope.importSource === importFormFromEntity.REDCAP_ZIP) {
                            $scope.importRedCapZip();
                            return;
                        }
                        const englishReader = new $window.FileReader();
                        const spanishReader = new $window.FileReader();
                        const englishFileNameExtension = $scope.data.englishFile.name.split('.').pop();
                        let spanishFileNameExtension = null;
                        if ($scope.data.spanishFile) {
                            spanishFileNameExtension = $scope.data.spanishFile.name.split('.').pop();
                        }

                        if ($scope.importSource === importFormFromEntity.VIBRENT && englishFileNameExtension !== 'json') {
                            AlertService.warning('Only ".json" file is accepted to import form from Vibrent');
                            return;
                        } else if ($scope.importSource === importFormFromEntity.QUALTRICS && englishFileNameExtension !== 'qsf') {
                            AlertService.warning('Only ".qsf" file is accepted to import form from Qualtrics');
                            return;
                        } else if ($scope.importSource === importFormFromEntity.REDCAP && englishFileNameExtension !== 'json') {
                            AlertService.warning('Only ".json" file is accepted to import English form from REDCap');
                            return;
                        } else if ($scope.importSource === importFormFromEntity.REDCAP && $scope.data.spanishFile && spanishFileNameExtension !== 'json') {
                            AlertService.warning('Only ".json" file is accepted to import Spanish form from REDCap');
                            return;
                        }
                        let importFrom = $scope.importSource;

                        let englishJsonData = null;
                        let spanishJsonData = null;
                        englishReader.onload = function () {
                            englishJsonData = englishReader.result;
                            if (englishJsonData == null || englishJsonData.length === 0) {
                                AlertService.warning('Uploaded file is empty. <br>Please upload valid file');
                                return;
                            } else if (typeof englishJsonData !== 'string') {
                                AlertService.warning('Uploaded file content is not valid JSON string. <br>Please upload valid file.');
                                return;
                            }

                            try {
                                englishJsonData = JSON.parse(englishJsonData);
                            } catch (error) {
                                AlertService.warning('Uploaded file content is not valid JSON string. <br>Please upload valid file.');
                                return;
                            }

                            // read spanish file if it exists and call onsuccess with english data + spanish data
                            if ($scope.data.spanishFile) {
                                spanishReader.readAsText($scope.data.spanishFile);
                            } else {
                                //  if there is no spanish file to parse then call on success with english json only
                                onSuccess(englishJsonData, null, importFrom);
                            }
                        };

                        spanishReader.onload = function () {
                            spanishJsonData = spanishReader.result;
                            if (spanishJsonData == null || spanishJsonData.length === 0) {
                                AlertService.warning('Uploaded file is empty. <br>Please upload valid file');
                                return;
                            } else if (typeof spanishJsonData !== 'string') {
                                AlertService.warning('Uploaded file content is not valid JSON string. <br>Please upload valid file.');
                                return;
                            }

                            try {
                                spanishJsonData = JSON.parse(spanishJsonData);
                            } catch (error) {
                                AlertService.warning('Uploaded file content is not valid JSON string. <br>Please upload valid file.');
                            }
                            onSuccess(englishJsonData, spanishJsonData, importFrom);
                        };

                        //  we always want to read the english json file
                        englishReader.readAsText($scope.data.englishFile);
                    };

                    function modifyUploadData (data) {
                        if ($scope.selectedFormType === $scope.formTypes.PROGRAM) {
                            data.programId = $scope.selectedProgram.programId;
                            data.programCode = $scope.selectedProgramCode;
                        } else {
                            data.programId = null;
                            data.programCode = null;
                        }
                        return data;
                    }

                    function onSuccess (englishJsonData, spanishJsonData, importFrom) {
                        $scope.processing = true;
                        if (importFrom === importFormFromEntity.VIBRENT) {
                            // here jsonData is Form configuration json
                            const modifiedEnglishJsonData = modifyUploadData(englishJsonData);
                            FormEditImportService.performInEditImport(modifiedEnglishJsonData, true, () => {
                                $state.go(getPreviousState());
                                $scope.processing = false;
                                $scope.closePopup();
                            });
                        } else if (importFrom === importFormFromEntity.QUALTRICS) {
                            // here jsonData is Qualtrics survey(.qsf) exported from Qualtrics website
                            // parse qualtrics survey to form configuration object(i.e. FormDTO)
                            QualtricsSurveyParser.setSkipLogic($scope.data.isSkipLogicIncluded);
                            const parsedEnglishJsonData = QualtricsSurveyParser.parseToVibrentForm(englishJsonData);
                            if (parsedEnglishJsonData == null) {
                                AlertService.error('Couldn\'t parse Qualtrics survey. <br>Please upload valid Qualtrics format.');
                                $scope.processing = false;
                                return;
                            }
                            let dto = modifyUploadData(parsedEnglishJsonData);
                            dto.category = $scope.category || null;
                            let formModel = new FormModelServiceForm.FormModel(null, null, null);
                            formModel.fromDto(dto);
                            $scope.processing = false;
                            if ($scope.data.isSkipLogicIncluded) {
                                VibrentFormSkippingLogicService.saveSkippingLogic(formModel, resp => {
                                    AlertService.success('Qualtric form imported along with skipping logic');
                                    $scope.closePopup();
                                    let formDTO = resp.data; $state.go(`${getPreviousState()}.edit`, { id : formDTO.id, versionId : formDTO.formVersions[0].id });
                                });
                                return;
                            }
                            VibrentFormSkippingLogicService.saveForm(formModel, resp => {
                                AlertService.success('Qualtrics form imported');
                                $scope.closePopup();
                                let formDTO = resp.data;
                                $state.go(`${getPreviousState()}.edit`, { id : formDTO.id, versionId : formDTO.formVersions[0].id });
                            });
                            // $scope.$parent.closeThisDialog(formModel);
                        } else if (importFrom === importFormFromEntity.REDCAP) {
                            if (spanishJsonData) {
                                createLanguageObject(englishJsonData, spanishJsonData);
                            }
                            RedcapSurveyParser.setSkipLogic($scope.data.isSkipLogicIncluded);
                            const parsedEnglishJsonData = RedcapSurveyParser.parseToVibrentForm(englishJsonData);
                            if (parsedEnglishJsonData == null) {
                                AlertService.error('Couldn\'t parse REDCap survey. <br>Please upload valid REDCap format.');
                                $scope.processing = false;
                                return;
                            }
                            let dto = modifyUploadData(parsedEnglishJsonData);
                            dto.category = $scope.category || null;
                            let formModel = new FormModelServiceForm.FormModel(null, null, null);
                            formModel.fromDto(dto);
                            formModel.isSkipLogicIncluded = $scope.data.isSkipLogicIncluded;
                            $scope.processing = false;
                            if ($scope.data.isSkipLogicIncluded) {
                                const errorList = RedcapSurveyParser.getSkipLogicErrorList();
                                if (errorList && errorList.length > 0) {
                                    _.forEach(errorList, error => {
                                        const questionId = error.fieldName;
                                        const branchingLogic = error.logic;
                                        AlertService.warning(`REDCap form imported with error on question id ${questionId} with`
                                            + `skipping logic ${branchingLogic}`);
                                    });
                                }
                                VibrentFormSkippingLogicService.saveSkippingLogic(formModel, resp => {
                                    AlertService.success('REDCap form imported along with skipping logic');
                                    $scope.closePopup();
                                    let formDTO = resp.data;
                                    $state.go(`${getPreviousState()}.edit`, { id : formDTO.id, versionId : formDTO.formVersions[0].id });
                                });
                                return;
                            }
                            VibrentFormSkippingLogicService.saveForm(formModel, resp => {
                                AlertService.success('REDCap form imported');
                                $scope.closePopup();
                                let formDTO = resp.data;
                                $state.go(`${getPreviousState()}.edit`, { id : formDTO.id, versionId : formDTO.formVersions[0].id });
                            });
                            // $scope.$parent.closeThisDialog(formModel);

                            AlertService.success('REDCap form imported');
                        }
                    }

                    /**
                     * Helper method to create Spanish language field in each English survey object
                     * If an English question does not have a Spanish translation, we log field_name to the console and move on
                     * If there are any Spanish questions that do not exist in the English list, they are ignored and not logged anywhere
                     * @param englishJson
                     * @param spanishJson
                     */
                    function createLanguageObject (englishJson, spanishJson) {
                        //  iterate over spanishJson + create map of field name, survey object
                        let spanishFieldNameInputMap = {};
                        _.forEach(spanishJson, surveyObject => {
                            const fieldName = CommonParserUtilService.getValueFromObject(surveyObject, 'field_name');
                            spanishFieldNameInputMap[fieldName] = surveyObject;
                        });
                        //  flag to indicate if any Spanish values corresponding with the English file were found
                        //  we want to alert the user at the end, if a Spanish file was uploaded but no Spanish values were imported.
                        let noSpanishValues = true;
                        //  iterate over englishJson + create language and ES field if there is a Spanish translation
                        _.forEach(englishJson, surveyObject => {
                            const fieldName = CommonParserUtilService.getValueFromObject(surveyObject, 'field_name');
                            if (spanishFieldNameInputMap.hasOwnProperty(fieldName)) {
                                surveyObject.language = { ES : spanishFieldNameInputMap[fieldName] };
                                noSpanishValues = false;
                            } else {
                                surveyObject.language = null;
                                //  we found an English question but no Spanish translation
                                console.log('WARNING: No Spanish translation found for question %s', fieldName);
                            }
                        });
                        if (noSpanishValues) {
                            AlertService.warning(`No Spanish values were imported from ${ $scope.data.spanishFile.name}`);
                        }
                    }
                    function getPreviousState () {
                        let url = $location.absUrl(); let previousState = url.split('/').pop(); return previousState === 'formsVersionTwo' ? 'formVersionTwo' : 'form';
                    }
                },
                restrict : 'E',
                scope    : {
                    category : '@',
                    title    : '@',
                },
                templateUrl : 'admin-templates/site/forms/form/formImportConfirmation.html',
            };
        });
}());
