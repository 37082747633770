import template from './tooltip.html';
(function () {
    angular.module('acadiamasterApp')
        .directive('tooltipCommon', () => {
            return {
                restrict : 'E',
                scope    : {
                    content      : '=',
                    styleHeader  : '=',
                    titleTooltip : '=',
                },
                template : template,
            };
        });
}());
