angular.module('acadiamasterApp').factory('ProgramVXPService', function (localWeb, $http) {
    const baseUrl = localWeb.createUrl('api/programVXP');

    function replayProgramDetail(programId, includeTenant) {
        var finalUrl = baseUrl + '/replayProgramDetail/' + programId;
        if (includeTenant != null) {
            finalUrl += "?includeTenant=" + includeTenant;
        }
        return $http.get(finalUrl);
    }

    return {
        replayProgramDetail
    };
});
