'use strict';

angular.module('acadiamasterApp')
    .factory('DataTypePropertyValueConversion', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/dataTypePropertyValueConversions/:id'), {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    });
