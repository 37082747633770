/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * program consent container association directive
     */
        .directive('programConsentFormAssociation', function (ProgramAssociationService, FormAssociationUtil, AlertService,
                                                              $state, CONFIG, FormPreviewService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/consentFormAssociation/programConsentFormAssociation.html',
                scope: {
                    program: '='
                },
                link: function ($scope) {
                    $scope.CONFIG=CONFIG;
                    $scope.FormPreviewService = FormPreviewService;
                    $scope.save = function () {
                        ProgramAssociationService.associateConsentForm($scope.program.id, $scope.consentFormId).then(function (response) {
                            var result = response.data;
                            $scope.$emit('acadiamasterApp:programUpdate', result);
                            $state.go('program.manage', {'id':$scope.program.id});
                        });
                    };

                    $scope.searchSupportData = FormAssociationUtil.searchSupportData();

                    $scope.searchData = {
                        searchString: "",
                        searchResultCount: "loading ...",
                        orderBySelected: 'name',
                        ascending: true,
                        pageSize: 200,
                        forms: [],
                        formSearchType:$scope.searchSupportData.formSearchTypes.ALL_BASE_CONSENT_FORMS
                    };

                    $scope.currentSelected = null;

                    // States for create/edit controller
                    $scope.states = {
                        formsReady: false,
                        programReady: false
                    };

                    $scope.isFormAssociated = function (form) {
                        return $scope.consentFormId == form.id;
                    };

                    $scope.selectForm = function (form) {
                        $scope.currentSelected = form;
                    };

                    $scope.removeFormAssociation = function () {
                        $scope.consentFormId = null;
                        $scope.consentForm = null;
                    };

                    $scope.addFormAssociation = function (form) {
                        $scope.consentFormId = form.id;
                        $scope.consentForm = form;
                    };

                    $scope.isFormSelected = function (form) {
                        return $scope.currentSelected != null && $scope.currentSelected.id == form.id;
                    };

                    $scope.searchForms = function () {
                        FormAssociationUtil.searchForms($scope);
                    };

                    init($scope);
                }
            };


            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                if ($scope.program.$promise) {
                    $scope.program.$promise.then(function (data) {
                        $scope.program = data;
                        $scope.consentFormId = $scope.program.consentForm != null ? $scope.program.consentForm.id : null;
                        $scope.consentForm = $scope.program.consentForm;
                        $scope.states.programReady = true;
                        FormAssociationUtil.searchForms($scope);
                    });
                }
            }
        });
})();

