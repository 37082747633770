/**
 * @author pravin.gayal on 22-05-2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('IconModelService', ['ModelServiceBase', function (ModelServiceBase) {

            /**************************************
             *  Model definition/construction
             ***************************************/
            IconModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function IconModel(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
                this.id = null;
                this.iconName = null;
                this.iconUrl = null;
                this.iconSize = null;
                this.aspectRatio = null;

                // variable to hold icon
                this.icon = null;
            }

            IconModel.prototype.toDto = function (files) {
                if (this.icon == null && this.iconUrl == null) {
                    // return null icon if icon file and icon url both are null
                    return null;
                }
                var dto = {};
                dto.id = this.id;
                dto.iconName = this.iconName;
                dto.iconUrl = this.iconUrl;
                dto.iconSize = this.iconSize;
                dto.aspectRatio = this.aspectRatio;

                if (this.icon != null) {
                    dto.iconName = this.icon.name;
                    dto.iconSize = this.icon.size;
                    files.push(this.icon);
                }

                return dto;
            };

            IconModel.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.iconName = dto.iconName;
                this.iconUrl = dto.iconUrl;
                this.iconSize = dto.iconSize;
                this.aspectRatio = dto.aspectRatio;
            };

            /***************************************
             * Private functions
             ***************************************/


            /***************************************
             * service return call
             ***************************************/

            return {
                IconModel: IconModel
            };

        }]);
})();