(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('UpdateRichTextFontSizesAction', function (
        ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        UpdateRichTextFontSizesAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateRichTextFontSizesAction() {
            this.name = 'Update rich text font sizes from old values to newer values';
            this.ticketId = 'AC-36152';
            this.steps = [
                {
                    description: 'find all rich text fields that have string like font-size configured in old style and change it to new style<br>' +
                        'Medium --> 12pt<br>'+
                        'Large --> 14pt<br>'+
                        'X-large --> 18pt<br>',
                    searchFunction: function (formMode) {
                        var targets = FormScriptActionUtil.findFieldsByType(formMode, FormConstants.fieldsType.RICH_TEXT_LABEL);

                        targets = _.filter(targets, function (s) {
                            return containsFontValue(s.fieldValue);
                        });

                        return targets;
                    },
                    changeFunction: applyFontChanges
                },
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        function applyFontChanges(target) {
            target.fieldValue.value = _.replace(target.fieldValue.value, new RegExp('font-size: medium', 'g'), 'font-size: 12pt');
            target.fieldValue.value = _.replace(target.fieldValue.value, new RegExp('font-size: large', 'g'), 'font-size: 14pt');
            target.fieldValue.value = _.replace(target.fieldValue.value, new RegExp('font-size: x-large', 'g'), 'font-size: 18pt');
        }

        function containsFontValue(fieldValue) {
            return _.includes(fieldValue.value,'font-size: medium') ||
                _.includes(fieldValue.value,'font-size: large') ||
                _.includes(fieldValue.value,'font-size: x-large');
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateRichTextFontSizesAction;
    });

})();
