(function () {
    'use strict';
    /**
     * showing the counts of the a specific test
     */
    angular.module('acadiamasterApp').directive('testRunCount', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/testRun/testRunCount.html',
            scope: {
                // status object with various counts inside
                statusObject: '=',
                // boolean to indicate if we are actively monitoring the counts
                isMonitoring : '='
            },
            link: function ($scope) {
                $scope.data = {
                    totalItems : getTotalItems($scope.statusObject),
                    totalCompleted : getTotalCompleted($scope.statusObject)
                };

                $scope.hasFailedId = function() {
                    return $scope.statusObject && $scope.statusObject.countFailedCorrectiveAction > 0;
                };

                $scope.getFailedCount = function() {
                    return $scope.hasFailedId() ? $scope.statusObject.countFailedCorrectiveAction : 0;
                };

                $scope.shouldShowProgressBar = function() {
                    return $scope.isMonitoring && $scope.statusObject.countBeforeCorrectiveAction > 0;
                };

                $scope.getPercentageComplete = function () {
                    var d = $scope.data;
                    return Math.floor(d.totalCompleted * 100 / d.totalItems) + '%';
                };

                $scope.$watch("statusObject", function(statusObject) {
                    $scope.data.totalItems = getTotalItems(statusObject);
                    $scope.data.totalCompleted = getTotalCompleted(statusObject);
                });
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/

        function getTotalItems(statusObject) {
            if (statusObject && statusObject.countBeforeCorrectiveAction) {
                return statusObject.countBeforeCorrectiveAction * 2;
            }
            return 0;
        }

        function getTotalCompleted(statusObject) {
            if (statusObject && statusObject.countSucceededCorrectiveAction) {
                var successCount = statusObject.countSucceededCorrectiveAction<0
                    ? 0
                    : statusObject.countSucceededCorrectiveAction;
                return successCount + statusObject.countUserAudits;
            }

            return 0;
        }

    });

})();



