import template from './participantSumaryDetails.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('participantSummaryDetails', function (ParticipantProfileService, AlertService, ngDialog) {
        let participantSummaryDetailsController = function () {
            let vm = this;
            vm.columns = [
                { headerName : 'Field Name', keyValue : 'fieldName', sortable : true, type : 'text' },
                { headerName : 'Value', keyValue : 'fieldValue', sortable : true, type : 'text' },
                { headerName : 'Updated On', keyValue : 'updatedOn', sortable : true, tdClass : 'updatedDate', type : 'date' },
            ];

            vm.page = 0;
            vm.filterData = {
                fieldSortOrder         : 'asc',
                jumpTo            : null,
                fieldSortBy   : 'fieldName',
                pageSizeChoices   : [ 20, 50, 100 ],
                pageSizeSelected  : 20,
                searchResultCount : 0,
            };

            function parseLink (data) {
                return {
                    first : 0,
                    last  : Math.floor(data.total / vm.filterData.pageSizeSelected),
                    next  : data.page + 1,
                    prev  : data.page - 1,
                };
            }

            /*
             * Sorts the table by the provided keyValue
             */
            vm.setOrderBy = function (keyValue) {
                vm.filterData.fieldSortOrder = vm.filterData.fieldSortOrder === 'asc' ? 'desc': 'asc';
                vm.filterData.fieldSortBy = keyValue;
                vm.loadParticipantSummaryDetails();
            };

            function setupParticipant (participants) {
                let models = participants.map(field => {
                    let model = {};

                    model.fieldName = field.fieldName;
                    model.fieldValue = field.fieldValue;
                    model.updatedOn = field.updatedOn;
                    return model;
                });
               return models;
            }

            vm.loadParticipantSummaryDetails = () => {
                let params = {
                    fieldSortOrder     : vm.filterData.fieldSortOrder,
                    fieldSortBy       : vm.filterData.fieldSortBy,
                    pageNumber    : vm.page,
                    pageSize      : vm.filterData.pageSizeSelected,
                    userIds       : vm.userId,
                    includePayload : true,
                };
                ParticipantProfileService.getParticipantSummaryDetails(params)
                    .then(response => {
                        let participantSummary = response.data.entries[0];
                        vm.participantcount = response.data.entries.length;
                        if(vm.participantcount > 0){
                             vm.lastModifiedDate = participantSummary.lastModifiedDate;
                             vm.updatedOn = participantSummary.updatedOn;
                             vm.Participant = setupParticipant(participantSummary.fields);
                             vm.links = parseLink(response.data);
                             vm.bloodStatus = participantSummary.bloodSampleReceived;
                             vm.salivaStatus = participantSummary.salivaSampleReceived;
                             vm.completeData = participantSummary.completeData;
                        }
                    }).catch(() => {
                        AlertService.error('Failed to retrieve getParticipant Summary Details for given user');
                    });
            };

            vm.syncWithDRC = () => {
                let data = {
                    userIds : [vm.userId],
                }
                ParticipantProfileService.getSyncWithDRCData(data)
                  .then(() => {
                    AlertService.success('Sync job has started: Participant summary data for this user is being synced with DRC. It might take few minutes to complete.');
                  }).catch(() => {
                    AlertService.error('Sync job has failed. Oops! There was a problem syncing your data. Please try again by clicking Sync with DRC button.');
                });

            };

            vm.viewJSON = () => {
                ngDialog.open({
                    className    : 'ngdialog-theme-plain custom-width-large viewjson-dialog',
                    controller   : [ function () {
                        let vjd = this;
                        vjd.completeData = vm.completeData;
                        vjd.copyJSONContent = () =>{
                            let jsonData = document.getElementById('json-data');
                                let range = document.createRange();
                                range.selectNodeContents(jsonData);
                                window.getSelection().removeAllRanges();
                                window.getSelection().addRange(range);
                                document.execCommand('copy');
                        };
                        vjd.countLine = () => {
                            var contentDiv = document.getElementById("json-data");
                            var lineHeight = window.getComputedStyle(contentDiv).lineHeight;
                            var height = contentDiv.clientHeight;
                            var lineCount = Math.round(height / parseFloat(lineHeight));
                            return lineCount;
                         }
                    }],
                    controllerAs : 'vjd',
                    templateUrl : 'admin-templates/site/participant/profile/experience/participantSummaryDetails/viewJSON/viewJSON.template.html',
                });
            };

            vm.$onInit = () => {
                vm.loadParticipantSummaryDetails();
            };
        };
        return {
            bindToController : true,
            controller       : participantSummaryDetailsController,
            controllerAs     : 'psdl',
            restrict: 'E',
            template: template,
            scope: {
                userId: '='
            },
        };
    });
})();
