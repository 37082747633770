/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * over all configuration for user account update
     */
    app.directive('ruleElementConfigActionUserAccountUpdate', ['RuleUtilService', 'ExpressionConstantsService', function (RuleUtilService, ExpressionConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.account.update.html',
            scope: {
                action: '=',
                parentForm: '='
            },
            link: function ($scope) {
                $scope.cs = RuleUtilService.ruleConstants;
                $scope.expConstants = ExpressionConstantsService;

                $scope.hasUpdatesToAddress = function () {
                    var updates = $scope.action.actionConfig.updates;
                    if (updates != null) {
                        for (var i =0;i<updates.length;i++){
                            var updateField = updates[i].targetField;
                            if (isAddressField(updateField)) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                function isAddressField(updateField) {
                    var addressFields = [
                        $scope.cs.accountInformationField.ADDRESS_STREET_ONE,
                        $scope.cs.accountInformationField.ADDRESS_STREET_TWO,
                        $scope.cs.accountInformationField.ADDRESS_CITY,
                        $scope.cs.accountInformationField.ADDRESS_STATE,
                        $scope.cs.accountInformationField.ADDRESS_ZIP,
                        $scope.cs.accountInformationField.ADDRESS_PHONE_NUMBER,
                    ];
                    return addressFields.includes(updateField);
                }
            }
        };
    }]);

})();
