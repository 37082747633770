/**
 * program configuration for tab bar items
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programTabBarConfig', function (
        FormModelServiceFieldValue,
        ModelServiceConditionExpression,
        ExpressionConstantsService,
        LocaleConstantsService,
        ProgramUtilConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programManagement/program/tabBar/program.tabBarItems.html',
            scope: {
                tabBarList: '=',  // list of currently associated tab bars
                itemMap: '=', // item map list where all possible tabs are stored as well as its usage stats
                showReadOnly: '='
            },
            controller: controller
        };

        /**
         * main controller function
         */
        function controller($scope, $uibModal) {
            const tabBarNameOptions = Object.keys($scope.itemMap);

            $scope.getTabBarName = function (item) {
                return item.displayName?.en || getMappedItem(item).name || item.name;
            };

            $scope.lcs = LocaleConstantsService;

            $scope.activeTab = null;

            $scope.getMappedItem = getMappedItem;

            $scope.addItem = function (item) {
                addItem(item);
            };

            $scope.addPage = function () {
                $uibModal.open({
                    animation: true,
                    size: 'md',
                    windowClass: 'tabbar-modal-window',
                    templateUrl: 'admin-templates/site/programManagement/program/tabBar/programAddPage.modal.html',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.pageTypeNameOptionIds = ProgramUtilConstantsService.pageTypeNameOptionIds;
                        $scope.tabBarNameOptions = tabBarNameOptions;
                        $scope.tabItem = {name: 'ABOUT', displayName: '', pageType: 'ABOUT'};
                        $scope.validateTabType = () => {
                            const { tabItem } = $scope;
                            if (tabItem.name) {
                                $scope.form.name.$setValidity('required', true);
                                $scope.tabItem.pageType = ProgramUtilConstantsService.tabBarItemMap[tabItem.name]?.pageType;
                            }
                        };
                        $scope.validateDisplayName = () => {
                            const { tabItem } = $scope;
                            if (tabItem.displayName.trim()) {
                                $scope.form.displayName.$setValidity('required', true);
                            }
                        };
                        $scope.validateAndSubmit = (tab) => {
                            if (!tab.name) {
                                $scope.form.name.$setValidity('required', false);
                            }
                            if (!tab.displayName) {
                                $scope.form.displayName.$setValidity('required', false);
                            } else {
                                $uibModalInstance.close();
                                addItem({serverKey: tab.name, pageType: tab.pageType, displayName: tab.displayName.trim()});
                            }
                        }
                    }],
                });
            };

            $scope.selectItem = function (item) {
                $scope.activeTab = item;
            };

            $scope.removeItem = function (item) {
                removeItem(item);
                $scope.activeTab = null;
            };

            $scope.isItemRequired = function (item) {
                var itemInMap = getMappedItem(item);
                return itemInMap != null && itemInMap.required;
            };

            $scope.firstItemInList = firstItemInList;

            // calling the initialization function, don't add any additional scope variable after this
            init();

            /* ----------------------------------------------------------------------
             local private functions
             * ---------------------------------------------------------------------- */

            /**
             * main initialization function, load all the data needed from server and set state to be ready
             */
            function init() {
                $scope.expConstants = ExpressionConstantsService;
                _.forEach($scope.tabBarList, function (item) {
                    var itemInMap = getMappedItem(item);
                    if (itemInMap != null) {
                        itemInMap.used = true;
                    }
                    const data = {
                        isVisibleExpressionTree : item.isVisibleExpressionTree,
                        isUnlockedExpressionTree : item.isUnlockedExpressionTree
                    };
                    item.isUnlockedExpressionTree = new ModelServiceConditionExpression.ConditionExpression(item);
                    item.isVisibleExpressionTree = new ModelServiceConditionExpression.ConditionExpression(item);
                    item.isUnlockedExpressionTree.fromDto(data.isUnlockedExpressionTree);
                    item.isVisibleExpressionTree.fromDto(data.isVisibleExpressionTree);
                    if (!item.icons) {
                        item.icons = {};
                    }
                    if (!item.icons.mobileEnableIcon) {
                        item.icons.mobileEnableIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!item.icons.mobileDisableIcon) {
                        item.icons.mobileDisableIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!item.icons.webEnableIcon) {
                        item.icons.webEnableIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!item.icons.webDisableIcon) {
                        item.icons.webDisableIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                });
            }

            /**
             * check if the item is the first item in the list
             * @param item -- item
             * @param list -- list
             */
            function firstItemInList(item, list) {
                return item === list[0];
            }

            /**
             * getting mapped item from tab bar item using its name for reference
             * @param tabBarItem - tab bar item
             * @returns {*} mapped item or null if name is not valid
             */
            function getMappedItem(tabBarItem) {
                if (tabBarItem == null || tabBarItem.name == null) {
                    return null;
                }
                return $scope.itemMap[tabBarItem.name];
            }

            /**
             * Adding an item into the list
             * Only 5 can be added.
             * @param mappedItem - an item from the mapping list
             */
            function addItem(mappedItem) {
                    var newBarItem = {
                        id: null,
                        name: mappedItem.serverKey,
                        menuNavigationEnabled: true,
                        sequenceNo: -1,
                        useAdvancedConfig: false,
                        lockForCati:true,
                        description: {
                            en: '',
                            es: ''
                        },
                        displayName: {
                            en: mappedItem.displayName ?? '',
                            es: '',
                        },
                        pageType: mappedItem.pageType,

                        isVisibleExpression: null,
                        isVisibleExpressionTree: new ModelServiceConditionExpression.ConditionExpression(this),
                        isUnlockedExpression: null,
                        isUnlockedExpressionTree: new ModelServiceConditionExpression.ConditionExpression(this),
                        icons: {
                            mobileEnableIcon: new FormModelServiceFieldValue.ImageValue(),
                            mobileDisableIcon: new FormModelServiceFieldValue.ImageValue(),
                            webEnableIcon: new FormModelServiceFieldValue.ImageValue(),
                            webDisableIcon: new FormModelServiceFieldValue.ImageValue(),
                            mobileSelectedColor: null,
                            webSelectedColor: null,
                        }
                    };
                    if(mappedItem.serverKey==='DASHBOARD'){
                        newBarItem.lockForCati=false;
                    }
                    mappedItem.used = true;
                    $scope.tabBarList.push(newBarItem);

                    $scope.activeTab = newBarItem;
            }

            /**
             * remove a tab bar item
             * @param tabBarItem - tab bar item
             */
            function removeItem(tabBarItem) {
                const index = $scope.tabBarList.findIndex(item => {
                    return angular.equals(item, tabBarItem);
                });

                if($scope.tabBarList.length==1){
                $('#navigationMsg').show();
                setTimeout(function() { $('#navigationMsg').hide(); }, 5000);
                return;
                }
                if (index !== -1 ) {
                    $scope.tabBarList.splice(index, 1);

                    var itemInMap = getMappedItem(tabBarItem);
                    if (itemInMap) {
                        itemInMap.used = false;
                    }
                }
            }

        }

    });

})();
