/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form field's validation rules
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('CompareResultValidationRuleService',
        function (CompareResultUtilService, vbrCommonUtil) {

            /**
             * compare two validation rule object
             * @param vr1 - validation rule 1
             * @param vr2 - validation rule 2
             * @param parentTitle - parent title
             * @returns {{CompareResult}} - returning a map of Compare Result object
             */
            function compare(vr1, vr2, parentTitle) {
                // some basic error handling to avoid null pointer exception
                if (vbrCommonUtil.isNullOrUnavailable(vr1)) {
                    vr1 = {};
                }

                if (vbrCommonUtil.isNullOrUnavailable(vr2)) {
                    vr2 = {};
                }

                var result = {};

                CompareResultUtilService.compareNamedObjectsByKey(vr1, vr2, "type", result);
                CompareResultUtilService.compareObjectsByKey(vr1, vr2, "options", result);
                CompareResultUtilService.comparePrimitivesByKey(vr1, vr2, "validationValue", result);
                CompareResultUtilService.comparePrimitivesByKey(vr1, vr2, "enabled", result);
                CompareResultUtilService.comparePrimitivesByKey(vr1, vr2, "errorMessage", result);

                // localization map
                var localMap1 = vr1==null ? null : vr1.localizationMap;
                var localMap2 = vr2==null ? null : vr2.localizationMap;

                CompareResultUtilService.compareMaps(localMap1, localMap2, "lm", result);

                var type = vr1.type != null ? vr1.type.name : (vr2.type != null ? vr2.type.name : null);
                type = type==null ? "" : "(" + type + ")";


                result._title = "Validation Rule " + type + " - " + parentTitle;

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

