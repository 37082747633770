(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('commonUseCaseDefinitions', function ($filter, CommonUseCasesService) {
            function commonUserCaseController() {
                var vm = this;

                vm.EXAMPLE_NOTES = 'notes';
                vm.FORMATTED_EXPRESSION = 'formattedExpression';
                vm.OVERALL_EXAMPLE_NOTES = 'exampleNotes';
                vm.UNFORMATTED = 'unformatted';

                vm.ID = {
                    DESCRIPTION: 'description',
                    DETAILS: 'details',
                    EXAMPLES: 'examples',
                    EXPRESSION: 'expression',
                    RULES: 'rules',
                    USE_CASE: 'useCaseName',
                };

                vm.headers = [
                    vm.ID.USE_CASE,
                    vm.ID.DETAILS,
                ];

                function init() {
                    // only show functions that match the usedBy value
                    vm.useCases = CommonUseCasesService.functions.filter(function (fn) {
                        return fn.usedBy.indexOf(vm.usedBy) !== -1;
                    }).map(function (fn) {
                        // if the function has expressions, format them here to avoid
                        // multiple API calls when the search filter changes
                        if (fn.examples) {
                            fn.examples.forEach(function (example) {
                                if (!example[vm.UNFORMATTED]) {
                                    example[vm.FORMATTED_EXPRESSION] = $filter(
                                        'formatExpression')(
                                        example[vm.ID.EXPRESSION],
                                        vm.usedBy
                                    );
                                    example[vm.UNFORMATTED] = false;
                                }
                            });
                        }
                        return fn;
                    });
                }

                init();
            }

            return {
                bindToController: true,
                controller: commonUserCaseController,
                controllerAs: 'ucd',
                restrict: 'E',
                scope: {
                    usedBy: '='
                },
                templateUrl: 'admin-templates/util/expression/common-use-cases.html',

            };
        });
})();
