/**
 * user profile display
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrUserProfileDisplay', function (FormEntryUtilService, ngDialog, ProfileUpdateService) {
        /***************************************
         * directive return call
         ***************************************/

        return {
            restrict: 'E',
            templateUrl: "admin-templates/site/userProfile/vbrUserProfileDisplay.html",
            scope: {
                user : '=',
                options : '=',
                title : '@'
            },
            link: function ($scope) {
                $scope.displayProfileValueAsHtml = displayProfileValueAsHtml;

                $scope.changeProfile = function(profileField) {
                    changeProfile($scope, profileField);
                };

                $scope.profileFieldUpdated = function(newProfileField) {
                    if (newProfileField==null || newProfileField.propertyName==null) {
                        return;
                    }
                    $scope.user.profiles[newProfileField.propertyName] = newProfileField;
                };
            }
        };

        function displayProfileValueAsHtml(profileField) {
            var isDate = false; // todo: fix this later
            var profileValues = profileField.profileValues;
            var valueString = [];
            _.forEach(profileValues, function(pv) {
                valueString.push(FormEntryUtilService.convertFieldEntryValueToHtml(pv, isDate));
            });

            return _.join(valueString, ', ');
        }

        function changeProfile($scope, profileField) {
            // open Change Profile Model
            var dialog = ngDialog.open({
                template: 'admin-templates/site/userProfile/changeProfileWindow.html',
                closeByNavigation : true,
                closeByDocument : false,
                height : '70%',
                className: 'ngdialog-theme-default',
                controller : function() {
                    var vm = this;
                    vm.data = {
                        profileField : angular.copy(profileField),
                        triggerExternalUpdate : false
                    };
                    vm.profileField = profileField;
                    vm.showExternalFlagControl = $scope.options.showExternalFlagControl;

                    vm.doUpdate = function() {
                        ProfileUpdateService.updateProfile(vm.data).then(function() {
                            dialog.close(vm.data.profileField);
                        });
                    };
                },
                controllerAs : 'windowCtrl',
                preCloseCallback : function(updatedProfileField) {
                    $scope.windowOpen = false;
                    $scope.profileFieldUpdated(updatedProfileField);
                }
            });
            $scope.windowOpen = true;
        }

    });

})();

