import spanUtil from "../../../../../util/textGeneration/spanUtil";

angular.module('acadiamasterApp').directive('personalizedContainers', function (
    ParticipantProfileService, PersonalizedContainerItemModel, ContainerMgmtService, AlertService, PageSelectorService) {
    return {
        restrict: 'E',
        scope: {
            userId: '<'
        },
        templateUrl: 'admin-templates/site/participant/profile/experience/personalizedContainer/personalizedContainers.html',
        link: function ($scope) {
            $scope.data = {
                rawData: null,

                personalizedContainersMap: {},
                personalizedContainerItemsMap: {},
                tabBarItemMap: {},

                totalPersonalizedContainers : 0,
                totalPersonalizedContainerItems : 0,

                isPreCalculatedSpan : null,

                isLoading: true,

                reset: function () {
                    this.totalPersonalizedContainers = 0;
                    this.totalPersonalizedContainerItems = 0;

                    this.isLoading = false;
                    this.rawData = null;
                    this.personalizedContainersMap = {};
                    this.personalizedContainerItemsMap = {};

                    this.isPreCalculatedSpan = null;
                }
            };

            $scope.recalculate = function() {
                recalculate($scope);
            };

            $scope.getPageInfo = function(pageId, fieldName) {
                const tabBarItem = $scope.data.tabBarItemMap[pageId];
                if (tabBarItem==null) {
                    return null;
                }

                return tabBarItem[fieldName];
            }

            init($scope);
        }
    };

    // ---------------------------------  private functions ------------------------------------------

    /**
     * initialization function for the directive, it will load data from server
     * @param $scope - scope object
     */
    function init($scope) {
        $scope.data.isLoading = true;

        // loading data
        ParticipantProfileService.getPersonalizedDashboardDetails($scope.userId)
            .then(function (result) {
                processResult($scope, result.data);
            }, function (error) {
                $scope.data.reset();
                console.error(error);
            });
    }

    /**
     * populate the personalized container items data
     */
    function populateDataForPersonalizedContainerItems($scope, personalizedContainerItemDTOMap) {
        $scope.data.personalizedContainerItemsMap = {};

        const itemMap = $scope.data.personalizedContainerItemsMap;
        let totalPersonalizedContainerItems = 0;

        _.forEach(personalizedContainerItemDTOMap, (personalizedContainerItems, containerId) => {
            const personalizedContainerItemModels = [];
            itemMap[containerId] = personalizedContainerItemModels;

            _.forEach(personalizedContainerItems, (personalizedContainerItemDTO) => {
                totalPersonalizedContainerItems++;

                const personalizedContainerItem = new PersonalizedContainerItemModel();
                personalizedContainerItem.fromDto(personalizedContainerItemDTO);
                personalizedContainerItemModels.push(personalizedContainerItem);
            });

            personalizedContainerItemModels.sort((pci1, pci2) => pci1.getDisplayOrder() - pci2.getDisplayOrder());
        });

        $scope.data.totalPersonalizedContainerItems = totalPersonalizedContainerItems;
    }

    /**
     * populate the personalized container data
     */
    function populateDataForPersonalizedContainer($scope, personalizedContainersDTOs) {
        $scope.data.personalizedContainersMap = {};
        let totalPersonalizedContainers = 0;

        // put the personalized container into a map where
        // 1. key = page id
        // 2. value = a list of personalized containers for that page id
        personalizedContainersDTOs.forEach(function (personalizedContainer) {
            let pageId = personalizedContainer.containerDTO.pageId;
            if (pageId == null) {
                pageId = '-1';
            }
            let listByPageId = $scope.data.personalizedContainersMap[pageId];
            // create the list if it doesn't exist
            if (listByPageId==null) {
                listByPageId = [];
                $scope.data.personalizedContainersMap[pageId] = listByPageId;
            }

            listByPageId.push(personalizedContainer);
            totalPersonalizedContainers++;
        });

        // sort the personalized containers by page id and display order
        _.forEach($scope.data.personalizedContainersMap, (listByPageId) => {
            listByPageId.sort((pc1, pc2) => {
                return pc1.containerDTO.displayOrder - pc2.containerDTO.displayOrder;
            });
        });

        $scope.data.totalPersonalizedContainers = totalPersonalizedContainers;
    }

    /**
     * recalculate the personalized values, after the calculation is done, message will be displayed on
     * screen to show if the operation was successful or not
     * @param $scope - scope object that contains user id and data object
     * note: data object will get auto refreshed after this
     */
    function recalculate($scope) {
        ContainerMgmtService.recalculatePersonalizedByUserId($scope.userId)
            .then(function (result) {
                processResult($scope, result.data);
                AlertService.success('Personalized container information has been recalculated and reloaded');
            }, function (error) {
                $scope.data.reset();
                AlertService.error('Personalized container information can not be calculated for some reason, please check console log for more information');
                console.error(error);
            }).finally(function () {
            $scope.data.isLoading = false;
        });
    }

    /**
     * process the server result on success
     * @param $scope - scope object, various fields will be updated
     * @param personalizedDashboardDetails - personalized dashboard details from server
     */
    function processResult($scope, personalizedDashboardDetails) {
        $scope.data.rawData = personalizedDashboardDetails;

        if (personalizedDashboardDetails != null && personalizedDashboardDetails.length > 0) {
            $scope.data.isPreCalculatedSpan = spanUtil.createBooleanSpan({
                valueToCheck: personalizedDashboardDetails[0].preCalculated,
                faIconOnTrue: 'fa-check text-success',
                faIconOnNotTrue: 'fa-times text-warn',
                titleTextOnTrue: 'Those Personalized Container Information is pre-calculated and retrieved from database',
                titleTextOnNotTrue: 'Those Personalized Container Information is calculated on the fly'
            });

            // multi-subscription has a number of other issues, so just handle the first subscription for now
            populateDataForPersonalizedContainer($scope, personalizedDashboardDetails[0].personalizedContainers);
            populateDataForPersonalizedContainerItems($scope, personalizedDashboardDetails[0].personalizedContainerItemMap);
        }

        populateTabbarItemMap($scope);
    }

    /**
     * populate the tab bar item map using the container's program id
     * @param $scope - scope object, include the container information as well as tabbar item map
     */
    function populateTabbarItemMap($scope) {
        const tabBarItemMap = $scope.data.tabBarItemMap;

        const programId = getProgramId($scope.data.personalizedContainersMap);

        if (programId == null) {
            console.warn('unable to find program id, maybe user has no subscription');
            $scope.data.isLoading = false
        }

        PageSelectorService.getPages(programId)
            .then(function (result) {
                updateTabItemMap(tabBarItemMap, result.data);
            }, function (error) {
                AlertService.error('Unable to load page information, check console for more information');
                console.error('Unable to load page information', error);
            }).finally(function () {
            $scope.data.isLoading = false;
        });
    }

    /**
     * update tab item map (key = page id, value = actual page object) from list of tab bar items
     * note: we also create a fake object for page id = -1, that's used for null page id
     * @param tabBarItemMap - tab bar item map, at the start of the function, this map should be empty
     * @param tabBarItems - list of available tab bar items
     */
    function updateTabItemMap(tabBarItemMap, tabBarItems) {
        // init the -1 item
        tabBarItemMap['-1'] = {
            'name': 'NONE',
            'pageType': 'null'
        };

        _.forEach(tabBarItems, (tabBarItem) => {
            tabBarItemMap[tabBarItem.id] = tabBarItem;
        });
    }

    /**
     * get program id from the first container in the personalized containers map
     * @param personalizedContainersMap - a map of personalized containers
     * @returns {*} - id of the program for the container in containers map, if nothing is found, return null
     */
    function getProgramId (personalizedContainersMap) {
        const personalizedContainerList = _.find(personalizedContainersMap,
            (listByPageId) => listByPageId != null && listByPageId.length > 0);

        return personalizedContainerList[0].containerDTO.programId;
    }

});
