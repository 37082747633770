/**
 * Created by moustafabaiou on 2/27/17
 */
(function () {
    'use strict';

    angular
        .module('acadiamasterApp')
        .factory('PDFPreview', function (localStorageService, localWeb, Upload,AlertService, $window) {

            var previewURL = localWeb.createUrl('/api/forms/previewConsentPDF/');

            function previewConsentPDF(data, files) {

                return Upload.upload({
                    url: previewURL,
                    responseType: 'arraybuffer',
                    headers: {
                        'Accept': 'application/pdf'
                    },
                    arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                    data: {
                        file: files,
                        data: JSON.stringify(data)
                    }
                }).then(function (resp) {
                    if (resp.status == 200) {
                        var blob = new Blob([resp.data], {type: "application/pdf"});
                        var objectUrl = URL.createObjectURL(blob);
                        $window.open(objectUrl);
                    } else if (resp.status == 204){
                        AlertService.warning("Could not generate PDF Preview. Check and save your configuration before trying to generate preview");
                    }
                }, function (error) {
                });
            }

            return previewConsentPDF;

        });
})();
