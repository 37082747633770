(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {

            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('programTest');
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }

            $stateProvider
                .state('programTests.pstSuite', {
                    parent: 'programTests',
                    url: '/programTestSuite',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                        pageTitle: 'programTest.program.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/programTests/programTestSuite/programTestSuite.html',
                            controller: 'ProgramTestSuiteController'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader',
                            function ($translate, $translatePartialLoader) {
                                return loadTranslatePartial($translate, $translatePartialLoader);
                            }]
                    }
                })
                .state('programTests.pstSuite.new', {
                    parent: 'programTests.pstSuite',
                    url: '/new/{programId}',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'programTest.program.suite.new'
                    },
                    views: {
                        'content@': {
                            template: '<program-test-suite-configuration is-new="true" test-suite="testSuite" program-id="programId" ' +
                            'state-after-save="\'programTests.pstSuite\'" state-after-cancel="\'programTests.pstSuite\'">' +
                            '</program-test-suite-configuration>',
                            controller: function ($scope, entity, programId) {
                                $scope.testSuite = entity;
                                entity.programId = programId;
                            },
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader',
                                    function ($translate, $translatePartialLoader) {
                                        return loadTranslatePartial($translate, $translatePartialLoader)
                                    }
                                ],
                                entity: ['ProgramTestSuiteModelService', function (ProgramTestSuiteModelService) {
                                    return new ProgramTestSuiteModelService.ProgramTestSuiteModel();
                                }],
                                programId : ['$stateParams', function ($stateParams) {
                                    return $stateParams.programId;
                                }]
                            }
                        }
                    }
                })
                .state('programTests.pstSuite.edit', {
                    parent: 'programTests.pstSuite',
                    url: '/edit/{id}',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    views: {
                        'content@': {
                            template: '<program-test-suite-configuration is-new="false" test-suite="testSuite" ' +
                            'state-after-save="\'programTests.pstSuite\'" state-after-cancel="\'programTests.pstSuite\'">' +
                            '</program-test-suite-configuration>',
                            controller: function ($scope, entity, ProgramTestSuiteModelService) {
                                $scope.testSuite = new ProgramTestSuiteModelService.ProgramTestSuiteModel();
                                $scope.testSuite.fromDto(entity.data);
                            },
                            resolve: {
                                translatePartialLoader: ['$translate', '$translatePartialLoader',
                                    function ($translate, $translatePartialLoader) {
                                        return loadTranslatePartial($translate, $translatePartialLoader);
                                    }
                                ],
                                entity: ['ProgramTestSuiteService', 'ProgramTestSuiteModelService', '$stateParams',
                                    function (ProgramTestSuiteService, ProgramTestSuiteModelService, $stateParams) {
                                        let id = $stateParams.id;
                                        return ProgramTestSuiteService.getTestSuite(id);
                                    }]
                            }
                        }
                    }
                });
        });
})();
