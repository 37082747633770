(function () {
    'use strict';

    /**
     * directive for Form Comparison Result display, everything should be prepared when it get to
     * this directive, it has no dependency other than the bootstrap library for some css icon class
     */
    angular.module('acadiamasterApp').directive('formVersionCompareResult', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/formVersionCompare.result.html',
            scope: {
                compareResult : '=',
                showChangeOnly : '=',
                firstVersion: '=',
                secondVersion: '='
            },
            link: function ($scope) {
                // 2 version are just used pass the form mode objects to form mode comparison directive
                // This is needed for rendering flow charts
            }
        };


    });

})();

