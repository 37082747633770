'use strict';

/**
 * drc related services
 */
(function() {
    angular.module('acadiamasterApp').factory('PairingService', function ($http, localWeb, vbrCommonUtil) {
        var baseUrl = 'api/drc/pairing/';

        return {
            search : function (ids) {
                return $http.get(localWeb.createUrl(baseUrl + "userInfo"), {params: {'ids' : ids}});
            },

            getAllAvailableOptions : function () {
                return $http.get(localWeb.createUrl(baseUrl + "allAvailable"));
            },

            update : function (pairingInfo) {
                return $http.post(localWeb.createUrl(baseUrl), pairingInfo,{
                    headers: vbrCommonUtil.getCommonPostHeader()
                });
            },

            historyCount : function () {
                return $http.get(localWeb.createUrl(baseUrl + "historyCount"), {params: {'days' : [7]}});
            },

            history : function (day) {
                return $http.get(localWeb.createUrl(baseUrl + "historyData"), {params: {'day' : day}});
            },
        };
    })
})();
