angular.module('acadiamasterApp')

    /**
     * agreements directive
     */
    .directive('exportWizardStepAgreements', (AgreementsService,
        AlertService) => {

        function loadAll($scope) {
            const params = {
                programId: $scope.program.id
            };
            AgreementsService.loadAgreements(params).then((response) => {
                if (response == null || response.data == null || response.status !== 200) {
                    AlertService.error(
                        `Failed to load agreements data for Program Id: ${params.programId}`,
                    );
                    return;
                }

                $scope.data.agreements = response.data.map(agreement => ({
                    ...agreement,
                    displayTitle: agreement.displayTitle.en,
                }));

                if (response.data.length === 0) {
                    AlertService.warning('No agreements were found');
                }
            }, (error) => {
                console.error('Error loading agreements', error);
            }).finally(() => {
                $scope.data.isLoading = false;
            });
        }

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            $scope.orderByChoices = {
                TYPE: 'type',
                FORM_ID: 'formId',
                FORM_NAME: 'formName',
                TITLE: 'displayTitle',
            };

            $scope.data = {
                agreements: [],
                ascending: true,
                orderBy: $scope.orderByChoices.TYPE,
                isLoading: true,
            };

            loadAll($scope);
        }

        return {
            controller: ($scope) => {
                init($scope);

                $scope.selectAll = () => {
                    if ($scope.selectedData.includeProgramAgreements && $scope.data.agreements) {
                        $scope.selectedData.agreements = $scope.data.agreements;
                    } else {
                        $scope.selectedData.agreements = [];
                    }
                };

                $scope.sortBy = (name) => {
                    $scope.data.ascending = ($scope.data.orderBy === name)
                        ? !$scope.data.ascending
                        : true;
                    $scope.data.orderBy = name;
                };

                $scope.$watch('selectedData.includeProgramAgreements', (nv) => {
                    if(nv){
                        $scope.selectedData.agreements = $scope.data.agreements;
                    }
                });
            },
            restrict: 'E',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '=',
            },
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepAgreements.html',
        };
    });
