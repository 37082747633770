/**
 * tree node options service for program test edit GUI
 */
(function () {
    'use strict';
    angular.module('acadiamasterApp').factory('ProgramTestTreeOptionsService', function (
        ProgramTestConstantsService, VbrTreeUtilService) {

        var nodeTypeMap = createNodeTypeMap();

        return {
            accept: accept,
            beforeDrag: beforeDrag,
            nodeAdded: nodeAdded,
            removed: nodeRemoved,
            dropped: dropped
        };

        /******************************************************
         * private functions after this
         ******************************************************/

        /**
         * before drag will be called before user can move a node, it should return true if we can move it, and return false/null otherwise
         * @param sourceNodeScope
         * @returns {boolean}
         */
        function beforeDrag(sourceNodeScope) {
            return true;
        }


        /**
         * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
         * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
         * @param sourceNodeScope
         * @param destNodeScope
         * @returns {boolean}
         */
        function accept(sourceNodeScope, destNodeScope) {
            var sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
            var destType = getParentTypeFromNodeScope(destNodeScope);

            // do not delete the commented out lines, this part of the code is often broken when changes are
            // made, and those console statements are useful when that happens.  Those calls happen at a
            // very high frequency, debugger with break point is not suitable for that

            var shouldAccept = nodeTypeMap[sourceType].typesCanMoveUnder.indexOf(destType) != -1;

            // console.log('should Accept = ' + shouldAccept + ', sourceType =', sourceType +
            //      ", destType : " + destType, destNodeScope);

            return shouldAccept;
        }

        function getParentTypeFromNodeScope(nodeScope) {
            var modelValue = nodeScope.$modelValue;

            if (isValueCorrectiveActionList(modelValue)) {
                return ProgramTestConstantsService.nodeType.CORRECTIVE_ACTION_LIST;
            }

            // this is not great, but works for now
            return ProgramTestConstantsService.nodeType.IF_ASSERT_CONDITION;
        }

        function isValueCorrectiveActionList(modelValue) {
            return _.isArray(modelValue) && modelValue.length>0 &&
                modelValue[0].nodeType == ProgramTestConstantsService.nodeType.CORRECTIVE_ACTION
        }

        function nodeAdded(node) {
            return node;
        }

        function nodeRemoved(node) {
            // do nothing there
        }

        /**
         * event dropped is called after node moves it's position
         * @param event
         */
        function dropped(event) {
            var sourceModel = event.source.nodeScope.$modelValue;

            if (event.dest.nodesScope.$nodeScope == null) { // this is a top level node
                return;
            }

            var destModel = event.dest.nodesScope.$nodeScope.$modelValue;

            if (sourceModel._parent != destModel) {
                sourceModel._parent = destModel;
            }
        }

        function createNodeTypeMap() {
            var map = {};

            var nodeType = ProgramTestConstantsService.nodeType;

            map[nodeType.CORRECTIVE_ACTION] = VbrTreeUtilService.createNodeTypeItem(nodeType.CORRECTIVE_ACTION, [nodeType.CORRECTIVE_ACTION_LIST], false);
            map[nodeType.IF_ASSERT_CONDITION] = VbrTreeUtilService.createNodeTypeItem(nodeType.IF_ASSERT_CONDITION, [nodeType.IF_ASSERT_CONDITION], false);

            return map;
        }
    });
})();

