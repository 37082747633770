(function () {
    'use strict';

    /***************************************************************
     * utility for loading property from server, the input object should have the following property and prototype function
     *  -- propertyId (property, a number)
     *  -- getProperty()
     *  -- setProperty(property)
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PropertyLoadUtil', function (PropertyCacheService, AlertService) {

        return {
            loadProperty: loadProperty
        };

        /**
         * load property from server if needed for the container object
         * @param containerWithProperty - container object that contains a field for property id and function for get/set property
         */
        function loadProperty(containerWithProperty) {
            if (requiredFunctionNotDefined(containerWithProperty)) {
                AlertService.error('container with property is not valid', null, containerWithProperty);
                return;
            }

            var propertyId = containerWithProperty.propertyId;
            if (propertyId == null) {
                // nothing to load
                return;
            }

            var property = containerWithProperty.getProperty();

            if (property != null && property.id === propertyId) {
                // already loaded, do nothing
                return;
            }

            PropertyCacheService.loadPropertyById(propertyId, false).then(function (data) {
                containerWithProperty.setProperty(data);
            }).catch(function (error) {
                AlertService.error('failed to load property by id : ' + propertyId, null, error);
            });
        }

        function requiredFunctionNotDefined(container) {
            return !_.isFunction(container.getProperty) || !_.isFunction(container.setProperty);
        }

    });
})();
