/**
 * directive for displaying a function evaluation result tree
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.directive('funcEvalResultDisplay', function (ExpressionConstantsService) {
        const {nodeTypes} = ExpressionConstantsService;

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/funcEval/funcEvalResultDisplay.html',
            scope: {
                resultTree: '='
            },
            link: function ($scope) {
                $scope.isNullResult = () => {
                    const rt = $scope.resultTree;
                    return rt != null && rt.nodeType === nodeTypes.NULL;
                };

                $scope.isTopResultSimple = () => {
                    const rt = $scope.resultTree;
                    return rt != null &&
                        (rt.nodeType === nodeTypes.BOOLEAN ||
                            rt.nodeType === nodeTypes.STRING ||
                            rt.nodeType === nodeTypes.DOUBLE);
                };

            }
        };
    });

})();
