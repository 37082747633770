import template from './isSubscribedToProgram.html';
(function () {
    /**
     * directive for selecting program subscription
     */
    angular.module('acadiamasterApp').directive('isSubscribedToProgramConfig', (ProgramCacheService, AlertService) => {
        function addWatches ($scope) {
            $scope.$watch('data.program', newValue => {
                $scope.condition.setProgram(newValue);
            });
        }

        function init ($scope) {
            console.log('init called');
            // this is not a force reload of all the properties
            ProgramCacheService.loadPrograms(false).then(data => {
                $scope.data.allPrograms = data;
            }).catch(error => {
                AlertService.error('failed to load program list : ', null, error);
            });

            addWatches($scope);
        }
        return {
            link : function ($scope) {
                $scope.data = {
                    allPrograms : null,
                    program     : $scope.condition.getProgram(),
                };

                init($scope);
            },
            restrict : 'E',
            scope    : {
                condition : '=',
            },
            template : template,
        };
    });
}());
