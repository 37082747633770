'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('toolCommunicationCategories.new', {
                parent: 'toolCommunicationCategories',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.communicationCategory.createOrEditLabel'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/communicationCategory/communicationCategory.html',
                        controller: 'communicationCatController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('communicationCategory');

                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            name: {'en': null}, description: {'en': null}, isEditable: true
                        };
                    },
                    isNew: [function () {
                        return true;
                    }]
                }
            })
            .state('toolCommunicationCategories.edit', {
                parent: 'toolCommunicationCategories',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/communicationCategory/communicationCategory.html',
                        controller: 'communicationCatController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('communicationCategory');

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CommunicationCategoryService', function ($stateParams, CommunicationCategoryService) {
                        let id = $stateParams.id;
                        return CommunicationCategoryService.getCategory(id);
                    }],
                    isNew: [function () {
                        return false;
                    }]
                }
            });
    });
