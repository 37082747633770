/**
 * created by Jason Cao on 9/12/2019
 * directive for upload and cropping an image file inside an window, it should be used as a popup
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('imageCropWindow', function (AlertService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/common/imageCrop/imageCropWindow.html',
                scope: {
                    // @required function to be invoked when image cropping is finished, it takes two parameters,
                    // 1st param -- type: DataURI, the cropped image uri
                    // 2nd param -- type: File, the actual file uploaded
                    imageCroppedCallBack: '=',

                    // required, used to be called with close button is pressed
                    closeFunction: '=',

                    // @required crop area type, ie: 'circle', 'rectangle', 'square'
                    cropAreaType: '=',
                    // @optional aspect ratio restriction, ie: if we want the crop to be 2x1 (w x h), then use 2 here
                    aspectRatio: '=',
                    // @required result image size in both height and width, ie: if we want the result image to be 1080 x 500, use
                    // {w: 1080, h: 500}
                    resultImageSize: '=',
                },
                link: function ($scope) {

                    $scope.data = {
                        imageFile : null,
                        resultImage : null,
                    };

                    function isFileExtensionAllowed(fileName, extensions) {
                        if (fileName==null) {
                            return false;
                        }

                        let fileExtension = "";
                        if (fileName.lastIndexOf(".") > 0) {
                            fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
                        }

                        for (let i=0; i<extensions.length; i++) {
                            if (fileExtension.toLowerCase() === extensions[i].toLowerCase()) {
                                return true;
                            }
                        }
                        return false;
                    }

                    $scope.handleFileSelect = function (currentTarget) {
                        let file = currentTarget.files[0];

                        if (file==null) {
                            $scope.data.imageFile = null;
                            return;
                        }

                        if (!isFileExtensionAllowed(file.name, ['png'])) {
                            AlertService.warning('file upload does not appear to be in the supported format, ' +
                                'please upload a png file');
                            return;
                        }

                        let reader = new FileReader();
                        reader.onload = function () {
                            $scope.$apply(function ($scope) {
                                $scope.data.imageFile = file;
                            });
                        };
                        reader.readAsDataURL(file);
                    };

                    $scope.$watch('data.resultImage', function(newValue) {
                        $scope.imageCroppedCallBack($scope.data.resultImage, $scope.data.imageFile);
                    });
                }
            };

        });

})();

