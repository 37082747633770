/**
 * Created by Jason Cao on 01/31/2020
 * simple controller for stopping a running operation
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('StopOperationController', function ($scope, OperationHistoryService, AlertService) {
            $scope.operationHistory = $scope.ngDialogData;
            $scope.data = {
                reason: null
            };

            $scope.canStop = function() {
                return $scope.data.reason!=null;
            };

            $scope.stopOperation = function () {
                OperationHistoryService.stopOperationById($scope.operationHistory.id, $scope.data.reason)
                    .then(function () {
                        AlertService.success(`operation with id ${$scope.operationHistory.id} has been stopped`);
                        $scope.confirm();
                    });
            };
        });

})();

