angular
    .module('acadiamasterApp')
    .controller(
        'ImportSnapQuestionsConfirmController',
        ($scope, AlertService, ConfigurationServer, drcSyncUtilService, OperationStatus, FormConstants) => {
            $scope.program = $scope.ngDialogData;
            $scope.data = {
                isInfoCollapsed : true,
                isSendingFile   : false,
                zipFile         : null,
            };

            // data migration related
            $scope.operationData = {
                isReady         : false,
                operationId     : null,
                operationStatus : new OperationStatus(),
            };

            $scope.formTypes = FormConstants.formTypes;
            $scope.defaultSelection = 'Choose Program';
            $scope.selectedProgramCode = $scope.defaultSelection;
            $scope.selectedProgram = {};
            $scope.isEnabled = false;
            $scope.selectedFormType = $scope.formTypes.SYSTEM;
            $scope.formDetails = {
                programCode : null,
                programId   : null,
            };

            $scope.checkFormType = function () {
                if ($scope.selectedFormType === $scope.formTypes.PROGRAM) {
                    return $scope.selectedProgramCode === $scope.defaultSelection;
                }
                return false;
            };

            $scope.$watch('selectedFormType', (newValue, oldValue) => {
                if (newValue === oldValue) {
                    return;
                }
                if (newValue === $scope.formTypes.SYSTEM) {
                    $scope.isEnabled = false;
                    if ($scope.selectedProgram) {
                        $scope.selectedProgram = {};
                        $scope.selectedProgramCode = $scope.defaultSelection;
                    }
                } else {
                    $scope.isEnabled = true;
                }
            });

            $scope.importSnapQuestions = function () {
                $scope.data.isSendingFile = true;
                // check status every 5 seconds
                $scope.checkStatusInterval = 5000;
                drcSyncUtilService.openModelWindow(
                    $scope,
                    $scope.operationData,
                    'Import Snap Questions',
                    () => {
                        // reload the program list on popup close
                        $scope.loadAll();
                        $scope.closeThisDialog();
                    },
                    true,
                );

                if ($scope.selectedFormType === $scope.formTypes.PROGRAM) {
                    $scope.formDetails.programId = $scope.selectedProgram.programId;
                    $scope.formDetails.programCode = $scope.selectedProgramCode;
                }

                ConfigurationServer.importSnapQuestions($scope.data.zipFile, $scope.formDetails.programId).then(
                    response => {
                        AlertService.success(`Snap question import process started<p>Operation Id: ${response.data.operationId}`);
                        $scope.operationData.operationId = response.data.operationId;
                        drcSyncUtilService.checkAndShowStatus($scope, $scope.operationData);
                    },
                    error => {
                        AlertService.error(error);
                        console.error('Error importing snap questions', error);
                    },
                );
            };

            $scope.downloadTemplate = function () {
                const a = document.createElement('a');
                const content = [
                    '\uFEFF'
                        + 'Name,Display Name,Display Name Spanish,'
                        + 'Image,Question,Question Spanish,Answers,'
                        + 'Answers Spanish,Snap Fact,Snap Fact Spanish'
                        + ',Category,Category Spanish,Period,'
                        + 'Period Spanish,Insights Age,Insights Gender, Question Id',
                ];
                const file = new Blob(content, { type : 'text/csv;charset=utf-8' });
                a.href = URL.createObjectURL(file);
                a.download = 'SnapQuestionTemplate.csv';
                a.click();
            };

            $scope.isFileValid = function (file) {
                return file && file.name.indexOf('.zip') === file.name.length - 4 && file.size !== 0;
            };
        },
    );
