'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeCategoryDetailController', function ($location, $uibModal, $scope, $rootScope,
        $state, $stateParams, entity, DataTypeCategory, DataTypeMasterRecordCount) {

        var modalInstance = null;
        var dataTypeCategory = null;
        $scope.dataReady = false;
        $scope.dataTypeCategory = entity;
        dataTypeCategory = $scope.dataTypeCategory;
        $scope.inheritedPropertyMappings = [];

        $scope.loadMore = function () {
            alert('Not Implemented');
            return false;
        };
        $scope.mappingStatus = {
            OVERWRITTEN: 'O'
        };

        $scope.dataTypeCategory.$promise.then(function () {
            $scope.dataReady = true;

            var dataTypeTypeRootCategorys = $scope.dataTypeCategory.dataTypeRootCategorys;
            for (var i = 0; i < dataTypeTypeRootCategorys.length; i++) {
                var propertyMappings = dataTypeTypeRootCategorys[i].propertyMappings;
                for (var j = 0; j < propertyMappings.length; j++) {
                    if ($scope.inheritedPropertyMappings.IdExist(propertyMappings[j].id) != null) {

                        var newMapping = propertyMappings[j];
                        // If it is also in directly associated list, we consider that was overridden.
                        if (findPropertyMapping(newMapping.property.name, $scope.dataTypeCategory.propertyMappings) != null) {
                            console.log(newMapping.name);
                            newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                        }


                        $scope.inheritedPropertyMappings.push(newMapping);
                    }
                }
            }
        });

        $scope.load = function (id) {
            DataTypeCategory.get({ id: id }, function (result) {
                $scope.dataTypeCategory = result;
            });
        };
        $rootScope.$on('acadiamasterApp:dataTypeCategoryUpdate', function (event, result) {
            $scope.dataTypeCategory = result;
        });

        $scope.showDeleteConfirm = function (id) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'deleteConfirmation.html',
                scope: $scope,
                controller: function ($scope) {
                    $scope.delete = function (id) {
                        DataTypeCategory.delete({ id: dataTypeCategory.id },
                            function (result) {
                                DataTypeMasterRecordCount.updateCounts();
                                modalInstance.close(result);
                                $state.go('dataTypeCategory');
                            });
                    };

                    $scope.closeConfirmation = function () {
                        modalInstance.dismiss('cancel');
                    }

                },
                size: 'md',
                resolve: {
                    item: function () {
                        return DataTypeCategory.get({ id: id });
                    }
                }
            });
        };

        function findPropertyMapping(propertyName, propertyMappingList) {
            if (propertyMappingList != null) {
                for (var i = 0; i < propertyMappingList.length; i++) {

                    if (propertyName.toLowerCase() == propertyMappingList[i].property.name.toLowerCase()) {
                        return propertyMappingList[i];
                    }
                }
            }
            return null;
        }
    });
