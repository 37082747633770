(function () {
   'use strict';

   angular.module('acadiamasterApp')
       .config(function ($stateProvider) {
           $stateProvider
               .state('role', {
                   parent: 'accessControl',
                   url: '/roles',
                   data: {
                       authorities: ['ROLE_ADMIN']
                   },
                   views: {
                      'content@': {
                          templateUrl: 'admin-templates/admin/role/role.html',
                          controller: 'RoleController'
                      }
                   },
                   resolve: {
                       translatePartialLoader : [ '$translate', '$translatePartialLoader',
                           function($translate, $translatePartialLoader) {
                               $translatePartialLoader.addPart('global');
                               return $translate.refresh();
                           } ]
                   }
               });
       });
}());
