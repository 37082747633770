(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('checkMultiSubscriptionConfig', function () {
        return {
            restrict: 'E',
            template: '',
            scope: {
                condition: '='
            },
            link: function ($scope) {
            }
        };
    });
})();
