(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for HasFormAssociationConditionModel model
     */
    app.factory('HasFormAssociationConditionModel', function (QObjectModel, ProgramTestConstantsService,
                                                                FormLoadUtil) {
        /***************************************************************
         * private functions
         **************************************************************/

        HasFormAssociationConditionModel.inheritsFrom(QObjectModel);

        function HasFormAssociationConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.HAS_FORM_ASSOCIATION);

            //initialized the variables
            this.formId = null;
            this.formAssociated = true;

            this._form = null;
        }

        /**
         * convert the current UI model to dto format
         */
        HasFormAssociationConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.formId = this.formId;
            dto.formAssociated = this.formAssociated;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        HasFormAssociationConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.formId = dto.formId;
            this.formAssociated = dto.formAssociated;

            FormLoadUtil.loadForm(this);
        };

        HasFormAssociationConditionModel.prototype.setForm = function (form) {
            this._form = form;
            this.formId = form == null ? null : form.id;
        };

        HasFormAssociationConditionModel.prototype.getForm = function () {
            return this._form;
        };

        HasFormAssociationConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var formMsg = 'Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                (this._form == null ? 'no name' : this._form.name) + ')';

            var associationMsg = ' should be <span class="badge badge-info">' +
                (this.formAssociated ? 'Associated' : 'Not Associated') + '</span>';

            return baseMsg + ' ' + formMsg + associationMsg;
        };

        HasFormAssociationConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.formId == null) {
                this.setErrorMessage('form id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return HasFormAssociationConditionModel;

    });
})();
