(function () {
    'use strict';

    angular.module('acadiamasterApp').service('EmailConfigModel', function (RuleConstantsService) {

        function EmailConfigModel() {
            this.subject = null;
            this.message = null;
            this.file = null;
            this.templateFileName = null;
            this.templateFileUrl = null;
            this.templateFileSize = 0;
            this.emailContentType = RuleConstantsService.notificationTemplateType.MESSAGE;
            this.localizationMap = {};
        }

        EmailConfigModel.prototype.toDto = function (files, data) {
            let dto = {};
            if(!data.emailEnabled) {
                dto =  new EmailConfigModel();
                dto.emailContentType = RuleConstantsService.notificationTemplateType.MESSAGE.name;
                return dto;
            }
            dto.subject = this.subject;
            dto.emailContentType = this.emailContentType? this.emailContentType.name : null;
            if(dto.emailContentType && dto.emailContentType === 'MESSAGE') {
                 dto = toDtoMessage(dto, this);
            } else
            if(dto.emailContentType && dto.emailContentType === 'TEMPLATE') {
                dto = toDtoTemplate(dto, files, this);
            }
            return dto;
        };

        function clearLocalizedTemplate(localizationMap) {
            if (localizationMap && localizationMap['es'] && localizationMap['es']['templateFileUrl']) {
                localizationMap['es']['templateFileUrl'] = null;
                localizationMap['es']['templateFileName'] = null;
                localizationMap['es']['templateFileSize'] = 0;
            }
            return localizationMap;
        }

        function toDtoMessage(dto, _self) {
            dto.message = _self.message;
            dto.file = null;
            dto.templateFileName = null;
            dto.templateFileUrl = null;
            dto.templateFileSize = 0;
            dto.localizationMap = clearLocalizedTemplate(_self.localizationMap);
            return dto;
        }

        function clearLocalizedMessage(localizationMap) {
            if(localizationMap && localizationMap['es'] && localizationMap['es']['message']) {
                localizationMap['es']['message'] = null;
            }
            return localizationMap;
        }

        function toDtoTemplate(dto, files, _self) {
            dto.message = null;
            if (_self.file != null) {
                dto.templateFileName = _self.file.name;
                dto.templateFileSize = _self.file.size;
                dto.templateFileUrl = _self.file.name;
                files.push(_self.file);
            }
            else if (_self.templateFileUrl == null) {
                dto.templateFileName = null;
                dto.templateFileSize = 0;
            }
            else {
                dto.templateFileUrl = _self.templateFileUrl;
                dto.templateFileName = _self.templateFileName;
                dto.templateFileSize = _self.templateFileSize;
            }

            dto.localizationMap = clearLocalizedMessage(_self.localizationMap);
            if (dto.localizationMap) {
                let localization = dto.localizationMap;
                for (let key in localization) {
                    const object = localization[key];
                    const file = object['file'];
                    if (file != null) {
                        object['templateFileName'] = file.name;
                        object['templateFileUrl'] = file.name;
                        object['templateFileSize'] = file.size;
                        files.push(file);
                        delete object['file'];
                    }
                }
            }
            return dto;

        }

        EmailConfigModel.prototype.fromDto = function (dto) {
            this.subject = dto.subject;
            this.message = dto.message;
            this.templateFileName = dto.templateFileName;
            this.templateFileUrl = dto.templateFileUrl;
            this.templateFileSize = dto.templateFileSize;

            if (dto.emailContentType) {
                this.emailContentType = RuleConstantsService.getObjectByName(RuleConstantsService.notificationTemplateType,
                    dto.emailContentType);
            } else {
                if (this.templateFileUrl) {
                    this.emailContentType = RuleConstantsService.notificationTemplateType.TEMPLATE;
                } else {
                    this.emailContentType = RuleConstantsService.notificationTemplateType.MESSAGE;
                }
            }

            this.localizationMap = dto.localizationMap != null ? dto.localizationMap : {};
        };

        /***************************************
         * service return call
         ***************************************/

        return EmailConfigModel;

    });


})();
