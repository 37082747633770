(function () {
    angular.module('acadiamasterApp').factory('AppVersioningService', $http => {
        let baseUrl = 'api/appVersion';

        return {
            createUpdateAppVersion : function (dto) {
                return $http.post(baseUrl, dto);
            },
            deleteAppVersionById : function (id) {
                return $http.delete(`${baseUrl }/${ id}`);
            },
            getAppVersionDetails : function (id) {
                return $http.get(`${baseUrl }/${ id}`);
            },
            getAppVersions : function (requestBody) {
                // eslint-disable-next-line no-undef
                return $http.get(`${baseUrl }?${ $.param(requestBody)}`);
            },

        };
    });
}());
