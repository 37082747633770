/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('tag', function (TagLookup) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/tags/tag.html',
                scope: {
                    parentForm: '=',
                    tagMappings: '=',
                    tagSource: '='
                },
                link: function ($scope) {

                    $scope.settings = {
                        tagSettings: createTagLookAheadSettings()
                    };
                    $scope.formData = {
                        selectedTag: null
                    };
                    $scope.addTag = addTag;
                    $scope.removeTag = removeTag;
                    $scope.tagLookupReady = false;
                    init();

                    function createTagLookAheadSettings() {
                        return {
                            source: [],
                            height: 25,
                            placeHolder: 'Enter a tag name to start searching',
                            searchMode: 'startswithignorecase',
                            select: function (data) {
                                var value;
                                if (data.args == null) {
                                    value = null;
                                } else {
                                    value = data.args.value;
                                }
                                if (value != null) {
                                    value = value.trim();
                                    if (value.length > 0) {
                                        addTag(value);
                                    }
                                }
                            }
                        };
                    }

                    // / Private Functions////

                    function init() {
                        initAllAvailableTagsList();
                    }

                    function initAllAvailableTagsList() {
                        if (TagLookup.isTagLookupReady()) {
                            $scope.settings.tagSettings.source = TagLookup.getTagNames();
                            $scope.tagLookupReady = true;
                        } else {
                            TagLookup.init().then(function (data) {
                                $scope.settings.tagSettings.source = data;
                                $scope.tagLookupReady = true;
                            });
                        }
                    }

                    function createTagMapping(tagName) {
                        return {
                            id: null,
                            tag: createTagDTO(tagName)
                        };
                    }

                    function createTagDTO(tagName) {
                        return {
                            id: null,
                            name: tagName
                        };
                    }

                    function isTagAlreadyAssociated(tag) {
                        var tagMappings = $scope.tagMappings;
                        for (var i = 0; i < tagMappings.length; i++) {
                            if (tagMappings[i].tag.name == tag) {
                                return true;
                            }
                        }
                        return false;
                    }

                    function addTag(value) {
                        value = value.trim();
                        if (value.length > 0 && !isTagAlreadyAssociated(value)) {
                            var newTagMapping = createTagMapping(value);
                            $scope.tagMappings.push(newTagMapping);
                        }
                        $scope.formData.selectedTag = null;
                    }

                    function removeTag(index) {
                        $scope.tagMappings.splice(index, 1);
                    }
                }
            };
        })

})();
