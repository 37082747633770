angular.module('acadiamasterApp')
    .controller('UserImportController', function ($scope,UserImportService,ParseLinks,ngDialog) {
        $scope.page = 0;
        const statusList = ['COMPLETED_WITH_USER_CREATION_FAILURE','FAILED','YET_TO_BEGIN'];

        $scope.loadAll =function() {
            let promise = UserImportService.getUserImportTasks({page: $scope.page, pageSize: 10});
            promise.then(function (response) {
               $scope.links = ParseLinks.parse(response.headers('link'));
               $scope.userImportTasks = response.data;
            }, function (error) {
               console.log(error);
            });
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.startTask = function(taskId){
            ngDialog.openConfirm({
                template: 'runTask-confirmation-dialog'
            }).then(function () {
                UserImportService.startTask(taskId).then(function (response) {
                    $scope.loadAll();
                }).catch(function (error) {
                    console.error(error);
                });
            });
        };

        $scope.showTrigger = function(status) { 
            return statusList.includes(status);
        };

        $scope.loadAll();
    });