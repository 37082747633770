/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an display config with icon and messages
 */

angular.module('acadiamasterApp')
    .factory('ContainerIconMessageDisplayConfig', (LocalizedStringModel, ModelServiceBase, ContainerIconModel) => {

        ContainerIconMessageDisplayConfig.inheritsFrom(ContainerIconModel);

        /**
         * @constructor
         */
        function ContainerIconMessageDisplayConfig(parent) {
            ContainerIconModel.call(this, parent);

            // both title and message are using the same format as string resource
            this.title = new LocalizedStringModel();
            this.message = new LocalizedStringModel();
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerIconMessageDisplayConfig.prototype.toDto = function (files) {
            const dto = ContainerIconModel.prototype.toDto.call(this, files);

            // localized labels
            dto.title = this.title.toDto();
            dto.message = this.message.toDto();

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerIconMessageDisplayConfig.prototype.fromDto = function (dto) {
            if (dto==null) {
                return;
            }
            ContainerIconModel.prototype.fromDto.call(this, dto);

            this.title.fromDto(dto.title);
            this.message.fromDto(dto.message);
        };

        /****************************************
         * service return call
         ****************************************/

        return ContainerIconMessageDisplayConfig;
    });
