(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueEmbeddedTextInput', function (vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/embeddedTextInput.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '='
                },
                link: function ($scope) {
                    $scope.numberOfLineOptions = createNumberOfLineOptions($scope);
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function createNumberOfLineOptions($scope) {
                if (vbrCommonUtil.isNullOrUnavailable($scope.fieldValue.numberOfLines)) {
                    return null;
                }

                return {
                    from: 1, to: 10, step: 1, skin: 'blue'
                };
            }
        });

})();

