
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * Directive for form field value for calendar.
     */
        .directive('formFieldConfigFieldValueLink', function (FormModelServiceFieldValue, LocaleConstantsService, FormConstants, ProgramCacheService) {
            return {
                restrict: 'E',
                templateUrl: "admin-templates/site/forms/configPanel/fieldValue/link.html",
                scope: {
                    fieldValue: '=',
                    parentForm: '=',
                    fieldValueChange: '=',
                    fieldElement: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.mode = $scope.fieldElement.findParentMode();
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.data = {
                        programId: parseInt($scope.fieldValue.internalLinkParameters[FormConstants.linkParameters.PROGRAM_CODE.name]),
                    }
                    $scope.isMapEmpty = function () {
                        return Object.keys($scope.fieldValue.internalLinkParameters).length == 0;
                    };

                    $scope.filterOutWindowSections = function (listToFilterFrom) {
                        var windowSections = [];
                        if (listToFilterFrom != null) {
                            for (var i = 0; i < listToFilterFrom.length; i++) {
                                if (listToFilterFrom[i].sectionType == $scope.constants.sectionTypes.WINDOW) {
                                    windowSections.push(listToFilterFrom[i]);
                                }
                            }
                        }
                        return windowSections;
                    };

                    $scope.selectPage = page => {
                        $scope.fieldValue.pageId = page?.id;
                    };
                    init($scope);
                }
            };

        function init($scope) {
            if ($scope.data.programId) {
                ProgramCacheService.loadProgramById($scope.data.programId).then(
                    function(response) {
                        $scope.programOptions = {
                            setProgram: function(program) {
                                $scope.fieldValue.internalLinkParameters[FormConstants.linkParameters.PROGRAM_CODE.name] = program?.id;
                                $scope.program = program;
                            },
                            getProgram: function() {
                                return response;
                            },
                        };
                    },
                    function(error) {
                        console.error(error);
                    }).finally(function() {
                    $scope.data.isLoading = false;
                });
            } else {
                $scope.programOptions = {
                    setProgram: function(program) {
                        $scope.fieldValue.internalLinkParameters[FormConstants.linkParameters.PROGRAM_CODE.name] = program?.id;
                        $scope.data.programId = program?.id;
                        $scope.program = program;
                    },
                    getProgram: function() {
                        return $scope.program;
                    },
                };
            }
        }

        });

})();
