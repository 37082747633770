angular.module('acadiamasterApp')
    .controller('VXPDataMigrationController', ($scope, ngDialog, CONFIG, VbrToolsService, drcSyncUtilService, OperationStatus) => {
        const vm = this;

        vm.readOnlyServer = CONFIG.readOnlyServer;
        vm.vxpMigrationType = 'VXP_DATA_MIGRATION_OPERATION';
        vm.operationId = null;

        // data migration related
        vm.data = {
            isReady : false,
            operationId: null,
            operationStatus: new OperationStatus(),
        };

        /**
         * migration dialog
         */
        vm.openFullMigrationDialog = (isFullMigration) => {
            if(vm.isReadOnlyServer){
                return;
            }

            // get operation id if there is an operation processing
            VbrToolsService.getOperationIdByOperationType(vm.vxpMigrationType)
                .then(function (result) {
                    vm.data.operationId = result.data.operationId;
                    processMigration(result.data.operationId, isFullMigration);
            }, function (error) {
                console.error(error);
            });

        };

        // Choose either start migration or
        function processMigration(operationId, isFullMigration) {
            if (!operationId) {
                //bring up migration config dialog
                ngDialog.openConfirm({
                    className: 'ngdialog-theme-plain custom-width-medium',
                    controller: 'startMigrationController',
                    controllerAs: 'smc',
                    scope: $scope,
                    resolve: {
                        isFullMigration: () => isFullMigration,
                    },
                    templateUrl: 'admin-templates/site/tools/vxpDataMigration/startMigration.html',
                });
            } else {
                // check status every 10 seconds
                $scope.checkStatusInterval = 10000;

                // if operationId, then present the status data
                drcSyncUtilService.openModelWindow($scope, vm.data, "VXP Data Migration ", null);
                drcSyncUtilService.checkAndShowStatus($scope, vm.data);

            }
        }

        vm.isReady = true;

        return vm;
    });
