/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for a container display config that extends from
 * ContainerIconMessageDisplayConfig Model, and add the following property
 * 1. button text
 */

angular.module('acadiamasterApp').factory('ContainerWithButtonDisplayConfig', (
    LocalizedStringModel, ContainerIconMessageDisplayConfig) => {
    /** *************************************************************
     * constructor
     ************************************************************** */
    ContainerBgImageWithButtonDisplayConfig.inheritsFrom(ContainerIconMessageDisplayConfig);

    function ContainerBgImageWithButtonDisplayConfig(parent) {
        ContainerIconMessageDisplayConfig.call(this, parent);

        this.buttonLabel = new LocalizedStringModel();
        this.showOpenFormButton = true;
    }

    /**
     * convert the current UI model to dto format
     */
    ContainerBgImageWithButtonDisplayConfig.prototype.toDto = function(files) {
        const dto = ContainerIconMessageDisplayConfig.prototype.toDto.call(this, files);
        dto.showOpenFormButton = this.showOpenFormButton;
        if (this.showOpenFormButton) {
            dto.buttonLabel = this.buttonLabel.toDto();
        }
        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerBgImageWithButtonDisplayConfig.prototype.fromDto = function(dto) {
        ContainerIconMessageDisplayConfig.prototype.fromDto.call(this, dto);

        this.buttonLabel.fromDto(dto.buttonLabel);
        this.showOpenFormButton = dto.showOpenFormButton;
    };


    /**
     * validate the entity
     */
    ContainerBgImageWithButtonDisplayConfig.prototype._validateSelf = function () {
        this.clearError();
        if (this.showOpenFormButton && (this.buttonLabel == null || this.buttonLabel.en==null ||
            this.buttonLabel.en.length===0)) {
            const errMsg = 'button label is required';
            this.setErrorMessage(errMsg);
            if (this._parent!=null && this._parent._parent!=null && !this._parent._parent.hasError()) {
                // set container error message to if there are no other error message at container level
                // this is hack for UI display because this object itself doesn't have top level display
                this._parent._parent.setErrorMessage(errMsg);
            }
        }
    };


    /** *************************************
     * private function call
     ************************************** */


    /** *************************************
     * service return call
     ************************************** */

    return ContainerBgImageWithButtonDisplayConfig;
});
