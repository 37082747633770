(function () {
    'use strict';

    /**
     * string management directive
     */
    angular.module('acadiamasterApp').directive('exportWizardStepStrings', function (StringManagementConstants) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepStringManagement.html',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '='
            },
            link: function ($scope) {
                $scope.cs = StringManagementConstants;
            }
        };

        /******************************************************************************
         * private functions
         ******************************************************************************/
    });
})();
