/**
 * @author bobbybounvichit on 01/30/2019
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('WhitelistService', function ($http, localWeb) {
            return {
                getWhitelistingEnabled: function (id) {
                    return $http.get(localWeb.createUrl('api/services/vns/providers/program'),{ params: {id: id} });
                },
                toggleWhitelisting: function(whitelistRequest) {
                    return $http.put(localWeb.createUrl('api/services/vns/providers/program'), whitelistRequest);
                },
                deleteWhitelistNumber: function (id) {
                    return $http.delete(localWeb.createUrl('api/services/vns/whitelist/' + id));
                },
                updateWhitelistNumber: function(id,phoneNumber) {
                    return $http.put(localWeb.createUrl('/api/services/vns/whitelist'), { id: id, whitelistNumber: phoneNumber });
                },
                createWhitelistNumber: function (phoneNumber) {
                    return $http.post(localWeb.createUrl('/api/services/vns/whitelist'), { whitelistNumber: phoneNumber });
                },
                getWhitelistedNumbers: function() {
                    return $http.get(localWeb.createUrl('api/services/vns/whitelist'));
                }
            };
        });
})();
