import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an container display config of MultiForm Return of Result
 * this model extends from ContainerMultipleItemsDisplayConfigImpl and few
 * more properties
 */
angular.module('acadiamasterApp')
    .factory('ContainerMultiFormRoRDisplayConfigImpl', (
        ContainerMultipleItemsDisplayConfigImpl, LocalizedStringModel) => {
        const {classes} = ProgramContainerConstantsService;

        /**
         * @constructor
         */
        ContainerMultiFormRoRDisplayConfigImpl.inheritsFrom(ContainerMultipleItemsDisplayConfigImpl);

        function ContainerMultiFormRoRDisplayConfigImpl(parent) {
            ContainerMultipleItemsDisplayConfigImpl.call(this, parent);

            this.notCompletedMessage = new LocalizedStringModel();
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerMultiFormRoRDisplayConfigImpl.prototype.toDto = function (files) {
            const dto = ContainerMultipleItemsDisplayConfigImpl.prototype.toDto.call(this, files);
            dto[classes.classReferenceKey] = classes.ContainerMultiFormRoRDisplayConfigImpl;

            dto.notCompletedMessage = this.notCompletedMessage.toDto();

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerMultiFormRoRDisplayConfigImpl.prototype.fromDto = function (dto) {
            if (dto==null) {
                return;
            }

            ContainerMultipleItemsDisplayConfigImpl.prototype.fromDto.call(this, dto);

            this.notCompletedMessage.fromDto(dto.notCompletedMessage);
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerMultiFormRoRDisplayConfigImpl;
    });
