/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('localizedFileInput', function (LocaleConstantsService, vbrCommonUtil, LocalizationService, $document) {

            /**
             *
             * @param $scope
             */
            function init($scope) {
                // Create list of locale object
                $scope.list = [];
                $scope.data = { isPopupOpen: false };
                $scope.availableLocale = [];

                for (var prop in LocaleConstantsService.locale) {
                    // your code
                    var object = LocaleConstantsService.locale[prop];
                    $scope.availableLocale.push(object);
                }

                if ($scope.localizationMap != null) {
                    initUIList($scope);
                }
            }

            /**
             *
             * @param $scope
             */
            function initUIList($scope) {
                for (var key in $scope.localizationMap) {
                    var object = $scope.localizationMap[key];
                    var locale = vbrCommonUtil.getObjectByName(LocaleConstantsService.locale, key);
                    var url = object[$scope.fileUrl];
                    var name = object[$scope.fileName];
                    var fileData = object[$scope.file];
                    var size = object[$scope.fileSize];
                    if (url != null && url.length > 0 && name != null && name.length) {
                        // Add in UI list
                        var obj = {
                            selectedLocale: locale,
                            url: url,
                            name: name,
                            fileData: fileData,
                            size: size
                        };
                        $scope.list.push(obj)
                        $scope.url = url;
                        $scope.name = name;
                        if (fileData != null && fileData != undefined) {
                            $scope.fileData = fileData;
                        }
                        // Remove added locale from available list
                        updateAvailableLocaleList($scope, locale, null, false);
                    }

                }
            }

            function addWatchOnList($scope) {
                $scope.$watch('list', function (newValue, oldValue) {
                    if ($scope.localizationMap == null) {
                        $scope.localizationMap = {};
                    }

                    // Process old list
                    _.forEach(oldValue, function (oldObj) {
                        if (oldObj != null && oldObj.selectedLocale != null) {
                            // Set old locale value to null
                            LocalizationService.addValueToMap($scope.localizationMap, oldObj.selectedLocale.name, $scope.fileName, null);
                            LocalizationService.addValueToMap($scope.localizationMap, oldObj.selectedLocale.name, $scope.fileUrl, null);
                            LocalizationService.addValueToMap($scope.localizationMap, oldObj.selectedLocale.name, $scope.file, null);
                        }
                    });

                    // Process new value
                    _.forEach(newValue, function (newObj) {
                        if (newObj != null && newObj.selectedLocale != null) {
                            // Add new value to map
                            LocalizationService.addValueToMap($scope.localizationMap, newObj.selectedLocale.name, $scope.fileName, newObj.name);
                            LocalizationService.addValueToMap($scope.localizationMap, newObj.selectedLocale.name, $scope.fileUrl, newObj.url);
                            LocalizationService.addValueToMap($scope.localizationMap, newObj.selectedLocale.name, $scope.file, newObj.fileData);
                        }
                    });

                }, true);
            }

            function updateAvailableLocaleList($scope, locale, localeIndex, isAddLocale) {
                if ($scope.availableLocale == null) {
                    return;
                }
                if (isAddLocale) {
                    // Add locale to available list
                    $scope.availableLocale.push(locale);
                } else {
                    // Remove locale from available list
                    var index = localeIndex;
                    if (localeIndex == null && locale != null) {
                        index = _.findIndex($scope.availableLocale, { name: locale.name });

                    }
                    $scope.availableLocale.splice(index, 1);
                }
            }

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/util/localization/fileInput.html',
                scope: {
                    localizationMap: '=',
                    fileName: '@',
                    fileUrl: '@',
                    fileSize: '@',
                    fileAspectRatio: '@',
                    file: '@',
                    fileDisplaySize: '=',
                    inputType: '=',
                    allowedExtensions: '=',
                    maxFileSize: '=',
                    allowMimeType: '=',
                    maxLength: '=',
                    minLength: '=',
                    disabled: '='
                },
                link: function ($scope) {
                    $scope.name = null;
                    $scope.url = null;
                    $scope.fileData = null;
                    $scope.lcs = LocaleConstantsService;
                    init($scope);
                    // Watch localizedText and modify value in localizationMap
                    addWatchOnList($scope);
                    $scope.addLocaleObject = function (locale, localeIndex) {
                        var obj = {
                            selectedLocale: locale,
                            url: null,
                            name: null,
                            fileData: null,
                            size: null
                        };
                        $scope.list.push(obj)
                        // Remove added locale from available list
                        updateAvailableLocaleList($scope, locale, localeIndex, false);
                    };
                    $scope.removeLocaleObject = function (index, locale) {
                        LocalizationService.removeLocaleObject($scope.list, index);
                        $scope.name = null;
                        $scope.url = null;
                        $scope.fileData = null;
                        // Add removed locale in available list
                        updateAvailableLocaleList($scope, locale, null, true);
                    };
                    $scope.getSupportedLangCount = function () {
                        return LocalizationService.getSupportedLangCount();
                    };

                    $scope.localeChanged = function (localeObj) {
                        _.forEach($scope.list, function (obj) {
                            if (obj.selectedLocale == localeObj.selectedLocale) {
                                obj.url = localeObj.url;
                                obj.name = localeObj.name;
                                obj.fileData = localeObj.fileData;
                                return;
                            }
                        })
                    };
                    $scope.closePopup = function () {
                        $scope.data.isPopupOpen = false;
                    };

                    $scope.onPopoverOpened = function ($event) {
                        $document[0].body.click();
                        $event.stopPropagation();
                        $scope.data.isPopupOpen = true;

                        if (LocalizationService.getSupportedLangCount() == 1) {
                            $scope.addLocaleObject($scope.availableLocale[0], 0);
                        }
                    };
                }
            };
        });

})();
