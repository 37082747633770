/**
 * directive for displaying a single action specifically for the deprecated personalized form
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('ruleElementConfigActionView', function () {

        function init($scope) {
            $scope.ready = true;
        }

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.view.html',
            scope: {
                selectedAction: '='
            },
            link: function ($scope) {
                $scope.ready = false;
                init($scope);
            }
        };
    });
})();
