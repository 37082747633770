(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for IsSubscribedToProgramCondition model
     */
    app.factory('IsSubscribedToProgramConditionModel', function (QObjectModel, ProgramTestConstantsService,
                                                                      ProgramLoadUtil) {
        /***************************************************************
         * private functions
         **************************************************************/

        IsSubscribedToProgramConditionModel.inheritsFrom(QObjectModel);

        function IsSubscribedToProgramConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.IS_SUBSCRIBED_TO_PROGRAM);

            //initialized the variables
            this.programId = null;
            this.active = true;

            this._program = null;
        }

        /**
         * convert the current UI model to dto format
         */
        IsSubscribedToProgramConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.programId = this.programId;
            dto.active = this.active;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any format you have on the current object
         * @param dto
         */
        IsSubscribedToProgramConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.programId = dto.programId;
            this.active = dto.active;

            ProgramLoadUtil.loadProgram(this);
        };

        IsSubscribedToProgramConditionModel.prototype.setProgram = function (program) {
            this._program = program;
            this.programId = program == null ? null : program.id;
        };

        IsSubscribedToProgramConditionModel.prototype.getProgram = function () {
            return this._program;
        };

        IsSubscribedToProgramConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var programMsg = 'Subscription to Program (' + (this.programId == null ? 'no id' : this.programId) + ' | ' +
                (this._program == null ? 'no name' : this._program.name) + ')';

            var activeMsg = ' should be <span class="badge badge-info">' +
                (this.active ? 'Active' : 'Not Active') + '</span>';

            return baseMsg + ' ' + programMsg + activeMsg;
        };

        IsSubscribedToProgramConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.programId == null) {
                this.setErrorMessage('program id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return IsSubscribedToProgramConditionModel;

    });
})();
