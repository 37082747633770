/**
 * Created by Jason Cao on 08/12/2019
 * just a copy of the existing treenode basically, probably need more work later
 * directive for display a tree node view of one program container
 */
angular.module('acadiamasterApp').directive('treeNodeProgramContainer', () => ({
    link($scope) {
        $scope.getName = function () {
            return $scope.container.name;
        };

        $scope.selectContainer = function () {
            $scope.selector.selectItem($scope.container);
        };

    },
    restrict: 'E',
    scope: {
        container: '=',
        parent: '=',
        selector: '=',
        showTemplatePreview: '=?',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
        + 'treeNode/programContainer.treeNode.html',
}));



