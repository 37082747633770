'use strict';

(function () {

    angular.module('acadiamasterApp').directive('correctiveActionCondition', function (ProgramTestConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/correctiveAction.html',
            scope: {
                element: '='
            },
            link: function ($scope) {
                $scope.correctiveActionType = ProgramTestConstantsService.correctiveActionEnum;

                $scope.clearMapValues = function () {
                    $scope.element.mapValues = {};
                }
            }
        };
    });
})();