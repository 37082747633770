(function () {


    angular.module('acadiamasterApp')
        .directive('shareDataConfig', function (FormConstants, ExpressionConstantsService) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/dataSharing/dataSharing.config.html',
                scope       : {
                    parentForm : '=',
                    page       : '=',
                },
                link : function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.expConstants = ExpressionConstantsService;
                },
            };
        });

})();
