(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of a list of Pages
     */
        .directive('treeNodePreCondition', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/treeNode/preConditionNode.html',
                scope: {
                    selector: '=',
                    preCondition: '=',
                    testCase: '='
                }
            };

        });
})();

