/**
 * simple status badge directive, used to display the badge
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('statusBadge', function (StatusType) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/statusBadge.html',
            scope: {
                status: '='
            },
            link: function ($scope) {
                $scope.st = StatusType;
            }
        };
    });

})();

