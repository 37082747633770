import _ from 'lodash';
(function () {
    angular.module('acadiamasterApp').controller('FormCloneController', ($scope, FormEditImportService, Form) => {
        $scope.formDto = $scope.ngDialogData.form;
        $scope.defaultSelection = 'Choose Program';
        $scope.selectedProgramCode = $scope.defaultSelection;
        $scope.selectedProgram = {};
        $scope.isEnabled = Boolean(!$scope.formDto.programId);
        $scope.customStyles = {
            childWidth  : '100%',
            parentWidth : '75%',
        };

        $scope.isReady = () => {
            if (!$scope.formDto.programId && Object.keys($scope.selectedProgram).length === 0) {
                return false;
            }
            return true;
        };

        $scope.cloneForm = function () {
            Form.get({
                id         : $scope.formDto.latestFormVersion.formId,
                projection : Form.projections.ACTIVE_ONLY,
            }).$promise.then(async formModel => {
                if (formModel.programId) {
                    formModel.programId = null;
                    formModel.programCode = null;
                } else {
                    formModel.programId = $scope.selectedProgram.programId;
                    formModel.programCode = $scope.selectedProgram.code;
                }
                // erase duplicate values while cloning Redcap form
                if (formModel.externalSurveyName) {
                    delete formModel.externalSource;
                    delete formModel.externalSurveyName;
                    let editMode = formModel.formVersions[0].editMode;
                    _.forEach(editMode.pages, page => {
                        _.forEach(page.sections, section => {
                            _.forEach(section.formComponents, component => {
                                _.forEach(component.formComponentFields, field => {
                                    if (field.externalFieldName) {
                                        delete field.externalFieldName;
                                    }
                                });
                            });
                        });
                    });
                }
                FormEditImportService.performInEditImport(formModel, true, () => {
                    $scope.confirm();
                });
            });
        };
    });
}());
