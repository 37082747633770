(function () {
    'use strict';


    /**
     * util class for creating any pre condition by type
     */

    angular.module('acadiamasterApp').factory('PreconditionCreationUtil',
        function (PreConditionEHRConfigModel, PreConditionFeatureToggleModel, PreConditionFormExistModel,
                  PreConditionProgramDistributionExistModel, PreConditionProgramExistModel,
                  PreConditionPropertyExistModel, PreConditionRuleActionExistModel,
                  PreConditionRuleExistModel, PreConditionSyncDeviceToggleModel, ProgramTestConstantsService, PreConditionCreatorModel) {
            // two local variable that holds the creator list and creator map
            var pcList = [];
            var pcMap = {};

            init();

            function init() {
                // configuration and toggles
                addCreator(PreConditionEHRConfigModel, ProgramTestConstantsService.preConditionType.EHR_CONFIG_EXIST);
                addCreator(PreConditionFeatureToggleModel, ProgramTestConstantsService.preConditionType.FEATURE_TOGGLED_STATUS);
                addCreator(PreConditionSyncDeviceToggleModel, ProgramTestConstantsService.preConditionType.SYNC_DEVICES_TOGGLED_STATUS);

                // program and program distribution
                addCreator(PreConditionProgramExistModel, ProgramTestConstantsService.preConditionType.PROGRAM_EXIST, true);
                addCreator(PreConditionProgramDistributionExistModel, ProgramTestConstantsService.preConditionType.PROGRAM_DISTRIBUTION_EXIST);

                // profile property
                addCreator(PreConditionPropertyExistModel, ProgramTestConstantsService.preConditionType.PROPERTY_EXIST, true);

                // form
                addCreator(PreConditionFormExistModel, ProgramTestConstantsService.preConditionType.FORM_EXIST);

                // rule
                addCreator(PreConditionRuleExistModel, ProgramTestConstantsService.preConditionType.RULE_EXIST, true);
                addCreator(PreConditionRuleActionExistModel, ProgramTestConstantsService.preConditionType.BUSINESS_RULE_ACTION_EXIST);
            }

            function addCreator(modelClass, modelType, hasDividerAbove) {
                var creator = new PreConditionCreatorModel(modelClass, modelType, hasDividerAbove);
                pcList.push(creator);
                pcMap[modelType.name] = creator;
            }

            function createPreconditionByType(typeName, parent) {
                var creator = pcMap[typeName];

                if (creator==null) {
                    console.error("cant find creator for type name : " + typeName);
                    return null;
                }

                return creator.createNew(parent);
            }

            /***************************************
             * service return call
             ***************************************/
            return {
                getAllPreconditionCreators: function () {
                    return pcList;
                },
                createPreconditionByType: createPreconditionByType,
            };

        });
})();
