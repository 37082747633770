(function () {
    'use strict';

    /***************************************************************
     * Precondition for property exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionPropertyExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, PropertyLoadUtil) {

            PreConditionPropertyExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for property exist with status
             * @constructor
             */
            function PreConditionPropertyExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.PROPERTY_EXIST);

                this.propertyId = null;

                this._property = null;
            }

            PreConditionPropertyExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.propertyId = dto.propertyId;

                PropertyLoadUtil.loadProperty(this);
            };

            PreConditionPropertyExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.propertyId = this.propertyId;

                return dto;
            };


            PreConditionPropertyExistModel.prototype.setProperty = function (property) {
                this._property = property;
                this.propertyId = property==null ? null : property.id;
            };

            PreConditionPropertyExistModel.prototype.getProperty = function () {
                return this._property;
            };

            PreConditionPropertyExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var propertyMsg = 'Property (' + (this.propertyId == null ? 'no id' : this.propertyId) + ' | ' +
                    (this._property == null ? 'no name' :  this._property.name) + ')';

                return typeMsg + propertyMsg +  ' should exist ';
            };

            PreConditionPropertyExistModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.propertyId == null) {
                    this.setErrorMessage('property id is required');
                }
            };

            return PreConditionPropertyExistModel;
        });
})();
