(function () {
    angular.module('acadiamasterApp')
        .directive('captureDataConfig', (FormConstants, ExpressionConstantsService, filterFieldsUtil) => {
            return {
                link : function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.formTargetType = FormConstants.formTargetType.FORM_FIELD_UNVERSIONED;
                    $scope.filterFieldsUtil = filterFieldsUtil;
                    $scope.editMode = $scope.page.findParentMode()._parent.editMode;

                    $scope.isExternalTest = function () {
                        return $scope.page.formPageMetadata.dataCaptureMetadata.device
                        === $scope.constants.DataCaptureSource.EXTERNAL_JAVASCRIPT_TEST;
                    };
                },
                restrict : 'E',
                scope    : {
                    page       : '=',
                    parentForm : '=',
                },
                templateUrl : 'admin-templates/site/forms/configPanel/dataCapture/dataCapture.config.html',
            };
        });
}());
