/**
 * directive for configuring the content value
 */
angular.module('acadiamasterApp').directive('contentValueConfigSimple', (
    TemplateTextValue, TemplateImageValue, TemplateIconValue, TemplateHtmlValue,
    TemplateButtonValueSimple, TemplateButtonValueLockPauseEnabled, TemplateButtonValueAllStates,
    TemplateImageBackgroundValue, TemplateBackgroundValue, TemplateBooleanValue, TemplateClickActionValue
) => ({
    link($scope) {
        // various functions to check the value type
        $scope.isIconType = () => $scope.contentValue instanceof TemplateIconValue;
        $scope.isTextType = () => $scope.contentValue instanceof TemplateTextValue;
        $scope.isImageType = () => $scope.contentValue instanceof TemplateImageValue;
        $scope.isHtmlType = () => $scope.contentValue instanceof TemplateHtmlValue;
        $scope.isSimpleButtonType = () => $scope.contentValue instanceof TemplateButtonValueSimple;
        $scope.isMultiStateButtonType = () => $scope.contentValue instanceof TemplateButtonValueLockPauseEnabled ||
            $scope.contentValue instanceof TemplateButtonValueAllStates;
        $scope.isImageBackgroundType = () => $scope.contentValue instanceof TemplateImageBackgroundValue;
        $scope.isImageBackgroundType = () => $scope.contentValue instanceof TemplateImageBackgroundValue;
        $scope.isBackgroundType = () => $scope.contentValue instanceof TemplateBackgroundValue;
        $scope.isBooleanType = () => $scope.contentValue instanceof TemplateBooleanValue;
        $scope.isClickActionType = () => $scope.contentValue instanceof TemplateClickActionValue;

        $scope.data = {
            hasActionTarget : $scope.isSimpleButtonType(),
        };
    },
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
        linkedEntities: '<?',
        containerList: '<',
        containerType: '<?',
        nodeType: '<?',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/' +
        'contentValueConfigSimple.html',
}));

