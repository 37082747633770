(function () {
    'use strict';

    /**
     * Method to modify components which include title with 22 dp, paragraph followed by text input
     * Modify these kind of sets to update the display config of all 3 fields and add a label at the end of file
     */
    angular.module('acadiamasterApp').factory('UpdateTitleParagraphTextInputComboAction', function (ScriptActionBaseModel, FormScriptActionUtil,
                                                                                                    FormModelFieldService, FormConstants) {

        // defining some local variable to make things easier
        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateTitleParagraphTextInputComboAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateTitleParagraphTextInputComboAction() {
            this.name = 'Update title, paragraph and text input combination to new display setting and an added label at the botto,';
            this.steps = [
                {
                    ticketId: 'AC-24460',
                    description: 'find a sequence where title, paragraph and text input setting match --> '
                    + ' Update the settings to those mentioned in AC and add a label at the bottom',
                    searchFunction: findTitleParagraphTextInputCombo,
                    changeFunction: updateDisplaySettingsAndAddLabel
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /**
         * Find components that contain the required combination
         * Save component as well as the index of first title field in target object
         */
        function findTitleParagraphTextInputCombo(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

            var targets = [];
            // Get all components
            var components = fsa.findComponentsInMode(formMode);

            _.forEach(components, function (component) {
                // If component has less than 3 fields skip it
                if (component == null || component.formComponentFields == null || component.formComponentFields.length < 3) {
                    return;
                }
                _.forEach(component.formComponentFields, function (field, index) {
                    // See if the title config matches
                    var titleMatched = isTitleMatch(field);
                    if (titleMatched) {
                        // if title config matches then try to match paragraph config and text input config for next 2 fields
                        var paragraphMatched = component.formComponentFields.length > index + 1 && isParagraphMatch(component.formComponentFields[index + 1]);
                        var textInputMatched = component.formComponentFields.length > index + 2 && isTextInputMatch(component.formComponentFields[index + 2]);
                        if (paragraphMatched && textInputMatched) {
                            // If we get a match then add the component as target with index of title
                            var target = {
                                component: component,
                                titleIndex: index,
                                getDescriptionAsHtml: function () {
                                    return component.getDescriptionAsHtml();
                                }
                            };
                            targets.push(target);
                        }
                    }
                });


            });

            return targets;
        }

        function isTitleMatch(field) {
            return field.type == constants.fieldsType.TEXT_LABEL && fsa.isFontSizeEqual(field.displayConfig, 22);
        }

        function isTextInputMatch(field) {
            return field.type == constants.fieldsType.TEXT_INPUT && fsa.isStyleTypeEqual(field.fieldValue, constants.inputTextStyle.styleType.PRIMARY);
        }

        function isParagraphMatch(field) {
            return !!(field.type == constants.fieldsType.TEXT_LABEL && fsa.isPaddingLeftEqual(field.displayConfig, 22)
                && fsa.isPaddingRightEqual(field.displayConfig, 22) && fsa.isPaddingBottomEqual(field.displayConfig, 16)
                && fsa.isPaddingTopEqual(field.displayConfig, 0));
        }

        /**
         * Update the settings for 3 fields in component and add additional label
         */
        function updateDisplaySettingsAndAddLabel(target) {
            var component = target.component;

            // Modify display for title field
            var titleDc = component.formComponentFields[target.titleIndex].displayConfig;
            fsa.updateColor(titleDc, '#262261');
            fsa.updateBgColor(titleDc, 'white');
            fsa.updateFontSize(titleDc, 14);
            fsa.updateFontName(titleDc, fsa.fontNames.notoSans);
            fsa.updateFontStyle(titleDc, false, false, true, false);
            fsa.updatePadding(titleDc, 43, 15, 30, 30);
            fsa.updateGravity(titleDc, constants.gravity.CENTER, constants.gravity.MIDDLE);

            // Modify display for paragraph
            var paragraphDc = component.formComponentFields[target.titleIndex + 1].displayConfig;
            fsa.updateColor(paragraphDc, '#333333');
            fsa.updateBgColor(paragraphDc, 'white');
            fsa.updateFontSize(paragraphDc, 12);
            fsa.updateFontName(paragraphDc, fsa.fontNames.notoSans);
            fsa.updateFontStyle(paragraphDc, false, false, false, false);
            fsa.updatePadding(paragraphDc, 0, 20, 30, 30);
            fsa.updateGravity(paragraphDc, constants.gravity.LEFT, constants.gravity.MIDDLE);

            // Modify display for text input
            var textInputField = component.formComponentFields[target.titleIndex + 2];
            fsa.updateStyleType(textInputField.fieldValue, constants.inputTextStyle.styleType.DEFAULT);
            fsa.updateWidth(textInputField.displayConfig, 100, '%');
            fsa.updatePadding(textInputField.displayConfig, 0, 0, 30, 30);

            // Add new label with required specs
            component.addNewField(FormModelFieldService.getLabelFieldType());
            var newLabel = component.formComponentFields[component.formComponentFields.length - 1];
            var newLabelDc = newLabel.displayConfig;
            newLabel.fieldValue.value = textInputField.fieldValue.value;
            newLabel.fieldValue.localizationMap = textInputField.fieldValue.localizationMap;
            fsa.updateColor(newLabelDc, '#333333');
            fsa.updateBgColor(newLabelDc, 'white');
            fsa.updateFontSize(newLabelDc, 12);
            fsa.updateFontName(newLabelDc, fsa.fontNames.notoSans);
            fsa.updateFontStyle(newLabelDc, false, false, false, false);
            fsa.updatePadding(newLabelDc, 8, 20, 30, 30);
            fsa.updateGravity(newLabelDc, constants.gravity.LEFT, constants.gravity.MIDDLE);


        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateTitleParagraphTextInputComboAction;
    });

})();
