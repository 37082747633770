(function () {
    'use strict';
    var app = angular.module('acadiamasterApp');

    /**
     * a field with condition object that contains field, local ids of containers this field's visibility
     * should be depended on
     */
    app.factory('FieldWithCondition', function (ViewModeGeneratorUtilService, AlertService,
                                                TextLabelReferenceFieldModel, FormModelSubFieldService,
                                                ExpressionConstantsService, ExpressionParserService, FormScriptActionUtil,
                                                ViewTextOrNumberInputFieldModel, ViewTakeAPictureFieldModel, FormConstants,MatrixQuestionnaireResponseModel) {
        
        /**
         * constructor for the class
         * @param field - field this is object is wrapping around
         * @constructor
         */
        function FieldWithCondition(field) {
            this.field = field;
            this.targets = [];
            this.refField={};
            this.subFields=[];
            this.fieldAbove={};
            this.fieldValue={};
        }

        /**
         * check if the text of current field is equal to the text of the other field, this function
         * this function should only be applied with both internal field and other field is type of text label,
         * and it only compares the english text label
         * @param otherField - other field to compare with
         * @returns {boolean} - true if the text is true on current field and other field, false otherwise
         */
        FieldWithCondition.prototype.isTextEqual = function (otherField) {
            if (this.field == null || !ViewModeGeneratorUtilService.isLabelField(this.field) ||
                otherField == null || !ViewModeGeneratorUtilService.isLabelField(otherField)) {
                return false;
            }

            return textEqualTrimmed(getText(this.field), getText(otherField));
        };

        /**
         * add a target for this label
         * @param targetField - target field
         */
        FieldWithCondition.prototype.addTarget = function (targetField) {
            this.targets.push(targetField);
        };

        /**
         * getting the page associated with the internal field
         */
        FieldWithCondition.prototype.getPage = function () {
            if (this.field == null) {
                return null;
            }

            // go up the parent chain to find component, section, page
            return this.field._parent._parent._parent;
        };

        /**
         * getting the page local id associated with the internal field
         */
        FieldWithCondition.prototype.getPageLocalId = function () {
            var page = this.getPage();

            return page == null ? null : page.localId;
        };



        FieldWithCondition.prototype.addMatrixResponseField = function (component, referenceFieldIdMap) {
            var field = this.field;
            var refField = new MatrixQuestionnaireResponseModel(component);

            refField.name = ViewModeGeneratorUtilService.getRefFieldName(field);

            var fieldValue = refField.fieldValue;
            fieldValue.localId = field.localId;
            fieldValue.referenceFieldDisplayStyle = FormConstants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;

            updateVisibilityBasedOnFieldEntry(refField.displayConfig, this.targets);

            updateReferenceFieldIdMap(referenceFieldIdMap, this.targets);

            component.formComponentFields.push(refField);
        };

        /**
         * add reference field to the component that references internal field
         * @param component - component to add reference field into
         * @param referenceFieldIdMap - fields that has already been referenced before in some labels visibility condition
         *           this map will be updated if the internal field is a label field with targets
         */
        FieldWithCondition.prototype.addReferenceToComponent = function (component, referenceFieldIdMap) {
            var field = this.field;

            if (component == null || field == null) {
                // should never happen, just to be safe
                return;
            }

            var fieldTypeName = field.type.name;

            switch (fieldTypeName) {
            case FormConstants.fieldsType.TEXT_LABEL.name:
                this.addLabelReference(component, referenceFieldIdMap);
                break;
            case FormConstants.fieldsType.MULTI_SELECTOR.name:
                addReferenceForFieldWithSubFields(referenceFieldIdMap, component, field, 'answerTextAllWithEmbedded', null);
                break;
            case FormConstants.fieldsType.RADIO_SELECTOR.name:
            case FormConstants.fieldsType.DROPDOWN.name:
            case FormConstants.fieldsType.API_DROPDOWN.name:
                addReferenceForFieldWithSubFields(referenceFieldIdMap, component, field, 'answerTextAll', null);
                break;

            case FormConstants.fieldsType.SLIDER.name:
                addReferenceForFieldWithSubFields(referenceFieldIdMap, component, field, 'value', null);
                break;

            case FormConstants.fieldsType.DAY_SELECTOR.name:
                addReferenceForFieldWithSubFields(referenceFieldIdMap, component, field, 'value', 'dateFormat: \'MMM dd, yyyy\'');
                break;
            case FormConstants.fieldsType.TAKE_PICTURE.name:
                this.addTakePictureReferenceWithNoSubfield(component, field);
                break;
            case FormConstants.fieldsType.TEXT_INPUT.name:
            case FormConstants.fieldsType.NUMBER_INPUT.name:
                addReferenceWithNoSubfield(referenceFieldIdMap, component, field, false);
                break;
            
            case FormConstants.fieldsType.BOOLEAN_SELECTOR.name:
                addReferenceWithNoSubfield(referenceFieldIdMap, component, field, true);
                break;

            case FormConstants.fieldsType.PHONE_INPUT.name:
            case FormConstants.fieldsType.WHEEL.name:
            case FormConstants.fieldsType.VIDEO_PLAYER.name:
            case FormConstants.fieldsType.SIGNATURE_BOX.name:
            case FormConstants.fieldsType.TIME_PICKER.name:
                AlertService.warning('Unsupported field type encountered, field id : ' + field.formField.id + ' of type ' + fieldTypeName);
                break;

            case FormConstants.fieldsType.EMBEDDED_TEXT_INPUT.name:
            case FormConstants.fieldsType.MATRIX_QUESTION.name:
            case FormConstants.fieldsType.TYPE_AHEAD.name:
                // Nothing is required for embedded text input fields
                break;
            default:
                AlertService.error('Unexpected field type encountered, field id : ' + field.formField.id + ' of type ' + fieldTypeName);
                break;
            }
        };

        /**
         * adding label reference for a specific text label field
         * @param component - component to add the reference field to
         * @param referenceFieldIdMap - fields that has already been referenced before in some labels visibility condition
         *           this map will be updated if the internal field is a label field with targets
         * @returns {null}
         */
        FieldWithCondition.prototype.addLabelReference = function (component, referenceFieldIdMap) {
            var field = this.field;
            var refField = new TextLabelReferenceFieldModel(component);

            refField.name = ViewModeGeneratorUtilService.getRefFieldName(field);

            var fieldValue = refField.fieldValue;
            fieldValue.localId = field.localId;
            fieldValue.referenceFieldDisplayStyle = FormConstants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;

            updateVisibilityBasedOnFieldEntry(refField.displayConfig, this.targets);

            updateReferenceFieldIdMap(referenceFieldIdMap, this.targets);

            component.formComponentFields.push(refField);
        };


        // ---------------------------------------------  private internal functions ------------------------

        function getText(labelField) {
            return labelField.fieldValue.value;
        }

        function textEqualTrimmed(text1, text2) {
            return text1.trim() == text2.trim();
        }

        function updateReferenceFieldIdMap(referenceFieldIdMap, targets) {
            if (targets!=null) {
                _.forEach(targets, function(inputField) {
                    var localId = inputField.localId;
                    referenceFieldIdMap[localId] = localId;
                });
            }
        }

        /**
         * updating the display config's visibility to be controlled by field value if input field is not null
         * @param displayConfig - display config object, should never be null
         * @param targets - target input fields list, could be null
         */
        function updateVisibilityBasedOnFieldEntry(displayConfig, targets) {
            if (displayConfig == null || targets == null || targets.length==0) {
                return;
            }

            displayConfig.advancedVisibility = true;

            displayConfig.advancedVisibilityConfig = createAdvancedVisibilityConfig(targets);
        }

        function createAdvancedVisibilityConfig(targets) {
            var containerIds = [];

            _.forEach(targets, function(targetInputField) {
                containerIds.push(targetInputField._parent.localId);
            });

            var conditionText = 'hasFieldEntryInContainers([' + containerIds.join(',') + '])';
            var conditionTree = ExpressionParserService.parse(conditionText,
                ExpressionConstantsService.modules.STATE_RULES, {}, null);

            return {
                conditionText: conditionText,
                conditionTree: conditionTree
            };
        }


        /**
         * adding simple input reference for any free text input, it could be a number or a string
         * @param component - component to add the reference field to
         * @param field - field in edit mode being referenced
         * @param referenceFieldIdMap - fields that has already been referenced before in some labels visibility condition
         *           this map will be updated if the internal field is a label field with targets
         * @param useFieldName - whether we should use field name or not in case of unable to find
         *                       a label above the target field, note: if we don't use field name, then
         *                       we will use field's hint text
         */
        function addReferenceWithNoSubfield(referenceFieldIdMap, component, field, useFieldName) {
            var refField = new ViewTextOrNumberInputFieldModel(component);

            refField.name = ViewModeGeneratorUtilService.getRefFieldName(field);

            var fieldValue = refField.fieldValue;
            fieldValue.localId = field.localId;
            fieldValue.includeDividerAbove = false;

            component.formComponentFields.push(refField);

            // setting up subFields, we will recreate it here so that we are not dependent on
            // the default configuration that much (still depend on default config a little bit at the
            // sub field level)
            var subFields = [];
            subFields.push(new FormModelSubFieldService.SubFieldLabelReferenceModel(refField));

            // make first sub field reference a label field if possible
            var fieldAbove = FormScriptActionUtil.findFieldBefore(field);
            if (fieldAbove != null && fieldAbove.type.name == FormConstants.fieldsType.TEXT_LABEL.name) {
                // found a text label just above this, assume it is the field we need to reference
                subFields[0].fieldValue.localId = fieldAbove.localId;
                subFields[0].fieldValue.targetType = FormConstants.formTargetType.FORM_FIELD;
                subFields[0].fieldValue.referenceFieldDisplayStyle = FormConstants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;

                // make 2nd sub field invisible
                subFields.push(new FormModelSubFieldService.SubFieldTitleModel(refField, 'Unused And Invisible', true));
            }
            else {
                // can't find text label above this
                // hide the reference field
                subFields[0].displayConfig.visible = false;

                if (useFieldName) {
                    subFields.push(new FormModelSubFieldService.SubFieldTitleModel(refField, field.name, false));
                }
                else {
                    // make 2nd sub field render the hint text
                    subFields.push(new FormModelSubFieldService.SubFieldTitleModel(refField, field.fieldValue.value, false));
                }
            }


            // make 3rd sub field to display the value
            var value = ViewModeGeneratorUtilService.buildValueString(field.formField.id, 'value', null);
            var answerTextModel = new FormModelSubFieldService.SubFieldTitleModel(refField, value, false);
            var styledAnswerTextModel = styleAnswerTextModel(answerTextModel);
            subFields.push(styledAnswerTextModel);

            refField.subFields = subFields;

            ViewModeGeneratorUtilService.checkForMissingServerId(field);
        }
        FieldWithCondition.prototype.addTakePictureReferenceWithNoSubfield= function(component, field) {
            this.refField = new ViewTakeAPictureFieldModel(component);

            this.refField.name = ViewModeGeneratorUtilService.getRefFieldName(field);

            this.fieldValue = this.refField.fieldValue;
            this.fieldValue.localId = field.localId;
            this.fieldValue.includeDividerAbove = false;

            component.formComponentFields.push(this.refField);

            // setting up subFields, we will recreate it here so that we are not dependent on
            // the default configuration that much (still depend on default config a little bit at the
            // sub field level)
            this.subFields = [];
            this.subFields.push(new FormModelSubFieldService.SubFieldLabelReferenceModel(this.refField));

            // make first sub field reference a label field if possible
            this.fieldAbove = FormScriptActionUtil.findFieldBefore(field);
            if ( this.fieldAbove && this.fieldAbove != null) {
                 // found a text label just above this, assume it is the field we need to reference
                if(this.fieldAbove.type.name == FormConstants.fieldsType.TEXT_LABEL.name || this.fieldAbove.type.name == FormConstants.fieldsType.RICH_TEXT_LABEL.name ){
                    this.subFields[0].fieldValue.localId = this.fieldAbove.localId;
                    this.subFields[0].fieldValue.targetType = FormConstants.formTargetType.FORM_FIELD;
                    this.subFields[0].fieldValue.referenceFieldDisplayStyle = FormConstants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;
                }
            }
            this.refField.subFields = this.subFields;
        }
        /**
         * adding reference for an input field that has sub field for title
         * ie: multi-selector, radio selector, drop down selector, day selector, slider
         * @param referenceFieldIdMap - fields that has already been referenced before in some labels visibility condition
         *           this map will be updated if the internal field is a label field with targets
         * @param component - component to add the reference field to
         * @param field - field in edit mode being referenced
         * @param valueType - value type enum, default to 'value', can be 'answerText','answerTextAll','answerTextAllWithEmbedded', 'value'
         * @param formatterInfo - any extra formatter info if needed, ie: "dateFormat : 'MMM dd, yyyy'"
         */
        function addReferenceForFieldWithSubFields(referenceFieldIdMap, component, field, valueType, formatterInfo) {
            var refField = new ViewTextOrNumberInputFieldModel(component);

            refField.name = ViewModeGeneratorUtilService.getRefFieldName(field);

            var fieldValue = refField.fieldValue;
            fieldValue.localId = field.localId;
            fieldValue.includeDividerAbove = false;

            component.formComponentFields.push(refField);

            // setting up subFields, we will recreate it here so that we are not dependent on
            // the default configuration that much (still depend on default config a little bit at the
            // sub field level)
            var subFields = [];
            subFields.push(new FormModelSubFieldService.SubFieldLabelReferenceModel(refField));
            // make first sub field reference the subfield
            subFields[0].fieldValue.localId = field.subFields[0].localId;
            subFields[0].fieldValue.targetType = FormConstants.formTargetType.FORM_SUB_FIELD;
            subFields[0].fieldValue.referenceFieldDisplayStyle = FormConstants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;

            // make 2nd sub field invisible
            subFields.push(new FormModelSubFieldService.SubFieldTitleModel(refField, 'Unused And Invisible', true));

            // make 3rd sub field to display the value or answerText
            var value = ViewModeGeneratorUtilService.buildValueString(field.formField.id, valueType, formatterInfo);
            var answerTextModel = new FormModelSubFieldService.SubFieldTitleModel(refField, value, false);
            var styledAnswerTextModel = styleAnswerTextModel(answerTextModel);
            subFields.push(styledAnswerTextModel);

            refField.subFields = subFields;

            ViewModeGeneratorUtilService.checkForMissingServerId(field);
        }

        /**
         * Add default styling for answer model subfield of view text number/input field
         * @param model Sub field model
         */
        function styleAnswerTextModel(model) {
            var displayConfig = model.displayConfig;
            FormScriptActionUtil.updateColor(displayConfig, '#333333');
            FormScriptActionUtil.updateBgColor(displayConfig, 'white');
            FormScriptActionUtil.updateFontSize(displayConfig, 12);
            FormScriptActionUtil.updateFontName(displayConfig, FormScriptActionUtil.fontNames.montserratMedium);
            FormScriptActionUtil.updatePadding(displayConfig, 0, 16, 10, 10);
            FormScriptActionUtil.updateGravity(displayConfig, FormConstants.gravity.LEFT, FormConstants.gravity.MIDDLE);
            FormScriptActionUtil.updateWidth(displayConfig, 100, '%');
            return model;
        }



        return FieldWithCondition;
    });
})();
