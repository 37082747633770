(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionCreateOrder', function (RuleUtilService, ORDER_TYPES) {
            var createOrderActionController = function () {
                var vm = this;
                vm.cs = RuleUtilService.ruleConstants;
                vm.orderTypes = Object.keys(ORDER_TYPES);
            };

            return {
                bindToController: true,
                controller: createOrderActionController,
                controllerAs: 'vm',
                restrict: 'E',
                scope: {
                    action: '=',
                    parentForm: '='
                },
                templateUrl: 'admin-templates/site/businessRule/configPanel/action/createOrder/createOrder.html'
            };
        });
})();