(function () {
    angular.module('acadiamasterApp').controller(
        'basicConfigController',
        function (
            ProgramService, Organization, ProgramCategory, $state, CONFIG, ProgramUtilConstantsService,
            RegExpConstantsService, RuleConstantsService, DeviceDataTypeService, HKDatatypesConstantsService,
            FormModelServiceFieldValue, FormConstants, $scope, LocaleConstantsService, ProgramActiveCountries,
        ) {
            const vm = this;
            vm.programId = $scope.programId;
            console.log('config', CONFIG);
            vm.CONFIG = CONFIG;
            vm.RegExpConstants = RegExpConstantsService;
            vm.rcs = RuleConstantsService;
            vm.lcs = LocaleConstantsService;
            vm.constants = FormConstants;
            vm.readyStates = {
                allData         : false,
                organization    : false,
                program         : false,
                programCategory : false,

                /**
                     * basic update function to verify if allData is loaded
                     */
                update () {
                    this.allData = this.program && this.organization && this.programCategory;
                },

            };

            vm.program = null;
            vm.organizations = null;
            vm.programCategories = null;
            vm.syncDevicesOptions = ProgramUtilConstantsService.syncDevicesOptions;
            vm.systemLocales = createSystemLocales();
            vm.loadProgramOrCreateNew = loadProgramOrCreateNew;
            vm.loadOrganizations = loadOrganizations;
            vm.loadProgramCategories = loadProgramCategories;
            vm.saveProgram = saveProgram;
            vm.setDefaultProgramLocale = setDefaultProgramLocale;
            vm.getEnableDeviceMap = getEnableDeviceMap;
            vm.getDataTypesByDeviceMap = getDataTypesByDeviceMap;
            vm.updateSyncDeviceFlag = updateSyncDeviceFlag;
            vm.processDeviceChecked = processDeviceChecked;
            vm.getEnableViewDataFlag = getEnableViewDataFlag;
            vm.unSetSecondaryLogo = unSetSecondaryLogo;
            vm.unSetChatURL = unSetChatURL;
            vm.unSetFromEmail = unSetFromEmail;
            vm.unSetAppStoreURL = unSetAppStoreURL;
            vm.unSetProgramCode = unSetProgramCode;
            vm.addressSectionToggle = addressSectionToggle;
            vm.addressElementToggle = addressElementToggle;
            vm.personalSectionToggle = personalSectionToggle;
            vm.personalElementToggle = personalElementToggle;
            vm.secondaryContact1SectionToggle = secondaryContact1SectionToggle;
            vm.secondaryContact1ElementToggle = secondaryContact1ElementToggle;
            vm.secondaryContact2SectionToggle = secondaryContact2SectionToggle;
            vm.secondaryContact2ElementToggle = secondaryContact2ElementToggle;
            vm.isValidated = isValidated;
            vm.secondaryLogoPositionToAvoid = [
                FormConstants.position.LEFT,
                FormConstants.position.RIGHT,
            ];
            vm.loadSupportedCountries = loadSupportedCountries;
            vm.getActiveSuportedList = getActiveSuportedList;
            // calling the initialization function, don't add any additional scope variable after this
            init($scope);

            /**
                 * main initialization function, load all the data needed from server and set state to be ready
                 */
            function init ($scope) {
                loadProgramOrCreateNew($scope.programId);
                loadOrganizations();
                loadProgramCategories();
            }

            /**
                 * loading program from server if programId is not null, or create new one
                 * @param programId program id, can be null in case of creating new program
                 */
            function loadProgramOrCreateNew (programId) {
                if (programId == null) { // creating a new program
                    vm.program = {
                        createdOn             : null,
                        description           : null,
                        displayName           : null,
                        type                  : 'ENTERPRISE',
                        id                    : null,
                        isDeleted             : null,
                        mobileBuildParameters : {},
                        name                  : null,
                        programCategories     : [],
                        programOptions        : {
                            allowEmailRegistration                     : true,
                            allowMobilePhoneRegistration               : true,
                            autoSelectSingleSite                       : false,
                            dashboardMessage                           : 'Welcome to your dashboard. You will able to log your activity here. You have nothing to log for now. Please, check back later.',
                            ehrMessageIfProviderIsAdded                : 'We\'ve connected to your electronic health records. You\'re sharing your data from:',
                            ehrMessageIfProviderIsNotAdded             : 'You are not currently sharing electronic health record (EHR) data from your health care provider',
                            enabledDataTypesByDeviceMap                : vm.getDataTypesByDeviceMap(),
                            enableDevicesMap                           : vm.getEnableDeviceMap(),
                            enableDeviceSync                           : false,
                            enablePairing                              : false,
                            enableBiometricsRegistration               : false,
                            enableSnapQuestions                        : true,
                            forceEmailRegistration                     : false,
                            forceMobilePhoneRegistration               : false,
                            fromEmail                                  : null,
                            localizationMap                            : {},
                            msgInsightItemNotStarted                   : 'you have not started on [form name], please complete it before trying to view insight',
                            msgInsightItemPaused                       : '[form name] has been paused, you must complete it before viewing insight',
                            msgMobileInsightSummaryCompletedAtLeastOne : 'Click on the completed item to view detail',
                            msgMobileInsightSummaryCompletedNone       : 'Sorry, you haven\'t completed any survey yet.',
                            msgWebInsightSummaryDescription            : 'Select a tab to view your survey responses.  You can only view responses to surveys you have finished',
                            msgWebInsightSummaryTitle                  : 'Welcome to your Insight',
                            programLocales                             : vm.setDefaultProgramLocale(),
                            showAddress                                : false,
                            showAgreements                             : true,
                            showAlternateEmail                         : false,
                            showContactPhoneNumber                     : false,
                            showDateOfBirth                            : false,
                            showEhr                                    : false,
                            showFirstName                              : false,
                            showFooterText                             : false,
                            showGradientIcon                           : false,
                            showHeaderSeparator                        : false,
                            showHomeAddress                            : false,
                            showInsightTab                             : true,
                            showLastName                               : false,
                            showMiddleName                             : false,
                            showPoweredByVibrent                       : false,
                            showPreferredName                          : false,
                            showPrefix                                 : false,
                            showPreRegistrationQuestions               : false,
                            showSecondaryContact1                      : false,
                            showSecondaryContact1ContactAddress        : false,
                            showSecondaryContact1Country               : false,
                            showSecondaryContact1EmailAddress          : false,
                            showSecondaryContact1PhoneNumber           : false,
                            showSecondaryContact1Relationship          : false,
                            showSecondaryContact2                      : false,
                            showSecondaryContact2ContactAddress        : false,
                            showSecondaryContact2Country               : false,
                            showSecondaryContact2EmailAddress          : false,
                            showSecondaryContact2PhoneNumber           : false,
                            showSecondaryContact2Relationship          : false,
                            showShippingAddress                        : false,
                            showSSN                                    : false,
                            showSuffix                                 : false,
                            showTtySupportText                         : false,
                            showViewData                               : false,
                            showZipCode                                : false,
                            strictMobileVersionCheck                   : false,
                            supportedCountries                         : [],
                            noCallingCodeEnabled                       : false
                        },
                        supportParameters : {},
                        updatedOn         : null,
                    };
                    vm.readyStates.program = true;
                    vm.readyStates.update();
                    loadSupportedCountries();
                } else {
                    vm.program = ProgramService.get({ id : programId });
                    vm.program.$promise.then(() => {
                        vm.readyStates.program = true;
                        vm.readyStates.update();
                        vm.program.type = vm.program.type || 'ENTERPRISE';
                        vm.program.programOptions = vm.program.programOptions || {};
                        vm.program.supportParameters = vm.program.supportParameters || {};
                        vm.program.mobileBuildParameters = vm.program.mobileBuildParameters || {};
                        if (vm.program.programOptions.localizationMap == null) {
                            vm.program.programOptions.localizationMap = {};
                        }
                        vm.program.programOptions.enableDevicesMap = vm.getEnableDeviceMap(vm.program.programOptions.enableDevicesMap);
                        vm.program.programOptions.enabledDataTypesByDeviceMap = vm.getDataTypesByDeviceMap(vm.program.programOptions.enabledDataTypesByDeviceMap);
                        // reset system locale list
                        vm.systemLocales = createSystemLocales(vm.program.programOptions.programLocales);
                        loadSupportedCountries();
                    });
                }
            }

            function createSystemLocales (programLocales) {
                const locales = CONFIG.systemLocales?.map(locale => {
                    const copyLocale = angular.copy(locale);

                    if (programLocales) {
                        for (let i = 0; i < programLocales.length; i++) {
                            if (copyLocale.locale === programLocales[i].locale) {
                                copyLocale.defaultLocale = programLocales[i].defaultLocale;
                                if (copyLocale.defaultLocale) {
                                    break;
                                }
                            }
                        }
                    } else if (copyLocale.locale === 'en') {
                        copyLocale.defaultLocale = true;
                    } else {
                        copyLocale.defaultLocale = false;
                    }
                    return copyLocale;
                });
                return locales;
            }

            function loadProgramCategories () {
                vm.programCategories = ProgramCategory.query();
                vm.programCategories.$promise.then(() => {
                    vm.readyStates.programCategory = true;
                    vm.readyStates.update();
                });
            }

            function loadOrganizations () {
                vm.organizations = Organization.query();
                vm.organizations.$promise.then(() => {
                    vm.readyStates.organization = true;
                    vm.readyStates.update();
                });
            }

            function loadSupportedCountries () {
                vm.activeSupportedCountryList = [];
                vm.getCountryList = ProgramActiveCountries.query();
                vm.getCountryList.$promise.then(() => {
                    getActiveSuportedList(vm.program.programOptions.supportedCountries);
                });
            }

            function getActiveSuportedList (selectedCountries) {
                vm.getCountryList.forEach(country => {
                    const countryObj = {
                        country        : country,
                        isoCountryCode : country.iso3166Alpha2,
                        phoneCode      : country.phoneCode,
                    };
                    if (Array.isArray(selectedCountries)) {
                        selectedCountries.forEach(selection => {
                            if (selection.phoneCode === countryObj.phoneCode && selection.isoCountryCode === countryObj.isoCountryCode) {
                                countryObj.selected = true;
                            }
                        });
                    }
                    vm.activeSupportedCountryList.push(countryObj);
                });
            }

            function onSaveFinished () {
                if (vm.programId != null) {
                    $state.go('program.manage', { id : vm.program.id });
                } else {
                    $state.go('program');
                }
            }

            function isValidated () {
                if (vm.program && vm.program.programOptions && !vm.program.programOptions.allowEmailRegistration && !vm.program.programOptions.allowMobilePhoneRegistration) {
                    return true;
                }
                return false;
            }

            /**
                 * save program
                 */
            function saveProgram () {
                vm.files = [];
                vm.program.programOptions.supportedCountries = [];
                vm.activeSupportedCountryList.forEach(country => {
                    if (country.selected) {
                        vm.program.programOptions.supportedCountries.push(country);
                    }
                });
                ProgramService.createUpdate(vm.files, vm.program, onSaveFinished);
            }

            function setDefaultProgramLocale () {
                const defaultLocaleOption = [];
                for (let i = 0; i < vm.CONFIG.systemLocales.length; i++) {
                    const defaultLocale = angular.copy(vm.CONFIG.systemLocales[i]);
                    if (defaultLocale.locale === 'en') {
                        defaultLocale.defaultLocale = true;
                        defaultLocaleOption.push(defaultLocale);
                        return defaultLocaleOption;
                    }
                }
                return defaultLocaleOption;
            }

            function getEnableDeviceMap (enableDevicesMap) {
                const enableDeviceMap = enableDevicesMap || {};

                for (const device in ProgramUtilConstantsService.syncDevicesOptions) {
                    if (ProgramUtilConstantsService.syncDevicesOptions.hasOwnProperty(device)) {
                        enableDeviceMap[device] = enableDeviceMap[device] || false;
                    }
                }
                return enableDeviceMap;
            }

            function getDataTypesByDeviceMap (paramDataTypesByDeviceMap) {
                const dataTypesByDeviceMap = paramDataTypesByDeviceMap || {};
                const supportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_BYOD.name;
                if (!dataTypesByDeviceMap.hasOwnProperty(supportedDeviceType)) {
                    dataTypesByDeviceMap[supportedDeviceType] = {};
                }
                const ehrSupportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_EHR.name;
                if (!dataTypesByDeviceMap.hasOwnProperty(ehrSupportedDeviceType)) {
                    dataTypesByDeviceMap[ehrSupportedDeviceType] = {};
                }
                const samsungHealthSupportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.SAMSUNG_HEALTH.name;
                if (!dataTypesByDeviceMap.hasOwnProperty(samsungHealthSupportedDeviceType)) {
                    dataTypesByDeviceMap[samsungHealthSupportedDeviceType] = {};
                }

                // Healthkit BYOD data types
                fillDataTypeDeviceMap(
                    supportedDeviceType,
                    HKDatatypesConstantsService.categoryTypesOptions, dataTypesByDeviceMap,
                );

                fillDataTypeDeviceMap(
                    ehrSupportedDeviceType,
                    HKDatatypesConstantsService.ehrCategoryOptions, dataTypesByDeviceMap,
                );

                fillDataTypeDeviceMap(
                    samsungHealthSupportedDeviceType,
                    HKDatatypesConstantsService.samsungHealthCategoryOptions, dataTypesByDeviceMap,
                );

                return dataTypesByDeviceMap;
            }

            function fillDataTypeDeviceMap (supportedDeviceType, categoryTypesOptions, dataTypesByDeviceMap) {
                for (let categoryIndex = 0; categoryIndex < categoryTypesOptions.length; categoryIndex++) {
                    const category = categoryTypesOptions[categoryIndex];
                    for (let dataTypeIndex = 0; dataTypeIndex < category.supported_datatypes.length; dataTypeIndex++) {
                        const dataTypes = category.supported_datatypes[dataTypeIndex];
                        if (!dataTypesByDeviceMap[supportedDeviceType].hasOwnProperty(dataTypes.type)) {
                            dataTypesByDeviceMap[supportedDeviceType][dataTypes.type] = true;
                        }
                    }
                }
                return dataTypesByDeviceMap;
            }

            /**
                 * Callback on change any device sync.
                 * If any device sync enabled, set enableDeviceSync to true.
                 */
            function updateSyncDeviceFlag () {
                const { enableDevicesMap } = vm.program.programOptions;
                for (const device in enableDevicesMap) {
                    if (enableDevicesMap.hasOwnProperty(device)
                            && enableDevicesMap[device] === true) {
                        vm.program.programOptions.enableDeviceSync = true;
                        return;
                    }
                }
                vm.program.programOptions.showViewData = false;
                vm.program.programOptions.enableDeviceSync = false;
            }

            function getEnableViewDataFlag () {
                const { enableDevicesMap } = vm.program.programOptions;
                for (const device in enableDevicesMap) {
                    if (enableDevicesMap.hasOwnProperty(device)
                            && enableDevicesMap[device] === true) {
                        return true;
                    }
                }
                return false;
            }

            function processDeviceChecked (deviceName) {
                const { enableDevicesMap } = vm.program.programOptions;
                const supportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_BYOD.name;
                const ehrDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_EHR.name;
                const samsungHealthSupportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.SAMSUNG_HEALTH.name;

                if ((deviceName === supportedDeviceType || deviceName === ehrDeviceType || deviceName === samsungHealthSupportedDeviceType)
                        && enableDevicesMap[deviceName] === true) {
                    showHkDataTypeConfigModel(deviceName);
                }
            }

            vm.openHKPopUp = function ($scope) {
                DeviceDataTypeService.openHKWindow($scope, vm);
            };

            function showHkDataTypeConfigModel (deviceType) {
                let catOptions = [];
                const configOptions = {
                    categoryTypes : [],
                    deviceType,
                };

                const supportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_BYOD.name;
                const ehrDeviceType = ProgramUtilConstantsService.syncDevicesOptions.HEALTHKIT_EHR.name;
                const samsungHealthSupportedDeviceType = ProgramUtilConstantsService.syncDevicesOptions.SAMSUNG_HEALTH.name;

                if (deviceType === supportedDeviceType) {
                    catOptions = HKDatatypesConstantsService.categoryTypesOptions;
                } else if (deviceType === ehrDeviceType) {
                    catOptions = HKDatatypesConstantsService.ehrCategoryOptions;
                } else if (deviceType === samsungHealthSupportedDeviceType) {
                    catOptions = HKDatatypesConstantsService.samsungHealthCategoryOptions;
                } else {
                    return;
                }

                for (let categoryTypeIndex = 0; categoryTypeIndex < catOptions.length; categoryTypeIndex++) {
                    const categoryType = catOptions[categoryTypeIndex];
                    let isEnabled = false;
                    for (let dataTypeIndex = 0; dataTypeIndex < categoryType.supported_datatypes.length; dataTypeIndex++) {
                        const dataType = categoryType.supported_datatypes[dataTypeIndex];
                        if (vm.program.programOptions.enabledDataTypesByDeviceMap[deviceType][dataType.type] === true) {
                            isEnabled = true;
                            break;
                        }
                    }

                    configOptions.categoryTypes.push({
                        category_type       : categoryType.category_type,
                        isEnabled,
                        supported_datatypes : categoryType.supported_datatypes,
                    });
                }

                vm.categoryTypesOptions = configOptions;

                vm.openHKPopUp($scope);
            }

            function personalSectionToggle (showPersonalInformation) {
                vm.program.programOptions.showPrefix = showPersonalInformation;
                vm.program.programOptions.showFirstName = showPersonalInformation;
                vm.program.programOptions.showMiddleName = showPersonalInformation;
                vm.program.programOptions.showLastName = showPersonalInformation;
                vm.program.programOptions.showPreferredName = showPersonalInformation;
                vm.program.programOptions.showSuffix = showPersonalInformation;
                vm.program.programOptions.showDateOfBirth = showPersonalInformation;
                vm.program.programOptions.showContactPhoneNumber = showPersonalInformation;
                vm.program.programOptions.showAlternateEmail = showPersonalInformation;
                vm.program.programOptions.showSSN = showPersonalInformation;
            }

            function personalElementToggle () {
                vm.personalInformationOptions = [
                    vm.program.programOptions.showPrefix,
                    vm.program.programOptions.showFirstName,
                    vm.program.programOptions.showMiddleName,
                    vm.program.programOptions.showLastName,
                    vm.program.programOptions.showPreferredName,
                    vm.program.programOptions.showSuffix,
                    vm.program.programOptions.showDateOfBirth,
                    vm.program.programOptions.showContactPhoneNumber,
                    vm.program.programOptions.showAlternateEmail,
                    vm.program.programOptions.showSSN,
                ];
                for (let personalElement = 0; personalElement < vm.personalInformationOptions.length; personalElement++) {
                    if (vm.personalInformationOptions[personalElement] === true) {
                        return;
                    }
                }
                vm.program.programOptions.showPersonalInformation = false;
            }

            function secondaryContact1SectionToggle (showSecondaryContact1) {
                vm.program.programOptions.showSecondaryContact1ContactAddress = showSecondaryContact1;
                vm.program.programOptions.showSecondaryContact1Country = showSecondaryContact1;
                vm.program.programOptions.showSecondaryContact1PhoneNumber = showSecondaryContact1;
                vm.program.programOptions.showSecondaryContact1EmailAddress = showSecondaryContact1;
                vm.program.programOptions.showSecondaryContact1Relationship = showSecondaryContact1;
            }

            function secondaryContact1ElementToggle () {
                vm.secondaryContact1Options = [
                    vm.program.programOptions.showSecondaryContact1ContactAddress,
                    vm.program.programOptions.showSecondaryContact1Country,
                    vm.program.programOptions.showSecondaryContact1PhoneNumber,
                    vm.program.programOptions.showSecondaryContact1EmailAddress,
                    vm.program.programOptions.showSecondaryContact1Relationship,
                ];
                for (let secondaryContact1Element = 0; secondaryContact1Element < vm.secondaryContact1Options.length; secondaryContact1Element++) {
                    if (vm.secondaryContact1Options[secondaryContact1Element] === true) {
                        return;
                    }
                }
                vm.program.programOptions.showSecondaryContact1 = false;
            }

            function secondaryContact2SectionToggle (showSecondaryContact2) {
                vm.program.programOptions.showSecondaryContact2ContactAddress = showSecondaryContact2;
                vm.program.programOptions.showSecondaryContact2Country = showSecondaryContact2;
                vm.program.programOptions.showSecondaryContact2PhoneNumber = showSecondaryContact2;
                vm.program.programOptions.showSecondaryContact2EmailAddress = showSecondaryContact2;
                vm.program.programOptions.showSecondaryContact2Relationship = showSecondaryContact2;
            }

            function secondaryContact2ElementToggle () {
                vm.secondaryContact2Options = [
                    vm.program.programOptions.showSecondaryContact2ContactAddress,
                    vm.program.programOptions.showSecondaryContact2Country,
                    vm.program.programOptions.showSecondaryContact2PhoneNumber,
                    vm.program.programOptions.showSecondaryContact2EmailAddress,
                    vm.program.programOptions.showSecondaryContact2Relationship,
                ];
                for (let secondaryContact2Element = 0; secondaryContact2Element < vm.secondaryContact2Options.length; secondaryContact2Element++) {
                    if (vm.secondaryContact2Options[secondaryContact2Element] === true) {
                        return;
                    }
                }
                vm.program.programOptions.showSecondaryContact2 = false;
            }

            vm.setFormValidity = () => {
                if (vm.program.programOptions.secondaryLogoPosition != null) {
                    vm.editForm.$setValidity('secondaryLogo', true);
                }
            };

            function unSetSecondaryLogo () {
                if (!vm.program.programOptions.showSecondaryLogo) {
                    vm.program.programOptions.secondaryLogoPosition = null;
                } else if (vm.program.programOptions.secondaryLogoPosition == null) {
                    vm.editForm.$setValidity('secondaryLogo', false);
                }
            }

            function unSetChatURL () {
                if (!vm.program.programOptions.enableChat) {
                    vm.program.programOptions.chatServiceURL = null;
                }
            }
            function unSetFromEmail () {
                if (vm.program.programOptions.fromEmail === '') {
                    vm.program.programOptions.fromEmail = null;
                }
            }

            function unSetProgramCode () {
                if (vm.program.programOptions.gtmId === '') {
                    vm.program.programOptions.gtmId = null;
                }
                if (vm.program.programOptions.analyticsId === '') {
                    vm.program.programOptions.analyticsId = null;
                }
            }

            function addressSectionToggle (showAddress) {
                vm.program.programOptions.showHomeAddress = showAddress;
                vm.program.programOptions.showShippingAddress = showAddress;
            }

            function addressElementToggle (home, shipping) {
                if (home === false && shipping === false) {
                    vm.program.programOptions.showAddress = false;
                }
            }

            function unSetAppStoreURL () {
                if (!vm.program.programOptions.enableGooglePlay) {
                    vm.program.programOptions.googlePlayURL = null;
                    vm.program.programOptions.googlePlayAppId = null;
                }
                if (!vm.program.programOptions.enableAppleStore) {
                    vm.program.programOptions.appleStoreURL = null;
                    vm.program.programOptions.appleStoreAppId = null;
                }
            }
        },
    );
}());
