(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for content model
     */
        .factory('ProgramTestSuiteModelService', function (ProgramTestTagMappingModelService, ProgramTestMappingModelService, ProgramTestSuiteService, ProgramTestModelService, ModelServiceBase, RuleUtilService) {
            ProgramTestSuiteModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
            /***
             * Program  Test Suite
             * @constructor
             */
            function ProgramTestSuiteModel() {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false,null);
                this.id = null;
                this.name = null;
                this.description = null;
                this.programId = null;
                this.testMappings = [];
                this.tagMappings = [];
                this.hasSchedule = false;
                this.cronExpression = null;
                this.recurrenceType = null;
                this.scheduleEnabled = false;
                this.runCorrectiveActions = false;
                this.dateRange = {};
                this.dateRange.start = null;
                this.dateRange.end = null;
                this.lastRunOn = null;
            }

            /**
             * convert the current UI model to dto format
             */
            ProgramTestSuiteModel.prototype.toDto = function () {
                let dto = {};
                dto.id = this.id;
                dto.name = this.name;
                dto.description = this.description;
                dto.programId = this.programId;
                dto.testMappings = [];

                dto.hasSchedule = this.hasSchedule;
                dto.cronExpression = this.cronExpression;
                dto.recurrenceType = this.recurrenceType;
                dto.scheduleEnabled = this.scheduleEnabled;
                dto.runCorrectiveActions = this.runCorrectiveActions;
                dto.lastRunOn = this.lastRunOn;

                dto.startAbsoluteTime = null;
                dto.endAbsoluteTime = null;

                if (this.dateRange != null) {
                    if (this.dateRange.start != null) {
                        dto.startAbsoluteTime = RuleUtilService.fromDateToUTCTimestamp(this.dateRange.start);
                    }
                    if (this.dateRange.end != null) {
                     dto.endAbsoluteTime = RuleUtilService.fromDateToUTCTimestamp(this.dateRange.end);
                    }
                }

                setMappingToDto(dto, this);
                dto.tagMappings = [];
                setTagToDto(dto, this);
                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            ProgramTestSuiteModel.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.name = dto.name;
                this.description = dto.description;
                this.programId = dto.programId;

                this.hasSchedule = dto.hasSchedule;
                this.cronExpression = dto.cronExpression;
                this.recurrenceType = dto.recurrenceType;
                this.scheduleEnabled = dto.scheduleEnabled;
                this.runCorrectiveActions = dto.runCorrectiveActions;
                this.lastRunOn = dto.lastRunOn;

                this.dateRange.start = RuleUtilService.fromUTCTimestampToDate(dto.startAbsoluteTime);
                this.dateRange.end = RuleUtilService.fromUTCTimestampToDate(dto.endAbsoluteTime);

                setMappingFromDto(this, dto);
                setTagFromDto(this, dto);
            };

            ProgramTestSuiteModel.prototype.addNewMapping = function (index, testCaseToAdd) {
                let programTestSuiteInstance = new ProgramTestMappingModelService.ProgramTestMappingModel(this);
                programTestSuiteInstance.sequenceNumber = index + 1;
                programTestSuiteInstance.programTest = testCaseToAdd;
                this.testMappings.splice(index + 1, 0, programTestSuiteInstance);
            };


            ProgramTestSuiteModel.prototype.saveSuite = function(callback) {
                let testSuiteDto = this.toDto();
                ProgramTestSuiteService.createOrUpdateSuite(testSuiteDto,callback);
            };

            ProgramTestSuiteModel.prototype.moveMappingDown = function (index) {
                let temp = this.testMappings[index];
                this.testMappings[index] = this.testMappings[index + 1];
                this.testMappings[index + 1] = temp;
                resetSequence(this.testMappings);
            };

            ProgramTestSuiteModel.prototype.loadModelFromDto = function() {
                let model = new ProgramTestSuiteModel();
                model.fromDto(this);
                return model;
            };

            /***************************************
             * utility functions
             ***************************************/

            function setMappingToDto(dto, testCase) {
                _.forEach(testCase.testMappings, function (mapping) {
                    dto.testMappings.push(mapping);
                });
            }

            function setMappingFromDto(testSuiteDto, dto) {
                _.forEach(dto.testMappings, function (mapping) {
                    testSuiteDto.testMappings.push(mapping);
                });
            }

            function setTagToDto(dto, testSuiteDto) {
                _.forEach(testSuiteDto.tagMappings, function (mapping) {
                    dto.tagMappings.push(mapping);
                });
            }

            function setTagFromDto(testSuiteDto, dto) {
                _.forEach(dto.tagMappings, function (mapping) {
                    testSuiteDto.tagMappings.push(mapping);
                });
            }

            function removeMapping(existingMappings, index) {
                existingMappings.splice(index, 1);
                resetSequence(existingMappings);
            }

            function moveMappingUp(existingMappings, index) {
                let temp = existingMappings[index];
                existingMappings[index] = existingMappings[index - 1];
                existingMappings[index - 1] = temp;
                resetSequence(existingMappings);
            }

            function resetSequence (existingMappings) {
                for(let i=0;i<existingMappings.length;i++){
                    existingMappings[i].sequenceNumber = i+1;
                }
            }

            function moveMappingDown(existingMappings, index) {
                let temp = existingMappings[index];
                existingMappings[index] = existingMappings[index + 1];
                existingMappings[index + 1] = temp;
                resetSequence(existingMappings);
            }


            /***************************************
             * service return call
             ***************************************/
            return {
                ProgramTestSuiteModel: ProgramTestSuiteModel,
                moveMappingDown: moveMappingDown,
                moveMappingUp: moveMappingUp,
                removeMapping: removeMapping
            };
        });
})();
