/**
 * Created by moustafabaiou on 2/17/17
 */
angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one component
     */
    .directive('treeNodeComponent', (FormUtilService, ngDialog,
        ListContainerService, FormConstants, $timeout) => {
        return {
            link($scope) {
                $scope.cs = FormConstants;
                $scope.flags = {
                    dropDownIsOpen: false,
                };

                $scope.allFieldTypes = FormUtilService.FormModelFieldService.getAllFieldTypes();
                $scope.matrixFieldType = FormUtilService.FormModelFieldService.getMatrixFieldType();
                $scope.states = {
                    isAddFieldPopupOpen: false,
                };

                $scope.showAddFieldDialog = ($event) => {
                    $scope.states.isAddFieldPopupOpen = true;
                    ngDialog.openConfirm({
                        className: 'ngdialog-theme-plain custom-width-small',
                        preCloseCallback: () => {
                            $timeout(() => {
                                $scope.states.isAddFieldPopupOpen = false;
                            });
                        },
                        scope: $scope,
                        templateUrl: 'admin-templates/site/components/treeEditorDialogs/addField.template.html',
                    }).then((fieldType) => {
                        $scope.component.addNewField(fieldType, $scope.selector, $scope.currentTreeName);
                    });
                    $event.stopPropagation();
                };

                $scope.addMatrixQuestionField = () => {
                    $scope.component.addNewField($scope.matrixFieldType, $scope.selector, $scope.currentTreeName);
                };

                // create view container, this will make small list slower than normal, but
                // smaller list we don't need to care too much about performance anyway
                $scope.container = ListContainerService.createListContainer(
                    $scope, $scope.selector, $scope.currentTreeName,
                    $scope.component.formComponentFields, 'component.formComponentFields',
                    20, [20, 50, 100],
                );

                $scope.container.init();

                $scope.shouldShowFieldType = (fieldType) => {
                    const formModeType = getParentModeNodeType($scope.component);
                    if (formModeType && formModeType.name == FormConstants.nodeType.MODE.EDIT.name) {
                        return fieldType.availableToEditMode;
                    } else if (formModeType && formModeType.name == FormConstants.nodeType.MODE.VIEW_ENTRY.name) {
                        return fieldType.availableToViewMode;
                    }

                    return fieldType.availableToPromptMode;
                };

                /**
                 * clone the component, insert it right below the current one
                 */
                $scope.cloneComponent = () => {
                    const { component } = $scope;
                    component._parent.cloneComponent(component, $scope.selector, $scope.currentTreeName);
                    $scope.flags.dropDownIsOpen = false;
                };

                /**
                 * delete current component
                 */
                $scope.deleteComponent = () => {
                    $scope.component._parent.removeComponent($scope.component, $scope.selector);
                    $scope.selector.selectItem($scope.component._parent);
                    $scope.flags.dropDownIsOpen = false;
                };

                /**
                 * cut the current element, this doesn't make any change to the system until
                 * a paste action is made later, the object reference is stored in the selector
                 */
                $scope.doCut = () => {
                    $scope.selector.cutItem($scope.component);
                    $scope.flags.dropDownIsOpen = false;
                };

                /**
                 * copy the current element, this doesn't make any change to the system until
                 * a paste action is made later, the object reference is stored in the selector
                 */
                $scope.doCopy = () => {
                    $scope.selector.copyItem($scope.component);
                    $scope.flags.dropDownIsOpen = false;
                };

                /**
                 * check if the current item in buffer can be pasted as a child
                 * @returns {boolean}
                 */
                $scope.canPasteAsChild = () => {
                    const itemInBuffer = $scope.selector.getItemInBuffer();

                    if (itemInBuffer == null) {
                        return false;
                    }

                    // check for matching type and make sure this is not already a child
                    return itemInBuffer.nodeType == FormConstants.nodeType.FIELD
                        && itemInBuffer._parent != $scope.component;
                };

                /**
                 * check if the current item in buffer can be pasted as a sibling
                 * @returns {boolean}
                 */
                $scope.canPasteBelowAsSibling = () => {
                    const itemInBuffer = $scope.selector.getItemInBuffer();

                    if (itemInBuffer == null) {
                        return false;
                    }

                    // check for matching type and make sure this is not the same as current component
                    return itemInBuffer.nodeType == FormConstants.nodeType.COMPONENT
                        && itemInBuffer != $scope.component;
                };

                /**
                 * paste the last element copied or cut as a child
                 * ie: this element copied of cut must be a form field
                 */
                $scope.doPasteAsChild = () => {
                    let elementInBuffer = $scope.selector.getItemInBuffer();

                    // remove the element from its previous parent
                    const oldParent = elementInBuffer._parent;

                    if ($scope.selector.isBufferCut) {
                        // cut
                        oldParent.removeField(elementInBuffer);
                    } else {
                        // make a copy
                        elementInBuffer = elementInBuffer.customClone();
                    }

                    // link it to this element
                    elementInBuffer._parent = $scope.component;

                    // add it to the top of the children array
                    $scope.component.formComponentFields.unshift(elementInBuffer);
                    $scope.selector.selectItem(elementInBuffer);

                    $scope.selector.itemPasted();

                    $scope.flags.dropDownIsOpen = false;
                };

                /**
                 * paste the last element copied or cut as a sibling
                 * ie: this element copied of cut must be a form component
                 */
                $scope.doPasteBelowAsSibling = () => {
                    let elementInBuffer = $scope.selector.getItemInBuffer();

                    // remove the element from its previous parent
                    const oldParent = elementInBuffer._parent;

                    if ($scope.selector.isBufferCut) {
                        // cut
                        oldParent.removeComponent(elementInBuffer);
                    } else {
                        // make a copy
                        elementInBuffer = elementInBuffer.customClone();
                    }

                    // link it to the parent of this element
                    elementInBuffer._parent = $scope.component._parent;

                    // add it to the next spot in the children array
                    const components = $scope.component._parent.formComponents;
                    const index = components.indexOf($scope.component);
                    components.splice(index + 1, 0, elementInBuffer);

                    // select the new item
                    $scope.selector.selectItem(elementInBuffer);
                    $scope.selector.itemPasted();
                    $scope.flags.dropDownIsOpen = false;
                };
            },
            restrict: 'E',
            scope: {
                component: '=',
                currentTreeName: '=',
                selector: '=',
            },
            templateUrl: 'admin-templates/site/forms/treeNode/component.html',
        };

        function getParentModeNodeType(component) {
            const parentMode = component.findParentMode();

            if (parentMode == null) {
                return FormConstants.nodeType.MODE.EDIT;
            }

            return parentMode.nodeType;
        }
    });
