/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/**
 * drc sync user directive
 */
(function () {
    angular.module('acadiamasterApp').directive('vbrDrcCreateDataTypeProperty', (
        drcService, AlertService) => {
        /** *************************************
         * factory return call
         ***************************************/

        return {
            link : function ($scope) {
                // all data are stored here
                $scope.data = {

                    allPropertiesAssociated : false,

                    // using a data model to do this would be better, but this is more a one time use page, and this structure isn't so simple
                    dataType : null,

                    // list of data type properties that we want to create and associate to PMI program
                    dataTypePropertiesToCreate : [
                        'awardee_code', 'suspension_status', 'consent_provided_time',
                    ],
                    inheritedPropertyMappings : [],
                    isReady                   : false,
                };

                $scope.createDataTypeProperty = function () {
                    createDataTypeProperty($scope);
                };

                init($scope);
            },
            restrict    : 'E',
            scope       : {},
            templateUrl : 'admin-templates/site/drc/createDataTypeProperty/drc.createDataTypeProperty.html',
        };

        /*
         * initialization function
         * @param $scope scope object
         */
        function init ($scope) {
            checkCurrentDataTypeForPMI($scope);
        }

        /*
         * get inherited property mappings from data type
         * @param dataType data type
         */
        function getInheritedPropertyMappings (dataType) {
            let mappings = [];
            if (dataType != null) {
                _.forEach(dataType.dataTypeCategorys, dataTypeCategory => {
                    if (dataTypeCategory != null) {
                        // load all properties in the data type into mappings
                        if (dataTypeCategory.propertyMappings != null) {
                            _.forEach(dataTypeCategory.propertyMappings, m => {
                                mappings.push(m);
                            });
                        }

                        // go up to root category level for more inherited properties
                        _.forEach(dataTypeCategory.dataTypeRootCategorys, rootCategory => {
                            // load all properties into mappings
                            if (rootCategory.propertyMappings != null) {
                                _.forEach(rootCategory.propertyMappings, m => {
                                    mappings.push(m);
                                });
                            }
                        });
                    }
                });
            }

            return mappings;
        }

        /*
         * make sure property name is not in either of the mappings
         * @param propertyName property name
         * @param mappings1 list of mappings
         * @param mappings2 another list of mappings
         */
        function foundPropertyNameIn (propertyName, mappings1, mappings2) {
            let found = false;
            if (mappings1 != null) {
                found = _.findIndex(mappings1, m => {
                    return m.property.name === propertyName;
                }) != -1;
            }

            if (!found && mappings2 != null) {
                found = _.findIndex(mappings1, m => {
                    return m.property.name === propertyName;
                }) != -1;
            }

            return found;
        }

        /*
         * reload inherited properties for easier rendering, check property list to see
         * if all the needed properties has already been associated
         * @param $scope scope object
         */
        function processProperties ($scope) {
            $scope.data.inheritedPropertyMappings = getInheritedPropertyMappings($scope.data.dataType);

            let allPropertiesAssociated = true;

            let propertiesToLookFor = $scope.data.dataTypePropertiesToCreate;

            for (let i = 0; i < propertiesToLookFor.length && allPropertiesAssociated; i++) {
                allPropertiesAssociated = foundPropertyNameIn(propertiesToLookFor[i],
                    $scope.data.dataType.propertyMappings, $scope.data.inheritedPropertyMappings);
            }

            $scope.data.allPropertiesAssociated = allPropertiesAssociated;
        }

        /*
         * check current data type
         * @param $scope scope object
         */
        function checkCurrentDataTypeForPMI ($scope) {
            let data = $scope.data;
            data.isReady = false;
            drcService.getDataTypeForPMI()
                .then(response => {
                    data.isReady = true;

                    data.dataType = response.data;
                    processProperties($scope);
                });
        }

        /*
         * create data type properties for pmi
         */
        function createDataTypeProperty ($scope) {
            drcService.addDataTypePropertyForPMI()
                .then(response => {
                    let message = `# of properties associated : ${ response?.data?.length}`;
                    message = `${message }<br>${ response.data}`;
                    AlertService.success(message);
                    checkCurrentDataTypeForPMI($scope);
                });
        }
    });
}());
