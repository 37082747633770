(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programTestCaseRun', function (ProgramTestService,
                                                                                LoadingStatus,
                                                                                TestExecutionStatusService,
                                                                                ProgramTestExecutionAuditService,
                                                                                AlertService,
                                                                                ngDialog,
                                                                                $timeout) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/testRun/testRun.html',
            scope: {
                id: '='
            },
            link: function ($scope) {
                $scope.LoadingStatus = LoadingStatus;

                $scope.flag = {
                    testCaseLoaded: LoadingStatus.WAITING,
                    testAuditLoaded: LoadingStatus.WAITING,
                    monitoringStatus: false,

                    isMonitoring : function() {
                        return this.monitoringStatus;
                    },

                    isTestCaseLoaded: function () {
                        return this.testCaseLoaded === LoadingStatus.LOAD_COMPLETE;
                    },

                    isTestAuditLoaded: function () {
                        return this.testAuditLoaded === LoadingStatus.LOAD_COMPLETE;
                    },

                    isAllDataLoaded: function () {
                        return this.isTestCaseLoaded() && this.isTestAuditLoaded();
                    }
                };

                $scope.data = {
                    testCase: null,
                    testAudit: null
                };

                $scope.canRunTest = function () {
                    return $scope.flag.testAuditLoaded && !$scope.isTestRunning();
                };

                $scope.isTestRunning = function () {
                    return $scope.data.testAudit &&
                        TestExecutionStatusService.isRunning($scope.data.testAudit.testExecutionStatus);
                };

                $scope.runTest = function () {
                    runTest($scope);
                };

                $scope.cancelTest = function() {
                    cancelTest($scope);
                };

                $scope.startCheckingStatus = function () {
                    startCheckingStatus($scope, null);
                };

                init($scope);
            }
        };

        /***************************************************************
         * interface functions on $scope
         ***************************************************************/

        /**
         * applying the program test
         * @param $scope - scope of the directive
         */
        function runTest($scope) {
            var programTest = $scope.data.testCase;

            // open a pop up for displaying the program test data
            ngDialog.openConfirm({
                template: 'runTest-confirmation-dialog'
            }).then(function () {
                ProgramTestService.runTest(programTest.id).then(function (response) {
                    loadTestAuditSucceeded($scope, response.data);
                    startCheckingStatus($scope, 2000);
                }).catch(function (error) {
                    runTestFailed(error);
                });
            });
        }

        function cancelTest($scope) {
            // open a pop up for displaying the program test data
            ngDialog.openConfirm({
                template: 'cancelTest-confirmation-dialog'
            }).then(function () {
                ProgramTestService.cancelTest($scope.data.testAudit.id).then(function (response) {
                    init($scope);
                }).catch(function (error) {
                    cancelTestFailed(error);
                });
            });
        }

        /**
         * start checking the status of the test until it is completed
         * @param $scope - scope of the directive
         * @param waitTime - wait time in millisecond for the next waiting time
         */
        function startCheckingStatus($scope, waitTime) {
            turnOnMonitoringFlag($scope);

            ProgramTestExecutionAuditService.getTestExecutionAuditById($scope.data.testAudit.id, false)
                .then(function (response) {
                    loadTestAuditSucceeded($scope, response.data);

                    if ($scope.isTestRunning()) {
                        if (waitTime == null) {
                            waitTime = 2000; // starting out the wait at 2 seconds
                        }
                        else {
                            // double the wait time after each subsequent check
                            waitTime *= 2;
                        }

                        if (waitTime >= 20000) {
                            // wait time should never be higher than 30 seconds
                            waitTime = 20000;
                        }

                        $timeout(function () {
                            startCheckingStatus($scope, waitTime);
                        }, waitTime); // run it after some wait
                    }
                    else {
                        turnOffMonitoringFlag($scope);
                    }
                })
                .catch(function (error) {
                    console.error('check status failed : ', error);
                    turnOffMonitoringFlag($scope);
                });

        }

        /***************************************************************
         * private functions
         ***************************************************************/

        /**
         * initializing function, this will load all the necessary data for page display
         * @param $scope - scope of the directive
         */
        function init($scope) {
            // initialize test case object
            $scope.flag.testCaseLoaded = LoadingStatus.LOADING;
            $scope.data.testCase = null;

            ProgramTestService.getTestCase($scope.id)
                .then(function (response) {
                    TestCaseLoadSucceeded($scope, response.data);
                })
                .catch(function (error) {
                    TestCaseLoadFailed($scope, error);
                });
        }

        function turnOnMonitoringFlag($scope) {
            $scope.flag.monitoringStatus = true;
        }

        function turnOffMonitoringFlag($scope) {
            $scope.flag.monitoringStatus = false;
        }

        /**
         * callback for loading test case success, this will setup the flags and data
         * properly, as well as making a call to load test case status
         * @param $scope - scope of the directive
         * @param testCase - test case object from server
         */
        function TestCaseLoadSucceeded($scope, testCase) {
            $scope.flag.testCaseLoaded = LoadingStatus.LOAD_COMPLETE;
            $scope.data.testCase = testCase;

            // load test status by name now
            $scope.flag.testAuditLoaded = LoadingStatus.LOADING;
            $scope.data.testAudit = null;

            ProgramTestExecutionAuditService.getLatestTestExecutionAudit(testCase.id, false)
                .then(function (response) {
                    loadTestAuditSucceeded($scope, response.data);
                })
                .catch(function (error) {
                        loadTestAuditFailed($scope, error);
                });
        }

        function loadTestAuditSucceeded($scope, testCaseExecutionAudit) {
            $scope.flag.testAuditLoaded = LoadingStatus.LOAD_COMPLETE;

            if (testCaseExecutionAudit == null || testCaseExecutionAudit === '') {
                $scope.data.testAudit = null;
            }
            else {
                $scope.data.testAudit = testCaseExecutionAudit;
            }
        }

        function loadTestAuditFailed($scope, error) {
            console.error('failed to load test audit: ', error);
            AlertService.error('Unable to load test audit');

            $scope.flag.testAuditLoaded = LoadingStatus.LOAD_FAILED;
            $scope.data.testAudit = null;
        }

        function runTestFailed(error) {
            console.error('Error in Running test', error);
            AlertService.error('Error in Running test, check the log in console');
        }

        function cancelTestFailed(error) {
            console.error('Error in Cancelling test', error);
            AlertService.error('Error in Cancelling test, check the log in console');
        }

        function TestCaseLoadFailed($scope, error) {
            console.error('failed to load test case: ', error);
            AlertService.error('Unable to load program test object');

            $scope.flag.testCaseLoaded = LoadingStatus.LOAD_FAILED;
            $scope.data.testCase = null;
        }

    });

})();



