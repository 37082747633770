/**
 * Dependency link information
 */
angular.module('acadiamasterApp').controller('DependencyLinkDialogController',
    (dpType, dpLinkMap) => {
        const vm = this;
        vm.type = dpType;
        vm.dpLinkMap = Array.from(dpLinkMap);
        return vm;
    });
