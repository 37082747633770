(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('CommonResource', ['$http', 'localWeb', function ($http, localWeb) {
            return {
                'ping': function () {
                    var finalUrl = localWeb.createUrl('api/ping');
                    return $http.get(finalUrl);
                }
            };
        }]);
})();
