/**
 * created by Jason.Cao on Nov 2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for navigation edge editing, this will be done in a popup window, user can only modify
     * - fromNode
     * - toNode
     * - name
     *
     * note: condition is currently modified outside, we might bring it into this GUI later
     */
        .directive('navigationEdgeEdit', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/navigation/navigationEdgeEdit.html',
                scope: {
                    edge: '=',
                    nodes: '=',
                    closeFunction: '='
                },
                link: function ($scope) {
                    // a copy of the edge passed in used for modification, so cancel will work correct
                    $scope.edgeCopy = {
                        nodeFrom: $scope.edge.nodeFrom,
                        nodeTo: $scope.edge.nodeTo,
                        name: $scope.edge.name
                    };

                    $scope.data = {
                        errorMessage: null
                    };

                    function updateErrorMessage(newError, appendTo) {
                        if (appendTo) {
                            if ($scope.data.errorMessage==null) {
                                $scope.data.errorMessage = newError;
                            }
                            else {
                                $scope.data.errorMessage += '<br>' + newError;
                            }
                        }
                        else {
                            $scope.data.errorMessage = newError;
                        }
                    }

                    // save the edge being modified
                    $scope.save = function () {
                        // copy over the data from edge copy to actual object
                        $scope.edge.nodeFrom = $scope.edgeCopy.nodeFrom;
                        $scope.edge.nodeTo = $scope.edgeCopy.nodeTo;
                        $scope.edge.name = $scope.edgeCopy.name;

                        // close the window and signal it is a actual save (ie. not cancel)
                        $scope.closeFunction(true);
                    };

                    /**
                     * check if an edge is valid or not, this is used to enable/disable the save button
                     */
                    $scope.isEdgeValid = function () {
                        updateErrorMessage(null);

                        var allRequiredFieldsAreThere = $scope.edgeCopy.nodeTo != null && $scope.edgeCopy.nodeFrom != null;
                        if (!allRequiredFieldsAreThere) {
                            updateErrorMessage("both nodeTo and nodeFrom must be selected", true)
                        }

                        var nodesAreNotTheSame = $scope.edgeCopy.nodeTo != $scope.edgeCopy.nodeFrom;
                        if (!nodesAreNotTheSame) {
                            updateErrorMessage("nodeTo and nodeFrom can not be the same", true)
                        }

                        var edgeFound = findEdge($scope.edgeCopy.nodeFrom, $scope.edgeCopy.nodeTo);
                        var hasDuplicatedEdge = edgeFound != null && edgeFound != $scope.edge;

                        if (hasDuplicatedEdge) {
                            updateErrorMessage("an edge from the same source and destination already exist", true)
                        }

                        return allRequiredFieldsAreThere && nodesAreNotTheSame && !hasDuplicatedEdge;
                    };

                }
            };


            /**
             * find an existing edge from nodeFrom --> nodeTo
             * @param nodeFrom - starting node of the edge
             * @param nodeTo - ending node of the edge
             */
            function findEdge(nodeFrom, nodeTo) {
                if (nodeFrom==null) {
                    return null;
                }

                var edgesFrom = nodeFrom._edgesFrom;
                if (edgesFrom == null) {
                    return null;
                }

                return _.find(edgesFrom, function(e) {
                    return e.nodeTo == nodeTo;
                });
            }

        });


})();

