import ProgramSelectionListController from './programSelectionList.controller';

ProgramSelectionListController.$inject = [ '$scope', 'AlertService', 'FormVersionTwoUtilityService', 'ProgramSearch' ];

(function () {
    // eslint-disable-next-line angular/controller-name
    angular
        .module('acadiamasterApp')
        .directive('programSelectionList', () => {
            return {
                controller : 'ProgramSelectionListController',
                restrict   : 'E',
                scope      : {
                    customStyles        : '<',
                    isEnabled           : '<',
                    selectedProgram     : '=',
                    selectedProgramCode : '=',
                    onSelection         : '=',
                },
                templateUrl : 'admin-templates/site/forms/form/programSelectionList.html',
            };
        })
        .controller('ProgramSelectionListController', ProgramSelectionListController);
}());
