
import cs from '../../const/programContainer.constants.service';

angular.module('acadiamasterApp').factory('ContainerItemAppointmentConfig', (
    ContainerItemTypeSpecificConfig, LocalizedStringModel,
) => {
    const classKey = cs.classes.classReferenceKey;
    ContainerItemAppointmentConfig.inheritsFrom(ContainerItemTypeSpecificConfig);

    /**
     * @constructor
     */
    function ContainerItemAppointmentConfig(parent) {
        ContainerItemTypeSpecificConfig.call(this, parent);

        this[classKey] = cs.classes.ContainerItemAppointmentConfig;

        this.title = new LocalizedStringModel();
        this.eligibleText = new LocalizedStringModel();
        this.upcomingText = new LocalizedStringModel();
        this.pastText = new LocalizedStringModel();
        this.cancellationText = new LocalizedStringModel();
        this.hideCancelButton = false;
        this.cancelButtonText = new LocalizedStringModel();
        this.scheduleButtonText = new LocalizedStringModel();
        this.rescheduleButtonText = new LocalizedStringModel();
        this.daysToAllowReschedulingNoShows = 0;
        this.appointmentCategory = null;
        this.scheduleAppointmentURL = null;
        this.rescheduleAppointmentURL = null;
        this.cancelAppointmentURL = null;
        this.externalService = false;

        if(!this.eligibleText.hasValue()) {
            this.eligibleText.fromDto({en : 'You are eligible to schedule an appointment.'});
        }
        if(!this.upcomingText.hasValue()) {
            this.upcomingText.fromDto({en : 'Your appointment has been scheduled.'});
        }
        if(!this.pastText.hasValue()) {
            this.pastText.fromDto({en : 'We have received your sample.'});
        }
        if(!this.cancellationText.hasValue()) {
            this.cancellationText.fromDto({en : 'Your appointment has been cancelled.'});
        }
        if(!this.cancelButtonText.hasValue()) {
            this.cancelButtonText.fromDto({en : 'Cancel', es : 'Cancelar'});
        }
        if(!this.scheduleButtonText.hasValue()) {
            this.scheduleButtonText.fromDto({en : 'Schedule', es : 'Calendario'});
        }
        if(!this.rescheduleButtonText.hasValue()) {
            this.rescheduleButtonText.fromDto({en : 'Reschedule', es : 'Reprogramar'});
        }
    }
    /**
     * convert the current UI model to dto format
     */
    ContainerItemAppointmentConfig.prototype.toDto = function () {
        const dto = ContainerItemTypeSpecificConfig.prototype.toDto.call(this);
        dto.title = this.title.toDto();
        dto.eligibleText = this.eligibleText.toDto();
        dto.upcomingText = this.upcomingText.toDto();
        dto.pastText = this.pastText.toDto();
        dto.cancellationText = this.cancellationText.toDto();
        dto.hideCancelButton = this.hideCancelButton;
        dto.cancelButtonText = this.cancelButtonText.toDto();
        dto.scheduleButtonText = this.scheduleButtonText.toDto();
        dto.rescheduleButtonText = this.rescheduleButtonText.toDto();
        dto.daysToAllowReschedulingNoShows = this.daysToAllowReschedulingNoShows;
        dto.appointmentCategory = this.appointmentCategory;
        dto.scheduleAppointmentURL = this.scheduleAppointmentURL;
        dto.rescheduleAppointmentURL = this.rescheduleAppointmentURL;
        dto.cancelAppointmentURL = this.cancelAppointmentURL;
        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemAppointmentConfig.prototype.fromDto = function (dto) {
        ContainerItemTypeSpecificConfig.prototype.fromDto.call(this, dto);
        this.title.fromDto(dto.title);
        this.eligibleText.fromDto(dto.eligibleText);
        this.upcomingText.fromDto(dto.upcomingText);
        this.pastText.fromDto(dto.pastText);
        this.cancellationText.fromDto(dto.cancellationText);
        this.hideCancelButton = dto.hideCancelButton;
        this.cancelButtonText.fromDto(dto.cancelButtonText);
        this.scheduleButtonText.fromDto(dto.scheduleButtonText);
        this.rescheduleButtonText.fromDto(dto.rescheduleButtonText);
        this.daysToAllowReschedulingNoShows = dto.daysToAllowReschedulingNoShows;
        this.appointmentCategory = dto.appointmentCategory;
        this.scheduleAppointmentURL = dto.scheduleAppointmentURL;
        this.rescheduleAppointmentURL = dto.rescheduleAppointmentURL;
        this.cancelAppointmentURL = dto.cancelAppointmentURL;
    };


    ContainerItemAppointmentConfig.prototype.getDescriptionAsHtml = function () {
        const titleLabel = getTitleLabel(this.title.en);
        return `${titleLabel}`;
    };


    ContainerItemAppointmentConfig.prototype._validateSelf = function () {
        let errMsg = null;
        if (this.title &&
            (this.title.en == null || this.title.en.length === 0)) {
            this._parent.setErrorMessage('Title is required');
        }
        else if (this.eligibleText &&
            (this.eligibleText.en == null || this.eligibleText.en.length === 0)) {
            this._parent.setErrorMessage('Eligible Text is required');
        }
        else if (this.upcomingText &&
            (this.upcomingText.en == null || this.upcomingText.en.length === 0)) {
            this._parent.setErrorMessage('Upcoming Text is required');
        }
        else if (this.pastText &&
            (this.pastText.en == null || this.pastText.en.length === 0)) {
            this._parent.setErrorMessage('Past Text is required');
        }
        else if (this.cancellationText &&
            (this.cancellationText.en == null || this.cancellationText.en.length === 0)) {
            this._parent.setErrorMessage('Cancellation Text is required');
        }
        else if (!this.hideCancelButton && this.cancelButtonText &&
            (this.cancelButtonText.en == null || this.cancelButtonText.en.length === 0)) {
            this._parent.setErrorMessage('Cancel Button Text is required');
        }
        else if (this.scheduleButtonText &&
            (this.scheduleButtonText.en == null || this.scheduleButtonText.en.length === 0)) {
            this._parent.setErrorMessage('Schedule Button Text is required');
        }
        else if (this.rescheduleButtonText &&
            (this.rescheduleButtonText.en == null || this.rescheduleButtonText.en.length === 0)) {
            this._parent.setErrorMessage('Reschedule Button Text is required');
        }
        else if (this.appointmentCategory == null) {
            this._parent.setErrorMessage('The Appointment Category is required');
        }
        else if (this.externalService == true){
            if (this.scheduleAppointmentURL == null || this.scheduleAppointmentURL == '') {
                this._parent.setErrorMessage('The scheduleAppointmentURL is required');
            }
            else if (this.rescheduleAppointmentURL == null || this.rescheduleAppointmentURL == '') {
                this._parent.setErrorMessage('The rescheduleAppointmentURL is required');
            }
            else if (this.cancelAppointmentURL == null || this.cancelAppointmentURL == '') {
                this._parent.setErrorMessage('The cancelAppointmentURL is required');
            }
        }
        return errMsg;
    };


    /** *************************************
     * private function call
     ************************************** */

    function getTitleLabel(title) {
        if (!title) {
            return `No title added`;
        } else {
            return shortenString(title, 30);
        }
    }

    function shortenString(input, maxLength) {
        if (input == null || maxLength == null || maxLength <= 3 || input.length <= maxLength) {
            return input;
        }

        return input.substring(0, maxLength - 3) + '...';
    }

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemAppointmentConfig;
});
