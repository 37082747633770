/**
 * Created by Jamie Nola on 12/07/2018
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .constant('StringManagementConstants', function () {
            var LEVEL = {
                SYSTEM: 'SYSTEM',
                PROGRAM: 'PROGRAM'
            };

            return {
                LEVEL: LEVEL
            };
        }());
})();
