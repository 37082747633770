(function () {
    'use strict';

    angular.module('acadiamasterApp').controller('externalDataImportCreateTaskController',
        function ($scope,$state,ExternalDataImportService, ProgramService) {


            function init(){


                $scope.taskData = {
                    taskName: '',
                    externalSource: '',
                    programCode: '',
                    responseCsvFilePath: '',
                    surveyDictionaryFilePath: '',
                    userIdentifierFromCsv: ''
                };
                $scope.fileData = {
                    responseCsvFile: '',
                    surveyDictionaryFile: ''
                };
                $scope.files = [];
                $scope.programs = ProgramService.query();
            }

            $scope.importFormFromEntity = {
                REDCAP: 'REDCAP'
            };


            $scope.save = function() {
              $scope.taskData.responseCsvFilePath =  $scope.fileData.responseCsvFile.name;
              $scope.taskData.surveyDictionaryFilePath =  $scope.fileData.surveyDictionaryFile.name;
              $scope.files.push($scope.fileData.responseCsvFile);
              $scope.files.push($scope.fileData.surveyDictionaryFile);

                ExternalDataImportService.createTask($scope.taskData,$scope.files)
                .then(function (response) {
                    init();
                    $state.go('externalDataImport');
                 }, function (error) {
                    console.log(error);
                    $scope.files= [];
                 });


            };

            init();
    });

})();

