'use strict';
(function () {
    angular.module('acadiamasterApp').factory('ProgramTestExecutionAudit', function ($resource, $http, localWeb) {
        var resource = $resource(localWeb.createUrl('api/programTest/audit/:id'), {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    var processedData = angular.fromJson(data);
                    return processedData;
                }
            },
        });
        return resource;
    });
})();
