import ContainerItemType from '../../../const/ContainerItemType.const';

/**
 * created by Jason Cao at 12/05/2019
 * container item type specific config directive, this is just a top level directive that
 * will invoke other type specific configuration.
 */
angular.module('acadiamasterApp').directive('containerItemTypeSpecificConfig', () => ({
    link($scope) {
        $scope.ContainerItemType = ContainerItemType;

        /**
         * decide if this item is of an action type,
         * if it is set useCustomDisplayConfig to true
         * @param item - container item
         * @returns {boolean} - true if action item, false otherwise
         */
        $scope.isActionItem = item => {
            if (!item) {
                return false;
            }
            const result = Object.values(ContainerItemType.ACTION).includes(item.type);
            if (result) {
                item.useCustomDisplayConfig = true;
            }
            return result;
        };
    },
    restrict: 'E',
    scope: {
        containerItem: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement'
            + '/config/containerItem/typeSpecific/containerItemTypeSpecificConfig.html',
}));
