/**
 * Created by Jamie Nola on 06/26/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('importWizardSessionDetailModalCategory', function (ngDialog, IMPORT_WIZARD_CONSTANTS) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/importWizard/sessionDetailModal/category/importWizardSessionDetailModalCategory.template.html',
                scope: {
                    category: '='
                },
                link: function ($scope) {
                    $scope.cs = IMPORT_WIZARD_CONSTANTS;

                    $scope.openStackTrace = function (file) {
                        ngDialog.open({
                            template: '<import-wizard-stack-trace-modal' +
                                ' entity-id="' + file.entityId +
                                '" entity-name="' + file.entityName +
                                '" status="' + file.status +
                                '" error-message="' + file.errorMessage +
                                '" stack-trace="' + file.stackTrace +
                                '"></import-wizard-stack-trace-modal>',
                            plain: true,
                            scope: $scope,
                            size: 'md',
                            className: 'ngdialog-theme-plain custom-width-medium'
                        });
                    };

                    $scope.sortCategory = function(header) {
                        if(header.sortable) {
                            $scope.category.orderByAscending = ($scope.category.orderBy === header.id) ? !$scope.category.orderByAscending : true;
                            $scope.category.orderBy = header.id;
                        }
                    };
                }
            };
        });
})();
