/**
 * Created by Jamie Nola on 04/22/2019
 *
 * Allows client-side pagination using ng-repeat
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('paginate', function () {
            return function (input, currentPage, pageSize) {
                var start = +(currentPage * pageSize); //parse to int
                var end = +(start + pageSize);
                return input.slice(start, end);
            }
        });
})();
