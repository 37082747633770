(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleConfiguration', function (RuleUtilService, $state, RuleModelService,
            CONFIG, AlertService, ngDialog, BusinessRule, $window, RuleToKmapService,
            FlowChartKmapService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/rule/rule-configuration.html',
                scope: {
                    type: '@',
                    programId: '@',
                    isNew: '=',
                    stateAfterSave: '=',
                    stateAfterCancel: '=',
                    isCloned: '=?',
                    rule: '='
                },
                link: function ($scope) {
                    $scope.treeOptions = RuleUtilService.ruleTreeOptions;
                    $scope.selector = RuleUtilService.selector.createSelector();

                    $scope.ruleArray = [];
                    $scope.CONFIG = CONFIG;

                    // States for create/edit controller
                    $scope.states = {
                        entityReady: false,
                        isAddFieldPopupOpen: false,
                        debugEnabled: false
                    };

                    // States for create/edit controller
                    $scope.states = {
                        entityReady: false,
                        isAddFieldPopupOpen: false,
                        debugEnabled: false
                    };

                    // Override selector to trigger validation when a new item is selected
                    var selectItemOriginal = $scope.selector.selectItem;
                    $scope.selector.selectItem = function () {
                        $scope.rule.validate(true);
                        selectItemOriginal.apply(this, arguments);
                    };

                    // Controls
                    $scope.controls = {
                        cancel: function () {
                            $state.go($scope.stateAfterCancel);
                        },
                        save: function () {
                            doSave($scope);
                        },
                        preview: function () {
                            // For now there is no implementation
                        },
                        onSaveFinished: function () {
                            $state.go($scope.stateAfterSave);
                        },
                        isValid: function () {
                            $scope.rule.validate(true);
                            return $scope.rule.isValid();
                        },
                        validate: function () {
                            $scope.rule.validate(true);
                            if ($scope.controls.isValid()) {
                                AlertService.success('Your configuration seems alright. Go ahead and save it.');
                            } else {
                                AlertService.warning('There are some validation errors in the rule you are trying to save. ' +
                                    'Please check the highlighted nodes to see what is missing');
                            }
                        },
                        importRuleWithoutIds: function () {
                            var _scope = $scope;
                            ngDialog.openConfirm({
                                template: '<import-rule-without-ids title="Upload Rule" on-success-callback="onSuccess"></import-rule-without-ids>',
                                plain: true,
                                controller: ['$scope', function ($scope) {
                                    $scope.onSuccess = function (data) {
                                        if (data) {
                                            _scope.isCloned = true;
                                            _scope.ruleArray = [];
                                            _scope.programId = data.programId;
                                            setRuleEntity(_scope, data);
                                        }
                                    }
                                }]
                            });
                        },
                        openFlowChartWindow: function (ruleId) {
                            var ruleDetail = BusinessRule.get({
                                id: ruleId
                            });
                            ruleDetail.$promise.then(function (data) {
                                var url = '/assets/kmap/ruleMap/rule.html';
                                var newWindow = $window.open(url, 'rule_flow_chart_window');
                                var model = RuleToKmapService.transform(data);
                                FlowChartKmapService.loadRuleModel(newWindow, model, 'TB', null, null);
                            });
                        }
                    };

                    init($scope);


                }
            };


            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                // Resolve entity
                resolveEntityIfNeeded($scope);

                // Reset the auto generated key
                RuleUtilService.localCodeGenerator.resetCode(RuleUtilService.ruleConstants.codeType.ACTION);

            }

            /**
             * save object
             * @param $scope
             */
            function doSave($scope) {
                if ($scope.controls.isValid()) {
                    RuleModelService.saveRule([], $scope.rule, $scope.controls.onSaveFinished);
                    $scope.ruleDTO = $scope.rule.toDto([]);
                } else {
                    AlertService.warning('There are some validation errors in the rule you are trying to save. ' +
                        'Please check the highlighted nodes to see what is missing');
                }


            }

            function resolveEntityIfNeeded($scope) {
                if ($scope.isNew && !$scope.isCloned) {
                    setRuleEntity($scope, null);
                } else {
                    resolveRuleEntity($scope);
                }
            }

            function resolveRuleEntity($scope) {
                $scope.rule.$promise.then(function (data) {
                    setRuleEntity($scope, data);
                });
            }

            function setRuleEntity($scope, dto) {
                $scope.rule = new RuleModelService.RuleModel($scope.type, $scope.programId);
                if (dto != null) {
                    $scope.rule.fromDto(dto);
                    if ($scope.isCloned) {
                        $scope.rule.customCloneReset(true);
                    }
                    $scope.ruleDTO = dto;
                }

                $scope.ruleArray.push($scope.rule);
                $scope.states.entityReady = true;
            }


        });
})();
