/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for field value for boolean selector
     */
        .directive('formFieldConfigFieldValueBooleanSelector', function (FormModelServiceFieldValue,
                                                                         LocaleConstantsService, ImagePresetConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/booleanSelector.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.ImagePresetConstants = ImagePresetConstants;

                    $scope.hasChangeModel = $scope.fieldValueChange != null;
                    if ($scope.hasChangeModel) {
                        initChangeModelValuesIfNeeded($scope);
                    }

                    $scope.getOptionValueEnabled = function () {
                        if ($scope.fieldValueChange == null || $scope.fieldValueChange.optionValue == null ||
                            $scope.fieldValueChange.optionValue.enabled == null) {
                            return $scope.fieldValue.optionValue.enabled;
                        }
                        else {
                            return $scope.fieldValueChange.optionValue.enabled;
                        }
                    }
                }
            };

            function initChangeModelValuesIfNeeded($scope) {
                if ($scope.fieldValueChange.optionValue == null) {
                    $scope.fieldValueChange.optionValue = {
                        enabled: null
                    };
                }
            }

        });

})();

