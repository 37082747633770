(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerVxpEvent', function (RuleUtilService) {
            var VXPEventTriggerController = function () {
                var vm = this;
                vm.data = {
                    VXPEvents: RuleUtilService.ruleConstants.VXPEvents,
                };
            };

            return {
                bindToController: true,
                controller: VXPEventTriggerController,
                controllerAs: 'vxp',
                restrict: 'E',
                scope: {
                    element: '='
                },
                templateUrl: 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.vxpEvent.html',
            };
        });
})();
