/**
 *  Form entry search directive
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrDrcFormEntrySearch', function (Form, RenderUtil) {

        /***************************************
         * directive return call
         ***************************************/

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/drc/formEntrySearch/drc.formEntrySearch.html',
            scope: {},
            link: function ($scope) {

                $scope.data = {
                    searchResult: {},
                    selectedItem: null,
                    selectedItemType: null,
                    formModel: null,
                };

                // list of control functions that can be passed to other directives
                $scope.controls = {
                    userIdClicked: function (user) {
                        userSelected($scope, user);
                    },
                    userProfileClicked: function (user) {
                        userSelected($scope, user);
                    },
                    entryClicked: function (formEntry) {
                        formEntrySelected($scope, formEntry);
                    },
                    closeDetailPanel: function () {
                        closeDetailPanel($scope);
                    }
                };

                // options for user profile detail page
                $scope.userProfileViewOptions = {
                    allowChange: true,
                    showExternalFlagControl: true
                };

            }
        };

        function userSelected($scope, user) {
            $scope.data.selectedItem = user;
            $scope.data.selectedItemType = 'USER';
        }

        function formEntrySelected($scope, formEntry) {
            Form.get({
                id: formEntry.formId,
                projection: Form.projections.SPECIFIC_VERSION,
                formVersionId: formEntry.formVersionId
            }).$promise.then(function (data) {
                $scope.data.formModel = RenderUtil.createFormModel(data);
                $scope.data.selectedItem = formEntry;
                $scope.data.selectedItemType = 'FORM_ENTRY';
            });
        }

        function closeDetailPanel($scope) {
            $scope.data.selectedItem = null;
            $scope.data.selectedItemType = null;
        }

    });
})();
