(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for adding a banner
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionAddBannerModel',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, RuleConstantsService, FormLoadUtil,
                  BannerSpecificDataModel, BannerNotificationTargetAreaModel) {

            CorrectiveActionAddBannerModel.inheritsFrom(CorrectiveActionBaseModel);

            function CorrectiveActionAddBannerModel(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.ADD_BANNER);

                this.bannerType = RuleConstantsService.bannerType.OPEN_FORM;
                this.bannerSpecificData = new BannerSpecificDataModel();
                this.bannerNotificationTargetAreas = [];
                this.addBannerNotificationTargetArea(RuleConstantsService.bannerDisplayTargetArea.DASHBOARD);
                this.formId = null;

                this._form = null;
            }

            CorrectiveActionAddBannerModel.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);
                this.bannerType = RuleConstantsService.getObjectByName(RuleConstantsService.bannerType, dto.bannerType);
                this.formId = dto.formId;
                this.bannerSpecificData = new BannerSpecificDataModel();
                this.bannerSpecificData.fromDto(dto.bannerSpecificData);
                if (dto.bannerNotificationTargetAreas != null) {
                    var bannerNotificationTargetAreas = [];

                    _.forEach(dto.bannerNotificationTargetAreas, function (targetArea) {
                        var targetAreaModel = new BannerNotificationTargetAreaModel();
                        targetAreaModel.fromDto(targetArea);
                        bannerNotificationTargetAreas.push(targetAreaModel);
                    });

                    this.bannerNotificationTargetAreas = bannerNotificationTargetAreas;
                }

                FormLoadUtil.loadForm(this);
            };

            CorrectiveActionAddBannerModel.prototype.toDto = function () {
                var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.bannerType = this.bannerType.name;
                dto.formId = this.formId;
                dto.bannerSpecificData = this.bannerSpecificData.toDto();
                if (this.bannerNotificationTargetAreas != null) {
                    var bannerNotificationTargetAreas = [];
                    _.forEach(this.bannerNotificationTargetAreas, function (targetArea) {
                        bannerNotificationTargetAreas.push(targetArea.toDto());
                    });
                    dto.bannerNotificationTargetAreas = bannerNotificationTargetAreas;
                }

                return dto;
            };

            CorrectiveActionAddBannerModel.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form == null ? null : form.id;
            };

            CorrectiveActionAddBannerModel.prototype.getForm = function () {
                return this._form;
            };

            CorrectiveActionAddBannerModel.prototype.bannerTypeNeedsForm = function () {
                return this.bannerType != null && this.bannerType == RuleConstantsService.bannerType.OPEN_FORM;
            };

            CorrectiveActionAddBannerModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);
                return typeMsg + 'Banner Type: - ' + (this.bannerType!=null ? this.bannerType.name : 'Not Selected')
                    + (this.bannerTypeNeedsForm() ? ' with form id ' + this.formId : '');
            };

            CorrectiveActionAddBannerModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.bannerType == null) {
                    this.setErrorMessage('banner type is required');
                }
            };

            CorrectiveActionAddBannerModel.prototype.addBannerNotificationTargetArea = function (targetAreaType) {
                this.bannerNotificationTargetAreas.push(new BannerNotificationTargetAreaModel(targetAreaType));
            };

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionAddBannerModel;
        });
})();
