/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * user over time chart/grid directive inside admin dashboard
     */
        .directive('vbrUsersOverTime', function () {
            var roles = ['Subscriber', 'Provider', 'Program Creator', 'Customer Support', 'Admin'];
            var weekNames = ["Oct 26, 2015", "Oct 19, 2015", "Oct 12, 2015", "Oct 05, 2015", "Sep 28, 2015"];

            return {
                restrict: 'E',
                templateUrl: "admin-templates/site/usersOverTime.html",
                scope: {},
                link: function ($scope) {
                    console.log('in vbrUsersOverTime.link()');
                    $scope.dataIsReady = false;
                    var sampleData = createSampleData();

                    $scope.gridSettings = createGridSettings(sampleData);
                    $scope.chartSettings = createChartSettings(sampleData);

                    $scope.dataIsReady = true;
                }
            };

            /*****************************************************************************************
             * private functions after this
             *****************************************************************************************/


            function createRandom(start, end) {
                if (start==null) start = 0;
                if (end==null) end = 100;

                return Math.floor(Math.random() * (end-start) + start);
            }

            function createSampleData() {
                var sampleData = [];

                for (var i = 0; i < weekNames.length; i++) {
                    var weeklyData = {week : weekNames[i]};
                    for (var j = 0; j < roles.length; j++) {
                        weeklyData[roles[j]] = createRandom(0, 20);
                    }

                    sampleData.push(weeklyData);
                }

                return sampleData;
            }

            function createGridSettings(sourceData) {
                var dataFields = [{ name: "week", type: "string" }];
                for (var i = 0; i < roles.length; i++) {
                    var roleName = roles[i];
                    dataFields.push({name : roleName, type : "number"});
                }

                var adapter = new $.jqx.dataAdapter({
                    datafields: dataFields,
                    localdata: sourceData,
                    datatype: 'array'
                });

                return {
                    width: '100%',
                    autoheight: true,
                    source: adapter,
                    columns:
                        [
                            { text: "Week", width: '25%', datafield: "week" },
                            { text: roles[0], width: '15%', datafield: roles[0], filterable : false },
                            { text: roles[1], width: '15%', datafield: roles[1], filterable : false },
                            { text: roles[2], width: '15%', datafield: roles[2], filterable : false },
                            { text: roles[3], width: '15%', datafield: roles[3], filterable : false },
                            { text: roles[4], width: '15%', datafield: roles[4], filterable : false }
                        ]
                };
            }

            function createChartSettings(sourceData) {
                var series = [];
                for (var i = 0; i < roles.length; i++) {
                    var roleName = roles[i];
                    series.push({
                        dataField : roleName, displayText : roleName
                    });
                }
                /*
                 [
                 { dataField: 'Referral', displayText: 'Referral Traffic' },
                 { dataField: 'SearchPaid', displayText: 'Paid Search Traffic' },
                 { dataField: 'SearchNonPaid', displayText: 'Organic Search Traffic' }
                 ]
                 */
                return {
                    title: "New Users Added Over Time",
                    description: "(last 5 weeks)",
                    enableAnimations: true,
                    showLegend: true,
                    padding: { left: 5, top: 5, right: 5, bottom: 5 },
                    // titlePadding: { left: 90, top: 0, right: 0, bottom: 10 },
                    source: sourceData,
                    xAxis:
                        {
                            dataField: 'week',
                            unitInterval: 1,
                            tickMarks: { visible: true, interval: 1 },
                            gridLinesInterval: { visible: true, interval: 1 },
                            valuesOnTicks: false,
                            padding: { bottom: 10 }
                        },
                    //colorScheme: 'scheme01',
                    seriesGroups:
                        [
                            {
                                type: 'line',
                                series: series
                            }
                        ]
                };
            }

        });

})();

