(function () {
    angular.module('vibrent-external-survey-parser').factory('vbrLocalIdGeneratorService', () => {
        /**
         * local id generator
         */
        function LocalIdGenerator() {
            // this is pretty inefficient, could have used a map for faster lookup,
            // but for our purpose, only <50 id will exist, probably okay for now
            this.existingIds = {};
            this.maxIdNumber = 100;
        }

        LocalIdGenerator.prototype.addExistingId = function (id) {
            if (id == null) {
                return;
            }
            if (this.existingIds[id] == null) {
                this.existingIds[id] = id;
            }
        };

        LocalIdGenerator.prototype.resetIds = function () {
            this.existingIds = {};
        };

        LocalIdGenerator.prototype.getNextId = function () {
            let newId;
            let retryCount = 0;
            do {
                if (retryCount >= 3) {
                    this.maxIdNumber *= 2;
                    retryCount = 0;
                }
                newId = Math.floor(Math.random() * this.maxIdNumber);
                retryCount += 1;
            } while (this.existingIds[newId] != null);

            this.addExistingId(newId);

            return newId;
        };


        return LocalIdGenerator;
    });
}());
