'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('propertyPossibleValue', {
                parent: 'entity',
                url: '/propertyPossibleValues',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.propertyPossibleValue.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/propertyPossibleValue/propertyPossibleValues.html',
                        controller: 'PropertyPossibleValueController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('propertyPossibleValue');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('propertyPossibleValue.detail', {
                parent: 'propertyPossibleValue',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.propertyPossibleValue.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/propertyPossibleValue/propertyPossibleValue-detail.html',
                        controller: 'PropertyPossibleValueDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('propertyPossibleValue');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PropertyPossibleValue', function($stateParams, PropertyPossibleValue) {
                        return PropertyPossibleValue.get({id : $stateParams.id});
                    }]
                }
            })
            .state('propertyPossibleValue.new', {
                parent: 'propertyPossibleValue',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/dataTypeMaster/propertyPossibleValue/propertyPossibleValue-dialog.html',
                        controller: 'PropertyPossibleValueDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {value: null, createdOn: null, updatedOn: null, id: null};
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('propertyPossibleValue', null, { reload: true });
                    }, function() {
                        $state.go('propertyPossibleValue');
                    })
                }]
            })
            .state('propertyPossibleValue.edit', {
                parent: 'propertyPossibleValue',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/dataTypeMaster/propertyPossibleValue/propertyPossibleValue-dialog.html',
                        controller: 'PropertyPossibleValueDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['PropertyPossibleValue', function(PropertyPossibleValue) {
                                return PropertyPossibleValue.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('propertyPossibleValue', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    });
