
angular.module('acadiamasterApp')
    .factory('ErrorHandlerService', (AlertService, $rootScope, $translate) => {
        function setupErrorListener() {
            const addErrorAlert = function (message, key, data) {
                key = key && key != null ? key : message;
                AlertService.error(key, data);
            };

            // TODO: error handling may need to be enhanced, not exactly sure how error messages are constructed on server right now
            // I think we are no longer following the standard set by jhipster generated code and that causes issue with error message
            // display, especially when dealing with i18n translation
            $rootScope.$on('sharedUtilities.httpError', (event, httpResponse) => {
                event.stopPropagation();
                switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    addErrorAlert('Server not reachable', 'error.serverNotReachable');
                    break;
                case 400:
                    handleBadRequest(httpResponse, addErrorAlert);
                    break;
                case 401:
                    handleUnauthorized(httpResponse, addErrorAlert);
                    break;
                    // ignoring all 404 responses
                case 404:
                    break;
                default:
                    handleDefault(httpResponse, addErrorAlert);
                }
            });
        }


        function handleBadRequest(httpResponse, addErrorAlert) {
            if (httpResponse.data && httpResponse.data.fieldErrors) {
                for (let i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                    const fieldError = httpResponse.data.fieldErrors[i];
                    // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                    const convertedField = (fieldError && fieldError.field) ? fieldError.field.replace(/\[\d*\]/g, '[]') : '';
                    const fieldName = $translate.instant(`sharedUtilities.${fieldError.objectName}.${convertedField}`);
                    addErrorAlert(`Field ${fieldName} cannot be empty`, `error.${fieldError.message}`, { fieldName });
                }
            } else if (httpResponse.data && httpResponse.data.message) {
                addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
            } else if (httpResponse.data && httpResponse.data.errorCode != null) {
                let errorMsg = '';
                if (httpResponse.data.description) {
                    errorMsg += `${httpResponse.data.description}<br>`;
                }
                errorMsg += httpResponse.data.errorCode;

                addErrorAlert(errorMsg, null);
            } else if (httpResponse.statusText != null) {
                addErrorAlert(httpResponse.statusText);
            } else {
                addErrorAlert(httpResponse.data);
            }
        }

        function handleUnauthorized(httpResponse, addErrorAlert) {
            if (httpResponse.data && httpResponse.data.description) {
                if (httpResponse.data.description == 'Unauthorized Access') {
                    if ($rootScope.currentPage) {
                        addErrorAlert(httpResponse.statusText);
                    }
                } else {
                    addErrorAlert(httpResponse.data.description);
                }
            } else if (httpResponse.statusText != null) {
                addErrorAlert(httpResponse.statusText);
            }
        }

        function handleDefault(httpResponse, addErrorAlert) {
            if (httpResponse.data && httpResponse.data.message) {
                addErrorAlert(httpResponse.data.message);
            } else if (httpResponse.data && httpResponse.data.errorCode != null) {
                addErrorAlert(httpResponse.data.errorCode, httpResponse.data.description);
            } else if (httpResponse.statusText != null) {
                addErrorAlert(httpResponse.statusText);
            } else {
                addErrorAlert(JSON.stringify(httpResponse));
            }
        }

        return {
            init() {
                setupErrorListener();
            },
        };
    });
