/**
 * directive for listing form entry with user's profile information together
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrUserEntryProfileListing', function (FormEntryUtilService) {
        var cs = {
            USER_ID_CLICK_FUNCTION: 'USER_ID_CLICK_FUNCTION',
            USER_PROFILE_CLICK_FUNCTION: 'USER_PROFILE_CLICK_FUNCTION',
            ENTRY_CLICK_FUNCTION: 'ENTRY_CLICK_FUNCTION'
        };

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/formEntry/formEntryListing.withProfile.html',
            scope: {
                listingData: '=',
                /**
                 * onClickControls includes a number of functions
                 * userIdClicked(user)
                 * userProfileClicked(user)
                 * entryClicked(formEntry)
                 */
                onClickControls: '='
            },
            link: function ($scope) {

                // table data that's used for easy ng-repeats
                $scope.tableData = {
                    tableTitles: [],
                    rows: []
                };

                $scope.handleClickFunction = function (functionType, data) {
                    if (functionType === cs.USER_ID_CLICK_FUNCTION) {
                        $scope.onClickControls.userIdClicked(data);
                    }
                    else if (functionType === cs.USER_PROFILE_CLICK_FUNCTION) {
                        $scope.onClickControls.userProfileClicked(data);
                    }
                    else if (functionType === cs.ENTRY_CLICK_FUNCTION) {
                        $scope.onClickControls.entryClicked(data);
                    }
                };

                setupWatches($scope);
            }
        };

        function reset($scope) {
            $scope.tableData = {
                tableTitles: [],
                rows: []
            };
        }

        function createTitle(titleHtml, iconClass, mouseOverHtml) {
            return {
                titleHtml: titleHtml,
                iconClass: iconClass,
                mouseOverHtml: mouseOverHtml
            };
        }

        function rebuildTableTitle(columns) {
            var titles = [];
            titles.push(createTitle('User Id', null, null));
            titles.push(createTitle('Profile Values', null, null));
            titles.push(createTitle('Form Id', null, null));
            titles.push(createTitle('Form Name', null, null));
            titles.push(createTitle('Entry Id', null, null));

            // go through the columns
            if (columns != null) {
                _.forEach(columns, function (col) {
                    if (col.formId != null) {
                        var info = '<b>formId</b> : ' + col.formId +
                            '<br><b>fieldId</b> : ' + col.fieldId +
                            '<br><b>valueType</b> : ' + col.valueType +
                            '<br>' + col.fieldHtml;

                        var titleName = col.name + '<br>(' + col.formId + ' | ' + col.fieldId + ')';

                        titles.push(createTitle(titleName, 'glyphicon glyphicon-info-sign', info));
                    }
                });
            }

            return titles;
        }

        function createDataCol(html, rowspan, functionType, functionData) {
            return {
                html: html,
                rowspan: rowspan,
                functionType: functionType,
                functionData: functionData
            };
        }

        function createDataColUserId(user, hasClickFunction) {
            var rowspan = user.entries.length;

            var html = '' + user.id;
            var functionType = null;
            var functionData = null;

            if (hasClickFunction) {
                functionData = user;
                functionType = cs.USER_ID_CLICK_FUNCTION;
            }

            return createDataCol(html, rowspan, functionType, functionData);
        }


        function createDataColProfileValues(user, hasClickFunction, columns) {
            var rowspan = user.entries.length;

            var profiles = user.profiles;

            var html = '<div class=\'alert alert-info\'><b>total profiles</b>: ' + user.numOfProfiles + '</div>';

            // get a list of profile values that we need to display
            _.forEach(columns, function (c) {
                if (c.type === 'PROFILE_FIELD') {
                    html += '<b>' + c.name + '</b> = ';

                    if (profiles[c.name] != null) {
                        var profileValues = profiles[c.name].profileValues;
                    } else {
                        var profileValues = [];
                    }
                    var valueString = [];
                    _.forEach(profileValues, function (pv) {
                        valueString.push(FormEntryUtilService.convertFieldEntryValueToHtml(pv, c.valueType === 'DATETIME'));
                    });

                    html += _.join(valueString, ', ');
                    html += '<br>';
                }
            });

            var functionType = null;
            var functionData = null;

            if (profiles != null && hasClickFunction) {
                functionData = user;
                functionType = cs.USER_PROFILE_CLICK_FUNCTION;
            }

            return createDataCol(html, rowspan, functionType, functionData);
        }

        function createDataColFieldEntry(formFieldEntry, isDate) {
            var html = '';
            if (formFieldEntry != null) {
                var values = formFieldEntry.formFieldEntryValues;
                var valueString = [];
                _.forEach(values, function (v) {
                    valueString.push(FormEntryUtilService.convertFieldEntryValueToHtml(v, isDate));
                });

                html = _.join(valueString, ', ');
            }

            return createDataCol(html, 1, null, null);
        }

        function createDataColFormEntryId(entry, hasClickFunction) {
            var html = '' + entry.id;
            var functionType = null;
            var functionData = null;

            if (hasClickFunction) {
                functionData = entry;
                functionType = cs.ENTRY_CLICK_FUNCTION;
            }

            return createDataCol(html, 1, functionType, functionData);
        }

        function rebuildTableRows(listingData, controls) {
            var hasIdClickedFunction = _.isFunction(controls.userIdClicked);
            var hasProfileClickedFunction = _.isFunction(controls.userProfileClicked);
            var hasEntryClickedFunction = _.isFunction(controls.entryClicked);

            var rows = [];

            // go through each user
            _.forOwn(listingData.users, function (user) {
                // create a row and fill-in first few rows
                var row = [];

                // add user id data
                row.push(createDataColUserId(user, hasIdClickedFunction));

                // add profile values
                row.push(createDataColProfileValues(user, hasProfileClickedFunction, listingData.columns));

                // go through each form entry to fill in the rest of the column,
                _.forEach(user.entries, function (fe) {
                    // add form Id and form version Id
                    row.push(createDataCol('' + fe.formId + ' | ' + fe.formVersionId, 1, null, null));

                    // add form name
                    row.push(createDataCol('' + fe.formName, 1, null, null));

                    // add form entry id
                    row.push(createDataColFormEntryId(fe, hasEntryClickedFunction));

                    // go through the field entries and match them with column needed to display
                    _.forEach(listingData.columns, function (column) {
                        if (column.type === 'FORM_FIELD') {
                            // find the field entry for it
                            var formFieldEntry = _.find(fe.formFieldEntries, function (ffe) {
                                return column.fieldId === ffe.formFieldId;
                            });

                            row.push(createDataColFieldEntry(formFieldEntry, column.valueType === 'DATETIME'));
                        }

                    });

                    // add row into rows and start a new row
                    rows.push(row);
                    row = [];
                });
            });

            return rows;
        }

        function rebuildTableData(listingData, $scope) {
            var data = $scope.tableData;
            var columns = listingData.columns;

            data.tableTitles = rebuildTableTitle(columns);
            data.rows = rebuildTableRows(listingData, $scope.onClickControls);
        }

        function setupWatches($scope) {
            $scope.$watch('listingData', function (newData) {
                if (newData === null) {
                    reset($scope);
                }
                else {
                    rebuildTableData(newData, $scope);
                }
            });
        }

    });
})();