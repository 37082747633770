angular.module('acadiamasterApp')
    .config($stateProvider => {
        $stateProvider
            .state('login', {
                parent : 'account',
                url    : '/login',
                data   : {
                    authorities    : [],
                    pageTitle      : 'login.title',
                    hideBreadcrumb : true,
                },
                views : {
                    'content@' : {
                        templateUrl : 'admin-templates/account/login/login.html',
                        controller  : 'LoginController',
                    },
                },
                resolve : {
                    translatePartialLoader : ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('login');

                        return $translate.refresh();
                    }],
                },
            });
    });
