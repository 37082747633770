'use strict';

angular.module('acadiamasterApp')
    .factory('errorHandlerInterceptor', ['$q', '$rootScope', function ($q, $rootScope) {
        return {
            'responseError': function (response) {
                $rootScope.$emit('sharedUtilities.httpError', response);
                return $q.reject(response);
            }
        };
    }]);
