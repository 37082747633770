/**
 * program configuration for social icons
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programSocialIconsConfig', function (FormModelServiceFieldValue) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programManagement/program/socialIcons/program.socialIcons.html',
            scope: {
                socialMediaIcons: '=',
                showReadOnly: '='
            },
            controller: controller
        };
        function controller($scope) {
            function unSetSocialMedia() {
                if (!$scope.socialMediaIcons.enableTwitter) {
                    $scope.socialMediaIcons.twitterURL = null;
                    $scope.socialMediaIcons.twitterIcon = new FormModelServiceFieldValue.ImageValue();
                }
                if (!$scope.socialMediaIcons.enableFacebook) {
                    $scope.socialMediaIcons.facebookURL = null;
                    $scope.socialMediaIcons.facebookIcon = new FormModelServiceFieldValue.ImageValue();
                }
                if (!$scope.socialMediaIcons.enableYouTube) {
                    $scope.socialMediaIcons.youTubeURL = null;
                    $scope.socialMediaIcons.youTubeIcon = new FormModelServiceFieldValue.ImageValue();
                }
                if (!$scope.socialMediaIcons.enableInstagram) {
                    $scope.socialMediaIcons.instagramURL = null;
                    $scope.socialMediaIcons.instagramIcon = new FormModelServiceFieldValue.ImageValue();
                }
            }
            $scope.unSetSocialMedia = unSetSocialMedia;
        }

    });

})();
