/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config of advanced value with unit property properties
     * options will include the following properties
     *    careAboutParentWrapContent -- boolean indicate if we care about whether parent's height is wrap content
     *    unitsNormal -- normal unit list
     *    unitsParentIsWrapContent -- unit list to use when parent is wrap content
     *    required -- whether field value is required
     *    mustBePositive -- whether field value must be positive
     *    mustNotBeNegative -- whether field value must be non negative
     *    noHigherThan100Percent -- whether field value must be no higher than 100 %
     *    noHigherThan100PercentCombined -- whether field value combined with another model must be no higher than 100%
     */
    .directive('configValueWithUnitAdvanced', function (FormUtilService) {
        var errorMessages = {
            'required' : 'value is required',
            'noHigherThan100PercentCombined' : 'combined value can not be bigger than 100%',
            'mustNotBeNegative' : 'value must not be a negative number',
            'mustBePositive' : 'value must be a positive number',
            'noHigherThan100Percent' : 'value can not be bigger than 100%'
        };

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/common/valueWithUnit.advanced.html',
            scope: {
                valueModel: '=',
                combinedValueModel : '=',
                element : '=',
                iconClass : '@',
                contentTitle : '@',
                nullContentTitle : '@',
                options : '='
            },
            link: function ($scope) {
                $scope.ec = FormUtilService.elementCompare;

                $scope.getUnits = function() {
                    return getUnits($scope);
                };

                $scope.errorMessage = null;

                $scope.doValidate = function() {
                    doValidate($scope);
                };

                $scope.$watchGroup(['combinedValueModel.value', 'combinedValueModel.unit'], function(newData, oldData) {
                    doValidate($scope);
                });

                doValidate($scope);
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/
        function setErrorMessage($scope, key) {
            if ($scope.options[key] && errorMessages[key]) {
                $scope.errorMessage = errorMessages[key];
            }
        }

        function doValidate($scope) {
            $scope.errorMessage = null;
            if ($scope.valueModel==null) {
                setErrorMessage($scope, 'required');
            }
            else {
                var inputValue = $scope.valueModel.value;
                var inputUnit = $scope.valueModel.unit;
                if (inputValue==null) {
                    setErrorMessage($scope, 'required');
                }
                else if (inputValue<=0) {
                    setErrorMessage($scope, 'mustBePositive');
                    if (inputValue<0) {
                        setErrorMessage($scope, 'mustNotBeNegative');
                    }
                }
                else if (inputUnit=='%') {
                    if (inputValue>100) {
                        setErrorMessage($scope, 'noHigherThan100Percent');
                    }
                    else if ($scope.combinedValueModel!=null && $scope.combinedValueModel.value!=null
                        && $scope.combinedValueModel.unit=='%') {
                        if ($scope.combinedValueModel.value + inputValue > 100) {
                            setErrorMessage($scope, 'noHigherThan100PercentCombined');
                        }
                    }
                }
            }
        }

        function getUnits($scope) {
            if ($scope.options.careAboutParentWrapContent && FormUtilService.dcs.isHeightWrapContent($scope.element._parent)) {
                return $scope.options.unitsParentIsWrapContent;
            }
            else return $scope.options.unitsNormal;
        }
    });

})();

