'use strict';

angular.module('acadiamasterApp')
    .controller('UserManagementController', function ($scope, User, ParseLinks, Language, $stateParams, UserSearch, ngDialog, AlertService, ParticipantProfileService) {
        $scope.users = [];
        $scope.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPPORT', 'ROLE_PROVIDER', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT','ROLE_ATA'];
        Language.getAll().then(function (languages) {
            $scope.languages = languages;
        });

        $scope.page = 0;

        $scope.searchData = {
            searchString: '',
            searchedString: '',
            searchResultCount: 'loading ...',
            orderByChoices: ['ID', 'Login', 'Email'],
            orderBySelected: 'Login',
            pageSizeChoices: [20, 50, 100],
            pageSizeSelected: 20,
            ascending: true,
            includeDeactivated: false
        };

        $scope.roleCategory = {
            ADMIN: 'ADMIN',
            PROVIDER: 'PROVIDER',
            USER: 'SUBSCRIBER',
            SUPPORT: 'SUPPORT',
            ALL: 'ALL',
            DEVELOPER: 'DEVELOPER',
            T2SUPPORT: 'T2-SUPPORT'
        };

        $scope.loadAll = function () {
            $scope.page = 0;
            loadUsers();
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            loadUsers();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                $scope.loadAll();
                $scope.clear();
            });
        };

        if ($stateParams.defaultRoleCategory === null) {
            $scope.changedRoleCategory = $scope.roleCategory.ALL;
            $scope.previousRoleCategory = $scope.roleCategory.ALL;
        } else {
            $scope.changedRoleCategory = $stateParams.defaultRoleCategory;
            $scope.previousRoleCategory = $stateParams.defaultRoleCategory;
        }

        $scope.userCategoryFilterChanged = function () {
            if ($scope.previousRoleCategory !== $scope.changedRoleCategory) {
                $scope.page = 0;
                $scope.loadAll();
            }
            $scope.previousRoleCategory = $scope.changedRoleCategory;
        };

        $scope.showUpdate = function (id) {
            User.getById({ id: id }, function (result) {
                $scope.user = result;
                $('#saveUserModal').modal('show');
            });
        };

        $scope.save = function () {
            User.update($scope.user,
                function () {
                    $scope.refresh();
                });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $('#saveUserModal').modal('hide');
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            $scope.editForm.$setPristine();
            $scope.editForm.$setUntouched();
        };

        $scope.unlockUser = function (id) {
            ParticipantProfileService.unlockUser(id)
                .then(function () {
                    AlertService.success('User\'s account has been unlocked');
                }).catch(function (error) {
                    AlertService.error('Error unlocking user\'s account. Please try again.');
                    console.error(error);
                });
        };

        $scope.resetSecurityQuestions = function (id) {
            ParticipantProfileService.resetSecurityQuestions(id)
                .then(function () {
                    AlertService.success('User\'s security questions have been reset');
                }).catch(function (error) {
                    AlertService.error('Error resetting user\'s security questions. Please try again.');
                    console.error(error);
                });
        }

        $scope.changeAuthKey = function (user) {
            ngDialog.openConfirm({
                template: 'admin-templates/account/authenticationKey/authenticationKeyDialog.html',
                controller: 'AuthKeyDialogController',
                data: user
            }).then(function () {
                $scope.loadAll();
            });
        };

        $scope.shouldAllowAuthKeyConfig = function (user) {
            var authorities = user.authorities;
            if (authorities != null) {
                for (var i = 0; i < authorities.length; i++) {
                    if (authorities[i] === 'ROLE_ADMIN' || authorities[i] === 'ROLE_T2_SUPPORT') {
                        return true;
                    }
                }

            }
            return false;
        };

        function loadUsers() {
            UserSearch.query({
                page: $scope.page,
                size: $scope.searchData.pageSizeSelected,
                ascending: $scope.searchData.ascending,
                orderBy: $scope.searchData.orderBySelected,
                includeDeactivated: $scope.searchData.includeDeactivated,
                searchString: $scope.searchData.searchString || null,     // Set null for empty string or undefined value.
                userRole: getRoleSearchString($scope.changedRoleCategory)
            }).then(function (response) {
                $scope.links = ParseLinks.parse(response.headers('link'));
                $scope.users = response.data;
                $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                $scope.searchData.searchedString = $scope.searchData.searchString;
            });
        }

        function getRoleSearchString(role) {
            switch (role) {
                case 'ADMIN':
                    return 'ROLE_ADMIN';
                case 'SUBSCRIBER':
                    return 'ROLE_USER';
                case 'PROVIDER':
                    return 'ROLE_PROVIDER';
                case 'SUPPORT':
                    return 'ROLE_SUPPORT';
                case 'DEVELOPER':
                    return 'ROLE_DEVELOPER';
                case 'T2-SUPPORT':
                    return 'ROLE_T2_SUPPORT';
                case 'ATA':
                    return 'ROLE_ATA';
                case 'ALL':
                default:
                    return null;
            }
        }
    });
