/**
 * Created by Jamie Nola 03/15/2019
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerOrderUpdate', ($timeout, ORDER_TYPES) => {
            let orderUpdateTriggerController = function () {
                let vm = this;
                vm.data = {
                    orderStatuses : [],
                };

                function filterStatuses (statuses, targetNames) {
                    targetNames.forEach(targetName => {
                        vm.element[targetName] = vm.element[targetName].filter(status => {
                            return statuses.indexOf(status) !== -1;
                        });
                    });
                }

                function updateOrderStatusList () {
                    let statuses = ORDER_TYPES[vm.element.orderType].orderStatusList;
                    filterStatuses(statuses, [ 'orderStatusFrom', 'orderStatusTo' ]);
                    vm.data.orderStatuses = statuses;
                }

                vm.updateOrderStatusList = function () {
                    $timeout(updateOrderStatusList);
                };

                vm.$onInit = () => {
                    vm.orderTypes = Object.keys(ORDER_TYPES);
                    updateOrderStatusList();
                };
            };

            return {
                bindToController : true,
                controller       : orderUpdateTriggerController,
                controllerAs     : 'ouc',
                restrict         : 'E',
                scope            : {
                    element : '=',
                },
                templateUrl : 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.orderUpdate.html',
            };
        });
}());
