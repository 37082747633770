
(function () {
    angular.module('acadiamasterApp')
        .directive('programTestSuiteListWithSearch', (CONFIG, ParseLinks, ngDialog, ProgramTestSuiteService, $state, PROGRAM_TEST_SUITE_CONSTANTS) => {
            return {
                link : function ($scope) {
                    $scope.CONFIG = CONFIG;
                    $scope.cs = PROGRAM_TEST_SUITE_CONSTANTS;

                    $scope.fields = [
                        $scope.cs.FIELDS.ID,
                        $scope.cs.FIELDS.NAME,
                        $scope.cs.FIELDS.DESCRIPTION,
                        $scope.cs.FIELDS.PROGRAM_NAME,
                        $scope.cs.FIELDS.NUMBER_OF_TEST_CASES,
                        $scope.cs.FIELDS.AUTO_RUN_CORRECTIVE_ACTION,
                        $scope.cs.FIELDS.HAS_SCHEDULE,
                        $scope.cs.FIELDS.SCHEDULE_ENABLED,
                        $scope.cs.FIELDS.LAST_RUN_ON,
                        $scope.cs.FIELDS.UPDATED_ON,
                        $scope.cs.FIELDS.ACTIONS,
                    ];

                    $scope.searchData = {
                        ascending         : false,
                        orderBy           : 'updatedOn',
                        orderByChoices    : [ 'id', 'name', 'name', 'displayName' ],
                        orderBySelected   : 'updatedOn',
                        pageSizeChoices   : [ 20, 50, 100 ],
                        pageSizeSelected  : 20,
                        searchResultCount : 'loading ...',
                        searchString      : '',
                    };

                    function loadTestSuite () {
                        let promise = ProgramTestSuiteService.getAllTestSuite({
                            ascending    : $scope.searchData.ascending,
                            orderBy      : $scope.searchData.orderBy,
                            page         : $scope.searchData.page,
                            searchString : $scope.searchData.searchString || null,
                            size         : $scope.searchData.pageSizeSelected,
                        });
                        return promise.then(result => {
                            $scope.links = ParseLinks.parse(result.headers('link'));
                            $scope.testSuites = result.data;
                            $scope.searchData.searchResultCount = result.headers('X-Total-Count');
                        });
                    }

                    $scope.loadAll = function (page) {
                        $scope.searchData.page = page || 0;
                        loadTestSuite();
                    };

                    $scope.getIcon = function (status) {
                        let faIcon = '';
                        if (status === true) {
                            faIcon = 'fa fa-check fa-1x text-success';
                        } else if (status === false) {
                            faIcon = 'fa fa-times fa-1x text-danger';
                        }
                        return faIcon;
                    };

                    $scope.loadPage = function (page) {
                        $scope.searchData.page = page;
                        loadTestSuite();
                    };

                    $scope.runTestSuite = function (programTestSuite) {
                        ProgramTestSuiteService.runTestSuite(programTestSuite.id)
                            .then(() => {
                                $state.go('programTests.pstSuite');
                                $scope.loadAll();
                            });
                    };

                    $scope.setOrderBy = function (newOrderBy) {
                        if ($scope.searchData.orderBy === newOrderBy) {
                            $scope.searchData.ascending = !$scope.searchData.ascending;
                        } else {
                            $scope.searchData.ascending = true;
                            $scope.searchData.orderBy = newOrderBy;
                        }
                        $scope.loadAll(0);
                    };

                    $scope.delete = function (testSuite) {
                        ngDialog.openConfirm({
                            controller : 'ProgramTestSuiteDeleteController',
                            data       : testSuite,
                            template   : 'admin-templates/site/programTests/programTestSuite/programTestSuiteDelete.html',
                        }).then(() => {
                            $scope.loadAll();
                        });
                    };

                    $scope.download = function () {
                    // API integration
                    };

                    $scope.loadAll();
                    if ($scope.loadAllCallback) {
                        $scope.loadAllCallback = $scope.loadAll;
                    }
                },
                restrict : 'E',
                scope    : {
                    loadAllCallback : '=loadAll',
                },
                templateUrl : 'admin-templates/site/programTests/programTestSuite/programTestSuiteListWithSearch.html',
            };
        });
}());
