
angular.module('acadiamasterApp')
    .factory('MobileBuildCreationService', ($http, AlertService) => {
        //
        // eslint-disable-next-line max-len
        const JOB_URL = 'api/mobile/build';
        return {
            // trigger android build
            doBuild : function (iosBranchName, androidBranchName, email, program, host, buildIOS, buildAndroid, completion) {
                let config = this.getConfig(iosBranchName, androidBranchName, email, program, host, buildIOS, buildAndroid);
                return $http.get(JOB_URL, { params : config })
                    .then(response => {
                        if (response.data === 'OK') {
                            AlertService.success(`Build: ${response.data}`);
                            completion();
                        } else if (response.data !== undefined) {
                            AlertService.error(`Error building: ${response.data}`);
                            console.error(response.data);
                        }
                    })
                    .catch(err => {
                        AlertService.error(`Error building: ${err}`);
                    });
            },

            getConfig : function (iosBranchName, androidBranchName, email, program, host, buildIOS, buildAndroid) {
                return {
                    android       : buildAndroid,
                    androidBranch : encodeURIComponent(androidBranchName),
                    email         : encodeURIComponent(email),
                    host          : encodeURIComponent(host),
                    ios           : buildIOS,
                    iosBranch     : encodeURIComponent(iosBranchName),
                    program       : encodeURIComponent(program),
                };
            },
        };
    });
