

angular.module('vibrent-external-survey-parser')
    .service('QualtricsParserUtilService', function (VibrentFormStructureConstantService, CommonParserUtilService) {
        const constantService = VibrentFormStructureConstantService;
        const commonParser = CommonParserUtilService;

        function findElementsByType(surveyElements, type) {
            return _.filter(surveyElements, function (s) {
                return s.Element === type;
            });
        }

        function findOneElementByType(surveyElements, type) {
            return _.find(surveyElements, function (s) {
                return s.Element === type;
            });
        }

        function getQuestionType(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion) || !commonParser.isObject(surveyQuestion.Payload)) {
                return 'NONE';
            }
            const questionType = surveyQuestion.Payload.QuestionType;
            if (commonParser.isEmpty(questionType)) {
                return 'NONE';
            }
            return questionType;
        }

        function getQuestionId(surveyQuestion) {
            if (!commonParser.isObject(surveyQuestion)) {
                return null;
            }
            return commonParser.getValueFromObject(surveyQuestion, 'PrimaryAttribute');
        }

        function printSurveyMetadata(surveyQuestions) {
            console.log('-------------------------------');
            if (!surveyQuestions) {
                console.log('No survey questions');
            }

            console.log(`Total questions: ${ surveyQuestions.length}`);
            const questionTypeMap = {};
            _.forEach(surveyQuestions, surveyQuestion => {
                const questionType = getQuestionType(surveyQuestion);

                const selector = surveyQuestion.Payload.Selector;
                const subSelector = surveyQuestion.Payload.SubSelector;
                const obj = questionTypeMap[questionType];
                if (obj) {
                    obj.count += 1;
                    const subOptionCount = obj.subOption[selector];
                    obj.subOption[selector] = subOptionCount ? subOptionCount + 1 : 1;

                    const subSelectorCount = obj.subSelector[subSelector];
                    if (subSelector) {
                        obj.subSelector[subSelector] = subSelectorCount ? subSelectorCount + 1 : 1;
                    }
                } else {
                    const subOption = {};
                    subOption[selector] = 1;

                    const subSelectorMap = {};
                    if (subSelector) {
                        subSelectorMap[subSelector] = 1;
                    }

                    questionTypeMap[questionType] = {
                        count       : 1,
                        subOption   : subOption,
                        subSelector : subSelectorMap,
                    };
                }
            });
            console.log(`Question count per type: \n${ JSON.stringify(questionTypeMap, undefined, 2)}`);
            console.log('-------------------------------');
        }

        /**
         * Method to parse choices to selector options in required order
         * @param choices - choice to be parse
         * @param choiceOrder - order of choices to be in
         * @param spanishChoices - spanish choices
         * @returns [] array
         */
        function parseChoicesInExpectedOrder (choices, spanishChoices, choiceOrder, localIdGenerator) {
            if (choices == null || choices.length <= 0) {
                return [];
            }

            const options = [];
            const sortedChoices = sortChoicesInExpectedOrder(choices, choiceOrder);
            _.forEach(sortedChoices, choice => {
                const option = constantService.initSelectorOptionValue(commonParser.removeHtmlTagsIfRequired(choice.value.Display), choice.key);
                //check if textEntry is exist. if does, add embeddedTextInputLocalId AC-100431
                if (!commonParser.isEmpty(choice.value.TextEntry)) {
                    option.embeddedTextInputLocalId = localIdGenerator.getNextId();
                }
                if (spanishChoices && spanishChoices[choice.key]) {
                    const spanishText = spanishChoices[choice.key].Display;
                    commonParser.addSpanishValueToMap(option.localizationMap, 'text', spanishText);
                    commonParser.addSpanishValueToMap(option.localizationMap, 'contentDescription', spanishText);
                }

                options.push(option);
            });
            return options;
        }

        /**
         * Method to sort choices array in order given on choice order
         * @param choices
         * @param choiceOrder
         * @returns {[]|Array}
         */
        function sortChoicesInExpectedOrder(choices, choiceOrder) {
            if (choices == null || choices.length <= 0) {
                return [];
            }
            const sortedChoices = [];
            _.forEach(choiceOrder, function (orderValue, orderKey) {
                _.forEach(choices, function (choiceValue, choiceKey) {
                    if (orderValue == choiceKey) {
                        sortedChoices.push({key : choiceKey, value : choiceValue});
                    }
                });
            });
            return sortedChoices;
        }

        function addSpanishText(payload, map, fromQualtricsKey, toVibrentKey) {
            if (payload == null || map == null || fromQualtricsKey == null || toVibrentKey == null) {
                return;
            }
            // get text to be added
            const spanishText = commonParser.removeHtmlTagsIfRequired(getSpanishObjectFromQualtricsLanguage(payload, fromQualtricsKey));

            commonParser.addSpanishValueToMap(map, toVibrentKey, spanishText);
        }

        function getSpanishObjectFromQualtricsLanguage(payload, key) {
            if (payload == null || key == null) {
                return;
            }

            // get language object
            const language = commonParser.getValueFromObject(payload, 'Language');
            if (language == null) {
                return;
            }

            // get spanish object
            // if both spanish present, the system will use es (spanish latin america) as default
            let spanishPayload = commonParser.getValueFromObject(language, constantService.qualtricsLanguage.SPANISH_LATIN_AMERICA);
            spanishPayload = spanishPayload !=null? spanishPayload: commonParser.getValueFromObject(language, constantService.qualtricsLanguage.SPANISH);
            if (spanishPayload == null) {
                return;
            }

            return commonParser.getValueFromObject(spanishPayload, key);
        }

        function hasBothSpanishFromQualtricsLanguage(payload){
            if (payload == null) {
                return false;
            }

            // get language object
            const language = commonParser.getValueFromObject(payload, 'Language');
            if (language == null) {
                return false;
            }
            let spanishLatinAmerica = _.has(language,constantService.qualtricsLanguage.SPANISH_LATIN_AMERICA);
            let spanishEU = _.has(language,constantService.qualtricsLanguage.SPANISH);
            return (spanishLatinAmerica && spanishEU);

        }

        return {
            findElementsByType,
            findOneElementByType,
            getQuestionType,
            getQuestionId,
            printSurveyMetadata,
            parseChoicesInExpectedOrder,
            sortChoicesInExpectedOrder,
            addSpanishText,
            getSpanishObjectFromQualtricsLanguage,
            hasBothSpanishFromQualtricsLanguage,
        };
    })

    .directive('fileModel', ['$parse', function ($parse) {
        return {
            restrict : 'A',
            link     : function (scope, element, attrs) {
                const model = $parse(attrs.fileModel);
                const modelSetter = model.assign;
                element.bind('change', function () {
                    scope.$apply(function () {
                        modelSetter(scope, element[0].files[0]);
                    });
                });
            },
        };
    }]);
