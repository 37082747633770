/**
 * various function for form version api
 */
(function () {
    'use strict';
    angular.module('acadiamasterApp').factory('FormVersion', function ($http, localWeb) {
        var baseUrl = 'api/forms/version/';
        return {

            delete: function (formVersionId) {
                return $http.delete(localWeb.createUrl(baseUrl + formVersionId));
            },

            restore: function (formVersionId) {
                return $http({
                    url: (localWeb.createUrl(baseUrl + "restore/" + formVersionId)),
                    method: 'PUT'
                });
            },

            activate : function(formId, formVersionId) {
                return $http({
                    url: localWeb.createUrl('api/formVersion/activate?') + 'formId=' + formId + '&formVersionId=' + formVersionId,
                    method: 'PUT'
                });
            },

            updateVersionComment: function(data) {
                return $http({
                    url: localWeb.createUrl('api/formVersion/updateComment'),
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify(data)
                });
            },

            replacePDF: function(versionIdFrom, versionIdTo) {
                return $http({
                    url: localWeb.createUrl('api/forms/version/updatePdfConfig/' + versionIdFrom + '/' + versionIdTo),
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json'}
                });
            }
        };
    });
})();
