/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigExpression', function (RuleUtilService,ExpressionConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/expression/elementConfig.expression.html',
                scope: {
                    element: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.expConstants = ExpressionConstantsService;
                }
            };
        });
})();

