(function () {
    'use strict';

    /**
     * directive for selecting program distribution
     * todo: remove this later when all configuration has been updated to remove this precondition
     */
    angular.module('acadiamasterApp').directive('programDistributionSelection', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/programDistributionSelection.html',
        };
    });
})();
