(
    function () {
        'use strict';

        angular.module('acadiamasterApp')
            .factory('Auth', function Auth($rootScope, $state, $q, Principal, AuthServerProvider,
                Account, Register, Activate, Password, PasswordResetInit, PasswordResetFinish,
                localWeb, $http) {
                return {
                    login: function (credentials, callback) {
                        var cb = callback || angular.noop;
                        var deferred = $q.defer();

                        AuthServerProvider.login(credentials).then(function (data) {
                            // retrieve the logged account information
                            Principal.identity(true).then(function (account) {
                                deferred.resolve(data);
                                Principal.authenticate(account);

                            });
                            return cb();
                        }).catch(function (err) {
                            this.logout();
                            deferred.reject(err);
                            return cb(err);
                        }.bind(this));

                        return deferred.promise;
                    },

                    logout: function (isWebUser) {
                        AuthServerProvider.logout(isWebUser);
                        Principal.authenticate(null);
                    },

                    authorize: function (force) {
                        return Principal.identity(force)
                            .then(function () {
                                var isAuthenticated = Principal.isAuthenticated();

                                // an authenticated user can't access to login and register pages
                                if (isAuthenticated && ($rootScope.toState.name === localWeb.getLoginState() || $rootScope.toState.name === 'register')) {
                                    $state.go(localWeb.getDefaultState());
                                    return;
                                }

                                if (!$rootScope.toState) {
                                    return;
                                }

                                if ($rootScope.toState.redirectTo) {
                                    $state.go($rootScope.toState.redirectTo, $rootScope.toState.toStateParams, { location: 'replace' });
                                    return;
                                }

                                if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 &&
                                    !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                                    if (isAuthenticated) {
                                        // user is signed in but not authorized for desired state
                                        Principal.unAuthenticate();
                                        $state.go(localWeb.getDenyState());
                                    }
                                    else {
                                        // user is not authenticated. stow the state they wanted before you
                                        // send them to the signin state, so you can return them when you're done
                                        $rootScope.returnToState = $rootScope.toState;
                                        $rootScope.returnToStateParams = $rootScope.toStateParams;

                                        // now, send them to the signin state so they can log in
                                        $state.go(localWeb.getLoginState());
                                    }
                                }
                            });
                    },
                    createAccount: function (account, callback) {
                        var cb = callback || angular.noop;

                        return Register.save(account,
                            function () {
                                return cb(account);
                            },
                            function (err) {
                                this.logout();
                                return cb(err);
                            }.bind(this)).$promise;
                    },

                    registerUser: function (data, file) {
                        return Register.registerUser(data, file);
                    },

                    updateUser: function (data) {
                        return Register.updateUser(data);
                    },

                    updateAccount: function (account, callback) {
                        var cb = callback || angular.noop;

                        return Account.save(account,
                            function () {
                                return cb(account);
                            },
                            function (err) {
                                return cb(err);
                            }.bind(this)).$promise;
                    },

                    activateAccount: function (key, callback) {
                        var cb = callback || angular.noop;

                        return Activate.get(key,
                            function (response) {
                                return cb(response);
                            },
                            function (err) {
                                return cb(err);
                            }.bind(this)).$promise;
                    },

                    changePassword: function (data, callback) {
                        var cb = callback || angular.noop;

                        return Password.save(data, function () {
                            return cb();
                        }, function (err) {
                            return cb(err);
                        }).$promise;
                    },

                    resetPasswordInit: function (mail, callback) {
                        var cb = callback || angular.noop;

                        return PasswordResetInit.save({ userName: mail }, function () {
                            return cb();
                        }, function (err) {
                            return cb(err);
                        }).$promise;
                    },

                    resetPasswordFinish: function (keyAndPassword, callback) {
                        var cb = callback || angular.noop;

                        return PasswordResetFinish.save(keyAndPassword, function () {
                            return cb();
                        }, function (err) {
                            return cb(err);
                        }).$promise;
                    },

                    /** Update Admin / developer role basic profile. */
                    updateAdmin: function (userDto) {
                        return $http.post('/api/account/updateAdmin', userDto);
                    }

                };
            });
    }()
);
