/**
 * Created by pravin.gayal on 06-07-2016.
 */
(function () {
    angular.module('vibrent-cron-generator', ['checklist-model']);
    angular.module('vibrent-cron-generator')
        .directive('cronGenerator',
            function ($timeout, CronService, CronConstantsService) {
                function init($scope) {

                    // Initialize config
                    $scope.config = {
                        showTitle: $scope.config != null && $scope.config.showTitle != null ? $scope.config.showTitle : false,
                        title: $scope.config != null && $scope.config.title != null ? $scope.config.title : "Build Cron Expression"
                    };

                    // Init recurrence type to default value
                    if ($scope.recurrenceType == null) {
                        $scope.recurrenceType = CronConstantsService.recurrenceTypes.DAILY.name;
                    }

                    // Initialize cron object
                    $scope.cronObject = {
                        recurrenceType: CronConstantsService.recurrenceTypes.DAILY,
                        minutes: 1,
                        hours: 1,
                        days: 1,
                        months: 1,
                        isEveryWeekDay: false,
                        isEveryHour: true,
                        weekDays: [
                            CronConstantsService.weekDays.MON.name
                        ],
                        startTime: {
                            hourValue: 12,
                            minValue: 0
                        },
                        allowCronEdit: false
                    };

                    // Initialize UI element by parsing cron expression and populate cron object
                    if ($scope.cronExpression != null && $scope.recurrenceType != null) {
                        CronService.parseCronExpression($scope.cronExpression, $scope.recurrenceType, $scope.cronObject);
                    }

                    $scope.isReady = true;

                    // set value on load if liveUpdate is on
                    $scope.onValueChanged();
                }

                return {
                    restrict: 'E',
                    templateUrl: 'cron-generator/cronGenerator.html',
                    scope: {
                        cronExpression: '=',
                        recurrenceType: '=',
                        config: '=?',
                        hideExpression: '=?',
                        liveUpdate: '=?'
                    },
                    link: function ($scope) {
                        $scope.constant = CronConstantsService;

                        $scope.isReady = false;

                        $scope.generateCron = function () {
                            $scope.cronExpression = CronService.generateCron($scope.cronObject);
                            $scope.recurrenceType = $scope.cronObject.recurrenceType.name;
                        };

                        $scope.onValueChanged = function () {
                            if ($scope.liveUpdate) {
                                $timeout($scope.generateCron);
                            }
                        };

                        $scope.$watch('cronObject.recurrenceType', function (newValue, oldValue) {
                            if (!_.isEqual(newValue, oldValue)) {
                                if ($scope.liveUpdate) {
                                    $scope.generateCron();
                                } else {
                                    $scope.cronExpression = null;
                                }
                            }
                        });

                        // Init directive
                        init($scope);
                    }
                };
            })

        .factory('CronService', ['CronConstantsService', function (CronConstantsService) {
            /**
             * Method to generate cron expression
             * @param cronObject
             * @returns {string}
             */
            function generateCron(cronObject) {
                if (cronObject == null) {
                    return null;
                }

                // If any of the number fields becomes invalid, set its value to 1.
                cronObject.days = cronObject.days === undefined ? CronConstantsService.cronConstants.ONE : cronObject.days;
                cronObject.hours = cronObject.hours === undefined ? CronConstantsService.cronConstants.ONE : cronObject.hours;
                cronObject.minutes = cronObject.minutes === undefined ? CronConstantsService.cronConstants.ONE : cronObject.minutes;
                cronObject.months = cronObject.months === undefined ? CronConstantsService.cronConstants.ONE : cronObject.months;

                // cronArray  = [Sec, Min, Hr, DayOfMonth, Month, DayOfWeek, Yr]
                var cronArray = ['0', '*', '*', '*', '*', '*', '*'];

                switch (cronObject.recurrenceType) {
                    case CronConstantsService.recurrenceTypes.MINUTES:
                        setMinuteRecurrence(cronObject, cronArray);
                        break;
                    case CronConstantsService.recurrenceTypes.HOURLY:
                        setHourlyRecurrence(cronObject, cronArray);
                        break;
                    case CronConstantsService.recurrenceTypes.DAILY:
                        setDailyRecurrence(cronObject, cronArray);
                        break;
                    case CronConstantsService.recurrenceTypes.WEEKLY:
                        setWeeklyRecurrence(cronObject, cronArray);
                        break;
                    case CronConstantsService.recurrenceTypes.MONTHLY:
                        setMonthlyRecurrence(cronObject, cronArray);
                        break;
                    case CronConstantsService.recurrenceTypes.YEARLY:
                        setYearlyRecurrence(cronObject, cronArray);
                        break;
                    default:
                        // no default
                        break;
                }
                return cronArray.join(' ');
            }

            /**
             * Method to set cron values for minute recurrence
             * @param cronObject
             * @param cronArray
             */
            function setMinuteRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null || cronObject.minutes == null) {
                    return;
                }

                // Set Minute value
                var minValue = CronConstantsService.cronConstants.ZERO + CronConstantsService.cronConstants.INCREMENT + cronObject.minutes;
                setValue(cronArray, CronConstantsService.cronElement.MINUTES, minValue);

                // Set Day of week
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);

                // Set day of month
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, CronConstantsService.cronConstants.EVERY_ONE_WITH_INCREMENT);
            }

            /**
             * Method to set cron values for hourly recurrence
             * @param cronObject
             * @param cronArray
             */
            function setHourlyRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null) {
                    return;
                }

                // Set Day of week
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);

                // Set day of month
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, CronConstantsService.cronConstants.EVERY_ONE_WITH_INCREMENT);


                if (cronObject.isEveryHour) {
                    if (cronObject.hours == null) {
                        return;
                    }

                    // Set Minute value
                    setValue(cronArray, CronConstantsService.cronElement.MINUTES, CronConstantsService.cronConstants.ZERO);

                    // Set hr value
                    var hrValue = CronConstantsService.cronConstants.ZERO + CronConstantsService.cronConstants.INCREMENT + cronObject.hours;
                    setValue(cronArray, CronConstantsService.cronElement.HOURS, hrValue);
                } else {
                    // With start at
                    setStartTimeToArray(cronArray, cronObject.startTime);
                }
            }

            /**
             * Method to set cron values for daily recurrence
             * @param cronObject
             * @param cronArray
             */
            function setDailyRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null) {
                    return;
                }

                // Set start time values
                setStartTimeToArray(cronArray, cronObject.startTime);

                if (cronObject.isEveryWeekDay) {
                    // Set day of month value
                    setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);

                    // Set Day of week
                    setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, CronConstantsService.cronConstants.WEEKDAY_RANGE);
                } else {
                    if (cronObject.days == null) {
                        return;
                    }

                    // Set day of month value
                    var dayValue = CronConstantsService.cronConstants.ONE +
                        CronConstantsService.cronConstants.INCREMENT +
                        cronObject.days;
                    setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, dayValue);

                    // Set Day of week
                    setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);
                }
            }

            /**
             * Method to get a string representation of the selected days of the week
             * @param days An array of days. i.e. ["MON", "TUES", "SUN"]
             */
            function getWeekDayString(days) {
                // handle cases with zero, one, or all days selected
                switch (days.length) {
                    case 0:
                        return CronConstantsService.cronConstants.NO_SPECIFIC_VALUE;
                    case 1:
                        return days[0];
                    case 7:
                        return CronConstantsService.cronConstants.ALL_VALUE;
                    default:
                        // no default
                        break;
                };

                // for all other cases, iterate through the days of the week and build a string from the days array
                var weekDayString = [];
                var first, last, firstIndex, lastIndex;
                Object.keys(CronConstantsService.weekDays).forEach(function (key, index) {
                    // if this day exists in the day array, save its value and the index
                    if (days.indexOf(key) > -1) {
                        if (!first) {
                            first = key;
                            firstIndex = index;
                        } else {
                            last = key;
                            lastIndex = index;
                        }
                    }
                    // Either we found a break or ran out of days, so create a string using first and last
                    if (days.indexOf(key) === -1 || index === 6) {
                        if (first && last) {
                            // only use a hyphen if first and last are next to each other
                            if (lastIndex - firstIndex === 1) {
                                weekDayString.push(first);
                                weekDayString.push(last);
                            } else {
                                weekDayString.push(first + CronConstantsService.cronConstants.RANGE + last);
                            }
                        } else if (first) {
                            weekDayString.push(first);
                        }
                        // reset stored values
                        first = null;
                        last = null;
                    }
                });
                return weekDayString.join(',');
            }

            /**
             * Method to split ranges such as "MON-THU" into separate days. i. e. [MON, TUES, WED, THU]
             * @param _days
             */
            function splitWeekDayString(_days) {
                var days = [];
                _days.split(',').forEach(function(day) {
                    if(day.indexOf(CronConstantsService.cronConstants.RANGE) > -1) {
                        var start = day.substr(0, 3);
                        var end = day.substr(4, 3);
                        var keys = Object.keys(CronConstantsService.weekDays);
                        for(var i=keys.indexOf(start); i<=keys.indexOf(end); i++) {
                            days.push(keys[i]);
                        }
                    } else {
                        days.push(day);
                    }
                });
                return days;
            }

            /**
             * Method to set cron values for weekly recurrence
             * @param cronObject
             * @param cronArray
             */
            function setWeeklyRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null || cronObject.weekDays.length < 1) {
                    return;
                }

                // Get string representation of selected days
                var weekDayString = getWeekDayString(cronObject.weekDays);

                // Set Day of week
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, weekDayString);

                // Set start time values
                setStartTimeToArray(cronArray, cronObject.startTime);

                // Set day of month
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);
            }

            /**
             * Method to set cron values for monthly recurrence
             * @param cronObject
             * @param cronArray
             */
            function setMonthlyRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null) {
                    return;
                }

                // Set start time values
                setStartTimeToArray(cronArray, cronObject.startTime);

                // Set day of month value
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH, cronObject.days);

                // Set month value
                var monthValue = CronConstantsService.cronConstants.ONE + CronConstantsService.cronConstants.INCREMENT + cronObject.months;
                setValue(cronArray, CronConstantsService.cronElement.MONTHS, monthValue);

                // Set Day of week
                setValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK, CronConstantsService.cronConstants.NO_SPECIFIC_VALUE);
            }

            /**
             * Method to set cron values for yearly recurrence
             * @param cronObject
             * @param cronArray
             */
            function setYearlyRecurrence(cronObject, cronArray) {
                if (cronArray == null || cronObject == null) {
                    return;
                }
                // TODO Implement this later
            }

            /**
             * Method to set value in cron array for specified element
             * @param cronArray
             * @param element
             * @param value
             */
            function setValue(cronArray, element, value) {
                if (cronArray == null || element == null || value == null) {
                    return;
                }
                cronArray[element.index] = value;
            }

            /**
             * Method to set hours and minutes values
             * @param cronArray
             * @param startTimeObject
             */
            function setStartTimeToArray(cronArray, startTimeObject) {
                // Set min value
                setValue(cronArray, CronConstantsService.cronElement.MINUTES, startTimeObject.minValue);

                // Set hour value
                setValue(cronArray, CronConstantsService.cronElement.HOURS, startTimeObject.hourValue);

            }

            /**
             * Method to get element value from cron array
             * @param cronArray
             * @param element
             * @returns {*}
             */
            function getValue(cronArray, element) {
                if (cronArray == null || element == null) {
                    return null;
                }
                return cronArray[element.index];
            }

            /**
             *
             * @param cronArray
             * @param startTimeObject
             */
            function setStartTimeFromArray(cronObject, cronArray) {
                cronObject.startTime.minValue = parseInt(getValue(cronArray, CronConstantsService.cronElement.MINUTES), 10);
                cronObject.startTime.hourValue = parseInt(getValue(cronArray, CronConstantsService.cronElement.HOURS), 10);
            }

            function getIncrementalValue(value) {
                if (value.includes(CronConstantsService.cronConstants.INCREMENT)) {
                    return parseInt(value.substring(value.indexOf(CronConstantsService.cronConstants.INCREMENT) + 1), 10);
                }
                return null;
            }


            /**
             * Initialize UI elements
             * @param $scope
             */
            function parseCronExpression(cronExpression, recurrenceType, cronObject) {
                if (cronExpression == null || recurrenceType == null) {
                    return;
                }

                // Set recurrence type
                cronObject.recurrenceType = CronConstantsService.getObjectByName(CronConstantsService.recurrenceTypes, recurrenceType);

                var cronArray = cronExpression.split(' ');

                switch (cronObject.recurrenceType) {
                    case CronConstantsService.recurrenceTypes.MINUTES:
                        var minValue = getValue(cronArray, CronConstantsService.cronElement.MINUTES);
                        cronObject.minutes = getIncrementalValue(minValue);
                        break;
                    case CronConstantsService.recurrenceTypes.HOURLY:
                        var hour = getValue(cronArray, CronConstantsService.cronElement.HOURS);
                        if (hour.includes(CronConstantsService.cronConstants.INCREMENT)) {
                            // Every x hours
                            cronObject.hours = getIncrementalValue(hour);
                            cronObject.isEveryHour = true;
                        } else {
                            // Start at
                            setStartTimeFromArray(cronObject, cronArray);
                            cronObject.isEveryHour = false;
                        }
                        break;
                    case CronConstantsService.recurrenceTypes.DAILY:
                        setStartTimeFromArray(cronObject, cronArray);
                        var dayOfMonth = getValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH);
                        if (dayOfMonth.includes(CronConstantsService.cronConstants.INCREMENT)) {
                            // Every x days
                            cronObject.days = getIncrementalValue(dayOfMonth);
                            cronObject.isEveryWeekDay = false;
                        } else {
                            // Every weekday
                            cronObject.isEveryWeekDay = true;
                        }
                        break;
                    case CronConstantsService.recurrenceTypes.WEEKLY:
                        setStartTimeFromArray(cronObject, cronArray);
                        var daysOfWeek = getValue(cronArray, CronConstantsService.cronElement.DAY_OF_WEEK);
                        cronObject.weekDays = splitWeekDayString(daysOfWeek);
                        break;
                    case CronConstantsService.recurrenceTypes.MONTHLY:
                        setStartTimeFromArray(cronObject, cronArray);
                        cronObject.days = parseInt(getValue(cronArray, CronConstantsService.cronElement.DAY_OF_MONTH), 10);
                        var monthValue = getValue(cronArray, CronConstantsService.cronElement.MONTHS);
                        cronObject.months = getIncrementalValue(monthValue);
                        break;
                    default:
                        // no default
                        break;
                }
            }

            return {
                generateCron: generateCron,
                parseCronExpression: parseCronExpression
            }
        }])

        .factory('CronConstantsService', function () {
            return {
                getObjectByName: function (objectType, name) {
                    if (name == null || objectType == null) {
                        return null;
                    }

                    for (var prop in objectType) {
                        // your code
                        var object = objectType[prop];
                        if (object.name === name) {
                            return object;
                        }
                    }
                },

                cronConstants: {
                    ALL_VALUE: '*',
                    NO_SPECIFIC_VALUE: '?',
                    RANGE: '-',
                    INCREMENT: '/',
                    EVERY_ONE_WITH_INCREMENT: '1/1',
                    WEEKDAY: 'W',
                    ZERO: 0,
                    ONE: 1,
                    WEEKDAY_RANGE: 'MON-FRI'
                },
                recurrenceTypes: {
                    MINUTES: { name: "MINUTES", text: "Minutes", index: 1 },
                    HOURLY: { name: "HOURLY", text: "Hourly", index: 2 },
                    DAILY: { name: "DAILY", text: "Daily", index: 3 },
                    WEEKLY: { name: "WEEKLY", text: "Weekly", index: 4 },
                    MONTHLY: { name: "MONTHLY", text: "Monthly", index: 5 },
                    YEARLY: { name: "YEARLY", text: "Yearly", index: 6 }
                },
                cronElement: {
                    SECONDS: { index: 0, value: 0 },
                    MINUTES: { index: 1, value: 0 },
                    HOURS: { index: 2, value: null },
                    DAY_OF_MONTH: { index: 3, value: null },
                    MONTHS: { index: 4, value: null },
                    DAY_OF_WEEK: { index: 5, value: null },
                    YEARS: { index: 6, value: null }
                },
                weekDays: {
                    MON: { name: 'MON', text: 'Monday' },
                    TUE: { name: 'TUE', text: 'Tuesday' },
                    WED: { name: 'WED', text: 'Wednesday' },
                    THU: { name: 'THU', text: 'Thursday' },
                    FRI: { name: 'FRI', text: 'Friday' },
                    SAT: { name: 'SAT', text: 'Saturday' },
                    SUN: { name: 'SUN', text: 'Sunday' }
                }
            }
        })

        /**
         * Filter for creating ranges of numbers, complete with padding support.
         */
        .filter('range', function () {
            return function (input, _start, _end, padding) {
                function pad(_n, width, _z) {
                    var z = _z || '0';
                    var n = _n.toString();
                    return n.length >= width ? n : z.toString().repeat(width - n.length) + n;
                }

                var start = parseInt(_start, 10);
                var end = parseInt(_end, 10);
                var direction = (start <= end) ? 1 : -1;
                while (start != end) {
                    input.push({
                        value: start,
                        label: pad(start, padding)
                    });
                    start += direction;
                }
                return input;
            };
        });

})();
