(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrRuleSearch', function (ParseLinks, ngDialog, BusinessRule) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/rule/ruleSearch.html',
                scope: {
                    options: '=',
                    isRequired: '=',
                    programId: '<',
                },
                link: function (scope) {
                    scope.search = {
                        rules: [],
                        searchComplete: false,
                        searchString: '',
                        exactMatch: false,
                        page: 0,
                        pageSize: getPageSize(scope.options),
                        searchLabel: getSearchLabel(scope.options),
                        changeLabel: getChangeLabel(scope.options),
                        links: null,
                        programId: scope.programId,
                    };

                    scope.getSearchOrChangeTitle = function() {
                        return (scope.options.getRule()==null) ?
                            getSearchLabel(scope.options) :
                            getChangeLabel(scope.options);
                    };

                    scope.searchRule = function () {
                        var _scope = scope;

                        ngDialog.openConfirm({
                            template: 'ruleSearchDialog',
                            controller: ['$scope', function ($scope) {
                                $scope.search = _scope.search;
                                $scope.data = _scope.data;
                                $scope.controls = _scope.controls;
                            }],
                            className: 'ngdialog-theme-default large-dialog-width'
                        });
                    };

                    scope.hasTitle = function () {
                        return (scope.options && scope.options.title && scope.options.title.length > 0);
                    };

                    scope.controls = {
                        parent: scope,
                        loadPage: function (page) {
                            return loadPage(this.parent, page);
                        },
                        searchRules: function () {
                            return searchRules(this.parent);
                        },
                        selectRule: function (rule) {
                            return selectRule(this.parent, rule);
                        }
                    };

                }
            };

            /*************************************************************************************
             * private functions
             *************************************************************************************/

            function selectRule(scope, rule) {
                scope.options.setRule(rule);
                scope.search.searchComplete = false;
                scope.search.searchString = '';
                scope.search.rules = [];
                scope.search.page = 0;
                scope.search.links = null;
                ngDialog.close(rule);
            }

            function loadPage(scope, page) {
                scope.search.page = page;
                searchRules(scope);
            }

            function searchRules(scope) {
                scope.search.searchComplete = false;

                var searchFilter = {
                    searchString: scope.search.searchString,
                    page: scope.search.page,
                    size: scope.search.pageSize,
                    sort: 'id,DESC',
                    programId: scope.search.programId,
                };

                BusinessRule.query(searchFilter, function (result, headers) {
                    scope.search.rules = [];
                    scope.search.links = ParseLinks.parse(headers('link'));
                    var data = result;
                    if (result) {
                        for (var i = 0; i < data.length; i++) {
                            scope.search.rules[i] = data[i];
                        }
                    }
                    scope.search.searchComplete = true;
                });

            }

            function getPageSize(options) {
                if (options == null || options.pageSize == null || !_.isNumber(options.pageSize)) {
                    return 10;
                } else {
                    return options.pageSize;
                }
            }

            function getSearchLabel(options) {
                if (options == null || options.searchLabel == null || options.searchLabel.length == 0) {
                    return 'Search';
                } else {
                    return options.searchLabel;
                }
            }

            function getChangeLabel(options) {
                if (options == null || options.changeLabel == null || options.changeLabel.length == 0) {
                    return 'Change';
                } else {
                    return options.changeLabel;
                }
            }
        });

})();
