/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular
        .module('acadiamasterApp')
        /**
         * directive for config a slider input
         */
        .directive('formConfigSliderInput', function() {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/sliderInputCommon.html',
                scope: {
                    title: '@',
                    sliderOptions: '=',
                    inputModel: '=',
                    changeModel: '='
                },
                link: function ($scope) {
                    $scope.hasChangeModel = $scope.changeModel !== undefined;

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        inputModel: $scope.inputModel,
                        changeModel: $scope.changeModel,
                        makingChange: $scope.hasChangeModel && $scope.changeModel != null
                    };

                    $scope.$watch("data.inputModel", function (newValue) {
                        $scope.inputModel = newValue;
                    });

                    $scope.$watch("data.changeModel", function (newValue) {
                        $scope.changeModel = newValue;
                    });

                    $scope.resetChange = function () {
                        // $scope.changeModel = null;
                        $scope.data.makingChange = false;
                        $scope.data.changeModel = null;
                    };

                    $scope.startChange = function () {
                        $scope.data.makingChange = true;
                        $scope.data.changeModel = $scope.inputModel;
                        $scope.changeModel = $scope.inputModel;
                    };
                }
            };
        });
})();

