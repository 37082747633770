(function () {


    angular.module('acadiamasterApp')

        /**
         * service for action model
         */
        .factory(
            'RuleActionModelService',
            function (
                RuleConstantsService, RuleUtilService, ModelServiceConditionExpression,
                FormUtilService, RuleActionBaseModelService, ModelServiceBase, RuleActionConfigModelService,
                DismissBannerActionModel, FormConstants
            ) {
                var ActionModelBase = RuleActionBaseModelService.ActionModelBase;

                /***************************************************************
                 * Getting action config details
                 ***************************************************************/


                /***************************************************************
                 * Form Action model
                 ***************************************************************/
                FormActionModel.inheritsFrom(ActionModelBase);
                /**
                 * @constructor
                 */
                function FormActionModel(parent) {
                    ActionModelBase.call(this, parent);
                    this.type = RuleConstantsService.actionType.FORM;

                    // Initialize default display type
                    this.displayType = RuleConstantsService.formDisplayTypes.SHOW_FORM_IN_WALL;

                    this.form = null;
                }

                FormActionModel.prototype.toDto = function (files) {
                    var dto = ActionModelBase.prototype.toDto.call(this, files);
                    dto.actionForm = {
                        actionId    : this.id,
                        formId      : this.form != null ? this.form.id : null,
                        displayType : this.displayType.name,
                    };
                    return dto;
                };

                FormActionModel.prototype.fromDto = function (dto) {
                    ActionModelBase.prototype.fromDto.call(this, dto);
                    this.displayType = RuleConstantsService.getObjectByName(
                        RuleConstantsService.formDisplayTypes,
                        dto.actionForm.displayType
                    );
                    if (dto.actionForm != null && dto.actionForm.formId != null) {
                        this.form = RuleUtilService.getFormById(dto.actionForm.formId);
                    }
                };

                FormActionModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                    var message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                    message += ` | ${ this.displayType.text}`;

                    if (this.form) {
                        message += ` | ${ this.form.name }(${ this.form.id })`;
                    }
                    else {
                        message += ' | **** NO FORM SELECTED YET!! ****';
                    }
                    return message;
                };

                FormActionModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.form == null) {
                        this.setErrorMessage('Form is not selected');
                    }
                };

                /***************************************************************
                 * User profile value action model
                 ***************************************************************/
                UserProfileValueActionModel.inheritsFrom(ActionModelBase);
                /**
                 * @constructor
                 */
                function UserProfileValueActionModel(parent) {
                    ActionModelBase.call(this, parent);
                    this.type = RuleConstantsService.actionType.PROFILE_UPDATE;

                    // list of properties that are saved inside the inner object
                    this.expression = '';
                    this.expressionTree = null;
                    this.property = null;
                    this.unit = null;
                    this.valueAsNumber = null;
                    this.valueAsString = null;
                    this.valueAsBoolean = null;

                    // this is a transient property that doesn't get saved to server right now, why not??
                    this.profileUpdateType = FormConstants.setFieldsValueType.VALUE;
                }

                UserProfileValueActionModel.prototype.toDto = function (files) {
                    var dto = ActionModelBase.prototype.toDto.call(this, files);
                    if(this.property && this.property.shortDescription){
                        delete this.property.shortDescription;
                    }
                    dto.actionUserProfileValue = {
                        actionId       : this.id,
                        property       : this.property,
                        unit           : this.unit,
                        valueAsNumber  : null,
                        valueAsString  : null,
                        valueAsBoolean : null,
                        expression     : null,
                        expressionTree : null,
                    };

                    if (this.profileUpdateType === FormConstants.setFieldsValueType.VALUE) {
                        dto.actionUserProfileValue.valueAsNumber = this.valueAsNumber;
                        dto.actionUserProfileValue.valueAsString = this.valueAsString;
                        dto.actionUserProfileValue.valueAsBoolean = this.valueAsBoolean;

                    } else if (this.profileUpdateType === FormConstants.setFieldsValueType.EXPRESSION) {
                        if (this.expression != null && this.expression.length > 0) {
                            dto.actionUserProfileValue.expression = this.expression;
                            dto.actionUserProfileValue.expressionTree = this.expressionTree.toDto();
                        } else {
                            dto.actionUserProfileValue.expressionTree = null;
                            dto.actionUserProfileValue.expression = null;
                        }
                    }

                    return dto;
                };

                UserProfileValueActionModel.prototype.fromDto = function (dto) {
                    ActionModelBase.prototype.fromDto.call(this, dto);
                    if (dto.actionUserProfileValue != null) {
                        this.property = dto.actionUserProfileValue.property;
                        this.valueAsNumber = dto.actionUserProfileValue.valueAsNumber;
                        this.valueAsString = dto.actionUserProfileValue.valueAsString;
                        this.valueAsBoolean = dto.actionUserProfileValue.valueAsBoolean;
                        this.unit = dto.actionUserProfileValue.unit;
                        this.expression = dto.actionUserProfileValue.expression;
                        if (dto.actionUserProfileValue.expressionTree != null) {
                            this.expressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                            this.expressionTree.fromDto(dto.actionUserProfileValue.expressionTree);
                        }

                        if (dto.actionUserProfileValue.expression != null) {
                            this.profileUpdateType = FormConstants.setFieldsValueType.EXPRESSION;
                        } else {
                            this.profileUpdateType = FormConstants.setFieldsValueType.VALUE;
                        }
                    }
                };

                UserProfileValueActionModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                    var message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                    message += ' | property: (';

                    if (this.property == null) {
                        message += '*** NO PROPERTY SELECTED!!! ***';
                    }
                    else {
                        message += `${this.property.id },${ this.property.name}`;
                    }

                    message += ')';

                    // get value string
                    var valueString = null;

                    if (this.profileUpdateType === FormConstants.setFieldsValueType.EXPRESSION) {
                        valueString = '***EXPRESSION***';
                    }
                    else {
                        if (this.valueAsNumber != null) {
                            valueString = this.valueAsNumber;
                        }
                        else if (this.valueAsBoolean != null) {
                            valueString = this.valueAsBoolean;
                        }
                        else if (this.valueAsString != null) {
                            valueString = this.valueAsString;
                        }
                    }

                    if (valueString != null) {
                        message += ` -> ${ valueString}`;
                    }
                    return message;
                };

                UserProfileValueActionModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.property == null) {
                        this.setErrorMessage('Property is not selected');
                        return;
                    }
                    if (this.profileUpdateType === FormConstants.setFieldsValueType.EXPRESSION) {
                        if (this.expression == null || this.expression.length === 0) {
                            this.setErrorMessage('Expression is not specified');
                        }
                    } else {
                        if (this.property.valueType === FormConstants.propertyValueType.NUMBER ||
                            this.property.valueType === FormConstants.propertyValueType.DATETIME) {
                            if (this.valueAsNumber == null) {
                                this.setErrorMessage('Number value to be set is not selected');
                            }
                        } else if (this.property.valueType === FormConstants.propertyValueType.STRING) {
                            if (this.valueAsString == null || this.valueAsString.length === 0) {
                                this.setErrorMessage('String value to be set is not selected');
                            }
                        } else if (this.property.valueType === FormConstants.propertyValueType.BOOLEAN) {
                            if (this.valueAsBoolean == null) {
                                this.setErrorMessage('Boolean value to be set is not selected');
                            }
                        }
                    }
                    if (this.property.valueType === FormConstants.propertyValueType.NUMBER && this.property.units.length > 0) {
                        if (this.unit == null) {
                            this.setErrorMessage('Unit is not selected');
                        }
                    }
                };


                /**
                 * Method to create action model for give action type
                 * @param dto
                 * @param parent
                 * @returns {*}
                 */
                function createActionModelFromDto(dto, parent) {
                    var action = null;
                    if (dto != null) {
                        var type = dto.actionType;
                        if (type === RuleConstantsService.actionType.FORM.name) {
                            action = new FormActionModel(parent);
                        } else if (type === RuleConstantsService.actionType.EMAIL_OR_SMS_NOTIFICATION.name) {
                            action = new RuleActionConfigModelService.ActionConfigEmailOrSMSNotificationModel(parent);
                        } else if (type === RuleConstantsService.actionType.PUSH_NOTIFICATION.name) {
                            action = new RuleActionBaseModelService.ActionConfigBaseModel(parent);
                        } else if (type === RuleConstantsService.actionType.PROFILE_UPDATE.name) {
                            action = new UserProfileValueActionModel(parent);
                        } else if (type === RuleConstantsService.actionType.PERSONALIZED_FORM.name) {
                            action = new RuleActionConfigModelService.ActionConfigPersonalizedFormModel(parent);
                        } else if (type === RuleConstantsService.actionType.USER_ACCOUNT_UPDATE.name) {
                            action = new RuleActionConfigModelService.ActionConfigUserAccountUpdateListModel(parent);
                        } else if (type === RuleConstantsService.actionType.TRIGGER_RULE.name) {
                            action = new RuleActionConfigModelService.ActionConfigTriggerRuleModel(parent);
                        } else if (type === RuleConstantsService.actionType.TRACK_CHANGE.name) {
                            action = new RuleActionConfigModelService.ActionConfigTrackChangeModel(parent);
                        } else if (type === RuleConstantsService.actionType.LEVEL_CHANGE.name) {
                            action = new RuleActionConfigModelService.ActionConfigLevelChangeModel(parent);
                        } else if (type === RuleConstantsService.actionType.IN_APP_NOTIFICATION.name) {
                            action = new RuleActionConfigModelService.ActionConfigInAppNotificationModel(parent);
                        } else if (type === RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION.name) {
                            action = new RuleActionConfigModelService.ActionConfigMobileAppPushNotificationModel(parent);
                        } else if (type === RuleConstantsService.actionType.BANNER_NOTIFICATION.name) {
                            action = new RuleActionConfigModelService.ActionConfigBannerNotificationModel(parent);
                        } else if (type === RuleConstantsService.actionType.DISMISS_BANNER.name) {
                            action = new DismissBannerActionModel(parent);
                        } else if (type === RuleConstantsService.actionType.MODAL_NOTIFICATION.name) {
                            action = new RuleActionConfigModelService.ActionConfigModalNotificationModel(parent);
                        } else if (type === RuleConstantsService.actionType.WITHDRAW.name) {
                            action = new RuleActionConfigModelService.ActionConfigWithdrawModel(parent);
                        } else if (type === RuleConstantsService.actionType.CREATE_ORDER.name) {
                            action = new RuleActionConfigModelService.ActionConfigCreateOrderModel(parent);
                        } else if (type === RuleConstantsService.actionType.CANCEL_ORDER.name) {
                            action = new RuleActionConfigModelService.ActionConfigCancelOrderModel(parent);
                        } else if (type === RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER.name) {
                            action = new RuleActionConfigModelService.ActionConfigEvaluatePersonalizedContainerModel(parent);
                        } else if (type === RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER_ITEM.name) {
                            action = new RuleActionConfigModelService.ActionConfigEvaluatePersonalizedContainerItemModel(parent);
                        } else if (type === RuleConstantsService.actionType.UNAUTHENTICATED_TASK.name) {
                            action = new RuleActionConfigModelService.ActionConfigUnauthenticatedTaskModel(parent);
                        }  else if (type === RuleConstantsService.actionType.FIRE_VXP_EVENT.name) {
                            action = new RuleActionConfigModelService.ActionConfigFireVXPEventModel(parent);
                        } else if (type === RuleConstantsService.actionType.CREATE_CHILD_ACCOUNT.name) {
                            action = new RuleActionConfigModelService.ActionConfigCreateChildAccountListModel(parent);
                        } else if (type === RuleConstantsService.actionType.PCI_CHANGE_DISMISS_STATUS.name) {
                            action = new RuleActionConfigModelService.ActionConfigPciChangeDismissStatusModel(parent);
                        }

                    }

                    if (action != null) {
                        action.fromDto(dto);
                    }

                    return action;
                }

                /***************************************************************
                 * Action type utility model that include icon, constructor, etc
                 ***************************************************************/
                function ActionTypeModel(actionType, newInstanceFunction) {
                    this.name = actionType.name;
                    this.icon = actionType.icon;
                    this.text = actionType.text;
                    this.newInstance = newInstanceFunction;
                }

                function buildActionTypeUtil() {
                    return {
                        actionTypes : [
                            new ActionTypeModel(RuleConstantsService.actionType.PUSH_NOTIFICATION, function (expression) {
                                var pushNotificationActionConfig =
                                    new RuleActionBaseModelService.ActionConfigBaseModel(expression.getRoot());
                                pushNotificationActionConfig.type = RuleConstantsService.actionType.PUSH_NOTIFICATION;
                                pushNotificationActionConfig.actionPushNotificationConfig =
                                    new RuleActionBaseModelService.ActionPushNotificationConfig();

                                return new ActionHolder(expression, pushNotificationActionConfig);
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.EMAIL_OR_SMS_NOTIFICATION, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigEmailOrSMSNotificationModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigMobileAppPushNotificationModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.BANNER_NOTIFICATION, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigBannerNotificationModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.IN_APP_NOTIFICATION, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigInAppNotificationModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.PROFILE_UPDATE, function (expression) {
                                return new ActionHolder(expression, new UserProfileValueActionModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.USER_ACCOUNT_UPDATE, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigUserAccountUpdateListModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.TRIGGER_RULE, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigTriggerRuleModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.DISMISS_BANNER, function (expression) {
                                return new ActionHolder(expression, new DismissBannerActionModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.MODAL_NOTIFICATION, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigModalNotificationModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.WITHDRAW, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigWithdrawModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.CREATE_ORDER, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigCreateOrderModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.CANCEL_ORDER, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigCancelOrderModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.TRACK_CHANGE, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigTrackChangeModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.LEVEL_CHANGE, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigLevelChangeModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigEvaluatePersonalizedContainerModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER_ITEM, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigEvaluatePersonalizedContainerItemModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.UNAUTHENTICATED_TASK, function (expression) {
                                return new ActionHolder(
                                    expression,
                                    new RuleActionConfigModelService.ActionConfigUnauthenticatedTaskModel(expression.getRoot())
                                );
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.FIRE_VXP_EVENT, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigFireVXPEventModel(expression.getRoot()));
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.CREATE_CHILD_ACCOUNT, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigCreateChildAccountListModel(expression.getRoot()))
                            }),
                            new ActionTypeModel(RuleConstantsService.actionType.PCI_CHANGE_DISMISS_STATUS, function (expression) {
                                return new ActionHolder(expression, new RuleActionConfigModelService.ActionConfigPciChangeDismissStatusModel(expression.getRoot()))
                            })
                        ],
                        ruleTypeBasedActions         : buildRuleTypeBasedActionType(),
                        isActionSupportedForRuleType : function (ruleTypeBasedActions, ruleType, actionType, programId) {
                            if(ruleType == null || actionType == null){
                                return false;
                            }

                            if (ruleType == RuleConstantsService.ruleType.PROTOCOL && programId!=null) {
                                // this is a hack to avoid creating new rule type on server side, should be fixed later
                                ruleType = RuleConstantsService.ruleType.PROGRAM_RULE;
                            }

                            var actions = ruleTypeBasedActions[ruleType];

                            if(actions == null){
                                return false;
                            }
                            return (actions.indexOf(actionType) != -1);
                        },

                    };
                }

                function buildRuleTypeBasedActionType() {
                    var map = {};
                    map[RuleConstantsService.ruleType.PROTOCOL] = [
                        RuleConstantsService.actionType.PUSH_NOTIFICATION.name,
                        RuleConstantsService.actionType.IN_APP_NOTIFICATION.name,
                        RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION.name,
                        RuleConstantsService.actionType.EMAIL_OR_SMS_NOTIFICATION.name,
                        RuleConstantsService.actionType.BANNER_NOTIFICATION.name,
                        RuleConstantsService.actionType.PROFILE_UPDATE.name,
                        RuleConstantsService.actionType.USER_ACCOUNT_UPDATE.name,
                        RuleConstantsService.actionType.TRIGGER_RULE.name,
                        RuleConstantsService.actionType.DISMISS_BANNER.name,
                        RuleConstantsService.actionType.MODAL_NOTIFICATION.name,
                        RuleConstantsService.actionType.WITHDRAW.name,
                        RuleConstantsService.actionType.CREATE_ORDER.name,
                        RuleConstantsService.actionType.CANCEL_ORDER.name,
                        RuleConstantsService.actionType.UNAUTHENTICATED_TASK.name,
                        RuleConstantsService.actionType.FIRE_VXP_EVENT.name,
                        RuleConstantsService.actionType.CREATE_CHILD_ACCOUNT.name,
                    ];
                    map[RuleConstantsService.ruleType.PROGRAM_SCHEDULE_RULE] = [
                        RuleConstantsService.actionType.EMAIL_OR_SMS_NOTIFICATION.name,
                        RuleConstantsService.actionType.PUSH_NOTIFICATION.name,
                        RuleConstantsService.actionType.IN_APP_NOTIFICATION.name,
                        RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION.name,
                        RuleConstantsService.actionType.PROFILE_UPDATE.name,
                        RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER.name,
                        RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER_ITEM.name,
                        RuleConstantsService.actionType.IN_APP_NOTIFICATION.name,
                        RuleConstantsService.actionType.MOBILE_APP_PUSH_NOTIFICATION.name,
                        RuleConstantsService.actionType.PCI_CHANGE_DISMISS_STATUS.name,
                    ];
                    map[RuleConstantsService.ruleType.PROGRAM_RULE] = [
                        // unused rule types
                        // RuleConstantsService.actionType.TRACK_CHANGE.name,
                        // RuleConstantsService.actionType.LEVEL_CHANGE.name,
                        ...map[RuleConstantsService.ruleType.PROTOCOL],
                        RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER.name,
                        RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER_ITEM.name,
                        RuleConstantsService.actionType.PCI_CHANGE_DISMISS_STATUS.name,
                    ];

                    return map;
                }

                /***************************************************************
                 * Action holder to hold parent and action model
                 ***************************************************************/
                ActionHolder.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function ActionHolder(expression, action, expressionActionMapping) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, false, false, expression);

                    // properties for UI
                    this.nodeType = RuleConstantsService.nodeType.ACTION;

                    this.action = action;

                    if (expressionActionMapping != null) {
                        this.expressionActionMappingId = expressionActionMapping.id;
                    }
                }

                /**
                 * validate the entity
                 */
                ActionHolder.prototype._validateSelf = function (validateChildModels) {
                    // this is a holder model, the actual validation happens in the action
                };

                ActionHolder.prototype.customClone = function () {
                    var newActionHolder = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);
                    // Clone the action inside holder explicitly and Set cloned action inside the holder
                    newActionHolder.action = this.action.customClone();
                    return newActionHolder;
                };

                /***************************************
                 * service return call
                 ***************************************/
                var actionTypes = buildActionTypeUtil();

                return {
                    getActionTypeUtil : function () {
                        return actionTypes;
                    },
                    ActionHolder                     : ActionHolder,
                    createActionModelFromDto         : createActionModelFromDto,
                    NotificationConfigFileAttachment : RuleActionConfigModelService.NotificationConfigFileAttachment,
                    ActionPushNotificationConfig     : RuleActionBaseModelService.ActionPushNotificationConfig,
                };
            }
        );

})();
