/* eslint-disable sort-keys-fix/sort-keys-fix */
/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    angular.module('acadiamasterApp')

        .factory('LocaleConstantsService', () => {
            return {
                inputType : {
                    FILE             : { name : 'file', text : 'This is file input' },
                    NUMBER           : { name : 'number', text : 'This is number input field' },
                    RICH_TEXT_EDITOR : { name : 'richtexteditor', text : 'This is rich text input' },
                    TEXT             : { name : 'text', text : 'This is text input field' },
                    TEXT_AREA        : { name : 'textarea', text : 'This is text area input field' },
                },
                locale : {
                    // EN: {name: "en", text: "English"},
                    ES : { name : 'es', text : 'Spanish' },
                    ZH : { name : 'zh', text : '中文' },
                    FR : { name : 'fr', text : 'Français' },
                    AR : { name : 'ar', text : 'عربي' },
                },
                localeArray : { langs : [ 'en', 'es', 'zh', 'ar', 'fr' ] },
            };
        });
}());

