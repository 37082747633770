/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    angular.module('acadiamasterApp')

    /**
     * directive for form field value for radio options
     */
        .directive('formFieldConfigFieldValueDropdownOptions',
            (FormUtilService, FormModelServiceFieldValue, LocaleConstantsService, ngDialog, $templateCache,
                ValueConfigOptionService) => {
                function processOnCSVLoad ($scope) {
                    ngDialog.openConfirm({
                        className : 'ngdialog-theme-default',
                        plain     : true,
                        template  : $templateCache.get('loadOptionConfirmation.html'),
                    }).then(value => {
                        if ($scope.csv.result != null && $scope.csv.result.length > 0) {
                            $scope.fieldValue.values = [];
                            _.forEach($scope.csv.result, object => {
                                $scope.fieldValue.values.push(new FormModelServiceFieldValue.OptionsValue(object.label, object.value));
                            });
                            $scope.showReset = true;
                        }
                    }, error => {
                        console.error('unable to process csv load', error);
                    });
                }

                /**
                 * Method to set reference to default option
                 * @param $scope
                 */
                function setDefaultOptionReference ($scope) {
                    if ($scope.fieldValue.defaultSelectedOption != null) {
                        _.forEach($scope.fieldValue.values, optionValue => {
                            if (optionValue.value === $scope.fieldValue.defaultSelectedOption.value
                                && optionValue.text === $scope.fieldValue.defaultSelectedOption.text) {
                                $scope.fieldValue.defaultSelectedOption = optionValue;
                            }
                        });
                    }
                }

                /**
                 * Init method
                 * @param $scope
                 */
                function init ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.oldFieldValues = angular.copy($scope.fieldValue.values);
                    $scope.showReset = false;
                    $scope.isDefaultOptionSelected = false;
                    $scope.hasChangeModel = $scope.fieldValueChange != null && $scope.fieldValueChange != undefined;

                    // Set reference to default selected option
                    setDefaultOptionReference($scope);
                }

                return {
                    link : function ($scope) {
                        init($scope);

                        $scope.valueConfigOption = ValueConfigOptionService.createValueConfigOption(
                            $scope.fieldElement._parent.fieldValue, $scope.fieldValue);

                        $scope.csv = {
                            accept           : 1,
                            content          : null,
                            encoding         : 'ISO-8859-1',
                            encodingVisible  : true,
                            header           : true,
                            result           : null,
                            separator        : ',',
                            separatorVisible : true,
                        };

                        $scope.onCSVLoad = function () {
                            processOnCSVLoad($scope);
                        };
                        $scope.addNewOption = function (index) {
                            FormUtilService.addNewOption($scope.fieldValue.values, index);
                        };

                        $scope.removeOption = function (index) {
                            FormUtilService.removeOption($scope.fieldValue.values, index);
                        };

                        $scope.moveOptionUp = function (index) {
                            FormUtilService.moveOptionUp($scope.fieldValue.values, index);
                        };

                        $scope.moveOptionDown = function (index) {
                            FormUtilService.moveOptionDown($scope.fieldValue.values, index);
                        };

                        $scope.undoOptions = function () {
                            $scope.fieldValue.values = angular.copy($scope.oldFieldValues);
                            $scope.csv.result = null;
                            $scope.showReset = false;
                        };

                        $scope.openCSVHelpDialog = function () {
                            ngDialog.open({
                                className : 'ngdialog-theme-default',
                                plain     : true,
                                template  : $templateCache.get('csvHelp.html'),
                            });
                        };

                        $scope.selectDefaultSelectedOption = function (option) {
                            if ($scope.fieldValue.defaultSelectedOption !== option) {
                                if ($scope.fieldValue.defaultSelectedOption) {
                                    $scope.fieldValue.defaultSelectedOption.selected = false;
                                }
                                $scope.fieldValue.defaultSelectedOption = option;
                            } else {
                                $scope.fieldValue.defaultSelectedOption = null;
                            }
                        };

                        $scope.getOptionValueEnabled = function (index) {
                            if ($scope.fieldValueChange == null || $scope.fieldValueChange.values == null
                                || $scope.fieldValueChange.values[index] == null || $scope.fieldValueChange.values[index].enabled == null) {
                                return $scope.fieldValue.values[index].enabled;
                            }

                            return $scope.fieldValueChange.values[index].enabled;
                        };
                    },
                    restrict : 'E',
                    scope    : {
                        fieldElement     : '=',
                        fieldValue       : '=',
                        fieldValueChange : '=',
                    },
                    templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/dropdownOptions.html',
                };
            });
}());

