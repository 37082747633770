/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueSliderOptions', function () {

            return {
                restrict: 'E',
                template: '<form-field-config-field-value-slider-base-options ' + "\n" +
                          '         field-value-change="fieldValueChange"' + "\n" +
                          '  field-value="fieldValue" field-element="fieldElement" display-strings="displayStrings">'  + "\n" +
                          '</form-field-config-field-value-slider-base-options>',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.displayStrings = {};
                    $scope.displayStrings.minValue = "Min Value";
                    $scope.displayStrings.maxValue = "Max Value";
                    $scope.displayStrings.displayIncrementalValue = "Display Incremental Value";
                    $scope.displayStrings.selectionIncrementalValue = "Selection Incremental Value";
                }
            }
        });

})();

