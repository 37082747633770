(function () {

    angular.module('acadiamasterApp').directive('personalizedContainerItemStateConfig', function () {
        return {
            restrict    : 'E',
            templateUrl : 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/personalizedContainerItemState.html',
            scope       : {
                condition : '<',
            },
            link : function ($scope) {

                $scope.containerOptions = {
                    getContainer   : () => $scope.condition.getContainer(),
                    setContainer   : container => $scope.condition.setContainer(container),
                    programId      : $scope.condition.getProgramId(),
                    filterFunction : container => {
                        // filter out container that doesn't have container items
                        return container != null && container.containerItems != null && container.containerItems.length > 0;
                    },
                };

                $scope.containerItemOptions = {
                    getContainerItem : () => $scope.condition.getContainerItem(),
                    setContainerItem : containerItem => $scope.condition.setContainerItem(containerItem),
                    programId        : $scope.condition.getProgramId(),
                    getContainerId   : () => {
                        const container = $scope.condition.getContainer();
                        return container == null ? null : container.id;
                    },
                };

                $scope.shouldShowContainerItemSelection = function () {
                    const container = $scope.condition.getContainer();
                    return container != null && container.containerItems != null && container.containerItems.length > 0;
                };
            },
        };
    });
})();
