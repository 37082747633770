
/**
 * util class for creating any if/assert condition by type
 */
angular.module('acadiamasterApp').factory('ConditionTreeCreationUtil',
    (ProgramTestConstantsService, ConditionTreeCreatorModel, QGroupModel,
        FieldValueComparisonConditionModel, FormAssociationStateConditionModel, PersonalizedContainerStateConditionModel,
        FormEntryCheckWithTimeCondition, FormEntryStateCondition, PropertyValueComparisonConditionModel,
        HasBannerByFormIdCondition, HasBannerByTypeCondition, HasFormAssociationConditionModel,
        IsSubscribedToDistributionConditionModel, IsSubscribedToProgramConditionModel,
        HasRuleBeenExecutedCondition, UserIdInCondition,PushEnabledConditionModel,
        AddressZipComparisonConditionModel, AddressStateComparisonConditionModel, AddressTypeConditionModel,
        PhysicalMeasurementStatusConditionModel, BioSpecimenStatusConditionModel, ProgramDayInRangeCondition,
        OrderStatusConditionModel, OrderTrackingStatusConditionModel, ReuseExpressionConditionModel,
        RuleActionStatusCondition, CheckMultiSubscriptionCondition, ExpressionConditionConstantsService,
        PersonalizedContainerItemStateConditionModel, HasUnauthenticatedTaskConditionModel
    ) => {
        // two local variable that holds the creator list and creator map
        const caList = [];
        const caMap = {};
        const expConstant = ExpressionConditionConstantsService;

        init();

        function init() {
            const qObjectType = ProgramTestConstantsService.QObjectType;

            addCreator(QGroupModel, qObjectType.GROUP_OBJECT);
            addCreator(ReuseExpressionConditionModel, qObjectType.REUSE_EXPRESSION_CONDITION);

            addCreator(FormAssociationStateConditionModel, qObjectType.FORM_ASSOCIATION_STATE, true);
            addCreator(HasFormAssociationConditionModel, qObjectType.HAS_FORM_ASSOCIATION);
            addCreator(PersonalizedContainerStateConditionModel, qObjectType.PERSONALIZED_CONTAINER_STATE);
            addCreator(PersonalizedContainerItemStateConditionModel, qObjectType.PERSONALIZED_CONTAINER_ITEM_STATE);

            addCreator(FormEntryStateCondition, qObjectType.FORM_ENTRY_STATE, true);
            addCreator(FormEntryCheckWithTimeCondition, qObjectType.FORM_ENTRY_CHECK_WITH_TIME);
            addCreator(FieldValueComparisonConditionModel, qObjectType.FIELD_VALUE_COMPARISON);
            addCreator(HasUnauthenticatedTaskConditionModel, qObjectType.HAS_UNAUTHENTICATED_TASK);

            addCreator(PropertyValueComparisonConditionModel, qObjectType.PROPERTY_VALUE_COMPARISON, true);

            addCreator(HasBannerByTypeCondition, qObjectType.HAS_BANNER_NOTIFICATION_BY_TYPE, true);
            addCreator(HasBannerByFormIdCondition, qObjectType.HAS_BANNER_NOTIFICATION_BY_FORM_ID);

            addCreator(IsSubscribedToDistributionConditionModel, qObjectType.IS_SUBSCRIBED_TO_DISTRIBUTION, true);
            addCreator(IsSubscribedToProgramConditionModel, qObjectType.IS_SUBSCRIBED_TO_PROGRAM, false);
            addCreator(CheckMultiSubscriptionCondition, qObjectType.CHECK_MULTIPLE_SUBSCRIPTION, false, [expConstant.usageTypes.BUSINESS_RULE, expConstant.usageTypes.EXPRESSION_CONDITION]);
            addCreator(ProgramDayInRangeCondition, qObjectType.PROGRAM_DAY_IN_RANGE);

            addCreator(AddressStateComparisonConditionModel, qObjectType.ADDRESS_STATE_COMPARISON, true);
            addCreator(AddressZipComparisonConditionModel, qObjectType.ADDRESS_ZIP_CODE_COMPARISON);
            addCreator(AddressTypeConditionModel, qObjectType.ADDRESS_TYPE);

            addCreator(HasRuleBeenExecutedCondition, qObjectType.HAS_RULE_BEEN_EXECUTED, true);
            addCreator(RuleActionStatusCondition, qObjectType.RULE_ACTION_STATUS);

            addCreator(UserIdInCondition, qObjectType.USER_ID_IN, true);
            addCreator(PushEnabledConditionModel,qObjectType.PUSH_ENABLED,false);

            addCreator(PhysicalMeasurementStatusConditionModel, qObjectType.PHYSICAL_MEASUREMENT_STATUS, true);
            addCreator(BioSpecimenStatusConditionModel, qObjectType.BIO_SPECIMEN_STATUS);

            addCreator(OrderStatusConditionModel, qObjectType.ORDER_STATUS, true);
            addCreator(OrderTrackingStatusConditionModel, qObjectType.ORDER_TRACKING_STATUS);


        }

        function addCreator(modelClass, modelType, hasDividerAbove, hideConditionForUsageType) {
            const creator = new ConditionTreeCreatorModel(modelClass, modelType, hasDividerAbove, hideConditionForUsageType);
            caList.push(creator);
            caMap[modelType.name] = creator;
        }

        function createByType(typeName, parent) {
            const creator = caMap[typeName];

            if (creator == null) {
                console.error('cant find creator for type name : ', typeName);
                return null;
            }

            return creator.createNew(parent);
        }

        function createByDto(dto, parent) {
            if (dto == null) {
                // this is okay, it's sometimes being called on null object as part of the from dto function
                return;
            }

            if (dto.type == null) {
                console.error('cant find creator for dto that has no type name');
                return;
            }

            const newObject = createByType(dto.type, parent);

            if (newObject == null) {
                return null;
            }

            newObject.fromDto(dto);

            if (newObject.type == ProgramTestConstantsService.QObjectType.GROUP_OBJECT) {
                // a group object, needs to make recursive calls
                newObject.subItems = [];

                // load subItems
                _.forEach(dto.subItems, (stDto) => {
                    // this will add item automatically
                    createByDto(stDto, newObject);
                });
            }

            return newObject;
        }

        function canHideConditionButton(creator, currentUsageType) {

            if(currentUsageType == null || creator.hideConditionForUsageType == null){
                return false;
            }
            return creator.hideConditionForUsageType.includes(currentUsageType);
        }

        /** *************************************
         * service return call
         ************************************** */
        return {
            createByType,
            getAllCreators() {
                return caList;
            },
            createByDto,
            canHideConditionButton
        };
    });
