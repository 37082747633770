(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for configuring one validation rule
     */
        .directive('formElementConfigValidationRule', function (LocaleConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/validationRule/validationRule.html',
                scope: {
                    vr: '=',
                    element: '=',
                    vrChangeArray : '=',
                    vrChangeIndex :'='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;

                    $scope.includeEqualChanged = function(validationRule) {
                        includeEqualChanged(validationRule);
                    };

                    $scope.includeUnitChanged = function(validationRule) {
                        includeUnitChanged(validationRule);
                    };

                    $scope.includeChange = function() {
                        return $scope.vrChangeArray!=null;
                    };

                    $scope.isVrEnabled = function() {
                        if ($scope.includeChange() && $scope.vrChangeArray[$scope.vrChangeIndex]!=null) {
                            return $scope.vrChangeArray[$scope.vrChangeIndex];
                        }
                        else {
                            return $scope.vr.enabled;
                        }
                    };

                    $scope.enabledToggle = function() {
                        if ($scope.includeChange()) {
                            if ($scope.vrChangeArray[$scope.vrChangeIndex]==null) {
                                $scope.vrChangeArray[$scope.vrChangeIndex] = !$scope.vr.enabled;
                            }
                            else {
                                $scope.vrChangeArray[$scope.vrChangeIndex] = !$scope.vrChangeArray[$scope.vrChangeIndex];
                                if ($scope.vrChangeArray[$scope.vrChangeIndex]==$scope.vr.enabled) {
                                    $scope.vrChangeArray[$scope.vrChangeIndex] = null;
                                }
                            }
                        }
                        else {
                            $scope.vr.enabled = !$scope.vr.enabled;
                        }
                    };

                    $scope.removeCurrentRule = function() {
                        _.pull($scope.element.validationRules, $scope.vr);
                    };

                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function includeEqualChanged(vr) {
                if (vr.includeEqual && vr.options[FormConstants.validationRuleOptions.INCLUDE_EQUAL.name]==undefined) {
                    vr.options[FormConstants.validationRuleOptions.INCLUDE_EQUAL.name] = true;
                }
                else if (!vr.includeEqual && vr.options[FormConstants.validationRuleOptions.INCLUDE_EQUAL.name]!=undefined) {
                    delete vr.options[FormConstants.validationRuleOptions.INCLUDE_EQUAL.name];
                }
            }

            function includeUnitChanged(vr) {
                console.log('include unit changed: ' + vr.includeUnit + ", " + vr.selectedUnit);
                if (vr.includeUnit) {
                    vr.options[FormConstants.validationRuleOptions.INCLUDE_UNIT.name] = vr.selectedUnit;
                }
                else if (!vr.includeUnit && vr.options[FormConstants.validationRuleOptions.INCLUDE_UNIT.name]!=undefined) {
                    delete vr.options[FormConstants.validationRuleOptions.INCLUDE_UNIT.name];
                }
            }
        });

})();
