(function () {
    angular.module('acadiamasterApp')
        .controller('FormVersionDeleteController', ($scope, ngDialog, FormVersion) => {
            $scope.delete = function () {
                FormVersion.delete($scope.ngDialogData.id)
                    .then(() => {
                        $scope.confirm($scope.ngDialogData.id);
                    });
            };
        });
}());
