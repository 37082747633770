/**
 * directive for configuring whether or not to hide the border of a screen
 */
angular.module('acadiamasterApp').directive('templateValueBooleanConfig', () => ({
    restrict : 'E',
    scope    : {
        contentValue : '=',
        definition   : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
            'templateValue.boolean.config.html',
}));

