(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for PersonalizedContainerStateCondition model, this condition is to check the lock/unlock, visible/invisible state of
     * a personalized container for the user
     */
    app.factory('PersonalizedContainerStateConditionModel', function (QObjectModel, ProgramTestConstantsService, ContainerCacheService) {
        /***************************************************************
         * private functions
         **************************************************************/

        PersonalizedContainerStateConditionModel.inheritsFrom(QObjectModel);

        function PersonalizedContainerStateConditionModel(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.PERSONALIZED_CONTAINER_STATE);

            //initialized the variables
            this.containerId = null;
            this.unlocked = true;
            this.visible = true;

            this._container = null;
        }

        /**
         * convert the current UI model to dto format
         */
        PersonalizedContainerStateConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.containerId = this.containerId;
            dto.unlocked = this.unlocked;
            dto.visible = this.visible;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        PersonalizedContainerStateConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.containerId = dto.containerId;
            this.unlocked = dto.unlocked;
            this.visible = dto.visible;

            const programId = this.getRoot().programId;
            const _this = this;

            // load container here if necessary
            if (this.containerId && programId) {
                ContainerCacheService.loadContainerById(this.containerId, programId, false)
                    .then(container => {
                        _this.setContainer(container);
                    });
            }
        };

        PersonalizedContainerStateConditionModel.prototype.setContainer = function (container) {
            this._container = container;
            this.containerId = container == null ? null : container.id;
        };

        PersonalizedContainerStateConditionModel.prototype.getContainer = function () {
            return this._container;
        };

        PersonalizedContainerStateConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var containerMsg = 'Personalized Container (' + (this.containerId == null ? 'no id' : this.containerId) + ' | ' +
                (this._container == null ? 'no name' : this._container.name) + ')';

            var lockedMsg = 'is <span class="badge badge-info">' + (this.unlocked ? 'Unlocked' : 'Locked') + '</span>';
            var visibleMsg = ' and is <span class="badge badge-info">' + (this.visible ? 'Visible' : 'Invisible') + '</span>';

            return baseMsg + ' ' + containerMsg + ' should be associated and ' + lockedMsg + visibleMsg;
        };

        PersonalizedContainerStateConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.containerId == null) {
                this.setErrorMessage('container id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return PersonalizedContainerStateConditionModel;

    });
})();
