(function () {
    angular.module('acadiamasterApp').factory('ProgramTestService', ($http, localWeb) => {
        const baseUrl = 'api/';
        return {
            // Get list of all test cases
            deleteTestCase(testCaseId) {
                return $http.delete(localWeb.createUrl(`${baseUrl  }programTest/${  testCaseId}`));
            },
            // Get test case by Id
            createOrUpdateCase(data, onSaveFinished) {
                return $http({
                    method: 'POST',
                    url: localWeb.createUrl('api/programTest'),
                    data: JSON.stringify(data),
                    headers: { 'Content-Type': 'application/json' },
                }).then((resp) => {
                    if (_.isFunction(onSaveFinished)) {
                        onSaveFinished(resp);
                    }
                }, (error) => {
                    console.error(error);
                });
            },
            // delete test case by Id
            getAllTestCase(searchFilter) {
                return $http({
                    method: 'GET',
                    url: (localWeb.createUrl(baseUrl + 'programTest?') + $.param(searchFilter)),
                });
            },
            // create update test suite
            getBannerNotificationTargetAreas() {
                return $http.get(localWeb.createUrl('api/banner/bannerDisplayTargetAreas'));
            },

            getTestCase(id) {
                return $http.get(localWeb.createUrl(baseUrl + 'programTest/' + id));
            },

            cancelTest(testAuditId) {
                return $http.get(localWeb.createUrl(baseUrl + 'programTest/' + 'cancelTest?testAuditId=' + testAuditId));
            },
            getBioSpecimenTypes() {
                return $http.get(localWeb.createUrl('api/biospecimenStatusTypes'));
            },

            /**
             * run a specific test by id
             * @param id - test id
             * @returns {HttpPromise} - http promise for the api call
             */
            getPhysicalMeasurementTypes() {
                return $http.get(localWeb.createUrl('api/physicalMeasurementStatusTypes'));
            },

            /**
             * cancel a specific test by audit id
             * @param testAuditId - test execution audit id
             * @returns {HttpPromise} - http promise for the api call
             */
            getSqlQuery(programTestId, condition, expressionType, programId) {
                let data = {
                    condition: (condition != null ? condition.toDto() : null),
                    programTestId: (programTestId != null ? programTestId : ''),
                    expressionType: (expressionType != null ? expressionType : null),
                    programId: (programId != null ? programId : null),
                };
                return $http({
                    method: 'POST',
                    url: localWeb.createUrl('api/programTest/queryBuilder'),
                    data: JSON.stringify(data),
                    headers: { 'Content-Type': 'application/json' },
                });
            },

            /**
             * Check if the given name is available
             * @param name Name we are trying to save
             * @param id Id of the case (To avoid comparison with own name during edit)
             */
            isNameAvailable(name, id) {
                return $http.get(localWeb.createUrl(`${baseUrl}programTest/isNameAvailable?name=${name}${id != null ? '&id=' + id : ''}`));
            },


            /**
             * get the sql formatted string of a program test, or condition
             * @param programTestId - test program test id.  If provided, condition will be ignored.
             * @param condition - if/assert condition
             * @returns {HttpPromise} - http promise for the api call
             */
            runTest(id) {
                return $http.get(localWeb.createUrl(baseUrl + 'programTest/' + 'runTest?testId=' + id));
            },

        };
    });
}());
