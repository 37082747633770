(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('UserIdInCondition', function (QObjectModel, ProgramTestConstantsService, VbrStringUtil) {

        UserIdInCondition.inheritsFrom(QObjectModel);

        function UserIdInCondition(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.USER_ID_IN);
            this.userIdTextInput = '';
        }

        UserIdInCondition.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.userIds = [];
            dto.participantIds = [];

            var fullList = stringToList(this.userIdTextInput);

            // move id into the appropriate list
            _.forEach(fullList, function(idString) {
                if (VbrStringUtil.isStringEmpty(idString)) {
                    // do nothing
                }
                else if (isUserId(idString)) {
                    dto.userIds.push(idString);
                }
                else if (isParticipantId(idString)) {
                    dto.participantIds.push(idString);
                }
            });

            return dto;
        };

        UserIdInCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);
            var userIdsString = listToString(dto.userIds);
            var pIdsString = listToString(dto.participantIds);

            this.userIdTextInput = VbrStringUtil.isStringEmpty(userIdsString) ? '' : userIdsString;

            if (!VbrStringUtil.isStringEmpty(pIdsString)) {
                // add divider as a new line
                this.userIdTextInput += VbrStringUtil.isStringEmpty(userIdsString) ? '' : "\n";
                this.userIdTextInput += pIdsString;
            }
        };

        UserIdInCondition.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            return baseMsg + ' ' + 'User id is one of the specified ids : ' +
                getShortStringForDisplay(this.userIdTextInput, 15);
        };

        UserIdInCondition.prototype._validateSelf = function () {
            this.clearError();

            var list = stringToList(this.userIdTextInput);
            if (list == null || list.length == 0) {
                this.setErrorMessage('At least one user id must be specified');
                return;
            }

            var invalidIdString = _.find(list, function(idString) {
                return !VbrStringUtil.isStringEmpty(idString) && !isUserId(idString) && !isParticipantId(idString);
            });

            if (invalidIdString!=null) {
                this.setErrorMessage('"' + invalidIdString + '" is not a valid number');
            }
        };

        /***************************************
         * private functions and variables
         ***************************************/
        var validUserIdRegex = new RegExp('^\\d+$');
        var validParticipantIdRegex = new RegExp('^[pP]\\d+$');

        function stringToList(userIdTextInput) {
            return userIdTextInput.split(/[\s,]+/);
        }

        function getShortStringForDisplay(inputString, maxLength) {
            return inputString==null || inputString.length <= maxLength ?
                inputString :
                inputString.substring(0, maxLength) + "...";
        }

        /**
         * check if the id string is a valid participant id format, ie: start with P and followed by a number of digits
         */
        function isParticipantId(idString) {
            return validParticipantIdRegex.test(idString);
        }

        /**
         * check if the id string is a valid user id format, ie: a number of digits
         */
        function isUserId(idString) {
            return validUserIdRegex.test(idString);
        }

        function listToString(userIds) {
            return userIds ==null ? '' : userIds.join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return UserIdInCondition;

    });
})();
