/* eslint-disable no-undef */
/**
 */

angular.module('acadiamasterApp')
    .controller(
        'ManageProgramController',
        (
            $location, $scope, $rootScope, $stateParams, entity, ProgramModelService, $uibModal, ProgramService,
            $state, CONFIG, ProgramUtilService, TILE_CONSTANTS,
        ) => {
            $scope.program = entity;
            $scope.CONFIG = CONFIG;

            function getProgramManagementCounts () {
                let counts = {
                    consentFormAssociation : 0,
                    formAssociation        : 0,
                };
                if ($scope.program.consentForm) {
                    counts.consentFormAssociation = 1;
                }

                if ($scope.program.containers) {
                    $scope.program.containers.forEach(container => {
                        if (container.containerItems) {
                            counts.formAssociation = counts.formAssociation + container.containerItems.length;
                        }
                    });
                }
                return counts;
            }

            /* Wrapper so that getProgramMangementCounts can be called like an $http request */
            $scope.getCounts = function () {
                return {
                    then : function (resolve) {
                        if ($scope.program.$resolved) {
                            resolve(getProgramManagementCounts());
                        } else {
                            $scope.program.$promise.then(() => {
                                resolve(getProgramManagementCounts());
                            });
                        }
                    },
                };
            };

            $scope.data = {
                tileCollectionId : TILE_CONSTANTS.ID.PROGRAM_MANAGE,
            };

            $scope.programConfigurationStatusActions = {
                closePopup : function () {
                    $('#progamConfigurationCompleteModal').modal('hide');
                },
                openPopup : function () {
                    $('#progamConfigurationCompleteModal').modal('show');
                },
                saveStatus : function () {
                    let promise = ProgramService.changeConfigurationCompleteStatus(
                        $scope.program.id,
                        !$scope.program.programStatus.configurationComplete,
                    );
                    promise.then(() => {
                        $scope.programConfigurationStatusActions.closePopup();
                        $state.reload();
                    });

                    promise.catch(() => {
                        $scope.programConfigurationStatusActions.closePopup();
                    });
                },
            };

            $scope.deactivateUsersActions = {
                closePopup : function () {
                    $('#deactivateUsersModal').modal('hide');
                },
                closeResponsePopup : function () {
                    $('#deactivateUsersResponseModal').modal('hide');
                    $state.reload();
                },
                deactivateUsers : function () {
                    let promise = ProgramService.deactivateUsers($scope.program.id);
                    promise.then(response => {
                        $scope.deactivateUsersActions.closePopup();
                        if (response && response.status === 200) {
                            $scope.deactivateUsersActions.openResponsePopup();
                        }
                    });

                    promise.catch(() => {
                        $scope.deactivateUsersActions.closePopup();
                    });
                },
                openPopup : function () {
                    $('#deactivateUsersModal').modal('show');
                },
                openResponsePopup : function () {
                    $('#deactivateUsersResponseModal').modal('show');
                },
            };

            $scope.download = function (programId) {
                let fileName = `program_${ programId }.json`;
                ProgramUtilService.downloadProgram(programId, fileName);
            };
        },
    );
