'use strict';

(function () {

    angular.module('acadiamasterApp').directive('preCondition', function (ProgramTestConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/preCondition.html',
            scope: {
                element: '='
            },
            link: function ($scope) {
                $scope.preConditionType = ProgramTestConstantsService.preConditionType;

                $scope.clearMapValues = function () {
                    $scope.element.mapValues = {};
                }
            }
        };
    });
})();