(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for HasFormAssociationConditionModel model
     */
    app.factory('PushEnabledConditionModel', function (QObjectModel, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        PushEnabledConditionModel.inheritsFrom(QObjectModel);

        function PushEnabledConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.PUSH_ENABLED);

            //initialized the variables
            this.pushEnabled = true;
        }

        /**
         * convert the current UI model to dto format
         */
        PushEnabledConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.pushEnabled = this.pushEnabled;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        PushEnabledConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.pushEnabled = dto.pushEnabled;
        };

        PushEnabledConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var associationMsg = ' <span class="badge badge-info">' +
                (this.pushEnabled ? 'True' : 'False') + '</span>';

            return baseMsg + ' ' + associationMsg;
        };

        PushEnabledConditionModel.prototype._validateSelf = function () {
            this.clearError();

        };

        /***************************************
         * service return call
         ***************************************/
        return PushEnabledConditionModel;

    });
})();
