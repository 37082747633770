/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/**
 * drc sync user directive
 */
(function () {
    angular.module('acadiamasterApp').directive('vbrDrcFormToQuestionnaire', drcService => {
        /** *************************************
         * factory return call
         ***************************************/

        return {
            link : function ($scope) {
                $scope.CONVERSION_TYPE_BY_ID = 'byId';
                $scope.CONVERSION_TYPE_BY_UPLOAD = 'byUpload';
                $scope.data = {
                    conversionType     : $scope.CONVERSION_TYPE_BY_ID,
                    conversionTypeList : [ $scope.CONVERSION_TYPE_BY_ID, $scope.CONVERSION_TYPE_BY_UPLOAD ],
                    formDTOString      : null,
                    formId             : null,
                    formVersionId      : null,
                };

                $scope.startDownload = function () {
                    startDownload($scope);
                };
            },
            restrict    : 'E',
            scope       : {},
            templateUrl : 'admin-templates/site/drc/formToQuestionnaire/drc.formToQuestionnaire.html',
        };

        /*
         * create a downloaded file for any promise
         * @param inputString input string data
         * @param outputFileName output file name
         * todo: move this into common util when we do restructuring, it can be used in any place where we need to do this
         * type of downloads
         */
        function createDownload (inputString, outputFileName) {
            let anchor = angular.element('<a/>');
            anchor.attr({
                download : outputFileName,
                href     : `data:attachment/json;charset=utf-8,${ encodeURI(inputString)}`,
                target   : '_blank',
            })[0].click();
        }

        /*
         * start the download process by calling the appropriate api, no error handling here
         * @param $scope scope object
         */
        function startDownload ($scope) {
            let fileName = 'questionnaire.json';

            // this is a utility function used by admin only, no error handling here, all of
            // that is covered by server side validation
            if ($scope.data.conversionType == $scope.CONVERSION_TYPE_BY_UPLOAD) {
                drcService.convertFormVersionUploadedToQuestionnaire($scope.data.formDTOString)
                    .then(response => {
                        createDownload(JSON.stringify(response.data), fileName);
                    });
            } else {
                drcService.convertFormToQuestionnaireById($scope.data.formId, $scope.data.formVersionId)
                    .then(response => {
                        createDownload(JSON.stringify(response.data), fileName);
                    });
            }
        }
    });
}());
