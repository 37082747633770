(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('BannerNotificationTargetAreaModel',
        function (RuleConstantsService) {
            function BannerNotificationTargetAreaModel(bannerDisplayTargetArea) {
                this.dismissible = true;
                this.dismissOnFormEntry = true;
                this.bannerDisplayTargetArea = bannerDisplayTargetArea;
                this.remindable = false;
                this.reminderTimeOptions = {
                    dismissalDurationEnumList: [RuleConstantsService.reminderTimeOptions.PERMANENT]
                };
                return this;
            }

            BannerNotificationTargetAreaModel.prototype.fromDto = function (dto) {
                this.dismissible = dto.dismissible;
                this.dismissOnFormEntry = dto.dismissOnFormEntry;
                this.remindable = this.dismissible ? dto.remindable : false;
                this.bannerDisplayTargetArea = RuleConstantsService.getObjectByName(
                    RuleConstantsService.bannerDisplayTargetArea, dto.bannerDisplayTargetArea);
                if (this.dismissible) {
                    var _this = this;
                    if (dto.reminderTimeOptions && dto.reminderTimeOptions.dismissalDurationEnumList) {
                        _this.reminderTimeOptions.dismissalDurationEnumList = [];
                        _.forEach(dto.reminderTimeOptions.dismissalDurationEnumList, function (reminderOption) {

                            if (_this.reminderTimeOptions.dismissalDurationEnumList.indexOf(RuleConstantsService.getObjectByName(
                                RuleConstantsService.reminderTimeOptions, reminderOption)) == -1) {

                                _this.reminderTimeOptions.dismissalDurationEnumList.push(RuleConstantsService.getObjectByName(
                                    RuleConstantsService.reminderTimeOptions, reminderOption));
                            }
                        });
                    }
                }
            };

            BannerNotificationTargetAreaModel.prototype.toDto = function () {
                var dto = {};
                dto.dismissible = this.dismissible;
                dto.dismissOnFormEntry = this.dismissOnFormEntry;
                dto.remindable = dto.dismissible ? this.remindable : false;
                dto.bannerDisplayTargetArea = this.bannerDisplayTargetArea.name;
                if (dto.dismissible) {
                    dto.reminderTimeOptions = {
                        dismissalDurationEnumList: []
                    };
                    if (this.reminderTimeOptions && this.reminderTimeOptions.dismissalDurationEnumList) {
                        _.forEach(this.reminderTimeOptions.dismissalDurationEnumList, function (reminderOption) {
                            if (dto.reminderTimeOptions.dismissalDurationEnumList.indexOf(reminderOption.name) == -1) {
                                dto.reminderTimeOptions.dismissalDurationEnumList.push(reminderOption.name);
                            }
                        });
                    }
                }
                return dto;
            };

            return BannerNotificationTargetAreaModel;
        }
    );
})();