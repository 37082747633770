(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigTrackChangeModel',
        function (RuleConstantsService, RuleActionBaseModelService) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigTrackChangeModel.inheritsFrom(ActionConfigBaseModel);
            function ActionConfigTrackChangeModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.TRACK_CHANGE;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigTrackChangeModel;

                this.trackId = null;
                this.trackChangeType = null;
            }

            ActionConfigTrackChangeModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.trackChangeType = RuleConstantsService.getObjectByName(RuleConstantsService.trackChangeType, actionConfig.trackChangeType);
                    this.trackId = actionConfig.trackId;
                }
            };

            ActionConfigTrackChangeModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.trackId = this.trackId;
                actionConfig.trackChangeType = this.trackChangeType.name;
                return dto;
            };

            ActionConfigTrackChangeModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.trackId == null) {
                    this.setErrorMessage("Track is not selected");
                } else if (this.trackChangeType == null) {
                    this.setErrorMessage("Either activate or de-activate should be selected");
                }
            };

            ActionConfigTrackChangeModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                var changeType = this.trackChangeType;
                var trackId = this.trackId;
                if (changeType && trackId) {
                    message += " | " + changeType.text + " track id " + trackId;
                } else {
                    message += " | **** NO TRACK AND CHANGE TYPE SELECTED YET!! ****";
                }
                return message;
            };
            return ActionConfigTrackChangeModel;
        }
    );
})();