/**
 * directive for configuring the lock preview, the default message, and condition with messages
 */
angular.module('acadiamasterApp')
    .directive('lockPreviewConfig', (ngDialog, $timeout, ExpressionConstantsService,
        LocaleConstantsService) => ({
            bindToController: true,
            controller() {
                const vm = this;

                vm.expConstants = ExpressionConstantsService;
                vm.lcs = LocaleConstantsService;
                vm.treeOptions = {
                    accept: accept,
                };

                vm.onLockPreviewChanged = function (hasLockPreview) {
                    vm.element.hasLockPreview = hasLockPreview;
                };

                vm.shouldShowAdditionalMessage = () => {
                    return vm.element.hasLockPreview && vm.element.defaultLockedMessage.hasValue();
                };


                /**
                 * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
                 * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
                 * @param sourceNodeScope
                 * @param destNodeScope
                 * @returns {boolean}
                 */
                function accept(sourceNodeScope, destNodeScope) {
                    var destNodeModel = getCurrentModel(destNodeScope);
                    var sourceModel = getCurrentModel(sourceNodeScope);

                    const sourceParent = sourceModel._parent;
                    const destParent = getParentFromList(destNodeModel);

                    return sourceParent == destParent;
                }

                function getParentFromList(list) {
                    if (list==null || list.length==0) {
                        return null;
                    }

                    return list[0]._parent;
                }

                function getCurrentModel(nodeScope) {
                    if (nodeScope == null || nodeScope.$modelValue == null) {
                        return null;
                    }

                    return nodeScope.$modelValue;
                }

            },
            controllerAs: 'lpc',
            restrict: 'E',
            scope: {
                element: '=',
                isActionItem : '<?',
            },
            templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/lockPreviewConfig.template.html',
        }));

