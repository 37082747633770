'use strict';
(function () {
    angular.module('acadiamasterApp').factory('ProgramTestSuiteService', function ($http, localWeb) {
        var baseUrl = 'api/';
        return {
            // Get list of all test suites
            getAllTestSuite: function (searchFilter) {
                return $http({
                    url: (localWeb.createUrl(baseUrl + "programTestSuite?") + $.param(searchFilter)),
                    method: 'GET'
                });
            },
            getTestSuiteOfProgram: function (programId, searchFilter) {
                return $http({
                    url: (localWeb.createUrl(baseUrl + "programTestSuite/program?programId=" + programId + "&") + $.param(searchFilter)),
                    method: 'GET'
                });
            },
            // Get test suite by Id
            getTestSuite: function (id) {
                return $http.get(localWeb.createUrl(baseUrl + "programTestSuite/" + id));
            },
            // delete suite case by Id
            deleteTestSuite: function (testSuiteId) {
                return $http.delete(localWeb.createUrl(baseUrl + "programTestSuite/" + testSuiteId));
            },
            // create update test suite
            createOrUpdateSuite: function (data, onSaveFinished) {
                return $http({
                    url: localWeb.createUrl('api/programTestSuite'),
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    data: JSON.stringify(data)
                }).then(function (resp) {
                    if (_.isFunction(onSaveFinished)) {
                        onSaveFinished(resp);
                    }
                }, function (error) {
                    console.error(error);
                });

            },
            runTestSuite: function (id) {
                return $http.get(localWeb.createUrl(baseUrl + 'programTestSuite/' + 'runTestSuite?testSuiteId=' + id));
            },
            isNameAvailable: function (name, currentId) {
                var url = localWeb.createUrl(baseUrl + 'programTestSuite/' + 'isNameAvailable?name=' + name);
                if (currentId) {
                    url = url + '&&testSuiteId=' + currentId;
                }
                return $http.get(url);
            },
            getPageAudits: (params = {}) => $http.get(localWeb.createUrl(`${baseUrl}programTestSuite/audit`), { params }),

        };
    })
})();
