/**
 * Created by Jamie Nola on 12/10/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * service for string key model
         */
        .service('StringResourceKeyModelService',
            function (StringManagementConstants, StringResourceValueModelService) {
                /***************************************************************
                 * String Model
                 ***************************************************************/
                function StringResourceKeyModel() {
                    // Add string dto related fields
                    this.description = null;
                    this.feature = null;
                    this.values = {};
                    this.keyName = null;
                    this.isCustom = false;
                }

                /**
                 * convert a dto into a model that the UI can use
                 */
                StringResourceKeyModel.prototype.fromDto = function (dto, programId) {
                    this.keyName = dto.keyName;
                    this.description = dto.description;
                    this.programId = programId;
                    var isCustom = false;
                    var values = this.values;
                    var PROGRAM_LEVEL = StringManagementConstants.LEVEL.PROGRAM;
                    var SYSTEM_LEVEL = StringManagementConstants.LEVEL.SYSTEM;
                    if (dto.values) {
                        dto.values.forEach(function (value) {
                            var level = value.programId != null ?
                                PROGRAM_LEVEL :
                                SYSTEM_LEVEL;

                            if (values[value.locale] == null) {
                                values[value.locale] = {};
                            }
                            var stringValueModel = new StringResourceValueModelService.StringResourceValueModel(dto.keyName, programId, value.locale);
                            stringValueModel.fromDto(value);
                            values[value.locale][level] = stringValueModel;
                            if (!isCustom && stringValueModel.isCustom) {
                                isCustom = true;
                            }
                        });

                        this.values = values;
                    }
                    this.isCustom = isCustom;
                    this.feature = dto.feature;
                };

                StringResourceKeyModel.prototype.getValueModel = function (locale, level) {
                    var localeValues = this.values[locale] || {};
                    this.values[locale] = localeValues;
                    var valueModel = localeValues[level] ||
                        new StringResourceValueModelService.StringResourceValueModel(this.keyName, this.programId, locale);
                    this.values[locale][level] = valueModel;
                    return valueModel;
                };

                /**
                 * Updates the isCustom flag based on the stored values
                 */
                StringResourceKeyModel.prototype.updateIsCustomFlag = function () {
                    var isCustom = {};
                    isCustom[StringManagementConstants.LEVEL.SYSTEM] = false;
                    isCustom[StringManagementConstants.LEVEL.PROGRAM] = false;

                    var values = this.values;
                    Object.keys(values).forEach(function (localeKey) {
                        var localeValues = values[localeKey];
                        Object.keys(localeValues).forEach(function (platformKey) {
                            var value = localeValues[platformKey];
                            if (value.isCustom) {
                                isCustom[platformKey] = true;
                            };
                        });
                    });
                    this.isCustom = isCustom;
                };

                /***************************************
                 * service return call
                 ***************************************/
                return {
                    StringResourceKeyModel: StringResourceKeyModel
                };
            });
})();
