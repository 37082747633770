(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {

            $stateProvider
                .state('funcEval', {
                    parent: 'ruleDashboard',
                    url: '/funcEval',
                    data: {
                        authorities: ['ROLE_ADMIN'],
                        pageTitle: 'Functional Condition Evaluation'
                    },
                    views: {
                        'content@': {
                            template: '<func-eval></func-eval>'
                        }
                    }
                });
        });

})();

