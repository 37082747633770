import * as moment from 'moment';
import {AgreementEvents} from '../../agreements/model/agreementEvent.constants';

(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * service for trigger model
         */
        .service('RuleTriggerModelService',
            function (RuleConstantsService, RuleUtilService, ModelServiceBase, ORDER_TYPES, ProgramLoadUtil, AgreementLoadUtil, ContainerItemLoadUtil) {
                /***************************************************************
                 * private functions
                 **************************************************************/
                function initDelayValueModel(triggerModel, delayInSeconds) {
                    if (delayInSeconds == null || delayInSeconds <= 0) {
                        return;
                    }

                    var delayModel = RuleUtilService.convertValueFromSeconds(delayInSeconds);
                    if (delayModel != null) {
                        triggerModel.delayValue = delayModel.value;
                        triggerModel.delayValueUnit = delayModel.unit;
                    }
                }

                /***************************************************************
                 * Trigger model base
                 ***************************************************************/
                TriggerConditionModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function TriggerConditionModel(fixedTriggerNode) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, fixedTriggerNode);

                    // properties for UI
                    this.nodeType = RuleConstantsService.nodeType.TRIGGER;

                    // trigger properties
                    this.id = null;
                    this.trigger = new TriggerConditionConfigBaseModel(this);
                }

                /**
                 * convert the current UI model to dto format
                 */
                TriggerConditionModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id = this.id;
                    dto.triggerType = this.type.name;
                    dto.trigger = this.trigger != null ? this.trigger.toDto() : null;
                    return dto;
                };

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 * @param dto
                 */
                TriggerConditionModel.prototype.fromDto = function (dto) {
                    this.id = dto.id;
                    this.type = RuleConstantsService.getObjectByName(RuleConstantsService.triggerType, dto.triggerType);
                    if (dto.trigger != null) {
                        this.trigger.fromDto(dto.trigger);
                    }
                };


                /**
                 * validate the entity
                 */
                TriggerConditionModel.prototype._validateSelf = function () {
                    // by default, no validation at all, sub class will override the validation if needed
                    this.clearError();
                    var errorMessage = this.trigger != null ? this.trigger._validateSelf() : null;
                    if (errorMessage) {
                        this.setErrorMessage(errorMessage);
                    }
                };

                /**
                 * getting the description of the trigger
                 */
                TriggerConditionModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                    var message = '';
                    if (addBadgeAndType) {
                        message += '<span data-nodrag class="badge badge-warning">Trigger</span>';
                        message += ' <i>' + this.type.name + '</i>';
                    }
                    message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                    message += this.trigger != null ? this.trigger.getDescriptionAsHtml() : '';
                    return message;
                };

                /***************************************************************
                 * Trigger config base model
                 ***************************************************************/
                TriggerConditionConfigBaseModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
                /**
                 * @constructor
                 */
                function TriggerConditionConfigBaseModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
                    this.triggerId = null;
                    this.delay = 0; // default delay is 0

                    this.delayValue = 0;
                    this.delayValueUnit = RuleConstantsService.timeUnits.SECOND;
                }

                TriggerConditionConfigBaseModel.prototype.toDto = function () {
                    var dto = {};

                    dto[RuleConstantsService.trigger.classes.classReferenceKey] = this[RuleConstantsService.trigger.classes.classReferenceKey];

                    dto.triggerId = this._parent.id;
                    if (this.delayValue !== null) {
                        dto.delay = RuleUtilService.convertValueToSeconds(this.delayValue, this.delayValueUnit);
                    }

                    return dto;
                };

                TriggerConditionConfigBaseModel.prototype.fromDto = function (dto) {
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = dto[RuleConstantsService.trigger.classes.classReferenceKey];
                    initDelayValueModel(this, dto.delay);
                };

                TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml = function () {
                    if (!RuleConstantsService.isUnavailable(this.delay)) {
                        return 'delay: ' + this.delayValue + ' ' + this.delayValueUnit.text;
                    } else {
                        return '';
                    }
                };

                TriggerConditionConfigBaseModel.prototype._validateSelf = function () {
                    // by default, no validation at all, sub class will override the validation if needed
                    var errorMessage = null;
                    if (this.delayValue == null) {
                        errorMessage = 'Delay is not provided';
                    } else {
                        if (this.delayValue < 0) {
                            errorMessage = 'Delay Time must be greater than or equal to zero';
                        } else if (this.delayValue !== Math.floor(this.delayValue)) {
                            errorMessage = 'Delay Time must be a whole number';
                        }
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * Sign up trigger model
                 ***************************************************************/
                SignUpTriggerConditionModel.inheritsFrom(TriggerConditionModel);
                /**
                 * @constructor
                 */
                function SignUpTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.SIGN_UP;
                    this.trigger = new SignUpTriggerConditionConfigModel(this);
                }

                /***************************************************************
                 * Sign up trigger config model
                 ***************************************************************/
                SignUpTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);
                /**
                 * @constructor
                 */
                function SignUpTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerSignUpDTO;
                }

                /***************************************************************
                 * log entry trigger model
                 ***************************************************************/
                LogEntryTriggerConditionModel.inheritsFrom(TriggerConditionModel);
                /**
                 * @constructor
                 */
                function LogEntryTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.LOG_ENTRY;
                    this.trigger = new LogEntryTriggerConditionConfigModel(this);
                }

                /***************************************************************
                 * log entry trigger config  model
                 ***************************************************************/
                LogEntryTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                /**
                 * @constructor
                 */
                function LogEntryTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerLogDTO;
                    this.dataTypeRootCategoryId = null;
                    this.dataTypeCategoryId = null;
                    this.dataTypeId = null;
                    this.propertyId = null;
                    this.submitType = RuleConstantsService.logTriggerSubmitTypes.FORM_SUBMIT;
                    this.form = null;
                    this.formCategory = RuleUtilService.formConstant.formCategoriesObjects.DATA_FORM;
                    this.formEntryStatus = RuleConstantsService.formEntryStatusType.COMPLETE;
                }

                LogEntryTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);

                    if (this.submitType != null) {
                        if (this.submitType.name === RuleConstantsService.logTriggerSubmitTypes.FORM_SUBMIT.name) {
                            dto.formId = this.form.id;
                            dto.dataTypeId = null;
                            dto.propertyId = null;
                        } else if (this.submitType.name === RuleConstantsService.logTriggerSubmitTypes.DATA_TYPE_SUBMIT.name) {
                            dto.formId = null;
                            dto.dataTypeId = this.dataTypeId;
                            dto.propertyId = this.propertyId;
                        }
                    }
                    dto.formEntryStatus = this.formEntryStatus.name;
                    return dto;
                };

                LogEntryTriggerConditionConfigModel.prototype.fromDto = function (triggerDTO) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDTO);

                    if (triggerDTO.formId != null) {
                        // Trigger based on form submit
                        this.submitType = RuleConstantsService.logTriggerSubmitTypes.FORM_SUBMIT;
                        this.form = RuleUtilService.getFormById(triggerDTO.formId);
                        this.form.$promise.then(function (formData) {
                            this.formCategory = RuleConstantsService.getObjectByName(RuleUtilService.formConstant.formCategoriesObjects, formData.category);
                        }.bind(this));
                    } else {
                        // Trigger is based on dat type details submit
                        this.submitType = RuleConstantsService.logTriggerSubmitTypes.DATA_TYPE_SUBMIT;
                        this.dataTypeId = triggerDTO.dataTypeId;
                        this.propertyId = triggerDTO.propertyId;
                    }
                    this.formEntryStatus = RuleConstantsService.getObjectByName(RuleConstantsService.formEntryStatusType, triggerDTO.formEntryStatus);
                };

                LogEntryTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    if (this.submitType.name === RuleConstantsService.logTriggerSubmitTypes.FORM_SUBMIT.name) {
                        if (this.form) {
                            message += ' | form: (' + this.form.name + ', ' + this.form.category + ', ' + this.form.id + ', ' + this.formEntryStatus.text + ')';
                        }
                        else {
                            message += ' | *** no form selected yet ***';
                        }
                    } else if (this.submitType.name === RuleConstantsService.logTriggerSubmitTypes.DATA_TYPE_SUBMIT.name) {
                        message += ' | dt/property id : (' + this.dataTypeId + ', ' + this.propertyId + ')';
                    }

                    return message;
                };


                /**
                 * validate the entity
                 */
                LogEntryTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    if (this.submitType.name === RuleConstantsService.logTriggerSubmitTypes.DATA_TYPE_SUBMIT.name) {
                        if (this.dataTypeId == null) {
                            errorMessage = 'Data type ID is not provided';
                        }
                        if (this.propertyId == null) {
                            errorMessage = 'Property ID is not provided';
                        }
                    } else {
                        if (this.form == null) {
                            errorMessage = 'Form is not selected';
                        }
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * schedule trigger model
                 ***************************************************************/
                ScheduleTriggerConditionModel.inheritsFrom(TriggerConditionModel);
                /**
                 * @constructor
                 */
                function ScheduleTriggerConditionModel(fixedTriggerNode) {
                    TriggerConditionModel.call(this, fixedTriggerNode);
                    this.type = RuleConstantsService.triggerType.SCHEDULE;
                    this.trigger = new ScheduleTriggerConditionConfigModel(this);
                }

                /***************************************************************
                 * schedule trigger config model
                 ***************************************************************/
                ScheduleTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);
                /**
                 * @constructor
                 */
                function ScheduleTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this.cronExpression = null;
                    this.recurrenceType = null;
                    this.delay = RuleConstantsService.UNAVAILABLE;

                    /**
                     * Period specific properties
                     */
                    this.ruleScheduleType = RuleConstantsService.ruleScheduleType.PROGRAM_SPECIFIC;
                    this.dayType = RuleConstantsService.ruleScheduleDayType.CALENDAR_DAY_USER_TIME_ZONE;
                    this.startPeriod = null;
                    this.endPeriod = null;
                    this.periodFrequency = null;
                    this.startAbsoluteTime = null;
                    this.endAbsoluteTime = null;
                    this.triggerMissedScheduleNow = false;

                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerScheduleDTO;
                }

                ScheduleTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.ruleScheduleType = this.ruleScheduleType.name;
                    dto.dayType = this.dayType.name;
                    dto.cronExpression = this.cronExpression;
                    dto.recurrenceType = this.recurrenceType;
                    dto.startPeriod = this.startPeriod;
                    dto.endPeriod = this.endPeriod;
                    dto.periodFrequency = this.periodFrequency;
                    dto.triggerMissedScheduleNow = this.triggerMissedScheduleNow;

                    dto.startAbsoluteTime = RuleUtilService.fromDateToUTCTimestamp(this.startAbsoluteTime);
                    dto.endAbsoluteTime = RuleUtilService.fromDateToUTCTimestamp(this.endAbsoluteTime);
                    return dto;
                };

                ScheduleTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);

                    this.ruleScheduleType = RuleConstantsService.getObjectByName(RuleConstantsService.ruleScheduleType, triggerDto.ruleScheduleType);
                    this.dayType = RuleConstantsService.getObjectByName(RuleConstantsService.ruleScheduleDayType, triggerDto.dayType);
                    this.cronExpression = triggerDto.cronExpression;
                    this.recurrenceType = triggerDto.recurrenceType;
                    this.startPeriod = triggerDto.startPeriod;
                    this.endPeriod = triggerDto.endPeriod;
                    this.periodFrequency = triggerDto.periodFrequency;
                    this.startAbsoluteTime = triggerDto.startAbsoluteTime;
                    this.endAbsoluteTime = triggerDto.endAbsoluteTime;
                    this.triggerMissedScheduleNow = triggerDto.triggerMissedScheduleNow;
                    this.startAbsoluteTime = RuleUtilService.fromUTCTimestampToDate(triggerDto.startAbsoluteTime);
                    this.endAbsoluteTime = RuleUtilService.fromUTCTimestampToDate(triggerDto.endAbsoluteTime);
                };

                ScheduleTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = this.dayType && this.dayType.text ? this.dayType.text : '';

                    function addCommaIfRequired() {
                        if (message != '') {
                            message += ', ';
                        }
                    }

                    if (this.startAbsoluteTime == null && this.endAbsoluteTime == null) {
                        message += ' | No Time Limit';
                    }
                    else {
                        if (this.startAbsoluteTime != null) {
                            message += ' | Start Date: ' + moment(this.startAbsoluteTime).format('DD-MM-YYYY HH:mm:ss');
                        }

                        if (this.endAbsoluteTime != null) {
                            addCommaIfRequired();
                            message += 'End Date: ' + moment(this.endAbsoluteTime).format('DD-MM-YYYY HH:mm:ss');
                        }
                    }

                    return message + ' | cron expression : (' + this.cronExpression + ')';
                };

                /**
                 * validate the schedule trigger
                 */
                ScheduleTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = null;

                    if (this.startAbsoluteTime !== null && this.endAbsoluteTime !== null && this.endAbsoluteTime < this.startAbsoluteTime) {
                        errorMessage = 'End date cannot be lower than start date';
                    }

                    if (this.cronExpression == null) {
                        errorMessage = 'Cron expression is not provided';
                    }

                    return errorMessage;
                };

                /***************************************************************
                 * user info change trigger model
                 ***************************************************************/
                UserInfoChangeTriggerConditionModel.inheritsFrom(TriggerConditionModel);
                /**
                 * @constructor
                 */
                function UserInfoChangeTriggerConditionModel(fixedTriggerNode) {
                    TriggerConditionModel.call(this, fixedTriggerNode);
                    this.type = RuleConstantsService.triggerType.USER_INFO_CHANGE;
                    this.trigger = null;
                }

                /***************************************************************
                 * user profile value change trigger config model
                 ***************************************************************/
                UserProfileValueChangeTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);
                /**
                 * @constructor
                 */
                function UserProfileValueChangeTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this.propertyId = null;
                    this.property = null;
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerUserProfileChangeDTO;
                }

                UserProfileValueChangeTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.propertyId = this.propertyId;
                    return dto;
                };

                UserProfileValueChangeTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.propertyId = triggerDto.propertyId;
                };

                UserProfileValueChangeTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    if (this.property != null) {
                        message += ' | property:' + this.property.name + '(' + this.property.id + ')';
                    }
                    else if (this.propertyId != null) {
                        message += ' | property id:' + this.propertyId;
                    }

                    return message;
                };

                UserProfileValueChangeTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    if (this.propertyId == null) {
                        errorMessage = 'Property is not specified';
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * User Profile Value Change trigger model
                 ***************************************************************/
                UserProfileValueChangeTriggerConditionModel.inheritsFrom(TriggerConditionModel);
                /**
                 * @constructor
                 */
                function UserProfileValueChangeTriggerConditionModel(fixedTriggerNode) {
                    TriggerConditionModel.call(this, fixedTriggerNode);
                    this.type = RuleConstantsService.triggerType.USER_PROFILE_VALUE_CHANGE;
                    this.trigger = new UserProfileValueChangeTriggerConditionConfigModel(this);
                }

                /***************************************************************
                 * Login Trigger
                 ***************************************************************/
                LoginTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function LoginTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.LOGIN;
                    this.trigger = new LoginTriggerConditionConfigModel(this);
                }

                LoginTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function LoginTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerLoginDTO;
                }

                /***************************************************************
                 * Address Update Trigger
                 ***************************************************************/
                AddressUpdateTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function AddressUpdateTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.ADDRESS_UPDATE;
                    this.trigger = new AddressUpdateTriggerConditionConfigModel(this);
                }

                AddressUpdateTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function AddressUpdateTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerAddressUpdateDTO;
                    this.addressType = RuleConstantsService.addressType.ACCOUNT;
                }

                AddressUpdateTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.addressType = this.addressType;
                    return dto;
                };

                AddressUpdateTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.addressType = triggerDto.addressType;
                };

                AddressUpdateTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    message += ' | ' + this.addressType;
                    return message;
                };

                /***************************************************************
                 * Order Update Trigger
                 ***************************************************************/
                OrderUpdateTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function OrderUpdateTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.ORDER_UPDATE;
                    this.trigger = new OrderUpdateTriggerConditionConfigModel(this);
                }

                OrderUpdateTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function OrderUpdateTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerOrderUpdateDTO;
                    this.orderType = Object.keys(ORDER_TYPES)[0];
                    this.orderStatusFrom = [];
                    this.orderStatusTo = [];
                }

                OrderUpdateTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.orderType = this.orderType;
                    dto.orderStatusFrom = this.orderStatusFrom;
                    dto.orderStatusTo = this.orderStatusTo;
                    return dto;
                };

                OrderUpdateTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.orderType = triggerDto.orderType;
                    this.orderStatusFrom = triggerDto.orderStatusFrom;
                    this.orderStatusTo = triggerDto.orderStatusTo;
                };

                OrderUpdateTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    message += ' | ' + this.orderType;
                    if (this.orderStatusFrom.length) {
                        message += ' | from: ' + this.orderStatusFrom.join(', ');
                    }
                    if (this.orderStatusTo.length) {
                        message += ' | to: ' + this.orderStatusTo.join(', ');
                    }
                    return message;
                };

                OrderUpdateTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    var from = this.orderStatusFrom;
                    var to = this.orderStatusTo;
                    if (from !== null && to !== null) {
                        from.forEach(function (entry) {
                            if (to.indexOf(entry) !== -1) {
                                errorMessage = 'From Order Status and To Order Status cannot have any matching values';
                                return;
                            }
                        });
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * Order Delivery Tracking Status Trigger
                 ***************************************************************/
                OrderDeliveryTrackingStatusTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function OrderDeliveryTrackingStatusTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.ORDER_TRACKING_UPDATE;
                    this.trigger = new OrderDeliveryTrackingStatusTriggerConditionConfigModel(this);
                }

                OrderDeliveryTrackingStatusTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function OrderDeliveryTrackingStatusTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerOrderTrackingUpdateDTO;
                    this.orderType = Object.keys(ORDER_TYPES)[0];
                    this.trackingType = ORDER_TYPES[this.orderType].orderTrackingTypeSummaryList[0].trackingTypeName;
                    this.trackingStatusFrom = [];
                    this.trackingStatusTo = [];
                }

                OrderDeliveryTrackingStatusTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.orderType = this.orderType;
                    dto.trackingType = this.trackingType;
                    dto.trackingStatusFrom = this.trackingStatusFrom;
                    dto.trackingStatusTo = this.trackingStatusTo;
                    return dto;
                };

                OrderDeliveryTrackingStatusTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.orderType = triggerDto.orderType;
                    this.trackingType = triggerDto.trackingType;
                    this.trackingStatusFrom = triggerDto.trackingStatusFrom || [];
                    this.trackingStatusTo = triggerDto.trackingStatusTo || [];
                };

                OrderDeliveryTrackingStatusTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    message += ' | ' + this.orderType;
                    message += ' | ' + this.trackingType;
                    if (this.trackingStatusFrom.length) {
                        message += ' | from: ' + this.trackingStatusFrom.join(', ');
                    }
                    if (this.trackingStatusTo.length) {
                        message += ' | to: ' + this.trackingStatusTo.join(', ');
                    }
                    return message;
                };

                OrderDeliveryTrackingStatusTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    var from = this.trackingStatusFrom;
                    var to = this.trackingStatusTo;
                    if (from !== null && to !== null) {
                        from.forEach(function (entry) {
                            if (to.indexOf(entry) !== -1) {
                                errorMessage = 'From Delivery Tracking Status and To Delivery Tracking Status cannot have any matching values';
                                return;
                            }
                        });
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * Deactivate Trigger
                 ***************************************************************/
                DeactivateTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function DeactivateTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.DEACTIVATE;
                    this.trigger = new DeactivateTriggerConditionConfigModel(this);
                }

                DeactivateTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function DeactivateTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerDeactivateDTO;
                }

                /***************************************************************
                 * Agreement Event Trigger
                 ***************************************************************/
                AgreementEventTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function AgreementEventTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.AGREEMENT_EVENT;
                    this.trigger = new AgreementEventTriggerConditionConfigModel(this);
                }

                AgreementEventTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function AgreementEventTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerAgreementEventDTO;
                    this.event = AgreementEvents.EXPIRED;
                    this._program = null;
                    this.programId = null;
                    this.agreementId = null;
                    this._agreement = null;
                }

                AgreementEventTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.event = this.event;
                    dto.programId = this.programId;
                    dto.agreementId = this.agreementId;
                    return dto;
                };

                AgreementEventTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.event = triggerDto.event;
                    this.programId = triggerDto.programId;
                    this.agreementId = triggerDto.agreementId;

                    ProgramLoadUtil.loadProgram(this);
                    AgreementLoadUtil.loagAgreement(this);
                };

                AgreementEventTriggerConditionConfigModel.prototype.getDescriptionAsHtml = function () {
                    var message = TriggerConditionConfigBaseModel.prototype.getDescriptionAsHtml.call(this);
                    var eventString = this.event === AgreementEvents.EXPIRED ? 'On Expiry' :
                        this.event === AgreementEvents.ABOUT_TO_EXPIRE ? 'In expiration window' : 'Not Specified';
                    if (this._agreement != null) {
                        message += ' || Agreement (' + this._agreement.id + ', ' + this._agreement.displayTitle.en +
                            ', ' + eventString + ' )';
                    }
                    return message;
                };

                AgreementEventTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    if (this.event == null) {
                        errorMessage = 'Event selection is required';
                    } else if (this.agreementId == null) {
                        errorMessage = 'Agreement selection is required';
                    }
                    return errorMessage;
                };

                AgreementEventTriggerConditionConfigModel.prototype.setProgram = function (program) {
                    this._program = program;
                    this.programId = program==null ? null : program.id;
                };

                AgreementEventTriggerConditionConfigModel.prototype.setAgreement = function (agreement) {
                    this._agreement = agreement;
                    this.agreementId = agreement==null ? null : agreement.id;
                };

                AgreementEventTriggerConditionConfigModel.prototype.getProgram = function () {
                    return this._program;
                };

                AgreementEventTriggerConditionConfigModel.prototype.getAgreement = function () {
                    return this._agreement;
                };

                /***************************************************************
                 * VXP Event Trigger
                 ***************************************************************/
                VXPEventTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function VXPEventTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.VXP_EVENT;
                    this.trigger = new VXPEventTriggerConditionConfigModel(this);
                }

                VXPEventTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function VXPEventTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerVXPEventDTO ;
                    this.event = RuleConstantsService.VXPEvents.CREATE_TRACK_ORDER_RESPONSE;
                }

                VXPEventTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.event = this.event;
                    return dto;
                };

                VXPEventTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.event = triggerDto.event;
                };

                /***************************************************************
                 * PCI Dismiss Status Change Trigger
                 ***************************************************************/
                PCIDismissStatusChangeTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function PCIDismissStatusChangeTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.PCI_DISMISS_STATUS_CHANGE;
                    this.trigger = new PCIDismissStatusChangeTriggerConditionConfigModel(this);
                    this.containerId = null;
                    this.containerItemId = null;
                    this.dismissStatusAfterAction = false;

                    this._container = null;
                    this._containerItem = null;
                }

                PCIDismissStatusChangeTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function PCIDismissStatusChangeTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerPCIDismissStatusChangeDTO ;
                    this.dismissStatusAfterAction = RuleUtilService.ruleConstants.PCIDismissStatus.REINSTATE;
                }

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.toDto = function () {
                    var dto = TriggerConditionConfigBaseModel.prototype.toDto.call(this);
                    dto.containerItemId = this.containerItemId;
                    dto.dismissStatusAfterAction = dismissStatusActionToDto(
                        RuleUtilService.ruleConstants.PCIDismissStatus, this.dismissStatusAfterAction);
                    return dto;
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.fromDto = function (triggerDto) {
                    TriggerConditionConfigBaseModel.prototype.fromDto.call(this, triggerDto);
                    this.containerItemId = triggerDto.containerItemId;
                    this.dismissStatusAfterAction = dismissStatusActionFromDto(triggerDto.dismissStatusAfterAction);

                    ContainerItemLoadUtil.loadContainerItem(this);
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.getContainer = function() {
                    return this._container;
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.setContainer = function(newContainer) {
                    this._container = newContainer;
                    this.containerId = newContainer ? newContainer.id : null;
                    if (this._containerItem && this._container !== this._containerItem._parent) {
                        // we are changing container, we should clear the container item if it's not for the same parent
                        this._containerItem = null;
                        this.containerItemId = null;
                    }
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.getProgramId = function() {
                    const rootElement = this.getRoot();
                    return rootElement==null ? null : rootElement.programId;
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.getContainerItem = function() {
                    return this._containerItem;
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.setContainerItem = function(newContainerItem) {
                    this._containerItem = newContainerItem;
                    this.containerItemId = newContainerItem == null ? null : newContainerItem.id;
                    this.formId = newContainerItem == null || newContainerItem.form == null ? null : newContainerItem.form.id;
                    if (this._containerItem && this._container !== this._containerItem._parent) {
                        this._container = this._containerItem._parent;
                    }
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype.setDismissStatusAfterAction = function (dismissStatusAfterAction) {
                    this.dismissStatusAfterAction = dismissStatusAfterAction;
                };

                PCIDismissStatusChangeTriggerConditionConfigModel.prototype._validateSelf = function () {
                    var errorMessage = TriggerConditionConfigBaseModel.prototype._validateSelf.call(this);
                    if (this._container == null) {
                        errorMessage = 'Container should be selected';
                    } else if (this._containerItem == null) {
                        errorMessage = 'Container Item should be selected';
                    } else if (this.dismissStatusAfterAction == null) {
                        errorMessage = 'Dismiss Status Action is required';
                    }
                    return errorMessage;
                };

                /***************************************************************
                 * Link child Account Added Trigger
                 ***************************************************************/
                LinkChildAccountAddedTriggerConditionModel.inheritsFrom(TriggerConditionModel);

                function LinkChildAccountAddedTriggerConditionModel(parent) {
                    TriggerConditionModel.call(this, parent);
                    this.type = RuleConstantsService.triggerType.LINKED_CHILD_ACCOUNT_ADDED;
                    this.trigger = new LinkChildAccountAddedTriggerConditionConfigModel(this);
                }

                LinkChildAccountAddedTriggerConditionConfigModel.inheritsFrom(TriggerConditionConfigBaseModel);

                function LinkChildAccountAddedTriggerConditionConfigModel(parent) {
                    TriggerConditionConfigBaseModel.call(this, parent);
                    this[RuleConstantsService.trigger.classes.classReferenceKey] = RuleConstantsService.trigger.classes.TriggerLinkedChildAccountAddedDTO;
                }

                /***************************************************************
                 * trigger type utility model that include icon, constructor, etc
                 ***************************************************************/
                /**
                 * Method to create trigger model for give trigger type
                 * @returns {*}
                 */
                function createTriggerModelFromDto(dto, fixedTriggerNode) {
                    var trigger = null;
                    if (dto != null) {
                        var type = dto.triggerType;
                        if (type === RuleConstantsService.triggerType.SIGN_UP.name) {
                            trigger = new SignUpTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.SCHEDULE.name) {
                            trigger = new ScheduleTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.LOG_ENTRY.name) {
                            trigger = new LogEntryTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.USER_INFO_CHANGE.name) {
                            trigger = new UserInfoChangeTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.USER_PROFILE_VALUE_CHANGE.name) {
                            trigger = new UserProfileValueChangeTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.LOGIN.name) {
                            trigger = new LoginTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.ADDRESS_UPDATE.name) {
                            trigger = new AddressUpdateTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.ORDER_UPDATE.name) {
                            trigger = new OrderUpdateTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.ORDER_TRACKING_UPDATE.name) {
                            trigger = new OrderDeliveryTrackingStatusTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.DEACTIVATE.name) {
                            trigger = new DeactivateTriggerConditionModel(fixedTriggerNode);
                        } else if (type === RuleConstantsService.triggerType.AGREEMENT_EVENT.name) {
                            trigger = new AgreementEventTriggerConditionModel(fixedTriggerNode);
                        } else if(type === RuleConstantsService.triggerType.VXP_EVENT.name) {
                            trigger = new VXPEventTriggerConditionModel(fixedTriggerNode);
                        } else if(type === RuleConstantsService.triggerType.PCI_DISMISS_STATUS_CHANGE.name) {
                            trigger = new PCIDismissStatusChangeTriggerConditionModel(fixedTriggerNode);
                        } else if(type === RuleConstantsService.triggerType.LINKED_CHILD_ACCOUNT_ADDED.name) {
                            trigger = new LinkChildAccountAddedTriggerConditionModel(fixedTriggerNode);
                        }

                    }

                    if (trigger != null) {
                        trigger.fromDto(dto);
                    }

                    return trigger;
                }

                function TriggerTypeModel(triggerType, newInstanceFunction) {
                    this.name = triggerType.name;
                    this.icon = triggerType.icon;
                    this.text = triggerType.text;
                    this.newInstance = newInstanceFunction;
                }

                function buildTriggerTypes() {
                    return {
                        commonTriggerTypes: {
                            name: 'Available to all rules',
                            availableToNonProgramSpecificRules: true,
                            availableToProgramSpecificRules: true,
                            availableToPeriodSpecificRules: true,
                            triggerTypes: [
                                new TriggerTypeModel(RuleConstantsService.triggerType.SCHEDULE, function (fixedTriggerNode) {
                                    return new ScheduleTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.LOG_ENTRY, function (fixedTriggerNode) {
                                    return new LogEntryTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.USER_INFO_CHANGE, function (fixedTriggerNode) {
                                    return new UserInfoChangeTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.USER_PROFILE_VALUE_CHANGE, function (fixedTriggerNode) {
                                    return new UserProfileValueChangeTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.LOGIN, function (fixedTriggerNode) {
                                    return new LoginTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.ADDRESS_UPDATE, function (fixedTriggerNode) {
                                    return new AddressUpdateTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.ORDER_UPDATE, function (fixedTriggerNode) {
                                    return new OrderUpdateTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.ORDER_TRACKING_UPDATE, function (fixedTriggerNode) {
                                    return new OrderDeliveryTrackingStatusTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.DEACTIVATE, function (fixedTriggerNode) {
                                    return new DeactivateTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.AGREEMENT_EVENT, function (fixedTriggerNode) {
                                    return new AgreementEventTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.VXP_EVENT, function (fixedTriggerNode) {
                                    return new VXPEventTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.PCI_DISMISS_STATUS_CHANGE, function (fixedTriggerNode) {
                                    return new PCIDismissStatusChangeTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.SIGN_UP, function (fixedTriggerNode) {
                                    return new SignUpTriggerConditionModel(fixedTriggerNode);
                                }),
                                new TriggerTypeModel(RuleConstantsService.triggerType.LINKED_CHILD_ACCOUNT_ADDED, function (fixedTriggerNode) {
                                    return new LinkChildAccountAddedTriggerConditionModel(fixedTriggerNode);
                                })
                            ]
                        },
                    };
                }

                // create trigger types object
                var triggerTypes = buildTriggerTypes();

                function findTriggerTypeModel(triggerType, modelArray){
                    var found = null;
                    modelArray.forEach(function (m) {
                        if (m.name === triggerType.name) {
                            found = m;
                            return;
                        }
                    });
                    return found;
                }

                function getTriggerTypeModelByType(triggerType) {
                    if(triggerType == null){
                        return null;
                    }

                    // check in common types
                    var model = findTriggerTypeModel(triggerType, triggerTypes.commonTriggerTypes.triggerTypes);
                    if(model != null){
                        return model;
                    }
                }

                /**
                 * Method to map PCI Dismiss Status Action toDto
                 */
                function dismissStatusActionToDto(dismissStatus, action) {
                    switch (action) {
                        case dismissStatus.DISMISS:
                            return true;
                        case dismissStatus.REINSTATE:
                            return false;
                        case dismissStatus.ALL:
                            return null;
                        default:
                            return null;
                    }
                }

                /**
                 * Method to map PCI Dismiss Status Action fromDto
                 */
                function dismissStatusActionFromDto(dismissStatusAction) {
                    var dismissStatus = RuleUtilService.ruleConstants.PCIDismissStatus;
                    if (dismissStatusAction === true) {
                        return dismissStatus.DISMISS;
                    } else if (dismissStatusAction === false) {
                        return dismissStatus.REINSTATE;
                    }  else {
                        return dismissStatus.ALL;
                    }
                }

                /***************************************
                 * service return call
                 ***************************************/

                return {
                    getTriggerTypes: function () {
                        return triggerTypes;
                    },
                    getTriggerTypeModelByType: getTriggerTypeModelByType,
                    createTriggerModelFromDto: createTriggerModelFromDto
                };
            });

})();
