/**
 * Created by Ryan Berry on 12/7/22
 *
 * Directive for configuring which forms should be returned for the Data Reference
 * Return of Results container type.
 */

import template from './dataReferenceConfig.template.html';

angular.module('acadiamasterApp')
    .directive('containerDataReferenceConfig', () => {
        return {
            bindToController : true,
            controller       : 'DataReferenceConfigController',
            controllerAs     : 'drc',
            restrict         : 'E',
            scope            : {
                container : '=',
                programId : '<',
            },
            template,
        };
    });
