/**
 * Created by shekhar.sukhadeve on 10-04-2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * Directive for common form field value for calendar.
     */
        .directive('formFieldConfigFieldValueCalendar', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: "admin-templates/site/forms/configPanel/fieldValue/dateConfigOption.html",
                scope: {
                    dateValue: '=',
                    dateValueChange: '=',
                    fieldValue: '=',
                    fieldElement: '=',
                    title:"@"
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                },
                controller: function($scope) {
                    $scope.dateFormats = FormConstants.dateFormatsList;
                    $scope.selectedFormat = $scope.fieldValue.dateFormat?$scope.dateFormats.find(ele=>ele.value===$scope.fieldValue.dateFormat) : $scope.dateFormats[0];
                    $scope.onValueChanged = ()=>{
                        $scope.fieldValue.dateFormat = $scope.selectedFormat.value;
                    }
                }
            };
        });
})();