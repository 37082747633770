/**
 * Created by Jamie Nola on 03/26/2018
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ParticipantManagementController', function ($scope, TILE_CONSTANTS) {
            $scope.data = {
                tileCollectionId: TILE_CONSTANTS.ID.PARTICIPANT
            };
        });

})();
