(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formConfigNumericInput', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/numericInputCommon.html',
                scope: {
                    title: '@',
                    name: '@?',
                    hintText: '@',
                    includeHelp: '=',
                    inputModel: '=',
                    changeModel: '=',
                    disabled: '='
                },
                link: function ($scope) {
                    $scope.hasChangeModel = $scope.changeModel !== undefined;

                    if ($scope.hintText == null) {
                        $scope.hintText = '';
                    }

                    $scope.data = {
                        inputModel: $scope.inputModel,
                        changeModel: $scope.changeModel,
                        makingChange: $scope.hasChangeModel && $scope.changeModel != null
                    };

                    $scope.$watch('data.inputModel', function (newValue) {
                        $scope.inputModel = newValue;
                    });

                    $scope.$watch('data.changeModel', function (newValue) {
                        $scope.changeModel = newValue;
                    });

                    $scope.resetChange = function () {
                        $scope.data.makingChange = false;
                        $scope.data.changeModel = null;
                    };

                }
            };
        });

})();

