(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .constant('SCHEDULE_RULE_EXECUTION_AUDIT_CONSTANTS', function () {
            const FIELDS = {
                BUSINESS_RULE_ID: {
                    id: 'businessRuleId',
                    sortable: true,
                    isBRLink: true
                },
                BUSINESS_RULE_NAME: {
                    id: 'businessRuleName',
                    isHidden: true
                },
                EXPRESSION_ID: {
                    id: 'evaluationExpressionId',
                    sortable: true
                },
                FAILED_PAGE_COUNT: {
                    id: 'failedPageCount'
                },
                ID: {
                    id: 'id',
                    sortable: true,
                    isIndex: true
                },
                PROGRAM_ID: {
                    id: 'programId'
                },
                SCHEDULE_NAME: {
                    id: 'scheduleName',
                    sortable: true
                },
                TOTAL_NUMBER_OF_PAGES: {
                    id: 'totalNumberOfPages'
                },
                UPDATED_ON: {
                    id: 'updatedOn',
                    sortable: true,
                    isDate: true
                },
                VIEW_PAGES: {
                    id: 'viewPages',
                    isLink: true
                },
                PAGE_NUMBER: {
                    id: 'pageNumber',
                    sortable: true,
                },
                PUBLISHED_ON: {
                    id: 'publishedOn',
                    sortable: true,
                    isDate: true
                },
                EXECUTION_STARTED_ON: {
                    id: 'executionStartedOn',
                    sortable: true,
                    isDate: true
                },
                EXECUTION_FINISHED_ON: {
                    id: 'executionFinishedOn',
                    sortable: true,
                    isDate: true
                },
                TOTAL_NUMBER_OF_USERS: {
                    id: 'totalNumberOfUsers'
                },
                NUMBER_OF_FAILED_USERS: {
                    id: 'numberOfFailedUsers',
                    sortable: true
                },
                VIEW_WORKFLOW_INSTANCE_AUDITS: {
                    id: 'viewWorkflowInstanceAudits',
                    isLink: true
                }
            };

            return {
                FIELDS: FIELDS
            };
        }());
})();
