(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    /**
     * rule detail directive
     */
    app.directive('vbrRuleDetail', function (BusinessRule, vbrCommonUtil, RuleModelService,
        FlowChartKmapService, RuleToKmapService, ngDialog, AlertService, $window, CONFIG) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/ruleDetail/rule-detail.html',
            scope: {
                selectedRule: '=',
                isReadOnly: '=?',
                detailedView: '='
            },
            link: function ($scope) {
                $scope.CONFIG = CONFIG;

                $scope.deletePopup = function (id) {
                    var _scope = $scope;

                    ngDialog.openConfirm({
                        template: '<delete-business-rule rule-id="ruleId" on-success-callback="onSuccess"></delete-business-rule>',
                        plain: true,
                        scope: $scope,
                        controller: ['$scope', function ($scope) {
                            $scope.ruleId = id;
                            $scope.onSuccess = function () {
                                initRule(_scope);
                            };
                        }]
                    }).then(function () {
                        initRule(_scope);
                    });
                };

                $scope.openFlowChartWindow = function(ruleId) {
                    var ruleDetail = BusinessRule.get({
                        id: ruleId
                    });
                    ruleDetail.$promise.then(function (data) {
                        var url = '/assets/kmap/ruleMap/rule.html';
                        var newWindow = $window.open(url, 'rule_flow_chart_window');
                        var model = RuleToKmapService.transform(data);
                        FlowChartKmapService.loadRuleModel(newWindow, model, 'TB', null, null);
                    });
                };

                $scope.download = function (ruleId) {
                    var ruleDetail = BusinessRule.get({
                        id: ruleId
                    });
                    ruleDetail.$promise.then(function (data) {
                        // create file name
                        var fileName = 'rule_' + ruleId
                            + '.json';
                        // download as file in browser
                        vbrCommonUtil.downloadAsFile(fileName, data);
                    });
                };

                $scope.$watch('selectedRule', function (newValue, oldValue) {
                    if (!_.isEqual(newValue, oldValue)) {
                        initRule($scope);
                    }
                });
            }
        };

        function initRule($scope) {
            $scope.selectedRuleModel = null;
            if ($scope.selectedRule) {
                loadBusinessRule($scope, $scope.selectedRule.id)
            } else {
                $scope.selectedRuleModel = null;
            }
        }

        function loadBusinessRule($scope, ruleId) {
            BusinessRule.get({
                id: ruleId
            }).$promise.then(function (data) {
                $scope.selectedRuleModel = new RuleModelService.RuleModel(data.type, data.programId);
                $scope.selectedRuleModel.fromDto(data);
            }).catch(function (err) {
                AlertService.error('Failed to fetch rule details for rule id: ' + ruleId);
            });
        }
    });

})();
