(function () {
    'use strict';

    /**
     * directive for configuration of value comparison, it consists of a comparator and a value
     */
    angular.module('acadiamasterApp').directive('valueComparisonConfig',
        function (ValueType, ComparisonOperatorService, ValueComparisonService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/common/directive/valueComparison/valueComparison.html',
                scope: {
                    options: '='  // a value comparison option object
                },
                link: function ($scope) {
                    $scope.ValueType = ValueType;
                    $scope.data = {
                        operator: $scope.options.getOperator(),
                        values: $scope.options.getValues(),
                        possibleValues: ValueComparisonService.convertToArrayIfNeeded(
                            $scope.options.getPossibleValues(), $scope.options.getValues()),
                        regexPattern: $scope.options.regexPattern,
                        regexErrorMsg: $scope.options.regexErrorMsg
                    };

                    $scope.flags = {
                        hasPossibleValues: $scope.data.possibleValues != null && $scope.data.possibleValues.length > 0,
                        hasRegex: $scope.options.hasRegex()
                    };

                    $scope.getAllOperators = function () {
                        return getAllOperators($scope.options);
                    };

                    $scope.getValueType = function () {
                        return $scope.options.getValueType();
                    };

                    $scope.isValueTypeNumber = function () {
                        var valueType = $scope.getValueType();
                        return valueType == ValueType.NUMBER || valueType == ValueType.DATETIME;
                    };

                    $scope.shouldShowValueField = function () {
                        return $scope.options.getOperator() != ComparisonOperatorService.operators.IS_NULL;
                    };

                    $scope.isInComparator = function () {
                        return $scope.options.getOperator() == ComparisonOperatorService.operators.IN;
                    };

                    $scope.showDescription = function () {
                        if($scope.options.showDescription){
                            return $scope.options.showDescription();
                        }
                        return false;
                    };

                    addWatches($scope);
                }
            };

            function getAllOperators(valueComparisonOption) {
                if (valueComparisonOption == null || !valueComparisonOption.canConfigureOperator) {
                    return null;
                }

                var valueType = valueComparisonOption.getValueType();
                var supportPossibleValues = valueComparisonOption.supportGetPossibleValues();

                return ComparisonOperatorService.getOperatorsByType(valueType, supportPossibleValues);
            }

            function addWatches($scope) {
                if ($scope.options.supportSetOperator()) {
                    $scope.$watch('data.operator', function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            $scope.options.setOperator(newValue);
                            $scope.data.values = $scope.options.getValues();
                        }
                    });
                }

                if ($scope.options.supportSetValues()) {
                    // deep watch the data values
                    $scope.$watch('data.values', function (newValues) {
                        $scope.options.setValues(newValues);
                    }, true);
                }
            }
        });
})();
