/**
 * Created by jason.cao on 2/03/2020
 * directive for displaying a single operation history messages
 **/
angular.module('acadiamasterApp').directive('operationHistoryMessage', function () {
    return {
        restrict: 'E',
        templateUrl: 'admin-templates/site/tools/operationHistory/messages/operationHistoryMessage.html',
        scope : {
            operationHistoryMessage : '='
        },
    };
});
