/**
 * Created by Jamie Nola on 03/04/2019
 */

(function () {
    angular.module('acadiamasterApp')
        .controller('OrderManagementController', function ($timeout, ORDER_TYPES) {
            let vm = this;

            function init () {
                vm.orderTypes = Object.keys(ORDER_TYPES).map(
                    orderKey => {
                        return ORDER_TYPES[orderKey];
                    });
            }

            $timeout(init);
        });
}());
