/**
 * Created by Danny on 2/3/2016
 */
(function () {

    'use strict';

    angular.module('acadiamasterApp')
        .controller('ProgramCategoryCreateEditController',
            function ($scope, $state, $location, AlertService, vbrCommonUtil, isNew, entity, ProgramCategory,
                      User, ProgramService, LocaleConstantsService, $window, CONFIG, FileConverterService) {
                $scope.CONFIG = CONFIG;
                $scope.programCategory = entity;
                $scope.isReady = isNew;
                // Resolve entity
                if (!isNew && $scope.programCategory != null) {
                    $scope.programCategory.$promise.then(function () {
                        $scope.isReady = true;
                    });
                }

                $scope.users = User.query();
                $scope.programs = ProgramService.query();
                $scope.lcs = LocaleConstantsService;
                $scope.load = function (id) {
                    ProgramCategory.get({id: id}, function (result) {
                        $scope.programCategory = result;
                    });
                };

                var onSaveFinished = function (result) {
                    $scope.$emit('acadiamasterApp:programCategoryUpdate', result);
                    $state.go('programCategory');
                };

                var onSaveError = function (result) {
                    $scope.$emit('acadiamasterApp:programCategoryUpdate', result);
                };

                $scope.save = function () {
                    if ($scope.programCategory.id != null) {
                        ProgramCategory.updateProgramCategory($scope.programCategory, onSaveFinished, onSaveError);

                    } else {
                        ProgramCategory.createProgramCategory($scope.programCategory, onSaveFinished, onSaveError);

                    }
                };

                $scope.clear = function () {
                    $state.go('programCategory');
                };
            });


})();
