'use strict';

angular.module('acadiamasterApp')
    .controller('ExecLogHistoryAuditController', function ($state, $stateParams, ParseLinks, execLogHistoryAudits) {

        var vm = this;

        vm.searchData = {
            schedName: $stateParams.schedName || null,
            jobGroup: $stateParams.jobGroup|| null,
            jobName: $stateParams.jobName || null,
            startDate: $stateParams.startDate || null,
            endDate: $stateParams.endDate || null,
            searchResultCount: 0,
            page: parseInt($stateParams.page,10) || 0
        };

        vm.links = ParseLinks.parse(execLogHistoryAudits.responseHeaders('link'));
        vm.searchData.searchResultCount = execLogHistoryAudits.responseHeaders('X-Total-Count');

        vm.execAudits = execLogHistoryAudits;

        vm.loadAll = function(page) {
            if (page == null) {
                vm.searchData.page = 0;
            } else {
                vm.searchData.page = page;
            }

            $state.go('execDetailsAudit', {
                'schedName': $stateParams.schedName,
                'jobGroup': $stateParams.jobGroup,
                'jobName': $stateParams.jobName,
                'page': vm.searchData.page
            });
        };

        vm.loadPage = function (page) {
            vm.loadAll(page);
        };

        execLogHistoryAudits.forEach(function (execAudit) {
            execAudit.hasError = execAudit.eventType =='JOB_EXECUTION_FAILED' || execAudit.eventType =='TRIGGER_MISFIRED' ;
        });

        vm.execAudits = execLogHistoryAudits;

    });
