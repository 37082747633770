/**
 */

'use strict';

angular.module('acadiamasterApp')
    .controller('ProgramDialogController',
        function ($location, $scope, $rootScope, $state, $stateParams, entity, ProgramService, User, Organization, ProgramCategory,CONFIG) {
            $scope.CONFIG=CONFIG;

            $scope.program = entity;
            $scope.organizations = Organization.query();
            $scope.programCategories = ProgramCategory.query();

            var onSaveFinished = function (result) {
                $scope.$emit('acadiamasterApp:programUpdate', result);
                $state.go('program');
            };

            $scope.save = function () {
                ProgramService.save($scope.program, onSaveFinished);
            };

            $scope.validateProgramName = function () {
                var promise = ProgramService.isNameAvailable($scope.program.name, $scope.program.id);
                promise.then(function (response) {
                    if (response.data) {
                        $scope.editForm.name.$setValidity('vbrProgramAvailable', true);
                    } else {
                        $scope.editForm.name.$setValidity('vbrProgramAvailable', false);
                    }
                });
            }
        });
