import ProgramContainerConstantService from "../const/programContainer.constants.service";

/**
 * container tree options service for edit GUI
 */
(function () {
    'use strict';
    angular.module('acadiamasterApp').factory('ContainerTreeOptionsService', function (VbrTreeUtilService) {

        const nodeType = ProgramContainerConstantService.nodeType;
        const nodeTypeAcceptableMap = createNodeTypeAcceptableMap();

        return {
            accept: accept,
            dropped: dropped
        };

        /******************************************************
         * private functions after this
         ******************************************************/

        function getParentModel(nodeScope) {
            if (nodeScope == null || nodeScope.$nodeScope == null) {
                return null;
            }

            return nodeScope.$nodeScope.$modelValue;
        }

        function getCurrentModel(nodeScope) {
            if (nodeScope == null || nodeScope.$modelValue == null) {
                return null;
            }

            return nodeScope.$modelValue;
        }

        /**
         * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
         * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
         * @param sourceNodeScope
         * @param destNodeScope
         * @returns {boolean}
         */
        function accept(sourceNodeScope, destNodeScope) {
            // do not delete the commented out lines, this part of the code is often broken when changes are
            // made, and those console statements are useful when that happens.  Those calls happen at a
            // very high frequency, debugger with break point is not suitable for that

            const sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
            const destParentModel = getParentModel(destNodeScope);
            const destType = destParentModel==null ? null : destParentModel.nodeType;

            const sourceModel = getCurrentModel(sourceNodeScope);

            // const destNodeModel = getCurrentModel(destNodeScope);
            // console.log('accept(), source type =' + sourceType + ", destType : " + destType,
            //     "destNodeModel : ", destNodeModel, 'sourceModel : ', sourceModel);

            const typesCanMoveUnder = nodeTypeAcceptableMap[sourceType].typesCanMoveUnder;

            if (destType == null) {
                // this is the top level, so destType is null, only container is allowed to move at this level
                return sourceType == nodeType.CONTAINER;
            }

            if (typesCanMoveUnder!=null && typesCanMoveUnder.indexOf(destType) != -1) {
                // type wise it is allowed, now check if it is really allowed to move under
                return canMoveItemUnderContainer(sourceModel, destParentModel);
            }

            return false;
        }

        /**
         * check if the container item is allowed to move under the container using type check
         * @param containerItem - container item model about to be moved
         * @param destContainer - dest container
         * @return {boolean} true if allowed to move, false otherwise
         */
        function canMoveItemUnderContainer(containerItem, destContainer) {
            if (destContainer==null) {
                console.error('dest container is null when it should not be');
                return false;
            }

            if (containerItem._parent == destContainer) {
                // move within the same parent is always allowed
                return true;
            }

            // todo: we should pass in the container item and check if the item type is allowed under the container item type
            return destContainer.canAddItem(containerItem);
        }

        /**
         * event dropped is called after node moves it's position
         * @param event
         */
        function dropped(event) {
            const sourceModel = event.source.nodeScope.$modelValue;

            if (event.dest.nodesScope.$nodeScope == null) { // this is a top level node
                return;
            }

            const destModel = event.dest.nodesScope.$nodeScope.$modelValue;

            if (sourceModel._parent != destModel) {
                sourceModel._parent = destModel;
            }

            if (destModel!=null && _.isFunction(destModel.getRoot)) {
                destModel.getRoot().validate();
            }
        }

        function createNodeTypeAcceptableMap() {
            const map = {};
            map[nodeType.CONTAINER_ITEM] = VbrTreeUtilService.createNodeTypeItem(nodeType.CONTAINER_ITEM, [nodeType.CONTAINER]);
            map[nodeType.CONTAINER] = VbrTreeUtilService.createNodeTypeItem(nodeType.CONTAINER, null);

            return map;
        }
    });
})();

