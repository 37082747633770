/**
 * directive to preview a screen shot
 * it will add some opacity and allow for collapse/expand
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('previewScreenShot', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/previewScreenShot.html',
            scope: {
                previewUrl: '@',
                title : '@'
            },
            link: function ($scope) {
                $scope.data = {
                    isOpen : false,
                };

                $scope.toggle = () => {
                    $scope.data.isOpen = !$scope.data.isOpen;
                };

                $scope.isOpen = () => {
                    return $scope.data.isOpen;
                };

                $scope.getTitle = () => {
                    if ($scope.title!=null && $scope.title.length>0) {
                        return $scope.title;
                    }
                    else {
                        return "Screen Shot Preview";
                    }
                };
            }
        };
    });

})();

