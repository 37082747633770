export const mockOrder = {
    address : {
        city       : 'Sun City',
        country    : 'US',
        line1      : '10401 W Thunderbird Blvd',
        line2      : null,
        postalCode : '85351',
        state      : 'AZ',
    },
    attributes : {
        BARCODE_1D     : '1698322522024',
        FULFILLMENT_ID : '33787d58-736d-4542-8e98-a70b03cad099',
    },
    createdOn : 1698316966524,
    id        : 1,
    orderType : {
        active         : true,
        id             : 1,
        orderType      : 'Salivary',
        orderTypeCode  : 'Salivary',
        supplierCode   : null,
        supportedModes : [
            'PARTICIPANT_TRACKING',
            'RETURN_TRACKING',
            'SUPPLIER_ORDER',
        ],
    },
    product : {
        active : true,
        code   : 'SAL1',
        id     : 2,
        name   : 'Genotek Salivary kit',
        sku    : '4810A',
    },
    programId   : 106,
    programName : 'All of US',
    quantity    : 1,
    status      : 'COMPLETED',
    subStatus   : 'RETURN_DELIVERED',
    supplier    : {
        code : 'GENOTEK',
        id   : 1,
        name : 'GENOTEK',
    },
    supplierOrderId : '92416',
    tenantId        : 41,
    trackings       : {
        PARTICIPANT_TRACKING : {
            carrierCode  : 'USPS',
            deliveredOn  : 1698350809432,
            shippedOn    : 1698350569157,
            status       : 'DELIVERED',
            trackingId   : 'ca3cd6e4-04e9-4d80-ace0-ab976e8cc7eb',
            trackingType : 'PARTICIPANT_TRACKING',
        },
        RETURN_TRACKING : {
            carrierCode  : 'USPS',
            deliveredOn  : 1698351169861,
            shippedOn    : 1698350929558,
            status       : 'DELIVERED',
            trackingId   : '708febb5-4998-4481-9d21-fe60ee3224d8',
            trackingType : 'RETURN_TRACKING',
        },
    },
    updatedOn  : 1698351169865,
    userId     : 299037,
    workflowId : '9b884713-ea5c-46b6-9163-118a745a9fd5',
};

export const mockOrderWithTrackingEmpty = {
    ...mockOrder,
    trackings : {
        PARTICIPANT_TRACKING : {
            trackingId : null,
        },
        RETURN_TRACKING : {
            trackingId : null,
        },
    },
};

export const mockCancellationTypes = {
    data : {
        cancellationTypes : [
            {
                cancellationType            : 'ORDER_INCOMPLETE_ADDRESS',
                cancellationTypeDisplayText : 'Failed delivery - Incomplete address',
                id                          : 1,
            },
            {
                cancellationType            : 'ORDER_WRONG_ADDRESS',
                cancellationTypeDisplayText : 'Failed delivery - Wrong address',
                id                          : 2,
            },
            {
                cancellationType            : 'ORDER_OTHER',
                cancellationTypeDisplayText : 'Other',
                id                          : 3,
            },
        ],
    },
};
