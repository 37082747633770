import template from './modal.notification.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionModalNotification', function (RuleUtilService) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    action: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.programId = $scope.action.getRoot().programId;
                    $scope.cs = RuleUtilService.ruleConstants;

                    $scope.isReady = false;

                    $scope.form = {};
                    $scope.form.options = {
                        form: $scope.action.form,
                        title: 'Form',
                        viewOnly: false,
                    };
                    $scope.isReady = true;
                    $scope.discription = "If Dismissible flag is set to true, pop-up form will allow participant to close modal by using \"x\" to close button and proceed to the configured post action navigation.  Else if set to false, when clicking on \"x\" to close button of pop-up form will end participant's session and log them out.  In current iteration, system does not support pausing form for the pop-up form action but will be implemented in the future.";
                    $scope.$watch('form.options.form', function (newForm) {
                        $scope.action.form = newForm;
                    });
                }
            };
        });
})();