'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('program.manage.asset', {
                parent: 'program.manage',
                url: '/serverAssets/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programAssetManagement.detail.title'
                    },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/program/assetManagement/programAssetManagement.html',
                        controller: 'programAssetManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('programAssetManagement');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProgramAssetManagement', function ($stateParams, ProgramAssetManagement) {
                        return ProgramAssetManagement.query({ id: $stateParams.id });
                    }]
                }
            });
    });
