/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp').directive('vbrDrcWithdraw', (drcService, AlertService, WithdrawalReasons) => {
        /** *************************************
         * directive return call
         ***************************************/
        return {
            link : function ($scope) {
                $scope.lockUserId = $scope.userId !== undefined;
                $scope.lockParticipantId = $scope.participantId !== undefined;
                $scope.data = {
                    isReady                       : false,
                    lastWithdrawnStatus           : null,
                    participantIdToWithdraw       : $scope.participantId,
                    userIdToWithdraw              : $scope.userId,
                    withdrawalReason              : WithdrawalReasons.UNSET,
                    withdrawalReasonJustification : '',
                    withdrawalReasons             : WithdrawalReasons,
                };

                $scope.withdrawParticipant = function () {
                    withdrawParticipant($scope);
                };
            },
            restrict : 'E',
            scope    : {
                onWithdrawSuccess : '<?',
                participantId     : '<?',
                userId            : '<?',
            },
            templateUrl : 'admin-templates/site/drc/withdraw/drcWithdraw.html',
        };

        /*
         * Try to withdraw participant
         * @param $scope scope object
         */
        function withdrawParticipant ($scope) {
            let participantId = $scope.data.participantIdToWithdraw;
            let userId = $scope.data.userIdToWithdraw;
            let withdrawalReason = $scope.data.withdrawalReason;
            let withdrawalReasonJustification = $scope.data.withdrawalReasonJustification;
            if (participantId == null) {
                AlertService.warning('participantId id can not be null');
                return;
            } else if (withdrawalReason == null) {
                AlertService.warning('withdrawalReason cannot be null');
                return;
            }

            drcService.withdraw(participantId, userId, withdrawalReason, withdrawalReasonJustification)
                .then(() => {
                    let status = `Participant with id ${ participantId } withdrawn successfully`;
                    AlertService.success(status);
                    $scope.data.lastWithdrawnStatus = status;
                    if ($scope.onWithdrawSuccess) {
                        $scope.onWithdrawSuccess();
                    }
                }).catch(errResponse => {
                    let status = `Failed to withdraw participant with id ${ participantId}`;
                    AlertService.error(status);
                    if (errResponse.description != null) {
                        $scope.data.lastWithdrawnStatus = `${status }\n${ errResponse.description}`;
                    } else {
                        // eslint-disable-next-line no-useless-concat
                        $scope.data.lastWithdrawnStatus = `${status }\n` + 'Unknown error';
                    }
                });
        }
    });
}());
