/**
 * Created by Jamie Nola on 03/12/2019
 */
(function () {
    'use strict';

    // for setting the values provided by server for configuration of the application
    angular.module('acadiamasterApp')
        .factory('OrderTypeService', function (ORDER_TYPES, $q, $http, localStorageService,
            $rootScope) {
            var apiUrl = '/api/orderManagement/orderTypeSummary';
            var hasLoaded = false;

            function setOrderTypes(values) {
                var parsedValues = {};
                // create object to enable easier access later on
                values.forEach(function (value) {
                    parsedValues[value.orderTypeName] = value;
                });

                angular.extend(ORDER_TYPES, parsedValues);
                localStorageService.set('ORDER_TYPES', ORDER_TYPES);
                $rootScope.ORDER_TYPES = ORDER_TYPES;
                hasLoaded = true;
            }

            return {
                // use this if you need to get the ORDER_TYPES object from local storage (meaning its not available in the ORDER_TYPES value)
                getOrderTypes: function () {
                    var localStorage = localStorageService.get('ORDER_TYPES');
                    angular.extend(ORDER_TYPES, localStorage);
                    $rootScope.ORDER_TYPES = ORDER_TYPES;
                    return ORDER_TYPES;
                },
                hasLoaded: function () {
                    return hasLoaded;
                },
                init: function () {
                    var deferred = $q.defer();

                    var success = function (response) {
                        setOrderTypes(response.data);
                        deferred.resolve(response);
                    };

                    var error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http.get(apiUrl).then(success).catch(error);

                    return deferred.promise;
                }
            };
        });
})();
