(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleAudit', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/ruleAudit/ruleAudits.html',
                scope: {
                    userId: '<?',
                    searchResultCount: '=?',
                    pageAuditId: '<?'
                },
                controller: 'RuleAuditController',
                controllerAs: 'ruleAuditCtrl',
                bindToController: true
            };

        });

})();
