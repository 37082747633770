'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('programCategory', {
                parent: 'programManagement',
                url: '/categories',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programCategory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/programCategory/programCategoryMain.html',
                        controller: 'ProgramCategoryController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('programCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('programCategory.detail', {
                parent: 'programCategory',
                url: '/details/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programCategory.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/programCategory/programCategoryDetail.html',
                        controller: 'ProgramCategoryDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('programCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProgramCategory', function($stateParams, ProgramCategory) {
                        return ProgramCategory.get({id : $stateParams.id});
                    }]
                }
            })
            .state('programCategory.new', {
                parent: 'programCategory',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.programCategory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/programCategory/programCategoryCreateEdit.html',
                        controller: 'ProgramCategoryCreateEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('programCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProgramCategory', function() {
                        return {name: null, createdOn: null, updatedOn: null, isDeleted: null, id: null};
                    }],
                    isNew: [ function() {
                        return true;
                    }]
                }
            })
            .state('programCategory.edit', {
                parent: 'programCategory',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programCategory.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/programCategory/programCategoryCreateEdit.html',
                        controller: 'ProgramCategoryCreateEditController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('programCategory');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'ProgramCategory', function($stateParams, ProgramCategory) {
                        return ProgramCategory.get({id: $stateParams.id});
                    }],
                    isNew: [function () {
                        return false;
                    }]
                }
            });
    });
