/**
 * Created by Jamie Nola on 8/10/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('searchFilter', function ($timeout, $translate, $filter, ngDialog,
                                             SessionStorageService, SEARCH_FILTER_CONSTANTS) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/searchFilter/searchFilter.template.html',
                scope: {
                    // an array of fields that should be displayed in the modal
                    fields: '<',
                    // where to insert the values when the user saves a set of filters
                    values: '=',
                    // the callback for loading a single page in the parent controller
                    loadPage: '<?',
                    // the callback for loading data in the parent controller
                    loadAll: '<?',
                    // the callback for updating the filter summary when filters update
                    summaryCallback: '<?',
                    // the unique key used by sessionStorageService for storing data (optional)
                    storageKey: '@?',
                    // the parent state domain. If the user leaves this domain, the storage data is deleted (optional)
                    storageDomain: '@?',
                    // the text that shows on the button that opens the modal (optional)
                    buttonText: '@?',
                    // Should we add a refresh button or not (optional)
                    refreshButtonText: '@?',
                    // the text to display on the refresh button (default 'Refresh') (optional)
                    enableRefreshButton: '<?',
                    // the title on the top bar of the modal when opened (optional)
                    modalTitle: '@?',
                    // boolean to indicate if we should call loadAll in init method
                    ignoreLoadOnInit: '<?',
                    // programId
                    programId: '<'
                },
                controller: function ($scope) {
                    $scope.cs = SEARCH_FILTER_CONSTANTS;

                    // filters are used to store the user's selections.
                    // active filters stores the filters the user has selected to be exported.
                    // filterValues stores all values globally so that nested fields can not have conflicting values.
                    // storing filterOptions here ensures that we don't load options for any field more than once, but still clear them every time the modal opens
                    $scope.data = {
                        filters: [],
                        activeFilters: [],
                        filterValues: {},
                        cachedFilterValues: {},
                        filterOptions: {},
                        isFilterLoadingOptions: {}
                    };

                    // opens the filter modal, which allows the user to select which filters to use
                    $scope.openFilterModal = function () {
                        $scope.data.filterValues = angular.copy($scope.data.cachedFilterValues);
                        $scope.data.filterOptions = getFilterOptions($scope.data.filters);
                        $scope.data.isFilterLoadingOptions = {};

                        $scope.filterDialog = ngDialog.open({
                            templateUrl: 'admin-templates/site/components/searchFilter/searchFilterModal.template.html',
                            className: 'ngdialog-theme-plain custom-width-medium',
                            scope: $scope
                        });
                    };

                    // closes the modal and exports the filters to the parent controller
                    $scope.applyFilters = function () {
                        $scope.filterDialog.close();
                        updateActiveFilters($scope);
                        exportFilters($scope);
                    };

                    // deletes a single filter
                    $scope.removeFilter = function (filter) {
                        safelyRemoveFilterValues($scope.data.activeFilters, filter, $scope.data.filterValues);
                        updateActiveFilters($scope);
                        exportFilters($scope);
                    };

                    // deletes all filters
                    $scope.clearAllFilters = function () {
                        $scope.data.filterValues = {};
                        updateActiveFilters($scope);
                        exportFilters($scope);
                    };

                    $scope.refresh = function () {
                        loadData(true);
                    };

                    // called when a field with subfilters' value changes. If the subfields are being expanded,
                    // we add a delay so that the subfields' height can be measured before expanding.
                    // If collapsing, we do so immediately to keep the height before the subfields disappear.
                    $scope.onValueChange = function (filter) {
                        if (filter.subfilters) {
                            var values = $scope.data.filterValues;
                            var value = values[filter.id] ? values[filter.id] : undefined;

                            let subfilterMatchesValue = false;
                            // if the field is multiselect, value will be an array. If so, handle
                            // each item in the array the same way we would handle an individual
                            // item.
                            if (angular.isArray(value)) {
                                subfilterMatchesValue = value.some(val => {
                                    return filter.subfilters[val.value];
                                });
                            } else if(value !== undefined) {
                                subfilterMatchesValue = filter.subfilters[value.value];
                            }

                            var hideSubfilters = !subfilterMatchesValue &&
                                !filter.subfilters[$scope.cs.ANY_SELECTION]
                            if (hideSubfilters && !filter.hideSubfilters) {
                                filter.hideSubfilters = hideSubfilters;
                            } else if (!hideSubfilters && filter.hideSubfilters) {
                                // add timeout when removing subfilters.
                                // This allows the collapse animation to happen correctly
                                $timeout(function () {
                                    filter.hideSubfilters = hideSubfilters;

                                    if(hideSubfilters) {
                                        $scope.clearSubfilterValues(filter);
                                    }
                                });
                            }
                        }
                    }

                    // callback for resetting all of a filter's subfilters' values when the filter's value is changed
                    $scope.clearSubfilterValues = function (filter) {
                        safelyRemoveFilterValues($scope.data.activeFilters, filter,
                            $scope.data.filterValues, true);
                    };

                    // creates filter data based on the parent controller's filtered fields
                    function createFilters($scope, fields) {
                        return fields.map(function (field) {
                            var filter = {
                                id: field.id,
                                type: field.type,
                                field: field
                            };
                            if (field.displayId) {
                                filter.displayId = field.displayId;
                            }
                            if (field.optionSource) {
                                filter.optionSource = field.optionSource;
                            }
                            if (field.hasEmptyOption) {
                                filter.hasEmptyOption = field.hasEmptyOption;
                            }
                            // set up the filter's display object.
                            filter.display = {
                                name: getFilterDisplayName(filter)
                            };
                            // set up this field's subfields (if any exist)
                            if (field.subfields) {
                                filter.hideSubfilters = true;
                                filter.subfilters = {};
                                Object.keys(field.subfields).forEach(function (key) {
                                    filter.subfilters[key] = createFilters($scope, field.subfields[key]);
                                });
                            }
                            return filter;
                        });
                    }

                    // clears all options and reloads them. This happens every time the modal is opened.
                    function getFilterOptions(filters, _options) {
                        var options = _options || {};
                        filters.forEach(function (filter) {
                            // if we aren't already storing options for this field, get them now.
                            var needsOptions = filter.type.isEnumerable && !options.hasOwnProperty(filter.id);
                            if (needsOptions) {
                                options[filter.id] = filter.field.options || [];
                            }
                            // set up this filter's subfilters (if any exist)
                            if (filter.subfilters) {
                                Object.keys(filter.subfilters).forEach(function (key) {
                                    options = getFilterOptions(filter.subfilters[key], options);
                                });
                            }
                        });
                        return options;
                    }

                    // get all of a list of filter's IDs, including all subfilters
                    function getFilterIds(filters, _ids) {
                        var ids = _ids || [];
                        filters.forEach(function (filter) {
                            if (ids.indexOf(filter.id) === -1) {
                                ids.push(filter.id);
                            }
                            if (filter.subfilters) {
                                // if the filter has any of its own subfilters, process them too
                                Object.keys(filter.subfilters).forEach(function (key) {
                                    ids = getFilterIds(filter.subfilters[key], ids);
                                });
                            };
                        });
                        return ids;
                    }

                    // given a list of filters, return the count of each ID that has an active value
                    function getValueCounts(filters, values, excludeId, _counts) {
                        var counts = _counts || {};
                        filters.forEach(function (filter) {
                            var value = values[filter.id];
                            if (value != null && filter.id !== excludeId) {
                                counts[filter.id] = counts.hasOwnProperty(filter.id) ? counts[filter.id] + 1 : 1;

                                // if this filter's current value has subfilters, iterate through them to continue getting the counts
                                if (filter.subfilters) {
                                    Object.keys(filter.subfilters).forEach(function (key) {
                                        var subfilter = filter.subfilters[key];
                                        var id = subfilter.id;

                                        let subfilterMatchesValue = false;
                                        if (angular.isArray(filter.value)) {
                                            subfilterMatchesValue = filter.value.some(value => id === value.value);
                                        } else {
                                            subfilterMatchesValue = id === value.value;
                                        }
                                        if (key === SEARCH_FILTER_CONSTANTS.ANY_SELECTION || subfilterMatchesValue) {
                                            counts = getValueCounts(subfilter, values, excludeId, counts);
                                        }
                                    });
                                }
                            }
                        });
                        return counts;
                    }

                    // safely removes all values for a particular filter. If one of these values already exists in another filter in the list, it will not be removed.
                    function safelyRemoveFilterValues(filters, filter, values, onlyRemoveSubfilterValues) {
                        var ids = getFilterIds([filter]);
                        var counts = getValueCounts(filters, values, filter.id);

                        ids.forEach(function (id) {
                            if (!counts[id] && !(id === filter.id && onlyRemoveSubfilterValues)) {
                                // if no fields are using this value, delete it
                                delete values[id];
                            }
                        });
                    }

                    // updates the activeFilters array with only filters that have a value
                    function updateActiveFilters($scope) {
                        // filter out only filters that have values
                        var activeFilters = $scope.data.filters.filter(function (filter) {
                            var value = $scope.data.filterValues[filter.id];
                            var valueExists = value != null && value != '';
                            if (!valueExists) {
                                delete $scope.data.filterValues[filter.id];
                            }

                            return valueExists;
                        });

                        // remove isExpandable fields and move their subfields to the root level
                        var filteredActiveFilters = [];
                        activeFilters.forEach(function (filter) {
                            if (filter.type.isExpandable) {
                                var value = $scope.data.filterValues[filter.id].value.toString();
                                if (filter.subfilters && filter.subfilters[value]) {
                                    var expandedValue;
                                    filter.subfilters[value].forEach(function (subfilter) {
                                        var subValue = $scope.data.filterValues[subfilter.id];
                                        if (subValue != null && subValue != '') {
                                            filteredActiveFilters.push(subfilter);
                                            // if an expandable field has at least one subfield with a value,
                                            // keep it open.
                                            expandedValue = { value: true };
                                        }
                                    });

                                    $scope.data.filterValues[filter.id] = expandedValue;
                                }
                            } else {
                                filteredActiveFilters.push(filter);
                            }
                        });
                        activeFilters = filteredActiveFilters;

                        // set values on each active filter (used for notification badges)
                        $scope.data.activeFilters = setFilterValues(
                            activeFilters,
                            $scope.data.filterValues
                        );
                        $scope.data.cachedFilterValues = angular.copy($scope.data.filterValues);
                    }

                    // gives each active filter a value from the data.filterValues object
                    function setFilterValues(filters, values) {
                        filters.forEach(function (filter) {
                            var value = values[filter.id];
                            if (value != null &&
                                ((!value.hasOwnProperty('enabled') || value.enabled) &&
                                    (!value.hasOwnProperty('isInvalid') || !value.isInvalid))) {
                                filter.value = values[filter.id];

                                // set up this filter's subfilters (if any exist)
                                if (filter.subfilters) {
                                    Object.keys(filter.subfilters).forEach(function (key) {
                                        let subfilterMatchesValue = false;
                                        if (angular.isArray(filter.value)) {
                                            subfilterMatchesValue = filter.value.some(value => key === value.value.toString());
                                        } else {
                                            subfilterMatchesValue = key === filter.value.value.toString();
                                        }
                                        if (key === SEARCH_FILTER_CONSTANTS.ANY_SELECTION || subfilterMatchesValue) {
                                            setFilterValues(filter.subfilters[key], values);
                                        }
                                    });
                                }
                            } else {
                                filter.value = null;
                            }
                        });
                        return filters;
                    }

                    // formats the filter's stored value into the right format for exporting
                    function updateExportableValuesFromFilter(filter, values) {
                        // Enumerable types
                        if (filter.type.isEnumerable) {
                            // Multi-Select type (create comma-separated string from array)
                            if (filter.type.id === $scope.cs.TYPES.MULTI_SELECT.id) {
                                values[filter.id] = filter.value.map(function (item) {
                                    return item.value;
                                }).join(',');
                            } else {
                                if (filter.value.value !== $scope.cs.EMPTY_OPTION_VALUE) {
                                    values[filter.id] = filter.value.value;
                                }
                            }
                            return values;
                        }
                        // Expandable types (no value saved)
                        if (filter.type.isExpandable) {
                            return values;
                        }

                        // Non-enumerable, non-expandable types
                        switch (filter.type.id) {
                            // Cron type
                            case SEARCH_FILTER_CONSTANTS.TYPES.CRON.id:
                                if (filter.value.enabled && !filter.value.isInvalid) {
                                    values[filter.id] = filter.value.expression;
                                    values[filter.field.recurrenceTypeId] = filter.value.recurrence;
                                }
                                break;
                            // Date Range type
                            case SEARCH_FILTER_CONSTANTS.TYPES.DATE_RANGE.id:
                                var startId = filter.field.start.id;
                                if (filter.value.start && !values.hasOwnProperty(startId)) {
                                    values[startId] = new Date(filter.value.start).getTime();
                                }
                                var endId = filter.field.end.id;
                                if (filter.value.end && !values.hasOwnProperty(endId)) {
                                    // for end value, add one 59.999 seconds to ensure that the response
                                    // includes the specified end timestamp parameter
                                    values[endId] = new Date(filter.value.end).getTime() + 59999;
                                }
                                break;
                            // all other types
                            default:
                                values[filter.id] = filter.value;
                                break;
                        }
                        return values;
                    }

                    // gets all values from active filters
                    function getActiveValues(filters, _values) {
                        var values = _values || {};
                        filters.forEach(function (filter) {
                            // if this filter's value exists and hasn't been formatted, format the value for exporting

                            let valueExists = false;
                            if (angular.isArray(filter.value)) {
                                valueExists = filter.value.some(value => value != null && value != '');
                            } else {
                                valueExists = filter.value != null && filter.value != '';
                            }

                            if (valueExists) {
                                if (!values.hasOwnProperty(filter.id)) {
                                    values = updateExportableValuesFromFilter(filter, values);
                                }

                                // update the filter's display name and value text
                                filter.display = {
                                    name: getFilterDisplayName(filter),
                                    value: getFilterDisplayValue(filter),
                                };

                                // If the filter has subfilters, get their values too
                                if (filter.subfilters) {
                                    Object.keys(filter.subfilters).forEach(function (key) {
                                        let subfilterMatchesValue = false;
                                        if (angular.isArray(filter.value)) {
                                            subfilterMatchesValue = filter.value.some(value => key === value.value.toString());
                                        } else {
                                            subfilterMatchesValue = key === filter.value.value.toString();
                                        }
                                        if (key === SEARCH_FILTER_CONSTANTS.ANY_SELECTION || subfilterMatchesValue) {
                                            values = getActiveValues(filter.subfilters[key], values);
                                        }
                                    });
                                }
                            }
                        });
                        return values;
                    }

                    /**
                     * Builds a string representation of a filter's name
                     * @param {Object} filter
                     */
                    function getFilterDisplayName(filter) {
                        if (filter.type.id === $scope.cs.TYPES.MULTI_SELECT.id) {
                            return $translate.instant('searchFilter.field.' +
                                (filter.displayId || filter.id) +
                                (filter.value && filter.value.length === 1 ?
                                    '.singular' :
                                    '.plural'));
                        }
                        return $translate.instant('searchFilter.field.' +
                            (filter.displayId || filter.id));
                    }

                    /**
                     * Builds a string representation of a filter's values
                     * @param {Object} filter
                     */
                    function getFilterDisplayValue(filter) {
                        var types = $scope.cs.TYPES;
                        var values = [];
                        switch (filter.type.id) {
                            case types.CRON.id:
                                return filter.value.name;
                            case types.DATE.id:
                                return $filter('date')(filter.value, 'short');
                            case types.DATE_RANGE.id:
                                if (filter.value.start) {
                                    values.push($translate.instant('searchFilter.dateRange.start') +
                                        ' ' + $filter('date')(filter.value.start, 'short'));
                                }
                                if (filter.value.end) {
                                    values.push($translate.instant('searchFilter.dateRange.end') +
                                        ' ' + $filter('date')(filter.value.end, 'short'));
                                }
                                return values.join(' ');
                            case types.MULTI_SELECT.id:
                                values = filter.value.map(function (item) {
                                    return item.name;
                                });
                                return values.join(', ');
                            default:
                                if (filter.type.isEnumerable) {
                                    return filter.value.name;
                                }
                                return filter.value;
                        }
                    }

                    /**
                     * Recursively builds a summary for filters and subfilters
                     * @param {Object} filter
                     * @param {Array} summary
                     */
                    function buildFilterSummary(filter, summary) {
                        if (filter.value != null) {
                            summary.push(filter.display);
                        }
                        // if the filter has subfilters, build a summary for each one
                        if (filter.subfilters) {
                            // first, iterate through the 'any selection' subfilters
                            (filter.subfilters[$scope.cs.ANY_SELECTION] || []).forEach(function (subfilter) {
                                if (subfilter.value) {
                                    buildFilterSummary(subfilter, summary);
                                }
                            });
                            // then, do each other subfilter
                            (filter.subfilters[filter.value.value] || []).forEach(function (subfilter) {
                                if (subfilter.value) {
                                    buildFilterSummary(subfilter, summary);
                                }
                            });
                        }
                    }

                    /**
                     * If necessary, exports a text summary of active filters
                     */
                    function exportSummary() {
                        var summary = [];
                        $scope.data.activeFilters.forEach(function (filter) {
                            buildFilterSummary(filter, summary);
                        });
                        $scope.summaryCallback(summary);
                    }

                    /**
                     * Calls either the loadPage or loadAll callback from the parent controller.
                     * The timeout is needed so that searchData is updated in the parent controller.
                     * @param {Object} filter
                     */
                    function loadData(resetPage) {
                        // if needed, build a summary and export it
                        if ($scope.summaryCallback) {
                            exportSummary();
                        }

                        var loadFn;
                        if (resetPage) {
                            // if we need to reset the page, prefer loadPage but fallback to loadAll
                            loadFn = $scope.loadPage || $scope.loadAll || $.noop;
                            $timeout(function () {
                                loadFn(0);
                            });
                        } else {
                            // if not, prefer loadAll but fallback to loadPage
                            loadFn = $scope.loadAll || $scope.loadPage || $.noop;
                            $timeout(loadFn);
                        }
                    }

                    /**
                     * Exports filter values into the parent controller and reloads.
                     * @param {Object} $scope
                     */
                    function exportFilters($scope) {
                        $scope.values = getActiveValues($scope.data.activeFilters);
                        if ($scope.storageKey) {
                            SessionStorageService.setItem(
                                $scope.storageKey,
                                $scope.data,
                                $scope.storageDomain || null);
                        }
                        loadData(true);
                    }

                    function init($scope) {
                        var data;
                        if ($scope.storageKey) {
                            // load $scope.data from session storage service
                            data = SessionStorageService.getItem($scope.storageKey);
                        }

                        if (data) {
                            $scope.data = data;
                            $scope.values = getActiveValues($scope.data.activeFilters);
                        } else {
                            //initial setup for filters
                            $scope.data.filters = createFilters($scope, $scope.fields);
                        }

                        if (!$scope.ignoreLoadOnInit) {
                            loadData();
                        }
                    }

                    init($scope);
                }
            };
        });
})();
