(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');
    /**
     * provider detail directive
     */
    app.directive('registerBasicFields', function(EmailValidation,RegisterConstantService, PasswordUtilService) {

        return {
            restrict: 'E',
            templateUrl:"admin-templates/account/register/registerBasicFields.html",
            scope : {
                registerAccount : '=',
                userRole:'='
            },
            link:function($scope){
                $scope.urs=RegisterConstantService.roles;
                $scope.doNotMatch=null;
                $scope.errorEmailExists=null;
                $scope.errorInvalidEmail=null;
                $scope.inviteCodeRequired=$scope.$root.CONFIG?$scope.$root.CONFIG.inviteCodeRequired:undefined;

                //Check for valid email address.
                $scope.checkEmail = function () {
                    if ($scope.basicInfo.email.$valid) {
                        $scope.errorEmailExists=null;
                        $scope.errorInvalidEmail=null;
                        EmailValidation.checkEmailOrLoginAvailable($scope.basicInfo.email.$modelValue)
                            .then(function (response) {
                                if (response.data.isValid === true) {
                                    $scope.errorEmailExists=false;
                                } else {
                                    $scope.errorEmailExists=true;
                                    $scope.basicInfo.$invalid = true;
                                }
                            },function(err){
                                if (err.status === 400 && err.data.description === 'Please enter a valid email address') {
                                    $scope.errorInvalidEmail = true;
                                }
                            });
                    }
                };

                $scope.validateNewPassword = function () {
                    var password = $scope.registerAccount.password;
                    var confirmPassword = $scope.confirmPassword;

                    $scope.basicInfo.password.$setValidity('invalid', PasswordUtilService.isValidPassword(password, 8));

                    // check for password match
                    $scope.basicInfo.confirmPassword.$setValidity('mismatch', confirmPassword === undefined || password === confirmPassword);
                };

                $scope.validateConfirmPassword = function () {
                    var password = $scope.registerAccount.password;
                    var confirmPassword = $scope.confirmPassword;

                    // check for password match
                    $scope.basicInfo.confirmPassword.$setValidity('mismatch', confirmPassword === undefined || password === confirmPassword);
                };
            }
        };
    });

})();
