/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * Directive for form field value for calendar.
     */
        .directive('formFieldConfigFieldValueTimePickerOptions', function (FormModelServiceFieldValue, LocaleConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: "admin-templates/site/forms/configPanel/fieldValue/timePickerOptions.html",
                scope: {
                    fieldValue: '=',
                    parentForm: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                }
            };
        });
})();

