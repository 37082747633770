import ProgramContainerConstantsService from '../../../const/programContainer.constants.service';

angular.module('acadiamasterApp').directive('addContainerItemMeasurementsButton', () => ({
    link ($scope) {
        $scope.containerTypeNames = ProgramContainerConstantsService.containerTypeNames;

        $scope.addMeasurementsItem = function () {
            $scope.container.addMeasurements();
        };

        $scope.hasIconClass = () => {
            return $scope.iconClass && $scope.iconClass.length > 0;
        };

        $scope.getIconClass = () => {
            return $scope.hasIconClass() ? $scope.iconClass : '';
        };
    },
    restrict : 'E',
    scope    : {
        container : '=',
        iconClass : '<',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/'
        + 'config/containerItem/addItem/addContainerItemMeasurementsButton.html',
}));
