'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeController', function ($scope, DataType, DataTypeSearch, ParseLinks, DataTypeMasterRecordCount, ngDialog, SearchWithFilter,
    vbrCommonUtil, CONFIG) {
        $scope.CONFIG = CONFIG;
        $scope.dataTypes = [];
        $scope.page = 0;

        $scope.formData = {
           searchTerm: null
        };
        $scope.loadAll = function() {
            DataType.query({page: $scope.page, size: 20}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.dataTypes = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.searchFilter.pageNum = page;
            $scope.page = page;
            queryWithFilter($scope.searchFilter);
        };
        $scope.loadAll();

        $scope.searchFilter = {
            name: null,
            usedFor: null,
            exactMatch: false,
            pageNum: 0,
            pageSize: 20,
            entityType: 'DATA_TYPE'
        };

        $scope.usedFor = {
            DATAFORM: 'DATAFORM',
            PROFILEFORM: 'PROFILEFORM',
            ALL: 'ALL'
        };

        $scope.changedUsedFor = $scope.usedFor.ALL;
        $scope.previousUsedFor = $scope.usedFor.ALL;

        $scope.delete = function (id) {
            DataType.get({id: id}, function(result) {
                $scope.dataType = result;
                $('#deleteDataTypeConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            DataType.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteDataTypeConfirmation').modal('hide');
                    DataTypeMasterRecordCount.updateCounts();
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            refresh();
        };

        function refresh() {
            $scope.loadAll();
            $scope.clear();
        }

        $scope.clear = function () {
            $scope.dataType = {name: null, description: null, createdOn: null, updatedOn: null, isDeleted: null, id: null};
        };

        $scope.searchByFilter = function() {
            setPageToZero();
            queryWithFilter($scope.searchFilter);
        };

        $scope.clearSearchFilter = function() {
            setPageToZero();
            $scope.searchFilter.name = null;
            $scope.searchFilter.usedFor = $scope.usedFor.ALL;
            $scope.searchFilter.exactMatch = false;
            $scope.changedUsedFor = $scope.usedFor.ALL;
            $scope.loadAll();
        };

        $scope.modalShown = false;

        $scope.toggleModal = function() {
            console.log($scope.modalShown);
            $scope.modalShown = !$scope.modalShown;
        };

        $scope.usedForFilterChanged = function(){
          if ($scope.previousUsedFor != $scope.changedUsedFor)
          {
              setPageToZero();
              queryWithFilter($scope.searchFilter);
          }
          $scope.previousUsedFor = $scope.changedUsedFor;
        };

        function queryWithFilter(searchFilter) {
            searchFilter.usedFor = ($scope.changedUsedFor==$scope.usedFor.ALL)? null :$scope.changedUsedFor ;
            SearchWithFilter.query(searchFilter).then(function(result){
                $scope.links = ParseLinks.parse(result.headers('link'));
                $scope.dataTypes = result.data;
            })
        }

        // Data Type change/remove
        $scope.options = {
          entityType: "DATA_TYPE",
          entity: $scope.dataTypes == null ? [] : $scope.dataTypes,
          page: $scope.page,
          links: $scope.links == null ? [] : $scope.links
        };

       $scope.confirmSearch = function confirmSearch(dataType) {
           console.log('I am callback function');
           console.log(dataType);
           //$scope.confirm();
       };

        function setPageToZero(){
            $scope.searchFilter.pageNum = 0;
            $scope.page = 0;
        }

        $scope.download = function (id) {
            var dataType = DataType.get({id: id});
            dataType.$promise.then(function (data) {
                var fileName = 'dataType_' + id + '.json';
                vbrCommonUtil.downloadAsFile(fileName, data);
            });
        };

        $scope.importDataType = function () {
            ngDialog.openConfirm({
                template: '<upload-to-configuration-server title="Import Data Type" ws-url="/api/configuration/dataType/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                plain: true,
                size: 'md',
                className : 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        refresh();
                    }
                }]
            }).then(function () {
                $scope.refresh();
            });
        };

    });
