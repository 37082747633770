/**
 * Created by moustafabaiou on 2/15/17
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import loadjs from 'loadjs';

import angular from 'angular';
import 'checklist-model';
import 'angular-translate';
import 'angular-cookies';
import 'angular-bootstrap';
import 'angular-ui-router';
import 'angular-animate';
import 'angular-toastr';
import 'bootstrap-ui-datetime-picker';
import 'isteven-angular-multiselect/isteven-multi-select';
import ngSanitize from 'angular-sanitize';
import 'angular-local-storage';
import 'angular-dynamic-locale';
import 'angular-awesome-slider';
import 'angular-resource';
import 'angular-aria';
import 'angular-file-upload';
import 'angular-cache-buster';
import 'angular-query-builder';
import 'ng-infinite-scroll/build/ng-infinite-scroll.min';
import 'angular-messages';
import 'ng-dialog';
import './jslib/dropzone/dropzone.directive';
import './jslib/angular-ckeditor/angular-ckeditor.min';
import './jslib/angular-wizard/angular-wizard';
import './jslib/ng-image-cache/ng-image-cache';
import './jslib/ngFlowChart/ngFlowchart';
import './jslib/angular-ui-tree/angular-ui-tree';
import 'angular-ui-ace';
import 'ui-cropper';

// CSS
import 'normalize.css';
import 'dropzone/dist/min/dropzone.min.css';
import 'ng-img-crop-full-extended/compile/minified/ng-img-crop.css';
import 'ng-dialog/css/ngDialog.css';
import 'ng-dialog/css/ngDialog-theme-default.css';
import 'angular-bootstrap-colorpicker/css/colorpicker.css';
import 'animate.css/animate.css';
import 'jsonformatter/dist/json-formatter.min.css';
import 'angular-awesome-slider/dist/css/angular-awesome-slider.min.css';
import 'isteven-angular-multiselect/isteven-multi-select.css';
import 'angular-toastr/dist/angular-toastr.min.css';
import './styles/admin-main.scss';
// END CSS

import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow_night';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-html';

import { react2angular } from 'react2angular';
import {
    AdminApp,
} from '@vibrent/goober';

// eslint-disable-next-line import/no-unresolved
import 'shared_utilities';
import 'angular-strap';
import 'ng-csv/build/ng-csv.min';
import 'ng-clipboard/dist/ng-clipboard';
import 'ng-img-crop-full-extended';
import './jslib/wysiwyg/wysiwyg.module';
import 'angular-bootstrap-colorpicker';
import './jslib/vibrent-cron-generator/cron-generator';
import './jslib/vibrent-external-survey-parser/app.module';
import './jslib/vibrent-external-survey-parser/parser-util/commonParserUtil.service';
import './jslib/vibrent-external-survey-parser/parser-util/vbrLocalIdGenerator.service';
import './jslib/vibrent-external-survey-parser/parser-util/vibrentFormStructureConstant.service';
import './jslib/vibrent-external-survey-parser/parser-util/vibrentFormSkippingLogic.service';
import './jslib/vibrent-external-survey-parser/qualtrics-parser/qualtricsParserUtil.service';
import './jslib/vibrent-external-survey-parser/qualtrics-parser/qualtricsSurveyParser.service';
import './jslib/vibrent-external-survey-parser/qualtrics-parser/qualtricsSurveyQuestionParser.service';
import './jslib/vibrent-external-survey-parser/qualtrics-parser/qualtricSkippingLogic.service';
import './jslib/vibrent-external-survey-parser/redcap-parser/redcapFormStructureConstant.service';
import './jslib/vibrent-external-survey-parser/redcap-parser/textTypeParser.service';
import './jslib/vibrent-external-survey-parser/redcap-parser/redcapParserUtil.service';
import './jslib/vibrent-external-survey-parser/redcap-parser/redcapSurveyParser.service';
import './jslib/vibrent-external-survey-parser/redcap-parser/redcapSurveyQuestionParser.service';
import 'jsonformatter';
import 'angular-csv-import';
import 'angular-qrcode';
import './jslib/vibrent-sass/scripts/index.min';
import 'bootstrap-sass/assets/javascripts/bootstrap.min';
import './jslib/jqwidgets/jqxcore';
import './jslib/jqwidgets/jqx-all';
import './jslib/jqwidgets/jqxangular';

import keycloakService from './keycloak/keycloak.service';

// Loading this manually since the script tag in index page is not working.
// eslint-disable-next-line angular/window-service
window.CKEDITOR_BASEPATH = `${window.location.origin }/assets/ckeditor/`;
loadjs('/assets/ckeditor/ckeditor.js');

// Initialize keycloak before angular boots up. Boot up Angular after keycloak.
angular.element(document).ready(() => {
    keycloakService.initKeycloak(() => {
        angular.bootstrap(document, [ 'acadiamasterApp' ]);
    });
});

angular.module(
    'acadiamasterApp',
    [
        'LocalStorageModule',
        'ngCookies',
        'pascalprecht.translate',
        'tmh.dynamicLocale',
        'ui.bootstrap',
        'ui.bootstrap.datetimepicker',
        'isteven-multi-select',
        ngSanitize,
        'jsonFormatter',
        'angularAwesomeSlider',
        'toastr',
        'ngAnimate',
        'ngResource',
        'ui.router',
        'ngAria',
        'ngCacheBuster',
        'ngFileUpload',
        'infinite-scroll',
        'vibrent-wizard',
        'acadiaUtil',
        'ngMessages',
        'ui.tree',
        'ngDialog',
        'dropzone',
        'colorpicker.module',
        'ngImgCrop',
        'ngImageCache',
        'vibrent-cron-generator',
        'ngCsvImport',
        'mgcrea.ngStrap',
        'sharedUtilities',
        'ui.ace',
        'wysiwyg',
        'monospaced.qrcode',
        'ng-clipboard',
        'ngCsv',
        'vbr-style-guide',
        'flowchart',
        'ckeditor',
        'jqwidgets',
        'queryBuilder',
        'uiCropper',
        'vibrent-external-survey-parser',
    ],
).component('adminApp', react2angular(AdminApp, [ 'id', 'token' ]))
    // This is necessary since moving to AngularJS 1.8 will add a '!' prefix if we
    // don't specify and empty string
    .config([ '$locationProvider', function ($locationProvider) {
        $locationProvider.hashPrefix('');
    } ]);
