(function () {
    'use strict';
    angular.module('acadiamasterApp')
        .service('CodeBook', function ($http) {
            var baseUrl = 'api/codeBookReport';

            function exportCodeBook(formVersionId) {
                var config = {
                    params: {
                        formVersionId: formVersionId
                    }
                };
                return $http.get(baseUrl, config);
            }

            return {
                exportCodeBook: exportCodeBook
            };
        });
})();