/* eslint-disable valid-jsdoc */
/**
 * Created by Jamie Nola on 08/02/2019
 */

angular.module('acadiamasterApp')
    .factory('ContainerMgmtService', ($http, localWeb, Upload) => {
        /**
         * Gets all containers for the given program
         */
        function getContainers (programId) {
            return $http.get(localWeb.createUrl(`api/admin/program/${programId}/containers`));
        }

        /**
         * Saves entire containers object to the server
         */
        function saveContainers (programId, dto, files) {
            const data = {
                data : JSON.stringify(dto),
            };
            if (files) {
                data.file = files;
            }
            return Upload.upload({
                // to allow files array to upload with param name file instead of file[i]
                arrayKey : '',
                data,
                url      : localWeb.createUrl(`api/admin/program/${programId}/containers`),
            });
        }

        /**
         * recalculate all the personalized containers for one program, this applies to all subscription of the program, regardless
         * of program id
         */
        function recalculatePersonalized (programId) {
            return $http.post(localWeb.createUrl(`api/admin/program/${programId}/containers/recalculatePersonalized`));
        }

        /**
         * recalculate all the personalized containers for one user
         */
        function recalculatePersonalizedByUserId (userId) {
            return $http.post(localWeb.createUrl(`api/containers/user/recalculatePersonalized?userId=${userId}`));
        }

        /**
         * save a container template
         * @param templateDTO - template
         * @returns {promise} - promise that contains the template
         */
        function saveContainerTemplate (templateDTO) {
            return $http.post(localWeb.createUrl('api/admin/containers/template'), templateDTO);
        }

        function importContainerItemZipFile (programId, zipFile) {
            return Upload.upload({
                data : {
                    file : zipFile,
                },
                url : localWeb.createUrl(`api/configuration/containerItem/import/${programId}`),
            });
        }

        return {
            getContainers,
            importContainerItemZipFile,
            recalculatePersonalized,
            recalculatePersonalizedByUserId,
            saveContainers,
            saveContainerTemplate,
        };
    });
