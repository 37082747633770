'use strict';

angular.module('acadiamasterApp')
    .factory('BusinessRuleExecutionLogAudit', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/schedulers/jobs/logs/:schedName/:jobGroup/:jobName'),
            {schedName: '@schedName', jobGroup: '@jobGroup', jobName: '@jobName'}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        var resultData = angular.fromJson(data);
                        return resultData;
                    }
                }
            });
    });