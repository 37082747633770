/**
 * Created by Lulu Wang on 05/20/2019
 */

angular.module('acadiamasterApp')
    .controller('ExpressionConditionDetailsController', ($stateParams, ExpressionConditionService,
        ConditionTreeCreationUtil) => {
        const vm = this;
        vm.expressionId = $stateParams.expressionId;
        return vm;
    });
