'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('interstitialFile', {
                parent: 'entity',
                url: '/interstitials',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.report.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/interstitial/interstitial.html',
                        controller: 'InterstitialController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['Interstitial', function (Interstitial) {
                        return Interstitial.getFile();
                    }]
                }
            });
    });
