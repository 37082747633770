import cs from '../../const/programContainer.constants.service';

/**
 * container item type specific config base class
 */
angular.module('acadiamasterApp').factory('ContainerItemTypeSpecificConfig', (
    ModelServiceBase,
) => {

    const classKey = cs.classes.classReferenceKey;

    ContainerItemTypeSpecificConfig.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

    /**
     * @constructor
     */
    function ContainerItemTypeSpecificConfig(parent) {
        ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);
    }

    /**
     * convert the current UI model to dto format
     */
    ContainerItemTypeSpecificConfig.prototype.toDto = function () {
        const dto = {};
        dto[classKey] = this[classKey];
        return dto;
    };

    /**
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemTypeSpecificConfig.prototype.fromDto = function (dto) {
        if (dto==null) {
            return;
        }
        this[classKey] = dto[classKey];
    };

    ContainerItemTypeSpecificConfig.prototype.getDescriptionAsHtml = function () {
        // default configuration, not going to return anything, the sub class should override
        // this function if we want to display something useful
        return 'getDescriptionAsHtml not implemented';
    };

    /** *************************************
     * private function call
     ************************************** */

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemTypeSpecificConfig;
});
