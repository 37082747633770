(function () {
    'use strict';

    /**
     * directive for selecting property
     */
    angular.module('acadiamasterApp').directive('propertySelection', function (PropertyCacheService, AlertService,
                                                                               ValueComparisonOptionModel) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/propertySelection.html',
            scope: {
                propertyOptions: '=',
                isRequired: '=',
                shouldSetCompareValue: '=',
                title: '@',
                propertyIndex: '<?',
            },
            link: function ($scope) {

                $scope.data = {
                    allProperties : null,
                    property : $scope.propertyOptions.getProperty($scope.propertyIndex),
                };

                if ($scope.shouldSetCompareValue) {
                    $scope.valueComparisonOption = createValueComparisonOption($scope);
                }

                init($scope);
            }
        };

        function init($scope) {
            // this is not a false reload of all the properties
            PropertyCacheService.loadProperties(false).then(function (properties) {
                $scope.data.allProperties = properties;
            }).catch(function (error) {
                AlertService.error('failed to load properties list', null, error);
            });

            addWatches($scope);
        }

        function createValueComparisonOption($scope) {
            var valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = $scope.isRequired;
            valueComparisonOption.canConfigureOperator = _.isFunction($scope.propertyOptions.getOperator);

            valueComparisonOption.functions.getValueType = function() {
                return $scope.data.property == null ? null : $scope.data.property.valueType;
            };

            if (_.isFunction($scope.propertyOptions.getOperator)) {
                valueComparisonOption.functions.getOperator = function() {
                    return $scope.propertyOptions.getOperator();
                };
            }

            if (_.isFunction($scope.propertyOptions.setOperator)) {
                valueComparisonOption.functions.setOperator = function (newOperator) {
                    $scope.propertyOptions.setOperator(newOperator);
                };
            }

            if (_.isFunction($scope.propertyOptions.getPropertyValues)) {
                valueComparisonOption.functions.getValues = function() {
                    return $scope.propertyOptions.getPropertyValues();
                };
            }

            if (_.isFunction($scope.propertyOptions.setPropertyValues)) {
                valueComparisonOption.functions.setValues = function (newValues) {
                    $scope.propertyOptions.setPropertyValues(newValues);
                };
            }

            return valueComparisonOption;
        }

        function addWatches($scope) {
            $scope.$watch('data.property', function(newValue) {
                $scope.propertyOptions.setProperty(newValue, $scope.propertyIndex);
            });
        }
    });
})();
