/**
 * Created by Jeetendra Mandal
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('commPreferenceSystemImportModal', function (CommunicationCategoryService, AlertService, $translate) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/communicationCategory/importPopupModal/commPreferenceSystemImportModal.template.html',
                controller: function ($scope) {

                    $scope.importJSONFile = function (file) {
                        sendJSONFile($scope, file);
                    };

                    $scope.isFileValid = function (file) {
                        return file && file.name.indexOf('.json') === file.name.length - 5;
                    };

                    init($scope);

                }
            };

            function init($scope) {
                $scope.data = {
                    isSendingFile: false,
                    serverError: false
                };
            }

            /**
             * Upload JSON file and close modal on success
             */
            function sendJSONFile($scope, file) {
                $scope.data.isSendingFile = true;
                CommunicationCategoryService.importJSON(file).then(
                    function () {
                        AlertService.success($translate.instant('stringManagement.jsonFileUploadSuccess'));
                        $scope.confirm();
                    }, function (error) {
                        console.error(error);
                        $scope.data.serverError = true;
                    }).finally(function () {
                        $scope.data.isSendingFile = false;
                    });
            }

        });

})();
