(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueImageContainerOptions',
            function (FormUtilService, ValueConfigOptionService, FormModelServiceFieldValue,
                      LocaleConstantsService, FormConstants, $timeout) {
                return {
                    restrict: 'E',
                    templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/imageContainerOptions.html',
                    scope: {
                        fieldValue: '=',
                        fieldValueChange: '=',
                        fieldElement: '=',
                        selector: '='
                    },
                    link: function ($scope) {
                        $scope.mode = $scope.fieldElement.findParentMode();
                        $scope.cs = FormConstants;
                        $scope.lcs = LocaleConstantsService;

                        $scope.data = {
                            unAvailablePositions: [],
                        };

                        $scope.valueConfigOption = ValueConfigOptionService.createValueConfigOption(
                            $scope.fieldElement._parent.fieldValue, $scope.fieldValue);

                        $scope.hasChangeModel = $scope.fieldValueChange != null;
                        if ($scope.hasChangeModel) {
                            initChangeModelValuesIfNeeded($scope);
                        }

                        $scope.addNewOption = function (index) {
                            FormUtilService.addNewOption($scope.fieldValue.values, index);
                        };

                        $scope.removeOption = function (index) {
                            FormUtilService.removeOption($scope.fieldValue.values, index);
                        };

                        $scope.moveOptionUp = function (index) {
                            FormUtilService.moveOptionUp($scope.fieldValue.values, index);
                        };

                        $scope.moveOptionDown = function (index) {
                            FormUtilService.moveOptionDown($scope.fieldValue.values, index);
                        };

                        $scope.getFieldValuePattern = function () {
                            var valueType = $scope.fieldElement._parent.fieldValue.valueType;
                            return FormUtilService.validationUtil.getFieldValuePattern(valueType);
                        };

                        $scope.sortValues = function () {
                            sortValues($scope);
                        };

                        $scope.isVerticalContainer = function () {
                            return $scope.fieldValue.containerType == $scope.cs.imageContainerTypes.VERTICAL;
                        };

                        $scope.onContainerTypeChange = function (newContainerType) {
                            if (newContainerType == $scope.cs.imageContainerTypes.HORIZONTAL) {
                                $scope.fieldValue.showHeader = false;
                                $scope.fieldValue.showFooter = false;
                                $scope.data.unAvailablePositions = [
                                    $scope.cs.position.LEFT,
                                    $scope.cs.position.RIGHT,
                                ];

                                $timeout(() => {
                                    if ($scope.fieldValue.textPosition != $scope.cs.position.BOTTOM
                                        && $scope.fieldValue.textPosition != $scope.cs.position.TOP) {
                                        $scope.fieldValue.textPosition = $scope.cs.position.BOTTOM;
                                    }
                                }, 100);


                            } else if (newContainerType == $scope.cs.imageContainerTypes.VERTICAL) {
                                $scope.data.unAvailablePositions = [
                                    $scope.cs.position.TOP,
                                    $scope.cs.position.BOTTOM,
                                ];


                                $timeout(() => {
                                    if ($scope.fieldValue.textPosition != $scope.cs.position.LEFT
                                        && $scope.fieldValue.textPosition != $scope.cs.position.RIGHT) {
                                        $scope.fieldValue.textPosition = $scope.cs.position.LEFT;
                                    }
                                }, 100);
                            }
                        };
                    }
                };

                /***************************************************************
                 * private functions
                 ***************************************************************/

                /**
                 * sort all the values alphabetically
                 * @param $scope
                 */
                function sortValues($scope) {
                    $scope.fieldValue.values.sort(function (v1, v2) {
                        if (v1.value < v2.value) {
                            return -1;
                        }

                        if (v1.value > v2.value) {
                            return 1;
                        }

                        return 0;
                    });
                }

                function initChangeModelValuesIfNeeded($scope) {
                    if ($scope.fieldValueChange.values == null) {
                        $scope.fieldValueChange.values = [];
                    }

                    for (var i = 0; i < $scope.fieldValue.values.length; i++) {
                        if ($scope.fieldValueChange.values[i] == null) {
                            $scope.fieldValueChange.values[i] = {
                                enabled: null
                            };
                        }
                    }
                }
            });
})();

