(function () {
    angular.module('acadiamasterApp')
        .factory('ExternalDataImportService', (localWeb, $http, Upload) => {
            const getAllTasks = searchFilters => {
                return $http({
                    method : 'GET',
                    url    : localWeb.createUrl('externalimporter/v1/survey/getTasks?') + $.param(searchFilters),
                });
            };

            const getPrograms = () => {
                return $http({
                    method : 'GET',
                    url    : localWeb.createUrl('api/programs'),
                });
            };

            const createTask = (dataObj, files) => {
                return Upload.upload({
                    arrayKey : '',
                    data     : {
                        data : JSON.stringify(dataObj),
                        file : files,
                    },
                    url : localWeb.createUrl('externalimporter/v1/survey/importTask'),
                });
            };

            const startTask = taskId => {
                return $http({
                    method : 'POST',
                    url    : localWeb.createUrl('externalimporter/v1/survey/startImport' + `?taskId=${ taskId}`),
                });
            };

            const getTaskStatus = (id, searchFilters) => {
                return $http({
                    method : 'GET',
                    url    : localWeb.createUrl(`externalimporter/v1/survey/retrieveTask/${ id }?${ $.param(searchFilters)}`),
                });
            };

            return {
                createTask,
                getAllTasks,
                getPrograms,
                getTaskStatus,
                startTask,

            };
        });
}());
