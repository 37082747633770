(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultPageService',
        function (CompareResultSectionService, CompareResultDisplayConfigService, CompareResultUtilService,
                  AddressVerificationSetup, CompareResultAddressPageService,CompareResultDataSharingPageService, FormConstants) {


            function comparePageDetail(page1, page2) {
                var result = {
                    localId: CompareResultUtilService.comparePrimitives(page1.localId, page2.localId),
                    name: CompareResultUtilService.comparePrimitives(page1.name, page2.name),
                    formPageType: CompareResultUtilService.comparePrimitives(page1.formPageType, page2.formPageType)
                };

                if (page1.formPageType == FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION) {
                    var addressComparisonResult = CompareResultAddressPageService.compareAddressVerificationProperties(page1, page2);
                    Object.assign(result, addressComparisonResult);
                }
                if (page1.formPageType == FormConstants.formPageDiffTypes.DATA_SHARING) {
                    var dataShareComparisonResult = CompareResultDataSharingPageService.compareDataSharingVerificationProperties(page1, page2);
                    Object.assign(result, dataShareComparisonResult);
                }
                if (page1.formPageType == FormConstants.formPageDiffTypes.STOP_DATA_SHARING) {
                    var dataShareComparisonResult = CompareResultDataSharingPageService.compareDataSharingVerificationProperties(page1, page2);
                    Object.assign(result, dataShareComparisonResult);
                }

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            /**
             * main comparison function to compare two pages
             */
            function compare(page1, page2) {
                var title;
                if (page1 == null) {
                    page1 = {};
                    title = 'Page ' + page2.localId;
                } else {
                    title = 'Page ' + page1.localId;
                }

                if (page2 == null) {
                    page2 = {};
                }

                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: comparePageDetail(page1, page2),
                    displayConfig: CompareResultDisplayConfigService.compare(page1.displayConfig, page1.displayConfig),
                    sections: CompareResultUtilService.compareArrays(page1.sections, page2.sections, title,
                        CompareResultSectionService.compare)
                };

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

