/**
 * Created by moustafabaiou on 6/13/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('hoo', function hoo() {
            var daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

            var map = {
                0: 'M',
                1: 'T',
                2: 'W',
                3: 'Th',
                4: 'F',
                5: 'Sat',
                6: 'Sun'
            };

            /**
             * a filter for hours of operation that will return the appropriate days
             * @param days
             * @returns {*}
             */
            function hooFilter(days) {
                var returnString = '';
                var array = [];
                var orderedArray = {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false,
                    6: false
                };
                days.forEach( function (day) {
                    var dayNumber = daysOfTheWeek.indexOf(day);
                    if(dayNumber > -1){
                        orderedArray[dayNumber] = true;
                    }else{
                        return 'INVALID DAY ARRAY'
                    }
                });
                angular.forEach(orderedArray, function (day, index) {
                    if(day){
                        array.push(index);
                    }
                });
                array = getRanges(array);


                returnString = array.join();

                for( var i = 0; i < 7; i++){
                    var ind = String(i);
                    var replacement = map[i];
                    if(returnString.indexOf(ind) > -1){
                        returnString = returnString.replace(ind, replacement);
                    }
                }

                return returnString;
            }

            function areAdjacent(x, y) {
                x = Number(x);
                y = Number(y);
                var adjacent = (x + 1 === y) || (x === 6 && y === 0);
                return adjacent;
            }

            function getRanges(array) {
                var ranges = [], rstart, rend;
                for (var i = 0; i < array.length; i++) {
                    rstart = array[i];
                    rend = rstart;
                    while (areAdjacent(array[i], array[i+1])) {
                        rend = array[i + 1]; // increment the index if the numbers sequential
                        i++;
                    }
                    ranges.push(rstart == rend ? rstart+'' : rstart + ' - ' + rend);
                }
                return ranges;
            }


            return hooFilter;
        });

})();

