/**
 * regenerate pdf directive
 *
 * // todo: there are a lot of similarity for this directive and drcSyncPDF directive
 * // it would be good to refactor the code to make it generic and can be reused
 * // unfortunately, we don't have the time for it in a request for hotfix prod issue
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('regeneratePdf', function (FormEntryUtilService, vbrCommonUtil, AlertService, $http, $window) {

        /***************************************
         * factory return call
         ***************************************/
        const statusList = {
            /**
             * initial status
             */
            NONE: {text: 'NONE'},
            /**
             * just starting, still processing the CSV file
             */
            STARTING: {text: 'STARTING', css: 'badge-info'},
            /**
             * actually processing the form entries
             */
            RUNNING: {text: 'RUNNING', css: 'badge-info'},
            /**
             * failed to process the csv
             */
            FAILED: {text: 'FAILED', css: 'badge-danger'},
            /**
             * stopping, user has clicked the stop button but there are still ongoing calls
             */
            STOPPING: {text: 'STOPPING', css: 'badge-info'},
            /**
             * the processing has fully stopped after user stopped it prematurely
             */
            STOPPED: {text: 'STOPPED', css: 'badge-warning'},
            /**
             * the processing has fully completed
             */
            COMPLETED: {text: 'COMPLETED', css: 'badge-success'},
        };

        return {
            restrict: 'E',
            templateUrl: "admin-templates/site/tools/regeneratePDF/regeneratePDF.html",
            scope: {},
            link: function($scope) {
                $scope.csv = vbrCommonUtil.defaultCSVConfig();
                $scope.statusList = statusList;
                $scope.maxCallValues = [1, 5, 10];
                $scope.data = {
                    counts: {
                        success: 0,
                        failed: 0,
                        currentWaitingCalls: 0,
                    },
                    inputs: {
                        // a list of id objects of {userId: int, formEntryId: int}
                        idObjects: [],
                    },
                    // results of the sync, will be used to display in table and download to csv file
                    // format would be {userId, formEntryId, timestamp, status}
                    // status is just simply success for fail
                    results: [],

                    // status of the operation
                    status: statusList.NONE,
                };

                $scope.regeneratePDF = function() {
                    regeneratePDF($scope);
                };

                $scope.canStop = function() {
                    return $scope.data.status === statusList.RUNNING;
                };

                $scope.stop = function() {
                    // this just set the status to stopping, the actually algorithm will read the status and stop it gracefully
                    $scope.data.status = statusList.STOPPING;
                };

                $scope.isRunning = function() {
                    const {status} = $scope.data;
                    return status === statusList.RUNNING || status === statusList.STARTING || status === statusList.STOPPING;
                }

                $scope.canDownload = function() {
                    return $scope.data.results!=null && $scope.data.results.length > 0;
                };

                $scope.download = function () {
                    return $scope.data.results;
                };
            }
        };

        /**
         * regenerate the pdf
         * @param $scope scope object
         */
        function regeneratePDF($scope) {
            validateAndPopulateInputs($scope);
            const idObjects = $scope.data.inputs.idObjects;
            if (idObjects!=null && idObjects.length>0) {
                performApiCalls($scope);
            }
        }

        /**
         * process one user/form entry object
         * @param idObject - id object holding user id and entry id
         * @param results - results array to add new results to, note: new result is added to the beginning of the array
         * @param counts - counts object to update success, fail, currentWaitingCall
         */
        function processUserObject (idObject, results, counts) {
            // increase the count to know a call is ongoing
            counts.currentWaitingCalls++;

            performSyncForOneEntry(idObject.userId, idObject.formEntryId).then(function() {
                results.unshift({
                    userId: idObject.userId,
                    formEntryId: idObject.formEntryId,
                    timestamp: new Date(),
                    status: 'SUCCESS'
                });
                counts.success++;
            }, function(err) {
                const result = {
                    userId: idObject.userId,
                    formEntryId: idObject.formEntryId,
                    timestamp: new Date(),
                    status: 'FAIL'
                };
                results.unshift(result);
                counts.failed++;
            }).finally(function() {
                // finish the call by decrease the count
                counts.currentWaitingCalls--;
            });
        }

        /**
         * reset all the counts to 0 and results to empty array, used at the beginning of the sync process
         * @param data - data object holding both counts and results
         */
        function resetCountsAndResults (data) {
            data.counts.success = 0;
            data.counts.failed = 0;
            data.counts.currentWaitingCalls = 0;
            data.counts.totalUsers = data.inputs.idObjects==null ? 0 : data.inputs.idObjects.length;
            data.results = [];
        }

        /**
         * recursively call itself to wait until all the calls are finished
         * @param data - data object with counts and status
         * @param $scope - $scope object to refresh the GUI after wait is completed
         */
        function waitUntilAllCallsAreFinished (data, $scope) {
            const {counts, results} = data;
            console.log('stopping, waiting for calls to finish', counts.currentWaitingCalls);
            if (counts.currentWaitingCalls===0) {
                // everything is finished
                if (counts.totalUsers === results.length) {
                    data.status = statusList.COMPLETED;
                }
                else {
                    data.status = statusList.STOPPED;
                }

                // refresh the scope so UI will update, this is needed due to the use of setInterval()
                // which is outside of the knowledge of angular
                vbrCommonUtil.safeApply($scope);
                return;
            }

            // check every 100ms to see if they are finished
            $window.setTimeout(function() {
                waitUntilAllCallsAreFinished(data, $scope);
                }, 100);
        }

        /**
         * the main function of the operation, it will make calls to server and check for exit condition.
         * note: this is a recursive function with timeout to allow us to limit the amount of open api calls.  Also, the reason
         * why so many variables are being passed even though all of them are already inside $scope is for performance
         * @param maxCalls - max number of calls can be opened with server at one time
         * @param idObjects - the list of id objects holding the user id and form entry id, we will always process the first item and then
         *                    make a recursive call again to process the next item
         * @param results - array object holding the results of the processing
         * @param counts - counts object holding various counts (success, failed, total, ongoing calls, etc)
         * @param data - data object with the status flag
         * @param $scope - $scope object used to refresh the GUI when needed
         */
        function performApiCallsHelper (maxCalls, idObjects, results, counts, data, $scope) {
            if (idObjects==null || idObjects.length===0) {
                // we have finished processing everything
                data.status = statusList.COMPLETED;
                // refresh the scope so UI will update, this is needed due to the use of setInterval()
                // which is outside of the knowledge of angular
                vbrCommonUtil.safeApply($scope);
                return;
            }

            // if user has asked us to stop, we should just wait until all the calls are finished and return, no
            // more processing from this point on
            if (data.status === statusList.STOPPING) {
                waitUntilAllCallsAreFinished(data, $scope);
                return;
            }

            // need to use recursive callback mechanism to allow javascript to block on ajax calls
            if (counts.currentWaitingCalls < maxCalls) {
                // take the first idObject out of the list and process it
                const idObject = idObjects.shift();

                // no more wait, the current waiting call is less than max calls allowed
                processUserObject(idObject, results, counts);

                // recursive call to process the next element
                performApiCallsHelper(maxCalls, idObjects, results, counts, data, $scope);
            }
            else {
                console.log('waiting for calls to finish, calls = ' + counts.currentWaitingCalls);
                // there are too many calls ongoing, let's wait 100ms and check again
                $window.setTimeout(function() {
                    performApiCallsHelper(maxCalls, idObjects, results, counts, data, $scope);
                }, 100);
            }
        }

        /**
         * perform the api calls using recursive algorithm (has to be recursive since it's async)
         * @param $scope - $scope object
         */
        function performApiCalls($scope) {
            const {data} = $scope;
            data.status = statusList.RUNNING;
            resetCountsAndResults(data);
            const {results, counts, inputs} = data;
            const {idObjects} = inputs;


            // need to control this, or else the browser will start hanging
            const maxCalls = 10;

            performApiCallsHelper(maxCalls, idObjects, results, counts, data, $scope);
        }

        /**
         * validate the inputs, mostly the
         * @param $scope
         */
        function validateAndPopulateInputs($scope) {
            $scope.data.status = statusList.STARTING;
            let parsedObject = parseCsv($scope.csv);
            $scope.data.inputs.idObjects = null;

            if(parsedObject.isValid) {
                $scope.data.inputs.idObjects = parsedObject.list;
            }
            else {
                $scope.data.status = statusList.FAILED;
                AlertService.error('Unable to parse csv file, did you have the correct format?');
            }
        }

        /**
         * parse the csv object from library to our object list
         * @param csv - csv input, key field is csv.result where it stores the actual loaded data
         * @returns {{isValid: *, list: *}}  - return an object of list and isValid flag
         */
        function parseCsv(csv) {
            let list = null;
            let isValid = true;

            if (!csv.result || csv.result.length === 0) {
                AlertService.warning('There is nothing in uploaded CSV file');
                isValid = false;
            } else {
                const firstItem = csv.result[0];
                if (firstItem.userId!=null && firstItem.formEntryId!=null) {
                    // right format, load the list
                    // list to return
                    list = csv.result;
                }
                else {
                    isValid = false;
                }
            }

            return {
                list: list,
                isValid: isValid
            };
        }

        /**
         * perform the operation for one entry
         * @param userId - user id
         * @param formEntryId - form entry id
         * @returns {HttpPromise} - promise from server
         */
        function performSyncForOneEntry(userId, formEntryId) {
            return FormEntryUtilService.regeneratePDF(formEntryId, userId);
        }
    });

})();

