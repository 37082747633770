(function () {


    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of a list of Pages
     */
        .directive('treeNodePageList', function (ListContainerService, FormConstants) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/treeNode/pageList.html',
                scope       : {
                    currentTreeName : '=',
                    mode            : '=',
                    selector        : '=',
                },
                link : function ($scope) {
                    $scope.constants = FormConstants;
                    init($scope);
                },
            };

            function init($scope) {
                createContainer($scope);

                addWatches($scope);
            }

            function addWatches($scope) {
                // watch the mode change and when it happens, we need to recreate the container
                $scope.$watch('mode', function(oldMode, newMode) {
                    if (oldMode !== newMode) {
                        createContainer($scope);
                    }
                });
            }

            function createContainer($scope) {
                // create view container, this will make small list slower than normal, but
                // smaller list we don't need to care too much about performance anyway
                $scope.container = ListContainerService.createListContainer(
                    $scope, $scope.selector, $scope.currentTreeName,
                    $scope.mode.pages, 'mode.pages',
                    20, [20, 50, 100]
                );

                $scope.container.init();
            }

        });
})();

