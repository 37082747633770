/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('treeNodeTrigger', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/treeNode/trigger.html',
                scope: {
                    trigger: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.ruleUtil = RuleUtilService;
                }
            }
        });

})();

