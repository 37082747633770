/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/* eslint-disable vars-on-top */
/**
 * directive for displaying a form entry
 */
(function () {
    angular.module('acadiamasterApp').directive('vbrFormEntryDisplay', (
        FormEntryUtilService, AlertService, VbrLoggingDialogService, FormPreviewService,
        ngDialog, CONFIG, FormConstants, localStorageService, FieldEntryService, FormEntryAnswerService,
    ) => {
        return {
            link : function ($scope) {
                $scope.entryNameLengthLimit = 20;
                $scope.handleSelectedAnswers = FormEntryAnswerService.handleSelectedAnswers;
                $scope.drcEnabled = CONFIG.drcEnabled;
                if ($scope.formModel == null && $scope.formWrapper != null) {
                    $scope.formModel = $scope.formWrapper.formModelCopy;
                }

                // need to hold it inside an object because the reference of form entry might be recalculated
                // in admin portal preview
                $scope.data = {
                    formEntryLogsReadyV1 : false,
                    formEntryLogsReadyV2 : false,
                    formEntryLogsV1      : null,
                    formEntryLogsV2      : null,
                    formEntryUpdated     : false,

                    formFieldEntries : copyArray($scope.formEntry.formFieldEntries),

                    isStared : function (fieldEntry) {
                        return this.stared.indexOf(fieldEntry.formFieldId) != -1;
                    },
                    // a list of field ids where user has highlighted
                    stared       : [],
                    toggleStared : function (fieldEntry) {
                        let findIndex = this.stared.indexOf(fieldEntry.formFieldId);
                        if (findIndex == -1) {
                            this.stared.push(fieldEntry.formFieldId);
                        } else {
                            this.stared.splice(findIndex, 1);
                        }
                    },
                };

                $scope.editFieldValue = function (entryField) {
                    let user = localStorageService.get('user');
                    ngDialog.openConfirm({
                        controller : 'FormEntryFieldValueUpdateController',
                        data       : {
                            adminUserId : user.id,
                            entryField  : entryField,
                        },
                        template : 'admin-templates/site/formEntry/formEntry.fieldValueUpdate.html',
                    });
                };

                $scope.displayFieldEntryValueAsHtml = displayFieldEntryValueAsHtml;
                $scope.displayFormEntryEventLogAsHtml = displayFormEntryEventLogAsHtml;

                $scope.previewForm = function (formId, formVersionId) {
                    return previewForm($scope, formId, formVersionId);
                };

                $scope.getFileUrl = function (file) {
                    return `/api/file?fileName=${ file.url}`;
                };

                $scope.getEntryFieldInfoAsHtml = getEntryFieldInfoAsHtml;
                $scope.getFieldDetailsAsHtml = getFieldDetailsAsHtml;

                $scope.$watch(() => {
                    return $scope.formEntry;
                }, () => {
                    $scope.data.formFieldEntries = copyArray($scope.formEntry.formFieldEntries);
                    loadEntryLogs($scope, $scope.formEntry.id);
                });

                $scope.closeFormEntry = function () {
                    $scope.isShowFormEntry = false;
                };

                $scope.forceSyncFormEntry = function (formEntryId, userId) {
                    forceSyncFormEntry($scope, formEntryId, userId, ngDialog);
                };

                $scope.isConsentForm = form => {
                    return form != null && form.category === FormConstants.formCategories.CONSENT_FORM;
                };

                $scope.regeneratePDF = function (formEntryId, userId) {
                    regeneratePDF($scope, formEntryId, userId, ngDialog);
                };

                init($scope);
            },
            restrict : 'E',
            scope    : {
                formEntry        : '=',
                formModel        : '=?',
                formWrapper      : '=?',
                isInAdminPreview : '=?',
                isShowFormEntry  : '=',
                pdfData          : '=?',
                previewMode      : '=',
                subTitle         : '@',
                timezone         : '=',
                title            : '@',
            },
            templateUrl : 'admin-templates/site/formEntry/formEntry.detail.html',
        };

        function init ($scope) {
            if ($scope.isInAdminPreview) {
                $scope.$on(VbrLoggingDialogService.cs.STATE_RULE_VALUE_CHANGED, () => {
                    loadFormEntry($scope);
                    $scope.$apply();
                });
            }

            loadEntryLogs($scope, $scope.formEntry.id);
        }

        function loadEntryLogsV1 ($scope, entryId) {
            $scope.data.formEntryLogsReadyV1 = false;
            $scope.data.formEntryLogsV1 = null;
            FormEntryUtilService.loadActionLogsByEntryIdV1(entryId)
                .then(response => {
                    $scope.data.formEntryLogsV1 = response.data;
                }, error => {
                    console.error('no form entry logs to display', error);
                }).finally(() => {
                    $scope.data.formEntryLogsReadyV1 = true;
                });
        }

        function loadEntryLogsV2 ($scope, entryId) {
            $scope.data.formEntryLogsReadyV2 = false;
            $scope.data.formEntryLogsV2 = null;
            // try current API for getting logs
            FormEntryUtilService.loadActionLogsByEntryIdV2(entryId)
                .then(response => {
                    const sessions = response.data;
                    if (sessions && sessions.length > 0) {
                        // break each session's logs' fields into key/value pairs for display in the UI
                        const formattedSessions = sessions.map(session => ({
                            ...session,
                            formattedLogs : session.formEntryEventLogs.map(log => ({
                                ...log,
                                fields : Object.keys(log).map(key => (
                                    {
                                        isDate : key === 'timestampEvent',
                                        key    : key,
                                        value  : log[key],
                                    }
                                )),
                            })),
                        }));
                        $scope.data.formEntryLogsV2 = formattedSessions;
                    }
                }, error => {
                    console.error('no form entry logs to display', error);
                }).finally(() => {
                    $scope.data.formEntryLogsReadyV2 = true;
                });
        }

        function loadEntryLogs ($scope, entryId) {
            if (entryId) {
                loadEntryLogsV1($scope, entryId);
                loadEntryLogsV2($scope, entryId);
            } else {
                $scope.data.formEntryLogsReadyV1 = true;
                $scope.data.formEntryLogsReadyV2 = true;
            }
        }

        /*
         * load form entry and convert it to dto format for display
         */
        function loadFormEntry ($scope) {
            if ($scope.formWrapper) {
                let collectedFieldEntries = $scope.formWrapper.collectFormFieldEntries(true);

                $scope.data.formFieldEntries = [];
                _.forEach(collectedFieldEntries, fe => {
                    // need to convert to dto to match the other form entry format, may change later
                    let dto = fe.toDto();

                    // these two are added just for this and not in the output of toDto function
                    dto.pageNum = fe.pageNum;
                    dto.pageLocalId = fe.pageLocalId;
                    dto.fieldName = fe.fieldName;
                    $scope.data.formFieldEntries.push(dto);
                });
            }
        }

        function getEntryFieldInfoAsHtml (entryField, formModel) {
            let message = entryField.formFieldId;
            if (formModel !== null) {
                let editMode = formModel.formVersions[0].editMode;
                let field = editMode.findByUnversionedIdInLookupMap(entryField.formFieldId);
                // if (field && field.name && field.name.length > this.entryNameLengthLimit) {
                //     message = message + FieldEntryService.handleFieldName(field, this.entryNameLengthLimit);
                // } else {
                //     message = message + (field && field.name ? ` | ${ field.name}` : ' | No Field Name');
                // }
                if (field) {
                    message = message + (field && field.name ? ` | ${ field.name}` : ' | No Field Name');
                }
                message = `${message } | ${field.formField.type.text} <span class="page__id">(Page ID: ${entryField.pageLocalId})</span>`;
            }
            return message;
        }

        function getQuestionText (field) {
            switch (field.type) {
            case FormConstants.fieldsType.TEXT_INPUT:
            case FormConstants.fieldsType.PHONE_INPUT:
            case FormConstants.fieldsType.NUMBER_INPUT:
            case FormConstants.fieldsType.EMBEDDED_TEXT_INPUT:
                return field.fieldValue.value;
            case FormConstants.fieldsType.DAY_SELECTOR:
                return field.fieldValue.labelText;
            case FormConstants.fieldsType.SLIDER:
            case FormConstants.fieldsType.WHEEL:
            case FormConstants.fieldsType.RADIO_SELECTOR:
            case FormConstants.fieldsType.MULTI_SELECTOR:
            case FormConstants.fieldsType.DROPDOWN:
            case FormConstants.fieldsType.SIGNATURE_BOX:
            case FormConstants.fieldsType.TIME_PICKER:
                return field.subFields[0].fieldValue.value;
            case FormConstants.fieldsType.VIDEO_PLAYER:
                return `Video Player with ID: ${ field.fieldValue.value}`;
            case FormConstants.fieldsType.BOOLEAN_SELECTOR:
                return field.fieldValue.optionValue.text;
            default:
                break;
            }
            return '';
        }

        function getPossibleAnswers (field) {
            switch (field.type) {
            case FormConstants.fieldsType.RADIO_SELECTOR:
            case FormConstants.fieldsType.MULTI_SELECTOR:
            case FormConstants.fieldsType.DROPDOWN:
                var optionHtml = '<div class="possible__answers">Possible answers</div>';
                var options = field.subFields[1].fieldValue.values;
                for (let i = 0; i < options.length; i++) {
                    optionHtml = `${optionHtml }${options[i].text } (${ options[i].value })`;
                    if (i != options.length - 1) {
                        optionHtml = `${optionHtml }<br>`;
                    }
                }
                return optionHtml;
            default:
                break;
            }
            return '';
        }

        function getFieldDetailsAsHtml (entryField, formModel) {
            let message = '';
            if (formModel !== null) {
                let editMode = formModel.formVersions[0].editMode;
                let field = editMode.findByUnversionedIdInLookupMap(entryField.formFieldId);
                if (field != null) {
                    const noAnswers = 'Prefer not to answer';
                    let question = getQuestionText(field);
                    message = `${message }<div class="question">
                                    ${question !== noAnswers && question
        ? `<span class="question__q">Q.</span>${ question}` : question}
                                <div>`;
                    message = `${message }<div class="answers">${getPossibleAnswers(field)}<div>`;
                }
            }
            return message;
        }

        /*
         * display a field entry dto value as html
         * @param fieldEntry - field entry dto
         * @returns {string} - formatted html string
         */
        function displayFieldEntryValueAsHtml (fieldEntry, timezone) {
            let entryValues = fieldEntry.formFieldEntryValues;
            let valueString = [];
            _.forEach(entryValues, pv => {
                // todo: fix this later, this is not the right way to do it, correct way is to
                // retrieve the form field information and get the value type
                let minimumDateValue = 500000000;
                let isDate = pv.valueAsNumber != null && pv.valueAsNumber > minimumDateValue;
                valueString.push(FormEntryUtilService.convertFieldEntryValueToHtml(pv, isDate, timezone));
            });

            return _.join(valueString, ', ');
        }

        function copyArray (arrayInput) {
            let output = [];
            if (arrayInput != null && arrayInput.length > 0) {
                // fastest way to copy an array in most browser
                output = arrayInput.slice(0);
            }
            return output;
        }

        function previewForm ($scope, formId, formVersionId) {
            let form = {
                id : formId,
            };
            FormPreviewService.preview(form, formVersionId);
        }

        /*
         * display form_entry_action_record row as html
         * @param logEvent - a roew in form_entry_action_record
         */
        function displayFormEntryEventLogAsHtml (logEvent) {
            return logEvent.logText;
        }

        function forceSyncFormEntry (outsideScope, formEntryId, userId, ngDialog) {
            ngDialog.openConfirm({
                controller : [ '$scope', 'drcService', 'FormConstants', function ($scope, drcService, FormConstants) {
                    $scope.data = {
                        requestSent          : false,
                        shouldRegeneratePdfs : false,
                    };
                    $scope.confirmForceSync = function () {
                        drcService.forceSyncFormEntry($scope.ngDialogData.userId, $scope.ngDialogData.formEntryId, $scope.data.shouldRegeneratePdfs)
                            .then(response => {
                                AlertService.success(response.data?.result);
                                $scope.confirm(true);
                            }).catch(error => {
                                AlertService.error(error.description, null, error);
                                $scope.confirm(false);
                            });
                        $scope.data.requestSent = true;
                    };
                    $scope.isConsentForm = function () {
                        return $scope.ngDialogData.formCategory === FormConstants.formCategories.CONSENT_FORM;
                    };
                } ],
                data : {
                    formCategory : outsideScope.formModel.category,
                    formEntryId  : formEntryId,
                    userId       : userId,
                },
                template : 'admin-templates/site/formEntry/formEntry.forceSync.confirmation.popup.html',
            }).then(formEntryUpdated => {
                outsideScope.data.formEntryUpdated = formEntryUpdated;
            });
        }

        function regeneratePDF (outsideScope, formEntryId, userId, ngDialog) {
            ngDialog.openConfirm({
                controller : [ '$scope', 'FormEntryUtilService', function ($scope, FormEntryUtilService) {
                    $scope.data = {
                        requestSent : false,
                    };

                    $scope.confirmAction = function () {
                        FormEntryUtilService.regeneratePDF(formEntryId, userId)
                            .then(response => {
                                AlertService.success(response.data?.result);
                                $scope.confirm(true);
                            }).catch(error => {
                                AlertService.error(error.description, null, error);
                                $scope.confirm(false);
                            });
                        $scope.data.requestSent = true;
                    };
                } ],
                data : {
                    formEntryId : formEntryId,
                    userId      : userId,
                },
                template : 'admin-templates/site/formEntry/formEntry.regeneratePDF.confirmation.popup.html',
            }).then(formEntryUpdated => {
                outsideScope.data.formEntryUpdated = formEntryUpdated;
            });
        }
    });
}());
