/**
 * directive for expression parser editing
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('expressionParserEdit', (ExpressionParserService, $timeout, $window, ExpressionConstantsService, ExpressionCommentService) => {
            let expressionParserController = function ($scope) {
                $scope.isOpen = {
                    functions : false,
                    howTo     : false,
                    useCases  : false,
                };

                $scope.toggles = {
                    shouldShowSecondPanel : function () {
                        return this.showUsage || this.showHelp || this.showExpressionTree;
                    },
                    showExpressionTree : false,
                    showHelp           : false,
                    showUsage          : false,
                };

                $scope.originalExpression = angular.copy($scope.expressionData.expression);

                function debounce (callback, wait) {
                    let timeout;
                    return function () {
                        let context = this, args = arguments;
                        let later = function () {
                            timeout = null;
                            callback.apply(context, args);
                        };
                        $timeout.cancel(timeout);
                        timeout = $timeout(later, wait);
                    };
                }

                function expressionCompletions (editor, session, pos, prefix, callback) {
                    callback(null, $scope.aceFunctionObjects);
                }

                function onAceLoaded (editor) {
                    // Create a completer for matching
                    let expressionCompleter = {
                        getCompletions : expressionCompletions,
                    };

                    editor.completers.push(expressionCompleter);
                    editor.focus();
                }

                // Wrap in debounce function to avoid rapid calls to parse function
                let validate = debounce(() => {
                    if (!$scope.expressionData.expression
                        || $scope.expressionData.expression.length === 0) {
                        $scope.expressionData.model = null;
                        $scope.expressionError = null;
                        $timeout(() => {
                            if ($scope.expressionData.element && $scope.expressionData.element.clearError) {
                                $scope.expressionData.element.clearError();
                            }
                        });
                    } else {
                        $timeout(() => {
                            // Create new expression string without comments to validate
                            let expressionNoComment = ExpressionCommentService.expressionWithoutComments($scope.expressionData.expression);

                            $scope.expressionData.model = ExpressionParserService.parse(expressionNoComment,
                                $scope.expressionData.usedBy, $scope.expressionData.element, $scope.expressionData.variables);
                        });
                    }
                }, 250);

                function format () {
                    if ($scope.expressionData.model && !$scope.expressionData.model.hasError()) {
                        let expFromExpressionDataModel = $scope.expressionData.model.toFormattedString(0, false);
                        $scope.modelCopy = $scope.expressionData.model;
                        // Attach comments from commentMap to create new expression string with contains comments
                        //for now we are skipping this logic for applying comments
                        // $scope.expressionData.expression = ExpressionCommentService.applyComment(expFromExpressionDataModel, $scope.expressionData.expression).newExpressions;
                        $scope.expressionData.expression = expFromExpressionDataModel;
                    }
                }

                $scope.initialExpression = $scope.expressionData.expression;
                $scope.initialModel = $scope.expressionData.model;

                // This is used for information gathering. Information gathering on each keypress is too heavy
                // Information gathering is done initially and only when the expression is formatted (Not as user types)
                $scope.modelCopy = $scope.expressionData.model;

                $scope.clear = function () {
                    $scope.expressionData.expression = '';
                    $scope.expressionData.model = null;
                    $scope.expressionError = null;
                };

                $scope.reset = function () {
                    $scope.expressionData.expression = $scope.initialExpression;
                    $scope.expressionData.model = $scope.initialModel;
                    $scope.expressionError = null;
                };

                $scope.shouldBeDisabled = function () {
                    return $scope.expressionData.expression
                        && $scope.expressionData.expression.length > 0
                        && (!$scope.expressionData.model
                            || $scope.expressionData.model.hasError()
                        );
                };

                /**
                 * Builds the regex for pattern matching functions and autocomplete objects used by the Ace editor
                 */
                function buildAceFunctionObjects () {
                    let aceFunctionObjects = [ {
                        meta  : '',
                        name  : 'true',
                        score : 1,
                        value : 'true',
                    }, {
                        meta  : '',
                        name  : 'false',
                        score : 1,
                        value : 'false',
                    } ];
                    let expressionRegex = [];

                    // Iterate through the functions and set up values for Ace to use
                    Object.keys(ExpressionConstantsService.functions).forEach(key => {
                        let fn = ExpressionConstantsService.functions[key];

                        // only use functions that have a usedBy value matching the current scope
                        if (fn.usedBy.indexOf($scope.expressionData.usedBy) !== -1) {
                            expressionRegex.push(`\\b${ fn.name }\\b`);
                            aceFunctionObjects.push({
                                meta  : fn.description,
                                name  : fn.name,
                                score : 1,
                                value : fn.name,
                            });
                        }
                    });

                    $scope.expressionRegex = expressionRegex.join('|');
                    $scope.aceFunctionObjects = aceFunctionObjects;
                }

                function init () {
                    buildAceFunctionObjects();

                    // Create a custom Ace mode with customized highlighting
                    $window.ace.define('ace/mode/expression_builder', (require, exports) => {
                        let oop = require('ace/lib/oop');
                        let TextMode = require('ace/mode/text').Mode;
                        let ExpressionHighlightRules = require('ace/mode/expression_highlight_rules').ExpressionHighlightRules;
                        let Mode = function () {
                            this.HighlightRules = ExpressionHighlightRules;
                        };
                        oop.inherits(Mode, TextMode);
                        exports.Mode = Mode;
                    });

                    // Create the highlight rules for the custom mode
                    $window.ace.define('ace/mode/expression_highlight_rules', (require, exports) => {
                        let oop = require('ace/lib/oop');
                        let TextHighlightRules = require('ace/mode/text_highlight_rules').TextHighlightRules;
                        let ExpressionHighlightRules = function () {
                            // set up highlight rules for all functions, true & false, and string literals
                            this.$rules = {
                                // strings with double quotes
                                qqstring : [
                                    {
                                        onMatch : null,
                                        regex   : /\\(?:x[\da-fA-F]{2}|u[\da-fA-F]{4}|u\{[\da-fA-F]{1,6}\}|[0-2][0-7]{0,2}|3[0-7]{1,2}|[4-7][0-7]?|.)/,
                                        token   : 'constant.language.escape',
                                    },
                                    {
                                        consumeLineEnd : true,
                                        onMatch        : null,
                                        regex          : /\\$/,
                                        token          : 'string',
                                    },
                                    {
                                        next    : 'start',
                                        onMatch : null,
                                        regex   : /"|$/,
                                        token   : 'string',
                                    },
                                    { defaultToken : 'string' },
                                ],
                                // strings with single quotes
                                qstring : [
                                    {
                                        onMatch : null,
                                        regex   : /\\(?:x[\da-fA-F]{2}|u[\da-fA-F]{4}|u\{[\da-fA-F]{1,6}\}|[0-2][0-7]{0,2}|3[0-7]{1,2}|[4-7][0-7]?|.)/,
                                        token   : 'constant.language.escape',
                                    },
                                    {
                                        consumeLineEnd : true,
                                        onMatch        : null,
                                        regex          : /\\$/,
                                        token          : 'string',
                                    },
                                    {
                                        next    : 'start',
                                        onMatch : null,
                                        regex   : /'|$/,
                                        token   : 'string',
                                    },
                                    { defaultToken : 'string' },
                                ],
                                start : [
                                    // string detection
                                    {
                                        next    : 'qstring',
                                        onMatch : null,
                                        regex   : /'(?=.)/,
                                        token   : 'string',
                                    },
                                    {
                                        next    : 'qqstring',
                                        onMatch : null,
                                        regex   : /"(?=.)/,
                                        token   : 'string',
                                    },
                                    // booleans
                                    {
                                        regex : /\btrue\b|\bfalse\b/,
                                        token : 'variable',
                                    },
                                    // expression functions
                                    {
                                        regex : new RegExp($scope.expressionRegex),
                                        token : 'support.function',
                                    },
                                ],
                            };
                        };
                        oop.inherits(ExpressionHighlightRules, TextHighlightRules);
                        exports.ExpressionHighlightRules = ExpressionHighlightRules;
                    });

                    // set up Ace
                    $scope.aceOptions = {
                        advanced : {
                            enableBasicAutocompletion : true,
                            enableLiveAutocompletion  : true,
                        },
                        highlightSelectedWord : true,
                        mode                  : 'expression_builder',
                        onBlur                : format,
                        onChange              : validate,
                        onLoad                : onAceLoaded,
                        require               : [ 'ace/ext/language_tools' ],
                        showPrintMargin       : false,
                        theme                 : 'tomorrow_night',
                        useWrapMode           : true,
                        workerPath            : 'workers',
                    };
                }

                init();
            };

            return {
                controller : expressionParserController,
                restrict   : 'E',
                scope      : {
                    closeFunction  : '=',
                    expressionData : '=',
                },
                templateUrl : 'admin-templates/util/expression/expression.edit.html',
            };
        });
}());
