/**
 * Created by moustafabaiou on 2/17/17
 */
angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of state rule trigger list
     */
    .directive('treeNodeFormTriggerList', (FormUtilService, FormConstants, $timeout,
        ngDialog) => ({
        controller: ($scope) => {
            $scope.cs = FormUtilService.cs;
            $scope.allTriggerTypes = angular.copy(FormUtilService.formStateTriggerService.allTriggerTypes);

            $scope.states = {
                isAddTriggerPopupOpen: false,
            };

            $scope.showAddTriggerDialog = ($event) => {
                $scope.states.isAddTriggerPopupOpen = true;
                ngDialog.openConfirm({
                    className: 'ngdialog-theme-plain custom-width-small',
                    preCloseCallback: () => {
                        $timeout(() => {
                            $scope.states.isAddTriggerPopupOpen = false;
                        });
                    },
                    scope: $scope,
                    templateUrl: 'admin-templates/site/components/treeEditorDialogs/addStateRuleTrigger.template.html',
                }).then((triggerType) => {
                    $scope.element.addTrigger(triggerType, $scope.selector, $scope.currentTreeName);
                });
                $event.stopPropagation();
            };

            // Controls
            $scope.getTriggerTypes = () => {
                // get form mode object parent for specified element
                const formMode = $scope.element.getFormMode();

                _.forEach($scope.allTriggerTypes, (triggerType) => {
                    triggerType.isHidden = !_.some(triggerType.triggerType.availableInModes, {
                        name: formMode.nodeType.name,
                    });
                });
                return $scope.allTriggerTypes;
            };

            /**
                 * check if the current item in buffer can be pasted as a child
                 * @returns {boolean}
                 */
            $scope.canPasteAsChild = () => {
                const itemInBuffer = $scope.selector.getItemInBuffer();

                if (itemInBuffer == null) {
                    return false;
                }


                // check for matching type and make sure this is not already a child
                return itemInBuffer.nodeType == FormConstants.nodeType.RULE_TRIGGER
                        && itemInBuffer._parent != $scope.element;
            };


            /**
                * paste the last element copied or cut as a child
                * ie: this element copied of cut must be a form state rule trigger
                */
            $scope.doPasteAsChild = () => {
                let elementInBuffer = $scope.selector.getItemInBuffer();

                // remove the element from its previous parent
                const oldParent = elementInBuffer._parent;

                if ($scope.selector.isBufferCut) {
                    // cut
                    oldParent.removeTrigger(elementInBuffer);
                } else {
                    // make a copy
                    elementInBuffer = elementInBuffer.customClone();
                }

                // link it to this element
                elementInBuffer._parent = $scope.element;

                // add it to the top of the children array
                $scope.element.triggerList.unshift(elementInBuffer);
                $scope.selector.selectItem(elementInBuffer);

                $scope.selector.itemPasted();
            };
        },
        restrict: 'E',
        scope: {
            currentTreeName: '=',
            element: '=',
            selector: '=',
        },
        templateUrl: 'admin-templates/site/forms/treeNode/formStateRule.triggerList.html',
    }));
