
/**
 *
 * Model for an individual container dependency linking
 */

angular.module('acadiamasterApp')

    .factory('ContainerDpLinkModel', ($q, $translate, ModelServiceBase) => {
        /**
         * @constructor
         */
        function ContainerDpLinkModel() {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false);
            this.sourceType = null;
            this.sourceId = null;
            this.dependencyType = null;
            this.dependencyId = null;
            this.dependencyStringId = null;
            this.programId = null;
            this.id = null;
         }

        /**
         * Convert the DTO object into current object.
         * This function will wipe out any information you have on the current object
         * @param dto
         */
        ContainerDpLinkModel.prototype.fromDto = function fromDto(dto) {
            this.sourceType = dto.sourceType;
            this.sourceId = dto.sourceId;
            this.dependencyType = dto.dependencyType;
            this.dependencyId = dto.dependencyId;
            this.dependencyStringId = dto.dependencyStringId;
            this.programId = dto.programId;
            this.id = dto.id;

            return this;
        };


        /****************************************
         * service return call
         ****************************************/
        return ContainerDpLinkModel;
    });
