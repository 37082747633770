'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('user-management', {
                parent: 'accessControl',
                url: '/users',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'user-management.home.title'
                },
                params: { defaultRoleCategory: null },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/admin/user-management/user-management.html',
                        controller: 'UserManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('user-management-detail', {
                parent: 'user-management',
                url: '/user/:id',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'user-management.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/admin/user-management/user-management-detail.html',
                        controller: 'UserManagementDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('user.management');
                            return $translate.refresh();
                        }]
                }
            })
        .state('user-management-new', {
            parent: 'user-management',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'user-management.home.createLabel'
            },
            views: {
                'content@': {
                    templateUrl: 'admin-templates/admin/user-management/createAdminUser.html',
                    controller: 'CreateAdminUserController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
            }
        });
    });
