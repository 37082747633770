'use strict';


angular.module('acadiamasterApp')
    .directive('vbrUnauthenticatedTaskSearch', function (ParseLinks,ngDialog, ProgramService, UnauthenticatedTaskService, FormSearch) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/configPanel/action/unauthenticatedTask/unauthenticated-task-search.html',
            scope: {
                action: '=',
                options: '='
            },
            link: function (scope) {
                scope.search = {
                    searchComplete: false,
                    searchString: "",
                    page: 0,
                    pageSize: getPageSize(scope.options),
                    searchLabel: getSearchLabel(scope.options),
                    changeLabel: getChangeLabel(scope.options),
                    links: null,
                    allPrograms: [],
                    allTasks: null,
                    taskIds: [],
                    forms: [],
                    unauthenticatedTaskId: scope.action.unauthenticatedTaskId
                };
                scope.searchTask = function () {
                    ngDialog.openConfirm({
                        template: 'taskSearchDialog',
                        controller: ['$scope', function ($scope) {
                            $scope.search = scope.search;
                            $scope.data = scope.data;
                            $scope.controls = scope.controls;
                        }],
                        className: 'ngdialog-theme-default large-dialog-width'
                    });
                };

                scope.removeTask = function() {
                     scope.options.taskId = null;
                     scope.action.unauthenticatedTaskId=null;
                };

                scope.hasTitle = function () {
                    return (scope.options && scope.options.title && scope.options.title.length > 0);
                };

                scope.controls = {
                    parent : scope,
                    loadPage: function (page) {
                        return loadPage(this.parent, page);
                    },
                    searchUnauthenticatedTask: function() {
                        return searchUnauthenticatedTask(this.parent);
                    },
                    selectUnauthenticatedTask: function(unauthenticatedTask) { return selectUnauthenticatedTask(this.parent, unauthenticatedTask); },
                };
                init(scope);
            }
        };
        /*************************************************************************************
         * private functions
         */

         function init(scope) {
                ProgramService.getAllPrograms().then(function (response) {
                //Get all Programs in the portal
                scope.search.allPrograms = response.data;
                for (var program = 0; program < scope.search.allPrograms.length; program++) {
                //Get tasks for all programs available
                UnauthenticatedTaskService.getAllTasks(scope.search.allPrograms[program].id).then(function (tasks) {
                if (tasks.data) {
                     if (scope.search.allTasks == null) {
                         scope.search.allTasks = tasks.data;
                     } else {
                       for (var task = 0; task < tasks.data.length; task++) {
                               scope.search.allTasks.push(tasks.data[task]);
                       }
                     }
                   }
                });
              }
            });
         }

        function selectUnauthenticatedTask(scope, taskId) {
            scope.options.taskId = taskId.id;
            scope.search.searchComplete= false;
            scope.search.searchString= "";
            scope.search.taskIds = [];
            scope.search.page =0;
            scope.search.links=null;
            addWatches(scope,taskId);
            ngDialog.close(taskId);
        }

        function loadPage(scope, page) {
            scope.search.page = page;
            searchUnauthenticatedTask(scope);
        }

        function searchUnauthenticatedTask(scope) {
            scope.search.searchComplete = false;
            if (scope.search.allTasks) {
            scope.search.taskIds = [];
            for (var task = 0; task < scope.search.allTasks.length; task++) {
                if (scope.search.allTasks[task].taskInfo.formId == scope.search.searchString || scope.search.allTasks[task].id == scope.search.searchString) {
                          scope.search.taskIds.push(scope.search.allTasks[task]);
                 }
              }
               scope.search.searchComplete = true;
           }
            if(scope.search.taskIds.length == 0){
                  searchFormForUnauthenticatedTask(scope,scope.search.searchString);
            }
        }

        function searchFormForUnauthenticatedTask(scope,searchString){
                        let searchFilter = {
                             searchString: searchString,
                             formCategory: 'DATA_FORM',
                        };
                        let promise = FormSearch.query(searchFilter);
                        return promise.then((result) => {
                        scope.search.forms = [];
                        if (result!=null) {
                            scope.search.links = ParseLinks.parse(result.headers('link'));
                            var data = result.data;
                            if (data != null) {
                            for (var i = 0; i < data.length; i++) {
                                    scope.search.forms[i] = data[i];
                             }
                         }
                      }
                      if(scope.search.forms.length > 0){
                        if (scope.search.allTasks) {
                             for (var task = 0; task < scope.search.forms.length; task++) {
                                 scope.search.forms.id = scope.search.forms[task].id;
                             }
                             for (var task = 0; task < scope.search.allTasks.length; task++) {
                             if (scope.search.allTasks[task].taskInfo.formId == scope.search.forms.id) {
                                   scope.search.taskIds.push(scope.search.allTasks[task]);
                              }
                         }
                         }
                      }
                });

        }

         function addWatches(scope,taskId) {
            scope.action.unauthenticatedTaskId = taskId.id;
         }

        function getPageSize(options) {
            if (options == null || options.pageSize == null || !_.isNumber(options.pageSize)) {
                return 10;
            } else {
                return options.pageSize;
            }
        }

        function getSearchLabel(options) {
            if (options == null || options.searchLabel == null || options.searchLabel.length == 0) {
                return "Search";
            } else {
                return options.searchLabel;
            }
        }

        function getChangeLabel(options) {
            if (options == null || options.changeLabel == null || options.changeLabel.length == 0) {
                return "Change";
            } else {
                return options.changeLabel;
            }
        }

    });




