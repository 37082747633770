/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('FormAssociationUtil', function (FormSearch, FormUtilService, FormPreviewService, FormConstants) {
            return {
                searchForms: searchForms,
                searchSupportData: searchSupportData
            };

            function searchForms($scope) {
                FormSearch.query({
                    page: 0,
                    size: $scope.searchData.pageSize,
                    ascending: $scope.searchData.ascending,
                    orderBy: $scope.searchData.orderBySelected,
                    searchString: $scope.searchData.searchString == null || $scope.searchData.searchString.length == 0 ? null : $scope.searchData.searchString,
                    formCategory: $scope.searchData.formCategory,
                    formSearchType: $scope.searchData.formSearchType
                }).then(function (response) {
                    $scope.searchData.forms = response.data;
                    $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                    $scope.states.formsReady = true;
                });
            }

            function searchSupportData() {
                return {
                    orderByChoices: ['updatedOn', 'id', 'name', 'displayName'],
                    formCategory: FormConstants.formCategories,
                    formSearchTypes:FormConstants.formSearchTypes
                };
            }
        });

})();

