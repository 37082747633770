(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for reference label base
     */
        .directive('formFieldConfigFieldValueReferenceLabel', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/viewReferenceLabel.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    selector: '=',
                    field: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;

                    $scope.editMode = $scope.field.findParentMode()._parent.editMode;
                    
                    $scope.filterOutLabels = function (listToFilterFrom) {
                        var textLabels = [];
                        if (listToFilterFrom != null) {
                            for (var i = 0; i < listToFilterFrom.length; i++) {
                                if (listToFilterFrom[i].type == FormConstants.fieldsType.TEXT_LABEL ||
                                    listToFilterFrom[i].type == FormConstants.fieldsType.RICH_TEXT_LABEL ||
                                    listToFilterFrom[i].type == $scope.constants.subFieldsType.TITLE) {
                                    textLabels.push(listToFilterFrom[i]);
                                }
                            }
                        }
                        return textLabels;
                    };
                }
            };

        });

})();

