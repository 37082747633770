/**
 * Created by ashmita kumar on 9/6/2017
 */
 import template from './inviteCodesUsage.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('inviteCodeUsage', function (InviteCodesService, Property, toastr) {
        return {
            restrict: 'E',
            template:template,
            scope: {
                code: '@'
            },
            link: function ($scope) {
                $scope.status = {
                    isReady : false
                };

                $scope.data = {
                    usages : null
                };
                init($scope);
            }
        };

        /* ---------------------------------------------------------
         private functions
         * --------------------------------------------------------- */

        function init($scope) {
            InviteCodesService.getUsage($scope.code, 0, 100)
                .then(function(inviteCodeUsageView) {
                    $scope.data.inviteCode = inviteCodeUsageView.inviteCode;
                    $scope.data.usages = inviteCodeUsageView.usages.content;
                    $scope.status.isReady = true;
                    setPropertyNames($scope);
                })
                .catch(function(error) {
                    $scope.status.isReady = true;
                    $scope.success = null;
                    $scope.error = 'ERROR';
                    console.log(error);
                    toastr.error('Usage for this invite code not found', 'Error');
                });
        }

        function setPropertyNames($scope) {
            if ($scope.data && $scope.data.inviteCode && $scope.data.inviteCode.metaData && $scope.data.inviteCode.metaData.profilePropertyValues) {
                $scope.data.inviteCode.metaData.profilePropertyValues.forEach(function (associatedProperty) {
                    Property.get({id: associatedProperty.propertyId},
                        function (response) {
                            associatedProperty.propertyName = response.name ;
                        });
                });
            }
        }
    });
})();
