/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form field
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultFieldService',
        function (CompareResultUtilService, CompareResultDisplayConfigService, CompareResultSubFieldService,
                  CompareResultFieldValueService, CompareResultValidationRuleService) {

            function compareFieldDetail(field1, field2) {
                var result = {};

                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "localId", result);
                CompareResultUtilService.compareNamedObjectsByKey(field1, field2, "type", result);
                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "name", result);
                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "displayOrder", result);
                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "contentDescription", result);

                // localization map
                CompareResultUtilService.compareMaps(field1.localizationMap, field2.localizationMap, "lm", result);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;

            }

            function compare(field1, field2, parentTitle) {
                var title;
                if (field1 == null) {
                    field1 = {};
                    title = "Field " + field2.localId;
                }
                else {
                    title = "Field " + field1.localId;
                }

                if (field2 == null) {
                    field2 = {};
                }

                // add type information
                if (field2.type != null) {
                    title += " | " + field2.type.name;
                }
                else if (field1.type != null) {
                    title += " | " + field1.type.name;
                }

                // add field name to title
                if (field2.name != null) {
                    title += " (" + field2.name + ")";
                }
                else if (field1.name != null) {
                    title += " (" + field1.name + ")";
                }

                if (parentTitle != null)
                    title += " - " + parentTitle;

                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareFieldDetail(field1, field2),
                    displayConfig: CompareResultDisplayConfigService.compare(field1.displayConfig, field2.displayConfig)
                };

                // collect field value information if needed
                var fieldValue1 = field1.fieldValue;
                var fieldValue2 = field2.fieldValue;
                if (CompareResultUtilService.isValueAvailable(fieldValue1) || CompareResultUtilService.isValueAvailable(fieldValue2)) {
                    result.fieldValue = CompareResultFieldValueService.compare(fieldValue1, fieldValue2)
                }

                // collect validation rules information if needed
                var validationRules1 = field1.validationRules;
                var validationRules2 = field2.validationRules;
                if (CompareResultUtilService.isValueAvailable(validationRules1) || CompareResultUtilService.isValueAvailable(validationRules2)) {
                    result.validationRules = CompareResultUtilService.compareArrays(validationRules1, validationRules2, title,
                        CompareResultValidationRuleService.compare, 'type')
                }

                // collect subFields information if needed
                var subFields1 = field1.subFields;
                var subFields2 = field2.subFields;

                if (CompareResultUtilService.isValueAvailable(subFields1) || CompareResultUtilService.isValueAvailable(subFields2)) {
                    result.subFields = CompareResultUtilService.compareArrays(subFields1, subFields2, title,
                        CompareResultSubFieldService.compare);
                }

                result._validationRulesStatus = CompareResultUtilService.collectStatus(result.validationRules);
                result._subFieldsStatus = CompareResultUtilService.collectStatus(result.subFields);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

