(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * creator model used for creating the instance
     */
    app.factory('CorrectiveActionCreatorModel', function () {

        function CorrectiveActionCreatorModel(model, type) {
            this.model = model;

            // Action properties
            this.type = type;
        }

        /**
         * create new corrective action for the current model
         * @param parentTestCase - parent test case (can not be null)
         * @param selector - selector, optional
         * @returns {*} newly created corrective action
         */
        CorrectiveActionCreatorModel.prototype.createNew = function (parentTestCase, selector) {
            var newItem = new this.model(parentTestCase);
            parentTestCase.addCorrectiveAction(newItem, selector);

            return newItem;
        };

        /***************************************
         * service return call
         ***************************************/
        return CorrectiveActionCreatorModel;

    });
})();
