import ProgramContainerConstantsService from './const/programContainer.constants.service';

/**
 * Created by Jamie Nola on 08/02/2019
 *
 * Allows users to manage containers for a given program
 */
angular.module('acadiamasterApp')
    .controller('ContainerMgmtController', function (entity, ContainerMgmtService, $document, AlertService,
        ProgramUtilConstantsService, ProgramContainerGroupModel, VbrSelectorService,
        CONFIG, ContainerModel, ContainerItemModel, ContainerTreeOptionsService, $timeout, ngDialog, IMPORT_WIZARD_CONSTANTS,
    ) {
        const vm = this;
        const allowedPageTypes = [
            ProgramUtilConstantsService.pageTypeNameOptions.containerPage,
            ProgramUtilConstantsService.pageTypeNameOptions.settings,
            ProgramUtilConstantsService.pageTypeNameOptions.appointments,
        ];

        vm.ready = false;
        vm.entity = null;
        vm.CONFIG = CONFIG;
        vm.cs = ProgramContainerConstantsService;
        vm.tbc = ProgramUtilConstantsService.tabBarItemMap;
        vm.ptno = ProgramUtilConstantsService.pageTypeNameOptions;

        vm.data = {
            accordion : {
                collapsed : false,
            },
            containerGroupModel : {},
            previewItem         : null,
            selectedTab         : null,
            selector            : VbrSelectorService.createSelector(),
            showTemplatePreview : false,
            tabs                : [],
            treeOptions         : ContainerTreeOptionsService,
        };

        vm.getTabBarName = function (tab) {
            return tab.displayName?.en || vm.tbc[tab.id]?.name || tab.name;
        };

        vm.toggleAccordionCollapse = () => {
            vm.data.accordion.collapsed = !vm.data.accordion.collapsed;
        };

        vm.showTemplatePreview = (value, containerItem) => {
            vm.data.showTemplatePreview = value;
            vm.data.previewItem = containerItem;
        };

        /**
     * close the template preview div
     * reset showTemplatePreview and previewItem
     */
        vm.closeTemplatePreview = () => {
            vm.showTemplatePreview(false, null);
        };

        vm.addContainer = () => {
            vm.data.containerGroupModel.addContainer(
                null,
                vm.data.selectedTab.id,
                vm.data.selector,
            );
        };

        vm.updatePageId = (container, pageId) => {
            vm.data.containerGroupModel.moveContainer(container, pageId);
            vm.selectTabByPageId(container.pageId, container.uniqueId);
        };

        vm.selectTabByPageId = (pageId, uniqueContainerId) => {
            const target = vm.data.tabs.find(tab => tab.id === pageId);
            if (target) {
                vm.selectTab(target, uniqueContainerId);
            }
        };

        vm.selectTab = (tab, uniqueContainerId) => {
            vm.data.selectedTab = tab;
            const containers = vm.data.containerGroupModel.containers[tab.id];
            if (containers && containers.length) {
                let targetContainer = containers[0];
                if (uniqueContainerId) {
                    const requestedContainer = containers.find(
                        container => uniqueContainerId === container.uniqueId,
                    );
                    if (requestedContainer) {
                        targetContainer = requestedContainer;
                    }
                }
                vm.data.selector.selectItem(targetContainer);
            } else {
                vm.data.selector.resetSelection();
            }
        };

        function setupModel (containersDto) {
            vm.data.containerGroupModel = ProgramContainerGroupModel.loadModelFromDto(
                {
                    containers : containersDto,
                    programId  : vm.programId,
                    tabs       : vm.data.tabs,
                },
            );
        }

        function init (selectedTabId) {
            vm.programId = vm.entity.id;
            let selectedTab;
            const tabNames = [];
            const tabs = vm.entity.tabBarItems
                .filter(tab => allowedPageTypes.includes(tab.pageType))
                .sort((a, b) => a.sequenceNo - b.sequenceNo)
                .map(tab => {
                    tabNames.push(tab.name);
                    if (selectedTabId && !selectedTab && tab.id === selectedTabId) {
                        selectedTab = tab;
                    }
                    return tab;
                });
            let orphanTabs = tabs.filter(tab => tab.menuNavigationEnabled === false);
            let nonOrphanTab = tabs.filter(tab => tab.menuNavigationEnabled === true);
            //by design, regular tab appears before orphan tab
            vm.data.tabs = [
                vm.cs.defaultTab,
                ...nonOrphanTab,
                ...orphanTabs,
            ];

            // select the previously selected tab if it exists, or the first available tab, or the default tab
            vm.data.selectedTab = selectedTab || nonOrphanTab[0] || vm.cs.defaultTab;

            ContainerMgmtService.getContainers(vm.programId)
                .then(response => {
                    setupModel(response.data);
                }, error => {
                    console.error('Error loading containers', error);
                }).finally(() => {
                    vm.ready = true;
                });
        }

        vm.onPopoverOpened = $event => {
            $document[0].body.click();
            $event.preventDefault();
            $event.stopPropagation();
        };

        vm.save = function () {
            vm.data.containerGroupModel.validate(true);
            if (!vm.data.containerGroupModel.isValid()) {
                AlertService.warning('There are some validation errors in the containers you are trying to save. '
                + 'Please check the highlighted nodes to see what is wrong.');
                return;
            }
            const files = [];

            ContainerMgmtService.saveContainers(vm.programId, vm.data.containerGroupModel.toDto(files), files)
                .then(() => {
                    AlertService.success('Containers saved successfully, cascading effect on the entire user base can take 10+ minutes. '
                + ' Please go to <a href="/#/tools/operationHistory"><b>Tools/Operation History</b></a>  to monitor the progress');
                    vm.data.containerGroupModel = {};
                    $timeout(() => {
                        const selectedTabId = vm.data.selectedTab?.id || null;
                        vm.data.selector.resetSelection();
                        init(selectedTabId);
                    });
                }, error => {
                    console.error('Error saving containers', error);
                });
        };

        vm.validate = function () {
            vm.data.containerGroupModel.validate(true);
            if (vm.data.containerGroupModel.isValid()) {
                AlertService.success('Your configuration seems alright. Go ahead and save it.');
            } else {
                AlertService.warning('<b> Validation Error </b> <hr class="black-line"> There are some validation errors in the containers you are trying to save. '
                + 'Please check the highlighted nodes to correct the error.  <br><br>'
                + '(The error may or may not be in the active tab that you are working on, please make sure you '
                + 'check all the tabs)');
            }
        };

        vm.getContainerCountByPageId = pageId => {
            if (!vm.data.containerGroupModel || !vm.data.containerGroupModel.containers) {
                return 0;
            }
            const containers = vm.data.containerGroupModel.containers[pageId];
            if (containers == null) {
                return 0;
            }
            return containers.length;
        };

        vm.hasValidationErrorInPageId = pageId => {
            if (!vm.data.containerGroupModel || !vm.data.containerGroupModel.containers) {
                return false;
            }
            const containers = vm.data.containerGroupModel.containers[pageId];
            if (containers == null) {
                return false;
            }

            for (let i = 0; i < containers.length; i++) {
                if (containers[i].hasError()) {
                    return true;
                }
            }

            return false;
        };

        vm.isContainerSelected = () => {
            const selectedItem = vm.data.selector.selectedItem;
            return selectedItem instanceof ContainerModel;
        };

        vm.isContainerItemSelected = () => {
            const selectedItem = vm.data.selector.selectedItem;
            return selectedItem instanceof ContainerItemModel;
        };

        // upload the file and close this modal with a success status
        function importContainerItemZipFile ($scope, file) {
            $scope.data.isSendingFile = true;
            $scope.data.serverError = false;
            $scope.data.errorDescription = '';
            // import file
            ContainerMgmtService.importContainerItemZipFile(
                $scope.programId, file).then(
                response => {
                    AlertService.success('Your zip file has been uploaded successfully, Please go to <a href="/#/tools/operationHistory"><b>Tools/Operation History</b></a> '
                        + 'to check for any cascading effect and to monitor the progress before you import another container item.');
                    ngDialog.close(null, {
                        id     : response.data.id,
                        status : IMPORT_WIZARD_CONSTANTS.UPLOAD_STATUS.SUCCESS,
                    });
                    vm.data.containerGroupModel = {};
                    $timeout(() => {
                        const selectedTabId = vm.data.selectedTab?.id || null;
                        vm.data.selector.resetSelection();
                        init(selectedTabId);
                    });
                }, error => {
                    console.error(error);
                    $scope.data.serverError = true;
                    $scope.data.errorDescription = error.data.description;
                }).finally(() => {
                $scope.data.isSendingFile = false;
            });
        }

        // upload the file and close this modal with a success status
        vm.importContainerItemFile = () => {
            // show open window
            ngDialog.open({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : [ '$scope', '$state', function ($scope) {
                    $scope.programId = vm.programId;
                    $scope.data = {
                        errorDescription : null,
                        isSendingFile    : false,
                        serverError      : false,
                    };

                    $scope.startImport = function (file) {
                        importContainerItemZipFile($scope, file);
                    };

                    $scope.isFileValid = function (file) {
                        const pattern = '^[^,]+.zip$';
                        return Boolean(file && file.name.match(pattern));
                    };
                } ],
                size     : 'md',
                template : 'admin-templates/site/programManagement/program/containerManagement/config/containerItem/import/importContainerItemModal.template.html',
            });
        };

        /**
     * Load program data to get name and org data
     */
        entity.$promise.then(
            response => {
                vm.entity = response;
                init();
            },
            error => {
                console.error('Error loading program entity.', error);
            },
        );

        return vm;
    });
