import { capitalized, capitalizedArray } from '../../../../../../util/format/formatFunction';

export const columns = [
    { headerName : 'CARRIER NAME', keyValue : 'carrierCode', sortable : false, tdClass : 'carrier-code', type : 'text' },
    { headerName : 'SHIPPING ID', keyValue : 'trackingId', sortable : false, tdClass : 'shipping-id', type : 'text' },
    { headerName : 'SHIPPING STATUS', keyValue : 'status', sortable : false, tdClass : 'shipping-status', type : 'text' },
    { formatDate : 'M/d/yyyy h:mm:ss:sss a',
        headerName : 'SHIPPED DATE', keyValue   : 'shippedOn',
        sortable   : false, tdClass    : 'shipped-on', type       : 'date' },
    { formatDate : 'M/d/yyyy h:mm:ss:sss a',
        headerName : 'DELIVERED DATE', keyValue   : 'deliveredOn',
        sortable   : false, tdClass    : 'delivered-on', type       : 'date' },
];

const getCancellationTypeDisplayText = (cancellationType, options) => {
    let matchingOption = _.find(options, { cancellationType : cancellationType });
    return matchingOption?.cancellationTypeDisplayText;
};

export const formatData = (order, cancelationType) => ({
    order : {
        cancellationReason          : order.cancellationReason,
        cancellationTypeDisplayText : getCancellationTypeDisplayText(order.cancellationType, cancelationType),
        isCanceled                  : order.status === 'CANCELLED',
        isDivide                    : true,
        items                       : [
            { col : 1, title : 'Fulfillment Order ID', value : order.id },
            { col : 1, title : 'Order Type Code', value : order.orderType.orderTypeCode },
            { col : 1, title : 'Order Type Name', value : order.orderType.orderType },
            { col   : 1, title : 'Order Tracking Mode', type  : 'array',
                value : capitalizedArray(order.orderType.supportedModes) },
            { col       : 2, itemClass : order.status.toLowerCase(),
                title     : 'Order Status', type      : 'pill', value     : capitalized(order.status) },
            { col : 2, itemClass : 'semi-bold', title : 'Order Sub-Status', value : capitalized(order.subStatus) },
            { col : 2, title : 'UUID Request ID', value : order.workflowId },
            { col : 2, title : 'Order Created Date', type : 'date', value : order.createdOn },
            { col : 2, title : 'Order Updated Date', type : 'date', value : order.updatedOn },

        ],
        title     : 'order',
        translate : 'orderDetails.dialog.detail.order',
    },

    participant : {
        items : [
            { title : 'Vibrent ID', value : order.userId },
            { title : 'Shipping Address', type : 'address', value : order.address },
        ],
        title     : 'participant',
        translate : 'orderDetails.dialog.detail.participant',
    },
    product : {
        items : [
            { title : 'Product ID', value : order.product.id },
            { title : 'Product Code', value : order.product.code },
            { title : 'Product Name', value : order.product.name },
            { title : 'Product Sku', value : order.product.sku },

        ],
        title     : 'product',
        translate : 'orderDetails.dialog.detail.product',
    },
    program : {
        items : [
            { title : 'Tenant ID', value : order.tenantId },
            { title : 'Program ID', value : order.programId },
            { title : 'Program Name', value : order.programName },
        ],
        title     : 'program',
        translate : 'orderDetails.dialog.detail.program',
    },
    supplier : {
        items : [
            { title : 'Supplier ID', value : order.supplier.id },
            { title : 'Supplier Name', value : capitalized(order.supplier.name) },
            { title : 'Supplier Order ID', value : order.supplierOrderId },
            { itemClass : 'semi-bold', title : 'Supplier Order Status', value : capitalized(order.subStatus) },
            { title : 'Supplier barcode', value : order.attributes && order.attributes.BARCODE_1D },
        ],
        title     : 'supplier',
        translate : 'orderDetails.dialog.detail.supplier',
    },
    trackings : {
        ...order.trackings,
        filterData : {
            noDataText : 'Currently, tracking data is not available.',
        },
        participant : order.trackings.PARTICIPANT_TRACKING.trackingId ? [ {
            ...order.trackings.PARTICIPANT_TRACKING,
            status : capitalized(order.trackings.PARTICIPANT_TRACKING.status),
        } ] : [],
        return : order.trackings.RETURN_TRACKING.trackingId ? [ {
            ...order.trackings.RETURN_TRACKING,
            status : capitalized(order.trackings.RETURN_TRACKING.status),
        } ] : [],
    },
});
