(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('treeNodeAction', function (RuleUtilService, ExpressionService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/treeNode/action.html',
                scope: {
                    actionHolder: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.ruleConstants = RuleUtilService.ruleConstants;

                    $scope.removeAction = function (expression, actionHolder, index) {
                        ExpressionService.removeAction(expression, actionHolder, index);
                        $scope.selector.itemRemoved(actionHolder);
                    }
                }
            }
        });
})();
