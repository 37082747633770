'use strict';

angular.module('acadiamasterApp').config(
    function ($stateProvider) {
        $stateProvider.state('directivetest', {
            parent: 'site',
            url: '/directivetest',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'admin-templates/site/util/directiveTest/directiveTest.html',
                    controller: 'DirectiveTestController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('dataType');
                    $translatePartialLoader.addPart('form');
                    return $translate.refresh();
                }]
            }
        });
    });
