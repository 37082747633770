'use strict';
import {ASSET_TYPES} from './programAssetManagement.constants';

angular.module('acadiamasterApp')
    .controller('programAssetManagementController',
    function ($scope, $state, entity, ProgramAssetManagement, CONFIG, LocaleConstantsService,AlertService) {
        $scope.CONFIG = CONFIG;
        $scope.lcs = LocaleConstantsService;
        $scope.programAssetManagement = entity;
        $scope.assetListSize = 0;
        $scope.assetTypeCS = ASSET_TYPES;
        $scope.programId = $state.params.id;

        $scope.loadAll = function () {
            ProgramAssetManagement.query({id: $scope.programId}, function(result) {
                $scope.programAssetManagement = result;
                $scope.assetListSize = $scope.programAssetManagement.length;
            });
         };
        $scope.loadAll();

        $scope.removeAsset = function (index) {
            $scope.programAssetManagement.splice(index, 1);
        };

        $scope.addNewAsset = function () {
            var formData = $scope.programAssetManagement;
            $scope.newAssetTypeCS = [];
			_.forEach(ASSET_TYPES, function(value, key) {
                var exists = false;
                _.forEach(formData, function(val2, key) {
                    if( value.name === val2.assetType){
                        exists = true;
                    }
                });
                if(!exists && value.id != '') {
                    $scope.newAssetTypeCS.push(value);
                }
			});
            $('#addServerAssetsDialog').modal('show');
        };

        $scope.assetSelected = function (assetType) {
            $scope.selectedAsset = '';
            $('#addServerAssetsDialog').modal('hide');
            var newAsset = {
                assetType: assetType,
                programId: $scope.programId,
                programAssetId: null,
                subject: null,
                assetLocation: null,
                localizationMap: {}
            };
            $scope.programAssetManagement.push(newAsset);
         };

        $scope.haveFiles = function() {
            if($scope.programAssetManagement.length==0){
                if($scope.assetListSize==0){
                    return false;
                }else{
                    return true;
                }
            }else{
                var flag = false;
                var payload = $scope.programAssetManagement;
                for (var index in payload) {
                    var key = payload[index];
                    if(key.assetType!=null && key.assetType!=undefined){
                        if(key.file != null || key.assetLocation!=null){
                            flag = true;
                        }else{
                            return false;
                        }
                    }
                }
                return flag;
            }
        };

    $scope.validateFile = function(index) {
                var flag = false;
                var payload = $scope.programAssetManagement;
                    var key = payload[index];
                    if(key.assetType!=null && key.assetType!=undefined){
                        if(key.file != null || key.assetLocation!=null){
                            flag = true;
                        }else{
                            return false;
                        }
                    }

                return flag;

        };

        var onSaveFinished = function (resp) {
            AlertService.success('Program Assets changes saved successfully.');
            $state.go('program.manage');
        };

        $scope.save = function () {
            $scope.files = [];
            $scope.programServerAssets = [];
            for (var index in $scope.programAssetManagement) {
                var key = $scope.programAssetManagement[index];
                if(key.assetType!=null && key.assetType!=undefined){
                var dto = {};
                dto.assetType = key.assetType;
                dto.programId = key.programId;
                dto.id = key.id;
                dto.subject = key.subject;
                if (key.file != null) {
                    dto.assetLocation = key.file.name;
                    dto.assetName = key.file.name;
                    dto.assetSize = key.file.size;
                    dto.createdDate = key.file.lastModified;
                    dto.mimeType = key.file.type;

                    $scope.files.push(key.file);
                }
                else if (key.assetLocation == null) {
                    dto.assetLocation = null;
                    dto.assetSize = 0;
                }
                else {
                    dto.assetLocation = key.assetLocation;
                    dto.assetName = key.assetName;
                    dto.assetSize = key.assetSize;
                }
                dto.localizationMap = key.localizationMap;
                if (key.localizationMap != null && key.localizationMap != undefined) {
                    var localization = key.localizationMap;
                        for (var loc in localization) {
                        var object = localization[loc];
                        var file = object['file'];
                        if (file != null) {
                            object['assetName'] = file.name;
                            object['assetLocation'] = file.name;
                            object['assetSize'] = file.size;
                            $scope.files.push(file);
                            delete object['file'];
                        }
                    }
                }
                $scope.programServerAssets.push(dto);
                }
            }
            saveAssetDetails($scope.programId,$scope.files, $scope.programServerAssets, onSaveFinished);
        };
        function saveAssetDetails(programId,files, jsonData, onSaveFinished) {
            ProgramAssetManagement.postContent(programId,files, jsonData, onSaveFinished);
        }
    });
