/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for state rule action
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultStateRuleActionService', function (CompareResultUtilService) {

        function compare(action1, action2, parentTitle) {
            var title;
            if (action1 == null) {
                action1 = {};
                title = "Action | " + action2.actionType.name + " | ";
            }
            else {
                title = "Action | " + action1.actionType.name + " | ";
            }

            if (action2 == null) {
                action2 = {};
            }


            // add name to title
            if (action2.name != null) {
                title += " (" + action2.name + ")";
            }
            else if (action1.name != null) {
                title += " (" + action1.name + ")";
            }

            if (parentTitle != null)
                title += " - " + parentTitle;

            var result = {
                _title: title,
                _isOpen: true
            };

            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "localId", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "name", result);
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "actionType", result);
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "targetType", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "targetLocalId", result);

            // sleep action
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "duration", result);

            // set field entry value
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "expression", result);
            CompareResultUtilService.compareToDtoObjectsByKey(action1, action2, "expressionTree", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "newValue", result);
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "fieldValueType", result);

            // set current page
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "pageChangeType", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "pageValue", result);


            // change config
            CompareResultUtilService.compareObjectsByKey(action1, action2, "targetDisplayConfig", result);
            CompareResultUtilService.compareObjectsByKey(action1, action2, "targetFieldValue", result);
            CompareResultUtilService.compareObjectsByKey(action1, action2, "targetValidationRules", result);


            // set variable
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "variableName", result);
            CompareResultUtilService.compareObjectsByKey(action1, action2, "variableValue", result);

            // show/hide page
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "applyValidationChange", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "pageSectionId1", result);
            CompareResultUtilService.comparePrimitivesByKey(action1, action2, "pageSectionId2", result);
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "visibilityType", result);
            CompareResultUtilService.compareNamedObjectsByKey(action1, action2, "pageRangeType", result);


            result._status = CompareResultUtilService.collectStatus(result);

            return result;
        }

        return {
            compare: compare
        };
    });

})();

