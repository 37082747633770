/**
 * Created by jason.cao on 4/27/17
 */
(function () {
    /**
     * directive for a download page with various jar files and swift files
     */
    angular.module('acadiamasterApp').directive('vbrDownloadTools', VbrToolsService => {
        let allJars = [
            { name : 'label parser', type : 'LABEL_PARSER' },
            { name : 'full dto', type : 'FULL_DTO' },
        ];

        return {
            link : function ($scope) {
                // a list of constants to use which result to show when we have more than one
                // functionality on the page
                $scope.cs = {
                    SWIFT : 'SWIFT',
                };
                // this variable will equal to the result constant that we want to show
                $scope.showResult = null;

                $scope.swiftResultMap = {};

                $scope.jarDownload = {
                    allJars     : allJars,
                    selectedJar : allJars[0],
                };

                $scope.downloadJarFile = function () {
                    return VbrToolsService.downloadJarFile($scope.jarDownload.selectedJar.type);
                };

                $scope.generateSwiftCode = function () {
                    // eslint-disable-next-line no-use-before-define
                    generateSwiftCode($scope);
                    $scope.showResult = $scope.cs.SWIFT;
                };
            },
            restrict    : 'E',
            scope       : {},
            templateUrl : 'admin-templates/site/tools/tools.download.html',
        };

        /*
         * generate and download swift code from server
         */
        function generateSwiftCode ($scope) {
            let httpResponse = VbrToolsService.getGeneratedSwift();
            httpResponse.then(response => {
                $scope.swiftResultMap = response.data;
            });
        }
    });
}());
