'use strict';

angular.module('acadiamasterApp')
    .factory('Account', ['$resource', 'localWeb', function Account($resource, localWeb) {
        return $resource(localWeb.createUrl('api/account'), {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });
    }]);
