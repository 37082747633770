(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for business rule action config model
     */
    app.factory('RuleActionBaseModelService',
        function (RuleConstantsService, RuleUtilService, FormUtilService, ModelServiceBase) {
            /***************************************************************
             * private functions
             **************************************************************/

            /***************************************************************
             * Action push notification model for all the required and supported action type in the business rule.
             ***************************************************************/
            ActionPushNotificationConfig.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function ActionPushNotificationConfig() {
                ActionConfigBaseModel.call(this, parent);
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionPushNotificationConfig;
                this.inTray = true;
                this.inApp = true;
                this.title = 'sample title';
                this.message = 'sample message';
                this.actionLinkType = RuleConstantsService.notificationActionLinkType.NONE;
                this.actionNavigationLink = null;
                this.actionPageId = null;
                this.actionContainerId = null;
                this.actionContainerType = null;
                this.actionContainerInPageId = null;
                this.form = null;
                this.actionFormId = null;
                this.postActionNavigationLink = null;
                this.postActionPageId = null;
                this.postActionContainerId = null;
                this.postActionContainerType = null;
                this.postActionContainerInPageId = null;
                this.imageFile = null;
                this.imageFileName = null;
                this.imageFileUrl = null;
                this.imageFileSize = null;
                this.imageAspectRatio = null;
                this.localizationMap = {};
                this.url = null;
                this.dismissible = false;
                this.dismissOnFormEntry = false;
            }

            ActionPushNotificationConfig.prototype.fromDto = function (dto) {
                if (dto.actionPushNotificationConfig != null) {
                    this[RuleConstantsService.classes.classReferenceKey] = dto.actionPushNotificationConfig[RuleConstantsService.classes.classReferenceKey];
                }
                var actionPushNotificationConfig = dto.actionPushNotificationConfig;
                if (actionPushNotificationConfig != null) {
                    this.inTray = actionPushNotificationConfig.inTray;
                    this.inApp = actionPushNotificationConfig.inApp;
                    this.message = actionPushNotificationConfig.message;
                    this.title = actionPushNotificationConfig.title;
                    this.imageFileName = actionPushNotificationConfig.imageFileName;
                    this.imageFileUrl = actionPushNotificationConfig.imageFileUrl;
                    this.imageFileSize = actionPushNotificationConfig.imageFileSize;
                    this.imageAspectRatio = actionPushNotificationConfig.imageAspectRatio;
                    this.actionLinkType = RuleConstantsService.getObjectByName(RuleUtilService.ruleConstants.notificationActionLinkType, actionPushNotificationConfig.actionLinkType);
                    //-- actionNavigationLink and postActionNavigationLink will be removed in the future (depreciate)
                    this.actionNavigationLink = actionPushNotificationConfig.actionNavigationLink;
                    this.postActionNavigationLink = actionPushNotificationConfig.postActionNavigationLink;
                    this.postActionPageId = actionPushNotificationConfig.postActionPageId;
                    this.postActionContainerId = actionPushNotificationConfig.postActionContainerId;
                    this.postActionContainerType = actionPushNotificationConfig.postActionContainerType;
                    this.postActionContainerInPageId = actionPushNotificationConfig.postActionContainerInPageId;
                    this.actionPageId = actionPushNotificationConfig.actionPageId;
                    this.actionContainerId = actionPushNotificationConfig.actionContainerId;
                    this.actionContainerType = actionPushNotificationConfig.actionContainerType;
                    this.actionContainerInPageId = actionPushNotificationConfig.actionContainerInPageId;
                    this.localizationMap = actionPushNotificationConfig.localizationMap != null ? actionPushNotificationConfig.localizationMap : {};
                    this.actionFormId = actionPushNotificationConfig.actionFormId;
                    if (actionPushNotificationConfig.actionFormId != null) {
                        this.form = RuleUtilService.getFormById(actionPushNotificationConfig.actionFormId);
                    }
                    this.url = actionPushNotificationConfig.url;
                    this.dismissible = actionPushNotificationConfig.dismissible;
                    this.dismissOnFormEntry = actionPushNotificationConfig.dismissOnFormEntry;
                }
            };

            ActionPushNotificationConfig.prototype.toDto = function (files) {
                var actionPushNotificationConfig = {};
                actionPushNotificationConfig.inTray = this.inTray;
                actionPushNotificationConfig.inApp = this.inApp;
                actionPushNotificationConfig.message = this.message;
                actionPushNotificationConfig.title = this.title;
                actionPushNotificationConfig.imageSize = this.imageSize;
                actionPushNotificationConfig.imageName = this.imageName;
                actionPushNotificationConfig.imageUrl = this.imageUrl;
                actionPushNotificationConfig.aspectRatio = this.aspectRatio;
                actionPushNotificationConfig.actionLinkType = this.actionLinkType != null ? this.actionLinkType.name : null;
                actionPushNotificationConfig.localizationMap = this.localizationMap;

                // set notification type based parameters in dto
                switch (this.actionLinkType.name) {
                    case RuleConstantsService.notificationActionLinkType.NONE.name:
                        actionPushNotificationConfig.postActionNavigationLink = this.postActionNavigationLink;
                        actionPushNotificationConfig.postActionPageId = this.postActionPageId;
                        actionPushNotificationConfig.postActionContainerId = this.postActionContainerId;
                        actionPushNotificationConfig.postActionContainerType = this.postActionContainerType;
                        actionPushNotificationConfig.postActionContainerInPageId = this.postActionContainerInPageId;
                        break;
                    case RuleConstantsService.notificationActionLinkType.NAVIGATION.name:
                        actionPushNotificationConfig.actionNavigationLink = this.actionNavigationLink;
                        actionPushNotificationConfig.actionPageId = this.actionPageId;
                        actionPushNotificationConfig.actionContainerId = this.actionContainerId;
                        actionPushNotificationConfig.actionContainerType = this.actionContainerType;
                        actionPushNotificationConfig.actionContainerInPageId = this.actionContainerInPageId;
                        break;
                    case RuleConstantsService.notificationActionLinkType.EXTERNAL_NAVIGATION.name:
                        actionPushNotificationConfig.url = this.url;
                        break;
                    case RuleConstantsService.notificationActionLinkType.FORM.name:
                        if (this.form != null) {
                            actionPushNotificationConfig.actionFormId = this.form.id;
                        }
                        actionPushNotificationConfig.postActionNavigationLink = this.postActionNavigationLink;
                        actionPushNotificationConfig.postActionPageId = this.postActionPageId;
                        actionPushNotificationConfig.postActionContainerId = this.postActionContainerId;
                        actionPushNotificationConfig.postActionContainerType = this.postActionContainerType;
                        actionPushNotificationConfig.postActionContainerInPageId = this.postActionContainerInPageId;
                        break;
                    default:
                        break
                }

                if (this.imageFile != null) {
                    actionPushNotificationConfig.imageFileName = this.imageFile.name;
                    actionPushNotificationConfig.imageFileSize = this.imageFile.size;
                    files.push(this.imageFile);
                }


                if(this.inApp){
                    actionPushNotificationConfig.dismissible = this.dismissible;
                    if(this.actionLinkType && this.actionLinkType.name !== RuleConstantsService.notificationActionLinkType.FORM.name){
                        actionPushNotificationConfig.dismissOnFormEntry = false;
                    } else {
                        actionPushNotificationConfig.dismissOnFormEntry = this.dismissOnFormEntry;
                    }
                } else {
                    actionPushNotificationConfig.dismissible = false;
                    actionPushNotificationConfig.dismissOnFormEntry = false;
                }

                return actionPushNotificationConfig;
            };

            ActionPushNotificationConfig.prototype.getDescriptionAsHtml = function () {
                return 'Add Notification (' +
                    (this.inTray ? 'inTray, ' : '') +
                    (this.inApp ? 'inApp, ' : '') +
                    'Subject: ' + this.title + ')';
            };

            ActionPushNotificationConfig.prototype._validateSelf = function () {
                this.clearError();
                    // validate notification type based parameters
                    switch (this.actionLinkType.name) {
                        case RuleConstantsService.notificationActionLinkType.NAVIGATION.name:
                            if (this.actionNavigationLink == null){
                                this.setErrorMessage('Navigate to is required');
                                return;
                            }
                            if (this.actionNavigationLink === RuleConstantsService.notificationActionNavigationLink.PAGE.name && this.actionPageId == null){
                                this.setErrorMessage('Page Id is required when selected Page');
                                return;
                            }
                            if (this.actionNavigationLink === RuleConstantsService.notificationActionNavigationLink.CONTAINER.name && this.actionContainerId == null){
                                this.setErrorMessage('Container Id is required when selected Container');
                                return;
                            }
                            break;
                        case RuleConstantsService.notificationActionLinkType.NONE.name:
                            if (this.postActionNavigationLink == null){
                                this.setErrorMessage('Post Navigation is required');
                                return;
                            }
                            if (this.postActionNavigationLink === RuleConstantsService.notificationActionNavigationLink.PAGE.name && this.postActionPageId == null){
                                this.setErrorMessage('Page Id is required when selected Page');
                                return;
                            }
                            if (this.postActionNavigationLink === RuleConstantsService.notificationActionNavigationLink.CONTAINER.name && this.postActionContainerId == null){
                                this.setErrorMessage('Container Id is required when selected Container');
                                return;
                            }
                            break;
                        case RuleConstantsService.notificationActionLinkType.EXTERNAL_NAVIGATION.name:
                            if (this.url == null || this.url.length === 0) {
                                this.setErrorMessage('A value must be provided for the URL');
                                return;
                            }

                            var r = new RegExp('^https?:\\/\\/[^ "]+$');
                            if (!r.test(this.url)) {
                                this.setErrorMessage('URL value is invalid');
                            }
                            break;
                        case RuleConstantsService.notificationActionLinkType.FORM.name:
                            if (this.form == null){
                                this.setErrorMessage('form id is required');
                                return;
                            }
                            break;
                        default:
                            break

                    }
                };

            /***************************************************************
             * Action model base
             ***************************************************************/
            ActionModelBase.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function ActionModelBase(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

                // Action properties
                this.id = null;
                this.type = null;
                this.code = RuleUtilService.localCodeGenerator.getNextCode(RuleConstantsService.codeType.ACTION);
                this.actionConfig = null;
                this.usageCount = 0;
                this.actionPushNotificationConfig = null;
                this.name = '';

                // This 'isAsyncAction' flag will be used to show a warning on expression node while adding multiple actions after async action.
                // async action example is CREATE_ORDER which calls external server's apis to create an order
                this.isAsyncAction = false;
            }

            /**
             * convert the current UI model to dto format
             */
            ActionModelBase.prototype.toDto = function (files) {
                var dto = {};
                dto.id = this.id;
                dto.actionCode = this.code;
                dto.actionType = this.type?.name;
                dto.name = this.name;
                if (this.actionPushNotificationConfig) {
                    dto.actionPushNotificationConfig = this.actionPushNotificationConfig.toDto(files);
                }
                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            ActionModelBase.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.code = dto.actionCode;
                this.type = RuleConstantsService.getObjectByName(RuleConstantsService.actionType, dto.actionType);
                this.name = dto.name || '';
                if (dto.actionPushNotificationConfig) {
                    this.actionPushNotificationConfig = new ActionPushNotificationConfig();
                    this.actionPushNotificationConfig.fromDto(dto);
                }
                RuleUtilService.localCodeGenerator.resetCode(RuleConstantsService.codeType.ACTION, this.code);
            };


            /**
             * validate the entity
             */
            ActionModelBase.prototype._validateSelf = function () {
                // prototype
            };

            ActionModelBase.prototype.customClone = function () {
                var newAction = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);
                // Re-generate the code as the code generated gets overwritten by from DTO
                newAction.code = RuleUtilService.localCodeGenerator.getNextCode(RuleConstantsService.codeType.ACTION);
                newAction.getRoot().addActionUsage(newAction);
                return newAction;
            };

            /**
             * getting the description of this action
             */
            ActionModelBase.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = '';
                if (addBadgeAndType) {
                    message += '<span data-nodrag class="badge badge-warning">Action</span>';
                    message += ' <i>' + this.type.name + '</i>';
                }
                message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                var name = this.name;
                message += name == null || name.trim().length == 0 ? '~~NO NAME~~' : name;
                if (this.actionPushNotificationConfig) {
                    message += ' | ' + this.actionPushNotificationConfig.getDescriptionAsHtml();
                }

                return message;
            };

            /***************************************************************
             * Action config base model
             ***************************************************************/
            /**
             * action config base model
             * @constructor
             */
            // todo: why we inherits from ActionModelBase instead of base model?? and ActionModelBase seem to contain too many things, it's
            // very different than the same class on server side, this different creates difficulty when working with them
            ActionConfigBaseModel.inheritsFrom(ActionModelBase);
            function ActionConfigBaseModel(parent) {
                ActionModelBase.call(this, parent);
                this[RuleConstantsService.classes.classReferenceKey] = null;
            }

            ActionConfigBaseModel.prototype.toDto = function (files) {
                var dto = ActionModelBase.prototype.toDto.call(this, files);

                if (this[RuleConstantsService.classes.classReferenceKey] != null) {
                    dto.actionConfig = {};
                    dto.actionConfig[RuleConstantsService.classes.classReferenceKey] = this[RuleConstantsService.classes.classReferenceKey];
                }

                return dto;
            };

            ActionConfigBaseModel.prototype.fromDto = function (dto) {
                ActionModelBase.prototype.fromDto.call(this, dto);

                // Set class reference
                if (dto.actionConfig != null) {
                    this[RuleConstantsService.classes.classReferenceKey] = dto.actionConfig[RuleConstantsService.classes.classReferenceKey];
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return {
                ActionModelBase: ActionModelBase,
                ActionConfigBaseModel: ActionConfigBaseModel,
                ActionPushNotificationConfig: ActionPushNotificationConfig
            };

        }
    );
})();
