import cs from '../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/27/2019
 *
 * utility service for container's display config
 */
angular.module('acadiamasterApp').factory('ContainerDisplayConfigUtil', (
    ContainerMultipleItemsDisplayConfigImpl, ContainerMultiFormRoRDisplayConfigImpl,
    ContainerMultiFormDisplayConfigImpl, ContainerSingleItemDisplayConfigImpl,
    ContainerContentDisplayConfigImpl) => {

    const {classes} = cs;

    function getDisplayConfigFromDto(displayConfigDto, containerType, parent) {
        let displayConfig = null;
        const dcClass = displayConfigDto != null ?
            displayConfigDto[classes.classReferenceKey] :
            getClassByContainerType(containerType);

        if (dcClass === classes.ContainerMultipleItemsDisplayConfigImpl) {
            displayConfig = new ContainerMultipleItemsDisplayConfigImpl(parent);
        }
        else if (dcClass === classes.ContainerMultiFormRoRDisplayConfigImpl) {
            displayConfig = new ContainerMultiFormRoRDisplayConfigImpl(parent);
        }
        else if (dcClass === classes.ContainerMultiFormDisplayConfigImpl) {
            displayConfig = new ContainerMultiFormDisplayConfigImpl(parent);
        }
        else if (dcClass === classes.ContainerSingleItemDisplayConfigImpl) {
            displayConfig = new ContainerSingleItemDisplayConfigImpl(parent);
        }
        else if (dcClass === classes.ContainerContentDisplayConfigImpl || dcClass === classes.ContainerItemContentConfig) {
            displayConfig = new ContainerContentDisplayConfigImpl(parent);
        }
        if (displayConfig != null) {
            displayConfig.fromDto(displayConfigDto);
        }

        return displayConfig;
    }

    /** *************************************
     * private functions
     ************************************** */

    /**
     * getting the class string for container type, this is a fallback mechanism for
     * older configuration, shouldn't be needed for any new configuration
     * @param containerType - container type
     */
    function getClassByContainerType(containerType) {
        const type = cs.findTypeByName(containerType);

        return type==null ? null : type.displayConfigClass;
    }



    /** *************************************
     * service return call
     ************************************** */

    return {
        getDisplayConfigFromDto,
    };
});
