/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for action configuration for change config
     */
        .directive('formElementConfigActionChangeConfig', function (FormUtilService, $uibModal, vbrCommonUtil, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfig.action.changeConfig.html',
                scope: {
                    element: '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.changeValidationRules = function() {
                        changeValidationRules($scope);
                    };
                    $scope.changeFieldValue = function() {
                        changeFieldValue($scope);
                    };

                    $scope.changeDisplayConfig = function() {
                        changeDisplayConfig($scope);
                    };

                    $scope.gotoLinkedElement = function() {
                        var linkedElement = $scope.element.currentCopy.linkedElement;
                        if (linkedElement!=null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, "_parent", ['collapsed', 'sectionCollapsed'], false);
                        }
                    };
                }
            };

            /**
             * open up a new window for configuration of validation rules change
             * @param inputScope
             */
            function changeValidationRules(inputScope) {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'admin-templates/site/forms/configPanel/stateRule/windowAction.validationRules.html',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.targetElement = inputScope.element.currentCopy.linkedElement;
                        if (inputScope.element.targetValidationRules==null) {
                            inputScope.element.targetValidationRules = [];
                        }

                        $scope.validationRuleChanges = inputScope.element.targetValidationRules;
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                    }],
                    size: 'lg'
                });
            }


            /**
             * open up a new window for configuration of field value change
             * @param inputScope
             */
            function changeFieldValue(inputScope) {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'admin-templates/site/forms/configPanel/stateRule/windowAction.fieldValue.html',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.targetElement = inputScope.element.currentCopy.linkedElement;
                        if (inputScope.element.targetFieldValue == null) {
                            inputScope.element.targetFieldValue = vbrCommonUtil.copyEmptyObject(this.currentCopy.linkedElement.fieldValue,
                                inputScope.constants.classes.classReferenceKey);
                            inputScope.element.targetFieldValue.localizationMap = {};
                        }

                        $scope.fieldValueChange = inputScope.element.targetFieldValue;
                        $scope.selector = inputScope.selector;
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                    }],
                    size: 'lg'
                });
            }


            /**
             * open up a new window for configuration of display config change
             * @param inputScope
             */
            function changeDisplayConfig(inputScope) {
                $uibModal.open({
                    animation: true,
                    templateUrl: 'admin-templates/site/forms/configPanel/stateRule/windowAction.displayConfig.html',
                    controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        $scope.targetElement = inputScope.element.currentCopy.linkedElement;
                        setupUIStatesIfNeeded($scope.targetElement);

                        if (inputScope.element.targetDisplayConfig == null) {
                            inputScope.element.targetDisplayConfig = vbrCommonUtil.copyEmptyObject(
                                this.currentCopy.linkedElement.displayConfig, inputScope.constants.classes.classReferenceKey);
                        }

                        $scope.displayConfigChange = inputScope.element.targetDisplayConfig;
                        $scope.close = function () {
                            $uibModalInstance.close();
                        };
                    }],
                    size: 'lg'
                });
            }

            function setupUIStatesIfNeeded(element) {
                if (element != null && element.uiStates == null) {
                    element.uiStates = FormUtilService.stateControlUtil.setupInitialUIStates(
                        FormUtilService.stateControlUtil.calculateIncludeBasic(element));
                }
            }
        });
})();

