
angular.module('acadiamasterApp').directive('addContainerItemAppointment', () => ({
    link($scope) {
        $scope.addAppointmentsItem = function () {
            $scope.container.addAppointment();
        };

        $scope.hasIconClass = () => {
            return ($scope.iconClass && $scope.iconClass.length > 0);
        };

        $scope.getIconClass = () => {
            return $scope.hasIconClass() ? $scope.iconClass : '';
        };
    },
    restrict: 'E',
    scope: {
        container: '=',
        iconClass: '<',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
        + 'config/containerItem/addItem/addContainerItemAppointment.html',
}));
