/**
 * Created by Jamie Nola on 12/12/2018
 */
(function () {
    'use strict';

    /**
     * directive for editing string values
     */
    angular.module('acadiamasterApp')
        .directive('stringValueEditor', function (StringManagementConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/stringManagement/valueEditor/stringValueEditor.template.html',
                scope: {
                    valueModel: '=',
                    systemValueModel: '=',
                    isEditable: '=?',
                    isSaveDisabled: '=?',
                    isRevertDisabled: '=?',
                    isServerString: '=?'
                },
                controller: function ($scope) {
                    $scope.cs = StringManagementConstants;
                    if($scope.isServerString) {
                        $scope.platforms = ['default'];
                    } else {
                        $scope.platforms = ['default', 'web', 'mobile'];
                    }

                    function init() {
                        /**
                         * When the keyName changes, update our local version of the model to match the new one.
                         */
                        $scope.$watch('valueModel.value.keyName', function () {
                            $scope.valueModel.resetTempValue();
                            $scope.tempValue = $scope.valueModel.tempValue;
                        });

                        /**
                         * If this directive is editable, watch the default value and use object so we can disable buttons accordingly
                         */
                        if ($scope.isEditable) {
                            if ($scope.valueModel.value.programId === null) {
                                $scope.$watch('tempValue.value.default', function (newValue) {
                                    $scope.isSaveDisabled = newValue.length === 0;
                                });
                            } else {
                                $scope.$watch('tempValue.use', function (newValue) {
                                    var hasAnyProgramValue = newValue.default || newValue.web || newValue.mobile;
                                    var entryExistsInDB = $scope.tempValue.id != null;

                                    // only allow revert if the database has an entry for this string
                                    $scope.isRevertDisabled = !entryExistsInDB;

                                    // only allow save if DB entry already exists OR we are setting any custom valuehasAnyProgramValue)
                                    $scope.isSaveDisabled = !entryExistsInDB && !hasAnyProgramValue;
                                }, true);
                            }
                        }
                    }

                    init();
                }
            };
        });
})();
