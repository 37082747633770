
angular.module('acadiamasterApp')
    .directive('importWizardSessionDetailModal', (ngDialog, $timeout, AlertService, ImportWizardService, IMPORT_WIZARD_CONSTANTS) => {
        /** ****************************************************************************
         * private functions
         ***************************************************************************** */

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            $scope.data = {
                UPDATE_INTERVAL_MS: 100,
                errorLoading: false,
                isCanceling: false,
                isDownloadingZipFile: false,
                isLoading: true,
                isOpen: true,
                isStartingImport: false,
                session: {},
            };

            // detect when dialog closes so we can turn off the update check
            $scope.$on('ngDialog.closing', () => {
                $scope.data.isOpen = false;
            });

            $scope.reloadData = function () {
                $scope.data.isLoading = true;
                $scope.data.errorLoading = false;
                loadSessionData($scope);
            };

            $timeout(() => {
                loadSessionData($scope);
            }, 500);
        }

        function resetEntitySort(entity) {
            entity.orderBy = IMPORT_WIZARD_CONSTANTS.DETAIL.CATEGORY.TABLE_HEADER.ID.id;
            entity.orderByAscending = true;
        }

        function parseEntities(rawData, importStatus) {
            const map = {};
            rawData.forEach((entity) => {
                map[entity.entityType] = entity;
            });

            const entities = [];
            const totals = [];
            IMPORT_WIZARD_CONSTANTS.DETAIL.CATEGORIES.forEach((category) => {
                const entity = map[category.title] || {};
                const showProgress = entity.hasOwnProperty('totalFileCount');

                entity.hasFailed = entity.hasFailed || category.showAsInvalid;

                if (showProgress) {
                    updateEntityProgress(entity, null, importStatus);
                }

                if (category.isTotalCount) {
                    entity.files = totals;
                } else if (!category.hideIfEmpty || entity.totalFileCount > 0) {
                    totals.push({
                        entityType: category.title,
                        fileCount: entity.totalFileCount || 0,
                        hideIfEmpty: category.hideIfEmpty,
                    });
                }

                // if we are forcing the category to show as invalid, set the file statuses
                if (category.showAsInvalid && entity.totalFileCount > 0) {
                    entity.files.forEach((file) => {
                        file.status = IMPORT_WIZARD_CONSTANTS.DETAIL.ITEM.STATUS.INVALID;
                    });
                }

                entity.showProgress = showProgress;
                entity.isOpen = category.isTotalCount || entity.hasFailed || category.showAsInvalid || false;
                entity.headers = category.headers;
                entity.showTableTitle = category.showTableTitle || false;
                entity.hideIfEmpty = category.hideIfEmpty || false;
                entity.isTotalCount = category.isTotalCount || false;
                entity.showAsInvalid = category.showAsInvalid || false;
                entity.entityType = category.title;
                resetEntitySort(entity);
                entities.push(entity);
            });

            return entities;
        }

        // set properties on category
        function updateEntityProgress(entity, newData, importStatus) {
            if (!newData) {
                newData = entity;
            }
            entity.status = newData.overallEntityStatus;

            // figure out how far the entity has progressed
            const isImporting = entity.status === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IN_PROGRESS
                || entity.status === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IMPORT_FAILED
                || importStatus === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IN_PROGRESS;
            const hasCompletedImport = newData.importSuccessFileCount === newData.totalFileCount
                || entity.status === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IMPORT_SUCCESS;
            const hasFailed = entity.status === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.VALIDATION_FAILED
                || entity.status === IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IMPORT_FAILED;

            // calculate progress from either import or validation count.
            entity.progress = isImporting || hasCompletedImport
                ? (newData.importSuccessFileCount / newData.totalFileCount)
                : (newData.validationSuccessFileCount / newData.totalFileCount);
            entity.isOpen = (hasFailed && !entity.hasFailed) || entity.isOpen;
            entity.hasFailed = hasFailed || entity.showAsInvalid;
        }

        function loadSessionData($scope) {
            ImportWizardService.getSessionDetail($scope.sessionId).then((response) => {
                response.data.entities = parseEntities(response.data.importedEntities, response.data.importStatus);
                $scope.data.session = response.data;
                updateStatus($scope);
                $scope.data.errorLoading = false;
            }, (error) => {
                $scope.data.errorLoading = true;
                console.error(error);
            }).finally(() => {
                $scope.data.isLoading = false;
            });
        }

        // update each file with the status from the server
        function updateEntities(session, response) {
            const newEntities = response.importedEntities;
            const map = {};
            // map new entities into a map so we can recall them quicker
            newEntities.forEach((entity) => {
                map[entity.entityType] = entity;
            });

            // iterate through existing entities and update their status based on the new data
            session.entities.forEach((entity) => {
                const newData = map[entity.entityType];
                if (!newData || newData.isTotalCount) {
                    return;
                }

                // update parent category status
                if (entity.showProgress) {
                    updateEntityProgress(entity, newData, response.importStatus);
                }

                // update the existing files
                entity.files = newData.files;
            });

            session.importStatus = response.importStatus;
        }

        // grab the most recent state of each entity
        function updateStatus($scope) {
            if (!$scope.data.isOpen
                || ($scope.data.session.importStatus !== IMPORT_WIZARD_CONSTANTS.LIST.STATUS.NOT_STARTED
                    && $scope.data.session.importStatus !== IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IN_PROGRESS)) {
                return;
            }
            ImportWizardService.getSessionDetail($scope.sessionId).then((response) => {
                updateEntities($scope.data.session, response.data);
                $timeout(() => {
                    updateStatus($scope);
                }, $scope.data.UPDATE_INTERVAL_MS);
            }, (error) => {
                console.error(error);
            });
        }

        return {
            controller($scope) {
                $scope.cs = IMPORT_WIZARD_CONSTANTS;

                $scope.closePopup = function () {
                    ngDialog.close();
                };

                $scope.downloadZipFile = function (filename, path) {
                    $scope.data.isDownloadingZipFile = true;
                    ImportWizardService.downloadZipFile(path).then((response) => {
                        const a = document.createElement('a');
                        const file = new Blob([response.data], { type: response.config.params.responseType });
                        a.href = URL.createObjectURL(file);
                        a.download = filename;
                        a.click();
                    }, (error) => {
                        console.error(error);
                        AlertService.error('An error occurred while downloading the zip file. Please try again later.');
                    }).finally(() => {
                        $scope.data.isDownloadingZipFile = false;
                    });
                };

                $scope.cancelImport = function (id) {
                    ngDialog.openConfirm({
                        plain: true,
                        template: '\
                                <p>Are you sure you want to cancel the program import validation session currently running?</p>\
                                <div class="large-margin-top" style="text-align: right">\
                                    <button type="button" class="btn btn-danger" ng-click="confirm(1)">Confirm</button>\
                                    <button type="button" class="btn btn-secondary" ng-click="closeThisDialog(0)">Cancel</button>\
                                </div>',
                    }).then((value) => {
                        if (value === 1) {
                            $scope.data.isCanceling = true;
                            ImportWizardService.cancelImport(id).then((response) => {
                                AlertService.success('The session has been successfully canceled.');
                                loadSessionData($scope);
                            }, (error) => {
                                console.error(error);
                                AlertService.error('An error occurred while canceling the import. Please try again later.');
                            }).finally(() => {
                                $scope.data.isCanceling = false;
                            });
                        }
                    });
                };

                $scope.importEntities = function (id) {
                    $scope.data.isStartingImport = true;
                    ImportWizardService.importEntities(id).then((response) => {
                        AlertService.success('The import process is now beginning.');
                        response.data.importStatus = IMPORT_WIZARD_CONSTANTS.LIST.STATUS.IN_PROGRESS;
                        updateEntities($scope.data.session, response.data);
                        updateStatus($scope);
                    }, (error) => {
                        console.error(error);
                        AlertService.error('An error occurred while beginning the import. Please try again later.');
                    }).finally(() => {
                        $scope.data.isStartingImport = false;
                    });
                };

                $scope.resetEntitySort = function (entity) {
                    if (!entity.isOpen) {
                        resetEntitySort(entity);
                    }
                };

                init($scope);
            },
            restrict: 'E',
            scope: {
                sessionId: '=',
            },
            templateUrl: 'admin-templates/site/programManagement/program/importWizard/sessionDetailModal/importWizardSessionDetailModal.template.html',
        };
    });
