/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('hasAuthority', function (Principal) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var authority = attrs.hasAuthority.replace(/\s+/g, '');

                if (authority.length > 0) {
                    defineVisibility(true);
                }

                function setVisible() {
                    element.removeClass('hidden');
                }

                function setHidden() {
                    element.addClass('hidden');
                }

                function defineVisibility(reset) {
                    if (reset) {
                        setVisible();
                    }

                    Principal.hasAuthority(authority)
                        .then(function (result) {
                            if (result) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                }
            }
        };
    });

})();

