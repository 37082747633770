/**
 * Form Preview Service
 */
(function () {

    angular.module('acadiamasterApp').factory('DeviceDataTypeService', function ($uibModal) {

        const openHKWindow = ($scope, data) => {
            $uibModal.open({
                animation   : true,
                scope       : $scope,
                backdrop    : 'static',
                templateUrl : 'admin-templates/site/programManagement/program/basicConfig/program.devices.datatypeConfig.html',
                controller  : ['$scope', '$uibModalInstance','localStorageService', function ($scope, $uibModalInstance, localStorageService) {
                    const vm = this;
                    vm.data = data;
                    $scope.ok = function () {
                        localStorageService.set('profile', {});
                        const addedProfile = localStorageService.get('addedProfile');
                        localStorageService.set('profile', addedProfile);
                        $uibModalInstance.close();
                    };
                    $scope.updateDeviceTypeCategoryFlag = function(categoryType, dataTypeMap){

                        for(var dataTypeIndex = 0; dataTypeIndex < categoryType.supported_datatypes.length; dataTypeIndex++){
                            var dataType = categoryType.supported_datatypes[dataTypeIndex];
                            dataTypeMap[dataType.type] = categoryType.isEnabled;
                        }
                    };
                    $scope.updateCategoryFlag = function(categoryType, dataTypeMap){
                        for(var dataTypeIndex = 0; dataTypeIndex < categoryType.supported_datatypes.length; dataTypeIndex++){
                            var dataType = categoryType.supported_datatypes[dataTypeIndex].type;
                            if(dataTypeMap[dataType] === true){
                                categoryType.isEnabled = true;
                                return;
                            }
                        }
                        categoryType.isEnabled = false;
                    };
                }],
                controllerAs : 'pdd',
                size         : 'lg',
            });
        };

        return {
            openHKWindow,
        };
    });
})();
