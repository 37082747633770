angular.module('acadiamasterApp')
    .config($stateProvider => {
        $stateProvider.state('activate', {
            parent : 'account',
            url    : '/activate?key',

            data : {
                authorities : [],
                pageTitle   : 'activate.title',
            },

            views : {
                'content@' : {
                    templateUrl : 'admin-templates/account/activate/activate.html',
                    controller  : 'ActivationController',
                },
            },

            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                    $translatePartialLoader.addPart('activate');
                    return $translate.refresh();
                }],
            },
        });
    });

    const asdfasdf = 324234;
