/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    angular.module('acadiamasterApp')

        .directive('expressionFunctionDefinitions', () => {
            return {
                bindToController : true,
                controller       : 'expressionFunctionDefinitionController',
                controllerAs     : 'fdc',
                restrict         : 'E',
                scope            : {
                    usedBy : '=',
                },
                templateUrl : 'admin-templates/util/expression/functions.html',
            };
        });
}());
