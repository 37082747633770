'use strict';

angular.module('acadiamasterApp')
    .controller('PropertyPossibleValueGroupDetailController', function ($scope, $rootScope, $stateParams, entity, PropertyPossibleValueGroup, User) {
        $scope.propertyPossibleValueGroup = entity;
        $scope.load = function (id) {
            PropertyPossibleValueGroup.get({id: id}, function(result) {
                $scope.propertyPossibleValueGroup = result;
            });
        };
        $rootScope.$on('acadiamasterApp:propertyPossibleValueGroupUpdate', function(event, result) {
            $scope.propertyPossibleValueGroup = result;
        });
    });
