/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {


    angular.module('acadiamasterApp')

    /**
     * directive for component basic configuration regarding form
     */
        .directive('formElementConfigBasicForm', function (
            FormUtilService, LocaleConstantsService, LocaleJsonService,
            FormConstants, vbrCommonUtil, ExpressionConstantsService, ContainerItemTemplateConstants
        ) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/elementConfig.basic.form.html',
                scope       : {
                    element    : '=',
                    parentForm : '=',
                },
                link : function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.vcu = vbrCommonUtil;
                    $scope.citc = ContainerItemTemplateConstants;

                    $scope.expConstants = ExpressionConstantsService;
                    // boolean to control the accordion to default to open state
                    $scope.consentAccordionOpen = true;

                    $scope.commentPattern = '^[a-zA-Z0-9\\_\\.\\-\\s\\\\]*$';

                    $scope.listToAvoid = [
                        FormConstants.submitButton.buttonType.BUTTON_DEFAULT,
                        FormConstants.submitButton.buttonType.BUTTON_INFO,
                        FormConstants.submitButton.buttonType.BUTTON_SUCCESS,
                        FormConstants.submitButton.buttonType.BUTTON_WARNING,
                        FormConstants.submitButton.buttonType.LINK,
                    ];

                    if($scope.element.cancelButtonType){
                        $scope.element.cancelButtonSpecificType = $scope.constants.submitButton.buttonType[$scope.element.cancelButtonType];
                    }

                    $scope.formTypeName = $scope.element._parent.programId ? $scope.element._parent.programCode : $scope.constants.formTypes.SYSTEM;

                    $scope.hasError = function(){
                        return $scope.semanticVersionForm.semanticVersion.$error;
                    };
                    $scope.validateUpdateIrb = function(){
                        $scope.element.irbSemanticVersion=$.trim($scope.element.irbSemanticVersion).substr(0, 100);
                    };
                    $scope.validateUpdateSemantic = function(){
                        $scope.element.semanticVersion=$.trim($scope.element.semanticVersion).substr(0, 100);
                    };
                    $scope.cancelButtonChangeCallback = function (newValue) {
                        if(newValue){
                            $scope.element.cancelButtonSpecificType = $scope.constants.submitButton.buttonType.BUTTON_AQUA;
                            $scope.element.cancelButtonLabelMap = {
                                en : 'Cancel',
                                es : '',
                            };
                            LocaleJsonService.jsonToLocalizationMap($scope.element.cancelButtonLabelMap,
                                $scope.element, 'cancelButtonLabelMap', 'cancelButtonLabelLocaleMap');
                        } else {
                            $scope.element.cancelButtonSpecificType = null;
                            $scope.element.cancelButtonLabelMap = null;
                        }
                    };

                },
            };
        });

})();

