/**
 * Created by pravin.gayal on 14-06-2016
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for fixed node model
     */
        .service('FixedNodeModelService',
            function (RuleConstantsService, RuleUtilService, RuleTriggerModelService, RuleExpressionModelService, ModelServiceBase,
                      $injector, ExpressionService, ExpressionConditionConstantsService) {
                /***************************************************************
                 * private functions
                 **************************************************************/


                /***************************************************************
                 *  Trigger List Model for Business Rule
                 ***************************************************************/
                FixedNodeTriggerModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function FixedNodeTriggerModel(parent, atleastOneRequired) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                    this.text = 'Triggers';
                    this.nodeType = RuleConstantsService.nodeType.TRIGGER_FIXED_NODE;
                    this.collapsed = false;
                    this.triggers = [];
                    this.atleastOneRequired = atleastOneRequired;

                    // add SCHEDULE trigger if it is a schedule rule
                    if(RuleUtilService.isScheduleRule(this)){
                        this.addTrigger(RuleTriggerModelService.getTriggerTypeModelByType(RuleConstantsService.triggerType.SCHEDULE));
                    }
                }

                /**
                 * Create trigger model from dto and set it in triggers model list
                 * @param dto
                 */
                FixedNodeTriggerModel.prototype.fromDto = function (dto) {
                    var _this = this;
                    _this.triggers = [];

                    _.forEach(dto.triggerConditions, function (trigger) {
                        var triggerModel = new RuleTriggerModelService.createTriggerModelFromDto(trigger, _this);
                        _this.triggers.push(triggerModel);
                    });
                };


                /**
                 * validate the entity
                 */
                FixedNodeTriggerModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.atleastOneRequired && (this.triggers == null || this.triggers.length < 1)) {
                        this.setErrorMessage('At least one trigger should be present');
                    }
                };

                /**
                 * add a trigger to the list of triggers
                 */
                FixedNodeTriggerModel.prototype.addTrigger = function (triggerType, selector) {
                    var trigger = triggerType.newInstance(this);

                    this.triggers.push(trigger);

                    if (selector && selector.selectItem) {
                        selector.selectItem(trigger);
                    }

                    this.validate(false);
                };

                /**
                 * add a trigger to the list of triggers
                 */
                FixedNodeTriggerModel.prototype.removeTrigger = function (trigger, selector) {
                    var index = this.findTriggerIndex(trigger);

                    if (index != null && index >= 0) {
                        this.triggers.splice(index, 1);
                        if (selector != null) {
                            selector.itemRemoved(trigger);
                        }
                        this.validate(false);
                    }
                };

                FixedNodeTriggerModel.prototype.findTriggerIndex = function (trigger) {
                    return _.findIndex(this.triggers, function (m) {
                        return m == trigger;
                    });
                };

                FixedNodeTriggerModel.prototype.cloneTrigger = function (trigger, selector) {
                    var newTrigger = null;
                    if (trigger != null) {
                        newTrigger = trigger.customClone();
                    }

                    if (newTrigger != null) {
                        var addAfterIndex = this.findTriggerIndex(trigger);
                        if (addAfterIndex == null || addAfterIndex >= this.triggers.length - 1) {
                            this.triggers.push(newTrigger);
                        }
                        else {
                            this.triggers.splice(addAfterIndex + 1, 0, newTrigger);
                        }

                        if (selector && selector.selectItem) {
                            selector.selectItem(newTrigger, false, false);
                        }
                    }
                };

                /***************************************************************
                 *  Expression List Model For Business Rule
                 ***************************************************************/
                FixedNodeFunctionalExpressionModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function FixedNodeFunctionalExpressionModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                    this.text = 'Functional Expression Conditions';
                    this.nodeType = RuleConstantsService.nodeType.FUNCTIONAL_EXPRESSION_FIXED_NODE;
                    this.collapsed = false;
                    this.expressions = [];

                    this.addExpression();
                }

                /**
                 * Create expression model from dto and set it in expression model list
                 * @param dto
                 */
                FixedNodeFunctionalExpressionModel.prototype.fromDto = function (dto) {
                    var _this = this;
                    _this.expressions = [];

                    _.forEach(dto.evaluationExpressions, function (expression) {
                        var expressionModel = new RuleExpressionModelService.ExpressionModel(_this);
                        expressionModel.fromDto(expression);
                        _this.expressions.push(expressionModel);
                    });
                };


                /**
                 * validate the entity
                 */
                FixedNodeFunctionalExpressionModel.prototype._validateSelf = function () {
                    // no need to validate anything for this model
                };

                FixedNodeFunctionalExpressionModel.prototype.addExpression = function () {
                    var expressionModel = new RuleExpressionModelService.ExpressionModel(this);

                    this.expressions.push(expressionModel);

                    this.collapsed = false;
                };

                FixedNodeFunctionalExpressionModel.prototype.findExpressionIndex = function (expression) {
                    return _.findIndex(this.expressions, function (e) {
                        return e == expression;
                    });
                };

                FixedNodeFunctionalExpressionModel.prototype.customClone = function () {
                    var newExpressionFixedNode = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);
                    var newExpressions = [];
                    _.forEach(this.expressions, function (expression) {
                        var newExpression = expression.customClone();
                        newExpression._parent = newExpressionFixedNode;
                        newExpressions.push(newExpression);
                    });
                    newExpressionFixedNode.expressions = newExpressions;
                    return newExpressionFixedNode;
                };

                FixedNodeFunctionalExpressionModel.prototype.cloneExpression = function (expression, selector) {
                    var newExpression = null;
                    if (expression != null) {
                        newExpression = expression.customClone();
                    }

                    if (newExpression != null) {
                        var addAfterIndex = this.findExpressionIndex(expression);
                        if (addAfterIndex == null || addAfterIndex >= this.expressions.length - 1) {
                            this.expressions.push(newExpression);
                        }
                        else {
                            this.expressions.splice(addAfterIndex + 1, 0, newExpression);
                        }

                        if (selector && selector.selectItem) {
                            selector.selectItem(newExpression, false, false);
                        }
                    }
                };

                /***************************************************************
                 *  Parent model for SQL based expression and related actions
                 ***************************************************************/
                FixedNodeSQLExpressionModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function FixedNodeSQLExpressionModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                    this.text = 'SQL Expression Conditions & Actions';
                    this.nodeType = RuleConstantsService.nodeType.SQL_EXPRESSION_FIXED_NODE;
                    this.collapsed = false;
                    this.expressionActionHolders = [];

                    this.addExpressionActionHolder();
                }

                FixedNodeSQLExpressionModel.prototype.fromDto = function (dto) {
                    var _this = this;
                    _this.expressionActionHolders = [];
                    _.forEach(dto.evaluationExpressions, function (expression) {
                        var holder = new FixedNodeSQLExpressionActionHolderModel(_this);
                        holder.fromDto(expression);
                        _this.expressionActionHolders.push(holder);
                    });
                };

                FixedNodeSQLExpressionModel.prototype.addExpressionActionHolder = function () {
                    var holder = new FixedNodeSQLExpressionActionHolderModel(this);
                    this.expressionActionHolders.push(holder);
                };

                FixedNodeSQLExpressionModel.prototype.findExpressionActionHolderIndex = function (holder) {
                    return _.findIndex(this.expressionActionHolders, function (e) {
                        return e == holder;
                    });
                };

                FixedNodeSQLExpressionModel.prototype.customClone = function () {
                    var newFixedNode = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);
                    var newExpressions = [];
                    _.forEach(this.expressionActionHolders, function (holder) {
                        var newExpression = holder.customClone();
                        newExpression._parent = newFixedNode;
                        newExpressions.push(newExpression);
                    });
                    newFixedNode.expressions = newExpressions;
                    return newFixedNode;
                };

                FixedNodeSQLExpressionModel.prototype.cloneExpressionActionHolder = function (holder, selector) {
                    var newHolder = null;
                    if (holder != null) {
                        newHolder = holder.customClone();
                    }

                    if (newHolder != null) {
                        var addAfterIndex = this.findExpressionActionHolderIndex(holder);
                        if (addAfterIndex == null || addAfterIndex >= this.expressionActionHolders.length - 1) {
                            this.expressionActionHolders.push(newHolder);
                        }
                        else {
                            this.expressionActionHolders.splice(addAfterIndex + 1, 0, newHolder);
                        }

                        if (selector && selector.selectItem) {
                            selector.selectItem(newHolder, false, false);
                        }
                    }
                };

                /***************************************************************
                 *  Holder model to hold SQL based expression and actions together
                 *
                 *  Note: This Model maps to EvaluationExpressionDTO in business rule hierarchy
                 ***************************************************************/
                FixedNodeSQLExpressionActionHolderModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function FixedNodeSQLExpressionActionHolderModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);
                    this.text = 'SQL Expression Condition & Actions';
                    this.nodeType = RuleConstantsService.nodeType.SQL_EXPRESSION_ACTION_HOLDER_FIXED_NODE;
                    this.collapsed = false;

                    this.id = null;
                    this.name = 'New Expression';

                    // SQL based expression condition
                    var ExpressionConditionModelService = $injector.get('ExpressionConditionModelService');
                    this.expressionCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.BUSINESS_RULE);

                    // fixed node to hold actions under this expression
                    this.actionHolderFixedNode = new FixedNodeActionHolderModel(this);
                }

                FixedNodeSQLExpressionActionHolderModel.prototype.fromDto = function (dto) {
                    // SQL expression condition from dto to model
                    this.id = dto.id;
                    this.name = dto.name;

                    // set expression condition from dto
                    var ExpressionConditionModelService = $injector.get('ExpressionConditionModelService');
                    this.expressionCondition = new ExpressionConditionModelService.ExpressionConditionModel(this, false, true, ExpressionConditionConstantsService.usageTypes.BUSINESS_RULE);
                    this.expressionCondition.fromDto(dto.expressionCondition);

                    // set actions mapping from dto to model
                    this.actionHolderFixedNode = new FixedNodeActionHolderModel(this);
                    this.actionHolderFixedNode.fromDto(dto);
                };

                FixedNodeSQLExpressionActionHolderModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id = this.id;
                    dto.name = this.name;
                    dto.expressionCondition = this.expressionCondition.toDto();

                    if(dto.expressionCondition != null){
                        // override expression name if SQL condition exists
                        dto.name = dto.expressionCondition.name;
                    }

                    // set expression action mapping from model to dto
                    ExpressionService.setActionMappingFromModelInExpressionDto(dto, this.actionHolderFixedNode, this.id);

                    return dto;
                };

                FixedNodeSQLExpressionActionHolderModel.prototype.customClone = function () {
                    var holder = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);

                    var clonedExpressionCondition = this.expressionCondition.customClone();
                    clonedExpressionCondition._parent = this;
                    holder.expressionCondition = clonedExpressionCondition;

                    var clonedActionHolderFixedNode = this.actionHolderFixedNode.customClone();
                    clonedActionHolderFixedNode._parent = this;
                    holder.actionHolderFixedNode = clonedActionHolderFixedNode;

                    return holder;
                };
                FixedNodeSQLExpressionActionHolderModel.prototype.getDescriptionAsHtml = function () {
                    var message = '<span data-nodrag class="badge badge-info">'+this.text+'</span>';
                    message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                    message += ' <i>' + (this.expressionCondition != null ? this.name : "<b class='bg-success'>(*** ALWAYS TRUE ***)</b>") + '</i>';
                    return message;
                };
                /***************************************************************
                 *  Holder model to hold actions under SQL based expression
                 ***************************************************************/
                FixedNodeActionHolderModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function FixedNodeActionHolderModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

                    // Add nodeType for tree structure
                    this.text = 'Actions';
                    this.nodeType = RuleConstantsService.nodeType.ACTION_HOLDER_FIXED_NODE;
                    this.collapsed = false;

                    this.actionHolders = [];
                }

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 * @param dto
                 */
                FixedNodeActionHolderModel.prototype.fromDto = function (dto) {
                    // set actions mapping from dto to model
                    ExpressionService.setActionMappingFromDtoToExpressionModel(this, dto);
                };


                /**
                 * validate the entity
                 */
                FixedNodeActionHolderModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.actionHolders.length === 0) {
                        this.setErrorMessage('At least one action must be added for each expression');
                    }
                };

                FixedNodeActionHolderModel.prototype.getRule = function () {
                    return this.getRoot();
                };

                FixedNodeActionHolderModel.prototype.customClone = function () {
                    return ExpressionService.cloneExpressionWithAllActions(this);
                };

                FixedNodeActionHolderModel.prototype.cloneAction = function (actionHolder, selector) {
                    ExpressionService.cloneAndAddActionToExpression(this, actionHolder, selector);
                };

                FixedNodeActionHolderModel.prototype.hasAsyncAction = function () {
                   return ExpressionService.expressionHasAsyncAction(this);
                };
                /***************************************
                 * service return call
                 ***************************************/

                return {
                    FixedNodeTriggerModel: FixedNodeTriggerModel,
                    FixedNodeFunctionalExpressionModel: FixedNodeFunctionalExpressionModel,
                    FixedNodeSQLExpressionModel: FixedNodeSQLExpressionModel,
                    getTriggerTypes: RuleTriggerModelService.getTriggerTypes
                };
            });
})();
