/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one component field
     */
        .directive('treeNodeField', function (vbrCommonUtil, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/field.html',
                scope: {
                    field: '=',
                    currentTreeName : '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.flags = {
                        dropDownIsOpen : false
                    };

                    // use this variable for faster watch calculation
                    $scope.noSubFields = vbrCommonUtil.isNullOrUnavailable($scope.field.subFields);

                    /**
                     * clone the field, insert it right below the current one
                     */
                    $scope.cloneField = function() {
                        var field = $scope.field;
                        field._parent.cloneField(field, $scope.selector, $scope.currentTreeName);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * delete current field
                     */
                    $scope.deleteField = function() {
                        $scope.field._parent.removeField($scope.field, $scope.selector);
                        $scope.selector.selectItem($scope.field._parent);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * cut the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCut = function() {
                        $scope.selector.cutItem($scope.field);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * copy the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCopy = function() {
                        $scope.selector.copyItem($scope.field);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * check if the current item in buffer can be pasted as a sibling
                     * @returns {boolean}
                     */
                    $scope.canPasteBelowAsSibling = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not the same as current field
                        return itemInBuffer.nodeType == FormConstants.nodeType.FIELD &&
                            itemInBuffer != $scope.field;
                    };


                    /**
                     * paste the last element copied or cut as a sibling
                     * ie: this element copied of cut must be a form field
                     */
                    $scope.doPasteBelowAsSibling = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;

                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeField(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to the parent of this element
                        elementInBuffer._parent = $scope.field._parent;

                        // add it to the next spot in the children array
                        var fields = $scope.field._parent.formComponentFields;
                        var index = fields.indexOf($scope.field);
                        fields.splice(index+1, 0, elementInBuffer);

                        // select the new item
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * check if we should show the sub-fields in the listing
                     */
                    $scope.shouldShowSubFields = function() {
                        var field = $scope.field;
                        var subFields = field.subFields;

                        // show it if
                        // 1. has subFields +
                        // 2. is on hover or one of the subfield is selected or current field is selected
                        if ($scope.noSubFields) {
                            return false;
                        }

                        if ($scope.onHover || field.selected) {
                            return true;
                        }

                        // check if any of the sub fields are selected
                        return _.find(subFields, function(sf) {
                            return sf.selected;
                        }) != null;
                    }

                }
            };
        });
})();

