/**
 * directive for configuring the template content value of text type
 */
angular.module('acadiamasterApp').directive('templateValueTextConfig', (LocaleConstantsService) => ({
    link: ($scope) => {
       $scope.LocaleConstantsService = LocaleConstantsService;
    },
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
        'templateValue.text.config.html',
}));

