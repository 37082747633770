/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for config text style out of list of possibilities
     */
        .directive('configTextStyle', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/textStyle.html',
                scope: {
                    currentStyle: '=',
                    currentStyleChange : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.hasChangeModel = $scope.currentStyleChange!==undefined;

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        currentStyleChange : $scope.currentStyleChange,
                        makingChange : $scope.hasChangeModel && $scope.currentStyleChange!=null
                    };

                    if ($scope.currentStyleChange===null) {
                        $scope.$watch("data.currentStyleChange", function(newValue) {
                            $scope.currentStyleChange = newValue===undefined ? null : newValue;
                        });
                    }
                    $scope.getStyle = function() {
                        return $scope.currentStyle;
                    };
                    $scope.resetChange = function() {
                        $scope.currentStyleChange = null;
                        $scope.data.makingChange = false;
                        $scope.data.currentStyleChange = null;
                    };

                    $scope.startChange = function() {
                        $scope.data.makingChange = true;
                    };

                    $scope.getCurrentStyle = function() {
                        return $scope.currentStyleChange!=null ? $scope.currentStyleChange : $scope.currentStyle;
                    };

                    $scope.selectStyle = function(newStyle) {
                        if ($scope.currentStyleChange!==undefined || $scope.currentStyleChange!=null) {
                            $scope.currentStyleChange = newStyle;
                        }
                        else {
                            $scope.currentStyle = newStyle;
                        }
                    };
                }
            };
        });
})();

