'use strict';

(function(){

    angular.module('acadiamasterApp').
    directive('conditionConfig', function (ProgramTestConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionConfig.html',
            scope: {
                condition: '='
            },
            link: function($scope) {
                $scope.cs = ProgramTestConstantsService;
                $scope.conditionTypes = ProgramTestConstantsService.QObjectType;
            }
        };
    });
})();