(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('suggestedAddress', function (AddressVerificationSetup) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/addressVerification/suggestedAddress.html',
                scope: {
                    parentForm: '=',
                    page: '='
                },
                link: function ($scope) {
                    $scope.ready = false;
                    $scope.labelField = AddressVerificationSetup.getSuggestedAddressLabelField($scope.page);
                    $scope.ready = true;
                }
            };
        });

})();


