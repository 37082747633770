(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('addressTypeConfig', function (
        AddressStatesConstant, ValueType, ValueComparisonOptionModel) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/addressTypeConfig.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.typeValueComparisonOption = createTypeValueComparisonOption($scope.condition);
                $scope.stateValueComparisonOption = createStateValueComparisonOption($scope.condition);

                $scope.isReady = true;

            }
        };

        function createTypeValueComparisonOption(condition) {
            const typeArray = ['ACCOUNT', 'MAILING'];
            const typeOptions = {};
            typeArray.forEach(function (option) {
                typeOptions[option] = { name: option, text: option };
            });

            const valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = true;
            valueComparisonOption.canConfigureOperator = true;

            valueComparisonOption.functions.getValueType = function() {
                return ValueType.STRING;
            };

            valueComparisonOption.functions.getOperator = function() {
                return condition.getTypeComparator();
            };

            valueComparisonOption.functions.setOperator = function (newOperator) {
                condition.setTypeComparator(newOperator);
            };

            valueComparisonOption.functions.getValues = function() {
                return condition.typeList;
            };

            valueComparisonOption.functions.setValues = function (newValues) {
                condition.typeList = newValues;
            };

            valueComparisonOption.functions.getPossibleValues = function () {
                return typeOptions;
            };

            return valueComparisonOption;
        }

        function createStateValueComparisonOption(condition) {
            const stateArray = ['NONE', 'SYSTEM', 'SELF'];
            const stateOptions = {};
            stateArray.forEach(function (option) {
                stateOptions[option] = { name: option, text: option };
            });

            const valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = true;
            valueComparisonOption.canConfigureOperator = true;

            valueComparisonOption.functions.getValueType = function() {
                return ValueType.STRING;
            };

            valueComparisonOption.functions.getOperator = function() {
                return condition.getStateComparator();
            };

            valueComparisonOption.functions.setOperator = function (newOperator) {
                condition.setStateComparator(newOperator);
            };

            valueComparisonOption.functions.getValues = function() {
                return condition.stateList;
            };

            valueComparisonOption.functions.setValues = function (newValues) {
                condition.stateList = newValues;
            };

            valueComparisonOption.functions.getPossibleValues = function () {
                return stateOptions;
            };

            return valueComparisonOption;
        }

    });
})();
