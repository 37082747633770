'use strict';

angular.module('acadiamasterApp')
    .factory('FirebaseProject', function (localWeb, $http) {
        function getProjects(onFinished){
            $http.get(localWeb.createUrl('api/programappsettings/firebaseprojects'))
                .then(function (response){
                    onFinished(response.data);
                });
        };

        return { getProjects };
    });
