'use strict';

(function () {

    angular.module('acadiamasterApp').controller(
        'ExternalDataImportController',
        function ($scope,ExternalDataImportService,ParseLinks,$stateParams,ngDialog) { 
            
            $scope.page = 0;
            const statusList = ['COMPLETED_WITH_SURVEY_IMPORT_FAILURE','FAILED','YET_TO_BEGIN'];

            $scope.loadAll =function() {
                let promise = ExternalDataImportService.getAllTasks({page: $scope.page, pageSize: 10});
                promise.then(function (response) {
                   $scope.links = ParseLinks.parse(response.headers('link'));
                   $scope.externalImportTasks = response.data;
                }, function (error) {
                   console.log(error);
                });
            };

            $scope.loadPage = function (page) {
                $scope.page = page;
                $scope.loadAll();
            };

            $scope.startTask= function(taskId) {
               
                // open a pop up for displaying the program test data
                ngDialog.openConfirm({
                    template: 'runTask-confirmation-dialog'
                }).then(function () {
                    ExternalDataImportService.startTask(taskId).then(function (response) {
                        $scope.loadAll();
                    }).catch(function (error) {
                        console.error(error);
                    });
                });
            };

            $scope.showTrigger = function(status) { 
                return statusList.includes(status);
            };

            $scope.loadAll();

        }
        );
}());

