/**
 * Created by pchaudhary
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for default value of signature type
     */
        .directive('formFieldConfigFieldValueSignatureOptions', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/signatureOptions.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.cs = FormConstants;
                    $scope.signatureOptions = {
                        "TEXT": {name: "TEXT", text: 'Text Input'},
                        "CANVAS": {name: 'CANVAS', text: 'Canvas'}
                    };
                }
            };

        });

})();

