/**
 * Directive to search user
 * Currently only used on check and fix site pairing page but can be re-used at multiple places
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrUserSearch', function (AlertService,
        FormEntrySearchService, FilterFieldService, FilterConfigService) {

        /***************************************
         * directive return call
         ***************************************/

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/util/filters/searchFilter.html',
            scope: {
                searchResult: '=',
                showAwardeeCodeProperty: '=',
                onSearchFinishCallback: '='

            },
            link: function ($scope) {

                // all the data and flags are stored here
                $scope.data = {
                    pageSizes: [10, 25, 50, 100],
                    filter: new FilterConfigService.FilterConfig($scope.showAwardeeCodeProperty),
                    filterTabOpen: true
                };

                // query builder constants that are not going to change
                $scope.qbOptions = {
                    comparators: FilterFieldService.comparators,
                    operators: FilterFieldService.operators,
                    json: null,
                    settings: {
                        nesting: true,
                        addIconClass: 'glyphicon glyphicon-plus',
                        removeIconClass: 'glyphicon glyphicon-minus',
                        addButtonClass: 'btn btn-sm btn-success',
                        removeButtonClass: 'btn btn-sm btn-danger'
                    },
                    filter: {
                        group: {
                            operator: FilterFieldService.operators[0],
                            rules: []
                        }
                    }
                };

                $scope.doSearch = function () {
                    doSearch($scope);
                };

                init($scope);

            }
        };


        /****************************Private methods*******************************/

        /**
         * initialization function
         * @param $scope scope object
         */
        function init($scope) {
            $scope.searchResult = {};
            $scope.qbOptions.humanReadable = groupToReadable($scope.qbOptions.filter.group);

            // add deep watch on query builder's filter object
            $scope.$watch('qbOptions.filter', function (newValue) {
                $scope.qbOptions.json = JSON.stringify(newValue, null, 2);
                $scope.qbOptions.humanReadable = groupToReadable(newValue.group);
            }, true);
        }


        /**
         * perform the search here
         * @param $scope
         */
        function doSearch($scope) {
            var group = $scope.qbOptions.filter.group;
            var filter = $scope.data.filter;

            var advancedSearchDTO = {
                queryGroup: group,
                pageable: {
                    pageSize: filter.pageSize,
                    pageNumber: filter.pageNum,
                    sort: filter.sort
                },
                fields: filter.fields
            };


            FormEntrySearchService.advancedSearch(advancedSearchDTO).then(function (response) {
                $scope.searchResult = processSearchResult(response.data, $scope);
                $scope.data.filterTabOpen = false;
            }).catch(function () {
                AlertService.error('Unable to perform search, check log for more detail');
            }).finally(function () {
                if (_.isFunction($scope.onSearchFinishCallback)) {
                    $scope.onSearchFinishCallback();
                }
            });
        }

        /**
         * process the search response into a more workable format for display
         * @param searchResponse - search response
         * @param $scope - scope object
         * @returns {{users: {}, numOfUsers: number, totalEntries: number, totalPages: number, currentPage: number, columns: *}}
         */
        function processSearchResult(searchResponse, $scope) {
            var result = {
                users: {},
                numOfUsers: 0,
                numOfEntries: 0,
                totalEntries: 0,
                totalPages: 0,
                currentPage: 0,
                columns: buildColumns($scope.data.filter.fields)
            };

            if (searchResponse == null) {
                return result;
            }

            // load user data
            var userDtos = searchResponse.userDTOList;
            if (userDtos != null && userDtos.length > 0) {
                result.numOfUsers = userDtos.length;
                _.forEach(userDtos, function (u) {
                    result.users[u.id] = u;
                    // add profiles here later
                    u.profiles = {};
                    u.numOfProfiles = 0;
                    // add entries here later
                    u.entries = [];
                });
            }

            // load profile field data
            var profiles = searchResponse.profileFieldList;
            if (profiles != null && profiles.length > 0) {
                _.forEach(profiles, function (p) {
                    var userId = p.userId;
                    var propertyName = p.propertyName;
                    result.users[userId].numOfProfiles++;
                    result.users[userId].profiles[propertyName] = p;
                });
            }

            // load form entry data
            var entryPage = searchResponse.formEntryDTOPage;
            if (entryPage != null) {
                result.totalEntries = entryPage.totalElements;
                result.totalPages = entryPage.totalPages;
                result.currentPage = entryPage.number;
                result.numOfEntries = entryPage.numberOfElements;

                var contents = entryPage.content;
                if (contents != null) {
                    _.forEach(contents, function (e) {
                        var userId = e.userId;
                        result.users[userId].entries.push(e);
                    });
                }
            }

            return result;
        }

        function buildColumns(fields) {
            var columns = [];
            _.forEach(fields, function (f) {
                var column = createColumn(f);
                if (column != null) {
                    columns.push(column);
                }
            });
            return columns;
        }

        function createColumn(field) {
            var column = {
                name: field.name,
                valueType: field.valueType,
                fieldId: field.id,
                formId: field.formId,
                fieldType: field.fieldType,
                fieldHtml: field.fieldHtml,
                type: field.type
            };

            if (column.fieldId < 0) {
                // other column that's special are not needed to be listed in columns
                column = null;
            }
            return column;
        }

        function getDataName(data, options) {
            var name;
            var matchingOption = _.find(options, { 'id': data.id });
            if (matchingOption != null) {
                name = matchingOption.name;
            }
            return name;
        }

        function groupToReadable(group) {
            if (!group) {
                return 'No Filter';
            }
            var str = '';
            for (var i = 0; i < group.rules.length; i++) {
                // add operator if it's not first element of group
                if (i > 0) {
                    str += ' ' + group.operator.name + ' ';
                }

                // Get readable for rule
                var rule = group.rules[i];
                var ruleDescription = ruleToReadable(rule);

                // Append group string if the element was group or else append rule string
                if (rule.group) {
                    str += '(' + groupToReadable(rule.group) + ')';
                } else {
                    if (rule.comparator.id === 8 ) {
                        str += rule.field.name + ' ' + rule.comparator.value;
                        // Since this is the NOT NULL operator we do not want a value, so set the data/value field to an empty string!
                        rule.data = '';
                    } else {
                        str += rule.field.name + ' ' + rule.comparator.value + ' "' + ruleDescription + '"';
                    }
                }
            }

            if (str === '') {
                str = 'No Filter';
            }

            return str;
        }

        function ruleToReadable(rule) {
            var dataString = '';
            if (rule.field && rule.field.options && rule.field.options.length > 0) {
                var isFirst = true;
                if (!!rule.data && Array.isArray(rule.data)) {
                    rule.data.forEach(function (data) {
                        if (!isFirst) {
                            dataString += ',' + getDataName(data, rule.field.options);
                        } else {
                            isFirst = false;
                            dataString += getDataName(data, rule.field.options);
                        }
                    });
                } else {
                    dataString = rule.data.name;
                }
            } else {
                dataString = rule.data;
            }
           return dataString;
        }


    });

})();

