(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for dismissing a banner
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionDismissBannerModel',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, RuleConstantsService, FormLoadUtil) {

            CorrectiveActionDismissBannerModel.inheritsFrom(CorrectiveActionBaseModel);

            function CorrectiveActionDismissBannerModel(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.DISMISS_BANNER);

                this.bannerType = null;
                this.formId = null;

                this._form = null;
            }

            CorrectiveActionDismissBannerModel.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.bannerType = RuleConstantsService.getObjectByName(RuleConstantsService.bannerType, dto.bannerType);
                this.formId = dto.formId;

                FormLoadUtil.loadForm(this);
            };

            CorrectiveActionDismissBannerModel.prototype.toDto = function () {
                var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.bannerType = this.bannerType.name;
                if (this.bannerTypeNeedsForm()) {
                    dto.formId = this.formId;
                }
                return dto;
            };

            CorrectiveActionDismissBannerModel.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form == null ? null : form.id;
            };

            CorrectiveActionDismissBannerModel.prototype.getForm = function () {
                return this._form;
            };

            CorrectiveActionDismissBannerModel.prototype.bannerTypeNeedsForm = function () {
                return this.bannerType != null && this.bannerType == RuleConstantsService.bannerType.OPEN_FORM;
            };

            CorrectiveActionDismissBannerModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);
                return typeMsg + 'Banner Type: - ' + (this.bannerType!=null ? this.bannerType.name : 'Not Selected')
                    + (this.bannerTypeNeedsForm() ? ' with form id ' + this.formId : '');            };

            CorrectiveActionDismissBannerModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.bannerType == null) {
                    this.setErrorMessage('banner type is required');
                    return;
                }

                if (this.formId == null && this.bannerTypeNeedsForm()) {
                    this.setErrorMessage('form selection is required');
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionDismissBannerModel;
        });
})();
