(function () {
    angular
        .module('acadiamasterApp')
        .controller('ActivateOrphanRuleController', ($scope, BusinessRule, ProgramService, AlertService, RuleModelService) => {
            $scope.businessRule = $scope.ngDialogData;

            // Private function
            function fetchRuleDetails ($scope, ruleId) {
                BusinessRule.get({
                    id : ruleId,
                })
                    .$promise.then(data => {
                        $scope.selectedRuleModel = new RuleModelService.RuleModel(data.type, data.programId);
                        $scope.selectedRuleModel.fromDto(data);
                    })
                    .catch(error => {
                        AlertService.error(`Failed to fetch rule details for rule id: ${ruleId}`, error);
                    });
            }

            function init () {
                ProgramService.query({}, result => {
                    $scope.programs = result;
                });
                fetchRuleDetails($scope, $scope.businessRule.id);
            }

            $scope.activateOrphanRule = function () {
                $scope.selectedRuleModel.programId = $scope.selectedProgramId;
                $scope.selectedRuleModel.enabled = true;
                RuleModelService.saveRule([], $scope.selectedRuleModel, response => {
                    $scope.confirm(response);
                });
            };

            init();
        });
}());
