/**
 * Created by amit.rao on 15-07-2016
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('expressionParser', function (ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/util/expression/expression.html',
                scope: {
                    expression: '=',
                    model: '=',
                    element: '=',
                    usedBy: '=',
                    title: '@',
                    variables: '=',
                    hideTree: '=',
                    form: '='
                },
                link: function ($scope) {
                    var _scope = $scope;

                    $scope.getTitle = function() {
                        if ($scope.title) {
                            return $scope.title;
                        }

                        return 'Condition';
                    };

                    $scope.showEditWindow = function () {
                        var template = '<expression-parser-edit expression-data="data" close-function="closeFunction"></expression-parser-edit>';
                        var data = {
                            expression: _scope.expression,
                            model: _scope.model,
                            element: _scope.element,
                            usedBy: _scope.usedBy,
                            variables: _scope.variables,
                            title: $scope.title,
                            form: $scope.form
                        };

                        // open modal window and pass in all the scope variables
                        var dialog = ngDialog.open({
                            showClose: false,
                            closeByEscape: false,
                            closeByDocument: false,
                            template: template,
                            plain: true,
                            controller: ['$scope', function ($scope) {
                                $scope.data = data;
                                $scope.closeFunction = closeDialog;
                            }],
                            className: 'ngdialog-theme-plain custom-width-large'
                        });

                        function closeDialog() {
                            // setting value back to parent directive after it is closed
                            _scope.expression = data.expression;
                            _scope.model = data.model;
                            dialog.close();
                        }
                    };
                }
            };
        });


})();
