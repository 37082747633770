(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * program rule association directive
     */
        .directive('exportWizardStepProperties', function (DataTypeSearch, AlertService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepProperties.html',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '='
                },
                link: function ($scope) {
                    init($scope);

                    $scope.selectAll = function () {
                        if ($scope.data.selectAllValue) {
                            $scope.selectedData.properties = angular.copy($scope.data.properties);
                        } else {
                            $scope.selectedData.properties = [];
                        }
                    };

                    $scope.sortBy = function (name) {
                        $scope.data.orderByAsc = ($scope.data.orderBy === name) ? !$scope.data.orderByAsc : true;
                        $scope.data.orderBy = name;
                    };

                    $scope.$watch('selectedData.properties.length', function(nv) {
                        $scope.data.selectAllValue = nv === $scope.data.properties.length && nv > 0;
                    });
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.data = {
                    orderBy: 'id',
                    orderByAsc: true,
                    selectAllValue: false,
                    properties: []
                };

                loadDataTypeForProgram($scope);
            }

            function loadDataTypeForProgram($scope) {
                var programId = $scope.program.id;
                // load program specific data type which will have properties used in profile values
                // Data type name = '__'+ProgramId+'__', e.g. '__106__'
                var programDataTypeName = '__' + programId + '__';
                DataTypeSearch.query({
                    name: programDataTypeName
                }, function (result) {
                    // Program specific data types can only be created by system when admin create program for first time.
                    // This response list will always have only one object
                    // Getting first object from list
                    if (result == null || result.length <= 0) {
                        AlertService.error('Failed to load program specific data type for Program Id: ' + programId);
                        return;
                    }

                    var dataType = result[0];
                    if (dataType.propertyMappings == null || dataType.propertyMappings.length <= 0) {
                        AlertService.warning('No properties associated to this program');
                        return;
                    }

                    var properties = [];
                    for (var i = 0; i < dataType.propertyMappings.length; i++) {
                        var mapping = dataType.propertyMappings[i];
                        properties[i] = mapping.property;
                    }

                    $scope.data.properties = properties;
                });
            }

        });


})();
