(function () {
    'use strict';

    /**
     * Update field value of multi-select field to text from number
     */
    angular.module('acadiamasterApp').factory('UpdateMultiSelectValueType', function (ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        UpdateMultiSelectValueType.inheritsFrom(ScriptActionBaseModel);

        function UpdateMultiSelectValueType() {
            this.name = 'Update field value of multi-select field to text from number';
            this.steps = [
                {
                    ticketId: 'AC-33609',
                    description: '<b>THIS UPDATES THE VALUE TYPE FOR ALL MULTI SELECTS. DO NOT USE IT OTHER THAN PMH FORM</b> <br>' +
                    'Find a multi-select field with number value type -->  Update value type to text. ',
                    searchFunction: findNumericMultiSelectFields,
                    changeFunction: updateFieldValueType
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        function findNumericMultiSelectFields(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

            var targets = FormScriptActionUtil.findFieldsByType(formMode, FormConstants.fieldsType.MULTI_SELECTOR);

            targets = _.filter(targets, function (target) {
                return target.fieldValue.valueType == FormConstants.fieldEntryValueType.NUMBER;
            });

            return targets;
        }

        function updateFieldValueType(target) {
            target.fieldValue.valueType = FormConstants.fieldEntryValueType.STRING;
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateMultiSelectValueType;
    });

})();
