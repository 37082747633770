/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('FormDeleteController', function($scope, ngDialog, Form) {
            $scope.delete = function() {
                Form.delete({
                    id: $scope.ngDialogData.id
                }, function() {
                    $scope.confirm($scope.ngDialogData.id);
                });
            };
        });

})();

