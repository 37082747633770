(function () {
    'use strict';

    /***************************************************************
     * Precondition for rule exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionRuleExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, RuleLoadUtil) {

            PreConditionRuleExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for rule exist
             * @constructor
             */
            function PreConditionRuleExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.RULE_EXIST);

                this.ruleId = null;

                this._rule = null;
            }

            PreConditionRuleExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.ruleId = dto.ruleId;

                RuleLoadUtil.loadRule(this);
            };

            PreConditionRuleExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.ruleId = this.ruleId;

                return dto;
            };

            PreConditionRuleExistModel.prototype.setRule = function (rule) {
                this._rule = rule;
                this.ruleId = rule==null ? null : rule.id;
            };

            PreConditionRuleExistModel.prototype.getRule = function () {
                return this._rule;
            };

            PreConditionRuleExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var ruleMsg = 'Rule (' + (this.ruleId == null ? 'no id' : this.ruleId) + ' | ' +
                    (this._rule == null ? 'no name' :  this._rule.name) + ')';

                return typeMsg + ruleMsg +  ' should exist ';
            };

            PreConditionRuleExistModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.ruleId == null) {
                    this.setErrorMessage('rule id is required');
                }
            };


            return PreConditionRuleExistModel;
        });
})();
