'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeRootCategoryCreateEditController',
    ['$scope', '$location', '$state', '$stateParams', 'entity', 'DataTypeRootCategory', 'PropertiesLookup', 'UnitsLookup',
        'UnitCategoriesLookup', 'isNew', 'AlertService', 'vbrCommonUtil', 'DataTypeMasterRecordCount', 'ngDialog',
        'CONFIG', function ($scope, $location, $state, $stateParams, entity, DataTypeRootCategory, PropertiesLookup, UnitsLookup,
                  UnitCategoriesLookup, isNew, AlertService, vbrCommonUtil, DataTypeMasterRecordCount, ngDialog, CONFIG) {
            $scope.CONFIG = CONFIG;
            $scope.dataTypeRootCategory = entity;

            $scope.isNew = isNew;

            $scope.mappingStatus = {
                OVERWRITTEN: 'O',
                REMOVED: 'R'
            };

            $scope.valueType = {
                NUMBER: 'NUMBER',
                STRING: 'STRING',
                DATE: 'DATE'
            };

            $scope.usedFor = {
                DATAFORM: 'DATAFORM',
                PROFILEFORM: 'PROFILEFORM',
                ALL: 'ALL'
            };

            $scope.formData = {
                property: "",  // input box ng-model bind to this
                addedPropertyMappings: [],  // list of newly added properties
                existingPropertyMappingsCopy: [] // list of existing properties
            };

            $scope.settings = {
                comboBoxSettings: [],  // all combo box settings for property units are stored here
                propertySettings: createPropertyLookAheadSettings()
            };

            $scope.lookupData = {
                units: null,
                unitCategories: null
            };

            $scope.states = {
                isNew: isNew,
                propertyReady: false,
                unitReady: false,
                unitCategoryReady: false,
                hasProperties : hasProperties
            };

            $scope.addProperty = addProperty;

            $scope.removeProperty = removeProperty;

            $scope.removePropertyAssociated = removePropertyAssociated;

            $scope.load = load;

            $scope.save = save;

            $scope.exitOut = exitOut;

            $scope.finishedWizard = save;
            $scope.canExitDueToValidation = canExitDueToValidation;

            $scope.filterUnits = function(property) {
                property.units = [];
                $scope.settings.comboBoxSettings[property.name] = createComboBoxSettings(property.units, property.unitCategoryId);
                property.unitCategoryName = findUnitCategoryName(property.unitCategoryId);
            };

            $scope.checkValueType = function(property) {
               if (property.valueType!='NUMBER') {
                   property.unitCategoryId = null;
                   property.units = [];
                   property.unitCategoryName = null;
               }
            };

            $scope.deleteProperty = function(property) {
                removeNewlyAddedProperty(property);
            };

            $scope.deleteAssociatedProperty = function(property) {
                var len = $scope.dataTypeRootCategory.propertyMappings.length;
                for (var i=0; i < len; i++) {
                    if (property.id == $scope.dataTypeRootCategory.propertyMappings[i].property.id) {
                        $scope.dataTypeRootCategory.propertyMappings[i].status = $scope.mappingStatus.REMOVED;
                        return 1;
                    }
                }
            };

            $scope.restoreAssociatedProperty = function(property) {
                var len = $scope.dataTypeRootCategory.propertyMappings.length;
                for (var i=0; i < len; i++) {
                    if (property.id == $scope.dataTypeRootCategory.propertyMappings[i].property.id) {
                        $scope.dataTypeRootCategory.propertyMappings[i].status = null;
                        return 1;
                    }
                }
            };

            $scope.notRemoved = function(mapping) {
                return (mapping.status==null);
            };

            $scope.openDescriptionForm = function(property) {
                $scope.selectedProperty = property;
                ngDialog.openConfirm({template: 'descriptionTemplateView',
                    scope: $scope, //Pass the scope object if you need to access in the template,
                }).then(
                    function(value) {
                        //save the contact form
                    },
                    function(value) {
                        //Cancel or do nothing
                    }
                );
            };

            init();

            /**************************************************************************************
             * private functions
             **************************************************************************************/

            function getUnitById(id) {
                var unitLists = $scope.lookupData.units;
                for (var i=0; i<unitLists.length; i++) {
                    if (unitLists[i].id == id) {
                        return unitLists[i];
                    }
                }
                return null;
            }

            function filterUnitsByUnitCategoryId(id) {
                var units = [];
                var unitLists = $scope.lookupData.units;
                for (var i=0; i < unitLists.length; i++) {
                    if (unitLists[i].unitCategoryId == id) {
                        units.push(unitLists[i]);
                    }
                }
                return units;
            }

            function createComboBoxSettings(selectedUnits, unitCategoryId) {

                var checkChangeFunction = function (event) {
                    var checked = event.args.item.checked;
                    var id = event.args.item.value;

                    var item = getUnitById(id);

                    var index = selectedUnits.indexOf(item);
                    if (checked && index == -1) {
                        selectedUnits.push(item);
                    } else if (!checked && index > -1) {
                        selectedUnits.splice(index, 1);
                    }
                };

                var commonSettings = {
                    displayMember: "name",
                    valueMember: "id",
                    width: "100%",
                    height: 34,
                    filterable: true,
                    checkboxes: true,
                    checkChange: checkChangeFunction
                };

                var existingListSetting = $.extend({
                    renderer: function (index, label, value) {
                        return label;
                    }
                }, commonSettings);


                var lookupData;

                if (unitCategoryId!=null) {
                    lookupData =  filterUnitsByUnitCategoryId(unitCategoryId);
                } else {
                    lookupData = $scope.lookupData.units;
                }

                var newSettings = vbrCommonUtil.jqxUtil.createJqxDropDownSettings(lookupData, existingListSetting);

                return newSettings;
            }


            function dataToComboSource(data) {
                var source =[];
                for (var i=0; i < data.length; i++) {
                    var row = {};
                    row["label"] = data[i].name;
                    row["name"] = data[i].name;
                    source.push(row);
                }
                return source;
            }

            function createPropertyLookAheadSettings() {
                return {
                    source: [],
                    height: 25,
                    placeHolder: "Enter a property name",
                    searchMode: 'startswithignorecase',
                    select: function (data) {
                        var value;
                        if (data.args == null) {
                            value = null;
                        } else {
                            value = data.args.value;
                        }
                        if (value != null) {
                            value = value.trim();
                        }
                        if (value.length > 0) {
                            addProperty(value);
                        }
                    }

                };
            }

            function initAllAvailablePropertiesList() {
                if (PropertiesLookup.isPropertiesReady()) {
                    $scope.settings.propertySettings.source = PropertiesLookup.getPropertyNames();
                    $scope.states.propertyReady = true;
                } else {
                    PropertiesLookup.init()
                        .then(function (data) {
                            $scope.settings.propertySettings.source = data;
                            $scope.states.propertyReady = true;
                        }).catch(function () {
                            console.info("Error while getting unit categories");
                        });
                }
            }

            function initUnitSystem() {
                if (UnitCategoriesLookup.isDataReady()) {
                    $scope.lookupData.unitCategories = UnitCategoriesLookup.getUnitCategories();
                    $scope.states.unitCategoryReady = true;
                } else {
                    UnitCategoriesLookup.init()
                        .then(function (data) {
                            $scope.lookupData.unitCategories = data;
                            $scope.states.unitCategoryReady = true;
                        }, function () {
                            console.info("Error while getting unit categories");
                        });
                }


                if (UnitsLookup.isDataReady()) {
                    $scope.lookupData.units = UnitsLookup.getUnits();
                    $scope.lookupData.unitNames = dataToComboSource($scope.lookupData.units);
                    $scope.states.unitReady = true;
                } else {
                    UnitsLookup.init()
                        .then(function (data) {
                            $scope.lookupData.units = data;
                            $scope.lookupData.unitNames = dataToComboSource($scope.lookupData.units);
                            $scope.states.unitReady = true;
                        }, function () {
                            console.info("Error while getting units");
                        });
                }
            }

            function findPropertyMapping(propertyName, propertyMappingList) {
                if (propertyMappingList != null) {
                    for (var i = 0; i < propertyMappingList.length; i++) {
                        if (propertyName.toLowerCase() == propertyMappingList[i].property.name.toLowerCase()) {
                            return propertyMappingList[i];
                        }
                    }
                }
                return null;
            }

            function findUnitCategoryName(unitCategoryId) {
                for (var i = 0; i < $scope.lookupData.unitCategories.length; i++) {
                    if ($scope.lookupData.unitCategories[i].id == unitCategoryId) {
                        return $scope.lookupData.unitCategories[i].name;
                    }
                }
            }

            function createNewPropertyMapping(propertyName) {
                var existingProperty = PropertiesLookup.getPropertyFromName(propertyName);

                if (existingProperty) {
                    existingProperty.isUsed = true;
                }

                return {
                    id: null,
                    isUserEnterable: true,
                    defaultValue: null,
                    dataTypeRootCategoryId: $scope.dataTypeRootCategory.id,
                    property: existingProperty ? existingProperty : {
                        id: null,
                        name: propertyName,
                        isUsed: true
                    }
                }
            }

            function addProperty(propertyName) {
                var property = propertyName;
                if (property == null) {
                    property = $scope.formData.property;
                }

                if (property == null || property == 0 || property.length < 2) {
                    AlertService.error("entity.validation.min", {min: 2});
                    return;
                } else if (property.length > 128) {
                    AlertService.error("entity.validation.max", {max: 128});
                    return;
                }

                var propertyMapping = null;
                if (findPropertyMapping(property, $scope.dataTypeRootCategory.propertyMappings) != null) { // already in the associated list, warning message
                    AlertService.error("global.messages.error.alreadyAssociated", {param: property});  // TODO: add key in json file
                } else if ((propertyMapping = findPropertyMapping(property, $scope.formData.existingPropertyMappingsCopy)) == null) { // never been associated before, trying to create new if needed
                    if (findPropertyMapping(property, $scope.formData.addedPropertyMappings) == null) { // never been added, create new now
                        $scope.formData.addedPropertyMappings.push(createNewPropertyMapping(property));
                        $scope.formData.property = null;
                    } else {
                        AlertService.error("global.messages.error.alreadyAdded", {param: property});
                    }
                } else { // has been associated before, and have been removed, now add it back in
                    $scope.dataTypeRootCategory.propertyMappings.push(propertyMapping);
                    $scope.formData.property = null;
                }
            }

            function initEntity() {
                if ($scope.dataTypeRootCategory.$promise) {
                    $scope.dataTypeRootCategory.$promise.then(function () {
                        $scope.formData.existingPropertyMappingsCopy = $.merge([], $scope.dataTypeRootCategory.propertyMappings);
                    })
                }
            }

            function removeProperty(property) {
                removeNewlyAddedProperty(property);
            }

            function removePropertyAssociated(mapping) {
                mapping.status = $scope.mappingStatus.REMOVED;
            }

            function load(id) {
                if (DataTypeRootCategory != null) {
                    DataTypeRootCategory.get({id: id}, function (result) {
                        $scope.dataTypeRootCategory = result;
                    });
                }
            }

            function onSaveFinished(result) {
                $scope.$emit('acadiamasterApp:dataTypeRootCategoryUpdate', result);
                DataTypeMasterRecordCount.updateCounts();
                PropertiesLookup.updateProperties();
                $state.go('dataTypeRootCategories');
            }

            function onSaveError(result) {
                console.error(result);
            }

            function getFormData(dataTypeRootCategory, addedPropertyMappings) {
                var formData = $.extend({}, dataTypeRootCategory);
                if (formData.propertyMappings == null) {
                    formData.propertyMappings = [];
                }
                if (formData.id==null) {
                    formData.usedFor = $scope.usedFor.DATAFORM;
                }
                var formDataPropertyMapping = formData.propertyMappings;
                for (var i = 0; i < addedPropertyMappings.length; i++) {
                    var propertyMapping = addedPropertyMappings[i];
                    formDataPropertyMapping.push(propertyMapping);
                }
                return formData;
            }

            function save() {

                // Delete Removed mapping from existing
                if ($scope.dataTypeRootCategory.propertyMappings) {
                    var len = $scope.dataTypeRootCategory.propertyMappings.length;
                    for (var i=len -1 ; i >= 0; i--) {
                        if ((angular.isDefined($scope.dataTypeRootCategory.propertyMappings[i].status) &&
                            $scope.dataTypeRootCategory.propertyMappings[i].status == $scope.mappingStatus.REMOVED))
                        {
                            $scope.dataTypeRootCategory.propertyMappings.splice(i, 1);
                        }
                    }
                }

                if ($scope.dataTypeRootCategory.id != null) {
                    DataTypeRootCategory.update(getFormData($scope.dataTypeRootCategory, $scope.formData.addedPropertyMappings), onSaveFinished, onSaveError);
                } else {
                    DataTypeRootCategory.save(getFormData($scope.dataTypeRootCategory, $scope.formData.addedPropertyMappings), onSaveFinished, onSaveError);
                }

            }

            function canExitDueToValidation(scopeContext) {
                if ($scope.editForm==null) {
                    return false;
                }

                return !$scope.editForm.$invalid;
            }

            function exitOut() {
                $state.go('dataTypeRootCategories');
            }

            function hasProperties() {
                return ($scope.dataTypeRootCategory.propertyMappings!=null && $scope.dataTypeRootCategory.propertyMappings.length>0) ||
                    ($scope.formData.addedPropertyMappings!=null && $scope.formData.addedPropertyMappings.length>0);
            }

            function removeNewlyAddedProperty(property) {
                var len = $scope.formData.addedPropertyMappings.length;
                for (var i=len-1; i >= 0; i--) {
                    if (property.name == $scope.formData.addedPropertyMappings[i].property.name) {
                        $scope.formData.addedPropertyMappings.splice(i, 1);
                        break;
                    }
                }

            }

            function init() {
                initEntity();
                initAllAvailablePropertiesList();
                initUnitSystem();
            }


    }])
    .directive('vbrDataTypeRootCategoryAvailable', ['DataTypeRootCategoryByName', '$q', function (DataTypeRootCategoryByName, $q) {
        function isNameAvailable(name, currentId) {
            var promise = DataTypeRootCategoryByName.get(name, currentId);
            return promise.then(function (response) {
                if (!response.data) {
                    return $q.reject("Name is already taken");
                }
                return true;
            });
        }

        return {
            restrict: 'AE',
            require: 'ngModel',
            scope: {
                currentId: '=vbrDataTypeRootCategoryAvailable'
            },
            link: function (scope, elm, attr, model) {
                model.$asyncValidators.vbrDataTypeRootCategoryAvailable = function (modelValue) {
                    //console.log("validator called for org code : " + modelValue);
                    return isNameAvailable(modelValue, scope.currentId);
                };
            }
        }
    }]);



