(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitGroupDetailController', function ($scope, $rootScope, $stateParams, entity, UnitGroup, Unit, $state,ngDialog) {
            $scope.unitGroup = entity;
            $scope.load = function (id) {
                UnitGroup.get({id: id}, function(result) {
                    $scope.unitGroup = result;
                });
            };
            $rootScope.$on('acadiamasterApp:unitGroupUpdate', function(event, result) {
                $scope.unitGroup = result;
            });
            $scope.deleteUnitGroup = function () {
                ngDialog.openConfirm({
                    template: 'admin-templates/site/unitSystem/unitGroup/unitGroupDelete.html',
                    controller: 'UnitGroupDeleteController',
                    data: $scope.unitGroup
                }).then(function(value) {
                    $state.go('unitGroup');
                });
            };
        });
})();
