(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ProgramTestAuditController', function ($scope, ptsAuditId, ProgramTestExecutionAudit, ParseLinks,
                                                            TestExecutionStatusService, TestExecutionStatus) {
            var vm = this;
            vm.ptsAuditId = ptsAuditId;
            vm.TestExecutionStatusService = TestExecutionStatusService;

            vm.orderByChoices = {
                'id': 'id',
                'programTestId': 'programTest.id',
                'programTestSuiteId': 'programTestSuite.id',
                'programId': 'program.id',
                'testExecutionStatus': 'testExecutionStatus',
                'hasCorrectiveActionTaken': 'hasCorrectiveActionTaken',
                'startTimestamp': 'startTimestamp'
            };

            vm.searchData = {
                orderBy: vm.orderByChoices.id,
                ascending: false,
                getOrderSort: function () {
                    return this.orderBy;
                },
                getOrder: function () {
                    return this.ascending;
                },
                setOrderBy: function (newOrderBy) {
                    if (this.orderBy == newOrderBy) {
                        this.ascending = !this.ascending;
                    }
                    else {
                        this.ascending = true;
                        this.orderBy = newOrderBy;
                    }
                    vm.loadAll();
                }
            };

            vm.testExecutionStatusEnum = TestExecutionStatus;

            vm.programTestExecutionAudits = [];
            vm.page = 0;

            vm.hasCorrectiveActionTaken = hasCorrectiveActionTaken;

            ;

            vm.loadAll = function (page) {
                loadAll(vm, page);
            };

            vm.loadPage = function (page) {
                vm.loadAll(page);
            };

            vm.loadAll();



            /***************************************************************
             * private functions
             ***************************************************************/

            function hasCorrectiveActionTaken(audit) {
                return audit!=null &&
                    audit.countAfterCorrectiveAction < audit.countBeforeCorrectiveAction &&
                    audit.countAfterCorrectiveAction!=-1 &&
                    audit.countBeforeCorrectiveAction!=-1;
            }

            function loadAll(vm, page) {
                if (page == null) {
                    vm.page = 0;
                }
                vm.page = page;
                ProgramTestExecutionAudit.query({
                    page: vm.page,
                    size: 20,
                    orderBy: vm.searchData.getOrderSort(),
                    ascending: vm.searchData.getOrder(),
                    id: vm.searchData.id,
                    programTestId: vm.searchData.programTestId,
                    programTestSuiteId: vm.searchData.programTestSuiteId,
                    programId: vm.searchData.programId,
                    testExecutionStatus: vm.searchData.testExecutionStatus,
                    programTestSuiteAuditId: vm.ptsAuditId,
                }, function (result, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.programTestExecutionAudits = result;
                    vm.searchData.searchResultCount = headers('X-Total-Count');
                });
            }
        });
})();