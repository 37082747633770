/**
 * Created by Jamie Nola on 12/12/2018
 */
(function () {
    'use strict';

    /**
     * directive for editing string values
     */
    angular.module('acadiamasterApp')
        .directive('stringValueEditorInput', function (StringManagementConstants, $translate) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/stringManagement/valueEditorInput/stringValueEditorInput.template.html',
                scope: {
                    platform: '@',
                    valueModel: '=',
                    systemValueModel: '=',
                    isEditable: '=?',
                },
                controllerAs: 'sei',
                controller: function ($scope) {
                    var vm = this;

                    /**
                     * Toggles the isCustom flag. This is used when the user clicks an editable input's title
                     */
                    vm.toggleCustomFlag = function () {
                        vm.value.use[ vm.platform ] = !vm.value.use[ vm.platform ];
                    };

                    /**
                     * Update the currently used translation key for the input's status, based on several properties
                     */
                    function updateStatusKey() {
                        var targetLevel = vm.level === StringManagementConstants.LEVEL.PROGRAM &&
                            !vm.value.use[ vm.platform ] &&
                            !vm.isBaseLevel &&
                            !vm.value.use.default ?
                            StringManagementConstants.LEVEL.SYSTEM :
                            vm.level;

                        var targetPlatform = vm.value.use[ vm.platform ] ||
                            (!vm.value.use.default &&
                                vm.systemValue.use[ vm.platform ]) ?
                            $scope.platform :
                            'default';

                        vm.targetLevelDisplay = $translate.instant('stringManagement.level.' + targetLevel);
                        vm.platformDisplay = $translate.instant('stringManagement.platform.' + targetPlatform);

                        var key = 'stringManagement.editor.status.' +
                            (!vm.isBaseLevel && vm.value.use[ vm.platform ] ? 'custom' : 'default');
                        vm.data.statusKey = key;
                    }

                    /**
                     * Some exterior data has changed, so check to make sure we are still using the correct data for the default case
                     */
                    function updateDefaultValue() {
                        vm.data.defaultValue = vm.value.use.default ?
                            vm.value.value.default :
                            (vm.systemValue.use[ vm.platform ] ?
                                vm.systemValue.value[ vm.platform ] :
                                vm.systemValue.value[ 'default' ]);
                    }

                    function init() {
                        $scope.$watch('valueModel.value.keyName', function () {
                            // copy values from scope, since the keyName has changed
                            vm.platform = $scope.platform;
                            vm.isBaseLevel = $scope.valueModel.value.programId == null;
                            vm.level = vm.isBaseLevel ?
                                StringManagementConstants.LEVEL.SYSTEM :
                                StringManagementConstants.LEVEL.PROGRAM;
                            vm.isEditable = $scope.isEditable;
                            vm.value = $scope.valueModel.tempValue;
                            vm.systemValue = $scope.systemValueModel.value;
                            vm.isDefault = vm.platform === 'default';
                            vm.data = {};
                            updateStatusKey();
                            updateDefaultValue();
                        });

                        // the default value has changed (outside of this directive), so update our local copy
                        $scope.$watch('valueModel.tempValue.value.default', function () {
                            updateDefaultValue();
                        });

                        // The checkbox states have changed for the entire model, so update our values accordingly
                        $scope.$watch('valueModel.tempValue.use', function () {
                            updateStatusKey();
                            updateDefaultValue();
                        }, true);
                    }

                    init();
                }
            };
        });
})();
