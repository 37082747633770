import Keycloak from 'keycloak-js';
import angular from 'angular';

function initKeycloak (bootAngularApp) {
    function getRedirectUrl () {
        const isRefreshed = sessionStorage.getItem('is_reloaded');
        const path = isRefreshed ? window.location.pathname : '/';
        const hash = isRefreshed ? window.location.hash : '#/';
        let keycloakRedirectUrl = `${window.location.origin}${path}${hash}`;
        if (!isRefreshed) {
            sessionStorage.setItem('is_reloaded', true);
        }
        return keycloakRedirectUrl;
    }
    const initInjector = angular.injector([ 'ng' ]);
    const $http = initInjector.get('$http');
    const $q = initInjector.get('$q');
    const deferred = $q.defer();
    const success = function (response) {
        deferred.resolve(response);
        window.CONFIG = response.data;
        if (response.data.keycloakParticipantEnabled && response.data.keycloakEnterpriseEnabled) {
            const keycloakConfig = {
                clientId : 'admin-web',
                realm    : response.data.keycloakEnterpriseRealm,
                url      : `${response.data.keycloakBaseUrl}/auth`,
            };
            const initConfig = {
                onLoad                    : 'check-sso',
                promiseType               : 'native',
                redirectUri               : getRedirectUrl(),
                silentCheckSsoRedirectUri : `${window.location.origin}/assets/sso.html`,
            };
            const keycloakAuth = new Keycloak(keycloakConfig);
            keycloakAuth.init(initConfig).then(auth => {
                if (auth) {
                    window.keycloakAuth = keycloakAuth;
                    bootAngularApp();
                } else {
                    keycloakAuth.login({
                        action      : 'login',
                        redirectUri : getRedirectUrl(),
                    });
                }
            }).catch(error => {
                console.log(error);
                window.keycloakAuth = null;
            });
        } else {
            bootAngularApp();
        }
    };
    const error = function (err) {
        console.error(err);
        window.keycloakAuth = null;
        deferred.reject(err);
    };
    $http.get('/api/appConfig').then(success).catch(error);
}
const keycloakService = {
    initKeycloak,
};

export default keycloakService;
