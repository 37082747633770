import 'shared_utilities/dist/index';
(function () {
    angular.module('acadiamasterApp')

        .service('vxpEventModelService',
            (RuleActionBaseModelService, RuleConstantsService, ModelServiceConditionExpression) => {
                let ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

                /** *************************************************************
                 * Model for CREATE_TRACK_ORDER_REQUEST VXP Event
                 ***************************************************************/

                CreateTrackOrderRequestActionModel.inheritsFrom(ActionConfigBaseModel);

                function CreateTrackOrderRequestActionModel () {
                    this.provider = RuleConstantsService.ProviderEnum.GENOTEK;
                    this.item = new ItemModel();
                }

                CreateTrackOrderRequestActionModel.prototype.fromCreateTrackOrderRequestActionDto = function (dto) {
                    this.provider = dto.provider ? dto.provider : RuleConstantsService.ProviderEnum.GENOTEK;
                    this.item = new ItemModel();
                    this.item.fromItemModelDto(dto.createTrackOrderRequestActionDTO);
                };

                CreateTrackOrderRequestActionModel.prototype.toCreateTrackOrderRequestActionDto = function () {
                    let dto = {};
                    dto.provider = this.provider;
                    dto.item = this.item.toItemModelDto();
                    return dto;
                };

                /** *************************************************************
                 * Model for itemsDto
                 ***************************************************************/

                function ItemModel () {
                    this.itemId = null;
                    this.itemName = null;
                    this.quantity = null;
                }

                ItemModel.prototype.fromItemModelDto = function (dto) {
                    this.itemId = dto.item.itemId;
                    this.itemName = dto.item.itemName;
                    this.quantity = dto.item.quantity;
                };

                ItemModel.prototype.toItemModelDto = function () {
                    let dto = {};
                    dto.itemId = this.itemId;
                    dto.itemName = this.itemName;
                    dto.quantity = this.quantity;
                    return dto;
                };

                /** *************************************************************
                 * Model for TRACK_DELIVERY_REQUEST VXP Event
                 ***************************************************************/

                TrackDeliveryRequestActionModel.inheritsFrom(ActionConfigBaseModel);

                function TrackDeliveryRequestActionModel () {
                    this.provider = RuleConstantsService.ProviderEnum.USPS;
                    this.trackingIdExpression = null;
                    this.trackingIdExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                }

                TrackDeliveryRequestActionModel.prototype.fromTrackDeliveryRequestActionModel = function (dto) {
                    this.provider = dto.trackDeliveryRequestActionDTO.provider
                        ? dto.trackDeliveryRequestActionDTO.provider : RuleConstantsService.ProviderEnum.USPS;
                    this.trackingIdExpression = dto.trackDeliveryRequestActionDTO.trackingIdExpression
                        ? dto.trackDeliveryRequestActionDTO.trackingIdExpression : null;
                    this.trackingIdExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(dto.trackDeliveryRequestActionDTO);
                    this.trackingIdExpressionTree.fromDto(dto.trackDeliveryRequestActionDTO.trackingIdExpressionTree);
                };

                TrackDeliveryRequestActionModel.prototype.toTrackDeliveryRequestActionModel = function () {
                    let dto = {};
                    dto.provider = this.provider;
                    dto.trackingIdExpression = this.trackingIdExpression;
                    dto.trackingIdExpressionTree = this.trackingIdExpressionTree.toDto();
                    return dto;
                };



                /** *************************************************************
                 * Model for TRACK_DELIVERY_REQUEST VXP Event
                 ***************************************************************/

                FulfilmentReturnTrackingModel.inheritsFrom(ActionConfigBaseModel);

                function FulfilmentReturnTrackingModel () {
                    this.actionType = RuleConstantsService.TrackingActionType.START;
                    this.orderIdExpression = null;
                    this.orderIdExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                }

                FulfilmentReturnTrackingModel.prototype.fromFulfilmentReturnTrackingModel = function (dto) {
                    this.actionType = dto.fulfillmentReturnTrackingEventActionDTO.actionType
                        ? dto.fulfillmentReturnTrackingEventActionDTO.actionType : RuleConstantsService.TrackingActionType.START;
                    this.orderIdExpression = dto.fulfillmentReturnTrackingEventActionDTO.orderIdExpression
                        ? dto.fulfillmentReturnTrackingEventActionDTO.orderIdExpression : null;
                    this.orderIdExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(dto.fulfillmentReturnTrackingEventActionDTO);
                    this.orderIdExpressionTree.fromDto(dto.fulfillmentReturnTrackingEventActionDTO.orderIdExpressionTree);
                };

                FulfilmentReturnTrackingModel.prototype.toFulfilmentReturnTrackingModel = function () {
                    let dto = {};
                    dto.actionType = this.actionType;
                    dto.orderIdExpression = this.orderIdExpression;
                    dto.orderIdExpressionTree = this.orderIdExpressionTree.toDto();
                    return dto;
                };


                /** *************************************************************
                 * Model for METADATA_KEY_VALUE
                 ***************************************************************/

                function MilestoneEventMetadataModel () {
                    this.keyName = null;
                    this.valueExpression = null;
                    this.valueExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                }

                MilestoneEventMetadataModel.prototype.fromMilestoneEventMetadataModel = function (metadataKeyValueList) {
                    this.keyName = metadataKeyValueList.keyName ? metadataKeyValueList.keyName : null;
                    this.valueExpression = metadataKeyValueList.valueExpression
                        ? metadataKeyValueList.valueExpression : null;
                    this.valueExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(metadataKeyValueList);
                    this.valueExpressionTree.fromDto(metadataKeyValueList.valueExpressionTree);
                };

                MilestoneEventMetadataModel.prototype.toMilestoneEventMetadataModel = function (metadataKeyValueList) {
                    let dto = {};
                    dto.keyName = this.keyName;
                    dto.valueExpression = metadataKeyValueList.valueExpression;
                    if (metadataKeyValueList.valueExpressionTree) {
                        dto.valueExpressionTree = metadataKeyValueList.valueExpressionTree.toDto();
                    }
                    return dto;
                };

                /** *************************************************************
                 * Model for METADATA_KEY_VALUE for Fulfillment Event
                 ***************************************************************/

                function FulfillmentEventMetadataModel () {
                    this.keyName = null;
                    this.value = null;
                }

                FulfillmentEventMetadataModel.prototype.fromFulfillmentEventMetadataModel = function (dto) {
                    this.keyName = dto.keyName ? dto.keyName : null;
                    this.value = dto.value ? dto.value : null;
                };

                FulfillmentEventMetadataModel.prototype.toFulfillmentEventMetadataModel = function () {
                    let dto = {};
                    dto.keyName = this.keyName;
                    dto.value = this.value;
                    return dto;
                };

                /** *************************************************************
                 * Model for Tracking List for Fulfillment Event
                 ***************************************************************/

                function FulfillmentTrackingModel () {
                    this.trackingType = null;
                    this.trackingIdExpression = null;
                    this.trackingIdExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                    this.carrierCode = RuleConstantsService.CarrierCodeEnum.USPS;
                }

                FulfillmentTrackingModel.prototype.fromFulfillmentTrackingModel = function (trackings) {
                    this.trackingType = trackings.trackingType ? trackings.trackingType : null;
                    this.trackingIdExpression = trackings.trackingIdExpression
                        ? trackings.trackingIdExpression : null;
                    this.trackingIdExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(trackings);
                    this.trackingIdExpressionTree.fromDto(trackings.trackingIdExpressionTree);
                    this.carrierCode = trackings.carrierCode ? trackings.carrierCode : RuleConstantsService.CarrierCodeEnum.USPS;
                };

                FulfillmentTrackingModel.prototype.toFulfillmentTrackingModel = function (trackings) {
                    let dto = {};
                    dto.trackingType = this.trackingType;
                    dto.trackingIdExpression = trackings.trackingIdExpression;
                    if (trackings.trackingIdExpressionTree) {
                        dto.trackingIdExpressionTree = trackings.trackingIdExpressionTree.toDto();
                    }
                    dto.carrierCode = this.carrierCode;
                    return dto;
                };

                /** *************************************************************
                 * Model for USER_MILESTONE_EVENT VXP Event
                 ***************************************************************/

                UserMilestoneEventActionModel.inheritsFrom(ActionConfigBaseModel);

                function UserMilestoneEventActionModel () {
                    this.eventType = null;
                    this.statusExpression = null;
                    this.statusExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                    this.sourceExpression = null;
                    this.sourceExpressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                    this.metadataKeyValueList = [];
                }

                UserMilestoneEventActionModel.prototype.fromUserMilestoneEventActionModel = function (dto) {
                    this.eventType = dto.userMilestoneEventActionDTO.eventType ? dto.userMilestoneEventActionDTO.eventType : null;
                    this.statusExpression = dto.userMilestoneEventActionDTO.statusExpression
                        ? dto.userMilestoneEventActionDTO.statusExpression : null;
                    this.statusExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(dto.userMilestoneEventActionDTO);
                    this.statusExpressionTree.fromDto(dto.userMilestoneEventActionDTO.statusExpressionTree);
                    this.sourceExpression = dto.userMilestoneEventActionDTO.sourceExpression
                        ? dto.userMilestoneEventActionDTO.sourceExpression : null;
                    this.sourceExpressionTree = new ModelServiceConditionExpression
                        .ConditionExpression(dto.userMilestoneEventActionDTO);
                    this.sourceExpressionTree.fromDto(dto.userMilestoneEventActionDTO.sourceExpressionTree);
                    this.metadataKeyValueList = [];
                    for (let i = 0; i < dto.userMilestoneEventActionDTO.metadataKeyValueList.length; i++) {
                        this.metadataKeyValueList[i] = new MilestoneEventMetadataModel();
                        this.metadataKeyValueList[i]
                            .fromMilestoneEventMetadataModel(dto.userMilestoneEventActionDTO.metadataKeyValueList[i]);
                    }
                };

                UserMilestoneEventActionModel.prototype.toUserMilestoneEventActionModel = function () {
                    let dto = {
                        metadataKeyValueList : [],
                    };
                    dto.eventType = this.eventType;
                    dto.statusExpression = this.statusExpression;
                    for (let i = 0; i < this.metadataKeyValueList.length; i++) {
                        dto.metadataKeyValueList[i] = new MilestoneEventMetadataModel();
                    }
                    if (this.statusExpressionTree) {
                        dto.statusExpressionTree = this.statusExpressionTree.toDto();
                    }
                    dto.sourceExpression = this.sourceExpression;
                    if (this.sourceExpressionTree) {
                        dto.sourceExpressionTree = this.sourceExpressionTree.toDto();
                    }
                    for (let i = 0; i < this.metadataKeyValueList.length; i++) {
                        dto.metadataKeyValueList[i]
                            = this.metadataKeyValueList[i].toMilestoneEventMetadataModel(this.metadataKeyValueList[i]);
                    }
                    return dto;
                };

                /** *************************************************************
                 * Model for FULFILLMENT_REQUEST_EVENT VXP Event
                 ***************************************************************/

                FulfillmentRequestActionModel.inheritsFrom(ActionConfigBaseModel);

                function FulfillmentRequestActionModel () {
                    this.orderTypeCode = null;
                    this.productCode = null;
                    this.trackings = [];
                    this.metadata = {};
                }

                FulfillmentRequestActionModel.prototype.fromFulfillmentRequestActionModel = function (dto) {
                    this.orderTypeCode = dto.fulfillmentRequestEventActionDTO.orderTypeCode ? dto.fulfillmentRequestEventActionDTO.orderTypeCode : null;
                    this.productCode = dto.fulfillmentRequestEventActionDTO.productCode ? dto.fulfillmentRequestEventActionDTO.productCode : null;
                    this.trackings = [];
                    for (let i = 0; i < dto.fulfillmentRequestEventActionDTO.trackings.length; i++) {
                        this.trackings[i] = new FulfillmentTrackingModel();
                        this.trackings[i]
                            .fromFulfillmentTrackingModel(dto.fulfillmentRequestEventActionDTO.trackings[i]);
                    }
                    this.metadata = dto.fulfillmentRequestEventActionDTO.metadata ? dto.fulfillmentRequestEventActionDTO.metadata : {};
                };

                FulfillmentRequestActionModel.prototype.toFulfillmentRequestActionModel = function () {
                    let dto = {
                        trackings : [],
                    };
                    for (let i = 0; i < this.trackings.length; i++) {
                        dto.trackings[i] = new FulfillmentTrackingModel();
                    }
                    dto.orderTypeCode = this.orderTypeCode;
                    dto.productCode = this.productCode;
                    for (let i = 0; i < this.trackings.length; i++) {
                        dto.trackings[i]
                            = this.trackings[i].toFulfillmentTrackingModel(this.trackings[i]);
                    }
                    dto.metadata = this.metadata;
                    return dto;
                };

                return {
                    CreateTrackOrderRequestActionModel,
                    FulfillmentEventMetadataModel,
                    FulfillmentRequestActionModel,
                    FulfillmentTrackingModel,
                    FulfilmentReturnTrackingModel,
                    MilestoneEventMetadataModel,
                    TrackDeliveryRequestActionModel,
                    UserMilestoneEventActionModel,
                };
            });
}());
