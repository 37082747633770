import spanUtil from '../../../../../util/textGeneration/spanUtil';

/**
 * directive for displaying one personalized container with the personalized container item inside. it will have the
 * following format
 * 1.  personalized container information
 * 2.  personalized container item headers
 * 3.  list of personalized container item information
 */
angular.module('acadiamasterApp').directive('personalizedContainerDisplay', (FormPreviewService) => {
    return {
        restrict: 'E',
        scope: {
            personalizedContainer: '=',
            personalizedContainerItems: '='
        },
        templateUrl: 'admin-templates/site/participant/profile/experience/personalizedContainer/personalizedContainerDisplay.html',
        link: function($scope) {

            const isVisibleSpan = spanUtil.createBooleanSpan({
                valueToCheck: $scope.personalizedContainer.visible,
                faIconOnTrue: 'fa-eye text-success',
                faIconOnNotTrue: 'fa-eye-slash text-danger',
                titleTextOnTrue: 'This Personalized Container is Visible',
                titleTextOnNotTrue: 'This Personalized Container is NOT Visible'
            });
            const isUnlockedSpan = spanUtil.createBooleanSpan({
                valueToCheck: $scope.personalizedContainer.unlocked,
                faIconOnTrue: 'fa-unlock text-success',
                faIconOnNotTrue: 'fa-lock text-danger',
                titleTextOnTrue: 'This Personalized Container is Unlocked',
                titleTextOnNotTrue: 'This Personalized Container is Locked'
            });

            $scope.containerFlags = {
                isVisibleSpan : isVisibleSpan,
                isUnlockedSpan: isUnlockedSpan,
            };

            $scope.hasContainerItems = function() {
                return $scope.personalizedContainerItems!=null && $scope.personalizedContainerItems.length > 0;
            };

            $scope.previewForm = function(formId) {
                var form = {
                    id: formId
                };
                FormPreviewService.preview(form, null);
            };



        }
    }
});
