/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')


    /**
     * directive for Canvas.
     */
        .directive('formFieldConfigFieldValueCanvas', function () {
            return {
                restrict: 'E',
//            template: "directive template for formFieldConfigFieldValueCanvas",
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/canvas.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
            }
        });

})();

