import {ASSET_TYPES} from '../../assetManagement/programAssetManagement.constants';
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * program asset Management directive
     */
        .directive('exportWizardStepAssetManagement', function (ProgramAssetManagement, AlertService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepAssetManagement.html',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '='
                },
                link: function ($scope) {
                    init($scope);

                    $scope.sortBy = function (name) {
                        $scope.data.orderByAsc = ($scope.data.orderBy === name) ? !$scope.data.orderByAsc : true;
                        $scope.data.orderBy = name;
                    };

                    $scope.$watch('selectedData.programAssets.length', function(nv) {
                        $scope.data.selectAllValue = nv === $scope.data.programAssets.length && nv > 0;
                    });
                }
            };

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.data = {
                    orderBy: 'assetType',
                    orderByAsc: true,
                    selectAllValue: false,
                    programAssets: [],
                    assetTypeConst: ASSET_TYPES
                };

                loadAssetsForProgram($scope);
            }

            function loadAssetsForProgram($scope) {
                var programId = $scope.program.id;
                ProgramAssetManagement.query({id: programId}, function (result) {

                    if (result == null || result.length <= 0) {
                        AlertService.warning('No Server Asset Mappings found for Program Id: ' + programId);
                        return;
                    }
                    var programAssets = [];
                    for (var i = 0; i < result.length; i++) {
                        programAssets[i] = result[i];
                    }
                    $scope.data.programAssets = programAssets;
                });
            }

        });


})();
