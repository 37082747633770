/**
 * Created by Jamie Nola on 10/18/2018
 *
 * This file is used to control an individual set of bread crumbs. Normally there will only be
 * one of these active at any time, but the system was build to accomodate any number of them.
 */

angular.module('acadiamasterApp')
    .controller('BreadcrumbController', function ($scope, BreadcrumbService, $translate, $translatePartialLoader) {
        let vm = this;
        vm.path;

        // omit any nodes that are either abstract or are set to be hidden
        function filterNode (node) {
            return !node.abstract && !node.hideBreadcrumb;
        }

        function loadJSON () {
            // if last used translate is spanish
            if ($translate.use() === 'es') {
                $translate.use('en').then(() => {
                    $translatePartialLoader.addPart('global');
                    $translate.refresh();
                });
            }
        }

        // callback for BreadcrumbService to call when the page changes
        function updatePath (path) {
            vm.path = path;
        }

        function init () {
            vm.loadJSON = loadJSON;
            updatePath(BreadcrumbService.getPath());
            BreadcrumbService.subscribe($scope, updatePath);
        }

        vm.filterNode = filterNode;

        init();
    });
