(function () {
    'use strict';

    /**
     * constant to store all the address states in PMI format
     */

    angular.module('acadiamasterApp').constant('AddressStatesConstant', {
        PIIState_AL : {text: 'Alabama', name: 'PIIState_AL'},
        PIIState_AK : {text: 'Alaska', name: 'PIIState_AK'},
        PIIState_AS : {text: 'American Samoa', name: 'PIIState_AS'},
        PIIState_AZ : {text: 'Arizona', name: 'PIIState_AZ'},
        PIIState_AR : {text: 'Arkansas', name: 'PIIState_AR'},
        PIIState_CA : {text: 'California', name: 'PIIState_CA'},
        PIIState_CO : {text: 'Colorado', name: 'PIIState_CO'},
        PIIState_CT : {text: 'Connecticut', name: 'PIIState_CT'},
        PIIState_DC : {text: 'District of Columbia', name: 'PIIState_DC'},
        PIIState_DE : {text: 'Delaware', name: 'PIIState_DE'},
        PIIState_FM : {text: 'Federated States of Micronesia', name: 'PIIState_FM'},
        PIIState_FL : {text: 'Florida', name: 'PIIState_FL'},
        PIIState_GA : {text: 'Georgia', name: 'PIIState_GA'},
        PIIState_GU : {text: 'Guam', name: 'PIIState_GU'},
        PIIState_HI : {text: 'Hawaii', name: 'PIIState_HI'},
        PIIState_ID : {text: 'Idaho', name: 'PIIState_ID'},
        PIIState_IL : {text: 'Illinois', name: 'PIIState_IL'},
        PIIState_IN : {text: 'Indiana', name: 'PIIState_IN'},
        PIIState_IA : {text: 'Iowa', name: 'PIIState_IA'},
        PIIState_KS : {text: 'Kansas', name: 'PIIState_KS'},
        PIIState_KY : {text: 'Kentucky', name: 'PIIState_KY'},
        PIIState_LA : {text: 'Louisiana', name: 'PIIState_LA'},
        PIIState_ME : {text: 'Maine', name: 'PIIState_ME'},
        PIIState_MH : {text: 'Marshall Islands', name: 'PIIState_MH'},
        PIIState_MD : {text: 'Maryland', name: 'PIIState_MD'},
        PIIState_MA : {text: 'Massachusetts', name: 'PIIState_MA'},
        PIIState_MI : {text: 'Michigan', name: 'PIIState_MI'},
        PIIState_MN : {text: 'Minnesota', name: 'PIIState_MN'},
        PIIState_MS : {text: 'Mississippi', name: 'PIIState_MS'},
        PIIState_MO : {text: 'Missouri', name: 'PIIState_MO'},
        PIIState_MT : {text: 'Montana', name: 'PIIState_MT'},
        PIIState_NE : {text: 'Nebraska', name: 'PIIState_NE'},
        PIIState_NV : {text: 'Nevada', name: 'PIIState_NV'},
        PIIState_NH : {text: 'New Hampshire', name: 'PIIState_NH'},
        PIIState_NJ : {text: 'New Jersey', name: 'PIIState_NJ'},
        PIIState_NM : {text: 'New Mexico', name: 'PIIState_NM'},
        PIIState_NY : {text: 'New York', name: 'PIIState_NY'},
        PIIState_NC : {text: 'North Carolina', name: 'PIIState_NC'},
        PIIState_MP : {text: 'North Marianas Islands', name: 'PIIState_MP'},
        PIIState_ND : {text: 'North Dakota', name: 'PIIState_ND'},
        PIIState_OH : {text: 'Ohio', name: 'PIIState_OH'},
        PIIState_OK : {text: 'Oklahoma', name: 'PIIState_OK'},
        PIIState_OR : {text: 'Oregon', name: 'PIIState_OR'},
        PIIState_PW : {text: 'Palau', name: 'PIIState_PW'},
        PIIState_PA : {text: 'Pennsylvania', name: 'PIIState_PA'},
        PIIState_PR : {text: 'Puerto Rico', name: 'PIIState_PR'},
        PIIState_RI : {text: 'Rhode Island', name: 'PIIState_RI'},
        PIIState_SC : {text: 'South Carolina', name: 'PIIState_SC'},

        PIIState_SD : {text: 'South Dakota', name: 'PIIState_SD'},
        PIIState_TN : {text: 'Tennessee', name: 'PIIState_TN'},
        PIIState_TX : {text: 'Texas', name: 'PIIState_TX'},
        PIIState_UT : {text: 'Utah', name: 'PIIState_UT'},
        PIIState_VT : {text: 'Vermont', name: 'PIIState_VT'},
        PIIState_VA : {text: 'Virginia', name: 'PIIState_VA'},

        PIIState_VI : {text: 'Virgin Islands', name: 'PIIState_VI'},
        PIIState_WA : {text: 'Washington', name: 'PIIState_WA'},
        PIIState_WV : {text: 'West Virginia', name: 'PIIState_WV'},
        PIIState_WI : {text: 'Wisconsin', name: 'PIIState_WI'},
        PIIState_WY : {text: 'Wyoming', name: 'PIIState_WY'}
    });
})();


