(function () {
    'use strict';

    /***************************************************************
     * Precondition for program exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionProgramExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, ProgramLoadUtil) {

            PreConditionProgramExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for feature toggle with status
             * @constructor
             */
            function PreConditionProgramExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.PROGRAM_EXIST);

                this.programId = null;

                this._program = null;
            }

            PreConditionProgramExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.programId = dto.programId;

                ProgramLoadUtil.loadProgram(this);
            };

            PreConditionProgramExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.programId = this.programId;

                return dto;
            };

            PreConditionProgramExistModel.prototype.setProgram = function (program) {
                this._program = program;
                this.programId = program==null ? null : program.id;
            };

            PreConditionProgramExistModel.prototype.getProgram = function () {
                return this._program;
            };

            PreConditionProgramExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var programMsg = 'Program (' + (this.programId == null ? 'no id' : this.programId) + ' | ' +
                    (this._program == null ? 'no name' :  this._program.name) + ')';

                return typeMsg + programMsg +  ' should exist ';
            };

            PreConditionProgramExistModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.programId == null) {
                    this.setErrorMessage('program id is required');
                }
            };


            return PreConditionProgramExistModel;
        });
})();
