/**
 * common function to perform the fromDto operation on an object that has the following expression fields
 * isVisibleExpression
 * isVisibleExpressionTree
 * isUnlockedExpression
 * isUnlockedExpressionTree
 * @param currentObject - current object to be converted into a dto - this
 * @param dto - dto object, this is a plain object just hold json data - dto
 * @param constructorFunction - constructor function to create new expression
 */
function fromDto(currentObject, dto, constructorFunction) {
    // is visible expression
    currentObject.isVisibleExpression = dto.isVisibleExpression;
    currentObject.isVisibleExpressionTree = new constructorFunction(currentObject);
    currentObject.isVisibleExpressionTree.fromDto(dto.isVisibleExpressionTree);

    // is unlocked expression
    currentObject.isUnlockedExpression = dto.isUnlockedExpression;
    currentObject.isUnlockedExpressionTree = new constructorFunction(currentObject);
    currentObject.isUnlockedExpressionTree.fromDto(dto.isUnlockedExpressionTree);
}

/**
 * common function to perform the toDto operation on an object that has the following expression fields
 * isVisibleExpression
 * isVisibleExpressionTree
 * isUnlockedExpression
 * isUnlockedExpressionTree
 * @param currentObject - current object to be converted into a dto
 * @param dto - dto object, this is a plain object just hold json data
 */
function toDto(currentObject, dto) {
    dto.isVisibleExpression = currentObject.isVisibleExpression;
    dto.isVisibleExpressionTree = currentObject.isVisibleExpressionTree!=null ?
        currentObject.isVisibleExpressionTree.toDto() : null;

    dto.isUnlockedExpression = currentObject.isUnlockedExpression;
    dto.isUnlockedExpressionTree = currentObject.isUnlockedExpressionTree!=null ?
        currentObject.isUnlockedExpressionTree.toDto() : null;
}


export default {
    fromDto,
    toDto,
};
