/**
 * Created by moustafabaiou on 2/13/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrImageCrop', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/util/imageCrop.html',
                scope: {
                    cropAreaType: '@',
                    aspectRatio: '=',
                    resultImage: '=',  // this is the image uri
                    resultImageFile: '=',  // this is an actual image file
                    resultImageSize: '=',
                },
                link: function (scope) {
                    scope.fileSelected = false;

                    scope.handleFileSelect = function (currentTarget) {
                        console.log(currentTarget.files);
                        var file = currentTarget.files[0];
                        var reader = new FileReader();
                        reader.onload = function (evt) {
                            scope.$apply(function (scope) {
                                scope.myImage = evt.target.result;
                                scope.resultImageFile = file;
                            });
                        };
                        reader.readAsDataURL(file);
                        scope.fileSelected = true;
                    };

                }
            };

        });

})();

