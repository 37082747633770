 (function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('externalDataTaskAudit', function (ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/externalDataImport/trackTask/taskAuditList.html',
                scope: {
                    audits: "=",

                },
                link: function ($scope) {
                    $scope.isCollapsed = true;
                }
            };
        });
})();
