angular.module('acadiamasterApp')
    .factory('EmailValidation', ['$http', 'localWeb', function ($http, localWeb) {

            return {
                    checkEmailOrLoginAvailable : function(data) {
                        var emailID = encodeURIComponent(data);
                        var url = localWeb.createUrl("api/users/isEmailAvailable?emailId=" + emailID);
                        return $http.get(url, {
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
                                "Accept": "application/json"
                            }
                        })
                    }
        };
}]);