(function () {
    'use strict';


    /**
     * service for HasBannerByFormIdCondition model
     */
    angular.module('acadiamasterApp')
        .factory('HasBannerByFormIdCondition', function (QObjectModel, ProgramTestConstantsService,
            FormLoadUtil) {
            /***************************************************************
             * private functions
             **************************************************************/

            HasBannerByFormIdCondition.inheritsFrom(QObjectModel);

            function HasBannerByFormIdCondition(parent) {
                QObjectModel.call(this, parent,
                    ProgramTestConstantsService.QObjectType.HAS_BANNER_NOTIFICATION_BY_FORM_ID);

                //initialized the variables
                this.formId = null;
                this.bannerStatus = ProgramTestConstantsService.BannerStatus.ALL_BANNERS;
                this.targetAreaList = [];
                this.comparator = ProgramTestConstantsService.operators.EQ;

                this._form = null;
            }

            /**
             * convert the current UI model to dto format
             */
            HasBannerByFormIdCondition.prototype.toDto = function () {
                var dto = QObjectModel.prototype.toDto.call(this);

                dto.formId = this.formId;
                dto.bannerStatus = this.bannerStatus;
                dto.targetAreaList = this.targetAreaList.length ? this.targetAreaList.map(function (item) {
                    return item.name;
                }) : null;
                dto.comparator = this.comparator == null ? null : this.comparator.name;
                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            HasBannerByFormIdCondition.prototype.fromDto = function (dto) {
                QObjectModel.prototype.fromDto.call(this, dto);

                this.formId = dto.formId;
                this.bannerStatus = dto.bannerStatus;
                this.targetAreaList = dto.targetAreaList ? dto.targetAreaList.map(function (item) {
                    return { name: item, text: item };
                }) : [];
                this.setComparator(ProgramTestConstantsService.getObjectByName(
                    ProgramTestConstantsService.operators, dto.comparator));

                FormLoadUtil.loadForm(this);
            };

            HasBannerByFormIdCondition.prototype.getComparator = function () {
                return this.comparator;
            };

            HasBannerByFormIdCondition.prototype.setComparator = function (comparator) {
                this.comparator = comparator;

                if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                    this.targetAreaList = [];
                } else if (this.targetAreaList.length > 0 && comparator !== ProgramTestConstantsService.operators.IN) {
                    // just use the first value
                    this.targetAreaList = [this.targetAreaList[0]];
                }
            };

            HasBannerByFormIdCondition.prototype.getForm = function () {
                return this._form;
            };

            HasBannerByFormIdCondition.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form == null ? null : form.id;
            };

            HasBannerByFormIdCondition.prototype.getDescriptionAsHtml = function () {
                var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

                var formMsg = 'Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                    (this._form == null ? 'no name' : this._form.name) + ')';

                formMsg += '<span class="badge badge-info">' + this.bannerStatus + '</span>';

                var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                    '</span> ';

                var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : this.targetAreaList.map(function (item) {
                    return item.text;
                }).join(', ');

                return baseMsg + ' has a banner notification which will open ' + formMsg + comparatorMsg + valueMsg;
            };

            HasBannerByFormIdCondition.prototype._validateSelf = function () {
                this.clearError();

                if (this.formId == null) {
                    this.setErrorMessage('form id is required');
                }

                if (this.comparator == null) {
                    this.setErrorMessage('comparator is required');
                }

                if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                    (this.targetAreaList == null || this.targetAreaList.length == 0)) {
                    this.setErrorMessage('target area list is required except when comparator is "is null"');
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return HasBannerByFormIdCondition;

        });
})();
