export const fieldDisplayConfig = {
    width: '100%',
    gravity: 'LEFT',
    padding: 'System',
    margin: 'System',
    fontSize: '12pt',
    fontFamily: 'inherit',
    textStyle: 'inherit'
};

export const titleDisplayConfig = {
    width: '100%',
    gravity: 'LEFT',
    padding: '0 30 30 30',
    margin: 'System',
    fontSize: '12pt',
    fontFamily: 'inherit',
    textStyle: 'inherit',
    bgColor: 'white',
    color: 'black80'
};

export const optionsDisplayConfig = {
    width: '100%',
    padding: '23 30 30 20',
    margin: 'System'
};
