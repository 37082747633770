
/**
 * drc related services
 */
(function () {
    angular.module('acadiamasterApp').factory('drcService', ($http, localWeb, vbrCommonUtil) => {
        let baseUrl = 'api/drc';

        return {

            /*
             * api call to create all the necessary data type properties for PMI program
             * @returns HttpPromise with response body that contains a list of properties names
             */
            addDataTypePropertyForPMI : function () {
                return $http.post(localWeb.createUrl(`${baseUrl }/addDataTypePropertyForPMI`), null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to check if drc has been initialized
             * @returns HttpPromise that include a simple true/false flag in the response body
             */
            checkDrcInitialized : function () {
                return $http.get(localWeb.createUrl(`${baseUrl }/isConnectorInitialized`));
            },

            /*
             * check operation status by id
             * @param operationId
             * @returns HttpPromise with response body of OperationStatus model
             */
            checkOperationStatus : function (operationId) {
                return $http.get(localWeb.createUrl(`${baseUrl }/checkOperationStatus/${ operationId}`));
            },

            /*
             * get questionnaire by form id or form version id
             * @returns HttpPromise with response body that is the string representation of questionnaire
             */
            convertFormToQuestionnaireById : function (formId, formVersionId) {
                let url = `${baseUrl }/convertFormToQuestionnaireById?`;
                if (formVersionId != null) {
                    url = `${url }formVersionId=${ formVersionId}`;
                } else {
                    url = `${url }formId=${ formId}`;
                }

                return $http.get(localWeb.createUrl(url));
            },

            /*
             * api call to convert a form dto string into questionnaire string
             * @returns HttpPromise with response body that is the string representation of questionnaire
             */
            convertFormVersionUploadedToQuestionnaire : function (formDTOString) {
                let uploadData = { form : formDTOString };

                return $http.post(localWeb.createUrl(`${baseUrl }/convertFormVersionUploadedToQuestionnaire`), uploadData, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            forceSyncFormEntry : function (userId, formEntryId, shouldRegeneratePdfs) {
                let url = localWeb.createUrl(`${baseUrl }/forceSyncFormEntry/${ userId}`);

                // add params
                url = `${url }?formEntryId=${ formEntryId }&shouldRegeneratePdfs=${ shouldRegeneratePdfs}`;

                return $http.post(url, null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * get current data type associated with PMI program
             * @returns HttpPromise with response body of DataTypeDTO model
             */
            getDataTypeForPMI : function () {
                return $http.get(localWeb.createUrl(`${baseUrl }/dataTypeForPMI`));
            },

            /*
             * api call to sync all the forms with DRC that don't have an external id already
             * @returns HttpPromise with response body that contains the operation id
             */
            stopOperation : function (operationId) {
                return $http.post(localWeb.createUrl(`${baseUrl }/operation/stop/${ operationId}`), null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to sync all the forms with DRC that don't have an external id already
             * @returns HttpPromise with response body that contains the operation id
             */
            syncAllForms : function () {
                return $http.post(localWeb.createUrl(`${baseUrl }/syncAllForms`), null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to check the current form count as to how many forms are there and how many are registered
             * @returns HttpPromise that include two counts, one for total item count, one of registered item count
             */
            syncFormCheckCount : function () {
                return $http.get(localWeb.createUrl(`${baseUrl }/syncFormCheckCounts`));
            },

            /*
             * api call to check the current form entry count as to how many forms are there and how many are registered
             * @returns HttpPromise that include two counts, one for total item count, one of registered item count
             */
            syncFormEntryCheckCount : function () {
                return $http.get(localWeb.createUrl(`${baseUrl }/syncFormEntryCheckCounts`));
            },

            /*
            * api call to sync one or more forms by id
            * @param formIds Ids of the form to be synced
            * @returns HttpPromise with response body that contains the operation id
            */
            syncFormsById : function (formIds) {
                let url = localWeb.createUrl(`${baseUrl }/syncFormsById`);
                return $http.post(url, formIds, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * API call to sync a given form version to DRC
             * @param versionId Form version id
             */
            syncFormVersionById : function (versionId) {
                let url = localWeb.createUrl(`${baseUrl }/syncFormVersion/${ versionId}`);
                return $http.post(url, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to sync all/selected userid with  the forms with DRC for partial include
             * @returns HttpPromise with response body that contains the operation id
             */
            syncPartialForms : function (partilaFormSynData) {
                let url = localWeb.createUrl(`${baseUrl }/syncFormEntries`);
                return $http.post(url, partilaFormSynData, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to sync one or all the users with DRC that don't have an external id already
             * @param userId user id, only required if it's for a single user sync
             * @returns HttpPromise with response body that contains the operation id
             */
            syncUser : function (userId) {
                let url = userId == null
                    ? localWeb.createUrl(`${baseUrl }/syncAllUsers`)
                    : localWeb.createUrl(`${baseUrl }/syncUser/${ userId}`);

                return $http.post(url, null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },

            /*
             * api call to check the current user count as to how many users are there and how many are registered
             * @returns HttpPromise that include two counts, one for total item count, one of registered item count
             */
            syncUserCheckCount : function () {
                return $http.get(localWeb.createUrl(`${baseUrl }/syncUserCheckCounts`));
            },

            /*
             * API call to withdraw participant from DRC
             * @param participantId Participant id from DRC
             * @param userId Id of the user in vibrent DB
             * @param withdrawalReason
             * @param withdrawalReasonJustification
             */
            withdraw : function (participantId, userId, withdrawalReason, withdrawalReasonJustification) {
                let url = localWeb.createUrl(`${baseUrl }/withdraw?`);

                url = `${url }participantId=${ participantId}`;

                if (userId != null) {
                    url = `${url }&userId=${ userId}`;
                }

                url = `${url }&withdrawalReason=${ withdrawalReason}`;

                if (userId != null) {
                    url = `${url }&withdrawalReasonJustification=${ withdrawalReasonJustification}`;
                }

                return $http.post(url, null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            },
        };
    });
}());
