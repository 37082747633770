(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigBannerNotificationModel',
        function (RuleConstantsService, RuleActionBaseModelService, RuleUtilService,
                  BannerSpecificDataModel, BannerNotificationTargetAreaModel) {

            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigBannerNotificationModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigBannerNotificationModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.BANNER_NOTIFICATION;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigBannerNotificationModel;
                this.bannerType = RuleConstantsService.bannerType.OPEN_FORM;
                this.form = null;
                this.bannerSpecificData = new BannerSpecificDataModel();

                this.bannerNotificationTargetAreas = [];
                this.addBannerNotificationTargetArea(RuleConstantsService.bannerDisplayTargetArea.DASHBOARD);

                return this;
            }

            ActionConfigBannerNotificationModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.bannerType = RuleConstantsService.getObjectByName(RuleConstantsService.bannerType, actionConfig.bannerType);
                    this.formId = actionConfig.formId;
                    if (actionConfig.formId != null) {
                        this.form = RuleUtilService.getFormById(actionConfig.formId);
                    }

                    this.archivable = actionConfig.archivable;
                    this.bannerSpecificData = new BannerSpecificDataModel();
                    this.bannerSpecificData.fromDto(actionConfig.bannerSpecificData);

                    if (actionConfig.bannerNotificationTargetAreas != null) {
                        var bannerNotificationTargetAreas = [];

                        _.forEach(actionConfig.bannerNotificationTargetAreas, function (targetArea) {
                            var targetAreaModel = new BannerNotificationTargetAreaModel();
                            targetAreaModel.fromDto(targetArea);
                            bannerNotificationTargetAreas.push(targetAreaModel);
                        });

                        this.bannerNotificationTargetAreas = bannerNotificationTargetAreas;
                    }
                }
            };

            ActionConfigBannerNotificationModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.bannerType = this.bannerType.name;
                actionConfig.formId = this.form != null ? this.form.id : null;
                actionConfig.archivable = this.archivable;
                actionConfig.bannerSpecificData = this.bannerSpecificData.toDto(files);

                if (this.bannerNotificationTargetAreas != null) {
                    var bannerNotificationTargetAreas = [];

                    _.forEach(this.bannerNotificationTargetAreas, function (targetArea) {
                        bannerNotificationTargetAreas.push(targetArea.toDto());
                    });

                    actionConfig.bannerNotificationTargetAreas = bannerNotificationTargetAreas;
                }

                return dto;
            };

            ActionConfigBannerNotificationModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                message += " | " + this.bannerType.name;

                if (this.bannerSpecificData) {
                    message += " | " + this.bannerSpecificData.title;
                }
                if (this.formId) {
                    message += " | Form: " + (this.form ? this.form.name: '') +' (ID: '+ this.formId +')';
                }

                return message;
            };

            ActionConfigBannerNotificationModel.prototype._validateSelf = function () {
                this.clearError();
                if ((this.bannerType === RuleConstantsService.bannerType.OPEN_FORM ||
                        this.bannerType === RuleConstantsService.bannerType.MANUAL_SCHEDULING_FORM) && this.form == null) {
                    this.setErrorMessage("Select form to be opened ");
                }

                if (this.bannerNotificationTargetAreas == null || this.bannerNotificationTargetAreas.length < 1) {
                    this.setErrorMessage("Please add banner display target area");
                }
                var _this = this;
                if (this.bannerNotificationTargetAreas !== null) {
                    _.forEach(this.bannerNotificationTargetAreas, function (bannerTargetArea) {
                        if (bannerTargetArea.remindable && bannerTargetArea.reminderTimeOptions == null ||
                            (bannerTargetArea.reminderTimeOptions !== null &&
                                bannerTargetArea.reminderTimeOptions.dismissalDurationEnumList !== null)) {

                            if(bannerTargetArea.reminderTimeOptions.dismissalDurationEnumList.length < 1) {
                                _this.setErrorMessage("Please select reminder time option");
                            }
                        }
                    });
                }
            };

            ActionConfigBannerNotificationModel.prototype.addBannerNotificationTargetArea = function (targetAreaType) {
                this.bannerNotificationTargetAreas.push(new BannerNotificationTargetAreaModel(targetAreaType));
            };

            return ActionConfigBannerNotificationModel;
        }
    );
})();