/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config of one single color property
     */
        .directive('formDisplayConfigColorConfig', function (FormUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/color.config.html',
                scope: {
                    colorModel: '=',
                    colorTitle: '@',
                    colorKey : '@',
                    element : '='
                },
                link: function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;

                    $scope.getColorString = function () {
                        return FormUtilService.colorUtil.getColorString($scope.colorModel);
                    };

                    $scope.getColorValue = function () {
                        return FormUtilService.colorUtil.getColorValue($scope.colorModel, $scope.element, $scope.colorKey);
                    };

                    $scope.getColorValueRGB = function () {
                        return FormUtilService.colorUtil.getColorValueRGB($scope.colorModel, $scope.element, $scope.colorKey);
                    };

                    $scope.isInherit = function () {
                        return FormUtilService.colorUtil.isInherit($scope.colorModel);
                    };

                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
        });
})();

