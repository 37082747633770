/**
 * Created by Jamie Nola 03/20/2019
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerOrderDeliveryTrackingStatus', ($timeout, ORDER_TYPES) => {
            let orderDeliveryTrackingStatusTriggerController = function () {
                let vm = this;
                vm.data = {
                    trackingStatuses : [],
                    trackingTypes    : [],
                };

                function filterStatuses (statuses, targetNames) {
                    targetNames.forEach(targetName => {
                        vm.element[targetName] = vm.element[targetName].filter(status => {
                            return statuses.indexOf(status) !== -1;
                        });
                    });
                }

                function updateTrackingStatusList () {
                    let trackingStatuses = [];
                    let trackingTypes = [];
                    let trackingTypeList = ORDER_TYPES[vm.element.orderType].orderTrackingTypeSummaryList;
                    let nameIsValid = false;

                    // build the new list of tracking types, since the order type could have changed
                    trackingTypeList.forEach(type => {
                        let name = type.trackingTypeName;
                        trackingTypes.push(name);
                        if (name === vm.element.trackingType) {
                            nameIsValid = true;
                            trackingStatuses = type.trackingStatusList;
                        }
                    });

                    // if the currently set trackingType doesn't exist in the new list, default
                    // to the first option
                    if (!nameIsValid) {
                        let type = trackingTypeList[0];
                        vm.element.trackingType = type.trackingTypeName;
                        trackingStatuses = type.trackingStatusList;
                    }

                    // filter to and from options based on the new status list
                    filterStatuses(trackingStatuses, [ 'trackingStatusFrom', 'trackingStatusTo' ]);

                    // pass new values back to vm.data
                    vm.data.trackingTypes = trackingTypes;
                    vm.data.trackingStatuses = trackingStatuses;
                }

                vm.updateTrackingStatusList = function () {
                    $timeout(updateTrackingStatusList);
                };

                vm.$onInit = () => {
                    vm.orderTypes = Object.keys(ORDER_TYPES);
                    updateTrackingStatusList();
                };
            };

            return {
                bindToController : true,
                controller       : orderDeliveryTrackingStatusTriggerController,
                controllerAs     : 'ouc',
                restrict         : 'E',
                scope            : {
                    element : '=',
                },
                templateUrl : 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.orderDeliveryTrackingStatus.html',
            };
        });
}());
