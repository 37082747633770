(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for FormAssociationStateCondition model, this condition is to check the lock/unlock state of a form
     * that's already associated
     */
    app.factory('FormAssociationStateConditionModel', function (QObjectModel, ProgramTestConstantsService, FormLoadUtil) {
        /***************************************************************
         * private functions
         **************************************************************/

        FormAssociationStateConditionModel.inheritsFrom(QObjectModel);

        function FormAssociationStateConditionModel(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.FORM_ASSOCIATION_STATE);

            //initialized the variables
            this.formId = null;
            this.formLocked = true;
            this.visible = true;

            this._form = null;
        }

        /**
         * convert the current UI model to dto format
         */
        FormAssociationStateConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.formId = this.formId;
            dto.formLocked = this.formLocked;
            dto.visible = this.visible;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        FormAssociationStateConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.formId = dto.formId;
            this.formLocked = dto.formLocked;
            this.visible = dto.visible;

            FormLoadUtil.loadForm(this);
        };

        FormAssociationStateConditionModel.prototype.setForm = function (form) {
            this._form = form;
            this.formId = form == null ? null : form.id;
        };

        FormAssociationStateConditionModel.prototype.getForm = function () {
            return this._form;
        };

        FormAssociationStateConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var formMsg = 'Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                (this._form == null ? 'no name' : this._form.name) + ')';

            var lockedMsg = 'is <span class="badge badge-info">' + (this.formLocked ? 'Locked' : 'Unlocked') + '</span>';
            var visibleMsg = ' and is <span class="badge badge-info">' + (this.visible ? 'Visible' : 'Invisible') + '</span>';

            return baseMsg + ' ' + formMsg + ' should be associated and ' + lockedMsg + visibleMsg;
        };

        FormAssociationStateConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.formId == null) {
                this.setErrorMessage('form id is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return FormAssociationStateConditionModel;

    });
})();
