'use strict';
import template from './fieldValueComparison.html';
(function () {

    angular.module('acadiamasterApp').directive('fieldValueComparisonConfig', function (ProgramTestConstantsService,
                                                                                        ValueType, FormLoadUtil,
                                                                                        ValueComparisonOptionModel, Form) {
        return {
            restrict: 'E',
            template: template,
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.condition.getRoot().programId;
                $scope.cs = ProgramTestConstantsService;

                $scope.data = {
                    selectedReference : $scope.condition.getField()
                };

                $scope.formOptions = {
                    form: $scope.condition.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack: function () {
                        if (this.form == null) {
                            $scope.condition.setForm(null);
                            return;
                        }

                        $scope.condition.formId = this.form.id;
                        $scope.condition._form = null;

                        FormLoadUtil.loadForm($scope.condition, function() {
                            $scope.data.selectedReference = $scope.condition.getField();
                        }, undefined, Form.projections.ACTIVE_ONLY);
                    }
                };

                $scope.hasInputFields = function() {
                    var condition = $scope.condition;
                    return condition.getAllInputFields() != null && condition.getAllInputFields().length > 0;
                };

                $scope.shouldShowValueField = function() {
                    return $scope.condition.comparator != ProgramTestConstantsService.operators.IS_NULL;
                };

                $scope.valueComparisonOption = createValueComparisonOption($scope);

                addWatches($scope);
            }
        };

        function addWatches($scope) {
            $scope.$watch('data.selectedReference', function(newValue, oldValue) {
                if (newValue!=oldValue) {
                    $scope.condition.setField(newValue);
                }
            });
        }

        function createValueComparisonOption($scope) {
            var valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = $scope.isRequired;
            valueComparisonOption.canConfigureOperator = true;

            valueComparisonOption.functions.getValueType = function() {
                var field = $scope.condition.getField();

                var valueTypeName = field == null ? null : field.getValueTypeName();

                return valueTypeName == null ? null : ValueType[valueTypeName];
            };

            valueComparisonOption.functions.getOperator = function() {
                return $scope.condition.getComparator();
            };

            valueComparisonOption.functions.setOperator = function (newOperator) {
                $scope.condition.setComparator(newOperator);
            };

            valueComparisonOption.functions.getValues = function() {
                return $scope.condition.getFieldValuesAsProperType();
            };

            valueComparisonOption.functions.setValues = function (newValues) {
                $scope.condition.setFieldValues(newValues);
            };

            return valueComparisonOption;
        }

    });
})();
