/**
 * Created by Lulu Wang on 10/25/2019
 */

angular.module('acadiamasterApp')
    .directive('emailConfig', (LocaleConstantsService, RuleConstantsService, $translate) => {
        return {
            link: ($scope) => {

                $scope.tempData = {
                    hasFile: null,
                    fileChanged: false,
                    hasSecondFile: null,
                    secondFileChanged: false,
                };

                $scope.isConsentDeclined = $scope.model === 'consentDeclined';

                init($scope);
            },
            restrict: 'E',
            scope: {
                data: '=',
                emailConfigTitle: '@',
                model: '@',
                parentForm: '<?',
                name: '@',
            },
            templateUrl: 'admin-templates/site/agreements/configuration/emailConfig.template.html'
        };

        /** ****************************************************************************
         * private functions
         ***************************************************************************** */

        function init($scope) {
            //constants for email config templates
            $scope.templateTypeConstants = RuleConstantsService.notificationTemplateType;
            $scope.templateAllowExtensions = RuleConstantsService.templateAllowExtensions;
            $scope.templateAllowMimeType = RuleConstantsService.templateAllowMimeType;
            $scope.lcs = LocaleConstantsService;
            $scope.editFormDirty = false;

            const model = $scope.model;
            if ($scope.data.emailConfig[model]) {
                addWatches($scope);
            }
        }

        function addWatches($scope) {
            $scope.$watch('data.emailConfig[model].file', function (newValue, oldValue) {
                if (newValue == oldValue) {
                    return;
                }
                updateHasFile($scope);
            });

            $scope.$watch('data.emailConfig[model].templateFileUrl', function (newValue, oldValue) {
                const model = $scope.model;
                if (newValue == oldValue) {
                    if ($scope.data.emailConfig[model].templateFileUrl) {
                        $scope.tempData.hasFile = 'hasFile';
                    }
                    if ($scope.data.emailConfig['consentDeclined'].templateFileUrl) {
                        $scope.tempData.hasSecondFile = 'hasSecondFile';
                    }
                    return;
                }
                updateHasFile($scope);
            });
        }

        function updateHasFile($scope) {
            const model = $scope.model;
            if (model === 'disclosureEmailConfig') {
                doUpdateHasFile($scope, 'disclosureEmailConfig');
            } else {
                doUpdateHasFile($scope, 'consented');
                doUpdateHasSecondFile($scope, 'consentDeclined');
            }
        }

        function doUpdateHasFile($scope, model) {
            if ($scope.data.emailConfig[model].file === null) {
                $scope.tempData.hasFile = null;
                $scope.tempData.fileChanged = true;
                return;
            }
            const enFile = $scope.data.emailConfig[model];

            $scope.tempData.hasFile = (enFile == null || (enFile.file == null && enFile.templateFileUrl == null)) ? null : 'hasFile';
            $scope.tempData.fileChanged = true;
        }

        function doUpdateHasSecondFile($scope, model) {
            if ($scope.data.emailConfig[model].file === null) {
                $scope.tempData.hasSecondFile = null;
                $scope.tempData.secondFileChanged = true;
                return;
            }
            const enFile = $scope.data.emailConfig[model];

            $scope.tempData.hasSecondFile = (enFile == null || (enFile.file == null && enFile.templateFileUrl == null)) ? null : 'hasSecondFile';
            $scope.tempData.secondFileChanged = true;
        }

    });
