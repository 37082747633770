/**
 * Created by Jamie Nola on 04/18/2019
 */
(function () {
    angular.module('acadiamasterApp').directive('orderTrackingStatusConfig',
        ($timeout, ValueType, ValueComparisonOptionModel, OrderTypeService) => {
            let orderTrackingStatusConfigController = function () {
                let vm = this;

                /*
                 * Fires when the orderType is updated. Sets the new possible tracking types.
                 */
                vm.onOrderTypeUpdated = function (orderTypeName) {
                    vm.condition.orderType = orderTypeName;
                    vm.data.possibleTrackingTypes = vm.orderTypes[orderTypeName]
                        .orderTrackingTypeSummaryList.map(item => {
                            return item.trackingTypeName;
                        });

                    // if the current tracking type is now invalid, set it to the first possible
                    // value.
                    if (vm.data.possibleTrackingTypes.indexOf(vm.data.trackingType) === -1) {
                        vm.data.trackingType = vm.data.possibleTrackingTypes[0];
                    }
                };

                /*
                 * Fires when the trackingType is updated. Clears out the comparison and updates the
                 * UI but remove any values that are no longer allowed with the new trackingType.
                 */
                vm.onTrackingTypeUpdated = function (trackingTypeName) {
                    vm.condition.trackingType = trackingTypeName;
                    let statuses = vm.orderTypes[vm.data.orderType]
                        .orderTrackingTypeSummaryList.find(
                            type => {
                                return type.trackingTypeName === trackingTypeName;
                            }).trackingStatusList;

                    // Transform the statuses into the format the comparison directive needs
                    let statusObj = {};
                    statuses.forEach((status, index) => {
                        statusObj[index] = { name : status, text : status };
                    });

                    // Filter out any invalid values currently stored in the comparison, but keep
                    // any valid ones.
                    let validValues;
                    if (vm.valueComparisonOption) {
                        let currentValues = vm.valueComparisonOption.getValues();
                        if (currentValues) {
                            validValues = Object.keys(currentValues).filter(key => {
                                return statuses.indexOf(currentValues[key].name) >= 0;
                            }).map(key => {
                                return currentValues[key];
                            });
                        }
                    }

                    // Create a comparison object with updated (and valid) values.
                    // eslint-disable-next-line no-use-before-define
                    let comparison = createValueComparisonOption(
                        vm.condition,
                        statusObj,
                    );
                    if (validValues) {
                        comparison.setValues(validValues);
                    }

                    // without a double timeout, the comparison option directive breaks, so we just
                    // wait one frame, then remove it, then wait one more and set the new one.
                    // This way Angular updates the UI properly.
                    $timeout(() => {
                        vm.valueComparisonOption = null;
                        $timeout(() => {
                            vm.valueComparisonOption = comparison;
                        });
                    });
                };

                vm.$onInit = () => {
                    vm.orderTypes = OrderTypeService.getOrderTypes();

                    let possibleOrderTypes = Object.keys(vm.orderTypes);
                    let orderType = vm.condition.orderType || Object.keys(vm.orderTypes)[0];
                    let possibleTrackingTypes = vm.orderTypes[orderType]
                        .orderTrackingTypeSummaryList.map(item => {
                            return item.trackingTypeName;
                        });
                    let trackingType = vm.condition.trackingType
                    || vm.orderTypes[
                        Object.keys(vm.orderTypes)[0]
                    ].orderTrackingTypeSummaryList[0].trackingTypeName;

                    vm.data = {
                        orderType             : orderType,
                        possibleOrderTypes    : possibleOrderTypes,
                        possibleTrackingTypes : possibleTrackingTypes,
                        trackingType          : trackingType,
                    };

                    if (vm.data.orderType) {
                        vm.onOrderTypeUpdated(vm.data.orderType);
                    }

                    if (vm.data.trackingType) {
                        vm.onTrackingTypeUpdated(vm.data.trackingType);
                    }
                };
            };

            /*
             * Creates a comparison object to be used by the UI
             * @param {Object} condition
             * @param {Object} options
             */
            function createValueComparisonOption (condition, options) {
                let valueComparisonOption = new ValueComparisonOptionModel();

                valueComparisonOption.isRequired = true;
                valueComparisonOption.canConfigureOperator = true;

                valueComparisonOption.functions.getValueType = function () {
                    return ValueType.STRING;
                };

                valueComparisonOption.functions.getOperator = function () {
                    return condition.getComparator();
                };

                valueComparisonOption.functions.setOperator = function (newOperator) {
                    condition.setComparator(newOperator);
                };

                valueComparisonOption.functions.getValues = function () {
                    return condition.trackingStatusList;
                };

                valueComparisonOption.functions.setValues = function (newValues) {
                    condition.trackingStatusList = newValues;
                };

                valueComparisonOption.functions.getPossibleValues = function () {
                    return options;
                };

                return valueComparisonOption;
            }

            return {
                bindToController : true,
                controller       : orderTrackingStatusConfigController,
                controllerAs     : 'otsc',
                restrict         : 'E',
                scope            : {
                    condition : '<',
                },
                templateUrl :
                    'admin-templates/site/programTests/programTest/directive/conditionsDirectives/orderTrackingStatusConfig.html',
            };
        });
}());
