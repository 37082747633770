import template from './dismissBanner.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionDismissBanner', function (RuleUtilService) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    action: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.programId = $scope.action.getRoot().programId;
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.formOptions = {
                        form: $scope.action.getForm(),
                        title: 'Select Form',
                        viewOnly: false,
                        onChangeCallBack : function() {
                            $scope.action.setForm(this.form);
                        }
                    };

                    $scope.bannerTypeNeedsForm = function () {
                        return $scope.action.bannerTypeNeedsForm();
                    };
                }
            };
        });
})();
