'use strict';

angular.module('acadiamasterApp')
    .controller('RuleScheduledAuditController', function ($state, $stateParams, ParseLinks, scheduledRuleAudits, $scope, BusinessRuleScheduledRuleAuditHelperService) {

        var vm = this;

        vm.searchData = {
            userId: parseInt($stateParams.userId) || null,
            ruleId: parseInt($stateParams.ruleId) || null,
            startDate: $stateParams.startDate ? BusinessRuleScheduledRuleAuditHelperService.parseDate($stateParams.startDate) : BusinessRuleScheduledRuleAuditHelperService.getYesterday(),
            endDate: $stateParams.endDate ? BusinessRuleScheduledRuleAuditHelperService.parseDate($stateParams.endDate) : BusinessRuleScheduledRuleAuditHelperService.getToday(),
            searchResultCount: 0,
            pendingJobs: $stateParams.pendingJobs || "false",
            page: parseInt($stateParams.page) || 0
        };

        vm.category = {
            EXECUTED: 'EXECUTED',
            UNEXECUTED: 'UNEXECUTED'
        };

        if ( vm.searchData.pendingJobs == "true" ) {
            vm.changedCategory = vm.category.UNEXECUTED;
            vm.previousCategory = vm.category.UNEXECUTED;
            vm.datedisabled = true;
        } else {
            vm.changedCategory = vm.category.EXECUTED;
            vm.previousCategory = vm.category.EXECUTED;
            vm.datedisabled = false;
        }

        vm.links = ParseLinks.parse(scheduledRuleAudits.responseHeaders('link'));
        vm.searchData.searchResultCount = scheduledRuleAudits.responseHeaders('X-Total-Count');
        vm.ruleAudits = scheduledRuleAudits;

        vm.loadAll = function (page) {
            if (page == null) {
                vm.searchData.page = 0;
            } else {
                vm.searchData.page = page;
            }

            $state.go('ruleScheduledAudits', {
                "userId": vm.searchData.userId,
                "ruleId": vm.searchData.ruleId,
                "startDate": vm.changedCategory == vm.category.UNEXECUTED ? null : vm.searchData.startDate.getTime(),
                "endDate": vm.changedCategory == vm.category.UNEXECUTED ? null : vm.searchData.endDate.getTime(),
                "page": vm.searchData.page,
                "pendingJobs": vm.searchData.pendingJobs
            });

        };

        vm.loadPage = function (page) {
            vm.loadAll(page);
        };

        scheduledRuleAudits.forEach(function (ruleAudit) {
            ruleAudit.hasError = !!ruleAudit.failureStatus;
        });

        vm.ruleAudits = scheduledRuleAudits;

        /***************************************************************
         * Date picker functions
         ***************************************************************/
        vm.startDateOptions = {
            showWeeks: false,
            formatDayTitle: 'MMMM yyyy',
            formatDay: 'd',
            maxDate: new Date(),
            showWeekDays: true,
            calendarWeeks: false,
            showButtonBar: true
        };

        $scope.$watch('ruleScheduledAuditCtrl.searchData.startDate', function (nv, ov) {
            vm.endDateOptions = {
                showWeeks: false,
                formatDayTitle: 'MMMM yyyy',
                formatDay: 'd',
                maxDate: new Date(),
                minDate: nv,
                showWeekDays: true,
                calendarWeeks: false,
                showButtonBar: true
            };
        });

        vm.popupBeginDate = {
            opened: false
        };
        vm.popupEndDate = {
            opened: false
        };
        vm.openBeginDate = function () {
            vm.popupBeginDate.opened = true;
        };
        vm.openEndDate = function () {
            vm.popupEndDate.opened = true;
        };

        vm.categoryFilterChanged = function () {
            if (vm.previousCategory !== vm.changedCategory) {
                if (vm.changedCategory == vm.category.UNEXECUTED) {
                    vm.searchData.pendingJobs = true;
                } else {
                    vm.searchData.pendingJobs = false;
                }
                vm.loadAll(0);
            }
            vm.previousCategory = vm.changedCategory;
        };
    });

