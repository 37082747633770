(function () {
    angular.module('acadiamasterApp')
        .factory('RuleConstantsService', (vbrCommonUtil, FormConstants) => {
            let basePackageActionConfig = 'com.vibrent.acadia.web.rest.dto.br.actionConfig.';
            let basePackageTriggerConfig = 'com.vibrent.acadia.web.rest.dto.';
            return {
                accountInformationField : FormConstants.accountInformationFieldConst,

                actionType : {
                    BANNER_NOTIFICATION                  : { icon : 'flaticon-label-reference', name : 'BANNER_NOTIFICATION', text : 'Banner Notification' },
                    CANCEL_ORDER                         : { icon : 'fa fa-ban', name : 'CANCEL_ORDER', text : 'Cancel Order' },
                    CREATE_ORDER                         : { icon : 'fa fa-truck', name : 'CREATE_ORDER', text : 'Create Order' },
                    DISMISS_BANNER                       : { icon : 'glyphicon glyphicon-log-out', name : 'DISMISS_BANNER', text : 'Dismiss Banner' },
                    EMAIL_OR_SMS_NOTIFICATION            : { icon : 'flaticon-email', name : 'EMAIL_OR_SMS_NOTIFICATION', text : 'Email or SMS Notification' },
                    EVALUATE_PERSONALIZED_CONTAINER      : { icon : 'fa fa-archive', name : 'EVALUATE_PERSONALIZED_CONTAINER', text : 'Evaluate Personalized Container' },
                    EVALUATE_PERSONALIZED_CONTAINER_ITEM : { icon : 'fa fa-th', name : 'EVALUATE_PERSONALIZED_CONTAINER_ITEM', text : 'Evaluate Personalized Container Item' },
                    FIRE_VXP_EVENT                       : { icon : 'fa fa-fire', name : 'FIRE_VXP_EVENT', text : 'Fire VXP event' },
                    FORM                                 : { icon : 'flaticon-form', name : 'FORM', text : 'Form' },
                    IN_APP_NOTIFICATION                  : { icon : 'flaticon-push-notification', name : 'IN_APP_NOTIFICATION', text : 'In App Rich Text Notification' },
                    LEVEL_CHANGE                         : { icon : 'glyphicon glyphicon-signal', name : 'LEVEL_CHANGE', text : 'Change level' },
                    MOBILE_APP_PUSH_NOTIFICATION         : { icon : 'flaticon-push-notification', name : 'MOBILE_APP_PUSH_NOTIFICATION', text : 'Mobile App Push Notification' },
                    MODAL_NOTIFICATION                   : { icon : 'flaticon-form', name : 'MODAL_NOTIFICATION', text : 'Modal notification as a form' },
                    PERSONALIZED_FORM                    : { icon : 'flaticon-personal-form', name : 'PERSONALIZED_FORM', text : 'Personalized form' },
                    PROFILE_UPDATE                       : { icon : 'flaticon-person-info', name : 'PROFILE_UPDATE', text : 'Profile update' },
                    PUSH_NOTIFICATION                    : { icon : 'flaticon-push-notification', name : 'PUSH_NOTIFICATION', text : 'Push Notification' },
                    TRACK_CHANGE                         : { icon : 'glyphicon glyphicon-sound-dolby', name : 'TRACK_CHANGE', text : 'Activate/Deactivate Track' },
                    TRIGGER_RULE                         : { icon : 'flaticon-trigger', name : 'TRIGGER_RULE', text : 'Trigger Business Rule' },
                    UNAUTHENTICATED_TASK                 : { icon : 'flaticon-personal-form', name : 'UNAUTHENTICATED_TASK', text : 'Unauthenticated task' },
                    USER_ACCOUNT_UPDATE                  : { icon : 'flaticon-avatar', name : 'USER_ACCOUNT_UPDATE', text : 'User account update' },
                    WITHDRAW                             : { icon : 'glyphicon glyphicon-log-out', name : 'WITHDRAW', text : 'Withdraw' },
                    CREATE_CHILD_ACCOUNT                 : { icon : 'flaticon-person-info', name : 'CREATE_CHILD_ACCOUNT', text : 'Create Child Account'},
                    PCI_CHANGE_DISMISS_STATUS            : { icon : 'fa fa-times', name : 'PCI_CHANGE_DISMISS_STATUS', text : 'PCI Change Dismiss Status' },
                },

                addressType : FormConstants.addressType,

                attachmentAllowedExtensions : '.doc,.docx,.ppt,.pptx,.xls,.xlsx,.pdf,.mp4,.txt,.mp3,.png,.zip',

                // To support all the file types for attachment we have kept it as empty. We will add this later when specified.
                attachmentAllowMimeType : '',

                bannerDisplayTargetArea : {
                    AGREEMENTS   : { name : 'AGREEMENTS', text : 'Agreements' },
                    DASHBOARD    : { name : 'DASHBOARD', text : 'Dashboard' },
                    MEASUREMENT  : { name : 'MEASUREMENT', text : 'Measurement' },
                    PROFILE      : { name : 'PROFILE', text : 'Profile' },
                    SETTING      : { name : 'SETTING', text : 'Setting' },
                    SUPPORT      : { name : 'SUPPORT', text : 'Support' },
                    SYNC_DEVICES : { name : 'SYNC_DEVICES', text : 'Sync Devices' },
                },

                bannerType : {
                    APPOINTMENT_SCHEDULED  : { icon : 'flaticon-clock', name : 'APPOINTMENT_SCHEDULED', text : 'Appointment Scheduled' },
                    INFO                   : { icon : 'glyphicon glyphicon-info-sign', name : 'INFO', text : 'Info' },
                    MANUAL_SCHEDULING_FORM : {
                        icon : 'glyphicon glyphicon-hand-up', name : 'MANUAL_SCHEDULING_FORM',
                        text : 'Show form for manual appoint scheduling',
                    },
                    OPEN_FORM                     : { icon : 'flaticon-form', name : 'OPEN_FORM', text : 'Open Form' },
                    PMB_DATA_RECEIVED             : { icon : 'glyphicon glyphicon-hand-up', name : 'PMB_DATA_RECEIVED', text : 'Physical Measurements Received' },
                    SCHEDULE_PHYSICAL_MEASUREMENT : {
                        icon : 'flaticon-clock', name : 'SCHEDULE_PHYSICAL_MEASUREMENT',
                        text : 'Schedule Physical Measurement',
                    },
                },

                CarrierCodeEnum : {
                    USPS : 'USPS',
                },

                classes : {
                    ActionConfigBannerNotificationModel                : `${basePackageActionConfig }ActionConfigBannerNotificationModel`,
                    ActionConfigCancelOrderModel                       : `${basePackageActionConfig }ActionConfigCancelOrderModel`,
                    ActionConfigCreateOrderModel                       : `${basePackageActionConfig }ActionConfigCreateOrderModel`,
                    ActionConfigEmailOrSMSNotificationModel            : `${basePackageActionConfig }ActionConfigEmailOrSMSNotificationModel`,
                    ActionConfigEvaluatePersonalizedContainerItemModel : `${basePackageActionConfig }ActionConfigEvaluatePersonalizedContainerItemModel`,
                    ActionConfigEvaluatePersonalizedContainerModel     : `${basePackageActionConfig }ActionConfigEvaluatePersonalizedContainerModel`,
                    ActionConfigFireVXPEventModel                      : `${basePackageActionConfig }ActionConfigFireVXPEventModel`,
                    ActionConfigInAppNotificationModel                 : `${basePackageActionConfig }ActionConfigInAppNotificationModel`,
                    ActionConfigLevelChangeModel                       : `${basePackageActionConfig }ActionConfigLevelChangeModel`,
                    ActionConfigMobileAppPushNotificationModel         : `${basePackageActionConfig }ActionConfigMobileAppPushNotificationModel`,
                    ActionConfigModalNotificationModel                 : `${basePackageActionConfig }ActionConfigModalNotificationModel`,
                    ActionConfigPersonalizedFormModel                  : `${basePackageActionConfig }ActionConfigPersonalizedFormModel`,
                    ActionConfigTrackChangeModel                       : `${basePackageActionConfig }ActionConfigTrackChangeModel`,
                    ActionConfigTriggerRuleModel                       : `${basePackageActionConfig }ActionConfigTriggerRuleModel`,
                    ActionConfigUnauthenticatedTaskModel               : `${basePackageActionConfig }ActionConfigUnauthenticatedTaskModel`,
                    ActionConfigUserAccountUpdateItemModel             : `${basePackageActionConfig }ActionConfigUserAccountUpdateItemModel`,
                    ActionConfigUserAccountUpdateListModel             : `${basePackageActionConfig }ActionConfigUserAccountUpdateListModel`,
                    ActionConfigUserAccountUpdateModel                 : `${basePackageActionConfig }ActionConfigUserAccountUpdateModel`,
                    ActionConfigWithdrawModel                          : `${basePackageActionConfig }ActionConfigWithdrawModel`,
                    ActionPushNotificationConfig                       : `${basePackageActionConfig }ActionPushNotificationConfig`,
                    classReferenceKey                                  : '@class',
                    ActionConfigCreateChildAccountListModel            : `${basePackageActionConfig }ActionConfigCreateChildAccountListModel`,
                    ActionConfigCreateChildAccountItemModel            : `${basePackageActionConfig }ActionConfigCreateChildAccountItemModel`,
                    ActionConfigPciChangeDismissStatusModel            : `${basePackageActionConfig }ActionConfigPciChangeDismissStatusModel`,
                },

                codeType : {
                    ACTION    : { prefix : 'A', type : 'action' },
                    CONDITION : { prefix : 'C', type : 'condition' },
                },
                conditionType : {
                    LOG_EVALUATION     : { icon : 'flaticon-editing', name : 'LOG_EVALUATION', text : 'Log' },
                    PROFILE_EVALUATION : { icon : 'flaticon-person-info', name : 'PROFILE_EVALUATION', text : 'Profile' },
                },
                consentTypes : {
                    EHR       : 'EHR',
                    PII       : 'PII',
                    REFRESHER : 'REFRESHER',
                },
                delayTypes : {
                    NONE               : { name : 'NONE', text : 'None' },
                    RELATIVE_DELAY     : { name : 'RELATIVE_DELAY', text : 'Relative Delay' },
                    SPECIFIC_DATE_TIME : { name : 'SPECIFIC_DATE_TIME', text : 'Specific Date & Time' },
                },
                emailNotificationCategory : {
                    SYSTEM : { icon : 'flaticon-gear', name : 'SYSTEM', text : 'System email' },
                },
                emailOrSMSNotificationPreference : {
                    BOTH      : { icon : 'flaticon-internet', name : 'BOTH', text : 'Both Email and SMS' },
                    EMAIL     : { icon : 'flaticon-email', name : 'EMAIL', text : 'Email' },
                    PREFERRED : { icon : 'flaticon-gear', name : 'PREFERRED', text : 'Preferred' },
                    SMS       : { icon : 'flaticon-push-notification', name : 'SMS', text : 'SMS' },
                },
                FIELDS : {
                    ACTIONS : {
                        id        : 'actions',
                        isActions : true,
                    },
                    ID : {
                        id       : 'id',
                        sortable : true,
                    },
                    NAME : {
                        id       : 'name',
                        sortable : true,
                    },
                    PROGRAM_CODE : {
                        id       : 'programCode',
                        isCode   : true,
                        sortable : false,
                    },
                    RULE_TYPE : {
                        id       : 'ruleType',
                        sortable : false,
                    },
                    RULES_ENABLE : {
                        id           : 'rulesEnable',
                        isRuleToggle : true,
                        sortable     : false,
                    },
                    UPDATED_ON : {
                        id       : 'updatedOn',
                        isDate   : true,
                        sortable : true,
                    },
                },
                formDisplayTypes : {
                    SHOW_FORM_IMMEDIATELY_AS_POP_UP : {
                        icon : 'flaticon-window', name : 'SHOW_FORM_IMMEDIATELY_AS_POP_UP',
                        text : 'Show Form Immediately As Popup',
                    },
                    SHOW_FORM_IN_WALL : { icon : 'flaticon-title', name : 'SHOW_FORM_IN_WALL', text : 'Show Form In Wall' },
                },
                formEntryStatusType : {
                    COMPLETE : { icon : 'glyphicon glyphicon-ok', name : 'COMPLETE', text : 'On complete' },
                    EDITED   : { icon : 'glyphicon glyphicon-edit', name : 'EDITED', text : 'On Edit' },
                    PAUSED   : { icon : 'glyphicon glyphicon-pause', name : 'PAUSED', text : 'On Pause' },
                },
                getObjectByName       : vbrCommonUtil.getObjectByName,
                isNullOrUnavailable   : vbrCommonUtil.isNullOrUnavailable,
                isUnavailable         : vbrCommonUtil.isUnavailable,
                logTriggerSubmitTypes : {
                    DATA_TYPE_SUBMIT : { icon : 'flaticon-gear', name : 'DATA_TYPE_SUBMIT', text : 'On Data Type Submit' },
                    FORM_SUBMIT      : { icon : 'flaticon-save', name : 'FORM_SUBMIT', text : 'On Form Submit' },
                },
                nodeType : {
                    ACTION                                  : 'action',
                    ACTION_HOLDER_FIXED_NODE                : 'actionHolderFixedNode',
                    CONDITION                               : 'evaluationCondition',
                    EXPRESSION                              : 'expression',
                    FUNCTIONAL_EXPRESSION_FIXED_NODE        : 'functionalExpressionFixedNode',
                    RULE                                    : 'rule',
                    SQL_EXPRESSION_ACTION_HOLDER_FIXED_NODE : 'sqlExpressionActionHolderFixedNode',
                    SQL_EXPRESSION_FIXED_NODE               : 'sqlExpressionFixedNode',
                    TRIGGER                                 : 'trigger',
                    TRIGGER_FIXED_NODE                      : 'triggerFixedNode',
                    WORKFLOW                                : 'workflow',
                },
                notificationActionLinkType : {
                    EXTERNAL_NAVIGATION : { icon : 'glyphicon glyphicon glyphicon-globe', name : 'EXTERNAL_NAVIGATION', text : 'External Navigation' },
                    FORM                : { icon : 'flaticon-form', name : 'FORM', text : ' Form' },
                    NAVIGATION          : { icon : 'glyphicon glyphicon-transfer', name : 'NAVIGATION', text : 'Navigation' },
                    NONE                : { icon : 'glyphicon glyphicon-info-sign', name : 'NONE', text : 'Information' },
                },
                notificationActionNavigationLink : {
                    AGREEMENTS : { isForProgram : false, name : 'AGREEMENTS', text : 'Agreements' },
                    // only show up for program rule
                    CONTAINER  : { isForProgram : true, name : 'CONTAINER', text : 'Container' },

                    DASHBOARD : { isForProgram : false, name : 'DASHBOARD', text : 'Dashboard' },

                    MY_DATA_MEASUREMENTS : { isForProgram : false, name : 'MY_DATA_MEASUREMENTS', text : 'My Data -  Measurements' },

                    MY_DATA_SELF_ASSESSMENTS : { isForProgram : false, name : 'MY_DATA_SELF_ASSESSMENTS', text : 'My Data -  Self Assessment' },

                    PAGE : { isForProgram : true, name : 'PAGE', text : 'Page' },

                    SCHEDULING : { isForProgram : false, name : 'SCHEDULING', text : 'Scheduling' },

                    SETTINGS     : { isForProgram : false, name : 'SETTINGS', text : 'Settings' },
                    SYNC_DEVICES : { isForProgram : false, name : 'SYNC_DEVICES', text : 'Sync Devices' }, // only show up for program rule
                },
                notificationDeliveryTypeEnum : {
                    EMAIL : { icon : 'flaticon-editing', name : 'EMAIL', text : 'Send an email' },
                    PUSH  : { icon : 'flaticon-phone', name : 'PUSH', text : 'Send push' },
                },
                notificationTemplateType : {
                    MESSAGE  : { icon : 'flaticon-profile-program', name : 'MESSAGE', text : 'Set message' },
                    TEMPLATE : { icon : 'flaticon-personal-form', name : 'TEMPLATE', text : 'Set email template' },
                },
                PCIDismissStatus : {
                    ALL       : { name : 'ALL', text : 'ALL' },
                    DISMISS   : { name : 'DISMISS', text : 'DISMISS' },
                    REINSTATE : { name : 'REINSTATE', text : 'REINSTATE' },
                },
                personalizedFormActionType : {
                    ADD_FORM    : { icon : 'flaticon-check-narrow', name : 'ADD_FORM', text : 'Add Personalized Form' },
                    MOVE_FORM   : { icon : 'flaticon-change-setting', name : 'MOVE_FORM', text : 'Move form to group' },
                    REMOVE_FORM : { icon : 'flaticon-spinner-of-dots', name : 'REMOVE_FORM', text : 'Remove Personalized Form' },
                },
                PCIDissmissEnum : {
                    DISMISSED   : { name : 'DISMISSED', text : 'DISMISSED' },
                    UNDISMISSED : { name : 'UNDISMISSED', text : 'UNDISMISSED' },
                },
                ProviderEnum : {
                    GENOTEK : 'GENOTEK',
                    USPS    : 'USPS',
                },
                referenceIdType : {
                    LOCAL_ID  : 'LOCAL_ID',
                    SERVER_ID : 'SERVER_ID',
                },
                reminderTimeOptions : {
                    DAY_1     : { name : 'DAY_1', text : '1 day' },
                    DAY_3     : { name : 'DAY_3', text : '3 days' },
                    DAY_7     : { name : 'DAY_7', text : '7 days' },
                    DAY_14    : { name : 'DAY_14', text : '14 days' },
                    DAY_30    : { name : 'DAY_30', text : '30 days' },
                    HOUR_1    : { name : 'HOUR_1', text : '1 hour' },
                    HOUR_2    : { name : 'HOUR_2', text : '2 hours' },
                    HOUR_4    : { name : 'HOUR_4', text : '4 hours' },
                    HOUR_12   : { name : 'HOUR_12', text : '12 hours' },
                    MINUTE_1  : { name : 'MINUTE_1', text : '1 minute' },
                    MINUTE_5  : { name : 'MINUTE_5', text : '5 minutes' },
                    MINUTE_10 : { name : 'MINUTE_10', text : '10 minutes' },
                    MINUTE_15 : { name : 'MINUTE_15', text : '15 minutes' },
                    MINUTE_30 : { name : 'MINUTE_30', text : '30 minutes' },
                    MINUTE_45 : { name : 'MINUTE_45', text : '45 minutes' },
                    PERMANENT : { name : 'PERMANENT', text : 'never' },
                },
                ruleActionStatus : {
                    COMPLETED : 'COMPLETED',
                    FAILED    : 'FAILED',
                    UNKNOWN   : 'UNKNOWN',
                },
                ruleExecutionStatus : {
                    CANCELLED          : 'CANCELLED',
                    COMPLETED          : 'COMPLETED',
                    EVALUATED_TO_FALSE : 'EVALUATED_TO_FALSE',
                    FAILED             : 'FAILED',
                    IN_PROGRESS        : 'IN_PROGRESS',
                    PUBLISHED_TO_KAFKA : 'PUBLISHED_TO_KAFKA',
                },
                ruleScheduleDayType : {
                    CALENDAR_DAY_GMT            : { icon : 'flaticon-clock', name : 'CALENDAR_DAY_GMT', text : 'Calendar Day UTC' },
                    CALENDAR_DAY_USER_TIME_ZONE : { icon : 'flaticon-clock', name : 'CALENDAR_DAY_USER_TIME_ZONE', text : 'Calendar Day User Timezone' },
                },
                ruleScheduleType : {
                    PERIOD_SPECIFIC  : { icon : 'flaticon-clock', name : 'PERIOD_SPECIFIC', text : 'Period Specific' },
                    PROGRAM_SPECIFIC : { icon : 'flaticon-editing', name : 'PROGRAM_SPECIFIC', text : 'Program Specific' },
                },
                ruleType : {
                    PROGRAM_RULE          : 'PROGRAM_RULE',
                    PROGRAM_SCHEDULE_RULE : 'PROGRAM_SCHEDULE',
                    PROTOCOL              : 'PROTOCOL',
                },
                shortTimeUnits : {
                    DAY    : { multiplier : 86400, name : 'DAY', text : 'Days' },
                    HOUR   : { multiplier : 3600, name : 'HOUR', text : 'Hours' },
                    MINUTE : { multiplier : 60, name : 'MINUTE', text : 'Minutes' },
                    SECOND : { multiplier : 1, name : 'SECOND', text : 'Seconds' },
                },
                templateAllowExtensions : '.zip',
                templateAllowMimeType   : 'application/zip',
                timeUnits               : {
                    DAY    : { multiplier : 86400, name : 'DAY', text : 'Days' },
                    HOUR   : { multiplier : 3600, name : 'HOUR', text : 'Hours' },
                    MINUTE : { multiplier : 60, name : 'MINUTE', text : 'Minutes' },
                    MONTH  : { multiplier : 2592000, name : 'MONTH', text : 'Months' },
                    SECOND : { multiplier : 1, name : 'SECOND', text : 'Seconds' },
                    YEAR   : { multiplier : 31622400, name : 'YEAR', text : 'Years' },
                },
                trackChangeType : {
                    ACTIVATE   : { icon : 'glyphicon glyphicon-play-circle', name : 'ACTIVATE', text : 'Activate' },
                    DEACTIVATE : { icon : 'glyphicon glyphicon-off', name : 'DEACTIVATE', text : 'Deactivate' },
                },
                TrackingTypeEnum : {
                    PARTICIPANT_TRACKING : 'PARTICIPANT_TRACKING',
                    RETURN_TRACKING      : 'RETURN_TRACKING',
                },

                trigger : {
                    classes : {
                        classReferenceKey                 : '@class',
                        TriggerAddressUpdateDTO           : `${basePackageTriggerConfig }TriggerAddressUpdateDTO`,
                        TriggerAgreementEventDTO          : `${basePackageTriggerConfig }TriggerAgreementEventDTO`,
                        TriggerDeactivateDTO              : `${basePackageTriggerConfig }TriggerDeactivateDTO`,
                        TriggerLogDTO                     : `${basePackageTriggerConfig }TriggerLogDTO`,
                        TriggerLoginDTO                   : `${basePackageTriggerConfig }TriggerLoginDTO`,
                        TriggerOrderTrackingUpdateDTO     : `${basePackageTriggerConfig }TriggerOrderTrackingUpdateDTO`,
                        TriggerOrderUpdateDTO             : `${basePackageTriggerConfig }TriggerOrderUpdateDTO`,
                        TriggerPCIDismissStatusChangeDTO  : `${basePackageTriggerConfig }TriggerPCIDismissStatusChangeDTO`,
                        TriggerScheduleDTO                : `${basePackageTriggerConfig }TriggerScheduleDTO`,
                        TriggerSignUpDTO                  : `${basePackageTriggerConfig }TriggerSignUpDTO`,
                        TriggerUserProfileChangeDTO       : `${basePackageTriggerConfig }TriggerUserProfileChangeDTO`,
                        TriggerVXPEventDTO                : `${basePackageTriggerConfig }TriggerVXPEventDTO`,
                        TriggerLinkedChildAccountAddedDTO                : `${basePackageTriggerConfig }TriggerLinkedChildAccountAddedDTO`,
                    },
                },

                triggerType : {
                    ADDRESS_UPDATE            : { icon : 'glyphicon glyphicon-home', name : 'ADDRESS_UPDATE', text : 'Address Update' },
                    AGREEMENT_EVENT           : { icon : 'fa fa-handshake-o', name : 'AGREEMENT_EVENT', text : 'Agreement Event' },
                    DEACTIVATE                : { icon : 'fa fa-user-times', name : 'DEACTIVATE', text : 'Deactivate' },
                    LINKED_CHILD_ACCOUNT_ADDED  : { icon : 'flaticon-person-info', name : 'LINKED_CHILD_ACCOUNT_ADDED', text : 'Linked Child Account Added' },
                    LOG_ENTRY                 : { icon : 'flaticon-editing', name : 'LOG_ENTRY', text : 'Form Entry' },
                    LOGIN                     : { icon : 'fa fa-sign-in', name : 'LOGIN', text : 'Login' },
                    ORDER_TRACKING_UPDATE     : { icon : 'fa fa-location-arrow', name : 'ORDER_TRACKING_UPDATE', text : 'Order Delivery Tracking Status' },
                    ORDER_UPDATE              : { icon : 'fa fa-truck', name : 'ORDER_UPDATE', text : 'Order Update' },
                    PCI_DISMISS_STATUS_CHANGE : { icon : 'fa fa-times', name : 'PCI_DISMISS_STATUS_CHANGE', text : 'PCI Dismiss Status Change' },
                    SCHEDULE                  : { icon : 'flaticon-clock', name : 'SCHEDULE', text : 'Schedule' },
                    SIGN_UP                   : { icon : 'flaticon-come-in', name : 'SIGN_UP', text : 'Sign Up' },
                    USER_INFO_CHANGE          : { icon : 'flaticon-person-info', name : 'USER_INFO_CHANGE', text : 'User Info Change' },
                    USER_PROFILE_VALUE_CHANGE : {
                        icon : 'flaticon-person-info', name : 'USER_PROFILE_VALUE_CHANGE',
                        text : 'User Profile Value Change',
                    },
                    VXP_EVENT : { icon : 'fa fa-calendar', name : 'VXP_EVENT', text : 'VXP Event' },
                },
                UNAVAILABLE : vbrCommonUtil.UNAVAILABLE,
                VXPEvents   : {
                    APPOINTMENT_DETAILS_UPDATE  : 'APPOINTMENT_DETAILS_UPDATE',
                    CREATE_TRACK_ORDER_REQUEST  : 'CREATE_TRACK_ORDER_REQUEST',
                    CREATE_TRACK_ORDER_RESPONSE : 'CREATE_TRACK_ORDER_RESPONSE',
                    EXTERNAL_EVENT              : 'EXTERNAL_EVENT',
                    FULFILLMENT_REQUEST_EVENT   : 'FULFILLMENT_REQUEST_EVENT',
                    FULFILLMENT_RESPONSE_EVENT  : 'FULFILLMENT_RESPONSE_EVENT',
                    FULFILLMENT_RETURN_TRACKING_EVENT: 'FULFILLMENT_RETURN_TRACKING_EVENT',
                    IDENTITY_VERIFICATION       : 'IDENTITY_VERIFICATION',
                    PAIRING_UPDATE              : 'PAIRING_UPDATE',
                    PARTICIPANT_SUMMARY_UPDATE  : 'PARTICIPANT_SUMMARY_UPDATE',
                    TRACK_DELIVERY_REQUEST      : 'TRACK_DELIVERY_REQUEST',
                    TRACK_DELIVERY_RESPONSE     : 'TRACK_DELIVERY_RESPONSE',
                    USER_MILESTONE_EVENT        : 'USER_MILESTONE_EVENT',
                },
                TrackingActionType :{
                    START : 'START',
                    STOP : 'STOP'
                }
            };
        });
}());
