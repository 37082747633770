(() => {
    angular.module('acadiamasterApp')
        .controller('ProgramTestSuiteExecutionAuditController', (
            $scope,
            $stateParams,
            $location,
            $httpParamSerializer,
            ProgramTestSuiteService,
            ParseLinks,
            PTSEA_CONSTANTS,
        ) => {
            const vm = this;
            const SEARCH_CHOICES = [
                {
                    checked: false,
                    label: 'Program Test Suite ID',
                    name: 'programTestSuiteId',
                    value: null,
                },
                {
                    checked: false,
                    label: 'ID',
                    name: 'id',
                    value: null,
                },
            ];
            const fieldConstants = PTSEA_CONSTANTS;
            const defaultPageSize = 20;

            vm.toggleSearchType = (name) => {
                if (name === SEARCH_CHOICES[1].name) {
                    vm.search.label = `${SEARCH_CHOICES[0].label}`;
                    vm.search.name = `${SEARCH_CHOICES[0].name}`;
                    if (vm.params.hasOwnProperty(SEARCH_CHOICES[0].name)) {
                        delete vm.params.programTestSuiteId;
                    }
                } else {
                    vm.search.label = SEARCH_CHOICES[1].label;
                    vm.search.name = SEARCH_CHOICES[1].name;
                    if (vm.params.hasOwnProperty(SEARCH_CHOICES[0].name)) {
                        delete vm.params.id;
                    }
                }

                vm.search.checked = true;
                Object.assign(vm.params, {
                    [name]: vm.search.value,
                });
            };

            vm.doSearch = (criteria = null) => ProgramTestSuiteService.getPageAudits(criteria)
                .then(response => response.data);

            vm.searchBtnClick = () => {
                let paramObj = {};
                const { name } = vm.search;

                if (name) {
                    paramObj = Object.assign(paramObj, {
                        [vm.search.name]: vm.search.value,
                    });
                } else if (vm.search.value) {
                    // a user has entered a search value but has not toggled
                    // the search type button, so we use default key
                    paramObj = Object.assign(paramObj, {
                        [SEARCH_CHOICES[1].name]: vm.search.value,
                    });
                }

                const paramString = $httpParamSerializer(paramObj);
                $location.url([`/programTests/test-suite-execution-audits?${paramString}`]);
                vm.doSearch(paramObj).then((results) => { vm.audits.list = results; },
                    (error) => { vm.error = error; });
            };

            vm.order = ({ by }) => {
                // doing a sort should wipe out search details
                if (vm.params.hasOwnProperty(SEARCH_CHOICES[0].name)) {
                    delete vm.params.programTestSuiteId;
                } else if (vm.params.hasOwnProperty(SEARCH_CHOICES[1].name)) {
                    delete vm.params.id;
                }

                vm.params = Object.assign(vm.params, {
                    // if the parameters already had an orderBy value
                    // and it was the same as it is now,
                    // and ascending was previously true,
                    // make ascending = false;
                    ascending: !(vm.params.orderBy
                        && (vm.params.orderBy === by)
                        && vm.params.ascending),
                    orderBy: `${by}`,
                });

                vm.sort = Object.assign({}, {
                    ascending: !!vm.params.ascending,
                    orderBy: `${vm.params.orderBy}`,
                });

                const paramString = $httpParamSerializer(vm.sort);
                $location.url([`/programTests/test-suite-execution-audits?${paramString}`]);
                vm.doSearch(vm.sort).then((results) => { vm.audits.list = results; },
                    (error) => { vm.error = error; });
            };

            vm.goToPage = (pageNum) => {
                vm.pagination = Object.assign(vm.pagination, {
                    page: pageNum,
                });

                vm.params = Object.assign(vm.params, {
                    page: pageNum,
                });

                const paramString = $httpParamSerializer(vm.params);
                $location.url([`/programTests/test-suite-execution-audits?${paramString}`]);
                ProgramTestSuiteService.getPageAudits(vm.params).then(({ data, headers }) => {
                    vm.audits.list = data;
                    vm.pagination.links = ParseLinks.parse(headers('link'));
                    vm.pagination.searchResultCount = headers('X-Total-Count');
                }, (error) => { vm.error = error; });
            };

            const init = () => {
                vm.data = {
                    isLoading: true,
                };
                vm.audits = {
                    fields: fieldConstants,
                    list: [],
                };
                vm.params = $location.search();
                vm.search = {};
                vm.sort = {};
                vm.pagination = {};

                const {
                    id,
                    programTestSuiteId,
                    orderBy,
                    ascending,
                    page,
                    size,
                } = vm.params;

                // url has search
                if (vm.params.hasOwnProperty(SEARCH_CHOICES[0].name)) {
                    vm.search = Object.assign({}, SEARCH_CHOICES[0],
                        { checked: true, value: programTestSuiteId });
                } else if (vm.params.hasOwnProperty(SEARCH_CHOICES[1].name)) {
                    vm.search = Object.assign({}, SEARCH_CHOICES[1], { checked: true, value: id });
                } else {
                    vm.search = Object.assign({}, SEARCH_CHOICES[0]);
                }

                // url has sorting
                if (orderBy) {
                    vm.sort = Object.assign({}, {
                        ascending,
                        orderBy,
                    });
                }

                // url has pagination
                if (page) {
                    vm.pagination = Object.assign({}, {
                        page,
                        size: size || defaultPageSize,
                    });
                }

                ProgramTestSuiteService.getPageAudits(vm.params).then(({ data, headers }) => {
                    vm.audits.list = data;
                    vm.pagination.links = ParseLinks.parse(headers('link'));
                    vm.pagination.searchResultCount = headers('X-Total-Count');
                }, (error) => { vm.error = error; })

                    .finally(() => {
                        vm.data.isLoading = false;
                    });
            };

            init();

            return vm;
        });
})();
