/**
 * Created by Danny on 2/10/2016.
 */
/**
 * Created by Danny on 10/28/2015.
 */
'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('programManagement', {
                name: 'programManagement',
                parent: 'site',
                url: '/programs',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programManagement.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programManagement/programManagement.html',
                        controller: 'ProgramManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('programManagement');
                        return $translate.refresh();
                    }
                }
            });
    });


