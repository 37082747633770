(function () {
    'use strict';

    /**
     * form search service related to display config, all the business logic is here
     *
     * some functions in here are copied from ActionScript framework, that framework will become
     * obsolete once this is finished, so there is no reason to spend time to make sure the function
     * works in both places, we'll remove the action script related code once this is fully tested
     */
    angular.module('acadiamasterApp').factory('FormDisplaySearchService', function (vbrCommonUtil) {
        var unitDp = 'dp';

        /***************************************
         * service return call
         ***************************************/

        return {
            filter : filter
        };

        /***************************************************************
         * service return functions
         ***************************************************************/

        /**
         * filter a list of results by display search model
         * @param displaySearchModel - filter criteria related to display config
         * @param originalResults - original list of results to filter from
         * @returns {Array} - a list of item that's from the original result list and satisfies the
         *                  filter criteria defined by displaySearchModel
         */
        function filter(displaySearchModel, originalResults) {
            if (displaySearchModel == null || originalResults == null || originalResults.length == 0) {
                return [];
            }

            return _.filter(originalResults, function(element) {
                return matchDisplayFilter(element, displaySearchModel);
            });
        }

        /***************************************************************
         * private functions
         **************************************************************/

        /**
         * perform some misc filter to
         * check if the display config matches the display search model
         * @param displayConfig
         * @param displaySearchModel
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         *
         * note: filters checked here are visibility, editable, width, gravity
         */
        function matchingMiscFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            if (sm.visibility.enabledAndHasValue() && !isVisibilityEqual(dc, sm.visibility.value)) {
                return false;
            }
            if (sm.editable.enabledAndHasValue() && !isEditableEqual(dc, sm.editable.value)) {
                return false;
            }

            // width
            if (sm.width.enabledAndHasValue() && !isWidthEqual(dc, sm.width.value, sm.width.unit)) {
                return false;
            }

            // gravity
            if (sm.gravityH.enabledAndHasValue() && !isGravityHEqual(dc, sm.gravityH.value)) {
                return false;
            }

            if (sm.gravityV.enabledAndHasValue() && !isGravityVEqual(dc, sm.gravityV.value)) {
                return false;
            }

            // return true now all the conditions are checked
            return true;
        }

        /**
         * perform font related filter to
         * check if the display config matches the display search model
         * @param displayConfig
         * @param displaySearchModel
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         */
        function matchingFontFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            if (sm.fontSize.enabledAndHasValue() && !isFontSizeEqual(dc, sm.fontSize.value)) {
                return false;
            }
            if (sm.fontName.enabledAndHasValue() && !isFontNameEqual(dc, sm.fontName.value)) {
                return false;
            }
            if (sm.fontStyleNormal.enabledAndHasValue() && !isFontStyleNormalEqual(dc, sm.fontStyleNormal.value)) {
                return false;
            }
            if (sm.fontStyleBold.enabledAndHasValue() && !isFontStyleBoldEqual(dc, sm.fontStyleBold.value)) {
                return false;
            }
            if (sm.fontStyleItalic.enabledAndHasValue() && !isFontStyleItalicEqual(dc, sm.fontStyleItalic.value)) {
                return false;
            }
            if (sm.fontStyleInherit.enabledAndHasValue() && !isFontStyleInheritEqual(dc, sm.fontStyleInherit.value)) {
                return false;
            }

            // return true now all the conditions are checked
            return true;
        }

        /**
         * perform color related filter to
         * check if the display config matches the display search model
         * @param displayConfig
         * @param displaySearchModel
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         */
        function matchingColorFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            if (sm.color.enabledAndHasValue() && !isColorEqual(dc, sm.color.value)) {
                return false;
            }
            if (sm.bgColor.enabledAndHasValue() && !isBgColorEqual(dc, sm.bgColor.value)) {
                return false;
            }

            // return true now all the conditions are checked
            return true;
        }

        /**
         * perform padding related filter to
         * check if the display config matches the display search model
         * @param displayConfig
         * @param displaySearchModel
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         */
        function matchingPaddingFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            if (sm.paddingLeft.enabledAndHasValue() && !isPaddingLeftEqual(dc, sm.paddingLeft.value, sm.paddingLeft.unit)) {
                return false;
            }
            if (sm.paddingRight.enabledAndHasValue() && !isPaddingRightEqual(dc, sm.paddingRight.value, sm.paddingRight.unit)) {
                return false;
            }
            if (sm.paddingTop.enabledAndHasValue() && !isPaddingTopEqual(dc, sm.paddingTop.value, sm.paddingTop.unit)) {
                return false;
            }
            if (sm.paddingBottom.enabledAndHasValue() && !isPaddingBottomEqual(dc, sm.paddingBottom.value, sm.paddingBottom.unit)) {
                return false;
            }


            // return true now all the conditions are checked
            return true;
        }

        /**
         * perform margin related filter to
         * check if the display config matches the display search model
         * @param displayConfig
         * @param displaySearchModel
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         */
        function matchingMarginFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            if (sm.marginLeft.enabledAndHasValue() && !isMarginLeftEqual(dc, sm.marginLeft.value, sm.marginLeft.unit)) {
                return false;
            }
            if (sm.marginRight.enabledAndHasValue() && !isMarginRightEqual(dc, sm.marginRight.value, sm.marginRight.unit)) {
                return false;
            }
            if (sm.marginTop.enabledAndHasValue() && !isMarginTopEqual(dc, sm.marginTop.value, sm.marginTop.unit)) {
                return false;
            }
            if (sm.marginBottom.enabledAndHasValue() && !isMarginBottomEqual(dc, sm.marginBottom.value, sm.marginBottom.unit)) {
                return false;
            }

            // return true now all the conditions are checked
            return true;
        }

        /**
         * check for the matching display filter
         * @param element - element to check
         * @param displaySearchModel - display search filter
         * @returns {boolean} - true if all filters are satisfied, false otherwise
         */
        function matchDisplayFilter(element, displaySearchModel) {
            if (element == null) {
                // this is just for safety, should never happen
                return false;
            }

            if (element.displayConfig == null) {
                // display config is null, it is something in the state rule list, not suitable for this search
                return false;
            }

            // now go through the list of the filters in the system one by one

            // misc item
            if (!matchingMiscFilters(element.displayConfig, displaySearchModel)) {
                return false;
            }

            // fonts related
            if (!matchingFontFilters(element.displayConfig, displaySearchModel)) {
                return false;
            }

            // color related
            if (!matchingColorFilters(element.displayConfig, displaySearchModel)) {
                return false;
            }

            // padding related
            if (!matchingPaddingFilters(element.displayConfig, displaySearchModel)) {
                return false;
            }

            // margin related
            if (!matchingMarginFilters(element.displayConfig, displaySearchModel)) {
                return false;
            }

            // all the filters are satisfied, match found
            return true;
        }


        /*****            fonts matching functions                 *****/
        /**
         * check if the font style is bold or not
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for bold
         * @returns {boolean}
         */
        function isFontStyleBoldEqual(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.bold)) {
                return displayConfig.font.fontStyle.bold === expectedValue;
            }

            return false;
        }

        /**
         * check if the font style is italic or not
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for italic
         * @returns {boolean}
         */
        function isFontStyleItalicEqual(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.italic)) {
                return displayConfig.font.fontStyle.italic === expectedValue;
            }

            return false;
        }

        /**
         * check if the font style is normal or not
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for normal
         * @returns {boolean}
         */
        function isFontStyleNormalEqual(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.normal)) {
                return displayConfig.font.fontStyle.normal === expectedValue;
            }

            return false;
        }

        /**
         * check if the font style is inherit or not
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for inherit
         * @returns {boolean}
         */
        function isFontStyleInheritEqual(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.inherit)) {
                return displayConfig.font.fontStyle.inherit === expectedValue;
            }

            return false;
        }

        /**
         * check if font name is equal to a specific font family name
         */
        function isFontNameEqual(displayConfig, fontName) {
            function isDefaultFontName(fontName) {
                if (fontName==null) {
                    return true;
                }

                return fontName === '"Noto Sans", Sans-serif';
            }

            if (displayConfig == null || vbrCommonUtil.isNullOrUnavailable(displayConfig.font)) {
                return false;
            }

            // if it's default color
            if (isDefaultFontName(fontName)) {
                return isDefaultFontName(displayConfig.font.fontName);
            }

            return displayConfig.font.fontName == fontName;
        }


        /**
         * check if the font size matches
         * @param displayConfig - font object
         * @param fontSize - font size, null == 14
         * @returns {boolean}
         */
        function isFontSizeEqual(displayConfig, fontSize) {
            // no display config or no font specified
            if (displayConfig == null || displayConfig.font == vbrCommonUtil.UNAVAILABLE || displayConfig.font == null) {
                return false;
            }

            // font size is null, use default font size
            if (displayConfig.font.fontSize == null) {
                return fontSize == null || fontSize == 14;
            }

            if (fontSize == null) {
                return displayConfig.font.fontSize == 14;
            }

            return displayConfig.font.fontSize == fontSize;
        }

        /*****        box model matching functions                 *****/
        
        /**
         * check if the display config's top padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isPaddingTopEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.padding, 'top', size, unit);
        }

        /**
         * check if the display config's bottom padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isPaddingBottomEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.padding, 'bottom', size, unit);
        }

        /**
         * check if the display config's left padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isPaddingLeftEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.padding, 'left', size, unit);
        }

        /**
         * check if the display config's right padding is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isPaddingRightEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.padding, 'right', size, unit);
        }


        /**
         * check if the display config's top margin is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isMarginTopEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.margin, 'top', size, unit);
        }

        /**
         * check if the display config's bottom margin is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isMarginBottomEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.margin, 'bottom', size, unit);
        }

        /**
         * check if the display config's left margin is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isMarginLeftEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.margin, 'left', size, unit);
        }

        /**
         * check if the display config's right margin is equal to a specific size
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function isMarginRightEqual(displayConfig, size, unit) {
            return isMarginPaddingEqual(displayConfig.margin, 'right', size, unit);
        }



        /**
         * is margin/padding equal to a specific value
         * ps. null == 0
         * @param marginPaddingModel - Margin/Padding model
         * @param paddingDirection - padding direction: left|right|top|bottom
         * @param size - size, null == 0 here
         * @param unit - unit to match
         * @returns {*} - true if the padding value specified in the model is equal to the input size
         */
        function isMarginPaddingEqual(marginPaddingModel, paddingDirection, size, unit) {
            if (marginPaddingModel == null || marginPaddingModel == vbrCommonUtil.UNAVAILABLE) {
                return false;
            }

            if (marginPaddingModel.simpleConfig != null) {
                return isValueEqual(marginPaddingModel.simpleConfig, size, unit, unitDp);
            }
            else if (marginPaddingModel.advancedConfig != null) {
                return isValueEqual(marginPaddingModel.advancedConfig[paddingDirection], size, unit, unitDp);
            }

            // system config, no match
            return false;
        }



        /*****        color model matching functions                 *****/
        
        /**
         * check if the color in display config matches the colorToMatch
         * @param displayConfig - display config object
         * @param colorToMatch - color to match for, black80 == #333333 == null
         */
        function isColorEqual(displayConfig, colorToMatch) {
            function isDefaultColor(color) {
                if (color==null) {
                    return true;
                }

                var lowerCaseColor = color.toLowerCase();
                return lowerCaseColor === 'black80' || lowerCaseColor === '#333333';
            }

            if (displayConfig == null || vbrCommonUtil.isNullOrUnavailable(displayConfig.color)) {
                return false;
            }

            // if it's default color
            if (isDefaultColor(colorToMatch)) {
                return isDefaultColor(displayConfig.color);
            }

            return displayConfig.color == colorToMatch;
        }

        /**
         * check if the background color in display config matches the colorToMatch
         * @param displayConfig - display config object
         * @param colorToMatch - color to match for
         */
        function isBgColorEqual(displayConfig, colorToMatch) {
            function isDefaultBgColor(color) {
                if (color==null) {
                    return true;
                }

                var lowerCaseColor = color.toLowerCase();
                return lowerCaseColor === 'white' || lowerCaseColor === '#ffffff';
            }

            if (displayConfig == null || vbrCommonUtil.isNullOrUnavailable(displayConfig.bgColor)) {
                return false;
            }

            // if it's default color
            if (isDefaultBgColor(colorToMatch)) {
                return isDefaultBgColor(displayConfig.bgColor);
            }

            return displayConfig.bgColor == colorToMatch;
        }

        
        /*****        gravity matching functions                 *****/
        /**
         * check if the vertical gravity defined in the display config is the same as the gravity value input
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function isGravityVEqual(displayConfig, gravityValue) {
            if (displayConfig==null || vbrCommonUtil.isNullOrUnavailable(displayConfig.gravity) || displayConfig.gravity.vertical==null) {
                return false;
            }

            return gravityValue == displayConfig.gravity.vertical;
        }

        /**
         * check if the horizontal gravity defined in the display config is the same as the gravity value input
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function isGravityHEqual(displayConfig, gravityValue) {
            if (displayConfig==null || vbrCommonUtil.isNullOrUnavailable(displayConfig.gravity) || displayConfig.gravity.horizontal==null) {
                return false;
            }

            return gravityValue == displayConfig.gravity.horizontal;
        }
        

        /*****            other matching functions                 *****/
        
        function isVisibilityEqual(dc, matchingVisibility) {
            if (isPropertyAvailable(dc.visible) && !dc.advancedVisibility) {
                var dcValue = dc && dc.visible;
                return dcValue==null || dcValue == matchingVisibility;
            }
            
            // if the property is not even available, ignore this match, just return false
            return false;
        }

        function isEditableEqual(dc, matchingValue) {
            if (isPropertyAvailable(dc.editable)) {
                var dcValue = dc && dc.editable;
                return dcValue==null || dcValue == matchingValue;
            }

            // if the property is not even available, ignore this match, just return false
            return false;
        }

        function isWidthEqual(dc, matchingWidth, matchingUnit) {
            function isDefaultWidth(width, unit) {
                if (width==null || width<=0) {
                    return true;
                }

                // width is 100%
                return width==100 && unit=='%';
            }

            if (isPropertyAvailable(dc.width)) {
                var dcValue = dc.width;
                var widthValue = dcValue==null ? null : dcValue.value;
                var widthUnit = dcValue==null ? null : dcValue.unit;

                widthUnit = widthUnit ? widthUnit : unitDp;

                // if it's default color
                if (isDefaultWidth(matchingWidth, matchingUnit)) {
                    return isDefaultWidth(widthValue, widthUnit);
                }

                return isUnitAndValueMatch(widthValue, widthUnit, matchingWidth, matchingUnit, unitDp);
            }

            // if the property is not even available, ignore this match, just return false
            return false;
        }

        /*****            internal util functions                 *****/

        /**
         * check if the property is available or not
         * @param propertyValue
         * @returns {boolean}
         */
        function isPropertyAvailable(propertyValue) {
            return !vbrCommonUtil.isUnavailable(propertyValue);
        }

        /**
         * check if the value in the value with unit object matches the value to match
         * @param valueWithUnitObject
         * @param valueToMatch
         * @param unit - unit to match
         * @param defaultUnit - defaultUnit to use when null is received in one of the unit, optional
         */
        function isValueEqual(valueWithUnitObject, valueToMatch, unit, defaultUnit) {
            if (valueWithUnitObject == null || valueWithUnitObject.value == null || valueWithUnitObject.value == 0) {
                return valueToMatch == null || valueToMatch == 0;
            }

            return isUnitAndValueMatch(valueWithUnitObject.value, valueWithUnitObject.unit, valueToMatch, unit, defaultUnit);
        }

        /**
         * check if units and values are equal
         * @param value1 - value 1
         * @param unit1 - unit 1
         * @param value2 - value 2
         * @param unit2 - unit 2
         * @param defaultUnit - defaultUnit to use when null is received in one of the unit, optional
         * @returns {boolean} - return true if both the value and unit are matching, false otherwise
         */
        function isUnitAndValueMatch(value1, unit1, value2, unit2, defaultUnit) {
            var valueMatched = value1 == value2;

            var isDefaultUnit1 = unit1 == null || unit1 == defaultUnit;
            var isDefaultUnit2 = unit2 == null || unit2 == defaultUnit;

            var unitMatched = (isDefaultUnit1 && isDefaultUnit2) || unit1==unit2;

            return valueMatched && unitMatched;
        }
    });

})();
