/**
 * Created by Pankaja on 27/10/2021
 *
 * directive for adding field in pdf config
 */
angular.module('acadiamasterApp').directive('addFieldList', (ngDialog, $document, FormConstants, TemplateFieldModelService) => ({
    restrict : 'E',
    scope    : {
        element    : '=',
    },
    link($scope) {
        $scope.ITEM_TITLES = 'Add Field Type';
        $scope.constants = FormConstants;

        $scope.newCheckboxField = function () {
            var templateField = new TemplateFieldModelService.TemplateFieldModel($scope.element, $scope.constants.pdfTemplateFieldType.CHECKBOX);
            $scope.element.templateFields.push(templateField);
            $scope.data.isPopupOpen = false;
        };

        $scope.newImageField = function () {
            var templateField = new TemplateFieldModelService.TemplateFieldModel($scope.element, $scope.constants.pdfTemplateFieldType.IMAGE);
            $scope.element.templateFields.push(templateField);
            $scope.data.isPopupOpen = false;
        };

        $scope.newTextField = function () {
            var templateField = new TemplateFieldModelService.TemplateFieldModel($scope.element, $scope.constants.pdfTemplateFieldType.TEXT);
            $scope.element.templateFields.push(templateField);
            $scope.data.isPopupOpen = false;
        };

        /**
         * method called when the plus button is clicked for adding a new container item
         * @param $event
         */
        $scope.onPopoverOpened = function ($event) {
            $event.stopPropagation();
            $scope.data.isPopupOpen = true;
        };

    },
    templateUrl : 'admin-templates/site/forms/configPanel/pdfConfig/addFieldList.html',
}));
