/* eslint-disable no-param-reassign */
(function () {
    /* *************************************************************
     * Form Entry Util Service, various utility functions here
     ***************************************************************/
    angular.module('acadiamasterApp').factory('FormEntryUtilService',
        ($q, $http, localWeb, AppConfigService, vbrCommonUtil) => {
            function convertDateToTimezone (valueAsNumber, timezone) {
                // check if timestamp is only for date and not time in it
                if (valueAsNumber % (24 * 3600 * 1000) === 0) {
                    return new Date(valueAsNumber).toLocaleString('en-US', {
                        day          : 'numeric',
                        month        : 'short',
                        timeZone     : 'UTC',
                        timeZoneName : 'long',
                        weekday      : 'short',
                        year         : 'numeric',
                    });
                }
                return new Date(valueAsNumber).toLocaleString('en-US', {
                    day          : 'numeric', hour         : 'numeric',
                    minute       : '2-digit',
                    month        : 'short',
                    timeZone     : timezone,
                    timeZoneName : 'long',
                    weekday      : 'short',
                    year         : 'numeric',
                });
            }
            function convertFieldEntryValueToHtml (fev, isDate, timezone) {
                if (isDate == null) {
                    isDate = false;
                }

                if (fev == null) {
                    return '';
                } else if (fev.valueAsNumber != null) {
                    if (isDate) {
                        let date;
                        date = convertDateToTimezone(fev.valueAsNumber, timezone);
                        return `${ date}`;
                    }
                    // a real number
                    return String(fev.valueAsNumber) + (fev.unitName != null ? ` ${ fev.unitName}` : '');
                } else if (fev.valueAsBoolean != null) {
                    return `${ fev.valueAsBoolean}`;
                } else if (fev.valueAsString != null) {
                    return fev.valueAsString;
                } else if (fev.valueAsFile != null) {
                    return `<a target='_blank' href='/api/file?fileName=${ fev.valueAsFile }'>View File</a>`;
                }

                return '';
            }

            function loadActionLogsByEntryIdV1 (entryId) {
                return $http.get(localWeb.createUrl(`form-entry-log/v1/${entryId}`));
            }

            function loadActionLogsByEntryIdV2 (entryId) {
                return $http.get(localWeb.createUrl(`form-entry-log/v2/${entryId}`));
            }

            /*
             * regenerate pdf using form entry id and user id
             * ps. we actually only need the form entry id, the user id is here just for validation purpose to
             * guard against data entry issue
             * @param formEntryId - id of the form entry
             * @param userId - user id that the form entry belongs
             */
            function regeneratePDF (formEntryId, userId) {
                let url = localWeb.createUrl('api/formEntry/regeneratePDF');

                // add params
                url = `${url}?formEntryId=${formEntryId }&userId=${userId}`;

                return $http.post(url, null, {
                    headers : vbrCommonUtil.getCommonPostHeader(),
                });
            }

            /** *************************************
             * factory return call
             ***************************************/

            return {
                convertFieldEntryValueToHtml : convertFieldEntryValueToHtml,
                loadActionLogsByEntryIdV1    : loadActionLogsByEntryIdV1,
                loadActionLogsByEntryIdV2    : loadActionLogsByEntryIdV2,
                regeneratePDF                : regeneratePDF,
            };
        });
}());
