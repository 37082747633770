'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
            $stateProvider.state('programTests', {
                parent: 'entity',
                url: '/programTests',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.programTest.home'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programTests/programTestDashboard.html',
                        controller: 'ProgramTestHomeController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('programTest');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                }
            });
        });
