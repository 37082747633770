/**
 * RegistrationCountHolder Model
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('RegistrationCountHolder', function () {
        /***************************************************************
         * Model
         ***************************************************************/
        function RegistrationCountHolder() {
            this.reset();
        }

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        RegistrationCountHolder.prototype.fromDto = function (dto) {
            if (dto!=null) {
                this.totalItem = dto.totalItem || 0;
                this.registeredItem = dto.registeredItem || 0;
            }
        };

        RegistrationCountHolder.prototype.reset = function () {
            this.totalItem = 0;
            this.registeredItem = 0;
        };

        /***************************************
         * factory return call
         ***************************************/

        return RegistrationCountHolder;
    });

})();
