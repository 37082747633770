(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('addressZipComparisonConfig', function (
        AddressStatesConstant, ValueType, ValueComparisonOptionModel) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/addressZipComparisonConfig.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.valueComparisonOption = createValueComparisonOption($scope.condition);
            }
        };

        function createValueComparisonOption(condition) {
            var valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = true;
            valueComparisonOption.canConfigureOperator = true;

            valueComparisonOption.functions.getValueType = function() {
                return ValueType.STRING;
            };

            valueComparisonOption.functions.getOperator = function() {
                return condition.getComparator();
            };

            valueComparisonOption.functions.setOperator = function (newOperator) {
                condition.setComparator(newOperator);
            };

            valueComparisonOption.functions.getValues = function() {
                return condition.zips;
            };

            valueComparisonOption.functions.setValues = function (newValues) {
                condition.zips = newValues;
            };

            valueComparisonOption.setRegex('^\\d{5}$', 'zip code must be 5 digits');

            return valueComparisonOption;
        }

    });
})();