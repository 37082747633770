import _ from 'lodash';

(function () {
    angular.module('acadiamasterApp')
        .factory('RedcapZipImportService', ($http, localWeb, Upload) => {
            return {
                importZipFile : function (files, redcapRequestDto) {
                    return Upload.upload({
                        data : {
                            data   : JSON.stringify(redcapRequestDto),
                            enFile : files.englishFile,
                            esFile : files.spanishFile,
                        },
                        url : localWeb.createUrl('redcap-import/v1'),
                    });
                },
                saveZipFileData : function (form, onSaveFinished) {
                    return Upload.upload({
                        arrayKey : '',
                        // to allow files array to upload with param name file instead of file[i]
                        data     : {
                            data : JSON.stringify(form),
                        },
                        url : localWeb.createUrl('api/forms/'),
                    }).then(resp => {
                        if (_.isFunction(onSaveFinished)) {
                            onSaveFinished(resp);
                        }
                    });
                },
            };
        });
}());
