angular.module('acadiamasterApp')
    .directive('timeCheck', ($translate, ProgramTestConstantsService, TimeComparisonOperatorService) => {
        return {
            link: ($scope) => {
                $scope.BETWEEN = TimeComparisonOperatorService.BETWEEN.name;
                $scope.ProgramTestConstantsService = ProgramTestConstantsService;
                $scope.TimeComparisonOperatorService = TimeComparisonOperatorService;

                $scope.localTimeZoneString = getLocalTimeZoneString();

                $scope.absoluteFlagChanged = function (absoluteTime) {
                    $scope.condition.setAbsoluteTime(absoluteTime);
                };

                /**
                 * getting a quick description of the condition, this is slightly different than the tree node display
                 * @returns {string}
                 */
                $scope.getDescription = function() {
                    var condition = $scope.condition;
                    var description = condition.timeComparator == null ? '?' : condition.timeComparator.description;
                    var timeMsg = timeMsgBuilder(condition, condition.value);
                    var timeMsgBegin = timeMsgBuilder(condition, condition.valueBeginAt);
                    var timeMsgEnd = timeMsgBuilder(condition, condition.valueEndAt);
                    if(condition.timeComparator.name === $scope.BETWEEN) {
                        const betweenMsg = `${ $scope.timeText } <span class="badge">${ description }</span class="badge"> ${ timeMsgBegin } and ${ timeMsgEnd }`;
                        return condition.getAbsoluteTime() ? betweenMsg : betweenMsg + ' ago';
                    }
                    const nonBetweenMsg = `${ $scope.timeText } <span class="badge">${ description }</span class="badge"> ${ timeMsg }`;
                    return condition.getAbsoluteTime() ? nonBetweenMsg : nonBetweenMsg + ' ago';
                };
            },
            restrict: 'E',
            scope: {
                condition: '=',
                timeText: '@?'
            },
            templateUrl: 'admin-templates/site/common/directive/timeCheck.html'
        };

        /***************************************
         * private function
         ***************************************/
        function timeMsgBuilder(condition, value) {
            return condition.absolute ? getAbsoluteTime(value) : getRelativeTime(value, condition.timeUnit);
        }
        function getLocalTimeZoneString() {
            var offSet = -(new Date().getTimezoneOffset() / 60);

            return Intl.DateTimeFormat().resolvedOptions().timeZone +
                ' GMT ' + (offSet >= 0 ? '+' : '') + offSet;
        }

        function getAbsoluteTime(dateValue) {
            if (dateValue == null) {
                return '?';
            }

            if (_.isFunction(dateValue.toUTCString)) {
                return dateValue.toUTCString();
            }

            return '?';
        }

        function getRelativeTime(relativeValue, timeUnit) {
            var msg = relativeValue == null ? '?' : '' + relativeValue;

            msg += ' ' + timeUnit;

            return msg;
        }


    });
