angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of a list of state rules
     */
    .directive('treeNodeCorrectiveActionList', function (
        CorrectiveActionCreationUtil, $timeout, ngDialog) {
            return {
                restrict: 'E',
                scope: {
                    testCase: '=',
                    selector: '=',
                    currentTreeName: '=',
                    showHiddenCorrectiveAction: '=',
                },

                templateUrl: 'admin-templates/site/programTests/programTest/treeNode/correctiveActionList.html',
                link: function ($scope) {
                    $scope.allCorrectiveActionCreators = CorrectiveActionCreationUtil.getAllCreators();

                    $scope.states = {
                        isAddNewPopupOpen: false,
                    };
                    init($scope);
                },

            };

            function init($scope) {

                $scope.data = {
                    ifConditionDetail: $scope.testCase.ifCondition.conditionTree,
                };

                $scope.showAddCorrectiveActionDialog = ($event) => {
                    $scope.states.isAddNewPopupOpen = true;
                    ngDialog.openConfirm({
                        className: 'ngdialog-theme-plain custom-width-small',
                        preCloseCallback: () => {
                            $timeout(() => {
                                $scope.states.isAddNewPopupOpen = false;
                            });
                        },
                        scope: $scope,
                        templateUrl: 'admin-templates/site/components/treeEditorDialogs/addCorrectiveAction.template.html',
                    }).then((creator) => {
                        creator.createNew($scope.testCase, $scope.selector);
                    });
                    $event.stopPropagation();

                };

                addWatches($scope);

                $scope.hasSubItems = function (item) {
                    var item = item;
                    return item != null && item.subItems != null && item.subItems.length > 0;
                };

                $scope.checkSubItemHasCondition = function (conditionTree) {
                    const typeName = conditionTree.type.name;
                    if (typeName == "CHECK_MULTIPLE_SUBSCRIPTION") {
                        return true;
                    } else if (typeName == "GROUP_OBJECT" && $scope.hasSubItems(conditionTree)) {

                        for (let i = 0; i < conditionTree.subItems.length; i++) {
                            const hasCondition = $scope.checkSubItemHasCondition(conditionTree.subItems[i]);
                            if (hasCondition) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                $scope.checkIfCondition = function () {
                    if ($scope.data.ifConditionDetail != null) {
                        return $scope.checkSubItemHasCondition($scope.data.ifConditionDetail);
                    } else if ($scope.hasSubItems($scope.data.ifConditionDetail)) {
                        return $scope.checkSubItemHasCondition($scope.data.ifConditionDetail);
                    }
                    return false;
                };

            }


            function addWatches($scope) {
                $scope.$watch(
                    function () {
                        return $scope.checkIfCondition();
                    },
                    function () {
                        if (!$scope.checkIfCondition()) {
                            $scope.testCase.correctiveActions = $scope.testCase.correctiveActions.filter(function (correctiveAction) {
                                return correctiveAction.type.name != "REMOVE_MULTIPLE_SUBSCRIPTION"
                            });
                        }
                    });
            }


        }
    );
