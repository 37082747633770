(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * form configuration tree listing directive
     */
    app.directive('formConfigTreePanel', function (FormTreeOptionsService, FormUtilService, FormConstants) {
        var dropDownNames = {
            "FORM" : 'FORM',
            "EDIT_MODE_PAGES" : 'EDIT_MODE_PAGES',
            "EDIT_MODE_STATE_RULES" : 'EDIT_MODE_STATE_RULES',
            "EDIT_MODE" : 'EDIT_MODE',
            "PROMPT_MODE" : 'PROMPT_MODE',
            "PROMPT_MODE_PAGES" : 'PROMPT_MODE_PAGES',
            "PROMPT_MODE_STATE_RULES" : 'PROMPT_MODE_STATE_RULES',

            "VIEW_ENTRY_MODE" : 'VIEW_ENTRY_MODE',
            "VIEW_ENTRY_MODE_PAGES" : 'VIEW_ENTRY_MODE_PAGES',
            "VIEW_ENTRY_MODE_STATE_RULES" : 'VIEW_ENTRY_MODE_STATE_RULES'
        };

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/treeSelectionPanel/treeSelectionPanel.html',
            scope: {
                currentTreeName : '=',
                states : '=',
                selector: '=', // selector that's shared on the form configuration page
                form: '='  // form object include one form version
            },
            link: function ($scope, element) {
                $scope.treeOptions = FormTreeOptionsService;


                /**
                 * list of items that shows up as the title drop down on the tree
                 * @type {[*]}
                 */
                $scope.dropDownOptions = [
                    {name: dropDownNames["FORM"], text : "Form"},
                    {name : dropDownNames["EDIT_MODE"], text : "__ Edit Mode"},
                    {name : dropDownNames["EDIT_MODE_PAGES"], text : "____ Edit Mode Pages", isPage : true},
                    {name : dropDownNames["EDIT_MODE_STATE_RULES"], text : "____ Edit Mode State Rules", isPage : false},
                    {name : dropDownNames["PROMPT_MODE"], text : "__ Prompt Mode"},
                    {name : dropDownNames["PROMPT_MODE_PAGES"], text : "____ Prompt Mode Pages", isPage : true},
                    {name : dropDownNames["PROMPT_MODE_STATE_RULES"], text : "____ Prompt Mode State Rules", isPage : false},
                    {name : dropDownNames["VIEW_ENTRY_MODE"], text : "__ Entry Mode"},
                    {name : dropDownNames["VIEW_ENTRY_MODE_PAGES"], text : "____ Entry Mode Pages", isPage : true},
                    {name : dropDownNames["VIEW_ENTRY_MODE_STATE_RULES"], text : "____ Entry Mode State Rules", isPage : false}
                ];

                $scope.data = {
                    selection : $scope.dropDownOptions[0]
                };
                $scope.treeMode = null;

                /**
                 * call this whenever selection is changed
                 */
                $scope.selectionChanged = function() {
                    var selectionOption = $scope.data.selection;
                    var formVersion = $scope.form.formVersions[0];

                    if (selectionOption.name===dropDownNames['FORM']) {
                        $scope.treeMode = null;
                        $scope.selector.selectItem(formVersion);
                    }
                    else if (selectionOption.name===dropDownNames['EDIT_MODE']) {
                        $scope.treeMode = null;
                        $scope.selector.selectItem(formVersion.editMode);
                    }
                    else if (selectionOption.name===dropDownNames['PROMPT_MODE']) {
                        $scope.treeMode = null;
                        $scope.selector.selectItem(formVersion.promptMode);
                    }
                    else if (selectionOption.name===dropDownNames['VIEW_ENTRY_MODE']) {
                        $scope.treeMode = null;
                        $scope.selector.selectItem(formVersion.viewMode);
                    }
                    else if (selectionOption.name===dropDownNames['EDIT_MODE_PAGES'] || selectionOption.name===dropDownNames['EDIT_MODE_STATE_RULES']) {
                        $scope.treeMode = formVersion.editMode;
                    }
                    else if (selectionOption.name===dropDownNames['PROMPT_MODE_PAGES'] || selectionOption.name===dropDownNames['PROMPT_MODE_STATE_RULES']) {
                        $scope.treeMode = formVersion.promptMode;
                    }
                    else if (selectionOption.name===dropDownNames['VIEW_ENTRY_MODE_PAGES'] || selectionOption.name===dropDownNames['VIEW_ENTRY_MODE_STATE_RULES']) {
                        $scope.treeMode = formVersion.viewMode;
                    }
                };


                /**
                 * function used to generate text rendered in the top level drop down
                 * @param option current option
                 */
                $scope.getOptionText = function(option) {
                    function getLength(input) {
                        if (input==null) {
                            return 0;
                        }
                        else {
                            return input.length;
                        }
                    }

                    var text = option.text;
                    var formVersion = $scope.form.formVersions[0];

                    var mode = null;
                    if (option.name === dropDownNames['EDIT_MODE_PAGES'] || option.name===dropDownNames['EDIT_MODE_STATE_RULES']) {
                        mode = formVersion.editMode;
                    }
                    else if (option.name===dropDownNames['PROMPT_MODE_PAGES'] || option.name===dropDownNames['PROMPT_MODE_STATE_RULES']) {
                        mode = formVersion.promptMode;
                    }
                    else if (option.name===dropDownNames['VIEW_ENTRY_MODE_PAGES'] || option.name===dropDownNames['VIEW_ENTRY_MODE_STATE_RULES']) {
                        mode = formVersion.viewMode;
                    }

                    if (mode!=null) {
                        text += "(" +  getLength(option.isPage ? mode.pages : mode.stateRules) + ")";
                    }

                    return text;
                };


                init($scope, element);
            }
        };

        function init($scope, directiveElement) {
            var turnOffListenerItemSelected = $scope.$on('selector.itemSelected' + '.' + $scope.currentTreeName, function (event, element) {
                updateTreeSelectionOnElementSelection($scope, element);
            });

            directiveElement.on('$destroy', function () {
                turnOffListenerItemSelected();
            });

            $scope.selectionChanged();
        }


        function updateTreeSelectionOnElementSelection($scope, selectedItem) {
            var targetSelection = null;
            selectedItem._currentSource = $scope.currentTreeName;

            if (selectedItem.nodeType===FormConstants.nodeType.FORM_VERSION) {
                targetSelection = _.find($scope.dropDownOptions, {name : dropDownNames['FORM']});
            }
            else if (selectedItem.nodeType===FormConstants.nodeType.MODE.EDIT) {
                targetSelection = _.find($scope.dropDownOptions, {name : dropDownNames['EDIT_MODE']});
            }
            else if (selectedItem.nodeType===FormConstants.nodeType.MODE.VIEW_ENTRY) {
                targetSelection = _.find($scope.dropDownOptions, {name : dropDownNames['VIEW_ENTRY_MODE']});
            }
            else if (selectedItem.nodeType===FormConstants.nodeType.MODE.PROMPT) {
                targetSelection = _.find($scope.dropDownOptions, {name : dropDownNames['PROMPT_MODE']});
            }
            else {
                // target is a sub element such as page, component, state rule, etc
                var findModeFunction = selectedItem['getFormMode'];
                var isPage = false;
                var formMode;
                if (findModeFunction==null || !_.isFunction(findModeFunction)) {
                    // this is a state rule type of element
                    findModeFunction = selectedItem['findParentMode'];
                    if (findModeFunction!=null && _.isFunction(findModeFunction)) {
                        // this is a page or element under page
                        isPage = true;
                    }
                }

                if (findModeFunction!=null && _.isFunction(findModeFunction)) {
                    // this is a state rule type of element
                    formMode = findModeFunction.apply(selectedItem);
                }
                else {
                    console.info('find mode function not found, no change to the current selection', selectedItem);
                    return;
                }

                if (formMode.nodeType === FormConstants.nodeType.MODE.EDIT) {
                    targetSelection = _.find($scope.dropDownOptions, {name : (isPage ? dropDownNames['EDIT_MODE_PAGES'] : dropDownNames['EDIT_MODE_STATE_RULES'])});
                }
                else if (formMode.nodeType===FormConstants.nodeType.MODE.VIEW_ENTRY) {
                    targetSelection = _.find($scope.dropDownOptions, {name : (isPage ? dropDownNames['VIEW_ENTRY_MODE_PAGES'] : dropDownNames['VIEW_ENTRY_MODE_STATE_RULES'])});
                }
                else if (formMode.nodeType===FormConstants.nodeType.MODE.PROMPT) {
                    targetSelection = _.find($scope.dropDownOptions, {name : (isPage ? dropDownNames['PROMPT_MODE_PAGES'] : dropDownNames['PROMPT_MODE_STATE_RULES'])});
                }
            }

            if (targetSelection!=null && targetSelection!=$scope.data.selection) {
                $scope.data.selection = targetSelection;
                $scope.selectionChanged();
            }
        }


    });
})();
