(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigInAppNotificationModel',
        function (RuleConstantsService, RuleActionBaseModelService,RuleUtilService) {

            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigInAppNotificationModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigInAppNotificationModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.IN_APP_NOTIFICATION;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigInAppNotificationModel;
                this.title = 'sample title';
                this.message = 'sample message';
                this.imageFile = null;
                this.imageFileName = null;
                this.imageFileUrl = null;
                this.imageFileSize = null;
                this.imageAspectRatio = null;
                this.localizationMap = {};
                this.dismissible = false;
                this.form = null;
                this.actionFormId = null;
                this.dismissOnFormEntry=false;
                return this;
            }

            ActionConfigInAppNotificationModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.message = actionConfig.message;
                    this.title = actionConfig.title;
                    this.imageFileSize = actionConfig.imageFileSize;
                    this.imageFileName = actionConfig.imageFileName;
                    this.imageFileUrl = actionConfig.imageFileUrl;
                    this.imageAspectRatio = actionConfig.imageAspectRatio;
                    this.localizationMap = actionConfig.localizationMap;
                    this.dismissible = actionConfig.dismissible;
                    this.actionFormId = actionConfig.actionFormId;
                    this.dismissOnFormEntry=actionConfig.dismissOnFormEntry;
                    if (actionConfig.actionFormId != null) {
                        this.form = RuleUtilService.getFormById(actionConfig.actionFormId);
                    }
                }
            };

            ActionConfigInAppNotificationModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.message = this.message;
                actionConfig.title = this.title;
                actionConfig.imageFileSize = this.imageFileSize;
                actionConfig.imageFileName = this.imageFileName;
                actionConfig.imageFileUrl = this.imageFileUrl;
                actionConfig.imageAspectRatio = this.imageAspectRatio;
                actionConfig.localizationMap = this.localizationMap;
                if (this.form != null) {
                    actionConfig.actionFormId = this.form.id;
                }
                if (this.imageFile !== null) {
                    actionConfig.imageFileName = this.imageFile.name;
                    actionConfig.imageFileSize = this.imageFile.size;
                    files.push(this.imageFile);
                }
                actionConfig.dismissible = this.dismissible;
                actionConfig.dismissOnFormEntry = this.dismissOnFormEntry;
                return dto;
            };
            ActionConfigInAppNotificationModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.dismissOnFormEntry && this.form == null) {
                    this.setErrorMessage('Form is not selected');
                }
            };

            ActionConfigInAppNotificationModel.prototype.getDescriptionAsHtml = function () {
                return `Add Notification ('inApp, 'Subject: ${ this.title })`;
            };

            return ActionConfigInAppNotificationModel;
        }
    );
})();
