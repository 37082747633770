'use strict';

(function () {
    angular.module('acadiamasterApp').directive('hasUnauthenticatedTask', function () {
        return {
            restrict    : 'E',
            templateUrl : 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/hasUnauthenticatedTask.html',
            scope       : {
                condition : '<',
            },
            link : function ($scope) {
                $scope.taskOptions = {
                    getTask   : () => $scope.condition.getTask(),
                    setTask   : task => $scope.condition.setTask(task),
                    programId : $scope.condition.getProgramId(),
                };


            },
        };
    });
})();
