angular.module('acadiamasterApp')
    .factory('ContainerIconModel', (LocalizedStringModel, ModelServiceBase, FileConverterService) => {

        ContainerIconModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        function ContainerIconModel(parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

            // icon configuration
            this.iconUrl = null;
            this.iconName = null;
            this.iconSize = null;

            // internal data uri object representing the file
            this._icon = null;
        }

        ContainerIconModel.prototype.toDto = function (files) {
            const dto = {};

            // image processing
            dto.iconName = this.iconName;
            dto.iconSize = this.iconSize;
            dto.iconUrl = this.iconUrl;

            if (this._icon != null) {
                const byCharacters = FileConverterService.convertDataURIToBinary(this._icon);
                const blob = new Blob([byCharacters], {type: 'image/png'});
                const fileName = this.iconName;
                const file = new File([blob], fileName);

                dto.iconSize = file.size;
                dto.iconUrl = null;
                files.push(file);
            }

            return dto;
        };

        ContainerIconModel.prototype.fromDto = function (dto) {
            if (dto==null) {
                return;
            }

            this._icon = null;

            this.iconName = dto.iconName;
            this.iconSize = dto.iconSize;
            this.iconUrl = dto.iconUrl;

        };

        ContainerIconModel.prototype.getImageUrl = function () {
            return this.iconUrl;
        };

        ContainerIconModel.prototype.getImageUri = function () {
            return this._icon;
        };

        ContainerIconModel.prototype.imageRemoved = function () {
            this._icon = null;
            this.iconName = null;
            this.iconSize = null;
            this.iconUrl = null;
        };

        ContainerIconModel.prototype.imageCropped = function (imageUri, imageFile) {
            this._icon = imageUri;
            this.iconName = null;
            this.iconSize = null;
            this.iconUrl = null;

            if (imageFile) {
                this.iconName = imageFile.name;
                this.iconSize = imageFile.size;
            }
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerIconModel;
    });
