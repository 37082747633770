/**
 * Created by moustafabaiou on 2/15/17
 */
angular.module('acadiamasterApp')
    .directive('treeNodeExpression', (RuleUtilService, $timeout, RuleExpressionModelService, ExpressionService, FixedNodeService, ngDialog) => ({
        controller: ($scope) => {
            $scope.ruleConstants = RuleUtilService.ruleConstants;
            $scope.fixedNodeService = FixedNodeService;

            $scope.ruleConstants = RuleUtilService.ruleConstants;
            $scope.actionTypeUtil = RuleExpressionModelService.getActionTypeUtil();

            $scope.states = {
                isAddActionPopupOpen: false,
            };

            $scope.showAddActionDialog = () => {
                $scope.states.isAddActionPopupOpen = true;
                ngDialog.openConfirm({
                    template: 'admin-templates/site/components/treeEditorDialogs/addActionExisting.template.html',
                    controller: 'ActionSelectionPopupController',
                    data: {
                        parentNode: $scope.expression
                    }
                }).then(function (action) {
                    ExpressionService.addNewAction(action, $scope.expression);
                    $scope.expression.validate(false);
                });
            };

            $scope.hasExistingActions = () => $scope.expression.getRule().actions.length > $scope.expression.actionHolders.length;
        },
        restrict: 'E',
        scope: {
            expression: '=',
            selector: '=',
        },
        templateUrl: 'admin-templates/site/businessRule/treeNode/expression.html',
    }));
