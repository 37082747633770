/**
 * program configuration for container dependency linking
 */

angular.module('acadiamasterApp')
.directive('programDpLinking', function() {
    return {
        bindToController: true,
        controller: 'ContainerDpLinkController',
        controllerAs: 'dplc',
        restrict: 'E',
        scope: {
            level: '@',
            entity: '<?',
        },
        templateUrl: 'admin-templates/site/programManagement/program/dpLinking/programDPLinking.template.html',
    }
});

