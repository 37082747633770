'use strict';

angular.module('vibrent-external-survey-parser')
    .service('RedcapFormStructureConstantService', () => {

        const redcapTextValidationTypes = {
            EMAIL : 'email',
            DATE_DMY : 'date_dmy',
            DATE_MDY : 'date_mdy',
            DATE_YMD : 'date_ymd',
            DATETIME_DMY : 'datetime_dmy',
            DATETIME_MDY : 'datetime_mdy',
            DATETIME_YMD : 'datetime_ymd',
            DATETIME_SECONDS_DMY : 'datetime_seconds_dmy',
            DATETIME_SECONDS_MDY : 'datetime_seconds_mdy',
            DATETIME_SECONDS_YMD : 'datetime_seconds_ymd',
            INTEGER : 'integer',
            NUMBER : 'number',
            PHONE : 'phone',
            TIME : 'time',
            TIME_HH_MM_SS : 'time_hh_mm_ss',
            ZIPCODE: 'zipcode',
        };

        const redcapFileValidationTypes = {
            SIGNATURE: 'signature',
        };

        const redcapSurveyQuestionTypes = {
            TEXT: 'text',
            DROPDOWN: 'dropdown',
            CHECKBOX: 'checkbox',
            RADIO: 'radio',
            FILE: 'file',
            DESCRIPTIVE: 'descriptive',
            NOTES: 'notes',
            YES_NO: 'yesno',
            TRUE_FALSE: 'truefalse',
            SLIDER: 'slider',
            RADIO_MATRIX: 'radio_matrix',
            CHECKBOX_MATRIX: 'checkbox_matrix',
        };

        const redcapAlignmentTypes = {
            RV: 'RV',
            RH: 'RH',
            LV: 'LV',
            LH: 'LH',
        };

        return {
            redcapSurveyQuestionTypes,
            redcapTextValidationTypes,
            redcapFileValidationTypes,
            redcapAlignmentTypes,
        };
    });
