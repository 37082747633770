'use strict';

(function () {
    angular.module('acadiamasterApp')
        .controller('RemoveZipCodesController', function ($scope, vbrCommonUtil, AlertService, DonationSiteService) {

            var zipCodesLabel = 'zip codes';
            $scope.csv = vbrCommonUtil.defaultCSVConfig();

            function parseCsv() {
                var removedZipCodesList = null;
                if(!$scope.csv.result || $scope.csv.result.length === 0){
                    AlertService.warning('There is nothing in CSV file to remove');
                    return removedZipCodesList;
                } else {
                    removedZipCodesList = [];
                    var invalidZipFound = false;
                    _.forEach($scope.csv.result, function (object) {
                        if(!isNaN(object[zipCodesLabel])) {
                            object[zipCodesLabel] && removedZipCodesList.push(object[zipCodesLabel].trim());
                        } else {
                            if(!invalidZipFound) {
                                AlertService.warning('Only numeric characters are allowed for zip codes. Found "' + object[zipCodesLabel] + '"');
                                invalidZipFound = true;
                            }
                            removedZipCodesList = null;
                            return null;
                        }
                    });
                    if(removedZipCodesList && removedZipCodesList.length === 0){
                        AlertService.warning('No data available');
                        removedZipCodesList = null;
                    }
                }

                return removedZipCodesList;
            }

            $scope.removeZipCodes = function () {
                var removedZipCodesList = parseCsv();
                if(removedZipCodesList){
                    DonationSiteService.removeZipCodes(removedZipCodesList).then(
                    function onRemoveSuccess(response) {
                        AlertService.success('Zip codes removed successfully');
                        $scope.closeThisDialog();
                        $scope.loadAll();
                    },
                    function onUploadFail(error) {
                        AlertService.warning(error, 'Error');
                    });
                }
            };
        });
})();
