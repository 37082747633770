/**
 * Created by Jamie Nola on 8/10/2018
 *
 * This file is used to control the behavior of the search filter modal.
 *
 * Note: When editing this file, keep all entries sorted alphabetically.
 *
 * Instructions:
 *
 * --------------------------------------------------
 *  FIELDS
 * --------------------------------------------------
 * Fields are used to populate the search filter modal. To add new fields, modify the FIELDS array. Within FIELDS, each entry can have the following properties:
 *
 *     - id (required): This is the key used by the server for the field. Make sure to also add a new entry in global.json, under searchFilter.field[id].
 *
 *     - displayId (optional): Sometimes two fields in different parts of the site need to utilize values with the same ID. When this happens, displayID can be used to change the displayed text in the UI for one without affecting the other. The displayID will pull its value from global.json, under searchFilter.field[filter.displayId]. This value will be displayed in the UI, but all other functionality will come from the filter's id attribute.
 *
 *     - type (required): A reference to an entry in the TYPES object. Determines if the field will be displayed as a text input, select box, typeahead input, etc. See next section for more details.
 *
 *     - options (optional): Only used for RADIO, SELECT, and TYPEAHEAD fields. If the options are hard-coded, add them here. Follow the other examples and fill the options array with name/value paired objects where the name is the displayed string, and value is the key to send the server. If they need to be loaded from another file or from an API call, DO NOT ADD THIS PROPERTY and instead use optionSource:
 *
 *     - optionSource (required for RADIO, SELECT, and TYPEAHEAD fields ONLY if options is not already set): Used to identify the source of where options need to come from. Must match an item in OPTION_SOURCE and can either be from a local file or an API. Consumed by the switch statement in searchFilterField.directive.js.
 *
 *     - optionNameTranslationPrefix: A prefix to be concatenated together with the option's name attribute that will translate to friendly display text.
 *
 *     - optionNameTranslationSuffix: The same as optionNameTranslationSuffix, but appended to the end of the name attribute.
 *
 *     - hasEmptyOption (optional): If this is set to true, an option will be added to the list that, when selected, will show the ANY_SELECTION subfields only, but will not return any value to the server for the parent field. The displayed text will need to be set in Global.json, under SearchFilter.emptyOption[filter.id].
 *
 *     - subfields (optional): All fields work recursively and can have any number of levels of subfields within them. For now, only enumerable type fields can have subfields, but this may change in the future. If a given field has subfields, they need to be added to this object. Each subfield is an array that uses the target value as its key within subfields. For example, if a SELECT field has an option with the value SERVICE that needs a TEXT subfield called INTERNAL_ID to show up when SERVICE is selected, the field's subfield object would look like this: { SERVICE: [ FIELDS.INTERNAL_ID ]}.
 *
 *     - 'Any Selection' subfields (optional): Some subfields need to be visible if any selection has been made in a RADIO, SELECT, or TYPEAHEAD field. To add these, just do the same as you would for a normal subfield, but use the ANY_SELECTION variable as the key. Example: FIELDS.TRIGGER_TYPE.subfields[ANY_SELECTION] = [ FIELDS.TRIGGER_FORM_ID, FIELDS.TRIGGER_ON_TYPE ]; This would make the TRIGGER_FORM_ID and TRIGGER_ON_TYPE show up as subfields anytime the user makes ANY selection on the TRIGGER_TYPE field.
 *
 * Special cases:
 *     - DATE_RANGE type: Date ranges have two additional fields, called start and end. These need to be references to other entries in the FIELDS array, which represent dates. Each one has an id that needs to match the server key for the min and/or max used in the date range.
 *
 *     - CRON: Crons must have an id and also a recurrenceTypeId. This is the key that the server expects for the field's recurrenceType. The normal id is used to represent the field's cron expression.
 *
 * --------------------------------------------------
 *  TYPES
 * --------------------------------------------------
 * Types represent the look and feel of a field. Each field must have a type. Each TYPE entry has the following properties:
 *
 *     - id (required): This just needs to be unique from any other type. It is only used to differentiate types from one another.
 *
 *     - isEnumerable (optional): Only used for fields that can have options, such as RADIO, SELECT, or TYPEAHEAD. If a field is enumerable, that means we can use it to store a series of options that a user can select.
 *
 *     - isExpandable (optional): If a field is expandable, it does not hold a value of its own. Instead, it can be clicked or selected to show or hide any number of subfields. Examples of expandable fields are accordions and checkboxes.
 *
 * */

angular.module('acadiamasterApp')
    .constant('SEARCH_FILTER_CONSTANTS', (function () {
        const ANY_SELECTION = '__any_selection__';
        const EMPTY_OPTION_VALUE = '__empty_option_value__';

        /*--------------------------------------------------
         *  SOURCES FOR DYNAMICALLY ADDED OPTIONS
         *--------------------------------------------------*/
        const OPTION_SOURCE = {
            API_EVENT_TYPE: 'apiEventType',
            API_EVENT_SOURCE: 'apiEventSource',
            API_FORM: 'apiForm',
            API_PROGRAM: 'apiProgram',
            API_PROPERTY: 'apiProperty',
            API_RULE: 'apiRule',
            API_SERVICE_TYPE: 'apiServiceType',
            API_TAG: 'apiTag',
            API_USER_EVENT_ACTION_TYPE: 'apiUserEventActionType',
            LOCAL_DEEP_LINK_PAGE: 'localDeepLinkPage',
            LOCAL_DEEP_LINK_TARGET_TYPE: 'localDeepLinkTargetType',
            LOCAL_FORM: 'localForm',
            LOCAL_RULE: 'localRule',
            LOCAL_STRING_FEATURE: 'localStringFeature',
            LOCAL_CONTAINER_DEPENDENCY_LINK_DEPENDENCY_TYPE: 'localContainerDependencyLinkDependencyType',
        };

        /*--------------------------------------------------
         *  FIELD TYPES
         *--------------------------------------------------*/
        const TYPES = {
            BOOLEAN: { id: 'boolean' },
            CRON: { id: 'cron' },
            DATE: { id: 'date' },
            DATE_RANGE: { id: 'dateRange' },
            EXPAND_CHECKBOX: { id: 'expandCheckbox', isExpandable: true },
            EXPAND_LINK: { id: 'expandLink', isExpandable: true },
            MULTI_SELECT: { id: 'multiSelect', isEnumerable: true },
            NUMBER: { id: 'number' },
            RADIO: { id: 'radio', isEnumerable: true },
            SELECT: { id: 'select', isEnumerable: true },
            TEXT: { id: 'text' },
            TYPEAHEAD: { id: 'typeahead', isEnumerable: true },
        };

        /**
         * Transforms an array of strings into an array of name/value paired objects.
         * @param {Array} array Array of strings to put into a name/value object
         */
        function createOptionsFromArray(array) {
            return array.map(item => ({
                name: item,
                value: item,
            }));
        }

        /*--------------------------------------------------
         *  SET FIELDS HERE
         *--------------------------------------------------*/
        const FIELDS = {
            ACTION_BUSINESS_RULE: {
                id: 'actionBusinessRuleId',
                optionSource: OPTION_SOURCE.API_RULE,
                type: TYPES.TYPEAHEAD,
            },
            ACTION_FORM_ID: {
                id: 'actionFormId',
                optionSource: OPTION_SOURCE.API_FORM,
                type: TYPES.TYPEAHEAD,
            },
            ACTION_MESSAGE: {
                id: 'actionMessage',
                type: TYPES.TEXT,
            },
            ACTION_NAME: {
                id: 'actionName',
                type: TYPES.TEXT,
            },
            ACTION_NOTIFICATION_LINK_TYPE: {
                id: 'notificationActionLinkType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            ACTION_PROPERTY: {
                id: 'actionPropertyId',
                optionSource: OPTION_SOURCE.API_PROPERTY,
                type: TYPES.TYPEAHEAD,
            },
            ACTION_PROPERTY_VALUE: {
                id: 'actionPropertyValue',
                type: TYPES.TEXT,
            },
            ACTION_TITLE: {
                id: 'actionTitle',
                type: TYPES.TEXT,
            },
            ACTION_TYPE: {
                hasEmptyOption: true,
                id: 'actionType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            BANNER_MESSAGE: {
                id: 'bannerMessage',
                type: TYPES.TEXT,
            },
            BANNER_TITLE: {
                id: 'bannerTitle',
                type: TYPES.TEXT,
            },
            BANNER_TYPE: {
                id: 'bannerType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            BANNER_TYPE_NO_SUBFIELDS: {
                id: 'bannerType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            BUSINESS_RULE_ID: {
                id: 'businessRuleId',
                type: TYPES.NUMBER
            },
            CRON: {
                id: 'cronExpression',
                recurrenceTypeId: 'recurrenceType',
                type: TYPES.CRON,
            },
            DATA_PROPERTY_ID: {
                id: 'propertyID',
                optionSource: OPTION_SOURCE.API_PROPERTY,
                type: TYPES.TYPEAHEAD,
            },
            DATA_PROPERTY_VALUE: {
                id: 'propertyValue',
                type: TYPES.TEXT,
            },
            DEEP_LINK_PAGE: {
                id: 'page',
                optionNameTranslationPrefix: 'deepLinks.page.',
                optionSource: OPTION_SOURCE.LOCAL_DEEP_LINK_PAGE,
                type: TYPES.MULTI_SELECT,
            },
            DEEP_LINK_TARGET_TYPE: {
                id: 'targetType',
                optionNameTranslationPrefix: 'deepLinks.targetType.',
                optionSource: OPTION_SOURCE.LOCAL_DEEP_LINK_TARGET_TYPE,
                type: TYPES.MULTI_SELECT,
            },
            CONTAINER_DEPENDENCY_LINKING_DEPENDENCY_TYPE: {
                id: 'dependencyType',
                optionNameTranslationPrefix: 'containerDpLinks.dependencyType.',
                optionSource: OPTION_SOURCE.LOCAL_CONTAINER_DEPENDENCY_LINK_DEPENDENCY_TYPE,
                type: TYPES.MULTI_SELECT,
            },
            DEPENDENCY_LINK_SOURCE_ID: {
                id: 'sourceId',
                type: TYPES.NUMBER
            },
            EMAIL_NOTIFICATION_CONTENT: {
                id: 'notificationTemplateType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.RADIO,
            },
            ENABLED: {
                hasEmptyOption: true,
                id: 'enabled',
                options: [
                    { name: 'Enabled', value: true },
                    { name: 'Disabled', value: false },
                ],
                type: TYPES.SELECT,
            },
            EVENT_TYPE: {
                id: 'eventType',
                optionSource: OPTION_SOURCE.API_EVENT_TYPE,
                type: TYPES.SELECT,
            },
            EVENT_SOURCE: {
                id: 'eventSource',
                optionSource: OPTION_SOURCE.API_EVENT_SOURCE,
                type: TYPES.SELECT,
            },
            EXPIRATION_DATE_END: {
                id: 'endExpirationDate',
                type: TYPES.DATE,
            },
            EXPIRATION_DATE_START: {
                id: 'startExpirationDate',
                type: TYPES.DATE,
            },
            EXPRESSION: {
                id: 'expression',
                type: TYPES.TEXT,
            },
            EXPRESSION_ID: {
                id: 'evaluationExpressionId',
                type: TYPES.NUMBER,
            },
            EXPRESSION_NAME: {
                id: 'expressionName',
                type: TYPES.TEXT,
            },
            EXPRESSIONS_HIDDEN: {
                id: 'hiddenExpressions',
                type: TYPES.EXPAND_LINK,
            },
            EXTERNAL_ID: {
                id: 'externalId',
                type: TYPES.TEXT,
            },
            FAILED_PAGE_ONLY: {
                id: 'failedPagesOnly',
                type: TYPES.BOOLEAN,
            },
            FORM_ID: {
                id: 'formId',
                optionSource: OPTION_SOURCE.API_FORM,
                type: TYPES.TYPEAHEAD,
            },
            HTTP_METHOD: {
                id: 'httpMethod',
                options: createOptionsFromArray([
                    'POST',
                    'GET',
                    'PUT',
                    'DELETE',
                ]),
                type: TYPES.SELECT,
            },
            LOGGING_LEVELS: {
                id: 'loggingLevels',
                options: createOptionsFromArray([
                    'TRACE',
                    'DEBUG',
                    'INFO',
                    'WARN',
                    'ERROR',
                    'FATAL',
                    'FAILED',
                ]),
                type: TYPES.SELECT,
            },
            INTERNAL_ID: {
                id: 'internalId',
                type: TYPES.NUMBER,
            },
            INVITE_CODE: {
                id: 'inviteCode',
                type: TYPES.TEXT,
            },
            ID: {
                id: 'id',
                type: TYPES.NUMBER,
            },
            PAGE_NUMBER: {
                id: 'pageNumber',
                type: TYPES.NUMBER,
            },
            PERSONALIZED_FORM_ACTION_TYPE: {
                id: 'personalizedFormActionType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            PROGRAM: {
                id: 'programId',
                optionSource: OPTION_SOURCE.API_PROGRAM,
                type: TYPES.SELECT,
            },
            REQUEST_TIMESTAMP_MAX: {
                id: 'maxTime',
                type: TYPES.DATE,
            },
            REQUEST_TIMESTAMP_MIN: {
                id: 'minTime',
                type: TYPES.DATE,
            },
            RESPONSE_CODE: {
                id: 'responseCode',
                type: TYPES.NUMBER,
            },
            SCHEDULE_NAME: {
                id: 'scheduleName',
                type: TYPES.TEXT,
            },
            SERVICE: {
                id: 'service',
                optionSource: OPTION_SOURCE.API_SERVICE_TYPE,
                type: TYPES.SELECT,
            },
            STRING_FEATURE: {
                id: 'stringFeature',
                optionSource: OPTION_SOURCE.LOCAL_STRING_FEATURE,
                type: TYPES.MULTI_SELECT,
            },
            STRING_KEY: {
                id: 'stringKey',
                type: TYPES.TEXT,
            },
            SUBJECT: {
                id: 'subject',
                type: TYPES.TEXT,
            },
            SEARCH: {
                id: 'search',
                type: TYPES.TEXT,
            },
            TAG: {
                id: 'tagID',
                optionSource: OPTION_SOURCE.API_TAG,
                type: TYPES.SELECT,
            },
            TRIGGER_FORM_ID: {
                id: 'triggerFormId',
                optionSource: OPTION_SOURCE.API_FORM,
                type: TYPES.TYPEAHEAD,
            },
            TRIGGER_ON_TYPE: {
                id: 'formEntryStatusType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            TRIGGER_PROPERTY: {
                id: 'triggerPropertyId',
                optionSource: OPTION_SOURCE.API_PROPERTY,
                type: TYPES.TYPEAHEAD,
            },
            TRIGGER_TYPE: {
                id: 'triggerType',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            USAGE_TYPE: {
                hasEmptyOption: true,
                id: 'usageType',
                options: createOptionsFromArray([
                    'REGISTRATION',
                    'PROGRAM_SUBSCRIPTION',
                ]),
                type: TYPES.SELECT,
            },
            USER_ACCOUNT_FIELD: {
                id: 'accountInformationField',
                optionSource: OPTION_SOURCE.LOCAL_RULE,
                type: TYPES.SELECT,
            },
            USER_EVENT_ACTION_TYPE: {
                id: 'eventTypes',
                optionSource: OPTION_SOURCE.API_USER_EVENT_ACTION_TYPE,
                type: TYPES.MULTI_SELECT,
            },
            USER_EVENT_TIMESTAMP_END: {
                id: 'endDate',
                type: TYPES.DATE,
            },
            USER_EVENT_TIMESTAMP_START: {
                id: 'startDate',
                type: TYPES.DATE,
            },
        };

        /*--------------------------------------------------
         *  SET FIELDS THAT REFERENCE OTHER FIELDS HERE
         *--------------------------------------------------*/
        FIELDS.REQUEST_TIMESTAMP = {
            end: FIELDS.REQUEST_TIMESTAMP_MAX,
            id: 'requestTimestamp',
            start: FIELDS.REQUEST_TIMESTAMP_MIN,
            type: TYPES.DATE_RANGE,
        };
        FIELDS.USER_EVENT_TIMESTAMP = {
            end: FIELDS.USER_EVENT_TIMESTAMP_END,
            id: 'userEventTimestamp',
            start: FIELDS.USER_EVENT_TIMESTAMP_START,
            type: TYPES.DATE_RANGE,
        };
        FIELDS.EXPIRATION_DATE = {
            end: FIELDS.EXPIRATION_DATE_END,
            id: 'expirationDate',
            start: FIELDS.EXPIRATION_DATE_START,
            type: TYPES.DATE_RANGE,
        };

        /*--------------------------------------------------
         *  SET SUBFIELDS HERE
         *--------------------------------------------------*/
        // notificationActionLinkType
        FIELDS.ACTION_NOTIFICATION_LINK_TYPE.subfields = {
            FORM: [FIELDS.ACTION_FORM_ID],
        };

        // actionType
        FIELDS.ACTION_TYPE.subfields = {
            BANNER_NOTIFICATION: [FIELDS.BANNER_TYPE, FIELDS.ACTION_TITLE, FIELDS.ACTION_MESSAGE],
            DISMISS_BANNER: [FIELDS.BANNER_TYPE_NO_SUBFIELDS],
            EMAIL_OR_SMS_NOTIFICATION: [FIELDS.EMAIL_NOTIFICATION_CONTENT, FIELDS.SUBJECT],
            PERSONALIZED_FORM: [FIELDS.PERSONALIZED_FORM_ACTION_TYPE, FIELDS.ACTION_FORM_ID],
            PROFILE_UPDATE: [FIELDS.ACTION_PROPERTY, FIELDS.ACTION_PROPERTY_VALUE],
            PUSH_NOTIFICATION: [FIELDS.ACTION_NOTIFICATION_LINK_TYPE],
            TRIGGER_RULE: [FIELDS.ACTION_BUSINESS_RULE],
            USER_ACCOUNT_UPDATE: [FIELDS.USER_ACCOUNT_FIELD],
        };
        FIELDS.ACTION_TYPE.subfields[ANY_SELECTION] = [FIELDS.ACTION_NAME];

        // bannerType
        FIELDS.BANNER_TYPE.subfields = {
            MANUAL_SCHEDULING_FORM: [FIELDS.ACTION_FORM_ID],
            OPEN_FORM: [FIELDS.ACTION_FORM_ID],
        };

        FIELDS.DEEP_LINK_TARGET_TYPE.subfields = {
            FORM: [FIELDS.FORM_ID],
        };

        // expression fields
        FIELDS.EXPRESSIONS_HIDDEN.subfields = {
            true: [FIELDS.EXPRESSION, FIELDS.EXPRESSION_NAME],
        };

        // triggerType
        FIELDS.TRIGGER_TYPE.subfields = {
            LOG_ENTRY: [
                FIELDS.TRIGGER_FORM_ID,
                FIELDS.TRIGGER_ON_TYPE,
            ],
            SCHEDULE: [FIELDS.CRON],
            USER_PROFILE_VALUE_CHANGE: [FIELDS.TRIGGER_PROPERTY],
        };

        // usage type
        FIELDS.USAGE_TYPE.subfields = {
            PROGRAM_SUBSCRIPTION: [FIELDS.PROGRAM],
        };

        return {
            ANY_SELECTION,
            EMPTY_OPTION_VALUE,
            FIELDS,
            OPTION_SOURCE,
            TYPES,
        };
    }()));
