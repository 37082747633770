(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config summary information
     **/
    .directive('formDisplayConfigSummary', function (FormUtilService, FormConstants, vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/displayConfigSummary.html',
            scope: {
                selector: '='
            },
            link: function ($scope) {
                $scope.ec = FormUtilService.elementCompare;
                $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                $scope.colorUtil = FormUtilService.colorUtil;
                $scope.constants = FormConstants;

            }
        }
    });

})();
