/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 * Created by pankaja
 */
(function () {
    /**
     * this controller require an input of allTags
     */
    angular.module('acadiamasterApp').controller('EditCodesController',
        function ($scope, InviteCodesService, ProgramSearch, allTags, entity, PropertyCacheService, AlertService, LocaleConstantsService, FormConstants, localWeb) {
            let vm = this;

            vm.addNewPropertyValue = function () {
                if (!vm.data.metaData) {
                    vm.data.metaData = {
                        profilePropertyValues : [] };
                }
                vm.data.metaData.profilePropertyValues.push({
                    property      : null,
                    propertyValue : null,
                });
            };

            vm.removePropertyValue = function (index) {
                vm.data.metaData.profilePropertyValues.splice(index, 1);
            };

            vm.addTag = function ($item) {
                addTag(vm, $item);
            };

            vm.removeTag = function (index) {
                vm.data.inviteCodeTagMappings.splice(index, 1);
            };

            vm.updateCode = updateCode;
            vm.loadProgramCodes = loadProgramCodes;
            vm.getPropertyById = getPropertyById;

            vm.createAndAddNewTag = function () {
                createAndAddNewTag(vm);
            };

            vm.lcs = LocaleConstantsService;

            $scope.constants = FormConstants;
            // getting tags from injection
            vm.allTags = allTags;
            vm.entity = entity;
            vm.extensionAllowed = '.png';
            vm.allProperties = null;
            vm.data = {};
            vm.isReady = {
                allReady : function () {
                    return this.programCodeLoaded && this.propertyLoaded;
                },
                programCodeLoaded : false,
                propertyLoaded    : false,
            };

            vm.availableUsageTypes = {
                PROGRAM_SUBSCRIPTION : 'PROGRAM_SUBSCRIPTION',
                REGISTRATION         : 'REGISTRATION',
            };

            if (entity) {
                vm.data = entity;

                vm.data.expirationDate = new Date(vm.data.expirationDate);
                vm.data.file = getImageUrl(entity.logoId);
                vm.data.fileUrl = getImageUrl(entity.logoId);
                if (entity.localizationMap && entity.localizationMap.es) {
                    vm.data.localizationMap.es.fileName = entity.localizationMap.es.logoName;
                    vm.data.localizationMap.es.file = getImageUrl(entity.localizationMap.es.logoId);
                    vm.data.localizationMap.es.fileUrl = getImageUrl(entity.localizationMap.es.logoId);
                }
            }

            loadProgramCodes();
            loadProperties();
            vm.currentDate = new Date();

            /* ---------------------------------------------------------
             private functions
             * --------------------------------------------------------- */
            function getImageUrl (logoId) {
                if (logoId != null) {
                    return localWeb.createUrl('/api/file?isThumbnail=true&res=XHDPI&fileName=') + logoId;
                }
                return null;
            }

            function validateTag (vm, tag) {
                let tagExists = false;

                vm.data.inviteCodeTagMappings.forEach(t => {
                    if (t.tag.name === tag.name) {
                        tagExists = true;
                        return tagExists;
                    }
                    return undefined;
                });
                return tagExists;
            }

            /*
             * add a tag from lookahead
             * @param vm - controller object
             * @param item - new tag to be added
             */
            function addTag (vm, item) {
                if (validateTag(vm, item) === false) {
                    vm.data.inviteCodeTagMappings.push({ tag : item });
                }
                vm.data.currentTag = null;
            }

            function createAndAddNewTag (vm) {
                let newTag = {
                    tag : {
                        id   : null,
                        name : vm.data.currentTag,
                    } };

                if (validateTag(vm, newTag) === false) {
                    vm.data.inviteCodeTagMappings.push(createNewTag(vm.data.currentTag));
                }

                vm.data.currentTag = null;
            }

            function createNewTag (tagName) {
                return {
                    tag : {
                        id   : null,
                        name : tagName,
                    },
                };
            }

            function updateMetaData (requestData) {
                let hasMetaData = false;

                let ppvs = requestData.metaData && requestData.metaData.profilePropertyValues;

                ppvs = _.filter(ppvs, ppv => {
                    return ppv.property != null && ppv.property.id != null;
                });

                _.forEach(ppvs, ppv => {
                    hasMetaData = true;
                    ppv.propertyId = ppv.property.id;
                });

                if (!hasMetaData) {
                    requestData.metaData = null;
                }
            }

            function getPropertyById (propertyId, profilePropertyValue) {
                for (let i = 0; i < vm.allProperties.length; i++) {
                    if (vm.allProperties[i].id == propertyId) {
                        profilePropertyValue.property = vm.allProperties[i];
                        return true;
                    }
                }
                return undefined;
            }

            function updateCode () {
                let requestData = angular.copy(vm.data);
                if (requestData.usageType == vm.availableUsageTypes.REGISTRATION) {
                    requestData.programCode = null;
                    requestData.programId = null;
                }
                if (requestData.expirationDate != null) {
                    requestData.expirationDate = requestData.expirationDate.getTime();
                }
                let files = [];
                // if we delete existing English file while update
                if (vm.data.file == null) {
                    requestData.logoName = null;
                    requestData.logoId = null;
                }
                // if we new English file while update
                else if (vm.data.file && vm.data.file.name) {
                    files.push(vm.data.file);
                    requestData.logoName = vm.data.file.name;
                }
                // if we delete existing Spanish file while update
                if (vm.data.localizationMap && vm.data.localizationMap.es && vm.data.localizationMap.es.file == null || vm.data.file == null) {
                    requestData.localizationMap = {
                        es : {
                            logoId   : null,
                            logoName : null,
                        },
                    };
                }
                // if we new Spanish file while update
                if (vm.data.localizationMap && vm.data.localizationMap.es && vm.data.localizationMap.es.file && vm.data.localizationMap.es.file.name) {
                    files.push(vm.data.localizationMap.es.file);
                    requestData.localizationMap = {
                        es : {
                            logoName : null,
                        },
                    };
                    requestData.localizationMap.es.logoName = vm.data.localizationMap.es.file.name;
                }

                // update programId
                if (requestData.programCode !== null) {
                    requestData.programId = vm.allPrograms.find(program => {
                        return program.code === requestData.programCode;
                    }).id;
                }

                updateMetaData(requestData);

                return InviteCodesService.updateCode(requestData, files).then(list => {
                    $scope.closeThisDialog();
                }, error => {
                    console.error('Error updating invite code', error);
                });
            }

            /*
             load all Programs
             */
            function loadProgramCodes () {
                ProgramSearch.query({
                    page: 0,
                    size: 1000,
                })
                    .then(response => {
                        vm.allPrograms = response.data;
                        vm.isReady.programCodeLoaded = true;

                        console.log('query result : ', response, vm.allPrograms);
                    })
                    .catch(error => {
                        AlertService.error(`ERROR loading program codes: ${ error}`);
                    });
            }

            /*
             load all Properties
             */
            function loadProperties () {
                // this is not a force reload of all the properties, so if properties have changed
                let promise = PropertyCacheService.loadProperties(false);

                promise.then(properties => {
                    vm.allProperties = properties;
                    vm.isReady.propertyLoaded = true;
                }).catch(error => {
                    AlertService.error(`failed to load properties list : ${ error}`);
                });
            }
        });
}());
