(function () {
    'use strict';
    var app = angular.module('acadiamasterApp');

    /**
     * utility service for form mode sorting that's used exclusively for admin portal
     */
    app.factory('FormModeSortUtil', function (AlertService) {
        return {
            sortPages : sortPages
        };


        /**
         * sort all the pages in a form mode using a modified depth first search
         * this sorting will modify the form mode object directly
         * @param formMode
         */
        function sortPages(formMode) {
            var startTime = performance.now();
            // create some shallow copy
            var sortedNodes = [];

            // map of nodes that has already been visited
            var visitedNodeMap = {};

            // get the starting node
            var startingNode = findStartingNode(formMode);

            sortPageHelperNoRecursion(startingNode, sortedNodes, visitedNodeMap);

            // all the nodes are sorted by this time
            // replace the nodes with sorted nodes in place, we do this so ui will pick up the
            // change immediately if there are reference used in any watches

            // sometimes the form is mis-configured with more than one starting node, then only the first starting
            // node is sorted, rest will remain in the order it was
            var otherNodesNotTravelled = findUnTravelledNodes(formMode.navigationNodes, visitedNodeMap);

            formMode.navigationNodes.splice(0, formMode.navigationNodes.length);
            _.forEach(sortedNodes, function(n) {
                formMode.navigationNodes.push(n);
            });
            _.forEach(otherNodesNotTravelled, function(n) {
                formMode.navigationNodes.push(n);
            });

            // replace pages with sorted pages in place
            formMode.pages.splice(0, formMode.pages.length);
            _.forEach(sortedNodes, function(node) {
                if (node.page != null) {
                    formMode.pages.push(node.page);
                }
            });

            _.forEach(otherNodesNotTravelled, function(node) {
                if (node.page != null) {
                    formMode.pages.push(node.page);
                }
            });

            var endTime = performance.now();
            AlertService.success(sortedNodes.length + " nodes sorted in " + (endTime - startTime).toFixed(3) + " ms ");
        }

        /* --------------------------------------------
            private functions
         * -------------------------------------------- */

        /**
         * find nodes that hasn't been travelled
         * @param allNodes - all the nodes
         * @param visitedNodeMap - a map nodes already visited
         * @return {array} -- a list of nodes that's in all nodes but not in travelled nodes
         */
        function findUnTravelledNodes(allNodes, visitedNodeMap) {
            var unvisited = [];
            _.forEach(allNodes, function (n) {
                if (visitedNodeMap[n.localId] == null) {
                    unvisited.push(n);
                }
            });

            return unvisited;
        }


        /**
         * find starting node in a form mode
         * @param formMode - form mode
         */
        function findStartingNode(formMode) {
            return _.find(formMode.navigationNodes, function(n) {
                return n.isStartNode();
            });
        }

        /**
         * check if we should visit the current node now
         * @param currentNode - current node
         * @param visitedNodeMap - map of visited node
         * @return {boolean} - true if all the parents of current node has been visited, false otherwises
         */
        function shouldVisitTheNode(currentNode, visitedNodeMap) {
            if (currentNode == null) {
                return false;
            }
            var unVisitedParent = _.find(currentNode._edgesTo, function(e) {
                var parentId = e.nodeFrom.localId;
                return visitedNodeMap[parentId] == null;
            });

            // return true if we can't find any parent that hasn't been visited
            return unVisitedParent == null || unVisitedParent.length == 0;
        }


        /**
         * recursive function for sorting pages using depth first but without recursion
         * @param currentNode - current node
         * @param sortedNodes {Array} - an array of already sorted nodes,
         * @param visitedNodeMap {*} - an map for fast lookup of visited nodes, the key is the local id
         */
        function sortPageHelperNoRecursion(currentNode, sortedNodes, visitedNodeMap) {
            var nodesToBeVisited = [currentNode];
            while ( nodesToBeVisited.length>0) {
                processFirstNode(nodesToBeVisited, sortedNodes, visitedNodeMap);
            }
        }

        /**
         * process the first node in the nodes to be visited and add all children into the nodes to be visited list
         * @param nodesToBeVisited - a list of nodes to be visited
         * @param sortedNodes {Array} - an array of already sorted nodes,
         * @param visitedNodeMap {*} - an map for fast lookup of visited nodes, the key is the local id
         */
        function processFirstNode(nodesToBeVisited, sortedNodes, visitedNodeMap) {
            var currentNode = nodesToBeVisited.shift();

            var nodeId = currentNode.localId;

            var nodeVisited = visitedNodeMap[nodeId] != null;

            // not already visited
            if (!nodeVisited && shouldVisitTheNode(currentNode, visitedNodeMap)) {
                sortedNodes.push(currentNode);
                visitedNodeMap[nodeId] = nodeId;

                _.forEach(currentNode._edgesFrom, function(e) {
                    nodesToBeVisited.unshift(e.nodeTo);
                });
            }
        }

    });
})();
