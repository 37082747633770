(function () {
    'use strict';

    /**
     * directive for configuration of a list of values configuration
     */
    angular.module('acadiamasterApp')
        .directive('valueComparisonMultipleValueConfig', function (ValueType, ngDialog, $timeout,
            ValueComparisonService, ValueTypeUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/common/directive/valueComparison/valueComparisonMultipleValue.html',
                scope: {
                    options: '=',  // a value comparison option object
                    data: '=', // data and flags are passed in from parent value comparison directive
                    flags: '='
                },
                link: function ($scope) {
                    $scope.ValueType = ValueType;

                    // pagination
                    $scope.pagination = {
                        // the number of items that must be on a page before the bottom pagination buttons appear
                        bottomControlLimit: 8,
                        currentPage: 0,
                        offset: 0,
                        pageCount: 1,
                        pageSize: 20,
                    };

                    function goToPage(page) {
                        $scope.pagination.currentPage = page;
                        $scope.pagination.offset = page * $scope.pagination.pageSize;
                    }

                    function goToFirstPage() {
                        goToPage(0);
                    }

                    function goToPrevPage() {
                        goToPage($scope.pagination.currentPage - 1);
                    }

                    function goToNextPage() {
                        goToPage($scope.pagination.currentPage + 1);
                    }

                    function goToLastPage() {
                        goToPage($scope.pagination.pageCount - 1);
                    }

                    function updatePageCount() {
                        $scope.pagination.pageCount = Math.max(
                            Math.ceil($scope.data.values.length / $scope.pagination.pageSize),
                            1
                        );
                        if ($scope.pagination.currentPage >= $scope.pagination.pageCount) {
                            goToLastPage();
                        }
                    }

                    $scope.goToFirstPage = goToFirstPage;
                    $scope.goToPrevPage = goToPrevPage;
                    $scope.goToNextPage = goToNextPage;
                    $scope.goToLastPage = goToLastPage;
                    // end pagination

                    $scope.noErrorInField = ValueComparisonService.noErrorInField;
                    $scope.shouldShowErrorMessageInField = ValueComparisonService.shouldShowErrorMessageInField;

                    $scope.getValueType = function () {
                        return $scope.options.getValueType();
                    };

                    $scope.isValueTypeNumber = function () {
                        var valueType = $scope.getValueType();
                        return valueType === ValueType.NUMBER || valueType === ValueType.DATETIME;
                    };

                    $scope.deleteValueAtIndex = function (index) {
                        if ($scope.data.values && $scope.data.values.length > index) {
                            $scope.data.values.splice(index, 1);
                            updatePageCount();
                        }
                    };

                    $scope.addNewValue = function () {
                        if (!$scope.data.values) {
                            $scope.data.values = [];
                        }

                        $scope.data.values.push(null);
                        updatePageCount();
                        goToLastPage();
                    };

                    $scope.removeAllValues = function () {
                        $scope.data.values = [];
                        updatePageCount();
                        goToFirstPage();
                    };

                    $scope.getValueCount = function () {
                        var values = $scope.data.values;
                        return !values ? 0 : values.length;
                    };

                    $scope.getIndex = function (index) {
                        return $scope.pagination.currentPage * $scope.pagination.pageSize + index;
                    };

                    $scope.startAdvancedEdit = function () {
                        var template = '<advanced-edit-list-of-value data="data" close-function="closeFunction"></advanced-edit-list-of-value>';

                        var _scope = $scope;

                        var data = {
                            allValues: combineValues($scope.data.values)
                        };

                        // open modal window and pass in all the scope variables
                        var dialog = ngDialog.open({
                            className: 'ngdialog-theme-plain custom-width-large',
                            closeByDocument: false,
                            closeByEscape: false,
                            controller: ['$scope', function ($scope) {
                                $scope.data = data;
                                $scope.closeFunction = closeDialog;
                            }],
                            height: '90%',
                            plain: true,
                            showClose: false,
                            template: template,
                        });

                        function closeDialog() {
                            // setting value back to parent directive after it is closed
                            _scope.data.values = separateValues(data.allValues, _scope.getValueType());
                            updatePageCount();
                            dialog.close();
                        }
                    }

                    function init() {
                        updatePageCount();
                    }

                    init();
                }
            };

            // --------------------------- private functions

            function combineValues(values) {
                if (!values || values.length === 0) {
                    return '';
                }

                return values.join('\n');
            }

            function separateValues(combinedValue, valueType) {
                // split on any new line and ,
                var stringValueListRaw = combinedValue.split(/[,\n]+/);

                var stringValueList = [];

                // now trim it to remove space from start and end of each string value (space in the middle should not removed)
                _.forEach(stringValueListRaw, function (s) {
                    var v = s;
                    if (v != null) {
                        v = v.trim();

                        // add value into the string list if it's not empty and not equal to null
                        if (v.length > 0) {
                            stringValueList.push(v);
                        }
                    }
                });

                return ValueTypeUtil.stringValueListConvertedToType(stringValueList, valueType)
            }

        });
})();
