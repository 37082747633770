(function () {
    'user strict';

    angular.module('acadiamasterApp')
        .controller('RoleController', function ($scope, RoleService, AlertService) {

            $scope.roles = [];

            loadRoles();

            function loadRoles() {
                RoleService.getRoles()
                    .then(function (data) {
                        $scope.roles = data.roles;
                    })
                    .catch(function (error) {
                        AlertService.error('ERROR loading roles: ' + error);
                    });
            }
        });
})();