/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable vars-on-top */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */

(function () {
    // eslint-disable-next-line angular/controller-name
    angular.module('acadiamasterApp')
        .directive('formListWithSearch', () => {
            return {
                controller : 'formListWithFiltersController',
                restrict   : 'E',
                scope      : {

                    // callback on form selection.
                    defaultFormCategory : '=',

                    isReadOnly      : '=',
                    // Here loadAll is an empty object which will send from parent.
                    // In this directive we bind a loadAll() callback with loadAll method.
                    // So we can use directive's loadAll function from its parent.
                    loadAllCallback : '=loadAll',
                    selectForm      : '&',
                },
                templateUrl : 'admin-templates/site/forms/form/formListWithSearch.html',
            };
        })
        .controller('formListWithFiltersController', ($scope, ParseLinks, FormSearch, ProgramSearch, CONFIG, FormUtilService,
            $rootScope, FormVersion, FormPreviewService, ngDialog, DependencyLinkService, $location, FormVersionTwoUtilityService, localStorageService, Form, FormVerification, $state) => {
            $scope.CONFIG = CONFIG;

            $scope.forms = [];

            $scope.isFillter = false;

            $scope.programChecked = '';
            $scope.searchProgram = '';
            $scope.formCategory = {
                ALL                : 'ALL',
                CONSENT_FORM       : 'CONSENT_FORM',
                DATA_FORM          : 'DATA_FORM',
                PROFILE_FORM       : 'PROFILE_FORM',
                SNAP_QUESTION_FORM : 'SNAP_QUESTION_FORM',
            };
            $scope.searchData = {
                ascending         : false,
                orderByChoices    : [ 'updatedOn', 'id', 'name', 'displayName', 'programCode' ],
                orderBySelected   : 'updatedOn',
                pageSizeChoices   : [ 20, 50, 100 ],
                pageSizeSelected  : 20,
                searchResultCount : 'loading ...',
                searchString      : '',
            };

            $scope.showOnlySystemForms = false;
            $scope.showOnlyVerifiedForms = false;

            $scope.changedFormCategory = $scope.defaultFormCategory === null ? $scope.formCategory.DATA_FORM
                : $scope.defaultFormCategory;
            $scope.previousFormCategory = $scope.defaultFormCategory === null ? $scope.formCategory.DATA_FORM
                : $scope.defaultFormCategory;
            $scope.page = 0; $scope.categoryChoosen = 'Filter by Category'; $scope.typeChoosen = 'Filter by Type'; $scope.dayChoosen = 'Filter by Days';
            $scope.getAllProgram = getAllProgram; $scope.getForms = getForms; $scope.sortByProgramItems = []; $scope.allProgramSortBy = false; $scope.limitTo = 16; $scope.errMsg = false;
            $scope.filters = { sortByCategory : '', sortByDays : '', sortByProgram : '' };
            $scope.formCategory = { ALL : 'ALL', CONSENT_FORM : 'CONSENT_FORM', DATA_FORM : 'DATA_FORM', PROFILE_FORM : 'PROFILE_FORM', SNAP_QUESTION_FORM : 'SNAP_QUESTION_FORM' };
            $scope.sortByDayItems = [ { name : 'Last Day', value : 1 }, { name : 'Last 2 Days', value : 2 }, { name : 'Last 7 Days', value : 7 }, { name : 'Last 15 Days', value : 15 }, { name : 'Last 30 Days', value : 30 } ];
            $scope.sortByCategoryItems = [ { name : 'All Form', value : 'ALL' }, { name : 'Data Form', value : 'DATA_FORM' }, { name : 'Consent Form', value : 'CONSENT_FORM' }, { name : 'Snap Question', value : 'SNAP_QUESTION_FORM' }, { name : 'Profile Form', value : 'PROFILE_FORM' } ];
            $scope.filterData = { ascending : false, jumpTo : null, orderByChoices : [ 'updatedOn', 'id', 'name', 'displayName', 'programCode' ], orderBySelected : 'updatedOn', pageSizeChoices : [ 20, 50, 100 ], pageSizeSelected : 20, searchResultCount : 'loading ...', searchString : '' };
            $scope.sort = { activeColumn : 'updatedOn', displayName : { asc : false }, id : { asc : true }, name : { asc : false }, programCode : { asc : false }, updatedOn : { asc : false } };

            function loadForms () {
                FormSearch.query({
                    ascending    : $scope.searchData.ascending,
                    formCategory : $scope.changedFormCategory === $scope.formCategory.ALL ? null
                        : $scope.changedFormCategory,
                    orderBy      : $scope.searchData.orderBySelected,
                    page         : $scope.page,
                    searchString : $scope.searchData.searchString || null,
                    size         : $scope.searchData.pageSizeSelected,
                }).then(response => {
                    $scope.links = ParseLinks.parse(response.headers('link'));
                    $scope.forms = response.data;
                    $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                });
            }
            $scope.editForm = function (form) {
                let id = form.id;
                let latestFormVersionId = form.latestFormVersion.id;
                let formDetail = Form.get({
                    formVersionId : latestFormVersionId,
                    id            : id,
                    projection : Form.projections.SPECIFIC_VERSION,
                });
                formDetail.$promise.then(data => {
                    let formObject
                        = new FormUtilService.FormModelServiceForm.FormModel();
                    formObject.fromDto(data);
                    $scope.selectedForm = formObject;
                    if($scope.selectedForm.formVersions && $scope.selectedForm.formVersions[0]?.isDeleted){
                        $scope.selectFormWrapper(form, true);
                    }else{
                        $state.go('formVersionTwo.edit', {'id':form.id, 'versionId': form.latestFormVersion.id, 'form': formDetail});
                    }

                });
            };
            $scope.loadAll = function () {
                $scope.page = 0;
                $location.absUrl().includes('formsVersionTwo') ? getForms() : loadForms();
            };

            $scope.loadPage = function (page, pageSize, jumpNumber) {
                $scope.page = page;
                if (pageSize) {
                    $scope.filterData.pageSizeSelected = pageSize;
                }
                if (jumpNumber >= 0) {
                    $scope.page = jumpNumber;
                }
                $location.absUrl().includes('formsVersionTwo') ? getForms() : loadForms();
            };

            // get forms after import
            $rootScope.$on('getForms', (event, data) => {
                getForms();
            });

            $scope.filterData.ascending = $scope.sort.updatedOn.asc;
            $scope.filterData.orderBySelected = 'updatedOn';
            $scope.loadAll();

            if ($scope.loadAllCallback) {
                $scope.loadAllCallback = $scope.loadAll;
            }

            $scope.viewMore = function () {
                $scope.limitTo = $scope.sortByProgramItems.length; $scope.allProgramSortBy = true; $scope.restoreProgramChecked();
            };
            $scope.hidden = function () {
                $scope.limitTo = 16; $scope.allProgramSortBy = false; $scope.restoreProgramChecked();
            };
            $scope.prevent = function (event) {
                if (!event) {
                    return;
                }
                event.stopPropagation();
            };
            $scope.toggleDropdown = function (event, element) {
                FormVersionTwoUtilityService.toggleDropdown(event, element);
            };

            $scope.selectOnlyThis = function (name, id, type, value, event, text) {
                $scope.programChecked = id;

                // check for only system forms
                $scope.showOnlySystemForms = name === 'systemValue';

                // check for only verified system forms
                $scope.showOnlyVerifiedForms = name === 'verifiedSystemValue';

                switch (name) {
                case 'categoryValue':
                    $scope.categoryChoosen = text && event.target.checked ? text : 'Filter by Category';
                    break;
                case 'programValue':
                    $scope.typeChoosen = text && event.target.checked ? text : 'Filter by Type';
                    break;
                case 'systemValue':
                    $scope.typeChoosen = text && event.target.checked ? text : 'Filter by Type';
                    break;
                case 'verifiedSystemValue':
                    $scope.typeChoosen = text && event.target.checked ? text : 'Filter by Type';
                    break;
                case 'dayValue':
                    $scope.dayChoosen = text && event.target.checked ? text : 'Filter by Days';
                    break;
                }

                var currentElement = document.getElementById(id);
                if (!currentElement) {
                    return;
                }

                // checked current item
                if (event && event.target.checked !== undefined) {
                    if (event.target.checked) {
                        currentElement.checked = true;
                        $scope.filters[type] = value;
                    } else {
                        currentElement.checked = false;
                        $scope.filters[type] = null;
                    }
                } else {
                    // eslint-disable-next-line no-var, no-redeclare
                    var currentElement = document.getElementById(id);
                    if (currentElement.checked && currentElement.name !== 'programSystemValue') {
                        currentElement.checked = false;
                        $scope.filters[type] = null;
                    } else {
                        currentElement.checked = true;
                        $scope.categoryChoosen = name === 'categoryValue' ? text : $scope.categoryChoosen;
                        $scope.typeChoosen = name === 'programValue' || name === 'systemValue' || name === 'verifiedSystemValue' ? text : $scope.typeChoosen;
                        $scope.dayChoosen = name === 'dayValue' ? text : $scope.dayChoosen;
                        $scope.filters[type] = value;
                    }
                }

                // unchecked all
                let myCheckbox = document.getElementsByName(name);
                myCheckbox.forEach(element => {
                    if (element.id !== id) {
                        element.checked = false;
                    }
                });

                // reset search box
                $scope.page = 0;
                $scope.filterData.jumpTo = null;

                // reset button
                hightLightResetButton();

                // call api
                getForms();
            };

            $scope.getFormType = function (type) {
                switch (type) {
                case 'DATA_FORM':
                    return 'Data';
                case 'SNAP_QUESTION_FORM':
                    return 'Snap';
                case 'CONSENT_FORM':
                    return 'Consent';
                default:
                    return 'Profile';
                }
            };

            $scope.restoreProgramChecked = function () {
                // eslint-disable-next-line angular/timeout-service
                setTimeout(() => {
                    let programsCheckbox = document.getElementsByName('programSystemValue');
                    programsCheckbox.forEach(element => {
                        element.checked = false;
                    });
                    if ($scope.programChecked.trim().length > 0) {
                        let programChecked = document.querySelector(`#${$scope.programChecked}`);
                        if (programChecked) {
                            programChecked.checked = true;
                        }
                    }
                }, 0);
            };

            $scope.clearSearch = function () {
                $scope.filterData.searchString = '';
                document.querySelector('#search').focus();
            };

            function hightLightResetButton () {
                $scope.isFillter = Boolean($scope.filters.sortByCategory || $scope.filters.sortByDays || $scope.filters.sortByProgram || $scope.showOnlySystemForms || $scope.showOnlyVerifiedForms);
            }

            $scope.displayPrograms = function () {
                const programs = $scope.sortByProgramItems.filter(program => {
                    return program.text.toUpperCase().includes($scope.searchProgram.toUpperCase()) || program.programId == $scope.searchProgram;
                });
                return programs;
            };

            $scope.toggleActiveConfirm = function (formId) {
                let popup = document.getElementById(`active-confirm-${formId}`);
                if (popup.style.display === 'none' || popup.style.display === '') {
                    popup.style.display = 'block';
                } else {
                    popup.style.display = 'none';
                }
            };

            $scope.toggleVerifiedStatus = function (form) {
                FormVerification.toggleVerifiedStatus(form.id).then(response => {
                    form.verified = response.data.verified;
                    $scope.showOnlyVerifiedForms ? $scope.resetFillter() : '';
                });
            };

            $scope.activateFormVersion = function (latestFormVersion) {
                let formDetail = FormVersion.activate(latestFormVersion.formId, latestFormVersion.id);
                formDetail.then(response => {
                    $scope.toggleActiveConfirm(latestFormVersion.id);
                    if (response.data.id) {
                        let formActived = $scope.forms.find(form => form.latestFormVersion.formId === response.data.id);
                        formActived.isLatestVersionActive = true;
                    }
                });
            };

            $scope.changeSorting = function (column) {
                $scope.sort.activeColumn = column;
                $scope.sort[column].asc = !$scope.sort[column].asc;
                $scope.filterData.ascending = $scope.sort[column].asc;
                $scope.filterData.orderBySelected = column;
                getForms();
            };

            $scope.toggleActiveConfirm = function (formId) {
                let popup = document.getElementById(`active-confirm-${formId}`);
                if (popup.style.display === 'none' || popup.style.display === '') {
                    popup.style.display = 'block';
                } else {
                    popup.style.display = 'none';
                }
            };

            function getAllProgram () {
                ProgramSearch.query({
                    page : 0,
                    size : 1000,
                }).then(programs => {
                    let dataDisplay = programs.data.map((program, index) => {
                        let text = program.code;
                        if (program.code.length > 10) {
                            text = `${program.code.slice(0, 9) }...`;
                        }
                        return { code : program.code, idElement : `programItem${index}`, programId : program.id, text : text };
                    });
                    $scope.sortByProgramItems = dataDisplay;
                    $scope.errMsg = false;
                }).catch(error => {
                    $scope.errMsg = true;
                });
            }

            function getForms () {
                FormSearch.query({
                    ascending             : $scope.filterData.ascending, formCategory          : $scope.filters.sortByCategory === $scope.formCategory.ALL ? null : $scope.filters.sortByCategory, lastModifiedDaysAgo   : $scope.filters.sortByDays === '' ? null : $scope.filters.sortByDays,
                    orderBy               : $scope.filterData.orderBySelected, page                  : $scope.page,
                    programId             : $scope.filters.sortByProgram === '' ? null : $scope.filters.sortByProgram,
                    searchString          : $scope.filterData.searchString || null,
                    showOnlySystemForms   : $scope.showOnlySystemForms,
                    showOnlyVerifiedForms : $scope.showOnlyVerifiedForms,
                    size                  : $scope.filterData.pageSizeSelected,
                }).then(response => {
                    $scope.links = ParseLinks.parse(response.headers('link'));
                    response.data.forEach(form => {
                        let versionId = form.latestFormVersion.versionId;
                        let id = form.latestFormVersion.id;
                        // Display long text for version
                        if (versionId.length > 5) {
                            versionId = `${versionId.slice(0, 4) }...`;
                        }
                        if (id.length > 7) {
                            id = `${id.slice(0, 6) }...`;
                        }
                        form.versionStatus = { id : form.latestFormVersion.id, idDisplay : id, versionId : form.latestFormVersion.versionId, versionIdDisplay : versionId };
                        if (!form.programId) {
                            form.programCode = 'System Form';
                        }
                    });
                    $scope.forms = response.data;
                    $scope.filterData.searchResultCount = response.headers('X-Total-Count');
                });
            }
            getAllProgram();
            $scope.resetFillter = function () {
                $scope.categoryChoosen = 'Filter by Category'; $scope.typeChoosen = 'Filter by Type'; $scope.dayChoosen = 'Filter by Days';
                $scope.filters.sortByCategory = null;
                $scope.filters.sortByDays = null;
                $scope.filters.sortByProgram = null;
                $scope.programChecked = '';
                $scope.searchProgram = '';
                $scope.showOnlySystemForms = false;
                $scope.showOnlyVerifiedForms = false;
                let checkbox = document.querySelectorAll('input[type="checkbox"].checkbox-filter');
                // eslint-disable-next-line no-return-assign
                checkbox.forEach(cb => cb.checked = false);
                let radio = document.querySelectorAll('input[name="programSystemValue"]');
                // eslint-disable-next-line no-return-assign
                radio.forEach(cb => cb.checked = false);
                hightLightResetButton();
                getForms();
            };
            $scope.delete = function (form) {
                DependencyLinkService.getFormDependencyLink(form.id).then(
                    response => {
                        const dpLinkMap = DependencyLinkService.consolidateDPBySourceType(
                            response.data);
                        if (dpLinkMap.size > 0) {
                            // open dialog to show the list of dependency link table
                            ngDialog.open({
                                className    : 'ngdialog-theme-plain custom-width-medium',
                                controller   : 'DependencyLinkDialogController',
                                controllerAs : 'dpLink',
                                resolve      : {
                                    dpLinkMap : () => dpLinkMap,
                                    dpType    : () => `Form ID: ${form.id}`,
                                },
                                templateUrl : 'admin-templates/site/dependencyLink/dependencyLinkDialog.template.html',
                            });
                        } else {
                            ngDialog.openConfirm({
                                controller : 'FormDeleteController',
                                data       : form,
                                template   : 'admin-templates/site/forms/form/formDelete.html',
                            }).then(() => {
                                $scope.loadAll();
                            });
                        }
                    }).catch(error => console.error('Error getting form dependency link information', error));
            };

            $scope.formCategoryFilterChanged = function () {
                if ($scope.previousFormCategory != $scope.changedFormCategory) {
                    $scope.page = 0;
                    loadForms();
                }
                $scope.previousFormCategory = $scope.changedFormCategory;
            };

            $scope.preview = function (form, versionId) {
                FormPreviewService.previewTab(form.id, versionId);
            };

            $scope.clone = function (form, versionId) {
                ngDialog
                    .openConfirm({
                        className       : 'ngdialog-theme-center custom-width-small',
                        closeByDocument : false,
                        closeByEscape   : false,
                        controller      : 'FormCloneController',
                        data            : { form : form, versionId : versionId },
                        template        : 'admin-templates/site/forms/form/formClone.html',
                    })
                    .then(data => {
                        $scope.resetFillter();
                    });
            };

            $scope.download = function (form, versionId, formProjection) {
                FormUtilService.download(form, versionId, formProjection);
            };

            $scope.selectFormWrapper = function (form, isDeleted) {
                form.isLatestVersionDeleted = isDeleted;
                $scope.selectForm({ form : form  });
                localStorageService.set('deleteRestoreVersionAlert', null);
            };
        });
}());
