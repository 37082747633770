/**
 * Created by Pankaja on 12/5/2021
 *
 * directive for adding page type
 */

import template from './addPageFromList.html';

angular.module('acadiamasterApp').directive('addPageFromList', (ngDialog, $document, FormConstants) => ({
    link ($scope) {
        $scope.ITEM_TITLES = 'Add Special Page Type';
        $scope.constants = FormConstants;

        function hasDataSharingPage (mode) {
            let hasDataSharingPage = false;
            if (mode != null && mode.pages != null) {
                for (let i = 0; i < mode.pages.length; i++) {
                    if (mode.pages[i].formPageType === FormConstants.formPageDiffTypes.DATA_SHARING
                        || mode.pages[i].formPageType === FormConstants.formPageDiffTypes.STOP_DATA_SHARING) {
                        hasDataSharingPage = true;
                    }
                }
            }
            return hasDataSharingPage;
        }

        function hasAddressVerificationPage (mode) {
            let hasAddressVerificationPage = false;
            if (mode != null && mode.pages != null) {
                for (let i = 0; i < mode.pages.length; i++) {
                    if (mode.pages[i].formPageType === FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION) {
                        hasAddressVerificationPage = true;
                    }
                }
            }
            return hasAddressVerificationPage;
        }

        function hasDataCapturePage (mode) {
            let hasDataCapturePage = false;
            if (mode != null && mode.pages != null) {
                for (let i = 0; i < mode.pages.length; i++) {
                    if (mode.pages[i].formPageType === FormConstants.formPageDiffTypes.DATA_CAPTURE) {
                        hasDataCapturePage = true;
                    }
                }
            }
            return hasDataCapturePage;
        }

        $scope.canAddDataSharingPage = function () {
            return $scope.mode.nodeType === FormConstants.nodeType.MODE.EDIT
                && !hasDataSharingPage($scope.mode);
        };

        $scope.canAddStopDataSharingPage = function () {
            return $scope.mode.nodeType === FormConstants.nodeType.MODE.EDIT
                && $scope.mode.getRoot().category === FormConstants.formCategories.DATA_FORM
                && !hasDataSharingPage($scope.mode);
        };
        $scope.canAddAddressVerificationPage = function () {
            return $scope.mode.nodeType === FormConstants.nodeType.MODE.EDIT
                && !hasAddressVerificationPage($scope.mode);
        };
        $scope.canAddDataCapturePage = function () {
            return $scope.mode.nodeType.name === FormConstants.nodeType.MODE.EDIT.name
                && !hasDataCapturePage($scope.mode);
        };

        /*
         * method called when the plus button is clicked for adding a new container item
         * @param $event
         */
        $scope.onPopoverOpened = function ($event) {
            $event.stopPropagation();
            $scope.data.isPopupOpen = true;
        };
    },
    restrict : 'E',
    scope    : {
        currentTreeName : '=',
        mode            : '=',
        selector        : '=',
    },
    template : template,
}));
