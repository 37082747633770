/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigMain', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/ruleConfigMain.html',
                scope: {
                    element: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.editRule = null;
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/

        });

})();



