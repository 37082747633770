'use strict';

angular.module('acadiamasterApp').controller('PropertyPossibleValueDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'PropertyPossibleValue', 'PropertyPossibleValueGroup', 'User',
        function($scope, $stateParams, $uibModalInstance, entity, PropertyPossibleValue, PropertyPossibleValueGroup, User) {

        $scope.propertyPossibleValue = entity;
        $scope.propertypossiblevaluegroups = PropertyPossibleValueGroup.query();
        $scope.users = User.query();
        $scope.load = function(id) {
            PropertyPossibleValue.get({id : id}, function(result) {
                $scope.propertyPossibleValue = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:propertyPossibleValueUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.propertyPossibleValue.id != null) {
                PropertyPossibleValue.update($scope.propertyPossibleValue, onSaveFinished);
            } else {
                PropertyPossibleValue.save($scope.propertyPossibleValue, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
