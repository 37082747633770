/**
 * service for dealing with time, pure javascript functions that doesn't need any angular
 */

/**
 * getting duration as a number in milliseconds, startTime and endTime are in UTC timestamp
 * @param startTime - start time in UTC
 * @param endTime - end time in UTC
 * @param useCurrentAsEndTime - flag to indicate if we should use the current time as end time if end time is null
 * @return {number} duration in milliseconds, ie: if start and end time are 1 minute apart, then return 60000,
 *         if inputs are not valid, then return -1
 */
function getCurrentRunTimeInMillis(startTime, endTime, useCurrentAsEndTime) {
    const invalidDuration = -1;
    if (startTime == null) {
        return invalidDuration;
    }

    const actualEndTime = endTime == null && useCurrentAsEndTime ? new Date().getTime() : endTime;

    if (actualEndTime == null) {
        return invalidDuration;
    }

    return actualEndTime - startTime;
}


/**
 * getting duration as a string in minutes and seconds, startTime and endTime are in UTC timestamp
 * @param startTime - start time in UTC
 * @param endTime - end time in UTC
 * @param useCurrentAsEndTime - flag to indicate if we should use the current time as end time if end time is null
 * @return {String} duration in minutes and seconds, ie: 5 minutes & 10.2 seconds.  If startTime or endTime is null, then return "N/A"
 */
function getDurationAsMinutesAndSeconds(startTime, endTime, useCurrentAsEndTime) {
    const invalidDuration = 'N/A';

    const durationInMs = getCurrentRunTimeInMillis(startTime, endTime, useCurrentAsEndTime);

    if (durationInMs <= 0) {
        return invalidDuration;
    }

    return timeInMillisToHumanReadable(durationInMs);
}

/**
 * convert time in millis into more human readable format string
 * @param timeInMillis - time in millisecond, ie: 15200 for 15.2 seconds
 * @return {String} human readable duration string, if timeInMillis is null, then return "N/A"
 */
function timeInMillisToHumanReadable(timeInMillis) {
    if (timeInMillis == null) {
        return 'N/A';
    }

    const msInMinute = 60000;
    const msInHours = msInMinute * 60;
    const msInDays = msInHours * 24;

    const seconds = Math.floor((timeInMillis % msInMinute) / 1000);
    const minutes = Math.floor(timeInMillis / msInMinute) % 60;
    const hours = Math.floor(timeInMillis / msInHours) % 24;
    const days = Math.floor(timeInMillis / msInDays);

    let msg = '';
    if (days > 0) {
        msg += `${days} day & `;
    }
    if (hours > 0) {
        msg += `${hours} hr & `;
    }
    if (minutes > 0) {
        msg += `${minutes} min & `;
    }
    if (seconds > 0) {
        msg += `${seconds} sec`;
    }

    return msg;
}

export default {
    getCurrentRunTimeInMillis,
    getDurationAsMinutesAndSeconds,
    timeInMillisToHumanReadable,
};
