/**
 * Created by Jamie Nola on 11/12/2018
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('RuleToKmapService', function (ConditionTreeCreationUtil) {
            function formatDateTime(milliseconds) {
                var date = new Date(milliseconds);
                var year = date.getUTCFullYear(),
                    month = date.getUTCMonth() + 1, // months are zero indexed
                    day = date.getUTCDate(),
                    hour = date.getUTCHours(),
                    minute = date.getUTCMinutes(),
                    hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
                    minuteFormatted = minute < 10 ? "0" + minute : minute,
                    morning = hour < 12 ? "am" : "pm";
                return month + "/" + day + "/" + year + " " + hourFormatted + ":" +
                    minuteFormatted + morning + ' GMT';
            }

            /**
             * Takes a count of seconds and transforms it into a more readable format
             */
            function formatSeconds(seconds) {
                var days = Math.floor(seconds / (3600 * 24));
                seconds -= days * 3600 * 24;
                var hrs = Math.floor(seconds / 3600);
                seconds -= hrs * 3600;
                var mnts = Math.floor(seconds / 60);
                seconds -= mnts * 60;
                var timeArr = [];
                if (days > 0) {
                    timeArr.push(days + ' ' + (days === 1 ? 'day' : 'days'));
                }
                if (hrs > 0) {
                    timeArr.push(hrs + ' ' + (hrs === 1 ? 'hour' : 'hours'));
                }
                if (mnts > 0) {
                    timeArr.push(mnts + ' ' + (mnts === 1 ? 'minute' : 'minutes'));
                }
                if (seconds > 0) {
                    timeArr.push(seconds + ' ' + (seconds === 1 ? 'second' : 'seconds'));
                }
                return timeArr.join(', ');
            }

            function formatExpression(expression) {
                if(expression == null) {
                    return '[ no expression ]';
                } else if(expression.expression != null){
                    return formatFunctionalExpression(expression.expression);
                } else if(expression.expressionCondition != null){
                    return formatSQLExpression(expression.expressionCondition.conditionTree);
                }
                return '';
            }

            function formatFunctionalExpression(expression) {
                if(expression == null) {
                    return '[ no expression ]';
                }
                return '<pre class="code">' + expression + '</pre>';
            }

            function formatSQLExpression(conditionTree) {
                if(conditionTree == null) {
                    return '[ no expression ]';
                }
                var ctree = ConditionTreeCreationUtil.createByDto(conditionTree, null);
                return ctree.getDescriptionAsHtmlFullTree();
            }

            /**
             * Creates a useable Kmap node, based on a series of attributes
             */
            function createNode(id, title, summary, dependencies, isStartNode, isEndNode) {
                var node = {
                    id: id,
                    title: title || '',
                    summary: summary || ' ',
                    fullSummary: '<h2>' + title + '</h2>' + (summary || ''),
                    dependencies: dependencies || [],
                    isStartNode: isStartNode || false,
                    isEndNode: isEndNode || false
                };
                return node;
            }

            /**
             * Builds an HTML summary to be displayed in List View.
             */
            function createSummary(data) {
                var keys = Object.keys(data);
                var summary = '<div class="node-detail">';
                keys.forEach(function (key) {
                    var item = data[key];
                    if(key === 'Functional Expression Condition') {
                        item = formatFunctionalExpression(item);
                    } else if(key === 'SQL Expression Condition') {
                        item = formatSQLExpression(item);
                    }
                    var dataString = '<div class="row"><div class="key">' + key + ':</div><div class="value">' + item + '</div></div>';
                    summary += dataString;
                });

                summary += '</div>';

                return summary;
            }

            /**
             * Creates the detailed set of properties to show for a rule in the List View
             * @param {Object} rule
             */
            function getRuleDetail(rule) {
                var detail = {
                    'Name': rule.name,
                    'Title': rule.title,
                    'Rule Type': rule.ruleType,
                    'Is Active': rule.isActive,
                    'Is Archivable': rule.isArchivable,
                    'Is Deleted': rule.isDeleted,
                    'Is Global': rule.isGlobal,
                    'Created By (ID)': rule.createdById,
                    'Created On': formatDateTime(rule.createdOn),
                    'Updated By (ID)': rule.updatedById,
                    'Updated On': formatDateTime(rule.updatedOn),
                    'Workflow Count': rule.workflows.length,
                    'Action Count': rule.actions.length
                };

                // count unique expressions and triggers. This ensures that reused triggers or expressions between workflows are only counted once.
                var expressionMap = {};
                var triggerMap = {};
                rule.workflows.forEach(function(workflow) {
                    workflow.evaluationExpressions.forEach(function(expression) {
                        expressionMap[expression.id] = true;
                    });
                    workflow.triggerConditions.forEach(function(trigger) {
                        triggerMap[trigger.id] = true;
                    });
                });
                detail['Expression Count'] = Object.keys(expressionMap).length;
                detail['Trigger Count'] = Object.keys(triggerMap).length;

                return detail;
            }

            /**
             * Creates the detailed set of properties to show for a workflow in the List View
             * @param {Object} workflow
             */
            function getWorkflowDetail(workflow) {
                var detail = {
                    'Name': workflow.name,
                    'Expression Count': workflow.evaluationExpressions.length,
                    'Trigger Count': workflow.triggerConditions.length
                };
                return detail;
            }

            /**
             * Creates the detailed set of properties to show for a trigger count node in the List View
             * @param {Array} triggers
             */
            function getTriggerCountDetail(triggers) {
                var detail = {};
                triggers.forEach(function(trigger) {
                    detail[trigger.id] = trigger.triggerType;
                });

                return detail;
            }

            /**
             * Creates the detailed set of properties to show for a trigger in the List View
             * @param {Object} trigger
             */
            function getTriggerDetail(trigger) {
                var detail = {
                    'Trigger Type': trigger.triggerType
                };
                var data = trigger.trigger;

                switch (trigger.triggerType) {
                    case 'LOG_ENTRY':
                        detail['Form Entry Status'] = data.formEntryStatus;
                        detail['Delay'] = formatSeconds(data.delay);
                        detail['Form ID'] = data.formId;
                        break;
                    case 'SCHEDULE':
                        detail['Day Type'] = data.dayType;
                        switch (data.dayType) {
                            case 'CALENDAR_DAY_GMT':
                            case 'CALENDAR_DAY_USER_TIME_ZONE':
                                detail['Start Date'] = formatDateTime(data.startAbsoluteTime);
                                detail['End Date'] = formatDateTime(data.endAbsoluteTime);
                                break;
                            default:
                                // empty default because Sonar demands it
                                break;
                        }

                        detail['Trigger Missed Schedule'] = data.triggerMissedScheduleNow || false;
                        detail['Cron Expression'] = data.cronExpression;
                        detail['Recurrence Type'] = data.recurrenceType;
                        break;
                    case 'USER_PROFILE_VALUE_CHANGE':
                        detail['Property ID'] = data.propertyId;
                        detail['Delay'] = formatSeconds(data.delay);
                        break;
                    case 'LOGIN':
                        detail['Delay'] = formatSeconds(data.delay);
                        break;
                    case 'LINKED_CHILD_ACCOUNT_ADDED':
                        detail['Delay'] = formatSeconds(data.delay);
                        break;
                    default:
                        // empty default because Sonar demands it
                        break;
                }

                return detail;
            }

            /**
             * Creates the detailed set of properties to show for an expression count node in the List View
             * @param {Array} expressions
             */
            function getExpressionCountDetail(expressions) {
                var detail = {};
                expressions.forEach(function(expression) {
                    detail[expression.id] = expression.name || '[no name]';
                });

                return detail;
            }

            /**
             * Creates the detailed set of properties to show for an expression in the List View
             * @param {Object} expression
             * @param {Object} actionDataMap
             */
            function getExpressionDetail(expression, actionDataMap) {
                var count = expression.expressionActionMappings.length;
                var actionKey = count + (count === 1 ? ' Action' : ' Actions');
                var detail = {
                    'Name': expression.name
                };

                if(expression.expression != null){
                    detail['Functional Expression Condition'] = expression.expression;
                } else if(expression.expressionCondition != null){
                    detail['SQL Expression Condition'] = expression.expressionCondition.conditionTree;
                }

                // list all actions associated with this expression
                detail[actionKey] = expression.expressionActionMappings.map(function(action) {
                    var name = actionDataMap[action.actionId].name;
                    return action.actionId + ' | '+actionDataMap[action.actionId].actionType+': ' + (name || '[no name]');
                }).join(',<br>')

                return detail;
            }

            /**
             * Creates the detailed set of properties to show for a action in the List View
             * @param {Object} action
             */
            function getActionDetail(action) {
                var detail = {
                    'Action Type': action.actionType,
                    'Name': action.name || '[none]'
                };

                // actionPushNotificationConfig
                var pushConfig = action.actionPushNotificationConfig;
                detail['Send Visible Push Notification'] = !!pushConfig;
                if (pushConfig) {
                    detail['Show Push in App'] = pushConfig.inApp || false;
                    detail['Show Push in Tray'] = pushConfig.inTray || false;
                    detail['Notification Type'] = pushConfig.actionLinkType;
                    if (pushConfig.imageFileUrl) {
                        detail['Notification Display Image File Name'] = pushConfig.imageFileName;
                    }
                    detail['Notification Title'] = pushConfig.title || '[none]';
                    detail['Notification Message'] = pushConfig.message || '[none]';
                    switch (pushConfig.actionLinkType) {
                        case 'NONE':
                        case 'FORM':
                            detail['Post Action Navigate to'] = pushConfig.postActionNavigationLink || '[none]';
                            break;
                        case 'NAVIGATION':
                            detail['Navigate to'] = pushConfig.actionNavigationLink;
                            break;
                        default:
                            // empty default because Sonar demands it
                            break;
                    }
                    if (pushConfig.actionFormId) {
                        detail['ID of Form to be Opened'] = pushConfig.actionFormId;
                    }
                }

                // actionConfig
                var actionConfig = action.actionConfig;
                if (actionConfig) {
                    if(actionConfig.hasOwnProperty('emailOrSMSNotificationPreference')) {
                        detail['Email or SMS Preference'] = actionConfig.emailOrSMSNotificationPreference;

                        // email configuration
                        if (actionConfig.emailOrSMSNotificationPreference !== 'SMS') {
                            detail['Email Notification Category'] = actionConfig.emailNotificationCategory;
                            detail['Email Notification Content'] = actionConfig.notificationTemplateType;
                            detail['Send to Providers'] = actionConfig.sendToProvider;
                            if (!actionConfig.sendToProvider) {
                                detail['Send To'] = actionConfig.sendTo;
                            }
                            detail['Email Subject'] = actionConfig.subject;
                            if (actionConfig.notificationTemplateType === 'MESSAGE') {
                                detail['Email Message'] = actionConfig.message;
                            } else if (actionConfig.notificationTemplateType === 'TEMPLATE') {
                                detail['Email Template File Name'] = actionConfig.templateFileName;
                            }
                            detail['Attach Consent PDF'] = actionConfig.attachConsent || false;
                            if (actionConfig.attachConsent) {
                                detail['Consent Form ID'] = actionConfig.consentFormId;
                            }
                            if (actionConfig.attachments) {
                                actionConfig.attachments.forEach(function (attachmentData, index) {
                                    detail['Attachment ' + (index + 1) + ' File Name'] = attachmentData.fileName;
                                });
                            }
                        }

                        // sms configuration
                        if (actionConfig.emailOrSMSNotificationPreference !== 'EMAIL') {
                            detail['SMS Content'] = actionConfig.smsContent;
                        }
                    }

                    // banner notification
                    if(actionConfig.hasOwnProperty('bannerType')) {
                        detail['Banner Type'] = actionConfig.bannerType;
                        // banner data
                        if(actionConfig.hasOwnProperty('bannerSpecificData') && actionConfig.bannerType !== 'SCHEDULE_PHYSICAL_MEASUREMENT') {
                            var bannerData = actionConfig.bannerSpecificData;
                            if(actionConfig.bannerType === 'OPEN_FORM' ||
                                actionConfig.bannerType ===  'INFO' ||
                                actionConfig.bannerType === 'MANUAL_SCHEDULING_FORM') {
                                detail['Banner Image File Name'] = bannerData.imageFileName;
                            }
                            detail['Banner Title'] = bannerData.title;
                            detail['Banner Message'] = bannerData.message;
                            if(actionConfig.bannerType !== 'INFO') {
                                detail['Banner Button Text'] = bannerData.buttonText;
                            }
                        }
                        if(actionConfig.bannerType === 'OPEN_FORM' || actionConfig.bannerType === 'MANUAL_SCHEDULING_FORM') {
                            detail['ID of Form to be Opened'] = actionConfig.formId;
                        }
                        // banner notification target areas
                        if(actionConfig.hasOwnProperty('bannerNotificationTargetAreas')) {
                            actionConfig.bannerNotificationTargetAreas.forEach(function(areaData, index) {
                                var areaName = 'Banner Notification Target Area' + (index + 1) + ' -';
                                var areaAbbr = 'BNTA' + (index + 1) + ' -';
                                detail[areaName + ' Dismissible'] = areaData.dismissible;
                                detail[areaAbbr + ' Remindable'] = areaData.remindable;
                                if(areaData.remindable &&
                                    areaData.hasOwnProperty('reminderTimeOptions') &&
                                    areaData.reminderTimeOptions.hasOwnProperty('dismissalDurationEnumList')) {
                                    areaData.reminderTimeOptions.dismissalDurationEnumList.forEach(function(optionData, optionIndex) {
                                        var optionName = ' Reminder Time ' + (optionIndex + 1);
                                        detail[areaAbbr + optionName] = optionData;
                                    });
                                }
                                detail[areaAbbr + ' Target Area'] = areaData.bannerDisplayTargetArea;
                            });
                        }
                    }

                    // personalized form
                    if(actionConfig.hasOwnProperty('containerFormMapping')) {
                        var formMapping = actionConfig.containerFormMapping;
                        detail['Form'] = formMapping.formName + ' (ID: ' + formMapping.formId + ')';
                        detail['Form Action Type'] = actionConfig.actionType;
                        detail['Program ID'] = formMapping.programId;
                        detail['Program Dashboard Group ID'] = formMapping.containerId;

                        if(actionConfig.actionType === 'ADD_FORM' || actionConfig.actionType === 'MOVE_FORM') {
                            detail['Form Behavior Template Type'] = formMapping.formBehaviorTemplateType;
                            detail['Max Entry Allowed'] = formMapping.maxEntryAllowed;
                            detail['Num of Entry as Complete'] = formMapping.numEntryAsComplete;
                            detail['Locked'] = formMapping.isLocked;
                            detail['Pausing Support'] = formMapping.templateDetail.supportPausing;
                        }
                    }

                    // user account update
                    if(actionConfig.hasOwnProperty('updates')) {
                        actionConfig.updates.forEach(function(update, index) {
                            detail['Update ' + (index + 1) + ' Field'] = update.targetField;
                            detail['Update ' + (index + 1) + ' Expression'] = update.expression;
                        });
                    }

                    // trigger business rule
                    if(action.actionType === 'TRIGGER_RULE') {
                        detail['Skip Evaluation'] = actionConfig.skipEvaluation;
                        var hasExecutionDate = actionConfig.hasOwnProperty('executionTime');
                        detail['Execute Rule Immediately'] = !hasExecutionDate;
                        if(hasExecutionDate) {
                            detail['Rule Execution Date'] = formatDateTime(actionConfig.executionTime);
                        }
                        detail['Business Rule ID'] = actionConfig.businessRuleId;
                        detail['Workflow ID'] = actionConfig.workflowId;
                    }

                    // modal notification as form
                    if(action.actionType === 'MODAL_NOTIFICATION') {
                        detail['Form ID'] = actionConfig.formId;
                        detail['Post Submit Navigation'] = actionConfig.postActionNavigationLink;
                        detail['Dismissible'] = actionConfig.dismissible;
                    }

                    // withdraw
                    if(action.actionType === 'WITHDRAW') {
                        detail['Consent Type'] = actionConfig.consentType;
                    }
                }

                // profile update
                if(action.hasOwnProperty('actionUserProfileValue')) {
                    var profile = action.actionUserProfileValue;
                    if(profile.hasOwnProperty('formId')) {
                        detail['Profile Form ID'] = profile.formId;
                    }
                    if(profile.hasOwnProperty('property')) {
                        detail['Property'] = profile.property.name + ' (ID: ' + profile.property.id + ')';
                    }

                    if(profile.hasOwnProperty('unit')) {
                        detail['Unit'] = profile.unit.name + '(ID: ' + profile.unit.id + ')';
                    }

                    if(profile.hasOwnProperty('valueAsNumber') ||
                        profile.hasOwnProperty('valueAsString') ||
                        profile.hasOwnProperty('valueAsBoolean')) {
                        detail['Value Type'] = profile.property.valueType;
                        switch(profile.property.valueType) {
                            case 'NUMBER':
                                detail['Number Value'] = profile.valueAsNumber;
                                break;
                            case 'STRING':
                                detail['String Value'] = profile.valueAsString;
                                break;
                            case 'BOOLEAN':
                                detail['Boolean Value'] = profile.valueAsBoolean;
                                break;
                            default:
                                // empty default because Sonar demands it
                                break;
                        }
                    }

                    if(profile.hasOwnProperty('expression')) {
                        detail['Expression'] = profile.expression;
                    }
                }

                // dismiss banner
                if(action.hasOwnProperty('actionDismissBanner')) {
                    var dismiss = action.actionDismissBanner;
                    detail['Banner Type'] = dismiss.bannerType;
                    if(dismiss.hasOwnProperty('targetAreas')) {
                        var targetKey = dismiss.targetAreas.length === 1 ? 'Target Area' : 'Target Areas';
                        detail[targetKey] = dismiss.targetAreas.join(',<br>') || '[none]';
                    }
                    detail['Ignore Remindable'] = dismiss.ignoreRemindable;
                }

                return detail;
            }

            function createTitle(node, placeholderTitle) {
                if(node == null && placeholderTitle == null){
                    return '';
                } else if(node == null){
                    return placeholderTitle;
                }

                var title = '';

                if(node.id){
                    title = title + node.id + ' | ';
                }

                if(node.triggerType){
                    title = title + node.triggerType + ' | ';
                } else if(node.actionType){
                    title = title + node.actionType + ' | ';
                }

                if(node.name){
                    if(node.name.length > 50){
                        title = title + node.name.substring(0, 47) + '...';
                    } else {
                        title = title + node.name
                    }

                } else {
                    title += placeholderTitle;
                }
                return title;
            }

            /**
             * Transforms a business rule into a model that the Kmap library can use
             * @param {Object} rule A business rule
             */
            function transform(rule) {
                // cache actions so we can refer back to them later
                var actionDataMap = {};
                rule.actions.forEach(function (action) {
                    actionDataMap[action.id] = action;
                });

                var nodes = [];
                var rootId = 'root_node_type_rule';
                var root = createNode(
                    rootId,
                    createTitle(rule, 'Rule'),
                    createSummary(
                        getRuleDetail(rule)
                    )
                );
                nodes.push(root);

                var actionNodeMap = {};
                var triggerNodeMap = {};
                var expressionNodeMap = {};

                // workflows
                rule.workflows.forEach(function (workflow) {
                    var workflowId = workflow.id + '_node_type_workflow';
                    var workflowNode = createNode(
                        workflowId,
                        createTitle(workflow, 'Workflow'),
                        createSummary(
                            getWorkflowDetail(workflow)
                        ),
                        [{ source: rootId, reason: ' ' }]
                    );
                    nodes.push(workflowNode);

                    // trigger count (only used if the workflow has more than one trigger)
                    var hasMultipleTriggers = workflow.triggerConditions &&
                        workflow.triggerConditions.length > 1;
                    if (hasMultipleTriggers) {
                        var triggerCountId = workflow.id + '_node_type_count_trigger';
                        var triggerCountNode = createNode(
                            triggerCountId,
                            workflow.triggerConditions.length + ' Triggers',
                            createSummary(
                                getTriggerCountDetail(workflow.triggerConditions)
                            ),
                            [{ source: workflowId, reason: ' ' }]
                        );
                        nodes.push(triggerCountNode);
                    }

                    // triggers
                    workflow.triggerConditions.forEach(function (trigger) {
                        var triggerId = trigger.id + '_node_type_trigger';
                        var triggerNode = triggerNodeMap[trigger.id] ||
                            createNode(
                                triggerId,
                                createTitle(trigger, 'Trigger'),
                                createSummary(
                                    getTriggerDetail(trigger)
                                )
                            );

                        triggerNode.dependencies.push({
                            source: hasMultipleTriggers ? triggerCountId : workflowId,
                            reason: ' '
                        });

                        triggerNodeMap[trigger.id] = triggerNode;
                    });


                    // expression count (only used if the workflow has more than one expression)
                    var hasMultipleExpressions = workflow.evaluationExpressions &&
                        workflow.evaluationExpressions.length > 1;
                    if (hasMultipleExpressions) {
                        var expressionCountId = workflow.id + '_node_type_count_expression';
                        var expressionCountNode = createNode(
                            expressionCountId,
                            workflow.evaluationExpressions.length + ' Expressions',
                            createSummary(
                                getExpressionCountDetail(workflow.evaluationExpressions)
                            ),
                            [{ source: workflowId, reason: ' ' }]
                        );
                        nodes.push(expressionCountNode);
                    }

                    // expressions
                    workflow.evaluationExpressions.forEach(function (expression) {
                        var expressionId = expression.id + '_node_type_expression';
                        var expressionNode = expressionNodeMap[expression.id] ||
                            createNode(
                                expressionId,
                                createTitle(expression, 'Expression'),
                                createSummary(
                                    getExpressionDetail(expression, actionDataMap)
                                )
                            );

                        expressionNode.dependencies.push({
                            source: hasMultipleExpressions ? expressionCountId : workflowId,
                            reason: ' '
                        });

                        expressionNodeMap[expression.id] = expressionNode;

                        // actions
                        expression.expressionActionMappings.forEach(function (action) {
                            var actionData = actionDataMap[action.actionId]
                            var actionId = actionData.id + '_node_type_action';
                            var actionNode = actionNodeMap[actionData.id] ||
                                createNode(
                                    actionId,
                                    createTitle(actionData, 'Action'),
                                    createSummary(
                                        getActionDetail(actionData)
                                    )
                                );

                            actionNode.dependencies.push({
                                source: expressionId,
                                reason: formatExpression(expression)
                            });

                            actionNodeMap[actionData.id] = actionNode;
                        });
                    });
                });

                // move all node maps into the main nodes array
                Object.keys(triggerNodeMap).forEach(function (key) {
                    nodes.push(triggerNodeMap[key]);
                });
                Object.keys(expressionNodeMap).forEach(function (key) {
                    nodes.push(expressionNodeMap[key]);
                });
                Object.keys(actionNodeMap).forEach(function (key) {
                    nodes.push(actionNodeMap[key]);
                });

                return nodes;
            };

            return {
                transform: transform
            };
        });
})();
