import template from './addContainerItemsHybrid.html';
angular.module('acadiamasterApp').directive('addContainerItemsHybrid', ($document, VbrSelectorService, $state) => ({
    link ($scope) {
        $scope.containerItemTitle = 'Add New Container Item';
        $scope.programId = $state?.params?.id;
        /**
         * form options for form search directive, multi-form support
         */
        $scope.formOptions = {
            allowMultiple : true,
            canSelectFunc : function (form) {
                return !$scope.container.isFormAlreadySelected(form);
            },
            customLabel      : 'Add A Primary Form',
            form             : null,
            onChangeCallBack : function () {
                if (this.form) {
                    $scope.container.addForm(this.form);
                }
            },
            viewOnly : false,
        };

        $scope.addExternalIntegration = function () {
            $scope.container.addExternalIntegration();
        };
        $scope.addContent = function () {
            $scope.container.addContent();
        };
        /**
         * action options for action item directive, support ROR container
         */
        $scope.actionOptions = {
            action           : null,
            iconClass        : $scope.iconClass,
            onChangeCallBack : function () {
                if (this.action) {
                    $scope.container.addActionItem(this.action);
                }
            },
            title : 'Add Action',
        };

        $scope.agreementOptions = {
            agreement     : null,
            allowMultiple : true,
            canSelectFunc : function (agreement) {
                return !$scope.container.isAgreementAlreadySelected(agreement);
            },
            onChangeCallBack : function () {
                if (this.agreement) {
                    $scope.container.addAgreement(this.agreement);
                }
            },
            viewOnly : false,
        };
        // add more options later for various events of tree operation
        // todo: at minimum, we need to prevent drop from another tree
        // need to implement the accept function with node type
        $scope.treeOptions = {};

        $scope.hasIconClass = () => {
            return $scope.iconClass && $scope.iconClass.length > 0;
        };

        $scope.getIconClass = () => {
            return $scope.hasIconClass() ? $scope.iconClass : '';
        };

        $scope.closePopup = function () {
            $scope.data.isPopupOpen = false;
        };

        $scope.onPopoverOpened = function ($event) {
            $document[0].body.click();
            $event.stopPropagation();
            $scope.data.isPopupOpen = true;
        };
        /**
         * create a selector here so it will be shared by all the children tree nodes,
         * this basically creates an accordion group like behavior where selecting one
         * will un-select another
         */
        $scope.selector = VbrSelectorService.createSelector();

        // set the updated formCategory in formOptions anytime it changes
        $scope.$watch('formCategory', value => {
            $scope.formOptions.formCategory = value;
        });
    },
    restrict : 'E',
    scope    : {
        container    : '=',
        formCategory : '=',
        iconClass    : '<',
    },
    template : template,
}));
