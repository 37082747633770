import template from './correctiveActionPropertyFromFieldValue.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('correctiveActionPropertyFromFieldValue', function (
        FormLoadUtil,ProgramTestConstantsService) {
        return {
            restrict: 'E',
            template: template,
            scope: {
                correctiveAction: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.correctiveAction._parent.programId;
                $scope.cs = ProgramTestConstantsService;

                $scope.data = {
                    selectedReference : $scope.correctiveAction.getField()
                };

                $scope.propertyOptions = {
                    getProperty : function() {
                        return $scope.correctiveAction.getProperty();
                    },
                    setProperty : function(newValue) {
                        $scope.correctiveAction.setProperty(newValue);
                    },
                };

                $scope.formOptions = {
                    form: $scope.correctiveAction.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack: function () {
                        if (this.form == null) {
                            $scope.correctiveAction.setForm(null);
                            return;
                        }

                        $scope.correctiveAction.formId = this.form.id;
                        $scope.correctiveAction._form = null;

                        FormLoadUtil.loadForm($scope.correctiveAction, function() {
                            $scope.data.selectedReference = $scope.correctiveAction.getField();
                        });
                    }
                };

                $scope.hasEligibleFields = function() {
                    var correctiveAction = $scope.correctiveAction;
                    return correctiveAction.getAllEligibleFields() != null && correctiveAction.getAllEligibleFields().length > 0;
                };

                addWatches($scope);
            }
        };

        function addWatches($scope) {
            $scope.$watch('data.selectedReference', function(newValue, oldValue) {
                if (newValue!=oldValue) {
                    $scope.correctiveAction.setField(newValue);
                }
            });
        }
    });
})();
