(function () {
    angular.module('acadiamasterApp').config($stateProvider => {
        function loadTranslate ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('form');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
        }
        $stateProvider
            .state('formVersionTwo', {
                data    : { authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ], pageTitle : 'acadiamasterApp.form.home.title' },
                params  : { defaultFormCategory : null },
                parent  : 'entity',
                resolve : {
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/formsVersionTwo',
                views : {
                    'content@' : {
                        controller  : 'FormController',
                        templateUrl : 'admin-templates/site/forms/formVersionTwo/forms.html',
                    },
                },
            })
            .state('formVersionTwo.new', {
                data   : { authorities : [ 'ROLE_ADMIN' ], pageTitle : 'acadiamasterApp.form.home.createLabel' },
                params : {
                    programCode : null,
                    programId   : null,
                },
                parent  : 'formVersionTwo',
                resolve : {
                    entity : [
                        'FormUtilService',
                        'FormConstants',
                        function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.DATA_FORM);
                        },
                    ],
                    formDetails : [
                        '$stateParams',
                        function ($stateParams) {
                            return { programCode : $stateParams.programCode, programId : $stateParams.programId };
                        },
                    ],
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/new',
                views : {
                    'content@' : {
                        controller : function ($scope, entity, formDetails) {
                            $scope.form = entity;
                            $scope.programCode = formDetails.programCode;
                            $scope.programId = formDetails.programId;
                        },
                        template :
                            '<form-configuration category="DATA_FORM" program-id="programId" program-code="programCode" is-new="true" form="form" state-after-save="\'formVersionTwo\'" state-after-cancel="\'formVersionTwo\'" hide-import-button="true"></form-configuration>',
                    },
                },
            })
            .state('formVersionTwo.help', {
                data    : { authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ], pageTitle : 'acadiamasterApp.form.home.help.title' },
                parent  : 'formVersionTwo',
                resolve : {
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/help',
                views : {
                    'content@' : {
                        controller  : 'FormHelpController',
                        templateUrl : 'admin-templates/site/forms/help/formHelp.template.html',
                    },
                },
            })
            .state('formVersionTwo.replacePDF', {
                data    : { authorities : [ 'ROLE_ADMIN' ], pageTitle : 'Replace PDF Configuration' },
                parent  : 'formVersionTwo',
                resolve : {
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/replacePDF/{formId}/{versionId}',
                views : {
                    'content@' : {
                        controller : function ($scope, $stateParams) {
                            $scope.formId = $stateParams.formId;
                            $scope.versionId = $stateParams.versionId;
                        },
                        template : '<form-version-pdf-replace form-id="formId" version-id="versionId"></form-version-pdf-replace>',
                    },
                },
            })
            .state('formVersionTwo.newSnapQuestion', {
                data   : { authorities : [ 'ROLE_ADMIN' ], pageTitle : 'acadiamasterApp.form.home.createLabel' },
                params : {
                    programCode : null,
                    programId   : null,
                },
                parent  : 'formVersionTwo',
                resolve : {
                    entity : [
                        'FormUtilService',
                        'FormConstants',
                        function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(
                                FormConstants.formCategories.SNAP_QUESTION_FORM,
                            );
                        },
                    ],

                    formDetails : [
                        '$stateParams',
                        function ($stateParams) {
                            return { programCode : $stateParams.programCode, programId : $stateParams.programId };
                        },
                    ],
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/newSnapQuestion',
                views : {
                    'content@' : {
                        controller : function ($scope, entity, formDetails) {
                            $scope.form = entity;
                            $scope.programCode = formDetails.programCode;
                            $scope.programId = formDetails.programId;
                        },
                        template :
                            '<form-configuration category="SNAP_QUESTION_FORM" program-id="programId" program-code="programCode" is-new="true" form="form" state-after-save="\'formVersionTwo\'" state-after-cancel="\'formVersionTwo\'" hide-import-button="true"></form-configuration>',
                    },
                },
            })
            .state('formVersionTwo.newConsent', {
                data   : { authorities : [ 'ROLE_ADMIN' ], pageTitle : 'acadiamasterApp.form.home.createConentLabel' },
                params : {
                    programCode : null,
                    programId   : null,
                },
                parent  : 'formVersionTwo',
                resolve : {
                    entity : [
                        'FormUtilService',
                        'FormConstants',
                        function (FormUtilService, FormConstants) {
                            return new FormUtilService.FormModelServiceForm.FormModel(FormConstants.formCategories.CONSENT_FORM);
                        },
                    ],

                    formDetails : [
                        '$stateParams',
                        function ($stateParams) {
                            return { programCode : $stateParams.programCode, programId : $stateParams.programId };
                        },
                    ],
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/newConsent',
                views : {
                    'content@' : {
                        controller : function ($scope, entity, formDetails) {
                            $scope.form = entity;
                            $scope.programCode = formDetails.programCode;
                            $scope.programId = formDetails.programId;
                        },
                        template :
                            '<form-configuration category="CONSENT_FORM" program-id="programId" program-code="programCode" is-new="true" form="form" state-after-save="\'formVersionTwo\'" state-after-cancel="\'formVersionTwo\'" hide-import-button="true"></form-configuration>',
                    },
                },
            })
            .state('formVersionTwo.edit', {
                data    : { authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ], pageTitle : 'acadiamasterApp.form.home.editLabel' },
                parent  : 'formVersionTwo',
                params : {
                    form : null,
                },
                resolve : {
                    entity : [
                        'Form',
                        '$stateParams',
                        function (Form, $stateParams) {
                                if ($stateParams.form != null) {
                                    return $stateParams.form;
                                } else {
                                return $stateParams.versionId === null
                                    ? Form.get({id: $stateParams.id, projection: Form.projections.ACTIVE_ONLY})
                                    : Form.get({
                                        formVersionId: $stateParams.versionId,
                                        id: $stateParams.id,
                                        projection: Form.projections.SPECIFIC_VERSION,
                                    });
                            }
                        },
                    ],
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/edit/{id}?versionId',
                views : {
                    'content@' : {
                        controller : function ($scope, entity) {
                            $scope.form = entity;
                        },
                        template :
                            '<form-configuration is-new="false" form="form" state-after-save="\'formVersionTwo\'" state-after-cancel="\'formVersionTwo\'"></form-configuration>',
                    },
                },
            })
            .state('formVersionTwo.compare', {
                data    : { authorities : [ 'ROLE_ADMIN', 'ROLE_DEVELOPER' ], pageTitle : 'acadiamasterApp.form.home.compareLabel' },
                parent  : 'formVersionTwo',
                resolve : {
                    formId : [
                        '$stateParams',
                        function ($stateParams) {
                            return $stateParams.formId;
                        },
                    ],
                    translatePartialLoader : [
                        '$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            return loadTranslate($translate, $translatePartialLoader);
                        },
                    ],
                },
                url   : '/compare?formId',
                views : {
                    'content@' : {
                        controller : function ($scope, formId) {
                            $scope.formId = formId;
                        },
                        template : '<form-version-compare form-id="formId"></form-version-compare>',
                    },
                },
            });
    });
}());
