/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for video display
     */
        .directive('formFieldConfigFieldValueVideoDisplay', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/videoDisplay.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.vcu = vbrCommonUtil;
                    $scope.constants = FormConstants;
                    $scope.initialChange = true;
                    $scope.asInputChanged = function (newAsInput) {
                        asInputChanged($scope, newAsInput);
                    };

                    $scope.sourceChanged = function (source) {
                        if (!$scope.initialChange) {
                            $scope.fieldValue.value = "";
                            $scope.fieldValue.downloadURL = "";
                            $scope.fieldValue.thumbnailURL = "";

                            $scope.fieldValue.localizationMap = {};
                        }
                        $scope.initialChange = false;
                    };
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function asInputChanged($scope, newAsInput) {
                if (newAsInput) {
                    $scope.fieldElement.dataTypeProperty = null;
                    $scope.fieldValue.asSubmit = false;
                    $scope.fieldValue.inputType = FormConstants.videoDisplayInputType.ON_VIDEO_END;
                    $scope.fieldElement.availableRuleTypes = [FormConstants.validationRuleTypes.REQUIRED];
                    $scope.fieldValue.changeDetectionDelay = 0;
                    $scope.fieldValue.questionLabel = "Add Question Label Here";
                }
                else {
                    $scope.fieldElement.unlinkDataType();
                    $scope.fieldElement.dataTypeProperty = vbrCommonUtil.UNAVAILABLE;
                    $scope.fieldValue.asSubmit = vbrCommonUtil.UNAVAILABLE;
                    $scope.fieldValue.inputType = vbrCommonUtil.UNAVAILABLE;
                    $scope.fieldValue.changeDetectionDelay = vbrCommonUtil.UNAVAILABLE;
                    $scope.fieldValue.questionLabel = vbrCommonUtil.UNAVAILABLE;
                    $scope.fieldElement.availableRuleTypes = null;
                    $scope.fieldElement.validationRules = [];

                }
            }

        });

})();
