/**
 * Created by moustafabaiou on 4/26/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('donationSiteHome', {
                    parent: 'site',
                    url: '/donation',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/donationSite/donationSiteHome.html',
                            controller: function($scope, TILE_CONSTANTS) {
                                $scope.data = {
                                    tileCollectionId: TILE_CONSTANTS.ID.DONATION
                                };
                            },
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('donationSite');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                })

                .state('donationSite', {
                    parent: 'donationSiteHome',
                    url: '/site',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/donationSite/donationSite.html',
                            controller: 'DonationSiteController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('donationSite');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                })

            .state('geoLocationZipCodes', {
                    parent: 'donationSiteHome',
                    url: '/zip',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'admin-templates/site/donationSite/geoLocationZipCodes.html',
                            controller: 'GeoLocationZipCodesController'
                        }
                    },
                    resolve: {
                        mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('donationSite');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
            });
})();
