(function () {
    'use strict';

    /**
     * status type enum for program test running status
     */
    angular.module('acadiamasterApp').factory('TestExecutionStatusService', function(TestExecutionStatus) {
        return {
            isFailed : isFailed,
            isCompleted : isCompleted,
            isRunning : isRunning,
            isCancelled : isCancelled,
        };

        /***************************************************************
         * interface functions
         ***************************************************************/

        /**
         * use the status to check if we can run the test or not
         * @param status - status of the test
         * @return {boolean} - true if test can be run, false otherwise
         */

        function isFailed(status) {
            return status==TestExecutionStatus.FAILED_IN_PRE_CONDITION ||
                status==TestExecutionStatus.FAILED_IF_ASSERT_EVALUATION ||
                status==TestExecutionStatus.FAILED_CORRECTIVE_ACTION;
        }

        function isCompleted(status) {
            return status== TestExecutionStatus.COMPLETED_WITH_NO_VIOLATION ||
                status==TestExecutionStatus.COMPLETED_NO_CORRECTIVE_ACTION_APPLIED
                || status== TestExecutionStatus.COMPLETED_CORRECTIVE_ACTION_APPLIED;
        }

        /**
         * use the status to check if the test is running or not
         * @param status - status of the test
         * @return {boolean} - true if test is running, false otherwise
         */
        function isRunning(status) {
            return status==TestExecutionStatus.STARTING ||
                status== TestExecutionStatus.RUNNING_CORRECTIVE_ACTION ||
                status==TestExecutionStatus.RUNNING_IF_ASSERT_EVALUATION;
        }

        function isCancelled(status) {
            return status == TestExecutionStatus.CANCELLED;
        }

        /***************************************************************
         * private functions
         ***************************************************************/

    });
})();
