/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
/**
 * drc sync util service, it has the modal window handling functions
 */
(function () {
    angular.module('acadiamasterApp').factory('drcSyncUtilService', (
        drcService, ngDialog, $timeout, OperationStatusType) => {
        /** *************************************
         * factory return call
         ***************************************/

        return {
            checkAndShowStatus : checkAndShowStatus,
            checkCount         : checkCount,
            openModelWindow    : openModelWindow,
            stopOperation      : stopOperation,
        };

        /*
         * check count call to check the counts for all type of entities
         * @param data data container for that entity
         * @param checkCountApiCall api call function for that entity, this function is expected to return a HttpPromise when called
         */
        function checkCount (data, checkCountApiCall) {
            data.isReady = false;
            checkCountApiCall()
                .then(response => {
                    let countHolder = data.countHolder;

                    countHolder.fromDto(response.data);
                    data.isReady = true;

                    data.hasUnregistered = countHolder.registeredItem < countHolder.totalItem;
                });
        }

        /*
         * check server operation status periodically until the operation is finished
         * @param $scope main scope object of the directive
         * @param dataContainer data container object where we store operation status, operation id, etc
         */
        function checkAndShowStatus ($scope, dataContainer) {
            drcService.checkOperationStatus(dataContainer.operationId)
                .then(response => {
                    let operationStatus = dataContainer.operationStatus;
                    operationStatus.fromDto(response.data);
                    if (!operationStatus.isOperationOver() && $scope.windowOpen) {
                        $timeout(() => {
                            checkAndShowStatus($scope, dataContainer);
                        }, $scope.checkStatusInterval);
                    }
                });
        }

        /*
         * open a model window for operation status
         * @param $scope main scope object of the directive
         * @param dataContainer data container object where we store operation status, operation id, etc
         * @param title title of the window to be displayed
         * @param checkCountCall function to check counts, used in close callback
         */
        function openModelWindow ($scope, dataContainer, title, checkCountCall, hideStopOperationButton) {
            ngDialog.open({
                className         : 'ngdialog-theme-plain custom-width-medium',
                closeByDocument   : false,
                closeByEscape     : false,
                closeByNavigation : false,
                controller        : function () {
                    let vm = this;
                    vm.title = title;
                    vm.data = dataContainer;
                    vm.hideStopOperationButton = hideStopOperationButton;

                    vm.getCompletionStatus = function () {
                        if (vm.data.operationStatus.totalItems == 0 && vm.data.operationStatus.completedItems == 0) {
                            return '100%';
                        }

                        return `${Math.floor(vm.data.operationStatus.completedItems * 100 / vm.data.operationStatus.totalItems) }%`;
                    };

                    vm.stopOperation = function () {
                        console.log(`stop operation called, ${ vm.data.operationId}`);
                        stopOperation(vm.data.operationId);
                    };

                    vm.showTotalItems = function () {
                        if (vm.data.operationStatus.totalItems <= 0
                            && !vm.data.operationStatus.isOperationOver()) {
                            return 'checking...';
                        }
                        return vm.data.operationStatus.totalItems <= 0 ? 0 : vm.data.operationStatus.totalItems;
                    };

                    vm.showCompletedItems = function () {
                        if (vm.data.operationStatus.completedItems <= 0
                            && !vm.data.operationStatus.isOperationOver()) {
                            return 'checking...';
                        }
                        return vm.data.operationStatus.completedItems;
                    };
                },
                controllerAs     : 'windowCtrl',
                height           : '70%',
                preCloseCallback : function () {
                    $scope.windowOpen = false;
                    if (checkCountCall != null) {
                        checkCountCall($scope);
                    } else if ($scope.closeThisDialog) {
                        $scope.closeThisDialog();
                    }
                },
                showClose : false,
                template  : 'admin-templates/site/common/statusWindow.html',
            });
            $scope.windowOpen = true;
        }

        /*
         * stop operation
         * @param operationId -- operation id
         */
        function stopOperation (operationId) {
            if (operationId == null) {
                return;
            }

            drcService.stopOperation(operationId);
        }
    });
}());
