(function () {
    'use strict';

    /**
     * form search model for display config
     */
    angular.module('acadiamasterApp').factory('FormSearchPropertyModel', function () {

        /***************************************************************
         * private functions
         **************************************************************/

        /***************************************************************
         * Simple Model for search property
         * @constructor
         ***************************************************************/
        function FormSearchPropertyModel() {
            this.enabled = false;
            this.value = null;
            this.unit = null;
        }

        /**
         * disable the search property
         */
        FormSearchPropertyModel.prototype.disable = function() {
            this.enabled = false;
            this.value = null;
            this.unit = null;
        };

        /**
         * enable the search property
         */
        FormSearchPropertyModel.prototype.enable = function() {
            this.enabled = true;
            this.value = null;
            this.unit = null;
        };

        /**
         * check if the property is enabled and has any value
         */
        FormSearchPropertyModel.prototype.enabledAndHasValue = function() {
            return this.enabled && this.value != null &&
                (!_.isArray(this.value) || this.value.length > 0);
        };


        /**
         * getting the description as html
         */
        FormSearchPropertyModel.prototype.getDescriptionAsHtml = function(key) {
            return '<span class="badge">' +
                key + ' = ' + this.value +
                (this.unit==null ? '' : this.unit) +
                '</span>';
        };


        /***************************************
         * service return call
         ***************************************/

        return FormSearchPropertyModel;
    });

})();
