(function () {
    /** *************************************************************
     * Filter Configuration Model, this model should be used along with
     * the query builder library
     ***************************************************************/
    angular.module('acadiamasterApp').factory('FilterConfigService',
        (Property, PropertySearch, FilterFieldService, FormFieldService, FormModelFieldService, AlertService) => {
            /*
             * Filter Config Object
             * @constructor
             */
            function FilterConfig (addSitePairingFilter) {
                this.newFieldId = null;
                this.newProfilePropertyId = null;
                this.pageSize = 25;
                this.pageNum = 0;
                this.fields = [
                    new FilterFieldService.FilterField('userId', 'NUMBER', -1, null, null, null, null, 'NUMBER'),
                    new FilterFieldService.FilterField('formId', 'NUMBER', -2, null, null, null, null, 'NUMBER'),
                    new FilterFieldService.FilterField('userExternalId', 'STRING', -3, null, null, null, null, 'STRING'),
                ];

                if (addSitePairingFilter === true) {
                    let _fields = this.fields;
                    PropertySearch.searchByName('awardee_code', true)
                        .then(response => {
                            const dto = response.data;
                            _fields.push(new FilterFieldService.FilterField(dto.name, dto.valueType, dto.id, null, null,
                                null, 'PROFILE_FIELD', 'STRING'));
                        }).catch(() => {
                            AlertService.error('failed to get site pairing property');
                        });
                }

                this.sort = [ {
                    direction : 'DESC',
                    property  : 'fe.user_id',
                } ];
            }

            FilterConfig.prototype.addFieldId = function () {
                if (this.newFieldId == null) {
                    return;
                }
                let _this = this;

                FormFieldService.findById(this.newFieldId)
                    .then(response => {
                        const dto = response.data;
                        let fieldModel = FormModelFieldService.createFieldModelFromDto(dto, null);
                        if (dto == null || dto.formField == null) {
                            AlertService.error('failed to get form field dto, maybe id is invalid');
                        } else if (!fieldModel.isInputField()) {
                            AlertService.error(`selected form field is not an input field, its type is [${ fieldModel.type.name }]`);
                        } else {
                            let fieldId = fieldModel.formField.id;
                            let fieldName = fieldModel.name;
                            let fieldValueType = fieldModel.getValueTypeName();
                            let fieldType = fieldModel.type.name;
                            let formId = dto.formField.formId;
                            let fieldHtml = fieldModel.getDescriptionAsHtml();

                            _this.fields.push(new FilterFieldService.FilterField(fieldName, fieldValueType, fieldId, fieldType, formId, fieldHtml, 'FORM_FIELD', fieldValueType));
                            _this.newFieldId = null;
                        }
                    }).catch(errResponse => {
                        console.error('error for getting form field dto', errResponse);
                        AlertService.error('failed to get form field dto');
                    });
            };

            FilterConfig.prototype.addPropertyId = function () {
                if (this.newProfilePropertyId == null) {
                    return;
                }
                let _this = this;

                Property.get({ id : this.newProfilePropertyId }, dto => {
                    if (dto == null) {
                        AlertService.error('failed to get property, maybe id is invalid');
                    } else {
                        let propertyId = dto.id;
                        let propertyName = dto.name;
                        let valueType = dto.valueType;
                        _this.fields.push(new FilterFieldService.FilterField(propertyName, valueType, propertyId, null, null, null, 'PROFILE_FIELD', valueType));
                        _this.newProfilePropertyId = null;
                    }
                }, () => {
                    AlertService.error('failed to get property, maybe id is invalid');
                });
            };

            /*
             * remove field
             * @param field - field to be removed
             */
            FilterConfig.prototype.removeField = function (field) {
                // eslint-disable-next-line no-undef
                let findIndex = _.findIndex(this.fields, f => {
                    return f === field;
                });

                if (findIndex !== -1) {
                    this.fields.splice(findIndex, 1);
                }
            };

            return {
                FilterConfig : FilterConfig,
            };
        });
}());
