/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display config basic properties
     */
        .directive('formDisplayConfigBasicProperties', function (FormConstants, ExpressionConstantsService, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/basic.html',
                scope: {
                    displayConfig: '=',
                    displayConfigChange: '=',
                    element : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.hasChangeModel = $scope.displayConfigChange!=null;
                    $scope.vcu = vbrCommonUtil;
                    /**
                     * generic function to get property value by key while using hasChangeModel property
                     * @param key
                     */
                    $scope.getPropertyValue = function(key) {
                        var r = null;
                        if ($scope.hasChangeModel) {
                            r = $scope.displayConfigChange[key];
                        }

                        if (r==null) {
                            r = $scope.displayConfig[key];
                        }

                        return r;
                    };

                    $scope.isCustomPreset = function() {
                        if (_.isFunction($scope.element.isCustomPreset)) {
                            return $scope.element.isCustomPreset();
                        } else {
                            return true;
                        }
                    };
                }
            };
        });

})();

