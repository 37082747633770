/**
 * program configuration basic, it will include configuration of program name, organization,
 * program category, and a number of top level program options
 */

(function () {
    angular.module('acadiamasterApp').directive('programConfigBasic',
        () => {
            return {
                controller: 'basicConfigController',
                controllerAs: 'bcc',
                restrict: 'E',
                scope: {
                    programId: '@',
                    showReadOnly: '=',
                },
                templateUrl: 'admin-templates/site/programManagement/program/basicConfig/program.basicConfig.html',
            };
        });
}());
