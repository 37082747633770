'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeDetailController', function ($location, $state, $scope, $uibModal,  $rootScope, $stateParams,
                                                      entity, DataType, DataTypeMasterRecordCount) {
        var modalInstance = null;
        var dataType = null;

        $scope.dataType = entity;
        dataType = $scope.dataType;
        $scope.dataTypeRootCategorys = [];
        $scope.inheritedPropertyMappings = [];

        $scope.mappingStatus = {
            OVERWRITTEN: 'O'
        };

        $scope.usedFor = {
            DATAFORM: 'DATAFORM',
            PROFILEFORM: 'PROFILEFORM',
            ALL: 'ALL'
        };

        entity.$promise.then(function(data){
            $scope.dataReady = true;
            var dataTypeCategorys = data.dataTypeCategorys;
            for (var i=0; i < dataTypeCategorys.length; i++) {
                var propertyMappings = dataTypeCategorys[i].propertyMappings;
                // go through all the properties
                for (var j=0; j < propertyMappings.length; j++) {
                    if (!$scope.inheritedPropertyMappings.IdExist(propertyMappings[j].id)) {

                        var newMapping = propertyMappings[j];
                        // If it is also in directly associated list, we consider that was overwritten.
                        if (findPropertyMapping(newMapping.property.name, $scope.dataType.propertyMappings) != null) {
                            newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                        }


                        $scope.inheritedPropertyMappings.push(newMapping);

                    }

                }
                var dataTypeRootCategorys = dataTypeCategorys[i].dataTypeRootCategorys;
                // Go through all the root categories
                for (var j=0; j < dataTypeRootCategorys.length; j++) {
                    if (!$scope.dataTypeRootCategorys.IdExist(dataTypeRootCategorys[j].id)) {
                        $scope.dataTypeRootCategorys.push(dataTypeRootCategorys[j]);
                    }

                    propertyMappings = dataTypeRootCategorys[j].propertyMappings;
                    // go through all the properties
                    for (var k=0; k < propertyMappings.length; k++) {
                        if (!$scope.inheritedPropertyMappings.IdExist(propertyMappings[k].id)) {

                            var newMapping = propertyMappings[k];
                            // If it is also in directly associated list, we consider that was overridden.
                            if (findPropertyMapping(newMapping.property.name, $scope.dataType.propertyMappings) != null) {
                                newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                            }

                            $scope.inheritedPropertyMappings.push(newMapping);
                        }

                    }
                }
            }
        });


        $scope.load = function (id) {
            DataType.get({id: id}, function(result) {
                $scope.dataType = result;
            });
        };
        $rootScope.$on('acadiamasterApp:dataTypeUpdate', function(event, result) {
            $scope.dataType = result;
        });

        $scope.showDeleteConfirm = function (id) {
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'deleteConfirmation.html',
                scope: $scope,
                controller: function($scope) {
                    $scope.delete = function (id) {
                        DataType.delete({id: dataType.id},
                            function (result) {
                                DataTypeMasterRecordCount.updateCounts();
                                modalInstance.close(result);
                                $state.go('dataTypeMaster');
                            });
                    };

                    $scope.closeConfirmation = function() {
                        modalInstance.dismiss('cancel');
                    }

                },
                size: 'md',
                resolve: {
                    item: function () {
                        return DataType.get({id: id});
                    }
                }
            });
        };


        function findPropertyMapping(propertyName, propertyMappingList) {
            if (propertyMappingList != null) {
                for (var i = 0; i < propertyMappingList.length; i++) {
                    if (propertyName.toLowerCase() == propertyMappingList[i].property.name.toLowerCase()) {
                        return propertyMappingList[i];
                    }
                }
            }
            return null;
        }

    });
