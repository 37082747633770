(function () {
    'use strict';

    /**
     * service to form mode updates, all the apply functions should accept a form mode model object
     */
    angular.module('acadiamasterApp').factory('FormUpdateUtilService',
        function (UpdateFontToMontSerratService, UpdateReturnOfResultAlignmentActionScript, FormConstants) {

            /***************************************
             * function to create all the available actions
             ***************************************/
            var availableActions = [
                {
                    actionInstance: new UpdateFontToMontSerratService(),
                    availableInEditMode: true,
                    availableInPromptMode: true,
                    availableInViewMode: true
                },
                {
                    actionInstance: new UpdateReturnOfResultAlignmentActionScript(),
                    availableInEditMode: false,
                    availableInPromptMode: false,
                    availableInViewMode: true
                },
            ];

            function getAllAvailableActions(formMode) {
                if(formMode == null){
                    return null;
                }
                var availableActionsForCurrentMode = [];
                for (var i = 0; i < availableActions.length; i++) {
                    var action = availableActions[i];
                    if ((formMode.nodeType == FormConstants.nodeType.MODE.EDIT && action.availableInEditMode)
                        || (formMode.nodeType == FormConstants.nodeType.MODE.PROMPT && action.availableInPromptMode)
                        || (formMode.nodeType == FormConstants.nodeType.MODE.VIEW_ENTRY && action.availableInViewMode)) {
                        availableActionsForCurrentMode.push(action.actionInstance);
                    }
                }
                return availableActionsForCurrentMode;
            }

            /***************************************
             * service return call
             ***************************************/

            return {
                getAllAvailableActions: getAllAvailableActions
            };
        });

})();
