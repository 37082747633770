(function () {
    'use strict';

    /***************************************************************
     * utility for loading expression condition from server, the input object should have the following property and prototype function
     *  -- conditionId (property, a number)
     *  -- getExpressionCondition()
     *  -- setExpressionCondition(expressionCondition)
     ***************************************************************/

    angular.module('acadiamasterApp').factory('ExpressionConditionLoadUtil', function (ExpressionConditionService, AlertService) {

        return {
            loadExpressionCondition: loadExpressionCondition
        };

        /**
         * load expression condition from server if needed for the container object
         * @param container - container object that contains a field for expression condition id and function for get/set expression condition
         * @param onLoadSuccess - function(expression), on load success callback when loading is completed
         * @param onLoadFailed - function(error), on load failed callback when loading failed
         */
        function loadExpressionCondition(container, onLoadSuccess, onLoadFailed) {
            if (requiredFunctionNotDefined(container)) {
                AlertService.error('container with expression condition is not valid', null, container);
                return;
            }

            var conditionId = container.conditionId;
            if (conditionId == null) {
                // nothing to load
                return;
            }

            var expressionCondition = container.getExpressionCondition();

            if (expressionCondition != null && expressionCondition.id === conditionId) {
                // already loaded, do nothing
                return;
            }

            ExpressionConditionService.getConditionById(conditionId).then(function (response) {
                container.setExpressionCondition(response.data);

                if (_.isFunction(onLoadSuccess)) {
                    onLoadSuccess(response.data);
                }
            }).catch(function (error) {
                AlertService.error('failed to load expression condition by id : ' + conditionId, null, error);

                if (_.isFunction(onLoadFailed)) {
                    onLoadFailed(error);
                }
            });
        }

        function requiredFunctionNotDefined(container) {
            return !_.isFunction(container.getExpressionCondition) || !_.isFunction(container.setExpressionCondition);
        }

    });
})();
