/**
 *
 */
(function () {
    'use strict';
    angular.module('acadiamasterApp')
        .factory('ScheduleRuleExecutionAuditsService', function ($resource, localWeb, $http) {
            const url = localWeb.createUrl('api/businessRules/scheduleRuleExecutionAudits');

            /**
             * GET /api/businessRules/scheduleRuleExecutionAudits?id=1&scheduleName=w&businessRuleId=1&evaluationExpressionId=1&programId=1
             */
            const scheduleRuleExecutionAudits = $resource(url, {}, {
                'query': {method: 'GET', isArray: true}
            });

            /**
             * GET /api/businessRules/scheduleRuleExecutionAudits/{auditId}/pages?id=1&pageNumber=1&failedPagesOnly=false
             */
            scheduleRuleExecutionAudits.getPageAudits = function (auditId, params) {
                return $http.get(localWeb.createUrl(`/api/businessRules/scheduleRuleExecutionAudits/${auditId}/pages`), {
                    params,
                });
            };

            return scheduleRuleExecutionAudits;

        })
        ;
})();
