/**
 * Created by kylemills on 5/11/18.
 */

(function(){
    'use strict';
    angular.module('acadiamasterApp')
        .factory('DataTypeSearch', ['$resource', 'localWeb', function ($resource, localWeb) {
            return $resource(localWeb.createUrl('api/dataTypes/name/:name'), {}, {
                'query': {method: 'GET', isArray: true}
            });
        }])
        .factory('DataTypeUniqueByName', ['$resource', 'localWeb', function ($resource, localWeb) {
            return $resource(localWeb.createUrl('api/dataTypes/uniquebyname/:name'), {}, {
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                }
            });
        }])
        .factory('DataTypeAdvancedSearch', ['$http', 'localWeb', function ($http, localWeb) {
            var url = localWeb.createUrl('api/dataTypes/advancedSearch?');
            return {
                'query': function (searchFilter) {
                    return $http({
                        url: url + $.param(searchFilter),
                        method: 'GET'
                    }).then(function (response) {
                        return response;
                    }, function (error) {
                        return error
                    });
                }
            }
        }])
        .factory('DataTypeByName', ['$http', 'localWeb', function ($http, localWeb) {
            var url = localWeb.createUrl('api/dataTypes/nameAvailable/');
            return {
                'get': function (dataTypeName, dataTypeId) {
                    var finalUrl = url + dataTypeName;
                    if (dataTypeId != null) {
                        finalUrl += "?dataTypeId=" + dataTypeId;
                    }
                    return $http.get(finalUrl);
                }
            };
        }])
})();
