'use strict';


angular.module('acadiamasterApp').directive('programTestSearch', function (ParseLinks, ngDialog, ProgramTestService) {
    return {
        restrict: 'E',
        templateUrl: 'admin-templates/site/programTests/programTestSuite/directive/programTestSearch.html',
        link: function (scope) {
            scope.selectedTests = [];
            scope.search = {
                programTests: [],
                searchComplete: false,
                searchString: "",
                programId: scope.programId,
                page: 0,
                pageSize: 20,
                links: null
            };
            scope.controls = {
                parent: scope,
                loadPage: function (page) {
                    console.log("In load page");
                    return loadPage(this.parent, page);
                },
                searchContents: function () {
                    return searchTests(this.parent);
                },
                save: function () {
                    return save(scope);
                },
                clear: function () {
                    return clear();
                },
                isAssociatedTag : function(id){
                    return isAssociatedTag(scope,id);
                }
            };
            loadPage(scope, scope.search.page);
        }
    };
    /*************************************************************************************
     * private functions
     */
    function save(scope) {
        scope.confirm(scope.selectedTests);
    }

    function clear() {
        ngDialog.close(null);
    }

    function loadPage(scope, page) {
        scope.search.page = page;
        searchTests(scope);
    }

    function isAssociatedTag(scope, id){
        for(let test in scope.associatedTestCases){
            if(scope.associatedTestCases[test].programTest && scope.associatedTestCases[test].programTest.id === id){
                return true;
            }
        }
    }

    function searchTests(scope) {
        scope.search.searchComplete = false;
        scope.selectedTests = [];
        var searchFilter = {
            searchString: scope.search.searchString,
            programId: scope.search.programId,
            exactMatch: false,
            page: scope.search.page,
            size: scope.search.pageSize
        };
        var promise = ProgramTestService.getAllTestCase({
            page: searchFilter.page,
            size: searchFilter.size,
            searchString: searchFilter.searchString,
            programId: searchFilter.programId
        });
        return promise.then(function (result) {
            scope.search.testCases = [];
            scope.search.links = ParseLinks.parse(result.headers('link'));
            var data = result.data;
            if (result.data) {
                scope.search.programTests = [];
                for (var i = 0; i < data.length; i++) {
                    scope.search.programTests[i] = data[i];
                }
            }
            scope.search.searchComplete = true;
        });
    }
});




