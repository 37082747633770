/**
 * created by Jason Cao on 9/11/2019
 * directive for upload and cropping an image file
 * todo: refactor the code in program category branding to use this directive later
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrImageCropNew', function (localWeb, ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/common/imageCrop/vbrImageCropNew.html',
                scope: {
                    // @required function to get existing image url, this url should be coming from server
                    getImageUrl: '=',
                    // @required function to get existing image uri, this is normally the newly added/change image in data uri format
                    getImageUri: '=',
                    // @required function to be invoked when image is removed
                    imageRemovedCallBack: '=',

                    // @required function to be invoked when image cropping is finished, it takes two parameters,
                    // 1st param -- type: DataURI, the cropped image uri
                    // 2nd param -- type: File, the actual file uploaded
                    imageCroppedCallBack: '=',
                    // @required crop area type, ie: 'circle', 'rectangle', 'square'
                    cropAreaType: '@',
                    // @optional aspect ratio restriction, ie: if we want the crop to be 2x1 (w x h), then use 2 here
                    aspectRatio: '=',
                    // @required result image size in both height and width, ie: if we want the result image to be 1080 x 500, use
                    // {w: 1080, h: 500}
                    resultImageSize: '=',
                },
                link: function ($scope) {
                    /**
                     * get either a data uri or server side url for image, uri take precedence over server side url
                     * @returns {*} - data uri or server url, can be used
                     */
                    $scope.getUrlOrUri = function() {
                        let uri = $scope.getImageUri();
                        if (uri) {
                            return uri;
                        }

                        let url = $scope.getImageUrl();

                        if (url) {
                            url = '/api/file?fileName=' + url + '&isThumbnail=false';
                            url = localWeb.createUrl(url);
                            return url;
                        }

                        return null;
                    };

                    /**
                     * check if image exist
                     * @returns {boolean}
                     */
                    $scope.hasImage = function() {
                        return $scope.getUrlOrUri() != null;
                    };

                    /**
                     * function to be called when image is being removed
                     */
                    $scope.removeImage = function() {
                        $scope.imageRemovedCallBack();
                    };

                    $scope.showEditWindow = function () {
                        let _scope = $scope;

                        let template = '<image-crop-window ' +
                            'crop-area-type="cropAreaType" ' +
                            'aspect-ratio="aspectRatio" ' +
                            'result-image-size="resultImageSize" ' +
                            'image-cropped-call-back="imageCroppedCallBack" ' +
                            'close-function="closeFunction"></image-crop-window>';

                        // open modal window and pass in all the scope variables
                        let dialog = ngDialog.open({
                            showClose: false,
                            closeByEscape: false,
                            closeByDocument: false,
                            template: template,
                            plain: true,
                            controller: ['$scope', function ($scope) {
                                $scope.imageCroppedCallBack = _scope.imageCroppedCallBack;
                                $scope.cropAreaType = _scope.cropAreaType;
                                $scope.aspectRatio = _scope.aspectRatio;
                                $scope.resultImageSize = _scope.resultImageSize;

                                $scope.closeFunction = closeDialog;
                            }],
                            className: 'ngdialog-theme-plain custom-width-large'
                        });

                        function closeDialog() {
                            dialog.close();
                        }
                    };
                }
            };

        });

})();

