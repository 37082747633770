(function () {

    'use strict';

    angular.module('acadiamasterApp')
        .controller('AppVersioningDetailsController', function ($scope, id, AppVersioningService, AlertService) {

            function initController() {
                if (id && !isNaN(id)) {
                    $scope.isUpdate = true;

                    AppVersioningService.getAppVersionDetails(id).then(
                        function (response) {
                            $scope.appVersion = response.data;
                        },
                        function (error) {
                            AlertService.error('Unable to retrieve app version detail', null);
                            console.error("failed to retrieve app version detail", error);
                            $scope.closeThisDialog('ERROR');
                        });
                }
            }

            $scope.saveAppVersion = function () {
                AppVersioningService.createUpdateAppVersion($scope.appVersion).then(
                    function (response) {
                        AlertService.success("New App Version Added", null);
                        console.log('app version added, response : ', response);
                        $scope.closeThisDialog('OK');
                    }
                );
            };

            $scope.applicationOS = ["Android", "iOS"];
            initController();

        });
})();