angular.module('acadiamasterApp')
    .config($stateProvider => {
        $stateProvider
            .state('logout', {
                parent : 'account',
                url    : '/logout',
                data   : {
                    authorities : [],
                },
                views : {
                    'content@' : {
                        templateUrl : 'admin-templates/site/main.html',
                        controller  : 'LogoutController',
                    },
                },
            });
    });
