/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form state rule
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultStateRuleService',
        function (CompareResultUtilService, CompareResultStateRuleTriggerService, CompareResultStateRuleConditionService) {

            function compareRuleDetail(field1, field2) {
                var result = {};

                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "localId", result);
                CompareResultUtilService.comparePrimitivesByKey(field1, field2, "name", result);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            function getTriggerList(rule) {
                if (rule==null || rule.triggerListModel==null) {
                    return null;
                }

                return rule.triggerListModel.triggerList
            }

            function getConditionList(rule) {
                if (rule==null || rule.conditionListModel==null) {
                    return null;
                }

                return rule.conditionListModel.conditionWithActionsList
            }

            function compare(rule1, rule2) {
                var title;
                if (rule1 == null) {
                    rule1 = {};
                    title = "Rule " + rule2.localId;
                }
                else {
                    title = "Rule " + rule1.localId;
                }

                if (rule2 == null) {
                    rule2 = {};
                }


                // add name to title
                if (rule2.name != null) {
                    title += " (" + rule2.name + ")";
                }
                else if (rule1.name != null) {
                    title += " (" + rule1.name + ")";
                }


                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareRuleDetail(rule1, rule2)
                };


                // collect triggers information if needed
                var triggers1 = getTriggerList(rule1);
                var triggers2 = getTriggerList(rule2);

                if (CompareResultUtilService.isValueAvailable(triggers1) || CompareResultUtilService.isValueAvailable(triggers2)) {
                    result.triggers = CompareResultUtilService.compareArrays(triggers1, triggers2, title,
                        CompareResultStateRuleTriggerService.compare, "index")
                }

                result._triggersStatus = CompareResultUtilService.collectStatus(result.triggers);


                // collect conditions information if needed
                var conditions1 = getConditionList(rule1);
                var conditions2 = getConditionList(rule2);

                if (CompareResultUtilService.isValueAvailable(conditions1) || CompareResultUtilService.isValueAvailable(conditions2)) {
                    result.conditions = CompareResultUtilService.compareArrays(conditions1, conditions2, title,
                        CompareResultStateRuleConditionService.compare);
                }

                result._conditionsStatus = CompareResultUtilService.collectStatus(result.conditions);


                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

