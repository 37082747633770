/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */

angular.module('acadiamasterApp')
    .factory('ProgramCategory', ($resource, $http, Upload, localWeb) => {
        let programCategory = $resource(localWeb.createUrl('api/programCategory/:id'), {}, {
            get : {
                method            : 'GET',
                transformResponse : function (data) {
                    data = angular.fromJson(data);
                    console.log(data);
                    return data;
                },
            },
            query : { isArray : true, method : 'GET' },
            // 'update': { method:'PUT' }
        });

        programCategory.createProgramCategory = function (data, onSaveFinished, onSaveError) {
            createEdit('/api/programCategory', data, onSaveFinished, onSaveError);
        };

        programCategory.updateProgramCategory = function (data, onSaveFinished, onSaveError) {
            createEdit('/api/programCategory/edit', data, onSaveFinished, onSaveError);
        };

        programCategory.searchProgramCategorys = function (filter) {
            console.log(filter);
        };

        function createEdit (url, data, onSaveFinished, onSaveError) {
            // send them all together for HTML5 browsers:
            Upload.upload({
                arrayKey : '',
                // to allow files array to upload with param name file instead of file[i]
                data     : {
                    data : JSON.stringify(data),
                },
                url : localWeb.createUrl(url),
            }).then(resp => {
                onSaveFinished(resp);
            }, error => {
                onSaveError(error);
            });
        }

        return programCategory;
    })
    .factory('ProgramCategorySearch', ($http, localWeb) => {
        let url = localWeb.createUrl('api/programCategory/filter/');

        return {
            query : function (searchFilter) {
                return $http({
                    data    : JSON.stringify(searchFilter),
                    headers : { 'Content-Type' : 'application/json' },
                    method  : 'POST',
                    url     : url,
                }).then(response => {
                    return response.data;
                }, error => {
                    return error;
                });
            },
        };
    });
