/**
 * Created by Jamie Nola on 02/20/2020
 *
 * Model for an deep link's context attribute
 */

angular.module('acadiamasterApp')

    .factory('DeepLinkContextModel', (ModelServiceBase, DeepLinkConstants) => {

        /**
         * @constructor
         */
        function DeepLinkContextModel(parent) {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false);
            this.formId = null;
            this.page = null;
            this.pageId = null;
            this.parent = parent;
            this.containerId = null;
            this.containerItemId = null;
            this.workFlowName = null;
            this.parameter = null;
        }

        /**
         * Convert the current UI model to DTO format
         */
        DeepLinkContextModel.prototype.toDto = function toDto(targetType) {
            const dto = {};

            dto['@class'] = DeepLinkConstants.CONTEXT_CLASSES[targetType];
            dto.formId = this.formId;
            dto.page = this.page;
            dto.pageId = this.pageId;
            dto.containerId = this.containerId;
            dto.containerItemId = this.containerItemId;
            dto.workFlowName = targetType === 'WORKFLOW' ? DeepLinkConstants.WORKFLOW : null;
            dto.parameter = targetType === 'CONTENT_CONTAINER' ? DeepLinkConstants.CONTENT_CONTAINER : null;
            return dto;
        };

        /**
         * Convert the DTO object into current object.
         * This function will wipe out any information you have on the current object
         * @param dto
         */
        DeepLinkContextModel.prototype.fromDto = function fromDto(dto) {
            this.formId = dto.formId;
            this.page = dto.page;
            this.pageId = dto.pageId;
            this.containerItemId = dto.containerItemId;
            this.containerId = dto.containerId;
            this.workFlowName = dto.workFlowName;
            this.parameter = dto.parameter;
            return this;
        };

        /****************************************
         * service return call
         ****************************************/
        return DeepLinkContextModel;
    });
