'use strict';
(function () {
    angular.module('acadiamasterApp').factory('ProgramLinkageService', function ($http, localWeb) {
        return {

            /**
             * Finds out programs are associated with the given form id, either directly and/or directly/indirectly via business rules.
             * @param formId Id of the form
             */
            getProgramsLinkedToForm: function (formId) {
                return $http.get(localWeb.createUrl('api/forms/associatedPrograms/' + formId));
            },
        };
    });
})();
