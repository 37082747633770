// eslint-disable-next-line angular/controller-name
angular.module('acadiamasterApp')
    .controller('createOrderConfigController', function (OrderTypeService) {
        let vm = this;
        vm.onOrderTypeUpdated = function () {
            vm.correctiveAction.orderType = vm.data.orderType;
        };


        vm.$onInit = () => {
            vm.orderTypes = OrderTypeService.getOrderTypes();

            vm.data = {
                orderType            : vm.correctiveAction.orderType || Object.keys(vm.orderTypes)[0],
                possibleOrderTypes   : Object.keys(vm.orderTypes),
            };

            vm.correctiveAction.orderType = vm.data.orderType;
        };
    });
