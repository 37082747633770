(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionTrackChange', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/action/trackChange/track.change.html',
                scope: {
                    action: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                }
            };
        });
})();