'use strict';

// TODO: Support for filters by root category, category and multiple selection are added to this directive
// TODO: These things are not implemented yet. UI/Handling/WS for above need to be implemented if you are planning to use any of the above features

angular.module('acadiamasterApp')
    .directive('datatypesearch', function(DataTypeSearch) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataType-search.html',
            scope: {
                callbackMethod: '&',
                filterByRootCategory: '=',
                filterByCategory: '=',
                selectMultiple: '='
            },
            link: function(scope) {
                scope.search = {
                    dataTypes: [],
                    searchComplete: false,
                    dataTypeName: ""
                };

                scope.selectedDataTypes = []; // To be used when select multiple is true
                scope.searchDataTypes = searchDataTypes;
                scope.selectDataType = selectDataType;

                function selectDataType(id) {
                    scope.callbackMethod({
                        id: id
                    });
                }

                function searchDataTypes() {
                    scope.search.searchComplete = false;
                    if(scope.search.dataTypeName!= "") {
                        DataTypeSearch.query({
                            name: scope.search.dataTypeName
                        }, function(result, headers) {
                            scope.search.dataTypes = [];
                            for (var i = 0; i < result.length; i++) {
                                scope.search.dataTypes[i] = result[i];
                            }
                            scope.search.searchComplete = true;
                        });
                    }
                }
            }
        };
    });
