
angular.module('vibrent-external-survey-parser')
    .service('VibrentFormSkippingLogicService', (FormModelServiceForm, ExpressionParserService, CommonParserUtilService, FormVersion, AlertService) => {
        const commonParser = CommonParserUtilService;

        function saveSkippingLogic (formModel, successCallBack) {
            let startSavingForm = performance.now();
            FormModelServiceForm.saveForm([], formModel, resp => {
                commonParser.logMessage(`Total time to save the form ${(performance.now() - startSavingForm).toFixed(3)} ms`);
                resolveSkippingLogic(resp, successCallBack);
            });
        }

        function saveForm(formModel, successCallBack) {
            FormModelServiceForm.saveForm([], formModel, resp => {
                if (_.isFunction(successCallBack)) {
                    successCallBack(resp);
                }
            });
        }

        function resolveSkippingLogic (response, successCallBack) {
            let formDTO = response.data;

            let lookupArray = parseFieldsFromVibrentForm(formDTO);
            let skipConditionArray = getSkipLogicFromVibrentForm(formDTO);
            let startTime = performance.now();
            commonParser.logMessage(`lookup Array ${lookupArray.length} vs skipConditionArrays ${skipConditionArray.length}`);
            _.each(skipConditionArray, (skipElement, skipConditionIndex) => {
                let isQuestion = _.has(skipElement, 'advancedVisibilityConfig.conditionText');
                let conditionText = _.get(skipElement, 'advancedVisibilityConfig.conditionText');
                // also get navigation edge logic
                conditionText = conditionText !== undefined ? conditionText : _.get(skipElement, 'conditionText');
                commonParser.logMessage(`Condition Text ${JSON.stringify(skipElement)}`);
                commonParser.logMessage(`Condition Text-Before: ${conditionText} with index ${skipConditionIndex} isQuestion ${isQuestion}`);
                let resolveCondition = resolveField(conditionText, lookupArray);

                commonParser.logMessage(`Condition Text-After: ${resolveCondition} isQuestion ${isQuestion}`);
                let expressionModel = ExpressionParserService.parse(resolveCondition, 'State Rules', skipElement);
                if (expressionModel) {
                    if (expressionModel.hasError()) {
                        commonParser.logMessage(`Expression model error--Ignore ${expressionModel.getError()}`);
                        AlertService.warning(`Unable to resolve skipping logic for ${resolveCondition} due to \n ${expressionModel.getError()}`);
                    } else if (isQuestion) {
                        skipElement.advancedVisibilityConfig.conditionText = resolveCondition;
                        skipElement.advancedVisibilityConfig.conditionTree = expressionModel.toDto();
                    } else {
                        skipElement.conditionText = resolveCondition;
                        skipElement.conditionTree = expressionModel.toDto();
                    }
                }
            });
            let endTime = performance.now();
            console.log(`Total time to resolve skipping logic ${(endTime - startTime).toFixed(3)} ms`);

            let formModel = new FormModelServiceForm.FormModel(null, null, null);
            formModel.fromDto(formDTO);
            FormModelServiceForm.saveForm([], formModel, resp => {
                let startVersionTime = performance.now();
                commonParser.logMessage(`Total time to performance second save ${(startVersionTime - endTime).toFixed(3)} ms`);
                let formDTO = resp.data;
                // activate second version and update with new comment
                let versionId = formDTO.formVersions[0].id;
                FormVersion.activate(formDTO.id, versionId).then(() => {
                    FormVersion.updateVersionComment({
                        id              : versionId,
                        isActive        : formDTO.formVersions[0].isActive,
                        semanticVersion : formDTO.formVersions[0].semanticVersion,
                        versionComment  : 'This version has resolved skipping logic form',
                    }).then(() => {
                        commonParser.logMessage(`Complete saving second time along with new version ${(performance.now() - startVersionTime).toFixed(3)} ms`);
                        if (_.isFunction(successCallBack)) {
                            successCallBack(resp);
                        }
                    });
                });
            });
        }

        function parseFieldsFromVibrentForm (json) {
            commonParser.logMessage('--> Retrieving fields with skip logic');

            let fields = [];
            if (json == null || json.formVersions == null || json.formVersions.length <= 0) {
                return fields;
            }

            let version = json.formVersions[0];
            let editMode = version.editMode;

            _.each(editMode.pages, page => {
                _.each(page.sections, section => {
                    _.each(section.formComponents, component => {
                        _.each(component.formComponentFields, field => {
                            if (skipFieldType(field.type)) {
                                commonParser.logMessage(`----> Skipping field ${field.name}. Field type ${field.type} is marked for ignoring.`);
                                if (field.contentDescription !== null || field.contentDescription !== undefined) {
                                    field.contentDescription = null;
                                }
                                return;
                            }
                            let fieldName = `${field.name}`;
                            let fieldValueType = field.fieldValue.valueType != null ? field.fieldValue.valueType.toLowerCase() : null;
                            let f = {
                                fieldId   : field.formField.id,
                                fieldName : fieldName,
                                tempLogic : `fieldEntryValueInCurrentView (${fieldName})`,
                                type      : field.type.toLowerCase(),
                                valueType : fieldValueType,
                                VBLogic   : `fieldEntryValueInCurrentView (${field.formField.id})`,
                            };
                            if (field.contentDescription !== null || field.contentDescription !== undefined) {
                                // carry over redCap variable name into field name
                                field.name = fieldName;
                                field.contentDescription = null;
                            }
                            fields.push(f);
                        });
                    });
                });
            });

            commonParser.logMessage('----> List of fields read from Vibrent form');
            _.each(fields, field => {
                commonParser.logMessage(`------> ${JSON.stringify(field)}`);
            });
            commonParser.logMessage('----> End of list of fields read from Vibrent form');

            return fields;
        }

        function skipFieldType (questionType) {
            if (questionType == null) {
                return true;
            }
            return questionType !== 'TEXT_INPUT'
                && questionType !== 'MULTI_SELECTOR'
                && questionType !== 'RADIO_SELECTOR'
                && questionType !== 'DROPDOWN'
                && questionType !== 'NUMBER_INPUT'
                && questionType !== 'SLIDER';
        }
        /**
         * retrieve skipping logic from vibrent form. The json still has redcap logic reference
         */
        function getSkipLogicFromVibrentForm (vibrentJson) {
            let fields = [];
            if (vibrentJson == null || vibrentJson.formVersions == null || vibrentJson.formVersions.length <= 0) {
                return fields;
            }

            let version = vibrentJson.formVersions[0];
            let editMode = version.editMode;
            let getSkippingLogic = component => {
                let isQuestion = _.has(component, 'displayConfig');
                let skipLogicValue = _.get(component.displayConfig, 'advancedVisibilityConfig.conditionText');
                // handle navigation or branch logic
                skipLogicValue = skipLogicValue !== undefined ? skipLogicValue : _.get(component, 'conditionText');
                commonParser.logMessage(`skippingLogic1 ${skipLogicValue} and isQuestion ${isQuestion}`);
                if (typeof skipLogicValue !== 'undefined' && skipLogicValue) {
                    if (isQuestion) {
                        fields.push(component.displayConfig);
                    } else {
                        fields.push(component);
                    }
                }
            };

            _.each(editMode.pages, page => {
                _.each(page.sections, section => {
                    getSkippingLogic(section);
                    _.each(section.formComponents, component => {
                        getSkippingLogic(component);
                        _.each(component.formComponentFields, formComponentField => {
                            getSkippingLogic(formComponentField);
                        });
                    });
                });
            });
            commonParser.logMessage(`---->Number of fields that have skip logic ${fields.length}`);
            // handle navigation or branch logic
            _.each(editMode.navigationEdges, edge => {
                getSkippingLogic(edge);
            });
            commonParser.logMessage(`---->Number of fields and branch that have skip logic ${fields.length}`);
            return fields;
        }

        /**
         * Replace raw question name or id with vibrent form ID from lookup Array
         * @param unresolvedSkipLogic skip logic with raw question id or name
         * @param lookupArray array which contains component id to resolve skipping logic
         */
        function resolveField (unresolvedSkipLogic, lookupArray) {
            // hold element in array that contain unresolved skipping logic
            // or ([eq (toString (fieldEntryValueInCurrentView (csb1)), "csb1_ABCD"),eq (toString (fieldEntryValueInCurrentView (csb2)), "csb2_1")])
            let matchArray = _.filter(lookupArray, element => {
                return unresolvedSkipLogic.includes(element.tempLogic);
            });
            let result = unresolvedSkipLogic;
            _.each(matchArray, matchElement => {
                commonParser.logMessage(`Before ${result}`);
                let escapeReg = _.escapeRegExp(`${matchElement.tempLogic}`);
                let regex = new RegExp(`${escapeReg}`, 'g');
                result = result.replace(regex, matchElement.VBLogic);
                commonParser.logMessage(`After replacing field: ${result}`);
                if (matchElement.valueType === 'number') {
                    result = result.replaceAll(`toString (${matchElement.VBLogic})`, `toDouble (${matchElement.VBLogic})`);
                    commonParser.logMessage(`After replacing valueType: ${result}`);
                }
                // remove suffix question
                let removeQuote = (text, fieldValueType) => {
                    let regex = new RegExp(`(\"${matchElement.fieldName}\\|\\S\*")`, 'g');
                    let matchValueArray = text.match(regex);
                    _.each(matchValueArray, value => {
                        let endIndex = value.lastIndexOf('"');
                        value = value.substring(2 + matchElement.fieldName.length, endIndex);
                        commonParser.logMessage(`--->Text ${text}-->Value ${value} --> ${fieldValueType}`);
                        if (fieldValueType === 'number') {
                            text = text.replace(`"${matchElement.fieldName}|${value}"`, value);
                        } else {
                            // handle if value is blank
                            value = value.trim();
                            if (value === '') {
                                text = text.replace(`"${matchElement.fieldName}|${value}"`, 'null');
                            } else {
                                text = text.replace(`"${matchElement.fieldName}|${value}"`, `"${value}"`);
                            }
                        }
                    });
                    return text;
                }; // end of removeQuote
                result = removeQuote(result, matchElement.valueType);
            });
            return result;
        }
        /**
         * service return
         */
        return {
            resolveField      : resolveField,
            saveSkippingLogic : saveSkippingLogic,
            saveForm: saveForm,
        };
    });
