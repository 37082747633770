(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('propertyCorrectiveAction', function () {
        return {
            restrict: 'E',
            template: '<property-selection ng-if="propertyOptions" is-required="true" ' +
            'property-options="propertyOptions" should-set-compare-value="true" title="Select Property"></property-selection>',
            scope: {
                correctiveAction: '='
            },
            link: function ($scope) {
                $scope.propertyOptions = {
                    getProperty : function() {
                        return $scope.correctiveAction.getProperty();
                    },
                    setProperty : function(newValue) {
                        $scope.correctiveAction.setProperty(newValue);
                    },
                    getPropertyValues : function() {
                        var value = $scope.correctiveAction.getPropertyValueAsProperType();
                        return value == null ? null : [value];
                    },
                    setPropertyValues : function(newValues) {
                        var value = newValues==null || newValues.length==0 ? null : newValues[0];

                        $scope.correctiveAction.setPropertyValue(value);
                    }
                };
            }
        };
    });
})();