/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one page
     */
        .directive('treeNodeFormPage', function (ngDialog, AlertService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/formPage.html',
                scope: {
                    page: '=',
                    selector: '=',
                    currentTreeName : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.node = $scope.page._parent.findNodeByPageLocalId($scope.page.localId);

                    $scope.flags = {
                        dropDownIsOpen : false
                    };

                    /**
                     * check if page can be modified
                     */
                    $scope.canModifyPages = function() {
                        return $scope.page._parent.canModifyPages() &&
                            $scope.page.formPageType != FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.STOP_DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_CAPTURE;
                    };


                    /**
                     * add a section to the page
                     */
                    $scope.addSection = function() {
                        $scope.page.addSection(null, $scope.selector, $scope.currentTreeName);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * clone the page, insert it right below the current page
                     */
                    $scope.clonePage = function() {
                        var page = $scope.page;
                        page._parent.clonePage(page, $scope.selector, $scope.currentTreeName);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * cut the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCut = function() {
                        $scope.selector.cutItem($scope.page);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * delete current page
                     */
                    $scope.deletePage = function() {
                        // check if there are edges coming in and out of this page that has condition on it
                        // (ie. those conditions are time-consuming to create, should provide a warning before deleting it)

                        var edgeWithCondition = _.find(this.node._edgesFrom, function(e) {
                            return e.hasCondition();
                        });

                        if (edgeWithCondition==null) {
                            edgeWithCondition = _.find(this.node._edgesTo, function(e) {
                                return e.hasCondition();
                            });
                        }


                        if (edgeWithCondition==null) {
                            // we can safely remove the page
                            $scope.page._parent.removePage($scope.page, $scope.selector);
                        }
                        else {
                            // we need to put up a warning dialog
                            ngDialog.openConfirm({
                                template: 'page.delete.confirmation.template.html',
                                controller : ['$scope', function ($scope) {
                                    $scope.edgeWithCondition = edgeWithCondition;
                                }]
                            }).then(function (reason) {
                                console.log('window closed with reason : ', reason);
                                if (reason==='DELETE') {
                                    // user has confirmed for deletion, remove the page and node and edges associated with it
                                    $scope.page._parent.removePage($scope.page, $scope.selector);
                                }
                            });
                        }


                        $scope.flags.dropDownIsOpen = false;
                    };

                    $scope.canAddSection = function() {
                        return $scope.page.formPageType != FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.STOP_DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_CAPTURE ;

                    };

                    $scope.shouldShowMenu = function() {
                        return ($scope.page.formPageType != FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.STOP_DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_SHARING
                            && $scope.page.formPageType != FormConstants.formPageDiffTypes.DATA_CAPTURE)
                            || $scope.page._parent.pages.length > 1;
                    };

                    /**
                     * check if the current item in buffer can be pasted as a child
                     * @returns {boolean}
                     */
                    $scope.canPasteAsChild = function() {
                        if ($scope.page.formPageType == FormConstants.formPageDiffTypes.ADDRESS_VERIFICATION
                            && $scope.page.formPageType == FormConstants.formPageDiffTypes.DATA_SHARING) {
                            return false;
                        }
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not already a child
                        return itemInBuffer.nodeType == FormConstants.nodeType.SECTION &&
                            itemInBuffer._parent != $scope.page;
                    };


                    /**
                     * paste the last element copied or cut as a child
                     * ie: this element copied of cut must be a form section
                     */
                    $scope.doPasteAsChild = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;
                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeSection(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to this element
                        elementInBuffer._parent = $scope.page;

                        // add it to the top of the children array
                        $scope.page.sections.unshift(elementInBuffer);
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * check if the current item in buffer can be pasted as a sibling
                     * @returns {boolean}
                     */
                    $scope.canPasteBelowAsSibling = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not the same as current section
                        return itemInBuffer.nodeType == FormConstants.nodeType.PAGE &&
                            itemInBuffer != $scope.page;
                    };


                    /**
                     * paste the last element copied or cut as a sibling
                     * ie: this element copied of cut must be a form page
                     */
                    $scope.doPasteBelowAsSibling = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        var pages = $scope.page._parent.pages;

                        // find the index of the item to be moved
                        var cutIndex = pages.indexOf(elementInBuffer);

                        if (cutIndex == -1) {
                            // strange behavior, can't find the cut element, maybe it's from another form mode,
                            // not allowed at this time
                            AlertService.warning("moving page from one form mode to another is not allowed!");
                            return;
                        }

                        // remove the item at previous position
                        pages.splice(cutIndex, 1);

                        // add it to the next spot in the children array
                        var currentPageIndex = pages.indexOf($scope.page);

                        // put it below the current page
                        pages.splice(currentPageIndex+1, 0, elementInBuffer);

                        // select the new item
                        $scope.selector.selectItem(elementInBuffer);

                        // notify the selector item has been pasted so it can empty its cut buffer
                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * getting the page node description html
                     */
                    $scope.getPageNodeDescription = function() {
                        var message = '';
                        var page = $scope.page;
                        var node = $scope.node;

                        if (page.formPageType == FormConstants.formPageDiffTypes.NORMAL && page.sections.length > 0) {
                            message += '<span class="badge badge-inverse">' + page.sections.length + '</span>';
                        }

                        message += '<span class="text-primary">' + page.getDescriptionAsHtml() + '</span>';

                        if (node.isStartNode()) {
                            message += '<span class="badge badge-success">Start Node</span>';
                        }
                        else {
                            var isEndNode = node.isEndNode();

                            if (isEndNode === 'maybe') {
                                message += '<span class="badge badge-warning" title="this only checks if there are no unconditioned nodes out of this node">Potential End Node</span>';
                            }
                            else if (isEndNode === 'yes') {
                                message += '<span class="badge badge-danger">End Node</span>';
                            }
                        }

                        return message;
                    };
                }
            };
        });
})();

