(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('testCaseAuditDetail', function (ProgramTestExecutionAudit, AlertService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTestExecutionAudit/testCaseAuditDetail.html',
            scope: {
                // id of the test case audit
                id: '='
            },
            link: function ($scope) {
                $scope.audit = null;

                init($scope);
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/

        function init($scope) {
            ProgramTestExecutionAudit.get({id: $scope.id}).$promise.then(function (data) {
                $scope.audit = data;
            }).catch(function (error) {
                AlertService.error('Unable to load audit detail by id : ' + $scope.id, null, error);
            });
        }
    });
})();

