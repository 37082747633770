import cs from '../const/programContainer.constants.service';
/**
 * Created by Jamie Nola on 09/07/2019
 *
 * directive for configuring a container
 */
angular.module('acadiamasterApp')
    .directive('programContainerConfig', (ngDialog, $timeout, ExpressionConstantsService,
        LocaleConstantsService) => ({
        bindToController : true,
        controller () {
            const vm = this;

            vm.toggleBasicInfo = () => {
                vm.data.accordionBasicInfo.collapsed = !vm.data.accordionBasicInfo.collapsed;
            };

            vm.getTabName = tab => {
                return tab.displayName?.en || tab.name;
            };

            vm.getImageUrl = () => {
                return vm.container.icon.getImageUrl();
            };

            vm.getImageUri = () => {
                return vm.container.icon.getImageUri();
            };

            vm.imageRemoved = () => {
                return vm.container.icon.imageRemoved();
            };

            vm.isAppTypeSupported = () => {
                return cs.hasAppTypeSupportedByLvl(vm.container.containerType, true);
            };

            vm.imageCropped = (imageUri, imageFile) => {
                return vm.container.icon.imageCropped(imageUri, imageFile);
            };

            vm.changePageId = () => {
                vm.changePageIdCallback(vm.container, vm.data.pageId);
            };

            vm.containerTypeChanged = value => {
                if (vm.container) {
                    // calling the setter to do
                    vm.container.setContainerType(value);
                }

                const type = cs.findTypeByName(value);
                let showBehavior = false;
                let showForms = false;
                let showReferences = false;
                let optionalHeader = false;
                let showHybridAssociations = false;
                let canVerticalStack = false;
                let appTypeSupported = false;
                if (type) {
                    showBehavior = type.displayConfigStates != null && type.displayConfigStates.length > 0;
                    if (type.sections) {
                        showForms = type.sections.indexOf(cs.sections.formAssociations) > -1;
                        showReferences = type.sections.indexOf(cs.sections.containerReferences) > -1;
                        showHybridAssociations = type.sections.indexOf(cs.sections.hybridAssociations) > -1;
                        // showAppointmentAssociations = type.sections.indexOf(cs.sections.hybridAssociations) > -1;
                    }
                    optionalHeader = type.optionalHeader;
                    canVerticalStack = type.canVerticalStack;
                    appTypeSupported = type.appTypeSupportedLevel && type.appTypeSupportedLevel.container;
                }
                vm.data.showBehavior = showBehavior;
                vm.data.showForms = showForms;
                vm.data.showReferences = showReferences;
                vm.data.showHybridAssociations = showHybridAssociations;
                vm.data.showHeader = vm.container.showHeader;
                vm.data.optionalHeader = optionalHeader;
                vm.data.canVerticalStack = canVerticalStack;
                vm.data.appTypeSupported = appTypeSupported;
            };

            vm.showHeaderChanged = newValue => {
                if (vm.container) {
                    vm.container.showHeader = newValue;
                }
            };

            vm.confirmSelectionPromise = () => {
                if (vm.container.containerItems && vm.container.containerItems.length > 0) {
                    // if there are associated forms, make the user confirm before switching
                    // to a new containerType
                    return ngDialog.openConfirm({
                        templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/confirmContainerTypeChangeDialog.html',
                    });
                }
                // instantly resolve the promise if there are no associated forms
                return new Promise(resolve => $timeout(resolve));
            };

            vm.$onInit = () => {
                vm.expConstants = ExpressionConstantsService;
                vm.lcs = LocaleConstantsService;

                vm.data = {
                    accordionBasicInfo : {
                        collapsed : false,
                    },
                    canVerticalStack       : false,
                    containerTypeNames     : cs.containerTypeNames,
                    containerTypes         : cs.containerTypes,
                    optionalHeader         : false,
                    pageId                 : vm.container.pageId,
                    showBehavior           : false,
                    showForms              : false,
                    showHeader             : false,
                    showHybridAssociations : false,
                    showReferences         : false,
                };
                vm.containerTypeChanged(vm.container.containerType);
            };
        },
        controllerAs : 'pcc',
        restrict     : 'E',
        scope        : {
            changePageIdCallback : '<',
            container            : '=',
            containerList        : '<',
            pageId               : '<',
            tabs                 : '<',
        },
        templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/programContainerConfig.template.html',
    }));
