'use strict';

import template from './formEntryWithTimeCheck.html';

(function () {

    angular.module('acadiamasterApp')
        .directive('formEntryWithTimeCheckConfig', function ($translate) {
        return {
            restrict: 'E',
            template: template,
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.condition.getRoot().programId;
                $scope.timeText= $translate.instant('acadiamasterApp.ifCondition.joinCondition.formEntryTime.text');

                $scope.formOptions = {
                    form: $scope.condition.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack: function () {
                        $scope.condition.setForm(this.form);
                    }
                };

                /**
                 * getting a quick description of the condition, this is slightly different than the tree node display
                 * @returns {string}
                 */
                $scope.getDescription = function() {
                    var condition = $scope.condition;
                    var description = condition.timeComparator == null ? '?' : condition.timeComparator.description;
                    var timeMsg = timeMsgBuilder(condition, condition.value);
                    var timeMsgBegin = timeMsgBuilder(condition, condition.valueBeginAt);
                    var timeMsgEnd = timeMsgBuilder(condition, condition.valueEndAt);
                    if(condition.timeComparator.name === $scope.BETWEEN) {
                        return $translate.instant('acadiamasterApp.ifCondition.joinCondition.formEntryTime.between',
                            {description: description, timeBegin: timeMsgBegin, timeEnd: timeMsgEnd});
                    }
                    return $translate.instant('acadiamasterApp.ifCondition.joinCondition.formEntryTime.not-between',
                        {description: description, timeAgo: timeMsg});
                };
            }
        };

        /***************************************
         * private function
         ***************************************/
        function timeMsgBuilder(condition, value) {
            return condition.absolute ? getAbsoluteTime(value) : getRelativeTime(value, condition.timeUnit);
        }
        function getLocalTimeZoneString() {
            var offSet = -(new Date().getTimezoneOffset() / 60);

            return Intl.DateTimeFormat().resolvedOptions().timeZone +
                ' GMT ' + (offSet >= 0 ? '+' : '') + offSet;
        }

        function getAbsoluteTime(dateValue) {
            if (dateValue == null) {
                return '?';
            }

            if (_.isFunction(dateValue.toUTCString)) {
                return dateValue.toUTCString();
            }

            return '?';
        }

        function getRelativeTime(relativeValue, timeUnit) {
            var msg = relativeValue == null ? '?' : '' + relativeValue;

            msg += ' ' + timeUnit;

            return msg;
        }


    });
})();
