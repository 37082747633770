/**
 * directive for displaying a single agreement with preview
 * note: preview is currently just a placeholder and not yet implemented
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('agreementDetailedView', function (AgreementsService) {

        function getAgreement(id, $scope) {
            $scope.ready = false;
            const params = {
                agreementId: id
            };

            AgreementsService.getAgreement(params).then((response) => {
                $scope.agreement = response.data;
            }, (error) => {
                console.error('Error loading agreement by id : ' + id, error);
            }).finally(() => {
                $scope.ready = true;
            });
        }

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/agreements/detailedView/agreement.view.html',
            scope: {
                agreementId: '=',
                isContainerItem: '=',
            },
            link: function ($scope) {
                $scope.ready = false;
                $scope.agreement = null;

                $scope.booleanToText = (booleanValue) => {
                    if (booleanValue == null) {
                        return 'n/a';
                    }

                    return booleanValue ? 'Yes' : 'No';
                };

                $scope.hasExpression = (expression) => {
                    return expression != null && expression.trim().length > 0;
                };

                getAgreement($scope.agreementId, $scope);
            }
        };
    });
})();
