'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {

        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('businessRule');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
        }

        $stateProvider
            .state('ruleAudits', {
                parent: 'ruleDashboard',
                url: '/audits',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'Rule Execution Audit'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/ruleAudit/ruleExecutionAudits.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
    });
