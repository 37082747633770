/**
 * Created by Ryan Berry on 06/23/22
 *
 * Directive for selecting pages in a drop-down
 */

import controller from './pageSelector.controller.js';
import template from './pageSelector.template.html';

angular.module("acadiamasterApp")
    .directive("pageSelector", function() {
        return {
            bindToController: true,
            controller: 'PageSelectorController',
            controllerAs: 'psc',
            restrict: 'E',
            scope: {
                callback: '<?',
                programId: '<',
                selectedPageId: '<?',
            },
            template,
        };
    });
