/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */

angular.module('acadiamasterApp')
    .controller('RuleManualTriggerController', (
        $scope, $state, rule, ProgramSubscriptions,
        BusinessRule, ParseLinks, OperationStatus, drcService, $timeout, ngDialog,
    ) => {
        $scope.isReady = false;

        init();

        $scope.windowOpen = false;
        $scope.checkStatusInterval = 5000;

        $scope.triggerRuleForSingleUser = function (userId) {
            let filter = {
                activeOnly: true,
                programId: $scope.searchData.programId,
                userId: userId,
            };
            trigger(filter);
        };

        function trigger (searchFilter) {
            let dto = {
                businessRuleId: $scope.selectionData.ruleId,
                programId: $scope.searchData.programId,
                searchFilter: searchFilter,
                skipEvaluation: $scope.selectionData.skipEvaluation,
                workflowId: $scope.selectionData.workflow.id,
            };

            openModelWindow($scope, $scope.data, 'Manual trigger');
            let response = BusinessRule.manualTrigger(dto);
            response.$promise.then(data => {
                $scope.data.operationId = data.operationId;
                checkAndShowStatus($scope, $scope.data);
            });
        }

        $scope.getAction = function (id) {
            // eslint-disable-next-line no-undef
            let index = _.findIndex($scope.data.ruleData.actions, { id : id });
            return $scope.data.ruleData.actions[index];
        };

        $scope.search = function () {
            $scope.searchData.page = 0;
            search();
        };

        $scope.loadPage = function (page) {
            $scope.searchData.page = page;
            search();
        };

        function init () {
            $scope.data = {
                activeUsers             : null,
                operationId             : null,
                operationStatus         : new OperationStatus(),
                ruleData                : null,
            };

            $scope.selectionData = {
                ruleId         : null,
                skipEvaluation : false,
                workflow       : null,
            };

            $scope.searchData = {
                lastSearch          : null,
                links               : null,
                page                : 0,
                programId           : null,
                results             : null,
                searchString        : null,
                size                : 25,
                userId              : null,
            };

            resolveEntities();
        }

        function resolveEntities () {
            rule.$promise.then(data => {
                $scope.isReady = true;
                $scope.data.ruleData = data;
                $scope.searchData.programId = data.programId;
                $scope.selectionData.ruleId = data.id;
                $scope.selectionData.workflow = data.workflows[0];
                search();
            });
        }

        function search () {
            let searchString = $scope.searchData.searchString;

            let filter = {
                activeOnly: true,
                page: $scope.searchData.page,
                programId: $scope.searchData.programId,
                searchString: searchString != null && searchString !== '' ? searchString : null,
                size: $scope.searchData.size,
                userId: $scope.searchData.userId,
            };

            ProgramSubscriptions.advancedSearch(filter)
                .then(response => {
                    $scope.searchData.results = response.data;
                    $scope.searchData.results.totalCount = response.headers('x-total-count');
                    $scope.searchData.lastSearch = filter;
                    $scope.searchData.links = ParseLinks.parse(response.headers('link'));
                }).catch(errResponse => {
                    console.log(errResponse);
                });
        }

        function openModelWindow ($scope, dataContainer, title) {
            ngDialog.open({
                className         : 'ngdialog-theme-plain custom-width-medium',
                closeByDocument   : false,
                closeByEscape     : false,
                closeByNavigation : false,
                controller        : function () {
                    let vm = this;
                    vm.title = title;
                    vm.data = dataContainer;

                    vm.getCompletionStatus = function () {
                        return `${Math.floor(vm.data.operationStatus.completedItems * 100 / vm.data.operationStatus.totalItems) }%`;
                    };

                    vm.stopOperation = function () {
                        console.log(`stop operation called, ${ vm.data.operationId}`);
                        stopOperation(vm.data.operationId);
                    };
                },
                controllerAs     : 'windowCtrl',
                height           : '70%',
                preCloseCallback : function () {
                    $scope.windowOpen = false;
                },
                showClose : false,
                template  : 'admin-templates/site/common/statusWindow.html',
            });
            $scope.windowOpen = true;
        }

        function checkAndShowStatus ($scope, dataContainer) {
            drcService.checkOperationStatus(dataContainer.operationId)
                .then(response => {
                    let operationStatus = dataContainer.operationStatus;
                    operationStatus.fromDto(response.data);
                    if (!operationStatus.isOperationOver() && $scope.windowOpen) {
                        $timeout(() => {
                            checkAndShowStatus($scope, dataContainer);
                        }, $scope.checkStatusInterval);
                    }
                });
        }

        function stopOperation (operationId) {
            if (operationId == null) {
                return;
            }
            drcService.stopOperation(operationId);
        }
    });
