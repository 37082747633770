'use strict';

/**
 * drc related services
 */
(function() {
    angular.module('acadiamasterApp').factory('FormEntrySearchService', function ($http, $q) {
        return {

            advancedSearch : function(searchDto) {
                var deferred = $q.defer();

                var success = function (response) {
                    deferred.resolve(response);
                };

                var error = function (err) {
                    console.error(err);
                    deferred.reject(err);
                };


                $http({
                    url: "api/formEntryAdmin/search",
                    method: 'POST',
                    headers: {'Content-Type' : 'application/json'},
                    data: JSON.stringify(searchDto)
                }).then(success).catch(error);

                return deferred.promise;
            }

        };
    })
})();
