(function () {
    'use strict';

    /**
     * directive for selecting container item
     */
    angular.module('acadiamasterApp').directive('containerItemSelection', function (ContainerCacheService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/containerItemSelection.html',
            scope: {
                /**
                 * container item options will have the following fields and properties
                 * 1. getContainerItem() -- function that returns the container item currently selected
                 * 2. setContainerItem(containerItem) -- function that should be invoked when container item is selected
                 * 3. programId -- program id for which to select container item from
                 * 4. getContainerId() -> container id
                 */
                containerItemOptions: '<',
                isRequired: '<',
                autoSelectIfOnlyOneChoice: '<',
                title: '@',
            },
            link: function ($scope) {
                $scope.data = {
                    // a list of container items to choose from
                    allContainerItems : null,
                    // the container item currently selected
                    containerItem: $scope.containerItemOptions.getContainerItem(),
                    // whether data is ready, the directive should be showing loading message while data is not ready
                    ready: false,
                };

                loadContainerItems($scope);

                addWatches($scope);
            }
        };

        function loadContainerItems($scope) {
            const programId = $scope.containerItemOptions.programId;
            const containerId = $scope.containerItemOptions.getContainerId();

            ContainerCacheService.loadContainerById(containerId, programId, false)
                .then(container => {
                    $scope.data.allContainerItems = container==null ? null : container.containerItems;

                    const items = $scope.data.allContainerItems;

                    if ($scope.autoSelectIfOnlyOneChoice && items!=null && items.length==1) {
                        // set the container item to be equal to the only available choice if desired
                        $scope.data.containerItem = items[0];
                    }
                });
        }

        function addWatches($scope) {
            $scope.$watch("data.containerItem", function(newValue, oldValue) {
                if (newValue==oldValue) {
                    return;
                }

                $scope.containerItemOptions.setContainerItem(newValue);
            });
        }
    });
})();
