/**
 * directive for configuring one conditional content value that include
 * 1. name
 * 2. expression
 * 3. actual content value
 * 4. button to add a conditional content value after the current one
 */
angular.module('acadiamasterApp').directive('contentValueConfigWithCondition', (ExpressionConstantsService) => ({
    link($scope) {
        $scope.expConstants = ExpressionConstantsService;
    },
    restrict: 'E',
    scope: {
        conditionalValue: '=',
        definition: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/' +
        'contentValueConfigWithCondition.html',
}));

