/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp')
        .directive('hasBannerByFormIdConfig', (ProgramTestConstantsService, ValueType,
            ValueComparisonOptionModel, ProgramTestService) => {
            function hasBannerNotificationByFormIdController () {
                let vm = this;
                vm.pc = ProgramTestConstantsService;

                // load potential options from API
                ProgramTestService.getBannerNotificationTargetAreas().then(
                    response => {
                        let options = {};
                        response.data.forEach(option => {
                            options[option] = { name : option, text : option };
                        });
                        vm.valueComparisonOption = createValueComparisonOption(
                            vm.condition,
                            options,
                        );
                    },
                    () => {
                        console.error('Error loading Banner Notification Target Areas');
                    });

                vm.$onInit = () => {
                    vm.formOptions = {
                        form             : vm.condition.getForm(),
                        onChangeCallBack : function () {
                            vm.condition.setForm(this.form);
                        },
                        title    : 'Select Form',
                        viewOnly : false,
                    };
                    vm.programId = vm.condition.getRoot().programId;
                };
            }

            function createValueComparisonOption (condition, options) {
                let valueComparisonOption = new ValueComparisonOptionModel();

                valueComparisonOption.isRequired = true;
                valueComparisonOption.canConfigureOperator = true;

                valueComparisonOption.functions.getValueType = function () {
                    return ValueType.STRING;
                };

                valueComparisonOption.functions.getOperator = function () {
                    return condition.getComparator();
                };

                valueComparisonOption.functions.setOperator = function (newOperator) {
                    condition.setComparator(newOperator);
                };

                valueComparisonOption.functions.getValues = function () {
                    return condition.targetAreaList;
                };

                valueComparisonOption.functions.setValues = function (newValues) {
                    condition.targetAreaList = newValues;
                };

                valueComparisonOption.functions.getPossibleValues = function () {
                    return options;
                };

                return valueComparisonOption;
            }

            return {
                bindToController : true,
                controller       : hasBannerNotificationByFormIdController,
                controllerAs     : 'bnc',
                restrict         : 'E',
                scope            : {
                    condition : '=',
                },
                templateUrl : 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/hasBannerNotificationByFormId.html',
            };
        });
}());
