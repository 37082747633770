'use strict';

angular.module('acadiamasterApp')
    .controller('DataTypeCategoryController', function ($scope, DataTypeCategory, ParseLinks, DataTypeMasterRecordCount, SearchWithFilter,CONFIG, vbrCommonUtil
    , ngDialog) {
        $scope.CONFIG = CONFIG;
        $scope.dataTypeCategorys = [];
        $scope.page = 0;
        $scope.loadAll = function() {
            DataTypeCategory.query({page: $scope.page, size: 20}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.dataTypeCategorys = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.searchFilter.pageNum = page;
            $scope.page = page;
            queryWithFilter($scope.searchFilter);
        };
        $scope.loadAll();

        $scope.searchFilter = {
            name: null,
            usedFor: null,
            exactMatch: false,
            pageNum: 0,
            pageSize: 20,
            entityType: 'DATA_TYPE_CATEGORY'
        };

        $scope.usedFor = {
            DATAFORM: 'DATAFORM',
            PROFILEFORM: 'PROFILEFORM',
            ALL: 'ALL'
        };

        $scope.changedUsedFor = $scope.usedFor.ALL;
        $scope.previousUsedFor = $scope.usedFor.ALL;

        $scope.delete = function (id) {
            DataTypeCategory.get({id: id}, function(result) {
                $scope.dataTypeCategory = result;
                $('#deleteDataTypeCategoryConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            DataTypeCategory.delete({id: id},
                function () {
                    DataTypeMasterRecordCount.updateCounts();
                    $scope.loadAll();
                    $('#deleteDataTypeCategoryConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        $scope.refresh = function () {
            refresh();
        };

        function refresh() {
            $scope.loadAll();
            $scope.clear();
        }

        $scope.clear = function () {
            $scope.dataTypeCategory = {name: null, description: null, createdOn: null, updatedOn: null, isDeleted: null, id: null};
        };


        $scope.searchByFilter = function() {
            setPageToZero();
            queryWithFilter($scope.searchFilter);
        };

        $scope.clearSearchFilter = function() {
            $scope.searchFilter.name = null;
            $scope.searchFilter.usedFor = $scope.usedFor.ALL;
            $scope.searchFilter.exactMatch = false;
            $scope.changedUsedFor = $scope.usedFor.ALL;
            setPageToZero();
            $scope.loadAll();
        };

        $scope.usedForFilterChanged = function(){
            if ($scope.previousUsedFor != $scope.changedUsedFor)
            {
                setPageToZero();
                queryWithFilter($scope.searchFilter);
            }
            $scope.previousUsedFor = $scope.changedUsedFor;
        };

        $scope.changedUsedFor = $scope.usedFor.ALL;
        $scope.previousUsedFor = $scope.usedFor.ALL;


        function queryWithFilter(searchFilter) {
            searchFilter.usedFor = ($scope.changedUsedFor==$scope.usedFor.ALL)? null :$scope.changedUsedFor ;
            SearchWithFilter.query(searchFilter).then(function(result){
                $scope.links = ParseLinks.parse(result.headers('link'));
                $scope.dataTypeCategorys = result.data;
            })
        }

        function setPageToZero(){
            $scope.searchFilter.pageNum = 0;
            $scope.page = 0;
        }

        $scope.download = function (id) {
            var dataTypeCategory = DataTypeCategory.get({id: id});
            dataTypeCategory.$promise.then(function (data) {
                var fileName = 'dataTypeCategory_' + id + '.json';
                vbrCommonUtil.downloadAsFile(fileName, data);
            });
        };

        $scope.importDataTypeCategory = function () {
            ngDialog.openConfirm({
                template: '<upload-to-configuration-server title="Import Data Type Category" ws-url="/api/configuration/dataTypeCategory/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                plain: true,
                size: 'md',
                className : 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        refresh();
                    }
                }]
            }).then(function () {
                $scope.refresh();
            });
        };
    });
