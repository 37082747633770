import {
    fieldDisplayConfig,
    optionsDisplayConfig,
    titleDisplayConfig
} from './dropdown.preset.config';

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('dropDownSelectPresetConfig', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/presetHelp/dropdown.preset.config.html',
                scope: {},
                link: function ($scope) {
                    $scope.fieldDisplayConfig = fieldDisplayConfig;
                    $scope.titleDisplayConfig = titleDisplayConfig;
                    $scope.optionsDisplayConfig = optionsDisplayConfig;
                }
            };
        });

})();

