/**
 * Created by arao on 3/16/2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     */
        .directive('pdfPreview', function (LocaleConstantsService, PDFPreview, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/pdfConfig/pdfPreview.html',
                scope: {
                    versionId: '=',
                    templateFields: '=',
                    fileUrl:'='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.selectedLocale = "en";
                    $scope.preview = function () {
                        var templateFields = [];
                        var files = [];
                        _.forEach(this.templateFields, function (templateField) {
                            templateFields.push(templateField.toDto(files));
                        });
                        var data = {
                            formVersionId: $scope.versionId,
                            templateFields: templateFields,
                            fileURL:$scope.fileUrl,
                            locale: $scope.selectedLocale
                        };
                        PDFPreview(data, files);
                    }
                }
            };
        });

})();

