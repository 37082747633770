/**
 * Created by Kshama on 23/05/2018.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for action configuration for change config
     */
        .directive('formElementConfigActionChangeVisibility', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfig.action.changeVisibility.html',
                scope: {
                    element: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.relativeFieldType = FormConstants.relativeFieldType;
                }
            };
        });
})();

