/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * controller for confirmation of form version activate
     */
        .controller('FormVersionActivateController', function($scope, ngDialog, FormVersion, AlertService) {
            $scope.activateFormVersion = function() {
                var formDetail = FormVersion.activate($scope.ngDialogData.formId, $scope.ngDialogData.formVersionId);
                formDetail.then(function(response) {
                    $scope.confirm($scope.ngDialogData.formId);
                });
            };
        });
})();

