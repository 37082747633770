/**
 * Created by jason.cao on 4/27/17
 */
(function () {
    angular.module('acadiamasterApp')
        .factory('VbrToolsService', ($http, vbrCommonUtil) => {
            return {
                downloadJarFile : function (jarType) {
                    let url = `/api/jarFileDownload?n=${ jarType}`;
                    $http({
                        headers : {
                            'Content-type' : 'application/json',
                        },
                        method       : 'GET',
                        responseType : 'arraybuffer',
                        url          : url,
                    }).then(response => {
                        let file = new Blob([ response.data ], { type : 'application/java-archive' });
                        let fileURL = URL.createObjectURL(file);
                        // eslint-disable-next-line angular/window-service
                        window.open(fileURL);
                        let link = document.createElement('a');
                        link.href = fileURL;
                        link.download = response.headers('filename');
                        link.click();
                    }).catch(errResponse => {
                        // eslint-disable-next-line no-undef
                        log.error(`download failed for jar type : ${ jarType}`, errResponse.data, errResponse.status, errResponse.headers(), errResponse.config);
                    });
                },

                getGeneratedSwift : function () {
                    return $http.get('/api/codeGenerator/swift');
                },

                // GET /api/drc/getOperationIdByType/{operationType}
                getOperationIdByOperationType : function (operationType) {
                    return $http.get(`/api/drc/getOperationIdByType/${operationType}`);
                },

                // POST /api/vxp/startMigration
                vxpDataMigration : function (dto) {
                    return $http.post('/api/vxp/startMigration', dto, {
                        headers : vbrCommonUtil.getCommonPostHeader(),
                    });
                },
            };
        });
}());
