/**
 * Created by shekhar.sukhadeve on 3/17/2017.
 */

'use strict';

angular.module('acadiamasterApp')
    .controller('ExternalServerLogsController', function ($scope, ExternalServerLogs, ParseLinks, EXTERNAL_LOGS_CONSTANTS, SEARCH_FILTER_CONSTANTS) {
        $scope.cs = EXTERNAL_LOGS_CONSTANTS;
        $scope.fields = [
            $scope.cs.FIELDS.ID,
            $scope.cs.FIELDS.SERVICE,
            $scope.cs.FIELDS.HTTP_METHOD,
            $scope.cs.FIELDS.RESPONSE_CODE,
            $scope.cs.FIELDS.INTERNAL_ID,
            $scope.cs.FIELDS.EXTERNAL_ID,
            $scope.cs.FIELDS.EVENT_TYPE,
            $scope.cs.FIELDS.EVENT_SOURCE,
            $scope.cs.FIELDS.DESCRIPTION,
            $scope.cs.FIELDS.REQUEST_TIMESTAMP,
            $scope.cs.FIELDS.RESPONSE_TIMESTAMP,
        ];

        $scope.pageSizeOptions = [
            20, 50, 100
        ];

        $scope.searchData = {
            page: 0,
            size: $scope.pageSizeOptions[0],
            orderBy: $scope.cs.FIELDS.ID.id,
            ascending: false,
            searchResultCount: 0
        };

        $scope.filteredFields = [
            SEARCH_FILTER_CONSTANTS.FIELDS.SERVICE,
            SEARCH_FILTER_CONSTANTS.FIELDS.EVENT_TYPE,
            SEARCH_FILTER_CONSTANTS.FIELDS.EVENT_SOURCE,
            SEARCH_FILTER_CONSTANTS.FIELDS.HTTP_METHOD,
            SEARCH_FILTER_CONSTANTS.FIELDS.RESPONSE_CODE,
            SEARCH_FILTER_CONSTANTS.FIELDS.INTERNAL_ID,
            SEARCH_FILTER_CONSTANTS.FIELDS.EXTERNAL_ID,
            SEARCH_FILTER_CONSTANTS.FIELDS.REQUEST_TIMESTAMP
        ];
        $scope.filteredValues = {};

        $scope.setOrderBy = function (newOrderBy) {
            if ($scope.searchData.orderBy === newOrderBy) {
                $scope.searchData.ascending = !$scope.searchData.ascending;
            }
            else {
                $scope.searchData.ascending = true;
                $scope.searchData.orderBy = newOrderBy;
            }
            $scope.loadAll(0);
        };

        $scope.ruleAudits = [];
        $scope.selectedRule = null;

        $scope.loadAll = function (page) {
            $scope.searchData.page = page || 0;
            const params = {
                page: $scope.searchData.page,
                size: $scope.searchData.size,
                orderBy: $scope.searchData.orderBy,
                ascending: $scope.searchData.ascending,
            };
            Object.keys($scope.filteredValues).forEach(function (key) {
                params[key] = $scope.filteredValues[key];
            });

            ExternalServerLogs.query(params, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.extLogs = result;
                $scope.searchData.searchResultCount = headers(
                        'X-Total-Count');
            })
        };
    });
