(function () {
    angular.module('acadiamasterApp').controller('ProgramIosMobileAssetsController',
        ($scope, $state, $stateParams, entity, ProgramAppSettings, toastr, CONFIG) => {
            $scope.programId = $stateParams.id;
            let platform = 'IOS';
            $scope.CONFIG = CONFIG;
            $scope.isValidated = false;
            $scope.canDownload = false;
            $scope.canUpload = false;

            ProgramAppSettings.getAppSettingsDetails($scope.programId).then(result => {
                if (result.data.iosBuildAssetsZipFileId) {
                    $scope.iosFileId = result.data.iosBuildAssetsZipFileId;
                    $scope.feedback = JSON.parse(result.data.iosAssetFeedback);
                    $scope.isValidated = true;
                    $scope.canUpload = false;
                }
            });

            $scope.data = {
                isInfoCollapsed : true,
                isSendingFile   : false,
                zipFile         : null,

            };

            $scope.validateZip = function () {
                if ($scope.data.zipFile.name !== 'Assets.xcassets.zip') {
                    toastr.error('Filename should be Assets.xcassets.zip');
                    $scope.canUpload = false;
                    $scope.isValidated = false;
                    $scope.feedback = null;
                    return;
                }

                ProgramAppSettings.validateZip($scope.data.zipFile, platform).then(res => {
                    if (res.data) {
                        $scope.feedback = res.data;
                        $scope.isValidated = true;
                        $scope.canDownload = true;
                        $scope.iosFileId = null;
                        $scope.canUpload = true;
                    }
                });
            };

            $scope.uploadZip = function () {
                ProgramAppSettings.uploadZip($scope.data.zipFile, $scope.programId, platform).then(res => {
                    $state.go('program.manage.programAppSettings', { id : $scope.programId });
                });
            };

            $scope.downloadZip = function () {
                ProgramAppSettings.downloadZip($scope.iosFileId).then(res => {
                    const a = document.createElement('a');
                    const file = new Blob([ res.data ], { type : 'application/octet-stream' });
                    a.href = URL.createObjectURL(file);
                    a.download = 'Assets.xcassets.zip';
                    a.click();
                });
            };

            $scope.isFileValid = function (file) {
                return file && file.name.indexOf('.zip') === file.name.length - 4 && file.size !== 0;
            };
        });
}());
