'use strict';

(function () {

    angular.module('acadiamasterApp').directive('programTestListWithSearch', function (
        CONFIG, ParseLinks, ngDialog, ProgramTestService, vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/programTestListWithSearch.html',
            scope: {},
            link: function ($scope) {
                $scope.CONFIG = CONFIG;
                $scope.searchData = {
                    searchString: "",
                    searchResultCount: "loading ...",
                    orderByChoices: ['updatedOn', 'id', 'name', 'displayName'],
                    orderBySelected: 'updatedOn',
                    pageSizeChoices: [20, 50, 100],
                    pageSizeSelected: 20,
                    ascending: false
                };

                // an object holding the test run data, probably should be moved out of here later
                $scope.testRunData = {
                    programTest : null,
                    testStatus : null
                };

                $scope.page = 0;

                function loadProgramTest() {
                    var promise = ProgramTestService.getAllTestCase({
                        page: $scope.page,
                        size: $scope.searchData.pageSizeSelected,
                        searchString: $scope.searchData.searchString || null,
                        ascending: $scope.searchData.ascending,
                        orderBy: $scope.searchData.orderBySelected
                    });
                    return promise.then(function (result) {
                        $scope.links = ParseLinks.parse(result.headers('link'));
                        $scope.programTests = result.data;
                        $scope.searchData.searchResultCount = result.headers('X-Total-Count');
                    });
                }

                $scope.loadAll = function () {
                    $scope.page = 0;
                    loadProgramTest();
                };

                $scope.loadPage = function (page) {
                    $scope.page = page;
                    loadProgramTest();
                };


                $scope.delete = function (programTest) {
                    ngDialog.openConfirm({
                        template: 'admin-templates/site/programTests/programTest/programTestDelete.html',
                        controller: 'TestCaseDeleteController',
                        data: programTest
                    }).then(function () {
                        $scope.loadAll();
                    });
                };


                $scope.loadAll();

                $scope.download = function (programTestId) {
                    var promise = ProgramTestService.getTestCase(programTestId);
                    return promise.then(function (result) {
                        // create file name
                        var fileName = 'programTest_' + programTestId
                            + '.json';
                        // download as file in browser
                        vbrCommonUtil.downloadAsFile(fileName, result.data);
                    });
                };

                $scope.importProgramTest = function () {
                    var _scope = $scope;

                    ngDialog.openConfirm({
                        template: '<upload-to-configuration-server title="Upload Program Test" ws-url="/api/configuration/programTest/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                        plain: true,
                        size: 'md',
                        className: 'ngdialog-theme-plain custom-width-medium',
                        controller: ['$scope', function ($scope) {
                            $scope.onSuccess = function () {
                                _scope.loadAll();
                            }
                        }]
                    }).then(function () {
                        $scope.loadAll();
                    });
                };


            }
        }
    });
})();
