(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                $translatePartialLoader.addPart('agreements');
                $translatePartialLoader.addPart('form');
                // this is admin site, don't worry about the i18n support for now, we can always add it later if needed
                // $translatePartialLoader.addPart('drc');
                return $translate.refresh();
            }

            /**
             * drc dashboard for all the functionality
             */

            $stateProvider.state('drcDashboard', {
                parent: 'entity',
                url: '/drc',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'DRC Dashboard'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-dashboard></vbr-drc-dashboard>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            /**
             * page to convert form to questionnaire
             */
            $stateProvider.state('drcConvertFormToQuestionnaire', {
                parent: 'drcDashboard',
                url: '/convertForm',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'DRC Feature -- Convert Form To Questionnaire'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-form-to-questionnaire></vbr-drc-form-to-questionnaire>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            /**
             * page to sync users and forms
             */
            $stateProvider.state('drcSyncUsersAndForms', {
                parent: 'drcDashboard',
                url: '/sync',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'DRC Sync Users & Forms'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-sync-user-and-form></vbr-drc-sync-user-and-form>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            /**
             * page to sync users and forms
             */
            $stateProvider.state('drcSyncPDF', {
                parent: 'drcDashboard',
                url: '/syncPDF',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'DRC Sync Form Entry with PDF Generation'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-sync-pdf></vbr-drc-sync-pdf>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });


            /**
             * pages for creating data type and properties for PMI program
             */
            $stateProvider.state('drcCreateDataTypeProperty', {
                parent: 'drcDashboard',
                url: '/createProperty',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Create Data Type Property for Use with DRC'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-create-data-type-property></vbr-drc-create-data-type-property>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            $stateProvider.state('formEntrySearch', {
                parent: 'drcDashboard',
                url: '/formEntrySearch',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'Form Entry Search'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-form-entry-search></vbr-drc-form-entry-search>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            $stateProvider.state('drcWithdraw', {
                parent: 'drcDashboard',
                url: '/withdraw',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'Withdraw Unmapped Participant'
                },
                views: {
                    'content@': {
                        template: '<div class="row"><div class="col-md-6"><vbr-drc-withdraw></vbr-drc-withdraw></div></div>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            $stateProvider.state('drcPairingManagement', {
                parent: 'drcDashboard',
                url: '/pairing',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'Participant Pairing Management'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-pairing-management></vbr-drc-pairing-management>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

            $stateProvider.state('drcUserSyncUpdate', {
                parent: 'drcDashboard',
                url: '/drcUserSyncUpdate',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'DRC Feature -- User Sync Status'
                },
                views: {
                    'content@': {
                        template: '<vbr-drc-user-sync-update></vbr-drc-user-sync-update>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });

        });
})();
