(function () {
    angular.module('acadiamasterApp').directive('modalNotificationList', (ParticipantProfileService, AlertService) => {
        return {
            link : function ($scope) {
                ParticipantProfileService.getModalNotifications($scope.userId)
                    .then(response => {
                        $scope.notifications = response.data;
                    }).catch(() => {
                        AlertService.error('Failed to retrieve modal notifications for given user');
                    });
            },
            restrict : 'E',
            scope    : {
                userId : '=',
            },
            templateUrl : 'admin-templates/site/participant/profile/experience/modalNotifications/modalNotifications.html',
        };
    });
}());
