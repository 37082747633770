'use strict';

(function(){
    angular.module('acadiamasterApp')
        .factory('UserSearch', ['$http', 'localWeb', function ($http, localWeb) {
            var url = localWeb.createUrl('api/users/search?');
            return {
                'query': function (searchFilter) {
                    return $http({
                        url: url + $.param(searchFilter),
                        method: 'GET'
                    }).then(function (response) {
                        return response;
                    }, function (error) {
                        console.log(error);
                        return error
                    });
                }
            }
        }]);
})();
