(function() {
    'use strict';

    angular.module('acadiamasterApp')

        .controller('DirectiveTestController', function($scope) {

            $scope.dataType= {};
            $scope.dataType.options = {
                dataTypes :  [],
                showDataTypeDetail : true,
                title : 'Data Type Associated'
            };


            $scope.form= {};
            $scope.form.options = {
                form :  null,
                title : 'Form',
                viewOnly: false,
                formCategory: 'DATA_FORM'

            };

            $scope.cronGenerator = {
                // cronExpression : "0 30 17 1/9 * ? *",
                cronExpression : "0 0/10 * 1/1 * ? *",
                recurrenceType : "MINUTES",

                config:{
                    showTitle: true,
                    title: "Build Expression in Test"
                }
            }
        });

})();

