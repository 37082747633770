/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for condition configuration
     */
        .directive('formElementConfigCondition', function (ExpressionConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfig.condition.html',
                scope: {
                    element: '='
                },
                link: function ($scope) {
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.constants = FormConstants;
                }
            };
        });
})();

