(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for AddressStateComparisonCondition model
     */
    app.factory('AddressStateComparisonConditionModel', function (QObjectModel, AddressStatesConstant,
                                                                  ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        AddressStateComparisonConditionModel.inheritsFrom(QObjectModel);

        function AddressStateComparisonConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.ADDRESS_STATE_COMPARISON);

            //initialized the variables
            this.states = [];
            this.comparator = ProgramTestConstantsService.operators.EQ;
        }

        /**
         * convert the current UI model to dto format
         */
        AddressStateComparisonConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            if (this.states==null) {
                dto.states = null;
            }
            else {
                dto.states = [];
                _.forEach(this.states, function(s) {
                    if (s && s.name) {
                        dto.states.push(s.name);
                    }
                });
            }

            dto.comparator = this.comparator==null ? null : this.comparator.name;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        AddressStateComparisonConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            if (dto.states == null) {
                this.states = null;
            }
            else {
                var states = [];
                this.states = states;
                _.forEach(dto.states, function(stateName) {
                    var stateModel = ProgramTestConstantsService.getObjectByName(
                        AddressStatesConstant, stateName);

                    if (stateModel!=null) {
                        // need to do a clone of the field to avoid being linked to
                        // other conditions when using multi-select directive
                        states.push(_.cloneDeep(stateModel));
                    }
                });
            }

            this.setComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator));
        };

        AddressStateComparisonConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        AddressStateComparisonConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.states = null;
            }
            else if (this.states == null) {
                this.states = [];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN) {
                if (this.states.length>1) {
                    // just use the first value
                    this.states = [this.states[0]];
                }
            }
        };

        AddressStateComparisonConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            var stateMsg = 'State in Address';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : getStatesAsString(this.states);

            return baseMsg + ' ' + stateMsg + comparatorMsg + valueMsg;
        };

        AddressStateComparisonConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.comparator==null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.states == null || this.states.length==0)) {
                this.setErrorMessage('state value is required except when comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        function getStatesAsString(states) {
            if (states==null) {
                return 'null';
            }

            return _.map(states, function(state) {
                return state.name;
            }).join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return AddressStateComparisonConditionModel;

    });
})();
