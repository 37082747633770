/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import expressionUtil from '../util/expression.util';
import cs from '../const/programContainer.constants.service';
import ContainerItemType from '../const/ContainerItemType.const';

angular.module('acadiamasterApp').factory('ContainerItemModel', (
    ModelServiceBase, FormModelServiceForm, ContainerItemCompletionStateConfig,
    ModelServiceConditionExpression, ContainerItemTypeSpecificConfigUtil, LocalizedStringModel, PlatformModel,
    ConditionWithMessageModel, vbrCommonUtil, TemplateCacheService, ConditionalContentValue, ContainerItemLinkedEntityModel,
) => {
    /** *************************************************************
     * dto for container item model
     ************************************************************** */
    ContainerItemModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

    /*
     * @constructor
     */
    function ContainerItemModel (parent) {
        ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
        this.nodeType = cs.nodeType.CONTAINER_ITEM;

        this.id = null;

        this.programId = parent == null ? null : parent.programId;
        this.name = null;
        this.type = null;
        this.typeSpecificConfig = null;

        // isVisible expression
        this.isVisibleExpression = null;
        this.isVisibleExpressionTree = null;

        // isUnlocked expression
        this.isUnlockedExpression = null;
        this.isUnlockedExpressionTree = null;

        this.displayOrder = null;
        this.containerId = null;

        this.linkedEntities = [];
        this.form = null;
        this.agreement = null;
        this.appointment = null;
        // item completion display configuration
        this._useCustomDisplayConfig = false;
        this.itemCompletionStateConfig = null;
        this.lockForCati = false;
        // All app types are supported by default
        this.webSupported = true;
        this.androidSupported = true;
        this.iosSupported = true;
        this.hasLockPreview = false;
        this.defaultLockedMessage = new LocalizedStringModel();
        this.lockedMessages = [];

        this._templateKey = null;
        this.templateValues = {};
        this.templateHeights = new PlatformModel();

        this._template = null;
    }

    /**
     * create getter and setter for internal variable useCustomDisplayConfig
     */
    Object.defineProperty(ContainerItemModel.prototype, 'useCustomDisplayConfig', {
        get : function () {
            return this._useCustomDisplayConfig;
        },
        set : function (value) {
            this._useCustomDisplayConfig = value;

            if (this._useCustomDisplayConfig && !this.itemCompletionStateConfig) {
                // if we are setting this value to true and we don't already have a config, create one
                this.itemCompletionStateConfig = new ContainerItemCompletionStateConfig();
                // populate the new config with values from the parent container's config
                const parent = this._parent;
                if (parent
                    && parent.containerBehavior
                    && parent.containerBehavior.itemCompletionStateConfig) {
                    if (!isActionItem(this.type)) {
                        this.itemCompletionStateConfig.fromDto(parent.containerBehavior.itemCompletionStateConfig.toDto());
                    } else {
                        this.itemCompletionStateConfig.actionItemFromDto(parent.containerBehavior.itemCompletionStateConfig.toDto());
                    }
                }
            }
        },
    });

    /**
     * create getter and setter for template key so when key is set, related template object will be loaded
     */
    Object.defineProperty(ContainerItemModel.prototype, 'templateKey', {
        get : function () {
            return this._templateKey;
        },
        set : function (templateKey) {
            if (this._templateKey == templateKey) {
                return;
            }

            // eslint-disable-next-line no-use-before-define
            const isTemplateKeyChanged = hasTemplateKeyChangedForContainerItem(this._templateKey, templateKey);

            this._templateKey = templateKey;
            this.templateValues = {};

            if (templateKey == null) {
                this._template = null;
                return;
            }

            const _this = this;

            // clear custom heights
            if (isTemplateKeyChanged) {
                _this.templateHeights = new PlatformModel();
            }

            TemplateCacheService.loadTemplateByKey(templateKey).then(templateModel => {
                _this._template = templateModel;
                // set default template heights
                if (_this._template.heightConfigured) {
                    // web/desktop
                    if (_this.templateHeights.web === null
                        || _this.templateHeights.web === undefined) {
                        _this.templateHeights.web = _this._template.screens.desktop.height;
                    }
                    // mobile
                    if (_this.templateHeights.mobile === null
                        || _this.templateHeights.mobile === undefined) {
                        _this.templateHeights.mobile = _this._template.screens.mobile.height;
                    }
                }
            });
        },
    });

    /**
     * create getter and setter for internal variable short description
     * itemInfo can be either form id and name
     * or action type and item name that defined for the action type
     */
    Object.defineProperty(ContainerItemModel.prototype, 'shortDescription', {
        get : function () {
            let itemInfo = null;
            if (this.form) {
                itemInfo = `${this.form.id} | ${this.form.name}`;
            } else if (isActionItem(this.type)) {
                if (this.type === ContainerItemType.ACTION.ACTION_NO_SUPPORT_DATA) {
                    itemInfo = this.name ? `ACTION | ${this.name}` : 'ACTION';
                } else {
                    itemInfo = this.name ? `${this.type} | ${this.name}` : this.type;
                }
            }
            return `(${this.id}) | ${itemInfo}`;
        },
    });

    /*
     * Get the linked entities from containerItemDTO,
     * used in fromDto
     * @param dto - container item DTO
     */
    ContainerItemModel.prototype.getLinkedEntities = function (containerItemDTO) {
        if (!containerItemDTO || !containerItemDTO.linkedEntities) {
            return;
        }
        containerItemDTO.linkedEntities.forEach(entityDTO => {
            const entity = new ContainerItemLinkedEntityModel(this);
            entity.fromDto(entityDTO);
            this.linkedEntities.push(entity);
        });
    };

    /*
     * Set the linked entities for containerItemDTO,
     * used in toDto
     * @param containerItemDTO - container item DTO
     */
    ContainerItemModel.prototype.setLinkedEntities = function (containerItemDTO) {
        if (!containerItemDTO) {
            return;
        }
        containerItemDTO.linkedEntities = this.linkedEntities.reduce((acc, entity) => {
            const entityDTO = entity.toDto(entity, this);
            if (entityDTO) {
                acc.push(entityDTO);
            }
            return acc;
        }, []);
    };

    /*
     * convert the current UI model to dto format
     */
    ContainerItemModel.prototype.toDto = function (files) {
        const dto = {};

        dto.id = this.id;
        dto.programId = this.programId;
        dto.name = this.name;
        dto.type = this.type;
        dto.typeSpecificConfig = this.typeSpecificConfig == null ? null
            : this.typeSpecificConfig.toDto(files);
        dto.form = getFormDto(this.form);
        dto.agreement = this.agreement;
        this.setLinkedEntities(dto);
        dto.itemCompletionStateConfig = this._useCustomDisplayConfig && this.itemCompletionStateConfig
            ? this.itemCompletionStateConfig.toDto()
            : null;

        // export the expression fields
        expressionUtil.toDto(this, dto);

        dto.displayOrder = this.displayOrder;
        dto.containerId = this.containerId;
        dto.lockForCati = this.lockForCati;
        dto.webSupported = this.webSupported;
        dto.androidSupported = this.androidSupported;
        dto.iosSupported = this.iosSupported;
        dto.hasLockPreview = this.hasLockPreview;
        dto.defaultLockedMessage = this.hasLockPreview ? this.defaultLockedMessage.toDto() : null;
        if (this.hasLockPreview && !isActionItem(this.type) && this.lockedMessages != null) {
            dto.lockedMessages = [];
            this.lockedMessages.forEach(cwm => {
                dto.lockedMessages.push(cwm.toDto());
            });
        }

        dto.templateKey = this.templateKey;
        if (dto.templateKey) {
            dto.templateValues = templateValuesToDto(this.templateValues, files);
            dto.templateHeights = this.templateHeights.toDto();
        }
        return dto;
    };

    /*
     * convert the dto object into current object, this function will
     * wipe out any information you have on the current object
     * @param dto
     */
    ContainerItemModel.prototype.fromDto = function (dto) {
        this.id = dto.id;
        this.programId = dto.programId;
        this.name = dto.name;
        this.type = dto.type;
        // import the ContainerItem type specific configuration
        this.typeSpecificConfig = ContainerItemTypeSpecificConfigUtil.loadConfigFromDto(dto.typeSpecificConfig, this);
        if (dto.form) {
            this.form = new FormModelServiceForm.FormModel();
            this.form.fromDto(dto.form);
        } else {
            this.form = null;
        }
        if (dto.agreement) {
            this.agreement = dto.agreement;
        } else {
            this.agreement = null;
        }
        this.getLinkedEntities(dto);
        // if the dto has an itemCompletionStateConfig, use it.
        if (dto.itemCompletionStateConfig) {
            this._useCustomDisplayConfig = true;
            this.itemCompletionStateConfig = new ContainerItemCompletionStateConfig();
            this.itemCompletionStateConfig.fromDto(dto.itemCompletionStateConfig);
        }

        // import the expression fields
        expressionUtil.fromDto(this, dto, ModelServiceConditionExpression.ConditionExpression);

        this.displayOrder = dto.displayOrder;
        this.containerId = dto.containerId;
        this.lockForCati = dto.lockForCati;
        this.webSupported = dto.webSupported;
        this.androidSupported = dto.androidSupported;
        this.iosSupported = dto.iosSupported;
        this.hasLockPreview = dto.hasLockPreview;
        this.defaultLockedMessage.fromDto(dto.defaultLockedMessage);
        if (dto.lockedMessages != null) {
            this.lockedMessages = [];
            dto.lockedMessages.forEach(cwm => {
                const newCwm = new ConditionWithMessageModel(this);
                newCwm.fromDto(cwm);
                this.lockedMessages.push(newCwm);
            });
        }

        this.templateKey = dto.templateKey;
        this.templateValues = dtoToTemplateValues(dto.templateValues);
        this.templateHeights.fromDto(dto.templateHeights);
    };

    ContainerItemModel.prototype.shouldContinueWithKey = function (key) {
        return key != 'form' && key != 'agreement';
    };

    /*
     * remove a message from the list
     * @param messageToBeRemoved - message to be removed
     */
    ContainerItemModel.prototype.removeLockedMessage = function (messageToBeRemoved) {
        if (this.lockedMessages == null || this.lockedMessages.length == 0) {
            console.warn('nothing to remove, list is empty', messageToBeRemoved);
            return;
        }

        let foundIndex = this.lockedMessages.indexOf(messageToBeRemoved);
        if (foundIndex != -1) {
            this.lockedMessages.splice(foundIndex, 1);
        }
    };

    /*
     * insert a new message into the list after a specific message, if it's null, then insert at the end of the list
     * @param messageToInsertAfter - insert new message after this message, nullable
     */
    ContainerItemModel.prototype.addLockedMessageAfter = function (messageToInsertAfter) {
        if (this.lockedMessages == null) {
            this.lockedMessages = [];
        }

        let foundIndex = this.lockedMessages.indexOf(messageToInsertAfter);

        const newMessageWithCondition = new ConditionWithMessageModel(this);

        if (foundIndex != -1) {
            this.lockedMessages.splice(foundIndex + 1, 0, newMessageWithCondition);
        } else {
            this.lockedMessages.push(newMessageWithCondition);
        }
    };

    /**
     * Create a primary linked entity for either form or agreement
     */
    ContainerItemModel.prototype.createPrimaryEntity = function () {
        if (!this.linkedEntities) {
            this.linkedEntities = [];
        }
        const linkedEntityModel = new ContainerItemLinkedEntityModel(this);
        linkedEntityModel.setPrimaryEntity(this);
        this.linkedEntities.push(linkedEntityModel);
    };

    /*
     * set form function used as part of the container with form model
     * it will be called by search UI or form load util
     * @param formModel - form model, can be null
     */
    ContainerItemModel.prototype.setForm = function (formModel) {
        this.form = formModel;

        if (this.type !== ContainerItemType.FORM) {
            this.type = ContainerItemType.FORM;
            this.typeSpecificConfig = ContainerItemTypeSpecificConfigUtil.createConfigByType(this);
        }
        this.createPrimaryEntity();
    };

    /*
     * setViewSharedEHR function is used as part of the container with form model
     * it will be called by search UI or form load util
     * @param formModel - form model for VIEW_SHARED_EHR, can be null
     */
    ContainerItemModel.prototype.setViewSharedEHR = function (formModel) {
        this.form = formModel;
        if (this.type !== ContainerItemType.VIEW_SHARED_EHR) {
            this.type = ContainerItemType.VIEW_SHARED_EHR;
        }
        this.createPrimaryEntity();
    };

    /*
     * set the container item type for an action item
     * @param actionModel - item type
     */
    ContainerItemModel.prototype.setAction = function (actionModel) {
        if (!isActionItem(this.type) && isActionItem(actionModel)) {
            this.type = actionModel;
        }
    };

    /**
     * get the container item type for an action item
     * @returns {null|string} - itm type
     */
    ContainerItemModel.prototype.getAction = function () {
        return isActionItem(this.type) ? this.type : 'Not an action';
    };

    ContainerItemModel.prototype.setExternalIntegration = function () {
        if (this.type !== ContainerItemType.EXTERNAL_INTEGRATION) {
            this.type = ContainerItemType.EXTERNAL_INTEGRATION;
            this.typeSpecificConfig = ContainerItemTypeSpecificConfigUtil.createConfigByType(this);
        }
    };
    ContainerItemModel.prototype.setContent = function () {
        if (this.type !== ContainerItemType.CONTENT) {
            this.type = ContainerItemType.CONTENT;
            this.typeSpecificConfig = ContainerItemTypeSpecificConfigUtil.createConfigByType(this);
        }
    };

    ContainerItemModel.prototype.setAgreement = function (agreementModel) {
        this.agreement = agreementModel;

        if (this.type !== ContainerItemType.AGREEMENT) {
            this.type = ContainerItemType.AGREEMENT;
        }

        this.createPrimaryEntity();
    };

    ContainerItemModel.prototype.setAppointment = function () {
        if (this.type !== ContainerItemType.APPOINTMENT) {
            this.type = ContainerItemType.APPOINTMENT;
            this.typeSpecificConfig = ContainerItemTypeSpecificConfigUtil.createConfigByType(this);
        }
    };

    ContainerItemModel.prototype.setMeasurements = function () {
        if (this.type !== ContainerItemType.MEASUREMENTS) {
            this.type = ContainerItemType.MEASUREMENTS;
        }
    };

    /**
     * getting the form model
     * it will be called by search UI or form load util
     * @returns {*} - current form model
     */
    ContainerItemModel.prototype.getForm = function () {
        return this.form;
    };

    ContainerItemModel.prototype.getFormName = function () {
        return this.form == null ? null : this.form.name;
    };

    ContainerItemModel.prototype.getDescriptionAsHtml = function () {
        let typeSpecificInfo = null;
        switch (this.type) {
        case 'FORM':
            const formSpecificConfigInfo = this.typeSpecificConfig == null ? '' : this.typeSpecificConfig.getDescriptionAsHtml();
            const customDisplayConfigInfo
                    = getUseCustomDisplayConfigLabel(this._useCustomDisplayConfig);
            const labels = [
                formSpecificConfigInfo,
                customDisplayConfigInfo,
            ];
            typeSpecificInfo = `${getFormInfo(this.form)} | ${labels.filter(label => Boolean(label)).join(' ')}`;
            break;
        case 'EXTERNAL_INTEGRATION':
        case 'CONTENT':
            const typeSpecificConfigInfo
                    = this.typeSpecificConfig == null ? '' : this.typeSpecificConfig.getDescriptionAsHtml();
            typeSpecificInfo = `${typeSpecificConfigInfo}`;
            break;
        case 'AGREEMENT':
            const agreementInfo = getAgreementInfo(this.agreement);
            typeSpecificInfo = `${agreementInfo}`;
            break;
        case 'APPOINTMENT':
            const appointmentSpecificConfigInfo
                = this.typeSpecificConfig == null ? '' : this.typeSpecificConfig.getDescriptionAsHtml();
            typeSpecificInfo = `${appointmentSpecificConfigInfo}`;
            break;
        case 'VIEW_SHARED_EHR':
            typeSpecificInfo = this.name ? `${getFormInfo(this.form)} | ${this.name}` : `${getFormInfo(this.form)}`;
            break;
        case 'ACTION_APPOINTMENT':
        case 'ACTION_VIEW_PMB':
        case 'ACTION_NO_SUPPORT_DATA':
            typeSpecificInfo = getActionTypeSpecificInfo(this);
            break;
        case 'MEASUREMENTS':
            typeSpecificInfo = this.name || 'No name added';
            break;
        default:
            break;
        }
        return `${getContainerItemIdInfo(this.id)} | ${getTypeInfo(this.type)} | ${typeSpecificInfo}`;
    };

    /**
     * check if item completion over write should be enabled or not in here
     * @returns {boolean} - true if item completion is supported, false otherwise
     */
    ContainerItemModel.prototype.isItemCompletionOverwriteSupported = function () {
        // currently, we will check if parent container allow this or not
        // it might need to be changed later when we have multiple items inside the same container
        // type, and some support overwrites and some doesn't
        return this._parent != null && this._parent.isItemCompletionOverwriteSupported();
    };

    /**
     * validate the entity
     */
    ContainerItemModel.prototype._validateSelf = function () {
        this.clearError();
        let errMsg = null;
        let errMsgList = [];
        if (this._parent && this._parent.containerType) {
            const type = cs.findTypeByName(this._parent.containerType);
            // if the container is not app supported, then all the three flags should be true
            if (type && type.appTypeSupportedLevel && !type.appTypeSupportedLevel.containerItem && !(this.androidSupported && this.iosSupported && this.webSupported)) {
                errMsg = 'All supported app types should be true in default.';
                errMsgList.push(errMsg);
            }
            // if the container is app supported, then each of the three flags should not be false
            if (type?.appTypeSupportedLevel?.containerItem) {
                if (!this.androidSupported && !this.iosSupported && !this.webSupported) {
                    errMsg = 'At least one supported app type should be configured.';
                    errMsgList.push(errMsg);
                }
                // if the container is app supported, then container item level need to be subset of container level.
                let container = this._parent;
                if (!container.isAndroidSupported && this.androidSupported || !container.isWebSupported && this.webSupported || !container.isIosSupported && this.iosSupported) {
                    errMsgList.push('Supported app type in container item should be subset of options selected at the container level');
                }
            }
        }

        if (this.hasLockPreview && (!this.defaultLockedMessage || !this.defaultLockedMessage.hasValue()) && !isActionItem(this.type)) {
            errMsg = 'container item default locked message is required';
            errMsgList.push(errMsg);
        }

        // a list of entities only have one primary entity for either form or agreement type
        let numberPrimaryForm = 0;
        let numberPrimaryAgreement = 0;
        if (this.linkedEntities && this.linkedEntities.length > 0) {
            this.linkedEntities.forEach(entity => {
                if (entity.primary && entity.entityType === cs.containerItemLinkedEntityType.FORM) {
                    numberPrimaryForm = numberPrimaryForm + 1;
                }
                if (entity.primary && entity.entityType === cs.containerItemLinkedEntityType.AGREEMENT) {
                    numberPrimaryAgreement = numberPrimaryAgreement + 1;
                }
            });
            if (numberPrimaryForm > 1) {
                errMsg = 'There should be only one primary form in a container item.';
                errMsgList.push(errMsg);
            }
            if (numberPrimaryAgreement > 1) {
                errMsg = 'There should be only one primary agreement in a container item.';
                errMsgList.push(errMsg);
            }
        }
        errMsg = validateTemplateValue(this.templateValues);
        if (errMsg == null) {
            const typeSpecificErrorMessage = vbrCommonUtil.isNullOrUnavailable(this.typeSpecificConfig) ? null
                : this.typeSpecificConfig._validateSelf();
            if (typeSpecificErrorMessage != null) {
                errMsg = typeSpecificErrorMessage;
                errMsgList.push(errMsg);
            }
        }

        if (errMsgList.length > 0) {
            errMsg = errMsgList.join('\n');
            this.setErrorMessage(errMsg);
            if (!this.hasError()) {
                // set error msg at the container level
                this.setErrorMessage(errMsg);
            }
        }
    };

    /** *************************************
     * private functions
     ************************************** */

    /*
     * get the type specific information for container item action type
     * @param item - action container item
     * @returns {string} - type specific information
     */
    function getActionTypeSpecificInfo (item) {
        let defaultString = item.type;
        defaultString = defaultString.slice(defaultString.indexOf('_') + 1);

        if (item.type === ContainerItemType.ACTION.ACTION_NO_SUPPORT_DATA) {
            return item.name ? `ACTION | ${item.name}` : 'ACTION';
        }
        return item.name ? `${defaultString} | ${item.name}` : defaultString;
    }

    /*
     * Self validate on item template value. If validate fails, it will return errorMsg.
     * @param templateValues
     * @returns {string|null}
     */
    function validateTemplateValue (templateValues) {
        let templateValueErrorList = [];
        if (templateValues != null) {
            Object.keys(templateValues).forEach(key => {
                templateValues[key].forEach(conditionalValue => {
                    let errorMsg = conditionalValue.contentValue._validateSelf();
                    if (errorMsg != null) {
                        templateValueErrorList.push(errorMsg);
                    }
                },
                );
            });
        }
        return templateValueErrorList != null && templateValueErrorList.length > 0 ? templateValueErrorList.join('') : null;
    }

    /*
     * check if type is one of the action container item type
     * @param type - container item
     * @returns {boolean} - true if type is an action type, false otherwise
     */
    function isActionItem (type) {
        return Object.values(ContainerItemType.ACTION).includes(type);
    }

    function getUseCustomDisplayConfigLabel (isCustom) {
        if (isCustom) {
            return '<span class="label label-primary" title="Custom Display Configured">C</span>';
        }
        return null;
    }

    function getContainerItemIdInfo (itemId) {
        if (itemId) {
            return `<span class="badge badge-info" title="item id">${itemId}</span>`;
        }
        return '';
    }

    function getTypeInfo (type) {
        switch (type) {
        case ContainerItemType.FORM:
            return '<span class="label label-primary" title="Form">F</span>';
        case ContainerItemType.VIEW_SHARED_EHR:
            return '<span class="label label-primary" title="Form">EHR</span>';
        case ContainerItemType.EXTERNAL_INTEGRATION:
            return '<span class="label label-primary" title="External Integration">E</span>';
        case ContainerItemType.AGREEMENT:
            return '<span class="label label-primary" title="Agreement">A</span>';
        case ContainerItemType.APPOINTMENT:
            return '<span class="label label-primary" title="Appointment">A</span>';
        case ContainerItemType.MEASUREMENTS:
            return '<span class="label label-primary" title="Measurements">M</span>';
        case ContainerItemType.ACTION.ACTION_APPOINTMENT:
        case ContainerItemType.ACTION.ACTION_VIEW_PMB:
        case ContainerItemType.ACTION.ACTION_NO_SUPPORT_DATA:
            return '<span class="label label-primary" title="Action">ACT</span>';
        default:
            return '';
        }
    }

    function getFormInfo (form) {
        if (form == null) {
            return '';
        }

        return `<span class="badge badge-info" title="form id">${form.id}</span> ${shortenString(form.name, 40)} `;
    }

    function getAgreementInfo (agreement) {
        if (agreement == null) {
            return '';
        }

        let agreementTypeString = null;
        switch (agreement.type) {
        case 'PRIMARY_CONSENT':
            agreementTypeString = '<span class="label label-primary" title="Primary">P</span>';
            break;
        case 'SUPPLEMENTAL_CONSENT':
            agreementTypeString = '<span class="label label-primary" title="Supplemental">S</span>';
            break;
        case 'DISCLOSURE':
            agreementTypeString = '<span class="label label-primary" title="Disclosure">D</span>';
            break;
        case 'DATA_SHARING':
            agreementTypeString = '<span class="label label-primary" title="Data Sharing">DS</span>';
            break;
        default:
            break;
        }

        return `${shortenString(agreement.displayTitle.en, 40)} <span class="badge badge-info"> ${agreement.id} </span> | ${agreementTypeString}`;
    }

    function shortenString (input, maxLength) {
        if (input == null || maxLength == null || maxLength <= 3 || input.length <= maxLength) {
            return input;
        }

        return `${input.substring(0, maxLength - 3) }...`;
    }

    /*
     * getting form dto from model
     * @param formModel - form model, could be null, could be a form
     * @returns {null|{name: *, id: *}|*}
     */
    function getFormDto (formModel) {
        if (formModel == null) {
            // if there is no form model at all, just return null;
            return null;
        }

        if (formModel.toDto != null) {
            // if form model has toDto function, then call it and return the result
            return formModel.toDto();
        }

        // non null object, but doesn't have toDto function, must be coming from form search, just get the basic property out
        return {
            id   : formModel.id,
            name : formModel.name,
        };
    }
    /*
     * convert template values map from object format to simple dto
     * @param templateValues - template values in a mapped object format
     * @param files - files array to insert new file into it
     * @returns {*} - simple dto for the mapped object
     */
    function templateValuesToDto (templateValues, files) {
        if (templateValues == null) {
            return null;
        }

        const dtoValues = {};
        let hasValue = false;

        _.forEach(templateValues, (valueListModel, key) => {
            if (valueListModel != null && valueListModel.length > 0) {
                const valueListDto = [];
                _.forEach(valueListModel, conditionalValue => {
                    valueListDto.push(conditionalValue.toDto(files));
                });
                dtoValues[key] = valueListDto;
                hasValue = true;
            }
        });

        return hasValue ? dtoValues : null;
    }

    function dtoToTemplateValues (templateValuesDto) {
        if (templateValuesDto == null) {
            return {};
        }

        const valueModels = {};
        _.forEach(templateValuesDto, (valueListDto, key) => {
            const valueListModel = [];
            _.forEach(valueListDto, vDto => {
                const conditionalValue = new ConditionalContentValue();
                conditionalValue.fromDto(vDto);
                valueListModel.push(conditionalValue);
            });
            valueModels[key] = valueListModel;
        });

        return valueModels;
    }

    function hasTemplateKeyChangedForContainerItem (oldKey, newKey) {
        return oldKey !== null && oldKey !== newKey;
    }

    /** *************************************
     * service return call
     ************************************** */

    return ContainerItemModel;
});
