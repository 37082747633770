import template from './ruleActionStatus.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('ruleActionStatusConfig', function (
        ProgramTestConstantsService, BusinessRule, RuleModelService, ValueComparisonOptionModel, ValueType, $translate,
        RuleConstantsService) {
        return {
            restrict: 'E',
            template: template,
            scope: {
                condition: '='
            },
            link: function ($scope) {
                $scope.timeText= $translate.instant('acadiamasterApp.ifCondition.joinCondition.businessRuleExecutionTime.text');

                $scope.pc = ProgramTestConstantsService;
                $scope.isReady = false;
                $scope.programId = $scope.condition.getRoot().programId;
                init($scope)

            }
        };

        function init($scope) {
            $scope.data = {
                ruleOptions : {
                    getRule: function () {
                        return $scope.condition.getRule();
                    },
                    setRule: function (rule) {
                        if (rule == null) {
                            $scope.condition.setRule(null);
                        }
                        else {
                            // load the full rule from server so we can set workflow data
                            BusinessRule.get({id: rule.id}).$promise.then(function (data) {
                                var ruleModel = new RuleModelService.RuleModel(null, null);
                                ruleModel.fromDto(data);

                                $scope.condition.setRule(ruleModel);
                            });
                        }
                    },
                    title: 'Business Rule',
                    viewOnly: false
                },
                valueComparisonOption : createValueComparisonOption($scope.condition)
            };
            addWatches($scope);

            $scope.isReady = true;
        }

        function addWatches($scope) {
            $scope.$watch('condition.workflowId', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    $scope.condition.expressionId = null;
                    $scope.condition.actionId = null;
                }
            });
        }

        function createValueComparisonOption(condition) {
            const options = {};
            let isFirst = true;
            _.forEach(RuleConstantsService.ruleActionStatus, function (key, value) {

                if(isFirst){
                    options[key] = { name: 'NONE', text: 'NONE' };
                    isFirst = false;
                }
                options[key] = { name: value, text: value };
            });

            const valueComparisonOption = new ValueComparisonOptionModel();

            valueComparisonOption.isRequired = false;
            valueComparisonOption.canConfigureOperator = true;
            valueComparisonOption.descirption = 'When IS_NULL is selected then query does not filter on ActionExecutionStatus. Query should return all users for that ActionAudit.';


            valueComparisonOption.functions.getValueType = function () {
                return ValueType.STRING;
            };

            valueComparisonOption.functions.getOperator = function () {
                return condition.getComparator();
            };

            valueComparisonOption.functions.setOperator = function (newOperator) {
                condition.setComparator(newOperator);
            };

            valueComparisonOption.functions.getValues = function () {
                return condition.ruleActionStatusList;
            };

            valueComparisonOption.functions.setValues = function (newValues) {
                condition.ruleActionStatusList = newValues;
            };

            valueComparisonOption.functions.getPossibleValues = function () {
                return options;
            };

            valueComparisonOption.functions.showDescription = function () {
                return condition.comparator == ProgramTestConstantsService.operators.IS_NULL;
            };

            return valueComparisonOption;
        }
    });
})();
