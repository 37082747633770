(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * test suite configuration directive
     */
        .directive('programTestSuiteConfiguration', function (vbrCommonUtil, ProgramTestService,ProgramService,
                                                              DataTypeUniqueByName, $location, $anchorScroll,
                                                              $state, $rootScope, ProgramTestSuiteModelService,
                                                              $timeout, CommonResource, CONFIG, ngDialog) {
            const PING_TIME_SEC = 900; // 15 minutes
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTestSuite/programTestSuiteCreate.html',
                scope: {
                    programId: '=',
                    stateAfterSave: '=',
                    stateAfterCancel: '=',
                    testSuite: '=',
                    isNew: '='
                },
                link: function ($scope) {
                    if(!$scope.testSuite) {
                        $scope.testSuite = {};
                        $scope.testSuite.tagMappings = [];
                    }
                    $scope.CONFIG = CONFIG;

                    // States for create/edit controller
                    $scope.states = {
                        entityReady: false
                    };
                    // Controls
                    $scope.controls = {
                        cancel: function () {
                            $state.go($scope.stateAfterCancel);
                        },
                        save: function () {
                            $scope.saveCount = 0;
                            $scope.timeStart = (new Date()).getTime();
                            doSave($scope);
                        },
                        onSaveFinishedAndExit: function () {
                            $state.go($scope.stateAfterSave);
                        }
                    };

                    $scope.program = ProgramService.get({id:$scope.testSuite.programId});

                    init($scope);
                }
            };


            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             */
            function init($scope) {
                vbrCommonUtil.translate.addPartsAndRefresh([
                    'global'
                ]);

                if($scope.testSuite) {
                    $scope.testSuite.loadModelFromDto();
                }else{
                    $scope.testSuite.ProgramTestSuiteModel();
                }
                schedulePingRequest($scope, PING_TIME_SEC);
            }

            function schedulePingRequest($scope, timeInSeconds) {
                const timeInMillis = timeInSeconds * 1000;

                // method to
                let callPingWebservice = function() {
                    let promise = CommonResource.ping();
                    promise.then(function () {
                        // handling is not needed
                        $scope.pingRequestTimeout = $timeout(callPingWebservice, timeInMillis);
                    }, function (response) {
                        console.error('error in ping server api call', response);
                    });
                };

                // create timeout
                $scope.pingRequestTimeout = $timeout(callPingWebservice, timeInMillis);

                // cancel timeout on destroy
                $scope.$on('$destroy', function(){
                    $timeout.cancel($scope.pingRequestTimeout);
                });
            }

            /**
             * save object
             * @param $scope
             */
            function doSave($scope) {
                // change callback when user selected stay on the page
                $scope.testSuite.saveSuite($scope.controls.onSaveFinishedAndExit);
                $scope.testSuiteDTO = $scope.testSuite.toDto([]);
            }
        });
})();
