(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('UnauthenticatedTaskService', function ($resource, localWeb, $http) {

            const getAllTasks = (programId) => {
                let url = localWeb.createUrl(`api/admin/program/${programId}/unauthenticatedTasks`);
                return $http.get(url);
            };

            const getTaskById = (taskId) => {
                let url = localWeb.createUrl(`api/admin/task/${taskId}`);
                return $http.get(url);
            };

            const createUpdateTasks = (taskData) => {
                let url = localWeb.createUrl(`api/admin/tasks`);
                return $http.post(url, taskData);
            };

            const deleteTaskById = (taskId) => {
                let url = localWeb.createUrl(`api/admin/task/${taskId}`);
                return $http.delete(url);
            };

            return {
                getAllTasks,
                getTaskById,
                createUpdateTasks,
                deleteTaskById
            };
        });
})
();
