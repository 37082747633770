'use strict';

angular.module('acadiamasterApp')
    .directive('checkPasteContent', function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, element, attrs, ctrl) {
                element.on('paste', function (event) {
                    var pasteContent = event.originalEvent.clipboardData.getData('text/plain');
                    var regex = new RegExp(attrs.regEx);
                    event.preventDefault();
                    var contentArray = [];
                    var tempArray = [];
                    const strLength=100;
                    contentArray = pasteContent.split("");

                    if(ctrl.$viewValue) {
                            tempArray += ctrl.$viewValue;
                    }

                    contentArray.forEach(i => {
                        if (i.match(regex)) {
                            tempArray += i;
                        } 
                    });
                    if(tempArray.length>strLength){
                        tempArray=$.trim(tempArray).substr(0, strLength); 
                    }
                    ctrl.$setViewValue(tempArray);
                    ctrl.$render();
                });
            }
        };
    });