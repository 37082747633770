(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for AddressStateComparisonCondition model
     */
    app.factory('AddressTypeConditionModel', function (QObjectModel, AddressStatesConstant,
                                                                  ProgramTestConstantsService) {

        AddressTypeConditionModel.inheritsFrom(QObjectModel);

        function AddressTypeConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.ADDRESS_TYPE);

            //initialized the variables
            this.stateComparator = ProgramTestConstantsService.operators.IS_NULL;
            this.stateList = [];

            this.typeComparator = ProgramTestConstantsService.operators.IS_NULL;
            this.typeList = [];
        }

        /**
         * convert the current UI model to dto format
         */
        AddressTypeConditionModel.prototype.toDto = function () {
            const dto = QObjectModel.prototype.toDto.call(this);

            dto.stateComparator = this.stateComparator==null ? null : this.stateComparator.name;

            dto.stateList = (this.stateList != null && this.stateList.length) ? this.stateList.map(function (item) {
                return item.name;
            }) : null;

            dto.typeComparator = this.typeComparator == null ? null : this.typeComparator.name;

            dto.typeList = (this.typeList != null && this.typeList.length) ? this.typeList.map(function (item) {
                return item.name;
            }) : null;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        AddressTypeConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.setTypeComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.typeComparator));

            this.typeList = dto.typeList ? dto.typeList.map(function (item) {
                return { name: item, text: item };
            }) : [];

            this.setStateComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.stateComparator));

            this.stateList = dto.stateList ? dto.stateList.map(function (item) {
                return { name: item, text: item };
            }) : [];

        };

        AddressTypeConditionModel.prototype.getTypeComparator = function () {
            return this.typeComparator;
        };

        AddressTypeConditionModel.prototype.setTypeComparator = function (typeComparator) {
            this.typeComparator = typeComparator;

            if (typeComparator === ProgramTestConstantsService.operators.IS_NULL) {
                this.typeList = null;
            } else if (this.typeList == null) {
                this.typeList = [];
            } else if ((typeComparator !== ProgramTestConstantsService.operators.IN) && (this.typeList.length > 1)) {
                this.typeList = [this.typeList[0]];
            }
        };

        AddressTypeConditionModel.prototype.getStateComparator = function () {
            return this.stateComparator;
        };

        AddressTypeConditionModel.prototype.setStateComparator = function (stateComparator) {
            this.stateComparator = stateComparator;

            if (stateComparator === ProgramTestConstantsService.operators.IS_NULL) {
                this.stateList = null;
            } else if (this.stateList == null) {
                this.stateList = [];
            } else if ((stateComparator !== ProgramTestConstantsService.operators.IN) && (this.stateList.length > 1)) {
                this.stateList = [this.stateList[0]];
            }
        };

        AddressTypeConditionModel.prototype.getDescriptionAsHtml = function () {
            const baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            const typeMsg = 'User Address';

            const stateIsNull = this.stateComparator === ProgramTestConstantsService.operators.IS_NULL;
            const stateMsg = (this.stateComparator == null || stateIsNull) ? ' and all Verification States' : ' and Verification State';


            const typeComparatorMsg = ' with Type <span class="badge badge-info">' + (this.typeComparator == null ? '?' : this.typeComparator.symbol) +
                '</span> ';

            const stateComparatorMsg = ' <span class="badge badge-info">' + (this.stateComparator == null ? '?' : this.stateComparator.symbol) +
                '</span> ';

            const typeIsNull = this.typeComparator === ProgramTestConstantsService.operators.IS_NULL;
            const typeValueMsg = typeIsNull ? '' : convertListToStringList(this.typeList);
            const stateValueMsg = stateIsNull ? '' : convertListToStringList(this.stateList);


            return baseMsg + ' ' + typeMsg + (typeIsNull ? ' with All Types' : typeComparatorMsg) + typeValueMsg
                + stateMsg + (stateIsNull ? '' : stateComparatorMsg) + stateValueMsg;
        };

        AddressTypeConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.typeComparator == null || this.stateComparator == null) {
                this.setErrorMessage('Type and state comparator is required');
            }

            if (this.typeComparator !== ProgramTestConstantsService.operators.IS_NULL &&
                (this.typeList == null || this.typeList.length === 0)) {
                this.setErrorMessage('Address Type value is required except when the comparator is "is null"');
            }

            if (this.stateComparator !== ProgramTestConstantsService.operators.IS_NULL &&
                (this.stateList == null || this.stateList.length === 0)) {
                this.setErrorMessage('Verification State value is required except when the comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        function convertListToStringList(list) {
            if (list == null) {
                return 'null';
            }

            return _.map(list, function(item) {
                return item.name;
            }).join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return AddressTypeConditionModel;

    });
})();
