(function () {
    angular.module('acadiamasterApp').controller('CreateFormModalController', ($scope, FormConstants) => {
        $scope.constants = FormConstants;
        $scope.defaultSelection = 'Choose Program';
        $scope.selectedProgramCode = $scope.defaultSelection;
        $scope.selectedProgram = {};
        $scope.isEnabled = false;
        $scope.selectedFormType = $scope.constants.formTypes.SYSTEM;

        $scope.checkFormType = function () {
            if ($scope.selectedFormType === $scope.constants.formTypes.PROGRAM) {
                return $scope.selectedProgramCode === $scope.defaultSelection;
            }
            return false;
        };

        $scope.$watch('selectedFormType', (newValue, oldValue) => {
            if (newValue === oldValue) {
                return;
            }
            if (newValue === $scope.constants.formTypes.SYSTEM) {
                $scope.isEnabled = false;
                if ($scope.selectedProgram) {
                    $scope.selectedProgram = {};
                    $scope.selectedProgramCode = $scope.defaultSelection;
                }
            } else {
                $scope.isEnabled = true;
            }
        });

        $scope.onFormTypeSelection = function () {
            let formDetails = {};
            if ($scope.selectedFormType === $scope.constants.formTypes.PROGRAM) {
                formDetails.programCode = $scope.selectedProgramCode;
                formDetails.programId = $scope.selectedProgram.programId;
            }

            $scope.confirm(formDetails);
        };
    });
}());
