(function () {

    angular.module('acadiamasterApp').controller(
        'taskConfigController',
        function (
            $scope, LocaleConstantsService, UnauthenticatedTaskService, $state, $stateParams,
            FormLoadUtil, ModelServiceConditionExpression, ExpressionConstantsService,
            PropertyCacheService, ExpressionParserService, CONFIG, AlertService
        ) {
            const vm = this;
            $scope.CONFIG = CONFIG;
            let onSaveFinished = function () {
                backRoute();
            };

            vm.save = function () {
                if (vm.selectedTask != null) {
                    vm.selectedTask.taskType = vm.data.taskType;
                    vm.selectedTask.taskInfo.formId = vm.data.formId;
                    vm.selectedTask.expirationTimestamp = (vm.data.expirationDate != null) ? vm.data.expirationDate.getTime() : null;
                    vm.selectedTask.eligibilityExpression = vm.data.eligibilityExpression;
                    vm.selectedTask.completionExpression = vm.data.completionExpression;
                    vm.selectedTask.eligibilityExpressionTree = (vm.data.eligibilityExpressionTree ? vm.data.eligibilityExpressionTree.toDto() : null);
                    vm.selectedTask.completionExpressionTree = (vm.data.completionExpressionTree ? vm.data.completionExpressionTree.toDto() : null);
                    vm.selectedTask.propertyIds = vm.getPropertyIds();
                    UnauthenticatedTaskService.createUpdateTasks(vm.selectedTask).then(response => {
                        if (response) {
                            onSaveFinished();
                        }
                    });
                }
            };
            vm.clear = function () {
                backRoute();
            };

            function backRoute() {
                $state.go('program.manage.unauthenticated');
            }

            function init($scope) {
                vm.expConstants = ExpressionConstantsService;
                vm.programId = $stateParams.id;

                if ($scope.task) {
                    vm.selectedTask = $scope.task;
                } else {
                    vm.selectedTask = {
                        eligibilityMessage : {
                            en : null,
                        },
                        completionMessage : {
                            en : null,
                        },
                        completionErrorMessage : {
                            en : null,
                        },
                        expirationErrorMessage : {
                            en : null,
                        },
                        completionExpression  : null,
                        eligibilityExpression : null,
                        expirationTimestamp   : null,
                    };
                }
                vm.selectedTask.programId = vm.programId;
                vm.isSelected = false;
                vm.lcs = LocaleConstantsService;
                vm.data = {
                    accordionTaskInfo : {
                        collapsed : false,
                    },
                    expirationDate            : vm.selectedTask.expirationTimestamp ? new Date(vm.selectedTask.expirationTimestamp) : null,
                    allowedTaskTypes          : vm.allowedTaskTypes(),
                    taskType                  : vm.selectedTask ? vm.selectedTask.taskType : 'FORM',
                    dataFormOptions           : vm.getFormOptions(),
                    eligibilityExpression     : vm.selectedTask.eligibilityExpression,
                    eligibilityExpressionTree : new ModelServiceConditionExpression.ConditionExpression(vm.data),
                    completionExpression      : vm.selectedTask.completionExpression,
                    completionExpressionTree  : new ModelServiceConditionExpression.ConditionExpression(vm.data),
                    propertyIds               : vm.selectedTask.propertyIds ? vm.selectedTask.propertyIds : [],
                };
                vm.data.eligibilityExpressionTree.fromDto(vm.selectedTask.eligibilityExpressionTree);
                vm.data.completionExpressionTree.fromDto(vm.selectedTask.completionExpressionTree);
                if (!vm.selectedTask.taskInfo) {
                    vm.selectedTask.taskInfo = {
                        '@class'          : 'com.vibrent.acadia.web.rest.dto.unauthenticated.task.FormTaskInfoModel',
                        'formId'          : vm.data.formId,
                        'isOneTimeSurvey' : false,
                    };
                }
                vm.data.formId = vm.selectedTask && vm.selectedTask.taskInfo.formId || null;
                if (vm.data.formId != null) {
                    FormLoadUtil.loadForm({
                        formId  : vm.data.formId,
                        getForm : function () {
                            return null;
                        },
                        setForm : function (formModel) {
                            vm.data.dataFormOptions.form = formModel;
                        },
                    });
                }
                vm.data.expirationDateOptions = {
                    showWeeks        : false,
                    formatDayTitle   : 'MMMM yyyy',
                    formatDay        : 'd',
                    isDisabledMonths : false,
                    showButtonBar    : false,
                    minDate          : new Date(),
                };
                vm.data.property = [];
                vm.data.isPropertySet = [];
                vm.data.propertyCount = vm.data.propertyIds.length;
                vm.isReady = false;
                vm.propertyOptions = {
                    getProperty : function(propertyIndex) {
                        return vm.data.propertyIds ? vm.data.property[propertyIndex] : null;
                    },
                    setProperty : function(newValue, propertyIndex) {
                        if(newValue){
                            if(vm.data.property.length > 0 && !(vm.data.propertyIds.includes(newValue.id))){
                                vm.data.property[propertyIndex] = newValue;
                                vm.data.isPropertySet[propertyIndex] = true;
                                vm.data.propertyIds[propertyIndex] = newValue.id;
                            } else if(!(vm.data.isPropertySet[propertyIndex]) && vm.data.propertyIds.includes(newValue.id)){
                                AlertService.error('This property is already added in the list', null);
                                vm.removeProperty(vm.data.property[propertyIndex]);
                            }
                        } else if (!newValue && vm.data.isPropertySet[propertyIndex]){
                            vm.addNullProperty(propertyIndex);
                        }
                    },
                };
                for(let i = 0; i < vm.data.propertyIds.length ; i++ ){
                    loadProperty(i);
                }
                vm.isReady = true;
            }

            function loadProperty(propertyIndex) {
                PropertyCacheService.loadPropertyById(vm.data.propertyIds[propertyIndex])
                    .then(function (property) {
                        vm.data.property[propertyIndex] = property;
                        vm.data.isPropertySet[propertyIndex] = true;
                    }, function (error) {
                        AlertService.error(`unable to load property by id : ${ vm.data.propertyIds[propertyIndex]}`, null, error);
                    });
            }

            vm.enableAdd = function (){
                if(vm.data.propertyIds.length > 0){
                    for(let i = 0; i < vm.data.propertyIds.length; i++){
                        if(vm.data.propertyIds[i] === null){
                            return true;
                        }
                    }
                }
                return false;
            };

            vm.addProperty = function (){
                vm.addNullProperty(vm.data.propertyCount);
                vm.data.propertyCount += 1;
            };

            vm.addNullProperty = function (propertyIndex){
                vm.data.property[propertyIndex] = null;
                vm.data.isPropertySet[propertyIndex] = false;
                vm.data.propertyIds[propertyIndex] = null;
            };

            vm.removeProperty = function (property){
                let propertyIndex = vm.data.property.indexOf(property);
                vm.data.property.splice(propertyIndex, 1);
                vm.data.propertyIds.splice(propertyIndex, 1);
                vm.data.propertyCount -= 1;
            };

            vm.getPropertyIds = function (){
                let temp = [];
                for(let i = 0; i < vm.data.propertyIds.length; i++){
                    if(vm.data.propertyIds[i] !== null){
                        temp.push(vm.data.propertyIds[i]);
                    }
                }
                if (temp.length > 0 && temp[temp.length - 1] === null){
                    temp.pop();
                }
                vm.data.propertyIds = temp;
                return vm.data.propertyIds;
            };

            vm.popupDate = {
                opened : false,
            };
            vm.openDate = function () {
                vm.popupDate.opened = true;
            };
            vm.allowedTaskTypes = () => {
                let taskType = [
                    {
                        name      : 'FORM',
                        serverKey : 'FORM',
                    },
                ];
                return taskType;
            };

            vm.getFormOptions = () => {
                let formOptions = {
                    form             : null,
                    title            : 'Select any data form',
                    viewOnly         : false,
                    formCategory     : 'DATA_FORM',
                    onChangeCallBack : function () {
                        if (this.form) {
                            vm.data.formId = this.form.id;
                            vm.data.completionExpression = `hasFormEntry (${ this.form.id })`;
                            vm.data.completionExpressionTree = ExpressionParserService.parse(
                                vm.data.completionExpression,
                                vm.expConstants.modules.RULES, vm.data, null
                            );
                        } else {
                            vm.data.formId = null;
                        }
                    },
                };
                return formOptions;
            };

            vm.toggleBasicInfo = () => {
                vm.data.accordionTaskInfo.collapsed = !vm.data.accordionTaskInfo.collapsed;
            };

            $scope.changeSurvey = function (value) {
                if(value){
                    vm.data.completionExpression ='';
                    vm.data.completionExpressionTree= null;
                }
            };
            init($scope);
        }
    );
}());
