(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programDistributionPreCondition', function () {
        return {
            restrict: 'E',
            template: '<program-distribution-selection></program-distribution-selection>',
            scope: {
                preCondition: '='
            }
        };
    });
})();
