(function () {
    'use strict';

    /***************************************************************
     ***************************************************************/
    angular.module('acadiamasterApp').factory('FilterFieldService',
        function () {

            /***************************************
             * local variables
             ***************************************/
            var comparators = [
                {id: 1, name: 'EQ', value: '=='},
                {id: 2, name: 'NOT_EQ', value: '!='},
                {id: 3, name: 'LT', value: '<'},
                {id: 4, name: 'LTE', value: '<='},
                {id: 5, name: 'GT', value: '>'},
                {id: 6, name: 'GTE', value: '>='},
                {id: 7, name: 'CONTAINS', value: 'like'},
                {id: 8, name: 'IS_NULL', value: 'is null'}
            ];

            var operators = [
                {name: 'AND', value: '&&'},
                {name: 'OR', value: '||'}
            ];

            // list of comparators that should be disabled
            var disabledComparators = {
                "STRING" : [3, 4, 5, 6],
                "NUMBER" : [7],
                "DATETIME" : [7],
                "BOOLEAN" : [3, 4, 5, 6, 7],
                "FILE" : [3, 4, 5, 6, 7]
            };

            /**
             * Filter Field Object
             * @constructor
             */
            function FilterField(name, valueType, id, fieldType, formId, fieldHtml, type, dataType) {
                this.name = name;
                this.valueType = valueType;
                this.id = id;
                this.formId = formId;
                this.fieldType = fieldType;
                this.fieldHtml = fieldHtml;
                this.type = type;
                this.dataType = dataType;

                // list of disabled comparators used by the library to make the UI easier to use
                this.disabledComparators = disabledComparators[dataType];
            }

            FilterField.prototype.toHtml = function () {
                var message = "";
                if (this.id) {
                    message += "<span class='badge'>fieldId:" + this.id + "</span> | ";
                }

                if (this.formId) {
                    message += "<span class='badge'>formId:" + this.formId + "</span> | ";
                }

                message += ' ' + this.name + " | " + this.valueType;

                if (this.fieldType!=null) {
                    message += " | " + this.fieldType;
                }

                return message;
            };

            /***************************************
             * factory return call
             ***************************************/

            return {
                FilterField : FilterField,
                operators : operators,
                comparators : comparators
            };
        });
})();
