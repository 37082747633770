/**
 * Created by moustafabaiou on 2/15/17
 */
angular.module('acadiamasterApp')
    .directive('treeNodeSqlActionFixedNode', (RuleUtilService, $timeout, RuleExpressionModelService, ExpressionService, FixedNodeService, ngDialog) => ({
        controller: ($scope) => {
            $scope.ruleConstants = RuleUtilService.ruleConstants;
            $scope.fixedNodeService = FixedNodeService;
            $scope.actionTypeUtil = RuleExpressionModelService.getActionTypeUtil();

            $scope.showAddActionDialog = () => {
                ngDialog.openConfirm({
                    template: 'admin-templates/site/components/treeEditorDialogs/addActionExisting.template.html',
                    controller: 'ActionSelectionPopupController',
                    data: {
                        parentNode: $scope.actionHolderFixedNode
                    }
                }).then(function (action) {
                    ExpressionService.addNewAction(action, $scope.actionHolderFixedNode);
                    $scope.actionHolderFixedNode.validate(false);
                });
            };

            // Controls
            $scope.controls = {
                addNewAction(actionType, actionHolderFixedNode) {
                    ExpressionService.addNewAction(actionType, actionHolderFixedNode);
                    $scope.expression.validate(false);
                },
            };

            $scope.hasExistingActions = () => $scope.expression.getRule().actions.length > $scope.expression.actionHolders.length;

            $scope.containsAction = (action) => {
                return RuleUtilService.findActionHolderByCode($scope.expression.actionHolders, action.code) != null;
            };
        },
        restrict: 'E',
        scope: {
            actionHolderFixedNode: '=',
            selector: '=',
        },
        templateUrl: 'admin-templates/site/businessRule/treeNode/sqlActionFixedNode.html',
    }));
