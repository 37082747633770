(function () {
    'use strict';

    /**
     * value configuration directive, to select which value
     */
    angular.module('acadiamasterApp').directive('valueConfig', function (ValueTypeObjectUtil, ValueTypeUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/common/valueConfig/valueConfig.html',
            scope: {
                valueIndex: '=',
                options: '='
            },
            link: function ($scope) {
                $scope.ValueTypeObjectUtil = ValueTypeObjectUtil;
                $scope.fieldName = 'value';

                var formName = 'form_value_' +  Math.floor(Math.random() * 100000);

                $scope.getValueType = function() {
                    return $scope.options.getValueType();
                };

                $scope.booleanOptions = [
                    {value: true, label: 'True'},
                    {value: false, label: 'False'}
                ];

                $scope.data = {
                    value : getValueAsPropertyType($scope)
                };

                $scope.getFormName = function() {
                    return formName;
                };

                $scope.getError = function() {
                    return $scope[formName][$scope.fieldName].$error;
                };

                init($scope);
            }
        };

        function getValueAsPropertyType($scope) {
            var v = $scope.options.getValue($scope.valueIndex);
            var valueType = $scope.options.getValueType();

            return ValueTypeUtil.stringValueConvertedToType(v, valueType);
        }

        function init($scope) {
            $scope.$watch('data.value', function(newValue, oldValue) {
                if (newValue != oldValue) {
                    var newValueAsString = ValueTypeUtil.valueConvertedToString(newValue);
                    $scope.options.setValue($scope.valueIndex, newValueAsString);
                }
            });
        }

    });

})();

