import template from './table.template.html';
(function () {
    angular.module('acadiamasterApp')
        .directive('tableCommon', () => {
            return {
                restrict : 'E',
                scope    : {
                    columns         : '=',
                    data            : '=',
                    filterData      : '=',
                    handleClickView : '=handleClickView',
                    orderBy         : '=orderBy',
                },
                template : template,
            };
        });
}());
