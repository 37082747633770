import ContainerItemType from '../../../programManagement/program/containerManagement/const/ContainerItemType.const';
(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for recalculate personalized container item
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionRecalculationPersonalizedContainerItem',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, ContainerItemLoadUtil) {

            CorrectiveActionRecalculationPersonalizedContainerItem.inheritsFrom(CorrectiveActionBaseModel);

            /**
             * @constructor
             */
            function CorrectiveActionRecalculationPersonalizedContainerItem(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService
                    .correctiveActionEnum.EVALUATE_PERSONALIZED_CONTAINER_ITEM);

                this.containerItemId = null;

                this._container = null;
                this._containerItem = null;
            }

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.getContainerItem = function() {
                return this._containerItem;
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.setContainerItem = function(newContainerItem) {
                this._containerItem = newContainerItem;
                this.containerItemId = newContainerItem == null ? null : newContainerItem.id;
                this.formId = newContainerItem == null || newContainerItem.form == null ? null : newContainerItem.form.id;
                if (this._containerItem && this._container !== this._containerItem._parent) {
                    this._container = this._containerItem._parent;
                }
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.getContainer = function() {
                return this._container;
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.setContainer = function(newContainer) {
                this._container = newContainer;
                this.containerId = this._container ? this._container.id : null;
                if (this._containerItem && this._container !== this._containerItem._parent) {
                    // we are changing container, we should clear the container item if it's not for the same parent
                    this._containerItem = null;
                    this.containerItemId = null;
                }
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.getProgramId = function() {
                const rootElement = this.getRoot();
                return rootElement==null ? null : rootElement.programId;
            };



            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.containerItemId = dto.containerItemId;
                this._containerItem = null;
                this._container = null;

                ContainerItemLoadUtil.loadContainerItem(this);
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.toDto = function () {
                const dto = CorrectiveActionBaseModel.prototype.toDto.call(this);
                dto.containerId = this._container.id;
                dto.containerItemId = this.containerItemId;
                return dto;
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype.getDescriptionAsHtml = function () {
                const typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);

                const containerItemMsg = getContainerItemMessage(this);

                return typeMsg + 'for ' + containerItemMsg;
            };

            CorrectiveActionRecalculationPersonalizedContainerItem.prototype._validateSelf = function () {
                this.clearError();

                if (this.containerItemId == null) {
                    this.setErrorMessage('container item id is required');
                }
            };

            /***************************************
             * private functions
             ***************************************/

            /**
             * build the container item message
             * @param correctiveAction - this
             * @returns {string} - message
             */
            function getContainerItemMessage (correctiveAction) {
                let message = `Container Item (${correctiveAction.containerItemId == null ? 'no id' : correctiveAction.containerItemId} | `;

                if (!correctiveAction._containerItem) {
                    message += 'no name';
                } else if (correctiveAction._containerItem.type === ContainerItemType.FORM) {
                    message += correctiveAction._containerItem.getFormName();
                } else if (isActionItem(correctiveAction._containerItem.type)) {
                    if (correctiveAction._containerItem.type === ContainerItemType.ACTION.ACTION_NO_SUPPORT_DATA) {
                        message += 'ACTION';
                    } else {
                        message += correctiveAction._containerItem.getAction();
                    }
                }
                if (correctiveAction._containerItem && correctiveAction._containerItem.name) {
                    message += ` | ${correctiveAction._containerItem.name}`;
                }
                message += ')';
                return message;
            }
            /**
             * check if type is one of the action container item type
             * hard coded types array because ContainerItemType cannot be wired in here
             * @param type - container item
             * @returns {boolean} - true if type is an action type, false otherwise
             */
            function isActionItem (type) {
                return ['ACTION_APPOINTMENT', 'ACTION_VIEW_PMB', 'ACTION_NO_SUPPORT_DATA'].includes(type) ;
            }

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionRecalculationPersonalizedContainerItem;
        });
})();
