(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('testCaseDetail', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/testCaseDetail/programTest.detail.html',
            scope: {
                // boolean to indicate if this is a simple format or not
                // simple format == true indicate no detail shown for all the conditions
                simpleFormat: '=',
                // raw json data for test case, for non-simple format, we need to create model object
                // on top of the raw format
                testCaseRaw : '='
            },
            link: function ($scope) {
                // todo: add more processing for non-simple format later
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/



    });

})();



