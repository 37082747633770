/**
 * Created by jason.cao on 10/31/17
 */
(function () {
    'use strict';

    /**
     * directive for a form comparison page
     */
    angular.module('acadiamasterApp').directive('vbrFormCompareTools', function (
        CompareResultFormVersionService, $window, FormUtilService) {

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/tools/tools.formCompare.html',
            scope: {},
            link: function ($scope) {
                $scope.showChangeOnlyOptions = [
                    {name : 'Show Change Only', value : true},
                    {name : 'Full Result (v slow)', value : false}
                ];

                // data object holding all the necessary information
                $scope.data = {
                    file1 : null,
                    file2 : null,
                    formVersion1 : null,
                    formVersion2 : null,
                    showChangeOnly : true,
                    formVersionCombined : null
                };

                /**
                 * check if files are already selected
                 * @returns {boolean}
                 */
                $scope.filesReady = function() {
                    return $scope.data.file1 != null && $scope.data.file2 != null;
                };

                /**
                 * function call to load the files into models and start the actual form version
                 * comparison, there is no return for this function, the output will be stored in
                 * $scope.data.formVersionCombined, and other directive will pick it up and display
                 * the result automatically through the digest cycle
                 */
                $scope.compareFiles = function() {
                    // load file 1 into model, comparison is done from callback
                    loadFileToDto($scope.data.file1, function(dto) {
                        var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                        formObject.fromDto(dto);
                        $scope.data.formVersion1 = formObject.formVersions[0];
                        startComparingIfPossible($scope);
                    });

                    // load file 2 into model, comparison is done from callback
                    loadFileToDto($scope.data.file2, function(dto) {
                        var formObject = new FormUtilService.FormModelServiceForm.FormModel();
                        formObject.fromDto(dto);
                        $scope.data.formVersion2 = formObject.formVersions[0];
                        startComparingIfPossible($scope);
                    });
                };

            }
        };

        /**
         * start comparison if possible (ie: both versions are loaded)
         * @param $scope - scope object
         */
        function startComparingIfPossible($scope) {
            // if data is not ready, just exit
            if ($scope.data.formVersion1==null || $scope.data.formVersion2==null) {
                return;
            }

            // actual comparison function is run here
            $scope.data.formVersionCombined = CompareResultFormVersionService.compare(
                $scope.data.formVersion1, $scope.data.formVersion2);

            $scope.$apply();
        }

        /**
         * loading file into model
         * @param file - file
         * @param onSuccess - onSuccess function, the dto created will be passed to the on success function
         */
        function loadFileToDto(file, onSuccess) {
            var reader = new $window.FileReader();

            reader.onload = function() {
                var dto = reader.result;
                dto = JSON.parse(dto);
                onSuccess(dto);
            };

            reader.readAsText(file);
        }


    });
})();

