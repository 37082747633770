(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerPciDismissStatusChange', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.pciDismissStatusChange.html',
                scope: {
                    element: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.isReady = false;

                    init($scope);
                }
            };

            function init($scope){
                $scope.containerOptions = {
                    getContainer: () => $scope.element.getContainer(),
                    setContainer: (container) => $scope.element.setContainer(container),
                    programId: $scope.element.getProgramId(),
                    filterFunction: (container) => {
                        // filter out container that doesn't have container items
                        return container!=null && container.containerItems!=null && container.containerItems.length > 0;
                    }
                };

                $scope.containerItemOptions = {
                    getContainerItem: () => $scope.element.getContainerItem(),
                    setContainerItem: (containerItem) => $scope.element.setContainerItem(containerItem),
                    programId: $scope.element.getProgramId(),
                    getContainerId : () => {
                        const container = $scope.element.getContainer();
                        return container == null ? null : container.id;
                    },
                };

                $scope.dismissStatusChange = function (newSelection) {
                    if (newSelection != null) {
                        $scope.element.setDismissStatusAfterAction(newSelection.name);
                    }
                };

                $scope.shouldShowContainerItemSelection = function() {
                    const container = $scope.element.getContainer();
                    return container!=null && container.containerItems!=null && container.containerItems.length>0;
                };


                // set flag
                $scope.isReady = true;
            }
        });
})();
