/**
 * Created by Stephen Yates on 05/24/2019
 *
 * Deletes expression condition
 */

angular.module('acadiamasterApp')
    .controller('ExpressionControllerDeleteDialogController', (expression) => {
        const vm = this;
        vm.expression = expression;
        vm.hasActiveUsage = expression.usages.some(usage => !usage.isDeleted);
        return vm;
    });
