/**
 * Created by Deeya Upadhyay on 18/8/2022
 */
import template from './metadata.map.html';

(function () {
    angular.module('acadiamasterApp')
        .directive('metaDataMap', vxpEventModelService => {
            function init ($scope) {
                $scope.tempMetaData = [];
                if (Object.keys($scope.action.fulfillmentRequestEventActionDTO.metadata).length !== 0) {
                    for (const key of Object.keys($scope.action.fulfillmentRequestEventActionDTO.metadata)) {
                        $scope.tempMetaData.push({
                            keyName : key,
                            value   : $scope.action.fulfillmentRequestEventActionDTO.metadata[key],
                        });
                    }
                }
            }
            return {
                link : function ($scope) {
                    $scope.removeMetaData = function (index) {
                        $scope.tempMetaData.splice(index, 1);
                        $scope.updateDto();
                    };

                    $scope.displayMetaData = function () {
                        const metaDataModel = new vxpEventModelService.FulfillmentEventMetadataModel();
                        $scope.tempMetaData.push(metaDataModel);
                    };

                    $scope.updateDto = function () {
                        const obj = {};
                        for (let i = 0; i < $scope.tempMetaData.length; i++) {
                            const { keyName, value } = $scope.tempMetaData[i];
                            obj[keyName] = value;
                        }
                        $scope.action.fulfillmentRequestEventActionDTO.metadata
                        = { ...obj };
                    };

                    init($scope);
                },
                restrict : 'E',
                scope    : {
                    action : '=',
                },
                template : template,
            };
        });
}());
