/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service used to define shared functions among all tree nodes
     */
        .factory('treeNodeCommonService', function(FormUtilService, vbrCommonUtil) {
            var FIELD_VALUE_MAX_LENGTH = 25;

            return {
                getFieldValueForDisplay : function(fieldValue, maxLength) {
                    var message = null;
                    if (maxLength==null) {
                        maxLength = FIELD_VALUE_MAX_LENGTH;
                    }
                    if (!vbrCommonUtil.isNullOrUnavailable(fieldValue)) {
                        var valueString = fieldValue.getValueAsString();
                        message = this.getStringValueForDisplay(valueString, maxLength);
                    }
                    return message;
                },

                getStringValueForDisplay : function(input, maxLength) {
                    if (maxLength==null) {
                        maxLength = FIELD_VALUE_MAX_LENGTH;
                    }
                    var message = input;
                    if (input!=null && input.length>maxLength) {
                        message = input.substring(0, maxLength) + " ...";
                    }

                    return message;
                }
            }
        });
})();

