
angular.module('acadiamasterApp')
    .controller('PasswordController', ($scope, Auth, Principal, PasswordUtilService) => {
        Principal.identity().then(account => {
            $scope.account = account;
        });

        $scope.success = null;
        $scope.error = null;

        $scope.changePassword = () => {
            if ($scope.password !== $scope.confirmPassword) {
            } else {
                const passwordJson = {
                    oldPassword : $scope.currentPassword,
                    newPassword : $scope.password,
                };
                Auth.changePassword(passwordJson).then(() => {
                    $scope.error = null;
                    $scope.success = 'OK';
                }).catch(() => {
                    $scope.success = null;
                    $scope.error = 'ERROR';
                });
            }
        };

        $scope.validateNewPassword = () => {
            const { password } = $scope;
            const { confirmPassword } = $scope;
            $scope.form.password.$setValidity('invalid', PasswordUtilService.isValidPassword(password, 8));
            $scope.form.confirmPassword.$setValidity('mismatch', confirmPassword === undefined || password === confirmPassword);
        };

        $scope.validateConfirmPassword = () => {
            const { password } = $scope;
            const { confirmPassword } = $scope;
            $scope.form.confirmPassword.$setValidity('mismatch', confirmPassword === undefined || password === confirmPassword);
        };
    });
