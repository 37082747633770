(function () {
    angular.module('acadiamasterApp').factory('filterFieldsUtil', FormConstants => {
        return {
            filterOutInputFields : function (listToFilterFrom) {
                let inputFields = [];
                if (listToFilterFrom != null) {
                    for (let i = 0; i < listToFilterFrom.length; i++) {
                        if (listToFilterFrom[i].formField && listToFilterFrom[i].formField.id
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.TEXT_LABEL
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.RICH_TEXT_LABEL
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.EXTERNAL_RICH_TEXT_LABEL
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.IMAGE_DISPLAY
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.DIVIDER
                            && listToFilterFrom[i].type !== FormConstants.fieldsType.LINK) {
                            inputFields.push(listToFilterFrom[i]);
                        }
                    }
                }
                return inputFields;
            },
        };
    });
}());
