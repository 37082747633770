/**
 * program configuration for social icons configuration
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('programSocialConfig', function (FormModelServiceFieldValue,ProgramService, $state, CONFIG) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programManagement/program/socialIcons/programSocialConfig.html',
            scope: {
                programId: '@',
                showReadOnly: '=',
            },
            controller: controller
        };

        /**
         * main controller function
         */
        function controller($scope) {
            $scope.CONFIG = CONFIG;
            $scope.readyStates = false;
            $scope.getSocialMediaIcons = getSocialMediaIcons;
            $scope.saveProgram = saveProgram;
            function getSocialMediaIcons(socialMediaIcons){
                const socialMedia = {
                    enableTwitter: false,
                    twitterURL: null,
                    enableFacebook: false,
                    facebookURL: null,
                    enableYouTube: false,
                    youTubeURL: null,
                    enableInstagram: false,
                    instagramURL: null,
                    twitterIcon: new FormModelServiceFieldValue.ImageValue(),
                    facebookIcon:new FormModelServiceFieldValue.ImageValue(),
                    youTubeIcon:new FormModelServiceFieldValue.ImageValue(),
                    instagramIcon:new FormModelServiceFieldValue.ImageValue(),
                }
                if (socialMediaIcons) {
                    if (!socialMediaIcons.twitterIcon) {
                        socialMediaIcons.twitterIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!socialMediaIcons.facebookIcon) {
                        socialMediaIcons.facebookIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!socialMediaIcons.youTubeIcon) {
                        socialMediaIcons.youTubeIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                    if (!socialMediaIcons.instagramIcon) {
                        socialMediaIcons.instagramIcon = new FormModelServiceFieldValue.ImageValue();
                    }
                }
                const socialMediaIconsReturn = socialMediaIcons || socialMedia;
                return socialMediaIconsReturn;
            }
            function init($scope) {
                $scope.program = ProgramService.get({id: $scope.programId});
                $scope.program.$promise.then(() => {
                    $scope.program.programOptions.socialMediaIcons = $scope.getSocialMediaIcons($scope.program.programOptions.socialMediaIcons);
                    $scope.readyStates = true;
                });
            }

            function onSaveFinished() {
                $state.go('program.manage', {id: $scope.program.id});
            }
            /**
             * save program
             */
            function saveProgram() {
                $scope.files = [];
                setSocialMediaImage($scope, $scope.program.programOptions.socialMediaIcons.twitterIcon);
                setSocialMediaImage($scope, $scope.program.programOptions.socialMediaIcons.facebookIcon);
                setSocialMediaImage($scope, $scope.program.programOptions.socialMediaIcons.youTubeIcon);
                setSocialMediaImage($scope, $scope.program.programOptions.socialMediaIcons.instagramIcon);
                ProgramService.createUpdate($scope.files, $scope.program, onSaveFinished);
            }
            function setSocialMediaImage($scope, icon){
                if(icon != null){
                    const file = icon.image;
                    const url = icon.imageUrl;
                    if (file != null) {
                        icon.imageName = file.name;
                        icon.imageSize = file.size;
                        $scope.files.push(file);
                    } else if (file == null && url == null) {
                        icon.imageName = null;
                        icon.imageSize = null;
                        icon.aspectRatio = null;
                    }
                }
            }
            init($scope);
        }

    });

})();