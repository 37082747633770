'use strict';
(function () {
    angular.module('acadiamasterApp')
        .service('BusinessRuleScheduledRuleAuditHelperService', function () {
            return {
                convertDateToUTC: function (date, isEndDate) {

                    var milliseconds = 60 * 1000; // the number of milliseconds in a minute; needed as getTimezoneOffset() works in minutes
                    if (isEndDate) {
                        date = this.setHoursAndMinutesToMidnight(date);
                    }else{
                        date = this.setHoursAndMinutesToZero(date);

                    }
                    return date.getTime() - (date.getTimezoneOffset() * milliseconds);
                },
                setHoursAndMinutesToMidnight: function (date) {
                    var dateObject = new Date(date);
                    dateObject.setHours(23);
                    dateObject.setMinutes(59);
                    dateObject.setSeconds(59);
                    dateObject.setMilliseconds(999);
                    return dateObject;
                },
                setHoursAndMinutesToZero: function (date) {
                    var dateObject = new Date(date);
                    dateObject.setHours(0);
                    dateObject.setMinutes(0);
                    dateObject.setSeconds(0);
                    dateObject.setMilliseconds(0);
                    return dateObject;
                },
                getToday: function () {
                    return this.setHoursAndMinutesToMidnight(new Date());
                },

                getYesterday: function () {
                    var date = new Date();
                    date.setDate(date.getDate() - 1)
                    return this.setHoursAndMinutesToZero(date);
                },

                parseDate: function (date) {

                    if (parseInt(date) > 0) {
                        var dateNew = new Date();
                        dateNew.setTime(date);
                        return dateNew;
                    }

                    return new Date(date);
                }

            }
        })
})();