/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/**
 * Created by Jason Cao on 08/12/2019
 * localized label configuration directive
 * this is just a wrapper around localized text input
 */
(function () {
    angular.module('acadiamasterApp').directive('localizedLabelConfig', (
        LocaleJsonService, LocaleConstantsService) => {
        function init ($scope) {
            LocaleJsonService.jsonToLocalizationMap($scope.localizedLabel,
                $scope.data, 'value', 'localizationMap');

            $scope.ready = true;

            if ($scope.rows === undefined) {
                $scope.rows = 3;
            }
        }

        function addWatches ($scope) {
            /*
             * note: this is quite inefficient with deep watches, should actually rewrite the
             * localized text input with another version to improve performance if it is an issue
             */
            $scope.$watch('data', () => {
                const json = LocaleJsonService.localizationMapToJson($scope.data.value,
                    $scope.data.localizationMap, 'value', true);

                // make the change back to localized label input
                _.forEach($scope.localizedLabel, (v, k) => {
                    $scope.localizedLabel[k] = json[k];
                });

                // console.log('data changed', $scope.data, json, $scope.localizedLabel);
            }, true);

            /*
             * create an object for $scope.data.localizationMap whenever $scope.localizedLabel is changed,
             * to pass in localizedTextInput directive, as the updated value of param localizationMap
             * @param localizedLabel - LocalizedStringModel i.e., {en : 'en value', es : 'es value'}
             */
            function setMapLocalizedTextObject (localizedLabel) {
                let localizationMap = $scope.data.localizationMap ? $scope.data.localizationMap : {};
                Object.keys(localizedLabel).forEach(locale => {
                    const value = localizedLabel[locale];
                    // if en
                    if (locale === 'en') {
                        $scope.data.value = value;
                    } else { // if es
                        // create locale object if needed
                        if (!localizationMap[locale]) {
                            localizationMap[locale] = {};
                        }
                        // set value
                        localizationMap[locale].value = value;
                    }
                });
                // use the spread operator to force $watch to pick up changes on localization map
                $scope.data.localizationMap = { ...localizationMap };
            }

            $scope.$watch('localizedLabel', (newValue, oldValue) => {
                if (newValue == oldValue) {
                    return;
                }
                setMapLocalizedTextObject(newValue);
            });
        }

        return {
            link : function ($scope) {
                $scope.lcs = LocaleConstantsService;

                // convert json to localization map
                $scope.data = {
                    localizationMap : {},
                    value           : null,
                };

                $scope.ready = false;

                init($scope);

                addWatches($scope);
            },
            restrict : 'E',
            scope    : {
                disabled       : '=',
                inputType      : '=',
                localizedLabel : '=',
                maxLength      : '=',
                minLength      : '=',
                name           : '@?',
                rows           : '<?',
            },
            templateUrl : 'admin-templates/util/localization/localizedLabel.html',
        };
    });
}());
