import cs from '../../../const/programContainer.constants.service';

/**
 * directive for configuring the template content value click action
 */
angular.module('acadiamasterApp').directive(
    'templateValueClickActionConfig',
    ($state, LocaleConstantsService, TemplateConstantsCacheService, ContainerItemTemplateConstants) => ({
        link ($scope) {
            $scope.LocaleConstantsService = LocaleConstantsService;
            $scope.data = {
                actionTargets       : [],
                isReady             : false,
                navigationContainer : null,
                selectedFormIdList  : getSelectedLinkedFormIds(),
            };
            $scope.programId = $state?.params?.id;
            function init () {
                $scope.containerDtoList = $scope.parseContainerUIList();
                TemplateConstantsCacheService.loadActionTargets(false).then(actionTargetMap => {
                    const containerType = cs.findTypeByName($scope.containerType);
                    const actionTargets = Object.values(actionTargetMap).sort();
                    // since we return all action target, we need to remove sub page action from the following two scenarios:
                    // 1. all other container type (except Data Reference ROR and subpage container)
                    // 2. container item level even on sub page container item level.
                    const isSubpageActionEnabled = containerType && (containerType.text
                        === cs.subPageContainerType.text || containerType.text
                        === cs.dataReferenceRORContainerType.text);
                    if (!isSubpageActionEnabled || $scope.nodeType !== cs.nodeType.CONTAINER) {
                        $scope.data.actionTargets = actionTargets.filter(target => target !== cs.subPageContainerType.actionTarget);
                    } else {
                        $scope.data.actionTargets = actionTargets;
                    }
                }, error => {
                    console.error('Error occurred in loading action targets', error);
                    $scope.isReady = false;
                });

                $scope.data.isReady = true;

                // watch form
                $scope.$watch('contentValue._form', (newValue, oldValue) => {
                    if (newValue == oldValue) {
                        return;
                    }
                    if (newValue != null) {
                        $scope.formOptions.form = newValue;
                    }
                });
                $scope.$watch('data.navigationContainer', newValue => {
                    if (newValue == null) {
                        $scope.contentValue.containerId = null;
                    }
                });
            }

            $scope.parseContainerUIList = () => {
                const dto = [];
                $scope.data.navigationContainer = null;
                if ($scope.containerList != null) {
                    Object.keys($scope.containerList).forEach(key => {
                        $scope.containerList[key]
                            .filter(container => container && container.id && container.pageId !== cs.defaultTab.id)
                            .sort((a, b) => a.id - b.id)
                            .forEach(container => {
                                if (container.id === $scope.contentValue.containerId) {
                                    $scope.data.navigationContainer = container;
                                }
                                dto.push({
                                    displayName : `${container.id} | ${container.name}`,
                                    id          : container.id,
                                    name        : container.name,
                                    pageId      : container.pageId,
                                });
                            });
                    });
                }
                return dto;
            };
            $scope.showPageSelection = () => {
                return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.PAGE;
            };
            $scope.selectPage = page => {
                $scope.contentValue.pageId = page?.id || null;
            };

            $scope.showFormIdConfig = () => {
                return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.FORM;
            };

            $scope.showExternalURLConfig = () => {
                return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.EXTERNAL_URL;
            };

            $scope.showContainerNavigationConfig = () => {
                return $scope.contentValue && $scope.contentValue.actionTarget === ContainerItemTemplateConstants.actionTarget.CONTAINER_NAVIGATION;
            };

            $scope.selectContainer = () => {
                $scope.contentValue.containerId = $scope.data.navigationContainer
                    ? $scope.data.navigationContainer.id
                    : null;
            };

            $scope.getContainerLabel = container => {
                if (container) {
                    return `${ container.id } - ${ container.name } (${
                        container.pageId === cs.defaultTab.id
                            ? 'DRAFT'
                            : container.pageId
                    })`;
                }
                return '';
            };

            function getSelectedLinkedFormIds () {
                const linkedEntities = $scope.linkedEntities;
                if (!linkedEntities || linkedEntities.length < 1) {
                    return [];
                }
                const tmp = linkedEntities.reduce((acc, entity) => {
                    if (entity && entity.entityType === cs.containerItemLinkedEntityType.FORM && entity.entityId) {
                        acc.push(entity.entityId);
                    }
                    return acc;
                }, []);
                return tmp;
            }

            function isFormAlreadySelected (form, selectedFormIdList) {
                if (!form || !form.id || !selectedFormIdList) {
                    return false;
                }

                const found = selectedFormIdList.find(formId => formId === form.id);
                return found === form.id;
            }

            $scope.formOptions = {
                canSelectFunc : function (form) {
                    // form is any of the search result form
                    return !isFormAlreadySelected(form, $scope.data.selectedFormIdList);
                },
                form             : $scope.showFormIdConfig() ? $scope.contentValue.getForm() : null,
                onChangeCallBack : function () {
                    $scope.contentValue.setForm(this.form);
                },
                title    : 'Select Target Form',
                viewOnly : false,
            };

            init();
        },
        restrict : 'E',
        scope    : {
            containerList  : '<',
            containerType  : '<?',
            contentValue   : '=',
            definition     : '=',
            linkedEntities : '<?',
            nodeType       : '<?',
        },
        templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/templateValue.clickAction.config.html',
    }),
);
