/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for action configuration
     */
        .directive('formElementConfigActionShowHidePage', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfigActionShowHidePage.html',
                scope: {
                    element: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.isUnavailableOnly = vbrCommonUtil.isUnavailableOnly;
                    $scope.updateFieldsBasedOnPageRangeType = function (newPageChangeType) {
                        $scope.element.updateFieldsBasedOnPageRangeType(newPageChangeType);
                    };

                    $scope.gotoLinkedElement = function (pageSectionId) {
                        var linkedElement = $scope.element.findLinkedElement(pageSectionId);
                        if (linkedElement != null) {
                            $scope.selector.selectItem(linkedElement, true, true);
                            $scope.selector.setElementParentKeyValue(linkedElement, "_parent", ['collapsed', 'sectionCollapsed'], false);
                        }
                    };
                }
            };
        });
})();