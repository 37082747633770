/**
 * directive for view entry mode's generator, this will provide UI element for generating the
 * view mode with various options
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.directive('formViewModeGenerator', function (AlertService, ViewModeGeneratorService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/formMode/formMode.viewModeGenerator.html',
            scope: {
                formVersion: '=',
                closeFunction: '='
            },
            link: function ($scope) {
                $scope.config = {
                    includeRegularLabels : true,
                    groupPagesIntoSections : true,
                    numOfPagesPerSection : 10
                };

                $scope.ruleOpen = false;

                $scope.pagesPerSectionChoices = [10, 20, 50, 999];

                $scope.generateViewMode = function() {
                    var startTime = performance.now();
                    var totalPages = $scope.formVersion.editMode.pages.length;
                    ViewModeGeneratorService.generateViewMode($scope.formVersion, $scope.config);

                    var endTime = performance.now();
                    AlertService.success('view mode generated for ' + totalPages + ' pages in ' + (endTime - startTime).toFixed(3) + " ms ");

                    var closeFunction = $scope.closeFunction;
                    // close the modal window
                    if (closeFunction && _.isFunction(closeFunction)) {
                        closeFunction();
                    }
                };

                $scope.toggleRule = function() {
                    $scope.ruleOpen = !$scope.ruleOpen;
                }
            }
        };

    });

})();

