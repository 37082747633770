/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    /**
     * directive for component basic configuration regarding form mode
     */
    angular.module('acadiamasterApp').directive('formElementConfigBasicFormMode', function (FormUtilService,
                                                                                            FormUpdateUtilService,
                                                                                            AlertService,
                                                                                            ngDialog,
                                                                                            FormModeSortUtil,
                                                                                            NavigationNodeExport,
                                                                                            FormConstants) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/formMode/elementConfig.basic.formMode.html',
            scope: {
                element: '=',
                parentForm: '='
            },
            link: function ($scope) {
                $scope.ec = FormUtilService.elementCompare;
                $scope.constants = FormConstants;
                $scope.FormUpdateUtilService = FormUpdateUtilService;
                $scope.actionResult = null;

                $scope.dataTypeOptions = null;

                $scope.templateTypeChanged = function (newTemplateType) {
                    $scope.element.templateTypeChanged(newTemplateType);
                };

                /**
                 * apply the action
                 * @param aa - available action object
                 */
                $scope.applyAction = function (aa) {
                    var result = aa.applyAction($scope.element);
                    if (result.hasError) {
                        AlertService.error(result.message);
                    }
                    else {
                        AlertService.success(aa.name + ' successfully finished');
                        $scope.actionResult = result;
                    }
                };

                /**
                 * sort pages based on navigation edges
                 */
                $scope.sortPages = function() {
                    FormModeSortUtil.sortPages($scope.element);
                };

                $scope.exportNodes = function () {
                    return NavigationNodeExport.exportAllNodes($scope.element);
                };

                /**
                 * auto generate view mode
                 */
                $scope.autoGenerateViewMode = function() {
                    showAutoGenerateWindow($scope.element);
                };

                init($scope);
            }
        };

        function init($scope) {
            createDataTypeOptionsIfNeeded($scope);
        }

        /**
         * show a window to generate view mode automatically
         * @param viewMode - form mode for view entry
         */
        function showAutoGenerateWindow(viewMode) {
            var template = '<form-view-mode-generator form-version="formVersion" close-function="closeFunction"></form-view-mode-generator>';

            var formVersion = viewMode._parent;

            // open modal window and pass in all the scope variables
            var dialog = ngDialog.open({
                showClose: false,
                closeByEscape : false,
                closeByDocument : false,
                template: template,
                plain: true,
                controller : ['$scope', function($scope) {
                    $scope.formVersion = formVersion;
                    $scope.closeFunction = closeDialog;
                }],
                height: '90%',
                className: 'ngdialog-theme-plain custom-width-large'
            });

            function closeDialog() {
                dialog.close();
            }
        }


        function createDataTypeOptionsIfNeeded($scope) {
            $scope.element.initDataTypePropertyUsageList(false, null);

            if ($scope.dataTypeOptions == null) {
                $scope.dataTypeOptions = {
                    dataTypes: $scope.element._dataTypes,
                    title: 'Data Type Associated',
                    viewOnly: !!$scope.element.isProfileForm(),
                    dataTypeChangeCallBack: function () {
                        $scope.element.updateDataTypeInformation();
                    },
                    dataTypeCanDelete: function (dataType) {
                        return dataType.propertyUsage.used.length == 0;
                    }
                };
            }
        }

    });
})();

