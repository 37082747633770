(function () {
    'use strict';

    angular
        .module('acadiamasterApp')

        .directive('formConfigVimeoVideo', function (LocaleConstantsService, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/vimeoVideoConfig.html',
                scope: {
                    fieldValue: '=',
                },
                link: function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                }
            };
        });

})();

