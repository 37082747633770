
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .constant('PROGRAM_TEST_SUITE_CONSTANTS', function () {
            let FIELDS = {
                ID: {
                    id: 'id',
                    sortable: true,
                    isIndex: true
                },
                NAME: {
                    id: 'name',
                    sortable: true
                },
                DESCRIPTION: {
                    id: 'description'
                },
                PROGRAM_NAME: {
                    id: 'programName',
                },
                NUMBER_OF_TEST_CASES: {
                    id: 'testCaseNos',
                },
                AUTO_RUN_CORRECTIVE_ACTION: {
                    id: 'runCorrectiveActions',
                    sortable: true
                },
                HAS_SCHEDULE: {
                    id: 'hasSchedule'
                },
                SCHEDULE_ENABLED: {
                    id: 'scheduleEnabled'
                },
                LAST_RUN_ON: {
                    id: 'lastRunOn',
                    sortable: true,
                    isDate: true
                },
                UPDATED_ON: {
                    id: 'updatedOn',
                    sortable: true,
                    isDate: true
                },
                ACTIONS: {
                    id: 'actions'
                }
            };

            return {
                FIELDS: FIELDS
            }
        }());
})();
