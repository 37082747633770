/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for view radio selection
     */
        .directive('formFieldConfigFieldValueViewSelection', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/viewSelection.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    selector: '=',
                    field: '='
                },
                controller: function ($scope) {
                    $scope.constants = FormConstants;

                    /**
                     * Checks to see if the View Selection field needs to show title reference display style.
                     */
                    $scope.isReferenceDisplayStyleNeeded = function() {
                        var linkedItem = $scope.fieldValue.findLinkedElement();
                        // if this is anything but a boolean selector field, show options
                        return linkedItem != null && linkedItem.type !== FormConstants.fieldsType.BOOLEAN_SELECTOR;
                    };


                    /**
                     * Checks to see if the View Selection field needs to show options.
                     */
                    $scope.isOptionShowFormatNeeded = function() {
                        var linkedItem = $scope.fieldValue.findLinkedElement();
                        // if this is anything but a dropdown field, show options
                        return linkedItem != null && (linkedItem.type !== FormConstants.fieldsType.DROPDOWN ||
                            linkedItem.type !== FormConstants.fieldsType.API_DROPDOWN);
                    };
                }
            };

        });

})();

