/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * server side validation
     */
        .directive('vbrOrgCodeAvailable', function (OrganizationByOrgCode, $q) {
            function isCodeAvailable(code, currentId) {
                var promise = OrganizationByOrgCode.get(code, currentId);
                return promise.then(function (response) {
                    console.log("data received = ", response);
                    if (!response.data) {
                        return $q.reject("Code is already taken");
                    }
                    else return true;
                });
            }

            return {
                restrict: 'AE',
                require: 'ngModel',
                scope: {
                    currentId: '=vbrOrgCodeAvailable'
                },
                link: function (scope, elm, attr, model) {
                    //console.log('created vbrOrgCodeAvailable function, current id = ' + scope.currentId);
                    model.$asyncValidators.vbrOrgCodeAvailable = function (modelValue) {
                        //console.log("validator called for org code : " + modelValue);
                        return isCodeAvailable(modelValue, scope.currentId);
                    };
                }
            }
        });

})();

