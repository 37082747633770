(function () {
    'use strict';
    /**
     * showing the user states for a user
     */
    angular.module('acadiamasterApp').directive('userStatesDisplay', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/userAudit/userStates.html',
            scope: {
                userStates: '=',
            },
            link: function ($scope) {
                // empty for now, add more functions to improve the display later
                $scope.displayValues = displayValues;
            }
        };

        /***************************************************************
         * private functions
         ***************************************************************/

        function displayValues(valueType, values) {

            if (valueType==="DATETIME") {
                return displayDateTime(values);
            } else {
                return JSON.stringify(values);
            }
        }


        function displayDateTime(values) {
            var result = "[";
            result += values.map(function (val, index, arr) {
                if (val != null) {
                    return "\"" + new Date(val).toISOString() + "\"";
                }
                else {
                    return "";
                }
            });
            result += "]";
            return result;
        }

    });

})();



