(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigWithdrawModel',
        function (RuleConstantsService, RuleActionBaseModelService, RuleUtilService) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigWithdrawModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigWithdrawModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.WITHDRAW;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigWithdrawModel;
                this.consentType = RuleConstantsService.consentTypes.PII;
                return this;
            }

            ActionConfigWithdrawModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.consentType = actionConfig.consentType;
                }
            };

            ActionConfigWithdrawModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.consentType = this.consentType;
                return dto;
            };

            ActionConfigWithdrawModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                return ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
            };

            return ActionConfigWithdrawModel;
        }
    );
})();