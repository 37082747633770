/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('treeNodeSqlExpressionFixedNode', function (RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/treeNode/sqlExpressionsFixedNode.html',
                scope: {
                    expressionFixedNode: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.ruleConstants = RuleUtilService.ruleConstants;
                }
            };
        });

})();

