'use strict';

(function () {

    angular.module('acadiamasterApp').directive('formPreCondition', function () {
        return {
            restrict: 'E',
            template: '<vbr-form-search ng-if="options" options="options" is-required="true" program-id="programId"></vbr-form-search>',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.preCondition._parent.programId;
                $scope.options = {
                    form: $scope.preCondition.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack : function() {
                        $scope.preCondition.setForm(this.form);
                    }
                };
            }
        };
    });
})();