/**
 * Module interacting with program test import window, getting the json and pass it
 * @author Eka.Renardi
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('programTestImportConfirmation', function (ngDialog) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/programTestImportConfirmation.html',
                scope: {
                    title: "@",
                    onSuccessCallback: '='
                },
                link: function ($scope) {

                    $scope.file = null;
                    $scope.processing = false;

                    $scope.closePopup = function () {
                        ngDialog.close();
                    };

                    $scope.uploadFile = function () {

                        var read = new FileReader();
                        read.readAsBinaryString($scope.file);
                        read.onloadend = function(){
                            var data = JSON.parse(read.result);
                            onSuccess(data);
                        };

                    };

                    function onSuccess(data) {
                        $scope.closePopup();
                        $scope.onSuccessCallback(data);
                    }

                }
            };
        });
})();
