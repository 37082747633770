'use strict';

(function () {

    angular.module('acadiamasterApp').directive('propertyValueComparisonConfig', function (
        $translate
    ) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/' +
                'propertyValueComparison.html',
            scope: {
                condition: '='
            },
            link: function ($scope) {

                $scope.propertyOptions = {
                    getProperty : function() {
                        return $scope.condition.getProperty();
                    },
                    setProperty : function(property) {
                        $scope.condition.setProperty(property);
                    },
                    getOperator : function() {
                        return $scope.condition.getComparator();
                    },
                    setOperator : function(comparator) {
                        $scope.condition.setComparator(comparator);
                    },
                    getPropertyValues : function() {
                        return $scope.condition.getPropertyValuesAsProperType();
                    },
                    setPropertyValues : function(newValues) {
                        $scope.condition.setPropertyValues(newValues);
                    }
                };
                $scope.timeText= $translate.instant('acadiamasterApp.ifCondition.joinCondition.propertyValueTime.text');


            }
        };

    });
})();
