(function () {
    'use strict';

    /**
     * script action to remove page title and icon, used for forms like family medical history
     */
    angular.module('acadiamasterApp').factory('RemovePageTitleAndIconScriptAction', function (ScriptActionBaseModel, FormConstants) {

        // defining some local variable to make things easier
        var constants = FormConstants;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        RemovePageTitleAndIconScriptAction.inheritsFrom(ScriptActionBaseModel);

        function RemovePageTitleAndIconScriptAction() {
            this.name = 'Remove Page Title (number/number) and First Image, Change display config for Title and Paragraph after it';
            this.steps = [
                {
                    ticketId: 'AC-24180',
                    description: 'find page with number title followed by an icon --> ' + 'remove them' + '<br>' +
                    '<i>this does not work if there are spacer elements on the page in the first field, we are assuming the first and 2nd field will be ' +
                    'under the first component of the first section on the page</i>',
                    searchFunction: findPageWithNumberTitleFollowedByIcon,
                    changeFunction: removeFirstTwoFieldInPage
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /**
         * find first component in page, assuming first component will be under the first section
         * @param page
         * @returns {*}
         */
        function findFirstComponentInPage(page) {
            if (page == null || page.sections == null || page.sections.length == 0) {
                return null;
            }

            var section = page.sections[0];
            if (section == null || section.formComponents == null || section.formComponents.length == 0) {
                return null;
            }

            return section.formComponents[0];
        }
        /**
         * finding page with number title followed by an icon
         */
        function findPageWithNumberTitleFollowedByIcon(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

            // getting all the pages
            var targets = formMode.pages;

            targets = _.filter(targets, function (page) {
                var component = findFirstComponentInPage(page);

                if (component == null || component.formComponentFields == null || component.formComponentFields.length < 2) {
                    return false;
                }

                var firstField = component.formComponentFields[0],
                    secondField = component.formComponentFields[1];

                if (firstField == null || secondField == null) {
                    // should never happen, put here just in case
                    return false;
                }

                if (firstField.type != constants.fieldsType.TEXT_LABEL) {
                    // first field is not text label
                    return false;
                }

                if (secondField.type != constants.fieldsType.IMAGE_DISPLAY) {
                    // 2nd first is not image
                    return false;
                }

                // valid the number pattern in text label to make sure it's a page title
                var firstFieldText = firstField.fieldValue.value;
                if (firstFieldText == null) {
                    return false;
                }

                firstFieldText = firstFieldText.trim();

                var matches = firstFieldText.match(/^[\d]+\/[\d]+$/i);

                return (matches != null && matches.length > 0);
            });

            return targets;
        }

        /**
         * removing the first 2 fields in the page
         * we are assuming those two fields will be in the same component
         * @param page - page model
         */
        function removeFirstTwoFieldInPage(page) {
            var component = findFirstComponentInPage(page);

            if (component == null || component.formComponentFields == null || component.formComponentFields.length < 2) {
                return false;
            }

            component.formComponentFields.splice(0, 2);
        }

        /***************************************
         * service return call
         ***************************************/

        return RemovePageTitleAndIconScriptAction;
    });

})();
