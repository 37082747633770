'use strict';

(function () {

    angular.module('acadiamasterApp').directive('personalizedContainerStateConfig', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/personalizedContainerState.html',
            scope: {
                condition: '<'
            },
            link: function ($scope) {
                $scope.containerOptions = {
                    getContainer: () => $scope.condition.getContainer(),
                    setContainer: (container) => $scope.condition.setContainer(container),
                    programId: $scope.condition.getRoot().programId,
                };
           }
        };
    });
})();