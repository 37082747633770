(function () {
    angular
        .module('acadiamasterApp')

        .controller(
            'FormController',
            (
                $scope,
                Form,
                ngDialog,
                FormUtilService,
                $stateParams,
                $state,
                CONFIG,
                ConfigurationServer,
                vbrCommonUtil,
                FormPreviewService,
                OperationStatus,
                VbrToolsService,
                drcSyncUtilService,
                $rootScope,
                FormVersionTwoUtilityService,
            ) => {
                $scope.CONFIG = CONFIG;
                // Here loadAll is an empty object which will send to "formListWithSearch" directive.
                // In "formListWithSearch" directive we bind a loadAll() callback with loadAll method.
                // So we can use directive's loadAll function here.
                $scope.loadAll = {};
                $scope.selectedForm = null;
                $scope.defaultFormCategory = $stateParams.defaultFormCategory;
                $scope.headerRenderedItems = 0; // components are rendered in the header box.

                $scope.increaseHeaderItems = function () {
                    $scope.headerRenderedItems = $scope.headerRenderedItems + 1;
                };

                $scope.programSelection = function () {
                    ngDialog
                        .openConfirm({
                            controller : 'ProgramSelectionController',
                            template :
                                'admin-templates/site/forms/form/programSelection.html',
                        })
                        .then(() => {
                            $scope.refresh();
                        });
                };
                $scope.latestDeletedVersion = null;
                $scope.refresh = function () {
                    $scope.loadAll();
                    $scope.selectedForm = null;
                };
                $scope.addDeletedVersionDetails = function (form) {
                    if (form.isLatestVersionDeleted) {
                        $scope.latestDeletedVersion = {};
                        $scope.latestDeletedVersion.versionId = form.latestFormVersion.id;
                    }else{
                        $scope.latestDeletedVersion = null;
                    }
                };

                $scope.selectForm = function (form) {
                    if (form === null) {
                        $scope.selectedForm = null;
                        $scope.latestDeletedVersion = null;
                        return;
                    }
                    let formDetail = Form.get({
                        id         : form.id,
                        projection : Form.projections.OVERVIEW,
                    });

                    formDetail.$promise.then(data => {
                        let formObject
                            = new FormUtilService.FormModelServiceForm.FormModel();
                        formObject.fromDto(data);
                        $scope.selectedForm = formObject;
                        $scope.addDeletedVersionDetails(form);
                    });
                };

                $scope.preview = function (form) {
                    FormPreviewService.preview(form, null);
                };

                $scope.download = function (form) {
                    FormUtilService.download(form, null);
                };

                $scope.showCreateFormModal = function (category) {
                    ngDialog
                        .openConfirm({
                            className  : 'ngdialog-theme-center custom-width-medium',
                            controller : 'CreateFormModalController',
                            data       : {},
                            template   : 'admin-templates/site/forms/form/createFormModal.html',
                        })
                        .then(data => {
                            switch (category) {
                            case 'Data':
                                $state.go('formVersionTwo.new', data);
                                break;
                            case 'Consent':
                                $state.go('formVersionTwo.newConsent', data);
                                break;
                            case 'Snap':
                                $state.go('formVersionTwo.newSnapQuestion', data);
                                break;
                            }
                        });
                };

                $scope.importForm = function () {
                    let _scope = $scope;

                    ngDialog
                        .openConfirm({
                            className :
                                'ngdialog-theme-plain custom-width-medium',
                            controller : [
                                '$scope',
                                function ($scope) {
                                    $scope.onSuccess = function () {
                                        _scope.refresh();
                                    };
                                },
                            ],
                            plain : true,
                            size  : 'md',
                            template :
                                '<upload-to-configuration-server title="Upload Form" ws-url="/api/configuration/forms/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
                        })
                        .then(() => {
                            _scope.refresh();
                        });
                };

                $scope.exportDeletedForms = function () {
                    ConfigurationServer.getDeletedForms().then(data => {
                        let fileName = 'deletedForms.json';
                        vbrCommonUtil.downloadAsFile(fileName, data);
                    });
                };
                $scope.showImportFormWindow = function (type) {
                    initDataImport(type);

                    let newClassDialog = ngDialog.open({
                        className       : 'ngdialog-theme-center custom-width-medium',
                        closeByDocument : false,
                        closeByEscape   : false,
                        plain           : true,
                        scope           : $scope,
                        template        : `<form-import-confirmation category="${$scope.category}"></form-import-confirmation>`,
                    });

                    newClassDialog.closePromise.then(formModel => {
                        // get forms after import
                        $rootScope.$emit('getForms', 'Get All Forms');
                    });
                };

                $scope.toggleDropdown = function ($event, element) {
                    FormVersionTwoUtilityService.toggleDropdown(
                        $event,
                        element,
                    );
                };

                function initDataImport (type) {
                    switch (type) {
                    case 'Data':
                        $scope.category = 'DATA_FORM';
                        break;
                    case 'Consent':
                        $scope.category = 'CONSENT_FORM';
                        break;
                    case 'Snap_Json':
                        $scope.category = 'SNAP_QUESTION_FORM';
                        break;
                    }
                }

                $scope.importDeletedForms = function () {
                    let _scope = $scope;

                    ngDialog
                        .openConfirm({
                            className :
                                'ngdialog-theme-plain custom-width-medium',
                            controller : [
                                '$scope',
                                function ($scope) {
                                    $scope.onSuccess = function () {
                                        _scope.refresh();
                                    };
                                },
                            ],
                            plain : true,
                            size  : 'md',
                            template :
                                '<upload-to-configuration-server title="Upload file containing deleted form ids" ws-url="/api/configuration/forms/importDeleted" on-success-callback="onSuccess"></upload-to-configuration-server>',
                        })
                        .then(() => {
                            _scope.refresh();
                        });
                };

                $scope.openImportSnapQuestionsConfirm = function (program) {
                    const operationType = 'SNAP_QUESTION_IMPORT_OPERATION';

                    const data = {
                        isReady         : false,
                        operationId     : null,
                        operationStatus : new OperationStatus(),
                    };

                    // get operation id if there is an operation processing
                    VbrToolsService.getOperationIdByOperationType(
                        operationType,
                    ).then(
                        result => {
                            data.operationId = result.data.operationId;
                            if (!result.data.operationId) {
                                ngDialog.openConfirm({
                                    className :
                                        'ngdialog-theme-center custom-width-medium',
                                    controller :
                                        'ImportSnapQuestionsConfirmController',
                                    data             : program,
                                    preCloseCallback : function () {
                                        // reload the program list on popup close
                                        $scope.loadAll();
                                    },
                                    scope : $scope,
                                    template :
                                        'admin-templates/site/forms/form/snapQuestionImport/form.snap.question.import.html',
                                });
                            } else {
                                // check status every 5 seconds
                                $scope.checkStatusInterval = 5000;

                                // if operationId, then present the status data
                                drcSyncUtilService.openModelWindow(
                                    $scope,
                                    data,
                                    'Import Snap Questions',
                                    () => {
                                        // reload the program list on popup close
                                        $scope.loadAll();
                                    },
                                    true,
                                );
                                drcSyncUtilService.checkAndShowStatus(
                                    $scope,
                                    data,
                                );
                            }
                        },
                        error => {
                            console.error(error);
                        },
                    );
                };
            },
        );
}());
