'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('externalDataImport', {
                parent: 'entity',
                url: '/imports',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.externalSurveyDataImport.title',
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/externalDataImport/externalDataImport.html',
                        controller: 'ExternalDataImportController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('externalImports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('externalDataImport.new', {
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.externalSurveyDataImport.title',
                },
                parent: 'externalDataImport',
                url: '/new',
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/externalDataImport/externalDataImportCreateTask.html',
                        controller: 'externalDataImportCreateTaskController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', function() {
                        return {

                        };
                    }],
                    isNew: [ function() {
                        return true;
                    }]
                }
            })
            .state('externalDataImport.track', {
                parent: 'externalDataImport',
                url: '/track/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'acadiamasterApp.externalSurveyDataImport.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/externalDataImport/trackTask/externalTaskTrack.html',
                        controller: 'externalDataTaskTrackController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {

                        return $translate.refresh();
                    }],
                    taskId: ['$stateParams', function() {
                        return {

                        };
                    }],

                }
            });

    });
