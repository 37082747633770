'use strict';

angular.module('acadiamasterApp').controller('DataTypePropertyMappingDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DataTypePropertyMapping', 'Property', 'DataType', 'DataTypeCategory', 'DataTypeRootCategory', 'PropertyPossibleValue',
        function($scope, $stateParams, $uibModalInstance, entity, DataTypePropertyMapping, Property, DataType, DataTypeCategory, DataTypeRootCategory, PropertyPossibleValue) {

        $scope.dataTypePropertyMapping = entity;
        $scope.propertys = Property.query();
        $scope.datatypes = DataType.query();
        $scope.datatypecategorys = DataTypeCategory.query();
        $scope.datatyperootcategorys = DataTypeRootCategory.query();
        $scope.propertypossiblevalues = PropertyPossibleValue.query();
        $scope.load = function(id) {
            DataTypePropertyMapping.get({id : id}, function(result) {
                $scope.dataTypePropertyMapping = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:dataTypePropertyMappingUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.dataTypePropertyMapping.id != null) {
                DataTypePropertyMapping.update($scope.dataTypePropertyMapping, onSaveFinished);
            } else {
                DataTypePropertyMapping.save($scope.dataTypePropertyMapping, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
