(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('isFeatureToggleStateEqualPreCondition', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/' +
            'preConditionDirective/isFeatureToggleStateEqualPreCondition.html',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {
                /**
                 * program options used for program search directive
                 * @type {{getProgram: getProgram, setProgram: setProgram, title: string}}
                 */
                $scope.programOptions = {
                    getProgram: function () {
                        return $scope.preCondition.getProgram();
                    },
                    setProgram: function (program) {
                        $scope.preCondition.setProgram(program);
                    }
                };
            }
        };
    });
})();