/**
 * Created by Jamie Nola on 10/11/2018
 *
 * Stores data for the user's entire session.
 * Optionally, a stateDomain parameter can be passed into setItem, which represents a parent state
 * in the routes files. If an item has a stateDomain and the browser leaves the corresponding
 * parent state, the stored data is deleted.
 *
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('SessionStorageService', function() {
        // all stored data is saved under this key:
        var STORAGE_KEY = 'vibrentAdminSessionStorage';

        // this vault stores a local version of all the stored data
        var vault;

        /**
         * Loads the vault from storage (if it exists) or creates a new empty object
         */
        function reloadVault() {
            var data = window.sessionStorage.getItem(STORAGE_KEY)

            if(data) {
                vault = JSON.parse(data);
            } else {
                vault = {
                    domains: {},
                    storage: {}
                };
            }
        }

        /**
         * Clears all associated sessionStorage data, including both storage and domains.
         * This occurs during both logout and login.
         */
        function clear() {
            window.sessionStorage.removeItem(STORAGE_KEY);
            reloadVault();
        }

        /**
         * Retrieves a stored item matching the key
         * @param {String} key
         */
        function getItem(key) {
            return vault.storage[key] || null;
        }

        /**
         * Saves a copy of the vault in the window's session storage
         */
        function saveStorage() {
            window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(vault));
        }

        /**
         * Stores an item in sessionStorage and keeps track of it
         * @param {String} key
         * @param {*} value
         * @param {String} stateDomain (optional) If the browser leaves this parent state, delete this data
         */
        function setItem(key, value, stateDomain) {
            vault.storage[key] = value;

            if(stateDomain) {
                var domain = vault.domains[stateDomain] || [];
                if(domain.indexOf(key) === -1) {
                    domain.push(key);
                }
                vault.domains[stateDomain] = domain;
                saveStorage();
            }
        }

        /**
         * Fires when state changes. Gets a path of state parents and then checks to see if any of
         * our stored domains are missing from the path. If they are, delete them.
         * @param {Array} path
         */
        function onStateChangeSuccess(path) {
            // if a stored domain is not in the new path, delete its associated storage,
            // and then delete the domain itself
            Object.keys(vault.domains).forEach(function(key) {
                if(path.indexOf(key) === -1) {
                    vault.domains[key].forEach(function(storageKey) {
                        delete vault.storage[storageKey];
                    });
                    delete vault.domains[key];
                }
            });
            saveStorage();
        }

        /**
         * Fires when page loads. Grabs stored vault from sessionStorage or creates an empty one
         */
        function init() {
            reloadVault();
        }

        init();

        return {
            clear: clear,
            getItem: getItem,
            onStateChangeSuccess : onStateChangeSuccess,
            setItem: setItem
        };
    });
})();
