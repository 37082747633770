(function () {
    'use strict';

    /**
     * Change padding of second title sub field of view text or number input fields if they are visible
     * Padding to be set to top 0 left 30 right 30 bottom 30
     */
    angular.module('acadiamasterApp').factory('UpdateViewTextNumberInputAction', function (ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        // defining some local variable to make things easier
        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateViewTextNumberInputAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateViewTextNumberInputAction() {
            this.name = 'Update padding if the second sub field is visible for view text or number input field';
            this.steps = [
                {
                    ticketId: 'AC-24578',
                    description: 'find second title sub field of view text or number input which are visible --> '
                    + ' Update them to use padding of top 0, left 30, right 30 and bottom 0',
                    searchFunction: findTitleSubFieldsOfViewTextNumberInputWhichAreVisible,
                    changeFunction: updatePaddingForTitleSubField
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /***
         * Field all subfields
         * whose parent field is view text or number input
         * second in the sub field list
         * are visible
         * do not have top 0 left 30 right 30 bottom 30 padding
         */
        function findTitleSubFieldsOfViewTextNumberInputWhichAreVisible(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

           // Find all fields of type view text or number input
            var viewTextNumberInputFields = fsa.findFieldsByType(formMode, constants.fieldsType.VIEW_TEXT_OR_NUMBER_INPUT);

            var targets = [];

            // Collect title sub fields (second only as per requirement)
            _.forEach(viewTextNumberInputFields, function (field) {
                if (field.subFields != null) {
                    targets.push(field.subFields[2]);
                }
            });

            // Filter out invisible fields
            targets = _.filter(targets, function (target) {
                return fsa.isVisible(target.displayConfig);
            });

            // Filter out fields which already have the correct padding configured
            targets = _.filter(targets, function (target) {
                return !fsa.isPaddingTopEqual(target.displayConfig, 0) ||
                    !fsa.isPaddingBottomEqual(target.displayConfig, 30) ||
                    !fsa.isPaddingRightEqual(target.displayConfig, 30) ||
                    !fsa.isPaddingLeftEqual(target.displayConfig, 30);
            });


            return targets;
        }

        /**
         * Set padding to top 0 left 30 right 30 bottom 30
         */
        function updatePaddingForTitleSubField(subField) {
            if (subField && subField.displayConfig) {
                // Update the padding to desired value
                fsa.updatePadding(subField.displayConfig, 0, 30, 30, 30);
            }
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateViewTextNumberInputAction;
    });

})();
