(function () {
    'use strict';

    /***************************************************************
     * Precondition for EHR configuration
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionEHRConfigModel',
        function (ProgramTestConstantsService, PreConditionBaseModel) {

            PreConditionEHRConfigModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for ehr configuration
             * @constructor
             */
            function PreConditionEHRConfigModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.EHR_CONFIG_EXIST);
            }

            PreConditionEHRConfigModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);
            };

            PreConditionEHRConfigModel.prototype.toDto = function () {
                return PreConditionBaseModel.prototype.toDto.call(this);
            };

            PreConditionEHRConfigModel.prototype.getDescriptionAsHtml = function () {
                return PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);
            };

            return PreConditionEHRConfigModel;
        });
})();
