/**
 *
 * controller for listing container dependency links on the program level
 */

angular.module('acadiamasterApp')
    .controller('ContainerDpLinkController', function ($translate, ProgramService, ContainerDpLinkConstants, SessionStorageService,
        ContainerDpLinkModel, SEARCH_FILTER_CONSTANTS, $state) {
        const vm = this;

        vm.cs = ContainerDpLinkConstants;
        vm.fields = [
            vm.cs.FIELDS.SOURCE_ID,
            vm.cs.FIELDS.SOURCE_TYPE,
            vm.cs.FIELDS.DEPENDENCY_TYPE,
            vm.cs.FIELDS.DEPENDENCY_TYPE_ID,
            vm.cs.FIELDS.DEPENDENCY_TYPE_STRING_ID,
        ];

        vm.filteredFields = [
            SEARCH_FILTER_CONSTANTS.FIELDS.CONTAINER_DEPENDENCY_LINKING_DEPENDENCY_TYPE,
            SEARCH_FILTER_CONSTANTS.FIELDS.DEPENDENCY_LINK_SOURCE_ID,
        ];

        vm.data = {
            filteredLinks  : [],
            filteredValues : {},
            isLoading      : true,
            links          : [],
            loadingError   : false,
            numFilters     : 0,
        };

        vm.searchData = {
            ascending : false,
            orderBy   : vm.cs.FIELDS.SOURCE_TYPE.id,
        };

        vm.storageDomain = $state.current.name;

        vm.setOrderBy = fieldId => {
            if (vm.searchData.orderBy === fieldId) {
                vm.searchData.ascending = !vm.searchData.ascending;
            } else {
                vm.searchData.orderBy = fieldId;
                vm.searchData.ascending = true;
            }
        };

        vm.filterDpLink = link => {
            let result = true;
            Object.keys(vm.data.filteredValues).forEach(key => {
                if (!result) {
                    return;
                }
                const value = vm.data.filteredValues[key];
                const type = typeof value;
                if (type === 'boolean' || type === 'number') {
                    result = link[key] === value;
                    return;
                }
                if (type === 'string') {
                    const values = value.split(',');
                    result = values.some(val => link[key] === val);
                    return;
                }
            });
            return result;
        };

        vm.onFiltersUpdated = () => {
            vm.data.numFilters = Object.keys(vm.data.filteredValues).length;
        };

        /**
         * Loads dependency links from the server. load at a program level
         * @param {String} programId The current program's ID
         */
        function loadDpLinks (programId) {
            vm.data.isLoading = true;
            ProgramService.getAllDependencyLinks(programId)
                .then(response => {
                    vm.data.links = response.data.map(dto => new ContainerDpLinkModel().fromDto(dto));
                }, error => {
                    vm.data.loadingError = true;
                    console.error('Error getting container dependency links from API', error);
                }).finally(() => {
                    vm.data.isLoading = false;
                });
        }

        vm.$onInit = () => {
            // load data from session storage service
            let data = SessionStorageService.getItem($state.current.name);
            if (data) {
                vm.searchData = data.searchData;
            }

            // if a program exists, load data based on the programId.
            if (vm.entity) {
                vm.entity.$promise
                    .then(response => {
                        vm.entity = response;
                        loadDpLinks(vm.entity.id);
                    }, error => {
                        console.error('Error loading program entity.', error);
                        vm.data.loadingError = true;
                    });
            } else {
                console.error('Error loading container dependency links.');
                vm.data.loadingError = true;
            }
        };
    });
