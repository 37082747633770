'use strict';

angular.module('acadiamasterApp').controller('DataTypePropertyValueConversionDialogController',
    ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'DataTypePropertyValueConversion', 'Unit', 'DataType', 'Property',
        function($scope, $stateParams, $uibModalInstance, entity, DataTypePropertyValueConversion, Unit, DataType, Property) {

        $scope.dataTypePropertyValueConversion = entity;
        $scope.units = Unit.query();
        $scope.datatypes = DataType.query();
        $scope.propertys = Property.query();
        $scope.load = function(id) {
            DataTypePropertyValueConversion.get({id : id}, function(result) {
                $scope.dataTypePropertyValueConversion = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:dataTypePropertyValueConversionUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.dataTypePropertyValueConversion.id != null) {
                DataTypePropertyValueConversion.update($scope.dataTypePropertyValueConversion, onSaveFinished);
            } else {
                DataTypePropertyValueConversion.save($scope.dataTypePropertyValueConversion, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}]);
