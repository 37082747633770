
angular.module('acadiamasterApp')
    .controller('ProgramCategoryController', ($scope, $uibModal, ProgramCategory, ParseLinks, ProgramCategorySearch, CONFIG, vbrCommonUtil, ngDialog, AddAssociation, DeleteAssociation, GetPrograms, GetAllPrograms, AlertService) => {
        $scope.CONFIG = CONFIG;

        let modalInstance = null;

        $scope.VIEW_MODE = {
            GALLERY : 'G',
            LIST    : 'L',
        };
        $scope.formData = {
            filterApplied   : null,
            programCategory : null,
            programs        : null,
            viewMode        : $scope.VIEW_MODE.LIST,
        };

        $scope.DateRangeOption = {
            LAST7DAYS   : 'LAST7DAYS',
            LAST24HOURS : 'LAST24HOURS',
            LAST30DAYS  : 'LAST30DAYS',
        };

        $scope.searchFilter = {
            created              : null,
            createdByMe          : false,
            programAssociatedMax : 0,
            programAssociatedMin : 0,
            searchTerm           : null,
            updated              : null,
            updatedByMe          : false,
        };

        $scope.obj2 = [];

        $scope.programCategorys = [];
        $scope.page = 0;
        $scope.loadAll = function () {
            ProgramCategory.query({ page : $scope.page, size : 20 }, (result, headers) => {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.searchResultCount = headers('X-Total-Count');
                $scope.programCategorys = result;
                $scope.obj2 = [];
                let programCategorysCopy = JSON.parse(JSON.stringify($scope.programCategorys));
                while (programCategorysCopy.length) {
                    $scope.obj2.push(programCategorysCopy.splice(0, 2));
                }
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            ProgramCategory.get({ id : id }, result => {
                $scope.programCategory = result;
                $('#deleteProgramCategoryConfirmation').modal('show');
            });
        };

        $scope.confirmDelete = function (id) {
            ProgramCategory.delete({ id : id },
                () => {
                    $scope.loadAll();
                    $('#deleteProgramCategoryConfirmation').modal('hide');
                    $scope.formData.programCategory = null;
                    $scope.clear();
                });
        };

        $scope.deleteProgram = function (pId, pName) {
            $('#deleteProgramConfirmation').modal('show');
            $scope.programId = pId;
            $scope.programName = pName;
        };

        $scope.confirmDeleteProgram = function (pcId) {
            const data = {
                programCategoryId : pcId,
                programIds        : [ $scope.programId ],
            };

            const promise = DeleteAssociation.query(data);
            return promise.then(() => {
                $('#deleteProgramConfirmation').modal('hide');
                $scope.getprogramsByCategory(pcId);
            });
        };

        $scope.addProgramFunction = function (pcId) {
            $scope.pId = null;
            $scope.allPrograms = [];
            const promise = GetAllPrograms.query();
            promise.then(response => {
                for (let i = 0; i < response.length; i++) {
                    if (response[i].programStatus.isActive && response[i].programStatus.configurationComplete && !response[i].isDeleted) {
                        $scope.allPrograms.push(response[i]);
                    }
                }
            });

            $scope.getProgramId = programId => {
                $scope.pId = programId;
                $scope.filteredPrograms.forEach(program => {
                    program.selected = false;
                    if (program.id === programId) {
                        program.selected = true;
                    }
                });
            };

            $scope.addProgramsConfirmation = () => {
                const data = {
                    programCategoryId : pcId,
                    programIds        : [ $scope.pId ],

                };
                const promise = AddAssociation.query(data);
                ngDialog.close();
                return promise.then(result => {
                    $scope.programs = result;
                    $scope.getprogramsByCategory(pcId);
                });
            };
        };

        $scope.filterBySearchString = searchString => {
            if (searchString) {
                if (typeof searchString === 'string') {
                    searchString = searchString.toLowerCase();
                }
                $scope.filteredPrograms = [];
                $scope.allPrograms.forEach(program => {
                    if (program.name.toLowerCase().includes(searchString) || program.id.toString().includes(searchString)) {
                        $scope.filteredPrograms.push(program);
                    }
                });
            }
        };

        $scope.addProgram = function (pcId) {
            $scope.addProgramPopup = ngDialog.openConfirm({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : ('addProgramController', [ '$scope', function ($scope) {
                    $scope.addProgramFunction(pcId);
                } ]),
                scope    : $scope,
                template : 'addProgramsConfirmation',
            });
        };

        $scope.refresh = function () {
            refresh();
        };
        $scope.download = function (pcId) {
            let programCategoryDetails = ProgramCategory.get({
                id : pcId,
            });
            programCategoryDetails.$promise.then(data => {
                // create file name
                let fileName = `program_category_${ pcId
                }.json`;
                // download as file in browser
                vbrCommonUtil.downloadAsFile(fileName, data);
            });
        };

        $scope.importProgramCategory = function () {
            ngDialog.openConfirm({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : [ '$scope', function ($scope) {
                    $scope.onSuccess = function () {
                        refresh();
                    };
                } ],
                plain    : true,
                size     : 'md',
                template : '<upload-to-configuration-server title="Import Program Category" ws-url="/api/configuration/programCategory/import" on-success-callback="onSuccess"></upload-to-configuration-server>',
            }).then(() => {
                $scope.refresh();
            });
        };

        $scope.clear = function () {
            $scope.programCategory = { createdOn : null, fileUrl : null, id : null, isDeleted : null, name : null, updatedOn : null };
        };

        $scope.clearFilter = function () {
            clearFilter();
        };

        $scope.doSearch = function () {
            doSearch();
        };

        $scope.showProgramCategoryFilterModal = function () {
            modalInstance = $uibModal.open({
                animation  : true,
                controller : function ($scope) {
                    $scope.closeFilterModal = function () {
                        modalInstance.dismiss('cancel');
                    };

                    $scope.resetFilter = function () {
                        clearFilter();
                    };

                    $scope.applyFilter = function () {
                        let filterString = '';
                        if ($scope.searchFilter.created != null) {
                            filterString = `${filterString }Created=${ $scope.searchFilter.created }, `;
                        }
                        if ($scope.searchFilter.updated != null) {
                            filterString = `${filterString }Updated=${ $scope.searchFilter.updated }, `;
                        }
                        if ($scope.searchFilter.createdByMe) {
                            filterString = `${filterString }Created By Me, `;
                        }
                        if ($scope.searchFilter.updatedByMe) {
                            filterString = `${filterString }Updated By Me`;
                        }

                        $scope.formData.filterApplied = filterString;
                        modalInstance.close('OK');
                        doSearch();
                    };
                },
                resolve : {
                    item : function () {
                        return null;
                    },
                },
                scope       : $scope,
                size        : 'md',
                templateUrl : 'programCategoryFilter.html',
                windowClass : 'filter-modal-window',
            });
        };

        $scope.getprogramsByCategory = function (programCategoryId) {
            const promise = GetPrograms.query(programCategoryId);
            promise.then(result => {
                $scope.programs = result;
                $scope.formData.programs = $scope.programs;
            });
        };

        $scope.selectProgramCategory = function (programCategory) {
            $scope.formData.programCategory = programCategory;
            $scope.getprogramsByCategory(programCategory.id);
        };

        function refresh () {
            $scope.loadPage(0);
            $scope.clear();
        }
        function clearFilter () {
            $scope.formData.filterApplied = null;
            $scope.searchFilter = {
                created              : null,
                createdByMe          : false,
                programAssociatedMax : 0,
                programAssociatedMin : 0,
                searchTerm           : null,
                updated              : null,
                updatedByMe          : false,
            };
            $scope.loadAll();
        }

        function doSearch () {
            let promise = ProgramCategorySearch.query($scope.searchFilter);
            return promise.then(result => {
                $scope.programCategorys = result;
                AlertService.success('Program category filter is successfully applied.');
                $scope.obj2 = [];
                let programCategorysCopy = JSON.parse(JSON.stringify($scope.programCategorys));
                while (programCategorysCopy.length) {
                    $scope.obj2.push(programCategorysCopy.splice(0, 2));
                }
            });
        }
    });
