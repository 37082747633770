/**
 * directive for evaluating a functional condition against a number of users identified by
 * their ids
 */
import template from './funcEval.html';
(function () {
    angular.module('acadiamasterApp').directive('funcEval', (ExpressionConstantsService, $state, FuncEvalService, AlertService) => {
        function convertDataToDto (data, vxpEventPayloadJson) {
            return {
                expression     : data.expression,
                expressionNode : data.expressionTree == null ? null : data.expressionTree.toDto(),
                programId      : data.program == null ? null : data.program.id,
                userIds        : data.userIds.split(/[ ,\n]+/).map(e => {
                    return e.trim();
                }),
                vxpEventPayload : vxpEventPayloadJson,
            };
        }

        return {
            link : function ($scope) {
                $scope.expConstants = ExpressionConstantsService;

                $scope.data = {
                    expression      : null,
                    expressionTree  : null,
                    program         : null,
                    showVxpEvent    : false,
                    userIds         : null,
                    vxpEventPayload : null,
                };

                $scope.programOptions = {
                    getProgram : function () {
                        return $scope.data.program;
                    },
                    setProgram : function (data) {
                        $scope.data.program = data;
                    },
                };

                $scope.resultData = {
                    processing : false,
                    result     : null,
                };

                $scope.goCancel = () => {
                    $state.go('ruleDashboard');
                };

                $scope.goEdit = () => {
                    $scope.resultData.result = null;
                    $scope.resultData.processing = false;
                };

                $scope.isEmptyString = input => {
                    return input == null || input.trim().length == 0;
                };

                $scope.goEvaluate = () => {
                    if ($scope.data.program == null) {
                        AlertService.error('Program selection is required');
                        return;
                    }
                    let vxpEventPayloadJson = null;
                    if ($scope.data.vxpEventPayload && $scope.data.showVxpEvent) {
                        try {
                            vxpEventPayloadJson = JSON.parse($scope.data.vxpEventPayload);
                        } catch (error) {
                            AlertService.error('vxp event payload is not valid JSON string. <br>Please enter valid value.');
                            return;
                        }
                    }
                    let dto = convertDataToDto($scope.data, vxpEventPayloadJson);
                    if (dto.userIds && dto.userIds.length > 10) {
                        AlertService.error(`Please enter up to 10 user ids. <br> current user count: ${dto.userIds.length}`);
                        return;
                    }

                    // reset result data
                    $scope.resultData.result = null;
                    $scope.processing = true;

                    FuncEvalService.eval(dto).$promise.then(data => {
                        $scope.resultData.result = data;
                    }).finally(() => {
                        $scope.resultData.processing = false;
                    });
                };

                $scope.inEdit = () => {
                    return $scope.resultData.result == null;
                };

                $scope.canEvaluate = () => {
                    if (!$scope.inEdit()) {
                        return false;
                    }

                    // check for at least some ids have been entered
                    const idStrings = $scope.data.userIds;
                    if (idStrings == null || idStrings.trim().length == 0) {
                        return false;
                    }

                    // checking for program
                    if ($scope.data.program == null) {
                        return false;
                    }

                    // all check has passed
                    return true;
                };
            },
            restrict : 'E',
            scope    : {
                // add new properties later when we integrate with existing conditions
            },
            template : template,
        };
    });
}());
