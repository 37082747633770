(function () {
    "use strict";

    /**
     * Form Version Two Utility Service
     */
    angular
        .module("acadiamasterApp")
        .factory("FormVersionTwoUtilityService", function () {
            return {
                /**
                 * Filter & Import Dropdown
                 * @param {*} event
                 * @param {*} elementId
                 */
                toggleDropdown: function (event, elementId) {
                    var categoryFilter = document.querySelector(elementId);
                    if (event.type === "mouseover") {
                        categoryFilter.style.display = "block";
                    } else if (event.type === "mouseout") {
                        categoryFilter.style.display = "none";
                    } else {
                        // click
                        if (categoryFilter.style.display === "none" || categoryFilter.style.display === "") {
                            categoryFilter.style.display = "block";
                        } else {
                            categoryFilter.style.display = "none";
                        }
                    }
                },
            };
        });
})();
