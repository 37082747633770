/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form section
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultSectionService',
        function (CompareResultComponentService, CompareResultDisplayConfigService,
                  CompareResultUtilService) {


            function compareSectionDetail(section1, section2) {
                var result = {
                    localId: CompareResultUtilService.comparePrimitives(section1.localId, section2.localId),
                    name: CompareResultUtilService.comparePrimitives(section1.name, section2.name),
                    displayOrder: CompareResultUtilService.comparePrimitives(section1.displayOrder, section2.displayOrder),
                    sectionType: CompareResultUtilService.compareNamedObjects(section1.sectionType, section2.sectionType)
                };

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            /**
             * main comparison function to compare two sections
             * @param section1
             * @param section2
             * @returns {{detail: ({localId, name, displayOrder, sectionType}|*), displayConfig: {CompareResult}, components: *}}
             */
            function compare(section1, section2) {
                var title;
                if (section1 == null) {
                    section1 = {};
                    title = "Section " + section2.localId;
                }
                else {
                    title = "Section " + section1.localId;
                }

                if (section2 == null) {
                    section2 = {};
                }

                var components1 = section1.formComponents;
                var components2 = section2.formComponents;

                var result = {
                    _title : title,
                    _isOpen : true,
                    detail: compareSectionDetail(section1, section2),
                    displayConfig : CompareResultDisplayConfigService.compare(section1.displayConfig, section1.displayConfig),
                    components: CompareResultUtilService.compareArrays(components1, components2, title, CompareResultComponentService.compare)
                };

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

