/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')


    /**
     * directive for form field value for button type
     */
        .directive('formConfigFieldValueButtonType', function (FormConstants) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/submitButton.buttonType.html',
                scope       : {
                    inputModel  : '=',
                    changeModel : '=',
                },
                link : function ($scope) {
                    $scope.hasChangeModel = $scope.changeModel !== undefined;
                    $scope.constants = FormConstants;
                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        inputModel   : $scope.inputModel,
                        changeModel  : $scope.changeModel,
                        makingChange : $scope.hasChangeModel && $scope.changeModel != null,
                    };

                    $scope.listToAvoid = [
                        FormConstants.submitButton.buttonType.BUTTON_DEFAULT,
                        FormConstants.submitButton.buttonType.BUTTON_INFO,
                        FormConstants.submitButton.buttonType.BUTTON_SUCCESS,
                        FormConstants.submitButton.buttonType.BUTTON_WARNING,
                        FormConstants.submitButton.buttonType.LINK,
                    ];

                    $scope.$watch('data.inputModel', function (newValue) {
                        $scope.inputModel = newValue;
                    });

                    $scope.$watch('data.changeModel', function (newValue) {
                        $scope.changeModel = newValue;
                    });

                    $scope.resetChange = function () {
                        $scope.data.makingChange = false;
                        $scope.data.changeModel = null;
                    };

                    $scope.startChange = function () {
                        $scope.data.makingChange = true;
                    };

                    $scope.getButtonType = function () {
                        return $scope.changeModel == null ? $scope.inputModel : $scope.changeModel;
                    };
                },
            };

            /***************************************************************
             * private functions
             ***************************************************************/
        });

})();

