'use strict';

angular.module('acadiamasterApp')
    .factory('Password', ['$resource', 'localWeb', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/account/change_password'), {}, {
        });
    }]);

angular.module('acadiamasterApp')
    .factory('PasswordResetInit', ['$resource', 'localWeb', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/account/reset_password/init'), {}, {
            save: {method: 'GET'}
        })
    }]);

angular.module('acadiamasterApp')
    .factory('PasswordResetT2', function ($http, localWeb) {
        return {
            save: function (userId, params) {
                return $http.get(localWeb.createUrl('api/account/reset_password/' + userId + '/init'),
                { params: params });
            }
        };
    });

angular.module('acadiamasterApp')
    .factory('PasswordResetFinish', ['$resource', 'localWeb', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/account/reset_password/finish'), {}, {
        })
    }]);
