/**
 * Created by kshama.jirage on 2/28/2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('PreferencesService', function($http, localWeb) {
            var url = localWeb.createUrl('api/account/preferences');
            return {
                update: function(data) {
                    return $http.post(url, data);
                },
                get: function () {
                    return $http.get(url);
                }
            };
        });
})();