(function () {
    angular.module('acadiamasterApp').service(
        'CompareResultDataSharingPageService',
        function (CompareResultUtilService) {

            function compareDataSharingVerificationProperties(page1, page2) {
                if(page1.formPageMetadata && page2.formPageMetadata) {
                    var formPageMetadata1 = page1.formPageMetadata.dataSharingMetadata;
                    var formPageMetadata2 = page2.formPageMetadata.dataSharingMetadata;
                    var formPageMetadataEs1 = page1.formPageMetadata.dataSharingMetadata.localizationMap &&
                        page1.formPageMetadata.dataSharingMetadata.localizationMap.es;
                    var formPageMetadataEs2 = page2.formPageMetadata.dataSharingMetadata.localizationMap &&
                        page2.formPageMetadata.dataSharingMetadata.localizationMap.es;
                    return {
                        initialText: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.initialText,
                            formPageMetadata2 && formPageMetadata2.initialText
                        ),
                        textAfterConnectFails: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.textAfterConnectFails,
                            formPageMetadata2 && formPageMetadata2.textAfterConnectFails
                        ),
                        initialConnectButtonLabel: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.initialConnectButtonLabel,
                            formPageMetadata2 && formPageMetadata2.initialConnectButtonLabel
                        ),
                        buttonLabelAfterConnectFails: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.buttonLabelAfterConnectFails,
                            formPageMetadata2 && formPageMetadata2.buttonLabelAfterConnectFails
                        ),
                        initialTextEs: CompareResultUtilService.comparePrimitives(
                            formPageMetadataEs1 && formPageMetadataEs1.initDisplay,
                            formPageMetadataEs2 && formPageMetadataEs2.initDisplay
                        ),
                        textAfterConnectFailsEs: CompareResultUtilService.comparePrimitives(
                            formPageMetadataEs1 && formPageMetadataEs1.failureDisplay,
                            formPageMetadataEs2 && formPageMetadataEs2.failureDisplay
                        ),
                        initialConnectButtonLabelEs: CompareResultUtilService.comparePrimitives(
                            formPageMetadataEs1 && formPageMetadataEs1.initSyncBtn,
                            formPageMetadataEs2 && formPageMetadataEs2.initSyncBtn
                        ),
                        buttonLabelAfterConnectFailsEs: CompareResultUtilService.comparePrimitives(
                            formPageMetadataEs1 && formPageMetadataEs1.reSyncBtn,
                            formPageMetadataEs2 && formPageMetadataEs2.reSyncBtn
                        ),
                        connectedService: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.connectedService,
                            formPageMetadata2 && formPageMetadata2.connectedService
                        ),
                        parameterConfigurations: CompareResultUtilService.comparePrimitives(
                            formPageMetadata1 && formPageMetadata1.parameterConfigurations,
                            formPageMetadata2 && formPageMetadata2.parameterConfigurations
                        ),
                    };
                }
            }
            return {
                compareDataSharingVerificationProperties : compareDataSharingVerificationProperties,
            };
        }
    );
})();

