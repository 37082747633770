/**
 *  for Individual Task configuration
 */

(function () {
    angular.module('acadiamasterApp').directive('programTaskConfig',
        () => {
            return {
                controller: 'taskConfigController',
                controllerAs: 'td',
                restrict: 'E',
                scope: {
                    task: '=?',
                    isNew: '=?',
                    stateAfterCancelSave: '@',
                },
                templateUrl: 'admin-templates/site/unauthenticatedTask/config/programTaskConfig.template.html',
            };
        });
}());
