/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * admin dashboard directive
     */
        .directive('vbrAdminDashboard', function (AuditsService, SystemInfoService,
            Principal, $timeout, TILE_CONSTANTS) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/adminDashboard.html',
                controller: function($scope) {
                    $scope.data = {
                        userName : null,
                        lastLogin : null,
                        systemInfo: null,
                        adminVersion : process.env.VERSION,
                        sharedVersion: process.env.SHAREDVERSION,
                        shownLogin : false,
                        tileCollectionId: TILE_CONSTANTS.ID.DASHBOARD
                    };

                    $scope.showLoginInfo = function() {
                        $timeout(function (){
                            $scope.data.shownLogin = true;
                        }, 5000);
                    };

                    loadAllData($scope);
                }
            };

            function loadAllData($scope) {
                loadUser($scope);
                loadSystemInfo($scope);
            }

            function loadSystemInfo($scope) {
                SystemInfoService.getSystemInfo().then(function(response) {
                    response.data.buildName = decodeURIComponent(response.data.buildName);
                    $scope.data.systemInfo = response.data;
                });
            }

            function loadUser($scope) {
                Principal.identity()
                    .then(function(account) {
                        if(!account) {
                            return null;
                        }
                        $scope.data.userName = account.login;
                        return account.id;
                    });
            }
        });

})();

