/**
 * Created by Jamie Nola on 03/04/2019
 */
'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('orderManagement', {
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER', 'ROLE_T2_SUPPORT'],
                    pageTitle: 'orderManagement.title'
                },
                parent: 'entity',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('orderManagement');
                        return $translate.refresh();
                    }]
                },
                url: '/orders',
                views: {
                    'content@': {
                        controller: 'OrderManagementController',
                        controllerAs: 'omc',
                        templateUrl: 'admin-templates/site/orderManagement/orderManagement.html',
                    }
                },
            });
    });
