'use strict';

angular.module('acadiamasterApp')
    .controller('MetricsController', function ($scope, MonitoringService, $uibModal) {
        $scope.metrics = {};
        $scope.updatingMetrics = true;

        $scope.timeOrderBy = 'key';
        $scope.timeOrderAscending = false;

        $scope.sortServiceStats = function(keyToSortBy) {
            sortServiceStats($scope, keyToSortBy);
        };

        $scope.refresh = function () {
            $scope.updatingMetrics = true;
            MonitoringService.getMetrics().then(function (promise) {
                $scope.metrics = promise;
                $scope.updatingMetrics = false;
            }, function (promise) {
                $scope.metrics = promise.data;
                $scope.updatingMetrics = false;
            });
        };

        $scope.$watch('metrics', function (newValue) {
            $scope.servicesStats = [];
            $scope.cachesStats = {};
            angular.forEach(newValue.timers, function (value, key) {
                if (key.indexOf('vibrent') !== -1) {
                    value.key=key;
                    $scope.servicesStats.push(value);
                }

                if (key.indexOf('net.sf.ehcache.Cache') !== -1) {
                    // remove gets or puts
                    var index = key.lastIndexOf('.');
                    var newKey = key.substr(0, index);

                    // Keep the name of the domain
                    index = newKey.lastIndexOf('.');
                    $scope.cachesStats[newKey] = {
                        'name': newKey.substr(index + 1),
                        'value': value
                    };
                }
            });

            sortServiceStats($scope);
        });

        $scope.refresh();

        $scope.refreshThreadDumpData = function() {
            MonitoringService.threadDump().then(function(data) {

                var modalInstance = $uibModal.open({
                    templateUrl: 'admin-templates/admin/metrics/metrics.modal.html',
                    controller: 'MetricsModalController',
                    size: 'lg',
                    resolve: {
                        threadDump: function() {
                            return data;
                        }

                    }
                });
            });
        };

        /**
         * sort the service stats based on
         * @param $scope
         */
        function sortServiceStats($scope, keyToSortBy) {
            // process sort by key
            if (keyToSortBy!=null) {
                if (keyToSortBy == $scope.timeOrderBy) {
                    // same key flip the ascending order
                    $scope.timeOrderAscending = !$scope.timeOrderAscending;
                }
                else {
                    $scope.timeOrderBy = keyToSortBy;
                }
            }



            // sort the array using order by and ascending
            $scope.servicesStats.sort(function(s1, s2) {
                var v1 = s1[$scope.timeOrderBy];
                var v2 = s2[$scope.timeOrderBy];
                if ($scope.timeOrderAscending) {
                    return v1>v2 ? 1 : (v1<v2 ? -1 : 0);
                }
                else {
                    return v1>v2 ? -1 : (v1<v2 ? 1 : 0);
                }
            });
        }


    });
