angular.module('acadiamasterApp')
    .directive('expressionConditionBasicInfo', () => ({
        link: ($scope) => {
            $scope.hasConditionTree = () => {
                return $scope.condition.conditionTree != null;
            };
        },
        restrict: 'E',
        scope: {
            condition: '=',
            conditionForm: '=',
        },
        templateUrl: 'admin-templates/site/expressionCondition/configuration/expressionConditionBasicInfo.html',
    }));
