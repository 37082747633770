/**
 * Created by jason.cao on 2/03/2020
 * directive for displaying a header row for history messages
 **/
angular.module('acadiamasterApp').directive('operationHistoryMessageHeader', function () {
    return {
        restrict: 'E',
        templateUrl: 'admin-templates/site/tools/operationHistory/messages/operationHistoryMessageHeader.html',
        scope: {
            messageData : '=',
            links: '=',
            loadPageFunction : '=',
        }
    };
});
