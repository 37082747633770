/**
 * service for dealing with the api of operation history
 */
angular.module('acadiamasterApp').factory('OperationHistoryService', (
    $http, vbrCommonUtil, localWeb, $httpParamSerializer) => {
    const baseUrl = '/api/admin/operationHistory';

    /* **************************************************************
     * service return functions
     ************************************************************** */

    /**
     * listing the operation history based on pageable object
     * @param {Object} pageable - pageable object with page number, size, sort, direction
     * @returns {HttpPromise} - return a http promise for response from server api, it's data should
     *          contain a list of the operation history objects
     */
    function listAll (pageable) {
        const queryParamString = $httpParamSerializer(pageable);

        return $http.get(localWeb.createUrl(`${baseUrl}/?${queryParamString}`));
    }

    /**
     * find the operation by operation id
     * @param {long} operationId - operation id
     * @returns {HttpPromise} - return a http promise for response from server api, it's data should contain
     *      a single operation history object
     */
    function findById (operationId) {
        return $http.get(localWeb.createUrl(`${baseUrl}/${operationId}`));
    }

    /**
     * stop an operation by id with reason
     * @param {long} operationId - operation id, required
     * @param {string} reason - reason for stopping
     * @returns {HttpPromise} - return a http promise for response from server api, it's data should contain
     *      a single operation history object
     */
    function stopOperationById (operationId, reason) {
        const data = {
            operationId   : operationId,
            reasonForStop : reason,
        };

        const config = {
            headers : vbrCommonUtil.getCommonPostHeader(),
        };

        return $http.post(localWeb.createUrl(`${baseUrl}/stop`),
            JSON.stringify(data), config,
        );
    }

    /**
     * restart an operation by id
     * @param {long} operationId - operation id, required
     * @returns {HttpPromise} - return a http promise for response from server api, it's data should contain
     *                          a single operation history object
     */
    function restartOperationById (operationId) {
        const config = {
            headers : vbrCommonUtil.getCommonPostHeader(),
        };

        return $http.post(localWeb.createUrl(`${baseUrl}/${operationId}/restart`),
            '{}', config,
        );
    }

    /** *************************************
     * service return call
     ***************************************/

    return {
        findById,
        listAll,
        stopOperationById,
        restartOperationById,
    };
});
