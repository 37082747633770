(function () {
    'use strict';

    /**
     * utility service for value comparison
     */
    angular.module('acadiamasterApp').factory('ValueComparisonService', function () {
        return {
            convertToArrayIfNeeded : convertToArrayIfNeeded,
            noErrorInField : noErrorInField,
            shouldShowErrorMessageInField: shouldShowErrorMessageInField
        };

        /**
         * check if form field has any error
         * @param formField - form field variable created by angular validation automatically, has an $error property
         * @returns {boolean} - true if there is no error in the field, false otherwise
         */
        function noErrorInField(formField) {
            return formField==null || !formField.$invalid;
        }

        /**
         * check if we should be showing error message for any field
         * @param formField - form field variable created by angular validation automatically, has an $error property
         * @param currentValue - current value of the field
         * @returns {boolean} - true if field has error and (it has been touched or its value is not empty), false otherwise
         */
        function shouldShowErrorMessageInField(formField, currentValue) {
            return !noErrorInField(formField) && (currentValue != null || formField.$dirty);
        }

        /**
         * convert an potential object to a array where each key's value is a value in the new array
         * @param inputObjectOrArray - input object or an array, if it's an array, nothing will be returned
         * @param currentValues - current selected values
         * @return [] - return an array or null, this array is a deep copy of the original one because
         *              the multi-selector directive will modify the object with the selected flags
         */
        function convertToArrayIfNeeded(inputObjectOrArray, currentValues) {
            if (inputObjectOrArray==null) {
                return null;
            }

            if (_.isArray(inputObjectOrArray)) {
                return _.cloneDeep(inputObjectOrArray);
            }

            if (typeof inputObjectOrArray !== 'object') {
                // not an object, and not an array, log an warning message and return itself
                console.warn('unable to convert to array, typeof = ' + (typeof inputObjectOrArray), inputObjectOrArray);
                return _.cloneDeep(inputObjectOrArray);
            }

            var newArray = [];

            _.forEach(inputObjectOrArray, function(value) {
                newArray.push(cloneOrUsingExistingValue(value, currentValues));
            });

            return newArray;
        }

        function cloneOrUsingExistingValue(value, currentValues) {

            var valueFound = _.find(currentValues, function(cv) {
                return cv.name == value.name;
            });

            if (valueFound == null) {
                return _.cloneDeep(value);
            }

            // set selected to true if it's not set
            valueFound.selected = true;

            return valueFound;
        }
    });
})();
