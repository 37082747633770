(function () {
    'use strict';

    /**
     * script action to update return of result answers
     */
    angular.module('acadiamasterApp').factory('UpdateReturnOfResultActionScript', function (ScriptActionBaseModel,
                                                                                            FormScriptActionUtil,
                                                                                            FormConstants) {

        // defining some local variable to make things easier

        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateReturnOfResultActionScript.inheritsFrom(ScriptActionBaseModel);

        function UpdateReturnOfResultActionScript() {
            this.name = 'Update Return of Result Answer Fields with design settings';
            this.ticketId = 'AC-23886';

            this.steps = [
                createStep1(),
                createStep2(this)
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }


        /**
         * function for creating the first step of the action
         * @returns {{description: string, searchFunction: searchFunction, changeFunction: changeFunction}}
         */
        function createStep1() {

            /**
             * check if the label reference already matches what we want to do for this action
             * @param labelReferenceSubField - sub field for label reference
             * @returns {boolean} - true if it matches, false otherwise
             */
            function labelReferenceMatch(labelReferenceSubField) {
                if (labelReferenceSubField==null || labelReferenceSubField.displayConfig == null || labelReferenceSubField.fieldValue == null) {
                    return false; // null object is not a match
                }

                var dc = labelReferenceSubField.displayConfig;

                // check padding (t, b, l, r) = (0, 0, 0, 0)
                if (!fsa.isPaddingTopEqual(dc, 0) || !fsa.isPaddingBottomEqual(dc, 0)
                    || !fsa.isPaddingLeftEqual(dc, 0) || !fsa.isPaddingRightEqual(dc, 0)) {
                    return false;
                }

                var fv = labelReferenceSubField.fieldValue;
                // check if the style is BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE

                if (!fsa.isReferenceFieldDisplayStyleEqual(fv, constants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE)) {
                    return false;
                }

                // everything matches, return true

                return true;
            }

            function applyLabelReferenceChange(labelReferenceSubField) {
                if (labelReferenceSubField==null || labelReferenceSubField.displayConfig == null || labelReferenceSubField.fieldValue == null) {
                    return false; // null object can't be updated
                }

                var dc = labelReferenceSubField.displayConfig;

                // update padding to 0,0,0,0
                fsa.updatePadding(dc, 0, 0, 0, 0);

                var fv = labelReferenceSubField.fieldValue;
                // set style to BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE
                fv.referenceFieldDisplayStyle = constants.referenceFieldDisplayStyle.BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE;
            }

            /**
             * check if the 2nd title already matches what we want to do for this action
             * @param secondTitle - sub field for 2nd title
             * @returns {boolean} - true if it matches, false otherwise
             */
            function secondTitleMatch(secondTitle) {
                if (secondTitle==null || secondTitle.displayConfig == null) {
                    return false; // null object is not a match
                }

                var dc = secondTitle.displayConfig;

                // check font color = black80
                if (!fsa.isColorEqual(dc, fsa.colors.colorBlack80)) {
                    console.log('color does not match: ' + dc.color);
                    return false;
                }

                // check gravity alignment = middle, left
                if (!fsa.isGravityV(dc, constants.gravity.MIDDLE) || !fsa.isGravityH(dc, constants.gravity.LEFT)) {
                    console.log('gravity does not match: ', dc.gravity);
                    return false;
                }

                // check font name = null
                if (!fsa.isFontNameEqual(dc, null)) {
                    console.log('font name does not match: ', dc.font);
                    return false;
                }

                // check font size = 12
                if (!fsa.isFontSizeEqual(dc, 12)) {
                    console.log('font size does not match: ', dc.font);
                    return false;
                }

                // check font type = null/normal
                if (!(fsa.isFontStyleNormal(dc) || fsa.isFontStyleInherit(dc))) {
                    console.log('font style does not match: ', dc.font);
                    return false;
                }

                // check padding (t, b, l, r) = (20, 20, 30, 30)
                if (!fsa.isPaddingTopEqual(dc, 20) || !fsa.isPaddingBottomEqual(dc, 20)
                || !fsa.isPaddingLeftEqual(dc, 30) || !fsa.isPaddingRightEqual(dc, 30)) {
                    return false;
                }

                // everything matches, return true
                return true;
            }

            function applySecondTitleChange(secondTitle) {
                if (secondTitle==null || secondTitle.displayConfig == null) {
                    return false; // null object can't be updated
                }

                var dc = secondTitle.displayConfig;

                // set font color = black80
                dc.color = null;  // black80 is the default, null is the same as black80 here

                // set gravity alignment = middle, left
                dc.gravity.horizontal = constants.gravity.LEFT;
                dc.gravity.vertical = constants.gravity.MIDDLE;

                // set font name = null
                fsa.updateFontName(dc, null);

                // set font size = 12
                fsa.updateFontSize(dc, 12);

                // check font type = null/normal
                fsa.updateFontStyle(dc, true, false, false, false);

                // check padding (t, b, l, r) = (20, 20, 30, 30)
                fsa.updatePadding(dc, 20, 20, 30, 30);
            }

            var description = 'find all "View Text Or Number Input" type field, and apply the following change --> <br>' +
            "<pre class='code'>Second Title sub-field display settings\n" +
            "  * Font color display settings is updated to color = black80 (#333333)\n" +
            "  * Gravity alignment is middle-left\n" +
            "  * Font type is inherit (Noto Sans by default)\n" +
            "  * Font size is 12\n" +
            "  * Font style is inherit (normal not bold by default)\n" +
            "  * Left and right padding = 30 dp\n" +
            "  * Top and bottom padding = 20 dp\n" +
            "At the Label Reference level:\n" +
            "  * Apply BOLD_LEFT_ALIGNED_TITLE_IN_DARK_SLATE_BLUE style\n" +
            "  * set margin and padding to 0\n" +
            '</pre>';

            var searchFunction = function(formMode) {
                // find all the view text/number input field
                var targets = fsa.findFieldsByType(formMode, constants.fieldsType.VIEW_TEXT_OR_NUMBER_INPUT);

                // filter out targets that has already performed the action
                targets = _.filter(targets, function(field) {
                    var labelReferenceSubField = field.subFields[0];
                    var secondTitle = field.subFields[2];
                    return !labelReferenceMatch(labelReferenceSubField) || !secondTitleMatch(secondTitle);
                });

                return targets;
            };

            var changeFunction = function(field) {
                var labelReferenceSubField = field.subFields[0];
                var secondTitle = field.subFields[2];

                applyLabelReferenceChange(labelReferenceSubField);
                applySecondTitleChange(secondTitle);
            };

            return {
                description: description,
                searchFunction : searchFunction,
                changeFunction: changeFunction
            };
        }

        /**
         * step 2, find reference and move the id around
         */
        function createStep2(currentActionScript) {
            var searchFunction = function(formMode) {
                // find all the view text/number input field
                var targets = fsa.findFieldsByType(formMode, constants.fieldsType.VIEW_TEXT_OR_NUMBER_INPUT);

                // filter out targets that has already performed the action
                targets = _.filter(targets, function(field) {
                    var labelReferenceSubField = field.subFields[0];
                    var firstTitle = field.subFields[1];
                    var firstTitleValue = firstTitle.fieldValue.value;

                    // we are interested in only label reference is hidden and 1st title is visible
                    return !fsa.isVisible(labelReferenceSubField.displayConfig)
                        && fsa.isVisible(firstTitle.displayConfig) && firstTitleValue!=null && firstTitleValue.trim().length>0;
                });

                return targets;
            };

            /**
             * check if 2 strings are equal after they are trimmed
             */
            function stringEqual(s1, s2) {
                if (s1!=null) {
                    s1 = s1.trim();
                }
                if (s2!=null) {
                    s2 = s2.trim();
                }

                if (s1 != null && s1.length==0) {
                    s1 = null;
                }

                if (s2 != null && s2.length==0) {
                    s2 = null;
                }

                return s1==s2;
            }

            var changeFunctionInternal = function(field, formViewMode) {
                var labelReferenceSubField = field.subFields[0];
                var firstTitle = field.subFields[1];
                var firstTitleValue = firstTitle.fieldValue.value;

                var formEditMode = formViewMode._parent.editMode;

                var targets = fsa.findFieldsByType(formEditMode, constants.fieldsType.TEXT_LABEL);
                var target = _.find(targets, function(field) {
                    return stringEqual(firstTitleValue, field.fieldValue.value);
                });

                var isField = true;
                if (target == null) {
                    // try to find it via subfield
                    targets = fsa.findSubFieldsByType(formEditMode, constants.subFieldsType.TITLE);
                    target = _.find(targets, function(sf) {
                        return stringEqual(firstTitleValue, sf.fieldValue.value);
                    });

                    isField = false;
                }

                if (target!=null) {
                    // found a target, now apply changes

                    // change visibility
                    labelReferenceSubField.displayConfig.visible = true;
                    firstTitle.displayConfig.visible = false;

                    // clear value of title
                    firstTitle.fieldValue.value = null;
                    firstTitle.fieldValue.localizationMap = {};

                    // set reference
                    labelReferenceSubField.fieldValue.targetType = isField ? constants.formTargetType.FORM_FIELD
                        : constants.formTargetType.FORM_SUB_FIELD;
                    labelReferenceSubField.fieldValue.localId = target.localId;
                }

            };

            var description = 'find all "View Text Or Number Input" type field with a hidden label reference and a non-hidden 1st title, and apply the following change --> <br>' +
                "<pre class='code'>" +
                " * search the string defined in the first Title Sub field to find the matching field or sub field for Label Reference and link to the found field in the label reference\n" +
                " * make the label reference visible\n" +
                " * clear the value of first title sub field and make it invisible\n" +
                '</pre>';

            return {
                description: description,
                searchFunction : searchFunction,
                changeFunction: function(field) {
                    return changeFunctionInternal(field, currentActionScript.formMode);
                }
            };

        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateReturnOfResultActionScript;
    });

})();
