/**
 * directive for displaying a list of text that supports multiple locale
 * note: at the moment, we only have one fixed template for the display, in the future, we might need to
 * support multiple templates with a template type passed into the directive
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('displayContentByLocale', function (LocaleConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/displayContentByLocale.html',
            scope: {
                /**
                 * content by locale, this is an simple map object of key and value, where the key is the locale and
                 * value would be simple String
                 * ie: {"en":"text for English", "es": "text for Spanish", "cn": "text for simplified Chinese"}
                 */
                contents: '<',
                supportMultiLine: '<',
                fieldDisplay: '@',
                hasLocalizationMap: '@',
            },
            link: function ($scope) {

                /**
                 * check if content is empty
                 * @returns {boolean}
                 */
                $scope.hasContent = () => {
                    const myObject = $scope.contents;
                    for (const key in myObject) {
                        if (myObject.hasOwnProperty(key)) {
                            return true;
                        }
                    }

                    return false;
                };

                init($scope);
            }
        };
        /** ****************************************************************************
         * private functions
         ***************************************************************************** */

        /**
         * add a list of available locales
         * @param $scope
         */
        function init($scope) {
            $scope.availableLocales = [];

            for (let prop in LocaleConstantsService.locale) {
                let object = LocaleConstantsService.locale[prop];
                $scope.availableLocales.push(object);
            }
        }
    });
})();
