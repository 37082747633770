/**
 * rich text directive, used by form GUI right now, but could easily be used in
 * other places in the future
 */
(function () {
    'use strict';

    /**
     * directive for rich text editor format
     */
    angular.module('acadiamasterApp').directive('richTextEditor', function (FormFontService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/richTextEditor.html',
            scope: {
                data: '=',
                name: '@?',
                parentForm: '<?',
                requiredEditor: '<?',
                editFormDirty: '=?',
            },
            link: function ($scope) {

                $scope.model = {
                    data: $scope.data
                };

                $scope.ckEditorOptions = {
                    font_names: FormFontService.getFontFamilyForCkEditor(),
                    fontSize_sizes: FormFontService.getFontSizesForCkEditor(),
                    font_defaultLabel: FormFontService.getDefaultFont(),
                    colorButton_colors: FormFontService.getFontColors(),
                    fillEmptyBlocks: false,
                    autoParagraph: false,
                };

                $scope.isReady = true;
                $scope.editFormDirty = false;

                $scope.$watch('model.data', function (newValue) {
                    $scope.data = newValue;
                    if (newValue) {
                        $scope.editFormDirty = true;
                    }
                });
                $scope.$watch('data', function (newValue) {
                    $scope.model.data = newValue;
                });


                /**
                 * Listener to link dialog to set the default target to blank and remove other option
                 */
                CKEDITOR.on('dialogDefinition', function (ev) {
                    var dialogName = ev.data.name;
                    var dialogDefinition = ev.data.definition;
                    if (dialogName == 'link') {
                        var informationTab = dialogDefinition.getContents('target');
                        var targetField = informationTab.get('linkTargetType');
                        targetField['default'] = '_self';
                        targetField.items = _.filter(targetField.items, function(item) {
                            return item[1] == '_blank' || item[1] == '_self';
                        });
                    }
                });
            }
        };
    });

})();

