
/**
 * created by Jason Cao at 12/05/2019
 * container item type specific config directive, this is just a top level directive that
 * will invoke other type specific configuration.
 */
angular.module('acadiamasterApp').directive('containerItemTypeSpecificConfigForm', (
    LocaleConstantsService, FormConstants
) => ({
    link($scope) {
        $scope.lcs = LocaleConstantsService;
        $scope.buttonTypes = FormConstants.submitButton.buttonType;
        $scope.disabledButtons = [
            FormConstants.submitButton.buttonType.BUTTON_DANGER.name,
            FormConstants.submitButton.buttonType.BUTTON_DEFAULT.name,
            FormConstants.submitButton.buttonType.BUTTON_SUCCESS.name,
            FormConstants.submitButton.buttonType.BUTTON_WARNING.name,
            FormConstants.submitButton.buttonType.LINK.name,
        ];
    },
    restrict: 'E',
    scope: {
        formConfig: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement'
            + '/config/containerItem/typeSpecific/containerItemTypeSpecificConfigForm.html',
}));
