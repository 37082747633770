/**
 * directive for building navigation through templates
 * this will be used to solve some of the common yet tedious navigation patterns
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('navBuilder', function (NavBuilderConfigModelService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/navigation/navBuilder/navBuilder.html',
                scope: {
                    navData: '=',
                    closeFunction : '='
                },
                link: function ($scope) {
                    // todo: this directive needs a lot of refactoring, it should be very simple with almost all the
                    // template specific logic moved into template services, once we have more than one template,
                    // move the template specific functions out into its own services
                    $scope.constants = FormConstants;
                    var statusList = NavBuilderConfigModelService.status;

                    $scope.filterForMultiChoiceField = $scope.navData.templateConfig.getFilterFunction();

                    $scope.hasAnswersToList = function() {
                        return hasAnswersToList($scope);
                    };

                    $scope.isPageSelectedValid = function() {
                        return $scope.navData.templateConfig.isValid();
                    };

                    $scope.buildBranches = function() {
                        $scope.navData.processConfig();
                    };

                    $scope.shouldShowConfig = function() {
                        return $scope.navData.getStatus() == statusList.NOT_STARTED;
                    };

                    /**
                     * check if we should show the back button or not
                     * @returns {boolean} - return true if the process has stopped (including error states)
                     */
                    $scope.shouldShowBackButton = function() {
                        var s = $scope.navData.getStatus();
                        return s == statusList.FINISHED ||
                            s == statusList.FAILED;
                    };

                    $scope.shouldHighlight = function(text) {
                        return text!=null && text.startsWith('**');
                    };

                    /**
                     * switch the page back to config page by clearing the messages and setting status to NOT_STARTED
                     */
                    $scope.goBackToConfig = function() {
                        $scope.navData.resetMessages();
                        $scope.navData.setStatus(statusList.NOT_STARTED);
                    };

                    // initialize everything
                    init($scope);
                }
            };


            /* --------------------------------------
                private functions
             * -------------------------------------- */

            function init($scope) {
                createWatchForMultiSelectChange($scope);
            }

            function createWatchForMultiSelectChange($scope) {
                // todo: move this into model object later
                $scope.$watch('navData.templateConfig.multiSelectLocalId', function(newValue, oldValue) {
                    if (newValue != oldValue) {
                        $scope.navData.templateConfig.resetAnswersList();
                    }
                });
            }

            function hasAnswersToList($scope) {
                var answersWithTarget = $scope.navData.templateConfig.answersWithTarget;
                return answersWithTarget!=null && answersWithTarget.length > 0;
            }

        });


})();
