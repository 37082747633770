import { AgreementEvents } from '../../../agreements/model/agreementEvent.constants';

(function () {
    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerAgreementEvent', () => {
            let agreementEventTriggerController = function () {
                let vm = this;

                vm.$onInit = () => {
                    vm.data = {
                        agreementEvents : AgreementEvents,
                    };

                    vm.element.setProgram(vm.element.getRoot().program);

                    vm.programOptions = {
                        getProgram : function () {
                            return vm.element.getProgram();
                        },
                        setProgram : function (program) {
                            vm.element.setProgram(program);
                            vm.agreementOptions.programId = program != null ? program.id : null;
                        },
                    };

                    vm.agreementOptions = {
                        agreement     : vm.element._agreement,
                        allowMultiple : false,
                        canSelectFunc : function () {
                            return true;
                        },
                        onChangeCallBack : function () {
                            vm.element.setAgreement(this.agreement);
                        },
                        programId : vm.element.getRoot().programId,
                        title     : 'Choose agreement',
                        viewOnly  : false,
                    };
                };
            };

            return {
                bindToController : true,
                controller       : agreementEventTriggerController,
                controllerAs     : 'atc',
                restrict         : 'E',
                scope            : {
                    element : '=',
                },
                templateUrl : 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.agreementEvent.html',
            };
        });
}());
