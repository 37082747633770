/**
 * Created by Jamie Nola on 03/19/18
 *
 * Instructions:
 *
 * To add new tiles, add a unique TILE_ID here with the following properties:
 *     - icon (String): The CSS class for displaying the tile's icon
 *     - routeId (String): The $state name for the destination on click
 *     - countId (String): The id of the count attribute that is returned from the count API. (Optional)
 *     - hasPopover (Boolean): If a popover exists, shows popover on click of heading. Look at tiles.popover in global.json. (Optional)
 *     - requiresFeatures (Array): Array of features required. All must be turned on or tile will not show
 *     - requiresDrc (Boolean): If DRC is required to show the tile. Loads DRC automatically and evaluates tile visibility
 *     - requresConfigurationServer (Boolean): Only shows the tile if the server is Configuration
 *     - requresReadOnlyServer (Boolean): Only shows the tile if the server is Read Only
 *     - requresConfigurationOrReadOnlyServer (Boolean): Only shows the tile if the server is Read Only OR Configuration
 *
 * To add a new collection of tiles, add a unique COLLECTION_ID and a corresponding COLLECTION with whichever tiles you want to include.
 * Collections have the following properties:
 *     - tiles (Array): A list of every tile the collection has within it
 *     - hideHeader (Boolean): If true, hide the collection's header (Optional)
 *
 * Add text to global.json under the tiles attribute.
 */

angular.module('acadiamasterApp')
    .constant('TILE_CONSTANTS', (() => {
        const COLLECTION_ID = {
            ACCESS_CONTROL                     : 'ACCESS_CONTROL',
            DASHBOARD                          : 'DASHBOARD',
            DATA_TYPE                          : 'DATA_TYPE',
            DONATION                           : 'DONATION',
            DRC                                : 'DRC',
            DRC_PARTICIPANT_PAIRING_MANAGEMENT : 'DRC_PARTICIPANT_PAIRING_MANAGEMENT',
            EHR                                : 'EHR',
            FORM                               : 'FORM',
            PARTICIPANT                        : 'PARTICIPANT',
            PROGRAM                            : 'PROGRAM',
            PROGRAM_MANAGE                     : 'PROGRAM_MANAGE',
            PROGRAM_TESTS                      : 'PROGRAM_TESTS',
            RULE                               : 'RULE',
            TOOLS                              : 'TOOLS',
            UNIT                               : 'UNIT',
        };

        const TILE_ID = {
            ACCESS_CONTROL                                     : 'ACCESS_CONTROL',
            ACCESS_CONTROL_ROLE                                : 'ACCESS_CONTROL_ROLE',
            ACCESS_CONTROL_USER                                : 'ACCESS_CONTROL_USER',
            APP_VERSIONING                                     : 'APP_VERSIONING',
            BUSINESS_RULE                                      : 'BUSINESS_RULE',
            DATA_TYPE                                          : 'DATA_TYPE',
            DATA_TYPE_CATEGORY                                 : 'DATA_TYPE_CATEGORY',
            DATA_TYPE_PROPERTY                                 : 'DATA_TYPE_PROPERTY',
            DATA_TYPE_ROOT                                     : 'DATA_TYPE_ROOT',
            DATA_TYPE_TYPES                                    : 'DATA_TYPE_TYPES',
            DONATION                                           : 'DONATION',
            DONATION_SITE                                      : 'DONATION_SITE',
            DONATION_ZIP                                       : 'DONATION_ZIP',
            DRC                                                : 'DRC',
            DRC_DATA_SYNC                                      : 'DRC_DATA_SYNC',
            DRC_DATA_TYPE                                      : 'DRC_DATA_TYPE',
            DRC_FORM_ENTRY_SEARCH                              : 'DRC_FORM_ENTRY_SEARCH',
            DRC_FORM_TO_QUESTIONAIRE                           : 'DRC_FORM_TO_QUESTIONAIRE',
            DRC_PAIRING_HISTORY                                : 'DRC_PAIRING_HISTORY',
            DRC_PAIRING_SEARCH_UPDATE                          : 'DRC_PAIRING_SEARCH_UPDATE',
            DRC_PARTICIPANT_PAIRING_MANAGEMENT                 : 'DRC_PARTICIPANT_PAIRING_MANAGEMENT',
            DRC_PDF_SYNC                                       : 'DRC_PDF_SYNC',
            DRC_USER_SYNC_UPDATE                               : 'DRC_USER_SYNC_UPDATE',
            DRC_WITHDRAW                                       : 'DRC_WITHDRAW',
            EHR                                                : 'EHR',
            EHR_PROVIDER                                       : 'EHR_PROVIDER',
            EHR_SYNC                                           : 'EHR_SYNC',
            EHR_USERS                                          : 'EHR_USERS',
            EXTERNAL                                           : 'EXTERNAL',
            EXTERNAL_DATA_IMPORT                               : 'EXTERNAL_DATA_IMPORT',
            EXTERNAL_USER_IMPORT                               : 'EXTERNAL_USER_IMPORT',
            FEATURES                                           : 'FEATURES',
            FORM                                               : 'FORM',
            FORM_DETAIL                                        : 'FORM_DETAIL',
            FORM_VERSION_TWO                                   : 'FORM_VERSION_TWO',
            INTERSTITIAL                                       : 'INTERSTITIAL',
            INVITE_CODE                                        : 'INVITE_CODE',
            MOBILE_BUILD_CREATION                              : 'MOBILE_BUILD_CREATION',
            ORDER_MANAGEMENT                                   : 'ORDER_MANAGEMENT',
            ORGANIZATION                                       : 'ORGANIZATION',
            PARTICIPANT_MANAGEMENT                             : 'PARTICIPANT_MANAGEMENT',
            PARTICIPANT_PROFILE                                : 'PARTICIPANT_PROFILE',
            PROGRAM                                            : 'PROGRAM',
            PROGRAM_CATEGORY                                   : 'PROGRAM_CATEGORY',
            PROGRAM_LIST                                       : 'PROGRAM_LIST',
            PROGRAM_MANAGE_AGREEMENTS                          : 'PROGRAM_MANAGE_AGREEMENTS',
            PROGRAM_MANAGE_ASSET                               : 'PROGRAM_MANAGE_ASSET',
            PROGRAM_MANAGE_ASSOCIATION_COMMUNICATION_CATEGORY  : 'PROGRAM_MANAGE_ASSOCIATION_COMMUNICATION_CATEGORY',
            PROGRAM_MANAGE_ASSOCIATION_FORM                    : 'PROGRAM_MANAGE_ASSOCIATION_FORM',
            PROGRAM_MANAGE_ASSOCIATION_FORM_CONSENT            : 'PROGRAM_MANAGE_ASSOCIATION_FORM_CONSENT',
            PROGRAM_MANAGE_CONTAINER_DEPENDENCY_LINKING        : 'PROGRAM_MANAGE_CONTAINER_DEPENDENCY_LINKING',
            PROGRAM_MANAGE_CONTAINERS                          : 'PROGRAM_MANAGE_CONTAINERS',
            PROGRAM_MANAGE_CONTAINERS_RECALCULATE_PERSONALIZED : 'PROGRAM_MANAGE_CONTAINERS_RECALCULATE_PERSONALIZED',
            PROGRAM_MANAGE_DEEP_LINKS                          : 'PROGRAM_MANAGE_DEEP_LINKS',
            PROGRAM_MANAGE_DETAILS                             : 'PROGRAM_MANAGE_DETAILS',
            PROGRAM_MANAGE_EXPORT_WIZARD                       : 'PROGRAM_MANAGE_EXPORT_WIZARD',
            FUNC_COND_EVAL                                     : 'FUNC_COND_EVAL',
            PROGRAM_MANAGE_EXPRESSION_CONDITION                : 'PROGRAM_MANAGE_EXPRESSION_CONDITION',
            PROGRAM_MANAGE_IMPORT_WIZARD                       : 'PROGRAM_MANAGE_IMPORT_WIZARD',
            PROGRAM_MANAGE_MOBILE_BUILD                        : 'PROGRAM_MANAGE_MOBILE_BUILD',
            PROGRAM_MANAGE_PROGRAM_APP_SETTINGS                : 'PROGRAM_MANAGE_PROGRAM_APP_SETTINGS',
            PROGRAM_MANAGE_PROGRAM_BRANDING                    : 'PROGRAM_MANAGE_PROGRAM_BRANDING',
            PROGRAM_MANAGE_SERVER_STRINGS                      : 'PROGRAM_MANAGE_SERVER_STRINGS',
            PROGRAM_MANAGE_SMS_ENABLE                          : 'PROGRAM_MANAGE_SMS_ENABLE',
            PROGRAM_MANAGE_SOCIAL                              : 'PROGRAM_MANAGE_SOCIAL',
            PROGRAM_MANAGE_STRINGS                             : 'PROGRAM_MANAGE_STRINGS',
            PROGRAM_MANAGE_TABS                                : 'PROGRAM_MANAGE_TABS',
            PROGRAM_MANAGE_UNAUTHENTICATED_TASKS               : 'PROGRAM_MANAGE_UNAUTHENTICATED_TASKS',
            PROGRAM_MANAGE_WIZARD                              : 'PROGRAM_MANAGE_WIZARD',
            PROGRAM_TEST                                       : 'PROGRAM_TEST',
            PROGRAM_TEST_EXECUTION_AUDIT                       : 'PROGRAM_TEST_EXECUTION_AUDIT',
            PROGRAM_TEST_SUITE                                 : 'PROGRAM_TEST_SUITE',
            PROGRAM_TEST_SUITE_EXECUTION_AUDIT                 : 'PROGRAM_TEST_SUITE_EXECUTION_AUDIT',
            PROGRAM_TESTS                                      : 'PROGRAM_TESTS',
            RULE                                               : 'RULE',
            RULE_AUDIT                                         : 'RULE_AUDIT',
            SCHEDULE_RULE_EXECUTION_AUDIT                      : 'SCHEDULE_RULE_EXECUTION_AUDIT',
            TOOLS                                              : 'TOOLS',
            TOOLS_COMMUNICATION_CATEGORIES                     : 'TOOLS_COMMUNICATION_CATEGORIES',
            TOOLS_CONFIG                                       : 'TOOLS_CONFIG',
            TOOLS_CONTAINER_ITEM_TEMPLATES                     : 'TOOLS_CONTAINER_ITEM_TEMPLATES',
            TOOLS_DEEP_LINKS                                   : 'TOOLS_DEEP_LINKS',
            TOOLS_DOWNLOAD                                     : 'TOOLS_DOWNLOAD',
            TOOLS_FORM                                         : 'TOOLS_FORM',
            TOOLS_LANGUAGES                                    : 'TOOLS_LANGUAGES',
            TOOLS_OPERATION_HISTORY                            : 'TOOLS_OPERATION_HISTORY',
            TOOLS_MOBILE_BUILD                                 : 'TOOLS_MOBILE_BUILD',
            TOOLS_SERVER_STRINGS                               : 'TOOLS_SERVER_STRINGS',
            TOOLS_STRINGS                                      : 'TOOLS_STRINGS',
            TOOLS_VXP_DATA_MIGRATION                           : 'TOOLS_VXP_DATA_MIGRATION',
            TOOLS_REGENERATE_PDF                               : 'TOOLS_REGENERATE_PDF',
            UNIT                                               : 'UNIT',
            UNIT_CATEGORY                                      : 'UNIT_CATEGORY',
            UNIT_DETAIL                                        : 'UNIT_DETAIL',
            UNIT_GROUP                                         : 'UNIT_GROUP',
        };

        const COLLECTION = {};
        COLLECTION[COLLECTION_ID.ACCESS_CONTROL] = {
            tiles : [
                TILE_ID.ACCESS_CONTROL_USER,
                TILE_ID.ACCESS_CONTROL_ROLE,
            ],
        };
        COLLECTION[COLLECTION_ID.DASHBOARD] = {
            tiles : [
                TILE_ID.ORGANIZATION,
                TILE_ID.PROGRAM,
                TILE_ID.FORM_VERSION_TWO,
                TILE_ID.RULE,
                TILE_ID.DATA_TYPE,
                TILE_ID.UNIT,
                TILE_ID.FEATURES,
                TILE_ID.PARTICIPANT_MANAGEMENT,
                TILE_ID.PROGRAM_TESTS,
                TILE_ID.DONATION,
                TILE_ID.DRC,
                TILE_ID.EHR,
                TILE_ID.TOOLS,
                TILE_ID.INVITE_CODE,
                TILE_ID.ACCESS_CONTROL,
                TILE_ID.APP_VERSIONING,
                TILE_ID.INTERSTITIAL,
                TILE_ID.EXTERNAL,
                TILE_ID.ORDER_MANAGEMENT,
                TILE_ID.EXTERNAL_DATA_IMPORT,
                TILE_ID.EXTERNAL_USER_IMPORT,
            ],
        };
        COLLECTION[COLLECTION_ID.DATA_TYPE] = {
            tiles : [
                TILE_ID.DATA_TYPE_ROOT,
                TILE_ID.DATA_TYPE_CATEGORY,
                TILE_ID.DATA_TYPE_TYPES,
                TILE_ID.DATA_TYPE_PROPERTY,
            ],
        };
        COLLECTION[COLLECTION_ID.DONATION] = {
            tiles : [
                TILE_ID.DONATION_SITE,
                TILE_ID.DONATION_ZIP,
            ],
        };
        COLLECTION[COLLECTION_ID.DRC] = {
            tiles : [
                TILE_ID.DRC_FORM_TO_QUESTIONAIRE,
                TILE_ID.DRC_DATA_SYNC,
                TILE_ID.DRC_PDF_SYNC,
                TILE_ID.DRC_DATA_TYPE,
                TILE_ID.DRC_FORM_ENTRY_SEARCH,
                TILE_ID.DRC_WITHDRAW,
                TILE_ID.DRC_PARTICIPANT_PAIRING_MANAGEMENT,
                TILE_ID.DRC_USER_SYNC_UPDATE,
            ],
        };
        COLLECTION[COLLECTION_ID.DRC_PARTICIPANT_PAIRING_MANAGEMENT] = {
            tiles : [
                TILE_ID.DRC_PAIRING_HISTORY,
                TILE_ID.DRC_PAIRING_SEARCH_UPDATE,
            ],
        };
        COLLECTION[COLLECTION_ID.EHR] = {
            tiles : [
                TILE_ID.EHR_PROVIDER,
                TILE_ID.EHR_SYNC,
                TILE_ID.EHR_USERS,
            ],
        };
        COLLECTION[COLLECTION_ID.PARTICIPANT] = {
            tiles : [
                TILE_ID.PARTICIPANT_PROFILE,
            ],
        };
        COLLECTION[COLLECTION_ID.PROGRAM] = {
            tiles : [
                TILE_ID.PROGRAM_LIST,
                TILE_ID.PROGRAM_CATEGORY,

            ],
        };
        COLLECTION[COLLECTION_ID.PROGRAM_TESTS] = {
            tiles : [
                TILE_ID.PROGRAM_TEST,
                TILE_ID.PROGRAM_TEST_SUITE,
                TILE_ID.PROGRAM_TEST_EXECUTION_AUDIT,
                TILE_ID.PROGRAM_TEST_SUITE_EXECUTION_AUDIT,
            ],
        };
        COLLECTION[COLLECTION_ID.PROGRAM_MANAGE] = {
            hideHeader : true,
            tiles      : [
                TILE_ID.PROGRAM_MANAGE_DETAILS,
                TILE_ID.PROGRAM_MANAGE_TABS,
                TILE_ID.PROGRAM_MANAGE_CONTAINERS,
                TILE_ID.PROGRAM_MANAGE_CONTAINERS_RECALCULATE_PERSONALIZED,
                TILE_ID.PROGRAM_MANAGE_ASSOCIATION_FORM_CONSENT,
                TILE_ID.PROGRAM_MANAGE_STRINGS,
                TILE_ID.PROGRAM_MANAGE_DEEP_LINKS,
                TILE_ID.PROGRAM_MANAGE_SERVER_STRINGS,
                TILE_ID.PROGRAM_MANAGE_IMPORT_WIZARD,
                TILE_ID.PROGRAM_MANAGE_PROGRAM_APP_SETTINGS,
                TILE_ID.PROGRAM_MANAGE_PROGRAM_BRANDING,
                TILE_ID.PROGRAM_MANAGE_EXPORT_WIZARD,
                TILE_ID.PROGRAM_MANAGE_SMS_ENABLE,
                TILE_ID.PROGRAM_MANAGE_ASSET,
                TILE_ID.PROGRAM_MANAGE_EXPRESSION_CONDITION,
                TILE_ID.PROGRAM_MANAGE_AGREEMENTS,
                TILE_ID.PROGRAM_MANAGE_ASSOCIATION_COMMUNICATION_CATEGORY,
                TILE_ID.PROGRAM_MANAGE_SOCIAL,
                TILE_ID.PROGRAM_MANAGE_UNAUTHENTICATED_TASKS,
                TILE_ID.PROGRAM_MANAGE_CONTAINER_DEPENDENCY_LINKING,
            ],
        };
        COLLECTION[COLLECTION_ID.RULE] = {
            tiles : [
                TILE_ID.BUSINESS_RULE,
                TILE_ID.FUNC_COND_EVAL,
                TILE_ID.RULE_AUDIT,
                TILE_ID.SCHEDULE_RULE_EXECUTION_AUDIT,
            ],
        };
        COLLECTION[COLLECTION_ID.TOOLS] = {
            tiles : [
                TILE_ID.TOOLS_DOWNLOAD,
                TILE_ID.TOOLS_FORM,
                TILE_ID.TOOLS_CONFIG,
                TILE_ID.TOOLS_LANGUAGES,
                TILE_ID.TOOLS_STRINGS,
                TILE_ID.TOOLS_SERVER_STRINGS,
                TILE_ID.TOOLS_CONTAINER_ITEM_TEMPLATES,
                TILE_ID.TOOLS_COMMUNICATION_CATEGORIES,
                TILE_ID.TOOLS_VXP_DATA_MIGRATION,
                TILE_ID.TOOLS_OPERATION_HISTORY,
                TILE_ID.TOOLS_MOBILE_BUILD,
                TILE_ID.TOOLS_REGENERATE_PDF,
            ],
        };
        COLLECTION[COLLECTION_ID.UNIT] = {
            tiles : [
                TILE_ID.UNIT_CATEGORY,
                TILE_ID.UNIT_DETAIL,
                TILE_ID.UNIT_GROUP,
            ],
        };

        const TILE = {};
        TILE[TILE_ID.ACCESS_CONTROL] = {
            icon    : 'fa-key',
            routeId : 'accessControl',
        };
        TILE[TILE_ID.ACCESS_CONTROL_ROLE] = {
            countId : 'roles',
            icon    : 'fa-graduation-cap',
            routeId : 'role',
        };
        TILE[TILE_ID.ACCESS_CONTROL_USER] = {
            countId : 'users',
            icon    : 'fa-users',
            routeId : 'user-management',
        };
        TILE[TILE_ID.APP_VERSIONING] = {
            icon    : 'fa-mobile',
            routeId : 'appVersioning',
        };
        TILE[TILE_ID.BUSINESS_RULE] = {
            countId    : 'rule',
            hasPopover : true,
            icon       : 'fa-magic',
            routeId    : 'businessRule',
        };
        TILE[TILE_ID.DATA_TYPE] = {
            countId : 'dataType',
            icon    : 'fa-code',
            routeId : 'dataTypeMaster',
        };
        TILE[TILE_ID.DATA_TYPE_CATEGORY] = {
            countId : 'dataTypeCategoryCount',
            icon    : 'fa-list',
            routeId : 'dataTypeCategory',
        };
        TILE[TILE_ID.DATA_TYPE_PROPERTY] = {
            countId : 'propertyCount',
            icon    : 'fa-archive',
            routeId : 'property',
        };
        TILE[TILE_ID.DATA_TYPE_ROOT] = {
            countId : 'dataTypeRootCategoryCount',
            icon    : 'fa-list',
            routeId : 'dataTypeRootCategory',
        };
        TILE[TILE_ID.DATA_TYPE_TYPES] = {
            countId : 'dataTypeCount',
            icon    : 'fa-code',
            routeId : 'dataType',
        };
        TILE[TILE_ID.DONATION] = {
            icon             : 'fa-hospital-o',
            requiresFeatures : [ 'GEOLOCATION' ],
            routeId          : 'donationSiteHome',
        };
        TILE[TILE_ID.DONATION_SITE] = {
            icon             : 'fa-hospital-o',
            requiresFeatures : [ 'GEOLOCATION' ],
            routeId          : 'donationSite',
        };
        TILE[TILE_ID.DONATION_ZIP] = {
            icon             : 'fa-map-marker',
            requiresFeatures : [ 'GEOLOCATION' ],
            routeId          : 'geoLocationZipCodes',
        };
        TILE[TILE_ID.DRC] = {
            icon        : 'fa-medkit',
            requiresDrc : true,
            routeId     : 'drcDashboard',
        };
        TILE[TILE_ID.DRC_FORM_TO_QUESTIONAIRE] = {
            icon    : 'fa-pencil-square-o',
            routeId : 'drcConvertFormToQuestionnaire',
        };
        TILE[TILE_ID.DRC_DATA_SYNC] = {
            icon    : 'fa-refresh',
            routeId : 'drcSyncUsersAndForms',
        };
        TILE[TILE_ID.DRC_PDF_SYNC] = {
            icon    : 'fa-refresh',
            routeId : 'drcSyncPDF',
        };
        TILE[TILE_ID.DRC_DATA_TYPE] = {
            icon    : 'fa-code',
            routeId : 'drcCreateDataTypeProperty',
        };
        TILE[TILE_ID.DRC_FORM_ENTRY_SEARCH] = {
            icon    : 'fa-sitemap',
            routeId : 'formEntrySearch',
        };
        TILE[TILE_ID.DRC_WITHDRAW] = {
            icon    : 'fa-sign-out',
            routeId : 'drcWithdraw',
        };
        TILE[TILE_ID.DRC_PARTICIPANT_PAIRING_MANAGEMENT] = {
            icon    : 'fa-link',
            routeId : 'drcPairingManagement',
        };
        TILE[TILE_ID.DRC_PAIRING_HISTORY] = {
            countId : '7',
            icon    : 'fa-history',
            routeId : 'drcPairingHistory',
        };
        TILE[TILE_ID.DRC_PAIRING_SEARCH_UPDATE] = {
            icon    : 'fa-pencil-square',
            routeId : 'drcPairingSearch',
        };
        TILE[TILE_ID.DRC_USER_SYNC_UPDATE] = {
            icon    : 'fa-history',
            routeId : 'drcUserSyncUpdate',
        };
        TILE[TILE_ID.EHR] = {
            icon    : 'fa-laptop',
            routeId : 'ehrDashboard',
        };
        TILE[TILE_ID.EHR_PROVIDER] = {
            icon    : 'fa-hospital-o',
            routeId : 'ehrProviders',
        };
        TILE[TILE_ID.EHR_SYNC] = {
            icon        : 'fa-refresh',
            requiresDrc : true,
            routeId     : 'ehrSyncUsers',
        };
        TILE[TILE_ID.EHR_USERS] = {
            icon    : 'fa-users',
            routeId : 'ehrUsers',
        };
        TILE[TILE_ID.EXTERNAL] = {
            icon    : 'fa-server',
            routeId : 'external',
        };
        TILE[TILE_ID.FEATURES] = {
            countId : 'features',
            icon    : 'fa-bug',
            routeId : 'features',
        };
        TILE[TILE_ID.FORM_VERSION_TWO] = {
            countId : 'form',
            icon    : 'fa-pencil-square-o',
            routeId : 'formVersionTwo',
        };
        TILE[TILE_ID.FORM_DETAIL] = {
            countId    : 'form',
            hasPopover : true,
            icon       : 'fa-pencil-square-o',
            routeId    : 'form({defaultFormCategory:"DATA_FORM"})',
        };
        TILE[TILE_ID.INTERSTITIAL] = {
            icon    : 'fa-cloud-upload',
            routeId : 'interstitialFile',
        };
        TILE[TILE_ID.INVITE_CODE] = {
            icon    : 'fa-birthday-cake',
            routeId : 'inviteCodes',
        };
        TILE[TILE_ID.ORDER_MANAGEMENT] = {
            icon    : 'fa-truck',
            routeId : 'orderManagement',
        };
        TILE[TILE_ID.ORGANIZATION] = {
            countId : 'organization',
            icon    : 'fa-sitemap',
            routeId : 'organization',
        };
        TILE[TILE_ID.PARTICIPANT_PROFILE] = {
            icon    : 'fa-address-card-o',
            routeId : 'participantProfile',
        };
        TILE[TILE_ID.PARTICIPANT_MANAGEMENT] = {
            icon    : 'fa-user',
            routeId : 'participantManagement',
        };
        TILE[TILE_ID.PROGRAM] = {
            countId : 'program',
            icon    : 'fa-heartbeat',
            routeId : 'programManagement',
        };
        TILE[TILE_ID.PROGRAM_CATEGORY] = {
            countId : 'programCategory',
            icon    : 'fa-list-ul',
            routeId : 'programCategory',
        };
        TILE[TILE_ID.EXTERNAL_DATA_IMPORT] = {
            countId : 'externalDataImport',
            icon    : 'fa-list-ul',
            routeId : 'externalDataImport',
        };
        TILE[TILE_ID.EXTERNAL_USER_IMPORT] = {
            countId : 'externalUserImport',
            icon    : 'fa-list-ul',
            routeId : 'externalUserImport',
        };
        TILE[TILE_ID.PROGRAM_LIST] = {
            countId : 'program',
            icon    : 'fa-heartbeat',
            routeId : 'program',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_AGREEMENTS] = {
            icon    : 'fa-handshake-o',
            routeId : 'program.manage.agreements',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_ASSET] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.asset',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_ASSOCIATION_FORM_CONSENT] = {
            countId : 'consentFormAssociation',
            icon    : 'fa-check',
            routeId : 'program.manage.consentForm',
        };

        TILE[TILE_ID.PROGRAM_MANAGE_CONTAINERS] = {
            countId : 'programContainers',
            icon    : 'fa-th-large',
            routeId : 'program.manage.containers',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_CONTAINERS_RECALCULATE_PERSONALIZED] = {
            icon    : 'fa-cogs',
            routeId : 'program.manage.containers.recalculatePersonalized',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_DEEP_LINKS] = {
            icon    : 'fa-link',
            routeId : 'program.manage.deepLinks',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_DETAILS] = {
            icon    : 'fa-pencil',
            routeId : 'program.manage.edit',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_EXPORT_WIZARD] = {
            icon                        : 'fa-cloud-download',
            requiresConfigurationServer : true,
            routeId                     : 'program.manage.exportWizard',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_EXPRESSION_CONDITION] = {
            icon    : 'fa-code',
            routeId : 'expressionCondition',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_IMPORT_WIZARD] = {
            icon                   : 'fa-cloud-upload',
            requiresReadOnlyServer : true,
            routeId                : 'program.manage.importWizard',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_PROGRAM_BRANDING] = {
            icon    : 'fa-cloud-upload',
            routeId : 'program.manage.programBranding',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_PROGRAM_APP_SETTINGS] = {
            icon    : 'fa-wrench',
            routeId : 'program.manage.programAppSettings',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_SMS_ENABLE] = {
            icon    : 'fa-mobile',
            routeId : 'program.manage.sms',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_STRINGS] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.strings',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_SERVER_STRINGS] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.server',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_WIZARD] = {
            icon                        : 'fa-download',
            requiresConfigurationServer : true,
            routeId                     : 'program.manage.exportWizard',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_ASSOCIATION_COMMUNICATION_CATEGORY] = {
            icon    : 'fa-list-ul',
            routeId : 'program.manage.communicationCategory',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_TABS] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.tabs',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_SOCIAL] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.social',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_UNAUTHENTICATED_TASKS] = {
            icon    : 'fa-list-alt',
            routeId : 'program.manage.unauthenticated',
        };
        TILE[TILE_ID.PROGRAM_MANAGE_CONTAINER_DEPENDENCY_LINKING] = {
            icon    : 'fa-link',
            routeId : 'program.manage.dpLinking',
        };
        TILE[TILE_ID.PROGRAM_TEST] = {
            countId : 'testCase',
            icon    : 'fa-heartbeat',
            routeId : 'programTests.pstCase',
        };
        TILE[TILE_ID.PROGRAM_TESTS] = {
            icon    : 'fa-heartbeat',
            routeId : 'programTests',
        };
        TILE[TILE_ID.PROGRAM_TEST_SUITE] = {
            countId : 'testSuite',
            icon    : 'fa-object-group',
            routeId : 'programTests.pstSuite',
        };
        TILE[TILE_ID.PROGRAM_TEST_EXECUTION_AUDIT] = {
            icon    : 'fa-history',
            routeId : 'programTests.programTestAudits',
        };
        TILE[TILE_ID.PROGRAM_TEST_SUITE_EXECUTION_AUDIT] = {
            icon    : 'fa-history',
            routeId : 'programTests.programTestSuiteExecutionAudits',
        };
        TILE[TILE_ID.RULE] = {
            countId : 'rule',
            icon    : 'fa-magic',
            routeId : 'ruleDashboard',
        };
        TILE[TILE_ID.FUNC_COND_EVAL] = {
            icon    : 'fa-flask',
            routeId : 'funcEval',
        };
        TILE[TILE_ID.RULE_AUDIT] = {
            icon    : 'fa-cogs',
            routeId : 'ruleAudits',
        };
        TILE[TILE_ID.SCHEDULE_RULE_EXECUTION_AUDIT] = {
            icon    : 'fa-clock-o',
            routeId : 'scheduleRuleExecutionAudits',
        };
        TILE[TILE_ID.TOOLS] = {
            icon    : 'fa-wrench',
            routeId : 'miscTools',
        };
        TILE[TILE_ID.TOOLS_CONFIG] = {
            icon    : 'fa-table',
            routeId : 'configurationIds',
        };
        TILE[TILE_ID.TOOLS_CONTAINER_ITEM_TEMPLATES] = {
            icon    : 'fa-object-group ',
            routeId : 'toolContainerItemTemplates',
        };
        TILE[TILE_ID.TOOLS_DEEP_LINKS] = {
            icon    : 'fa-link',
            routeId : 'deepLinks',
        };
        TILE[TILE_ID.TOOLS_DOWNLOAD] = {
            icon    : 'fa-cloud-download',
            routeId : 'downloadTools',
        };
        TILE[TILE_ID.TOOLS_FORM] = {
            icon    : 'fa-columns',
            routeId : 'formCompareTools',
        };
        TILE[TILE_ID.TOOLS_LANGUAGES] = {
            icon                                 : 'fa-globe',
            requresConfigurationOrReadOnlyServer : true,
            routeId                              : 'toolLanguages',
        };
        TILE[TILE_ID.TOOLS_STRINGS] = {
            icon    : 'fa-list-alt',
            routeId : 'toolStrings',
        };
        TILE[TILE_ID.TOOLS_SERVER_STRINGS] = {
            icon    : 'fa-list-alt',
            routeId : 'toolServerStrings',
        };
        TILE[TILE_ID.TOOLS_COMMUNICATION_CATEGORIES] = {
            icon    : 'fa-list-ul',
            routeId : 'toolCommunicationCategories',
        };
        TILE[TILE_ID.TOOLS_VXP_DATA_MIGRATION] = {
            icon    : 'fa-exchange',
            routeId : 'toolVXPDataMigration',
        };
        TILE[TILE_ID.TOOLS_OPERATION_HISTORY] = {
            icon    : 'fa-history',
            routeId : 'toolOperationHistory',
        };
        TILE[TILE_ID.TOOLS_MOBILE_BUILD] = {
            icon    : 'fa-mobile',
            routeId : 'toolMobileBuild',
        };
        TILE[TILE_ID.TOOLS_REGENERATE_PDF] = {
            icon    : 'fa-refresh',
            routeId : 'toolRegeneratePDF',
        };

        TILE[TILE_ID.UNIT] = {
            countId : 'unit',
            icon    : 'fa-balance-scale',
            routeId : 'unitSystem',
        };
        TILE[TILE_ID.UNIT_CATEGORY] = {
            countId : 'unitCategoryCount',
            icon    : 'fa-list-ul',
            routeId : 'unitCategory',
        };
        TILE[TILE_ID.UNIT_DETAIL] = {
            countId : 'unitCount',
            icon    : 'fa-balance-scale',
            routeId : 'unit',
        };
        TILE[TILE_ID.UNIT_GROUP] = {
            countId : 'unitGroupCount',
            icon    : 'fa-th-large',
            routeId : 'unitGroup',
        };

        const REQUIREMENT = {
            CONFIGURATION_SERVER              : 'CONFIGURATION_SERVER',
            DRC                               : 'DRC',
            FEATURE                           : 'FEATURE',
            READ_ONLY_OR_CONFIGURATION_SERVER : 'READ_ONLY_OR_CONFIGURATION_SERVER',
            READ_ONLY_SERVER                  : 'READ_ONLY_SERVER',
        };

        const VIEW = {
            DROPDOWN : 'DROPDOWN',
            GRID     : 'GRID',
        };

        return {
            COLLECTION,
            ID : COLLECTION_ID,
            REQUIREMENT,
            TILE,
            VIEW,
        };
    })());
