
/**
 * directive that checks if program name is available, must be used together with ng-model
 */
(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrProgramAvailable', function (ProgramService, $q) {

            function isNameAvailable(value, currentId, type) {
                var promise = ProgramService.isNameAvailable(value, currentId, type);
                return promise.then(function (response) {
                    if (!response.data) {
                        return $q.reject('Name is already taken');
                    }

                    return true;
                });
            }

            return {
                require: 'ngModel',
                scope: {
                    currentId: '=vbrProgramAvailable',
                    type: '=valueType',
                },
                link: function (scope, elm, attr, model) {
                    model.$asyncValidators.vbrProgramAvailable = function (modelValue) {
                        return isNameAvailable(modelValue, scope.currentId, scope.type);
                    };
                }
            }
        });

})();
