(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * testSuites directive
     */
        .directive('exportWizardStepProgramTestSuite', function (FormAssociationSearch, AlertService, ParseLinks, ProgramTestSuiteService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepProgramTestSuite.html',
                scope: {
                    program: '=',
                    selectedData: '=',
                    wizardConstant: '='
                },
                controller: function ($scope) {
                    init($scope);
                    $scope.selectAll = function () {
                        if ($scope.data.selectAllValue) {
                            // Add displayed testSuites to the selected testSuites array if they do not already exist there
                            var selectedIds = $scope.selectedData.testSuites.map(function (selectedTestSuite) {
                                return selectedTestSuite.id;
                            });
                            if ($scope.data.testSuites) {
                                $scope.data.testSuites.forEach(function (testSuite) {
                                    if (selectedIds.indexOf(testSuite.id) === -1) {
                                        $scope.selectedData.testSuites.push(testSuite);
                                    }
                                });
                            }
                        } else {
                            // Remove any testSuites that are currently displayed from the selected testSuites array
                            var testSuiteIds = $scope.data.testSuites.map(function (testSuite) {
                                return testSuite.id;
                            });
                            var selectedTestSuites = [];
                            if ($scope.selectedData.testSuites) {
                                $scope.selectedData.testSuites.forEach(function (testSuite) {
                                    if (testSuiteIds.indexOf(testSuite.id) === -1) {
                                        selectedTestSuites.push(testSuite);
                                    }
                                });
                            }
                            $scope.selectedData.testSuites = selectedTestSuites;
                        }
                    };

                    $scope.sortBy = function (name) {
                        $scope.searchData.ascending = ($scope.searchData.orderBy === name) ? !$scope.searchData.ascending : true;
                        $scope.searchData.orderBy = name;
                        $scope.searchData.page = 0;
                        loadAll($scope);
                    };

                    $scope.loadPage = function (page) {
                        $scope.searchData.page = page || 0;
                        loadAll($scope);
                    };

                    $scope.$watch('selectedData.testSuites.length', function () {
                        $scope.data.selectAllValue = areAllDisplayedTestSuiteSelected($scope);
                    });

                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                $scope.orderByChoices = {
                    ID: 'id',
                    NAME: 'name',
                };

                $scope.searchData = {
                    ascending: false,
                    orderByChoices : ['updatedOn', 'id', 'name', 'displayName'],
                    orderBySelected : 'updatedOn',
                    page: 0,
                    pageSize: 20,
                    pageSizeChoices: [20, 50, 100],
                    searchString: "",
                    previousSearchString: "",
                    searchResultCount: "loading..."
                };
                $scope.data = {
                    selectAllValue: false,
                    testSuites: [],
                };

                loadAll($scope);
            }

            function areAllDisplayedTestSuiteSelected($scope) {
                var selectedIds = $scope.selectedData.testSuites.map(function (selectedTestSuite) {
                    return selectedTestSuite.id;
                });
                if (!$scope.data.testSuites || !$scope.data.testSuites.length) {
                    return false;
                }
                for (var i = 0; i < $scope.data.testSuites.length; i++) {
                    var testSuiteId = $scope.data.testSuites[i].id;
                    if (selectedIds.indexOf(testSuiteId) === -1) {
                        return false;
                    }
                }
                return true;
            }

            function loadAll($scope) {
                var programId = $scope.program.id;

                var searchDto = {
                    page: $scope.searchData.page,
                    size: $scope.searchData.pageSize,
                    ascending: $scope.searchData.ascending,
                    orderBy: $scope.searchData.orderBy,
                    searchString: $scope.searchData.searchString
                };

                ProgramTestSuiteService.getTestSuiteOfProgram(programId, searchDto).then(function (response) {
                    if (response == null || response.data == null || response.status !== 200) {
                        AlertService.error("Failed to load program specific test suite for Program Id: " + programId);
                        return;
                    }

                    $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                    $scope.searchData.previousSearchString = searchDto.searchString;
                    $scope.data.links = ParseLinks.parse(response.headers('link'));
                    $scope.data.testSuites = response.data;
                    $scope.data.selectAllValue = areAllDisplayedTestSuiteSelected($scope);

                    if (response.data.length === 0) {
                        AlertService.warning("No Program Test Suites were found");
                    }
                });
            }
        });
})();
