/**
 * directive for internal search of the form
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.directive('formEditSearch', function (ngDialog, DisplayConfigReplacementService,
                                              FormLinkingElementService, FormSearchModel,
                                              FormSearchConstants, FormSearchService, AlertService, FormConstants, vbrCommonUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/advancedPanel/formEditSearch.html',
            scope: {
                formVersion: '=',
                selector: '='
            },
            link: function ($scope) {
                $scope.searchConstants = FormSearchConstants;
                $scope.constants = FormConstants;
                $scope.searchData = new FormSearchModel();
                $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;

                $scope.doSearch = function () {
                    FormSearchService.doSearch($scope.searchData, $scope.formVersion);
                };

                $scope.gotoLinkedElement = function (resultObject) {
                    FormLinkingElementService.gotoLinkedElement($scope.selector, resultObject);
                };

                $scope.toggleAdvancedFilter = function () {
                    toggleAdvancedFilter($scope.searchData);
                };

                $scope.openDisplayConfig = function () {
                    openDisplayConfig($scope.searchData.displayConfigFilter);
                };

                $scope.openDisplayConfigReplacement = function () {
                    openDisplayConfig($scope.searchData.displayConfigReplaceFilter);
                };

                $scope.searchTypeChanged = function() {
                    if ($scope.searchData.searchType != $scope.constants.nodeType.FIELD) {
                        $scope.searchData.fieldType = null;
                    }

                    if ($scope.searchData.searchType != $scope.constants.nodeType.SUB_FIELD) {
                        $scope.searchData.subFieldType = null;
                    }
                };

                $scope.selectAllChanged = function() {
                    $scope.searchData.selectAllChanged();
                };

                $scope.resultSelectionUpdated = function(localId) {
                    $scope.searchData.resultSelectionUpdated(localId);
                };

                $scope.applyDisplayReplacements = function() {
                    DisplayConfigReplacementService.apply($scope.searchData);
                };

                $scope.applyStringReplacements = function() {
                    var itemsToApplyChangesTo = _.filter($scope.searchData.searchResults, function(element) {
                        return $scope.searchData.selected[element.localId];
                    });

                    // go through each of the items to apply the change
                    _.forEach(itemsToApplyChangesTo, function(element) {
                        if (element!=null && _.isFunction(element.doReplace)) {
                            element.doReplace($scope.searchData.searchString, $scope.searchData.replacementString);
                        }
                    });

                    AlertService.success('changes applied to ' + itemsToApplyChangesTo.length + ' items');

                    // this is a hack to solve the issue with localization map GUI doesn't auto update on this type of
                    // changes, should be fixed later
                    $scope.selector.resetSelection();
                };
            }
        };

        /******************************************************************************
         * private functions
         ******************************************************************************/
        /**
         * toggle between enable/disable advanced filters, for now we only support
         * display config, we will introduce more items such as field value
         * @param searchData
         */
        function toggleAdvancedFilter(searchData) {
            if (searchData.isAdvancedChoiceEnabled()) {
                searchData.disableAdvancedChoice();
            }
            else {
                searchData.enableAdvancedChoice();
            }
        }

        /**
         * open the display config filter window
         * @param displayConfigFilter - display config filter to be used in the modal window
         */
        function openDisplayConfig(displayConfigFilter) {
            var template = '<display-config-filter filter-data="displayConfigFilter" close-function="closeFunction"></display-config-filter>';

            // open modal window and pass in all the scope variables
            var dialog = ngDialog.open({
                showClose: false,
                closeByEscape: false,
                closeByDocument: false,
                template: template,
                plain: true,
                controller: ['$scope', function ($scope) {
                    $scope.displayConfigFilter = displayConfigFilter;
                    $scope.closeFunction = closeDialog;
                }],
                height: '90%',
                className: 'ngdialog-theme-plain custom-width-large'
            });

            function closeDialog() {
                dialog.close();
            }

        }

    });
})();

