
(function() {
    'use strict';
    angular.module('acadiamasterApp')
        .factory('ProgramSubscriptions', ['$http', 'localWeb', function ($http, localWeb) {

            /**
             * method to get active subscription details by program id
             * @param programDistId
             */
            function getActiveSubscriptionsByProgramDistributionId(programDistId) {
                var url = localWeb.createUrl('api/programSubscriptions/');
                var finalUrl = url + programDistId;
                return $http.get(finalUrl);
            }

            function getAllConsentedForms() {
                var url = localWeb.createUrl('api/programSubscriptions/consentedForm');
                return $http.get(url);
            }

            function withdrawConsent(programDistId, consentType) {
                var url = localWeb.createUrl('api/programSubscriptions/withdraw/');
                var finalUrl = url + programDistId + "?consentType=" + consentType;
                return $http.post(finalUrl, "");
            }

            function advancedSearch(filter) {
                var url = localWeb.createUrl('api/programSubscriptions/advancedSearch?');
                var finalUrl = url + $.param(filter);
                return $http.get(finalUrl);
            }

            return {
                'get': getActiveSubscriptionsByProgramDistributionId,
                'getAllConsentedForms': getAllConsentedForms,
                'withdrawConsent': withdrawConsent,
                'advancedSearch': advancedSearch
            };
        }]);
})();
