/**
 * Created by jpierce on 5/18/20.
 */
(() => {
    angular.module('acadiamasterApp')
        .constant('PTSEA_CONSTANTS', {
            ID: {
                id: 'id',
                sIndex: true,
                sortable: true,
            },
            PROGRAM_TEST_SUITE_ID: {
                id: 'programTestSuiteId',
                sortable: true,
            },
            MANUALLY_TRIGGERED: {
                id: 'manuallyTriggered',
            },
            START_TIMESTAMP: {
                id: 'startTimestamp',
                isDate: true,
                sortable: true,
            },
            END_TIMESTAMP: {
                id: 'endTimestamp',
                isDate: true,
                sortable: true,
            },
            DETAILS: {
                id: 'details',
            },
        });
})();
