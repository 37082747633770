import {DefaultManageConfig} from "./manageConfig.constants";

(function () {
    'use strict';

    angular.module('acadiamasterApp').service('ManageConfigModel', function (InnerManageConfigModel) {

        function ManageConfigModel() {
            this.emailOption = new InnerManageConfigModel(DefaultManageConfig.emailOption);
            this.viewOption = new InnerManageConfigModel(DefaultManageConfig.viewOption);
            this.withdrawOption = new InnerManageConfigModel(DefaultManageConfig.withdrawOption);
            this.updateOption = new InnerManageConfigModel(DefaultManageConfig.updateOption);
            this.stopParticipatingOption = new InnerManageConfigModel(DefaultManageConfig.stopParticipatingOption);
            this.reconsentOption = new InnerManageConfigModel(DefaultManageConfig.reconsentOption);
            this.manageConfigArray = [this.emailOption, this.viewOption, this.withdrawOption, this.updateOption, this.stopParticipatingOption, this.reconsentOption];
            this.manageConfigArray.sort(compareOrder);
        }

        ManageConfigModel.prototype.toDto = function () {
            let dto = {};
            dto.emailOption = this.emailOption.toDto();
            dto.viewOption = this.viewOption.toDto();
            dto.withdrawOption = this.withdrawOption.toDto();
            dto.updateOption = this.updateOption.toDto();
            dto.stopParticipatingOption = this.stopParticipatingOption.toDto();
            dto.reconsentOption = this.reconsentOption.toDto();
            return dto;
        };

        ManageConfigModel.prototype.fromDto = function (dto) {
            this.emailOption.fromDto(dto.emailOption);
            this.viewOption.fromDto(dto.viewOption);
            this.withdrawOption.fromDto(dto.withdrawOption);
            this.updateOption.fromDto(dto.updateOption);
            this.stopParticipatingOption.fromDto(dto.stopParticipatingOption);
            this.reconsentOption.fromDto(dto.reconsentOption);
            this.manageConfigArray = [this.emailOption, this.viewOption, this.withdrawOption, this.updateOption, this.stopParticipatingOption, this.reconsentOption];
            this.manageConfigArray.sort(compareOrder);
        };

        function compareOrder(manageOption_1, manageOption_2) {
            const orderNum_1 = manageOption_1.orderNumber;
            const orderNum_2 = manageOption_2.orderNumber;
            let result = 0;
            if(orderNum_1 > orderNum_2){result = 1;}
            else if(orderNum_1 < orderNum_2) {result = -1;}
            return result;
        }

        /***************************************
         * service return call
         ***************************************/
        return ManageConfigModel;

    });

})();
