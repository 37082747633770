export const fieldDisplayConfig = {
    width: '100%',
    gravity: 'LEFT',
    padding: 'System',
    margin: 'System',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textStyle: 'inherit'
};

export const titleDisplayConfig = {
    width: '100%',
    gravity: 'LEFT',
    padding: '0 30 30 30',
    margin: 'System',
    fontSize: '12pt',
    fontFamily: 'inherit',
    textStyle: 'inherit',
    bgColor: 'white',
    color: 'black80'
};

export const optionsDisplayConfig = {
    width: '100%',
    gravity: 'LEFT',
    padding: '0 30 30 20',
    margin: 'System',
    fontSize: '12pt',
    fontFamily: 'inherit',
    textStyle: 'inherit',
    bgColor: 'white',
    color: '#333333'
};

export const optionsBasicConfig = {
    includeImage: true,
    includeText: true,
    imagePosition: 'LEFT',
    imageWidth: '20dp',
    imageMargin: '20 0 20 20',
    textPosition: 'RIGHT',
    textGravity: 'LEFT',
    textStyle: 'NORMAL',
    textMargin: '23 0 0 20',
    includeSeparator: true,
    imagePreset: 'CIRCULAR_BLUE(single select)/CHECK_BOX_BLUE(multi select)'
};