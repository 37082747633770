/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola 03/25/2019
 */
import template from './orderDetails.template.html';
import { capitalized } from '../../../../../util/format/formatFunction';

(function () {
    angular.module('acadiamasterApp').directive('orderDetailsList', (AlertService,
        ProgramService, ParticipantProfileService, ngDialog, ProgramSearch,
        OrderTypeService) => {
        let orderDetailsController = function ($rootScope) {
            let vm = this;

            vm.data = {
                allOrderTypeNames : [],
                allPrograms       : [],
                cancelationLookup : [],
                programCodes      : {},
                selectOrder       : {},
            };

            function orderStatus (cell) {
                if (cell.status) {
                    const status = capitalized(cell.status);
                    const className = cell.status.toLowerCase();
                    return `<div class="order-status ${className}">${status}</div>`;
                }
                return null;
            }

            function orderSubStatus (cell) {
                return cell.subStatus ? capitalized(cell.subStatus) : '';
            }

            vm.columns = [
                { headerName : 'PROGRAM ID(NAME)', keyValue : 'programId', sortable : true, type : 'text' },
                { headerName : 'FULFILLMENT ORDER ID', keyValue : 'id', sortable : true, type : 'text' },
                { headerName : 'ORDER TYPE CODE', keyValue : 'orderTypeCode', sortable : true, type : 'text' },
                { formatData  : orderStatus, headerHover : {
                    content : `The Order Status provides the order status for
                    complete end to end order. Example INITIATED, IN_PROGRESS, COMPLETED, CANCELLED or ERROR`,
                    style : {
                        height : '134px',
                        right  : '-153px',
                        top    : '-150px',
                        width  : '301px',
                    },
                    title : 'Order Status',
                }, headerName : 'ORDER STATUS', keyValue : 'status', sortable : true, type : 'formatted' },
                { formatData  : orderSubStatus, headerHover : {
                    content : `The Order Sub status provides more specific details on
                    order  when it goes through different stages from supplier
                    creating and shipping the order to participant and participant
                    tracking and return tracking via tracking services.`,
                    title : 'Order Sub Status',
                },
                headerName : 'ORDER SUB STATUS ', keyValue   : 'subStatus',
                sortable   : true, tdClass    : 'bold-text', type       : 'formatted' },
                { headerName : 'ORDER CREATED DATE', keyValue   : 'createdOn',
                    sortable   : true, tdClass    : 'bold-text created-date', type       : 'date' },
                { headerName : 'LAST UPDATED DATE', keyValue   : 'updatedOn',
                    sortable   : true, tdClass    : 'bold-text updated-date', type       : 'date' },
                { headerName : 'VIEW DETAILS', hoverText  : 'Click for View details',
                    iconValue  : 'glyphicon glyphicon-eye-open', name       : 'view-button', type       : 'button' },
            ];

            vm.page = 0;
            vm.filterData = {
                jumpTo            : null,
                pageSizeChoices   : [ 20, 50, 100 ],
                pageSizeSelected  : 20,
                searchResultCount : 0,
                sortColumn        : 'updatedOn',
                sortOrder         : 'desc',
            };

            vm.loadPage = function (page, pageSize, jumpNumber) {
                vm.page = page;
                if (pageSize) {
                    vm.filterData.pageSizeSelected = pageSize;
                }
                if (jumpNumber >= 0) {
                    vm.page = jumpNumber;
                }
                vm.loadOrders();
            };
            /*
             * Sorts the table by the provided keyValue
             */
            vm.setOrderBy = function (keyValue) {
                vm.filterData.sortOrder = vm.filterData.sortOrder === 'asc' ? 'desc': 'asc';
                vm.filterData.sortColumn = keyValue;
                vm.loadOrders();
            };

            /**
             * Shows the Create Order confirmation modal
             */
            vm.createNewOrder = function () {
                ngDialog.open({
                    className  : 'ngdialog-theme-plain custom-width-small',
                    controller : [ function () {
                        let cmc = this;
                        cmc.data = {
                            orderType  : vm.data.allOrderTypeNames[0] || null,
                            orderTypes : vm.data.allOrderTypeNames,
                            program    : null,
                            programs   : vm.data.allPrograms,
                        };
                        // This function will not be used and will be replaced with new UI changes

                        // cmc.submit = function () {
                        //    if (cmc.data.orderType && cmc.data.program) {
                        //        createNewOrder(cmc.data.orderType, cmc.data.program.value);
                        //    }
                        // };
                    } ],
                    controllerAs : 'cmc',
                    templateUrl  : 'admin-templates/site/participant/profile/experience/orderDetails/createOrder/confirmModal.template.html',
                });
            };
            vm.preCloseCallback = () => {
                if ($rootScope.dialogCancelHasJustClosed) {
                    delete $rootScope.dialogCancelHasJustClosed;
                    return false;
                }
                return true;
            };

            /*
             * Shows the detail dialog
             */

            vm.showOrderDetails = order => {
                vm.data.selectOrder = order;
                ParticipantProfileService.getOrderDetailsById(order.id).then(
                    response => {
                        const result = {
                            cancelation : vm.data.cancelationLookup,
                            order       : {
                                ...response.data,
                                programName : vm.data.selectOrder.programName,
                            },
                            selectOrder : order,
                        };
                        // open dialog to show order detail
                        ngDialog.open({
                            className        : 'ngdialog-theme-plain custom-width-large order-details-dialog',
                            controller       : 'DetailDialogController',
                            controllerAs     : 'odd',
                            preCloseCallback : () => vm.preCloseCallback(),
                            resolve          : {
                                data       : () => result,
                                loadOrders : () => vm.loadOrders,
                            },
                            templateUrl : 'admin-templates/site/participant/profile/experience/orderDetails/detailDialog/detailDialog.template.html',
                        });
                    }).catch(() => {
                    AlertService.error('Failed to retrieve order detail by Id');
                });
            };

            function parseLink (data) {
                return {
                    first : 0,
                    last  : Math.floor(data.total / vm.filterData.pageSizeSelected),
                    next  : data.page + 1,
                    prev  : data.page - 1,
                };
            }

            /*
             * Creates the model the UI uses for orders. Also loads program names.
             * @param {object} orders
             */
            function setupOrders (orders) {
                let programIds = {};
                let models = orders.map(order => {
                    let model = {};
                    let id = order.programId;
                    // copy properties to use in the UI
                    model.id = order.id;
                    model.orderTypeCode = order.orderTypeCode;
                    model.programId = vm.data.programCodes[id] || id;
                    model.status = order.status;
                    model.subStatus = order.subStatus;
                    model.createdOn = order.createdOn;
                    model.updatedOn = order.updatedOn;
                    // store every unique programId
                    if (!vm.data.programCodes[id]) {
                        if (!programIds[id]) {
                            programIds[id] = [];
                        }
                        programIds[id].push(model);
                    }

                    return model;
                });

                // get every program name once, then cache to avoid multiple API calls
                Object.keys(programIds).forEach(key => {
                    ProgramService.get({ id : key },
                        data => {
                            let name = `${data.id } (${ data.name })`;
                            vm.data.programCodes[key] = name;
                            programIds[key].forEach(model => {
                                model.programId = name;
                                model.programName = data.name;
                            });
                        });
                });

                return models;
            }

            /*
             * Tells the server to create a new order for the user. If successful, loads orders again
             * @param {string} orderTypeName
             * @param {number} programdId
             */
            function createNewOrder (orderTypeName, programId) {
                ParticipantProfileService.createNewOrder(vm.userId, orderTypeName, programId)
                    .then(vm.loadOrders,
                        () => {
                            AlertService.error('Failed to create order');
                        });
            }

            /**
             * Loads program data for creating new orders
             */
            vm.loadPrograms = () => {
                ProgramSearch.query({
                    page : 0,
                    size : 1000,
                })
                    .then(
                        response => {
                            vm.data.allPrograms = response.data.map(
                                item => {
                                    return { name : `${item.id } - ${ item.name } (${ item.code })`, value : item.id };
                                });
                        }, error => {
                            console.error(error);
                        });
            };

            /**
             * Loads order types for creating new orders
             */
            vm.loadOrderTypes = () => {
                vm.data.allOrderTypeNames = Object.keys(OrderTypeService.getOrderTypes());
            };

            /**
             * Loads cancelation Lookup for cancel
             */
            vm.loadCancelationLookup = () => {
                ParticipantProfileService.getCancelationLookup()
                    .then(
                        response => {
                            vm.data.cancelationLookup = response.data.cancellationTypes;
                        }, error => {
                            console.error(error);
                        });
            };
            /**
             * Gets all orders for the current user from the API
             */
            vm.loadOrders = () => {
                let params = {
                    pageNumber    : vm.page,
                    pageSize      : vm.filterData.pageSizeSelected,
                    participantID : vm.userId,
                    sortColumn    : vm.filterData.sortColumn,
                    sortOrder     : vm.filterData.sortOrder,
                };
                ParticipantProfileService.getOrderDetails(params)
                    .then(response => {
                        vm.orders = setupOrders(response.data.orders);
                        vm.filterData.searchResultCount = response.data.total;
                        vm.links = parseLink(response.data);
                    }).catch(() => {
                        AlertService.error('Failed to retrieve order details for given user');
                    });
            };

            vm.$onInit = () => {
                vm.loadPrograms();
                vm.loadOrderTypes();
                vm.loadOrders();
                vm.loadCancelationLookup();
            };
        };

        return {
            bindToController : true,
            controller       : orderDetailsController,
            controllerAs     : 'odl',
            restrict         : 'E',
            scope            : {
                userId : '=',
            },
            template : template,
        };
    });
}());
