'use strict';

angular.module('acadiamasterApp')
    .controller('UnitDetailController', function ($scope, $rootScope, entity, Unit, $state, ngDialog) {
        $scope.unit = entity;
        $scope.load = function (id) {
            Unit.get({id: id}, function (result) {
                $scope.unit = result;
            });
        };
        $rootScope.$on('acadiamasterApp:unitUpdate', function (event, result) {
            $scope.unit = result;
        });
        $scope.deleteUnit = function () {
            ngDialog.openConfirm({
                template: 'admin-templates/site/unitSystem/unit/unitDelete.html',
                controller: 'UnitDeleteController',
                data: $scope.unit
            }).then(function () {
                $state.go('unit');
            });
        };
    });
