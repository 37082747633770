/**
 * Created by moustafabaiou on 4/26/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('InviteCodesService', function ($q, $http, localWeb, Upload, InviteCodesModel) {

            var baseApi = localWeb.createUrl('api/invitecode');
            var updateApi = localWeb.createUrl('api/invitecode/update');
            var searchApi = localWeb.createUrl('api/invitecode/search');
            var usageApi = localWeb.createUrl('api/invitecode/usageview');
            var createCodesFromListApi = localWeb.createUrl('api/invitecode/fromList');

            return {
                compressTagNames: function (codes) {
                    codes.forEach(function (c) {
                        var mappingList = c.inviteCodeTagMappings;
                        var expDate = c.expirationDate;
                        var metaData = c.metaData;
                        if (mappingList !== null && mappingList.length > 0) {
                            var tagList = [];
                            var tagString = '\"';
                            mappingList.forEach(function (t) {
                                tagString += t.tag.name + ", ";
                            });
                            tagString = tagString.substring(0, tagString.length - 2);
                            tagList.push(tagString += '\"');
                            c.inviteCodeTagMappings = tagList;
                        }
                        if (expDate !== null) {
                            c.expirationDate = new Date(expDate).toLocaleDateString('en-US');
                        }
                        if (metaData && metaData != null && metaData.profilePropertyValues) {
                            c.metaData = metaData.profilePropertyValues.map(function (ppv) {
                                return 'Property ' + ppv.propertyId + ' - ' + ppv.propertyValue;
                            }).join(' | ');
                        }
                        // set localizationMap, logoId and logoName to empty string otherwise it comes in csv file with no header
                        c.localizationMap = '';
                        c.logoId = '';
                        c.logoName = '';
                    });
                    const codesArray = [];
                    for(let i =0; i<codes.length; i++){
                        const inviteCode = new InviteCodesModel();
                        inviteCode.fromDto(codes[i]);
                        codesArray.push(inviteCode);
                    }
                    return codesArray;
                },
                getCodes: function (params) {
                    return $http.get(searchApi, { params: params });
                },

                getUsage: function (inviteCode, page, pageSize) {
                    var deferred = $q.defer();

                    var params = {
                        inviteCode: inviteCode,
                        page: page,
                        size: pageSize
                    };

                    var success = function (response) {
                        var usage = response.data;
                        deferred.resolve(usage);
                    };

                    var error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http.get(usageApi, { params: params }).then(success).catch(error);

                    return deferred.promise;
                },

                createNewCodes: function(data, files) {
                // send them all together for HTML5 browsers:
                return Upload.upload({
                    url: baseApi,
                    arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                    data: {
                        file: files,
                        data: JSON.stringify(data)
                    }
                });
                },
                updateCode: function(data, files) {
                    // send them all together for HTML5 browsers:
                    return Upload.upload({
                        url: updateApi,
                        arrayKey: '', // to allow files array to upload with param name file instead of file[i]
                        data: {
                            file: files,
                            data: JSON.stringify(data)
                        }
                    });
                },
                
                createNewCodesFromList: function (requestDtoList) {
                    var deferred = $q.defer();

                    var success = function (response) {
                        deferred.resolve(response);
                    };

                    var error = function (err) {
                        console.error(err);
                        deferred.reject(err);
                    };

                    $http({
                        url: createCodesFromListApi,
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        data: JSON.stringify(requestDtoList)
                    }).then(success).catch(error);

                    return deferred.promise;
                },

               
            };
        });
})();
