/**
 * Created by Danny on 2/17/2016
 */
'use strict';


angular.module('acadiamasterApp').directive('vbrDataTypeSearchModal',
    function (ParseLinks, DataTypeAdvancedSearch, DataTypeCategory, DataTypeRootCategory, DataType, $uibModal) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/dataTypeMaster/dataType/dataType-search-modal.html',
            scope: {
                options: '='
            },
            link: function ($scope) {
                $scope.search = {
                    dataTypes: [],
                    searchComplete: false,
                    dataTypeName: "",
                    dataTypeCategory: null,
                    dataTypeRootCategory: null,
                    exactMatch: false,
                    page: 0,
                    pageSize: getPageSize($scope.options),
                    searchLabel: getSearchLabel($scope.options),
                    changeLabel: getChangeLabel($scope.options),
                    links: null
                };

                $scope.searchDataType = function () {

                    initLookups($scope);

                    $uibModal.open({
                        animation: true,
                        templateUrl: 'dataType.template.searchDialog',
                        controller: ['$scope', '$uibModalInstance', function ($innerScope, $uibModalInstance) {
                            $innerScope.search = $scope.search;
                            $innerScope.data = $scope.data;
                            $innerScope.controls = $scope.controls;
                            $innerScope.selectedDataTypes = $scope.options.dataTypes;

                            $innerScope.isIdAlreadySelected = function (dataTypeId) {
                                return _.findIndex($innerScope.selectedDataTypes, {'id': dataTypeId}) != -1;
                            };
                            $innerScope.close = function (value) {
                                closeSearchWindow(value, $scope);
                            };

                            $scope.$uibModalInstance = $uibModalInstance;
                        }],
                        size: 'lg'
                    });
                };

                $scope.hasTitle = function () {
                    return ($scope.options && $scope.options.title && $scope.options.title.length > 0);
                };

                $scope.data = {
                    dataTypeRootCategories: null,
                    dataTypeCategoriesAll: [],
                    dataTypeCategoriesCurrent: []
                };

                $scope.controls = {
                    parent: $scope,
                    loadPage: function (page) {
                        return loadPage(this.parent, page);
                    },
                    searchDataTypes: function () {
                        return searchDataTypes(this.parent);
                    },
                    selectDataType: function (dataType) {
                        selectDataType(this.parent, dataType);
                    },
                    filterByRootCategory: function () {
                        return filterByRootCategory(this.parent);
                    },
                    filterIsEmpty: function () {
                        return filterIsEmpty(this.parent);
                    },
                    removeDataTypeAtIndex: function (index) {
                        $scope.options.dataTypes.splice(index, 1);
                        if ($scope.options.dataTypeChangeCallBack) {
                            $scope.options.dataTypeChangeCallBack();
                        }
                    }
                };

            }
        };

        /*************************************************************************************
         * private functions
         *************************************************************************************/
        function initLookups(scope) {
            scope.selectedDataTypes = []; // To be used when select multiple is true

            if (scope.data.dataTypeRootCategories == null || scope.data.dataTypeRootCategories.length == 0) {
                DataTypeRootCategory.query({page: scope.page, size: 1000}, function (result) {
                    scope.data.dataTypeRootCategories = result;
                });
            }

            if (scope.data.dataTypeCategoriesAll == null || scope.data.dataTypeCategoriesAll.length == 0) {
                DataTypeCategory.lookup({page: scope.page, size: 1000}, function (result) {
                    scope.data.dataTypeCategoriesAll = result;
                    angular.extend(scope.data.dataTypeCategoriesCurrent, scope.data.dataTypeCategoriesAll)
                });
            }

        }

        function closeSearchWindow(value, $scope) {
            $scope.$uibModalInstance.close();
            if (value == null) {
                return;
            }
            $scope.options.dataTypes.push(value);
            if ($scope.options.dataTypeChangeCallBack) {
                $scope.options.dataTypeChangeCallBack();
            }
        }

        function loadPage(scope, page) {
            scope.search.page = page;
            searchDataTypes(scope);
        }

        function filterByRootCategory(scope) {
            if (scope.search.dataTypeRootCategory != null) {
                filterByRootCategoryHelper(scope, scope.search.dataTypeRootCategory.id);
            }
        }

        function filterIsEmpty(scope) {
            return (scope.search.dataTypeRootCategory == null &&
            scope.search.dataTypeCategory == null &&
            (scope.search.dataTypeName == null || scope.search.dataTypeName.length == 0));
        }

        function filterByRootCategoryHelper(scope, dataTypeRootCategoryId) {
            scope.data.dataTypeCategoriesCurrent = [];
            if (scope.data.dataTypeCategoriesAll != null) {
                for (var i = 0; i < scope.data.dataTypeCategoriesAll.length; i++) {
                    var dataTypeCategory = scope.data.dataTypeCategoriesAll[i];
                    if (isInRootCategory(dataTypeCategory.dataTypeRootCategorys, dataTypeRootCategoryId)) {
                        scope.data.dataTypeCategoriesCurrent.push(dataTypeCategory)
                    }
                }
            }
        }

        function isInRootCategory(rootCategories, dataTypeRootCategoryId) {
            for (var i = 0; i < rootCategories.length; i++) {
                if (rootCategories[i].id == dataTypeRootCategoryId) {
                    return true;
                }
            }
            return false;
        }

        function selectDataType(scope, dataType) {
            addDataType(scope, dataType.id);
        }

        function searchDataTypes(scope) {
            scope.search.searchComplete = false;
            var dataTypeRootCategoryId = null;
            var dataTypeCategoryId = null;
            if (scope.search.dataTypeRootCategory)
                dataTypeRootCategoryId = scope.search.dataTypeRootCategory.id;
            if (scope.search.dataTypeCategory)
                dataTypeCategoryId = scope.search.dataTypeCategory.id;

            var searchFilter = {
                dataTypeRootCategoryId: dataTypeRootCategoryId,
                dataTypeCategoryId: dataTypeCategoryId,
                dataTypeName: scope.search.dataTypeName,
                exactMatch: scope.search.exactMatch,
                pageNum: scope.search.page,
                pageSize: scope.search.pageSize
            };
            var promise = DataTypeAdvancedSearch.query(searchFilter);
            return promise.then(function (result) {
                scope.search.dataTypes = [];
                scope.search.links = ParseLinks.parse(result.headers('link'));
                var data = result.data;
                if (result.data)
                    for (var i = 0; i < data.length; i++) {
                        scope.search.dataTypes[i] = data[i];
                    }
                scope.search.searchComplete = true;

            });
        }

        function addDataType($scope, dataTypeId) {
            DataType.get({id: dataTypeId}, function (result) {
                cleanUp($scope);
                closeSearchWindow(result, $scope);
            });
        }

        function getPageSize(options) {
            if (options == null || !_.isNumber(options.pageSize) || options.page < 5 || options.pageSize > 50) {
                return 10;
            } else {
                return options.pageSize;
            }
        }

        function getSearchLabel(options) {
            if (options == null || options.searchLabel == null || options.searchLabel.length == 0) {
                return "Search";
            } else {
                return options.searchLabel;
            }
        }

        function getChangeLabel(options) {
            if (options == null || options.changeLabel == null || options.changeLabel.length == 0) {
                return "Change";
            } else {
                return options.changeLabel;
            }
        }

        function cleanUp(scope) {
            scope.search.searchComplete = false;
            scope.search.dataTypeName = "";
            scope.search.dataTypeCategory = null;
            scope.search.dataTypeRootCategory = null;
            scope.search.dataTypes = [];
            scope.search.page = 0;
            scope.search.links = null;
        }

    });




