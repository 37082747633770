(function () {
    'use strict';

    /**
     * service to update purple background script action
     */
    angular.module('acadiamasterApp').factory('UpdatePurpleBackgroundScriptAction', function (ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        // defining some local variable to make things easier

        var fsa = FormScriptActionUtil;
        var constants = FormConstants;

        var colorBluper = fsa.colors.colorBluper;
        var colorBlack80 = fsa.colors.colorBlack80;
        var colorWhite = fsa.colors.colorWhite;
        var updatePadding = fsa.updatePadding;


        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdatePurpleBackgroundAction.inheritsFrom(ScriptActionBaseModel);

        function UpdatePurpleBackgroundAction() {
            this.name = 'UpdatePurpleBackgroundAction';
            this.ticketId = 'AC-22106';

            this.steps = [
                getStep1(),
                getStep2(),
                getStep3(),
                getStep4()
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }


        function getStep1() {
            return {
                description: 'find text field by background color "#262261" and font size 14 --> ' +
                'Padding (t:43), color: #262261, bgColor: white',
                searchFunction: function(formMode) {
                    // find text field by background color "#262261" and font size 14 -->
                    // Padding (t:43), color: #262261, bgColor: white'
                    var targets = fsa.findFieldsByType(formMode, constants.fieldsType.TEXT_LABEL);

                    targets = _.filter(targets, function (f) {
                        return fsa.isBgColorEqual(f.displayConfig, colorBluper);
                    });

                    targets = _.filter(targets, function (f) {
                        return fsa.isFontSizeEqual(f.displayConfig, 14);
                    });

                    targets = _.filter(targets, function(target) {
                        var dc = target.displayConfig;
                        return !fsa.isPaddingTopEqual(dc, 43) ||
                            !fsa.isColorEqual(dc, colorBluper);
                    });

                    return targets;
                },
                changeFunction: function(field) {
                    updatePadding(field.displayConfig, 43, null, null, null);
                    field.displayConfig.color = colorBluper;
                    field.displayConfig.bgColor = colorWhite;
                }
            };
        }


        function getStep2() {
            return {
                description: 'find Sub-field with background: #262261 and has text label as Question Label --> ' +
                'color: #262261 (font size = 14), black80 (font size = 12), bgColor: white, padding(b: 30dp)',
                    searchFunction: function(formMode) {
                    var targets = fsa.findSubFieldsByTypeHasTextLabelAbove(formMode, constants.subFieldsType.TITLE, true);
                    targets = _.filter(targets, function (f) {
                        return fsa.isBgColorEqual(f.displayConfig, colorBluper);
                    });

                    // filter out targets where action has already been applied
                    targets = _.filter(targets, function(target) {
                        var dc = target.displayConfig;

                        if (fsa.isPaddingBottomEqual(dc, 30) || fsa.isBgColorEqual(dc, colorWhite)) {
                            return false;
                        }

                        if (fsa.isFontSizeEqual(dc, 14)) {
                            return !fsa.isColorEqual(dc, colorBluper);
                        }
                        else {
                            return !fsa.isColorEqual(dc, colorBlack80);
                        }
                    });

                    return targets;
                },
                changeFunction: function(sb) {
                    fsa.updatePadding(sb.displayConfig, null, 30, null, null);

                    sb.displayConfig.color = fsa.isFontSizeEqual(sb.displayConfig, 14) ? colorBluper : colorBlack80;
                    sb.displayConfig.bgColor = colorWhite;
                }
            }
        }

        function getStep3() {
            return {
                description: 'find Sub-field with background: #262261 and without text label as Question Label --> ' +
                'color: black80, bgColor: white, padding(t: 43dp, b: 30dp)',
                searchFunction: function(formMode) {
                    var targets = fsa.findSubFieldsByTypeHasTextLabelAbove(formMode, constants.subFieldsType.TITLE, false);
                    targets = _.filter(targets, function (f) {
                        return fsa.isBgColorEqual(f.displayConfig, colorBluper);
                    });

                    // filter out targets where action has already been applied
                    targets = _.filter(targets, function(target) {
                        var dc = target.displayConfig;

                        return !fsa.isPaddingTopEqual(dc, 43) ||
                            !fsa.isPaddingBottomEqual(dc, 30) ||
                            !fsa.isBgColorEqual(dc, colorWhite) ||
                            !fsa.isColorEqual(dc, colorBlack80);
                    });

                    return targets;
                },
                changeFunction: function(sb) {
                    updatePadding(sb.displayConfig, 43, 30, null, null);
                    sb.displayConfig.color = colorBlack80;
                    sb.displayConfig.bgColor = colorWhite;
                }
            };
        }

        function getStep4() {
            return                 {
                description: 'find text field by background color "#262261" and font size 12 --> ' +
                'Padding (b:30), color: black80, bgColor: white',
                searchFunction: function(formMode) {
                    var targets = fsa.findFieldsByType(formMode, constants.fieldsType.TEXT_LABEL);

                    targets = _.filter(targets, function (f) {
                        return fsa.isBgColorEqual(f.displayConfig, colorBluper);
                    });

                    targets = _.filter(targets, function (f) {
                        return fsa.isFontSizeEqual(f.displayConfig, 12);
                    });

                    // filter out targets where action has already been applied
                    targets = _.filter(targets, function(target) {
                        var dc = target.displayConfig;
                        return !(fsa.isPaddingBottomEqual(dc, 30) &&
                            fsa.isBgColorEqual(dc, colorWhite) &&
                            fsa.isColorEqual(dc, colorBlack80));
                    });

                    return targets;
                },
                changeFunction: function(field) {
                    updatePadding(field.displayConfig, null, 30, null, null);
                    field.displayConfig.color = colorBlack80;
                    field.displayConfig.bgColor = colorWhite;
                }
            };
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdatePurpleBackgroundAction;
    });

})();
