'use strict';

angular.module('acadiamasterApp')
    .controller('ScheduleRuleExecutionAuditsController',
        function ($scope, ScheduleRuleExecutionAuditsService, ParseLinks, SCHEDULE_RULE_EXECUTION_AUDIT_CONSTANTS,
                  SEARCH_FILTER_CONSTANTS) {
        $scope.cs = SCHEDULE_RULE_EXECUTION_AUDIT_CONSTANTS;
        $scope.fields = [
            $scope.cs.FIELDS.ID,
            $scope.cs.FIELDS.BUSINESS_RULE_ID,
            $scope.cs.FIELDS.BUSINESS_RULE_NAME,
            $scope.cs.FIELDS.EXPRESSION_ID,
            $scope.cs.FIELDS.SCHEDULE_NAME,
            $scope.cs.FIELDS.PROGRAM_ID,
            $scope.cs.FIELDS.TOTAL_NUMBER_OF_PAGES,
            $scope.cs.FIELDS.FAILED_PAGE_COUNT,
            $scope.cs.FIELDS.UPDATED_ON,
            $scope.cs.FIELDS.VIEW_PAGES,
        ];

        $scope.pageSizeOptions = [
            50, 100, 200
        ];

        $scope.searchData = {
            page: 0,
            size: $scope.pageSizeOptions[0],
            orderBy: $scope.cs.FIELDS.ID.id,
            ascending: false,
            searchResultCount: 0
        };

        $scope.filteredFields = [
            SEARCH_FILTER_CONSTANTS.FIELDS.ID,
            SEARCH_FILTER_CONSTANTS.FIELDS.SCHEDULE_NAME,
            SEARCH_FILTER_CONSTANTS.FIELDS.BUSINESS_RULE_ID,
            SEARCH_FILTER_CONSTANTS.FIELDS.EXPRESSION_ID,
            SEARCH_FILTER_CONSTANTS.FIELDS.PROGRAM,
        ];
        $scope.filteredValues = {};

        $scope.setOrderBy = function (newOrderBy) {
            if ($scope.searchData.orderBy == newOrderBy) {
                $scope.searchData.ascending = !$scope.searchData.ascending;
            }
            else {
                $scope.searchData.ascending = true;
                $scope.searchData.orderBy = newOrderBy;
            }
            $scope.loadAll(0);
        };

        $scope.loadAll = function (page) {
            $scope.isLoading = true;
            $scope.searchData.page = page || 0;
            var params = {
                page: $scope.searchData.page,
                size: $scope.searchData.size,
                orderBy: $scope.searchData.orderBy,
                ascending: $scope.searchData.ascending
            };
            Object.keys($scope.filteredValues).forEach(function(key) {
                params[key] = $scope.filteredValues[key];
            });

            ScheduleRuleExecutionAuditsService.query(params, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.audits = result;
                $scope.searchData.searchResultCount = headers('X-Total-Count');
            });
            $scope.isLoading = false;
        };

    });

