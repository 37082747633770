(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * creator model used for creating the instance
     */
    app.factory('PreConditionCreatorModel', function () {

        function PreConditionCreatorModel(model, type, hasDividerAbove) {
            this.model = model;

            // Action properties
            this.type = type;

            this.hasDividerAbove = hasDividerAbove;
        }

        /**
         * create new pre condition for the current model
         * @param parentTestCase - parent test case (can not be null)
         * @param selector - selector, optional
         * @returns {*} newly created pre condition
         */
        PreConditionCreatorModel.prototype.createNew = function (parentTestCase, selector) {
            var newItem = new this.model(parentTestCase);
            parentTestCase.addPreCondition(newItem, selector);

            return newItem;
        };
        /***************************************
         * service return call
         ***************************************/
        return PreConditionCreatorModel;

    });
})();
