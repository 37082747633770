/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for form field value
     */
        .directive('formFieldConfigFieldValue', function (FormConstants, vbrCommonUtil,RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/basic.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    parentForm: '=',
                    fieldElement: '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.isInputType = $scope.fieldElement.isInputField && $scope.fieldElement.isInputField();
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.eraseDataDisplayOnly = function () {
                        return $scope.fieldValue[FormConstants.classes.classReferenceKey] ==
                            FormConstants.classes.FieldValueEmbeddedTextInputModel;
                    };
                }
            };
        });

})();

