(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for setting property value
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionSetPropertyModel',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, PropertyLoadUtil, ValueTypeUtil) {

            CorrectiveActionSetPropertyModel.inheritsFrom(CorrectiveActionBaseModel);

            /**
             * corrective action for setting property value
             * @constructor
             */
            function CorrectiveActionSetPropertyModel(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.SET_PROPERTY);

                this.propertyId = null;
                this.propertyValue = null;

                // property value use get/set function for them
                this._property = null;
            }

            CorrectiveActionSetPropertyModel.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.propertyId = dto.propertyId;
                this.propertyValue = dto.propertyValue;

                PropertyLoadUtil.loadProperty(this);
            };

            CorrectiveActionSetPropertyModel.prototype.toDto = function () {
                var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.propertyId = this.propertyId;
                dto.propertyValue = this.propertyValue;

                return dto;
            };

            CorrectiveActionSetPropertyModel.prototype.setProperty = function (property) {
                this._property = property;
                this.propertyId = property==null ? null : property.id;
            };

            CorrectiveActionSetPropertyModel.prototype.getProperty = function () {
                return this._property;
            };

            /**
             * setting the property value and convert it to a String
             * @param propertyValue - property value
             */
            CorrectiveActionSetPropertyModel.prototype.setPropertyValue = function(propertyValue) {
                this.propertyValue = ValueTypeUtil.valueConvertedToString(propertyValue);
            };

            /**
             * getting property value as it is
             * @returns {*}
             */
            CorrectiveActionSetPropertyModel.prototype.getPropertyValue = function() {
                return this.propertyValue;
            };

            /**
             * getting property value as a property typed object depending on the value type of the property
             * ie: number, boolean, string, etc
             * @returns {*}
             */
            CorrectiveActionSetPropertyModel.prototype.getPropertyValueAsProperType = function() {
                if (this._property==null || this.propertyValue==null) {
                    return null;
                }

                return ValueTypeUtil.stringValueConvertedToType(this.propertyValue, this._property.valueType);
            };

            CorrectiveActionSetPropertyModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var propertyMsg = 'Property (' + (this.propertyId == null ? 'no id' : this.propertyId) + ' | ' +
                    (this._property == null ? 'no name' :  this._property.name) + ')';

                var valueMsg = '<span class="badge"> ' + this.propertyValue + ' </span>';

                return typeMsg + 'value of ' + propertyMsg + ' --> ' + valueMsg;
            };

            CorrectiveActionSetPropertyModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.propertyId == null) {
                    this.setErrorMessage('property id is required');
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionSetPropertyModel;
        });
})();
