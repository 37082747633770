/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
(function () {
    /**
     * Module to display sql string string of a program test/condition in popup window
     */
    angular.module('acadiamasterApp').directive('displaySqlPreview', ProgramTestService => {
        return {
            link ($scope) {
                $scope.conditionValid = false;
                $scope.isReady = false;
                $scope.isParentExpression = true;
                $scope.data = '';

                init($scope);
            },
            restrict : 'E',
            scope    : {
                closeFunction  : '=',
                condition      : '=',
                expressionType : '=',
                programId      : '=',
                programTestId  : '=',
            },
            templateUrl : 'admin-templates/site/programTests/programTest/displaySqlPreview.html',
        };

        /** *************************************************************
         * private functions
         ************************************************************** */

        function init ($scope) {
            // get the by program test Id
            if ($scope.programTestId != null) {
                $scope.conditionValid = true;
            } else if ($scope.condition != null && _.isFunction($scope.condition._validateSelf)) {
                // get by condition, validate it first
                $scope.condition._validateSelf();
                $scope.conditionValid = !$scope.condition.hasError();
            } else {
                $scope.isReady = true;
                $scope.conditionValid = false;
            }

            $scope.isParentExpression = $scope.programTestId != null && $scope.condition == null;

            // call api to get the formatted sql string
            if ($scope.conditionValid) {
                ProgramTestService.getSqlQuery($scope.programTestId, $scope.condition, $scope.expressionType, $scope.programId)
                    .then(response => {
                        $scope.data = response.data;
                        $scope.isReady = true;
                    }).catch(() => {
                        $scope.conditionValid = false;
                    });
            }
        }
    });
}());
