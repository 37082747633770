/**
 * Created by Ryan Berry on 06/23/22
 *
 * Controller for selecting pages in a drop-down
 */

angular.module('acadiamasterApp')
    .controller('PageSelectorController', function (PageSelectorService, PageModel){
    const vm = this;

    vm.data = {
        isLoading: false,
        loadingError: false,
        pages: [],
        selectedPage: null,
    };

    vm.data.isLoading = true;
    vm.getAllPages = (programId) => {
        PageSelectorService.getPages(programId).then(function(response) {
            vm.data.pages = response.data?.sort(
                (a, b) => a.sequenceNo - b.sequenceNo).map(dto => {
                const pageModel = new PageModel().fromDto(dto);
                if (vm.selectedPageId && pageModel.id === vm.selectedPageId) {
                    vm.data.selectedPage = pageModel;
                }
                return pageModel;
            });
        }, function(error) {
            vm.data.loadingError = true;
            console.error('Error getting pages from API', error);
        }).finally(() => {
            vm.data.isLoading = false;
        });
    };

    vm.callbackWrapper = function(page) {
        if (vm.callback) {
            vm.callback(page);
        }
    };
    vm.$onInit = () => {
        vm.getAllPages(vm.programId);
    };
});
