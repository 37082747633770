(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for business rule action config model for User Account Update
     */
    app.factory('ActionConfigUserAccountUpdateListModel',
        function (RuleConstantsService, RuleUtilService, RuleActionBaseModelService, FormUtilService, ModelServiceConditionExpression, ModelServiceBase) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            /***************************************************************
             * private functions
             **************************************************************/


            /***************************************************************
             * Action config user account update -- main config
             ***************************************************************/
            // this needs clean up later, the javascript object is indirectly mapped to the top level
            ActionConfigUserAccountUpdateListModel.inheritsFrom(ActionConfigBaseModel);
            function ActionConfigUserAccountUpdateListModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigUserAccountUpdateListModel;
                this.type = RuleConstantsService.actionType.USER_ACCOUNT_UPDATE;

                this.actionConfig = {
                    updates: []
                };

                this.addUpdate();
            }

            ActionConfigUserAccountUpdateListModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                var _this = this;
                this.actionConfig = {
                    updates: []
                };

                var actionConfig = dto.actionConfig;

                if (actionConfig.updates != null) {
                    _.forEach(actionConfig.updates, function (u) {
                        var item = new ActionConfigUserAccountUpdateItemModel(this);
                        item.fromDto(u);
                        _this.actionConfig.updates.push(item);
                    })
                }
            };

            ActionConfigUserAccountUpdateListModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);

                var updates = [];
                _.forEach(this.actionConfig.updates, function (u) {
                    updates.push(u.toDto());
                });

                dto.actionConfig = {
                    updates: updates
                };
                dto.actionConfig[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigUserAccountUpdateListModel;

                return dto;
            };

            ActionConfigUserAccountUpdateListModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                var updates = this.actionConfig.updates;
                if (updates != null && updates.length > 0) {
                    _.forEach(updates, function (u) {
                        message += "<br>&nbsp;&nbsp;&nbsp;" + u.getDescriptionAsHtml();
                    })
                }

                return message;
            };

            ActionConfigUserAccountUpdateListModel.prototype._validateSelf = function () {
                // nothing to validate
                this.clearError();
            };

            ActionConfigUserAccountUpdateListModel.prototype.removeUpdateAt = function (index) {
                var updates = this.actionConfig.updates;
                if (index == null || index < 0 || index > updates.length - 1) {
                    return;
                }

                updates.splice(index, 1);
            };

            ActionConfigUserAccountUpdateListModel.prototype.addUpdate = function (keepItOpen) {
                var updates = this.actionConfig.updates;
                var newUpdate = new ActionConfigUserAccountUpdateItemModel(this);
                newUpdate.isOpen = keepItOpen;
                updates.push(newUpdate);
            };

            /***************************************************************
             * Action config user account update -- item config
             ***************************************************************/
            ActionConfigUserAccountUpdateItemModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
            function ActionConfigUserAccountUpdateItemModel(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                this.expression = null;
                this.targetField = null;
                this.expressionTree = null;
            }

            ActionConfigUserAccountUpdateItemModel.prototype.fromDto = function (dto) {
                this.expression = dto.expression;
                if (dto.expressionTree != null) {
                    this.expressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                    this.expressionTree.fromDto(dto.expressionTree);
                }
                this.targetField = dto.targetField;
            };

            ActionConfigUserAccountUpdateItemModel.prototype.toDto = function () {
                var dto = {};
                if (this.expressionTree != null) {
                    dto.expression = this.expression;
                    dto.expressionTree = this.expressionTree.toDto();
                }
                dto.targetField = this.targetField;
                return dto;
            };

            ActionConfigUserAccountUpdateItemModel.prototype.getDescriptionAsHtml = function () {
                return "<span class='text-info'>" + this.targetField + "</span> -> " + this.expression;
            };

            ActionConfigUserAccountUpdateItemModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.expression == null || this.expression.length === 0) {
                    this.setErrorMessage("Expression is not specified");
                } else if (this.targetField == null) {
                    this.setErrorMessage("Target field is not selected");
                }
            };

            /***************************************
             * service return call
             ***************************************/
            return ActionConfigUserAccountUpdateListModel;

        }
    );
})();