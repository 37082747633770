(function () {
    'use strict';

    /***************************************************************
     * Precondition for business rule action exist
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionRuleActionExistModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, RuleLoadUtil) {

            PreConditionActionExistModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for action exist
             * @constructor
             */
            function PreConditionActionExistModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.BUSINESS_RULE_ACTION_EXIST);

                this.ruleId = null;
                this.actionId = null;

                this._rule = null;
                this._action = null;
            }

            PreConditionActionExistModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.actionId = dto.actionId;
                this.ruleId = dto.ruleId;

                RuleLoadUtil.loadRule(this);
            };

            PreConditionActionExistModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.actionId = this.actionId;
                dto.ruleId = this.ruleId;

                return dto;
            };

            PreConditionActionExistModel.prototype.setRule = function (rule) {
                this._rule = rule;
                this.ruleId = rule==null ? null : rule.id;
                
                if (rule == null || rule.actions==null || rule.actions.length==0) {
                    this.setAction(null);
                }
                else {
                    var currentActionId = this.actionId;
                    var action = _.find(rule.actions, function(a) {
                        return a.id === currentActionId;
                    });

                    if (action==null) {
                        action = rule.actions[0];
                    }

                    this.setAction(action);
                }
            };

            PreConditionActionExistModel.prototype.getRule = function () {
                return this._rule;
            };

            PreConditionActionExistModel.prototype.setAction = function (action) {
                this._action = action;
                this.actionId = action==null ? null : action.id;
            };

            PreConditionActionExistModel.prototype.getAction = function () {
                return this._action;
            };

            PreConditionActionExistModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var actionMsg = 'Action (' + (this.actionId == null ? 'no id' : this.actionId) + ' | ' +
                    (this._action == null ? 'no name' :  this._action.name) + ')';

                var ruleMsg = 'Rule (' + (this.ruleId == null ? 'no id' : this.ruleId) + ' | ' +
                    (this._rule == null ? 'no name' :  this._rule.name) + ')';

                return typeMsg + actionMsg + ' of ' + ruleMsg +  ' should exist ';
            };

            PreConditionActionExistModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.actionId == null) {
                    this.setErrorMessage('action id is required');
                }

                if (this.ruleId == null) {
                    this.setErrorMessage('rule id is required');
                }
            };


            return PreConditionActionExistModel;
        });
})();
