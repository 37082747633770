/* eslint-disable eqeqeq */
angular.module('acadiamasterApp')
    .controller('RuleAuditController', function (ngDialog, BusinessRuleExecutionAudit, ParseLinks,
        RuleConstantsService) {
        let vm = this;
        vm.hasUserId = vm.userId != undefined;
        vm.searchResultCount = 0;
        vm.orderByChoices = {
            id: 'id',
            name: 'name',
            programId: 'program.id',
            ruleExecutionStatus: 'ruleExecutionStatus',
            ruleId: 'businessRule.id',
            triggerId: 'triggerCondition.id',
            triggerTimestamp: 'triggerTimestamp',
            userId: 'user.id',
            workflowId: 'workflow.id',
        };

        vm.searchData = {
            ascending : false,
            getOrder  : function () {
                return this.ascending;
            },
            getOrderSort : function () {
                return this.orderBy;
            },
            orderBy    : vm.orderByChoices.id,
            setOrderBy : function (newOrderBy) {
                if (this.orderBy == newOrderBy) {
                    this.ascending = !this.ascending;
                } else {
                    this.ascending = true;
                    this.orderBy = newOrderBy;
                }
                vm.loadAll();
            },
        };
        vm.cs = RuleConstantsService;
        vm.ruleAudits = [];
        vm.page = 0;
        vm.size = 50;
        vm.sizeOptions = [ 10, 25, 50, 100 ];
        vm.selectedRule = null;
        vm.loadAll = function (page) {
            if (page == null) {
                vm.page = 0;
            }
            vm.page = page;
            const params = {
                ascending: vm.searchData.getOrder(),
                id: vm.searchData.id,
                orderBy: vm.searchData.getOrderSort(),
                page: vm.page,
                programId: vm.searchData.programId,
                ruleExecutionStatus: vm.searchData.ruleExecutionStatus,
                ruleId: vm.searchData.ruleId,
                scheduleRuleExecutionPageAuditId: vm.pageAuditId,
                size: vm.size,
                triggerType: vm.searchData.triggerType,
                userId: vm.userId ? vm.userId : vm.searchData.userId,
                workFlowId: vm.searchData.workflowId,
            };
            BusinessRuleExecutionAudit.query(params, (result, headers) => {
                vm.links = ParseLinks.parse(headers('link'));
                vm.ruleAudits = result;
                vm.searchData.searchResultCount = headers('X-Total-Count');
                vm.searchResultCount = vm.searchData.searchResultCount;
            });
        };
        vm.showDetailDialog = function (id) {
            ngDialog.open({
                className  : 'ngdialog-theme-plain custom-width-medium',
                controller : 'RuleAuditDetailController',
                resolve    : {
                    entity : [ 'BusinessRuleExecutionAudit', function (BusinessRuleExecutionAudit) {
                        return BusinessRuleExecutionAudit.get({ id : id });
                    } ],
                },
                templateUrl : 'admin-templates/site/businessRule/ruleAudit/ruleAudit-detail.html',
            });
        };
        vm.loadPage = function (page) {
            vm.loadAll(page);
        };

        vm.$onInit = () => {
            vm.loadAll();
        };
    });
