/**
 * form tree options service for form edit GUI
 */
(function () {
    'use strict';
    angular.module('acadiamasterApp').factory('FormTreeOptionsService', function (FormModelServiceStateRule, VbrTreeUtilService,
                                                                                  FormModelServiceFormMode, FormModelServicePage, FormConstants) {

        var nodeTypeMap = createNodeTypeMap();

        return {
            accept: accept,
            beforeDrag: beforeDrag,
            nodeAdded: nodeAdded,
            removed: nodeRemoved,
            dropped: dropped
        };

        /******************************************************
         * private functions after this
         ******************************************************/

        function getFormMode(modelValue) {
            if (modelValue == null) {
                return null;
            }

            if (modelValue instanceof FormModelServiceFormMode.FormModeModel) {
                // current object is form mode
                return modelValue.nodeType.text;
            }

            return getFormMode(modelValue._parent);
        }

        function getParentModel(nodeScope) {
            if (nodeScope == null || nodeScope.$modelValue == null) {
                return null;
            }

            var modelValue = nodeScope.$modelValue;

            if (modelValue instanceof FormModelServicePage.FormPageModel) {
                return modelValue._parent.pages;
            }
            if (modelValue instanceof FormModelServiceStateRule.FormStateRuleModel) {
                return modelValue._parent.stateRules;
            }
            else {
                return modelValue._parent;
            }
        }

        function getCurrentModel(nodeScope) {
            if (nodeScope == null || nodeScope.$modelValue == null) {
                return null;
            }

            return nodeScope.$modelValue;
        }

        /**
         * before drag will be called before user can move a node, it should return true if we can move it, and return false/null otherwise
         * @param sourceNodeScope
         * @returns {boolean}
         */
        function beforeDrag(sourceNodeScope) {
            var sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
            return nodeTypeMap[sourceType].typesCanMoveUnder != null;
        }


        /**
         * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
         * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
         * @param sourceNodeScope
         * @param destNodeScope
         * @returns {boolean}
         */
        function accept(sourceNodeScope, destNodeScope) {
            var sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
            var destType = VbrTreeUtilService.getParentTypeFromNodeScope(destNodeScope);
            var destNodeModel = getCurrentModel(destNodeScope);
            var sourceModel = getCurrentModel(sourceNodeScope);

            // do not delete the commented out lines, this part of the code is often broken when changes are
            // made, and those console statements are useful when that happens.  Those calls happen at a
            // very high frequency, debugger with break point is not suitable for that

            if (nodeTypeMap[sourceType].sameParentOnly) {
                var sourceParentModel = getParentModel(sourceNodeScope);
                // console.log('accept, same parent only, source type =' + sourceType + ", source parent model : ", sourceParentModel,
                //     ", destNodeModel : ", destNodeModel, 'sourceModel : ', sourceModel);
                return sourceParentModel == destNodeModel || (_.isArray(destNodeModel) && destNodeModel.indexOf(sourceModel) != -1);
            }

            if (nodeTypeMap[sourceType].typesCanMoveUnder.indexOf(destType) != -1) {
                var destinationMode = getFormMode(destNodeScope.$nodeScope.$modelValue);
                var sourceMode = getFormMode(sourceNodeScope.$modelValue);

                // console.log('accept, not same parent only, type matched for can move under, destinationMode =', destinationMode + ", sourceMode : ", sourceMode);
                return sourceMode == destinationMode;
            }
            else {
                // console.log('** not accept, not same parent only, type misMatch for can move under, sourceType =',
                //     sourceType + ", destType : ", destType);
                return false;
            }
        }

        /**
         * event dropped is called after node moves it's position
         * @param event
         */
        function dropped(event) {
            var sourceModel = event.source.nodeScope.$modelValue;

            if (event.dest.nodesScope.$nodeScope == null) { // this is a top level node
                return;
            }

            var destModel = event.dest.nodesScope.$nodeScope.$modelValue;

            if (sourceModel._parent != destModel) {
                if (sourceModel.nodeType == FormConstants.nodeType.FIELD) {
                    sourceModel._parent.removeField(sourceModel, null, null);
                }

                sourceModel._parent = destModel;
            }

            // todo: add this call when validation is implemented
            //findTreeRoot(node).validate();
        }

        function nodeAdded(node) {
            return node;
        }

        function nodeRemoved(node) {
            // do nothing there
        }

        function createNodeTypeMap() {
            var map = {};
            map[FormConstants.nodeType.SUB_FIELD] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.SUB_FIELD, null);
            map[FormConstants.nodeType.FIELD] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.FIELD, [FormConstants.nodeType.COMPONENT]);
            map[FormConstants.nodeType.COMPONENT] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.COMPONENT, [FormConstants.nodeType.SECTION]);
            map[FormConstants.nodeType.SECTION] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.SECTION, [FormConstants.nodeType.PAGE], true);
            map[FormConstants.nodeType.PAGE] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.PAGE, [
                FormConstants.nodeType.MODE.EDIT.text, FormConstants.nodeType.MODE.VIEW_ENTRY.text, FormConstants.nodeType.MODE.PROMPT.text], true);

            map[FormConstants.nodeType.MODE.EDIT.text] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.MODE.EDIT.text, null);
            map[FormConstants.nodeType.MODE.VIEW_ENTRY.text] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.MODE.VIEW_ENTRY.text, null);
            map[FormConstants.nodeType.MODE.PROMPT.text] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.MODE.PROMPT.text, null);

            map[FormConstants.nodeType.RULE_ACTION] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.RULE_ACTION, [FormConstants.nodeType.RULE_CONDITION_WITH_ACTION]);
            map[FormConstants.nodeType.RULE_CONDITION_WITH_ACTION] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.RULE_CONDITION_WITH_ACTION, [FormConstants.nodeType.RULE_CONDITION_LIST]);
            map[FormConstants.nodeType.RULE_CONDITION_LIST] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.RULE_CONDITION_LIST, null);

            map[FormConstants.nodeType.RULE_TRIGGER] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.RULE_TRIGGER, [FormConstants.nodeType.RULE_TRIGGER_LIST]);
            map[FormConstants.nodeType.RULE_TRIGGER_LIST] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.RULE_TRIGGER_LIST, null);

            map[FormConstants.nodeType.STATE_RULE] = VbrTreeUtilService.createNodeTypeItem(FormConstants.nodeType.STATE_RULE, [
                FormConstants.nodeType.MODE.EDIT.text, FormConstants.nodeType.MODE.VIEW_ENTRY.text, FormConstants.nodeType.MODE.PROMPT.text], true);

            return map;
        }
    });
})();

