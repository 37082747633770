(function () {
    angular.module('acadiamasterApp')
        .factory('ProgramAppSettings', [ '$http', 'localWeb', 'Upload', function ($http, localWeb, Upload) {
            return {
                downloadZip : function (uuid) {
                    return $http({
                        method       : 'GET',
                        responseType : 'arraybuffer',
                        url          : localWeb.createUrl(`/filemanagement/v1/file/${uuid}`),
                    });
                },

                getAppSettingsDetails : function (programId) {
                    return $http.get(localWeb.createUrl(`api/programappsettings/${programId}`));
                },
                getValidationFeedback : function (id, platform) {
                    return $http({
                        method : 'GET',
                        url    : localWeb.createUrl(`api/getMobileAssetFeedBack/${id}?platform=${platform}`),
                    });
                },

                setAppSettingsDetails : function (data) {
                    return $http.post(localWeb.createUrl('api/programappsettings/'), data);
                },

                uploadZip : function (zipFile, id, platform) {
                    return Upload.upload({
                        data : {
                            file     : zipFile,
                            platform : platform,
                        },
                        url : localWeb.createUrl(`api/programappsettings/mobileAssetUpload/${id}`),
                    });
                },

                validateZip : function (zipFile, platform) {
                    return Upload.upload({
                        data : {
                            file     : zipFile,
                            platform : platform,
                        },
                        url : localWeb.createUrl('api/programappsettings/validatebrandingassets/'),
                    });
                },

            };
        } ]);
}());
