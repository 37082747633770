const app = angular.module('acadiamasterApp');

/**
 * caching service used for various places where you need to select image reference by key
 */
app.factory('ImageReferenceCacheService', function (ImageReferenceService, $q) {
    // private variables that holds various states that we don't want to give direct access

    /** a list of image reference already loaded **/
    let _imageReferenceList = null;

    /**
     * reset function used remove cached container for a program
     */
    function reset() {
        _imageReferenceList = null;
    }

    /**
     * load image reference from server or from local copy if forceReload = false and we already have a cached version
     * @param forceReload set to true if we always want to load the full list from server regardless if there is already a
     *        cached version of the data
     * @return a promise that on success will provide a list of image reference
     */
    function loadImageReferences(forceReload) {
        const deferred = $q.defer();

        if (forceReload) {
            reset();
        }

        if (_imageReferenceList!=null && _imageReferenceList._length > 0) {
            deferred.resolve(_imageReferenceList);
        }
        else {
            ImageReferenceService.getImageReference().then(function(resp) {
                _imageReferenceList = resp.data;
                deferred.resolve(_imageReferenceList);
            }, function (error) {
                console.error('error occurred trying to load image reference list: ', error);
                deferred.reject('unable to load containers from server with image reference');
            });
        }

        return deferred.promise;
    }

    /**************************************************************
     * all the public accessible programs for the service
     **************************************************************/
    return {
        loadImageReferences,
    };
});
