// todo: @Deprecated, remove later
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for IsSubscribedToDistributionCondition model
     */
    app.factory('IsSubscribedToDistributionConditionModel', function (QObjectModel, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        IsSubscribedToDistributionConditionModel.inheritsFrom(QObjectModel);

        function IsSubscribedToDistributionConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.IS_SUBSCRIBED_TO_DISTRIBUTION);

            //initialized the variables
            this.programDistributionId = null;
            this.active = true;
        }

        /**
         * convert the current UI model to dto format
         */
        IsSubscribedToDistributionConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.programDistributionId = this.programDistributionId;
            dto.active = this.active;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any format you have on the current object
         * @param dto
         */
        IsSubscribedToDistributionConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.programDistributionId = dto.programDistributionId;
            this.active = dto.active;
        };

        IsSubscribedToDistributionConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            return baseMsg + '<span class="text-warning">Deprecated, do not use</span>';
        };

        IsSubscribedToDistributionConditionModel.prototype._validateSelf = function () {
            this.clearError();
        };

        /***************************************
         * service return call
         ***************************************/
        return IsSubscribedToDistributionConditionModel;

    });
})();
