/**
 * Created by Jamie Nola on 04/18/2019
 */

(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for OrderTrackingStatusConditionModel model
     */
    app.factory('OrderTrackingStatusConditionModel', function (QObjectModel,
        ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        OrderTrackingStatusConditionModel.inheritsFrom(QObjectModel);

        function OrderTrackingStatusConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.ORDER_TRACKING_STATUS);

            //initialized the variables
            this.orderType = null;
            this.trackingType = null;
            this.trackingStatusList = [];
            this.comparator = ProgramTestConstantsService.operators.EQ;
        }

        /**
         * convert the current UI model to dto format
         */
        OrderTrackingStatusConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.orderType = this.orderType;
            dto.trackingType = this.trackingType;

            if (this.trackingStatusList == null) {
                dto.trackingStatusList = null;
            }
            else {
                dto.trackingStatusList = [];
                _.forEach(this.trackingStatusList, function (s) {
                    if (s && s.name) {
                        dto.trackingStatusList.push(s.name);
                    }
                });
            }

            dto.comparator = this.comparator == null ? null : this.comparator.name;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        OrderTrackingStatusConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.orderType = dto.orderType;
            this.trackingType = dto.trackingType;

            if (dto.trackingStatusList == null) {
                this.trackingStatusList = null;
            }
            else {
                this.trackingStatusList = dto.trackingStatusList.map(function (status) {
                    return { name: status, text: status };
                });
            }

            this.setComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator));
        };

        OrderTrackingStatusConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        OrderTrackingStatusConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.trackingStatusList = null;
            }
            else if (this.trackingStatusList == null) {
                this.trackingStatusList = [];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN
                && this.trackingStatusList.length > 1) {
                // just use the first value
                this.trackingStatusList = [this.trackingStatusList[0]];
            }
        };

        OrderTrackingStatusConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            if (this.orderType) {
                baseMsg += ('Order Type: ' + this.orderType + ', ');
            }

            if (this.trackingType) {
                baseMsg += ('Tracking Type: ', this.trackingType + ', ');
            }

            var statusMsg = 'Tracking Status';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : getTrackingStatusListAsString(this.trackingStatusList);

            return baseMsg + ' ' + statusMsg + comparatorMsg + valueMsg;
        };

        OrderTrackingStatusConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.orderType == null) {
                this.setErrorMessage('order type is required');
            }

            if (this.trackingType == null) {
                this.setErrorMessage('tracking type is required');
            }

            if (this.comparator == null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.trackingStatusList == null || this.trackingStatusList.length == 0)) {
                this.setErrorMessage('order status value is required except when comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        function getTrackingStatusListAsString(trackingStatusList) {
            if (trackingStatusList == null) {
                return 'null';
            }
            return _.map(trackingStatusList, function (status) {
                return status.name;
            }).join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return OrderTrackingStatusConditionModel;

    });
})();
