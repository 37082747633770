/**
 * simple operation status badge directive, used to display the status as a colored badge
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('operationStatusBadge', function (
        OperationStatusService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/tools/status/operationStatusBadge.html',
            scope: {
                status: '='
            },
            link: function ($scope) {
                $scope.OperationStatusService = OperationStatusService;
            }
        };
    });

})();

