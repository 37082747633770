/**
 * Created by Jason Cao on 12/13/2019
 *
 * directive for configuring an state with
 * 1. hideWhenEmpty flag
 */
angular.module('acadiamasterApp').directive(
    'containerDisplayConfigStateHideWhenEmpty',
    (LocaleConstantsService) => ({
        restrict: 'E',
        scope: {
            // @Required - display config state or the display config object itself
            displayConfigState: '=',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
            + 'config/containerDisplayConfig/' +
            'containerDisplayConfigStateHideWhenEmpty.html'
    }));
