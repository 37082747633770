/**
 * Created by Jamie Nola on 03/29/2019
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('formatExpression', function (ExpressionParserService) {
            return function (expression, usedBy) {
                var model = ExpressionParserService.parse(expression, usedBy);
                var formatted = expression;

                if (model) {
                    if (model.hasError()) {
                        return ('<div class="white-space-pre-wrap code-wrapper"><code class="plain">'
                            + formatted
                            + '</code></div><br><div class="text-danger">'
                            + 'Error: '
                            + model.getError()
                            + '</div>');
                    }
                    formatted = model.toFormattedString(0, false);
                }

                return ('<div class="white-space-pre code-wrapper"><code class="plain">'
                    + formatted
                    + '</code></div>');
            };
        });
})();
