/**
 * status type enum for operation status
 */
angular.module('acadiamasterApp').factory('OperationStatusService', function(OperationStatusConstant) {
    return {
        isFailed,
        isCompletedSuccessfully,
        isRunning,
        isCancelled,
    };

    /***************************************************************
     * interface functions
     ***************************************************************/

    /**
     * use the status to check if the operation has failed
     * @param status - status of the test
     * @return {boolean} - true if test has failed, false otherwise
     */
    function isFailed(status) {
        return status==OperationStatusConstant.FAILED;
    }

    /**
     * check if the status is considered as completed
     * @param status
     * @returns {boolean}
     */
    function isCompletedSuccessfully(status) {
        return status==OperationStatusConstant.COMPLETED;
    }

    function isRunning(status) {
        return status==OperationStatusConstant.RUNNING ||
            status==OperationStatusConstant.STARTING;
    }

    function isCancelled(status) {
        return status == OperationStatusConstant.STOPPED;
    }

    /***************************************************************
     * private functions
     ***************************************************************/

});
