(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('taskDetailedView', function (UnauthenticatedTaskService) {

        function getTask(id, $scope) {
            UnauthenticatedTaskService.getTaskById(id).then((response) => {
                $scope.task = response.data;
                $scope.task.expirationDate = $scope.task.expirationTimestamp ?
                    new Date($scope.task.expirationTimestamp) : null;
            }, (error) => {
                console.error('Error loading task by id : ' + id, error);
            }).finally(() => {
                $scope.ready = true;
            });
        }

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/unauthenticatedTask/detailedView/task.view.template.html',
            scope: {
                taskId: '=',
            },
            link: function ($scope) {
                $scope.ready = false;
                $scope.task = null;

                $scope.booleanToText = (booleanValue) => {
                    if (booleanValue == null) {
                        return 'n/a';
                    }

                    return booleanValue ? 'Yes' : 'No';
                };

                $scope.hasExpression = (expression) => {
                    return expression != null && expression.trim().length > 0;
                };

                getTask($scope.taskId, $scope);
            }
        };
    });
})();
