import template from './businessRuleListWithSearch.html';
'use strict';

(function () {

    angular.module('acadiamasterApp')
        .directive('businessRuleListWithSearch', function ($window, BusinessRule, ParseLinks,
            vbrCommonUtil, ngDialog, RuleConstantsService, FlowChartKmapService, RuleToKmapService,
            SEARCH_FILTER_CONSTANTS, CONFIG, $state, SessionStorageService, AlertService) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    onSelectRule: '&',    // callback on Rule selection.
                    isReadOnly: '=?',
                    loadAllCallback: '=loadAll'     // took empty function.
                },
                controller: function ($scope) {
                    $scope.CONFIG = CONFIG;
                    $scope.cs = RuleConstantsService;
                    $scope.businessRules = [];
                    $scope.selectedRuleId = null;
                    $scope.page = 0;

                    $scope.fields = [
                        $scope.cs.FIELDS.ID,
                        $scope.cs.FIELDS.PROGRAM_CODE,
                        $scope.cs.FIELDS.NAME,
                        $scope.cs.FIELDS.RULE_TYPE,
                        $scope.cs.FIELDS.UPDATED_ON,
                        $scope.cs.FIELDS.ACTIONS,
                        $scope.cs.FIELDS.RULES_ENABLE
                    ];

                    $scope.filteredFields = [
                        SEARCH_FILTER_CONSTANTS.FIELDS.TRIGGER_TYPE,
                        SEARCH_FILTER_CONSTANTS.FIELDS.ACTION_TYPE,
                        SEARCH_FILTER_CONSTANTS.FIELDS.PROGRAM,
                        SEARCH_FILTER_CONSTANTS.FIELDS.EXPRESSIONS_HIDDEN
                    ];
                    $scope.filteredValues = {};

                    $scope.searchData = {
                        ascending: false,
                        orderBy: $scope.cs.FIELDS.UPDATED_ON.id,
                        searchString: '',
                        size: 20
                    };

                    $scope.setOrderBy = function (newOrderBy) {
                        if ($scope.searchData.orderBy === newOrderBy) {
                            $scope.searchData.ascending = !$scope.searchData.ascending;
                        }
                        else {
                            $scope.searchData.ascending = true;
                            $scope.searchData.orderBy = newOrderBy;
                        }
                        $scope.loadPage(0);
                    };

                    $scope.selectRule = function (businessRule) {
                        $scope.selectedRuleId = businessRule.id;
                        $scope.onSelectRule({ businessRule: businessRule });
                    };

                    $scope.loadPage = function (page) {
                        if(page !== undefined) {
                            $scope.page = page;
                        }
                        $scope.loadAll();
                    };

                    $scope.isRulePopoverOpen = {};

                    $scope.closeRulePopover = function () {
                        $scope.isRulePopoverOpen = {};
                    };

                    $scope.toggleRulePopup = function (businessRule) {
                        if (!businessRule.programId) {
                            ngDialog
                                .openConfirm({
                                    controller : 'ActivateOrphanRuleController',
                                    data       : businessRule,
                                    template   : 'admin-templates/site/businessRule/rule/activateOrphanRule.html',
                                })
                                .then(response => {
                                    $scope.refresh();
                                    AlertService.success(`Rule ${businessRule.id} Successfully Updated now for ${businessRule.programCode} Program.`);
                                });
                        }  else {
                            $scope.closeRulePopover(); // Close already opened popover
                            if(!$scope.isRulePopoverOpen[businessRule.id]){
                                $scope.isRulePopoverOpen[businessRule.id] = true; // Open the popover for the clicked row
                            }
                        }
                    };

                    $scope.activateDeactivateRule = function (businessRule) {
                        $scope.closeRulePopover();
                        BusinessRule.activateDeactivate(businessRule.id, !businessRule.enabled).then(
                            response => {
                                $scope.refresh();
                                AlertService.success(
                                    `Rule ${businessRule.id} Successfully Updated now for ${businessRule.programCode} Program.`,
                                );
                            },
                            error => {
                                AlertService.error('Error updating rule status', error);
                            },
                        );
                    };

                    $scope.deletePopup = function (id) {
                        var _scope = $scope;

                        ngDialog.openConfirm({
                            template: '<delete-business-rule rule-id="ruleId" on-success-callback="onSuccess"></delete-business-rule>',
                            plain: true,
                            scope: $scope,
                            controller: ['$scope', function ($scope) {
                                $scope.ruleId = id;
                                $scope.onSuccess = function () {
                                    _scope.refresh();
                                };
                            }]
                        }).then(function () {
                            $scope.refresh();
                        });
                    };

                    $scope.refresh = function () {
                        $scope.loadAll();
                        $scope.clear();
                    };

                    $scope.clear = function () {
                        $scope.businessRule = {
                            enabled: null,
                            name: null,
                            isActive: null,
                            isGlobal: null,
                            ruleType: null,
                            isArchivable: null,
                            id: null,
                        };
                    };

                    $scope.download = function (ruleId) {
                        var ruleDetail = BusinessRule.get({
                            id: ruleId
                        });
                        ruleDetail.$promise.then(function (data) {
                            // create file name
                            var fileName = 'rule_' + ruleId
                                + '.json';
                            // download as file in browser
                            vbrCommonUtil.downloadAsFile(fileName, data);
                        });
                    };

                    $scope.searchAndLoadAll = function () {
                        if ($scope.searchData.searchString) {
                            $scope.page = 0;
                        }
                        $scope.loadAll();
                    };

                    $scope.loadAll = function () {
                        var params = {
                            page: $scope.page,
                            size: $scope.searchData.size,
                            ascending: $scope.searchData.ascending,
                            orderBy: $scope.searchData.orderBy,
                            searchString: $scope.searchData.searchString || null,
                        };

                        // cache search params
                        SessionStorageService.setItem(
                            $state.current.name,
                            {
                                page: $scope.page,
                                searchData: $scope.searchData
                            },
                            $state.current.name
                        );

                        Object.keys($scope.filteredValues).forEach(function(key) {
                            params[key] = $scope.filteredValues[key];
                        });
                        BusinessRule.query(params, function (result, headers) {
                            $scope.links = ParseLinks.parse(headers('link'));
                            $scope.businessRules = result;
                            $scope.searchData.searchResultCount = headers('X-Total-Count');
                        });
                    };

                    $scope.programSelectionForProgramRule = function () {
                        ngDialog.openConfirm({
                            template: 'admin-templates/site/common/directive/programSelectionPopup.html',
                            controller: 'ProgramSelectionPopupController',
                            data: {
                                description: 'Choose the program for which you want to create a program specific business rule'
                            }
                        }).then(function (programId) {
                            $state.go('businessRule.newProgramRule', {'programId': programId});
                        });
                    };

                    $scope.programSelectionForScheduleRule = function () {
                        ngDialog.openConfirm({
                            template: 'admin-templates/site/common/directive/programSelectionPopup.html',
                            controller: 'ProgramSelectionPopupController',
                            data: {
                                description: 'Choose the program for which you want to create a program specific business rule'
                            }
                        }).then(function (programId) {
                            $state.go('businessRule.newScheduleRule', {'programId': programId});
                        });
                    };

                    $scope.openFlowChartWindow = function(ruleId) {
                        var ruleDetail = BusinessRule.get({
                            id: ruleId
                        });
                        ruleDetail.$promise.then(function (data) {
                            var url = '/assets/kmap/ruleMap/rule.html';
                            var newWindow = $window.open(url, 'rule_flow_chart_window');
                            var model = RuleToKmapService.transform(data);
                            FlowChartKmapService.loadRuleModel(newWindow, model, 'TB', null, null);
                        });
                    };

                    function init() {
                        // load $scope.data from session storage service
                        var data = SessionStorageService.getItem($state.current.name);
                        if(data) {
                            $scope.page = data.page;
                            $scope.searchData = data.searchData;
                        }

                        if ($scope.loadAllCallback) {
                            $scope.loadAllCallback = $scope.loadAll;
                        }
                    }

                    init();
                }
            }
        });
})();
