(function () {
    'use strict';

    /**
     * script action to update return of result answers
     */
    angular.module('acadiamasterApp').factory('UpdateReturnOfResultAlignmentActionScript', function (ScriptActionBaseModel,
                                                                                            FormScriptActionUtil,
                                                                                            FormConstants) {

        // defining some local variable to make things easier

        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateReturnOfResultAlignmentActionScript.inheritsFrom(ScriptActionBaseModel);

        function UpdateReturnOfResultAlignmentActionScript() {
            this.name = 'Update Return of Result Question And Answer Fields with designs';
            this.ticketId = 'AC-75086';

            this.steps = [
                createStep1(),
                createStep2(),
                createStep3()
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /**
         * function for creating the first step of the action
         * @returns {{description: string, searchFunction: searchFunction, changeFunction: changeFunction}}
         */
        function createStep3() {
            function applyFieldChange(field) {
                if (field==null || field.fieldValue == null) {
                    return false; // null object can't be updated
                }
                field.fieldValue.includeDividerAbove = false;
            }
            var description = 'find all "TEXT_LABEL_REFERENCE" type field, and apply the following change --> <br>' +
                "<pre class='code'>" +
                "  * Set includeDividerAbove to false\n" +
                '</pre>';

            function fieldMatch(field) {
                if (field==null || field.fieldValue == null) {
                    return false; // null object is not a match
                }
                if (field.fieldValue.includeDividerAbove) {
                    return false;
                }
                // everything matches, return true
                return true;
            }
            var searchFunction = function(formMode) {
                // find all the view text/number input field
                var targets = fsa.findFieldsByType(formMode, constants.fieldsType.TEXT_LABEL_REFERENCE);

                // filter out targets that has already performed the action
                targets = _.filter(targets, function(field) {
                    return !fieldMatch(field);
                });

                return targets;
            };

            var changeFunction = function(field) {
                applyFieldChange(field);
            };

            return {
                description: description,
                searchFunction : searchFunction,
                changeFunction: changeFunction
            };
        }

        /**
         * function for creating the first step of the action
         * @returns {{description: string, searchFunction: searchFunction, changeFunction: changeFunction}}
         */
        function createStep1() {
            function applyFieldChange(field) {
                if (field==null || field.fieldValue == null) {
                    return false; // null object can't be updated
                }
                field.fieldValue.includeDividerAbove = false;
            }
            /**
             * check if the 2nd title already matches what we want to do for this action
             * @param secondTitle - sub field for 2nd title
             * @returns {boolean} - true if it matches, false otherwise
             */
            function secondTitleMatch(secondTitle) {
                if (secondTitle==null || secondTitle.displayConfig == null) {
                    return false; // null object is not a match
                }

                var dc = secondTitle.displayConfig;
                // check padding (t, b, l, r) = (0, 10, 10, 0)
                if (!fsa.isPaddingTopEqual(dc, 0) || !fsa.isPaddingBottomEqual(dc, 16)
                    || !fsa.isPaddingLeftEqual(dc, 10) || !fsa.isPaddingRightEqual(dc, 10)) {
                    return false;
                }
                // everything matches, return true
                return true;
            }

            function applySecondTitleChange(secondTitle) {
                if (secondTitle == null || secondTitle.displayConfig == null) {
                    return false; // null object can't be updated
                }
                var dc = secondTitle.displayConfig;
                // check padding (t, b, l, r) = (0, 10, 10, 0)
                fsa.updatePadding(dc, 0, 16, 10, 10);
            }

            var description = 'find all "View Text Or Number Input" type field, and apply the following change --> <br>' +
                "<pre class='code'>Second Title sub-field display settings\n" +
                "  * Set includeDividerAbove to false\n" +
                "  * Set secondTitle padding, left to 10 and bottom to 16 and top and right to 10\n" +
                '</pre>';

            function fieldMatch(field) {
                if (field==null || field.fieldValue == null) {
                    return false; // null object is not a match
                }
                if (field.fieldValue.includeDividerAbove) {
                    return false;
                }
                // everything matches, return true
                return true;
            }
            var searchFunction = function(formMode) {
                // find all the view text/number input field
                var targets = fsa.findFieldsByType(formMode, constants.fieldsType.VIEW_TEXT_OR_NUMBER_INPUT);

                // filter out targets that has already performed the action
                targets = _.filter(targets, function(field) {
                    var secondTitle = field.subFields[2];
                    return !fieldMatch(field) || !secondTitleMatch(secondTitle);
                });

                return targets;
            };

            var changeFunction = function(field) {
                var secondTitle = field.subFields[2];
                applyFieldChange(field);
                applySecondTitleChange(secondTitle);
            };

            return {
                description: description,
                searchFunction : searchFunction,
                changeFunction: changeFunction
            };
        }

        /**
         * step 2, find reference and move the id around
         */
        function createStep2() {

            function fieldMatch(field) {
                if (field==null || field.displayConfig == null) {
                    return false; // null object is not a match
                }

                var dc = field.displayConfig;
                // check padding (t, b, l, r) = (0, 10, 10, 0)
                if (!fsa.isPaddingTopEqual(dc, 0) || !fsa.isPaddingBottomEqual(dc, 16)
                    || !fsa.isPaddingLeftEqual(dc, 10) || !fsa.isPaddingRightEqual(dc, 10)) {
                    return false;
                }
                // everything matches, return true
                return true;
            }
            var searchFunction = function(formMode) {
                // find all the view text/number input field
                var targets = fsa.findFieldsByType(formMode, constants.fieldsType.TEXT_LABEL);

                // filter out targets that has already performed the action
                targets = _.filter(targets, function(field) {
                    return !fieldMatch(field);
                });

                return targets;
            };
            var changeFunction = function(field) {
                if (field == null || field.displayConfig == null) {
                    return false; // null object can't be updated
                }
                var dc = field.displayConfig;
                // check padding (t, b, l, r) = (0, 10, 10, 0)
                fsa.updatePadding(dc, 0, 16, 10, 10);
            };

            var description = 'find all "Text Label" type field and apply the following change --> <br>' +
                "<pre class='code'>" +
                " * set padding to t:0 l:10 b:16 r:10\n" +
                '</pre>';

            return {
                description: description,
                searchFunction : searchFunction,
                changeFunction: changeFunction
            };

        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateReturnOfResultAlignmentActionScript;
    });

})();
