// eslint-disable-next-line no-use-before-define
/**
 * mobileBuildCreationController
 * Triggers android and ios jenkins job in https://labyrinth.vibrenthealth.com/job/On-Demand-Mobile-Build/
 * @param {any} $scope Scope form data
 * @param {MobileBuildCreationService} MobileBuildCreationService Service to redirect to jenkins url.
 */
'use strict';
angular.module('acadiamasterApp')
    .controller('MobileBuildCreationController', ($location, $scope, MobileBuildCreationService, $window) => {

        $scope.buildIOS = false;
        $scope.buildAndroid = false;
        $scope.disableBuildButton = true;
        $scope.evalBuildButton = function () {
            try {
                $scope.disableBuildButton =
                    (($scope.buildForm.iosBranchName.$viewValue || '').length === 0
                        && ($scope.buildForm.androidBranchName.$viewValue || '').length === 0)
                    || ($scope.buildForm.program.$viewValue || '').length === 0
                    || ($scope.buildForm.environmentUrl.$viewValue || '').length === 0
                    || !$scope.buildForm.email.$valid;
            } catch (e) {
                $scope.disableBuildButton = true;
            }
        };
        $scope.doBuild = function () {
            MobileBuildCreationService.doBuild(
                $scope.buildForm.iosBranchName.$viewValue,
                $scope.buildForm.androidBranchName.$viewValue,
                $scope.buildForm.email.$viewValue,
                $scope.buildForm.program.$viewValue,
                $scope.buildForm.environmentUrl.$viewValue,
                $scope.buildForm.buildIOS.$viewValue,
                $scope.buildForm.buildAndroid.$viewValue,
                function() {
                    $scope.clear();
                    $window.location.reload();
                },
            );
        };
        $scope.clear = function () {
            $scope.buildForm = {
                iosBranchName: null,
                androidBranchName: null,
                email: null,
                program: null,
                environmentUrl: null,
                buildIOS: null,
                buildAndroid: null,
            };
            $scope.buildAndroid = false;
            $scope.buildIOS = false;
        };

});
