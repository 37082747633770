(function () {
    'use strict';

    /**
     * editing a list of values using string pasting
     */
    angular.module('acadiamasterApp').directive('advancedEditListOfValue', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/valueComparison/advancedEditListOfValue.html',
            scope: {
                data : '=',
                closeFunction: '='
            }
        };
    });
})();
