/**
 * Created by Jamie Nola on 02/19/2020
 */

angular.module('acadiamasterApp')
    .factory('DeepLinkService', ($http, localWeb) => {
        const createNewLink = linkDto => {
            return $http.post(localWeb.createUrl('/api/admin/deepLink'), linkDto);
        };

        const loadLinks = programId => {
            return $http.get(localWeb.createUrl(`/api/admin/deepLink/program/${programId}`));
        };

        const updateExistingLink = (id, linkDto) => {
            return $http.patch(localWeb.createUrl(`/api/admin/deepLink/${id}`), linkDto);
        };

        const getForms = () => {
            return $http.get(localWeb.createUrl('/api/forms/loadAllNoPage'));
        };

        const getDeepLink = deepLinkId => {
            return $http.get(localWeb.createUrl(`/api/admin/deepLink/${deepLinkId}`));
        };

        const getContentContainers = programId => {
            return $http.get(localWeb.createUrl(`/api/admin/containers?programId=${programId}&containerType=CONTENT`));
        };

        const createConfigFile = (programId, config) => {
            return $http.post(localWeb.createUrl(`/api/programs/deepLinkConfiguration/${programId}`), config);
        };

        const getProgramConfig = programId => {
            return $http.get(localWeb.createUrl(`/api/programs/${programId}`));
        };

        return {
            createConfigFile,
            createNewLink,
            getContentContainers,
            getDeepLink,
            getForms,
            getProgramConfig,
            loadLinks,
            updateExistingLink,
        };
    });
