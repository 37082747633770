angular.module('acadiamasterApp')
    .controller('GeoLocationZipCodesController', ($scope, ngDialog, AlertService,
        DonationSiteService, ParseLinks) => {
        $scope.sites = [];
        $scope.fields = [
            { id: 'id', sortable: true },
            { id: 'postalCode', sortable: true },
        ];
        $scope.orderByChoices = {
            id: 'id',
            postalCode: 'postalCode',
        };
        $scope.size = 50;
        $scope.page = 0;

        $scope.searchData = {
            ascending: true,
            getOrder() {
                return this.ascending;
            },
            getOrderSort() {
                return this.orderBy;
            },
            orderBy: 'id',
            searchResultCount: 0,
            searchString: null,
            searchedString: null,
            setOrderBy(newOrderBy) {
                if (this.orderBy === newOrderBy) {
                    this.ascending = !this.ascending;
                } else {
                    this.ascending = true;
                    this.orderBy = newOrderBy;
                }
                $scope.loadAll();
            },
        };

        $scope.clearSearch = () => {
            $scope.searchData.searchString = null;
            $scope.loadAll();
        };

        $scope.resetPaginationThenLoadAll = () => {
            $scope.page = 0;
            $scope.size = 50;
            $scope.loadAll();
        };

        $scope.openCreateNewZipCodes = () => {
            ngDialog.openConfirm({
                controller: 'ImportZipCodesController',
                scope: $scope,
                template: 'admin-templates/site/donationSite/importZipCodes/importZipCodes.modal.html',
            });
        };

        $scope.openRemoveZipCodes = () => {
            ngDialog.openConfirm({
                controller: 'RemoveZipCodesController',
                scope: $scope,
                template: 'admin-templates/site/donationSite/removeZipCodes/removeZipCodes.modal.html',
            });
        };

        $scope.loadAll = function (resetPage) {
            DonationSiteService.getZipCodes(prepareRequest(resetPage)).then(
                (response) => {
                    if (response.status === 204) {
                        AlertService.warning('No data');
                    }
                    $scope.zipCodeList = response.data;
                    $scope.links = ParseLinks.parse(response.headers('link'));
                    $scope.page = resetPage ? 0 : $scope.page;
                    $scope.searchData.searchResultCount = response.headers('x-total-count');
                    $scope.searchData.searchedString = $scope.searchData.searchString;
                },
                (error) => {
                    AlertService.error('error while loading zip codes');
                },
            );
        };

        function prepareRequest(resetPage) {
            return {
                ascending: $scope.searchData.ascending,
                orderBy: $scope.searchData.orderBy,
                page: resetPage ? 0 : $scope.page,
                searchString: $scope.searchData.searchString || null,
                size: $scope.size,
            };
        }

        $scope.clearSearch = function () {
            $scope.searchData.searchString = null;
            $scope.searchData.searchedString = null;
            $scope.loadAll();
        };

        $scope.openExportConfirmation = () => {
            if ($scope.searchData.searchResultCount > 0) {
                ngDialog.openConfirm({
                    controller: ['$scope', function ($scope) {
                        const params = {
                            ascending: true,
                            orderBy: 'postalCode',
                            page: 0,
                            searchString: $scope.searchData.searchString || null,
                            size: $scope.searchData.searchResultCount,
                        };

                        $scope.exportZipCodes = () => DonationSiteService.getZipCodes(params).then(
                            (response) => {
                                if (response.status === 204
                                    || !response.data
                                    || response.data.length === 0) {
                                    AlertService.warning('No data');
                                } else {
                                    const zipCodes = response.data.map(item => ({ 'zip codes': item.postalCode }));
                                    $scope.closeThisDialog();
                                    return zipCodes;
                                }
                            },
                            (error) => {
                                AlertService.error('error while loading zip codes');
                            },
                        );
                    }],
                    scope: $scope,
                    template: 'admin-templates/site/donationSite/exportZipCodes/exportConfirmation.modal.html',
                });
            }
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };

        $scope.loadAll();
    });
