/**
 * directive for configuring the template content value of image type
 */
angular.module('acadiamasterApp').directive('templateValueImageConfig', (ImageReferenceCacheService, LocaleConstantsService) => ({
    link($scope) {
        $scope.data = {
            isReady: false,
            imageReferenceList : null,
            aspectRatio: null,
            imageSize : null,
        };

        $scope.LocaleConstantsService = LocaleConstantsService;

        $scope.getImageUrl = () => $scope.contentValue.getImageUrl();
        $scope.getImageUri = () => $scope.contentValue.getImageUri();
        $scope.imageRemoved = () => $scope.contentValue.imageRemoved();
        $scope.imageCropped = (imageUri, imageFile) => $scope.contentValue.imageCropped(imageUri, imageFile);
        $scope.getAspectRatio = () => $scope.definition.validation.aspectRatio;

        init();

        function init() {
            const validation = $scope.definition.validation;
            if (validation.desiredWidth && validation.desiredHeight) {
                $scope.data.aspectRatio = validation.desiredWidth / validation.desiredHeight;
                $scope.data.imageSize = {w: validation.desiredWidth, h: validation.desiredHeight};
            }

            ImageReferenceCacheService.loadImageReferences().then(function(data) {
                $scope.data.imageReferenceList = data;
                $scope.data.isReady = true;
            }, function(error) {
                console.error('error occurred in loading image reference', error);
                $scope.data.isReady = false;
            });
        }
    },
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
        isBackground: '<?'
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
        'templateValue.image.config.html',
}));

