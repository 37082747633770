/**
 * Created by Ryan Berry on 06/23/22
 *
 * Service for selecting pages in a drop-down
 */

angular.module('acadiamasterApp')
    .factory('ContainerSelectorService', function($http, localWeb) {
        const getBasicContainers = programId => {
            return $http.get(localWeb.createUrl(`/api/containers/program/${programId}`));
        };

        return {
            getBasicContainers
        };
    });
