(function () {
    'use strict';

    // for setting SSO values provided by server for configuration of the application
    angular.module('acadiamasterApp')
        .factory('SSOService', function () {
            return {
                // use this if you need to get SSO enable and related values
                isEnable: function () {
                    return window.CONFIG && window.CONFIG.keycloakParticipantEnabled && window.CONFIG.keycloakEnterpriseEnabled && window.keycloakAuth;
                }
            }

        });

})();
