(function () {
    // eslint-disable-next-line angular/controller-name
    angular.module('acadiamasterApp').controller(
        'brandingConfigController',
        function (
            ProgramBranding, $scope, localWeb, CONFIG, $state,
        ) {
            const vm = this;
            $scope.CONFIG = CONFIG;
            vm.programId = $scope.programId;
            $scope.isDataReady = false;
            $scope.branding = {};
            $scope.formData = {
                backgroundImage : null,
                faviconImage    : null,
                localizationMap : {
                    es : {},
                },
                programLogoImage   : null,
                secondaryLogoImage : null,
            };

            function getImageUrl (logoLink) {
                if (logoLink != null) {
                    return localWeb.createUrl('/api/file?isThumbnail=true&res=XHDPI&fileName=') + logoLink;
                }
                return null;
            }
            vm.getImageUrl = getImageUrl;
            ProgramBranding.getBrandingDetails(vm.programId).then(response => {
                let result = response.data;
                $scope.resultData = result;
                // eslint-disable-next-line no-use-before-define
                convertData();
            });
            function fetchSpanishProgramLogoData (branding) {
                // todo: need proper support for new language
                $scope.formData.localizationMap.es.programLogoFileName
                    = branding.localizationMap.es.programLogoNameSpanish;
                $scope.formData.localizationMap.es.programLogoFile
                    = getImageUrl(branding.localizationMap.es.programLogoLinkSpanish);
                $scope.formData.localizationMap.es.programLogoFileUrl
                    = getImageUrl(branding.localizationMap.es.programLogoLinkSpanish);
            }

            function fetchSpanishSecondaryLogoData (branding) {
                // todo: need proper support for new language
                $scope.formData.localizationMap.es.secondaryLogoFileName
                    = branding.localizationMap.es.secondaryLogoNameSpanish;
                $scope.formData.localizationMap.es.secondaryLogoFile
                    = getImageUrl(branding.localizationMap.es.secondaryLogoLinkSpanish);
                $scope.formData.localizationMap.es.secondaryLogoFileUrl
                    = getImageUrl(branding.localizationMap.es.secondaryLogoLinkSpanish);
            }

            function fetchSpanishBackgroundImageData (branding) {
                // todo: need proper support for new language
                $scope.formData.localizationMap.es.backgroundImageFileName
                    = branding.localizationMap.es.backgroundImageNameSpanish;
                $scope.formData.localizationMap.es.backgroundImageFile
                    = getImageUrl(branding.localizationMap.es.backgroundImageLinkSpanish);
                $scope.formData.localizationMap.es.backgroundImageFileUrl
                    = getImageUrl(branding.localizationMap.es.backgroundImageLinkSpanish);
            }

            function convertData () {
                $scope.branding = $scope.resultData;
                if (!$scope.resultData.localizationMap) {
                    $scope.branding.localizationMap = {
                        es : {},
                    };
                }
                $scope.formData.programLogoImage = getImageUrl($scope.branding.programLogoLink);
                $scope.formData.secondaryLogoImage = getImageUrl($scope.branding.secondaryLogoLink);
                $scope.formData.backgroundImage = getImageUrl($scope.branding.backgroundImageLink);
                $scope.branding.appIconLabel = $scope.resultData.appIconLabel;
                $scope.formData.faviconImage = getImageUrl($scope.branding.faviconLink);

                if ($scope.branding.localizationMap && $scope.branding.localizationMap.es) {
                    // todo: need proper support for new language
                    fetchSpanishProgramLogoData($scope.branding);
                    fetchSpanishSecondaryLogoData($scope.branding);
                    fetchSpanishBackgroundImageData($scope.branding);
                }
                $scope.isDataReady = true;
            }
            function saveSpanishProgramLogoData (formData) {
                // todo: need proper support for new language
                $scope.branding.localizationMap.es.programLogoNameSpanish
                    = formData.localizationMap.es.programLogoFile.name;
                $scope.branding.localizationMap.es.programLogoLinkSpanish
                    = formData.localizationMap.es.programLogoFile.name;
            }

            function saveSpanishSecondaryLogoData (formData) {
                // todo: need proper support for new language
                $scope.branding.localizationMap.es.secondaryLogoNameSpanish
                    = formData.localizationMap.es.secondaryLogoFile.name;
                $scope.branding.localizationMap.es.secondaryLogoLinkSpanish
                    = formData.localizationMap.es.secondaryLogoFile.name;
            }

            function saveSpanishBackgroundImageData (formData) {
                // todo: need proper support for new language
                $scope.branding.localizationMap.es.backgroundImageNameSpanish
                    = formData.localizationMap.es.backgroundImageFile.name;
                $scope.branding.localizationMap.es.backgroundImageLinkSpanish
                    = formData.localizationMap.es.backgroundImageFile.name;
            }
            vm.fetchSpanishProgramLogoData = fetchSpanishProgramLogoData;
            vm.fetchSpanishSecondaryLogoData = fetchSpanishSecondaryLogoData;
            vm.fetchSpanishBackgroundImageData = fetchSpanishBackgroundImageData;
            vm.saveSpanishProgramLogoData = saveSpanishProgramLogoData;
            vm.saveSpanishSecondaryLogoData = saveSpanishSecondaryLogoData;
            vm.saveSpanishBackgroundImageData = saveSpanishBackgroundImageData;
            vm.convertData = convertData;
            vm.getImageUrl = getImageUrl;
            $scope.save = function () {
                $scope.files = [];
                $scope.branding.programId = $scope.programId;
                if (!$scope.branding.localizationMap) {
                    $scope.branding.localizationMap = {
                        es : {},
                    };
                }
                if ($scope.formData.programLogoImage) {
                    $scope.files.push($scope.formData.programLogoImage);
                    if ($scope.formData.programLogoImage.name) {
                        $scope.branding.programLogoName = $scope.formData.programLogoImage.name;
                        $scope.branding.programLogoLink = $scope.formData.programLogoImage.name;
                    }
                    // if spanish program logo is removed
                    if ($scope.formData.localizationMap && $scope.formData.localizationMap.es
                        && !$scope.formData.localizationMap.es.programLogoFile) {
                        $scope.branding.localizationMap.es.programLogoNameSpanish = null;
                        $scope.branding.localizationMap.es.programLogoLinkSpanish = null;
                    }
                    // if spanish program logo is added
                    if ($scope.formData.localizationMap.es.programLogoFile) {
                        $scope.files.push($scope.formData.localizationMap.es.programLogoFile);
                        if ($scope.formData.localizationMap.es.programLogoFile.name) {
                            saveSpanishProgramLogoData($scope.formData);
                        }
                    }
                    $scope.formData.programLogoImage = null;
                } else {
                    $scope.branding.programLogoName = null;
                    $scope.branding.programLogoLink = null;
                    $scope.branding.localizationMap.es.programLogoNameSpanish = null;
                    $scope.branding.localizationMap.es.programLogoLinkSpanish = null;
                }

                if ($scope.formData.secondaryLogoImage) {
                    $scope.files.push($scope.formData.secondaryLogoImage);
                    if ($scope.formData.secondaryLogoImage.name) {
                        $scope.branding.secondaryLogoName = $scope.formData.secondaryLogoImage.name;
                        $scope.branding.secondaryLogoLink = $scope.formData.secondaryLogoImage.name;
                    }
                    // if spanish secondary logo is removed
                    if ($scope.formData.localizationMap && $scope.formData.localizationMap.es
                        && !$scope.formData.localizationMap.es.secondaryLogoFile) {
                        $scope.branding.localizationMap.es.secondaryLogoNameSpanish = null;
                        $scope.branding.localizationMap.es.secondaryLogoLinkSpanish = null;
                    }
                    // if spanish secondary logo is added
                    if ($scope.formData.localizationMap.es.secondaryLogoFile) {
                        $scope.files.push($scope.formData.localizationMap.es.secondaryLogoFile);
                        if ($scope.formData.localizationMap.es.secondaryLogoFile.name) {
                            saveSpanishSecondaryLogoData($scope.formData);
                        }
                    }
                    $scope.formData.secondaryLogoImage = null;
                } else {
                    $scope.branding.secondaryLogoName = null;
                    $scope.branding.secondaryLogoLink = null;
                    $scope.branding.localizationMap.es.secondaryLogoNameSpanish = null;
                    $scope.branding.localizationMap.es.secondaryLogoLinkSpanish = null;
                }

                if ($scope.formData.backgroundImage) {
                    $scope.files.push($scope.formData.backgroundImage);
                    if ($scope.formData.backgroundImage.name) {
                        $scope.branding.backgroundImageName = $scope.formData.backgroundImage.name;
                        $scope.branding.backgroundImageLink = $scope.formData.backgroundImage.name;
                    }
                    // if spanish background image is removed
                    if ($scope.formData.localizationMap && $scope.formData.localizationMap.es
                        && !$scope.formData.localizationMap.es.backgroundImageFile) {
                        $scope.branding.localizationMap.es.backgroundImageNameSpanish = null;
                        $scope.branding.localizationMap.es.backgroundImageLinkSpanish = null;
                    }
                    // if spanish background image is added
                    if ($scope.formData.localizationMap.es.backgroundImageFile) {
                        $scope.files.push($scope.formData.localizationMap.es.backgroundImageFile);
                        if ($scope.formData.localizationMap.es.backgroundImageFile.name) {
                            saveSpanishBackgroundImageData($scope.formData);
                        }
                    }
                    $scope.formData.backgroundImage = null;
                } else {
                    $scope.branding.backgroundImageName = null;
                    $scope.branding.backgroundImageLink = null;
                    $scope.branding.localizationMap.es.backgroundImageNameSpanish = null;
                    $scope.branding.localizationMap.es.backgroundImageLinkSpanish = null;
                }

                if ($scope.formData.faviconImage) {
                    $scope.files.push($scope.formData.faviconImage);
                    if ($scope.formData.faviconImage.name) {
                        $scope.branding.faviconName = $scope.formData.faviconImage.name;
                        $scope.branding.faviconLink = $scope.formData.faviconImage.name;
                    }
                    $scope.formData.faviconImage = null;
                } else {
                    $scope.branding.faviconName = null;
                    $scope.branding.faviconLink = null;
                }

                ProgramBranding.setBrandingDetails($scope.files, $scope.branding).then(response => {
                    if (vm.programId != null) {
                        $state.go('program.manage', { id : vm.programId });
                    } else {
                        $state.go('program');
                    }
                });
            };
        },
    );
}());
