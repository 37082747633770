/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
(function () {
    angular.module('acadiamasterApp')
        .controller('FormVersionCommentUpdateController', ($scope, ngDialog, FormVersion, AlertService) => {
            $scope.commentPattern = '^[a-zA-Z0-9\\_\\.\\-\\s\\\\]*$';

            $scope.data = {
                id                 : getVersionId($scope.ngDialogData),
                irbSemanticVersion : getIrbSemanticVersion($scope.ngDialogData),
                isActive           : $scope.ngDialogData.isActive,
                semanticVersion    : getSemanticVersion($scope.ngDialogData),
                versionComment     : getVersionComment($scope.ngDialogData),
            };

            $scope.formVersion = $scope.ngDialogData;

            $scope.update = function () {
                FormVersion.updateVersionComment($scope.data)
                    .then(() => {
                        AlertService.success('version comment updated');
                        $scope.formVersion.versionComment = $scope.data.versionComment;
                        $scope.formVersion.semanticVersion = $scope.data.semanticVersion;
                        $scope.formVersion.irbSemanticVersion = $scope.data.irbSemanticVersion;
                    });
            };

            $scope.validateUpdateIrb = function () {
                $scope.data.irbSemanticVersion = $scope.data.irbSemanticVersion.trim().substr(0, 100);
            };

            $scope.validateUpdateSemantic = function () {
                $scope.data.semanticVersion = $scope.data.semanticVersion.trim().substr(0, 100);
            };
            function getVersionId (formVersion) {
                return formVersion.id;
            }

            function getVersionComment (formVersion) {
                return formVersion.versionComment;
            }

            function getSemanticVersion (formVersion) {
                return formVersion.semanticVersion;
            }
            function getIrbSemanticVersion (formVersion) {
                return formVersion.irbSemanticVersion;
            }
        })
        .directive('disallowStartSpaces', () => {
            return {
                link : function ($scope, $element) {
                    $element.bind('input', function () {
                        $(this).val($(this).val().trimLeft());
                    });
                },

                restrict : 'A',
            };
        });
}());
