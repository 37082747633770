/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one sub field
     */
        .directive('treeNodeSubField', function (FormUtilService, treeNodeCommonService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/subField.html',
                scope: {
                    subField: '=',
                    currentTreeName : '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;

                    $scope.getFieldValueShort = function() {
                        return treeNodeCommonService.getFieldValueForDisplay($scope.subField.fieldValue);
                    }
                }
            };

        });

})();

