
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive(
            'ruleElementConfigActionMobileAppPushNotification',
            function (LocaleConstantsService,DeepLinkService,ProgramService) {
                return {
                    restrict    : 'E',
                    templateUrl : 'admin-templates/site/businessRule/configPanel/action/elementConfigActionMobileAppPushNotification.html',
                    scope       : {
                        action     : '=',
                        parentForm : '=',
                    },
                    link : function ($scope) {
                        $scope.lcs = LocaleConstantsService;
                        $scope.data = {
                            allPrograms      : [],
                            allDeepLinks     : null,
                            selectedDeepLink : $scope.action.navigationLink,
                        };
                        init($scope);
                    },
                };

                function init($scope) {
                    ProgramService.getAllPrograms().then(function (response) {
                        //Get all Programs in the portal
                        $scope.data.allPrograms = response.data;

                        for(var program = 0 ; program < $scope.data.allPrograms.length ; program++){

                            //Get DeepLinks for all programs available
                            DeepLinkService.loadLinks($scope.data.allPrograms[program].id).then(function (links) {

                                if($scope.data.allDeepLinks == null){
                                    $scope.data.allDeepLinks= links.data;
                                }
                                else {
                                    for(var link = 0 ; link < links.data.length ; link++){
                                        $scope.data.allDeepLinks.push(links.data[link]);
                                    }
                                }
                            });
                        }
                    });
                    addWatches($scope);
                }

                // todo: Handle this from the Model
                function addWatches($scope) {
                    $scope.$watch('data.selectedDeepLink', function(newValue) {
                        if(newValue.code){
                            $scope.action.navigationLink = newValue.code;
                        }
                        else {
                            $scope.action.navigationLink = newValue;
                        }
                    });
                }


            }
        );
})();
