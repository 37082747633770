/**
 * navigation builder configuration model, used to hold various configuration data
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('NavUtilService', function (FormNavigationEdgeModel, ExpressionParserService, ExpressionConstantsService) {

        /**
         * adding edge between two nodes, if there are already edge between the
         * two nodes, then update that edge with new condition
         * @param node1 - starting node
         * @param node2 - ending node
         * @param name - name of the edge
         * @param conditionText - condition text on the edge
         * @param configModel - config model
         * @param messageLoggingLevel - logging level for messages
         */
        function addEdgeBetween(node1, node2, name, conditionText, configModel, messageLoggingLevel) {
            if (node1 == null || node2==null) {
                return;
            }

            var edge = createOrFindEdge(node1, node2);

            edge.name = name;
            edge.condition = conditionTextToTree(conditionText);

            edge.conditionText = edge.condition ? edge.condition.toFormattedString(0, false) : null;

            if (edge.evaluationOrder == null) {
                configModel.addMessage('added a new edge : ' + edgeToText(edge), messageLoggingLevel);
                var formMode = node1._parent;

                formMode.addNavigationEdge(edge);
            }
            else {
                configModel.addMessage('updated an edge : ' + edgeToText(edge), messageLoggingLevel);
            }
        }

        /**
         * clear all edges coming from this node
         * note: if the edges is going to a navigation node that doesn't have any other incoming edge,
         * then that nav node will be removed
         * @param node - nav node object
         * @param configModel - config model
         * @param messageLoggingLevel - logging level for messages
         */
        function clearEdgesFrom(node, configModel, messageLoggingLevel) {
            // find all the edges coming out of this node, remove them
            if (node==null) {
                return;
            }

            configModel.addMessage('clearing edges from node : ' + node.name, messageLoggingLevel);

            var formMode = node._parent;
            
            var edgesToBeRemoved = shallowCopy(node._edgesFrom);
            
            _.forEach(edgesToBeRemoved, function(e) {
                formMode.removeNavigationEdge(e);
                configModel.addMessage('remove edge : ' + edgeToText(e), messageLoggingLevel);

                // if node to is pure nav node and has no incoming edge
                var nodeTo = e.nodeTo;

                if (nodeTo.isPureNavNode() && nodeTo.isStartNode()) {
                    formMode.removeNavigationNode(nodeTo);
                    configModel.addMessage('remove pure nav node that is only linked to the edge : ' + edgeToText(e), messageLoggingLevel);
                }
            });
        }

        /**
         * clear all edges coming to this node
         * @param node - nav node object
         * @param configModel - config model
         * @param messageLoggingLevel - logging level for messages
         */
        function clearEdgesTo(node, configModel, messageLoggingLevel) {
            // find all the edges coming out of this node, remove them
            if (node==null) {
                return;
            }

            configModel.addMessage('clearing edges to node : ' + node.name, messageLoggingLevel);

            var formMode = node._parent;

            var edgesToBeRemoved = shallowCopy(node._edgesTo);

            _.forEach(edgesToBeRemoved, function(e) {
                formMode.removeNavigationEdge(e);
                configModel.addMessage('remove edge : ' + edgeToText(e), messageLoggingLevel);
            });
        }

        /**
         * adding edge between two nodes and make sure there is no answer for field id
         * @param node1 - node start
         * @param node2 - node end
         * @param field - form field object
         * @param configModel - config model
         * @param messageLoggingLevel - logging level for messages
         */
        function addEdgeBetweenWithNoAnswer(node1, node2, field, configModel, messageLoggingLevel) {
            if (node1 == null || node2 == null || field == null) {
                return;
            }

            var fieldEntryValueFunctionString = 'fieldEntryValueInCurrentView(' + field.formField.id + ')';

            var conditionText = 'eq(toString(' + fieldEntryValueFunctionString + '), null)';

            var name = 'no answer selected for ' + field.name;
            addEdgeBetween(node1, node2, name, conditionText, configModel, messageLoggingLevel);
        }

        /***************************************
         * private functions
         ***************************************/
        function conditionTextToTree(conditionText) {
            if (conditionText == null) {
                return null;
            }

            return ExpressionParserService.parse(conditionText,
                ExpressionConstantsService.modules.STATE_RULES, {}, null);
        }

        /**
         * create a shallow copy of the array
         * @param inputArray
         * @returns {null}
         */
        function shallowCopy(inputArray) {
            if (inputArray == null) {
                return null;
            }

            return inputArray.slice();
        }

        /**
         * edge to text, utility function
         * @param edge - edge
         * @returns {string}
         */
        function edgeToText(edge) {
            var message = edge.name ? edge.name : 'no name';

            // node from
            if (edge.nodeFrom) {
                message += ' | ' + edge.nodeFrom.name;
            }

            // node to
            if (edge.nodeTo) {
                message += ' --> ' + edge.nodeTo.name;
            }
            
            if (edge.conditionText) {
                message += ' | ' + edge.conditionText;
            }
            
            return message;
        }
        
        /**
         * create or find an existing edge from node 1 to node 2
         * @param node1 - starting node
         * @param node2 - ending node
         * @returns {*} - edge
         */
        function createOrFindEdge(node1, node2) {
            var edgesFrom = node1._edgesFrom;

            var edge = _.find(edgesFrom, function(e) {
                return e.nodeFrom == node1 && e.nodeTo == node2;
            });
            

            if (edge == null) {
                edge = new FormNavigationEdgeModel(node1._parent);
                edge.nodeFrom = node1;
                edge.nodeTo = node2;
            }

            return edge;
        }

        /***************************************
         * service return call
         ***************************************/

        return {
            clearEdgesFrom : clearEdgesFrom,
            clearEdgesTo : clearEdgesTo,
            addEdgeBetween : addEdgeBetween,
            addEdgeBetweenWithNoAnswer : addEdgeBetweenWithNoAnswer
        };
    });
})();
