/**
 * constants for Container Dependency Linking
 */
angular.module('acadiamasterApp')
    .constant('ContainerDpLinkConstants', function () {


        const FIELDS = {
            SOURCE_TYPE: {
                id: 'sourceType',
                sortable: true,
            },
            SOURCE_ID: {
                id: 'sourceId',
                sortable: true,
            },
            DEPENDENCY_TYPE: {
                id: 'dependencyType',
                sortable: true,
            },
            DEPENDENCY_TYPE_ID: {
               id: 'dependencyId',
               sortable: true,
            },
            DEPENDENCY_TYPE_STRING_ID: {
                id: 'dependencyStringId',
                sortable: true,
            }
        };

        const DEPENDENCY_TYPES = [
            'FORM',
            'AGREEMENT',
            'PROPERTY',
            'USER_ADDRESS',
            'ORDER',
            'DEVICE_CONSENT',
            'SERVICE_NAME',
            'USER_PROFILE',
            'PCI'
        ];


        return {
            FIELDS,
            DEPENDENCY_TYPES
        };
    }());
