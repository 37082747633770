(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .controller('EHRDashboardController', function ($scope, TILE_CONSTANTS) {
            $scope.data = {
                tileCollectionId: TILE_CONSTANTS.ID.EHR
            };
        });
})();

