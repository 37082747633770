'use strict';

(function(){

    angular.module('acadiamasterApp').
    directive('caseBasicInfo', function (ProgramService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/caseBasicInfo.html',
            scope: {
                testCase: '=',
                testCaseForm: '='
            },
            link: function ($scope) {
                $scope.program = ProgramService.get({id:$scope.testCase.programId});
            }
        };
    });
})();
