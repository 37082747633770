angular.module('acadiamasterApp')

    /**
     * unauthenticated directive
     */
    .directive('exportWizardStepUnathenticatedTask', (UnauthenticatedTaskService, DeepLinkModel, AlertService, DeepLinkConstants) => {

        function loadAll($scope) {
            const params = {
                programId: $scope.program.id
            };
            $scope.data.isLoading = true;

            UnauthenticatedTaskService.getAllTasks(params.programId).then((response) => {
                if (response == null || response.data == null || response.status !== 200) {
                    AlertService.error(
                        `Failed to load task data for Program Id: ${params.programId}`,
                    );
                    return;
                }

                $scope.data.tasks = response.data;
                if (response.data.length === 0) {
                    AlertService.warning('No tasks were found');
                }

            }, (error) => {
                $scope.data.loadingError = true;
                console.error('Error loading unauthenticated task', error);
            }).finally(() => {
                $scope.data.isLoading = false;
            });
        }

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            $scope.cs = DeepLinkConstants;
            $scope.fields = [
                {
                    id: 'id',
                    sortable: true,
                    isIndex: true,
                },
                {
                    id: 'taskType',
                    sortable: true,
                },
                {
                    id: 'taskInfo',
                }
            ];


            $scope.data = {
                tasks: [],
                isLoading: true,
                loadingError: false,
            };

            $scope.searchData = {
                ascending: false,
                orderBy: $scope.cs.FIELDS.ID,
            };


            $scope.setOrderBy = (fieldId) => {
                if ($scope.searchData.orderBy === fieldId) {
                    $scope.searchData.ascending = !$scope.searchData.ascending;
                } else {
                    $scope.searchData.orderBy = fieldId;
                    $scope.searchData.ascending = true;
                }
            };

            loadAll($scope);
        }

        return {
            controller: ($scope) => {
                init($scope);

                $scope.selectAll = () => {
                    if ($scope.selectedData.includeUnauthenticatedTasks && $scope.data.tasks) {
                        $scope.selectedData.tasks = $scope.data.tasks;
                    } else {
                        $scope.selectedData.tasks = [];
                    }
                };

                $scope.sortBy = (name) => {
                    $scope.data.ascending = ($scope.data.orderBy === name)
                        ? !$scope.data.ascending
                        : true;
                    $scope.data.orderBy = name;
                };

                $scope.$watch('selectedData.includeUnauthenticatedTasks', (nv) => {
                    if(nv){
                        $scope.selectedData.unauthenticatedTasks = $scope.data.tasks;
                    }
                });
            },
            restrict: 'E',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '=',
            },
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepUnauthenticatedTasks.html',
        };
    });
