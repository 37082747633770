angular.module('acadiamasterApp')

    /**
     * service to store all the constants needed for program test case and suite
     */
    .factory('ProgramTestConstantsService', (vbrCommonUtil, ComparisonOperatorService) => {

        var basePackageConditionConfig = 'com.vibrent.acadia.web.rest.dto.program.test.';
        var preConditionType = {
            FORM_EXIST: { name: 'FORM_EXIST', text: 'Form Exist' },
            PROPERTY_EXIST: { name: 'PROPERTY_EXIST', text: 'Property Exist' },
            RULE_EXIST: { name: 'RULE_EXIST', text: 'Rule Exist' },
            PROGRAM_DISTRIBUTION_EXIST: { name: 'PROGRAM_DISTRIBUTION_EXIST', text: 'Program Distribution Exist' },
            PROGRAM_EXIST: { name: 'PROGRAM_EXIST', text: 'Program Exist' },
            EHR_CONFIG_EXIST: { name: 'EHR_CONFIG_EXIST', text: 'EHR config exist' },
            FEATURE_TOGGLED_STATUS: { name: 'FEATURE_TOGGLED_STATUS', text: 'Feature toggled status' },
            SYNC_DEVICES_TOGGLED_STATUS: { name: 'SYNC_DEVICES_TOGGLED_STATUS', text: 'Sync Devices toggled status' },
            BUSINESS_RULE_ACTION_EXIST: { name: 'BUSINESS_RULE_ACTION_EXIST', text: 'Businees rule action exist' }
        };

        var correctiveActionEnum = {
            SET_PROPERTY: { name: 'SET_PROPERTY', text: 'Set Property' },
            TRIGGER_RULE: { name: 'TRIGGER_RULE', text: 'Trigger Rule' },
            ADD_ONE_TIME_SURVEY: { name: 'ADD_ONE_TIME_SURVEY', text: 'Add One Time Survey to User' },
            CHANGE_FORM_LOCK_STATUS: { name: 'CHANGE_FORM_LOCK_STATUS', text: 'Change User Form Lock Status' },
            ADD_BANNER: { name: 'ADD_BANNER', text: 'Add banner' },
            DISMISS_BANNER: { name: 'DISMISS_BANNER', text: 'Dismiss Banner' },
            SET_PROPERTY_FROM_FIELD_VALUE: { name: 'SET_PROPERTY_FROM_FIELD_VALUE', text: 'Set property value from field value' },
            CREATE_ORDER: {
                name: 'CREATE_ORDER',
                text: 'Create Order'
            },
            REMOVE_MULTIPLE_SUBSCRIPTION: {
                name: 'REMOVE_MULTIPLE_SUBSCRIPTION',
                text: 'check multiple subscription'
            },
            EVALUATE_PERSONALIZED_CONTAINER: {name: 'EVALUATE_PERSONALIZED_CONTAINER', text: 'Evaluate Personalized Container'},
            EVALUATE_PERSONALIZED_CONTAINER_ITEM: {name: 'EVALUATE_PERSONALIZED_CONTAINER_ITEM', text: 'Evaluate Personalized Container Item'},
            UNAUTHENTICATED_TASK: {name : 'UNAUTHENTICATED_TASK', text : 'Unauthenticated Task'},
        };

        var QObjectType = {
            // group object, tree node with children
            GROUP_OBJECT: { name: 'GROUP_OBJECT', text: 'Group Object' },
            REUSE_EXPRESSION_CONDITION: { name: 'REUSE_EXPRESSION_CONDITION', text: 'Reuse Expression Condition' },

            // container related
            PERSONALIZED_CONTAINER_STATE: { name: 'PERSONALIZED_CONTAINER_STATE', text: 'Personalized Container State' },
            PERSONALIZED_CONTAINER_ITEM_STATE: { name: 'PERSONALIZED_CONTAINER_ITEM_STATE', text: 'Personalized Container Item State' },

            // everything else is item object, those are leaf nodes
            FORM_ASSOCIATION_STATE: { name: 'FORM_ASSOCIATION_STATE', text: 'Form Association State' },
            HAS_FORM_ASSOCIATION: { name: 'HAS_FORM_ASSOCIATION', text: 'Has Form Association' },

            FORM_ENTRY_STATE: { name: 'FORM_ENTRY_STATE', text: 'Form Entry State Check' },
            FORM_ENTRY_CHECK_WITH_TIME: {
                name: 'FORM_ENTRY_CHECK_WITH_TIME',
                text: 'Has Form Entry at Specific Time'
            },

            HAS_UNAUTHENTICATED_TASK : {
                name : 'HAS_UNAUTHENTICATED_TASK',
                text : 'Has Unauthenticated Task',
            },

            FIELD_VALUE_COMPARISON: {
                name: 'FIELD_VALUE_COMPARISON',
                text: 'Field Value Comparison'
            },

            HAS_PROPERTY_VALUE: { name: 'HAS_PROPERTY_VALUE', text: 'Has Property Value' },
            PROPERTY_VALUE_COMPARISON: { name: 'PROPERTY_VALUE_COMPARISON', text: 'Property Value Comparison' },

            HAS_BANNER_NOTIFICATION_BY_TYPE: {
                name: 'HAS_BANNER_NOTIFICATION_BY_TYPE',
                text: 'Has Banner by Type'
            },
            HAS_BANNER_NOTIFICATION_BY_FORM_ID: {
                name: 'HAS_BANNER_NOTIFICATION_BY_FORM_ID',
                text: 'Has Banner by Form ID'
            },

            IS_SUBSCRIBED_TO_DISTRIBUTION: {
                name: 'IS_SUBSCRIBED_TO_DISTRIBUTION',
                text: 'Is Subscribed to Distribution (Deprecated)'
            },

            IS_SUBSCRIBED_TO_PROGRAM: {
                name: 'IS_SUBSCRIBED_TO_PROGRAM',
                text: 'Is Subscribed to Program'
            },

            CHECK_MULTIPLE_SUBSCRIPTION: {
                name: 'CHECK_MULTIPLE_SUBSCRIPTION',
                text: 'check multiple subscription'
            },

            PROGRAM_DAY_IN_RANGE: {
                name: 'PROGRAM_DAY_IN_RANGE',
                text: 'Program Day in Range'
            },

            ADDRESS_STATE_COMPARISON: {
                name: 'ADDRESS_STATE_COMPARISON',
                text: 'Address State Comparison'
            },

            ADDRESS_TYPE: {
                name: 'ADDRESS_TYPE',
                text: 'Address Type'
            },

            ADDRESS_ZIP_CODE_COMPARISON: {
                name: 'ADDRESS_ZIP_CODE_COMPARISON',
                text: 'Address Zip Code Comparison'
            },

            HAS_RULE_BEEN_EXECUTED: {
                name: 'HAS_RULE_BEEN_EXECUTED',
                text: 'Has business rule been executed'
            },

            USER_ID_IN: {
                name: 'USER_ID_IN',
                text: 'User id in given list'
            },
            PUSH_ENABLED: {
                name: 'PUSH_ENABLED',
                text: 'Has a push token'
            },

            PHYSICAL_MEASUREMENT_STATUS: {
                name: 'PHYSICAL_MEASUREMENT_STATUS',
                text: 'Physical Measurement Status'
            },

            BIO_SPECIMEN_STATUS: {
                name: 'BIO_SPECIMEN_STATUS',
                text: 'Bio Specimen Status'
            },

            ORDER_STATUS: {
                name: 'ORDER_STATUS',
                text: 'Order Status'
            },

            ORDER_TRACKING_STATUS: {
                name: 'ORDER_TRACKING_STATUS',
                text: 'Order Tracking Status'
            },

            RULE_ACTION_STATUS: {
                name: 'RULE_ACTION_STATUS',
                text: 'Rule Action Status'
            }
        };

        var nodeType = {
            PROGRAM_TEST: 'program test',
            BASIC_INFO: 'basic info',
            EXPRESSION_CONDITION_BASIC_INFO: 'expression condition basic info',
            PRECONDITION: 'preCondition',
            IF_ASSERT_CONDITION: 'IF_ASSERT_CONDITION',
            CORRECTIVE_ACTION: 'CORRECTIVE_ACTION',
            CORRECTIVE_ACTION_LIST: 'CORRECTIVE_ACTION_LIST'
        };

        var featureTypeEnum = {
            EHR: 'EHR',
            INSIGHTS: 'INSIGHTS'
        };

        var filterType = {
            FORM_FIELD: 'FORM_FIELD',
            PROFILE_FIELD: 'PROFILE_FIELD',
            USER_ID: 'USER_ID',
            FORM_ID: 'FORM_ID',
            PROPERTY_ID: 'PROPERTY_ID',
            RULE_ID: 'RULE_ID',
            PROGRAM_ID: 'PROGRAM_ID',
            FORM_STATUS: 'FORM_STATUS',
            PROPERTY_VALUE: 'PROPERTY_VALUE',
            TOGGLE_STATUS: 'TOGGLE_STATUS',
            FEATURE_TYPE: 'FEATURE_TYPE',
            SYNC_DEVICE_TYPE: 'SYNC_DEVICE_TYPE',
            FORM_ENTRY_STATUS: 'FORM_ENTRY_STATUS',
            WORK_FLOW_ID: 'WORK_FLOW_ID',
            FORM_GROUP_NAME: 'FORM_GROUP_NAME',
            VALUE_TYPE: 'VALUE_TYPE',
            ACTION_ID: 'ACTION_ID'
        };

        var classes = {
            classReferenceKey: '@class',
            ConditionInfoDTO: basePackageConditionConfig + 'ConditionInfoDTO',
            QItem: basePackageConditionConfig + 'QItem',
            QGroup: basePackageConditionConfig + 'QGroup'
        };

        var valueType = {
            NUMBER: 'NUMBER',
            STRING: 'STRING',
            DATETIME: 'DATETIME',
            FILE: 'FILE',
            BOOLEAN: 'BOOLEAN'
        };

        var formState = {
            LOCKED: 'LOCKED',
            UNLOCKED: 'UNLOCKED'
        };

        var joinConditions = {
            AND: 'AND',
            OR: 'OR',
            NOT: 'NOT'
        };

        var fields = {
            DRAFT: 'DRAFT',
            LOCKED: 'LOCKED'
        };

        var TimeUnit = {
            DAYS: 'DAYS',
            HOURS: 'HOURS'
        };

        var BannerStatus = {
            ACTIVE_BANNERS: 'ACTIVE_BANNERS',
            DISMISSED_BANNERS: 'DISMISSED_BANNERS',
            ALL_BANNERS: 'ALL_BANNERS',
        };

        return {
            preConditionType: preConditionType,
            correctiveActionEnum: correctiveActionEnum,
            QObjectType: QObjectType,
            nodeType: nodeType,
            filterType: filterType,
            classes: classes,
            getObjectByName: vbrCommonUtil.getObjectByName,
            valueType: valueType,
            formState: formState,
            joinConditions: joinConditions,
            operators: ComparisonOperatorService.operators,
            fields: fields,
            featureTypeEnum: featureTypeEnum,
            TimeUnit: TimeUnit,
            BannerStatus: BannerStatus,
        };
    });
