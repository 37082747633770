(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for trigger a workflow inside a business rule
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionTriggerRuleModel',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, RuleLoadUtil) {

            CorrectiveActionTriggerRuleModel.inheritsFrom(CorrectiveActionBaseModel);

            /**
             * corrective action for trigger a workflow inside a business rule
             * @constructor
             */
            function CorrectiveActionTriggerRuleModel(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.TRIGGER_RULE);

                this.ruleId = null;
                this.workflowId = null;
                this.skipEvaluation = false;

                this._rule = null;
                this._workflow = null;
            }

            CorrectiveActionTriggerRuleModel.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.ruleId = dto.ruleId;
                this.workflowId = dto.workflowId;
                this.skipEvaluation = dto.skipEvaluation;

                RuleLoadUtil.loadRule(this);
            };

            CorrectiveActionTriggerRuleModel.prototype.toDto = function () {
                var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.ruleId = this.ruleId;
                dto.workflowId = this.workflowId;
                dto.skipEvaluation = this.skipEvaluation;

                return dto;
            };

            CorrectiveActionTriggerRuleModel.prototype.setRule = function (rule) {
                this._rule = rule;
                this.ruleId = rule==null ? null : rule.id;

                if (rule==null) {
                    this.setWorkflow(null);
                    return;
                }

                var workflow = getWorkFlowById(rule.workflows, this.workflowId);
                if (workflow==null && rule.workflows!=null && rule.workflows.length > 0) {
                    this.setWorkflow(rule.workflows[0]);
                }
                else {
                    this.setWorkflow(workflow);
                }
            };

            CorrectiveActionTriggerRuleModel.prototype.getRule = function () {
                return this._rule;
            };

            CorrectiveActionTriggerRuleModel.prototype.setWorkflow = function(workflow) {
                this._workflow = workflow;
                this.workflowId = workflow ? workflow.id : null;
            };

            CorrectiveActionTriggerRuleModel.prototype.getWorkflow = function() {
                return this._workflow;
            };


            CorrectiveActionTriggerRuleModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);

                var workflow = this._workflow;
                var workFlowMessage = workflow == null ? '<span class="text-danger"> no workflow selected </span>' :
                    ('workflow (<span class="badge">' + workflow.id + '</span> | ' + workflow.name + ')');

                var ruleMsg = 'Rule (' + (this.ruleId == null ? 'no id' : this.ruleId) + ' | ' +
                    (this._rule == null ? 'no name' :  this._rule.name) + ')';

                return typeMsg + 'trigger ' + workFlowMessage + ' of ' + ruleMsg;
            };

            CorrectiveActionTriggerRuleModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.ruleId == null) {
                    this.setErrorMessage('rule id is required');
                    return;
                }

                if (this.workflowId == null) {
                    this.setErrorMessage('workflow id is required');
                    return;
                }
            };

            /***************************************
             * private functions
             ***************************************/
            function getWorkFlowById(workflows, id) {
                if (id==null || workflows==null || workflows.length==0) {
                    return null;
                }

                return _.find(workflows, function(w) {
                    return w!=null && w.id == id;
                });
            }

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionTriggerRuleModel;
        });
})();
