/**
 * directive for internal search of the form
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.directive('displayConfigFilter', function (FormSearchConstants) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/advancedPanel/displayConfigFilter.html',
            scope: {
                // filter data is a object of FormSearchDisplayConfigModel
                filterData: '=',
                closeFunction: '='
            },
            link: function ($scope) {
                $scope.searchConstants = FormSearchConstants;

                // a list of properties to work with for the UI
                $scope.allProperties = initAllProperties($scope.filterData);
            }
        };

        /******************************************************************************
         * private functions
         ******************************************************************************/

        function initAllProperties(filterData) {
            return [
                {
                    title: 'Miscellaneous',
                    properties : [
                        createSubProperty(filterData, 'Visibility', 'visibility', 'booleanChoices'),
                        createSubProperty(filterData, 'Editable', 'editable', 'booleanChoices'),
                        createSubProperty(filterData, 'Width (dp/%)', 'width', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Gravity (Horizontal)', 'gravityH', 'gravityHChoices'),
                        createSubProperty(filterData, 'Gravity (Vertical)', 'gravityV', 'gravityVChoices')
                    ]
                },
                {
                    title: 'Font',
                    properties : [
                        createSubProperty(filterData, 'Size (pt)', 'fontSize', null, true),
                        createSubProperty(filterData, 'Name', 'fontName', 'fontNames'),
                        createSubProperty(filterData, 'Style (Normal)', 'fontStyleNormal', 'booleanChoices'),
                        createSubProperty(filterData, 'Style (Bold)', 'fontStyleBold', 'booleanChoices'),
                        createSubProperty(filterData, 'Style (Italic)', 'fontStyleItalic', 'booleanChoices'),
                        createSubProperty(filterData, 'Style (Inherit)', 'fontStyleInherit', 'booleanChoices')
                    ]
                },
                {
                    title: 'Color',
                    properties : [
                        createSubProperty(filterData, 'Color (Foreground)', 'color', null, false),
                        createSubProperty(filterData, 'Color (Background)', 'bgColor', null, false)
                    ]
                },
                {
                    title: 'Padding',
                    properties : [
                        createSubProperty(filterData, 'Left (dp/%)', 'paddingLeft', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Right (dp/%)', 'paddingRight', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Top (dp/%)', 'paddingTop', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Bottom (dp/%)', 'paddingBottom', null, true, 'lengthUnits')
                    ]
                },
                {
                    title: 'Margin',
                    properties : [
                        createSubProperty(filterData, 'Left (dp/%)', 'marginLeft', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Right (dp/%)', 'marginRight', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Top (dp/%)', 'marginTop', null, true, 'lengthUnits'),
                        createSubProperty(filterData, 'Bottom (dp/%)', 'marginBottom', null, true, 'lengthUnits')
                    ]
                }
            ];
        }

        /**
         * create a sub property object
         * @param filterData - filter data that holds all the properties
         * @param title - title used for display
         * @param propertyName - property name of the object
         * @param choicesKey - choices key to define the possible choices for the property, the actual choices
         * @param isNumber - is this a number or not
         * @param unitsKey - key to get a list of supported units
         *
         * note: one and only one parameter should be defined between choicesKey and isNumber, if both are
         * defined, then as part of the error handling, isNumber will be ignored
         */
        function createSubProperty(filterData, title, propertyName, choicesKey, isNumber, unitsKey) {
            return {
                title: title,
                keyName : propertyName,
                filter: filterData[propertyName],
                choices : choicesKey ? FormSearchConstants[choicesKey] : null,
                isNumber : choicesKey ? null : isNumber,
                unitsKey : unitsKey == null ? null : FormSearchConstants[unitsKey]
            };
        }

    });
})();

