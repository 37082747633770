/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for field basic configuration regarding data type property
     */
        .directive('formElementConfigBasicFieldDataTypeProperty', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/elementConfig.basic.dataTypeProperty.html',
                scope: {
                    field: '=',
                    parentForm : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.availablePropertyCount = function() {
                        var count = 0;

                        var dataTypes = $scope.field.findParentMode()._dataTypes;

                        _.forEach(dataTypes, function(dataType) {
                            count += $scope.availablePropertyCountForOneDataType(dataType);
                        });

                        return count;
                    };

                    $scope.availablePropertyCountForOneDataType = function(dataType) {
                        var count = 0;
                        var totalAvailable = dataType.propertyUsage.available;
                        var suitableTypes = $scope.field.possibleFieldValueType;
                        if (totalAvailable!=null && totalAvailable.length>0 && !vbrCommonUtil.isNullOrUnavailable(suitableTypes) && suitableTypes.length>0) {
                            _.forEach(totalAvailable, function(p) {
                                if ($scope.isPropertySuitable(p)) {
                                    count++;
                                }
                            });
                        }
                        return count;
                    };

                    $scope.isPropertySuitable = function(property) {
                        if (vbrCommonUtil.isNullOrUnavailable($scope.field.possibleFieldValueType)) {
                            return false;
                        }

                        return _.indexOf($scope.field.possibleFieldValueType, property.valueType)>=0;
                    };
                }
            };

        });
})();