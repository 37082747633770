/**
 * directive for configuring the background gradient of an image
 */
angular.module('acadiamasterApp').directive('templateValueImageBackgroundConfig', (GradientType) => ({
    link($scope) {
        $scope.gradientType = Object.values(GradientType).filter(e => !!e.name);
    },
    restrict : 'E',
    scope    : {
        contentValue : '=',
        definition   : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
            'templateValue.imageBackground.config.html',
}));

