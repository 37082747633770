'use strict';

angular.module('acadiamasterApp').controller('ProgramCategoryDialogController',
        function($scope, $stateParams, $uibModalInstance, entity, ProgramCategory, User, ProgramService,CONFIG) {
        $scope.CONFIG = CONFIG;
        $scope.programCategory = entity;
        $scope.users = User.query();
        $scope.programs = ProgramService.query();
        $scope.load = function(id) {
            ProgramCategory.get({id : id}, function(result) {
                $scope.programCategory = result;
            });
        };

        var onSaveFinished = function (result) {
            $scope.$emit('acadiamasterApp:programCategoryUpdate', result);
            $uibModalInstance.close(result);
        };

        $scope.save = function () {
            if ($scope.programCategory.id != null) {
                ProgramCategory.update($scope.programCategory, onSaveFinished);
            } else {
                ProgramCategory.save($scope.programCategory, onSaveFinished);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
});
