(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * service for workflow model
         */
        .service('RuleWorkflowModelService',
            function (RuleConstantsService, RuleUtilService, FixedNodeModelService, ModelServiceBase, TriggerUtilService) {
                /***************************************************************
                 * private functions
                 **************************************************************/

                /**
                 * Method to convert expression model to dto structure and set it in expression list in workflow dto
                 * @param dto
                 * @param workflowModel
                 */
                function setExpressionsToDto(dto, workflowModel) {
                    _.forEach(workflowModel.expressionFixedNodes, function (expressionFixedNode) {
                        if(RuleUtilService.isScheduleRule(workflowModel)){
                            _.forEach(expressionFixedNode.expressionActionHolders, function (holder, index) {
                                var expressionDto = holder.toDto();
                                expressionDto.sequenceNo = index + 1;
                                dto.evaluationExpressions.push(expressionDto);
                            });
                        } else {
                            _.forEach(expressionFixedNode.expressions, function (expression, index) {
                                var expressionDto = expression.toDto();
                                expressionDto.sequenceNo = index + 1;
                                dto.evaluationExpressions.push(expressionDto);
                            });
                        }

                    });
                }


                /**
                 * Method to create expression model from expression dto and set it in workflow model
                 * @param workflowModel
                 * @param dto
                 */
                function setExpressionsFromDto(workflowModel, dto) {
                    var fixedNode = null;

                    if(RuleUtilService.isScheduleRule(workflowModel)){
                        // Create trigger fixed node which holds trigger models
                        fixedNode = new FixedNodeModelService.FixedNodeSQLExpressionModel(workflowModel);
                        fixedNode.fromDto(dto);

                    } else {
                        // Create trigger fixed node which holds trigger models
                        fixedNode = new FixedNodeModelService.FixedNodeFunctionalExpressionModel(workflowModel);
                        fixedNode.fromDto(dto);
                    }

                    // Push trigger fixed node in workflow model
                    workflowModel.expressionFixedNodes.push(fixedNode);
                }

                /***************************************************************
                 * Method to initialize fix nodes for trigger and expression
                 **************************************************************/
                function initFixedNodes(workflow) {
                    TriggerUtilService.initFixedTriggerNode(workflow, false);

                    if(RuleUtilService.isScheduleRule(workflow)){
                        workflow.expressionFixedNodes = [
                            new FixedNodeModelService.FixedNodeSQLExpressionModel(workflow)
                        ];
                    } else {
                        workflow.expressionFixedNodes = [
                            new FixedNodeModelService.FixedNodeFunctionalExpressionModel(workflow)
                        ];
                    }
                }

                /***************************************************************
                 * Model
                 ***************************************************************/
                WorkflowModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function WorkflowModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

                    // Add nodeType for tree structure
                    this.nodeType = RuleConstantsService.nodeType.WORKFLOW;
                    this.collapsed = false;

                    // Add workflow fields
                    this.name = "New Workflow";

                    // These fields used to show the fixed trigger and and expression node in workflow node
                    initFixedNodes(this);

                }

                /**
                 * convert the current UI model to dto format
                 */
                WorkflowModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id = this.id;
                    dto.name = this.name;

                    // set data from model to dto
                    TriggerUtilService.fromTriggerModelToDto(this, dto);

                    dto.evaluationExpressions = [];
                    setExpressionsToDto(dto, this);

                    return dto;
                };

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 * @param dto
                 */
                WorkflowModel.prototype.fromDto = function (dto) {
                    this.id = dto.id;
                    this.name = dto.name;

                    // set data from dto to model
                    TriggerUtilService.fromDtoToTriggerModel(this, dto, false);

                    this.expressionFixedNodes = [];
                    setExpressionsFromDto(this, dto);
                };


                /**
                 * validate the entity
                 */
                WorkflowModel.prototype._validateSelf = function () {
                    // implement validation here later, should at least check there are at least 1 condition and 1 action
                };

                WorkflowModel.prototype.customClone = function () {
                    var newWorkflow = ModelServiceBase.BaseTreeNodeModel.prototype.customClone.call(this);
                    var newExpressionFixedNodes = [];
                    _.forEach(this.expressionFixedNodes, function (expressionFixedNode) {
                        var newExpressionFixedNode = expressionFixedNode.customClone();
                        newExpressionFixedNode._parent = newWorkflow;
                        newExpressionFixedNodes.push(newExpressionFixedNode);
                    });
                    newWorkflow.expressionFixedNodes = newExpressionFixedNodes;
                    return newWorkflow;
                };

                WorkflowModel.prototype.getDescriptionAsHtml = function () {
                    var message = ' <i>' + this.name + "</i>";
                    message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                    return message;
                };

                /***************************************
                 * service return call
                 ***************************************/

                return {
                    WorkflowModel: WorkflowModel
                };
            });
})();
