/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    angular.module('acadiamasterApp')
        .config(($stateProvider, $urlRouterProvider) => {
            $stateProvider
                .state('site', {
                    abstract: true,
                    views: {
                        'navbar@': {
                            templateUrl: 'admin-templates/navbar/navbar.html',
                            controller: 'NavbarController',
                        },
                        'breadcrumb@site': {
                            templateUrl: 'admin-templates/site/breadcrumb/breadcrumb.template.html',
                            controller: 'BreadcrumbController',
                            controllerAs: 'bc',
                        },
                    },
                    resolve: {
                        authorize: ['Auth', 'Principal',
                            function (Auth, Principal) {
                                if (Principal.isIdentityResolved()) {
                                    console.log('identity is resolved, trying to authorize');
                                    return Auth.authorize();
                                }

                                return Auth;
                            },
                        ],
                        translatePartialLoader($translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                        },
                    },
                })
                .state('entity', {
                    abstract: true,
                    parent: 'site',
                });

            $urlRouterProvider.otherwise('/');
        });
}());
