angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one condition
     */
    .directive('treeNodeExpressionCondition', ProgramTestTreeOptionsService => ({
        link($scope) {
            $scope.treeOptions = ProgramTestTreeOptionsService;
        },
        restrict: 'E',
        scope: {
            condition: '=',
            selector: '=',
            subTitle: '@?',
            titleText: '@?',
            showName: '='
        },
        templateUrl: 'admin-templates/site/expressionCondition/configuration/treeNode/expressionCondition.html',
    }));
