/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('treeNodeSqlExpressionActionHolder', function (RuleUtilService, FixedNodeService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/treeNode/sqlExpressionActionHolder.html',
                scope: {
                    holder: '=',
                    selector: '=',
                    indexInList: '='
                },
                link: function ($scope) {
                    $scope.ruleConstants = RuleUtilService.ruleConstants;
                    $scope.fixedNodeService = FixedNodeService;
                }
            };
        });

})();

