/**
 * directive for configuring the template content value of icon type
 */
angular.module('acadiamasterApp').directive('templateValueIconConfig', () => ({
    restrict: 'E',
    scope: {
        contentValue: '=',
        definition: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
        'templateValue.icon.config.html',
}));

