/**
 * element configuration for setting user profile value action
 * todo: this action should take multiple properties so it can be more efficient in execution and
 * more organized in configuration
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('ruleElementConfigActionUserProfileValue',
        function (Property, ExpressionConstantsService, PropertyCacheService, AlertService, FormConstants, RuleUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.user.profile.value.html',
                scope: {
                    action: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.programId = $scope.action.getRoot().programId;
                    $scope.allProperties = null;
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.constants = FormConstants;
                    $scope.formPreviewOpened = false;
                    $scope.RuleUtilService = RuleUtilService;

                    $scope.formOptions = {
                        form: null,
                        title: 'Form',
                        viewOnly: false,
                        formCategory: "PROFILE_FORM"
                    };

                    $scope.propertySelectionChanged = function () {
                        processPropertyAfterChange($scope);
                    };

                    init($scope);
                }
            };

            function init($scope) {
                // this is not a force reload of all the properties, so if properties have changed
                var promise = PropertyCacheService.loadProperties(false);

                promise.then(function (properties) {
                    $scope.allProperties = properties;
                }).catch(function (error) {
                    AlertService.warning("failed to load properties list : " + error);
                });

                processPropertyAfterChange($scope);
            }


            function processPropertyAfterChange($scope) {
                var action = $scope.action;
                if ($scope.type == $scope.constants.setFieldsValueType.VALUE) {
                    if (action.property.valueType == 'NUMBER') {
                        action.valueAsBoolean = $scope.action.valueAsString = null;
                    } else if (action.property.valueType == 'STRING') {
                        action.valueAsNumber = $scope.action.valueAsBoolean = null;
                    } else if (action.property.valueType == 'BOOLEAN') {
                        action.valueAsNumber = action.valueAsString = null;
                        action.valueAsBoolean = true;
                    }
                    action.expression = null
                } else if ($scope.type == $scope.constants.setFieldsValueType.EXPRESSION) {
                    resetActionValues($scope);
                }

                if (action.unit!=null) {
                    action.unit = _.find(action.property.units, function(u) {
                        return action.unit.id = u.id;
                    });
                }
            }

            /**
             * reset value as string, boolean and number
             */
            function resetActionValues($scope) {
                $scope.action.valueAsNumber = null;
                $scope.action.valueAsBoolean = null;
                $scope.action.valueAsString = null;
            }

        });

})();

