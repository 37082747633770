export const fieldDisplayConfig = {
    width: '100%',
    gravity: 'CENTER',
    padding: '43 30 30 20',
    margin: 'System',
    fontSize: '14pt',
    fontFamily: 'inherit',
    textStyle: 'bold',
    color: '#262261',
    bgColor: 'white',
};