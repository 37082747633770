/**
 * Deletes an agreement
 */

angular.module('acadiamasterApp')
    .controller('AgreementDeleteDialogController', (agreement) => {
        const vm = this;
        vm.agreement = agreement;
        return vm;
    });
