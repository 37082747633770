/**
 * Created by Jamie Nola on 03/28/2019
 *
 * Copied from the angular-filter library and modified
 * https://github.com/a8m/angular-filter/blob/master/dist/angular-filter.js
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('filterBy', function ($parse) {
            function toArray(object) {
                return angular.isArray(object)
                    ? object
                    : Object.keys(object).map(function (key) {
                        return object[key];
                    });
            }

            return function (collection, properties, search, strict) {
                var comparator;

                search = (angular.isString(search) || angular.isNumber(search)) ?
                    String(search).toLowerCase() : undefined;

                collection = angular.isObject(collection) ? toArray(collection) : collection;

                if (!angular.isArray(collection) || angular.isUndefined(search)) {
                    return collection;
                }

                return collection.filter(function (elm) {
                    return properties.some(function (prop) {
                        /**
                         * check if there is concatenate properties
                         * example:
                         * object: { first: 'foo', last:'bar' }
                         * filterBy: ['first + last'] => search by full name(i.e 'foo bar')
                         */
                        if (prop.indexOf('+') === -1) {
                            comparator = $parse(prop)(elm)
                        } else {
                            var propList = prop.replace(/\s+/g, '').split('+');
                            comparator = propList
                                .map(function (prop) { return $parse(prop)(elm); })
                                .join(' ');
                        }

                        if (!angular.isString(comparator) && !angular.isNumber(comparator)) {
                            return false;
                        }
                        comparator = String(comparator).toLowerCase();
                        return strict ? comparator === search : comparator.includes(search);
                    });
                });
            }
        });
})();
