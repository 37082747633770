(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('DismissBannerActionModel',
        function (RuleConstantsService, RuleActionBaseModelService, RuleUtilService) {
            var ActionModelBase = RuleActionBaseModelService.ActionModelBase;

            DismissBannerActionModel.inheritsFrom(ActionModelBase);

            function DismissBannerActionModel(parent) {
                ActionModelBase.call(this, parent);
                this.type = RuleConstantsService.actionType.DISMISS_BANNER;

                this.bannerType = null;
                this.targetAreas = [];
                this.ignoreRemindable = true;
                this.formId = null;
                this._form = null;
            }

            DismissBannerActionModel.prototype.toDto = function (files) {
                var dto = ActionModelBase.prototype.toDto.call(this, files);

                var targetAreas = [];
                if (this.targetAreas != null) {
                    _.forEach(this.targetAreas, function (targetArea) {
                        targetAreas.push(targetArea.name);
                    });

                }

                dto.actionDismissBanner = {
                    actionId: this.id,
                    bannerType: this.bannerType !=null ? this.bannerType.name : null,
                    ignoreRemindable: this.ignoreRemindable,
                    targetAreas: targetAreas,
                    formId: this._form !=null ? this._form.id : null
                };
                return dto;
            };

            DismissBannerActionModel.prototype.fromDto = function (dto) {
                ActionModelBase.prototype.fromDto.call(this, dto);
                var actionDismissBanner = dto.actionDismissBanner;
                if (actionDismissBanner != null) {
                    this.bannerType = actionDismissBanner.bannerType != null ?
                        RuleConstantsService.getObjectByName(RuleConstantsService.bannerType, actionDismissBanner.bannerType) : null;
                    this.ignoreRemindable = actionDismissBanner.ignoreRemindable;
                    if (actionDismissBanner.formId){
                        this._form = RuleUtilService.getFormById(actionDismissBanner.formId);
                    }
                    if (actionDismissBanner.targetAreas != null) {
                        var targetAreas = [];

                        _.forEach(actionDismissBanner.targetAreas, function (targetArea) {
                            targetAreas.push(RuleConstantsService.getObjectByName(RuleConstantsService.bannerDisplayTargetArea,
                                targetArea));
                        });

                        this.targetAreas = targetAreas;
                    }
                }
            };

            DismissBannerActionModel.prototype.setForm = function (form) {
                this._form = form;
                this.formId = form == null ? null : form.id;
            };

            DismissBannerActionModel.prototype.getForm = function () {
                return this._form;
            };

            DismissBannerActionModel.prototype.bannerTypeNeedsForm = function () {
                return this.bannerType != null && this.bannerType == RuleConstantsService.bannerType.OPEN_FORM;
            };


            DismissBannerActionModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionModelBase.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                message += ' Dismiss "' + 'Banner Type: - ' + (this.bannerType!=null ? this.bannerType.name : 'Not Selected')
                + (this.bannerTypeNeedsForm() ? ' with form id ' + (this._form!=null ? this._form.id : 'Not Selected') : '');

                if (this.targetAreas != null && this.targetAreas.length > 0) {
                    message += ' from ' + this.targetAreas[0].text;
                    for (var i = 1; i < this.targetAreas.length; i++) {
                        message += ', ' + this.targetAreas[i].text;
                    }
                }
                return message;
            };

            DismissBannerActionModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.bannerType == null) {
                    this.setErrorMessage('Banner type is required');
                }

                if (this._form == null && this.bannerTypeNeedsForm()) {
                    this.setErrorMessage('form selection is required');
                }

            };

            return DismissBannerActionModel;
        }
    );
})();
