(function () {
  'use strict';

  angular.module('acadiamasterApp').controller('CreateAdminUserController',
      function ($scope,CreateAdminUserService,$state, EmailValidation, PasswordUtilService) {

        function init(){
          $scope.roles = [[],['ROLE_ADMIN'], ['ROLE_T2_SUPPORT']];

          $scope.newAdminUser = {
            emailAddress : '',
            password : '',
            firstName: '',
            middleInitial: '',
            lastName: '',
            login:  '',
            activated: 'true',
            locked: 'false',
            testUser: 'false',
            profileImageUrl: '',
            email: '',
            authorities: $scope.roles[0],
            alternateEmail: '',
          };
        }

        $scope.checkEmailValid = function () {
          if ($scope.createForm.email.$valid) {
            $scope.errorEmailExists=null;
            $scope.errorInvalidEmail=null;
            EmailValidation.checkEmailOrLoginAvailable($scope.createForm.email.$modelValue)
            .then(function (response) {
              if (response.data.isValid === true) {
                $scope.errorEmailExists=false;
              } else {
                $scope.errorEmailExists=true;
                $scope.createForm.$invalid = true;
              }
            },function(err){
              if (err.status === 400 && err.data.description === 'Please enter a valid email address') {
                $scope.errorInvalidEmail = true;
              }
            });
          }
        };

        $scope.validatePassword = function () {
          var password = $scope.newAdminUser.password;
          $scope.createForm.password.$setValidity('invalid', PasswordUtilService.isValidPassword(password, 8));
        };

        $scope.save = function() {
          $scope.newAdminUser.email = $scope.newAdminUser.emailAddress;
          $scope.newAdminUser.login = $scope.newAdminUser.emailAddress;
          $scope.newAdminUser.alternateEmail = $scope.newAdminUser.emailAddress;
          CreateAdminUserService.createAdminUser($scope.newAdminUser)
          .then(function (response) {
            $state.go('user-management');
          }, function (error) {
            console.log(error);
          });
        };

        init();
      });

})();







