/* eslint-disable no-undef */
/**
 *
 */

// eslint-disable-next-line angular/controller-name
angular.module('acadiamasterApp')
    .controller('startMigrationController', ($scope, isFullMigration, drcSyncUtilService, OperationStatus, VbrToolsService, AlertService) => {
        const vm = this;
        vm.isFullMigration = isFullMigration;
        vm.displayConfirmation = false;

        // data migration related
        vm.data = {
            isReady         : false,
            operationId     : null,
            operationStatus : new OperationStatus(),
        };

        vm.startMigration = () => {
            const migrationParams = {
                eventsToMigrate : vm.migrationParams.eventsToMigrate,
                limitUserCount  : vm.migrationParams.limitUserCount,
                programCode     : vm.migrationParams.currentPdCode,
                // eslint-disable-next-line no-use-before-define
                userIdList      : convertStringToIntegerList(vm.migrationParams.userIds),
            };

            // check status every 5 seconds
            $scope.checkStatusInterval = 5000;
            drcSyncUtilService.openModelWindow($scope, vm.data, 'VXP Data Migration ', null);
            VbrToolsService.vxpDataMigration(migrationParams)
                .then(response => {
                    vm.data.operationId = response.data?.operationId;
                    drcSyncUtilService.checkAndShowStatus($scope, vm.data);
                });
        };

        /* ********************************************************************
         * ************************** Private Methods **************************
         * ******************************************************************** */
        function convertStringToIntegerList (commaSeparatedIds) {
            // full migration user id list is null
            if (!commaSeparatedIds) {
                return null;
            }

            // partial migration
            const startUserIds = commaSeparatedIds.split(/[\s,]+/);

            if (startUserIds == null || startUserIds.length === 0) {
                AlertService.warning('At least one user id should be synced');
                return undefined;
            }

            let userIds = [];
            _.forEach(startUserIds, userId => {
                if (userId == null) {
                    return;
                }

                // eslint-disable-next-line no-param-reassign
                userId = userId.trim();
                if (userId.length === 0) {
                    return;
                }

                // eslint-disable-next-line eqeqeq
                if (parseInt(userId) != userId) {
                    AlertService.warning(`${userId } is not a valid id`);
                    return;
                }

                userIds.push(parseInt(userId));
            });
            return userIds;
        }

        vm.readyFlag = true;
        return vm;
    });
