(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * service for expression model
         */
        .service('RuleExpressionModelService',
            function (RuleConstantsService, RuleActionModelService, RuleUtilService, ModelServiceConditionExpression, ModelServiceBase, ExpressionService) {
                /***************************************************************
                 * private functions
                 **************************************************************/

                /***************************************************************
                 * Rule Model
                 ***************************************************************/
                ExpressionModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                function ExpressionModel(parent) {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

                    // Add nodeType for tree structure
                    this.nodeType = RuleConstantsService.nodeType.EXPRESSION;
                    this.collapsed = false;

                    this.name = 'New Expression';
                    this.expression = '';
                    this.expressionTree = null;
                    this.template = null;
                    this.templateData = null;
                    this.actionHolders = [];
                    this.sequenceNo = null;
                }

                /**
                 * convert the current UI model to dto format
                 */
                ExpressionModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id = this.id;
                    dto.name = this.name;

                    if (this.expression != null && this.expression.length > 0) {
                        dto.expression = this.expression;
                        dto.expressionTree = this.expressionTree.toDto();
                    } else {
                        dto.expressionTree = null;
                        dto.expression = null;
                    }
                    dto.template = this.template;
                    dto.templateData = this.templateData;

                    // set expression action mapping from model to dto
                    ExpressionService.setActionMappingFromModelInExpressionDto(dto, this, this.id);

                    return dto;
                };

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 * @param dto
                 */
                ExpressionModel.prototype.fromDto = function (dto) {
                    this.id = dto.id;
                    this.name = dto.name == null ? '' : dto.name;
                    this.expression = dto.expression;
                    if (dto.expressionTree != null) {
                        this.expressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                        this.expressionTree.fromDto(dto.expressionTree);
                    }

                    this.template = dto.template;
                    this.templateData = dto.templateData;

                    // set actions mapping from dto to model
                    ExpressionService.setActionMappingFromDtoToExpressionModel(this, dto);
                };


                /**
                 * validate the entity
                 */
                ExpressionModel.prototype._validateSelf = function () {
                    this.clearError();
                    if (this.actionHolders.length === 0) {
                        this.setErrorMessage('At least one action must be added for each expression');
                    }
                };

                ExpressionModel.prototype.getRule = function () {
                    return this.getRoot();
                };

                /**
                 * get name of the condition, this will check if condition is empty or not
                 **/
                ExpressionModel.prototype.getName = function () {
                    var name = this.name || '';
                    if (this.expression == null || this.expression.trim().length === 0) {
                        name += ' (****** ALWAYS TRUE ******)';
                    }
                    return name;
                };

                ExpressionModel.prototype.customClone = function () {
                    return ExpressionService.cloneExpressionWithAllActions(this);
                };


                ExpressionModel.prototype.cloneAction = function (actionHolder, selector) {
                    ExpressionService.cloneAndAddActionToExpression(this, actionHolder, selector);
                };

                ExpressionModel.prototype.getDescriptionAsHtml = function () {
                    var message = '<span data-nodrag class="badge badge-info">Functional Expression Condition & Actions</span>';
                    message += ' <span class="tree-node-id-display" title="ID: ' + this.id + '"><b>ID: </b>' + this.id + '</span>';
                    message += ' <i>' + (this.expression != null ? this.name : "<b class='bg-success'>(*** ALWAYS TRUE ***)</b>") + '</i>';
                    return message;
                };

                ExpressionModel.prototype.hasAsyncAction = function () {
                    return ExpressionService.expressionHasAsyncAction(this);
                };

                /***************************************
                 * service return call
                 ***************************************/

                return {
                    ExpressionModel: ExpressionModel,
                    getActionTypeUtil: RuleActionModelService.getActionTypeUtil
                };
            });
})();
