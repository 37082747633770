'use strict';

angular.module('acadiamasterApp')
    .controller('BusinessRuleDetailPageController', function ($scope, $stateParams, BusinessRule) {
        $scope.id =  $stateParams.id;
        $scope.selectedRule = null;

        function init() {
            var rule = BusinessRule.get({
                id: $scope.id
            });

            rule.$promise.then(function(data) {
                $scope.selectedRule = data;
            });
        }

        init();
    });
