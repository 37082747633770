(function () {
    'use strict';

    /**
     * script action to update sentence casing in multiple select field
     */
    angular.module('acadiamasterApp').factory('UpdateSentenceCaseMultiSelectScriptAction', function (
        ScriptActionBaseModel, FormScriptActionUtil, FormConstants) {

        // defining some local variable to make things easier
        var fsa = FormScriptActionUtil;
        var constants = FormConstants;

        /***************************************************************
         * private function
         ***************************************************************/

        /**
         * change the answer's text value's first letter to upper case, this
         * applies to english, spanish, and content description
         * @param answer - answer of type OptionsValue
         */
        function changeAnswerCase(answer) {
            // change English Value Text
            answer.text = toUpperCaseFirstChar(answer.text);

            // change English content description
            answer.contentDescription = toUpperCaseFirstChar(answer.contentDescription);

            // change spanish contents

            var esMap = answer.localizationMap ? answer.localizationMap.es : null;

            if (esMap != null) {
                // change Spanish Value Text
                esMap.text = toUpperCaseFirstChar(esMap.text);

                // change Spanish content description
                esMap.contentDescription = toUpperCaseFirstChar(esMap.contentDescription);
            }

        }

        function toUpperCaseFirstChar(inputString) {
            if (inputString==null || inputString.length==0) {
                return null;
            }

            if (inputString[0] !== inputString[0].toUpperCase()) {
                return inputString[0].toUpperCase() + inputString.substr(1);
            }
            else {
                return inputString;
            }
        }

        function searchFunction(formMode) {
            var fields = fsa.findFieldsByType(formMode, constants.fieldsType.MULTI_SELECTOR);

            var answersToApply = [];

            _.forEach(fields, function(field) {
                var answers = field.subFields[1].fieldValue.values;

                if (answers!=null && answers.length>0) {
                    _.forEach(answers, function(answer) {
                        if (isAnswerInLowerCase(answer)) {
                            answersToApply.push(answer);
                        }
                    });
                }
            });

            return answersToApply;
        }

        /**
         * check if the english text value's first letter is lower case or not
         * @param answer
         */
        function isAnswerInLowerCase(answer) {
            if (answer == null) {
                return false;
            }

            var englishAnswerValue = answer.text;

            if (englishAnswerValue == null || englishAnswerValue.length == 0) {
                return false;
            }

            return englishAnswerValue[0] !== englishAnswerValue[0].toUpperCase();
        }



        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateSentenceCasingMultiSelectScriptAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateSentenceCasingMultiSelectScriptAction() {
            this.name = 'Update all answer in multi select to have capital letter in first word';
            this.ticketId = 'AC-34131';

            this.steps = [
                {
                    description: 'find all multi select answers, if first letter of the answer is a lower case letter ' +
                    '--> ' + 'change that letter to capital case <br>' +
                    'Note: the change applies to all of the following: <ul>' +
                    '<li>English version of the answer text</li>' +
                    '<li>Spanish version of the answer text</li>' +
                    '<li>content description of the answer (english and Spanish)</li>' +

                    '</ul>' +
                    'However, the search only applies to English version of the answer text',
                    searchFunction: searchFunction,
                    changeFunction: changeAnswerCase
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateSentenceCasingMultiSelectScriptAction;
    });

})();
