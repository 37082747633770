(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form field value for reference field base
     */
        .directive('formFieldConfigFieldValueReferenceFieldBase', function (FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/viewReferenceBase.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    selector: '=',
                    field: '='
                },
                controller: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.editMode = $scope.field.findParentMode()._parent.editMode;
                }
            };

        });

})();

