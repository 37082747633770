/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 */
(function () {
    angular.module('acadiamasterApp').controller('CreateCodesFromCSVController',
        function ($scope, vbrCommonUtil, InviteCodesService, PropertyCacheService, AlertService, InviteCodesModel) {
            let vm = this;
            vm.isReady = false;
            vm.pObj = null;
            vm.result = null;
            vm.allProperties = [];
            vm.isValid = true;
            vm.parsedObject = null;
            vm.metaObj = {};
            vm.list = [];
            vm.MAX_NUMBER_OF_CODES_IN_ONE_CSV = 50000;
            vm.property = {};
            vm.propertyId = null;
            vm.getPropsObj = getPropsObj;
            vm.loadProperties = loadProperties;
            vm.extractProperties = extractProperties;
            vm.parseCsv = parseCsv;
            vm.metaDataValues = [];
            vm.parseTags = parseTags;
            vm.csv = null;
            vm.createCodesFromCSV = createCodesFromCSV;
            vm.removeDoubleQuote = removeDoubleQuote;
            vm.createDtoFromCSVObject = createDtoFromCSVObject;
            vm.validateObject = validateObject;
            vm.promise = null;
            vm.object = null;
            vm.objTest = null;

            function init () {
                loadProperties();
                vm.csv = vbrCommonUtil.defaultCSVConfig();
                vm.isReady = true;
            }

            init();

            function createCodesFromCSV () {
                vm.parsedObject = vm.parseCsv();

                if (vm.parsedObject.isValid) {
                    return InviteCodesService.createNewCodesFromList(vm.parsedObject.list)
                        .then(() => {
                            $scope.closeThisDialog();
                            $scope.loadAll();
                        });
                }

                return null;
            }

            /*
             * method to parse csv data and convert it to list if server dto
             */
            function parseCsv () {
                let dtoList = null;
                vm.isValid = true;

                if (!vm.csv.result || vm.csv.result.length === 0) {
                    AlertService.warning('There is nothing in uploaded CSV file');
                    vm.isValid = false;
                } else {
                    // list to return
                    dtoList = [];
                    let inviteCodeCount = 0;
                    for (let i = 0; i < vm.csv.result.length; i++) {
                        // extract row from csv and convert it to dto
                        vm.object = vm.csv.result[i];
                        vm.object.inviteCodeTagMappings = vm.object.tags;
                        vm.object.programCode = vm.object.program;
                        vm.objTest = new InviteCodesModel();
                        vm.objTest.fromDto(vm.object);
                        let dto = createDtoFromCSVObject(vm.objTest, i);

                        // if dto is null means something is missing in csv row
                        if (dto === null) {
                            vm.isValid = false;
                            break;
                        }
                        dtoList.push(dto);

                        // sum total number of codes to validate later
                        inviteCodeCount = inviteCodeCount + dto.numberOfCodes;
                    }

                    // check if total number of codes is greater than 50000
                    if (inviteCodeCount > vm.MAX_NUMBER_OF_CODES_IN_ONE_CSV) {
                        vm.isValid = false;
                        AlertService.error(`Total number of codes can't be more than ${ vm.MAX_NUMBER_OF_CODES_IN_ONE_CSV}`);
                    }
                }

                return {
                    isValid : vm.isValid,
                    list    : dtoList,
                };
            }

            /*
             * convert row data to server dto
             */
            function createDtoFromCSVObject (obj, index) {
                if (!validateObject(obj, index)) {
                    return null;
                }
                let dto = obj.toDto();

                // tags
                dto.tags = parseTags(obj.tags);

                // properties in metaData
                dto.metaData = vm.extractProperties(obj);

                return dto;
            }

            function validateObject (obj, index) {
                let rowNumber = index + 2;

                if (obj === null) {
                    AlertService.error(`Row can't be empty, Row #${ rowNumber}`);
                    return false;
                }

                if (obj.usageType == null) {
                    AlertService.error(`Invite code type can't be empty in row ${ rowNumber}`);
                    return false;
                }

                if (obj.usageType === 'PROGRAM_SUBSCRIPTION' && (!obj.program || obj.program === null || obj.program.length === 0)) {
                    AlertService.error(`Program code can't be empty in row ${ rowNumber}`);
                    return false;
                }

                if (obj.numberOfCodes == null || obj.numberOfCodes === 0) {
                    AlertService.error(`Number of codes can't be empty or 0 in row ${ rowNumber}`);
                    return false;
                }
                return true;
            }

            /*
             * parse comma(',') separated list of tags and return list of tags object
             */
            function parseTags (tagStr) {
                if (tagStr === null || tagStr.trim().length === 0) {
                    return null;
                }

                let delimiter = ',';
                let l = tagStr.split(delimiter);

                let list = [];
                _.forEach(l, tag => {
                    // trim and replace extra double quotes added by CSV cell
                    list.push({ name : tag.trim().replace(/"/g, '') });
                });
                return list;
            }

            /*
             * extract properties from (p1_name, p1_value), (p2_name, p2_value), etc columns from csv
             */
            function extractProperties (obj) {
                vm.list = [];
                vm.metaDataValues = [];
                if (obj.metaData && obj.metaData.length > 0) {
                    if (obj.metaData.indexOf('|') !== -1) {
                        vm.metaDataValues = obj.metaData.split('|');
                    } else {
                        vm.metaDataValues.push(obj.metaData);
                    }
                    _.forEach(vm.metaDataValues, mData => {
                        vm.metaObj = getPropsObj(mData);
                        if (vm.metaObj.propertyName != null && vm.metaObj.propertyName.length > 0
                            && vm.metaObj.propertyValue != null && vm.metaObj.propertyValue.length > 0) {
                            vm.list.push(vm.metaObj);
                        }
                    });
                }
                return {
                    profilePropertyValues : vm.list,
                };
            }

            /*
             * Removing double quotes from values that have comma in them
             * 34 is char value for double quote
             */
            function removeDoubleQuote (propertyValue) {
                let trimmedValue = propertyValue.trim();
                let length = trimmedValue.length;

                if (trimmedValue.charCodeAt(0) == 34 && trimmedValue.charCodeAt(length - 1) == 34) {
                    return trimmedValue.substring(1, length - 1);
                }
                return trimmedValue;
            }
            /*
             load all Properties
             */
            function loadProperties () {
                // this is not a force reload of all the properties, so if properties have changed
                vm.promise = PropertyCacheService.loadProperties(false);

                vm.promise.then(properties => {
                    vm.allProperties = properties;
                }).catch(error => {
                    AlertService.error(`failed to load properties list : ${ error}`);
                });
            }
            function getPropsObj (metaData) {
                vm.pObj = {};
                vm.result = metaData.split('-');
                if (vm.result.length > 1) {
                    vm.propertyId = Number(vm.result[0].replace('Property', ''));
                    vm.property = _.filter(vm.allProperties, ppv => {
                        return ppv.id == vm.propertyId;
                    });
                    if (vm.property.length > 0) {
                        const propValue = removeDoubleQuote(vm.result[1]);
                        vm.pObj = {
                            propertyName  : vm.property[0].name,
                            propertyValue : propValue,
                        };
                    }

                    return vm.pObj;
                }

                return vm.pObj;
            }
        });
}());
