'use strict';

angular.module('acadiamasterApp')
    .controller('ParticipantProfileDetailController', function ($scope, $state, $stateParams, ngDialog, AlertService,
        PasswordResetT2, ParticipantProfileService, Principal, SSOService) {

        $scope.cs = {
            LANGUAGES: {
                'en': 'English',
                'es': 'Spanish'
            }
        };

        $scope.data = {
            isEmailValid: false,
            isSaving: true,
            isKeyCloakEnable: SSOService.isEnable(),
            savedUser: {
                email: '',
                verifiedPrimaryPhoneNumber: '',
                userPreferences: {
                    emailNotifications: false,
                    smsNotifications: false
                }
            },
            user: {
                userPreferences: {
                    emailNotifications: false,
                    smsNotifications: false
                }
            },
            isUserAdmin: false
        };

        checkIsAdmin();
        function checkIsAdmin() {
            Principal.identity()
                .then(function (account) {
                    if (account) {
                        $scope.data.isUserAdmin = Principal.ifAnyAuthority(['ROLE_ADMIN']);
                    }
                });
        }

        $scope.load = function (id) {
            $scope.data.isSaving = true;
            ParticipantProfileService.getParticipant(id)
                .then(function (result) {
                    $scope.data.savedUser.email = result.data.email || '';
                    $scope.data.savedUser.verifiedPrimaryPhoneNumber = result.data.verifiedPrimaryPhoneNumber || '';
                    $scope.data.savedUser.userPreferences.emailNotifications = result.data.userPreferences.emailNotifications;
                    $scope.data.savedUser.userPreferences.smsNotifications = result.data.userPreferences.smsNotifications;

                    // change undefined values to empty strings to that we can more easily detect changes
                    if(result.data.email === undefined) {
                        result.data.email = '';
                    }
                    if(result.data.verifiedPrimaryPhoneNumber === undefined) {
                        result.data.verifiedPrimaryPhoneNumber = '';
                    }
                    $scope.data.user = result.data;
                    $scope.data.isSaving = false;
                });
        };

        $scope.sendPasswordReset = function () {
            $scope.data.sendResetViaEmail =
                $scope.data.savedUser.email &&
                $scope.data.savedUser.userPreferences.emailNotifications;
            $scope.data.sendResetViaSMS =
                $scope.data.savedUser.verifiedPrimaryPhoneNumber &&
                $scope.data.savedUser.userPreferences.smsNotifications;

            ngDialog.openConfirm({
                template: 'admin-templates/site/participant/profile/detail/emailConfirmationDialog.html',
                scope: $scope,
                size: 'md',
                className: 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.confirmSendReset = function () {
                        $scope.closeThisDialog();
                        $scope.data.isSaving = true;
                        var params = {
                            sendEmail: !!$scope.data.sendResetViaEmail,
                            sendSMS: !!$scope.data.sendResetViaSMS
                        };
                        PasswordResetT2.save($stateParams.id, params).then(function () {
                            AlertService.success('Password reset sent successfully.');
                            $scope.data.isSaving = false;
                        }, function (err) {
                            AlertService.error('Error sending password reset. Please try again.');
                            $scope.data.isSaving = false;
                            console.error(err);
                        });
                    };
                }]
            });
        };

        $scope.saveUserProfile = function (form) {
            if (form.$valid) {
                var id = $scope.data.user.id;
                var data = $scope.data.user;
                if(Object.keys(data).length > 0) {
                    $scope.data.isSaving = true;
                    ParticipantProfileService.updateUserProfile(id, data)
                        .then(function () {
                            AlertService.success('User profile has been updated.');
                            $scope.load(id);
                        }).catch(function (error) {
                            $scope.data.isSaving = false;
                            console.error(error);
                            $scope.closeThisDialog('ERROR');
                        });
                }
            }
        };

        $scope.unlockAccount = function (id) {
            $scope.data.isSaving = true;
            ParticipantProfileService.unlockUser(id)
                .then(function () {
                    AlertService.success('User\'s account has been unlocked');
                    $scope.load(id);
                }).catch(function (error) {
                    AlertService.error('Error unlocking user\'s account. Please try again.');
                    $scope.data.isSaving = false;
                    console.error(error);
                });
        };

        $scope.resetSecurityQuestions = function (id) {
            ngDialog.openConfirm({
                template: 'admin-templates/site/participant/profile/detail/resetSecurityQuestionsConfirmDialog.html',
                scope: $scope,
                size: 'md',
                className: 'ngdialog-theme-plain custom-width-medium',
                controller: ['$scope', function ($scope) {
                    $scope.confirmResetSecurityQuestions = function () {
                        $scope.closeThisDialog();
                        $scope.data.isSaving = true;
                        ParticipantProfileService.resetSecurityQuestions(id)
                            .then(function () {
                                AlertService.success('User\'s security questions have been reset');
                                $scope.load(id);
                            }).catch(function (error) {
                                AlertService.error('Error resetting user\'s security questions. Please try again.');
                                $scope.data.isSaving = false;
                                console.error(error);
                            });
                    };
                }]
            });
        };

        $scope.haveValuesChanged = function() {
            var hasEmailChanged = $scope.data.user.email !== $scope.data.savedUser.email;
            var hasPhoneChanged = $scope.data.user.verifiedPrimaryPhoneNumber !== $scope.data.savedUser.verifiedPrimaryPhoneNumber;
            var haveUserPrefsChanged =
                $scope.data.user.userPreferences.emailNotifications !==
                    $scope.data.savedUser.userPreferences.emailNotifications ||
                $scope.data.user.userPreferences.smsNotifications !==
                    $scope.data.savedUser.userPreferences.smsNotifications;
            return hasEmailChanged || hasPhoneChanged || haveUserPrefsChanged;
        };

        $scope.phoneNumberValidation = (function() {
            return {
                test : function(value) {
                    if (value.startsWith('+')) {
                        const intlRegExp = /^\+[1-9]\d{1,14}$/;
                        return intlRegExp.test(value);
                    } else {
                        const usRegExp = /^\d{10}$/;
                        return usRegExp.test(value);
                    }
                },
            };
        })();

        $scope.onBackClicked = function () {
            $state.go('participantProfile');
        };

        $scope.load($stateParams.id);
    });
