angular.module('acadiamasterApp')
    .factory('authInterceptor', ($rootScope, $q, $location, localStorageService, SSOService) => {
        const timeInSeconds = 900;

        return {
            // Add authorization token to headers
            request(config) {
                config.headers = config.headers || {};

                if (SSOService.isEnable()) {
                    if (window.keycloakAuth.isTokenExpired(timeInSeconds)) {
                        window.keycloakAuth.updateToken(timeInSeconds).then(refreshed => {
                            if (refreshed) {
                                console.log(`refreshed ${new Date()}`);
                                document.cookie = `token=${window.keycloakAuth.token}; expires=${new Date(window.keycloakAuth.tokenParsed.exp * 1000).toUTCString()}; Secure; path=/; SameSite=Lax;`;
                            }
                        });
                    }

                    const barerToken = window.keycloakAuth.token;

                    config.headers.Authorization = `Bearer ${barerToken}`;
                } else {
                    const token = localStorageService.get('token');
                    config.headers['x-auth-token'] = token;
                }

                return config;
            },
        };
    });
