(function () {
    'use strict';

    /**
     * directive for displaying a summary of specific form version
     */
    angular.module('acadiamasterApp').directive('formVersionSummary', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/form/pdfReplace/formVersionSummary.html',
            scope: {
                formVersion: '='
            }
        };
    });
})();

