import {
    fieldDisplayConfig,
    optionsBasicConfig,
    optionsDisplayConfig,
    titleDisplayConfig
} from './multiselect.preset.config';

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('multiSelectPresetConfig', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/presetHelp/multiselect.preset.config.html',
                scope: {},
                link: function ($scope) {
                    $scope.fieldDisplayConfig = fieldDisplayConfig;
                    $scope.titleDisplayConfig = titleDisplayConfig;
                    $scope.optionsDisplayConfig = optionsDisplayConfig;
                    $scope.optionsBasicConfig = optionsBasicConfig;
                }
            };
        });

})();

