(function () {
    'use strict';

    /**
     * directive for selecting reusable expression
     */
    angular.module('acadiamasterApp')
        .directive('reusableExpressionConditionSelection', function (ExpressionConditionService, AlertService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/common/directive/expressionConditionSelection.html',
                scope: {
                    expressionOptions: '=',
                    isRequired: '=',
                    titleText: '@'
                },
                link: function ($scope) {
                    $scope.data = {
                        allExpressions: null,
                        expression: $scope.expressionOptions.getExpressionCondition(),
                        showSelectedExpressionDetails: $scope.expressionOptions.showSelectedExpressionDetails
                    };
                    init($scope);
                }
            };

            function init($scope) {
                if ($scope.expressionOptions.programId == null) {
                    AlertService.error('Program id is not passed in to directive');
                    return;
                }

                // add short description
                addShortDescription($scope.data.expression);

                const params = {
                    ascending: true,
                    orderBy: 'name',
                    page: 0,
                    programId: $scope.expressionOptions.programId,
                    size: 100000
                };

                // this is not a false reload of all the properties
                ExpressionConditionService.loadConditions(params).then(function (response) {
                    var expressions = [];
                    _.forEach(response.data, function(e) {
                        if(e && $scope.expressionOptions.expressionIdsToAvoid
                            && $scope.expressionOptions.expressionIdsToAvoid.indexOf(e.id) === -1) {
                            addShortDescription(e);
                            expressions.push(e);
                        }
                    });
                    $scope.data.allExpressions = expressions;
                }).catch(function (error) {
                    AlertService.error('failed to load expression conditions', null, error);
                });

                addWatches($scope);
            }

            function addShortDescription(e) {
                if(e){
                    e.shortDescription = e.id + ' | ' + e.name;
                }
            }

            function addWatches($scope) {
                $scope.$watch('data.expression', function (newValue) {
                    $scope.expressionOptions.setExpressionCondition(newValue);
                });
            }
        });
})();
