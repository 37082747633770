(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('AgreementLoadUtil', function (AgreementsService, AlertService) {

        return {
            loagAgreement: loagAgreement
        };

        function loagAgreement(containerWithAgreement) {
            if (requiredFunctionNotDefined(containerWithAgreement)) {
                AlertService.error('container with agreement is not valid', null, containerWithAgreement);
                return;
            }

            var agreementId = containerWithAgreement.agreementId;
            var agreement = containerWithAgreement.getAgreement();

            if (agreementId == null) {
                // nothing to load
                return;
            }

            if (agreement != null && agreement.id === agreementId) {
                // already loaded, do nothing
                return;
            }

            AgreementsService.getAgreement({agreementId: agreementId}).then(function(data) {
                containerWithAgreement.setAgreement(data.data)
            }).catch(function(error) {
                AlertService.error('failed to load agreement by id : ' + agreementId, null, error);
            });
        }

        function requiredFunctionNotDefined(container) {
            return !_.isFunction(container.getAgreement) || !_.isFunction(container.setAgreement);
        }

    });
})();
