(function() {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * Value type util service
     */
    app.factory('ValueTypeUtil', function(ValueType) {

        /**
         * convert a valid value into a string
         * @param value - value
         */
        function valueConvertedToString(value) {
            if (value==null) {
                return value;
            }

            return '' + value;
        }

        /**
         * convert a valid value list into a string list
         * @param values - values
         */
        function valueListConvertedToStringList(values) {
            if (values==null) {
                return values;
            }

            var convertedList = [];

            _.forEach(values, function(v) {
                convertedList.push(valueConvertedToString(v));
            });

            return convertedList;
        }

        /**
         * convert a string to a valid value based on value type, ie: ('23', NUMBER) --> 23
         * @param valueAsString - value as string
         * @param valueType - value type
         */
        function stringValueConvertedToType(valueAsString, valueType) {
            if (valueAsString==null || valueAsString.length==0) {
                return null;
            }

            if (valueType==null) {
                return valueAsString;
            }

            var vtName = valueType.name == null ? valueType : valueType.name;

            if (vtName == ValueType.BOOLEAN) {
                return valueAsString.toLowerCase() === 'true';
            }

            if (vtName == ValueType.NUMBER || vtName == ValueType.DATETIME) {
                return Number(valueAsString);
            }

            return valueAsString;
        }

        /**
         * convert a string list to a valid value list based on value type, ie: (['23', '34'], NUMBER) --> [23, 34]
         * @param valueListAsString - value list as string list
         * @param valueType - value type
         */
        function stringValueListConvertedToType(valueListAsString, valueType) {
            if (valueListAsString==null || valueListAsString.length==0) {
                return null;
            }

            var returnList = [];

            _.forEach(valueListAsString, function(v) {
                returnList.push(stringValueConvertedToType(v, valueType));
            });

            return returnList;
        }

        return {
            valueConvertedToString : valueConvertedToString,
            valueListConvertedToStringList : valueListConvertedToStringList,
            stringValueConvertedToType : stringValueConvertedToType,
            stringValueListConvertedToType : stringValueListConvertedToType,
        }
    })
})();