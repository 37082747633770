(function () {


    angular.module('acadiamasterApp')
        .directive('additionalParamConfig', function (ExpressionConstantsService, ParameterConfigModelService) {

            function init($scope) {
                $scope.addParam = false;
            }

            function updateParametersList($scope, index) {
                if($scope.addParam === true) {
                    $scope.parametersList.push($scope.param);
                }
                else {
                    $scope.parametersList.splice(index, 1);
                }
            }

            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/dataSharing/additionalParam.config.html',
                scope       : {
                    parentForm     : '=',
                    parametersList : '=',
                    isAgreement    : '=',
                    data           : '=',
                },
                link : function ($scope) {
                    $scope.expConstants = ExpressionConstantsService;
                    init($scope);

                    $scope.addParameter = function () {
                        $scope.param = new ParameterConfigModelService.ParameterConfigModel();
                        $scope.addParam = true;
                        updateParametersList($scope);
                    };

                    $scope.removeParameter = function (index) {
                        $scope.addParam = false;
                        updateParametersList($scope, index);
                    };

                    $scope.getRulesType = function () {
                        if($scope.isAgreement == true) {
                            return ExpressionConstantsService.modules.RULES;
                        }
                        return ExpressionConstantsService.modules.STATE_RULES;
                    };
                },
            };
        });

})();
