(function () {
    'use strict';

    /**
     * form search service, all the business logic is here
     */
    angular.module('acadiamasterApp').factory('FormSearchService', function (FormUtilService, FormDisplaySearchService, AlertService, FormConstants) {
        var constants = FormConstants;
        /***************************************
         * service return call
         ***************************************/

        return {
            doSearch : doSearch
        };

        /***************************************************************
         * service return functions
         ***************************************************************/


        /**
         * perform the search and update the search model with results
         * @param formSearchModel - form search model object
         * @param formVersion - form version where the search should start
         */
        function doSearch(formSearchModel, formVersion) {
            formSearchModel.resetResults();

            var rootElement = getRootSearchElement(formSearchModel.searchMode, formVersion);

            var searchString = formSearchModel.searchString;
            var searchLocalId = null;

            if (searchString == '') {
                searchString = null;
            }
            else {
                searchString = searchString.toLowerCase();
                if (!isNaN(searchString)) {
                    searchLocalId = +searchString;
                }
            }

            var searchType = formSearchModel.searchType;

            // now call the search function on the model class which will recursively search through all the values
            rootElement.doSearch(formSearchModel.searchResults, searchType, searchString, searchLocalId);

            // filter by field type if needed
            formSearchModel.searchResults = filterByType(formSearchModel.searchResults, formSearchModel.fieldType);

            // filter by sub field type if needed
            formSearchModel.searchResults = filterByType(formSearchModel.searchResults, formSearchModel.subFieldType);

            // check if advanced search filter are turned on, if so, then we'll filter on them as well
            if (formSearchModel.isAdvancedChoiceEnabled()) {
                if (formSearchModel.displayConfigFilter!=null) {
                    formSearchModel.searchResults = FormDisplaySearchService.filter(formSearchModel.displayConfigFilter, formSearchModel.searchResults);
                }
            }

            // perform the complete setup after the result has been finalized
            formSearchModel.finalizeResults();

            AlertService.success(formSearchModel.searchResults.length + ' items found');
        }


        /***************************************************************
         * private functions
         **************************************************************/

        /**
         * filter an array by field/subField type
         * @param searchResults - original search result
         * @param fieldTypeName - name of the field/subField type
         * @return {array} - filtered search result that matches the type
         */
        function filterByType(searchResults, fieldTypeName) {
            if (fieldTypeName == null || searchResults==null || searchResults.length == 0) {
                // return the original list if list is empty or field type is null
                return searchResults;
            }

            return _.filter(searchResults, function(result) {
                return result.type && result.type.name == fieldTypeName;
            });
        }

        /**
         * getting the root element to start the search from
         * @param searchMode - node type of the form mode to search against, nullable
         * @param formVersion - form version to search inside
         * @returns {*} - root element to search from, it's either the form version itself or one of the
         *                form modes specified by search mode
         */
        function getRootSearchElement(searchMode, formVersion) {
            var rootElement;

            if (searchMode != null && searchMode != '') {
                if (searchMode == constants.nodeType.MODE.EDIT) {
                    rootElement = formVersion.editMode;
                }
                else if (searchMode == constants.nodeType.MODE.VIEW_ENTRY) {
                    rootElement = formVersion.viewMode;
                }
                else {
                    rootElement = formVersion.promptMode;
                }
            }
            else {
                rootElement = formVersion;
            }

            return rootElement;
        }

    });

})();
