(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('addressVerificationConfig', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/addressVerification/addressVerification.config.html',
                scope: {
                    parentForm: '=',
                    page: '='
                },
                link: function ($scope) {
                    // Wrapper directive for address verification page type config
                }
            };
        });

})();


