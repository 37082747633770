export const DefaultLabelConfig = {
    provideConsentButton: {
        en: 'Provide Consent',
        es: 'Proveer su Consentimiento',
    },
    resumeConsentButton: {
        en: 'Resume Consent',
        es: 'Continuar Consentimiento',
    },
};
