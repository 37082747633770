(function () {
    'use strict';

    const app = angular.module('acadiamasterApp');

    app.factory('ActionConfigEvaluatePersonalizedContainerModel',
        function (RuleConstantsService, RuleActionBaseModelService, ContainerLoadUtil) {
            const ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigEvaluatePersonalizedContainerModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigEvaluatePersonalizedContainerModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.EVALUATE_PERSONALIZED_CONTAINER;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigEvaluatePersonalizedContainerModel;
                this.containerId = null;
                this.recalculateAllItemsInside = false;

                this._container = null;
                return this;
            }

            ActionConfigEvaluatePersonalizedContainerModel.prototype.getContainer = function() {
                return this._container;
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype.setContainer = function(newContainer) {
                this._container = newContainer;
                this.containerId = newContainer == null ? null : newContainer.id;
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype.getProgramId = function() {
                const rootElement = this.getRoot();
                return rootElement==null ? null : rootElement.programId;
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                const actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.containerId = actionConfig.containerId;
                    this.recalculateAllItemsInside = actionConfig.recalculateAllItemsInside;
                } else {
                    this.containerId = null;
                }

                ContainerLoadUtil.loadContainer(this);
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype.toDto = function (files) {
                const dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                const actionConfig = dto.actionConfig;
                actionConfig.containerId = this.containerId;
                actionConfig.recalculateAllItemsInside = this.recalculateAllItemsInside;
                return dto;
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let desc = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                desc = desc + ' | ' + this.containerId;

                if (this._container != null) {
                    desc += ' | ' + this._container.name + ' | ' + this._container.containerType;
                }

                return desc;
            };

            ActionConfigEvaluatePersonalizedContainerModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.containerId == null) {
                    this.setErrorMessage('A container should be selected');
                }
            };

            return ActionConfigEvaluatePersonalizedContainerModel;
        }
    );
})();
