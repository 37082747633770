/**
 * Created by Jamie Nola on 02/17/2019
 */
import template from './sortableTableHeader.template.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrSortableTableHeader', function () {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    displayName: '@',
                    sortable: '<',
                    disabled: '<?',
                    active: '<',
                    ascending: '<',
                    onClick: '&',
                    linkTheme: '@?',
                    isHidden: '<?'
                },
                controller: function($scope) {
                    $scope.translationData = {
                        name: $scope.displayName
                    };
                }
            };
        });
})();
