(function () {
    'use strict';

    /**
     * directive for Form Page Comparison
     */
    angular.module('acadiamasterApp').directive('formComparePage', function (CompareResultUtilService,
                                                                             FormConstants) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/container/formComparePage.html',
            scope: {
                compareResult: '=',
                showChangeOnly : '='
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;
                $scope.constants = FormConstants;
                $scope.showHeader = function() {
                    var compareResult = $scope.compareResult;
                    var showChangeOnly = $scope.showChangeOnly;

                    return CompareResultUtilService.shouldShowObject(compareResult.detail._status, showChangeOnly)
                        || CompareResultUtilService.shouldShowObject(compareResult.displayConfig._status, showChangeOnly);
                };
            }
        };
    });

})();

