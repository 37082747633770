(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('HasRuleBeenExecutedCondition', function (QObjectModel, ProgramTestConstantsService,
                                                          RuleLoadUtil, TimeComparisonOperatorService) {

        HasRuleBeenExecutedCondition.inheritsFrom(QObjectModel);

        function HasRuleBeenExecutedCondition(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.HAS_RULE_BEEN_EXECUTED);

            //initialized the variables
            this.ruleId = null;
            this.workflowId = null;
            this.expressionId = null;

            this.comparator = ProgramTestConstantsService.operators.IS_NULL;
            this.ruleExecutionStatusList = null;

            this.checkWithTime = false;

            // time check related fields
            // check if the time is absolute or not
            this.absolute = false;
            // comparator for the time check
            this.timeComparator = TimeComparisonOperatorService.GTE;
            // this value is either the absolute timestamp value (epoch in millisecond) or the
            // diff value from current timestamp
            this.value = 0;
            this.valueBeginAt = 0;
            this.valueEndAt = 0;
            this.timeUnit = ProgramTestConstantsService.TimeUnit.DAYS;

            this._rule = null;
        }

        HasRuleBeenExecutedCondition.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.ruleId = this.ruleId;
            dto.workflowId = this.workflowId;
            dto.expressionId = this.expressionId;
            dto.comparator = this.comparator == null ? null : this.comparator.name;
            dto.ruleExecutionStatusList = (this.ruleExecutionStatusList != null && this.ruleExecutionStatusList.length) ? this.ruleExecutionStatusList.map(function (item) {
                return item.name;
            }) : null;
            dto.checkWithTime = this.checkWithTime;
            // fields for checking time
            dto.absolute = this.absolute;
            dto.timeComparator = this.timeComparator == null ? null : this.timeComparator.name;
            dto.value = convertDateValueToNumberIfNeeded(this.absolute, this.value);
            dto.valueBeginAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueBeginAt);
            dto.valueEndAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueEndAt);
            dto.timeUnit = this.absolute ? null : this.timeUnit;

            return dto;
        };

        HasRuleBeenExecutedCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.ruleId = dto.ruleId;
            this.workflowId = dto.workflowId;
            this.expressionId = dto.expressionId;

            RuleLoadUtil.loadRule(this);
            this.checkWithTime = dto.checkWithTime;

            // fields for checking time
            this.absolute = dto.absolute;
            this.timeComparator = ProgramTestConstantsService.getObjectByName(
                TimeComparisonOperatorService, dto.timeComparator);
            this.value = convertNumberValueToDateIfNeeded(dto.absolute, dto.value);
            this.valueBeginAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueBeginAt);
            this.valueEndAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueEndAt);
            this.timeUnit = dto.absolute ? null : dto.timeUnit;

            // execution status comparison
            this.setComparator(ProgramTestConstantsService.getObjectByName(ProgramTestConstantsService.operators, dto.comparator));
            this.ruleExecutionStatusList = (dto.ruleExecutionStatusList && dto.ruleExecutionStatusList.length > 0) ? dto.ruleExecutionStatusList.map(function (item) {
                return { name: item, text: item };
            }) : [];
        };

        HasRuleBeenExecutedCondition.prototype.getComparator = function () {
            return this.comparator;
        };

        HasRuleBeenExecutedCondition.prototype.setComparator = function (comparator) {
            this.comparator = comparator;
            this.ruleExecutionStatusList = [];
        };

        HasRuleBeenExecutedCondition.prototype.setRule = function (rule) {
            this._rule = rule;
            this.ruleId = rule == null ? null : rule.id;

            if (rule == null) {
                this.workflowId = null;
                return;
            }

            var workflow = getWorkFlowById(rule.workflows, this.workflowId);
            if (workflow != null) {
                this.workflowId = workflow.id;
            }

            this._validateSelf();
        };

        HasRuleBeenExecutedCondition.prototype.getRule = function () {
            return this._rule;
        };

        /**
         * set absolute time flag, and will change the value and time unit if needed
         */
        HasRuleBeenExecutedCondition.prototype.setAbsoluteTime = function (absoluteTime) {
            this.absolute = absoluteTime;

            if (this.value == null) {
                return;
            }

            this.valueBeginAt = null;
            this.valueEndAt = null;

            this.timeUnit = this.absolute ? null : ProgramTestConstantsService.TimeUnit.DAYS;

            if (this.absolute && !(this.value instanceof Date)) {
                // absolute value and not a date, set it to local date now
                this.value = new Date();
            }
            else if (!this.absolute && (this.value instanceof Date)) {
                this.value = 0;
            }
        };

        HasRuleBeenExecutedCondition.prototype.getAbsoluteTime = function () {
            return this.absolute;
        };

        HasRuleBeenExecutedCondition.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var ruleMsg = 'Rule (' + (this.ruleId == null ? 'no id' : this.ruleId) + ' | ' +
                (this._rule == null ? 'no name' : this._rule.name) + ')';

            let ruleExecutionStatusMsg = '';
            if (this._rule != null) {
                var workflow = getWorkFlowById(this._rule.workflows, this.workflowId);
                var workFlowMessage = workflow == null ? '' :
                    ('workflow (<span class="badge">' + workflow.id + '</span> | ' + workflow.name + ')');

                if (workflow != null) {
                    var expression = getExpressionById(workflow.expressionFixedNodes[0].expressions, this.expressionId);
                    var expressionMessage = expression == null ? '' :
                        ('expression (<span class="badge">' + expression.id + '</span> | ' + expression.name + ')');
                }

                ruleExecutionStatusMsg = ' | Rule Execution Status <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) + '</span> ';

                if (this.comparator === ProgramTestConstantsService.operators.IS_NULL || !this.ruleExecutionStatusList || this.ruleExecutionStatusList.length <1) {
                    ruleExecutionStatusMsg = ruleExecutionStatusMsg + '';
                } else if (this.ruleExecutionStatusList && this.ruleExecutionStatusList.length > 0) {
                    ruleExecutionStatusMsg = ruleExecutionStatusMsg + this.ruleExecutionStatusList.map(item => item.text).join(', ');
                }

            }

            // time messages
            const timeComparatorMsg = ' executed <span class="badge">' + (this.timeComparator == null ? '?' : this.timeComparator.description) + '</span> ';
            let timeMsg = timeMsgBuilder(this, this.value);
            const timeMsgBegin = timeMsgBuilder(this, this.valueBeginAt);
            const timeMsgEnd = timeMsgBuilder(this, this.valueEndAt);
            if(this.timeComparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                timeMsg = timeMsgBegin + ' and ' + timeMsgEnd;
            }
            timeMsg = this.absolute ? timeMsg : timeMsg + ' ago';

            return baseMsg + ' ' + (expression == null ? '' : expressionMessage + ' of ') +
                (workflow == null ? '' : workFlowMessage + ' of ') + ruleMsg +
                (this.comparator === ProgramTestConstantsService.operators.IS_NULL ? '' : ruleExecutionStatusMsg) +
                (this.checkWithTime === false ? '' : (timeComparatorMsg + timeMsg));
        };

        HasRuleBeenExecutedCondition.prototype._validateSelf = function () {
            this.clearError();

            if (this.ruleId == null) {
                this.setErrorMessage('Rule id is required');
            }

            if (this.comparator == null) {
                this.setErrorMessage('Comparator is required');
            }

            if (this.comparator !== ProgramTestConstantsService.operators.IS_NULL &&
                (this.ruleExecutionStatusList == null || this.ruleExecutionStatusList.length === 0)) {
                this.setErrorMessage('Rule execution status list is required except when comparator is "is null"');
            }

            // if checkWithTime is false, do not need time configuration
            if(!this.checkWithTime) {
                return;
            }

            if((this.timeComparator.name !== 'BETWEEN' && this.value == null)
                || (this.timeComparator.name === 'BETWEEN' && (this.valueBeginAt == null || this.valueEndAt == null))) {
                this.setErrorMessage('Valid time values are required.');
            }

            if (!this.absolute) {
                if(this.valueBeginAt < this.valueEndAt) {
                    this.setErrorMessage('begin time must larger than or equal to end time');
                }

                // relative time, value should be >=0 and time unit is not null
                if (this.value < 0 || this.valueBeginAt < 0 || this.valueEndAt < 0) {
                    this.setErrorMessage('relative value must be 0 or positive');
                }

                if (this.timeUnit == null) {
                    this.setErrorMessage('time unit is required');
                }
            } else {
                // as the same variables are used for the relative and absolute time, the use of beginAt and endAt gets reversed.
                // for example,
                //    If absolute = true then beginAt < endAt because these are timestamps
                //    if absolute = false the beginAt > endAt because the days are moved back(ago)
                if('BETWEEN' === this.timeComparator.name && this.valueEndAt < this.valueBeginAt) {
                    this.setErrorMessage('begin time must smaller than or equal to end time');
                }
            }
        };

        /***************************************
         * private functions
         ***************************************/
        function getWorkFlowById(workflows, id) {
            if (id == null || workflows == null || workflows.length == 0) {
                return null;
            }

            return _.find(workflows, function (w) {
                return w != null && w.id == id;
            });
        }


        function getExpressionById(expressions, id) {
            if (id == null || expressions == null || expressions.length == 0) {
                return null;
            }

            return _.find(expressions, function (e) {
                return e != null && e.id == id;
            });
        }

        /**
         * converting a value into a local date time if necessary
         * @param isDate - flag to indicate if we should convert to date
         * @param numberValue - numerical value of a possible date in GMT timezone, ie: 1970-01-01 00:00 GMT = 0
         * @return {*} - the value back or a date object with value in local timezone
         */
        function convertNumberValueToDateIfNeeded(isDate, numberValue) {
            if (!isDate || numberValue == null) {
                return numberValue;
            }

            if (isNaN(numberValue)) {
                // should never happen
                console.warn('unable to convert value to date', numberValue);
                return numberValue;
            }

            return new Date(numberValue);
        }

        /**
         * convert a possible date value to the time number in GMT
         * @param isDate - flag to indicate if it's date value or not
         * @param dateValue - a normal numeric value or a date object in local time zone
         * @returns {*} - time value of the date value converted into GMT timestamp in epoch
         */
        function convertDateValueToNumberIfNeeded(isDate, dateValue) {
            if (!isDate || dateValue == null) {
                return dateValue;
            }

            return dateValue.getTime();
        }

        function timeMsgBuilder(condition, value) {
            return condition.absolute ? getAbsoluteTime(value) : getRelativeTime(value, condition.timeUnit);
        }

        function getAbsoluteTime(dateValue) {
            if (dateValue == null) {
                return '?';
            }

            if (_.isFunction(dateValue.toUTCString)) {
                return dateValue.toUTCString();
            }

            return '?';
        }

        function getRelativeTime(relativeValue, timeUnit) {
            var msg = relativeValue == null ? '?' : '' + relativeValue;

            msg += ' ' + timeUnit;

            return msg;
        }

        /***************************************
         * service return call
         ***************************************/
        return HasRuleBeenExecutedCondition;

    });
})();
