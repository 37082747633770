/**
 * directive for form navigation node with edges
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('formNavNodeConfig', function (ExpressionConstantsService, FormNavigationConfigUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/navigation/formNavNode.config.html',
            scope: {
                formMode: '=',
                node: '=',
                selector: '='
            },
            link: function ($scope) {
                $scope.expConstants = ExpressionConstantsService;

                $scope.moveEdgeUp = FormNavigationConfigUtil.moveEdgeUp;
                $scope.moveEdgeDown = FormNavigationConfigUtil.moveEdgeDown;

                /**
                 * show a window to add navigation node with some preset value
                 * @param nodeFrom - node from preset
                 * @param nodeTo - node to preset
                 */
                $scope.showAddNavigationEdgeWindow = function(nodeFrom, nodeTo) {
                    FormNavigationConfigUtil.showAddNavigationEdgeWindow(nodeFrom, nodeTo, $scope.formMode);
                };

                /**
                 * editing an edge
                 * @param edge - edge to be edited
                 */
                $scope.showEditNavigationEdgeWindow = function(edge) {
                    FormNavigationConfigUtil.showEditNavigationEdgeWindow(edge, $scope.formMode);
                };

                $scope.getEdgeDescriptionStart = FormNavigationConfigUtil.getEdgeDescriptionStart;
                $scope.getEdgeDescriptionEnd = FormNavigationConfigUtil.getEdgeDescriptionEnd;

                /**
                 * goto node to in the edge
                 * @param edge
                 */
                $scope.goToEdgeNodeTo = function(edge) {
                    FormNavigationConfigUtil.goToEdgeNodeTo(edge, $scope.selector, $scope.formMode);
                };

                /**
                 * goto node from in the edge
                 * @param edge
                 */
                $scope.goToEdgeNodeFrom = function(edge) {
                    FormNavigationConfigUtil.goToEdgeNodeFrom(edge, $scope.selector, $scope.formMode);
                };



            }
        };
    });

})();

