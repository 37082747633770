(function () {
    /**
     * Module for display sql icon, which will display display
     */
    angular.module('acadiamasterApp')
        .directive('displaySqlIcon', ngDialog => ({
            link($scope) {

                $scope.showDisplaySqlWindow = function () {
                    var template = '<display-sql-preview close-function="closeFunction" condition="condition"  expression-type="\'PTF\'"';
                    var programTestId = $scope.programTestId;
                    var programId = $scope.programId;
                    if (programTestId != null) {
                        template += ' program-test-id="' + programTestId + '"';
                    }
                    if (programId != null) {
                        template += ' program-id="' + programId + '"';
                    }
                    template += '></display-sql-preview>';

                    var _scope = $scope;

                    // open modal window and pass in all the scope variables
                    var dialog = ngDialog.open({
                        showClose: false,
                        closeByEscape: false,
                        closeByDocument: false,
                        template: template,
                        plain: true,
                        controller: ['$scope', function ($scope) {
                            $scope.condition = _scope.condition;
                            $scope.closeFunction = closeDialog;
                        }],
                        height: '90%',
                        className: 'ngdialog-theme-plain custom-width-large'
                    });

                    function closeDialog() {
                        dialog.close();
                    }
                };
            },
            restrict: 'E',
            scope: {
                condition: '<',
                closeFunction: '<',
                programTestId: '<',
                showButton: '<?',
                programId: '=',
            },
            template: '<span ng-if="showButton"><button type="button" class="btn btn-primary btn-xs" title="Preview SQL"><span class="glyphicon glyphicon-share" ng-click="showDisplaySqlWindow()"></span></button></span><span ng-if="!showButton" title="Preview SQL" class="glyphicon glyphicon-share text-success" ng-click="$event.stopPropagation(); showDisplaySqlWindow()"></span>',
        }));
}());
