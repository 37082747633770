/**
 * Created by jason.cao on 4/27/17
 */
(function () {
    'use strict'

    angular.module('acadiamasterApp').config(function ($stateProvider, StringManagementConstants, DeepLinkConstants) {
        $stateProvider.state('miscTools', {
            parent: 'site',
            url: '/tools',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
            },
            views: {
                'content@': {
                    template: '<vbr-misc-tools></vbr-misc-tools>'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global')
                    return $translate.refresh()
                }]
            }
        })
            .state('downloadTools', {
                parent: 'miscTools',
                url: '/download',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        template: '<vbr-download-tools></vbr-download-tools>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        return $translate.refresh()
                    }]
                }
            })
            .state('formCompareTools', {
                parent: 'miscTools',
                url: '/formCompare',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        template: '<vbr-form-compare-tools></vbr-form-compare-tools>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        return $translate.refresh()
                    }]
                }
            })
            .state('configurationIds', {
                parent: 'miscTools',
                url: '/ids',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/configurationIds/configurationIds.html',
                        controller: 'ConfigurationIdsController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolStrings', {
                parent: 'miscTools',
                url: '/strings',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'stringManagement.title.page'
                },
                views: {
                    'content@': {
                        template: '<string-management level="' +
                            StringManagementConstants.LEVEL.SYSTEM +
                            '" is-server-string="false"></string-management>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('stringManagement')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolServerStrings', {
                parent: 'miscTools',
                url: '/stringsServer',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'stringManagement.title.server.page'
                },
                views: {
                    'content@': {
                        template: '<string-management level="' +
                            StringManagementConstants.LEVEL.SYSTEM +
                            '" is-server-string="true"></string-management>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('stringManagement')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolLanguages', {
                parent: 'miscTools',
                url: '/languages',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'languages.title.page'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/tools/tools.languages.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('languages')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolContainerItemTemplates', {
                parent: 'miscTools',
                url: '/templates',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'containerItemTemplates.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/tools/containerItemTemplates/templates.html',
                        controller: 'ToolsContainerItemTemplates',
                        controllerAs: 'ctc',
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('containerItemTemplates')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolCommunicationCategories', {
                parent: 'miscTools',
                url: '/communicationCategories',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'communication.category.title.page'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/communicationCategory/communicationCategories.html',
                        controller: 'CommunicationCategoryController'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('communicationCategory')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolVXPDataMigration', {
                parent: 'miscTools',
                url: '/vxpDataMigration',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'vxpDataMigration.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/tools/vxpDataMigration/vxpDataMigration.html',
                        controller: 'VXPDataMigrationController',
                        controllerAs: 'dmc',
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('vxpDataMigration')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolOperationHistory', {
                parent: 'miscTools',
                url: '/operationHistory',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'operationHistory.title'
                },
                views: {
                    'content@': {
                        template: '<operation-history-listing></operation-history-listing>'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('operationHistory')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolOperationHistory.detail', {
                parent: 'toolOperationHistory',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'operationHistory.detailView.title'
                },
                views: {
                    'content@': {
                        template: '<operation-history-detail operation-id="operationId"></operation-history-detail>',
                        controller: function ($scope, $stateParams) {
                            $scope.operationId = $stateParams.id
                        }
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global')
                        $translatePartialLoader.addPart('operationHistory')
                        return $translate.refresh()
                    }]
                }
            })
            .state('toolMobileBuild', {
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'toolMobileBuild',
                },
                parent: 'miscTools',
                url: '/mobileBuild',
                resolve: {},
                views: {
                    'content@': {
                        controller: 'MobileBuildCreationController',
                        templateUrl: 'admin-templates/site/tools/mobileBuild/mobileBuildCreation.html',
                    },
                },
            })
            /**
             * page to sync users and forms
             */
            .state('toolRegeneratePDF', {
                parent: 'miscTools',
                url: '/regeneratePDF',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'toolRegeneratePDF'
                },
                views: {
                    'content@': {
                        template: '<regenerate-pdf></regenerate-pdf>'
                    }
                },
            })
    })
})()
