angular.module('acadiamasterApp')
    .factory('ImageReferenceService', ($http, localWeb) => {
        /**
         * Gets all the image reference available in the system
         */
        function getImageReference() {
            return $http.get(localWeb.createUrl(`api/imageReference/`));
        }

        return {
            getImageReference,
        };
    });
