/**
 * Created by Ryan Berry on 12/9/2022
 */

angular.module('acadiamasterApp')
    .factory('DataReferenceConfigService', ($http, localWeb) => {
        const getForms = formDataType => {
            return $http.get(localWeb.createUrl(`/api/forms/ror?formDataType=${formDataType}`));
        };

        return {
            getForms,
        };
    });
