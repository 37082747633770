/**
 * navigation builder configuration model, used to hold various configuration data
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('NavBuilderConfigModelService', function (NavTemplateMultiChoiceModel) {
        var templateTypes = {
            'MULTI_CHOICE_REDIRECT' : {
                name: 'MULTI_CHOICE_REDIRECT',
                text: 'a main page that derives navigation from answer choices made in a question that supports multiple selections'
            }
        };

        /*
            style classes used for formatting message
         */
        var styleClasses = [
            // top level
            {
                'padding-left': '0px',
                'color' : 'blue',
                'font-size': '1em'
            },
            // 2nd level
            {
                'padding-left': '10px',
                'color' : 'purple',
                'font-size': '0.9em'
            },
            // 3rd level
            {
                'padding-left': '20px',
                'color' : 'black',
                'font-size': '0.8em'
            },
            // 4th level
            {
                'padding-left': '30px',
                'color' : 'black',
                'font-size': '0.8em'
            },
            // 5th level
            {
                'padding-left': '40px',
                'color': 'black',
                'font-size': '0.8em'
            }
        ];


        var statusList = {
            'NOT_STARTED' : {
                name : 'NOT_STARTED',
                text : 'Not Started'
            },
            'RUNNING' : {
                name : 'RUNNING',
                text : 'Running'
            },
            'FINISHED' : {
                name : 'FINISHED',
                text : 'Finished'
            },
            'FAILED' : {
                name : 'FAILED',
                text : 'Failed'
            }
        };

        /**
         * nav builder config model
         * @param sourcePage - source page of the navigation
         * @constructor
         */
        function NavBuilderConfigModel(sourcePage) {
            this.sourcePage = sourcePage;
            this.formMode = sourcePage._parent;

            // todo: once we have more templates, this can't hard code to a specific type
            this.templateType = templateTypes.MULTI_CHOICE_REDIRECT;
            this.templateConfig = new NavTemplateMultiChoiceModel(this);

            // internal variables, use the getter/setter function to access it
            this._messages = [];
            this._status = statusList.NOT_STARTED;
        }

        NavBuilderConfigModel.prototype.getMessages = function() {
            return this._messages;
        };

        NavBuilderConfigModel.prototype.resetMessages = function() {
            this._messages = [];
        };

        /**
         * add a message with style level
         * @param newMessage {string} - new message to add
         * @param level {number} - message level, currently support levels:  0-4
         * @returns {Array} - list of messages currently in the config model
         */
        NavBuilderConfigModel.prototype.addMessage = function(newMessage, level) {
            var message = {
                ts: new Date(),
                text : newMessage,
                styleClass : level==null ? null : styleClasses[level]
            };

            this._messages.push(message);

            return this._messages;
        };

        NavBuilderConfigModel.prototype.getStatus = function() {
            return this._status;
        };

        NavBuilderConfigModel.prototype.setStatus = function(newStatus) {
            this._status = newStatus;
        };

        /**
         * process the configuration, include add/remove/update navigation edges
         */
        NavBuilderConfigModel.prototype.processConfig = function() {
            this.resetMessages();
            this.setStatus(statusList.RUNNING);

            try {
                this.templateConfig.processConfig();
                this.addMessage("process config completed", 0);
                this.setStatus(statusList.FINISHED);
            }
            catch (error) {
                console.error("process config failed : ", error);
                this.addMessage("process config failed : " + error, 0);
                this.setStatus(statusList.FAILED);
            }
        };

        /***************************************
         * service return call
         ***************************************/

        return {
            NavBuilderConfigModel : NavBuilderConfigModel,
            templateTypes : templateTypes,
            status : statusList
        };
    });
})();
