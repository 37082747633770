
angular.module('acadiamasterApp')
    .controller('ExportWizardController', ($scope, CONFIG, ngDialog, entity, AlertService,
        ConfigurationServer) => {
        $scope.CONFIG = CONFIG;
        $scope.wc = exportWizardConstants();
        $scope.isReady = false;
        $scope.isExportModalViewOnly = true;
        $scope.canExitDueToValidation = canExitDueToValidation;
        $scope.getIdsFromDtoList = getIdsFromDtoList;
        // resolve program entity
        entity.$promise.then(() => {
            init(entity);
        });

        $scope.controls = {
            canExitDueToValidation,
            exitOut: cancel,
            exportSelectedProgramEntities,
            viewSelectedEntities,
        };

        $scope.isExportDisabled = () => {
            const data = $scope.data.selectedData;
            const hasPropertyData = data.includeDataType
                || data.properties.length > 0;
            const hasRuleData = data.rules.length > 0;
            const hasFormData = data.forms.length > 0;
            const hasFormAssociation = data.includeFormAssociation;
            const hasTestSuiteData = data.testSuites.length > 0;
            const hasProgramBasicsData = data.includeProgramBasics;
            const hasStringData = data.includeProgramString;
            const hasExpressionConditionData = data.expressionConditions.length > 0;
            const hasAgreementData = data.includeProgramAgreements;
            const hasAssetsData = data.includeProgramAssets;
            const hasCommunicationCategoryData = data.communicationCategories.length > 0
                || data.includeCommunicationCategoryAssociation;
            const hasDeepLinkData = data.includeDeepLinks;
            const hasUnauthenticatedTaskData = data.includeUnauthenticatedTasks;
            const hasAnyData = hasPropertyData
                || hasRuleData
                || hasFormData
                || hasFormAssociation
                || hasTestSuiteData
                || hasProgramBasicsData
                || hasStringData
                || hasAgreementData
                || hasExpressionConditionData
                || hasAssetsData
                || hasCommunicationCategoryData
                || hasDeepLinkData
                || hasUnauthenticatedTaskData
                || hasDeepLinkData;
            return !hasAnyData;
        };

        /** ********************************************************************
         * ************************** Private Methods **************************
         * ******************************************************************** */
        function init(entity) {
            $scope.data = {
                currentStep: 0,
                program: entity,
                selectedData: {
                    agreements: [],
                    deepLinks: [],
                    expressionConditions: [],
                    forms: [],
                    includeProgramAgreements: false,
                    includeDataType: false,
                    includeDeepLinks: false,
                    includeFormAssociation: false,
                    includeProgramBasics: false,
                    includeCommunicationCategoryAssociation: false,
                    includeUnauthenticatedTasks: false,
                    programId: entity.id,
                    properties: [],
                    rules: [],
                    testSuites: [],
                    programAssets: [],
                    communicationCategories: [],
                    unauthenticatedTasks: [],
                },
            };
            // set ready flag to true after data init
            $scope.isReady = true;
        }

        function exportWizardConstants() {
            return {
                wizardSteps: {
                    AGREEMENTS: 'Program Agreements',
                    DEEP_LINKS: 'Deep Links',
                    EXPRESSION_CONDITIONS: 'Expression Conditions',
                    FORMS: 'Forms',
                    CONTAINER_ASSOCIATION: 'Container Association',
                    PROGRAM_BASICS: 'Program Basics',
                    PROGRAM_TEST_SUITE: 'Program Test Suite',
                    PROPERTIES: 'Properties',
                    RULES: 'Rules & Rule Association',
                    STRINGS: 'String Management',
                    PROGRAM_ASSETS: 'Server Assets Management',
                    COMMUNICATION_CATEGORY: 'Communication Category Association',
                    UNAUTHENTICATED_TASK: 'Unauthenticated Tasks',
                },
            };
        }

        function canExitDueToValidation() {
            // add step specific validation here
            return true;
        }

        function cancel() {
            window.history.back();
        }

        function getIdsFromDtoList(list, idKey) {
            if (list == null || list.length <= 0) {
                // return empty list
                return [];
            }
            return list.map(item => item[idKey]);
        }

        function exportSelectedProgramEntities() {
            const { selectedData } = $scope.data;

            // create request data object from selected data
            const dto = {
                agreementIds: getIdsFromDtoList(selectedData.agreements, 'id'),
                deepLinkIds: getIdsFromDtoList(selectedData.deepLinks, 'id'),
                expressionConditionIds: getIdsFromDtoList(selectedData.expressionConditions, 'id'),
                formIds: getIdsFromDtoList(selectedData.forms, 'id'),
                includeDataType: selectedData.includeDataType,
                includeFormAssociation: selectedData.includeFormAssociation,
                includeProgramBasics: selectedData.includeProgramBasics,
                includeProgramString: selectedData.includeProgramString,
                includeProgramAssets: selectedData.includeProgramAssets,
                programId: selectedData.programId,
                programTestSuiteIds: getIdsFromDtoList(selectedData.testSuites, 'id'),
                propertyIds: getIdsFromDtoList(selectedData.properties, 'id'),
                ruleIds: getIdsFromDtoList(selectedData.rules, 'id'),
                communicationCategoryIds: getIdsFromDtoList(selectedData.communicationCategories, 'id'),
                includeCommunicationCategoryAssociation: selectedData.includeCommunicationCategoryAssociation,
                unauthenticatedTaskIds: getIdsFromDtoList(selectedData.unauthenticatedTasks, 'id'),
            };

            ConfigurationServer.exportCompleteProgram(dto).then(
                // On Success.
                (response) => {
                    const a = document.createElement('a');
                    const file = new Blob([response.data], { type: response.contentType });
                    a.href = URL.createObjectURL(file);
                    a.download = response.filename;
                    a.click();
                },
                // On Failure.
                (error) => {
                    if (error.status == 400) {
                        AlertService.error('Invalid Input!');
                    }
                    AlertService.error('Error occurred while fetching the report. Please try again later.');
                },
            );
        }

        function viewSelectedEntities(isViewOnly) {
            $scope.isExportModalViewOnly = isViewOnly;
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-large',
                plain: true,
                scope: $scope,
                size: 'md',
                template: '<export-wizard-selected-entities selected-data="data.selectedData" opened-in-dialog="true" view-only="isExportModalViewOnly"></export-wizard-selected-entities>',
            }).then(
                () => {
                    // this function is never called by ngDialog
                },
                (value) => {
                    if (value === 1 && !isViewOnly) {
                        $scope.controls.exportSelectedProgramEntities();
                    }
                },
            );
        }
    });
