/**
 * Created by Jason Cao on 08/12/2019
 *
 * directive for configuring a behavior template for program container
 */
angular.module('acadiamasterApp').directive('programContainerBehaviorConfig', () => ({
    restrict: 'E',
    scope: {
        behaviorTemplate: '='
    },
    link($scope) {
        $scope.states = [
            {
                key: 'INITIAL',
                name: 'Initial State',
                state: $scope.behaviorTemplate.initialState
            },
            {
                key: 'FORM_COMPLETION',
                name: 'Form Completion State',
                state: $scope.behaviorTemplate.formCompletionState
            }
        ];

        // index of the current selected state
        $scope.currentStateIndex = 0;

        $scope.selectState = newStateIndex => $scope.currentStateIndex = newStateIndex;

        $scope.isStateSelected = (stateIndex) => {
            return stateIndex === $scope.currentStateIndex;
        };

        $scope.getSelectedState = () => {
            return $scope.states[$scope.currentStateIndex].state;
        };

        // flag to indicate if the configuration should be collapsed
        $scope.collapsed = false;

        $scope.isOpen = () => {
            return !$scope.collapsed;
        };

        $scope.toggle = () => {
            $scope.collapsed = !$scope.collapsed;
        };

    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/' +
        'config/programContainerBehavior.template.html',
}));
