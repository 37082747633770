'use strict';

angular.module('acadiamasterApp')
    .controller('ProgramController', function ($scope, ProgramService, ProgramSearch, ParseLinks,vbrCommonUtil, CONFIG, ProgramUtilService, ngDialog,
                                                ConfigurationServer, AlertService) {
        $scope.programs = [];
        $scope.page = 0;

        $scope.searchData = {
            searchString : "",
            searchResultCount : "loading ...",
            orderByChoices : [{label:'Last Updated On', value:'updatedOn'},
                            {label:'ID', value:'id'},
                            {label:'Program Name', value:'name'},
                            {label:'Organization Name', value:'organization.orgName'}],
            orderBySelected : 'updatedOn',
            pageSizeChoices : [20, 50, 100],
            pageSizeSelected : 20,
            ascending : false,
            exactMatch :false
        };

        $scope.CONFIG = CONFIG;

        $scope.loadAll = loadPrograms;

        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.delete = function (id) {
            ProgramService.get({id: id}, function(result) {
                $scope.program = result;
                $('#deleteProgramConfirmation').modal('show');
            });
        };

        /**
         * open a replay program detail confirmation window
         */
        $scope.openReplayProgramDetailConfirm = function(program) {
            ngDialog.openConfirm({
                template: 'admin-templates/site/programManagement/program/replay/program.vxp.replayDetail.html',
                controller: 'ProgramReplayDetailConfirmController',
                data: program
            });
        };

        $scope.confirmDelete = function (id) {
            ProgramService.delete({id: id},
                function () {
                    $scope.loadAll();
                    $('#deleteProgramConfirmation').modal('hide');
                    $scope.clear();
                });
        };

        function downloadAsZipFile (fileName, response) {
            const tagForDownload = document.createElement('a');
            const file = new Blob([ response.data ], { type : response.contentType });
            tagForDownload.href = URL.createObjectURL(file);
            tagForDownload.download = fileName;
            tagForDownload.click();
        }

        $scope.exportCompleteProgram = function (program) {
            ConfigurationServer.exportCompleteProgramAsZip(program.code).then((data) => {
                let fileName = `complete_program_${program.code}.zip`;
                downloadAsZipFile(fileName, data);
                AlertService.success(`Program ${program.code} is downloaded.`);
            }, error => {
                AlertService.error('Error while exporting complete program', program.code, error);
            });
        };

        $scope.importProgram = function () {
            ProgramUtilService.importProgramEntity($scope, "Upload Basic Program Details", "/api/configuration/program/import")
        };

        $scope.refresh = function () {
            $scope.loadAll();
        };

        $scope.clear = function () {
            $scope.program = {name: null, isDeleted: null, createdOn: null, updatedOn: null, id: null};
        };

        $scope.clearAndLoadAll = function(){
            $scope.searchData.searchString = "";
            $scope.loadAll();
        };


        /***************************************************************************
         * private functions
         ***************************************************************************/
        function loadPrograms() {
            ProgramSearch.query({
                page: $scope.page,
                size: $scope.searchData.pageSizeSelected,
                ascending : $scope.searchData.ascending,
                orderBy : $scope.searchData.orderBySelected,
                exactMatch : ($scope.searchData.exactMatch===null? null : $scope.searchData.exactMatch),
                searchString : $scope.searchData.searchString===null || $scope.searchData.searchString.length==0 ? null : $scope.searchData.searchString
            }).then(function(response) {
                $scope.links = ParseLinks.parse(response.headers('link'));
                $scope.programs = response.data;
                $scope.searchData.searchResultCount = response.headers('X-Total-Count');
            });
        }
    });
