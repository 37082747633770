/**
 * @author pravin.gayal on 23-05-2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('ProgramCategoryModelService', ['ModelServiceBase', 'ModelServiceConditionExpression', function (ModelServiceBase, ModelServiceConditionExpression) {

            /**************************************
             *  Model definition/construction
             ***************************************/
            ProgramCategoryModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function ProgramCategoryModel(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);
                this.id = null;
                this.name = null;
                this.localizationMap = {};
            }

            ProgramCategoryModel.prototype.toDto = function (sequenceNo) {
                var dto = {};
                dto.id = this.id;
                dto.name = this.name;
                dto.localizationMap = this.localizationMap;

                return dto;
            };

            ProgramCategoryModel.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.name = dto.name;
                this.localizationMap = dto.localizationMap != null ? dto.localizationMap : {};
            };

            /***************************************
             * Private functions
             ***************************************/


            /***************************************
             * service return call
             ***************************************/

            return {
                ProgramCategoryModel: ProgramCategoryModel
            };
        }]);
})();