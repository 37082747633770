/**
 * directive for session monitor dialog that include re-login functionality
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrSessionMonitor', function (SessionService, $uibModal, $window, $rootScope, localStorageService, $timeout, $q, $http, localWeb, AnalyticsLogger, Auth, AppConfigService, SSOService) {

        var cs = SessionService.cs;
        var _scope;  // will be set to the main controller scope when it is created, used in intern controller

        return {
            restrict: "E",
            template: '<div></div>', // could probably do better
            scope: {
                // add more things later if needed, make sure this is an isolated scope though so it can be used anywhere
            },
            link: function ($scope) {
                $scope.cs = cs;

                $scope.data = {
                    dialogHandler: null,
                    isAdmin : localWeb.getIsAdmin(),
                    twoFactorAuthenticationEnabled : false
                };

                _scope = $scope;

                init($scope);
            }
        };

        /******************************************************
         * private functions
         ******************************************************/

        /**
         * initialization function
         * @param $scope - scope of the directive
         */
        function init($scope) {
            // setup listeners for various session monitor events
            $scope.$on(cs.SHOW_DIALOG, function () {
                showModal($scope);
            });

            $scope.$on(cs.TIMER_UPDATED, function () {
                // just close the modal at this point, may need to add additional handling later
                closeModal($scope);
            });

            // check for two factor authentication for admin user
            updateTwoFactorAuthentication($scope);
        }

        /**
         * part of the initialization process to check if two factor authentication are needed,
         * assuming this will never change as the page is running
         * @param $scope
         */
        function updateTwoFactorAuthentication($scope) {
            if (!$scope.data.isAdmin) {
                $scope.data.includeTwoFactorAuthenticationEnabled = false;
            }
            else {
                $scope.data.twoFactorAuthenticationEnabled = null;
                AppConfigService.getAppConfig().then(function(response) {
                    $scope.data.twoFactorAuthenticationEnabled = response.data.twoFactorAuthenticationEnabled;
                });
            }
        }

        /**
         * show modal window if needed
         * @param $scope
         */
        function showModal($scope) {
            if ($scope.data.dialogHandler != null) {
                // dialog already open, do nothing
                return;
            }

            // can't find user name, then user never successfully logged in
            if (getUserName()==null) {
                return;
            }

            // open the modal
            $scope.data.dialogHandler = $uibModal.open({
                animation: true,
                templateUrl: 'admin-templates/util/session/sessionMonitor.html',
                controller: ModalController,
                backdrop: 'static',
                backdropClass : 'backdropDark80',
                size: 'md',
                keyboard: false
            });

            // make sure the handler is set to null when modal is closed
            $scope.data.dialogHandler.result.then(function () {
                _scope.data.dialogHandler = null;
            }, function () {
                _scope.data.dialogHandler = null;
            });
        }

        /**
         * close modal window if needed
         */
        function closeModal($scope) {
            if ($scope.data.dialogHandler == null) {
                // dialog is not open, do nothing
                return;
            }

            $scope.data.dialogHandler.close();
            $scope.data.dialogHandler = null;
        }

        /**
         * pad a number to at least 2 digit
         * @param n - input number
         * @return {string} 0 -> "00", 1 -> "01", 27 -> "27"
         */
        function numberPad(n) {
            if (n==null || n==0) {
                return "00";
            }
            else if (n<10) {
                return "0" + n;
            }
            else {
                return "" + n;
            }
        }

        function getUserName() {
            var user = localStorageService.get('user');
            return user ? user.login : null;
        }

        function getAuthCode(authCode) {
            if (authCode==null || authCode.trim().length==0) {
                return null;
            }
            else {
                return parseInt(authCode);
            }
        }

        function ModalController($scope, $uibModalInstance) {
            $scope.SessionService = SessionService;
            $scope.data = _scope.data;
            $scope.data.dialogHandler = $uibModalInstance;
            $scope.lastError=null;
            $scope.keycloakEnable = SSOService.isEnable();
            $scope.credentials = {
                password : null,
                username : getUserName(),
                twoFactorAuthCode : '',
                isReady : function() {
                    return this.password!=null && this.password.length > 0;
                }
            };

            $scope.closeAndLogout = function () {
                $scope.closeModal();

                // actually log out here
                Auth.logout();
                $rootScope.$broadcast('logout');
            };

            $scope.closeModal = function() {
                $uibModalInstance.close();
                $scope.lastError = null;
                $scope.data.dialogHandler = null;
            };

            $scope.getRemainingTimeString = function() {
                var remainingTime = SessionService.getRemainingTime();

                if (remainingTime <= 0) {
                    return "EXPIRED";
                }

                remainingTime = Math.round(remainingTime / 1000);
                var minutes = Math.floor(remainingTime / 60);
                var seconds = Math.floor(remainingTime % 60);

                return numberPad(minutes) + " : " + numberPad(seconds);
            };

            $scope.refreshSession = function() {
                $scope.closeModal();
            };

            /**
             * doing the login here so it is all self contained, we are trying to do just the minimum, as we
             * can assume user has already login before, so some of the
             */
            $scope.login = function() {

                var deferred = $q.defer();

                var success = function (response) {
                    response = response.data;

                    var user = response.baseUserDTO;
                    AnalyticsLogger.login(user);

                    localStorageService.set('token', response.token);
                    localStorageService.set('user', user);
                    localStorageService.set('subscriptions', response.activeProgramSubscriptions);

                    deferred.resolve(response);
                    $scope.closeModal();
                    // Force reload on window, rmeoving dep on state
                    $window.location.href = $window.location.href;
                };

                var error = function (error) {
                    $scope.lastError = "Login Failed : " + error.data.errorCode;

                    // remove the message in a few seconds
                    $timeout(function() {
                        $scope.lastError = null;
                    }, 5000);
                };

                var credentials = $scope.credentials;

                var data = "username=" +  encodeURIComponent(credentials.username) + "&password="
                    + encodeURIComponent(credentials.password);

                var authCode = getAuthCode(credentials.twoFactorAuthCode);
                if (authCode!=null) {
                    data += "&twoFactorAuthCode=" + encodeURIComponent(authCode);
                }

                var headers = {
                    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
                    "Accept": "application/json",
                    "appTypeId": 1
                };

                $http.post('api/authenticate', data, {
                    headers: headers
                }).then(success,error);

            };
        }

    });

})();
