(function () {
    'use strict';

    /**
     * form search model for display config
     */
    angular.module('acadiamasterApp').factory('FormSearchDisplayConfigModel', function (FormSearchPropertyModel) {

        var allKeys = [
            'visibility',
            'width',
            'editable',
            'gravityH',
            'gravityV',

            'fontSize',
            'fontName',
            'fontStyleNormal',
            'fontStyleBold',
            'fontStyleItalic',
            'fontStyleInherit',

            'color',
            'bgColor',

            'paddingLeft',
            'paddingRight',
            'paddingTop',
            'paddingBottom',

            'marginLeft',
            'marginRight',
            'marginTop',
            'marginBottom'
        ];


        /***************************************************************
         * private functions
         **************************************************************/

        /***************************************************************
         * Form Search Models for Display Config
         * @constructor
         ***************************************************************/
        function FormSearchDisplayConfigModel() {
            // list of potential properties to search against

            this.visibility = new FormSearchPropertyModel();
            this.editable = new FormSearchPropertyModel();

            // width
            this.width = new FormSearchPropertyModel();

            // gravity related
            this.gravityH = new FormSearchPropertyModel();
            this.gravityV = new FormSearchPropertyModel();

            // font related
            this.fontSize = new FormSearchPropertyModel();
            this.fontName = new FormSearchPropertyModel();
            this.fontStyleNormal = new FormSearchPropertyModel();
            this.fontStyleBold = new FormSearchPropertyModel();
            this.fontStyleItalic = new FormSearchPropertyModel();
            this.fontStyleInherit = new FormSearchPropertyModel();

            // color related
            this.color = new FormSearchPropertyModel();
            this.bgColor = new FormSearchPropertyModel();

            // padding related
            this.paddingLeft = new FormSearchPropertyModel();
            this.paddingRight = new FormSearchPropertyModel();
            this.paddingTop = new FormSearchPropertyModel();
            this.paddingBottom = new FormSearchPropertyModel();

            // margin related
            this.marginLeft = new FormSearchPropertyModel();
            this.marginRight = new FormSearchPropertyModel();
            this.marginTop = new FormSearchPropertyModel();
            this.marginBottom = new FormSearchPropertyModel();
        }

        /**
         * check if there are any property that has been filled out
         * @return {boolean} true if any property has been filled out, false otherwise
         */
        FormSearchDisplayConfigModel.prototype.hasValue = function() {
            for (var i=0; i<allKeys.length; i++) {
                if (this[allKeys[i]].enabledAndHasValue()) {
                    return true;
                }
            }
            return false;
        };

        /**
         * getting the description as html
         */
        FormSearchDisplayConfigModel.prototype.getDescriptionAsHtml = function() {
            var html='';

            var _this = this;

            _.forEach(allKeys, function(key) {
                if (_this[key].enabledAndHasValue()) {
                    var prefix = html==='' ? '' : ' | ';
                    html += prefix + _this[key].getDescriptionAsHtml(key);
                }
            });

            return html;
        };

        /***************************************
         * service return call
         ***************************************/

        return FormSearchDisplayConfigModel;
    });

})();
