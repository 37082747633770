/**
 * Created by Jason Cao on 10/21/2017
 * comparison result service for form sub-field
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('CompareResultSubFieldService',
        function (CompareResultDisplayConfigService, CompareResultFieldValueService, CompareResultUtilService) {

            function compareSubFieldDetail(sf1, sf2) {
                var result = {};

                CompareResultUtilService.comparePrimitivesByKey(sf1, sf2, "localId", result);
                CompareResultUtilService.compareNamedObjectsByKey(sf1, sf2, "type", result);
                CompareResultUtilService.comparePrimitivesByKey(sf1, sf2, "name", result);
                CompareResultUtilService.comparePrimitivesByKey(sf1, sf2, "displayOrder", result);
                CompareResultUtilService.comparePrimitivesByKey(sf1, sf2, "contentDescription", result);

                // localization map
                CompareResultUtilService.compareMaps(sf1.localizationMap, sf2.localizationMap, "lm", result);

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            /**
             * compare two sub-fields
             * @param sf1 - sub field 1
             * @param sf2 - sub field 2
             * @param parentTitle - parent title
             * @returns {{_title: *, _isOpen: boolean, detail: *, displayConfig: {CompareResult}}}
             */
            function compare(sf1, sf2, parentTitle) {
                var title;
                if (sf1 == null) {
                    sf1 = {};
                    title = "SubField " + sf2.localId;
                }
                else {
                    title = "SubField " + sf1.localId;
                }

                if (sf2 == null) {
                    sf2 = {};
                }

                // add type information
                if (sf2.type != null) {
                    title += " | " + sf2.type.name;
                }
                else if (sf1.type != null) {
                    title += " | " + sf1.type.name;
                }

                // add field name to title
                if (sf2.name != null) {
                    title += " (" + sf2.name + ")";
                }
                else if (sf1.name != null) {
                    title += " (" + sf1.name + ")";
                }

                if (parentTitle != null)
                    title += " - " + parentTitle;

                var result = {
                    _title: title,
                    _isOpen: true,
                    detail: compareSubFieldDetail(sf1, sf2),
                    displayConfig: CompareResultDisplayConfigService.compare(sf1.displayConfig, sf2.displayConfig)
                };

                // collect field value information if needed
                var fieldValue1 = sf1.fieldValue;
                var fieldValue2 = sf2.fieldValue;
                if (CompareResultUtilService.isValueAvailable(fieldValue1) || CompareResultUtilService.isValueAvailable(fieldValue2)) {
                    result.fieldValue = CompareResultFieldValueService.compare(fieldValue1, fieldValue2)
                }

                result._status = CompareResultUtilService.collectStatus(result);

                return result;
            }

            return {
                compare: compare
            };
        });

})();

