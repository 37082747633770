/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigAction', function (RuleUtilService, vbrCommonUtil) {
            return {
                controller: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.vcu = vbrCommonUtil;
                },
                restrict: 'E',
                scope: {
                    element: '=',
                    parentForm: '='
                },
                templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.html',
            };
        });

})();
