'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {

        $stateProvider
            .state('ruleScheduledAudits', {
                parent: 'ruleDashboard',
                url: '/ruleScheduledAudits?userId=&ruleId=&startDate=&endDate=&page=&pendingJobs',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Scheduled Rule Audit'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/ruleJobDetailsAudit/ruleScheduledAudits.html',
                        controller: 'RuleScheduledAuditController',
                        controllerAs: 'ruleScheduledAuditCtrl'
                    }
                },
                resolve: {
                    scheduledRuleAudits: ['BusinessRuleScheduledRuleAuditHelperService','BusinessRuleScheduledRuleAudit', '$stateParams', function
                        (BusinessRuleScheduledRuleAuditHelperService , BusinessRuleScheduledRuleAudit,  $stateParams) {

                        var startDate = $stateParams.startDate? BusinessRuleScheduledRuleAuditHelperService.parseDate ($stateParams.startDate) :  BusinessRuleScheduledRuleAuditHelperService.getYesterday() ;
                        var endDate = $stateParams.endDate ? BusinessRuleScheduledRuleAuditHelperService.parseDate ($stateParams.endDate)  : BusinessRuleScheduledRuleAuditHelperService.getToday()  ;

                        return BusinessRuleScheduledRuleAudit.query({
                            userId: $stateParams.userId || null,
                            ruleId: $stateParams.ruleId || null,
                            startDate: BusinessRuleScheduledRuleAuditHelperService.convertDateToUTC( startDate, false ) ,
                            endDate: BusinessRuleScheduledRuleAuditHelperService.convertDateToUTC( endDate, true ) ,
                            pendingJobs: $stateParams.pendingJobs,
                            page: $stateParams.page || 0,
                            size: 20
                        }, function (result, headers) {
                            result.responseHeaders = headers;
                        }).$promise.then(function (response) {

                            return response;

                        });
                    }]
                }
            })
            .state('execDetailsAudit', {
                parent: 'ruleScheduledAudits',
                url: '/execDetailsAudit/:schedName/:jobGroup/:jobName?page=&size=&startDate=&endDate=',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'Execution Log Rule Audit',
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/businessRule/ruleJobDetailsAudit/execLogRuleAudits.html',
                        controller: 'ExecLogHistoryAuditController',
                        controllerAs: 'execLogRuleAuditCtrl'
                    }
                },
                resolve: {
                    execLogHistoryAudits: ['BusinessRuleExecutionLogAudit', '$stateParams', function
                        (BusinessRuleExecutionLogAudit, $stateParams) {
                        return BusinessRuleExecutionLogAudit.query({
                            schedName: $stateParams.schedName || null,
                            jobGroup: $stateParams.jobGroup || null,
                            jobName: $stateParams.jobName || null,
                            startDate: $stateParams.startDate || 0,
                            endDate: $stateParams.endDate || null,
                            page: $stateParams.page || 0,
                            size: 20
                        }, function (result, headers) {
                            result.responseHeaders = headers;
                        }).$promise.then(function (response) {
                            return response;
                        });
                    }],

                }
            });
    });
