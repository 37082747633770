(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for program communication category mapping model
     */
        .factory('ProgramCommunicationCategoryMappingModelService',
            function (vbrCommonUtil, ModelServiceBase,ProgramCommunicationCategoryConstantService,CommunicationCategoryModelService) {

                /***************************************************************
                 * dto for program communication category model
                 ***************************************************************/
                ProgramCommunicationCategoryMappingModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

                /**
                 * @constructor
                 */
                function ProgramCommunicationCategoryMappingModel() {
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false, null);

                    this.nodeType = ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING;

                    this.id = null;
                    this.programId = null;
                    this.communicationCategoryId = null;
                    this.displayOrder = null;
                    this.communicationCategory=new CommunicationCategoryModelService.CommunicationCategoryModel();
                }

                /**
                 * convert the current UI model to dto format
                 */
                ProgramCommunicationCategoryMappingModel.prototype.toDto = function () {
                    var dto = {};
                    dto.id = this.id;
                    dto.programId = this.programId;
                    dto.communicationCategoryId = this.communicationCategoryId;
                    dto.displayOrder = this.displayOrder;
                    return dto;
                };

                /**
                 * convert the dto object into current object, this function will
                 * wipe out any information you have on the current object
                 */
                ProgramCommunicationCategoryMappingModel.prototype.fromDto = function (dto) {
                    this.id = dto.id;

                    this.programId = dto.programId;
                    this.communicationCategoryId = dto.communicationCategoryId;
                    this.communicationCategory.fromDto(dto.communicationCategory);

                    this.displayOrder = dto.displayOrder;
                };

                ProgramCommunicationCategoryMappingModel.prototype.loadCommunicationCategoryModel = function(model) {
                    this.displayOrder = null;
                    this.id = null;
                    this.programId = null;
                    this.communicationCategoryId = model.id;
                    this.communicationCategory.fromDto(model);
                };
                function loadModelsFromDtoList(dtoList) {
                    var modelList = [];
                    if (dtoList) {
                        _.forEach(dtoList, function(g) {
                            if(g.communicationCategoryId === 100 && g.communicationCategory.name.en === 'Program Communications'){ // If id and name for program Communications are correct then it will load to the UI
                                var mapping = new ProgramCommunicationCategoryMappingModel();
                                mapping.fromDto(g);
                                modelList.push(mapping);
                            }
                        });

                        modelList.sort(function(v1, v2) {
                            return v1.displayOrder - v2.displayOrder ;
                        });
                    }
                    return modelList;
                }

                function loadListDtoFormModels(models) {
                    var dtoList = [];
                    if (models) {
                            var displayOrder = 0;
                            _.forEach(models, function(g) {
                                g.displayOrder = displayOrder++;
                                dtoList.push(g.toDto());
                            });
                    }
                    return dtoList;
                }
                /***************************************
                 * service return call
                 ***************************************/

                return {
                    ProgramCommunicationCategoryMappingModel: ProgramCommunicationCategoryMappingModel,
                    loadModelsFromDtoList: loadModelsFromDtoList,
                    loadListDtoFormModels: loadListDtoFormModels
                };
            });

})();
