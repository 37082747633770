(function () {
    'use strict';

    let app = angular.module('acadiamasterApp');

    /**
     * service for business rule action config model for Create Child Account
     */
    app.factory('ActionConfigCreateChildAccountListModel', function(RuleConstantsService, RuleUtilService, RuleActionBaseModelService, ModelServiceConditionExpression, ModelServiceBase) {
            let ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;
            let requireFields = [RuleUtilService.ruleConstants.accountInformationField.FIRST_NAME,
                RuleUtilService.ruleConstants.accountInformationField.DOB,
                RuleUtilService.ruleConstants.accountInformationField.ADDRESS_ZIP];

            ActionConfigCreateChildAccountListModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigCreateChildAccountListModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigCreateChildAccountListModel;
                this.type = RuleConstantsService.actionType.CREATE_CHILD_ACCOUNT;
                this.actionConfig = {
                    accountInfos: []
                };

                this.addAccountInfos();

            }

            ActionConfigCreateChildAccountListModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);

                let _this = this;
                this.actionConfig = {
                    accountInfos: []
                };

                var actionConfig = dto.actionConfig;

                if (actionConfig.accountInfos != null) {
                    _.forEach(actionConfig.accountInfos, function (u) {
                        let item = new ActionConfigCreateChildAccountItemModel(this);
                        item.fromDto(u);
                        _this.actionConfig.accountInfos.push(item);
                    })
                }
            };

            ActionConfigCreateChildAccountListModel.prototype.toDto = function (files) {
                let dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                let accountInfos = [];
                _.forEach(this.actionConfig.accountInfos, function (u) {
                    accountInfos.push(u.toDto());
                });

                dto.actionConfig.accountInfos = accountInfos;

                dto.actionConfig[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigCreateChildAccountListModel;

                return dto;
            };

            ActionConfigCreateChildAccountListModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                let message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                var accountInfos = this.actionConfig.accountInfos;
                if (accountInfos != null && accountInfos.length > 0) {
                    _.forEach(accountInfos, function (u) {
                        message += "<br>&nbsp;&nbsp;&nbsp;" + u.getDescriptionAsHtml();
                    })
                }

                return message;
            };

            ActionConfigCreateChildAccountListModel.prototype._validateSelf = function () {
                this.clearError();
                const enoughRequireFields = requireFields.every(rf => this.actionConfig.accountInfos.some(af => af.targetField === rf.valueOf()));
                enoughRequireFields ? this.clearError() : this.setErrorMessage("Missing require fields for this action");
            };

            ActionConfigCreateChildAccountListModel.prototype.removeAccountInfosAt = function (index) {
                let accountInfos = this.actionConfig.accountInfos;
                if (index == null || index < 0 || index > accountInfos.length - 1) {
                    return;
                }

                accountInfos.splice(index, 1);
            };

            ActionConfigCreateChildAccountListModel.prototype.addAccountInfos = function (keepItOpen) {
                let accountInfos = this.actionConfig.accountInfos;
                let newCreate = new ActionConfigCreateChildAccountItemModel(this);
                newCreate.isOpen = keepItOpen;
                accountInfos.push(newCreate);
            };

            /***************************************************************
             * Action config Create Child Account -- item config
             ***************************************************************/
            ActionConfigCreateChildAccountItemModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
            function ActionConfigCreateChildAccountItemModel(parent) {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                this.expression = null;
                this.targetField = null;
                this.expressionTree = null;
            }
            ActionConfigCreateChildAccountItemModel.prototype.fromDto = function (dto) {
                this.expression = dto.expression;
                if (dto.expressionTree != null) {
                    this.expressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                    this.expressionTree.fromDto(dto.expressionTree);
                }
                this.targetField = dto.targetField;
            };
            ActionConfigCreateChildAccountItemModel.prototype.toDto = function () {
                var dto = {};
                if (this.expressionTree != null) {
                    dto.expression = this.expression;
                    dto.expressionTree = this.expressionTree.toDto();
                }
                dto.targetField = this.targetField;
                return dto;
            };
            ActionConfigCreateChildAccountItemModel.prototype.getDescriptionAsHtml = function () {
                return "<span class='text-info'>" + this.targetField + "</span> -> " + this.expression;
            };

            ActionConfigCreateChildAccountItemModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.expression == null || this.expression.length === 0) {
                    this.setErrorMessage("Expression is not specified");
                } else if (this.targetField == null) {
                    this.setErrorMessage("Target field is not selected");
                }
            };

            return ActionConfigCreateChildAccountListModel;
        }
    )
})()
