/**
 * Created by Danny on 10/28/2015.
 */
'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('dataTypeMaster', {
                parent: 'site',
                url: '/dataType',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/dataTypeMaster/dataTypeMaster.html',
                        controller: 'DataTypeMasterController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('dataTypeMaster');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    });


