/**
 * Created by amit.rao on 01-09-2016.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').service('ProgramModelService', function (
        ModelServiceBase, ProgramCategoryModelService,
        ProgramUtilConstantsService
    ) {

        /**************************************
         *  Model definition/construction
         ***************************************/
        ProgramModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        function ProgramModel() {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false);

            this.nodeType = ProgramUtilConstantsService.nodeType.PROGRAM;

            this.name = 'New Program';
            this.organizationId = null;
            this.programCategories = [];
        }

        ProgramModel.prototype.toDto = function (files) {
            var dto = {};
            dto.id = this.id;
            dto.name = this.name;
            dto.organizationId = this.organizationId;
            dto.programStatus = this.programStatus;
            dto.programCategories = this.programCategories;

            dto.programCategories = [];
            _.forEach(this.programCategories, function (p) {
                dto.programCategories.push(p.toDto());
            });

            return dto;
        };

        ProgramModel.prototype.fromDto = function (dto) {
            var _this = this;
            this.id = dto.id;
            this.name = dto.name;
            this.organizationId = dto.organizationId;
            this.programStatus = dto.programStatus;

            this.programCategories = [];
            if (dto.programCategories) {
                _.forEach(dto.programCategories, function (c) {
                    var category = new ProgramCategoryModelService.ProgramCategoryModel(_this);
                    category.fromDto(c);
                    _this.programCategories.push(category);
                });
            }
        };

        /***************************************
         * Private functions
         ***************************************/


        /***************************************
         * service return call
         ***************************************/

        return {
            ProgramModel : ProgramModel,
        };

    });


})();