
angular.module('vibrent-external-survey-parser')
    .service('RedcapSurveyQuestionParser', (VibrentFormStructureConstantService, CommonParserUtilService, RedcapParserUtilService,
        RedcapFormStructureConstantService, TextTypeParser) => {
        const constantService = VibrentFormStructureConstantService;
        const commonParser = CommonParserUtilService;
        const redcapParser = RedcapParserUtilService;
        const redcapConstantService = RedcapFormStructureConstantService;
        const textParser = TextTypeParser;

        /**
         * Method to parse text input field.
         * Here we create two form components:
         * 1 - a text label or rich text label (if field_label contains html)
         * 2 - a text input with validation as described in text_validation* and required_field
         * @param surveyQuestion
         * @param localIdGenerator
         * @param fields
         * @returns form field with type TEXT_INPUT
         */
        function parseTextInputQuestion (surveyQuestion, localIdGenerator, fields) {
            const validationType = commonParser.getValueFromObject(surveyQuestion, 'text_validation_type_or_show_slider_number');

            switch (validationType) {
            case redcapConstantService.redcapTextValidationTypes.EMAIL:
                textParser.parseEmail(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapTextValidationTypes.DATE_DMY:
            case redcapConstantService.redcapTextValidationTypes.DATE_MDY:
            case redcapConstantService.redcapTextValidationTypes.DATE_YMD:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_MDY:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_YMD:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_DMY:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_SECONDS_DMY:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_SECONDS_MDY:
            case redcapConstantService.redcapTextValidationTypes.DATETIME_SECONDS_YMD:
                textParser.parseDateTime(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapTextValidationTypes.NUMBER:
            case redcapConstantService.redcapTextValidationTypes.INTEGER:
                textParser.parseNumericField(surveyQuestion,
                    localIdGenerator, fields);
                break;
            case redcapConstantService.redcapTextValidationTypes.PHONE:
                textParser.parsePhoneNumber(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapTextValidationTypes.TIME:
            case redcapConstantService.redcapTextValidationTypes.TIME_HH_MM_SS:
                textParser.parseTimeInput(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapTextValidationTypes.ZIPCODE:
                textParser.parseZipcode(surveyQuestion, localIdGenerator, fields);
                break;
            default:
                textParser.parseTextInput(surveyQuestion, localIdGenerator, fields);
                break;
            }
        }

        /*
         * Method to create Dropdown field
         * @param payload
         * @param localIdGenerator
         */
        function createDropdownFieldForMultipleChoiceType (surveyQuestion, localIdGenerator) {
            const field = constantService.initFormFieldDropdown(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                //  set Spanish translation if available
                redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
            }

            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            const choicesString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');

            let spanishChoices = '';
            if (surveyQuestion.language && surveyQuestion.language.ES) {
                spanishChoices = commonParser.getValueFromObject(surveyQuestion.language.ES, 'select_choices_or_calculations');
            }
            if (choicesString) {
                // clear existing values
                optionsSubField.fieldValue.values.length = 0;

                // parse options
                optionsSubField.fieldValue.values = redcapParser.parseChoicesInExpectedOrder(choicesString, spanishChoices);

                field.fieldValue.valueType = redcapParser.getKeyType(optionsSubField.fieldValue.values);

                // set text from first option as dropdown placeholder and remove it from the options list
                if (optionsSubField.fieldValue.values && optionsSubField.fieldValue.values.length > 1) {
                    const placeholder = commonParser.getValueFromObject(surveyQuestion, 'field_note');

                    if (placeholder) {
                        optionsSubField.fieldValue.placeholder = placeholder;
                    } else {
                        optionsSubField.fieldValue.placeholder = ' ';
                    }
                }
            }
            return field;
        }

        /*
         * Create multiple choice question type, this could include checkbox, yesNo, trueFalse, radio types.
         * @param payload
         * @param localIdGenerator
         */
        function createMultiOptionsField (surveyQuestion, localIdGenerator) {
            // get multi selector template
            const field = constantService.initFormFieldMultiSelector(localIdGenerator);
            const fieldType = RedcapParserUtilService.getRedcapQuestionType(surveyQuestion);
            // parse choices and set in options subfield
            const optionsSubField = field.subFields[1];
            let spanishChoices = '';

            if (fieldType === redcapConstantService.redcapSurveyQuestionTypes.CHECKBOX) {
                // parse questions text and set in tile subfield
                const titleSubField = field.subFields[0];
                if (titleSubField) {
                    titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                    //  set Spanish translation if available
                    redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
                }
                const choicesString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');

                if (surveyQuestion.language && surveyQuestion.language.ES) {
                    spanishChoices = commonParser.getValueFromObject(surveyQuestion.language.ES, 'select_choices_or_calculations');
                }
                if (choicesString) {
                    // clear existing values
                    optionsSubField.fieldValue.values.length = 0;
                    // parse options
                    optionsSubField.fieldValue.values = redcapParser.parseChoicesInExpectedOrder(choicesString, spanishChoices);
                    field.fieldValue.valueType = redcapParser.getKeyType(optionsSubField.fieldValue.values);
                    optionsSubField.fieldValue.singleLine = false;
                    optionsSubField.fieldValue.imagePreset = 'CHECK_BOX_BLUE';
                }
            } else {
                let choicesString = '';
                switch (fieldType) {
                case redcapConstantService.redcapSurveyQuestionTypes.RADIO:
                    // parse questions text and set in tile subfield
                    const titleSubField = field.subFields[0];
                    if (titleSubField) {
                        titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                        //  set Spanish translation if available
                        redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
                    }
                    choicesString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');
                    break;
                case redcapConstantService.redcapSurveyQuestionTypes.TRUE_FALSE:
                    choicesString = '1, True | 0, False';
                    spanishChoices = '1, Verdadero | 0, falso';
                    break;
                case redcapConstantService.redcapSurveyQuestionTypes.YES_NO:
                    choicesString = '1, Yes | 0, No';
                    spanishChoices = '1, Si | 0, No';
                    break;
                default:
                    commonParser.logMessage(
                        `Field type ${fieldType} is not a supported type (radio; yes/no; true/false), Question Id: ${RedcapParserUtilService.getRedcapQuestionId(surveyQuestion)}`,
                        true,
                    );
                    break;
                }
                if (choicesString) {
                    // clear existing values
                    optionsSubField.fieldValue.values.length = 0;
                    // parse options
                    optionsSubField.fieldValue.values = redcapParser.parseChoicesInExpectedOrder(choicesString, spanishChoices);

                    field.fieldValue.valueType = redcapParser.getKeyType(optionsSubField.fieldValue.values);

                    // If the alignment in REDCap is either LH or RH then configure the radio box group to be horizontal (default is vertical alignment
                    const customAlignment = commonParser.getValueFromObject(surveyQuestion, 'custom_alignment');
                    const isHorizontal = customAlignment && (customAlignment === redcapConstantService.redcapAlignmentTypes.LH || customAlignment === redcapConstantService.redcapAlignmentTypes.RH);

                    // Configure radio button to appear as a vertical list, with a separator between each item with he image on the left and the label to the right
                    optionsSubField.fieldValue.optionsDisplayMode = isHorizontal ? constantService.layoutOrientationTypes.HORIZONTAL : constantService.layoutOrientationTypes.VERTICAL;
                    optionsSubField.fieldValue.imagePosition = isHorizontal ? constantService.positionTypes.TOP : constantService.positionTypes.LEFT;
                    optionsSubField.fieldValue.textPosition = isHorizontal ? constantService.positionTypes.BOTTOM : constantService.positionTypes.RIGHT;

                    // Configure the radio buttons to be left aligned in the container
                    const dispConfig = optionsSubField.displayConfig;
                    dispConfig.gravity.horizontal = isHorizontal ? constantService.positionTypes.CENTER : constantService.positionTypes.LEFT;

                    optionsSubField.fieldValue.singleLine = true;
                    optionsSubField.fieldValue.imagePreset = 'CIRCULAR_BLUE';
                }
            }
            return field;
        }

        function createSignatureField (surveyQuestion, localIdGenerator, formFields) {
            const fieldsToAdd = [];

            // get signature template
            const field = constantService.initFormSignatureInput(localIdGenerator);

            // parse questions text and set in tile subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                //  set Spanish translation if available
                redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
            }

            // parse validation and add to field
            redcapParser.parseAndAddValidation(surveyQuestion, field, 'Signature is required', 'Se requiere su firma para continuar');

            // hide title subfield and add rich text label field before selector field to show as title
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fieldsToAdd, field);

            if (fieldsToAdd) {
                formFields.push(...fieldsToAdd);
            }
        }

        function parseMatrixComponent (componentDto, surveyQuestion) {
            /* Add a section header for the matrix component */
            const matrixComponentHeader = commonParser.getValueFromObject(surveyQuestion, 'matrix_group_name');
            if (matrixComponentHeader && matrixComponentHeader.trim() !== '') {
                const fieldWithoutCss = commonParser.removeCssFontTagsIfRequired(matrixComponentHeader);
                const fieldWithoutLineFeeds = redcapParser.replaceLineFeedsIfRequired(fieldWithoutCss);
                if (fieldWithoutCss) {
                    // carry over the redcap matrix group name into component name.
                    componentDto.name = fieldWithoutLineFeeds;
                    // this is to set up the main question input
                    componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.title = fieldWithoutLineFeeds;
                    const spanishText = redcapParser.getSpanishValueFromObject(surveyQuestion, 'section_header');
                    if (spanishText) {
                        commonParser.addSpanishValueToMap(
                            componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.localizationMap,
                            'title', spanishText);
                    }
                }
            }

            // get number of answers from question, the number of columns will be answerCount + 1(to hold sub question text)
            const englishAnswerString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');
            const spanishAnswerString = redcapParser.getSpanishValueFromObject(surveyQuestion, 'select_choices_or_calculations');
            const answers = redcapParser.parseChoicesInExpectedOrder(englishAnswerString, spanishAnswerString);

            if (answers && answers.length > 0) {
                // clear existing values
                componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values.length = 0;

                // add choices as an options
                _.forEach(answers, (o, key) => {
                    componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.values.push(o);
                });
            }

            const questionType = RedcapParserUtilService.getRedcapQuestionType(surveyQuestion);
            componentDto.formComponentMetaDataDTO.matrixQuestionnaireMetadataDTO.singleSelect
                = questionType === redcapConstantService.redcapSurveyQuestionTypes.RADIO_MATRIX;
        }

        function parseMatrixQuestion (surveyQuestion, localIdGenerator, fields) {
            let matrixOptions = commonParser.getValueFromObject(surveyQuestion, 'matrix_group_items');
            if (Array.isArray(matrixOptions) && matrixOptions.length !== 0) {
                _.forEach(matrixOptions, option => {
                    const fieldName = option.field_name;
                    const titleText = option.field_label;

                    let spanishTitleText = null;
                    if (option.language && option.language.ES) {
                        spanishTitleText = redcapParser.getSpanishValueFromObject(option, 'field_label');
                    }

                    let field = commonParser.createMatrixQuestionField(titleText, spanishTitleText, localIdGenerator);
                    field.name = fieldName;

                    if (field) {
                        // parse validation and add to field
                        redcapParser.parseAndAddValidation(surveyQuestion, field);
                        fields.push(field);
                    }
                });
            }
        }

        /*
         * This is method to parse Multi Choice field and map it to radio button, multiple choice or dropdown field on our side
         *
         * @param surveyQuestion
         * @param localIdGenerator
         * @returns form field dto for field type
         */
        function parseMultipleChoiceQuestion (surveyQuestion, localIdGenerator, formFields) {
            const fieldsToAdd = [];

            // get selector from the payload
            const fieldType = RedcapParserUtilService.getRedcapQuestionType(surveyQuestion);
            let field;

            switch (fieldType) {
            case redcapConstantService.redcapSurveyQuestionTypes.DROPDOWN:
                field = createDropdownFieldForMultipleChoiceType(surveyQuestion, localIdGenerator);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.CHECKBOX:
            case redcapConstantService.redcapSurveyQuestionTypes.RADIO:
            case redcapConstantService.redcapSurveyQuestionTypes.TRUE_FALSE:
            case redcapConstantService.redcapSurveyQuestionTypes.YES_NO:
                field = createMultiOptionsField(surveyQuestion, localIdGenerator);
                break;
            default:
                commonParser.logMessage(
                    `Field type ${fieldType} is not a supported type (drop down; check box; radio button), Question Id: ${RedcapParserUtilService.getRedcapQuestionId(surveyQuestion)}`,
                    true,
                );
                break;
            }

            // parse validation and add to field
            redcapParser.parseAndAddValidation(surveyQuestion, field);

            // hide title subfield and add rich text label field before selector field to show as title
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fieldsToAdd, field);

            if (fieldsToAdd) {
                formFields.push(...fieldsToAdd);
            }
        }

        /**
         * This is method to parse text label field field and return RICH_TEXT_LABEL field on our side
         * @param surveyQuestion
         * @param localIdGenerator
         * @param formFields
         * @returns form field dto for rich text label field type
         */
        function parseTextGraphicQuestion (surveyQuestion, localIdGenerator, formFields) {
            const field = constantService.initFormFieldRichTextLabel(localIdGenerator);
            const fieldWithoutCss = commonParser.removeCssFontTagsIfRequired(commonParser.getValueFromObject(surveyQuestion, 'field_label'));
            field.fieldValue.value = redcapParser.replaceLineFeedsIfRequired(fieldWithoutCss);

            //  set Spanish translation if available
            if (surveyQuestion.language && surveyQuestion.language.ES) {
                field.fieldValue.localizationMap.es = { value : commonParser.getValueFromObject(surveyQuestion.language.ES, 'field_label') };
            }

            formFields.push(field);
        }

        function createSectionHeader (surveyQuestion, localIdGenerator, fields) {
            const field = constantService.initFormFieldRichTextLabel(localIdGenerator);
            const sectionHeaderText = commonParser.getValueFromObject(surveyQuestion, 'section_header');
            const fieldWithoutCss = commonParser.removeCssFontTagsIfRequired(sectionHeaderText);
            const fieldWithoutLineFeeds = redcapParser.replaceLineFeedsIfRequired(fieldWithoutCss);

            if (fieldWithoutCss) {
                field.fieldValue.value = fieldWithoutLineFeeds;
                redcapParser.setSpanishTitleSubField(surveyQuestion, field, 'section_header');
                fields.push(field);
            }
        }

        /**
         * Method to parse RedCap notes field. Here we create two form components:
         * 1 - one rich text label
         * 2 - one text input field with 3 number of lines (default is 1)
         */
        function parseNotesInputType (surveyQuestion, localIdGenerator, formFields) {
            const richTextLabel = redcapParser.createTextOrRichTextLabel(surveyQuestion, localIdGenerator);
            const textInputField = constantService.initFormFieldTextInput(localIdGenerator);
            textInputField.fieldValue.numberOfLines = 3;
            formFields.push(richTextLabel);
            formFields.push(textInputField);
        }

        function parseFileType (surveyQuestion, localIdGenerator, formFields) {
            const validationType = redcapParser.getRedcapValidationType(surveyQuestion);
            if (validationType === redcapConstantService.redcapFileValidationTypes.SIGNATURE) {
                createSignatureField(surveyQuestion, localIdGenerator, formFields);
            } else {
                commonParser.logMessage(`File Sub-Type ${validationType} is not supported, Question Id: ${RedcapParserUtilService.getRedcapQuestionId(surveyQuestion)}`, true);
            }
        }

        /**
         * Method to parse slider question to numeric slider
         * @param surveyQuestion
         * @param localIdGenerator
         * @param formFields
         * @returns {[]}
         */
        function parseSliderQuestion (surveyQuestion, localIdGenerator, formFields) {
            const field = constantService.initFormFieldSlider(localIdGenerator);

            // parse field_label text and set in title subfield
            const titleSubField = field.subFields[0];
            if (titleSubField) {
                titleSubField.fieldValue.value = commonParser.getValueFromObject(surveyQuestion, 'field_label');
                //  set Spanish translation if available
                redcapParser.setSpanishTitleSubField(surveyQuestion, titleSubField);
            }

            // parse choices and set in options subfield
            const sliderSubField = field.subFields[1];
            sliderSubField.fieldValue.minValue = 0;
            sliderSubField.fieldValue.maxValue = 100;
            sliderSubField.fieldValue.displayIncrementalValue = 50;
            sliderSubField.fieldValue.showValue = false;

            // Generate the list of text labels...
            const englishChoicesString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');
            let spanishChoiceString = null;
            const englishOptions = [];
            const spanishOptions = [];
            const positionValues = [ '0', '50', '100' ];

            //  parse Spanish options if they exist and add to spanishOptions list
            if (surveyQuestion.language && surveyQuestion.language.ES) {
                spanishChoiceString = commonParser.getValueFromObject(surveyQuestion.language.ES, 'select_choices_or_calculations');
                if (spanishChoiceString) {
                    spanishChoiceString.split('|').forEach((choice, index) => {
                        spanishOptions.push(choice);
                    });
                }
            }

            if (englishChoicesString) {
                englishChoicesString.split('|').forEach((choice, index) => {
                    // to check if choice is empty and is the middle one
                    if (index === 1 && choice.match(/^ *$/) !== null) {
                        sliderSubField.fieldValue.displayIncrementalValue = 100;
                    } else {
                        let option = constantService.initSelectorOptionValue(commonParser.removeHtmlTagsIfRequired(choice), positionValues[index]);
                        //  if there is a Spanish option at the current index then set Spanish translation for English option just created
                        if (spanishOptions[index]) {
                            option.localizationMap.es = { text : spanishOptions[index] };
                        }
                        englishOptions.push(option);
                    }
                });
                sliderSubField.fieldValue.values = englishOptions;
                sliderSubField.fieldValue.showLabel = true;
            }

            // parse validation and add to field
            redcapParser.parseAndAddValidation(surveyQuestion, field);

            // hide title subfield and add rich text label field before selector field to show as title
            const fieldsToAdd = [];
            commonParser.hideSelectorTitleAndPrependRichTextLabelAsTitle(localIdGenerator, fieldsToAdd, field);

            if (fieldsToAdd) {
                formFields.push(...fieldsToAdd);
            }
        }

        return {
            createSectionHeader,
            parseFileType,
            parseMatrixComponent,
            parseMatrixQuestion,
            parseMultipleChoiceQuestion,
            parseNotesInputType,
            parseSliderQuestion,
            parseTextGraphicQuestion,
            parseTextInputQuestion,
        };
    });
