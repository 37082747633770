import ieCSSFix from '../main/util/ie.helper';

/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .run(
            function ($rootScope, $state, Auth, Principal, uibButtonConfig,
                      LoadingService, ConfigService, StateChangeService,
                      ErrorHandlerService, GetUserProvider, SSOService) {
                $rootScope.ENV = process.env.ENV;
                $rootScope.VERSION = process.env.VERSION;
                $rootScope.SHAREDVERSION = process.env.SHAREDVERSION;
                uibButtonConfig.activeClass = 'btn-primary';

                // initialize a number of needed services
                ConfigService.init();
                if (SSOService.isEnable()) {
                    GetUserProvider.getUser();
                }
                StateChangeService.init();
                ErrorHandlerService.init();
                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                $rootScope.back = function () {
                    // If previous state is 'activate' or do not exist go to 'home'
                    if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                        $state.go('home');
                    } else {
                        $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                    }
                };

                $rootScope.$on('$networkInterceptor:showSpinner', function () {
                    LoadingService.startLoading({ spinnerId: 'FULL' });
                });

                $rootScope.$on('$networkInterceptor:hideSpinner', function () {
                    LoadingService.finishLoading('FULL');
                });

                ieCSSFix();
            });

})();
