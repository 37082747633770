/**
 * directive for any if/assert condition (can be a group condition)
 */
angular.module('acadiamasterApp')
    .directive('treeNodeIfAssertCondition',
        (ConditionTreeCreationUtil, ProgramTestConstantsService, AlertService, ngDialog, $timeout, ExpressionConditionModelService) => ({
            controller: ($scope) => {
                $scope.allCreators = ConditionTreeCreationUtil.getAllCreators();
                $scope.canHideConditionButton = function (creator) {
                    const parentExpressionCondition = $scope.condition.getParentExpressionCondition();
                    const usageType = parentExpressionCondition ? parentExpressionCondition.usageType : null;
                    return ConditionTreeCreationUtil.canHideConditionButton(creator, usageType)
                };

                $scope.states = {
                    isAddNewPopupOpen: false,
                };

                $scope.showAddConditionDialog = ($event) => {
                    $scope.states.isAddNewPopupOpen = true;
                    ngDialog.openConfirm({
                        className: 'ngdialog-theme-plain custom-width-small',
                        preCloseCallback: () => {
                            $timeout(() => {
                                $scope.states.isAddNewPopupOpen = false;
                            });
                        },
                        scope: $scope,
                        templateUrl: 'admin-templates/site/components/treeEditorDialogs/addCondition.template.html',
                    }).then((creator) => {
                        creator.createNew($scope.condition, $scope.selector);
                    });
                    $event.stopPropagation();
                };

                $scope.isGroupCondition = () => {
                    const {condition} = $scope;
                    return condition != null && condition.type == ProgramTestConstantsService.QObjectType.GROUP_OBJECT;
                };

                $scope.hasSubItems = () => $scope.isGroupCondition() && $scope.condition.subItems != null
                    && $scope.condition.subItems.length > 0;

                $scope.removeCondition = () => {
                    const parentGroup = $scope.condition._parent;

                    if (parentGroup != null && _.isFunction(parentGroup.removeCondition)) {
                        parentGroup.removeCondition($scope.condition, $scope.selector);
                    }
                };

                $scope.hasParentGroup = () => {
                    if ($scope.condition == null || $scope.condition._parent == null) {
                        return false;
                    }

                    return $scope.condition._parent.type === ProgramTestConstantsService.QObjectType.GROUP_OBJECT;
                };

                $scope.expandCondition = () => {
                    // the condition node that is to be expanded
                    const currentCondition = $scope.condition;
                    const currentParent = currentCondition._parent;

                    // create a group condition with newSubCondition
                    const newGroupCondition = ConditionTreeCreationUtil
                        .createByType(ProgramTestConstantsService.QObjectType.GROUP_OBJECT.name, null);

                    // create subCondition list
                    newGroupCondition.subItems.push(currentCondition);
                    newGroupCondition._parent = currentParent;
                    currentCondition._parent = newGroupCondition;

                    // new condition are created, need to insert back to the tree
                    if ($scope.isTopLevel) {
                        // top level, need to use the set condition function passed in
                        $scope.setTopLevelCondition(newGroupCondition);
                    } else {
                        // not top level, add it to the original parent
                        const previousIndex = currentParent.subItems.indexOf(currentCondition);
                        if (previousIndex === -1) {
                            AlertService.error('Unable to find current index of the item');
                            return;
                        }

                        currentParent.subItems.splice(previousIndex, 1, newGroupCondition);
                        newGroupCondition._parent = currentParent;
                    }

                    $scope.selector.selectItem(newGroupCondition, true);
                };

                $scope.collapseCondition = () => {
                    const {condition} = $scope;
                    const conditionParent = condition._parent;
                    // create a subItems[] list
                    const {subItems} = condition;

                    // top level condition with only one child
                    if (conditionParent==null || conditionParent instanceof ExpressionConditionModelService.ExpressionConditionModel) {
                        $scope.setTopLevelCondition(subItems[0]);
                        subItems[0]._parent = null;
                        // remove current node
                        $scope.removeCondition();
                        return;
                    }

                    const index = conditionParent.subItems.indexOf(condition);
                    if (index < 0) {
                        AlertService.error('Unable to find current index of the item');
                        return;
                    }
                    // remove current node
                    $scope.removeCondition();

                    // insert toBeCollapsed array into conditionParent subItems list at the index of removed condition
                    $scope.insertArrayAt(conditionParent.subItems, index, subItems);

                    _.forEach(subItems, (i) => {
                        i._parent = conditionParent;
                    });
                };

                $scope.insertArrayAt = (array, index, arrayToInsert) => {
                    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
                    return array;
                };

                // decide if the node is valid for collapse
                $scope.validForCollapse = () => {
                    const {condition} = $scope;
                    return condition != null && $scope.isGroupCondition()
                        && (!$scope.isTopLevel || condition.subItems.length === 1)
                };
            },
            restrict: 'E',
            scope: {
                condition: '=',
                isTopLevel: '=',
                selector: '=',
                setTopLevelCondition: '=',
            },
            templateUrl: 'admin-templates/site/programTests/programTest/treeNode/ifAssertCondition.treeNode.html',
        }));
