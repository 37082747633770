(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitCategoryDeleteController', function($scope, ngDialog, UnitCategory) {
            $scope.delete = function() {
                UnitCategory.delete({
                        id: $scope.ngDialogData.id
                    },
                    function() {
                        $scope.confirm($scope.ngDialogData.id);
                    });
            }
        });

})();