
(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('formConfigState', function (FormModelServiceFieldValue, FormConstants) {

            function addValueToMap(map, objKey) {
                // Get object from map
                var localeObj = map[objKey];
                if (localeObj == null) {
                    localeObj = new FormModelServiceFieldValue.ImageValue();
                }
                map[objKey] = localeObj;
            }

            function initUIList($scope) {
                //initialize form icon map
                if ($scope.formIcons == null || angular.equals({},$scope.formIcons)) {
                    $scope.formIcons = {};
                    for(var key in $scope.constants.formStatus){
                        $scope.formIcons[key]=null;
                        addValueToMap($scope.formIcons, key);
                    }

                }
                $scope.formIconsKeys = Object.keys($scope.formIcons);
            }

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/elementConfig.basic.form.state.html',
                scope: {
                    parentForm:'=',
                    formIcons : '=',
                    element:'='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    initUIList($scope);
                }
            };
        });


})();
