(function () {
    'use strict';

    /**
     * loading constants
     */
    angular.module('acadiamasterApp').constant('ValueType',
        {
            NUMBER : 'NUMBER',
            STRING : 'STRING',
            DATETIME : 'DATETIME',
            FILE : 'FILE',
            BOOLEAN : 'BOOLEAN'
        });

})();
