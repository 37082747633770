'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {

        function loadTranslatePartial($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('programTest');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
        }

        $stateProvider
            .state('programTests.programTestAudits', {
                parent: 'programTests',
                url: '/audits?ptsAuditId',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'Program Test Execution Audit'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/programTests/programTestExecutionAudit/programTestExecutionAudits.html',
                        controller: 'ProgramTestAuditController',
                        controllerAs: 'programTestAuditCtrl'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }],
                    ptsAuditId: function (Form, $stateParams) {
                        return $stateParams.ptsAuditId;
                    }
                }
            })
            .state('programTests.programTestAudits.detail', {
                parent: 'programTests.programTestAudits',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'Program Test Execution Audit'
                },
                views: {
                    'content@': {
                        template: '<test-case-audit-detail id="id"></test-case-audit-detail>',
                        controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
                            $scope.id = $stateParams.id;
                        }]
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
            // .state('programTests.programTestAudits.detail', {
            //     parent: 'programTests.programTestAudits',
            //     url: '/detail/{id}',
            //     data: {
            //         authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
            //         pageTitle: 'Program Test Execution Audit'
            //     },
            //     onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
            //         $uibModal.open({
            //             templateUrl: 'admin-templates/site/programTests/programTestExecutionAudit/programTestAudit-detail.html',
            //             controller: 'ProgramTestAuditDetailController',
            //             size: 'lg',
            //             resolve: {
            //                 entity: ['ProgramTestExecutionAudit', function (ProgramTestExecutionAudit) {
            //                     return ProgramTestExecutionAudit.get({id: $stateParams.id});
            //                 }]
            //             }
            //         }).result.then(function(result) {
            //             $state.go('programTests.programTestAudits', null, { reload: true });
            //         }, function() {
            //             $state.go('^');
            //         });
            //     }]
            // });
    });
