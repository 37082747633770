
angular.module('vibrent-external-survey-parser')
    .service('RedcapSurveyParser', (VibrentFormStructureConstantService, CommonParserUtilService, RedcapParserUtilService,
        vbrLocalIdGeneratorService, AlertService, RedcapSurveyQuestionParser, RedcapFormStructureConstantService, FormConstants) => {
        const constantService = VibrentFormStructureConstantService;
        const fieldParser = RedcapSurveyQuestionParser;
        const localIdGenerator = new vbrLocalIdGeneratorService();
        const pageLocalIdToNavigationNodeLocalIdMap = {};
        const commonParser = CommonParserUtilService;
        const redcapConstantService = RedcapFormStructureConstantService;
        const importEntities = FormConstants.importFormFromEntity;
        let isSkipLogicIncluded = false;
        let skipLogicErrorList = [];
        function getSkipLogicErrorList () {
            return skipLogicErrorList;
        }
        function setSkipLogic (flag) {
            isSkipLogicIncluded = flag;
        }
        function parseToVibrentForm (redcapSurveyJson) {
            // reset error list when parsing
            skipLogicErrorList = [];
            if (redcapSurveyJson == null || redcapSurveyJson.length <= 0) {
                // return null as redcap format is null or empty
                return null;
            }

            try {
                const formDto = constantService.initBaseFormDto();

                const groupedMatrixJson = groupMatrixQuestions(redcapSurveyJson);

                // set form basic information
                setFormBasicInformation(formDto, groupedMatrixJson);

                // set form version containing edit, view and prompt mode including pages, nodes and navigation edges
                setFormVersion(formDto, groupedMatrixJson);
                return formDto;
            } catch (e) {
                console.log(e);
                return null;
            }
        }

        /* ************************************************************ */
        /* ********************** Private Functions ******************* */

        function countOptionValues (surveyQuestion) {
            const choicesString = commonParser.getValueFromObject(surveyQuestion, 'select_choices_or_calculations');

            if (!choicesString) {
                return 0;
            }

            const choices = choicesString.split('|');
            return choices.length;
        }
        /*
         * In REDCap a matrix is actually a list of individual radio/checkbox buttons that are joined by a common name in
         * the matrix_group_name field. To simplify later processing we want to collect at of the records for a matrix
         * into a single object that will then be added to the form. In addition, we will change the field_type of the
         * main record to either radio_matrix or checkbox_matrix.
         */
        function groupMatrixQuestions (originalSurveyJson) {
            const cleanJson = [];
            let matrixGroupItems = [];
            let activeMatrixGroupName = '';
            let activeMatrixGroupInput = null;
            let inMatrixGroup = false;

            _.forEach(originalSurveyJson, surveyObject => {
                const fieldName = commonParser.getValueFromObject(surveyObject, 'field_name');

                // The first page in any REDCap form has the name record_id. This page means nothing to us so we can discard it.
                if (fieldName && fieldName === 'record_id') {
                    return;
                }

                // Now we need to identify the matrix questions in the json. For REDCap each row in the matrix is held as a separate
                // object in the JSON structure. We identify them by looking for objects with the field_type of radio or checkbox
                // AND with a value set for matrix_group_name. All objects with the same value for matrix_group_name belong to the
                // same matrix.

                const fieldType = commonParser.getValueFromObject(surveyObject, 'field_type');
                const matrixGroupName = commonParser.getValueFromObject(surveyObject, 'matrix_group_name');

                if (matrixGroupName && fieldType && (fieldType === 'radio' || fieldType === 'checkbox')) {
                    if (inMatrixGroup) {
                        if (matrixGroupName === activeMatrixGroupName) {
                            // We are in a group and the names match - same matrix
                            matrixGroupItems.push(surveyObject);
                        } else {
                            // We are in a group and the name don't match. End current matrix group
                            commonParser.setValueInObject(activeMatrixGroupInput, 'matrix_group_items', matrixGroupItems);
                            inMatrixGroup = true;
                            activeMatrixGroupName = matrixGroupName;
                            activeMatrixGroupInput = surveyObject;
                            matrixGroupItems = [];
                            matrixGroupItems.push(surveyObject);
                            // Mark the main group input as a matrix
                            commonParser.setValueInObject(surveyObject, 'field_type', `${fieldType}_matrix`);
                            // Add the main group input to the list of inputs
                            cleanJson.push(surveyObject);
                        }
                    } else {
                        inMatrixGroup = true;
                        activeMatrixGroupName = matrixGroupName;
                        activeMatrixGroupInput = surveyObject;
                        matrixGroupItems.push(surveyObject);
                        // Mark the main group input as a matrix
                        commonParser.setValueInObject(surveyObject, 'field_type', `${fieldType}_matrix`);
                        // Add the main group input to the list of inputs
                        cleanJson.push(surveyObject);
                    }
                } else {
                    if (inMatrixGroup) {
                        // We were previously in a matrix group, but now we have an item that does not belong to any matrix group.
                        // Finish processing the previous matrix group
                        commonParser.setValueInObject(activeMatrixGroupInput, 'matrix_group_items', matrixGroupItems);
                        activeMatrixGroupName = '';
                        matrixGroupItems = [];
                        inMatrixGroup = false;
                    }

                    // Add the current control to the list of controls
                    cleanJson.push(surveyObject);
                }
            });

            // If we are still in a matrix group even though we've processed all the records, then this means that the last items in the file
            // were part of a matrix group. Update the records accordingly
            if (inMatrixGroup) {
                commonParser.setValueInObject(activeMatrixGroupInput, 'matrix_group_items', matrixGroupItems);
            }

            return cleanJson;
        }

        /*
         * method to return form dto base structure - with form name set.
         */
        function setFormBasicInformation (formDto, redcapSurveyJson) {
            if (redcapSurveyJson == null || redcapSurveyJson[0] == null) {
                commonParser.logMessage('No text or input objects found in REDCap survey', true, true);
                return;
            }

            const formObject = redcapSurveyJson[0];

            // set form name
            const formName = CommonParserUtilService.getValueFromObject(formObject, 'form_name');

            CommonParserUtilService.setValueInObject(formDto, 'name', formName);
            CommonParserUtilService.setValueIfNotNull(formObject, 'form_name', formDto, 'displayName');
            CommonParserUtilService.setValueInObject(formDto, 'externalSurveyName', formName);
            CommonParserUtilService.setValueInObject(formDto, 'externalSource', importEntities.REDCAP);
            commonParser.logMessage(`Parsing REDCap survey ${formName} to Vibrent form structure`);
        }

        function setFormVersion (formDto, redcapSurveyJson) {
            const formVersion = constantService.initBaseFormVersionDto();

            // set edit mode
            setEditMode(formVersion, redcapSurveyJson);

            formDto.formVersions = [ formVersion ];
        }

        function setEditMode (formVersion, redcapSurveyJson) {
            const editMode = formVersion.editMode;

            // add form pages
            addOnePagePerSurveyObject(editMode.pages, editMode.navigationNodes, editMode.navigationEdges, editMode.externalSourceFields, redcapSurveyJson);
        }

        function addOnePagePerSurveyObject (pages, navigationNodes, navigationEdges, externalSourceFields, redcapSurveyJson) {
            if (redcapSurveyJson == null || redcapSurveyJson.length <= 0) {
                commonParser.logMessage('Missing survey elements object in survey', true, true);
                return;
            }
            // add one page per block option
            let pageLocalId = 1;
            let pageDto = null;
            let currentSection = null;
            _.forEach(redcapSurveyJson, (surveyObject, objectIndex) => {
                const fieldName = commonParser.getValueFromObject(surveyObject, 'field_name');
                externalSourceFields.push(fieldName);
                if (fieldName && fieldName !== 'record_id') {
                    const sectionHeader = commonParser.getValueFromObject(surveyObject, 'section_header');

                    //  create new page if this is the first page OR section header is non empty
                    if (objectIndex === 0 || sectionHeader.length > 0) {
                        //  create new page, set local id
                        localIdGenerator.addExistingId(pageLocalId);

                        const isLastPage = objectIndex === redcapSurveyJson.length - 1;
                        pageDto = constantService.initBasePageDto(pageLocalId, isLastPage);

                        // append block id to page name - using main block option
                        setPageName(pageDto, surveyObject);

                        //  create new section on current page
                        //  add section in page
                        addSection(pageDto, pageLocalId, surveyObject);
                        currentSection = pageDto.sections[0];
                        pages.push(pageDto);

                        //  add navigation node and navigation edge for new page
                        addNavigationNodeAndEdgeWithPreviousNode(navigationNodes, navigationEdges, pageDto);

                        //  increment page ID because new page was created
                        pageLocalId = pageLocalId + 1;
                    } else {
                        //  no need to create a new page or new section because section header is an empty string
                        //  add form component to currentSection
                        addFormComponent(currentSection, pageLocalId, surveyObject);
                    }
                    commonParser.logMessage('-----------------------------------------');
                }
            });

            //  when the last page was created, it may not have been clear that it was the last page
            //  show submit button on last page here
            pageDto.showSubmitButton = true;
        }

        function setPageName (pageDto, surveyObject) {
            const objectName = CommonParserUtilService.getValueFromObject(surveyObject, 'field_name');

            pageDto.name = `${objectName } | Page: ${pageDto.localId}`;
            commonParser.logMessage(pageDto.name);
        }

        function addNavigationNodeAndEdgeWithPreviousNode (navigationNodes, navigationEdges, pageDto) {
            // create navigation node and add new navigation node local id to map, it will be used in setting up edges
            const navigationNode = constantService.initNavigationNode(localIdGenerator, pageDto.localId, pageDto.name);
            navigationNodes.push(navigationNode);
            pageLocalIdToNavigationNodeLocalIdMap[pageDto.localId] = navigationNode.localId;

            if (pageDto.localId > 1) {
                // add navigation from last indexed node to this node
                // previous page local Id = this page id minus 1 as page local ids are sequential
                const previousPageLocalId = pageDto.localId - 1;
                const nodeFromLocalId = pageLocalIdToNavigationNodeLocalIdMap[previousPageLocalId];
                const nodeToLocalId = navigationNode.localId;
                const edgeName = `Page ${previousPageLocalId} => Page ${pageDto.localId}`;

                // create navigation edge and add to edges list
                const navigationEdge = constantService.initNavigationEdge(nodeFromLocalId, nodeToLocalId, edgeName);
                navigationEdges.push(navigationEdge);
            }
        }

        function addSection (pageDto, pageLocalId, surveyObject) {
            const sectionDto = constantService.initBaseSectionDto(localIdGenerator, pageLocalId);
            commonParser.logMessage(`Adding section, localId ${sectionDto.localId}`);
            // add skipping logic to whole section if it has skipping logic. Prevent to display blank page
            if (!isMatrixQuestion(surveyObject)) {
                transformRedcapBranchingLogicToVB(surveyObject, sectionDto);
            }
            // add form component in section
            addFormComponent(sectionDto, pageLocalId, surveyObject);

            pageDto.sections.push(sectionDto);
        }

        function isMatrixQuestion (surveyObject) {
            const questionType = RedcapParserUtilService.getRedcapQuestionType(surveyObject);
            return questionType === redcapConstantService.redcapSurveyQuestionTypes.RADIO_MATRIX
                   || questionType === redcapConstantService.redcapSurveyQuestionTypes.CHECKBOX_MATRIX;
        }

        function addFormComponent (sectionDto, pageLocalId, surveyObject) {
            if (surveyObject === undefined || surveyObject === null) {
                commonParser.logMessage(`No questions on page ${pageLocalId}`);
                return;
            }

            const isMatrix = isMatrixQuestion(surveyObject);

            const componentDto = constantService.initBaseComponentDto(localIdGenerator, pageLocalId);
            if (isMatrix) {
                addMatrixFormComponent(componentDto, sectionDto, surveyObject);
            } else {
                addRegularFormComponent(componentDto, sectionDto, surveyObject);
            }
        }

        function addRegularFormComponent (componentDto, sectionDto, surveyObject) {
            const componentList = sectionDto.formComponents;
            let skipLogicValue = _.get(sectionDto.displayConfig, 'advancedVisibilityConfig.conditionText');
            let hasSkipLogic = skipLogicValue !== undefined && componentList.length === 0;
            const formFieldsForQuestion = parseSurveyQuestion(surveyObject);

            addFormFieldsToComponent(componentDto, formFieldsForQuestion);

            if (componentDto.formComponentFields.length > 0) {
                if (!hasSkipLogic) {
                    transformRedcapBranchingLogicToVB(surveyObject, componentDto);
                }
                commonParser.logMessage(`Adding component, localId ${componentDto.localId}`);
                componentList.push(componentDto);
            }
        }

        function addMatrixFormComponent (componentDto, sectionDto, surveyObject) {
            commonParser.logMessage(`Adding matrix component, localId ${componentDto.localId}`);
            componentDto.componentType = 'MATRIX_QUESTIONNAIRE';
            componentDto.displayConfig.padding.configType = 'SIMPLE';
            componentDto.displayConfig.padding.simpleConfig = {};
            componentDto.displayConfig.padding.simpleConfig.value = '20';
            componentDto.displayConfig.padding.simpleConfig.unit = 'dp';
            fieldParser.parseMatrixComponent(componentDto, surveyObject);
            const fields = [];

            fieldParser.parseMatrixQuestion(surveyObject, localIdGenerator, fields);
            addFormFieldsToComponent(componentDto, fields);

            sectionDto.formComponents.push(componentDto);
        }

        function addFormFieldsToComponent (componentDto, fields) {
            if (fields !== null && fields.length > 0) {
                _.forEach(fields, f => {
                    // set current index as display order
                    f.displayOrder = componentDto.formComponentFields.length + 1;
                    componentDto.formComponentFields.push(f);
                });
            }
        }

        function transformRedcapBranchingLogicToVB (surveyQuestion, formComponent) {
            const questionType = RedcapParserUtilService.getRedcapQuestionType(surveyQuestion);
            const branchingLogic = RedcapParserUtilService.getRedCapBranchingLogic(surveyQuestion);
            const questionId = RedcapParserUtilService.getRedcapQuestionId(surveyQuestion);
            commonParser.logMessage(`---Parsing field, type: ${questionType} on fieldname ${questionId} along ${branchingLogic}`);
            let VBLogic = '';
            if (isSkipLogicIncluded) {
                let updateLogic = reformatBranchingLogic(branchingLogic);
                commonParser.logMessage(`-----> ${updateLogic}`);
                VBLogic = parseSkippingLogicToVibrentForm(updateLogic, questionType);
                // if encounter ERROR, add to error list for reporting
                if (VBLogic.indexOf('ERROR') !== -1 || VBLogic === '' && branchingLogic !== '') {
                    skipLogicErrorList.push({ fieldName : questionId, logic : branchingLogic });
                    // eslint-disable-next-line max-len
                    commonParser.logMessage(`--->Unable to translate ${branchingLogic} --> ${VBLogic} for question ${questionId}`);
                }
                commonParser.logMessage(`-->  ${VBLogic}`);
            } else {
                commonParser.logMessage('--->No skipping logic');
            }
            if (VBLogic !== '') {
                formComponent.displayConfig.advancedVisibilityConfig = {
                    conditionText : VBLogic,
                };
            }
        }
        function reformatBranchingLogic (branchingLogic) {
            let formattedBranchingLogic = null;
            if (branchingLogic) {
                formattedBranchingLogic
                    = branchingLogic.replace(/\[/g, '|')
                        .replace(/\]/g, '|')
                        .replace(/[=]/g, '==|')
                        .replace(/ or /g, '|or')
                        .replace(/ and /g, '|and')
                        .replace(/<>/g, '!=|')
                        .split('|')
                        .splice(1);
            }
            return formattedBranchingLogic;
        }
        function parseSkippingLogicToVibrentForm (branchingLogicArray, questionType) {
            let skipLogic = '';

            function equivalenceBuilder (question, responseCode, questionType) {
                // recap check box wil have this format d5(8) = '1' or d5(abc)= '1'
                if (question.match(/\(\w+\)/g) !== null) {
                    let result = equivalenceBuilderMultiSelect(question, questionType);
                    // if parse doesn't contain any error
                    if (result.indexOf('ERROR:') === -1) {
                        // if response code is 0, then redcap checkbox mean not selected.
                        // remove double quote and single quote
                        let cleanedResponseCode = responseCode.replace(/"|'/g, '').trim();
                        return _.toNumber(cleanedResponseCode) === 0 ? `not (${result})` : result;
                    }
                    return result;
                }
                return equivalenceBuilderRadio(question, responseCode, questionType);
            }

            function equivalenceBuilderRadio (question, responseCode, questionType) {
                // remove double quote and single quote
                let cleanedResponseCode = responseCode.replace(/"|'/g, '').trim();
                let condition = questionType === 'checkbox' ? 'contains' : 'eq';
                return `${condition} (toString (fieldEntryValueInCurrentView (${question})), "${question}|${cleanedResponseCode}")`;
            }

            function equivalenceBuilderMultiSelect (question, questionType) {
                if (question.indexOf(')') !== -1) {
                    let variableArray = question.split('(');
                    return equivalenceBuilderRadio(variableArray[0], variableArray[1].split(')')[0], questionType);
                }
                console.log(question);
                return `ERROR: ${question}`;
            }

            function orBuilder (logicArray, questionType) {
                let expression = orArrayBuilder(logicArray, questionType);
                return `or ([${expression}])`;
            }

            function orArrayBuilder (logicArray, questionType) {
                let factorsArray = [];

                for (let i = 0; i < logicArray.length / 4; i++) {
                    factorsArray.push(equivalenceBuilder(logicArray[4 * i], logicArray[4 * i + 2], questionType));
                }
                return factorsArray;
            }

            if (branchingLogicArray && branchingLogicArray.length > 0) {
                if (branchingLogicArray.length === 3) {
                    skipLogic = equivalenceBuilder(branchingLogicArray[0], branchingLogicArray[2], questionType);
                }

                if (branchingLogicArray.length > 3) {
                    skipLogic = orBuilder(branchingLogicArray, questionType);
                }
            }

            return skipLogic;
        }

        /**
         * Method to parse survey question and return one or more form fields
         * @param surveyQuestion - survey question to be parsed
         */
        function parseSurveyQuestion (surveyQuestion) {
            const questionType = RedcapParserUtilService.getRedcapQuestionType(surveyQuestion);
            const questionId = RedcapParserUtilService.getRedcapQuestionId(surveyQuestion);
            commonParser.logMessage(`Parsing field, type: ${questionType} on fieldName ${questionId}`);

            // one question can be parsed to one or more form fields
            const fields = [];

            const isMatrix = isMatrixQuestion(surveyQuestion);
            const sectionHeaderText = commonParser.getValueFromObject(surveyQuestion, 'section_header');
            if (!isMatrix && sectionHeaderText && sectionHeaderText.trim() !== '') {
                fieldParser.createSectionHeader(surveyQuestion, localIdGenerator, fields);
            }
            switch (questionType) {
            case redcapConstantService.redcapSurveyQuestionTypes.TEXT:
                fieldParser.parseTextInputQuestion(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.DROPDOWN:
            case redcapConstantService.redcapSurveyQuestionTypes.CHECKBOX:
            case redcapConstantService.redcapSurveyQuestionTypes.RADIO:
            case redcapConstantService.redcapSurveyQuestionTypes.YES_NO:
            case redcapConstantService.redcapSurveyQuestionTypes.TRUE_FALSE:
                fieldParser.parseMultipleChoiceQuestion(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.RADIO_MATRIX:
            case redcapConstantService.redcapSurveyQuestionTypes.CHECKBOX_MATRIX:
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.FILE:
                fieldParser.parseFileType(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.DESCRIPTIVE:
                fieldParser.parseTextGraphicQuestion(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.NOTES:
                fieldParser.parseNotesInputType(surveyQuestion, localIdGenerator, fields);
                break;
            case redcapConstantService.redcapSurveyQuestionTypes.SLIDER:
                fieldParser.parseSliderQuestion(surveyQuestion, localIdGenerator, fields);
                break;
            default:
                commonParser.logMessage(`Question Type ${questionType} is not supported, Question Id: ${RedcapParserUtilService.getRedcapQuestionId(surveyQuestion)}`, true);
                break;
            }

            if (fields && fields.length > 0 && !isMatrix) {
                // set common values in the fields
                const name = CommonParserUtilService.getValueFromObject(surveyQuestion, 'field_name');

                // set field name for each field
                _.forEach(fields, f => {
                    f.name = name;
                    f.externalFieldName = name;
                });
            }

            return fields;
        }

        function getParserInformationAsHtml () {
            // TODO: This should be converted to an object and then render as an HTML, no time to do it right now
            return '<p>This utility can be used to import a <strong>REDCap Survey</strong> in to a <strong>Vibrent form.</strong></p>'
                + ''
                + '<p>You will need to upload the \'<strong>.json</strong>\' file which contains the REDCap survey definition which can be'
                + '   exported from the REDCap application. Additionally you will also need the REDCap Spanish .json file if you wish to include Spanish support.</p>'
                + '<p>This uses the <strong>Vibrent - REDCap parser(custom library)</strong> to convert the REDCap survey to a vibrent'
                + '   form.</p>'
                + '<p></p>'
                + '<p><strong>REDCap Question Type to Vibrent Field Type Mapping:</strong></p>'
                + '<div>'
                + '    <table class="table table-bordered">'
                + '        <thead>'
                + '        <tr>'
                + '            <th scope="col">REDCap Question Type</th>'
                + '            <th scope="col">Mapped Vibrent Field Type</th>'
                + '            <th scope="col">Details</th>'
                + '        </tr>'
                + '        </thead>'
                + '        <tbody>'
                + '        <tr>'
                + '            <td>Text</td>'
                + '            <td>Text Input</td>'
                + '            <td>This component type is converted into a Text Input with no additional configuration.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(email)</td>'
                + '            <td>Text Input</td>'
                + '            <td>This component type is converted into a Text Input with default validation for an email address.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(daytime)</td>'
                + '            <td>Day Selector and Time Picker</td>'
                + '            <td>'
                + '                <p>This component type is converted into two individual components, a Day Selector and Time Picker.</p>'
                + '                <p>Any minimum and maximum constraints from the REDCap file for the date will be imported.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(integer)</td>'
                + '            <td>Number Input</td>'
                + '            <td>'
                + '                <p>This component type is converted into a Number Input.</p>'
                + '                <p>Any minimum and maximum constraints from the REDCap file for the number will be imported.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(number)</td>'
                + '            <td>Number Input</td>'
                + '            <td>'
                + '                <p>This component type is converted into a Number Input.</p>'
                + '                <p>Any minimum and maximum constraints from the REDCap file for the number will be imported.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(phone)</td>'
                + '            <td>Phone Input</td>'
                + '            <td>This component type is converted into a Phone Input which contains validations for a US phone number.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(time)</td>'
                + '            <td>Time Picker</td>'
                + '            <td>'
                + '                <p>This component type is converted into a Time Picker.</p>'
                + '                <p>Any minimum and maximum constraints from the REDCap file for the number will be imported.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Text<br>(zipcode)</td>'
                + '            <td>Text Input</td>'
                + '            <td>This component type is converted into a Text Input with default validation for a zipcode.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Descriptive</td>'
                + '            <td>Rich Text Label</td>'
                + '            <td>The component type is converted into a Rich Text Label.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Notes</td>'
                + '            <td>Text Input</td>'
                + '            <td>This component type is converted into a multi line Text Input component.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Dropdown</td>'
                + '            <td>'
                + '                Drop Down'
                + '            </td>'
                + '            <td>'
                + '                This component type is converted into a Drop Down menu. The \'value type\' will be number if all the drop option values'
                + '                are numbers, otherwise it will be string.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Checkbox</td>'
                + '            <td>'
                + '                Multi Selector'
                + '            </td>'
                + '            <td>'
                + '                This component type is converted into a Multi Selector component configured to operate as check boxes (i.e. multiple values can be selected).<br>'
                + '                The \'value type\' will be number if all the option values are numbers, otherwise it will be string.<br>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Radio</td>'
                + '            <td>'
                + '                <p>Radio Selector</p>'
                + '            </td>'
                + '            <td>'
                + '                Radio components are converted to a Radio Selector.<br>'
                + '                The \'value type\' will be number if all the option values are numbers, otherwise it will be string.<br>'
                + '                The orientation of the question can be either horizontal or vertical based on the value in \'custom_alignment\'.<br>'
                + '                \'LH\' or \'RH\' will be displayed horizontal while \'LV\' or \'RV\' will be displayed vertical.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>YesNo</td>'
                + '            <td>'
                + '                Radio Selector'
                + '            </td>'
                + '            <td>'
                + '                YesNo components are converted to a Radio Selector with the options \'Yes\' and \'No\'.<br>'
                + '                The value type is set to number and the values are 1 (Yes) and 0 (No).'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>TrueFalse</td>'
                + '            <td>'
                + '                Radio Selector'
                + '            </td>'
                + '            <td>'
                + '                TrueFalse components are converted to a Radio Selector with the options \'True\' and \'False\'.<br>'
                + '                The value type is set to number and the values are 1 (True) and 0 (False).'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Matrix</td>'
                + '            <td>'
                + '                Matrix'
                + '            </td>'
                + '            <td>'
                + '                Matrix componets is imported into a Matrix Compnents with the appropriate Matrix Question added for each question.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>File (Signature)</td>'
                + '            <td>Signature box</td>'
                + '            <td>This component is converted into Signature box.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Slider</td>'
                + '            <td>Slider</td>'
                + '            <td>'
                + '                This component is converted to a Slider with the range of 0 to 100 to match REDCap, a selection increment of 1 and display increment of 50.<br>'
                + '                If \'text_validation_type_or_show_slider_number\' has a value of \'number\' then the selected value will be shown above the slider.<br>'
                + '                <em>NOTE:</em> Vibrent slider only supports horizontal, so all sliders will be imported as horizontal (vertical is not supported).'
                + '            </td>'
                + '        </tr>'
                + '        </tbody>'
                + '    </table>'
                + '    <p></p>'
                + '    <p><strong>Other Supported Items</strong>:</p>'
                + '    <table class="table table-bordered">'
                + '        <thead>'
                + '        <tr>'
                + '            <th scope="col">Item</th>'
                + '            <th scope="col">Details</th>'
                + '        </tr>'
                + '        </thead>'
                + '        <tbody>'
                + '        <tr>'
                + '            <td>Page Breaks</td>'
                + '            <td>'
                + '               <p>A new page will be created whenever the field \'section_header\' in the RECap file is not empty.</p>'
                + '               <p>If the \'section_header\' contains anything other than white space the contents will be created as a label at the top of the page.</p>'
                + '               <p>If the \'section_header\' contains only white space a new page will be started, but no label will be added.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Validation</td>'
                + '            <td>'
                + '                REQUIRED validation is parsed from REDCap surveys for all field types. Any additional validations imported from'
                + '                the REDCap file are noted in the field type description above.'
                + '            </td>'
                + '        </tr>'
                + '        </tbody>'
                + '    </table>'
                + '    <p></p>'
                + '    <p><strong>Parser Limitations</strong>:</p>'
                + '    <table class="table table-bordered">'
                + '        <thead>'
                + '        <tr>'
                + '            <th scope="col">Item</th>'
                + '            <th scope="col">Details</th>'
                + '        </tr>'
                + '        </thead>'
                + '        <tbody>'
                + '        <tr>'
                + '            <td>Question Skip Logic</td>'
                + '            <td>Currently question skip logic is not parsed and it manually needs to be configured by comparing imported form with REDCap survey.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Page Skip logic</td>'
                + '            <td>Currently page skip logic is not parsed and it manually needs to be configured by comparing imported form with REDCap survey.</td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Input text box in Selector field</td>'
                + '            <td>'
                + '                REDCap Multi Choice type supports the feature to configure input text in last option(example,'
                + '                   \'None of above, please specify\') to accept user\'s input. Currently this is not parsed.<br>'
                + '                <p>Our system can support this by using reference field but it needs to be manually configured.</p>'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>File Upload</td>'
                + '            <td>'
                + '                Only File types of Signature are currently supported.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Descriptive Input</td>'
                + '            <td>'
                + '                Only supports importing text. Images and Videos will not be imported.'
                + '            </td>'
                + '        </tr>'
                + '        <tr>'
                + '            <td>Matrix</td>'
                + '            <td>'
                + '                Skip logic/carry forward questions are not currently implemented for the Matrix component and will be added in a future releease.'
                + '            </td>'
                + '        </tr>'
                + '        </tbody>'
                + '    </table>'
                + '</div>';
        }

        return {
            getParserInformationAsHtml,
            getSkipLogicErrorList,
            parseToVibrentForm,
            setSkipLogic,
            transformRedcapBranchingLogicToVB,
        };
    });
