/* eslint-disable */
angular.module('acadiamasterApp')
    .controller('expressionFunctionDefinitionController', function ($filter, ExpressionConstantsService) {
        let vm = this;

        vm.EXAMPLE_NOTES = 'notes';
        vm.FORMATTED_EXPRESSION = 'formattedExpression';
        vm.OVERALL_EXAMPLE_NOTES = 'exampleNotes';
        vm.UNFORMATTED = 'unformatted';

        vm.ID = {
            DESCRIPTION   : 'description',
            DETAILS       : 'details',
            EXAMPLES      : 'examples',
            EXPRESSION    : 'expression',
            FUNCTION_NAME : 'name',
            MODULES       : 'modules',
            PARAMETERS    : 'parameters',
            RETURNS       : 'returns',
            WARNING       : 'warning',
        };

        vm.headers = [
            vm.ID.FUNCTION_NAME,
            vm.ID.DETAILS,
        ];

        vm.moduleIcons = {};
        vm.moduleIcons[ExpressionConstantsService.modules.RULES] = 'fa-magic';
        vm.moduleIcons[ExpressionConstantsService.modules.STATE_RULES] = 'fa-pencil';
        vm.moduleIcons[ExpressionConstantsService.modules.VISUALIZATION_TEMPLATE] = 'fa-pie-chart';

        vm.$onInit = () => {
            let functions = [];
            Object.keys(ExpressionConstantsService.functions).forEach(key => {
                let fn = ExpressionConstantsService.functions[key];
                // only show functions that match the page's usedBy value
                if (fn.usedBy.indexOf(vm.usedBy) !== -1) {
                    // if the function has examples, format them here to avoid
                    // multiple API calls when the search filter changes
                    if (fn.examples) {
                        fn.examples.forEach(example => {
                            if (!example[vm.UNFORMATTED]) {
                                example[vm.FORMATTED_EXPRESSION] = $filter(
                                    'formatExpression')(
                                    example[vm.ID.EXPRESSION],
                                    vm.usedBy,
                                );
                                example[vm.UNFORMATTED] = false;
                            }
                        });
                    }
                    functions.push(fn);
                }
            });
            vm.functions = functions;
        };
    });
