/**
 * Created by jamie nola on 02/01/19
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * admin dashboard directive
     */
        .directive('systemSupportedLanguages', function ($translate, ConfigService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/systemSupportedLanguages/systemSupportedLanguages.template.html',
                controller: function($scope) {

                    // TODO: Get this list of supported languages from the API. Right now the config
                    // only shows a language if it is enabled, so there is no way to know
                    // if a language is supported by our app but is disabled for the current system.
                    // So instead of getting them dynamically, we hardcode English and Spanish here:
                    var locales = ['en', 'es'];
                    var systemLocaleMap = {};

                    // map configured system locales
                    var config = ConfigService.getConfig();
                    config.systemLocales.forEach(function(locale) {
                        systemLocaleMap[locale.locale] = true;
                    });

                    // create array of objects for the UI to use
                    $scope.locales = locales.map(function(locale) {
                        return {
                            displayName: $translate.instant('locale.' + locale),
                            isEnabled: systemLocaleMap[locale] || false
                        }
                    });
                    $scope.config = ConfigService.getConfig();
                }
            };
        });

})();
