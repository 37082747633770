/* eslint-disable sort-keys-fix/sort-keys-fix */
import timeUtilService from '../service/timeUtil.service';
import linkUtilService from '../service/linkUtil.service';

/**
 * Created by jason.cao on 1/30/2020
 * directive for displaying a single operation history in detail with
 * 1. message history
 * 2. progress with live update
 **/
angular.module('acadiamasterApp').directive('operationHistoryDetail', (
    OperationHistoryService, OperationHistoryMessageService, AlertService,
    OperationStatusService, SEARCH_FILTER_CONSTANTS, ngDialog, $timeout) => {
    // reload progress every 30 seconds if the test is running
    const waitInterval = 30000;

    return {
        restrict    : 'E',
        templateUrl : 'admin-templates/site/tools/operationHistory/detail/operationDetail.html',
        scope       : {
            operationId : '=',
        },
        link : function ($scope) {
            $scope.operationHistoryDataObject = {
                operationId : $scope.operationId,

                // an handler used to cancel the reloads
                reloadHandler : null,

                data    : null,
                isReady : false,
            };

            $scope.data = {
                filteredFields : [
                    SEARCH_FILTER_CONSTANTS.FIELDS.LOGGING_LEVELS,
                    SEARCH_FILTER_CONSTANTS.FIELDS.SEARCH,
                ],
                filteredValues : {},
            };

            $scope.loggingMessageDataObject = {
                operationId : $scope.operationId,
                // page of messages here containing message, total number of pages, etc
                data        : null,
                isReady     : false,
                isVisible   : false,
                links       : {},

                // pageable object used to control the loading of messages
                pageable : {
                    page : 0,
                    size : 20,
                    sort : 'id,ASC',
                },
            };

            $scope.loadMessagePageFunction = function (pageNum) {
                $scope.loggingMessageDataObject.pageable.page = pageNum;
                loadLoggingMessages($scope.loggingMessageDataObject, $scope.data.filteredValues);
            };

            $scope.getDuration = function () {
                const opHistory = $scope.operationHistoryDataObject.data;

                return timeUtilService.getDurationAsMinutesAndSeconds(opHistory.startTime, opHistory.endTime,
                    $scope.isRunning());
            };

            $scope.getRemainingTime = function () {
                const opHistory = $scope.operationHistoryDataObject.data;
                const isRunning = $scope.isRunning();

                if (!isRunning) {
                    return 'Done';
                }

                if (opHistory.totalCompletedCount <= 0) {
                    return 'N/A';
                }

                const currentCompletedCount = opHistory.totalCompletedCount + opHistory.totalFailedCount;
                const currentRunTime = timeUtilService.getCurrentRunTimeInMillis(opHistory.startTime, opHistory.endTime,
                    isRunning);

                const remainingCount = opHistory.totalCount - currentCompletedCount;

                if (remainingCount <= 0) {
                    return 'Done';
                }

                const remainTimeInMilli = currentRunTime / currentCompletedCount * remainingCount;

                return timeUtilService.timeInMillisToHumanReadable(remainTimeInMilli);
            };

            $scope.isRunning = function () {
                const opHistory = $scope.operationHistoryDataObject.data;
                return OperationStatusService.isRunning(opHistory.status);
            };

            $scope.stopOperation = function () {
                const opHistory = $scope.operationHistoryDataObject.data;
                // show confirmation window
                ngDialog.openConfirm({
                    template   : 'admin-templates/site/tools/operationHistory/deleteConfirmation/stopOperationConfirmation.html',
                    controller : 'StopOperationController',
                    data       : opHistory,
                }).then(() => {
                    init($scope);
                });
            };

            $scope.$on('$destroy', () => {
                // Make sure that the handler is destroyed too
                if ($scope.operationHistoryDataObject.reloadHandler) {
                    $timeout.cancel($scope.operationHistoryDataObject.reloadHandler);
                }
            });

            init($scope);
        },
    };

    /**
     * initialization function, load the data here
     **/
    function init ($scope) {
        loadOperationHistory($scope.operationHistoryDataObject);
        loadLoggingMessages($scope.loggingMessageDataObject);
    }

    /**
     * start checking the status of the operation history until it is completed or stopped
     * @param operationHistoryDataObject - scope of the directive
     * @param operationHistoryDataObject - a object that contains
     *         * operation id,
     *         * handler to stop the next pull
     *         * data - actual content of the operation history
     *         * isReady - flag to indicate if the data is available or not
     */
    function startMonitoringOperationHistory (operationHistoryDataObject) {
        // run it after some wait
        operationHistoryDataObject.reloadHandler = $timeout(() => {
            loadOperationHistory(operationHistoryDataObject);
        }, waitInterval);
    }

    /**
     * loading the operation, the result will be loaded into the operation history object passed in
     * @param operationHistoryDataObject - a object that contains
     *         * operation id,
     *         * handler to stop the next pull
     *         * data - actual content of the operation history
     *         * isReady - flag to indicate if the data is available or not
     */
    function loadOperationHistory (operationHistoryDataObject) {
        operationHistoryDataObject.isReady = false;
        operationHistoryDataObject.reloadHandler = null;
        OperationHistoryService.findById(operationHistoryDataObject.operationId)
            .then(resp => {
                operationHistoryDataObject.data = resp.data;

                if (OperationStatusService.isRunning(operationHistoryDataObject.data.status)) {
                    startMonitoringOperationHistory(operationHistoryDataObject);
                }
            }, error => {
                console.error('something went wrong in loading operation history', error);
                AlertService.error('something went wrong in loading operation history, check console or error detail');
            }).finally(() => {
                operationHistoryDataObject.isReady = true;
            });
    }

    /**
     * loading the logging message one page at a time, result will be saved back to the data field in the
     * logging message data object
     * @param loggingMessageDataObject - logging message data object including the following
     *     * operationId -- operation id for the messages
     *     * data -- page result including total number of page and elements as well as one page of data
     *     * isReady -- flag to indicate if data is ready
     *     * pageable -- pageable object including page size, page number, sorting info, etc
     */
    function loadLoggingMessages (loggingMessageDataObject, filteredValues) {
        loggingMessageDataObject.isReady = false;
        OperationHistoryMessageService.query(loggingMessageDataObject.operationId, filteredValues.loggingLevels, filteredValues.search, loggingMessageDataObject.pageable)
            .then(resp => {
                loggingMessageDataObject.data = resp.data;
                loggingMessageDataObject.links = linkUtilService.calculateLinks(resp.data);
            }, error => {
                console.error('something went wrong in loading operation history message', error);
                AlertService.error('something went wrong in loading operation history message, check console or error detail');
            }).finally(() => {
                loggingMessageDataObject.isReady = true;
            });
    }
});
