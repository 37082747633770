(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('syncDeviceToggleStateEqualPreCondition', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/' +
            'preConditionDirective/syncDeviceToggleStateEqualPreCondition.html',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {
                $scope.deviceEnum = {
                    FITBIT: {name: 'FITBIT',text: 'FITBIT'},
                    OMRON: {name: 'OMRON', text: 'OMRON'},
                };

                /**
                 * program options used for program search directive
                 * @type {{getProgram: getProgram, setProgram: setProgram}}
                 */
                $scope.programOptions = {
                    getProgram: function () {
                        return $scope.preCondition.getProgram();
                    },
                    setProgram: function (program) {
                        $scope.preCondition.setProgram(program);
                    },
                };
            }
        };
    });
})();