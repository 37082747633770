angular.module('acadiamasterApp')
    .factory('SecurityService', ($http, localWeb) => {
        /**
         * reset the authentication key
         * @returns {HttpPromise} return http promise, on success, it will
         *                        include the new authentication key
         */
        const resetAuthenticationKey = userId => {
            const url = localWeb.createUrl(`api/users/requestNewAuthenticationKey/${userId}`);
            const headers = {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=utf-8',
                Accept         : 'application/json',
            };
            return $http.post(url, null, {
                headers,
            });
        };

        const deleteAuthenticationKey = userId => {
            const url = localWeb.createUrl(`api/users/deleteAuthenticationKey/${userId}`);
            const headers = {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=utf-8',
                Accept         : 'application/json',
            };
            return $http.post(url, null, {
                headers,
            });
        };

        return {
            resetAuthenticationKey,
            deleteAuthenticationKey,
        };
    });
