'use strict';

angular.module('acadiamasterApp')
    .factory('Tags', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/tag/:id'), {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method: 'PUT' }
        });
    }).factory('TagLookup', function ($http, localWeb) {
        var tags = [];
        var tagNamesOnly = [];
        var callBackFunctions = [];
        var states = {
            ready: 1,
            loading: 2,
            error: 3,
            uninitialized: 4
        };
        var state = states.uninitialized;

        function processTags(data) {
            tagNamesOnly = [];
            tags = [];
            for (var i = 0; i < data.length; i++) {
                var tagObject = data[i];
                tagNamesOnly.push(tagObject.name);
                tags[tagObject.name] = tagObject;
            }

            tagNamesOnly.sort();
        }

        function loadTags() {
            state = states.loading;
            return $http({
                method: 'GET',
                url: localWeb.createUrl('/api/tag/')
            }).then(function (response) {
                processTags(response.data);
                state = states.ready;
                return tagNamesOnly;
            }, function (response) {
                state = states.error;
                return response;

            });

        }
        return {
            /**
             * initialization function that will load all the properties from server
             */
            init: function () {
                return loadTags();
            },

            /**
             * this will trigger an reload call from server
             */
            updateTags: function () {
                return loadTags();
            },

            isTagLookupReady: function () {
                return state == states.ready;
            },
            resetReadyState: function () {
                state = states.uninitialized;
            },
            addListener: function (callBackSuccess, callBackError) {

                var callBacks = {
                    onSuccess: null,
                    onError: null
                };

                if ($.isFunction(callBackSuccess)) {
                    callBacks.onSuccess = callBackSuccess;
                }

                if ($.isFunction(callBackError)) {
                    callBacks.onError = callBackError;
                }

                callBackFunctions.push(callBacks);
            },

            getTagNames: function () {
                return tagNamesOnly;
            },

            getTagFromName: function (name) {
                return tags[name];
            }

        };

    });
