'use strict';
angular.module('acadiamasterApp').factory('Interstitial', function(localWeb, $resource) {
	return $resource(localWeb.createUrl('api/uploadInterstitialFile'), {}, {
		'uploadFile': {
			method: 'POST',
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined
			},
			transformResponse: function(data, headers, status) {
				var response = {};
				console.log(response);
				response.data = data;
				response.headers = headers();
				response.status = status;
				return response;
			}
		},
		'getFile': {
			method: 'GET',
			url: localWeb.createUrl('api/getInterstitialFile'),
			transformResponse: function(data) {
				var response = {};
				response.data = data;
				return response;
			}
		},
		'removeFile': {
			method: 'DELETE',
			url: localWeb.createUrl('api/deleteInterstitialFile')
		}
		//Right now we have hard coded the path for preview we will use this method later
		/*,
        	 'showFile': {
                 method: 'POST',
                 url:localWeb.createUrl('/assets/html/en/index.html'),
                 responseType: "arraybuffer",
                 transformResponse: function (data, headers, status) {
                     var response = {};
     	            console.log(response);
     	            response.data = data;
     	            response.headers = headers();
     	            response.status=status;
     	            return response;
                 }
             }*/
	});
});