/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('ruleElementConfigActionEmailNotification',
        function (RuleUtilService, LocaleConstantsService, RuleActionModelService, ngDialog, FormConstants, RuleConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.email.notification.html',
                scope: {
                    action: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.programId = $scope.action.getRoot().programId;
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.isReady = false;
                    $scope.data = {
                        accordionStatus: {
                            emailConfiguration: false,
                            smsConfiguration: false
                        }
                    };

                    $scope.form = {};
                    $scope.form.options = {
                        form: $scope.action.consentForm,
                        title: 'Form',
                        viewOnly: false,
                        formCategory: FormConstants.formCategories.CONSENT_FORM
                    };

                    init($scope);


                    // ------------------------- don't put any initialization after this -----------------------

                    $scope.openHelpPage = openHelpPage;

                    $scope.addAttachment = function (index) {
                        if (index == null) {
                            index = 0;
                        }
                        $scope.action.attachments.splice(index + 1, 0, new RuleActionModelService.NotificationConfigFileAttachment());
                    };

                    $scope.removeAttachment = function (index) {
                        $scope.action.attachments.splice(index, 1);
                    };

                    $scope.isConfigComplete = function (type) {
                        if (type === $scope.cs.emailOrSMSNotificationPreference.EMAIL) {
                            if ($scope.action.subject == null || $scope.action.subject === '') {
                                return false;
                            }
                            if ($scope.action.notificationTemplateType === $scope.cs.notificationTemplateType.TEMPLATE) {
                                if ((!$scope.action.templateFileUrl || $scope.action.templateFileUrl == null) && (!$scope.action.templateFile && $scope.action.templateFile == null)) {
                                    return false;
                                }
                            } else {
                                if ($scope.action.message == null || $scope.action.message.length === 0) {
                                    return false;
                                }
                            }
                        } else if (type === $scope.cs.emailOrSMSNotificationPreference.SMS) {
                            if ($scope.action.smsContent == null || $scope.action.smsContent.length < 1) {
                                return false;
                            }
                        }

                        return true;
                    }

                    $scope.allowToSelectPreferences = function () {
                        //This is added when this directive is used on terms of composition wih other actions
                        if ($scope.parentForm && $scope.parentForm.type && $scope.parentForm.type.name === RuleConstantsService.actionType.UNAUTHENTICATED_TASK.name) {
                            return false;
                        }
                        return true;
                    }
                }
            };


            /* *****************************************************************
             * private functions
             * ***************************************************************** */
            function init($scope) {
                $scope.isReady = true;

                $scope.$watch('form.options.form', function (newForm) {
                    $scope.action.consentForm = newForm;
                });
            }

            function openHelpPage() {
                ngDialog.open({
                    template: 'admin-templates/site/businessRule/configPanel/action/emailTextHolderHelp.html',
                    closeByNavigation : true,
                    closeByDocument : true,
                    height : '70%',
                    className : 'ngdialog-theme-plain custom-width-large'
                });
            }
        });

})();

