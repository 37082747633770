(function () {
    'use strict';

    angular.module('acadiamasterApp').service('ExpirationConfigDataListModel', function (ModelServiceConditionExpression) {

        function ExpirationConfigDataListModel() {
            this.expirationDateType = 'FIXED';
            this.absoluteDateVal = null;
            this.relativeDateValue = null;
            this.valueExpression = null;
            this.expressionTree = null;
            this.isDatePopUpOpened = false;
        }

        ExpirationConfigDataListModel.prototype.toDto = function () {
            let dto = {};
            dto.expirationDateType = this.expirationDateType;
            if(this.expirationDateType === 'FIXED'){
                if(this.absoluteDateVal){
                    dto.absoluteDateVal = this.absoluteDateVal.getTime();
                }
                dto.relativeDateValue = null;
            } else {
                dto.relativeDateValue = this.relativeDateValue;
                dto.absoluteDateVal = null;
            }
            if (this.valueExpression != null && this.valueExpression.length > 0 && this.expressionTree != null) {
                dto.valueExpression = this.valueExpression;
                dto.expressionTree = this.expressionTree.toDto();
            } else {
                dto.expressionTree = null;
                dto.valueExpression = null;
            }
            return dto;
        };

        ExpirationConfigDataListModel.prototype.fromDto = function (dto) {
            this.expirationDateType = dto.expirationDateType;
            this.absoluteDateVal = dto.absoluteDateVal ? new Date(dto.absoluteDateVal) : null;
            this.relativeDateValue = dto.relativeDateValue ? dto.relativeDateValue : null;
            this.valueExpression = dto.valueExpression;
            if (dto.expressionTree != null) {
                this.expressionTree = new ModelServiceConditionExpression.ConditionExpression(this);
                this.expressionTree.fromDto(dto.expressionTree);
            }
        };

        return ExpirationConfigDataListModel;

    });


})();
