/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueWheelOptions', function () {

            return {
                restrict: 'E',
                template: '        <form-field-config-field-value-slider-base-options                                                  \
                                   field-value-change="fieldValueChange"                                                           \
                                   field-value="fieldValue" field-element="fieldElement" display-strings="displayStrings">         \
                               </form-field-config-field-value-slider-base-options>',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.displayStrings = {};
                    $scope.displayStrings.minValue = "Min Value";
                    $scope.displayStrings.maxValue = "Max Value";
                    $scope.displayStrings.displayIncrementalValue = "Large Dot Interval";
                    $scope.displayStrings.selectionIncrementalValue = "Small Dot Interval";
                    $scope.displayStrings.selectionIncrementalValue = "Small Dot Interval";
                    $scope.displayStrings.selectionInterval = "Selection Interval";
                }
            }

        });

})();

