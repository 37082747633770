'use strict';

(function () {

    angular.module('acadiamasterApp').directive('propertyPreCondition', function () {
        return {
            restrict: 'E',
            template: '<property-selection ng-if="propertyOptions" property-options="propertyOptions" ' +
            'is-required="true" title="Select Property"></property-selection>',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {
                $scope.propertyOptions = {
                    getProperty : function() {
                        return $scope.preCondition.getProperty();
                    },
                    setProperty : function(newValue) {
                        $scope.preCondition.setProperty(newValue);
                    }
                };
            }
        };

    });
})();