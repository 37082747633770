/**
 * drc dashboard directive
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * drc dashboard directive
     */
        .directive('vbrDrcDashboard', function () {
            return {
                restrict: 'E',
                templateUrl: "admin-templates/site/drc/drc.dashboard.html",
                controller: function($scope, TILE_CONSTANTS) {
                    $scope.data = {
                        tileCollectionId: TILE_CONSTANTS.ID.DRC
                    };
                }
            };

        });

})();
