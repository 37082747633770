(function () {
    angular.module('acadiamasterApp')
        .factory('UserSyncService', [ '$http', 'localWeb', function ($http, localWeb) {
            let url = localWeb.createUrl('/api/drc/sync/conflictAndSyncFailedUsers?');
            return {
                query : function (paginationParams) {
                    return $http({
                        method : 'GET',
                        // eslint-disable-next-line no-undef
                        url    : url + $.param(paginationParams),
                    }).then(response => {
                        return response;
                    }, error => {
                        console.log(error);
                        return error;
                    });
                },
            };
        } ]);
}());
