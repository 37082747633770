angular.module('acadiamasterApp')
    .factory('GetUserProvider', ($http, localWeb, localStorageService, ModalQueueService, sessionCache, $rootScope, $cookies, CookieService, Principal, SessionStorageService) => {
        return {
            getUser () {
                return $http.get(localWeb.createUrl('/api/account/managed'))
                    .then(response => {
                        localStorageService.clearAll();
                        sessionCache.removeAll();
                        localStorageService.set('user', response.data?.baseUserDTO);
                        localStorageService.set('subscriptions', response.data?.activeProgramSubscriptions);
                        Principal.authenticate(response.data?.baseUserDTO);
                        // Setting this cookie as PDF links (like consent) need token cookie.
                        // eslint-disable-next-line angular/window-service
                        document.cookie = `token=${window.keycloakAuth.token}; expires=${new Date(window.keycloakAuth.tokenParsed.exp * 1000).toUTCString()}; Secure; path=/; SameSite=Lax;`;
                        SessionStorageService.clear();
                        return response.data;
                    });
            },
        };
    });
