(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * form configuration directive
     */
        .directive('formConfiguration', function (vbrCommonUtil, FormUtilService, DataTypeUniqueByName, $location,
                                                  $state, Form, $rootScope, formCache, $timeout, CommonResource, CONFIG,
                                                  ngDialog, AlertService, VbrSelectorService, FormPreviewService, FormConstants) {
            var PING_TIME_SEC = 900; // 15 minutes
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/form/formConfiguration.html',
                scope: {
                    category         : '@',
                    form             : '=',
                    hideImportButton : '=',
                    isNew            : '=',
                    programCode      : '<',
                    programId        : '<',
                    stateAfterCancel : '=',
                    stateAfterSave   : '=',
                },
                link: function ($scope, element) {
                    $scope.formService = FormUtilService.FormModelServiceForm;
                    $scope.sectionService = FormUtilService.FormModelServiceSection;
                    $scope.componentModelService = FormUtilService.modelServiceComponent;
                    $scope.stateControlUtil = FormUtilService.stateControlUtil;
                    $scope.dataTypeUniqueByName = DataTypeUniqueByName;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.CONFIG = CONFIG;
                    $scope.cs = FormConstants;

                    $scope.selector = VbrSelectorService.createSelector();

                    // States for create/edit controller
                    $scope.states = {
                        entityReady: false,
                        isAddFieldPopupOpen: false,
                        debugEnabled: false, savedAlready: false, advancedPanelShow: false, includeSecondPanel: false
                    };
                    $scope.previewWindow = null;

                    // Controls
                    $scope.controls = {
                        cancel: function () {
                            $state.go($scope.stateAfterCancel);
                        },
                        save: function (shouldExit) {
                            $scope.saveCount = 0;
                            $scope.timeStart = (new Date()).getTime();
                            doSave($scope, shouldExit);
                        },
                        preview: function () {
                            var url = `#/forms/newPreview?formId=${$scope.form.id}&versionId=${$scope.form.formVersions[0].id}`;
                            FormPreviewService.openPreviewTab($scope.form, url);
                        },
                        onSaveFinishedAndExit: function () {
                            $state.go($scope.stateAfterSave);
                        },

                        showImportFormWindow: function () {
                            showImportFormWindow($scope);
                        },
                        isValid: function () {
                            $scope.form.validate(true);
                            return $scope.form.isValid();
                        },
                        validate: function () {
                            $scope.form.validate(true);
                            if ($scope.controls.isValid()) {
                                AlertService.success('Your configuration seems alright. Go ahead and save it.');
                            } else {
                                AlertService.warning('There are some validation errors in the form you are trying to save. ' +
                                    'Please check the highlighted nodes to see what is missing. \n' +
                                    'If the form is too big, enable advanced panel. Go to issues tab and hit check configuration button. ' +
                                    'You will be able to see list of errors at one go');
                            }
                        }
                    };

                    /**
                     * tree names, used to identify whether an event should go to first tree or 2nd tree
                     * @type {{tree1: string, tree2: string}}
                     */
                    $scope.treeNames = {
                        tree1: 'tree1',
                        tree2: 'tree2'
                    };

                    $scope.goNext = function () {
                        $scope.selector.goNext();
                        $scope.selector.setElementParentKeyValue($scope.selector.selectedItem, '_parent', ['collapsed', 'sectionCollapsed', 'stateRuleCollapsed'], false);
                    };

                    $scope.goPrevious = function () {
                        $scope.selector.goPrevious();
                        $scope.selector.setElementParentKeyValue($scope.selector.selectedItem, '_parent', ['collapsed', 'sectionCollapsed', 'stateRuleCollapsed'], false);
                    };

                    $scope.secondPanelChanged = function () {
                        if ($scope.states.includeSecondPanel) {
                            $scope.states.advancedPanelShow = false;
                        }
                    };

                    $scope.advancedPanelChanged = function () {
                        if ($scope.states.advancedPanelShow) {
                            $scope.states.includeSecondPanel = false;
                        }
                    };

                    init($scope, element);
                }
            };


            /******************************************************************************
             * private functions
             ******************************************************************************/

            /**
             * main init function for the directive
             */
            function init($scope, element) {
                vbrCommonUtil.translate.addPartsAndRefresh([
                    'formComponent',
                    'dataType',
                    'formComponentField',
                    'property',
                    'global',
                    'form'
                ]);
                resolveEntityIfNeeded($scope);

                setupItemSelectionListener($scope, element);

                schedulePingRequest($scope, PING_TIME_SEC);
            }

            function schedulePingRequest($scope, timeInSeconds) {
                var timeInMillis = timeInSeconds * 1000;

                // method to
                var callPingWebservice = function () {
                    var promise = CommonResource.ping();
                    promise.then(function () {
                        // handling is not needed
                        $scope.pingRequestTimeout = $timeout(callPingWebservice, timeInMillis);
                    }, function (response) {
                        console.error('error in ping server api call', response);
                    });
                };

                // create timeout
                $scope.pingRequestTimeout = $timeout(callPingWebservice, timeInMillis);

                // cancel timeout on destroy
                $scope.$on('$destroy', function () {
                    $timeout.cancel($scope.pingRequestTimeout);
                });
            }

            /**
             * setting up item selection listener, this listener will use the source click location plus whether 2nd tree is available
             * to determine which tree will handle the selection event.  the idea here is if both tree are available, try to handle the
             * click event from one element to another element
             * @param $scope scope of the directive
             * @param directiveElement element of the directive
             */
            function setupItemSelectionListener($scope, directiveElement) {
                var turnOffListener = $rootScope.$on('selector.itemSelected', function (event, selectedItem) {
                    var lastSelectedItemSource = selectedItem._lastSource;
                    var destinationTree = $scope.treeNames.tree1;

                    if (lastSelectedItemSource == null) {
                        lastSelectedItemSource = $scope.treeNames.tree1;
                    }

                    if (lastSelectedItemSource == $scope.treeNames.tree1 && $scope.states.includeSecondPanel) {
                        // if selection comes from tree 1 and tree 2 is available, set destination to tree 2
                        destinationTree = $scope.treeNames.tree2;
                    }
                    var reBroadCastTopic = 'selector.itemSelected' + '.' + destinationTree;
                    $scope.$broadcast(reBroadCastTopic, selectedItem);
                });

                directiveElement.on('$destroy', function () {
                    turnOffListener();
                });
            }


            function hasSemanticVersion($scope) {
                // check if semantic version is provided or not
                if ($scope.form.formVersions[0].semanticVersion) {
                    return true;
                }
                return false;
            }


            /**
             * save object
             * @param $scope
             * @param shouldExit if we should exit after the save
             */
            function doSave($scope, shouldExit) {
                if (hasSemanticVersion($scope)) {
                    if ($scope.form.formVersions[0].editMode.isMoreThanOneStartNode() || $scope.form.formVersions[0].viewMode.isMoreThanOneStartNode()) {
                        AlertService.error("Form mode can not have more than one start node");
                    } else {
                        // change callback when user selected stay on the page
                        var callBack = shouldExit ? $scope.controls.onSaveFinishedAndExit : $scope.controls.onSaveFinishedAndStay;
                        FormUtilService.FormModelServiceForm.saveForm([], $scope.form, callBack);
                    }
                } else {
                    AlertService.warning('Semantic Version field is required');
                }

            }

            function resolveEntityIfNeeded($scope) {
                if ($scope.isNew) {
                    // For new forms, if program id is passed it is
                    if ($scope.category === FormConstants.formCategories.PROFILE_FORM) {
                        var programDataType = getProgramDataType($scope.programId, $scope);

                        programDataType.$promise.then(function (data) {
                            $scope.programDataType = data;

                            setFormEntity($scope, null);
                        });
                    } else {
                        setFormEntity($scope, null);
                    }
                } else {
                    resolveFormEntity($scope, $scope.form);
                }
            }

            function resolveFormEntity($scope, formPromise) {
                formPromise.$promise.then(function (data) {
                    if (data.category === FormConstants.formCategories.PROFILE_FORM && data.profileFormProgramDetails != null) {
                        var programDataType = getProgramDataType(data.profileFormProgramDetails.id, $scope);

                        programDataType.$promise.then(function (programDataType) {
                            $scope.programDataType = programDataType;
                            $scope.programId = data.profileFormProgramDetails.id;

                            setFormEntity($scope, data);
                        });
                    } else {
                        setFormEntity($scope, data);
                    }
                });
            }

            function getProgramDataType(programId, $scope) {
                var programDataTypeName = '___' + programId + '___';
                return $scope.dataTypeUniqueByName.get({
                    name: programDataTypeName
                });
            }

            function setFormEntity($scope, dto) {
                if (!$scope.states.savedAlready) {
                    $scope.form = new FormUtilService.FormModelServiceForm.FormModel($scope.category,
                        $scope.programDataType, $scope.programId, $scope.programCode);
                }

                if (dto != null) {
                    $scope.form.fromDto(dto);
                }

                var editMode = $scope.form.formVersions[0].editMode;
                editMode.initDataTypePropertyUsageList(false, function () {
                    $scope.states.entityReady = true;
                });

                var formVersion = $scope.form.formVersions[0];
                formVersion.validate();
            }

            /**
             * show import form window
             * @param $scope
             */
            function showImportFormWindow($scope) {
                $scope.states.entityReady = false;
                var newClassDialog = ngDialog.open({
                    template: `<form-import-confirmation category="${$scope.category}"></form-import-confirmation>`,
                    plain: true,
                    className: 'ngdialog-theme-plain custom-width-medium',
                    scope: $scope,
                    showClose: false,
                    closeByDocument: false,
                    closeByEscape: false,
                });

                newClassDialog.closePromise.then(function (formModel) {
                    if (formModel && formModel.value) {
                        $scope.form = formModel.value;
                        $scope.form.category = $scope.category;

                        if ($scope.category === FormConstants.formCategories.CONSENT_FORM) {
                            // matching values to default values in FormModel constructor
                            $scope.form.consentType = FormConstants.consentType.PII;
                        }
                    }
                    $scope.states.entityReady = true;
                });

            }
        });

})();
