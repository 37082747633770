angular.module('acadiamasterApp')

    /**
     * directive for displaying an expression condition's condition tree (if or assert)
     */
    .directive('treeNodeConditionTree',
        (ConditionTreeCreationUtil, $timeout, ngDialog) => ({
            link($scope) {
                $scope.allCreators = ConditionTreeCreationUtil.getAllCreators();
                $scope.canHideConditionButton = function(creator){
                    return ConditionTreeCreationUtil.canHideConditionButton(creator, $scope.condition.usageType)
                };

                $scope.states = {
                    isAddNewPopupOpen: false,
                };

                $scope.showAddConditionDialog = ($event) => {
                    $scope.states.isAddNewPopupOpen = true;
                    ngDialog.openConfirm({
                        className: 'ngdialog-theme-plain custom-width-small',
                        preCloseCallback: () => {
                            $timeout(() => {
                                $scope.states.isAddNewPopupOpen = false;
                            });
                        },
                        scope: $scope,
                        templateUrl: 'admin-templates/site/components/treeEditorDialogs/addCondition.template.html',
                    }).then((creator) => {
                        $scope.addNewCondition(creator);
                    });
                    $event.stopPropagation();
                };

                $scope.addNewCondition = (creator) => {
                    const newCondition = creator.createNew(null, $scope.selector);
                    $scope.condition.setConditionTree(newCondition);
                    $scope.selector.selectItem(newCondition, true);

                };

                $scope.removeCondition = () => {
                    const oldItem = $scope.condition.conditionTree;
                    $scope.condition.setConditionTree(null);
                    if ($scope.selector != null) {
                        $scope.selector.itemRemoved(oldItem);
                    }
                };

                $scope.setConditionTree = (conditionTree) => {
                    $scope.condition.setConditionTree(conditionTree);
                };

                $scope.getConditionTree = () => $scope.condition.conditionTree;

            },
            restrict: 'E',
            scope: {
                selector: '=',
                condition: '=',
                subTitle: '@',
                titleText: '@',
            },
            templateUrl: 'admin-templates/site/expressionCondition/configuration/treeNode/conditionTree.html',
        }));
