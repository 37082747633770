import ProgramContainerConstantsService from "../../const/programContainer.constants.service";

/**
 * Created by Jason Cao on 08/27/2019
 *
 * directive for configuring a container's display config state
 * possible states are stored in constant file
 * note: this directive that will redirect to other state specific directives
 */
angular.module('acadiamasterApp').directive('containerDisplayConfigState', () => ({
    restrict: 'E',
    scope: {
        state: '=',
        displayConfig: '=',
        container: '=',
    },
    templateUrl: 'admin-templates/site/programManagement/program/' +
        'containerManagement/config/containerDisplayConfig/' +
        'containerDisplayConfigState.html',
    link: function($scope) {
        $scope.dcs = ProgramContainerConstantsService.displayConfigStates;

        $scope.shouldRender = () => {
            return $scope.state !=null && $scope.displayConfig!=null;
        };

        function isItemHiddenAfterCompletion(displayConfig) {
            if (displayConfig==null || displayConfig.itemCompletionStateConfig==null) {
                return false;
            }

            return displayConfig.itemCompletionStateConfig.removeAfterComplete;
        }

        $scope.getWarningMessageForAllCompleted = () => {

            let itemWillHideAfterCompletion = isItemHiddenAfterCompletion($scope.displayConfig);

            if (!itemWillHideAfterCompletion) {
                return 'Current configuration does not hide container item after it is completed.  ' +
                    'Therefore, any configuration for all completed state will never be used because if you ' +
                    'have completed something, they will always be showing inside the container. ';
            }

            return null;
        };
    }
}));
