(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitCategoryDialogController',
            function($scope, $stateParams, $uibModalInstance, $q, entity, isNew, baseunits,  UnitCategory, Unit, AlertService, $translate, UnitCategoriesLookup) {

                $scope.unitCategory = entity;
                $scope.baseunits = baseunits;
                $scope.isNew = isNew

                if(!isNew){
                    $q.all([$scope.unitCategory.$promise, $scope.baseunits.$promise]).then(function() {
                        $scope.oldBaseUnitId = $scope.unitCategory.baseUnitId;
                        if (!$scope.unitCategory.baseUnitId) {
                            return $q.reject();
                        }
                        return Unit.get({id : $scope.unitCategory.baseUnitId}).$promise;
                    });
                }
                $scope.load = function(id) {
                    UnitCategory.get({id : id}, function(result) {
                        $scope.unitCategory = result;
                    });
                };

                var onSaveFinished = function (result) {
                    $scope.$emit('acadiamasterApp:unitCategoryUpdate', result);
                    $uibModalInstance.close(result);
                    UnitCategoriesLookup.updateUnitCategories();
                };


                $scope.save = function () {
                    if ($scope.unitCategory.id != null) {
                        UnitCategory.update($scope.unitCategory, onSaveFinished);
                    } else {
                        UnitCategory.save($scope.unitCategory, onSaveFinished);
                    }
                };

                $scope.clear = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                $scope.onBaseUnitChange = function (){
                    console.log("oldBaseUnitId",$scope.oldBaseUnitId);
                    if($scope.unitCategory.baseUnitId != $scope.oldBaseUnitId){
                        var unitNames = "";
                        for(var i=0;i<$scope.baseunits.length;i++){
                            var baseunit = $scope.baseunits[i];
                            unitNames += "  - "+baseunit.name+"\n";
                        }

                        var msg = $translate.instant("acadiamasterApp.unitCategory.baseUnitChanged", {param: unitNames})
                        AlertService.warning(msg);
                    }
                }
            });

})();