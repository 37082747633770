/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')



    /**
     * directive for form detail display of one of the form modes
     */
        .directive('formDetailFormMode', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/form/formDetailFormMode.html',
                scope: {
                    currentMode: '=',
                    title: '@'
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                }
            };
        });

})();

