/**
 * Created by arao on 6/22/2017.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueDatePickerReference', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/datePickerReference.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                    selector: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                }
            };

        });

})();

