'use strict';

angular.module('acadiamasterApp')
    .factory('DataTypeRootCategory', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/dataTypeRootCategorys/:id'), {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'lookup': {
                url: localWeb.createUrl('/api/dataTypeRootCategorys/lookup'),
                method: 'GET',
                isArray: true
            },
            'update': { method:'PUT' }
        });
    })
    .factory('DataTypeRootCategoryByName', function ($http,localWeb) {
        var url = localWeb.createUrl('api/dataTypeRootCategorys/nameAvailable/');
        return {
            'get' : function(dataTypeRootCategoryName, dataTypeRootCategoryId) {
                var finalUrl = url + dataTypeRootCategoryName;
                if (dataTypeRootCategoryId!=null) {
                    finalUrl += "?dataTypeRootCategoryId=" + dataTypeRootCategoryId;
                }
                return $http.get(finalUrl);
            }
        };
    });


