/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    angular.module('acadiamasterApp')

    /**
     * directive for common image text field config
     */
        .directive('formFieldConfigImageText', (ImagePresetConstants, FormConstants, vbrCommonUtil) => {
            return {
                link : function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.ImagePresetConstants = ImagePresetConstants;

                    $scope.hasChangeModel = $scope.fieldValueChange != null;
                    let units = [ 'dp', '%' ];
                    $scope.widthOptions = createValueWithUnitOptions(null, units);
                    $scope.heightOptions = createValueWithUnitOptions(null, units);

                    $scope.imagePresetChangedCallBack = function (newPreset) {
                        $scope.fieldValue.imagePresetChanged(newPreset);
                    };

                    $scope.getIncludeText = function () {
                        return getPropertyValue($scope, 'includeText');
                    };

                    $scope.getIncludeImage = function () {
                        return getPropertyValue($scope, 'includeImage');
                    };

                    $scope.getImagePosition = function () {
                        return getPropertyValue($scope, 'imagePosition');
                    };
                    // AngularJS upgrade to 1.8: This was being called in the template and since upgrade to AngularJS 1.8
                    // it was causing a infinite loop so adding a scope property here an referencing it in template.
                    $scope.getImagePositionArray = $scope.getImagePosition();

                    $scope.getTextPosition = function () {
                        return getPropertyValue($scope, 'textPosition');
                    };
                    // AngularJS upgrade to 1.8: This was being called in the template and since upgrade to AngularJS 1.8
                    // it was causing a infinite loop so adding a scope property here an referencing it in template.
                    $scope.getTextPositionArray = [ $scope.getTextPosition() ];

                    $scope.includeImageChanged = function (newIncludeImage) {
                        includeImageChanged($scope, newIncludeImage);
                    };

                    $scope.includeImageDisplayOnly = function () {
                        return !$scope.getIncludeText() && $scope.getIncludeImage();
                    };

                    $scope.includeTextDisplayOnly = function () {
                        return !$scope.getIncludeText() && !$scope.getIncludeImage();
                    };

                    $scope.includeTextChanged = function (newIncludeText) {
                        includeTextChanged($scope, newIncludeText);
                    };

                    $scope.setImagePosition = function (position) {
                        setImagePosition($scope, position);
                    };

                    $scope.setTextPosition = function (position) {
                        setTextPosition($scope, position);
                    };
                },
                restrict : 'E',
                scope    : {
                    fieldElement     : '=',
                    fieldValue       : '=',
                    fieldValueChange : '=',
                },
                templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/imageText.html',
            };

            /* *************************************************************
             * private functions
             ***************************************************************/
            function createValueWithUnitOptions (title, units) {
                return {
                    title : title,
                    units : units,
                };
            }

            function setImagePosition ($scope, newPosition) {
                if ($scope.hasChangeModel) {
                    $scope.fieldValueChange.imagePosition = newPosition;
                } else {
                    $scope.fieldValue.imagePosition = newPosition;
                }
            }

            function setTextPosition ($scope, position) {
                if ($scope.hasChangeModel) {
                    $scope.fieldValueChange.textPosition = position;
                } else {
                    $scope.fieldValue.textPosition = position;
                }
            }

            function includeImageChanged ($scope, newIncludeImage) {
                if (newIncludeImage) {
                    setImagePosition($scope, findFirstAvailablePosition(getTextPosition($scope)));
                } else {
                    setImagePosition($scope, null);
                }
            }

            function getTextPosition ($scope) {
                return getPropertyValue($scope, 'textPosition');
            }

            function getImagePosition ($scope) {
                return getPropertyValue($scope, 'imagePosition');
            }

            /*
             * generic function to get property value by key while using hasChangeModel property
             * @param $scope
             * @param key
             */
            function getPropertyValue ($scope, key) {
                let r = null;
                if ($scope.hasChangeModel) {
                    r = $scope.fieldValueChange[key];
                }

                if (r == null) {
                    r = $scope.fieldValue[key];
                }

                return r;
            }

            function includeTextChanged ($scope, newIncludeText) {
                if (newIncludeText) {
                    setTextPosition($scope, findFirstAvailablePosition(getImagePosition($scope)));
                } else {
                    setTextPosition($scope, null);
                }
            }

            function findFirstAvailablePosition (unavailablePosition) {
                for (let key in FormConstants.position) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (FormConstants.position.hasOwnProperty(key)) {
                        if (FormConstants.position[key].name !== unavailablePosition.name) {
                            return FormConstants.position[key];
                        }
                    }
                }
            }
        });
}());
