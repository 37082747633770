angular.module('acadiamasterApp')
.factory('DependencyLinkService', ($http, localWeb) => {
    function getAgreementDependencyLink(agreementId) {
        return $http.get(localWeb.createUrl(`/api/admin/program/agreements/dependencies/${agreementId}`));
    }

    function getFormDependencyLink(formId) {
        return $http.get(localWeb.createUrl(`/api/forms/dependencies/${formId}`));
    }

    /**
     * Consolidate dpLink by sourceType.
     * @param dpLinks [container: 123, container: abc, containerItem: 1323, container:bcdwe]
     * @returns {Map<any, any>} {('container':'123,abc'),('containeritem': '1323,'bcdwe')}
     */
    function consolidateDPBySourceType(dpLinks) {
        //consolidate dp link by sourceType
        // {('container':'123,abc'),('containeritem': '1323,'bcdwe')}
        const dpLinkMap = new Map();
        dpLinks.forEach(link => {
            let sourceType = link.sourceType;
            if (dpLinkMap.has(sourceType)) {
                let keyvalue = dpLinkMap.get(sourceType);
                dpLinkMap.set(sourceType, keyvalue + ',' + link.sourceId);
            } else {
                dpLinkMap.set(sourceType, link.sourceId);
            }
        });
        return dpLinkMap;
    }

    return {
        getFormDependencyLink,
        getAgreementDependencyLink,
        consolidateDPBySourceType,
    };
});
