'use strict';

angular.module('acadiamasterApp')
    .factory('BusinessRuleScheduledRuleAudit', function ($resource, localWeb) {
        var resource = $resource(localWeb.createUrl('api/schedulers/jobs'), {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
        });

        return resource;
    });