(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('StateRuleDeleteController', function($scope) {
            $scope.confirmDelete = function() {
                $scope.confirm();
            };
        });

})();

