'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('organization', {
                parent: 'entity',
                url: '/organizations',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.organization.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/organization/organizations.html',
                        controller: 'OrganizationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organization');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('organization.detail', {
                parent: 'organization',
                url: 'detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.organization.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/organization/organizationDetail.html',
                        controller: 'OrganizationDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('organization');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Organization', function($stateParams, Organization) {
                        return Organization.get({id : $stateParams.id});
                    }]
                }
            })
            .state('organization.new', {
                parent: 'organization',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/organization/organizationDialog.html',
                        controller: 'OrganizationDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {orgCode: null, orgName: null, orgDescription: null, contactPerson: null, phone: null, email: null, icon: null, id: null};
                            },
                            isNew : function() { return true;}
                        }
                    }).result.then(function() {
                        $state.go('organization', null, { reload: true });
                    }, function() {
                        $state.go('organization');
                    })
                }]
            })
            .state('organization.edit', {
                parent: 'organization',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/organization/organizationDialog.html',
                        controller: 'OrganizationDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['Organization', function(Organization) {
                                return Organization.get({id : $stateParams.id});
                            }],
                            isNew : function() { return false;}
                        }
                    }).result.then(function() {
                        $state.go('organization', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    });
