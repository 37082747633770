import template from '../maybeShared/jsonObject.compressed.display.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrDisplayJsonCompressed', function () {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    jsonObj: '=',
                    jsonTitle: '=',
                    accordionOpen: "=?",
                    treeDepth: "=?"
                },
                link: function ($scope) {
                    if ($scope.accordionOpen == null) {
                        $scope.accordionOpen = false;
                    }

                    if ($scope.treeDepth == null) {
                        $scope.treeDepth = 1;
                    }
                }
            };
        });
})();