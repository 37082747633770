'use strict';

angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('unitGroup', {
                parent: 'unitSystem',
                url: '/group',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.unitGroup.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/unitSystem/unitGroup/unitGroups.html',
                        controller: 'UnitGroupController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitGroup');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('unitGroup.detail', {
                parent: 'unitGroup',
                url: '/detail/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.unitGroup.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/unitSystem/unitGroup/unitGroupDetail.html',
                        controller: 'UnitGroupDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('unitGroup');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'UnitGroup', function ($stateParams, UnitGroup) {
                        return UnitGroup.get({ id: $stateParams.id });
                    }]
                }
            })
            .state('unitGroup.new', {
                parent: 'unitGroup',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/unitSystem/unitGroup/unitGroupDialog.html',
                        controller: 'UnitGroupDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return { name: null, id: null };
                            },
                            isNew: function () {
                                return true;
                            }
                        }
                    }).result.then(function () {
                        $state.go('unitGroup', null, { reload: true });
                    }, function () {
                        $state.go('unitGroup');
                    })
                }]
            })
            .state('unitGroup.edit', {
                parent: 'unitGroup',
                url: '/edit/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'admin-templates/site/unitSystem/unitGroup/unitGroupDialog.html',
                        controller: 'UnitGroupDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['UnitGroup', function (UnitGroup) {
                                return UnitGroup.get({ id: $stateParams.id });
                            }],
                            isNew: function () {
                                return false;
                            }
                        }
                    }).result.then(function () {
                        $state.go('unitGroup', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    });
