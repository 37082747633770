(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitGroupController', function ($scope, UnitGroup, ParseLinks, $state,ngDialog) {
            $scope.unitGroups = [];
            $scope.page = 0;
            $scope.loadAll = function() {
                UnitGroup.query({page: $scope.page, size: 20}, function(result, headers) {
                    $scope.links = ParseLinks.parse(headers('link'));
                    $scope.unitGroups = result;
                });
            };
            $scope.loadPage = function(page) {
                $scope.page = page;
                $scope.loadAll();
            };
            $scope.loadAll();

            $scope.delete = function(id) {
                UnitGroup.get({
                    id: id
                }, function(result) {
                    $scope.unitGroup = result;
                    ngDialog.openConfirm({
                        template: 'admin-templates/site/unitSystem/unitGroup/unitGroupDelete.html',
                        controller: 'UnitGroupDeleteController',
                        data: $scope.unitGroup
                    }).then(function(value) {
                        $scope.refresh();
                    });
                });
            };

            $scope.refresh = function () {
                $scope.loadAll();
                $scope.clear();
            };

            $scope.clear = function () {
                $scope.unitGroup = {name: null, id: null};
            };

        });

})();