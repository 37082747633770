
angular.module('acadiamasterApp')
    .factory('networkInterceptor', ($q, $injector, $rootScope) => {
        let totalRequests = 0; let
            currentRequests = 0;
        let timerId = 0;
        const timersById = {};
        const TIMER_MS = 15000; // 15 seconds
        let alertService;
        const ignoredUrls = [
            'api/programImportHistory',
            'api/keepSessionAlive',
            'api/file?',
        ];

        function handleRequest(showSpinnerValue) {
            totalRequests++;
            currentRequests++;
            if (showSpinnerValue) {
                showSpinner();
            }
        }

        function handleResponse() {
            if (currentRequests > 0) {
                currentRequests--;
            }
            hideSpinner();
        }

        function startTimer(config) {
            timersById[timerId] = setTimeout(() => {
                if (timersById[config.netInterceptId]) {
                    // load alert service the first time it's needed only
                    if (!alertService) {
                        alertService = $injector.get('AlertService');
                    }
                    alertService.warning(`A network request has taken longer than ${TIMER_MS / 1000} seconds. You may need to refresh your page.`);
                }
            }, TIMER_MS);
            timerId++;
        }

        function stopTimer(response) {
            if (response.config) {
                const timer = timersById[response.config.netInterceptId];
                if (timer) {
                    clearTimeout(timer);
                    delete timersById[response.config.netInterceptId];
                }
            }
        }

        function showSpinner() {
            if (currentRequests === 1) {
                // only for admin portal
                $rootScope.$broadcast('$networkInterceptor:showSpinner', {});
            }
        }

        function hideSpinner() {
            if (currentRequests === 0) {
                $rootScope.$broadcast('$networkInterceptor:hideSpinner', {});
            }
        }

        /**
         * check if request is api or not
         * @param response - response object
         * @return {boolean}
         */
        function isNonOptionApiRequest(response) {
            if (response == null || response.config == null || response.config.url == null || response.method === 'OPTIONS') {
                return false;
            }

            let responseUrl = response.config.url;
            if (responseUrl.startsWith('/')) {
                responseUrl = responseUrl.substring(1);
            }

            const apiUrl = 'api/';

            return responseUrl.startsWith(apiUrl);
        }

        function shouldIgnoreUrlStart(url) {
            const foundMatch = _.find(ignoredUrls, u => url.indexOf(u) != -1);

            return foundMatch != null;
        }

        /**
         * check if we should ignore the request based on the config
         * @param config - http config object
         */
        function shouldIgnoreConfig(config) {
            if (config == null) {
                return true;
            }

            const { url } = config;

            if (shouldIgnoreUrlStart(url)) {
                return true;
            }
            // todo: add more checking here
            return false;
        }

        return {
            request(config) {
                let showSpinnerValue = config.showSpinner != undefined ? config.showSpinner : true;
                // sometimes config is undefined so check to see if it exists
                if (!shouldIgnoreConfig(config)) {
                    config.netInterceptId = timerId;
                    startTimer(config);
                    if (config.checkURL) {
                        for (let i = 0; i < config.checkURL.length; i++) {
                            if (document.URL.includes(config.checkURL[i])) {
                                showSpinnerValue = true;
                            }
                        }
                    }
                    handleRequest(showSpinnerValue);
                }

                return config;
            },

            response(response) {
                if (!shouldIgnoreConfig(response.config)) {
                    stopTimer(response);
                    handleResponse();
                    if (isNonOptionApiRequest(response)) {
                        $rootScope.$broadcast('networkRequest.success', response);
                    }
                }

                return response;
            },

            responseError(response) {
                if (!shouldIgnoreConfig(response.config)) {
                    stopTimer(response);
                    handleResponse();

                    if (response.status === 401) {
                        $rootScope.$broadcast('networkRequest.error.401', response);
                    } else if (response.status === 403) {
                        console.error('403 error detected');
                        $rootScope.$broadcast('networkRequest.error.403');
                    } else {
                        $rootScope.$emit('sharedUtilities.httpError', response);
                    }
                }

                return $q.reject(response);
            },
        };
    });
