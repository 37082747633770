'use strict';

angular.module('acadiamasterApp').controller('communicationCatController',
    function ($scope, $stateParams, $state, isNew, entity, CommunicationCategoryService, CONFIG, LocaleConstantsService) {

        $scope.CONFIG = CONFIG;
        $scope.lcs = LocaleConstantsService;
        $scope.communicationCategory = entity;
        $scope.isReady = true;
        let onSaveFinished = function () {
            backRoute();
        };

        $scope.save = function () {
            if ($scope.communicationCategory.id != null) {
                CommunicationCategoryService.updateCommunicationCategory($scope.communicationCategory.id, $scope.communicationCategory, onSaveFinished)
            } else {
                CommunicationCategoryService.createCommunicationCategory($scope.communicationCategory, onSaveFinished)
            }

        };
        $scope.clear = function () {
            backRoute();
        };

        function backRoute(){
            $state.go('toolCommunicationCategories');
        }
    });
