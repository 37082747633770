(function () {
    'use strict';

    /**
     * loading constants
     */
    angular.module('acadiamasterApp').constant('LoadingStatus',
        {
            WAITING: 'WAITING',
            LOADING: 'LOADING',
            LOAD_COMPLETE: 'LOAD_COMPLETE',
            LOAD_FAILED: 'LOAD_FAILED'
        });

})();
