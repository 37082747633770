/**
 * Created by Ryan Berry on 06/23/22
 *
 * Model to represent a page (aka tabBarItem)
 */

angular.module('acadiamasterApp')
    .factory('PageModel', (ModelServiceBase) => {
        /**
         * @constructor
         */
        function PageModel() {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false);
            this.createdOn = null;
            this.description = {};
            this.displayName = {};
            this.dropDownDisplay = null;
            this.icons = [];
            this.id = null;
            this.isDeleted = null;
            this.lockForCati = null;
            this.name = null;
            this.pageType = null;
            this.programId = null;
            this.sequenceNo = null;
            this.updatedOn = null;
            this.useAdvancedConfig = null;
        }

        /**
         * Convert the current UI model to DTO format
         */
        PageModel.prototype.toDto = function toDto() {
            const dto = {};
            dto.createdOn = this.createdOn;
            dto.description = this.description;
            dto.displayName = this.displayName;
            dto.icons = this.icons;
            dto.id = this.id;
            dto.isDeleted = this.isDeleted;
            dto.lockForCati = this.lockForCati;
            dto.name = this.name;
            dto.pageType = this.pageType;
            dto.programId = this.programId;
            dto.sequenceNo = this.sequenceNo;
            dto.updatedOn = this.updatedOn;
            dto.useAdvancedConfig = this.useAdvancedConfig;
            return dto;
        };

        /**
         * Convert the DTO object into current object.
         * This function will wipe out any information you have on the current object
         * @param dto
         */
        PageModel.prototype.fromDto = function fromDto(dto) {
            this.createdOn = dto.createdOn;
            this.description = dto.description;
            this.displayName = dto.displayName;
            pageTypeDropDisplay = dto.pageType ? ` | ${dto.pageType}` : '';
            displayNameDropDisplay = dto.displayName?.en ? ` | ${dto.displayName.en}` : '';
            this.dropDownDisplay =  `${dto.id} | ${dto.name}${pageTypeDropDisplay}${displayNameDropDisplay}`;
            this.icons = dto.icons;
            this.id = dto.id;
            this.isDeleted = dto.isDeleted;
            this.lockForCati = dto.lockForCati;
            this.name = dto.name;
            this.pageType = dto.pageType;
            this.programId = dto.programId;
            this.sequenceNo = dto.sequenceNo;
            this.updatedOn = dto.updatedOn;
            this.useAdvancedConfig = dto.useAdvancedConfig;
            return this;
        };

        /****************************************
         * service return call
         ****************************************/
        return PageModel;
    });
