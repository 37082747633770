/* eslint-disable */
angular.module('acadiamasterApp')
    .controller('configMultiSelectController', function ($scope) {
        const vm = this;

        vm.data = {
            options            : [],
            possibleSelections : [],
        };

        /**
         * Updates the selected status of any option, and if needed, toggles the default
         * @param {array} options
         * @param {boolean} reset
         * @returns {array} updated options array
         */
        function filterOptions (options, reset) {
            // if we need to reset the selected options, set the default to true
            // and all others to false
            if (reset) {
                return options.map(option => {
                    option.selected = option.isDefault;
                    return option;
                });
            }

            // count selected, non-default options
            const selectionCount = options.reduce((count, option) => count + (!option.isDefault && option.selected ? 1 : 0), 0);

            // If there are none, select the default option
            if (vm.defaultOption) {
                options[0].selected = selectionCount === 0;
            }

            return options;
        }

        /**
         * Toggles the selected state of a given option
         * @param {object} option
         */
        vm.toggleSelection = function (option) {
            // escape if option is disabled or is both default and selected
            if (option.disabled || option.isDefault && option.selected) {
                return;
            }
            option.selected = !option.selected;
            if (vm.defaultOption) {
                vm.data.options = filterOptions(vm.data.options, option.isDefault);
            }
            exportSelections();
        };

        /**
         * Creates the internal model used by this directive for a single option
         * @param {object/string} data
         * @param {object} selectedValues
         * @param {array} avoidList
         * @param {boolean} isDefault
         * @returns {object} the newly-created option model
         */
        function createOption (data, selectedValues, avoidList, isDefault) {
            let option = {};

            // some are objects and some are strings. This maps them to the same structure
            if (typeof data === 'object') {
                option = {
                    icon  : data.icon,
                    name  : data.text,
                    value : data.name,
                };
            } else if (typeof data === 'string' || typeof data === 'number') {
                option = {
                    name  : data,
                    value : data,
                };
            }

            // these values are the same for objects or strings.
            option.disabled = avoidList ? avoidList.indexOf(option.value) >= 0 : false;
            option.isDefault = isDefault || false;
            option.selected = selectedValues.indexOf(option.value) >= 0 && !option.disabled;

            return option;
        }

        /**
         * Sets values back to vm.currentSelections and optionally to a callback function
         */
        function exportSelections () {
            // iterate through options, filter only the selected, non-default ones,
            // and create an array of their values
            const selectedValues = vm.data.options.filter(option => option.selected && !option.isDefault).map(option => option.value);

            // get them back into the original format and order
            const options = vm.data.possibleSelections.filter(option => {
                const value = typeof option === 'object'
                    ? option.name
                    : option;
                return selectedValues.indexOf(value) >= 0;
            }).map(option => (!vm.exportObject && typeof option === 'object'
                ? option.name
                : option));

            vm.currentSelections = options;
            if (vm.onChange) {
                vm.onChange(options);
            }
        }

        /**
         * Parses the possible selections and creates option models to match the values
         * @param {array} possibleSelections
         */
        function setupOptions (possibleSelections) {
            // create a map of the current selections
            const selectedValues = vm.currentSelections.map(option => (vm.exportObject
                ? option.name
                : option)
            );

            if (angular.isArray(possibleSelections)) {
                vm.data.possibleSelections = possibleSelections;
            } else {
                // Sometimes possibleSelections is an object instead of an array,
                // so we need to convert it to an array.
                vm.data.possibleSelections = Object.keys(possibleSelections).map(key => possibleSelections[key]);
            }

            // iterate through the possible selections and create an internal model for each
            let options = vm.data.possibleSelections.map(option => createOption(option, selectedValues, vm.selectionListToAvoid));

            // if there's a default option, insert it into the available options
            if (vm.defaultOption) {
                options.unshift(createOption({
                    name : '__default_value__',
                    text : vm.defaultOption,
                }, selectedValues, null, true));

                options = filterOptions(options);
            }
            vm.data.options = options;

            // send updated values to the directive's parent
            exportSelections();
        }

        /**
         * Updates options based on the newly updated avoid list
         * @param {array} avoidList
         */
        function updateAvoidList (avoidList) {
            let needsExport = false;
            vm.data.options.forEach(option => {
                option.disabled = avoidList && avoidList.indexOf(option.value) >= 0 || false;
                if (option.disabled && option.selected) {
                    option.selected = false;
                    needsExport = true;
                }
            });

            if (needsExport) {
                exportSelections();
            }
        }

        vm.$onInit = () => {
            // every time the list of possible selections changes, rebuild the options
            $scope.$watchCollection(() => vm.possibleSelections, setupOptions);
            // If the avoid list updates, update the options
            $scope.$watchCollection(() => vm.selectionListToAvoid, updateAvoidList);
        };
    });
