/**
 * Created by Jamie Nola on 06/26/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('ImportWizardService', function ($http, localWeb, Upload) {
            return {
                getImportLogList: function (searchData) {
                    return $http.get(localWeb.createUrl('/api/programImportHistory'), { params: searchData });
                },
                getSessionDetail: function (sessionId) {
                    return $http.get(localWeb.createUrl('/api/programImportHistory/' + sessionId));
                },
                checkForInProgressImport: function (programId) {
                    return $http.get(
                        localWeb.createUrl('/api/programImportHistory/checkForInProgressImport'),
                        { params: { programId: programId } }
                    );
                },
                downloadZipFile: function (path) {
                    return $http.get(localWeb.createUrl('/api/file'), { params: { fileName: path }, responseType: 'arraybuffer' } );
                },
                cancelImport: function (id) {
                    return $http.get(localWeb.createUrl('/api/programImportHistory/' + id + '/cancel'));
                },
                importEntities: function(id) {
                    return $http.post(localWeb.createUrl('/api/configuration/performProgramImport/' + id ), "");
                },
                importZipFile: function (programId, zipFile) {
                    return Upload.upload({
                        url: localWeb.createUrl("/api/configuration/programImportAsZip"),
                        data: {
                            file: zipFile,
                            data: JSON.stringify({ programId: programId })
                        }
                    });
                }
            };
        });
})();
