/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/**
 * program configuration for tab bar items
 */

(function () {
    // eslint-disable-next-line angular/function-type
    angular.module('acadiamasterApp').directive('programTabsConfig', (ProgramService, $state,
        CONFIG, ProgramUtilConstantsService, AlertService, $stateParams) => {
        return {
            controller : controller,
            restrict   : 'E',
            scope      : {
                programId    : '@',
                showReadOnly : '=',
            },
            templateUrl : 'admin-templates/site/programManagement/program/tabBar/programTabsConfig.html',
        };

        /*
         * main controller function
         */
        function controller ($scope) {
            $scope.CONFIG = CONFIG;
            $scope.readyStates = false;
            init($scope);
            $scope.saveProgramTabs = saveProgramTabs;
            $scope.tabBarItemMap = ProgramUtilConstantsService.tabBarItemMap;

            function init ($scope) {
                $scope.program = ProgramService.get({ id : $scope.programId });
                $scope.program.$promise.then(() => {
                    $scope.readyStates = true;
                });
            }

            function onSaveFinished () {
                $state.go('program.manage', { id : $scope.program.id });
            }
            function onErrorCallBack (error) {
                if (error.data.errorCode !== 'error.program.duplicate.page.name') {
                    AlertService.error('Page can not be deleted, please check for configured dependencies');
                }
            }

            const getIsVisibleExpressTree = tabBarItem => {
                if (tabBarItem.menuNavigationEnabled) {
                    if (tabBarItem.isVisibleExpression && tabBarItem.isVisibleExpressionTree) {
                        if (tabBarItem.isVisibleExpressionTree.toDto) {
                            return tabBarItem.isVisibleExpressionTree.toDto();
                        }
                        return tabBarItem.isVisibleExpressionTree;
                    }
                }
                return null;
            };

            const getIsUnlockedExpressionTree = tabBarItem => {
                if (tabBarItem.isUnlockedExpression && tabBarItem.isUnlockedExpressionTree) {
                    if (tabBarItem.isUnlockedExpressionTree.toDto) {
                        return tabBarItem.isUnlockedExpressionTree.toDto();
                    }
                    return tabBarItem.isUnlockedExpressionTree;
                }
                return null;
            };

            /**
             * save program
             */
            function saveProgramTabs () {
                function fixTabOrdering ($scope, tabBarItems) {
                    for (let i = 0; i < tabBarItems.length; i++) {
                        tabBarItems[i].sequenceNo = tabBarItems[i].menuNavigationEnabled === true ? i : -1;
                        setIcons($scope, tabBarItems[i]);
                        // reset visible if orphan page
                        tabBarItems[i].isVisibleExpression = tabBarItems[i].menuNavigationEnabled === true
                            ? tabBarItems[i].isVisibleExpression
                            : null;
                        tabBarItems[i].isVisibleExpressionTree = getIsVisibleExpressTree(tabBarItems[i]);
                        tabBarItems[i].isUnlockedExpressionTree = getIsUnlockedExpressionTree(tabBarItems[i]);
                    }
                }
                $scope.files = [];
                fixTabOrdering($scope, $scope.program.tabBarItems);
                ProgramService.createUpdate($scope.files, $scope.program, onSaveFinished, onErrorCallBack);
            }

            function setIcons ($scope, tabBar) {
                if (tabBar.menuNavigationEnabled) {
                    setImage($scope, tabBar.icons.mobileEnableIcon);
                    setImage($scope, tabBar.icons.mobileDisableIcon);
                    setImage($scope, tabBar.icons.webEnableIcon);
                    setImage($scope, tabBar.icons.webDisableIcon);
                } else {
                    // reset icon
                    tabBar.icons = {};
                }
            }

            function setImage ($scope, icon) {
                if (icon != null) {
                    const file = icon.image;
                    const url = icon.imageUrl;
                    if (file != null) {
                        icon.imageName = file.name;
                        icon.imageSize = file.size;
                        $scope.files.push(file);
                    } else if (file == null && url == null) {
                        icon.imageName = null;
                        icon.imageSize = null;
                        icon.aspectRatio = null;
                    }
                }
            }
        }
    });
}());
