/**
 * Created by Jamie Nola on 06/01/2020
 *
 * controller for viewing container item templates
 */

angular.module('acadiamasterApp')
    .controller('ToolsContainerItemTemplates', ($timeout, ngDialog, vbrCommonUtil,
        ContainerItemTemplateConstants, ContainerItemTemplateConfigs, ContainerItemTemplateContent,
        ContainerItemTemplateService, ContainerMgmtService, AlertService, CONFIG) => {
        const vm = this;
        vm.cs = ContainerItemTemplateConstants;
        // save the original values so we can revert to them
        vm.originalConfigData = {};
        vm.originalContentData = {};
        // store current values for the UI to use
        vm.configData = {};
        vm.contentData = {};

        // store keys for all config and content data
        vm.configKeys = [];
        vm.contentKeys = [];

        vm.data = {
            configKey: '',
            contentKey: '',
            isReady: false,
            lastClick: {},
            showLastClick: false,
            templateViewMode: false,
            containerTemplateEditEnabled : CONFIG.containerTemplateEditEnabled,
            maxMobileWidth : 'none',
        };
        vm.sizes = Object.keys(vm.cs.size).map(key => vm.cs.size[key]);
        vm.states = Object.keys(vm.cs.state).map(key => vm.cs.state[key]);

        let clickTimer;
        vm.buttonClickCallback = (response) => {
            if (clickTimer) {
                $timeout.cancel(clickTimer);
            }

            vm.data.lastClick = response;
            vm.data.showLastClick = true;

            clickTimer = $timeout(() => {
                vm.data.showLastClick = false;
            }, 5000);
        };

        vm.downloadConfig = () => {
            const { config } = vm.configData[vm.data.configKey];
            vbrCommonUtil.downloadAsFile(
                `${config.key}.json`,
                config,
                null,
                4,
            );
        };

        vm.editConfig = () => {
            const config = JSON.stringify(vm.configData[vm.data.configKey].config, null, '\t');
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-large template-config-edit-dialog',
                height: '90%',
                template: 'admin-templates/site/tools/containerItemTemplates/editDialog/editDialog.template.html',
                controller: ['$scope', function ($scope) {
                    $scope.config = config;
                    $scope.title = `Edit Template Configuration JSON for ${vm.data.configKey}`;
                    $scope.revert = () => {
                        const original = ContainerItemTemplateConfigs.find(entry => entry.key === vm.data.configKey);
                        if (original) {
                            vm.configData[vm.data.configKey] = original;
                        }
                        $scope.closeThisDialog();
                    };
                }],
            }).then(function (config) {
                vm.configData[vm.data.configKey] = {
                    ...config,
                    config: JSON.parse(config),
                };
            });
        };

        vm.editContent = () => {
            const config = JSON.stringify(vm.contentData[vm.data.contentKey].content, null, '\t');
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-large template-config-edit-dialog',
                height: '90%',
                template: 'admin-templates/site/tools/containerItemTemplates/editDialog/editDialog.template.html',
                controller: ['$scope', function ($scope) {
                    $scope.config = config;
                    $scope.title = `Edit Content JSON for ${vm.data.configKey}`;
                    $scope.revert = () => {
                        const original = ContainerItemTemplateContent.find(entry => entry.key === vm.data.contentKey);
                        if (original) {
                            vm.contentData[vm.data.contentKey] = original;
                        }
                        $scope.closeThisDialog();
                    };
                }],
            }).then(function (config) {
                vm.contentData[vm.data.contentKey] = {
                    ...config,
                    content: JSON.parse(config),
                };
            });
        };

        /**
         * Save the template config with key `vm.data.configKey`
         *
         * Note, there is a minimum validation here since it is used as a playground in lower environment
         * as a develop tool only.
         * In some cases, there would be error thrown from ui code due to null pointer
         * or Bad Request or Unprocessable Entity from api response due to invalid payload etc.
         */
        vm.saveConfig = () => {
            const { config } = vm.configData[vm.data.configKey];
            ContainerMgmtService.saveContainerTemplate(config)
                .then(() => {
                    AlertService.success('Container template saved successfully');
                }, error => {
                    console.error('Error saving container template', error);
                });
        };

        function init() {
            // load config data
            ContainerItemTemplateConfigs.forEach((entry) => {
                const data = {
                    ...entry,
                    group: 'Local',
                }
                vm.originalConfigData[entry.key] = data;
                vm.configData[entry.key] = data;
                vm.configKeys.push(entry.key);
            });
            vm.data.configKey = vm.configKeys[0];
            // load content data
            ContainerItemTemplateContent.forEach((entry) => {
                vm.originalContentData[entry.key] = entry;
                vm.contentData[entry.key] = entry;
                vm.contentKeys.push(entry.key);
            });
            vm.data.contentKey = vm.contentKeys[0];
            vm.data.isReady = true;
        }

        ContainerItemTemplateService.getTemplateKeys()
            .then((response) => {
                let count = response.data.length;
                const configData = {};
                response.data.forEach(entry => {
                    ContainerItemTemplateService.getTemplate(entry.key)
                        .then(resp => {
                            const data = {
                                key: entry.key,
                                group: 'From API',
                                config: resp.data,
                            };
                            vm.originalConfigData[entry.key] = data;
                            configData[entry.key] = data;
                            vm.configKeys.push(entry.key);
                        })
                        .finally(() => {
                            count = count - 1;
                            if (count === 0) {
                                const sortedConfigData = {};
                                vm.configKeys.sort().forEach(key => {
                                    sortedConfigData[key] = configData[key];
                                });
                                vm.configData = sortedConfigData;
                                init();
                            }
                        });
                });
            }, init);

        return vm;
    });
