
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTriggerUserProfileValueChange', function (PropertyCacheService, AlertService) {
            return {
                restrict: 'E',
                template: '<property-selection ng-if="isReady"\n' +
                ' is-required="true" property-options="propertyOptions" title="Select Property"></property-selection>',
                scope: {
                    element: '='
                },
                link: function ($scope) {
                    // load property if needed
                    $scope.isReady = false;

                    $scope.propertyOptions = {
                        getProperty : function() {
                            return $scope.element.property;
                        },
                        setProperty : function(newValue) {
                            $scope.element.property = newValue;
                            $scope.element.propertyId = newValue==null ? null : newValue.id;
                        }
                    };

                    loadPropertyIfNeeded($scope);
                }
            };

            function loadPropertyIfNeeded($scope) {
                var property = $scope.element.property;
                var propertyId = $scope.element.propertyId;

                if (propertyId!=null && property == null) {
                    PropertyCacheService.loadPropertyById(propertyId)
                        .then(function(property) {
                            $scope.element.property = property;
                            $scope.isReady = true;
                        }, function(error) {
                            console.error('unable to load property by id : ' + propertyId, error);
                            AlertService.error('unable to load property by id: ' + propertyId);
                            $scope.isReady = true;
                        });
                }
                else {
                    $scope.isReady = true;
                }
            }
        });
})();


