angular.module('acadiamasterApp')
    .controller('LoginController', ($rootScope, $scope, $state, $timeout, Auth, Principal, AppConfigService, SessionStorageService) => {
        $scope.data = {
            username          : '',
            password          : '',
            twoFactorAuthCode : '',
            rememberMe        : true,
        };

        $scope.config = {
            twoFactorAuthenticationEnabled : null,
        };

        $scope.user = {};
        $scope.errors = {};

        init($scope);

        $scope.isReady = function () {
            return $scope.config.twoFactorAuthenticationEnabled != null;
        };

        $scope.requireMFA = function () {
            return $scope.config.twoFactorAuthenticationEnabled;
        };

        $scope.login = function (event) {
            event.preventDefault();

            Auth.login({
                username          : $scope.data.username,
                password          : $scope.data.password,
                twoFactorAuthCode : getAuthCode($scope.data.twoFactorAuthCode),
                rememberMe        : $scope.data.rememberMe,
            }).then(response => {
                $scope.authenticationError = false;
                Principal.authenticate(response.baseUserDTO);
                SessionStorageService.clear();

                if (shouldGoBackToPreviousState()) {
                    $rootScope.back();
                } else {
                    $state.go('home');
                }
            }).catch(() => {
                $scope.authenticationError = true;
            });
        };

        function shouldGoBackToPreviousState() {
            const pn = $rootScope.previousStateName;
            return pn != null && pn !== '' && pn !== 'register' && pn !== 'login';
        }

        function getAuthCode(authCode) {
            if (authCode == null || authCode.trim().length === 0) {
                return null;
            }

            return parseInt(authCode, 10);
        }

        /**
         * initialization function for login, check if MFA is required
         */
        function init($scope) {
            AppConfigService.getAppConfig().then(response => {
                $scope.config.twoFactorAuthenticationEnabled = response.data.twoFactorAuthenticationEnabled;

                $timeout(() => {
                    const loginElement = angular.element('[ng-model="data.username"]');
                    loginElement.focus();
                });
            });
        }
    });
