(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for form text label config
     */
        .directive('formFieldConfigFieldValueCalculatedScore', function (FormConstants, $document, ExpressionConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/calculatedScore.html',
                scope: {
                    fieldValue: '=',
                    fieldElement: '='
                },
                link: function ($scope) {
                    $scope.cs = FormConstants;
                    $scope.expConstants = ExpressionConstantsService;
                }
            };
        });
})();

