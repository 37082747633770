/**
 * various function for form version api
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');
    app.factory('FormEditImportService', function (FormModelServiceForm, FormVersion,
                                                   FormStringUtilService, AlertService, ExpressionConstantsService, FormConstants,
                                                   vbrCommonUtil,
                                                   ) {

        var constants = FormConstants;
        var epcs = ExpressionConstantsService;

        /**
         * perform the first save on the form dto
         * @param formModel - form model with no server id
         * @param localIdToImportIdMap - local id to original server id map
         *                              (those original server id are no longer in the form dto at this time)
         * @param oldFormId - old form id
         * @param successCallBack - success call back on the 2nd save
         * @param showAlert - indicate whether alerts should be shown while we are performing this task
         */
        function doFirstSave(formModel, localIdToImportIdMap, oldFormId, showAlert, successCallBack) {
            FormModelServiceForm.saveForm([], formModel, function (resp) {
                if (showAlert) {
                    AlertService.info('first save is successful, now working on additional processing');
                }
                // adding comment for first version
                var formDTO = resp.data;
                var versionId = formDTO.formVersions[0].id;

                FormVersion.updateVersionComment({
                    id : versionId,
                    isActive: formDTO.formVersions[0].isActive,
                    versionComment : "Initial version with wrong server id referenced"
                });

                doSecondSave(resp.data, localIdToImportIdMap, oldFormId, showAlert, successCallBack);
            });
        }

        /**
         * finding all the fields in a form mode
         * @param formMode - form mode model
         * @return [] - list of fields in the form mode
         */
        function findAllFields(formMode) {
            var fields = [];
            _.forEach(formMode.pages, function (page) {
                _.forEach(page.sections, function (section) {
                    _.forEach(section.formComponents, function (component) {
                        _.forEach(component.formComponentFields, function (field) {
                            fields.push(field);
                        });
                    });
                });
            });

            return fields;
        }

        /**
         * finding all the advanced visibility in the form mode
         * @param formMode - form mode
         * @return [] - list of advanced visibilities in the form mode
         */
        function findAllAdvancedVisibilities(formMode) {

            function addAdvancedVisibilityIfNeeded(displayConfig, outputs) {
                if (displayConfig!=null && displayConfig.advancedVisibilityConfig!=null && displayConfig.advancedVisibilityConfig.conditionTree != null) {
                    outputs.push(displayConfig.advancedVisibilityConfig);
                }
            }

            var outputs = [];
            _.forEach(formMode.pages, function (page) {
                addAdvancedVisibilityIfNeeded(page.displayConfig, outputs);
                _.forEach(page.sections, function (section) {
                    addAdvancedVisibilityIfNeeded(section.displayConfig, outputs);
                    _.forEach(section.formComponents, function (component) {
                        addAdvancedVisibilityIfNeeded(component.displayConfig, outputs);
                        _.forEach(component.formComponentFields, function (field) {
                            addAdvancedVisibilityIfNeeded(field.displayConfig, outputs);

                            _.forEach(field.subFields, function (subField) {
                                addAdvancedVisibilityIfNeeded(subField.displayConfig, outputs);
                            });
                        });
                    });
                });
            });

            return outputs;
        }

        /**
         * finding all the label field in the
         * @param formMode
         */
        function findAllLabelFields(formMode) {
            var fields = findAllFields(formMode);

            return _.filter(fields, function (field) {
                return field.type == constants.fieldsType.TEXT_LABEL;
            });
        }

        /**
         * finding all the title field in the
         * @param formMode
         */
        function findAllTitleSubFields(formMode) {
            var fields = findAllFields(formMode);

            var titleSubFields = [];

            _.forEach(fields, function(field) {
                if (vbrCommonUtil.isNullOrUnavailable(field.subFields) || field.subFields.length == 0) {
                    return;
                }

                // has at least one subfield, check if contains any title field
                _.forEach(field.subFields, function(subField) {
                    if (subField.type == constants.subFieldsType.TITLE) {
                        titleSubFields.push(subField);
                    }
                });
            });

            return titleSubFields;
        }

        /**
         * replace id in string field value that can include special embedded information
         * @param fv - field value of a label field or title subfield
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdInStringFieldValue(fv, oldIdToNewIdMap) {
            if (fv.value) {
                fv.value = FormStringUtilService.replaceIdInLabelValue(fv.value, oldIdToNewIdMap);
            }

            if (fv.localizationMap && fv.localizationMap.es && fv.localizationMap.es.value) {
                fv.localizationMap.es.value = FormStringUtilService.replaceIdInLabelValue(
                    fv.localizationMap.es.value, oldIdToNewIdMap);
            }
        }

        /**
         * replace id for any text label field (including translation)
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForTextLabelField(formMode, oldIdToNewIdMap) {
            // replacing id for label field
            var labelFields = findAllLabelFields(formMode);

            _.forEach(labelFields, function (labelField) {
                replaceIdInStringFieldValue(labelField.fieldValue, oldIdToNewIdMap);
            });
        }

        /**
         * replace id for any title sub field (including translation)
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForTitleSubField(formMode, oldIdToNewIdMap) {
            // replacing id for label field
            var titleSubFields = findAllTitleSubFields(formMode);

            _.forEach(titleSubFields, function (sf) {
                replaceIdInStringFieldValue(sf.fieldValue, oldIdToNewIdMap);
            });
        }

        /**
         * replace id in a double node
         * @param doubleNode - condition node for a double value, if the node type is not double,
         *                    then this is ignored and will just return false
         * @param oldIdToNewIdMap - old id to new id map
         * @return {boolean} - true if the id were replaced, false otherwise
         */
        function replaceIdForDoubleNode(doubleNode, oldIdToNewIdMap) {
            if (doubleNode == null || doubleNode.nodeType != epcs.nodeTypes.DOUBLE || doubleNode.valueDouble == null) {
                return false;
            }

            if (oldIdToNewIdMap[doubleNode.valueDouble] != null) {
                // found an id to be replaced
                doubleNode.valueDouble = oldIdToNewIdMap[doubleNode.valueDouble];
                return true;
            }

            return false;
        }

        /**
         * replace id in a function condition tree
         * @param condition - condition node
         * @param oldIdToNewIdMap - old id to new id map
         * @param oldFormId - old form id
         * @param newFormId - new form id
         * @return {boolean} - true if at least one id were replaced, false otherwise
         */
        function replaceIdForFunctionNode(condition, oldIdToNewIdMap, oldFormId, newFormId) {
            let valueNode = null;
            let idMap = null;

            if (condition.functionName == epcs.functions.FIELD_ENTRY_VALUE_IN_CURRENT_VIEW ||
                condition.functionName == epcs.functions.HAS_FORM_FIELD_VALUE_IN_HISTORY ||
                condition.functionName == epcs.functions.GET_SCORE ||
                condition.functionName == epcs.functions.GET_ALL_SCORES ||
                condition.functionName == epcs.functions.GET_MIN_SCORE ||
                condition.functionName == epcs.functions.GET_MAX_SCORE ||
                condition.functionName == epcs.functions.GET_AVERAGE_SCORE) {
                // found a function that we are interested in, let's see if the id needs to be replaced
                valueNode = condition.childNodes[0];
                idMap = oldIdToNewIdMap;
            }
            else if (condition.functionName == epcs.functions.FIELD_VALUE_LATEST ||
                condition.functionName == epcs.functions.FIELD_VALUE_TIME_PERIOD) {
                valueNode = condition.childNodes[4];
                idMap = oldIdToNewIdMap;
            }
            else if (condition.functionName == epcs.functions.HAS_FORM_ENTRY ||
                condition.functionName == epcs.functions.IS_FORM_PAUSED ||
                condition.functionName == epcs.functions.IS_AGREEMENT_COMPLETE_BY_FORM_ID ||
                condition.functionName == epcs.functions.IS_AGREEMENT_CONSENTED_BY_FORM_ID ||
                condition.functionName == epcs.functions.IS_AGREEMENT_DECLINED_BY_FORM_ID ||
                condition.functionName == epcs.functions.FORM_VERSION_FOR_LATEST_ENTRY
            ) {
                valueNode = condition.childNodes[0];
                idMap = {};
                idMap[oldFormId] = newFormId;
            }

            if (valueNode!=null && idMap != null) {
                let oldDouble = valueNode.valueDouble;
                let result = replaceIdForDoubleNode(valueNode, idMap);
                let newDouble = valueNode.valueDouble;

                if (newDouble!=oldDouble) {
                    console.log('replaced id for', condition.functionName.name, oldDouble, ' => ', newDouble);
                }

                return result;
            }

            if (condition.childNodes!=null) {
                let idReplaced = false;

                // go through all the children nodes for additional process
                _.forEach(condition.childNodes, function(conditionNode) {
                    idReplaced = replaceIdForConditionTree(conditionNode, oldIdToNewIdMap, oldFormId, newFormId) || idReplaced;
                });
                return idReplaced;
            }

            return false;
        }

        /**
         * replace id in a condition tree
         * @param condition - condition node
         * @param oldIdToNewIdMap - old id to new id map
         * @param oldFormId - old form id
         * @param newFormId - new form id
         * @return {boolean} - true if at least one id were replaced, false otherwise
         */
        function replaceIdForConditionTree(condition, oldIdToNewIdMap, oldFormId, newFormId) {
            if (condition == null) {
                return false;
            }

            if (condition.functionName != null) {
                return replaceIdForFunctionNode(condition, oldIdToNewIdMap, oldFormId, newFormId);
            }

            if (condition.nodeType == epcs.nodeTypes.ARRAY && condition.valueList!=null) {
                let idReplaced = false;

                // go through all the children nodes for additional process
                _.forEach(condition.valueList, function(conditionNode) {
                    idReplaced = replaceIdForConditionTree(conditionNode, oldIdToNewIdMap, oldFormId, newFormId) || idReplaced;
                });
                return idReplaced;
            }

            return false;
        }

        /**
         * replace id for an object that holds both condition and condition text field
         * @param conditionHolder - object that holds the condition in the fields of "condition" and "conditionText"
         * @param oldIdToNewIdMap - old id to new id map
         * @param oldFormId - old form id
         * @param newFormId - new form id
         * @param keyForConditionText - option key value for condition text, if it's not provided, we will use "conditionText"
         * @param keyForConditionTree - option key value for condition tree, if not provided, use "condition"
         */
        function replaceIdForConditionHolder(conditionHolder, oldIdToNewIdMap, oldFormId, newFormId,
                                             keyForConditionTree, keyForConditionText) {
            if (keyForConditionTree == null) {
                keyForConditionTree = 'condition';
            }

            if (keyForConditionText == null) {
                keyForConditionText = 'conditionText';
            }

            var idReplaced = replaceIdForConditionTree(conditionHolder[keyForConditionTree], oldIdToNewIdMap,
                oldFormId, newFormId);

            if (idReplaced) {
                // id has been replaced, need to reformat the text
                conditionHolder[keyForConditionText] = conditionHolder[keyForConditionTree].toFormattedString(0, false);
            }
        }

        /**
         * replacing id for navigation edge's condition
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForNavEdgeCondition(formMode, oldIdToNewIdMap) {
            var edgesWithCondition = _.filter(formMode.navigationEdges, function(edge) {
                return edge.conditionText != null && edge.condition != null;
            });

            _.forEach(edgesWithCondition, function(edge) {
                replaceIdForConditionHolder(edge, oldIdToNewIdMap, null, null, null, null);
            });
        }

        /**
         * finding all the state rule conditions
         * @param formMode - form mode model
         * @return [] - a list of state rule actions, never null, if nothing is found, return an empty list
         */
        function findAllStateRuleConditions(formMode) {
            var stateRuleConditions = [];

            _.forEach(formMode.stateRules, function(sr) {
                if (sr.conditionListModel!=null) {
                    _.forEach(sr.conditionListModel.conditionWithActionsList, function(condition) {
                        stateRuleConditions.push(condition);
                    });
                }
            });

            return stateRuleConditions;
        }

        /**
         * replacing id for state rule condition
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForStateRuleCondition(formMode, oldIdToNewIdMap) {
            var stateRuleConditions = findAllStateRuleConditions(formMode);

            _.forEach(stateRuleConditions, function(stateRuleCondition) {
                replaceIdForConditionHolder(stateRuleCondition, oldIdToNewIdMap, null, null,
                    'condition', 'conditionText');
            });
        }

        /**
         * finding all the actions of type set field entry
         * @param formMode
         */
        function findAllSetFieldEntryValueActions(formMode) {
            var stateRuleConditions = findAllStateRuleConditions(formMode);

            var actions = [];

            _.forEach(stateRuleConditions, function(condition) {
                _.forEach(condition.actions, function(action) {
                    if (action.actionType == constants.stateRuleActionType.SET_FIELD_ENTRY_VALUE) {
                        actions.push(action);
                    }
                });
            });

            return actions;
        }

        /**
         * replace id for any set field value action that uses an expression as the value
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForSetFieldValueAction(formMode, oldIdToNewIdMap) {
            var setFieldValueAction = findAllSetFieldEntryValueActions(formMode);

            _.forEach(setFieldValueAction, function(action) {
                if (action.expression!=null) {
                    replaceIdForConditionHolder(action, oldIdToNewIdMap, null, null,
                        'expressionTree', 'expression');
                }
            });
        }

        /**
         * replace id for visibility condition using expression condition
         * @param formMode - form mode
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForVisibilityCondition(formMode, oldIdToNewIdMap) {
            var advanceVisibilities = findAllAdvancedVisibilities(formMode);

            _.forEach(advanceVisibilities, function(av) {
                if (av!=null) {
                    replaceIdForConditionHolder(av, oldIdToNewIdMap,
                        null, null,
                        'conditionTree', 'conditionText');
                }
            });
        }

        /**
         * replace id in template field for either text or image insertion
         * @param templateField - template field
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdForTemplateField(templateField, oldIdToNewIdMap) {
            if (templateField==null) {
                return;
            }

            if (templateField.type == 'TEXT' && templateField.value != null) {
                // text field, it's basically the same as string replacement
                if (templateField.value) {
                    const oldFieldValue = templateField.value;
                    const newFieldValue = FormStringUtilService.replaceIdInLabelValue(oldFieldValue, oldIdToNewIdMap);

                    if (newFieldValue==null) {
                        console.warn('can not replace field id of ' + oldFieldValue + ' because it is not in the id map', oldIdToNewIdMap);
                    }
                    else if (newFieldValue != oldFieldValue) {
                        templateField.fieldId = newFieldValue;
                        console.log('replace id in template field, ', oldFieldValue, ' => ', newFieldValue);
                    }
                    templateField.value = newFieldValue;
                }
            }
            else if (templateField.type == 'IMAGE' && templateField.fieldId != null) {
                // replace the field id
                const oldFieldId = templateField.fieldId;
                const newFieldId = oldIdToNewIdMap[oldFieldId];

                if (newFieldId==null) {
                    console.warn('can not replace field id of ' + oldFieldId + ' because it is not in the id map', oldIdToNewIdMap);
                }
                else if (newFieldId != oldFieldId) {
                    templateField.fieldId = newFieldId;
                    console.log('replace id in template field, ', oldFieldId, ' -> ', newFieldId);
                }
            }
        }

        /**
         * replace id in a form mode
         * @param formMode - form mode model
         * @param oldIdToNewIdMap - old id to new id map
         */
        function replaceIdInFormMode(formMode, oldIdToNewIdMap) {

            replaceIdForTextLabelField(formMode, oldIdToNewIdMap);

            replaceIdForTitleSubField(formMode, oldIdToNewIdMap);

            replaceIdForVisibilityCondition(formMode, oldIdToNewIdMap);

            replaceIdForNavEdgeCondition(formMode, oldIdToNewIdMap);

            replaceIdForStateRuleCondition(formMode, oldIdToNewIdMap);

            replaceIdForSetFieldValueAction(formMode, oldIdToNewIdMap);
        }

        /**
         * replace id in pdf configuration, those are server side conditions
         * @param pdfTemplateDetail - pdf template detail consisted of
         * @param oldIdToNewIdMap - old id to new id map
         * @param oldFormId - id of the old form
         * @param newFormId - server generated id of the new form
         */
        function replaceIdInPDFTemplateDetail(pdfTemplateDetail, oldIdToNewIdMap, oldFormId, newFormId) {
            // replace id in the expression condition
            replaceIdForConditionHolder(pdfTemplateDetail, oldIdToNewIdMap, oldFormId, newFormId,
                'expressionTree', 'expression');

            _.forEach(pdfTemplateDetail.templateFields, function(templateField) {
                replaceIdForTemplateField(templateField, oldIdToNewIdMap);
            });
        }

        /**
         * processing the server response
         * @param dto - server response dto from saveForm api call, this time the server id is valid
         * @param localIdToOldServerIdMap - local id to old server id map
         * @param oldFormId - old form id
         * @param successCallBack - call back function when second save is completed
         * @param showAlert - indicate whether alerts should be shown while we are performing this task
         */
        function doSecondSave(dto, localIdToOldServerIdMap, oldFormId, showAlert, successCallBack) {
            // create a basic model
            var formModel = new FormModelServiceForm.FormModel(null, null, null);
            formModel.fromDto(dto);

            var localIdToRealServerIdMap = getLocalIdToFieldIdMap(formModel.formVersions[0]);
            var oldIdToNewIdMap = mergeOldAndNewIdMap(localIdToOldServerIdMap, localIdToRealServerIdMap);

            AlertService.info('old id to new id map is completed');

            _.forEach(formModel.formVersions[0].pdfTemplateDetails, function(pdfTemplateDetail) {
                replaceIdInPDFTemplateDetail(pdfTemplateDetail, oldIdToNewIdMap, oldFormId, formModel.id);
            });

            replaceIdInFormMode(formModel.formVersions[0].editMode, oldIdToNewIdMap);
            replaceIdInFormMode(formModel.formVersions[0].viewMode, oldIdToNewIdMap);
            replaceIdInFormMode(formModel.formVersions[0].promptMode, oldIdToNewIdMap);

            if (showAlert) {
                AlertService.info('all the modes are processed, getting ready to save for the 2nd time');
            }

            // perform the 2nd save to server and give back to user
            FormModelServiceForm.saveForm([], formModel, function (resp) {
                if (showAlert) {
                    AlertService.info('2nd save is successful');
                }

                var formDTO = resp.data;
                var versionId = formDTO.formVersions[0].id;


                FormVersion.activate(formDTO.id, versionId).then(function() {
                    FormVersion.updateVersionComment({
                        id : versionId,
                        isActive: formDTO.formVersions[0].isActive,
                        versionComment : "This version has all the server id referenced fixed"
                    }).then(function() {
                        if (_.isFunction(successCallBack)) {
                            successCallBack(resp.data);
                        }
                    });
                });

            });
        }


        /**
         * merging two map together to form the new map where the key = first map's value, and value = 2nd map's value
         * @param localIdToOldServerIdMap - local id to old server id map
         * @param localIdToRealServerIdMap - local id to new server id map
         */
        function mergeOldAndNewIdMap(localIdToOldServerIdMap, localIdToRealServerIdMap) {
            var map = {};
            _.forOwn(localIdToOldServerIdMap, function (value, key) {
                if (localIdToOldServerIdMap[key] != null) {
                    // has new value, add entry now
                    map[value] = localIdToRealServerIdMap[key];
                }
            });

            return map;
        }

        /**
         * populate the local id to field id map for a particular form mode
         * @param formMode - form mode
         * @param map - @NotNull map to be populated
         */
        function populateLocalIdToFieldIdMap(formMode, map) {
            if (formMode == null || formMode.pages == null) {
                return;
            }

            var fields = findAllFields(formMode);

            _.forEach(fields, function (field) {
                map[field.localId] = field.formField.id;
            });
        }

        /**
         * getting a map of <localId, fieldId> from the form version model
         * @param formVersion - form version model
         * @return {*} - local id to server id map
         */
        function getLocalIdToFieldIdMap(formVersion) {
            var map = {};

            populateLocalIdToFieldIdMap(formVersion.editMode, map);
            populateLocalIdToFieldIdMap(formVersion.viewMode, map);
            populateLocalIdToFieldIdMap(formVersion.promptMode, map);

            return map;
        }


        /**
         * perform import from edit gui where we want to reset all the server id
         * @param dto - form dto to be saved
         * @param successCallBack - success call back on the 2nd save
         * @param showAlert - indicate whether alerts should be shown while we are performing this task
         */
        function performInEditImport(dto, showAlert, successCallBack) {
            // create a basic model
            var formModel = new FormModelServiceForm.FormModel(null, null, null);
            formModel.fromDto(dto);

            var localIdToImportIdMap = getLocalIdToFieldIdMap(formModel.formVersions[0]);

            if (showAlert) {
                AlertService.info('local Id -> old server id map created');
            }

            // now we have finished building the id map, we can remove the old server id so it can be saved
            let oldFormId = formModel.id;
            formModel.customCloneReset(true);

            doFirstSave(formModel, localIdToImportIdMap, oldFormId, showAlert, successCallBack);
        }

        /**
         * service return
         */
        return {
            performInEditImport: performInEditImport
        };
    });
})();
