/**
 * Created by jason.cao on 07-03-2017
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * base model for all navigation builder templates, it will define a list of virtual functions that
     * all subclass should overwrite, this class is hear to define structure
     */
    app.factory('NavTemplateBaseModel', function () {

        /**************************************
         *  Model definition/construction
         ***************************************/

        /**
         * constructor for base template model
         * @param parent - parent nav build config model that holds the information on starting page, etc
         * @constructor
         */
        function NavTemplateBaseModel(parent) {
            this._parent = parent;
        }

        /*
         ****************************************************************************************************
         * default implementation of common functions
         ****************************************************************************************************
         */

        NavTemplateBaseModel.prototype.getParent = function() {
            return this._parent;
        };

        /*
         ****************************************************************************************************
         * default implementation of virtual functions, all of the following function should be
         * implemented at subclass
         ****************************************************************************************************
         */

        /**
         * check if the configuration is valid or not
         * @returns {boolean} - true if config is valid, false otherwise
         */
        NavTemplateBaseModel.prototype.isValid = function () {
            throw 'isValid not implemented';
        };

        /**
         * getting filter function used for target selection
         * return {function}
         */
        NavTemplateBaseModel.prototype.getFilterFunction = function () {
            throw 'getFilterFunction not implemented';
        };

        /**
         * process config, should be overwritten by every template model
         * only need to add messages when needed
         */
        NavTemplateBaseModel.prototype.processConfig = function () {
            // internal processing of the template, should never get here, so throw an error if you did get here
            throw 'processConfigInternal is not implemented!';
        };


        /***************************************
         * service return call
         ***************************************/

        return NavTemplateBaseModel;
    });


})();
