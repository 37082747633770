(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('addressConfirmation', function (AddressVerificationSetup, LocaleConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/addressVerification/addressConfirmation.html',
                scope: {
                    parentForm: '=',
                    page: '='
                },
                link: function ($scope) {
                    $scope.ready = false;
                    $scope.imageField = AddressVerificationSetup.getAddressConfirmationImageField($scope.page);
                    $scope.lcs = LocaleConstantsService;
                    $scope.labelField = AddressVerificationSetup.getAddressConfirmationLabelField($scope.page);
                    $scope.ready = true;
                }
            };
        });

})();


