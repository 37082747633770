'use strict';

angular.module('acadiamasterApp')
    .controller('UnitCategoryDetailController', function ($scope, $rootScope, $stateParams, entity, UnitCategory, Unit, UnitByCategory, $state, ngDialog) {
        $scope.unitCategory = entity;
        $scope.units = [];

        $scope.unitCategory.$promise.then(function () {
            loadUnitsForCategory($scope.unitCategory.id);
        });


        $scope.load = function (id) {
            UnitCategory.get({id: id}, function (result) {
                $scope.unitCategory = result;
            });
        };
        $rootScope.$on('acadiamasterApp:unitCategoryUpdate', function (event, result) {
            $scope.unitCategory = result;
        });

        $scope.deleteUnitCategory = function () {
            ngDialog.openConfirm({
                template: 'admin-templates/site/unitSystem/unitCategory/unitCategoryDelete.html',
                controller: 'UnitCategoryDeleteController',
                data: $scope.unitCategory
            }).then(function () {
                $state.go('unitCategory');
            });
        };

        /**
         * this is a deferred function, result may not be available immediately,
         * may need to be changed to return the promise instead for better control of event timing
         * @param categoryId
         */
        function loadUnitsForCategory(categoryId) {
            UnitByCategory.query({categoryId: categoryId}, function (units) {
                $scope.units = units;
            });
        }
    });
