(function () {
    'use strict';

    /**
     * directive for comparing common object with a list of properties
     * note: array property are not showing, only property with _status flag will be shown, other properties
     * are considered noise or handled elsewhere (array)
     */
    angular.module('acadiamasterApp').directive('formCompareCommonObject', function (CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/common/compareResult.commonObject.html',
            scope: {
                resultObject : '=',
                showChangeOnly : '=',
                keyPrefix : '@'
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;

                $scope.isAnswerArray = function(key) {
                    return key=='answers' && _.isArray($scope.resultObject[key]);
                };
            }
        };
    });

})();
