(function () {
    'use strict';

    /**
     * directive for building title rows
     */
    angular.module('acadiamasterApp').directive('formCompareTitle', function (CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/common/formCompareTitle.html',
            scope: {
                titleClass : '@',
                title : '@',
                compareResult : '=?',
                compareResultStatus : '=',
                showChangeOnly : '=',
                showHeader : '=?'
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;

                if ($scope.showHeader==null) {
                    $scope.showHeader = true;
                }

                $scope.getTitleClass = function() {
                    return $scope.titleClass + " " + $scope.compareResultStatus.rowClass;
                };

                $scope.shouldShowIcon = function() {
                    return $scope.compareResult!=null &&
                        CompareResultUtilService.shouldShowObject($scope.compareResultStatus, $scope.showChangeOnly);
                };

                $scope.shouldShowHeader = function() {
                    return $scope.showHeader && $scope.shouldShowIcon() && ($scope.compareResult!=null && $scope.compareResult._isOpen);
                };
            }
        };
    });

})();
