(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for ProgramDayInRangeCondition model
     */
    app.factory('ProgramDayInRangeCondition', function (QObjectModel, ProgramTestConstantsService, TimeComparisonOperatorService) {
        /***************************************************************
         * private functions
         **************************************************************/

        ProgramDayInRangeCondition.inheritsFrom(QObjectModel);

        function ProgramDayInRangeCondition(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.PROGRAM_DAY_IN_RANGE);

            this.absolute = false;

            this.comparator = TimeComparisonOperatorService.BETWEEN;

            this.value = 1;
            this.valueBeginAt = 1;
            this.valueEndAt = 1;
        }

        /**
         * convert the current UI model to dto format
         */
        ProgramDayInRangeCondition.prototype.toDto = function () {
            let dto = QObjectModel.prototype.toDto.call(this);

            dto.comparator = this.comparator == null ? null : this.comparator.name;

            if (this.comparator.name === 'BETWEEN') {
                dto.startDay = this.valueBeginAt;
                dto.endDay = this.valueEndAt;
            } else {
                dto.startDay = this.value;
                dto.endDay = this.value;
            }
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ProgramDayInRangeCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.comparator = ProgramTestConstantsService.getObjectByName(TimeComparisonOperatorService, dto.comparator);

            if (this.comparator.name === 'BETWEEN') {
                this.valueBeginAt = dto.startDay;
                this.valueEndAt = dto.endDay;
            } else {
                this.value = dto.startDay;
                this.valueBeginAt = null;
                this.valueEndAt = null;
            }
        };

        ProgramDayInRangeCondition.prototype.getDescriptionAsHtml = function () {
            let baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);
            let comparatorMsg = '<span class="badge">' + (this.comparator == null ? '?' : this.comparator.description) + '</span>';
            let baseString = baseMsg + ' Program day  ' + comparatorMsg;

            if(this.comparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                return baseString + ' day ' + timeMsgBuilder(this.valueBeginAt) + ' and day ' + timeMsgBuilder(this.valueEndAt);
            }

            return baseString + ' day ' + timeMsgBuilder(this.value);
        };

        ProgramDayInRangeCondition.prototype._validateSelf = function () {
            this.clearError();

            if (this.comparator.name === 'BETWEEN') {
                if (this.valueBeginAt == null || this.valueEndAt == null) {
                    this.setErrorMessage('Start and end day values are required');
                    return;
                }

                if (this.valueBeginAt < 1 || this.valueEndAt < 1) {
                    this.setErrorMessage('Start and end days must be >= 1');
                    return;
                }

                if(this.valueBeginAt > this.valueEndAt) {
                    this.setErrorMessage('Start day must less than or equal to end day');
                }

                if (this.valueBeginAt % 1 !== 0 || this.valueEndAt %1 !== 0) {
                    this.setErrorMessage('Start and end days must be a whole number');
                }
            } else {
                if (this.value == null) {
                    this.setErrorMessage('Start/End day is required');
                    return;
                }

                if (this.value < 1) {
                    this.setErrorMessage('Start/End day must be >= 1');
                    return;
                }

                if (this.value % 1 !== 0) {
                    this.setErrorMessage('Start/End day must be a whole number');
                }
            }
        };

        /***************************************
         * private function
         ***************************************/
        function timeMsgBuilder(value) {
            return value == null ? '?' : value;
        }

        /***************************************
         * service return call
         ***************************************/
        return ProgramDayInRangeCondition;
    });
})();
