(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                return $translate.refresh();
            }

            /**
             * Feature Toggles page
             */
            $stateProvider.state('features', {
                parent: 'entity',
                url: '/features',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'Features'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/features/features.html',
                        controller: 'FeaturesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('features');
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
        });
})();
