/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of one section
     */
        .directive('treeNodeFormSection', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/formSection.html',
                scope: {
                    section: '=',
                    selector: '=',
                    currentTreeName : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.flags = {
                        dropDownIsOpen : false
                    };

                    /**
                     * clone the section, insert it right below the current section
                     */
                    $scope.cloneSection = function() {
                        var section = $scope.section;
                        section._parent.cloneSection(section, $scope.selector, $scope.currentTreeName);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * add a component to the section
                     */
                    $scope.addComponent = function() {
                        $scope.section.addComponent(null, $scope.selector, $scope.currentTreeName,false);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * add a matrix component to the section
                     */
                    $scope.addMatrixComponent = function() {
                        $scope.section.addComponent(null, $scope.selector, $scope.currentTreeName,true);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * delete current section
                     */
                    $scope.deleteSection = function() {
                        $scope.section._parent.removeSection($scope.section, $scope.selector);
                        $scope.selector.selectItem($scope.section._parent);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * cut the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCut = function() {
                        $scope.selector.cutItem($scope.section);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * copy the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCopy = function() {
                        $scope.selector.copyItem($scope.section);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * check if the current item in buffer can be pasted as a child
                     * @returns {boolean}
                     */
                    $scope.canPasteAsChild = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not already a child
                        return itemInBuffer.nodeType == FormConstants.nodeType.COMPONENT &&
                                itemInBuffer._parent != $scope.section;
                    };


                    /**
                     * check if the current item in buffer can be pasted as a sibling
                     * @returns {boolean}
                     */
                    $scope.canPasteBelowAsSibling = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not the same as current section
                        return itemInBuffer.nodeType == FormConstants.nodeType.SECTION &&
                            itemInBuffer != $scope.section;
                    };


                    /**
                     * paste the last element copied or cut as a child
                     * ie: this element copied of cut must be a form component
                     */
                    $scope.doPasteAsChild = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;

                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeComponent(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to this element
                        elementInBuffer._parent = $scope.section;

                        // add it to the top of the children array
                        $scope.section.formComponents.unshift(elementInBuffer);
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };


                    /**
                     * paste the last element copied or cut as a sibling
                     * ie: this element copied of cut must be a form section
                     */
                    $scope.doPasteBelowAsSibling = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;

                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeSection(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to the parent of this element
                        elementInBuffer._parent = $scope.section._parent;

                        // add it to the next spot in the children array
                        var sections = $scope.section._parent.sections;
                        var index = sections.indexOf($scope.section);
                        sections.splice(index+1, 0, elementInBuffer);

                        // select the new item
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };

                    $scope.isOtherThanDataCapture = function(){
                        return $scope.section._parent.formPageType != FormConstants.formPageDiffTypes.DATA_CAPTURE;
                    }
                }
            }
        });
})();

