/**
 * Created by pravin.gayal on 2/21/17.
 */
(function () {
    'use strict';

    /**
     * Directive for trigger other rule action.
     */

    angular.module('acadiamasterApp').directive('ruleElementConfigActionTriggerRule', function (RuleUtilService, BusinessRule, RuleModelService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfigActionTriggerRule.html',
            scope: {
                action: '=',
                parentForm: '='
            },
            link: function ($scope) {
                $scope.programId = $scope.action.getRoot().programId;
                $scope.cs = RuleUtilService.ruleConstants;
                $scope.hasMultipleExpression = false;
                $scope.data = {
                    datepickerOptions: {
                        showWeeks: false,
                        formatDayTitle: 'MMMM yyyy',
                        formatDay: 'd',
                        minDate: new Date(),
                        showWeekDays: true,
                        calendarWeeks: false,
                        showButtonBar: true
                    },
                    now: new Date()
                };

                $scope.getAction = function (id) {
                    var index = _.findIndex($scope.action.rule.actions, { 'id': id });
                    return $scope.action.rule.actions[ index ];
                };

                $scope.ruleOptions = {
                    getRule: function () {
                        if ($scope.action.rule != null && !$scope.action.rule.toDto) {
                            var ruleModel = new RuleModelService.RuleModel(null, null);
                            ruleModel.fromDto($scope.action.rule);
                            $scope.action.rule = ruleModel;
                        }

                        return $scope.action.rule;
                    },
                    setRule: function (rule) {
                        ruleUpdated(rule);
                    },
                    title: 'Business Rule',
                    viewOnly: false
                };

                // work flow functions for work flow directive
                $scope.workIdFunctions = {
                    getId: function () {
                        return $scope.action.workflowId;
                    },
                    setId: function (id) {
                        $scope.action.workflowId = id;
                    }
                };

                $scope.workflowSelected = function (workflow) {
                    return workflow != null && workflow.id == $scope.workIdFunctions.getId();
                };

                /**
                 * getting the valid work flow id
                 * @param currentId - current work flow id
                 * @param availableWorkFlows - available workflow id
                 * @return  valid work flow id
                 */
                function getValidWorkFlowId(currentId, availableWorkFlows) {
                    if (currentId == null) {
                        return null;
                    }

                    var isWorkFlowIdValid = _.findIndex(availableWorkFlows, function (wk) {
                        return wk.id === currentId;
                    }) != -1;

                    return isWorkFlowIdValid ? currentId : null;
                }

                function ruleUpdated(newRule) {
                    if (newRule == null) {
                        $scope.action.rule = null;
                        $scope.action.workflowId = null;
                    } else {
                        BusinessRule.get({
                            id: newRule.id
                        }).$promise.then(function (data) {
                            var ruleModel = new RuleModelService.RuleModel(null, null);
                            ruleModel.fromDto(data);

                            $scope.action.rule = ruleModel;

                            if (ruleModel.workflows.length == 1) {
                                // only one workflow to select from, auto select it and make it read only afterwards
                                $scope.action.workflowId = ruleModel.workflows[ 0 ].id;
                                $scope.workFlowIdSelectionReadOnly = true;
                            }
                            else {
                                $scope.action.workflowId = getValidWorkFlowId($scope.action.workflowId, ruleModel.workflows);
                                if ($scope.action.workflowId == null) {
                                    $scope.workIdFunctions.setId(ruleModel.workflows[ 0 ].id);
                                }
                                $scope.workFlowIdSelectionReadOnly = false;
                            }

                            $scope.hasMultipleExpression = _.findIndex($scope.action.rule.workflows, function (workflow) {
                                return workflow.evaluationExpressions && workflow.evaluationExpressions.length > 1
                            }) != -1;
                        });
                    }
                }
            }
        };
    });
})();
