(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('formElementConfigActionScroll', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/stateRule/elementConfigActionScroll.html',
                scope: {
                    element: '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                }
            };
        });
})();
