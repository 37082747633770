(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('deleteBusinessRule', function (ngDialog, BusinessRule) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/rule/deleteBusinessRule.html',
                scope: {
                    ruleId: '=',
                    onSuccessCallback: '='
                },
                link: function ($scope) {
                    $scope.ready = false;
                    $scope.showAssociationRemovalMessage = false;
                    $scope.ready = true;
                    $scope.closePopup = function () {
                        ngDialog.close();
                    };

                    $scope.delete = function () {
                        BusinessRule.delete({
                            id: $scope.ruleId
                        }, onSuccess);
                    };

                    function onSuccess() {
                        $scope.closePopup();
                        $scope.onSuccessCallback();
                    }
                }
            };
        });
})();
