'use strict';

(function () {

    angular.module('acadiamasterApp').directive('correctiveActionDismissBanner', function (RuleConstantsService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/' +
                'correctiveActionDirective/correctiveActionDismissBanner.html',
            scope: {
                correctiveAction: '='
            },
            link: function ($scope) {
                $scope.rcs = RuleConstantsService;
                $scope.formOptions = {
                    form: $scope.correctiveAction.getForm(),
                    title: 'Select Form',
                    viewOnly: false,
                    onChangeCallBack : function() {
                        $scope.correctiveAction.setForm(this.form);
                    }
                };
                $scope.programId = $scope.correctiveAction._parent.programId;
                $scope.bannerTypeNeedsForm = function () {
                    return $scope.correctiveAction.bannerTypeNeedsForm();
                };
            }
        };
    });
})();
