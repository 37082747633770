/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')


    /**
     * directive for display a tree node view of one state rule trigger
     */
        .directive('treeNodeFormTrigger', function (treeNodeCommonService,
                                                    FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/formStateRule.trigger.html',
                scope: {
                    element: '=',
                    currentTreeName : '=',
                    selector: '='
                },
                link: function ($scope) {
                    $scope.tncs = treeNodeCommonService;

                    $scope.flags = {
                        dropDownIsOpen : false
                    };

                    /**
                     * clone the trigger, insert it right below the current one
                     */
                    $scope.cloneTrigger = function() {
                        var trigger = $scope.element;
                        trigger._parent.cloneTrigger(trigger, $scope.selector, $scope.currentTreeName);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * delete current trigger
                     */
                    $scope.deleteTrigger = function() {
                        $scope.element._parent.removeTrigger($scope.element, $scope.selector);
                        $scope.selector.selectItem($scope.element._parent);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * cut the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCut = function() {
                        $scope.selector.cutItem($scope.element);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * copy the current element, this doesn't make any change to the system until
                     * a paste action is made later, the object reference is stored in the selector
                     */
                    $scope.doCopy = function() {
                        $scope.selector.copyItem($scope.element);
                        $scope.flags.dropDownIsOpen = false;
                    };

                    /**
                     * check if the current item in buffer can be pasted as a sibling
                     * @returns {boolean}
                     */
                    $scope.canPasteBelowAsSibling = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }

                        // check for matching type and make sure this is not the same as current trigger
                        return itemInBuffer.nodeType == FormConstants.nodeType.RULE_TRIGGER &&
                            itemInBuffer != $scope.element;
                    };


                    /**
                     * paste the last element copied or cut as a sibling
                     * ie: this element copied of cut must be a form trigger
                     */
                    $scope.doPasteBelowAsSibling = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;

                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeTrigger(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to the parent of this element
                        elementInBuffer._parent = $scope.element._parent;

                        // add it to the next spot in the children array
                        var triggers = $scope.element._parent.triggers;
                        var index = triggers.indexOf($scope.element);
                        triggers.splice(index+1, 0, elementInBuffer);

                        // select the new item
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();

                        $scope.flags.dropDownIsOpen = false;
                    };

                }
            }
        });
})();

