/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for configuring any form element
     */
        .directive('formElementConfigMain', function (FormUtilService, FormConstants, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/elementConfigMain.html',
                scope: {
                    element: '=',
                    selector : '='
                },
                link: function ($scope) {
                    $scope.ec = FormUtilService.elementCompare;
                    $scope.isNullOrUnavailable = vbrCommonUtil.isNullOrUnavailable;
                    $scope.constants = FormConstants;
                    $scope.editForm = null;

                    addWatches($scope);
                }
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function addWatches($scope) {
                $scope.$watch('element', function () {
                    if ($scope.element != null) {
                        // build initial ui states on element selection change
                        if ($scope.element.uiStates == null) {
                            $scope.element.uiStates = FormUtilService.stateControlUtil.setupInitialUIStates(
                                FormUtilService.stateControlUtil.calculateIncludeBasic($scope.element));
                        }
                    }
                });
            }
        });
})();

