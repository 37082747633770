/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('ceil', function () {
            return function (input) {
                return Math.ceil(input);
            };
        });
})();

