(function () {
    'use strict';
    var app = angular.module('acadiamasterApp');

    /**
     * utility service for view mode generator
     */
    app.factory('ViewModeGeneratorUtilService', function (AlertService, FormConstants) {
        return {
            resetModeWithSinglePage: resetModeWithSinglePage,
            checkForMissingServerId: checkForMissingServerId,
            buildValueString: buildValueString,
            getRefFieldName: getRefFieldName,
            isInputFieldWithSubField: isInputFieldWithSubField,
            isLabelField: isLabelField
        };

        /**
         * clear all pages on a form mode and create just a single page
         * @param formMode - form mode object
         * @param pageName - the name of the page
         */
        function resetModeWithSinglePage(formMode, pageName) {
            while (formMode.pages.length > 0) {
                formMode.removePage(formMode.pages[0]);
            }

            var newPage = formMode.addPage(FormConstants.formPageDiffTypes.NORMAL);

            newPage.name = pageName;

            // remove the section from new page so it's totally empty and ready for the
            // next step
            newPage.removeSection(newPage.sections[0]);

            return newPage;
        }


        function isInputFieldWithSubField(inputField) {
            if (inputField == null || inputField.type == null || inputField.type.name == null) {
                return false;
            }

            switch (inputField.type.name) {
            case FormConstants.fieldsType.TEXT_INPUT.name:
            case FormConstants.fieldsType.NUMBER_INPUT.name:
            case FormConstants.fieldsType.BOOLEAN_SELECTOR.name:
            case FormConstants.fieldsType.MATRIX_QUESTION.name:
            case FormConstants.fieldsType.TAKE_PICTURE.name:
                return false;
            default:
                return true;
            }
        }

        /**
         * check if the field is a text label or not
         * ie: rich text is NOT a text label
         * @param field - field to test against
         * @returns {boolean}
         */
        function isLabelField(field) {
            return field != null && field.type == FormConstants.fieldsType.TEXT_LABEL;
        }

        /**
         * check if the field has a server generated id, if not, show an alert on screen
         * @param field - field to check
         */
        function checkForMissingServerId(field) {
            if (field.formField.id == null) {
                // add a warning if we can't find the field id to reference in answer text, this will have to be
                // manually fixed later
                AlertService.warning('Unsaved field encountered, field local id : ' + field.localId +
                     ' of type ' + field.type.name);
            }
        }

        /**
         * build value string for display
         * @param fieldId - field id (server id)
         * @param valueType - value type
         * @param formatterInfo - formatter info
         * @returns {string} - return the value string from the input,
         *                     ie: [field 123 value | dateFormat : 'MMM dd, yyyy']
         *                         [field 123 answerTextAll]
         */
        function buildValueString(fieldId, valueType, formatterInfo) {
            var value = '[field ' + fieldId + ' ';

            // add value type, default to 'value'
            value += valueType ? valueType : 'value';

            // add formatter if needed
            value += formatterInfo ? ' | ' + formatterInfo : '';

            value += ']';

            return value;
        }

        /**
         * get ref field name generated from a field
         * @param field - field to reference to
         * @returns {string}
         */
        function getRefFieldName(field) {
            return '';

            // don't delete yet, might be needed later
            // return "ref for " +
            //     (field.name!=null && field.name.length>0 ? field.name :
            //         'field id: (' + field.formField.id + '|' + field.localId + ')') +
            //     " of type: " + field.type.name;
        }


    });
})();
