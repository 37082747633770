/**
 * Created by shekhar.sukhadeve on 21-04-2017
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('pdfConfigurationList', function (LocaleConstantsService,PdfConfigurationModelService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/pdfConfig/pdfConfigList.html',
                scope: {
                    configModel:'=',
                    parentForm: '=',
                    element:'='
                },
                link: function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.open=false;
                    $scope.addPDFTemplate = function () {
                        var templateField = new PdfConfigurationModelService.PdfConfigurationModel();
                        $scope.configModel.push(templateField);
                    };

                    $scope.removePDFTemplate = function (index) {

                        $scope.configModel.splice(index, 1);
                        if($scope.configModel.length<1){
                            $scope.element.pdfEnabled=false;
                        }
                    };

                    $scope.$watch("element.pdfEnabled",function(newValue){
                        if (!($scope.configModel.length == 0 && newValue)) {
                            if (!newValue) {
                                $scope.configModel = [];
                            }
                        } else {
                            var templateField = new PdfConfigurationModelService.PdfConfigurationModel();
                            $scope.configModel.push(templateField);
                        }
                    });
                }
            };
        });

})();


