/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('SearchFilterService', function ($http, localWeb) {
            return {
                getEventTypes: function () {
                    return $http.get(localWeb.createUrl('external-log/v1/events/types'));
                },
                getForms: function () {
                    return $http.get(localWeb.createUrl('/api/forms/loadAllNoPage/'));
                },
                getRules: function () {
                    return $http.get(localWeb.createUrl('/api/businessRules/loadAllNoPage/'));
                },
                getServiceTypes: function () {
                    return $http.get(localWeb.createUrl('external-log/v1/services'));
                },
                getEventSource: function () {
                    return $http.get(localWeb.createUrl('external-log/v1/events/sources'));
                },
                getProperties: function () {
                    var params = {
                        size: 2147483647, // maximum value for int
                        page: 0,
                        orderBy: 'id',
                        ascending: true
                    };
                    return $http.get(localWeb.createUrl('/api/propertys/search/'), { params: params });
                }
            };
        });
})();
