(function () {
    angular.module('acadiamasterApp')
        .directive('participantPmbDetails', (ParticipantProfileService, AlertService, ngDialog) => {
            return {
                link : function ($scope) {
                    ParticipantProfileService.getPmb($scope.userId)
                        .then(response => {
                            $scope.pmbData = response.data;
                        }).catch(() => {
                            AlertService.error('Failed to retrieve physical measurements and participant summary data for given user');
                        });

                    $scope.getDataTypeString = function (dataType) {
                        let str = $scope.pmConstants.bodyVital[dataType];
                        if (str == null || str.length < 1) {
                            str = dataType;
                        }
                        return str;
                    };
                    $scope.getUniteString = function (unit) {
                        let str = $scope.pmConstants.units[unit];
                        if (str == null || str.length < 1) {
                            str = unit;
                        }
                        return str;
                    };

                    $scope.openDeleteModal = function (dataId) {
                        ngDialog.openConfirm({
                            scope       : $scope,
                            templateUrl : 'admin-templates/site/participant/profile/experience/pmb/deleteModal/deleteModal.template.html',
                        }).then(() => {
                            ParticipantProfileService.deletePmb(dataId)
                                .then(() => {
                                    ParticipantProfileService.getPmb($scope.userId)
                                        .then(response => {
                                            $scope.pmbData = response.data;
                                        }).catch(() => {
                                            AlertService.error('Failed to retrieve physical measurements and participant summary data for given user');
                                        });
                                }, () => {
                                    AlertService.error('Failed to delete physical measurement data.');
                                });
                        });
                    };

                    $scope.pmConstants = {
                        bodyVital : {
                            BLOOD_PRESSURE_DIASTOLIC : 'Diastolic',
                            BLOOD_PRESSURE_MEAN      : 'Blood Pressure',
                            BLOOD_PRESSURE_SYSTOLIC  : 'Systolic',
                            BMI                      : 'BMI',
                            HEART_RATE_MEAN          : 'Heart Rate',
                            HEIGHT                   : 'Height',
                            HEIGHT_FEET              : 'Feet',
                            HEIGHT_INCHES            : 'Inches',
                            HIP_CIRCUMFERENCE_MEAN   : 'Hip Circumference',
                            WAIST_CIRCUMFERENCE_MEAN : 'Waist Circumference',
                            WEIGHT                   : 'Weight',
                        },
                        units : {
                            BMI_UNIT         : 'kg/m2',
                            BP_UNIT          : 'mmHg',
                            FEET_INCHES_UNIT : '\'\'',
                            FEET_UNIT        : '\'',
                            HEART_RATE_UNIT  : 'BPM',
                            INCHES_UNIT      : 'in',
                            POUNDS_UNIT      : 'lbs',
                        },
                    };
                },
                restrict : 'E',
                scope    : {
                    userId : '=',
                },
                templateUrl : 'admin-templates/site/participant/profile/experience/pmb/participantPMBDetails.template.html',
            };
        });
}());
