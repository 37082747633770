/**
 * Created by Nikhil on 2/2/21.
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * directive for form field value of type matrix question
         */
        .directive('formFieldConfigFieldValueMatrixQuestion', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/fieldValue/matrixQuestion.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '='
                },
                link: function ($scope) {
                    /**
                     * Created for future use. Keeping empty now.
                     */
                }
            };
        });

})();
