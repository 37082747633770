/* eslint-disable eqeqeq */
/**
 * service for expression condition model
 */
angular.module('acadiamasterApp').factory('ExpressionConditionModelService',
    (
        ModelServiceBase,
        ProgramTestConstantsService,
        ExpressionConditionService,
        ExpressionConditionConstantsService,
        ConditionTreeCreationUtil,
    ) => {
        /*
         * Expression Condition Model
         * @constructor
         */
        function ExpressionConditionModel (parent, isReusable, supportNullableCondition, usageType) {
            ModelServiceBase.BaseTreeNodeModel.call(this, true, false, parent);

            // metadata fields
            this.supportNullableCondition = supportNullableCondition;
            this.collapsed = false;
            this.usageType = usageType;

            // expression fields
            this.id = null;
            this.programId = this.getRoot().programId;
            this.name = null;
            this.description = null;
            this.isReusable = isReusable ? isReusable : false;

            this.conditionTree = null;
            if (!this.supportNullableCondition) {
                this.conditionTree = ConditionTreeCreationUtil.createByType(
                    ProgramTestConstantsService.QObjectType.GROUP_OBJECT.name, this,
                );
            }

            this.usages = [];
            this.dependencies = [];
            this.nameAvailable = true;
            this.nodeType = ProgramTestConstantsService.nodeType.EXPRESSION_CONDITION_BASIC_INFO;
        }

        ExpressionConditionModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        /*
         * convert the current UI model to dto format
         */
        function toDto () {
            // if nullable expression is allowed and condition tree doesn't exists
            // it means admin would like to save null expression
            if (this.supportNullableCondition && this.conditionTree == null) {
                return null;
            }

            const dto = {};
            dto.id = this.id;
            dto.programId = this.programId;
            dto.name = this.name;
            dto.description = this.description;
            dto.isReusable = this.isReusable;
            dto.usages = this.usages;
            dto.dependencies = this.dependencies;
            dto.conditionTree = this.conditionTree == null ? null : this.conditionTree.toDto();
            return dto;
        }

        /*
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        function fromDto (dto) {
            // return null dto if nullable condition is allowed and condition tree is not configured
            if (this.supportNullableCondition && dto == null) {
                this.conditionTree = null;
                return;
            }

            this.id = dto.id;

            // set program id
            this.programId = dto.programId;
            if (this.programId == null) {
                this.programId = this.getRoot().programId;
            }
            this.name = dto.name;
            this.description = dto.description;
            this.isReusable = dto.isReusable;
            this.usages = dto.usages;
            this.dependencies = dto.dependencies;
            this.conditionTree = ConditionTreeCreationUtil.createByDto(dto.conditionTree, this);
        }

        function validateSelf () {
            this.clearError();
            const _this = this;

            if (this.supportNullableCondition && this.conditionTree == null) {
                return;
            }

            if (!this.supportNullableCondition || this.conditionTree != null) {
                if (this.name == null) {
                    this.setErrorMessage('name is required');
                    return;
                }

                if (this.name.length > 255) {
                    this.setErrorMessage('name is too long');
                    return;
                }

                if (this.usageType == ExpressionConditionConstantsService.usageTypes.EXPRESSION_CONDITION) {
                    ExpressionConditionService.isNameAvailable(this.name, this.id)
                        .then(response => {
                            _this.nameAvailable = response.data?.isValid;

                            if (!_this.nameAvailable) {
                                _this.setErrorMessage('name is not available');
                            }
                        });
                }

                if (this.description == null) {
                    this.setErrorMessage('description is required');
                }

                if (this.description != null && this.description.length > 28000) {
                    this.setErrorMessage('description cannot be more than 28,000 characters long');
                    return;
                }

                if (!this.nameAvailable) {
                    this.setErrorMessage('name is not available');
                }

                if (this.conditionTree == null) {
                    this.setErrorMessage('At least one condition must exist.');
                }
            }
        }

        function setConditionTree (conditionTree) {
            if (conditionTree) {
                conditionTree._parent = this;
            }
            this.conditionTree = conditionTree;
        }

        function customCloneReset () {
            // reset expression as it needs to be unique for every expression
            this.id = null;
            this.name = null;
        }

        ExpressionConditionModel.prototype.toDto = toDto;
        ExpressionConditionModel.prototype.fromDto = fromDto;
        ExpressionConditionModel.prototype._validateSelf = validateSelf;
        ExpressionConditionModel.prototype.setConditionTree = setConditionTree;
        ExpressionConditionModel.prototype.customCloneReset = customCloneReset;

        /** *************************************
         * service return call
         ************************************** */
        return {
            ExpressionConditionModel,
        };
    });
