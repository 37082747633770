(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('bioSpecimenStatusConfig',
        function (ValueType, ValueComparisonOptionModel, ProgramTestService) {
            function createValueComparisonOption($scope) {
                var options = {};

                // load potential options from API
                ProgramTestService.getBioSpecimenTypes().then(
                    function (response) {
                        response.data.forEach(function (option) {
                            options[option] = { name: option, text: option };
                        });
                        $scope.data.loaded = true;
                    },
                    function () {
                        console.error('Error loading Bio Specimen Types');
                    });


                var condition = $scope.condition;
                var valueComparisonOption = new ValueComparisonOptionModel();

                valueComparisonOption.isRequired = true;
                valueComparisonOption.canConfigureOperator = true;

                valueComparisonOption.functions.getValueType = function () {
                    return ValueType.STRING;
                };

                valueComparisonOption.functions.getOperator = function () {
                    return condition.getComparator();
                };

                valueComparisonOption.functions.setOperator = function (newOperator) {
                    condition.setComparator(newOperator);
                };

                valueComparisonOption.functions.getValues = function () {
                    return condition.statusTypeList;
                };

                valueComparisonOption.functions.setValues = function (newValues) {
                    condition.statusTypeList = newValues;
                };

                valueComparisonOption.functions.getPossibleValues = function () {
                    return options;
                };

                return valueComparisonOption;
            }

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/bioSpecimenStatusConfig.html',
                scope: {
                    condition: '='
                },
                link: function ($scope) {
                    $scope.data = {
                        loaded: false,
                    };
                    $scope.valueComparisonOption = createValueComparisonOption($scope);
                }
            };

        });
})();
