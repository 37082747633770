/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('searchFilterRadio', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/searchFilter/fields/radio/searchFilterRadio.template.html',
                scope: {
                    filter: '=',
                    value: '=',
                    options: '=',
                    onValueChange: '='
                },
                controller: function ($scope) {
                    $scope.localValue = $scope.value ? $scope.value.value : '';

                    $scope.setValue = function() {
                        var option = $scope.options.find(function(opt) {
                            return opt.value === $scope.localValue;
                        });
                        $scope.value = option;
                        $scope.onValueChange($scope.filter);
                    };

                    $scope.areOptionsLoaded = function() {
                        return $scope.value == null || $scope.options.length > 0
                    };
                }
            };
        });
})();
