'use strict';

angular.module('acadiamasterApp')
    .factory('Organization', function ($resource, localWeb) {
        return $resource(localWeb.createUrl('api/organizations/:id'), {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    })

    .factory('OrganizationByOrgCode', function ($http,localWeb) {
        var url = localWeb.createUrl('api/organizations/codeAvailable/');
        return {
            'get' : function(orgCode, orgId) {
                var finalUrl = url + orgCode;
                if (orgId!=null) {
                    finalUrl += "?orgId=" + orgId;
                }
                return $http.get(finalUrl);
            }
        };
    });

