(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('userIdInConfig', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/userIdIn.html',
            scope: {
                condition: '='
            },
        };
    });
})();
