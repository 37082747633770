/**
 * Created by Jamie Nola on 10/18/2018
 *
 * This file monitors state changes and reports those changes to various services that need the
 * current path.
 */

(function () {
    angular.module('acadiamasterApp')
        .factory('StateChangeService', ($rootScope, $state, $window, $translate, Principal,
            Auth, Language, BreadcrumbService, SessionStorageService, VbrLoggingDialogService, OrderTypeService) => {
            /*
             * Takes a state object and iterates through its parents to build an array
             * representing the path to root
             * @param {Object} toState
             */
            function getStatePath (toState) {
                let states = [];
                let names = [];
                let state = toState;
                while (state !== null) {
                    // breadcrumb controller needs an array of states, but only uses the following values:
                    //  * abstract (Boolean) If true, the abstract state does not have breadcrumbs
                    //  * name (String) The state's unique identifier
                    //  * hideBreadcrumb (Boolean) An option to manually hide a state's breadcrumb
                    states.push({
                        abstract       : Boolean(state.abstract),
                        hideBreadcrumb : Boolean(state.data && state.data.hideBreadcrumb),
                        name           : state.name,
                    });
                    // SessionStorageService only needs an array of state names
                    names.push(state.name);
                    state = state.parent ? $state.get(state.parent) : null;
                }
                return {
                    names  : names.reverse(),
                    states : states.reverse(),
                };
            }

            /* --------------------------------------------------
            *  callback for starting state changes
            *--------------------------------------------------*/
            function onStateChangeStart (event, toState, toStateParams, fromState) {
                VbrLoggingDialogService.addDebug(`   * fromState : ${ JSON.stringify(fromState)}`);
                VbrLoggingDialogService.addDebug(`   * toState : ${ JSON.stringify(toState)}`);

                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // if the user is authenticated but we haven't loaded orderTypes yet, do it now.
                if (Principal.isAuthenticated() && !OrderTypeService.hasLoaded()) {
                    OrderTypeService.init();
                }

                // Update the language
                Language.getCurrent().then(language => {
                    $translate.use(language);
                });
            }

            /* --------------------------------------------------
            *  callback for state change errors happen
            *--------------------------------------------------*/
            function onStateChangeError (event, toState, toStateParams, fromState, fromParams, error) {
                // this is used to log the error for debug purpose
            }

            /* --------------------------------------------------
            *  callback for successful state changes
            *--------------------------------------------------*/
            function onStateChangeSuccess (event, toState, toStateParams, fromState, fromParams) {
                let titleKey = 'global.title';

                $rootScope.previousStateName = fromState.name;
                $rootScope.previousStateParams = fromParams;

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }

                $translate(titleKey).then(title => {
                    // Change window title with translated one
                    $window.document.title = title;
                });

                // SessionStorageService only needs names, but BreadcrumbService needs other properties,
                // so getStatePath returns an object containing both.
                let pathData = getStatePath(toState);
                BreadcrumbService.onStateChangeSuccess(pathData.states);
                SessionStorageService.onStateChangeSuccess(pathData.names);
            }

            function init () {
                VbrLoggingDialogService.addDebug('vbr state change monitor service started');
                $rootScope.$on('$stateChangeStart', onStateChangeStart);
                $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);
            }

            return {
                init : init,
            };
        });
}());
