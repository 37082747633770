/**
 * Created by aditya.bhadoria on 01-07-2019.
 */

(function () {
    angular.module('acadiamasterApp')
        .service('HKDatatypesConstantsService', [ 'VbrTreeUtilService', function (VbrTreeUtilService) {
            return {
                categoryTypesOptions : [
                    { category_type       : 'Activity Measurements',
                        supported_datatypes : [
                            { displayText : 'Active Energy',	type : 'ACTIVE_ENERGY_BURNED' },
                            { displayText : 'Active Energy Goal',	type : 'ACTIVE_ENERGY_GOAL' },
                            { displayText : 'Resting Energy',	type : 'BASAL_ENERGY_BURNED' },
                            { displayText : 'Walking + Running Distance',	type : 'DISTANCE_WALKING_RUNNING' },
                            { displayText : 'Cycling Distance',	type : 'DISTANCE_CYCLING' },
                            { displayText : 'Wheelchair Distance',	type : 'DISTANCE_WHEEL_CHAIR' },
                            { displayText : 'Swimming Distance',	type : 'DISTANCE_SWIMMING' },
                            { displayText : 'Downhill Snow Sports Distance',	type : 'DISTANCE_DOWNHILL_SNOW_SPORTS' },
                            { displayText : 'Exercise Minutes',	type : 'EXERCISE_MINUTES' },
                            { displayText : 'Exercise Minutes Goal',	type : 'EXERCISE_MINUTES_GOAL' },
                            { displayText : 'NikeFuel',	type : 'NIKE_FUEL' },
                            { displayText : 'Flights Climbed',	type : 'FLIGHTS_CLIMBED' },
                            { displayText : 'Inhaler Usage',	type : 'INHALER_USAGE' },
                            { displayText : 'VO2 max',	type : 'VO2_MAX' },
                            { displayText : 'Insulin Delivery',	type : 'INSULIN_DELIVERY' },
                            { displayText : 'Number Of Times Fallen',	type : 'NUMBER_OF_TIMES_FALLEN' },
                            { displayText : 'Pushes (Wheelchair)',	type : 'PUSHES' },
                            { displayText : 'Mindfulness Minutes',	type : 'MINDFUL_SESSION' },
                            { displayText : 'Sleep Analysis',	type : 'SLEEP_ANALYSIS' },
                            { displayText : 'Steps',	type : 'STEP_COUNT' },
                            { displayText : 'Swimming Strokes',	type : 'SWIMMING_STROKES' },
                            { displayText : 'Stand Hours',	type : 'STAND_HOURS' },
                            { displayText : 'Stand Hours Goal',	type : 'STAND_HOURS_GOAL' },
                            { displayText : 'Workouts',	type : 'WORKOUTS' },
                            { displayText : 'Workout Route',	type : 'WORKOUT_ROUTE' },
                            { displayText : 'Audio Exposure Event',	type : 'AUDIO_EXPOSURE_EVENT' },
                            { displayText : 'Environmental Audio Exposure',	type : 'ENVIRONMENTAL_AUDIO_EXPOSURE' },
                            { displayText : 'Headphone Audio Exposure',	type : 'HEADPHONE_AUDIO_EXPOSURE' },
                            { displayText : 'Toothbrushing Event',	type : 'TOOTH_BRUSHING_EVENT' },
                            { displayText : 'Time spent standing',	type : 'TIME_SPENT_STANDING' },
                            { displayText : 'UV Exposure',	type : 'UV_EXPOSURE' },
                            { displayText : 'Apple Sleeping Wrist Temperature',	type : 'APPLE_SLEEPING_WRIST_TEMPERATURE' },
                            { displayText : 'Activity Move Mode',	type : 'ACTIVITY_MOVE_MODE' },
                            { displayText : 'Apple Move Time Goal',	type : 'APPLE_MOVE_TIME_GOAL' },
                            { displayText : 'Activity Summary Intraday',	type : 'ACTIVITY_SUMMARY_INTRADAY' },
                            { displayText : 'Heart Rate Summary Intraday',	type : 'HEART_RATE_SUMMARY_INTRADAY' },
                        ],
                    },

                    {
                        category_type       : 'Fertility Tracking',
                        supported_datatypes : [
                            { displayText : 'Ovulation Test Result',	type : 'OVULATION_TEST_RESULT' },
                            { displayText : 'Menstruation',	type : 'MENSTRUATION' },
                            { displayText : 'Spotting',	type : 'SPOTTING' },
                            { displayText : 'Cervical Mucus Quality',	type : 'CERVICAL_MUCUS_QUALITY' },
                            { displayText : 'Sexual Activity',	type : 'SEXUAL_ACTIVITY' },
                            { displayText : 'Basal Body Temperature',	type : 'BASAL_BODY_TEMPERATURE' },
                        ],
                    },

                    {
                        category_type       : 'Fitness',
                        supported_datatypes : [
                            { displayText : 'Apple Move Time',	type : 'APPLE_MOVE_TIME' },
                            { displayText : 'Cycling Cadence',	type : 'CYCLING_CADENCE' },
                            { displayText : 'Cycling Functional Threshold Power',	type : 'CYCLING_FUNCTIONAL_THRESHOLD_POWER' },
                            { displayText : 'Cycling Power',	type : 'CYCLING_POWER' },
                            { displayText : 'Cycling Speed',	type : 'CYCLING_SPEED' },
                            { displayText : 'Physical Effort',	type : 'PHYSICAL_EFFORT' },
                            { displayText : 'Running Power',	type : 'RUNNING_POWER' },
                            { displayText : 'Running Speed',	type : 'RUNNING_SPEED' },
                            { displayText : 'Under Water Depth',	type : 'UNDER_WATER_DEPTH' },
                        ],
                    },

                    {
                        category_type       : 'Hearing Health',
                        supported_datatypes : [
                            { displayText : 'Environmental Sound Reduction',	type : 'ENVIRONMENTAL_SOUND_REDUCTION' },
                            { displayText : 'Environmental Audio Exposure Event',	type : 'ENVIRONMENTAL_AUDIO_EXPOSUREEVENT' },
                            { displayText : 'Headphone Audio Exposure Event',	type : 'HEADPHONE_AUDIO_EXPOSURE_EVENT' },
                        ],
                    },

                    {
                        category_type       : 'Heart',
                        supported_datatypes : [
                            { displayText : 'Atrial Fibrillation Burden',	type : 'ATRIAL_FIBRILLATION_BURDEN' },
                            { displayText : 'Heart Rate Recovery One Minute',	type : 'HEART_RATE_RECOVERY_ONE_MINUTE' },
                            { displayText : 'Low Cardio Fitness Event',	type : 'LOW_CARDIO_FITNESS_EVENT' },
                        ],
                    },

                    {
                        category_type       : 'Mineral Tracking',
                        supported_datatypes : [
                            { displayText : 'Calcium',	type : 'DIETARY_CALCIUM' },
                            { displayText : 'Chromium',	type : 'DIETARY_CHROMIUM' },
                            { displayText : 'Copper',	type : 'DIETARY_COPPER' },
                            { displayText : 'Iodine',	type : 'DIETARY_IODINE' },
                            { displayText : 'Iron',	type : 'DIETARY_IRON' },
                            { displayText : 'Manganese',	type : 'DIETARY_MANGANESE' },
                            { displayText : 'Molybdenum',	type : 'DIETARY_MOLYBDENUM' },
                            { displayText : 'Phosphorus',	type : 'DIETARY_PHOSPHORUS' },
                            { displayText : 'Potassium',	type : 'DIETARY_POTASSIUM' },
                            { displayText : 'Magnesium',	type : 'DIETARY_MAGNESIUM' },
                            { displayText : 'Selenium',	type : 'DIETARY_SELENIUM' },
                            { displayText : 'Sodium',	type : 'DIETARY_SODIUM' },
                            { displayText : 'Sodium Chloride (Table Salt)',	type : 'DIETARY_CHLORIDE' },
                            { displayText : 'Zinc',	type : 'DIETARY_ZINC' },
                        ],
                    },

                    {
                        category_type       : 'Mobility',
                        supported_datatypes : [
                            { displayText : 'Running Ground Contact Time',	type : 'RUNNING_GROUND_CONTACT_TIME' },
                            { displayText : 'Running Stride Length',	type : 'RUNNING_STRIDE_LENGTH' },
                            { displayText : 'Running Vertical Oscillation',	type : 'RUNNING_VERTICAL_OSCILLATION' },
                            { displayText : 'Six Minute Walk Test Distance',	type : 'SIX_MINUTE_WALK_TEST_DISTANCE' },
                            { displayText : 'Stair Ascent Speed',	type : 'STAIR_ASCENT_SPEED' },
                            { displayText : 'Stair Descent Speed',	type : 'STAIR_DESCENT_SPEED' },
                            { displayText : 'Walking Asymmetry Percentage',	type : 'WALKING_ASYMMETRY_PERCENTAGE' },
                            { displayText : 'Walking Double Support Percentage',	type : 'WALKING_DOUBLE_SUPPORT_PERCENTAGE' },
                            { displayText : 'Walking Speed',	type : 'WALKING_SPEED' },
                            { displayText : 'Walking Step Length',	type : 'WALKING_STEP_LENGTH' },
                            { displayText : 'Number Of Alcoholic Beverages',	type : 'NUMBER_OF_ALCOHOLIC_BEVERAGES' },
                            { displayText : 'Time In Day Light',	type : 'TIME_IN_DAY_LIGHT' },
                            { displayText : 'Water Temperature',	type : 'WATER_TEMPERATURE' },
                            { displayText : 'Apple Walking Steadiness Event',	type : 'APPLE_WALKING_STEADINESS_EVENT' },
                        ],
                    },

                    {
                        category_type       : 'Nutrition Tracking',
                        supported_datatypes : [
                            { displayText : 'Caffeine',	type : 'DIETARY_CAFFEINE' },
                            { displayText : 'Carbohydrates',	type : 'DIETARY_CARBOHYDRATES' },
                            { displayText : 'Dietary Cholesterol',	type : 'DIETARY_CHOLESTEROL' },
                            { displayText : 'Total Fat',	type : 'DIETARY_FAT_TOTAL' },
                            { displayText : 'Monounsaturated Fat',	type : 'DIETARY_FAT_MONOUNSATURATED' },
                            { displayText : 'Polyunsaturated Fat',	type : 'DIETARY_FAT_POLYUNSATURATED' },
                            { displayText : 'Saturated Fat',	type : 'DIETARY_FAT_SATURATED' },
                            { displayText : 'Fiber',	type : 'DIETARY_FIBER' },
                            { displayText : 'Protein',	type : 'DIETARY_PROTEIN' },
                            { displayText : 'Dietary Sugar',	type : 'DIETARY_SUGAR' },
                            { displayText : 'Dietary Energy',	type : 'DIETARY_ENERGY_CONSUMED' },
                            { displayText : 'Water or Hydration',	type : 'DIETARY_WATER' },
                            { displayText : 'Food',	type : 'FOOD' },
                        ],
                    },

                    {
                        category_type       : 'Other',
                        supported_datatypes : [
                            { displayText : 'Hand Washing Event',	type : 'HAND_WASHING_EVENT' },
                        ],
                    },

                    {
                        category_type       : 'Physical Measurements',
                        supported_datatypes : [
                            { displayText : 'Height',	type : 'HEIGHT' },
                            { displayText : 'Weight ',	type : 'BODY_MASS' },
                            { displayText : 'Lean Body Mass (LBM)',	type : 'LEAN_BODY_MASS' },
                            { displayText : 'Birth Sex',	type : 'BIRTH_SEX' },
                            { displayText : 'Blood Type',	type : 'BLOOD_TYPE' },
                            { displayText : 'Date of Birth',	type : 'DATE_OF_BIRTH' },
                            { displayText : 'Fitzpatrick Skin Type',	type : 'FITZPATRICK_SKIN_TYPE' },
                            { displayText : 'Body Fat Percentage',	type : 'BODY_FAT_PERCENTAGE' },
                            { displayText : 'Waist Circumference',	type : 'WAIST_CIRCUMFERENCE' },
                            { displayText : 'Wheelchair',	type : 'WHEELCHAIR' },
                            { displayText : 'Body Mass Index (BMI) ',	type : 'BODY_MASS_INDEX' },
                        ],
                    },

                    {
                        category_type       : 'Reproductive Health',
                        supported_datatypes : [
                            { displayText : 'Contraceptive',	type : 'CONTRACEPTIVE' },
                            { displayText : 'Infrequent Menstrual Cycles',	type : 'INFREQUENT_MENSTRUAL_CYCLES' },
                            { displayText : 'Irregular Menstrual Cycles',	type : 'IRREGULAR_MENSTRUAL_CYCLES' },
                            { displayText : 'Lactation',	type : 'LACTATION' },
                            { displayText : 'Persistent Intermenstrual Bleeding',	type : 'PERSISTENT_INTERMENSTRUAL_BLEEDING' },
                            { displayText : 'Pregnancy',	type : 'PREGNANCY' },
                            { displayText : 'Pregnancy Test Result',	type : 'PREGNANCY_TEST_RESULT' },
                            { displayText : 'Progesterone Test Result',	type : 'PROGESTERONE_TEST_RESULT' },
                            { displayText : 'Prolonged Menstrual Periods',	type : 'PROLONGED_MENSTRUAL_PERIODS' },
                        ],
                    },

                    {
                        category_type       : 'Vital Measurements',
                        supported_datatypes : [
                            { displayText : 'Blood Alcohol Content (BAC)',	type : 'BLOOD_ALCOHOL_CONTENT' },
                            { displayText : 'Blood Glucose',	type : 'BLOOD_GLUCOSE' },
                            { displayText : 'Blood Pressure Diastolic',	type : 'BLOOD_PRESSURE_DIASTOLIC' },
                            { displayText : 'Blood Pressure Systolic',	type : 'BLOOD_PRESSURE_SYSTOLIC' },
                            { displayText : 'Body Temperature',	type : 'BODY_TEMPERATURE' },
                            { displayText : 'Electrodermal Activity (EDA)',	type : 'ELECTRO_DERMAL_ACTIVITY' },
                            { displayText : 'Oxygen Saturation (SpO2)',	type : 'OXYGEN_SATURATION' },
                            { displayText : 'Peak Expiratory Flow Rate (PEFR)',	type : 'PEAK_EXPIRATORY_FLOW_RATE' },
                            { displayText : 'Peripheral Perfusion Index (PPI) ',	type : 'PERIPHERAL_PERFUSION_INDEX' },
                            { displayText : 'Heart Rate (HR)',	type : 'HEART_RATE' },
                            { displayText : 'Respiratory Rate',	type : 'RESPIRATORY_RATE' },
                            { displayText : 'Blood Pressure',	type : 'BLOOD_PRESSURE' },
                            { displayText : 'Heart Rate Variability (HRV) ',	type : 'HEART_RATE_VARIABILITY' },
                            { displayText : 'Low Heart Rate Event',	type : 'LOW_HEART_RATE_EVENT' },
                            { displayText : 'High Heart Rate Event',	type : 'HIGH_HEART_RATE_EVENT' },
                            { displayText : 'Irregular Heart Rhythm Event',	type : 'IRREGULAR_HEART_RHYTHM_EVENT' },
                            { displayText : 'Resting Heart Rate (RHR) ',	type : 'RESTING_HEART_RATE' },
                            { displayText : 'Walking Heart Rate Average',	type : 'WALKING_HEART_RATE_AVERAGE' },
                            { displayText : 'Forced Vital Capacity (FVC) ',	type : 'FORCED_VITAL_CAPACITY' },
                            { displayText : 'Forced Expiratory Volume 1 (FEV1) ',	type : 'FORCED_EXPIRATORY_VOLUME' },
                            { displayText : 'Abdominal Cramps',	type : 'ABDOMINAL_CRAMPS' },
                            { displayText : 'Acne',	type : 'ACNE' },
                            { displayText : 'Appetite Changes',	type : 'APPETITE_CHANGES' },
                            { displayText : 'Bladder Incontinence',	type : 'BLADDER_INCONTINENCE' },
                            { displayText : 'Bloating',	type : 'BLOATING' },
                            { displayText : 'Breast Pain',	type : 'BREAST_PAIN' },
                            { displayText : 'Chest Tightness or Pain',	type : 'CHEST_TIGHTNESS_OR_PAIN' },
                            { displayText : 'Chills',	type : 'CHILLS' },
                            { displayText : 'Constipation',	type : 'CONSTIPATION' },
                            { displayText : 'Coughing',	type : 'COUGHING' },
                            { displayText : 'Diarrhea',	type : 'DIARRHEA' },
                            { displayText : 'Dizziness',	type : 'DIZZINESS' },
                            { displayText : 'Dry Skin',	type : 'DRY_SKIN' },
                            { displayText : 'Fainting',	type : 'FAINTING' },
                            { displayText : 'Generalized Body Acne',	type : 'GENERALIZED_BODY_ACHE' },
                            { displayText : 'Fever',	type : 'FEVER' },
                            { displayText : 'Fatigue',	type : 'FATIGUE' },
                            { displayText : 'Hair Loss',	type : 'HAIR_LOSS' },
                            { displayText : 'Headache',	type : 'HEADACHE' },
                            { displayText : 'Heartburn',	type : 'HEARTBURN' },
                            { displayText : 'Hot Flashes',	type : 'HOT_FLASHES' },
                            { displayText : 'Loss of Smell',	type : 'LOSS_OF_SMELL' },
                            { displayText : 'Loss of Taste',	type : 'LOSS_OF_TASTE' },
                            { displayText : 'Lower Back Pain',	type : 'LOWER_BACK_PAIN' },
                            { displayText : 'Memory Lapse',	type : 'MEMORY_LAPSE' },
                            { displayText : 'Mood Changes',	type : 'MOOD_CHANGES' },
                            { displayText : 'Nausea',	type : 'NAUSEA' },
                            { displayText : 'Night Sweats',	type : 'NIGHT_SWEATS' },
                            { displayText : 'Pelvic Pain',	type : 'PELVIC_PAIN' },
                            { displayText : 'Rapid Pounding or Fluttering Heartbeat',	type : 'RAPID_POUNDING_OR_FLUTTERING_HEARTBEAT' },
                            { displayText : 'Runny Nose',	type : 'RUNNY_NOSE' },
                            { displayText : 'Shortness of Breath',	type : 'SHORTNESS_OF_BREATH' },
                            { displayText : 'Sinus Congestion',	type : 'SINUS_CONGESTION' },
                            { displayText : 'Skipped Heartbeat',	type : 'SKIPPED_HEARTBEAT' },
                            { displayText : 'Sleep Changes',	type : 'SLEEP_CHANGES' },
                            { displayText : 'Sore Throat',	type : 'SORE_THROAT' },
                            { displayText : 'Vaginal Dryness',	type : 'VAGINAL_DRYNESS' },
                            { displayText : 'Vomiting',	type : 'VOMITING' },
                            // { displayText : 'Wheezing',	type : 'WHEEZING' },
                        ],
                    },

                    {
                        category_type       : 'Vitamin Tracking',
                        supported_datatypes : [
                            { displayText : 'Vitamin A (Retinoids)',	type : 'DIETARY_VITAMIN_A' },
                            { displayText : 'Vitamin B6 (Pyridoxine)',	type : 'DIETARY_VITAMIN_B6' },
                            { displayText : 'Vitamin B12 (Cobalamin or Cyanocobalamin)',	type : 'DIETARY_VITAMIN_B12' },
                            { displayText : 'Vitamin C (Ascorbic Acid)',	type : 'DIETARY_VITAMIN_C' },
                            { displayText : 'Vitamin D (Cholecalciferol)',	type : 'DIETARY_VITAMIN_D' },
                            { displayText : 'Vitamin E (Tocopherol)',	type : 'DIETARY_VITAMIN_E' },
                            { displayText : 'Vitamin K',	type : 'DIETARY_VITAMIN_K' },
                            { displayText : 'Vitamin B1 (Thiamin)',	type : 'DIETARY_THIAMIN' },
                            { displayText : 'Vitamin B2 (Riboflavin)',	type : 'DIETARY_RIBOFLAVIN' },
                            { displayText : 'Vitamin B3 (Niacin)',	type : 'DIETARY_NIACIN' },
                            { displayText : 'Vitamin B5 (Pantothenic Acid)',	type : 'DIETARY_PANTOTHENIC_ACID' },
                            { displayText : 'Vitamin B9 (Folate)',	type : 'DIETARY_FOLATE' },
                            { displayText : 'Vitamin H (Biotin)',	type : 'DIETARY_BIOTIN' },
                        ],
                    },
                ],
                ehrCategoryOptions : [
                    { category_type       : 'Clinical Record',
                        supported_datatypes : [
                            { displayText : 'Allergy Record',	type : 'ALLERGY_RECORD' },
                            { displayText : 'Condition Record',	type : 'CONDITION_RECORD' },
                            { displayText : 'Immunization Record',	type : 'IMMUNIZATION_RECORD' },
                            { displayText : 'Lab Result Record',	type : 'LAB_RESULT_RECORD' },
                            { displayText : 'Medication Record',	type : 'MEDICATION_RECORD' },
                            { displayText : 'Procedure Record',	type : 'PROCEDURE_RECORD' },
                            { displayText : 'Vital Sign Record',	type : 'VITAL_SIGN_RECORD' },
                        ],
                    },
                ],
                samsungHealthCategoryOptions : [
                    { category_type       : 'Activity Measurements',
                        supported_datatypes : [
                            { displayText : 'Exercise', type : 'EXERCISE' },
                            { displayText : 'Flights Climbed', type : 'FLIGHTS_CLIMBED' },
                            { displayText : 'Sleep Event', type : 'SLEEP_EVENT' },
                            { displayText : 'Sleep Phase Event', type : 'SLEEP_PHASE_EVENT' },
                            { displayText : 'Steps',	type : 'STEP_COUNT' },
                            { displayText : 'Daily Step Count Trend',	type : 'DAILY_STEP_COUNT' },
                            { displayText : 'Ambient Temperature Event',	type : 'AMBIENT_TEMPERATURE_EVENT' },
                            { displayText : 'UV Exposure', type : 'UV_EXPOSURE' },
                        ],
                    },

                    {
                        category_type       : 'Clinical Record',
                        supported_datatypes : [
                            { displayText : 'Hemoglobin A1C Test Result',	type : 'HEMOGLOBIN_A1C_TEST_RESULT' },
                            { displayText : 'Albumin Test Result', type : 'ALBUMIN_TEST_RESULT' },
                            { displayText : 'Alkaline Phosphatase (ALP) Test Result',	type : 'ALP_TEST_RESULT' },
                            { displayText : 'Alanine Aminotransferase (ALT) Test Result',	type : 'ALT_TEST_RESULT' },
                            { displayText : 'Amylase Test Result', type : 'AMYLASE_TEST_RESULT' },
                            { displayText : 'Aspartate Aminotransferase (AST) Test Result', type : 'AST_TEST_RESULT' },
                            { displayText : 'Blood Urea Nitrogen (BUN) Test Result',	type : 'BUN_TEST_RESULT' },
                            { displayText : 'Creatine Phosphokinase (CPK) Test Result', type : 'CPK_TEST_RESULT' },
                            { displayText : 'Creatinine Test Result',	type : 'CREATININE_TEST_RESULT' },
                            { displayText : 'Conjugated Billirubin Test Result',	type : 'CONJUGATED_TEST_RESULT' },
                            { displayText : 'Gamma-Glutamyl Transferase (GGT) Test Result', type : 'GGT_TEST_RESULT' },
                            { displayText : 'Gamma Globulin Test Result',	type : 'GAMMA_TEST_RESULT' },
                            { displayText : 'HDL Cholesterol Test Result', type : 'HDL_TEST_RESULT' },
                            { displayText : 'Homocysteine Test Result', type : 'HOMOCYSTEINE_TEST_RESULT' },
                            { displayText : 'Lactate Dehydrogenase (LDH) Test Result', type : 'LDH_TEST_RESULT' },
                            { displayText : 'LDL Cholesterol Test Result', type : 'LDL_TEST_RESULT' },
                            { displayText : 'Total Billirubin Test Result', type : 'TOTAL_BILLIRUBIN_TEST_RESULT' },
                            { displayText : 'Total Cholesterol Test Result', type : 'TOTAL_CHOLESTEROL_TEST_RESULT' },
                            { displayText : 'Total Protein Test Result',	type : 'TOTAL_PROTEIN_TEST_RESULT' },
                            { displayText : 'Triglyceride Test Result', type : 'TRIGLYCERIDE_TEST_RESULT' },
                            { displayText : 'Urinary pH (UPH) Test Result', type : 'UPH_TEST_RESULT' },
                            { displayText : 'Ultrasound or Ultrasonography (USG) Test Result', type : 'USG_TEST_RESULT' },
                        ],
                    },

                    {
                        category_type       : 'Nutrition Tracking',
                        supported_datatypes : [
                            { displayText : 'Nutrition',	type : 'NUTRITION' },
                            { displayText : 'Caffeine',	type : 'DIETARY_CAFFEINE' },
                            { displayText : 'Water or Hydration',	type : 'DIETARY_WATER' },
                        ],
                    },

                    {
                        category_type       : 'Physical Measurements',
                        supported_datatypes : [
                            { displayText : 'User Profile',	type : 'USER_PROFILE' },
                            { displayText : 'Height',	type : 'HEIGHT' },
                            { displayText : 'Weight',	type : 'BODY_MASS' },
                            { displayText : 'Body Fat Percentage', type : 'BODY_FAT_PERCENTAGE' },
                            { displayText : 'Body Muscle Percentage',	type : 'BODY_MUSCLE_PERCENTAGE' },
                            { displayText : 'Hip Circumference',	type : 'HIP_CIRCUMFERENCE' },
                            { displayText : 'Waist Circumference', type : 'WAIST_CIRCUMFERENCE' },
                        ],
                    },

                    {
                        category_type       : 'Vital Measurements',
                        supported_datatypes : [
                            { displayText : 'Blood Glucose',	type : 'BLOOD_GLUCOSE' },
                            { displayText : 'Blood Pressure',	type : 'BLOOD_PRESSURE' },
                            { displayText : 'Body Temperature',	type : 'BODY_TEMPERATURE' },
                            { displayText : 'Electrocardiogram (EKG/ECG)',	type : 'ELECTROCARDIOGRAM' },
                            { displayText : 'Oxygen Saturation (SpO2)',	type : 'OXYGEN_SATURATION' },
                            { displayText : 'Heart Rate (HR)',	type : 'HEART_RATE' },
                            { displayText : 'Forced Vital Capacity (FVC)',	type : 'FORCED_VITAL_CAPACITY' },
                            { displayText : 'Forced Expiratory Volume 1 (FEV1)',	type : 'FORCED_EXPIRATORY_VOLUME' },
                        ],
                    },
                ],
            };
        } ]);
}());

