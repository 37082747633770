/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('ruleElementConfigTrigger', function (RuleUtilService, vbrCommonUtil) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/businessRule/configPanel/trigger/elementConfig.trigger.html',
                scope: {
                    element: '=',
                    parentForm: '='
                },
                link: function ($scope) {
                    $scope.cs = RuleUtilService.ruleConstants;
                    $scope.vcu = vbrCommonUtil;
                    $scope.constants = RuleUtilService.formConstant;
                }
            };
        });

})();

