
/**
 * directive that checks if test suite name is available, must be used together with ng-model
 */
(function() {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('vbrTestSuiteAvailable', function (ProgramTestSuiteService, $q) {

            function isNameAvailable(name,currentId) {
                var promise = ProgramTestSuiteService.isNameAvailable(name,currentId);
                return promise.then(function (response) {
                    if (!response.data.isValid) {
                        return $q.reject("Name is already taken");
                    }
                    else {
                        return true;
                    }
                });
            }

            return {
                require: 'ngModel',
                scope: {
                   currentId: '=vbrTestSuiteAvailable'
                },
                link: function (scope, elm, attr, model) {
                    model.$asyncValidators.vbrTestSuiteAvailable = function (modelValue) {
                        return isNameAvailable(modelValue,scope.currentId);
                    };
                }
            }
        });

})();