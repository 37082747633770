/* eslint-disable eqeqeq */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 2/15/17
 */
(function () {
    angular.module('acadiamasterApp')
    /*
     * directive for configuration of reference ids
     */
        .directive('formConfigReferenceId', (FormUtilService, FormConstants) => {
            return {
                link : function ($scope) {
                    $scope.hasChangeModel = $scope.localIdPropertyChange !== undefined;

                    $scope.data = {
                        makingChange      : $scope.hasChangeModel && $scope.localIdPropertyChange != null,
                        // need to wrap those in an object for ng-model to work properly
                        selectedReference : '',

                        selectedReferenceChange : '',
                    };

                    addWatches($scope);

                    $scope.resetChange = function () {
                        $scope.data.makingChange = false;
                        $scope.localIdPropertyChange = null;
                        $scope.data.selectedReferenceChange = null;
                    };

                    $scope.startChange = function () {
                        $scope.data.makingChange = true;
                    };

                    $scope.getReferenceIdAsString = function () {
                        if ($scope.localIdProperty != null) {
                            return `${ $scope.localIdProperty}`;
                        }

                        return 'none';
                    };

                    $scope.calculateTargetItems = function () {
                        // Using an array instead of map because uib-typeahead does not work without an array
                        $scope.availableTargets = [];
                        let targetMap = $scope.mode.lookupMap[$scope.targetType.name];

                        for (let key in targetMap) {
                            if (targetMap.hasOwnProperty(key)) {
                                let object = targetMap[key];
                                object.shortDescription = object.getShortDescription($scope.targetType.name == FormConstants.formTargetType.FORM_FIELD_UNVERSIONED.name);
                                // This is always local id except for unversioned id case
                                object.referencingId = key;
                                if (key == $scope.localIdProperty) {
                                    $scope.data.selectedReference = object;
                                }
                                if (key == $scope.localIdPropertyChange) {
                                    $scope.data.selectedReferenceChange = object;
                                }
                                $scope.availableTargets.push(object);
                            }
                        }

                        if (_.isFunction($scope.filterFunction)) {
                            // If filter function is passed, send the available targets for filtering.
                            // Returned list of items are now filtered available targets
                            $scope.availableTargets = $scope.filterFunction({ listToFilterFrom : $scope.availableTargets });
                        }

                        // Any sections, components, fields, sub-fields on address to be filtered out
                        $scope.availableTargets = FormUtilService.filterOutAddressPageItems($scope.availableTargets);
                    };

                    $scope.clearSelection = function () {
                        if ($scope.hasChangeModel) {
                            $scope.localIdPropertyChange = null;
                            $scope.data.selectedReferenceChange = '';
                        } else {
                            $scope.localIdProperty = null;
                            $scope.data.selectedReference = '';
                        }
                    };

                    $scope.calculateTargetItems();
                },
                restrict : 'E',
                scope    : {
                    // Pass in a function that takes list of items and returns a list of items matching the filter
                    filterFunction : '&?',

                    localIdProperty : '=',

                    localIdPropertyChange : '=',

                    mode : '=',

                    noBox : '=',

                    noTitle : '=',

                    targetType : '=',

                    title : '@',
                },
                templateUrl : 'admin-templates/site/forms/configPanel/common/referenceIdConfig.html',
            };

            /* *************************************************************
             * private functions
             ***************************************************************/
            function addWatches ($scope) {
                $scope.$watch('data.selectedReference', newValue => {
                    if (newValue.referencingId != null) {
                        if ($scope.targetType.name === FormConstants.formTargetType.FORM_FIELD_UNVERSIONED.name) {
                            $scope.localIdProperty = newValue.formField.id;
                        } else {
                            $scope.localIdProperty = newValue.localId;
                        }
                    }
                    $scope.calculateTargetItems();
                });

                $scope.$watch('data.selectedReferenceChange', newValue => {
                    if (newValue.referencingId != null) {
                        $scope.localIdPropertyChange = newValue.referencingId;
                    }
                });

                $scope.$watch('targetType', () => {
                    $scope.calculateTargetItems();
                });
            }
        });
}());
