/**
 * drc sync user directive
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('vbrDrcUserSyncUpdate', function (UserSyncService, ParseLinks) {

        /***************************************
         * factory return call
         ***************************************/

        return {
            restrict: 'E',
            templateUrl: "admin-templates/site/drc/userSync/drc.userSyncUpdate.html",
            scope: {},
            link: function ($scope) {
                $scope.users = [];
                $scope.page = 0;

                $scope.searchData = {
                    pageSizeChoices: [20, 50, 100],
                    pageSizeSelected: 20,
                };

                $scope.loadAll = loadUsers;

                $scope.loadPage = function (page) {
                    $scope.page = page;
                    $scope.loadAll();
                };
                $scope.loadAll();

                $scope.refresh = function () {
                    $scope.loadAll();
                };

                /***************************************************************************
                 * private functions
                 ***************************************************************************/
                function loadUsers() {
                    UserSyncService.query({
                        page: $scope.page,
                        size: $scope.searchData.pageSizeSelected,
                    }).then(function (response) {
                        $scope.links = ParseLinks.parse(response.headers('link'));
                        $scope.users = response.data;
                        $scope.searchData.searchResultCount = response.headers('X-Total-Count');
                    });
                }
            }
        };


    });

})();

