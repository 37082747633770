/**
 * Created by Jamie Nola on 02/19/2020
 *
 * directive for managing deep links on the program or system level
 */
import template from './deepLinks.template.html';

angular.module('acadiamasterApp')
    .directive('deepLinks', function() {
        return {
            bindToController: true,
            controller: 'DeepLinkMgmtController',
            controllerAs: 'dlc',
            restrict: 'E',
            scope: {
                entity: '<?',
            },
            template
        }
    });
