/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an display config for an item after it is completed
 * this model include
 * 1. a title
 * 2. a message
 *
 * -- the format of those strings will be the same as string resource
 */
angular.module('acadiamasterApp')
    .factory('ContainerItemCompletionStateConfig', LocalizedStringModel => {
        /**
         * @constructor
         */
        function ContainerItemCompletionStateConfig() {
            // remove after complete flag
            this.removeAfterComplete = true;

            // delay in seconds
            this.removalDelayInterval = 5;

            // both title and message are using the same format as string resource
            this.title = new LocalizedStringModel();
            this.message = new LocalizedStringModel();
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerItemCompletionStateConfig.prototype.toDto = function () {
            const dto = {};

            // simple properties
            dto.removeAfterComplete = this.removeAfterComplete;
            dto.removalDelayInterval = dto.removeAfterComplete? this.removalDelayInterval : null;

            // localized labels
            dto.title = this.title.toDto();
            dto.message = this.message.toDto();

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerItemCompletionStateConfig.prototype.fromDto = function (dto) {
            if (dto==null) {
                return;
            }

            this.removeAfterComplete = dto.removeAfterComplete;
            this.removalDelayInterval = dto.removalDelayInterval;

            this.title.fromDto(dto.title);
            this.message.fromDto(dto.message);
        };

        /**
         * This converts the dto object into current object.
         * It only keeps the properties that are necessary for the action container item.
         * @param dto - ContainerItemCompletionStateConfig object
         */
        ContainerItemCompletionStateConfig.prototype.actionItemFromDto = function (dto) {
            if (!dto) {
                return;
            }

            this.removeAfterComplete = dto.removeAfterComplete;
            this.removalDelayInterval = dto.removalDelayInterval;
        };

        /** *************************************
         * service return call
         ************************************** */

        return ContainerItemCompletionStateConfig;
    });
