(function () {
    'use strict';

    angular.module('acadiamasterApp').controller('ProgramTestController', function ($scope, CONFIG, $state, ngDialog) {
            $scope.CONFIG = CONFIG;
        $scope.programSelection = function () {
            ngDialog.openConfirm({
                template: 'admin-templates/site/common/directive/programSelectionPopup.html',
                controller: 'ProgramSelectionPopupController',
                data: {
                    description: 'Choose the program for which you want to create a program test'
                }
            }).then(function (programId) {
                $state.go('programTests.pstCase.new', {'programId': programId});
            });
        };
    });
})();