/**
 * Created by Danny on 10/28/2015.
 */
'use strict';

angular.module('acadiamasterApp')
    .factory('DataTypeMaster', function () {
        return {};
    })
    .factory('DataTypeMasterRecordCount', function ($http,localWeb) {
        var counts = null;
        var states = {
            ready : 1,
            loading : 2,
            error : 3,
            uninitialized : 4
        };
        var state = states.uninitialized;

        function loadCounts() {

            state = states.loading;
            return $http({
                method: 'GET',
                url: localWeb.createUrl('/api/dataTypeMaster/dataTypeMasterCount/')
            }).then(function (response) {
                counts = response.data;
                state = states.ready;
                return counts;
            }, function(response) {
                state = states.error;
                return response;
            });

        }

        return {
            /**
             * initialization function that will load all the properties from server
             */
            init : function () {
                return loadCounts();
            },

            /**
             * this will trigger an reload call from server
             */
            updateCounts : function() {
                return loadCounts();
            },

            isCountReady : function () {
                return state==states.ready;
            },

            getCounts : function() {
                return counts;
            }
        };

    })
    .factory('PropertyMappingService', function ($http,localWeb) {
        var url = localWeb.createUrl('api/dataTypeMaster/propertyMappings/');
        var mappingsList = null;
        var states = {
            ready : 1,
            loading : 2,
            error : 3,
            uninitialized : 4
        };
        var state = states.uninitialized;

        function loadMappings(finalUrl) {
            state = states.loading;
            return $http({
                method: 'GET',
                url: finalUrl
            }).then(function (response) {
                mappingsList = response.data;
                state = states.ready;
                return mappingsList;
            }, function(response) {
                state = states.error;
                console.log(response);
                return response;
            });

        }

        return {
            'get' : function(entityName, entityId) {
                console.log('*****', entityName);
                console.log('*****', entityId);
                var finalUrl = url + entityName;
                if (entityId!=null) {
                    finalUrl += "?entityId=" + entityId;
                    return loadMappings(finalUrl);
                } else {
                    return null;
                }

            }
        };
    })
    .factory('DataTypeMasterUsage', function ($http,localWeb) {
        var url = localWeb.createUrl('api/dataTypeMaster/usage/');
        var usageList = null;
        var states = {
            ready : 1,
            loading : 2,
            error : 3,
            uninitialized : 4
        };
        var state = states.uninitialized;

        function loadUsages(finalUrl) {
            state = states.loading;
            return $http({
                method: 'GET',
                url: finalUrl
            }).then(function (response) {
                usageList = response.data;
                state = states.ready;
                return usageList;
            }, function(response) {
                state = states.error;
                console.log(response);
                return response;
            });

        }

        return {
            'get' : function(entityName, entityId) {
                var finalUrl = url + entityName;
                if (entityId!=null) {
                    finalUrl += "?entityId=" + entityId;
                    return loadUsages(finalUrl);
                } else {
                    return null;
                }

            }
        };
    })
    .factory('SearchWithFilter', function ($http,localWeb) {
        var url = localWeb.createUrl('api/dataTypeMaster/filter?');
        return {
            'query': function(searchFilter) {
                return $http({
                    url: url + $.param(searchFilter),
                    method: 'GET',
                }).then(function(response){
                    return response;
                }, function(error){
                    console.log(error);
                    return error
                });
            }
        }
    });
