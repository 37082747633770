/* eslint-disable no-use-before-define */
/**
 * Created by Jamie Nola on 08/07/2019
 *
 * service for all the constants necessary for models used in controller management
 */
const basePackageDisplayConfig = 'com.vibrent.acadia.web.rest.dto.container.displayconfig';
const basePackageContainerItem = 'com.vibrent.acadia.web.rest.dto.container.containeritem';
const subPageContainerType = {
    actionTarget : 'SUBPAGE',
    text         : 'SubPage',
};
const dataReferenceRORContainerType = {
    text : 'Return of Results - Data Reference',
};

const classes = {
    classReferenceKey : '@class',

    ContainerContentDisplayConfigImpl : `${basePackageDisplayConfig}.ContainerContentDisplayConfigImpl`,

    ContainerDataReferenceRORDisplayConfigImpl : `${basePackageDisplayConfig}.ContainerDataReferenceRORDisplayConfigImpl`,

    ContainerItemAppointmentConfig : `${basePackageContainerItem}.form.ContainerItemAppointmentConfig`,

    ContainerItemContentConfig : `${basePackageContainerItem}.form.ContainerItemContentConfig`,

    ContainerItemExternalIntegrationConfig : `${basePackageContainerItem}.form.ContainerItemExternalIntegrationConfig`,

    ContainerItemFormConfig : `${basePackageContainerItem}.form.ContainerItemFormConfig`,

    ContainerMultiFormDisplayConfigImpl : `${basePackageDisplayConfig}.ContainerMultiFormDisplayConfigImpl`,

    ContainerMultiFormRoRDisplayConfigImpl  : `${basePackageDisplayConfig}.ContainerMultiFormRoRDisplayConfigImpl`,
    // -- top level container display config classes
    ContainerMultipleItemsDisplayConfigImpl : `${basePackageDisplayConfig}.ContainerMultipleItemsDisplayConfigImpl`,
    ContainerSingleItemDisplayConfigImpl    : `${basePackageDisplayConfig}.ContainerSingleItemDisplayConfigImpl`,
};

// this is needed because we want to build tabs for different
// configuration sections for display configuration
// in the future, we can add icons and other properties here
// to make the UI more attractive and user-friendly
const displayConfigStates = {
    contentState : {
        purpose : 'Used for rendering the content container',
        title   : 'Content State',
    },
    lockPreviewState : {
        purpose : 'Used for the preview of a locked container',
        title   : 'Lock Preview State',
    },
    multiItemAllCompletedState : {
        purpose : 'Used for rendering the container when every item in the container has been completed (if the container is empty, '
            + 'then the empty state configuration will be used instead of this)',
        title : 'Everything Completed Container State',
    },
    multiItemCompletedItemState : {
        purpose : 'Used for rendering the container item after it has been completed',
        title   : 'Completed Item State',
    },
    multiItemEmptyState : {
        purpose : 'Used for rendering the container when it is empty',
        title   : 'Empty Container State',
    },
    nonCompletedItemState : {
        purpose : 'Used for rendering the container item before it has been completed',
        title   : 'Non Completed Item State',
    },
    singleItemAfterFormCompletionState : {
        purpose : 'Used for rendering the container after the form associated '
            + 'to the container has been completed at least once',
        title : 'After Form Completion Container State',
    },
    singleItemEmptyState : {
        purpose : 'Used for controlling the rendering of the container when it is empty',
        title   : 'Empty Container State',
    },
    singleItemInitialState : {
        purpose : 'Used for rendering the container before the form associated '
            + 'to the container is completed',
        title : 'Initial Container State',
    },
};

const sections = {
    actionAssociation        : 'ACTION_ASSOCIATION',
    appointmentAssociation   : 'APPOINTMENT_ASSOCIATION',
    containerBehavior        : 'CONTAINER_BEHAVIOR',
    containerReferences      : 'CONTAINER_REFERENCES',
    formAssociations         : 'FORM_ASSOCIATIONS',
    hybridAssociations       : 'HYBRID_ASSOCIATIONS',
    viewSharedEHRAssociation : 'EHR_ASSOCIATION',
};

const containerTypeNames = {
    appointments          : 'APPOINTMENTS',
    content               : 'CONTENT',
    dataReferenceROR      : 'DATA_REFERENCE_RETURN_OF_RESULTS',
    measurements          : 'MEASUREMENTS',
    multiForm             : 'MULTI_FORM',
    multiFormROR          : 'MULTI_FORM_RETURN_OF_RESULTS',
    singleForm            : 'SINGLE_FORM',
    singleQuestionForm    : 'SINGLE_QUESTION_FORM',
    singleQuestionFormROR : 'SINGLE_QUESTION_FORM_RETURN_OF_RESULTS',
    subPage               : 'DATA_RETURN_OF_RESULTS',
};

const containerTypes = [
    // Dashboard tab
    {
        appTypeSupportedLevel : {
            containerItem : true,
        },
        canAddItem          : true,
        canVerticalStack    : true,
        displayConfigClass  : classes.ContainerMultiFormDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
            displayConfigStates.multiItemAllCompletedState,
            displayConfigStates.multiItemCompletedItemState,
            displayConfigStates.lockPreviewState,
        ],
        enabledFlags : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : true,
        name                               : containerTypeNames.multiForm,
        optionalHeader                     : true,
        sections                           : [ sections.formAssociations, sections.actionAssociation, sections.viewSharedEHRAssociation ],
        text                               : 'Multi-Form Container',
    },
    {
        canAddItem          : true,
        displayConfigClass  : classes.ContainerSingleItemDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.singleItemEmptyState,
            displayConfigStates.singleItemInitialState,
            displayConfigStates.singleItemAfterFormCompletionState,
            displayConfigStates.lockPreviewState,
        ],
        enabledFlags : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : false,
        maxNumberOfChild                   : 1,
        name                               : containerTypeNames.singleForm,
        sections                           : [ sections.formAssociations ],
        text                               : 'Single-Form Container',
    },
    {
        canAddItem          : true,
        displayConfigClass  : classes.ContainerMultipleItemsDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
            displayConfigStates.lockPreviewState,
        ],
        enabledFlags : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.singleQuestionForm,
        sections                           : [ sections.formAssociations ],
        text                               : 'Single-Question-Form Container',
    },
    // My Data tab
    {
        displayConfigClass  : classes.ContainerMultiFormRoRDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
            displayConfigStates.nonCompletedItemState,
            displayConfigStates.lockPreviewState,
        ],
        enabledFlags : {
            unlocked : false,
            visible  : false,
        },
        hideFormCount                      : true,
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.multiFormROR,
        sections                           : [ sections.containerReferences ],
        text                               : 'Multi-Form Return of Results',
    },
    {
        displayConfigClass  : classes.ContainerMultipleItemsDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
            displayConfigStates.lockPreviewState,
        ],
        enabledFlags : {
            unlocked : false,
            visible  : false,
        },
        hideFormCount                      : true,
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.singleQuestionFormROR,
        sections                           : [ sections.containerReferences ],
        text                               : 'Single-Question Return of Results',
    },
    {
        displayConfigClass : null,
        enabledFlags       : {
            unlocked : true,
            visible  : true,
        },
        hideFormCount                      : true,
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.measurements,
        text                               : 'Measurements',
    },
    {
        appTypeSupportedLevel : {
            container     : true,
            containerItem : true,
        },
        displayConfigClass  : classes.ContainerContentDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.contentState,
        ],
        enabledFlags : {
            imageDisabled : true,
            unlocked      : false,
            visible       : true,
        },
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.content,
        optionalHeader                     : true,
        sections                           : [ sections.containerBehavior ],
        text                               : 'Content',
    },
    {
        appTypeSupportedLevel : {
            container     : true,
            containerItem : true,
        },
        canAddItem         : true,
        displayConfigClass : classes.ContainerMultipleItemsDisplayConfigImpl,
        enabledFlags       : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.subPage,
        sections                           : [ sections.hybridAssociations ],
        text                               : subPageContainerType.text,
    },
    {
        canAddItem          : true,
        displayConfigClass  : classes.ContainerMultipleItemsDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
        ],
        enabledFlags : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.appointments,
        sections                           : [ sections.appointmentAssociation ],
        text                               : 'Appointments',
    },
    // Filtered ROR Form Entry
    {
        appTypeSupportedLevel : {
            containerItem : true,
        },
        canAddItem          : false,
        canVerticalStack    : false,
        displayConfigClass  : classes.ContainerMultipleItemsDisplayConfigImpl,
        displayConfigStates : [
            displayConfigStates.multiItemEmptyState,
        ],
        enabledFlags : {
            unlocked : true,
            visible  : true,
        },
        isItemCompletionOverwriteSupported : false,
        name                               : containerTypeNames.dataReferenceROR,
        optionalHeader                     : true,
        sections                           : [ sections.hybridAssociations ],
        text                               : dataReferenceRORContainerType.text,
    },
];

// this tab is used for container drafts and is never sent to the server.
const defaultTab = {
    id   : null,
    name : 'DRAFT',
};

const nodeType = {
    CONTAINER      : 'container',
    CONTAINER_ITEM : 'containerItem',
};

const containerType = {
    MULTI_FORM  : 'MULTI_FORM',
    SINGLE_FORM : 'SINGLE_FORM',
};

const emptyStateImageDimensions = {
    appointmentsHeight : 572,
    appointmentsWidth  : 590,
    defaultHeight      : 411,
    defaultWidth       : 2784,
};

const containerItemLinkedEntityType = {
    AGREEMENT : 'AGREEMENT',
    FORM      : 'FORM',
};

const platforms = [ 'web', 'mobile' ];

const platformsObj = {
    mobile : 'MOBILE',
    web    : 'WEB',
};

const formDataTypes = [
    'CONSENT_FORM',
    'DATA_AND_CONSENT_FORM',
    'DATA_FORM',
    'SNAP_QUESTION_FORM',
];

const inclusionExclusionTypes = [
    'INCLUDE',
    'EXCLUDE',
];

/*
 * Check whether container type supports appType supported (ios/web/android) either on container or container item level
 * @param typeName container type
 * @param shouldUseContainerLvl true for checking on container level.  False for checking container item level.
 * @returns {boolean|boolean|*} true if container type supports appType (ios/web/android)
 */
function hasAppTypeSupportedByLvl (typeName, shouldUseContainerLvl) {
    const type = findTypeByName(typeName);
    if (!type) {
        return false;
    }
    if (type.appTypeSupportedLevel) {
        return shouldUseContainerLvl ? type.appTypeSupportedLevel.container
            : type.appTypeSupportedLevel.containerItem;
    }
    return false;
}

/**
 * True if external data source is fhir base. Otherwise, return false
 * @param externalDataSource external data source
 * @returns {boolean} true if external data source is fhir base
 */
function hasFHIRBaseExternalSource (externalDataSource) {
    return externalDataSource === 'FHIR_FORM_ENTRY_LATEST' || externalDataSource === 'FHIR_FORM_ENTRY_ALL';
}

function findTypeByName (typeName) {
    if (!typeName) {
        return null;
    }

    return containerTypes.find(type => type.name === typeName);
}

export default {
    classes,
    containerItemLinkedEntityType,
    containerType,
    containerTypeNames,
    containerTypes,
    dataReferenceRORContainerType,
    defaultTab,
    displayConfigStates,
    emptyStateImageDimensions,
    findTypeByName,
    formDataTypes,
    hasAppTypeSupportedByLvl,
    hasFHIRBaseExternalSource,
    inclusionExclusionTypes,
    nodeType,
    platforms,
    platformsObj,
    sections,
    subPageContainerType,
};
