(function () {
    angular.module('acadiamasterApp').directive('formPagination', function () {
        return {
            restrict: 'E', templateUrl: 'admin-templates/site/forms/formVersionTwo/formPagination/formPagination.html',
            scope: { page: '=', filterData: '=', links: '=', loadPage: '=loadPage' },
            controller: 'formPaginationController',
        };
    })
    .controller('formPaginationController', function ($scope, FormVersionTwoUtilityService) {
        $scope.lastPage = 0;
        $scope.totalPage = function() {
            var pages = Math.ceil($scope.filterData.searchResultCount / $scope.filterData.pageSizeSelected);
            var total = pages === 0 ? 1 : pages
            $scope.lastPage = total;
            return total;
        }
        $scope.pageSizeChange = function(pageSize) {
            $scope.filterData.jumpTo = null;
            $scope.loadPage($scope.page, pageSize, 0);
        }
        $scope.jumpToChange = function(jumpNumber) {
            validate(jumpNumber);
            if (jumpNumber && jumpNumber >= 1 && jumpNumber <= $scope.lastPage && typeof jumpNumber === 'number') {
                $scope.loadPage($scope.page, $scope.filterData.pageSizeSelected, jumpNumber - 1);
            }
        }
        $scope.showOptionPagination = function(event, element) {
            FormVersionTwoUtilityService.toggleDropdown(event, element);
        }
        function validate(jumpNumber) {
            var jumToInput = document.querySelectorAll('[ng-model="filterData.jumpTo"]');
            if (jumpNumber === null || (jumpNumber && jumpNumber >= 1 && jumpNumber <= $scope.lastPage && typeof jumpNumber === 'number')) {
                jumToInput.forEach(input => { input.style.borderColor = '#c4cdd5' });
            } else {
                jumToInput.forEach(input => { input.style.borderColor = '#f00' });
            }
        }
    });
})();
