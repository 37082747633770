(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for QObject model, this is base of all query object
     */
    app.factory('QObjectModel', function (ModelServiceBase, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        QObjectModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

        function QObjectModel(parent, type) {
            ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);
            this.nodeType = ProgramTestConstantsService.nodeType.IF_ASSERT_CONDITION;
            this.type = type;
        }

        /**
         * convert the current UI model to dto format
         */
        QObjectModel.prototype.toDto = function () {
            var dto = ModelServiceBase.BaseTreeNodeModel.prototype.toDto.call(this);
            dto.type = this.type.name;
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        QObjectModel.prototype.fromDto = function (dto) {
            ModelServiceBase.BaseTreeNodeModel.prototype.fromDto.call(this, dto);
            this.type = ProgramTestConstantsService.getObjectByName(ProgramTestConstantsService.QObjectType, dto.type);
        };

        QObjectModel.prototype.getDescriptionAsHtml = function () {
            return '<span data-nodrag class="badge badge-success">' + this.type.name + '</span>';
        };

        QObjectModel.prototype.getParentExpressionCondition = function () {
            if(this._parent){
                if(this._parent instanceof QObjectModel){
                    return this._parent.getParentExpressionCondition();
                }
                return this._parent;
            }
            return null;
        };

        QObjectModel.prototype.getDescriptionAsHtmlFullTree = function() {
            if (this.type != ProgramTestConstantsService.QObjectType.GROUP_OBJECT) {
                return this.getDescriptionAsHtml();
            }

            return '';
        };

        /***************************************
         * service return call
         ***************************************/
        return QObjectModel;

    });
})();
