(function () {
    'use strict';

    angular.module('acadiamasterApp')

        /**
         * directive for Metrix Questionnaire Component
         */
        .directive('formComponentMetrixQuestionnaire', function (FormUtilService, FormConstants, LocaleConstantsService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/component/metrixQuestionnaireComponent.html',
                scope: {
                    fieldValue: '=',
                    fieldValueChange: '=',
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.hasChangeModel = $scope.fieldValueChange != null;
                    if ($scope.hasChangeModel) {
                        initChangeModelValuesIfNeeded($scope);
                    }

                    $scope.addNewOption = function (index) {
                        FormUtilService.addNewOption($scope.fieldValue.values, index, $scope.constants.groups[1].name);
                    };

                    $scope.removeOption = function (index) {
                        FormUtilService.removeOption($scope.fieldValue.values, index);
                    };
                }
            };

            function initChangeModelValuesIfNeeded($scope) {
                if ($scope.fieldValueChange.values == null) {
                    $scope.fieldValueChange.values = [];
                }

                for (var i = 0; i < $scope.fieldValue.values.length; i++) {
                    if ($scope.fieldValueChange.values[i] == null) {
                        $scope.fieldValueChange.values[i] = {
                            enabled: null
                        };
                    }
                }
            }
        });
})();
