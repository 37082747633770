/**
 * Created by Jamie Nola on 06/26/2018
 */

(function () {


    angular.module('acadiamasterApp')
        .constant('IMPORT_WIZARD_CONSTANTS', (function () {
            var LIST = {
                STATUS: {
                    NOT_STARTED: 'NOT_STARTED',
                    IN_PROGRESS: 'IN_PROGRESS',
                    VALIDATION_SUCCESS: 'VALIDATION_SUCCESS',
                    VALIDATION_FAILED: 'VALIDATION_FAILED',
                    IMPORT_SUCCESS: 'IMPORT_SUCCESS',
                    IMPORT_FAILED: 'IMPORT_FAILED',
                    CANCELED: 'CANCELED'
                },
                SORT_CATEGORY: {
                    ID: 'id',
                    FILE_NAME: 'zipFileName',
                    STATUS: 'importStatus',
                    IMPORTED_ON: 'updatedOn',
                    IMPORTED_BY: 'updatedBy.email'
                }
            };

            var CATEGORY_TITLE = {
                EXPRESSION_CONDITION: 'EXPRESSION_CONDITION',
                PROPERTY: 'PROPERTY',
                FORM: 'FORM',
                FORM_ASSOCIATION: 'FORM_ASSOCIATION',
                RULE: 'BUSINESS_RULE',
                COMMUNICATION_CATEGORY: 'COMMUNICATION_CATEGORY',
                COMMUNICATION_CATEGORY_ASSOCIATION: 'COMMUNICATION_CATEGORY_ASSOCIATION',
                DATA_TYPE: 'DATA_TYPE',
                PROGRAM_TEST_SUITE: 'PROGRAM_TEST_SUITE',
                PROGRAM_BASICS: 'PROGRAM_BASICS',
                PROGRAM_DISTRIBUTION: 'PROGRAM_DISTRIBUTION',
                PROGRAM_STRING_VALUE: 'PROGRAM_STRING_VALUE',
                PROGRAM_ASSETS: 'PROGRAM_ASSETS',
                AGREEMENT: 'AGREEMENT',
                DEEP_LINK: 'DEEP_LINK',
                UNAUTHENTICATED_TASK: 'UNAUTHENTICATED_TASK',
                FILE_ASSETS: 'FILE_ASSETS',
                TOTAL: 'TOTAL',
                UNKNOWN: 'UNKNOWN'
            };

            var TABLE_HEADER = {
                ID: {
                    id: 'id',
                    sortable: true
                },
                SOURCE_FILE_NAME: {
                    id: 'sourceFileName',
                    sortable: true
                },
                ENTITY_NAME: {
                    id: 'entityName',
                    sortable: true
                },
                ENTITY_ID: {
                    id: 'entityId',
                    sortable: true
                },
                CHANGE_TYPE: {
                    id: 'changeType',
                    sortable: true
                },
                STATUS: {
                    id: 'status',
                    sortable: true
                },
                ASSOCIATION_TYPE: {
                    id: 'associationType',
                    sortable: true
                },
                ENTITY_TYPE: {
                    id: 'entityType',
                    sortable: true
                },
                FILE_COUNT: {
                    id: 'fileCount',
                    sortable: true
                },
                META_DATA: {
                    id: 'metaData',
                    sortable: false
                }
            };

            var DETAIL = {
                CATEGORIES: [
                    {
                        title: CATEGORY_TITLE.PROPERTY,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.DATA_TYPE,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.FORM,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.META_DATA,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.FORM_ASSOCIATION,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.META_DATA,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.EXPRESSION_CONDITION,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.RULE,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.COMMUNICATION_CATEGORY,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.COMMUNICATION_CATEGORY_ASSOCIATION,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.PROGRAM_TEST_SUITE,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.PROGRAM_BASICS,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.PROGRAM_STRING_VALUE,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.UNAUTHENTICATED_TASK,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.ASSOCIATION_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.PROGRAM_ASSETS,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.AGREEMENT,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.DEEP_LINK,
                        headers: [
                            TABLE_HEADER.ENTITY_NAME,
                            TABLE_HEADER.ENTITY_ID,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true
                    },
                    {
                        title: CATEGORY_TITLE.UNKNOWN,
                        headers: [
                            TABLE_HEADER.ID,
                            TABLE_HEADER.SOURCE_FILE_NAME,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true,
                        hideIfEmpty: true,
                        showAsInvalid: true
                    },
                    {
                        title: CATEGORY_TITLE.FILE_ASSETS,
                        headers: [
                            TABLE_HEADER.SOURCE_FILE_NAME,
                            TABLE_HEADER.CHANGE_TYPE,
                            TABLE_HEADER.STATUS
                        ],
                        showTableTitle: true,
                        hideIfEmpty: true,
                    },
                    {
                        title: CATEGORY_TITLE.TOTAL,
                        headers: [
                            TABLE_HEADER.ENTITY_TYPE,
                            TABLE_HEADER.FILE_COUNT
                        ],
                        showTableTitle: true,
                        isTotalCount: true
                    },
                ],
                CATEGORY: {
                    TABLE_HEADER: TABLE_HEADER
                },
                ITEM: {
                    STATUS: {
                        NOT_STARTED: 'NOT_STARTED',
                        VALIDATION_SUCCESS: 'VALIDATION_SUCCESS',
                        VALIDATION_FAILED: 'VALIDATION_FAILED',
                        IMPORT_SUCCESS: 'IMPORT_SUCCESS',
                        IMPORT_FAILED: 'IMPORT_FAILED',
                        STOPPED: 'STOPPED',
                        INVALID: 'INVALID'
                    },
                    CHANGE_TYPE: {
                        NEW: 'NEW',
                        UPDATED: 'UPDATED',
                        REMOVED: 'REMOVED',
                        UNKNOWN: 'UNKNOWN'
                    },
                    ASSOCIATION: {
                        DIRECT: 'DIRECT',
                        INDIRECT: 'INDIRECT',
                        UNKNOWN: 'UNKNOWN'
                    }
                }
            };

            var UPLOAD_STATUS = {
                SUCCESS: 'upload_success',
                CANCEL: 'upload_cancel'
            };

            return {
                LIST: LIST,
                DETAIL: DETAIL,
                UPLOAD_STATUS: UPLOAD_STATUS
            };
        }()));
}());
