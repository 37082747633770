(function () {
    'use strict';

    /**
     * form fonts service with all the font constants
     */
    angular.module('acadiamasterApp').factory('FormFontService', function () {

        /***************************************
         * service return call
         ***************************************/

        return {
            getFontOptions: getFontOptions,
            getFontFamilyForCkEditor: getFontFamilyForCkEditor,
            getFontSizesForCkEditor: getFontSizesForCkEditor,
            getFontColors: getFontColors,
            getDefaultFont: getDefaultFont,
        };

        /***************************************************************
         * service return functions (exposed in the return statement)
         ***************************************************************/

        function getFontOptions() {
            return [
                {name: '"Montserrat", sans-serif'},
                {name: '"Montserrat-Bold", sans-serif'},
                {name: '"Montserrat-Italic", sans-serif'},
                {name: '"Montserrat-Medium", sans-serif'},
                {name: '"Noto Sans", sans-serif'},
                {name: '"Noto Sans Bold", sans-serif'},
                {name: '"AvenirNext-Regular", serif'},
                {name: '"AvenirNext-Medium", serif'},
                {name: '"avenirnextdemibold", serif'},
                {name: '"OpenSans-Light", serif'},
                {name: '"OpenSans-Regular", serif'},
            ];
        }

        /**
         * getting the font family names for ck editor (ie: all the fonts are in a single string separated by ";"
         */
        function getFontFamilyForCkEditor() {
            return _.map(getFontOptions(), function (fo) {
                return fo.name;
            }).join(';');
        }

        /**
         * getting a list of font sizes for ck editor
         */
        function getFontSizesForCkEditor() {
            return [
                '7pt',
                '8pt',
                '10pt',
                '12pt',
                '14pt',
                '16pt',
                '18pt',
                '20pt',
                '24pt',
                '30pt',
                '36pt',
                '44pt',
                '52pt',
                '60pt'
            ].join(';');
        }


        /**
         * getting a list of font colors for ck editor
         */
        function getFontColors() {
            // Black, Title, Hyperlink
            return '333333,262261,0098db'
        }

        /**
         * get default font for display
         */
        function getDefaultFont() {
            return '"Montserrat-Medium", sans-serif';
        }

        /***************************************************************
         * private functions
         **************************************************************/


    });
})();
