/**
 * Created by Jamie Nola on 04/19/2019
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for CorrectiveActionCreateOrderModel model
     */
    app.factory('CorrectiveActionCreateOrderModel', function (ProgramTestConstantsService,
        CorrectiveActionBaseModel) {
        /***************************************************************
         * private functions
         **************************************************************/

        CorrectiveActionCreateOrderModel.inheritsFrom(CorrectiveActionBaseModel);

        function CorrectiveActionCreateOrderModel(parent) {
            CorrectiveActionBaseModel.call(this, parent,
                ProgramTestConstantsService.correctiveActionEnum.CREATE_ORDER);

            //initialize the variables
            this.orderType = null;
        }

        /**
         * convert the current UI model to dto format
         */
        CorrectiveActionCreateOrderModel.prototype.toDto = function () {
            var dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

            dto.orderType = this.orderType;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        CorrectiveActionCreateOrderModel.prototype.fromDto = function (dto) {
            CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

            this.orderType = dto.orderType;
        };

        CorrectiveActionCreateOrderModel.prototype.getDescriptionAsHtml = function () {
            var message = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);
            var msgArray = [];

            if (this.orderType) {
                msgArray.push('Order Type: ' + this.orderType);
            }

            message += msgArray.join(', ');
            return message;
        };

        CorrectiveActionCreateOrderModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.orderType == null) {
                this.setErrorMessage('order type is required');
            }
        };

        /***************************************
         * service return call
         ***************************************/
        return CorrectiveActionCreateOrderModel;

    });
})();
