/**
 * Created by Jamie Nola on 12/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('StringManagementService', function ($http, localWeb, Upload) {

            var features;

            return {
                loadStrings: function(programId, clearValueIds, includeProgramOnly, isServerMessage) {
                    var params = {};
                    if(isServerMessage !== undefined) {
                        params.isServerMessage = isServerMessage;
                    }

                    if (programId !== undefined) {
                        params.programId = programId;
                        if(includeProgramOnly) {
                            params.includeProgramOnly = true;
                        }
                    }

                    if (clearValueIds) {
                        params.clearValueIds = clearValueIds;
                    }

                    return $http.get(localWeb.createUrl('/api/stringResource/values'), {
                        params: params
                    });
                },
                saveString: function(dto) {
                    return $http.post(localWeb.createUrl('/api/stringResource/values/'), dto);
                },
                getFeatures: function() {
                    return features;
                },
                setFeatures: function(newFeatures) {
                    features = newFeatures;
                },
                exportToCsv: function(programId, isServerMessage) {
                    var params = {};
                    if(isServerMessage !== undefined) {
                        params.isServerMessage = isServerMessage;
                    }
                    if(programId === undefined && isServerMessage === undefined) {
                        return $http.get(localWeb.createUrl('api/stringResource/exportToCSV/'));
                    }

                    if(programId === undefined && !isServerMessage) {
                        return $http.get(
                            localWeb.createUrl('api/stringResource/exportToCSV'), {
                                params: {
                                    isServerMessage: isServerMessage
                                }
                            }
                        );
                    }

                    return $http.get(
                            localWeb.createUrl('api/stringResource/exportToCSV'), {
                                params: {
                                    programId: programId,
                                    isServerMessage: isServerMessage
                                }
                            }
                    );
                },
                importJSON: function (zipFile) {
                    return Upload.upload({
                        url: localWeb.createUrl('/api/stringResource/import/systemValues'),
                        data: {
                            file: zipFile
                        }
                    });
                }
            };
        });
})();
