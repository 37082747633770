(function () {
    'use strict';

    /**
     * Update all accordion multi-selects to align with new display config
     */
    angular.module('acadiamasterApp').factory('UpdateAccordionMultiselectAction', function (ScriptActionBaseModel, FormScriptActionUtil,
                                                                                            FormModelServiceGravity, FormModelServiceValueWithUnit, FormConstants) {

        // defining some local variable to make things easier
        var constants = FormConstants;
        var fsa = FormScriptActionUtil;

        /***************************************************************
         * private function
         ***************************************************************/

        /***************************************************************
         * model for different actions that we can apply
         ***************************************************************/
        UpdateAccordionMultiselectAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateAccordionMultiselectAction() {
            this.name = 'Update display settings in case we detect accordion multi-select field';
            this.steps = [
                {
                    ticketId: 'AC-24823',
                    description: 'Find an accordion multi-select field --> '
                    + ' Update the settings to those mentioned in AC. THIS MAY SHOW FIX COULD NOT BE APPLIED BUT IT WILL BE WHEN YOU CHECK CONFIG.',
                    searchFunction: findMultiselectAccordionFields,
                    changeFunction: updateDisplaySettings
                }
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        function fieldConfigMatch(target) {
            return fieldDisplayConfigMatch(target.displayConfig) && titleSubFieldDisplayConfigMatch(target.subFields[0].displayConfig)
                && optionsDisplayConfigMatch(target.subFields[1].displayConfig) && optionsFieldValueMatch(target.subFields[1].fieldValue);
        }

        function fieldDisplayConfigMatch(displayConfig) {
            return fsa.isPaddingBottomEqual(displayConfig, 2) && fsa.isPaddingTopEqual(displayConfig, 0)
                && fsa.isPaddingLeftEqual(displayConfig, 0) && fsa.isPaddingRightEqual(displayConfig, 0);
        }

        function titleSubFieldDisplayConfigMatch(displayConfig) {
            return fsa.isPaddingBottomEqual(displayConfig, 0) && fsa.isPaddingTopEqual(displayConfig, 0)
                && fsa.isPaddingLeftEqual(displayConfig, 30) && fsa.isPaddingRightEqual(displayConfig, 15)
                && fsa.isFontStyleBold(displayConfig) && fsa.isFontSizeEqual(displayConfig, 12)
                && fsa.isFontNameEqual(displayConfig, fsa.fontNames.notoSans)
                && fsa.isColorEqual(displayConfig, '#262261') && fsa.isGravityH(displayConfig, constants.gravity.LEFT)
                && fsa.isGravityV(displayConfig, constants.gravity.MIDDLE);
        }

        function optionsDisplayConfigMatch(displayConfig) {
            return fsa.isPaddingBottomEqual(displayConfig, 0) && fsa.isPaddingTopEqual(displayConfig, 0)
                && fsa.isPaddingLeftEqual(displayConfig, 30) && fsa.isPaddingRightEqual(displayConfig, 30)
                && fsa.isFontSizeEqual(displayConfig, 12) && fsa.isFontNameEqual(displayConfig, fsa.fontNames.notoSans)
                && fsa.isGravityH(displayConfig, constants.gravity.LEFT) && fsa.isGravityV(displayConfig, constants.gravity.MIDDLE);
        }

        function optionsFieldValueMatch(fieldValue) {
            return fsa.isMarginPaddingEqual(fieldValue.imageMargin, 'top', 20)
                && fsa.isMarginPaddingEqual(fieldValue.imageMargin, 'bottom', 20)
                && fsa.isMarginPaddingEqual(fieldValue.imageMargin, 'left', 15)
                && fsa.isMarginPaddingEqual(fieldValue.imageMargin, 'right', 20)
                && fsa.isMarginPaddingEqual(fieldValue.textMargin, 'top', 23)
                && fsa.isMarginPaddingEqual(fieldValue.textMargin, 'bottom', 20)
                && fsa.isMarginPaddingEqual(fieldValue.textMargin, 'left', 0)
                && fsa.isMarginPaddingEqual(fieldValue.textMargin, 'right', 0);
        }

        function findMultiselectAccordionFields(formMode) {
            if (formMode == null || formMode.pages == null) {
                return [];
            }

            var targets = fsa.findFieldsByType(formMode, constants.fieldsType.MULTI_SELECTOR);

            // Filter out invisible fields
            targets = _.filter(targets, function (target) {
                return target.fieldValue.showAccordion;
            });

            targets = _.filter(targets, function (target) {
                return !fieldConfigMatch(target);
            });

            return targets;
        }

        function updateDisplaySettings(target) {
            var multiselectField = target;
            var multiSelectFieldDc = multiselectField.displayConfig;
            fsa.updateMarginPadding(multiSelectFieldDc.padding, 0, 2, 0, 0);
            fsa.updateWidth(multiSelectFieldDc, 100, '%');

            // Update settings for question label (Multi select subfield)
            var multiselectTitle = multiselectField.subFields[0];
            var multiselectTitleDc = multiselectTitle.displayConfig;
            fsa.updateFontName(multiselectTitleDc, fsa.fontNames.notoSans);
            fsa.updateFontSize(multiselectTitleDc, 12);
            fsa.updateGravity(multiselectTitleDc, constants.gravity.LEFT, constants.gravity.MIDDLE);
            fsa.updateFontStyle(multiselectTitleDc, false, false, true, false);
            fsa.updateColor(multiselectTitleDc, '#262261');
            fsa.updatePadding(multiselectTitleDc, 0, 0, 30, 15);

            // Update display settings for multiselect options
            var multiselectOptions = multiselectField.subFields[1];
            var multiselectOptionsDc = multiselectOptions.displayConfig;
            fsa.updateFontSize(multiselectOptionsDc, 12);
            fsa.updateFontName(multiselectOptionsDc, fsa.fontNames.notoSans);
            fsa.updatePadding(multiselectOptionsDc, 0, 0, 30, 30);
            fsa.updateGravity(multiselectOptionsDc, constants.gravity.LEFT, constants.gravity.MIDDLE);
            fsa.updateWidth(multiselectOptionsDc, 100, '%');

            // Update field value settings for multiselect options
            var optionsFieldValue = multiselectOptions.fieldValue;
            fsa.updateMarginPadding(optionsFieldValue.imageMargin, 20, 20, 15, 20);
            fsa.updateMarginPadding(optionsFieldValue.textMargin, 23, 20, 0, 0);
            optionsFieldValue.includeSeparator = true;
            optionsFieldValue.cellBorderLayout = false;
            optionsFieldValue.imagePosition = constants.position.LEFT;
            optionsFieldValue.textPosition = constants.position.RIGHT;
            optionsFieldValue.textGravity = new FormModelServiceGravity.GravityModel(constants.gravity.LEFT, constants.gravity.MIDDLE);
            optionsFieldValue.imageType = constants.imageTypes.SQUARE;
            optionsFieldValue.imageWidth = new FormModelServiceValueWithUnit.ValueWithUnitModel(20, 'dp');
            optionsFieldValue.imageHeight = new FormModelServiceValueWithUnit.ValueWithUnitModel(20, 'dp');

        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateAccordionMultiselectAction;
    });

})();
