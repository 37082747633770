import template from './relatedAccounts.template.html';
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('relatedAccounts', function (ParticipantProfileService) {
            return {
                restrict: 'E',
                template: template,
                scope: {
                    userId: '<',
                },
                link: function ($scope) {

                    const filterLinkedAccounts = ( linkedAccounts ) => {

                        if ( linkedAccounts.length > 0) {
                            let filteredAccounts = [];
                            for( let account of linkedAccounts) {
                                let relatedAccount = {
                                };
                                const isUserGuardian = account.guardianId == $scope.userId;
                                const isUserChild = account.childId == $scope.userId;
                                if ( isUserGuardian ) {
                                    relatedAccount.relationship = 'Child';
                                    relatedAccount.id = account.childId;
                                    relatedAccount.childAccountType = account.childAccountType;
                                    relatedAccount.testUser = account.testChild;

                                }
                                if ( isUserChild ) {
                                    relatedAccount.relationship = 'Guardian';
                                    relatedAccount.id = account.guardianId;
                                    relatedAccount.childAccountType = 'N/A';
                                    relatedAccount.testUser = account.testGuardian;
                                }

                                relatedAccount.linkStatus = account.linkStatus;
                                relatedAccount.linkingCreatedOn = account.linkingCreatedOn;
                                relatedAccount.linkingUpdatedOn = account.linkingUpdatedOn;

                                filteredAccounts.push(relatedAccount);

                            }
                            return filteredAccounts;
                        }
                        return [];
                    }



                    $scope.data = {
                        ascending: false,
                        categories: [
                            { name: 'Vibrent ID' , isUser: true},
                            { name: 'Relationship' },
                            { name: 'Child Account Type' },
                            { name: 'Status' },
                            { name: 'Created Date', isDate: true },
                            { name: 'Updated Date', isDate: true },
                            { name: 'Test User' },

                        ],
                    };

                    function init() {
                        ParticipantProfileService.getLinkedAccounts($scope.userId)
                            .then(function (result) {
                                $scope.linkedAccounts = result.data;
                                $scope.relatedAccounts = filterLinkedAccounts( $scope.linkedAccounts );
                            });

                    }

                    $scope.filterLinkedAccounts = filterLinkedAccounts;
                    init();
                }
            };
        });
})();
