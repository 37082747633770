/**
 * Created by tcai on 7/12/23
 */
(function () {
    /**
     * over all configuration for user account update
     * @param {string} name
     */
    angular.module('acadiamasterApp')
        .directive('ruleElementConfigActionCreateChildAccount', (RuleUtilService, ExpressionConstantsService) => {
            return {
                link : function ($scope) {
                    $scope.expConstants = ExpressionConstantsService;
                    $scope.targetFieldSelections = [
                        RuleUtilService.ruleConstants.accountInformationField.FIRST_NAME,
                        RuleUtilService.ruleConstants.accountInformationField.LAST_NAME,
                        RuleUtilService.ruleConstants.accountInformationField.DOB,
                        RuleUtilService.ruleConstants.accountInformationField.ADDRESS_STREET_ONE,
                        RuleUtilService.ruleConstants.accountInformationField.ADDRESS_STREET_TWO,
                        RuleUtilService.ruleConstants.accountInformationField.ADDRESS_STATE,
                        RuleUtilService.ruleConstants.accountInformationField.ADDRESS_ZIP,
                        RuleUtilService.ruleConstants.accountInformationField.ADDRESS_CITY,
                    ];
                    $scope.programId = $scope.action.getRoot().programId;
                },
                restrict : 'E',
                scope    : {
                    action     : '=',
                    parentForm : '=',
                },
                templateUrl : 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.create.child.account.html',
            };
        });
}());
