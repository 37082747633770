/**
 * Created by jpierce on 4/6/18.
 */
/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 * https://codepen.io/gapcode/pen/vEJNZN
 */
function detectIE() {
    const ua = window.navigator.userAgent;
    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    const msie = ua.indexOf('MSIE');
    if (msie > 0) {
        // IE 10 or older => return version number
        return true;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        return true;
    }

    // other browser
    return false;
}

export default function ieCSSFix() {
    if (detectIE()) {
        document.body.className += ' ie-go-die';
    }
}
