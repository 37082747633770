'use strict';

angular.module('acadiamasterApp')
    .factory('Register', ['$resource', 'localWeb', 'Upload', function ($resource, localWeb, Upload) {
        var Register = $resource(localWeb.createUrl('api/register'), {}, {
        });

        Register.registerUser = function (data, file) {
            return Upload.upload({
                url: localWeb.createUrl("api/register"),
                data: {
                    file: file,
                    data : JSON.stringify(data)
                }
            });
        };

        return Register;
    }]);


