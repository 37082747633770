/**
 * Created by pravin.gayal on 05-06-2017.
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('ProgramUtilService', ['VbrTreeUtilService', 'ProgramUtilConstantsService', 'vbrCommonUtil', 'LocaleConstantsService', 'TriggerUtilService', 'ProgramService', 'ngDialog',
            function (VbrTreeUtilService, ProgramUtilConstantsService, vbrCommonUtil, LocaleConstantsService, TriggerUtilService, ProgramService, ngDialog) {
                var nodeTypeMap = createNodeTypeMap();

                return {
                    pcs           : ProgramUtilConstantsService,
                    lcs           : LocaleConstantsService,
                    isUnavailable : vbrCommonUtil.isUnavailable,

                    treeOptions : {
                        accept     : accept,
                        dropped    : VbrTreeUtilService.dropped,
                        beforeDrag : beforeDrag,
                    },

                    downloadProgram     : downloadProgram,
                    importProgramEntity : importProgramEntity,
                };

                function createNodeTypeMap() {
                    var map = {};
                    map[ProgramUtilConstantsService.nodeType.PROGRAM] = VbrTreeUtilService.createNodeTypeItem(ProgramUtilConstantsService.nodeType.PROGRAM, null);
                    map[TriggerUtilService.ruleConstants.nodeType.TRIGGER] = VbrTreeUtilService.createNodeTypeItem(TriggerUtilService.ruleConstants.nodeType.TRIGGER, [TriggerUtilService.ruleConstants.nodeType.TRIGGER_FIXED_NODE]);
                    return map;
                }

                function accept(sourceNodeScope, destNodeScope) {
                    var sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
                    var destType = VbrTreeUtilService.getParentTypeFromNodeScope(destNodeScope);
                    if (nodeTypeMap[sourceType].typesCanMoveUnder && nodeTypeMap[sourceType].typesCanMoveUnder.indexOf(destType) != -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }

                function beforeDrag(sourceNodeScope) {
                    var sourceType = VbrTreeUtilService.getTypeFromNodeScope(sourceNodeScope);
                    return nodeTypeMap[sourceType].typesCanMoveUnder != null;
                }

                function downloadProgram(programId, fileName) {
                    var progDetails = ProgramService.get({
                        id : programId,
                    });
                    progDetails.$promise.then(function(data) {
                        // download as file in browser
                        vbrCommonUtil.downloadAsFile(fileName, data);
                    });
                }

                function importProgramEntity($scope, popupTitle, wsURL, onSuccessCallback) {
                    $scope.popupTitle = popupTitle;
                    $scope.wsURL = wsURL;
                    ngDialog.openConfirm({
                        template   : '<upload-to-configuration-server title="{{popupTitle}}" ws-url="{{wsURL}}" on-success-callback="onSuccess"></upload-to-configuration-server>',
                        plain      : true,
                        scope      : $scope,
                        size       : 'md',
                        className  : 'ngdialog-theme-plain custom-width-medium',
                        controller : ['$scope', '$state', function ($scope, $state) {
                            $scope.onSuccess = function () {
                                if(onSuccessCallback instanceof Function){
                                    onSuccessCallback();
                                }else {
                                    $state.reload();
                                }
                            };
                        }],
                    }).then(function() {
                        $scope.refresh();
                    });
                }

            }]);

})();

