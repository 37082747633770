'use strict';

angular.module('acadiamasterApp')
    .factory('Language', function ($q, $http, $translate, LANGUAGES) {
        return {
            getCurrent: function () {
                var deferred = $q.defer();
                var storage = $translate.storage();
                var language;

                if(storage) {
                    language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');
                }

                if (angular.isUndefined(language)) {
                    language = 'en';
                }

                deferred.resolve(language);
                return deferred.promise;
            },
            getAll: function () {
                var deferred = $q.defer();
                deferred.resolve(LANGUAGES);
                return deferred.promise;
            }
        };
    })






