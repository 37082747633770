(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     */
    app.factory('PreConditionBaseModel', function (ProgramTestConstantsService, ModelServiceBase) {
            /***************************************************************
             * PreConditionModel
             ***************************************************************/
            PreConditionBaseModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);

            function PreConditionBaseModel(parent, type) {
                ModelServiceBase.BaseTreeNodeModel.call(this, false, false, parent);

                this.nodeType = ProgramTestConstantsService.nodeType.PRECONDITION;

                // Action properties
                this.type = type;
            }

            /**
             * convert the current UI model to dto format
             */
            PreConditionBaseModel.prototype.toDto = function () {
                var dto = {};

                dto.type = this.type.name;
                return dto;
            };

            /**
             * convert the dto object into current object, this function will
             * wipe out any information you have on the current object
             * @param dto
             */
            PreConditionBaseModel.prototype.fromDto = function (dto) {
                this.id = dto.id;
                this.type = ProgramTestConstantsService.getObjectByName(ProgramTestConstantsService.preConditionType, dto.type);
            };


            /**
             * validate the entity
             */
            PreConditionBaseModel.prototype._validateSelf = function () {
                // over
            };

            /**
             * getting the description
             */
            PreConditionBaseModel.prototype.getDescriptionAsHtml = function () {
                var message = '';

                if (this.type != null) {
                    message = '<span class="badge badge-success">' + this.type.name + '</span> ';
                }

                return message;
            };


            /***************************************
             * service return call
             ***************************************/
            return PreConditionBaseModel;

        }
    );
})();
