/**
 *
 * Directive for selecting container in a drop-down
 */

import controller from './containerSelector.controller.js';
import template from './containerSelector.template.html';

angular.module("acadiamasterApp")
    .directive("containerSelector", function() {
        return {
            bindToController: true,
            controller: 'ContainerSelectorController',
            controllerAs: 'psc',
            restrict: 'E',
            scope: {
                callback: '<?',
                programId: '<',
                selectedContainerId: '<?',
            },
            template,
        };
    });
