/**
 * Created by moustafabaiou on 2/17/17.
 */
(function () {
    'use strict';

    // eslint-disable-next-line angular/function-type
    angular.module('acadiamasterApp')
    /**
     * directive for configuring any validation rules
     */
        .directive('formElementConfigValidationRules', function (FormUtilService, FormConstants) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/validationRule/validationRules.html',
                scope       : {
                    element : '=',
                },
                link : function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.applyRule = function(ruleTypeToApply) {
                        applyRule($scope, ruleTypeToApply);
                    };
                    $scope.ruleAlreadyApplied = function(ruleType, ruleList) {
                        return ruleInList(ruleType, ruleList);
                    };
                    $scope.availableRuleTypes= availableRuleTypesList($scope.element.availableRuleTypes, 4);
                    prepareValidationRulesForGUI($scope.element);
                },
            };

            /***************************************************************
             * private functions
             ***************************************************************/
            function applyRule($scope, ruleToApply) {
                if (!ruleInList(ruleToApply, $scope.validationRules)) {
                    if ($scope.element.validationRules==null) {
                        $scope.element.validationRules = [];
                    }

                    $scope.element.validationRules.push(new FormUtilService.vrs.ValidationRuleModel(ruleToApply));
                    if(ruleToApply.name === $scope.constants.validationRuleTypes.WHOLE_NUMBERS_ONLY.name){
                        applyDecimalConfiguration($scope);
                    }    
                }
            }

            function ruleInList(ruleType, ruleList) {
                if (ruleList!=null && ruleList.length>0) {
                    for (var i=0, length = ruleList.length; i<length; i++) {
                        var r = ruleList[i];
                        if (r.type==ruleType) {
                            return true;
                        }
                    }
                }

                return false;
            }
            function availableRuleTypesList(ruleList, size) {
                var ruleTypeList = [];
                for(var i=0; i<ruleList.length; i+=size){
                    ruleTypeList.push(ruleList.slice(i, i+size));
                }
                return ruleTypeList;
            }
            function applyDecimalConfiguration($scope) {
                $scope.element.fieldValue.decimalPoints = $scope.constants.decimalPointOptions.NO_LIMIT;

            }
            function prepareValidationRulesForGUI(element) {
                if (element.validationRules!=null && element.validationRules.length>0) {
                    // eslint-disable-next-line no-undef
                    _.forEach(element.validationRules, function(vr) {
                        if (vr.options==null) {
                            vr.options = {}; //this is needed for backward compatibility
                        }

                        if (vr.options[FormConstants.validationRuleOptions.INCLUDE_UNIT.name]!=undefined) {
                            vr.includeUnit = true;
                            vr.selectedUnit = vr.options[FormConstants.validationRuleOptions.INCLUDE_UNIT.name];
                        }

                        if (vr.options[FormConstants.validationRuleOptions.INCLUDE_EQUAL.name]!=undefined) {
                            vr.includeEqual = true;
                        }
                    });
                }
            }
        });

})();

