(function () {
    'use strict';

    /**
     * directive for selecting program
     */
    angular.module('acadiamasterApp').directive('programSelection', function (ProgramCacheService, AlertService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/common/directive/programSelection.html',
            scope: {
                programOptions: '=',
                isRequired: '=',
                title: '@',
            },
            link: function ($scope) {
                $scope.data = {
                    allPrograms : null,
                    program : $scope.programOptions && $scope.programOptions.getProgram()
                };

                init($scope);
            }
        };

        function init($scope) {
            // this is not a false reload of all the properties
            ProgramCacheService.loadPrograms(false).then(function (data) {
                $scope.data.allPrograms = data;
            }).catch(function (error) {
                AlertService.error("failed to load program list : ", null, error);
            });

            addWatches($scope);
        }

        function addWatches($scope) {
            $scope.$watch("data.program", function(newValue) {
                $scope.programOptions.setProgram(newValue);
            });
        }
    });
})();
