
(
    function () {
        'use strict';

        angular.module('acadiamasterApp')
            .factory('Principal', function ($q, Account, localStorageService) {
                var _identity,
                    _authenticated = false;

                function authenticate(identity) {
                    _identity = identity;
                    _authenticated = identity !== null;
                }

                function getToken() {
                    return localStorageService.get('token');
                }

                /**
                 * hasAnyAuthority() function may use by someone.
                 * hence we created new function "ifAnyAuthority" and don't modify existing function.
                 * ifAnyAuthority() is used in has-any-authority directive
                */
                function hasAnyAuthority(authorities) {
                    if (!isAuthenticated() || !_identity || !_identity.authorities) {
                        return false;
                    }

                    for (var i = 0; i < authorities.length; i++) {
                        if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                            return true;
                        }
                    }

                    return false;
                }

                function hasAuthority(authority) {
                    if (!isAuthenticated()) {
                        return false;
                    }

                    return this.identity().then(function (_identity) {
                        return _identity != null
                            && _identity.authorities && _identity.authorities.indexOf(authority) !== -1;
                    }, function (error) {
                        console.error(error);
                        return false;
                    });
                }

                function identity(force) {
                    var deferred = $q.defer();

                    if (force === true) {
                        _identity = undefined;
                    }

                    // check and see if we have retrieved the identity data from the server.
                    // if we have, reuse it by immediately resolving
                    if (_identity !== undefined && _identity !== null) {
                        deferred.resolve(_identity);

                        return deferred.promise;
                    }

                    // retrieve account info from server if it's not in cache and we are not on
                    // sign up page or login page
                    if (getToken()) {
                        // retrieve the identity data from the server, update the identity object, and then resolve.

                        Account.get().$promise
                            .then(function (account) {
                                _identity = account.data;
                                _authenticated = true;
                                deferred.resolve(_identity);
                            })
                            .catch(function () {
                                _identity = null;
                                _authenticated = false;
                                deferred.resolve(_identity);
                            });
                    }
                    else {
                        // no token
                        _identity = null;
                        _authenticated = false;
                        deferred.resolve(_identity);
                    }
                    return deferred.promise;
                }

                function ifAnyAuthority(authorities) {
                    var loginUser = _identity ||
                        localStorageService.get('user');

                    if (loginUser == null || authorities == null || authorities.length == 0) {
                        return false;
                    }

                    for (var i = 0; i < authorities.length; i++) {
                        if (loginUser.authorities.indexOf(authorities[i]) !== -1) {
                            return true;
                        }
                    }
                    return false;
                }

                function isAuthenticated() {
                    return _authenticated || !!localStorageService.get('token') || !!localStorageService.get('user');
                }

                function isIdentityResolved() {
                    return angular.isDefined(_identity);
                }

                function unAuthenticate() {
                    _authenticated = false;
                    localStorageService.remove('token');
                }

                function updateAuthenticationKey(newKey) {
                    _identity.authenticationKey = newKey;
                }

                return {
                    authenticate: authenticate,
                    hasAnyAuthority: hasAnyAuthority,
                    hasAuthority: hasAuthority,
                    identity: identity,
                    ifAnyAuthority: ifAnyAuthority,
                    isAuthenticated: isAuthenticated,
                    isIdentityResolved: isIdentityResolved,
                    unAuthenticate: unAuthenticate,
                    updateAuthenticationKey: updateAuthenticationKey
                };
            });
    }()
);
