(function () {
    'use strict';

    angular.module('acadiamasterApp')

        .directive('ruleElementConfigActionUnauthenticatedTask',
            function (LocaleConstantsService, ProgramService, UnauthenticatedTaskService, RuleActionConfigModelService) {
                return {
                    restrict: 'E',
                    templateUrl: 'admin-templates/site/businessRule/configPanel/action/elementConfig.action.unauthenticated.task.html',
                    scope: {
                        action: '=',
                        parentForm: '=',
                    },
                    link: function ($scope) {
                        $scope.lcs = LocaleConstantsService;
                        $scope.data = {
                            allPrograms: [],
                            allTasks: null,
                        };
                            $scope.isReady = false;
                            $scope.unauthenticatedTask = {};
                            $scope.unauthenticatedTask.options = {
                            unauthenticatedTask: $scope.action.unauthenticatedTask,
                            taskId: $scope.action.unauthenticatedTaskId,
                            title: 'Task Id',
                            viewOnly: false
                        };
                        $scope.sendNotificationChangeCallback = function (newValue) {
                            if (!newValue) {
                                $scope.action.notificationModel = null;
                            } else {
                                if ($scope.action.notificationModel == null) {
                                    $scope.action.notificationModel = new RuleActionConfigModelService.ActionConfigEmailOrSMSNotificationModel();
                                    delete $scope.action.notificationModel.communicationCategories;
                                }
                            }
                        };
                    },
                };
            }
        );
})();
