'use strict';

(function () {
    angular.module('acadiamasterApp')

        .factory('User', function ($resource, localWeb) {
            return $resource(localWeb.createUrl('api/users/:login'), {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'getById': {
                    url: localWeb.createUrl('api/user/:id'),
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'update': {method: 'PUT'}
            });
        })
})();
