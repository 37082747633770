(function () {
    'use strict';

    /***************************************************************
     * Corrective Action for recalculate personalized container
     ***************************************************************/

    angular.module('acadiamasterApp').factory('CorrectiveActionRecalculationPersonalizedContainer',
        function (ProgramTestConstantsService, CorrectiveActionBaseModel, ContainerLoadUtil) {

            CorrectiveActionRecalculationPersonalizedContainer.inheritsFrom(CorrectiveActionBaseModel);

            /**
             * @constructor
             */
            function CorrectiveActionRecalculationPersonalizedContainer(parent) {
                CorrectiveActionBaseModel.call(this, parent, ProgramTestConstantsService.correctiveActionEnum.EVALUATE_PERSONALIZED_CONTAINER);

                this.containerId = null;
                this.recalculateAllItemsInside = false;

                this._container = null;
            }

            CorrectiveActionRecalculationPersonalizedContainer.prototype.fromDto = function (dto) {
                CorrectiveActionBaseModel.prototype.fromDto.call(this, dto);

                this.containerId = dto.containerId;
                this.recalculateAllItemsInside = dto.recalculateAllItemsInside;

                ContainerLoadUtil.loadContainer(this);
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype.toDto = function () {
                const dto = CorrectiveActionBaseModel.prototype.toDto.call(this);

                dto.containerId = this.containerId;
                dto.recalculateAllItemsInside = this.recalculateAllItemsInside;

                return dto;
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype.setContainer = function (container) {
                this._container = container;
                this.containerId = container==null ? null : container.id;
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype.getContainer = function () {
                return this._container;
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype.getProgramId = function () {
                const rootElement = this.getRoot();

                return rootElement==null ? null : rootElement.programId;
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype.getDescriptionAsHtml = function () {
                const typeMsg = CorrectiveActionBaseModel.prototype.getDescriptionAsHtml.call(this);

                const containerMsg = 'Container (' +
                    (this.containerId == null ? 'no id' : this.containerId) + ' | ' +
                    (this._container == null ? 'no name' :  this._container.name) + ')';

                return typeMsg + 'for ' + containerMsg;
            };

            CorrectiveActionRecalculationPersonalizedContainer.prototype._validateSelf = function () {
                this.clearError();

                if (this.containerId == null) {
                    this.setErrorMessage('container id is required');
                }
            };

            /***************************************
             * private functions
             ***************************************/

            /***************************************
             * service return call
             ***************************************/
            return CorrectiveActionRecalculationPersonalizedContainer;
        });
})();
