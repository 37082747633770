
const app = angular.module('acadiamasterApp');

/**
 * creator model used for creating the instance
 */
app.factory('ConditionTreeCreatorModel', () => {
    function ConditionTreeCreatorModel(model, type, hasDividerAbove, hideConditionForUsageType) {
        this.model = model;

        // Action properties
        this.type = type;

        this.hasDividerAbove = hasDividerAbove;

        // this is an array holding the expression condition usage type values.
        // UI will hide the button on the popup if usage type exists in hideConditionForUsageType array
        this.hideConditionForUsageType = hideConditionForUsageType;
    }

    /**
     * create new corrective action for the current model
     * @param parentGroup - parent query group (can be null, but should only for the top level)
     * @param selector - selector, optional
     * @returns {*} newly created corrective action
     */
    ConditionTreeCreatorModel.prototype.createNew = function (parentGroup, selector) {
        let newItem = new this.model(parentGroup);

        if (parentGroup != null && _.isFunction(parentGroup.addCondition)) {
            parentGroup.addCondition(newItem, selector);
        }

        return newItem;
    };

    /***************************************
     * service return call
     ************************************** */
    return ConditionTreeCreatorModel;
});
