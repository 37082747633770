(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('ProgramTestExecutionUserAuditService', function ($http, localWeb) {
        var baseUrl = 'api/programTest/userAudit';
        return {

            /**
             * retrieve one page of user audits associated to program test audit id
             * @param programTestAuditId - program test audit id
             * @param pageNum - page number
             * @param pageSize - page size
             * @returns {HttpPromise} - promise of the http call
             */
            listAudits: function (programTestAuditId, pageNum, pageSize) {
                return $http.get(localWeb.createUrl(baseUrl + '/list?' +
                    'programTestExecutionId=' + programTestAuditId +
                    '&pageNumber=' + pageNum +
                    '&pageSize=' + pageSize
                ));
            },

            /**
             * get the count for all the user audits associated to program test audit id
             * @param programTestAuditId - program test audit id
             * @returns {HttpPromise} - promise of the http call
             */
            countAudits: function (programTestAuditId) {
                return $http.get(localWeb.createUrl(baseUrl + '/count?' +
                    'programTestExecutionId=' + programTestAuditId
                ));
            },

            /**
             * Download the csv report for user audits
             * @param programTestAuditId  program test audit id
             */
            downloadAudits: function (programTestAuditId) {
                return $http.get(localWeb.createUrl('api/programtestuserauditreport?' +
                    'programTestExecutionId=' + programTestAuditId
                ));
            },
        };
    })
})();
