/**
 *
 * Controller for selecting container in a drop-down
 */

angular.module('acadiamasterApp')
    . controller('ContainerSelectorController',
        function (ContainerSelectorService, ContainerModel) {
            const vm = this;

            vm.data = {
                containers        : [],
                isLoading         : false,
                loadingError      : false,
                selectedContainer : null,
            };

            vm.data.isLoading = true;

            vm.callbackWrapper = function (container) {
                if (vm.callback) {
                    vm.callback(container);
                }
            };

            vm.$onInit = () => {
                ContainerSelectorService.getBasicContainers(vm.programId).then(response => {
                    vm.data.containers = response.data.map(dto => {
                        const containerModel = {
                            containerType   : dto.containerType,
                            dropDownDisplay : `${dto.id} | ${dto.name}`,
                            id              : dto.id,
                            pageId          : dto.pageId,
                        };
                        if (vm.selectedContainerId && containerModel.id === vm.selectedContainerId) {
                            vm.data.selectedContainer = containerModel;
                        }
                        return containerModel;
                    });
                }, error => {
                    vm.data.loadingError = true;
                    console.error('Error getting container from API', error);
                })
                    .finally(() => {
                        vm.data.isLoading = false;
                    });
            };
        });
