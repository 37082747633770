/**
 * calculate links from pageable data with total page, number, etc
 * @param pageableData - pageable data
 * @returns {{next: *, last: *, prev: *, first: *}} - link object used for pagination control
 */
function calculateLinks(pageableData) {
    const {totalPages, number} = pageableData;
    const last = totalPages -1;
    const first = 0;
    const next = number + 1 >= totalPages ? null : number + 1;
    const prev = number - 1 >=0 ? number - 1 : null;

    return {
        last,
        next,
        prev,
        first,
    };
}

export default {
    calculateLinks,
};