(function () {
    'use strict';

    /***************************************************************
     * Precondition for sync device toggle
     ***************************************************************/

    angular.module('acadiamasterApp').factory('PreConditionSyncDeviceToggleModel',
        function (ProgramTestConstantsService, PreConditionBaseModel, ProgramLoadUtil) {

            PreConditionSyncDeviceToggleModel.inheritsFrom(PreConditionBaseModel);

            /**
             * pre condition for sync device toggle with status
             * @constructor
             */
            function PreConditionSyncDeviceToggleModel(parent) {
                PreConditionBaseModel.call(this, parent, ProgramTestConstantsService.preConditionType.SYNC_DEVICES_TOGGLED_STATUS);

                this.programId = null;
                this.toggleStatus = false;
                this.supportedDevicesEnum = null;

                this._program = null;
            }

            PreConditionSyncDeviceToggleModel.prototype.fromDto = function (dto) {
                PreConditionBaseModel.prototype.fromDto.call(this, dto);

                this.programId = dto.programId;
                this.toggleStatus = dto.toggleStatus;
                this.supportedDevicesEnum = dto.supportedDevicesEnum;

                ProgramLoadUtil.loadProgram(this);
            };

            PreConditionSyncDeviceToggleModel.prototype.toDto = function () {
                var dto = PreConditionBaseModel.prototype.toDto.call(this);

                dto.programId = this.programId;
                dto.toggleStatus = this.toggleStatus;
                dto.supportedDevicesEnum = this.supportedDevicesEnum;

                return dto;
            };

            PreConditionSyncDeviceToggleModel.prototype.setProgram = function (program) {
                this._program = program;
                this.programId = program==null ? null : program.id;
            };

            PreConditionSyncDeviceToggleModel.prototype.getProgram = function () {
                return this._program;
            };

            PreConditionSyncDeviceToggleModel.prototype.getDescriptionAsHtml = function () {
                var typeMsg = PreConditionBaseModel.prototype.getDescriptionAsHtml.call(this);
                var supportedDeviceMsg = this.supportedDevicesEnum==null ? '<span class="text-danger">supported device Not Defined</span>' :
                    '<span class="badge">' + this.supportedDevicesEnum + '</span>';

                var programMsg = 'Program(' + (this.programId == null ? 'no id' : this.programId) + ' | ' +
                    (this._program == null ? 'no name' :  this._program.name) + ')';

                var statusMsg = this.toggleStatus ? ' enabled ' : 'disabled';

                return typeMsg + ' in ' + programMsg + ', ' + supportedDeviceMsg + ' should be ' + statusMsg;
            };

            PreConditionSyncDeviceToggleModel.prototype._validateSelf = function () {
                this.clearError();

                if (this.programId == null) {
                    this.setErrorMessage('program id is required');
                    return;
                }

                if (this.supportedDevicesEnum == null) {
                    this.setErrorMessage('supported device type is required');
                    return;
                }

                if (this.toggleStatus == null) {
                    this.setErrorMessage('toggle status is required');
                }
            };


            return PreConditionSyncDeviceToggleModel;
        });
})();
