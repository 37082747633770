(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('ProfileUpdateService', function ($http, localWeb, $q) {

        return {
            updateProfile: updateProfile
        };

        /**
         * update profile data
         * @param data -- update profile data, currently do not allow for file upload
         */
        function updateProfile(data) {
            var apiUrl = localWeb.createUrl('api/userProfileAdmin');

            var deferred = $q.defer();

            var success = function (response) {
                deferred.resolve(response);
            };

            var error = function (err) {
                console.error(err);
                deferred.reject(err);
            };

            $http({
                url: apiUrl,
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: JSON.stringify(data)
            }).then(success).catch(error);

            return deferred.promise;
        }
    });
})();

