/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')


    /**
     * directive for display a tree node view of state rule condition list
     */
        .directive('treeNodeFormConditionList', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/treeNode/formStateRule.conditionList.html',
                scope: {
                    element: '=',
                    currentTreeName : '=',
                    selector: '='
                },
                link: function ($scope) {

                    /**
                     * check if the current item in buffer can be pasted as a child
                     * @returns {boolean}
                     */
                    $scope.canPasteAsChild = function() {
                        var itemInBuffer = $scope.selector.getItemInBuffer();

                        if (itemInBuffer==null) {
                            return false;
                        }


                        // check for matching type and make sure this is not already a child
                        return itemInBuffer.nodeType == FormConstants.nodeType.RULE_CONDITION_WITH_ACTION &&
                            itemInBuffer._parent != $scope.element;
                    };


                    /**
                     * paste the last element copied or cut as a child
                     * ie: this element copied of cut must be a form state rule trigger
                     */
                    $scope.doPasteAsChild = function() {
                        var elementInBuffer = $scope.selector.getItemInBuffer();

                        // remove the element from its previous parent
                        var oldParent = elementInBuffer._parent;

                        if ($scope.selector.isBufferCut) {
                            // cut
                            oldParent.removeCondition(elementInBuffer);
                        }
                        else {
                            // make a copy
                            elementInBuffer = elementInBuffer.customClone();
                        }

                        // link it to this element
                        elementInBuffer._parent = $scope.element;

                        // add it to the top of the children array
                        $scope.element.conditionWithActionsList.unshift(elementInBuffer);
                        $scope.selector.selectItem(elementInBuffer);

                        $scope.selector.itemPasted();
                    };

                }
            }
        });

})();

