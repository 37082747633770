angular.module('acadiamasterApp')
    .factory('RegisterConstantService', function () {
        return {
            roles:{
                ROLE_ADMIN:"ROLE_ADMIN",
                ROLE_PROVIDER:"ROLE_PROVIDER",
                ROLE_USER:"ROLE_USER",
                ROLE_SUPPORT:"ROLE_SUPPORT"
            }
        }

    });
