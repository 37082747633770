/**
 * Created by amit.rao on 02-09-2016
 */


(function () {
    angular.module('acadiamasterApp').factory(
        'InviteCodesModel',
        () => {
            function InviteCodesModel() {
                this.id = null;
                this.code = null;
                this.timesUsed = null;
                this.timesAllowed = null;
                this.enabled = true;
                this.programId = null;
                this.program = null;
                this.usageType = 'REGISTRATION';
                this.tags = null;
                this.expirationDate = null;
                this.metaData = null;
                this.numberOfCodes = 1;
            }

            InviteCodesModel.prototype.fromDto = function (dto) {
               this.id = dto.id;
               this.code = dto.code;
               this.timesUsed = dto.timesUsed || '';
               this.timesAllowed = dto.timesAllowed || '';
               this.enabled = dto.enabled || '';
               this.programId = dto.programId || '';
               this.program = dto.programCode || '';
               this.usageType = dto.usageType || '';
               this.tags = dto.inviteCodeTagMappings || '';
               this.expirationDate = dto.expirationDate || '';
               this.metaData = dto.metaData || '';
               this.numberOfCodes = 1;
            };

            InviteCodesModel.prototype.toDto = function () {
               var dto = {};
               dto.id = this.id;
               dto.code = this.code;
               dto.timesUsed = this.timesUsed;
               dto.timesAllowed = this.timesAllowed ;
               dto.enabled = this.enabled;
               dto.programId = this.programId;
               dto.programCode = this.program;
               dto.usageType = this.usageType;
               dto.codeExpirationDate = (this.expirationDate ? Date.parse(this.expirationDate) : null);
               dto.numberOfCodes = parseInt(this.numberOfCodes);
               return dto;
            };
            return InviteCodesModel;
        },
    );
}());
