(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .config(function ($stateProvider) {
            function loadTranslatePartial($translate, $translatePartialLoader) {
                $translatePartialLoader.addPart('global');
                // this is admin site, don't worry about the i18n support for now, we can always add it later if needed
                // $translatePartialLoader.addPart('drc');
                return $translate.refresh();
            }

            $stateProvider.state('ehrDashboard', {
                parent: 'entity',
                url: '/ehr',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'EHR Dashboard'
                },
                views: {
                    'content@': {
                        templateUrl: 'admin-templates/site/ehr/ehrDashboard.html',
                        controller: 'EHRDashboardController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                }
            });

            /**
             * page to sync users EHR data
             */
            $stateProvider.state('ehrSyncUsers', {
                parent: 'ehrDashboard',
                url: '/sync',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'EHR Sync Users'
                },
                views: {
                    'content@': {
                        template: '<vbr-ehr-sync-user></vbr-ehr-sync-user>'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
            $stateProvider.state('ehrProviders', {
                parent: 'ehrDashboard',
                url: '/providers',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'EHR Providers'
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
    
            $stateProvider.state('ehrUsers', {
                parent: 'ehrDashboard',
                url: '/users',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'EHR Users'
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return loadTranslatePartial($translate, $translatePartialLoader);
                    }]
                }
            });
    
        });


})();
