/**
 * directive for configuring the background of a layout state
 */
angular.module('acadiamasterApp').directive('templateValueBackgroundConfig', (BackgroundType) => ({
    link($scope) {
        $scope.backgroundTypes = BackgroundType;
    },
    restrict : 'E',
    scope    : {
        contentValue : '=',
        definition   : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/byValueType/' +
            'templateValue.background.config.html',
}));

