(function () {
    'use strict';


    /**
     * form search constants
     */
    angular.module('acadiamasterApp').factory('FormSearchConstants', function (FormConstants) {
        var constants = FormConstants;
        // flatten the maps here
        var fieldTypes = _.map(constants.fieldsType, function(ft) {
            return ft.name;
        });

        var subFieldTypes = _.map(constants.subFieldsType, function(e) {
            return e.name;
        });



        /***************************************
         * service return call
         ***************************************/

        return {
            searchModeChoices : [
                constants.nodeType.MODE.EDIT,
                constants.nodeType.MODE.VIEW_ENTRY,
                constants.nodeType.MODE.PROMPT],

            searchTypeChoices: [
                constants.nodeType.FORM_VERSION,
                constants.nodeType.SECTION,
                constants.nodeType.COMPONENT,
                constants.nodeType.FIELD,
                constants.nodeType.SUB_FIELD,
                constants.nodeType.STATE_RULE,
                constants.nodeType.RULE_TRIGGER,
                constants.nodeType.RULE_CONDITION_WITH_ACTION,
                constants.nodeType.RULE_ACTION
            ],

            fieldTypes : fieldTypes,

            subFieldTypes: subFieldTypes,

            booleanChoices: [
                true,
                false
            ],

            lengthUnits : [
                'dp', '%'
            ],

            fontNames : [
                '"Noto Sans", sans-serif',
                '"Noto Sans Bold", sans-serif',
                '"AvenirNext-Regular", serif',
                '"AvenirNext-Medium", serif',
                '"avenirnextdemibold", serif',
                '"OpenSans-Light", serif',
                '"OpenSans-Regular", serif'
            ],

            gravityHChoices: [
                'LEFT',
                'CENTER',
                'RIGHT'
            ],

            gravityVChoices: [
                'TOP',
                'MIDDLE',
                'BOTTOM'
            ]
        };
    });

})();
