(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigModalNotificationModel',
        function (RuleConstantsService, RuleActionBaseModelService, RuleUtilService) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigModalNotificationModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigModalNotificationModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.MODAL_NOTIFICATION;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigModalNotificationModel;
                this.bannerType = RuleConstantsService.bannerType.OPEN_FORM;
                this.form = null;
                this.dismissible = true;
                this.postActionNavigationLink =  RuleConstantsService.notificationActionNavigationLink.DASHBOARD;
                return this;
            }

            ActionConfigModalNotificationModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    if (actionConfig.formId != null) {
                        this.form = RuleUtilService.getFormById(actionConfig.formId);
                    }
                    this.postActionNavigationLink = RuleConstantsService.getObjectByName(RuleConstantsService.notificationActionNavigationLink,
                        actionConfig.postActionNavigationLink);
                    this.dismissible = actionConfig.dismissible;
                }
            };

            ActionConfigModalNotificationModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.formId = this.form != null ? this.form.id : null;
                actionConfig.dismissible = this.dismissible;
                actionConfig.postActionNavigationLink = this.postActionNavigationLink.name;
                return dto;
            };

            ActionConfigModalNotificationModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var message = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);
                if (this.form) {
                    message += " | Form Id: " + this.form.id;
                }
                return message;
            };

            ActionConfigModalNotificationModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.form == null || this.form.id == null) {
                    this.setErrorMessage("Select form to be opened");
                }
            };

            return ActionConfigModalNotificationModel;
        }
    );
})();