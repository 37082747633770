/* eslint-disable no-use-before-define */
/**
 * Created by moustafabaiou on 4/26/17
 */
(function () {
    angular.module('acadiamasterApp')
        .controller('InviteCodesController', ($scope, $state, SessionStorageService,
            InviteCodesService, TagService, ngDialog, ParseLinks, SEARCH_FILTER_CONSTANTS, localWeb) => {
            $scope.inviteCodes = [];
            $scope.allPrograms = [];
            $scope.allTags = [];

            $scope.searchData = {
                searchResultCount : 0,
            };

            $scope.storageKey = 'inviteCodeFilters';
            $scope.storageDomain = $state.current.name;

            $scope.searchParams = {
                ascending : false,
                orderBy   : 'id',
                page      : 0,
                size      : 20,
            };

            $scope.FIELD_ID = {
                CODE            : 'code',
                EXPIRATION_DATE : 'expirationDate',
                LOGO            : 'logo',
                METADATA        : 'metaData',
                STATUS          : 'enabled',
                TAG             : 'tagID',
                TIMES_ALLOWED   : 'timesAllowed',
                TIMES_USED      : 'timesUsed',
                USAGE           : 'usage',
            };

            $scope.SORT_ID = {
                EXPIRATION_DATE : 'expiration_date',
                TIMES_ALLOWED   : 'times_allowed',
                TIMES_USED      : 'times_used',
            };

            $scope.fields = [
                { id : $scope.FIELD_ID.CODE },
                {
                    id       : $scope.FIELD_ID.TIMES_USED,
                    sortable : true,
                    sortId   : $scope.SORT_ID.TIMES_USED,
                },
                {
                    id       : $scope.FIELD_ID.TIMES_ALLOWED,
                    sortable : true,
                    sortId   : $scope.SORT_ID.TIMES_ALLOWED,
                },
                { id : $scope.FIELD_ID.STATUS },
                { id : $scope.FIELD_ID.USAGE },
                { id : $scope.FIELD_ID.TAG },
                { id : $scope.FIELD_ID.METADATA },
                { id : $scope.FIELD_ID.LOGO },
                {
                    id       : $scope.FIELD_ID.EXPIRATION_DATE,
                    sortable : true,
                    sortId   : $scope.SORT_ID.EXPIRATION_DATE,
                },
            ];

            $scope.filteredFields = [
                SEARCH_FILTER_CONSTANTS.FIELDS.USAGE_TYPE,
                SEARCH_FILTER_CONSTANTS.FIELDS.ENABLED,
                SEARCH_FILTER_CONSTANTS.FIELDS.TAG,
                SEARCH_FILTER_CONSTANTS.FIELDS.DATA_PROPERTY_ID,
                SEARCH_FILTER_CONSTANTS.FIELDS.DATA_PROPERTY_VALUE,
                SEARCH_FILTER_CONSTANTS.FIELDS.EXPIRATION_DATE,
                SEARCH_FILTER_CONSTANTS.FIELDS.INVITE_CODE,
            ];
            $scope.filteredValues = {};
            $scope.filteredPrograms = [];
            $scope.filterSummary = [];

            $scope.sortBy = function (field) {
                let id = field.sortId || field.id;
                if ($scope.searchParams.orderBy === id) {
                    $scope.searchParams.ascending = !$scope.searchParams.ascending;
                } else {
                    $scope.searchParams.ascending = true;
                    $scope.searchParams.orderBy = id;
                }
                $scope.loadPage(0);
            };

            $scope.loadAll = function () {
                loadInviteCodes();
            };

            $scope.loadPage = function (page) {
                if (page !== undefined) {
                    $scope.searchParams.page = page;
                }
                $scope.loadAll();
            };

            $scope.refresh = function () {
                $scope.loadPage(0);
            };

            $scope.setFilterSummary = function (summary) {
                $scope.filterSummary = summary;
            };

            $scope.openExportConfirmation = function () {
                ngDialog.openConfirm({
                    controller : [ '$scope', function ($scope) {
                        let params = angular.copy($scope.searchParams);
                        params.page = 0;
                        params.size = $scope.searchData.searchResultCount;

                        Object.keys($scope.filteredValues).forEach(key => {
                            params[key] = $scope.filteredValues[key];
                        });

                        $scope.exportCodes = function () {
                            return InviteCodesService.getCodes(params)
                                .then(response => {
                                    let codes = response.data;
                                    $scope.closeThisDialog();
                                    return InviteCodesService.compressTagNames(codes);
                                });
                        };
                    } ],
                    scope    : $scope,
                    template : 'admin-templates/site/inviteCodes/exportConfirmation.modal.html',
                });
            };

            $scope.openCreateNewCodes = function () {
                ngDialog.openConfirm({
                    className        : 'ngdialog-theme-plain custom-width-large large-height-modal',
                    controller       : 'CreateCodesController',
                    controllerAs     : 'vm',
                    height           : '550px',
                    preCloseCallback : function () {
                        $scope.refresh();
                    },
                    resolve : {
                        allTags : function () {
                            return $scope.allTags;
                        },
                        entity : function () {
                            return null;
                        },
                    },
                    template : 'admin-templates/site/inviteCodes/createCodes.modal.html',
                });
            };
            $scope.editCode = function (code) {
                ngDialog.openConfirm({
                    className        : 'ngdialog-theme-plain custom-width-large large-height-modal',
                    controller       : 'EditCodesController',
                    controllerAs     : 'vm',
                    height           : '550px',
                    preCloseCallback : function () {
                        $scope.refresh();
                    },
                    resolve : {
                        allTags : function () {
                            return $scope.allTags;
                        },
                        entity : function () {
                            return code;
                        },
                    },
                    template : 'admin-templates/site/inviteCodes/editCodes.modal.html',
                });
            };
            $scope.getImageUrl = function (logoId) {
                if (logoId != null) {
                    return localWeb.createUrl('/api/file?isThumbnail=true&res=XHDPI&fileName=') + logoId;
                }
                return null;
            };
            $scope.openCreateNewCodesFromCSV = function () {
                ngDialog.openConfirm({
                    controller   : 'CreateCodesFromCSVController',
                    controllerAs : 'vm',
                    scope        : $scope,
                    template     : 'admin-templates/site/inviteCodes/createCodesFromCSV.modal.html',
                });
            };

            /** ******************************
             * private functions
             ********************************/

            function loadTags () {
                // hack for giving us all results, rather than the first 20
                TagService.getTags(99999)
                    .then(data => {
                        $scope.allTags = data.codes;
                    })
                    .catch(error => {
                        console.error('ERROR loading tags: ', error);
                    });
            }

            function loadInviteCodes () {
                let params = angular.copy($scope.searchParams);
                Object.keys($scope.filteredValues).forEach(key => {
                    params[key] = $scope.filteredValues[key];
                });

                // cache search params for reload and browser history
                SessionStorageService.setItem(
                    $scope.storageDomain,
                    $scope.searchParams,
                    $scope.storageDomain,
                );

                InviteCodesService.getCodes(params)
                    .then(response => {
                        $scope.inviteCodes = response.data;
                        $scope.links = ParseLinks.parse(response.headers('link'));
                        $scope.searchData.searchResultCount = response.headers('x-total-count');
                        $scope.now = new Date().getTime();
                        loadTags();
                    })
                    .catch(error => {
                        console.error('ERROR loading codes: ', error);
                    });
            }

            function init () {
                let searchParams = SessionStorageService.getItem($scope.storageDomain);
                if (searchParams) {
                    $scope.searchParams = searchParams;
                }
            }

            init();
        });
}());
