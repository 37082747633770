(function () {
    'use strict';

    /***************************************************************
     * utility for loading container from server, the input object should have the following property and prototype function
     *  -- containerId (property, a number)
     *  -- getContainer()
     *  -- setContainer(container)
     *  -- getProgramId() --> program id, this is needed because containers are loaded by program id
     ***************************************************************/

    angular.module('acadiamasterApp').factory('ContainerLoadUtil', function (ContainerCacheService, AlertService) {

        return {
            loadContainer,
        };

        /**
         * load container from server if needed for the container object
         * @param containerParentObject - parent object that includes a field for container id and function for get/set container
         */
        function loadContainer(containerParentObject) {
            if (requiredFunctionNotDefined(containerParentObject)) {
                AlertService.error('parent object with container is not valid', null, containerParentObject);
                return;
            }

            const containerId = containerParentObject.containerId;
            const container = containerParentObject.getContainer();

            if (containerId == null) {
                // nothing to load
                return;
            }

            if (container != null && container.id === containerId) {
                // already loaded, do nothing
                return;
            }

            ContainerCacheService.loadContainerById(containerId, containerParentObject.getProgramId(), false).then(function(data) {
                containerParentObject.setContainer(data);
            }).catch(function(error) {
                AlertService.error('failed to load container by id : ' + containerId, null, error);
            });
        }

        function requiredFunctionNotDefined(containerParentObject) {
            return !_.isFunction(containerParentObject.getContainer) || !_.isFunction(containerParentObject.setContainer) || !_.isFunction(containerParentObject.getProgramId);
        }

    });
})();
