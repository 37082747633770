(function () {
    'use strict';

    /**
     * form replacement service related to display config, all the business logic is here
     */
    angular.module('acadiamasterApp').factory('DisplayConfigReplacementService', function (
                                                                                           FormModelServiceValueWithUnit,
                                                                                           AlertService, vbrCommonUtil, FormConstants) {

        /***************************************
         * service return call
         ***************************************/

        return {
            apply : apply
        };

        /***************************************************************
         * service return functions
         ***************************************************************/

        /**
         * apply the changes to all selected elements in display search model
         * @param displaySearchModel - search model
         */
        function apply(displaySearchModel) {
            if (displaySearchModel == null) {
                return;
            }

            var itemsToApplyChangesTo = _.filter(displaySearchModel.searchResults, function(element) {
                return displaySearchModel.selected[element.localId];
            });

            // go through each of the items to apply the change
            _.forEach(itemsToApplyChangesTo, function(element) {
                applyChanges(element, displaySearchModel.displayConfigReplaceFilter);
            });

            AlertService.success('changes applied to ' + itemsToApplyChangesTo.length + ' items');
        }


        /***************************************************************
         * private functions
         **************************************************************/

        /**
         * apply some misc filter to a display config
         * @param displayConfig
         * @param displaySearchModel
         *
         * note: filters checked here are visibility, editable, width, gravity
         */
        function applyMiscFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            sm.visibility.enabledAndHasValue() && applyVisibilityChange(dc, sm.visibility.value);
            
            sm.editable.enabledAndHasValue() && applyEditableChange(dc, sm.editable.value);

            // width
            sm.width.enabledAndHasValue() && applyWidthChange(dc, sm.width.value, sm.width.unit);

            // gravity
            sm.gravityH.enabledAndHasValue() && applyGravityHChange(dc, sm.gravityH.value);

            sm.gravityV.enabledAndHasValue() && applyGravityVChange(dc, sm.gravityV.value);
        }

        /**
         * apply font related filter to a display config
         * @param displayConfig
         * @param displaySearchModel
         */
        function applyFontFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            sm.fontSize.enabledAndHasValue() && applyFontSizeChange(dc, sm.fontSize.value);
            sm.fontName.enabledAndHasValue() && applyFontNameChange(dc, sm.fontName.value);
            sm.fontStyleNormal.enabledAndHasValue() && applyFontStyleNormalChange(dc, sm.fontStyleNormal.value);
            sm.fontStyleBold.enabledAndHasValue() && applyFontStyleBoldChange(dc, sm.fontStyleBold.value);
            sm.fontStyleItalic.enabledAndHasValue() && applyFontStyleItalicChange(dc, sm.fontStyleItalic.value);
            sm.fontStyleInherit.enabledAndHasValue() && applyFontStyleInheritChange(dc, sm.fontStyleInherit.value);
        }

        /**
         * apply color related filter to a display config
         * @param displayConfig
         * @param displaySearchModel
         */
        function applyColorFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            sm.color.enabledAndHasValue() && applyColorChange(dc, sm.color.value);
            sm.bgColor.enabledAndHasValue() && applyBgColorChange(dc, sm.bgColor.value);
        }

        /**
         * apply padding related filter to a display config
         * @param displayConfig
         * @param displaySearchModel
         */
        function applyPaddingFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            sm.paddingLeft.enabledAndHasValue() && applyPaddingLeftChange(dc, sm.paddingLeft.value, sm.paddingLeft.unit);
            sm.paddingRight.enabledAndHasValue() && applyPaddingRightChange(dc, sm.paddingRight.value, sm.paddingRight.unit);
            sm.paddingTop.enabledAndHasValue() && applyPaddingTopChange(dc, sm.paddingTop.value, sm.paddingTop.unit);
            sm.paddingBottom.enabledAndHasValue() && applyPaddingBottomChange(dc, sm.paddingBottom.value, sm.paddingBottom.unit);
        }

        /**
         * apply margin related filter to a display config
         * @param displayConfig
         * @param displaySearchModel
         */
        function applyMarginFilters(displayConfig, displaySearchModel) {
            var sm = displaySearchModel;
            var dc = displayConfig;

            sm.marginLeft.enabledAndHasValue() && applyMarginLeftChange(dc, sm.marginLeft.value, sm.marginLeft.unit);
            sm.marginRight.enabledAndHasValue() && applyMarginRightChange(dc, sm.marginRight.value, sm.marginRight.unit);
            sm.marginTop.enabledAndHasValue() && applyMarginTopChange(dc, sm.marginTop.value, sm.marginTop.unit);
            sm.marginBottom.enabledAndHasValue() && applyMarginBottomChange(dc, sm.marginBottom.value, sm.marginBottom.unit);
        }

        /**
         * apply change to an element's display config
         * @param element - element to apply change to
         * @param displayConfigReplacementFilter - display search filter for replacement
         */
        function applyChanges(element, displayConfigReplacementFilter) {
            if (element == null || element.displayConfig == null || displayConfigReplacementFilter==null) {
                // this is just for safety, should never happen
                return;
            }

            // now go through the list of the filters in the system one by one

            // misc item
            applyMiscFilters(element.displayConfig, displayConfigReplacementFilter);

            // fonts related
            applyFontFilters(element.displayConfig, displayConfigReplacementFilter);

            // color related
            applyColorFilters(element.displayConfig, displayConfigReplacementFilter);

            // padding related
            applyPaddingFilters(element.displayConfig, displayConfigReplacementFilter);

            // margin related
            applyMarginFilters(element.displayConfig, displayConfigReplacementFilter);
        }


        /*****            fonts matching functions                 *****/
        /**
         * apply the font style bold changes
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for bold
         */
        function applyFontStyleBoldChange(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.bold)) {
                displayConfig.font.fontStyle.setBold(expectedValue);
            }
        }

        /**
         * apply the font style italic changes
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for italic
         */
        function applyFontStyleItalicChange(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.italic)) {
                displayConfig.font.fontStyle.setItalic(expectedValue);
            }
        }

        /**
         * apply the font style normal changes
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for normal
         */
        function applyFontStyleNormalChange(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.normal)) {
                displayConfig.font.fontStyle.setNormal(expectedValue);
            }
        }

        /**
         * apply the font style inherit changes
         * @param displayConfig
         * @param expectedValue {bool} - expected value of the font style for inherit
         * @returns {boolean}
         */
        function applyFontStyleInheritChange(displayConfig, expectedValue) {
            if (displayConfig && displayConfig.font &&
                displayConfig.font.fontStyle && isPropertyAvailable(displayConfig.font.fontStyle.inherit)) {
                displayConfig.font.fontStyle.setInherit(expectedValue);
            }
        }

        /**
         * apply font name change
         */
        function applyFontNameChange(displayConfig, fontName) {
            if (displayConfig == null || vbrCommonUtil.isNullOrUnavailable(displayConfig.font)) {
                return;
            }

            displayConfig.font.fontName = fontName;
        }


        /**
         * apply the font size change
         * @param displayConfig - font object
         * @param fontSize - font size, null == 14
         */
        function applyFontSizeChange(displayConfig, fontSize) {
            // no display config or no font specified
            if (displayConfig == null || displayConfig.font == vbrCommonUtil.UNAVAILABLE || displayConfig.font == null) {
                return;
            }

            displayConfig.font.fontSize = fontSize;
        }

        /*****        box model matching functions                 *****/
        
        /**
         * apply the display config's top padding change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyPaddingTopChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.padding, 'top', size, unit);
        }

        /**
         * apply the display config's bottom padding change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyPaddingBottomChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.padding, 'bottom', size, unit);
        }

        /**
         * apply the display config's left padding change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyPaddingLeftChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.padding, 'left', size, unit);
        }

        /**
         * apply the display config's right padding change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyPaddingRightChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.padding, 'right', size, unit);
        }


        /**
         * apply the display config's top margin change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyMarginTopChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.margin, 'top', size, unit);
        }

        /**
         * apply the display config's bottom margin change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyMarginBottomChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.margin, 'bottom', size, unit);
        }

        /**
         * apply the display config's left margin change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyMarginLeftChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.margin, 'left', size, unit);
        }

        /**
         * apply the display config's right margin change
         * @param displayConfig - display config object
         * @param size - size to match
         * @param unit - unit to match
         */
        function applyMarginRightChange(displayConfig, size, unit) {
            return applyMarginPaddingChange(displayConfig.margin, 'right', size, unit);
        }

        /**
         * apply margin/padding change for a specific direction
         * @param marginPaddingModel - Margin/Padding model
         * @param paddingDirection - padding direction: left|right|top|bottom
         * @param size - size, null == 0 here
         * @param unit - unit to match
         */
        function applyMarginPaddingChange(marginPaddingModel, paddingDirection, size, unit) {
            if (marginPaddingModel == null || marginPaddingModel == vbrCommonUtil.UNAVAILABLE) {
                return;
            }

            if (marginPaddingModel.configType != FormConstants.marginPaddingType.ADVANCED) {
                convertToAdvancedConfig(marginPaddingModel);
            }

            marginPaddingModel.advancedConfig[paddingDirection] = new FormModelServiceValueWithUnit.ValueWithUnitModel(size, unit);
        }


        /**
         * convert simple config in margin padding into 4 equal advanced config value
         * @param marginPaddingModel - margin padding model
         */
        function convertToAdvancedConfig(marginPaddingModel) {
            var value = 0;
            var unit = 'dp';

            if (marginPaddingModel.configType == FormConstants.marginPaddingType.SIMPLE && marginPaddingModel.simpleConfig != null) {
                // get the value and move them into advanced config
                value = marginPaddingModel.simpleConfig.value;
                unit = marginPaddingModel.simpleConfig.unit;
                marginPaddingModel.simpleConfig = null;
                marginPaddingModel.configType = FormConstants.marginPaddingType.ADVANCED;
            }

            marginPaddingModel.advancedConfig = {
                left: new FormModelServiceValueWithUnit.ValueWithUnitModel(value, unit),
                right: new FormModelServiceValueWithUnit.ValueWithUnitModel(value, unit),
                top: new FormModelServiceValueWithUnit.ValueWithUnitModel(value, unit),
                bottom: new FormModelServiceValueWithUnit.ValueWithUnitModel(value, unit)
            };

            marginPaddingModel.configType = FormConstants.marginPaddingType.ADVANCED;
        }


        /*****        color model matching functions                 *****/
        
        /**
         * apply the color change
         * @param displayConfig - display config object
         * @param colorToChange - color to change to
         */
        function applyColorChange(displayConfig, colorToChange) {
            if (displayConfig == null || vbrCommonUtil.isUnavailable(displayConfig.color)) {
                return;
            }

            displayConfig.color = colorToChange;
        }

        /**
         * apply the background color change
         * @param displayConfig - display config object
         * @param colorToChange - color to change to
         */
        function applyBgColorChange(displayConfig, colorToChange) {
            if (displayConfig == null || vbrCommonUtil.isUnavailable(displayConfig.bgColor)) {
                return;
            }

            displayConfig.bgColor = colorToChange;
        }

        
        /*****        gravity matching functions                 *****/
        /**
         * apply the vertical gravity change
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function applyGravityVChange(displayConfig, gravityValue) {
            if (displayConfig==null || vbrCommonUtil.isNullOrUnavailable(displayConfig.gravity) || displayConfig.gravity.vertical==null) {
                return;
            }

            displayConfig.gravity.vertical = gravityValue;
        }

        /**
         * apply the horizontal gravity change
         * @param displayConfig - display config object
         * @param gravityValue - gravity value, one of the constants defined in FormConstantsService.gravity
         */
        function applyGravityHChange(displayConfig, gravityValue) {
            if (displayConfig==null || vbrCommonUtil.isNullOrUnavailable(displayConfig.gravity) || displayConfig.gravity.horizontal==null) {
                return;
            }

            displayConfig.gravity.horizontal = gravityValue;
        }
        

        /*****            other matching functions                 *****/
        
        function applyVisibilityChange(dc, visibilityValue) {
            if (isPropertyAvailable(dc.visible)) {
                dc.visible = visibilityValue;

                // turn off advancedVisibility configuration when visible is changed to anything not equal to null
                // this probably happens always, but adding the check here just in case we are setting it to null
                // which isn't supported right now, but might be in the future
                if (dc.visible != null) {
                    dc.advancedVisibility = false;
                    dc.advancedVisibilityConfig = {};
                }
            }
        }

        function applyEditableChange(dc, editableChangeValue) {
            if (isPropertyAvailable(dc.editable)) {
                dc.editable = editableChangeValue;
            }
        }

        function applyWidthChange(dc, newValue, newUnit) {
            if (isPropertyAvailable(dc.width)) {
                dc.width = new FormModelServiceValueWithUnit.ValueWithUnitModel(newValue, newUnit);
            }
        }

        /*****            internal util functions                 *****/

        /**
         * apply the property is available or not
         * @param propertyValue
         * @returns {boolean}
         */
        function isPropertyAvailable(propertyValue) {
            return !vbrCommonUtil.isUnavailable(propertyValue);
        }

    });

})();
