angular.module('acadiamasterApp')
    .config(function ($stateProvider) {
        $stateProvider
            .state('programTests.programTestPreview', {
                parent: 'programTests',
                url: '/programTestPreview/{testId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle: 'acadiamasterApp.programTest.preview'
                },
                views: {
                    'content@': {
                        template: '<test-case-preview test-case-id="testId"></test-case-preview>',
                        controller($scope, $stateParams) {
                            $scope.testId = $stateParams.testId;
                        },

                    }
                },
            })
    });
