/**
 * Created by Jason Cao on 08/13/2019
 *
 * directive for add form for a single form container
 */
import template from './addContainerItemSingleForm.html';
angular.module('acadiamasterApp').directive('addContainerItemSingleForm', $state => ({
    link($scope) {
        $scope.formOptions = {
            form: $scope.container.getFormByIndex(0),
            formCategory: $scope.formCategory,
            onChangeCallBack() {
                $scope.container.setSingleForm(this.form);
            },
            title: 'Select Base Form',
            viewOnly: false,
            iconClass: $scope.iconClass,
        };
        $scope.programId = $state?.params?.id;
    },
    restrict: 'E',
    scope: {
        container: '=',
        formCategory: '=',
        iconClass: '<',
    },
    template : template,
}));
