'use strict';

(function () {

    angular.module('acadiamasterApp').directive('pushEnabledConfig', function () {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/programTests/programTest/directive/conditionsDirectives/pushEnabled.html',
            scope: {
                condition: '='
            }
        };
    });
})();
