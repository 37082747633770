(function() {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * program communication category association directive
     */
        .directive('programCommunicationCategoryAssociation', function (vbrCommonUtil , AlertService, $state,ProgramService,$stateParams,
                                                                        ProgramCommunicationCategoryConstantService,
                                                                        ProgramCommunicationCategoryMappingModelService,CommunicationCategoryService,
                                                                        CommunicationCategoryModelService, VbrSelectorService, CONFIG) {
            var nodeTypeMap = createNodeTypeMap();

            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programManagement/program/communicationCategoryAssociation/programCommunicationCategoryAsso.html',
                scope: {
                    program: '='
                },
                link: function ($scope) {
                    $scope.selector = VbrSelectorService.createSelector();
                    $scope.CONFIG = CONFIG;
                    $scope.save = function() {
                        save($scope);
                    };

                    $scope.data = {
                        treeOptions : {
                            accept: function () {
                                // never allow user to drop in/move anything here
                                return false;
                            }
                        },
                        programCommunicationCategoryMappings:[]
                    };
                    $scope.communicationCategoryAssociation = {
                        treeOptions : {
                            accept: accept,
                            beforeDrag: beforeDrag,
                            nodeAdded: nodeAdded
                        },
                        currentSelected:null
                    };

                    $scope.states = {
                        programCommunicationCategoryMappingsReady: false,
                        programReady : false,
                        categoriesReady: false
                    };

                    $scope.addCommunicationCategoryToList = function(category) {
                        addCommunicationCategoryToList($scope,category);
                    };


                    init($scope);

                    $scope.selectCommunicationCategory = function(communicationCategory) {
                        $scope.communicationCategoryAssociation.currentSelected = CommunicationCategoryModelService.convertDtoToCommunicationCategoryModel(communicationCategory);
                    };
                }
            };

            /******************************************************************************
             * private functions
             ******************************************************************************/
            function save($scope) {
                var saveAssociationPromise = CommunicationCategoryService.saveCommunicationCategoryAssociations($scope.program.id,ProgramCommunicationCategoryMappingModelService.loadListDtoFormModels($scope.data.programCommunicationCategoryMappings));
                saveAssociationPromise.then(function(response) {
                    ProgramService.update($scope.program);
                    $state.go('program.manage', {'id':$scope.program.id});
                });
            };
            /**
             * main init function for the directive
             * @param $scope
             */
            function init($scope) {
                let promise = CommunicationCategoryService.getAssociatedCommunicationCategories($stateParams.id);
                $scope.states.programReady = true;
                if (promise) {
                    promise.then(function (result) {
                        $scope.data.programCommunicationCategoryMappings = ProgramCommunicationCategoryMappingModelService.loadModelsFromDtoList(result.data);
                        $scope.states.programCommunicationCategoryMappingsReady = true;
                    });
                }
                loadCategories($scope);
            }

            function loadCategories($scope) {
                let promise = CommunicationCategoryService.getAllCategories();
                return promise.then(function (result) {
                    const programCommunicationsArray = [];
                    result.data.forEach(element => {
                    if(element.id===100 && element.name.en === 'Program Communications'){ // If id and name for program Communications are correct then it will load to the UI
                        programCommunicationsArray.push(element);
                    }
                    });
                    $scope.categories = programCommunicationsArray;
                    $scope.states.categoriesReady = true;
                });
            }

            function addCommunicationCategoryToList($scope,communicationCategory) {
                var newNode = new ProgramCommunicationCategoryMappingModelService.ProgramCommunicationCategoryMappingModel();
                newNode.loadCommunicationCategoryModel(communicationCategory);

                if (communicationCategoryAlreadyInList($scope.data.programCommunicationCategoryMappings, communicationCategory)) {
                    AlertService.warning("This category is already included in list, can not added it again");
                }
                else {
                    $scope.data.programCommunicationCategoryMappings.push(newNode);
                }
            }

            /*******************************
             * tree option functions
             *******************************/

            function getTypeFromModelValue(modelValue) {
                var type = modelValue.nodeType;

                if (type == null) {
                    return ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING;
                }
                return (type.text == null) ? type : type.text;
            }

            /**
             * get node type from the node scope
             * @param nodeScope this will return the node type or node's children type if the modelValue is an array
             * @returns {*}
             */
            function getTypeFromNodeScope(nodeScope) {
                if (nodeScope == null || nodeScope.$modelValue == null) {
                    return null;
                }
                return getTypeFromModelValue(nodeScope.$modelValue);
            }

            function getParentTypeFromNodeScope(nodeScope) {
                if (nodeScope == null || nodeScope.$nodeScope == null) {
                    return ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING;
                }

                return getTypeFromModelValue(nodeScope.$nodeScope.$modelValue);
            }

            function getCurrentModel(nodeScope) {
                if (nodeScope == null || nodeScope.$modelValue == null) {
                    return null;
                }

                return nodeScope.$modelValue;
            }

            /**
             * before drag will be called before user can move a node, it should return true if we can move it, and return false/null otherwise
             * @param sourceNodeScope
             * @returns {boolean}
             */
            function beforeDrag(sourceNodeScope) {
                var sourceType = getTypeFromNodeScope(sourceNodeScope);
                return nodeTypeMap[sourceType].typesCanMoveUnder != null;
            }


            /**
             * this is called before the node is dropped into a location on the tree, this should return true if the drop is allowed
             * when this function is called, we can assume the before drag function has been called on the sourceNodeScope already
             */
            function accept(sourceNodeScope, destNodeScope, destIndex) {
                var sourceType = getTypeFromNodeScope(sourceNodeScope);
                var destType = getParentTypeFromNodeScope(destNodeScope);
                if (nodeTypeMap[sourceType].typesCanMoveUnder.indexOf(destType) != -1) {
                    //this destination type is acceptable, check if it already there or same parent has before.
                    if (sourceType==ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING) {
                        var destArrays = getCurrentModel(destNodeScope);
                        //There is no hierarchy for tree node in this implementation, So fetching the value of source model.
                        var sourceModel = getCurrentModel(sourceNodeScope);
                        if (sourceModel && sourceModel.nodeType && sourceModel.nodeType==ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING) {
                            //same parent, always allowed
                            return true;
                        }
                        else {
                            //  console.log('need to check if it already exist');
                            var currentModel = getCurrentModel(sourceNodeScope);
                            return !communicationCategoryAlreadyInList(destArrays, currentModel);
                        }

                    }
                    else {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }

            function communicationCategoryAlreadyInList(list, communicationCategory) {
                if (list == null) {
                    return false;
                }
                var currentCommunicationCategoryId = communicationCategory.communicationCategoryId==null ? communicationCategory.id : communicationCategory.communicationCategoryId;

                var index = _.findIndex(list, function(mapping) {
                    return mapping.communicationCategoryId == currentCommunicationCategoryId;
                });

                return index!=-1;
            }
            function isCommunicationCategory(node) {
                return (node!=null && node.name);
            }

            function nodeAdded(node) {
                // transform the object, need to get to the parent
                //check whether node comes from available communication categories.
                if (isCommunicationCategory(node)) {
                    var newNode = new ProgramCommunicationCategoryMappingModelService.ProgramCommunicationCategoryMappingModel();
                    newNode.loadCommunicationCategoryModel(node);
                    node = newNode;
                }
                return node;
            }

            function createNodeTypeMap() {
                var map = {};
                map[ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING] = createNodeTypeItem(
                    ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING, [ProgramCommunicationCategoryConstantService.nodeType.COMMUNICATION_CATEGORY_MAPPING], true);
                return map;
            }

            function createNodeTypeItem(nodeType, typesCanMoveUnder, sameParentOnly) {
                if (sameParentOnly == null) {
                    sameParentOnly = false;
                }
                return {
                    nodeType: nodeType,
                    typesCanMoveUnder: typesCanMoveUnder,
                    sameParentOnly: sameParentOnly
                };
            }
        });


})();
