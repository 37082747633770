(function () {
    'use strict';

    /**
     * value label directive, to show which text to show
     */
    angular.module('acadiamasterApp').directive('valueLabel', function (ValueTypeObjectUtil) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/configPanel/common/valueConfig/valueLabel.html',
            scope: {
                isRequired: '=',
                options: '='
            },
            link: function ($scope) {
                $scope.ValueTypeObjectUtil = ValueTypeObjectUtil;

                $scope.getValueType = function() {
                    return $scope.options.getValueType();
                };
            }
        };
    });

})();

