'use strict';

(function () {
    angular.module('acadiamasterApp')
        .service('ProgramTestCaseUtilService',
            function (vbrCommonUtil, VbrTreeUtilService, VbrSelectorService) {
                return {
                    selector: VbrSelectorService,

                    colorUtil: vbrCommonUtil.colorUtil,

                    removeItemFromList: removeItemFromList,
                    findElementById: findElementById,
                };
                function findElementById(list, id) {
                    if (id == null || list == null) {
                        return null;
                    }
                    return _.find(list, {'id': id});
                }
                /**
                 * Method to remove item from specified list
                 * @param list
                 * @param item
                 * @param index
                 * @param selector
                 */
                function removeItemFromList(list, item, index, selector) {
                    if (index == null) {
                        index = _.findIndex(list, function (m) {
                            return m == item;
                        });
                    }

                    if (index != null && index >= 0) {
                        list.splice(index, 1);
                        if (selector != null) {
                            selector.itemRemoved(item);
                        }
                    }
                }
            });
})();

