(function () {
    'use strict';

    angular.module('acadiamasterApp').service('SimpleFileDTOModel', function () {

        function SimpleFileDTOModel() {
            this.file = null;
            this.fileName = null;
            this.fileUrl = null;
            this.fileSize = 0;
            this.localizationMap = {};
        }


        SimpleFileDTOModel.prototype.toDto = function (files) {
            const dto = {};
            dto.fileName = this.fileName;
            dto.fileSize = this.fileSize;
            if (this.file != null) {
                dto.fileName = this.file.name;
                dto.fileSize = this.file.size;
                dto.fileUrl = this.file.name;
                files.push(this.file);
            }
            else if (this.fileUrl == null) {
                dto.fileName = null;
                dto.fileSize = 0;
            }
            else {
                dto.fileUrl = this.fileUrl;
            }

            dto.localizationMap = this.localizationMap;
            if (dto.localizationMap !== null && dto.localizationMap !== undefined) {
                let localization = dto.localizationMap;
                for (let key in localization) {
                    const object = localization[key];
                    const file = object['file'];
                    if (file != null) {
                        object['fileName'] = file.name;
                        object['fileUrl'] = file.name;
                        object['fileSize'] = file.size;
                        files.push(file);
                        delete object['file'];
                    }
                }
            }
            return dto;
        };

        SimpleFileDTOModel.prototype.fromDto = function (dto) {
            this.fileName = dto.fileName;
            this.fileUrl = dto.fileUrl;
            this.fileSize = dto.fileSize;
            this.localizationMap = dto.localizationMap != null ? dto.localizationMap : {};
        };

        /***************************************
         * service return call
         ***************************************/

        return SimpleFileDTOModel;

    });


})();
