import timeUtilService from './service/timeUtil.service';
import linkUtilService from './service/linkUtil.service';

/**
 * Created by jason.cao on 1/30/2019
 * listing directive to list operation histories in the system with pagination control
 */
(function () {
    angular.module('acadiamasterApp').directive('operationHistoryListing', (
        OperationHistoryService, AlertService, OperationStatusService, ngDialog, $state) => {
        function loadData (dataObject) {
            dataObject.isReady = false;
            OperationHistoryService.listAll(dataObject.pageable)
                .then(resp => {
                    dataObject.data = resp.data;
                    dataObject.links = linkUtilService.calculateLinks(resp.data);
                }, error => {
                    console.error('something went wrong in loading data', error);
                    AlertService.error('something went wrong in loading data, check console or error detail');
                }).finally(() => {
                    dataObject.isReady = true;
                });
        }

        /**
         * initialization function, load the data here
         * @param {DirectiveScope} $scope scope object
         */
        function init ($scope) {
            loadData($scope.operationHistoryListDataObject);
        }

        function restartOperation (operationId) {
            OperationHistoryService.restartOperationById(operationId)
                .then(() => {
                    $state.go('toolOperationHistory.detail', { id : operationId });
                }, error => {
                    console.error('something went wrong in restarting operation', error);
                    AlertService.error('something went wrong in restarting operation, check console or error detail');
                });
        }

        return {
            link : function ($scope) {
                $scope.operationHistoryListDataObject = {
                    data : null,

                    isReady : false,
                    links   : {},

                    // pageable object used to control the loading of messages
                    pageable : {
                        page : 0,
                        size : 20,
                        sort : 'id,DESC',
                    },
                };

                $scope.isRunning = function (opHistory) {
                    return OperationStatusService.isRunning(opHistory.status);
                };

                $scope.getDuration = function (opHistory) {
                    return timeUtilService.getDurationAsMinutesAndSeconds(opHistory.startTime, opHistory.endTime,
                        $scope.isRunning(opHistory));
                };

                $scope.stopOperation = function (opHistory) {
                    // show confirmation window
                    ngDialog.openConfirm({
                        controller : 'StopOperationController',
                        data       : opHistory,
                        // eslint-disable-next-line max-len
                        template   : 'admin-templates/site/tools/operationHistory/deleteConfirmation/stopOperationConfirmation.html',
                    }).then(() => {
                        loadData($scope.data);
                    });
                };

                $scope.viewOperation = function (opHistory) {
                    $state.go('toolOperationHistory.detail', { id : opHistory.id });
                };

                $scope.loadPageFunction = function (pageNum) {
                    $scope.operationHistoryListDataObject.pageable.page = pageNum;
                    loadData($scope.operationHistoryListDataObject);
                };

                $scope.restartOperation = function (operationId) {
                    restartOperation(operationId);
                };

                init($scope);
            },
            restrict : 'E',
            scope    : {
            },
            templateUrl : 'admin-templates/site/tools/operationHistory/operationHistoryListing.html',
        };
    });
}());
