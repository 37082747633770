(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ActionSelectionPopupController', function ($scope, RuleExpressionModelService, RuleUtilService) {
            $scope.actionTypeUtil = RuleExpressionModelService.getActionTypeUtil();
            $scope.parentNode = $scope.ngDialogData.parentNode;

            $scope.selectAction = function (actionType) {
                $scope.confirm(actionType);
            };

            $scope.containsAction = function (action){
                const existingHolder = RuleUtilService.findActionHolderByCode($scope.parentNode.actionHolders, action.code);
                return existingHolder != null;
            };
        });
})();
