import ProgramContainerConstantsService from '../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/27/2019
 *
 * Model for an container display config of MultiForm Return of Result
 * this model extends from ContainerMultipleItemsDisplayConfigImpl and few
 * more properties
 */
angular.module('acadiamasterApp')
    .factory('ContainerMultiFormDisplayConfigImpl', (
        ContainerMultipleItemsDisplayConfigImpl, ContainerIconMessageDisplayConfig,
        ContainerItemCompletionStateConfig,
    ) => {
        const { classes } = ProgramContainerConstantsService;

        /**
         * @constructor
         */
        ContainerMultiFormDisplayConfigImpl.inheritsFrom(ContainerMultipleItemsDisplayConfigImpl);

        function ContainerMultiFormDisplayConfigImpl(parent) {
            ContainerMultipleItemsDisplayConfigImpl.call(this, parent);

            // default to hide container when it is all completed
            // so we don't need to configure any all completed state information
            this.hideContainerWhenAllCompleted = false;
            this.allCompletedStateConfig = new ContainerIconMessageDisplayConfig();

            // container item after form completion
            this.itemCompletionStateConfig = new ContainerItemCompletionStateConfig();
        }

        /**
         * convert the current UI model to dto format
         */
        ContainerMultiFormDisplayConfigImpl.prototype.toDto = function (files) {
            const dto = ContainerMultipleItemsDisplayConfigImpl.prototype.toDto.call(this, files);

            dto[classes.classReferenceKey] = classes.ContainerMultiFormDisplayConfigImpl;

            dto.hideContainerWhenAllCompleted = this.hideContainerWhenAllCompleted;

            if (!dto.hideContainerWhenAllCompleted && this.allCompletedStateConfig) {
                dto.allCompletedStateConfig = this.allCompletedStateConfig.toDto(files);
            }

            dto.itemCompletionStateConfig = this.itemCompletionStateConfig.toDto(files);

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        ContainerMultiFormDisplayConfigImpl.prototype.fromDto = function (dto) {
            if (dto == null) {
                return;
            }

            ContainerMultipleItemsDisplayConfigImpl.prototype.fromDto.call(this, dto);

            // this will create empty configuration object if needed
            this.setHideContainerWhenAllCompleted(dto.hideContainerWhenAllCompleted);

            if (dto.allCompletedStateConfig) {
                this.allCompletedStateConfig = new ContainerIconMessageDisplayConfig(this);
                this.allCompletedStateConfig.fromDto(dto.allCompletedStateConfig);
            }

            this.itemCompletionStateConfig.fromDto(dto.itemCompletionStateConfig);
        };

        /**
         * setting the hide container when all items are completed boolean to new state value
         * note: if this value is false (ie: do not hide container when everything is completed), then we
         * will create a new object for all complete state config if it's not initialized already.
         *
         * btw, we do not remove existing object if the input is true, so it can be reused for configuration
         * when user toggle it back and forth on UI.  However, when we send it to server, we will
         * remove the object if value is not needed
         *
         * @param newState - new boolean value to set to for property hideContainerWhenAllCompleted
         */
        ContainerMultiFormDisplayConfigImpl.prototype.setHideContainerWhenAllCompleted = function (newState) {
            this.hideContainerWhenAllCompleted = newState;

            if (!this.hideContainerWhenAllCompleted && this.allCompletedStateConfig == null) {
                // do not hide container when it is empty and we don't have empty state configuration object
                // it's time to create one
                this.allCompletedStateConfig = new ContainerIconMessageDisplayConfig();
            }
        };


        /** *************************************
         * service return call
         ************************************** */

        return ContainerMultiFormDisplayConfigImpl;
    });
