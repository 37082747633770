(function () {
    'use strict';

    /**
     * script action to update multi-select image preset type
     */
    angular.module('acadiamasterApp').factory('UpdateMultiSelectImagePresetAction', function (
        ScriptActionBaseModel, FormScriptActionUtil, ImagePresetConstants, FormConstants) {

        UpdateMultiSelectImagePresetAction.inheritsFrom(ScriptActionBaseModel);

        function UpdateMultiSelectImagePresetAction() {
            this.name = 'Update multi-select image preset type';
            this.ticketId = 'AC-31351';

            this.steps = [
                {
                    description: 'find all multi-select fieilds and set image preset to circular check mark if its single select' +
                    ' and blue checkbox if its multi select',
                    searchFunction: function (formMode) {
                        var targets = FormScriptActionUtil.findSubFieldsByType(formMode, FormConstants.subFieldsType.MULTI_SELECT_OPTIONS);

                        // filter out object that already has non custom image preset type
                        targets = _.filter(targets, function (s) {
                            return presetTypeIsCustom(s.fieldValue);
                        });

                        return targets;
                    },
                    changeFunction: applyPresetType
                },
            ];

            this.description = this.collectDescriptionHTMLFromSteps();
        }

        function applyPresetType(target) {
            if (target.fieldValue.singleLine) {
                target.fieldValue.imagePreset = ImagePresetConstants.CIRCULAR_BLUE;
                target.fieldValue.imagePresetChanged(ImagePresetConstants.CIRCULAR_BLUE);
            } else {
                target.fieldValue.imagePreset = ImagePresetConstants.CHECK_BOX_BLUE;
                target.fieldValue.imagePresetChanged(ImagePresetConstants.CHECK_BOX_BLUE);
            }
        }

        function presetTypeIsCustom(fieldValue) {
            return fieldValue.imagePreset != null && fieldValue.includeImage
                && fieldValue.imagePreset == ImagePresetConstants.CUSTOM;
        }

        /***************************************
         * service return call
         ***************************************/

        return UpdateMultiSelectImagePresetAction;
    });

})();
