/**
 * Directive for displaying data migration payload
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('migrationFields', function (ProgramSubscriptionActiveUsers, ProgramService) {

        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/tools/vxpDataMigration/migrationFields.html',
            scope: {
                isFullMigration: '<?',
                data: '=',
            },
            link: function ($scope) {
                $scope.data = {
                    pdCodes: [],
                    currentPdCode: null,
                    activeUserCount: null,
                    limitUserCount: null,
                    userIds: null,
                    possibleEvents: ['ACCOUNT_INFORMATION_UPDATE',
                        'FORM_ENTRY',
                        'PERSONALIZED_CONTAINER_ITEM_UPDATE',
                        'PERSONALIZED_CONTAINER_UPDATE',
                        'PERSONALIZED_CONTAINER_AND_ITEM_BULK_UPDATE',
                        'PROGRAM_INFORMATION_UPDATE',
                        'SUBSCRIPTION_STATUS_UPDATE'],
                    eventsToMigrate: [],
                };

                loadProgramCodes($scope);

                $scope.getNumberOfActiveUsers = (currentPdCode) => {
                    ProgramSubscriptionActiveUsers.getActiveUsers(currentPdCode)
                        .then((response) => {
                            $scope.data.activeUserCount = response.data;
                        }, (error) => {
                            console.error('Error getting program subscription count', error);
                        });
                };

                addWatches($scope);
            }
        };

        /**
         * Get a list of program codes
         */
        function loadProgramCodes ($scope) {
            ProgramService.query({
                page: 0,
                size: 500,
            }).$promise.then(function(response) {
                let result = response;
                if(result) {
                    // get a list of Program codes
                    $scope.data.pdCodes = result.map(item => {
                        return item.code;
                    });
                }
            });
        }

        /**
         * watches
         * @param $scope
         */
        function addWatches($scope) {
            $scope.$watch('data.currentPdCode', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    $scope.data.currentPdCode = newValue;
                    $scope.getNumberOfActiveUsers(newValue);
                }
            });
        }
    });
})();
