/**
 * Created by moustafabaiou on 2/16/17
 */
(function () {
    angular.module('acadiamasterApp')
        .directive('formFieldConfigFieldValueSliderBaseOptions', (FormUtilService, FormModelServiceFieldValue,
            LocaleConstantsService, $timeout, ValueConfigOptionService, FormConstants) => {
            /**
             * Method to set reference to default option
             * @param $scope
             */
            function setDefaultOptionReference ($scope) {
                if ($scope.fieldValue.defaultSelectedOption != null) {
                    _.forEach($scope.fieldValue.values, optionValue => {
                        if (optionValue.value === $scope.fieldValue.defaultSelectedOption.value
                            && optionValue.text === $scope.fieldValue.defaultSelectedOption.text) {
                            $scope.fieldValue.defaultSelectedOption = optionValue;
                        }
                    });
                }
            }

            /**
             * Init method
             * @param $scope
             */
            function init ($scope) {
                $scope.constants = FormConstants;
                $scope.lcs = LocaleConstantsService;
                $scope.isPreviewReady = false;
                $scope.isDefaultOptionSelected = false;
                $scope.hasChangeModel = $scope.fieldValueChange != null && $scope.fieldValueChange != undefined;

                // Set reference to default selected option
                setDefaultOptionReference($scope);

                // Init preview
                initSliderPreview($scope);
            }

            function initSliderPreview ($scope) {
                $scope.$watch('fieldValue', () => {
                    $timeout(() => {
                        $scope.$broadcast('rzSliderForceRender');
                    });
                }, true);

                $scope.slider = {
                    options : {
                        ceil             : $scope.fieldValue.maxValue,
                        floor            : $scope.fieldValue.minValue,
                        showSelectionBar : true,
                        step             : $scope.fieldValue.selectionIncrementalValue,
                        // showTicks: $scope.fieldValue.showTicks
                    },
                    value : 0,
                };

                if ($scope.fieldValue.displayIncrementalValue != null && $scope.fieldValue.displayIncrementalValue > 0) {
                    $scope.slider.options.showTicks = $scope.fieldValue.maxValue / $scope.fieldValue.displayIncrementalValue;
                }
            }

            function swapPositions (value) {
                let target = null;
                if (value == FormConstants.position.TOP) {
                    target = FormConstants.position.BOTTOM;
                } else if (value == FormConstants.position.BOTTOM) {
                    target = FormConstants.position.TOP;
                }
                return target;
            }

            /**
             * generic function to get property value by key while using hasChangeModel property
             * @param $scope
             * @param key
             */
            function getPropertyValue ($scope, key) {
                let r = null;
                if ($scope.hasChangeModel) {
                    r = $scope.fieldValueChange[key];
                }

                if (r == null) {
                    r = $scope.fieldValue[key];
                }

                return r;
            }

            return {
                link : function ($scope) {
                    init($scope);

                    $scope.valueConfigOption = ValueConfigOptionService.createValueConfigOption(
                        $scope.fieldElement._parent.fieldValue, $scope.fieldValue);

                    $scope.getShowTextLabel = function () {
                        return getPropertyValue($scope, 'showLabel');
                    };

                    $scope.shouldShowScoreField = function () {
                        return $scope.fieldElement._parent.fieldValue.valueType.name == FormConstants.fieldEntryValueType.STRING.name
                            || $scope.fieldElement._parent.type == FormConstants.fieldsType.SLIDER && !$scope.fieldValue.incrementalSlider;
                    };

                    $scope.addNewOption = function (index) {
                        FormUtilService.addNewOption($scope.fieldValue.values, index);
                    };

                    $scope.removeOption = function (index) {
                        FormUtilService.removeOption($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionUp = function (index) {
                        FormUtilService.moveOptionUp($scope.fieldValue.values, index);
                    };

                    $scope.moveOptionDown = function (index) {
                        FormUtilService.moveOptionDown($scope.fieldValue.values, index);
                    };

                    $scope.valuePositionChangedCallBack = function (newSelection) {
                        $scope.fieldValue.labelPosition = swapPositions(newSelection);
                    };
                    $scope.labelPositionChangedCallBack = function (newSelection) {
                        $scope.fieldValue.valuePosition = swapPositions(newSelection);
                    };

                    $scope.selectDefaultSelectedOption = function (option) {
                        if ($scope.fieldValue.defaultSelectedOption !== option) {
                            if ($scope.fieldValue.defaultSelectedOption) {
                                $scope.fieldValue.defaultSelectedOption.selected = false;
                            }
                            $scope.fieldValue.defaultSelectedOption = option;
                        } else {
                            $scope.fieldValue.defaultSelectedOption = null;
                        }
                    };
                },
                restrict : 'E',
                scope    : {
                    displayStrings   : '=',
                    fieldElement     : '=',
                    fieldValue       : '=',
                    fieldValueChange : '=',
                },
                templateUrl : 'admin-templates/site/forms/configPanel/fieldValue/sliderBaseOptions.html',
            };
        });
}());

