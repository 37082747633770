/**
 * Created by moustafabaiou on 4/26/17
 */
(function () {
    'use strict';

    // for setting the values provided by server for configuration of the application
    angular.module('acadiamasterApp')
        .factory('ConfigService', function (CONFIG, $q, $http, localStorageService, $state, $rootScope) {

            var apiUrl = '/api/appConfig';

            return {
                // use this if you need to get the CONFIG object from local storage (meaning its not available in the CONFIG value)
                getConfig: function () {
                    var localStorage = localStorageService.get('CONFIG');
                    angular.extend(CONFIG, localStorage);
                    $rootScope.CONFIG = CONFIG;
                    return CONFIG;
                },
                init: function () {
                    var self = this;
                    if (!window.CONFIG) {
                        self.setConfig(window.CONFIG);
                    } else {
                        var deferred = $q.defer();
                        var success = function (response) {
                            self.setConfig(response.data);
                            deferred.resolve(response);
                        };

                        var error = function (err) {
                            console.error(err);
                            $state.go('error');
                            deferred.reject(err);
                        };

                        $http.get(apiUrl).then(success).catch(error);

                        return deferred.promise;
                    }
                },
                setConfig: function (values) {
                    angular.extend(CONFIG, values);
                    localStorageService.set('CONFIG', CONFIG);
                    $rootScope.CONFIG = CONFIG;
                }
            }

        });

})();
