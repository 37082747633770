(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('programSubscriptions', function (ParticipantProfileService, AlertService, ngDialog, CONFIG) {
            return {
                restrict: 'E',
                scope: {
                    userId: '<',
                    participantId: '<'
                },
                templateUrl: 'admin-templates/site/participant/profile/experience/subscriptions/programSubscriptions.template.html',
                link: function ($scope) {
                    $scope.ids = {
                        activated   : 'activated',
                        id          : 'id',
                        isActive    : 'isActive',
                        programCode : 'programCode',
                        programId   : 'programId',
                        withdraw    : 'withdraw',
                    };

                    $scope.data = {
                        categories: [
                            { name: $scope.ids.id },
                            { name: $scope.ids.programId },
                            { name: $scope.ids.programCode },
                            { name: $scope.ids.isActive, isBoolean: true },
                            { name: $scope.ids.withdraw, isButton: true },
                        ],
                        isDrcEnabled: false,
                        subscriptions: [],
                        isLoading: true,
                        resultCount: 0
                    };

                    function doWithdraw(id, userId, programId) {
                        const params = {
                            id,
                            userId,
                        };
                        ParticipantProfileService.withdrawPrimaryConsentSubscription(params).then(
                            (response) => {
                                AlertService.success('Participant withdrawn successfully');
                                window.location.reload();
                            }, (error) => {
                                console.error('Error withdrawing a subscription', error);
                            }
                        ).finally(loadAll);
                    }

                    /**
                     * If drcEnabled is true, withdraw subscription and drc
                     * If drcEnabled is false, withdraw subscription / primary consent agreement
                     */
                    $scope.withdrawPrimaryConsent = (id, programId) => {
                        if (!CONFIG.drcEnabled) {
                            ngDialog.openConfirm({
                                templateUrl: 'admin-templates/site/participant/profile/experience/subscriptions/primaryConsentWithdraw.html',
                                className: 'ngdialog-theme-plain custom-width-small',
                            }).then(function () {
                                doWithdraw(id, $scope.userId, programId);
                            });
                        } else {
                            ngDialog.open({
                                templateUrl: 'admin-templates/site/participant/profile/experience/subscriptions/drcWithdrawDialog.html',
                                className: 'ngdialog-theme-plain custom-width-medium',
                                data: {
                                    userId: $scope.userId,
                                    participantId: $scope.participantId,
                                    loadSubscription: $scope.loadAll,
                                },
                                controller : ['$scope', function ($scope) {
                                    $scope.closeAndReload = function() {
                                        ngDialog.close();
                                        window.location.reload();
                                    };
                                }],
                            });
                        }
                    };

                    function loadAll() {
                        $scope.data.isDrcEnabled = CONFIG.drcEnabled;
                        ParticipantProfileService.getProgramSubscriptions($scope.userId)
                            .then(function (result) {
                                $scope.data.subscriptions = result.data;
                                $scope.data.resultCount = result.data.length;
                            }, function (error) {
                                $scope.data.resultCount = 0;
                                console.error(error);
                            }).finally(function () {
                                $scope.data.isLoading = false;
                            });
                    }

                    function init() {
                        loadAll();
                    }
                    $scope.loadAll = loadAll;
                    init();
                }
            };
        });
})();
