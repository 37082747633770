/* eslint-disable eqeqeq */
import ProgramContainerConstantsService from '../../../const/programContainer.constants.service';

/**
 * Created by Jason Cao on 08/13/2019
 *
 * directive for adding container items, this is the parent directive that will
 * in term load other directives
 */

angular.module('acadiamasterApp').directive('addContainerItems', FormConstants => ({
    link ($scope) {
        // get some types from the constants for faster access
        $scope.types = {
            appointmentType        : ProgramContainerConstantsService.findTypeByName('APPOINTMENTS').name,
            hybridItemsType        : ProgramContainerConstantsService.findTypeByName('DATA_RETURN_OF_RESULTS').name,
            multiFormType          : ProgramContainerConstantsService.findTypeByName('MULTI_FORM').name,
            singleFormType         : ProgramContainerConstantsService.findTypeByName('SINGLE_FORM').name,
            singleQuestionFormType : ProgramContainerConstantsService.findTypeByName('SINGLE_QUESTION_FORM').name,
            subPageType            : ProgramContainerConstantsService.findTypeByName('DATA_RETURN_OF_RESULTS').name,
        };

        // utility functions to verify if it's multi-form or single form
        $scope.shouldUseSingleForm = () => {
            const { containerType } = $scope.container;
            const { types } = $scope;

            return containerType == types.singleFormType;
        };

        $scope.shouldUseMultiForm = () => {
            const { containerType } = $scope.container;
            const { types } = $scope;
            return containerType == types.singleQuestionFormType
                || containerType == types.multiFormType;
        };

        $scope.shouldUseHybridItems = () => {
            const { containerType } = $scope.container;
            const { types } = $scope;
            return containerType == types.hybridItemsType;
        };

        $scope.shouldUseAppointmentType = () => {
            const { containerType } = $scope.container;
            const { types } = $scope;
            return containerType == types.appointmentType;
        };

        $scope.getFormCategory = () => {
            const { containerType } = $scope.container;
            const { types } = $scope;
            const { formCategories } = FormConstants;

            if (containerType == types.singleQuestionFormType) {
                return formCategories.SNAP_QUESTION_FORM;
            }

            return null;
        };
    },
    restrict : 'E',
    scope    : {
        container : '=',
        iconClass : '@',
        selector  : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/'
        + 'config/containerItem/addItem/addContainerItems.html',
}));
