/**
 * Created by Jamie Nola on 09/07/2018
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .directive('searchFilterDateRange', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/components/searchFilter/fields/dateRange/searchFilterDateRange.template.html',
                controllerAs: 'drf',
                scope: {
                    filter: '=',
                    value: '=',
                    headerText: '@?',
                    fromText: '@?',
                    toText: '@?'
                },
                controller: function ($scope) {
                    var vm = this;
                    vm.filter = $scope.filter;

                    var internalValue = {
                        start: $scope.value && $scope.value.start || null,
                        end: $scope.value && $scope.value.end || null
                    };

                    vm.startDate = internalValue.start ? new Date(internalValue.start) : null;
                    vm.endDate = internalValue.end ? new Date(internalValue.end) : null;

                    /* Bind UI Actions to View-Model */
                    vm.onStartDateChange = onStartDateChange;
                    vm.onEndDateChange = onEndDateChange;

                    function onStartDateChange() {
                        if ((vm.endDate && vm.startDate === undefined) ||
                        (vm.startDate && vm.endDate && vm.startDate >= vm.endDate)) {
                            vm.startDate = new Date(vm.endDate.getTime());
                        }
                        internalValue.start = vm.startDate ? new Date(vm.startDate) : null;
                        exportValues();
                    }

                    function onEndDateChange() {
                        if ((vm.startDate && vm.endDate === undefined) ||
                        (vm.startDate && vm.endDate && vm.startDate >= vm.endDate)) {
                            vm.endDate = new Date(vm.startDate.getTime());
                        }
                        internalValue.end = vm.endDate ? new Date(vm.endDate) : null;
                        exportValues();
                    }

                    function exportValues() {
                        $scope.value = internalValue.start || internalValue.end ? internalValue : null;
                    }
                }
            };
        });
})();
