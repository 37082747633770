/**
 * Created by Jamie Nola on 06/26/2018
 *
 * @file Utility class to help with easing animations.
 *
 * To tween values, use the following parameters:
 * t: current time
 * b: start value
 * c: change in value
 * d: duration
 *
 * These functions were taken from http://www.gizma.com/easing.
 */

(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .factory('easing', function () {
            return {
                linearTween: linearTween,

                easeInQuad: easeInQuad,
                easeOutQuad: easeOutQuad,
                easeInOutQuad: easeInOutQuad,

                easeInCubic: easeInCubic,
                easeOutCubic: easeOutCubic,
                easeInOutCubic: easeInOutCubic,

                easeInQuart: easeInQuart,
                easeOutQuart: easeOutQuart,
                easeInOutQuart: easeInOutQuart,

                easeInQuint: easeInQuint,
                easeOutQuint: easeOutQuint,
                easeInOutQuint: easeInOutQuint,

                easeInSine: easeInSine,
                easeOutSine: easeOutSine,
                easeInOutSine: easeInOutSine,

                easeInExpo: easeInExpo,
                easeOutExpo: easeOutExpo,
                easeInOutExpo: easeInOutExpo,

                easeInCirc: easeInCirc,
                easeOutCirc: easeOutCirc,
                easeInOutCirc: easeInOutCirc
            };

            /*****************************************************************
             * internal functions
             *****************************************************************/

            // simple linear tweening - no easing, no acceleration
            function linearTween(t, b, c, d) {
                return c * t / d + b;
            };

            // quadratic easing in - accelerating from zero velocity
            function easeInQuad(t, b, c, d) {
                t /= d;
                return c * t * t + b;
            };

            // quadratic easing out - decelerating to zero velocity
            function easeOutQuad(t, b, c, d) {
                t /= d;
                return -c * t * (t - 2) + b;
            };

            // quadratic easing in/out - acceleration until halfway, then deceleration
            function easeInOutQuad(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return c / 2 * t * t + b;
                }
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            };

            // cubic easing in - accelerating from zero velocity
            function easeInCubic(t, b, c, d) {
                t /= d;
                return c * t * t * t + b;
            };

            // cubic easing out - decelerating to zero velocity
            function easeOutCubic(t, b, c, d) {
                t /= d;
                t--;
                return c * (t * t * t + 1) + b;
            };

            // cubic easing in/out - acceleration until halfway, then deceleration
            function easeInOutCubic(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return c / 2 * t * t * t + b;
                }
                t -= 2;
                return c / 2 * (t * t * t + 2) + b;
            };


            // quartic easing in - accelerating from zero velocity
            function easeInQuart(t, b, c, d) {
                t /= d;
                return c * t * t * t * t + b;
            };

            // quartic easing out - decelerating to zero velocity
            function easeOutQuart(t, b, c, d) {
                t /= d;
                t--;
                return -c * (t * t * t * t - 1) + b;
            };

            // quartic easing in/out - acceleration until halfway, then deceleration
            function easeInOutQuart(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return c / 2 * t * t * t * t + b;
                }
                t -= 2;
                return -c / 2 * (t * t * t * t - 2) + b;
            };

            // quintic easing in - accelerating from zero velocity
            function easeInQuint(t, b, c, d) {
                t /= d;
                return c * t * t * t * t * t + b;
            };

            // quintic easing out - decelerating to zero velocity
            function easeOutQuint(t, b, c, d) {
                t /= d;
                t--;
                return c * (t * t * t * t * t + 1) + b;
            };

            // quintic easing in/out - acceleration until halfway, then deceleration
            function easeInOutQuint(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return c / 2 * t * t * t * t * t + b;
                }
                t -= 2;
                return c / 2 * (t * t * t * t * t + 2) + b;
            };

            // sinusoidal easing in - accelerating from zero velocity
            function easeInSine(t, b, c, d) {
                return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
            };

            // sinusoidal easing out - decelerating to zero velocity
            function easeOutSine(t, b, c, d) {
                return c * Math.sin(t / d * (Math.PI / 2)) + b;
            };

            // sinusoidal easing in/out - accelerating until halfway, then decelerating
            function easeInOutSine(t, b, c, d) {
                return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
            };

            // exponential easing in - accelerating from zero velocity
            function easeInExpo(t, b, c, d) {
                return c * Math.pow(2, 10 * (t / d - 1)) + b;
            };

            // exponential easing out - decelerating to zero velocity
            function easeOutExpo(t, b, c, d) {
                return c * (-Math.pow(2, -10 * t / d) + 1) + b;
            };

            // exponential easing in/out - accelerating until halfway, then decelerating
            function easeInOutExpo(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
                }
                t--;
                return c / 2 * (-Math.pow(2, -10 * t) + 2) + b;
            };

            // circular easing in - accelerating from zero velocity
            function easeInCirc(t, b, c, d) {
                t /= d;
                return -c * (Math.sqrt(1 - t * t) - 1) + b;
            };

            // circular easing out - decelerating to zero velocity
            function easeOutCirc(t, b, c, d) {
                t /= d;
                t--;
                return c * Math.sqrt(1 - t * t) + b;
            };

            // circular easing in/out - acceleration until halfway, then deceleration
            function easeInOutCirc(t, b, c, d) {
                t /= d / 2;
                if (t < 1) {
                    return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
                }
                t -= 2;
                return c / 2 * (Math.sqrt(1 - t * t) + 1) + b;
            };
        });
})();
