(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('formCompletionAction',
        function (LocaleConstantsService, $uibModal, ExpressionConstantsService, FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/formCompletionAction/formCompletionAction.html',
                scope: {
                    element: '=configModel',
                    parentForm: '=',
                    isButtonConfig: '=',
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.lcs = LocaleConstantsService;
                    $scope.expConstants = ExpressionConstantsService;
                }
            };
        });
})();

