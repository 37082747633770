(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * service for content model
     */
        .factory('ProgramTestTagMappingModelService',
            function (ModelServiceBase) {
                ProgramTestTagMappingModel.inheritsFrom(ModelServiceBase.BaseTreeNodeModel);
                /***
                 * Program  Test Tag mapping
                 * @constructor
                 */
                function ProgramTestTagMappingModel(){
                    ModelServiceBase.BaseTreeNodeModel.call(this, true, false,null);
                    this.id = null;
                    this.programTestSuite = null;
                    this.tag = null;
                };
                ProgramTestTagMappingModel.prototype.toDto = function(){
                    var dto = {};
                    dto.id = this.id;
                    dto.programTestSuite = this.programTestSuite.toDto();
                    dto.tag = this.tag;
                    return dto;
                };
                ProgramTestTagMappingModel.prototype.fromDto = function(dto){
                    this.id = dto.id;
                    this.programTestSuite.fromDto(dto.programTestSuite);
                };
                /***************************************
                 * utility functions
                 ***************************************/
                function loadModelFromDto(dto) {
                    var model = new ProgramTestTagMappingModel();
                    model.fromDto(dto);
                    return model;
                }

                /***************************************
                 * service return call
                 ***************************************/
                return {
                    loadModelFromDto: loadModelFromDto,
                    ProgramTestTagMappingModel: ProgramTestTagMappingModel
                };
            });
})();
