(function () {
    'use strict';

    /***************************************************************
     * utility for loading container item from server, the input object should have the following property and prototype function
     *  -- containerItemId (property, a number)
     *  -- getContainerItem()
     *  -- setContainerItem(containerItem)
     *  -- getProgramId() --> program id, this is needed because container items are loaded by program id
     ***************************************************************/

    angular.module('acadiamasterApp').factory('ContainerItemLoadUtil', function (ContainerCacheService, AlertService) {

        return {
            loadContainerItem,
        };

        /**
         * load container item from server if needed for the container object
         * @param parentObject - parent object that includes a field for container item id and function for get/set container item
         */
        function loadContainerItem(parentObject) {
            if (requiredFunctionNotDefined(parentObject)) {
                AlertService.error('parent object with container is not valid', null, parentObject);
                return;
            }

            const containerItemId = parentObject.containerItemId;
            const containerItem = parentObject.getContainerItem();

            if (containerItemId == null) {
                // nothing to load
                return;
            }

            if (containerItem != null && containerItem.id === containerItemId) {
                // already loaded, do nothing
                return;
            }

            ContainerCacheService.loadContainerItemById(containerItemId, parentObject.getProgramId(), false).then(function(data) {
                parentObject.setContainerItem(data);
            }).catch(function(error) {
                AlertService.error('failed to load container item by id : ' + containerItemId, null, error);
            });
        }

        function requiredFunctionNotDefined(containerParentObject) {
            return !_.isFunction(containerParentObject.getContainerItem) ||
                !_.isFunction(containerParentObject.setContainerItem) ||
                !_.isFunction(containerParentObject.getProgramId);
        }

    });
})();
