
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('RegExpConstantsService', [function () {
            return {
                email: '^\\s*[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,6}\\s*$'
            }
        }]);

})();
