/**
 * Created by Jason Cao on 08/12/2019
 *
 * directive for configuring an state with
 * 1. remove after complete flag
 * 2. removal delay interval (in seconds)
 * 3. title
 * 4. message
 *
 * everything is optional except for
 * 1. remove after complete flag
 *
 *
 * if remove after complete flag is true, then removal delay interval is also required
 */
angular.module('acadiamasterApp').directive(
    'containerDisplayConfigStateCompletedItem',
    (LocaleConstantsService) => ({
        link: ($scope) => {
            $scope.lcs = LocaleConstantsService;
        },
        restrict: 'E',
        scope: {
            // @Required - display config state should be of type : ContainerItemCompletionStateConfig
            displayConfigState: '=',
            previewUrl: '@',
            isActionItem       : '<?',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
            + 'config/containerDisplayConfig/' +
            'containerDisplayConfigStateCompletedItem.html',
    }));
