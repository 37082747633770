/**
 * Created by kjirage on 01/09/18
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .controller('ConfigurationIdsController', function ($scope, ConfigurationIdsService) {

            $scope.configurationIds = [];

            $scope.loadAll = function () {
                loadConfigurationIds();
            };

            $scope.loadAll();

            $scope.exportConfigurationIds = function () {
                return ConfigurationIdsService.getConfigurationIds()
                    .then(function (response) {
                        var list = response.data;
                        var csvList = [];
                        var i = 0;
                        angular.forEach(list, function (value, key) {
                            var row = {
                                "TABLE_NAME": key,
                                "AUTO_INCREMENT": value
                            };
                            csvList[i++] = row;
                        });
                        return csvList;
                    });
            };

            /********************************
             * private functions
             ********************************/

            function loadConfigurationIds() {
                ConfigurationIdsService.getConfigurationIds()
                    .then(function (response) {
                        $scope.configurationIds = response.data;
                    })
                    .catch(function (error) {
                        console.error('ERROR loading DB Ids : ', error);
                    });
            }

        });

})();

