/**
 * Created by jason on 1/6/17.
 * service for all the constants necessary for models used in container
 */
(function () {
    'use strict';

    /**
     * service to store all the constants needed for container pages
     * todo: this class should be removed and whatever used to be merged with container constant file
     */
    angular.module('acadiamasterApp').factory('ProgramFormConstantsService', function () {
        var basePackageBehaviorTemplate = 'com.vibrent.acadia.web.rest.dto.templates.';

        var formBehaviorTemplate = {
            NONE: {name: "NONE", text: "None"},
            ONE_TIME_SURVEY: {name: "ONE_TIME_SURVEY", text: "One Time Survey"}
        };

        var formState = {
            UNLOCKED: 'UNLOCKED',
            LOCKED: 'LOCKED',
            VISIBLE: 'VISIBLE',
            COMPLETED: 'COMPLETED',
            PAUSED: 'PAUSED'
        };

        var timeConfigurationType = {
            NONE: {name: "NONE", text: "None"},
            PROGRAM_DAY: {name: "PROGRAM_DAY", text: "Program Day"},
            ABSOLUTE: {name: "ABSOLUTE", text: "Absolute"},
            DELTA: {name: "DELTA", text: "Delta"}
        };

        var templateMessageType = {
            AFTER_COMPLETION: {name: "AFTER_COMPLETION", text: "After Completion"},
            BEFORE_EXPIRE: {name: "BEFORE_EXPIRE", text: "Before Expire"},
            AFTER_ADDED: {name: "AFTER_ADDED", text: "After Added"},
            AFTER_EXPIRED: {name: "AFTER_EXPIRED", text: "After Expired"},
            AFTER_PAUSED: {name: "AFTER_PAUSED", text: "After Paused"}
        };

        return {
            DEFAULT_GROUP_NAME : '___DEFAULT_GROUP___',

            formBehaviorTemplate: formBehaviorTemplate,

            formState: formState,

            timeConfigurationType: timeConfigurationType,

            templateMessageType : templateMessageType,

            classes: {
                classReferenceKey: '@class',

                FormBehaviorTemplateOneTimeSurvey: basePackageBehaviorTemplate + 'FormBehaviorTemplateOneTimeSurvey',
                FormBehaviorTemplate: basePackageBehaviorTemplate + 'FormBehaviorTemplate'
            },

            // todo: some of the node type might not be used
            nodeType: {
                PROGRAM: 'program',
                GROUP: 'group',
                CONTAINER_ITEM: 'containerItem'
            }
        }
    });
})();
