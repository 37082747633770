'use strict';

angular.module('acadiamasterApp')
    .controller('ExternalLogDetailController', function ($scope, $stateParams, $uibModalInstance, entity) {
        $scope.log = entity;
        $scope.log.$promise.then(function() {
            $scope.log.requestBodyAsString = $scope.log.requestBody;
            $scope.log.responseBodyAsString = $scope.log.responseBody;

            $scope.log.requestBody = $scope.log.requestBody && JSON.parse($scope.log.requestBody);
            $scope.log.responseBody = $scope.log.responseBody && JSON.parse($scope.log.responseBody);
        });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    });
