/**
 * directive for configuring a behavior template for program container
 */
angular.module('acadiamasterApp').directive('containerRecalculatePersonalized', (
    $state, ContainerMgmtService) => ({
    restrict: 'E',
    scope: {
        programId: '@'
    },
    templateUrl: 'admin-templates/site/programManagement/program/containerManagement/' +
        'calculatePersonalized/calculatePersonalized.html',
    link($scope) {

        $scope.performRecalculation = () => {
            ContainerMgmtService.recalculatePersonalized($scope.programId)
                .then((resp) => {
                    const operationId = resp.data.id;
                    $state.go('toolOperationHistory.detail', {id: operationId});
                }, (error) => {
                    console.error('Error starting the re-calculation', error);
                });
        };

        $scope.cancel = () => {
            $state.go('^');
        };
    },
}));
