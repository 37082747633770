/**
 * created by Harshita Bhorshetti at 07/09/2020
 */
angular.module('acadiamasterApp').directive(
    'containerItemTypeSpecificConfigAppointment',
    (LocaleConstantsService, appointmentCategoriesService, AlertService) => ({
        link ($scope) {
            $scope.lcs = LocaleConstantsService;
            $scope.openEligibleSection = false;
            $scope.openUpcomingSection = false;
            $scope.openPastSection = false;
            $scope.openCancellationSection = false;
            $scope.showCategories = false;
            $scope.showURL = false;
            $scope.config.externalService = false;
            $scope.programId = $scope.config.getRoot().programId;
            appointmentCategoriesService.getAppointmentCategories($scope.programId).then(response => {
                if (response == null || response.data == null || response.status !== 200) {
                    AlertService.error(`Failed to get appointment category data for Program Id: ${$scope.programId}`);
                    return;
                } else if (response.data.length === 0) {
                    AlertService.warning('No appointment categories found');
                    return;
                }
                $scope.categories = response.data;
                $scope.showCategories = true;
                $scope.showAppointmentURL = function () {
                    $scope.categories.every(item => {
                        if (item.name === $scope.config.appointmentCategory) {
                            // eslint-disable-next-line eqeqeq
                            if (item.isExternalService == true) {
                                $scope.config.externalService = true;
                                $scope.showURL = true;
                            } else {
                                $scope.config.externalService = false;
                                $scope.showURL = false;
                            }
                            return false;
                        }
                        return true;
                    });
                };
                $scope.showAppointmentURL();
            }, error => {
                AlertService.warning('Error while loading the appointment categories', error);
            });
        },
        restrict : 'E',
        scope    : {
            config : '=',
        },
        templateUrl : 'admin-templates/site/programManagement/program/containerManagement'
        + '/config/containerItem/typeSpecific/containerItemTypeSpecificConfigAppointment.html',
    }),
);
