angular.module('acadiamasterApp')

    /**
     * deep links directive
     */
    .directive('exportWizardStepDeepLinks', (DeepLinkService, DeepLinkModel, AlertService, DeepLinkConstants) => {

        function loadAll($scope) {
            const params = {
                programId: $scope.program.id
            };
            $scope.data.isLoading = true;

            DeepLinkService.loadLinks(params.programId).then((response) => {
                if (response == null || response.data == null || response.status !== 200) {
                    AlertService.error(
                        `Failed to load deep link data for Program Id: ${params.programId}`,
                    );
                    return;
                }

                $scope.data.links = response.data.map(dto => new DeepLinkModel().fromDto(dto));

                // trim string for display
                $scope.data.links.forEach(link => {
                    if(link.hasOwnProperty('availabilityExpression') &&
                        link.availabilityExpression &&
                        link.availabilityExpression.length > 100) {
                        link.availabilityExpression = `${link.availabilityExpression.substring(0, 100).trim()}...`;
                    }
                });

                if (response.data.length === 0) {
                    AlertService.warning('No deep links were found');
                }

            }, (error) => {
                $scope.data.loadingError = true;
                console.error('Error loading deep links', error);
            }).finally(() => {
                $scope.data.isLoading = false;
            });
        }

        /**
         * main init function for the directive
         * @param $scope
         */
        function init($scope) {
            $scope.cs = DeepLinkConstants;
            $scope.fields = [
                $scope.cs.FIELDS.ID,
                $scope.cs.FIELDS.CODE,
                $scope.cs.FIELDS.TARGET_TYPE,
                $scope.cs.FIELDS.PAGE,
                $scope.cs.FIELDS.DETAILS,
                $scope.cs.FIELDS.ENABLED,
                $scope.cs.FIELDS.AVAILABILITY_EXPRESSION,
            ];

            $scope.detailFields = [
                $scope.cs.FIELDS.FORM_ID,
                $scope.cs.FIELDS.CONTAINER_ID,
                $scope.cs.FIELDS.CONTAINER_ITEM_ID,
                $scope.cs.FIELDS.WORKFLOW_NAME,
            ];

            $scope.data = {
                links: [],
                isLoading: true,
                loadingError: false,
            };

            $scope.searchData = {
                ascending: false,
                orderBy: $scope.cs.FIELDS.ID,
            };


            $scope.setOrderBy = (fieldId) => {
                if ($scope.searchData.orderBy === fieldId) {
                    $scope.searchData.ascending = !$scope.searchData.ascending;
                } else {
                    $scope.searchData.orderBy = fieldId;
                    $scope.searchData.ascending = true;
                }
            };

            loadAll($scope);
        }

        return {
            controller: ($scope) => {
                init($scope);

                $scope.selectAll = () => {
                    if ($scope.selectedData.includeDeepLinks && $scope.data.links) {
                        $scope.selectedData.deepLinks = $scope.data.links;
                    } else {
                        $scope.selectedData.deepLinks = [];
                    }
                };

                $scope.sortBy = (name) => {
                    $scope.data.ascending = ($scope.data.orderBy === name)
                        ? !$scope.data.ascending
                        : true;
                    $scope.data.orderBy = name;
                };

                $scope.$watch('selectedData.includeDeepLinks', (nv) => {
                    if(nv){
                        $scope.selectedData.deepLinks = $scope.data.links;
                    }
                });
            },
            restrict: 'E',
            scope: {
                program: '=',
                selectedData: '=',
                wizardConstant: '=',
            },
            templateUrl: 'admin-templates/site/programManagement/program/exportWizard/steps/exportWizardStepDeepLinks.html',
        };
    });
