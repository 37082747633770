/**
 * Created by duribe on 11/30/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
    /**
     * directive for display config
     */
        .directive('fieldInsightsConfig', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/insightsConfig/insightsConfig.html',
                scope: {
                    element: '=configModel',
                    insightsConfigChange : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;
                    $scope.chartType = FormConstants.insightsConfigConst.visualizationMode;
                }
            }
        });

})();