(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('CheckMultiSubscriptionCondition', function (QObjectModel, ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        CheckMultiSubscriptionCondition.inheritsFrom(QObjectModel);

        function CheckMultiSubscriptionCondition(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.CHECK_MULTIPLE_SUBSCRIPTION);
        }

        /**
         * convert the current UI model to dto format
         */
        CheckMultiSubscriptionCondition.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);
            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any format you have on the current object
         * @param dto
         */
        CheckMultiSubscriptionCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);
        };

        CheckMultiSubscriptionCondition.prototype.getDescriptionAsHtml = function () {
            return 'Check for users having multiple active subsriptions for this program';
        };

        CheckMultiSubscriptionCondition.prototype._validateSelf = function () {
            this.clearError();
        };

        /***************************************
         * service return call
         ***************************************/
        return CheckMultiSubscriptionCondition;

    });
})();
