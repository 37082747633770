(function () {

    'use strict';

    angular.module('acadiamasterApp')
        .controller('UnitDialogController',
            function($scope, $stateParams, $uibModalInstance, entity, isNew, Unit, UnitCategory, UnitsLookup, AlertService, $translate) {

                $scope.unit = entity;
                $scope.unitcategorys = UnitCategory.query();
                $scope.isNew = isNew;
                $scope.unitCategoryChanged = false;

                if(!isNew){
                    $scope.unit.$promise.then(function() {
                        if($scope.unit.conversionFormula!=null){
                            $scope.unit.conversionType = "conversionFormula";
                            $scope.unit.conversionRatioAgainstBase=null;
                        } else if($scope.unit.conversionRatioAgainstBase!=null){
                            $scope.unit.conversionType = "conversionRatioAgainstBase";
                            $scope.unit.conversionFormula=null;
                        }

                        $scope.oldUnitCategory = $scope.unit.unitCategoryId;
                        $scope.oldUnitCategoryName = $scope.unit.unitCategoryName
                    });
                }

                $scope.load = function(id) {
                    Unit.get({id : id}, function(result) {
                        $scope.unit = result;
                    });
                };

                var onSaveFinished = function (result) {
                    $scope.$emit('acadiamasterApp:unitUpdate', result);
                    $uibModalInstance.close(result);
                    UnitsLookup.updateUnits();
                };

                function setConversionTypeValue($scope){
                    // Set conversion formula to null if ratio value specified and vice versa.
                    if($scope.unit.conversionType == "conversionFormula"){
                        $scope.unit.conversionRatioAgainstBase=null;
                    } else if($scope.unit.conversionType == "conversionRatioAgainstBase"){
                        $scope.unit.conversionFormula=null;
                    }
                }

                $scope.save = function () {
                    // Modify conversion formula and ratio.
                    setConversionTypeValue($scope);

                    if ($scope.unit.id != null) {
                        Unit.update($scope.unit, onSaveFinished);
                    } else {
                        Unit.save($scope.unit, onSaveFinished);
                    }
                };

                $scope.clear = function() {
                    $uibModalInstance.dismiss('cancel');
                };

                $scope.onUnitCategoryChange = function (){
                    if(!$scope.isNew){
                        if($scope.oldUnitCategory != $scope.unit.unitCategoryId){
                            $scope.unitCategoryChanged = true;
                            var msg;
                            if($scope.unit.baseUnit){
                                msg = $translate.instant("acadiamasterApp.unit.categoryChangedForBase", {param: $scope.oldUnitCategoryName});
                            } else {
                                msg = $translate.instant("acadiamasterApp.unit.categoryChanged");
                            }
                            AlertService.warning(msg);
                        } else {
                            $scope.unitCategoryChanged = false;
                        }
                    }
                }
            });
})();
