/**
 * service for dealing with the api of operation history message
 */
angular.module('acadiamasterApp').factory('OperationHistoryMessageService', function ($http, $httpParamSerializer, localWeb) {
    const baseUrl = '/api/admin/operationHistoryMessage';

    /***************************************
     * service return call
     ***************************************/

    return {
        query,
    };

    /* **************************************************************
     * service return functions
     ************************************************************** */

    /**
     * listing the operation history based on page number and page size
     * @returns {HttpPromise} - return a http promise for response from server api, it's data should
     *          contain a pageable object of the operation history messages as content
     */
    function query(operationId, loggingLevels, search, pageable) {
        const queryParamString = $httpParamSerializer(pageable);
        if (loggingLevels === undefined) { loggingLevels = ''; }

        if (search === undefined){ search = ''; }
        var url = localWeb.createUrl(`${baseUrl}/${operationId}`);
        url += '?loggingLevels=' + loggingLevels;
        url += '&search=' + search;
        url += '&' + queryParamString;
        return $http.get(localWeb.createUrl(url));
    }

});
