'use strict';

/**
 * drc related services
 */
(function() {
    angular.module('acadiamasterApp').factory('ehrService', function ($http, localWeb) {
        var baseUrl = 'api/ehr';

        return {
            /**
             * Get EHR submission user count
             * @returns {HttpPromise}
             */
            ehrUserConsentSubmissionCount : function () {
                return $http.get(localWeb.createUrl(baseUrl + "/ehrUserConsentSubmissionCount"));
            },
            /**
             * Get EHR data to DRC upload failed count
             * @returns {HttpPromise}
             */
            ehrToDrcFailedCount : function () {
                return $http.get(localWeb.createUrl(baseUrl + "/ehrToDrcFailedCount"));
            },
            /**
             * get provider pair count
             * @param practiceCode
             * @returns {HttpPromise}
             */
            ehrProviderUserPairCount : function (practiceCode) {
                var url= localWeb.createUrl(baseUrl + "/ehrProviderUserPairCount");
                return $http.get(url,{params:{practiceCode:practiceCode}});
            },
            /**
             * get list of providers
             * @returns {HttpPromise}
             */
            providers : function () {
                return $http.get(localWeb.createUrl(baseUrl + "/providersList"));
            },
            /**
             * Sync EHR users call
             * @param data
             * @returns {HttpPromise}
             */
            syncUserEHR : function(data) {
                 var url = localWeb.createUrl(baseUrl + "/syncUserEHR");

                var config = {
                    params: data,
                    headers : {'Accept' : 'application/json'}
                };
                return $http.get(url, config)
            },
            /**
             * upload data to DRC
             * @returns {HttpPromise}
             */
            uploadToDRC : function() {
                return $http.get(localWeb.createUrl(baseUrl + "/uploadToDRC"));
            },
            /**
             * search users paired with specific provider
             * @param data
             * @returns {HttpPromise}
             */
            searchEHRUsers : function(data) {
                var url = localWeb.createUrl(baseUrl + "/searchUser");

                var config = {
                    params: data,
                    headers : {'Accept' : 'application/json'}
                };
                return $http.get(url, config)
            }
        };
    })
})();
