
angular.module('acadiamasterApp')
    .config($stateProvider => {
        $stateProvider
            .state('audits', {
                parent : 'admin',
                url    : '/audits',
                data   : {
                    authorities : ['ROLE_ADMIN', 'ROLE_DEVELOPER'],
                    pageTitle   : 'audits.title',
                },
                views : {
                    'content@' : {
                        templateUrl : 'admin-templates/admin/audits/audits.html',
                        controller  : 'AuditsController',
                    },
                },
                resolve : {
                    translatePartialLoader : ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('audits');
                        return $translate.refresh();
                    }],
                },
            });
    });
