/**
 * OperationStatus Model
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('OperationStatus', function (OperationStatusType) {
        /***************************************************************
         * Model
         ***************************************************************/
        function OperationStatus() {
            this.reset()
        }

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        OperationStatus.prototype.fromDto = function (dto) {
            if (dto!=null) {
                this.status = dto.status;
                this.startTime = dto.startTime;
                this.endTime = dto.endTime;
                this.totalItems = dto.totalItems || 0;
                this.completedItems = dto.completedItems || 0;
                this.messages = dto.messages;
            } else {
                this.reset();
            }
        };

        OperationStatus.prototype.reset = function () {
            this.status = null;
            this.startTime = 0;
            this.endTime = null;
            this.totalItems = 0;
            this.completedItems = 0;
            this.messages = [];
        };

        /**
         * method to check if the operation has been marked as done,
         * Operation will be done if status is set to one of below,
         * 1. COMPLETED
         * 2. STOPPED
         * 3. FAILED
         * 4. ERROR
         *
         * @returns {boolean}
         */
        OperationStatus.prototype.isOperationOver = function() {
            return this.status===OperationStatusType.COMPLETED
                || this.status===OperationStatusType.STOPPED
                || this.status===OperationStatusType.FAILED
                || this.status===OperationStatusType.ERROR;
        };

        /***************************************
         * factory return call
         ***************************************/

        return OperationStatus;
    });

})();
