/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        /**
         * directive for config of value with unit property
         */
        .directive('configValueWithUnit', function (FormUtilService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/common/valueWithUnit.html',
                scope: {
                    valueModel: '=',
                    valueModelChange : '=',
                    configOptions: '='
                },
                link: function ($scope) {
                    $scope.hasChangeModel = $scope.valueModelChange!==undefined;

                    if ($scope.valueModel == null) {
                        $scope.valueModel = FormUtilService.vus.createModelWithDefaultValue($scope.configOptions.defaultModel);
                    }

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        valueModelChange : $scope.valueModelChange,
                        makingChange : $scope.hasChangeModel && $scope.valueModelChange!=null
                    };

                    if ($scope.valueModelChange==null) {
                        $scope.$watch("data.valueModelChange", function(newValue) {
                            $scope.valueModelChange = newValue===undefined ? null : newValue;
                        });
                    }

                    $scope.resetChange = function() {
                        $scope.valueModelChange = null;
                        $scope.data.makingChange = false;
                        $scope.data.valueModelChange = null;
                    };

                    $scope.getModelAsString = function(valueModel) {
                        if (valueModel==null || valueModel.value==null) {
                            return "null";
                        }
                        else {
                            return valueModel.value + (valueModel.unit==null ? "" : valueModel.unit);
                        }
                    };

                    $scope.startChange = function() {
                        $scope.data.makingChange = true;
                    };
                }
            };
        });

})();

