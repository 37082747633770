(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for FormEntryCheckWithTimeCondition model
     */
    app.factory('FormEntryCheckWithTimeCondition', function (QObjectModel, ProgramTestConstantsService,
                                                             FormLoadUtil, TimeComparisonOperatorService) {
        /***************************************************************
         * private functions
         **************************************************************/

        FormEntryCheckWithTimeCondition.inheritsFrom(QObjectModel);

        function FormEntryCheckWithTimeCondition(parent) {
            QObjectModel.call(this, parent, ProgramTestConstantsService.QObjectType.FORM_ENTRY_CHECK_WITH_TIME);

            //initialized the variables
            this.formId = null;

            // check if the time is absolute or not
            this.absolute = false;

            this.timeComparator = TimeComparisonOperatorService.GTE;

            // this value is either the absolute timestamp value (epoch in millisecond) or the
            // diff value from current timestamp
            this.value = 1;
            this.valueBeginAt = 1;
            this.valueEndAt = 1;

            this.timeUnit = ProgramTestConstantsService.TimeUnit.DAYS;

            this._form = null;
        }

        /**
         * convert the current UI model to dto format
         */
        FormEntryCheckWithTimeCondition.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.formId = this.formId;

            dto.absolute = this.absolute;
            dto.timeComparator = this.timeComparator == null ? null : this.timeComparator.name;
            dto.value = convertDateValueToNumberIfNeeded(this.absolute, this.value);
            dto.valueBeginAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueBeginAt);
            dto.valueEndAt = convertDateValueToNumberIfNeeded(this.absolute, this.valueEndAt);
            dto.timeUnit = this.absolute ? null : this.timeUnit;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        FormEntryCheckWithTimeCondition.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.formId = dto.formId;
            this.absolute = dto.absolute;
            this.timeComparator = ProgramTestConstantsService.getObjectByName(
                TimeComparisonOperatorService, dto.timeComparator);
            this.value = convertNumberValueToDateIfNeeded(dto.absolute, dto.value);
            this.valueBeginAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueBeginAt);
            this.valueEndAt = convertNumberValueToDateIfNeeded(dto.absolute, dto.valueEndAt);
            this.timeUnit = dto.absolute ? null : dto.timeUnit;

            FormLoadUtil.loadForm(this);
        };

        FormEntryCheckWithTimeCondition.prototype.getForm = function () {
            return this._form;
        };

        FormEntryCheckWithTimeCondition.prototype.setForm = function (form) {
            this._form = form;
            this.formId = form == null ? null : form.id;
        };

        /**
         * set absolute time flag, and will change the value and time unit if needed
         */
        FormEntryCheckWithTimeCondition.prototype.setAbsoluteTime = function (absoluteTime) {
            this.absolute = absoluteTime;

            console.log('update time called: ' + this.value + ', ' + this.absolute + ', ' + this.timeUnit);
            if (this.value == null) {
                return;
            }

            this.valueBeginAt = null;
            this.valueEndAt = null;

            this.timeUnit = this.absolute ? null : ProgramTestConstantsService.TimeUnit.DAYS;

            if (this.absolute && !(this.value instanceof Date)) {
                // absolute value and not a date, set it to local date now
                this.value = new Date();
            }
            else if (!this.absolute && (this.value instanceof Date)) {
                this.value = 1;
            }
        };

        FormEntryCheckWithTimeCondition.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            var formEntryMsg = 'FormEntry of Form (' + (this.formId == null ? 'no id' : this.formId) + ' | ' +
                (this._form == null ? 'no name' : this._form.name) + ')';

            var comparatorMsg = '<span class="badge">' + (this.timeComparator == null ? '?' : this.timeComparator.description) + '</span> ';

            var timeMsg = timeMsgBuilder(this, this.value);
            var timeMsgBegin = timeMsgBuilder(this, this.valueBeginAt);
            var timeMsgEnd = timeMsgBuilder(this, this.valueEndAt);
            var baseString = baseMsg + ' ' + formEntryMsg + ' is completed ' + comparatorMsg;
            if(this.timeComparator!=null && this.timeComparator.name === TimeComparisonOperatorService.BETWEEN.name) {
                return baseString + timeMsgBegin + ' and ' + timeMsgEnd;
            }

            return baseString + timeMsg + ' ago';
        };

        FormEntryCheckWithTimeCondition.prototype._validateSelf = function () {
            this.clearError();

            if (this.formId == null) {
                this.setErrorMessage('form id is required');
            }

            if((this.timeComparator.name !== 'BETWEEN' && this.value == null)
                || (this.timeComparator.name === 'BETWEEN' && (this.valueBeginAt == null || this.valueEndAt == null))) {
                this.setErrorMessage('Time values are required.');
            }

            if (!this.absolute) {
                if(this.valueBeginAt < this.valueEndAt) {
                    this.setErrorMessage('begin time must larger than or equal to end time');
                }

                // relative time, value should be >=0 and time unit is not null
                if (this.value < 0 || this.valueBeginAt < 0 || this.valueEndAt < 0) {
                    this.setErrorMessage('relative value must be 0 or positive');
                }

                if (this.timeUnit == null) {
                    this.setErrorMessage('time unit is required');
                }
            } else {
                // as the same variables are used for the relative and absolute time, the use of beginAt and endAt gets reversed.
                // for example,
                //    If absolute = true then beginAt < endAt because these are timestamps
                //    if absolute = false the beginAt > endAt because the days are moved back(ago)
                if(this.valueEndAt < this.valueBeginAt) {
                    this.setErrorMessage('begin time must smaller than or equal to end time');
                }
            }
        };

        /***************************************
         * private function
         ***************************************/
        function timeMsgBuilder(condition, value) {
            return condition.absolute ? getAbsoluteTime(value) : getRelativeTime(value, condition.timeUnit);
        }
        function getAbsoluteTime(dateValue) {
            if (dateValue == null) {
                return '?';
            }

            if (_.isFunction(dateValue.toUTCString)) {
                return dateValue.toUTCString();
            }

            return '?';
        }

        function getRelativeTime(relativeValue, timeUnit) {
            var msg = relativeValue == null ? '?' : '' + relativeValue;

            msg += ' ' + timeUnit;

            return msg;
        }

        /**
         * converting a value into a local date time if necessary
         * @param isDate - flag to indicate if we should convert to date
         * @param numberValue - numerical value of a possible date in GMT timezone, ie: 1970-01-01 00:00 GMT = 0
         * @return {*} - the value back or a date object with value in local timezone
         */
        function convertNumberValueToDateIfNeeded(isDate, numberValue) {
            if (!isDate || numberValue == null) {
                return numberValue;
            }

            if (isNaN(numberValue)) {
                // should never happen
                console.warn('unable to convert value to date', numberValue);
                return numberValue;
            }

            return new Date(numberValue);
        }

        /**
         * convert a possible date value to the time number in GMT
         * @param isDate - flag to indicate if it's date value or not
         * @param dateValue - a normal numeric value or a date object in local time zone
         * @returns {*} - time value of the date value converted into GMT timestamp in epoch
         */
        function convertDateValueToNumberIfNeeded(isDate, dateValue) {
            if (!isDate || dateValue == null) {
                return dateValue;
            }

            return dateValue.getTime();
        }

        /***************************************
         * service return call
         ***************************************/
        return FormEntryCheckWithTimeCondition;

    });
})();
