/**
 * program Branding configuration
 */

(function () {
    angular.module('acadiamasterApp').directive('programAppSettingConfig',
        () => {
            return {
                controller   : 'appSettingsConfigController',
                controllerAs : 'asc',
                restrict     : 'E',
                scope        : {
                    programId    : '@',
                    showReadOnly : '=',
                },
                templateUrl : 'admin-templates/site/programManagement/program/appSettings/program.appSettings.html',
            };
        });
}());
