/**
 * Created by Jamie Nola on 04/18/2019
 */
(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    /**
     * service for OrderStatusConditionModel model
     */
    app.factory('OrderStatusConditionModel', function (QObjectModel,
        ProgramTestConstantsService) {
        /***************************************************************
         * private functions
         **************************************************************/

        OrderStatusConditionModel.inheritsFrom(QObjectModel);

        function OrderStatusConditionModel(parent) {
            QObjectModel.call(this, parent,
                ProgramTestConstantsService.QObjectType.ORDER_STATUS);

            //initialized the variables
            this.orderType = null;
            this.orderStatusList = [];
            this.comparator = ProgramTestConstantsService.operators.EQ;
        }

        /**
         * convert the current UI model to dto format
         */
        OrderStatusConditionModel.prototype.toDto = function () {
            var dto = QObjectModel.prototype.toDto.call(this);

            dto.orderType = this.orderType;

            if (this.orderStatusList == null) {
                dto.orderStatusList = null;
            }
            else {
                dto.orderStatusList = [];
                _.forEach(this.orderStatusList, function (s) {
                    if (s && s.name) {
                        dto.orderStatusList.push(s.name);
                    }
                });
            }

            dto.comparator = this.comparator == null ? null : this.comparator.name;

            return dto;
        };

        /**
         * convert the dto object into current object, this function will
         * wipe out any information you have on the current object
         * @param dto
         */
        OrderStatusConditionModel.prototype.fromDto = function (dto) {
            QObjectModel.prototype.fromDto.call(this, dto);

            this.orderType = dto.orderType;

            if (dto.orderStatusList == null) {
                this.orderStatusList = null;
            }
            else {
                this.orderStatusList = dto.orderStatusList.map(function (status) {
                    return { name: status, text: status };
                });
            }

            this.setComparator(ProgramTestConstantsService.getObjectByName(
                ProgramTestConstantsService.operators, dto.comparator));
        };

        OrderStatusConditionModel.prototype.getComparator = function () {
            return this.comparator;
        };

        OrderStatusConditionModel.prototype.setComparator = function (comparator) {
            this.comparator = comparator;

            if (comparator == ProgramTestConstantsService.operators.IS_NULL) {
                this.orderStatusList = null;
            }
            else if (this.orderStatusList == null) {
                this.orderStatusList = [];
            }
            else if (comparator != ProgramTestConstantsService.operators.IN
                && this.orderStatusList.length > 1) {
                // just use the first value
                this.orderStatusList = [this.orderStatusList[0]];
            }
        };

        OrderStatusConditionModel.prototype.getDescriptionAsHtml = function () {
            var baseMsg = QObjectModel.prototype.getDescriptionAsHtml.call(this);

            if (this.orderType) {
                baseMsg += ('Order Type: ' + this.orderType + ', ');
            }
            var statusMsg = 'Order Status';

            var comparatorMsg = ' <span class="badge badge-info">' + (this.comparator == null ? '?' : this.comparator.symbol) +
                '</span> ';

            var valueMsg = this.comparator == ProgramTestConstantsService.operators.IS_NULL ? '' : getOrderStatusListAsString(this.orderStatusList);

            return baseMsg + ' ' + statusMsg + comparatorMsg + valueMsg;
        };

        OrderStatusConditionModel.prototype._validateSelf = function () {
            this.clearError();

            if (this.orderType == null) {
                this.setErrorMessage('order type is required');
            }

            if (this.comparator == null) {
                this.setErrorMessage('comparator is required');
            }

            if (this.comparator != ProgramTestConstantsService.operators.IS_NULL &&
                (this.orderStatusList == null || this.orderStatusList.length == 0)) {
                this.setErrorMessage('order status value is required except when comparator is "is null"');
            }
        };


        /***************************************
         * private functions
         ***************************************/

        function getOrderStatusListAsString(orderStatusList) {
            if (orderStatusList == null) {
                return 'null';
            }
            return _.map(orderStatusList, function (status) {
                return status.name;
            }).join(', ');
        }

        /***************************************
         * service return call
         ***************************************/
        return OrderStatusConditionModel;

    });
})();
