/**
 * preview of the templates for container items
 */
angular.module('acadiamasterApp').directive('containerTemplatePreview', (ContainerItemTemplateConstants, ContainerItemTemplateService, TemplateImageValue) => ({
    link($scope) {
        let lastTemplateKey = '';
        $scope.cs = ContainerItemTemplateConstants;
        $scope.sizes = Object.keys($scope.cs.size).map(key => $scope.cs.size[key]);
        $scope.states = Object.keys($scope.cs.state).map(key => $scope.cs.state[key]);

        $scope.data = {
            templateViewMode : false,
            item             : $scope.element,
            itemWrapper      : {
                'templateKey'             : null,
                'containerItem'           : null,
                'calculatedContentValues' : null,
            },
            content : null,
            maxMobileWidth : 'none',
            displaySpanish: false,
        };

        /**
         * get the first one for each conditional template value
         * @param item - container item
         * @returns {null}
         */
        function getThePreviewedTemplateValues(item) {
            if (!item || !item.templateValues || !item._template || !item._template.definitions) {
                return null;
            }
            const definitionArray = item._template.definitions;

            const obj = {};
            definitionArray.forEach(def => {
                obj[def.key] = def.defaultValue;
            });

            for (const property in item.templateValues) {
                if (Object.prototype.hasOwnProperty.call(obj, property)) {
                    obj[property] = item.templateValues[property][0].contentValue;
                    removeUrlFormIcon(obj, property);
                }
            }
            return obj;
        }

        /**
         * remove url specifically for image definitions
         * @param obj - template value object
         */
        /**
         * Remove url specifically for image definitions
         * @param obj - template value object
         * @param property - definition key
         */
        function removeUrlFormIcon(obj, property) {
            let curr;
            if (obj[property] && obj[property].imageRef !== 'FORM_ICON') {
                return;
            }
            if (obj[property]['@class'] === 'com.vibrent.acadia.web.rest.dto.container.template.data.TemplateImageValue') {
                const newObj = new TemplateImageValue();
                curr = newObj.fromDto(obj[property]);
                if (!curr.url) {
                    return;
                }
                curr.url = null;
                obj[property] = curr;
            }
        }

        /**
         * refresh UI when switching templates
         * @param item - updated container item
         */
        $scope.refresh = item => {
            if (item._templateKey !== lastTemplateKey) {
                $scope.data.maxMobileWidth = 'none';
            }
            lastTemplateKey = item._templateKey;

            const containerItem = {
                ...item,
                'templateKey' : item._templateKey,
            };

            $scope.data.itemWrapper = {
                'containerItem'           : containerItem,
                'calculatedContentValues' : getThePreviewedTemplateValues($scope.element),
            };

            $scope.data.content = ContainerItemTemplateService.getItemContent($scope.data.itemWrapper, null, null, $scope.data.lang);

        };


        init();

        /**
         * initialize data needed for the preview
         *
         */
        function init() {
            $scope.refresh($scope.element);
        }

    },
    restrict : 'E',
    scope    : {
        element : '=',
    },
    templateUrl : 'admin-templates/site/programManagement/program/containerManagement/config/template/preview/containerTemplatePreview.html',
}));
