/**
 * Created by Jamie Nola on 03/28/2019
 */
(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .filter('highlight', function ($sce) {
            return function (input, search, isCaseSensitive) {
                if (angular.isUndefined(search)
                    || angular.isUndefined(input)
                    || search === null
                    || input === null
                    || search === ''
                    || input === '') {
                    return input;
                }

                var inputString = input.toString();
                var searchString = search.toString();

                var source = inputString;
                var find = searchString;
                if (!isCaseSensitive) {
                    source = inputString.toLowerCase();
                    find = searchString.toLowerCase();
                }

                var highlighted = '';
                var prefix = '<span class="highlighted">';
                var suffix = '</span>';
                var start = 0;

                // iterate through the source string and identify matches.
                // wrap each match with the prefix and suffix and concatenate the result.
                for (var i = 0; i < source.length;) {
                    if (source.substring(i, i + find.length) === find) {
                        var segment = inputString.substring(start, i)
                            + prefix
                            + inputString.substring(i, i + find.length)
                            + suffix;
                        highlighted += segment;
                        start = i + find.length;
                        i = start;
                    } else {
                        i++;
                    }
                }
                if (highlighted.length > 0) {
                    // append the end of the source string after concatenating all matches.
                    highlighted += inputString.substring(start);
                    return $sce.trustAsHtml(highlighted);
                }
                return input;
            }
        });
})();
