angular.module('acadiamasterApp')
    .controller('ActivationController', ($scope, $stateParams, Auth) => {
        const vm = this;

        Auth.activateAccount({ key : $stateParams.key }).then(() => {
            vm.error   = null;
            vm.success = 'OK';
        }).catch(() => {
            vm.success = null;
            vm.error   = 'ERROR';
        });
    });
