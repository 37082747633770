(function () {
    'use strict';

    var app = angular.module('acadiamasterApp');

    app.factory('ActionConfigCreateOrderModel',
        function (RuleConstantsService, RuleActionBaseModelService) {
            var ActionConfigBaseModel = RuleActionBaseModelService.ActionConfigBaseModel;

            ActionConfigCreateOrderModel.inheritsFrom(ActionConfigBaseModel);

            function ActionConfigCreateOrderModel(parent) {
                ActionConfigBaseModel.call(this, parent);
                this.type = RuleConstantsService.actionType.CREATE_ORDER;
                this[RuleConstantsService.classes.classReferenceKey] = RuleConstantsService.classes.ActionConfigCreateOrderModel;
                this.orderType = null;

                // set "isAsyncAction" flag to true so expression node will show a warning while adding multiple actions after async action.
                this.isAsyncAction = true;
                return this;
            }

            ActionConfigCreateOrderModel.prototype.fromDto = function (dto) {
                ActionConfigBaseModel.prototype.fromDto.call(this, dto);
                var actionConfig = dto.actionConfig;
                if (actionConfig != null) {
                    this.orderType = actionConfig.orderType;
                }
            };

            ActionConfigCreateOrderModel.prototype.toDto = function (files) {
                var dto = ActionConfigBaseModel.prototype.toDto.call(this, files);
                var actionConfig = dto.actionConfig;
                actionConfig.orderType = this.orderType;

                return dto;
            };

            ActionConfigCreateOrderModel.prototype.getDescriptionAsHtml = function (addBadgeAndType) {
                var desc = ActionConfigBaseModel.prototype.getDescriptionAsHtml.call(this, addBadgeAndType);

                if (this.orderType) {
                    desc = desc + ' | ' + this.orderType;
                }

                return desc;
            };

            ActionConfigCreateOrderModel.prototype._validateSelf = function () {
                this.clearError();
                if (this.orderType == null) {
                    this.setErrorMessage('Order type should be selected');
                }
            };

            return ActionConfigCreateOrderModel;
        }
    );
})();