'use strict';

angular.module('acadiamasterApp').controller('DataTypeCreateEditController',
    ['$scope', '$location', '$state', '$stateParams', 'entity', 'DataType', 'DataTypeCategory', 'PropertiesLookup', 'isNew',
        'UnitsLookup', 'UnitCategoriesLookup', 'AlertService', 'vbrCommonUtil', 'DataTypeMasterRecordCount', 'ngDialog', 'CONFIG',
        function($scope, $location, $state, $stateParams, entity, DataType, DataTypeCategory, PropertiesLookup, isNew,
                 UnitsLookup, UnitCategoriesLookup, AlertService, vbrCommonUtil,  DataTypeMasterRecordCount, ngDialog, CONFIG) {
        $scope.CONFIG = CONFIG;
        $scope.dataType = entity;
        $scope.datatypecategorys = DataTypeCategory.query();

        $scope.isNew = isNew;

        $scope.mappingStatus = {
            OVERWRITTEN: 'O',
            REMOVED: 'R'
        };

        $scope.valueType = {
            NUMBER: 'NUMBER',
            STRING: 'STRING',
            DATE: 'DATE',
            FILE: 'FILE'
        };

        $scope.usedFor = {
            DATAFORM: 'DATAFORM',
            PROFILEFORM: 'PROFILEFORM',
            ALL: 'ALL'
        };

        $scope.nameFilter = {
            name: ''
        };

        $scope.usedForFilter = function(parent) {
            if ($scope.dataType.id==null) {
                return (parent.usedFor==$scope.usedFor.DATAFORM);
            } else {
                return (parent.usedFor==$scope.dataType.usedFor);
            }
        };

        $scope.formData = {
            property: "",  // input box ng-model bind to this
            addedPropertyMappings: [],  // list of newly added properties
            existingPropertyMappingsCopy: [], // list of existing properties
            inheritedPropertyMappings: [],
            inheritedPropertyMappingsCopy: [],
            dataTypeRootCategorys: [],
            baseConversion: null,
            baseUnitsList: [],
            baseUnit: null,
            propertyValueConversions: []
        };



        $scope.settings = {
            comboBoxSettings: [],  // all combo box settings for property units are stored here
            propertySettings: createPropertyLookAheadSettings()
        };

        $scope.lookupData = {
            units: null,
            unitCategories: null
        };

        $scope.states = {
            isNew: isNew,
            propertyReady: false,
            unitReady: false,
            unitCategoryReady: false,
            hasProperties : hasProperties,
            hasInheritedProperties: hasInheritedProperties
        };

        $scope.addProperty = addProperty;

        $scope.removeProperty = removeProperty;

        $scope.removePropertyAssociated = removePropertyAssociated;

        $scope.load = load;

        $scope.save = save;

        $scope.exitOut = exitOut;

        $scope.finishedWizard = save;
        $scope.canExitDueToValidation = canExitDueToValidation;

        $scope.filterUnits = function(property) {
            property.units = [];
            $scope.settings.comboBoxSettings[property.name] = createComboBoxSettings(property, property.units, property.unitCategoryId);
            property.unitCategoryName = findUnitCategoryName(property.unitCategoryId);

        };

        $scope.checkValueType = function(property) {
            if (property.valueType!='NUMBER') {
                property.unitCategoryId = null;
                property.units = [];
                property.unitCategoryName = null;
                // find the property from valueConversion and remove units
                for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {
                    if (property.name == $scope.formData.propertyValueConversions[i].propertyName) {
                        $scope.formData.propertyValueConversions[i].units = null;
                        break;
                    }
                }
            }

        };

        $scope.importProperty = function(mapping) {
            var property = mapping.property;
            if (!findPropertyMapping(property.name, $scope.formData.addedPropertyMappings)) {
                var newMapping = createNewPropertyMapping(property.name);
                newMapping.isUserEnterable = (!mapping.isUserEnterable);
                newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                $scope.formData.addedPropertyMappings.push(newMapping);
                hideInheritedProperty(property);
            }
        };


        $scope.deleteProperty = function(property) {
            if (property.id!=null) {
                restoreInheritedProperty(property);
            } else {
                removeNewlyAddedProperty(property);
                removeFromValueConversionProperties(property);
            }
        };

        $scope.deleteAssociatedProperty = function(property) {
            var len = $scope.dataType.propertyMappings.length;
            removeFromValueConversionProperties(property);
            for (var i=0; i < len; i++) {
                if (property.id == $scope.dataType.propertyMappings[i].property.id) {
                    $scope.dataType.propertyMappings[i].status = $scope.mappingStatus.REMOVED;
                    break;
                }
            }

            restoreInheritedProperty(property);
        };

        $scope.restoreAssociatedProperty = function(property) {
            var len = $scope.dataType.propertyMappings.length;
            if (findPropertyConversion(property) == null) {
                $scope.formData.propertyValueConversions.push(createNewValueConversion(property));
            }
            for (var i=0; i < len; i++) {
                if (property.id == $scope.dataType.propertyMappings[i].property.id) {
                    $scope.dataType.propertyMappings[i].status = null;
                    break;
                }
            }
        };

        $scope.notRemoved = function(mapping) {
            return (mapping.status==null);
        };

       $scope.unitExit = function (conversion) {
           return !(conversion.property.units === undefined || conversion.property.units == null || conversion.property.units.length == 0)
       };

        $scope.propertyIsNumber = function (conversion) {
            return (conversion.property.valueType == $scope.valueType.NUMBER)
        };

       $scope.validConversion = function(conversion) {
            if (conversion.property.valueType==$scope.valueType.NUMBER) {
                return ((conversion.conversionRatioAgainstBase!=null) || (conversion.conversionFormula!=null))
            }
       };

        $scope.openDescriptionForm = function(property) {
            $scope.selectedProperty = property;
            ngDialog.openConfirm({template: 'descriptionTemplateView',
                scope: $scope //Pass the scope object if you need to access in the template,
            }).then(
                function(value) {
                    //save the contact form
                },
                function(value) {
                    //Cancel or do nothing
                }
            );
        };

        $scope.reloadInheritedProperties = function(dataTypeCategorys) {
            loadInheritedProperties(dataTypeCategorys);
            $scope.nameFilter.name='';
        };

        $scope.filterBaseUnits = function() {

            $scope.formData.baseUnitsList = $scope.formData.baseConversion.units;
                if ($scope.formData.baseUnitsList.length != 1) {
                    $scope.formData.baseUnit = null;
                }
            else {
                $scope.formData.baseUnit = $scope.formData.baseUnitsList[0];
            }


        };

        $scope.updateConversionRatio = function(valueConversion) {
                if (valueConversion.conversionRatioAgainstBase && valueConversion.conversionRatioAgainstBase != '') {
                    valueConversion.useConversionRatio = true;
                } else {
                    valueConversion.useConversionRatio = false;
                    valueConversion.useConversionRatio = null;
                }

            };

        $scope.updateConversionFormula = function(valueConversion) {
            valueConversion.useFormula = !!(valueConversion.conversionFormula && valueConversion.conversionFormula != '');
        };
        $scope.setBaseProperty = function() {

            for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {
                $scope.formData.propertyValueConversions[i].conversionBase = false;
            }
            if ( $scope.formData.baseConversion!=null) {
                $scope.formData.baseConversion.conversionBase = true;
                $scope.formData.baseConversion.conversionRatioAgainstBase = 1;
                $scope.formData.baseConversion.conversionFormula = null;
            }

        };

        init();

        /************************************************************************************
         *  private functions
         ************************************************************************************/

        function removeFromValueConversionProperties(property) {

            for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {
                if (property.name.toUpperCase() == $scope.formData.propertyValueConversions[i].property.name.toUpperCase()) {
                    $scope.formData.propertyValueConversions.splice(i, 1);
                    break;
                }
            }

        }

        function getUnitById(id) {
            var unitLists = $scope.lookupData.units;
            for (var i=0; i<unitLists.length; i++) {
                if (unitLists[i].id == id) {
                    return unitLists[i];
                }
            }
            return null;
        }

        function getUnitByIdFromProperyUnits(unitId, property) {
            var unitList = property.units;
            for (var i=0; i < unitList.length; i++) {
                if (unitList[i].id == unitId) {
                    return unitList[i];
                }
            }
            return null;
        }

        function filterUnitsByUnitCategoryId(id) {
            var units = [];
            var unitLists = $scope.lookupData.units;
            for (var i=0; i < unitLists.length; i++) {
                if (unitLists[i].unitCategoryId == id) {
                    units.push(unitLists[i]);
                }
            }
            return units;
        }

        function createComboBoxSettings(property, selectedUnits, unitCategoryId) {
            var checkChangeFunction = function (event) {
                var checked = event.args.item.checked;
                var id = event.args.item.value;

                var item = getUnitById(id);

                var index = selectedUnits.indexOf(item);
                if (checked && index == -1) {
                    selectedUnits.push(item);
                } else if (!checked && index > -1) {
                    selectedUnits.splice(index, 1);
                }

                // find the property from valueConversion and assign units
                if (selectedUnits && selectedUnits.length > 0) {
                    for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {
                        if (property.name.toUpperCase() == $scope.formData.propertyValueConversions[i].property.name.toUpperCase()) {
                            $scope.formData.propertyValueConversions[i].property.units = selectedUnits;
                            break;
                        }
                    }
                }
            };

            var commonSettings = {
                displayMember: "name",
                valueMember: "id",
                width: "100%",
                height: 34,
                filterable: true,
                checkboxes: true,
                checkChange: checkChangeFunction
            };

            var existingListSetting = $.extend({
                renderer: function (index, label) {
                    return label;
                }
            }, commonSettings);


            var lookupData;

            if (unitCategoryId!=null) {
                lookupData =  filterUnitsByUnitCategoryId(unitCategoryId);
            } else {
                lookupData = $scope.lookupData.units;
            }

            var newSettings = vbrCommonUtil.jqxUtil.createJqxDropDownSettings(lookupData, existingListSetting);

            return newSettings;
        }

        function dataToComboSource(data) {
            var source =[];
            for (var i=0; i < data.length; i++) {
                var row = {};
                row["label"] = data[i].name;
                row["name"] = data[i].name;
                source.push(row);
            }
            return source;
        }

        function initAllAvailablePropertiesList() {
            if (PropertiesLookup.isPropertiesReady()) {
                $scope.settings.propertySettings.source = PropertiesLookup.getPropertyNames();
                $scope.states.propertyReady = true;
            } else {
                PropertiesLookup.init()
                    .then(function (data) {
                        $scope.settings.propertySettings.source = data;
                        $scope.states.propertyReady = true;
                    }, function () {
                        console.info("Error while getting unit categories");
                    });
            }
        }

        function setInitialBaseConversion() {
            for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {
                var conversion = $scope.formData.propertyValueConversions[i];
                if (conversion.conversionBase) {
                    console.log("**** Setting Initial Conversion Base ****");
                    $scope.formData.baseConversion = conversion;
                    var baseUnit = getUnitByIdFromProperyUnits(conversion.unitId, conversion.property);
                    $scope.formData.baseConversion.conversionUnit = baseUnit;
                }

            }
        }

        function initUnitSystem() {

            if (UnitCategoriesLookup.isDataReady()) {
                $scope.lookupData.unitCategories = UnitCategoriesLookup.getUnitCategories();
                $scope.states.unitCategoryReady = true;
            } else {
                UnitCategoriesLookup.init()
                    .then(function (data) {
                        $scope.lookupData.unitCategories = data;
                        $scope.states.unitCategoryReady = true;
                    }, function () {
                        console.info("Error while getting unit categories");
                    });
            }


            if (UnitsLookup.isDataReady()) {
                $scope.lookupData.units = UnitsLookup.getUnits();
                $scope.lookupData.unitNames = dataToComboSource($scope.lookupData.units);
                $scope.states.unitReady = true;
            } else {
                UnitsLookup.init()
                    .then(function (data) {
                        $scope.lookupData.units = data;
                        $scope.lookupData.unitNames = dataToComboSource($scope.lookupData.units);
                        $scope.states.unitReady = true;
                    }, function () {
                        console.info("Error while getting units");
                    });
            }
        }

        function findPropertyMapping(propertyName, propertyMappingList) {
            if (propertyMappingList != null) {
                for (var i = 0; i < propertyMappingList.length; i++) {
                    if ((propertyName.toLowerCase() == propertyMappingList[i].property.name.toLowerCase()) &&
                        ((propertyMappingList[i].property.isDeleted==0) || propertyMappingList[i].property.isDeleted==undefined)) {
                        return propertyMappingList[i];
                    }
                }
            }
            return null;
        }

        function findUnitCategoryName(unitCategoryId) {
            for (var i = 0; i < $scope.lookupData.unitCategories.length; i++) {
                if ($scope.lookupData.unitCategories[i].id == unitCategoryId) {
                    return $scope.lookupData.unitCategories[i].name;
                }
            }
        }

        function createPropertyLookAheadSettings() {
            return {
                source: [],
                height: 30,
                placeHolder: "Enter a property name",
                searchMode: 'startswithignorecase',
                select: function (data) {
                    var value;
                    if (data.args == null) {
                        value = null;
                    } else {
                        value = data.args.value;
                    }
                    if (value != null) {
                        value = value.trim();

                        if (value.length > 2) {
                            addProperty(value);
                        }
                    }

                }

            };
        }

        function createNewPropertyMapping(propertyName) {
            var property = PropertiesLookup.getPropertyFromName(propertyName);

            if (property) {
                property.isUsed = true;
                if (property.units && property.units.length > 0) {
                    if (findPropertyConversion(property)==null) {
                        $scope.formData.propertyValueConversions.push(createNewValueConversion(property));
                    }
                }
            }
            else {
                property = {
                    id: null,
                    name: propertyName,
                    isUsed: true
                };

                var newValueConversion = {
                    id: null,
                    dataTypeId: $scope.dataType.id,
                    dataTypeName: $scope.dataType.name,
                    conversionFormula: null,
                    conversionRatioAgainstBase: null,
                    propertyId: null,
                    propertyName: property.name,
                    property: property,
                    unitId: null,
                    unitName: null,
                    units: null,
                    conversionBase: false
                };

                if (!alreadyInValueConversionList($scope.formData.propertyValueConversions, property)) {
                    $scope.formData.propertyValueConversions.push(newValueConversion);
                }

            }



            return {
                id: null,
                isUserEnterable: true,
                defaultValue: null,
                dataTypeId: $scope.dataType.id,
                property: property
            }
        }

        function addProperty(propertyName) {
            var property = propertyName;
            if (property == null) {
                property = $scope.formData.property;
            }

            if (property == null || property == 0 || property.length < 2) {
                AlertService.error("entity.validation.min", {min: 2});
                return;
            } else if (property.length > 128) {
                AlertService.error("entity.validation.max", {max: 128});
                return;
            }

            var propertyMapping = null;
            if (findPropertyMapping(property, $scope.dataType.propertyMappings) != null) { // already in the associated list, warning message
                AlertService.error("global.messages.error.alreadyAssociated", {param: property});  // TODO: add key in json file
            }
            else if ((propertyMapping = findPropertyMapping(property, $scope.formData.existingPropertyMappingsCopy)) == null) { // never been associated before, trying to create new if needed


                if (findPropertyMapping(property, $scope.formData.addedPropertyMappings) == null) { // never been added, go to next step

                    if (findPropertyMapping(property, $scope.formData.inheritedPropertyMappings) == null) { // not in the inherited property
                        $scope.formData.addedPropertyMappings.push(createNewPropertyMapping(property));
                        $scope.formData.property = null;

                    } else {
                        AlertService.error("global.messages.error.inherited", {param: property});  // TODO: add key in json file
                    }
                }
                else {
                    AlertService.error("global.messages.error.alreadyAdded", {param: property});
                }
            }
            else { // has been associated before, and have been removed, now add it back in
                if (findPropertyMapping(property, $scope.formData.inheritedPropertyMappings) == null) { // not in the inherited property
                    $scope.dataType.propertyMappings.push(propertyMapping);
                    $scope.formData.property = null;
                } else {
                    AlertService.error("global.messages.error.inherited", {param: property});  // TODO: add key in json file
                }
            }
        }


        function loadInheritedProperties(dataTypeCategorys) {

            $scope.formData.inheritedPropertyMappings = [];
            $scope.formData.inheritedPropertyMappingsCopy = [];
            if (dataTypeCategorys==null) {
                return 0;
            }
            for (var i=0; i < dataTypeCategorys.length; i++) {
                var propertyMappings = dataTypeCategorys[i].propertyMappings;
                // go through all the properties
                for (var j=0; j < propertyMappings.length; j++) {
                    if (!$scope.formData.inheritedPropertyMappings.IdExist(propertyMappings[j].id)) {
                        var newMapping = propertyMappings[j];

                        if (findPropertyMapping(newMapping.property.name, $scope.dataType.propertyMappings) != null) {
                            newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                        }
                        if (findPropertyMapping(newMapping.property.name, $scope.formData.inheritedPropertyMappings) == null) {
                            $scope.formData.inheritedPropertyMappings.push(newMapping);
                        }
                    }
                }
                var dataTypeRootCategorys = dataTypeCategorys[i].dataTypeRootCategorys;
                // Go through all the root categories
                for (var j=0; j < dataTypeRootCategorys.length; j++) {
                    if (!$scope.formData.dataTypeRootCategorys.IdExist(dataTypeRootCategorys[j].id)) {
                        $scope.formData.dataTypeRootCategorys.push(dataTypeRootCategorys[j]);
                    }

                    propertyMappings = dataTypeRootCategorys[j].propertyMappings;

                    if (propertyMappings && propertyMappings.length > 0) {
                        // go through all the properties
                        for (var k=0; k < propertyMappings.length; k++) {
                            if (!$scope.formData.inheritedPropertyMappings.IdExist(propertyMappings[k].id)) {

                                var newMapping = propertyMappings[k];

                                if (findPropertyMapping(newMapping.property.name, $scope.dataType.propertyMappings) != null) {
                                    newMapping.status = $scope.mappingStatus.OVERWRITTEN;
                                }
                                if (findPropertyMapping(newMapping.property.name, $scope.formData.inheritedPropertyMappings) == null) {
                                    $scope.formData.inheritedPropertyMappings.push(newMapping);
                                }
                            }

                        }
                    }

                }
            }

            $scope.formData.inheritedPropertyMappingsCopy = $.merge([], $scope.formData.inheritedPropertyMappings);
            loadPropertyValueConversion();


        }

        function createNewValueConversion(property) {
            if (property) {
                return {
                    id: null,
                    dataTypeId: $scope.dataType.id,
                    dataTypeName: $scope.dataType.name,
                    conversionFormula: null,
                    conversionRatioAgainstBase: null,
                    propertyId: property.id,
                    propertyName: property.name,
                    property: property,
                    unitId: (property.units && property.units.length == 1) ? property.units[0].id : null,
                    unitName: (property.units && property.units.length == 1) ? property.units[0].name : null,
                    units: property.units,
                    conversionBase: false
                }
            }
        }



        function findPropertyConversion(property) {
            for (var i=0; i < $scope.formData.propertyValueConversions.length; i++) {

                if (property.name.toUpperCase()==$scope.formData.propertyValueConversions[i].property.name.toUpperCase()) {
                    if ($scope.formData.propertyValueConversions[i].unitId!=null) {
                        var baseUnit = getUnitByIdFromProperyUnits($scope.formData.propertyValueConversions[i].unitId, $scope.formData.propertyValueConversions[i].property);
                        $scope.formData.propertyValueConversions[i].conversionUnit = baseUnit;
                        $scope.formData.propertyValueConversions[i].unitId = baseUnit.id;
                        $scope.formData.propertyValueConversions[i].unitName = baseUnit.name;
                    }

                    return $scope.formData.propertyValueConversions[i];
                }
            }
            return null;
        }

        function alreadyInValueConversionList(conversionsList, property) {
            if (conversionsList!=null) {
                for (var i=0; i < conversionsList.length; i++) {
                    var conversion = conversionsList[i];
                    if (conversion.property.name.toUpperCase() == property.name.toUpperCase()) {
                        return true;
                    }
                }
            }
            return false;
        }

        function loadPropertyValueConversion() {

            if ($scope.dataType.propertyMappings) {
                for (var i=0; i < $scope.dataType.propertyMappings.length; i++) {
                    // Only add to the list of property has units

                    if ($scope.dataType.propertyMappings[i].property.valueType == $scope.valueType.NUMBER) {
                        if (findPropertyConversion($scope.dataType.propertyMappings[i].property) == null) {
                            $scope.formData.propertyValueConversions.push(createNewValueConversion($scope.dataType.propertyMappings[i].property));
                        }
                    }
                }
            }

            if ($scope.formData.inheritedPropertyMappings) {
                for (var i=0; i < $scope.formData.inheritedPropertyMappings.length; i++) {
                    // Only add to the list if the property has units and it is not already added
                    if ($scope.formData.inheritedPropertyMappings[i].property.valueType == $scope.valueType.NUMBER) {

                        if (findPropertyConversion($scope.formData.inheritedPropertyMappings[i].property) == null) {
                            $scope.formData.propertyValueConversions.push(createNewValueConversion($scope.formData.inheritedPropertyMappings[i].property));
                        }
                    }
                }
            }
        }
        function initEntity() {
            if ($scope.dataType.$promise) {
                $scope.dataType.$promise.then(function () {
                    $scope.formData.existingPropertyMappingsCopy = $.merge([], $scope.dataType.propertyMappings);
                    $scope.formData.propertyValueConversions = $.merge([], $scope.dataType.propertyValueConversions);
                    loadInheritedProperties($scope.dataType.dataTypeCategorys);
                    setInitialBaseConversion();
                });
            }

        }

        function removeProperty(property) {
            removeNewlyAddedProperty(property);
            removeFromValueConversionProperties(property);
        }


        function removePropertyAssociated(mapping) {
            removeFromValueConversionProperties(mapping.property);
            mapping.status = $scope.mappingStatus.REMOVED;
        }

        function hideInheritedProperty(property) {
            var len = $scope.formData.inheritedPropertyMappings.length;
            for (var i=0; i < len; i++) {
                if (property.id==$scope.formData.inheritedPropertyMappings[i].property.id) {
                    $scope.formData.inheritedPropertyMappings[i].status = $scope.mappingStatus.OVERWRITTEN;;
                    return 1;
                }
            }
        }

        function removeNewlyAddedProperty(property) {
            var len = $scope.formData.addedPropertyMappings.length;

            for (var i=0; i < len; i++) {
                if (property.name == $scope.formData.addedPropertyMappings[i].property.name) {
                    $scope.formData.addedPropertyMappings.splice(i, 1);
                    break;
                }
            }
        }

        function restoreInheritedProperty(property) {
            var len = $scope.formData.inheritedPropertyMappingsCopy.length;

            for (var i=0; i < len; i++) {
                if (property.id == $scope.formData.inheritedPropertyMappingsCopy[i].property.id) {
                    $scope.formData.inheritedPropertyMappings[i].status = null;
                    break;
                }
            }

            len = $scope.formData.addedPropertyMappings.length;

            for (var i=0; i < len; i++) {
                if (property.id == $scope.formData.addedPropertyMappings[i].property.id) {
                    $scope.formData.addedPropertyMappings.splice(i, 1)
                    break;
                }
            }

        }

        function load(id) {
            if (DataType != null) {
                DataType.get({id: id}, function (result) {
                    $scope.dataType = result;
                });
            }
        }

        function onSaveFinished(result) {
            $scope.$emit('acadiamasterApp:dataTypeUpdate', result);
            DataTypeMasterRecordCount.updateCounts();
            PropertiesLookup.updateProperties();
            $state.go('dataTypeMaster');
        }

        function onSaveError(result) {
            console.error(result);
        }

        function getFormData(dataType, addedPropertyMappings, updatedPropertyValueConversions) {
            var formData = $.extend({}, dataType);
            if (formData.propertyMappings == null) {
                formData.propertyMappings = [];
            }

            if (formData.id==null) {
                formData.usedFor = $scope.usedFor.DATAFORM;
            }

            formData.propertyValueConversions = [];
            var formDataPropertyValueConversions =  formData.propertyValueConversions;

            for (var i = 0; i < updatedPropertyValueConversions.length; i++) {
                var valueConversion = updatedPropertyValueConversions[i];

                if (valueConversion.property.valueType==$scope.valueType.NUMBER) {

                    if (valueConversion.conversionUnit) {
                        valueConversion.unitId = valueConversion.conversionUnit.id;
                        valueConversion.unitName = valueConversion.conversionUnit.name;
                    }

                    if (valueConversion.property && valueConversion.property.valueType == $scope.valueType.NUMBER &&
                        (valueConversion.conversionRatioAgainstBase || valueConversion.conversionFormula))
                    {
                        if (!alreadyInValueConversionList(formDataPropertyValueConversions, valueConversion.property)) {
                            formDataPropertyValueConversions.push(valueConversion);
                        } else {
                            console.log("Already exist");
                        }

                    }

                }

            }

            var formDataPropertyMapping = formData.propertyMappings;
            for (var i = 0; i < addedPropertyMappings.length; i++) {
                var propertyMapping = addedPropertyMappings[i];
                formDataPropertyMapping.push(propertyMapping);
            }


            return formData;
        }

        function save() {

            // Delete Removed mapping from existing
            if ($scope.dataType.propertyMappings) {
                var len = $scope.dataType.propertyMappings.length;
                for (var i=len -1 ; i >= 0; i--) {
                    if ((angular.isDefined($scope.dataType.propertyMappings[i].status) &&
                            $scope.dataType.propertyMappings[i].status == $scope.mappingStatus.REMOVED))
                    {
                        $scope.dataType.propertyMappings.splice(i, 1);
                    }
                }
            }

            if ($scope.dataType.id != null) {
                DataType.update(getFormData($scope.dataType, $scope.formData.addedPropertyMappings, $scope.formData.propertyValueConversions), onSaveFinished, onSaveError);
            } else {
                DataType.save(getFormData($scope.dataType, $scope.formData.addedPropertyMappings, $scope.formData.propertyValueConversions), onSaveFinished, onSaveError);
            }

        }

        function onPageLoad() {
            $scope.finishedWizard = function () {
                this.save();

            };

            $scope.exitValidation = function () {
                return true;
            };
        }

        function clear() {
            $state.go('dataTypeMaster');
        }

        function hasInheritedProperties() {
            if ($scope.dataType.dataTypeCategorys != null && $scope.dataType.dataTypeCategorys.length > 0) {
                for (var i=0; i < $scope.dataType.dataTypeCategorys.length; i++) {
                    var dataTypeCategory = $scope.dataType.dataTypeCategorys[i];
                    return (dataTypeCategory.propertyMappings && dataTypeCategory.propertyMappings.length > 0);
                }
            }

            return false;
        }

        function canExitDueToValidation(scopeContext) {
            if ($scope.editForm==null) {
                return false;
            }

            return !$scope.editForm.$invalid;
        }

        function exitOut() {
            $state.go('dataTypeMaster');
        }

        function hasProperties() {
            return ($scope.dataType.propertyMappings!=null && $scope.dataType.propertyMappings.length>0) ||
                ($scope.formData.addedPropertyMappings!=null && $scope.formData.addedPropertyMappings.length>0) ||
                hasInheritedProperties();
        }


        function init() {
            initEntity();
            initAllAvailablePropertiesList();
            initUnitSystem();
        }
    }])
    .directive('vbrDataTypeAvailable', ['DataTypeByName', '$q', function (DataTypeByName, $q) {
        function isNameAvailable(name, currentId) {
            var promise = DataTypeByName.get(name, currentId);
            return promise.then(function (response) {
                if (!response.data) {
                    return $q.reject("Name is already taken");
                }
                return true;
            });
        }

        return {
            restrict: 'AE',
            require: 'ngModel',
            scope: {
                currentId: '=vbrDataTypeAvailable'
            },
            link: function (scope, elm, attr, model) {
                model.$asyncValidators.vbrDataTypeAvailable = function (modelValue) {
                    return isNameAvailable(modelValue, scope.currentId);
                };
            }
        }
    }]);
