(function () {
    'use strict';

    /**
     * status type enum for Operation type
     */
    angular.module('acadiamasterApp').constant('OperationStatusConstant',
        {
            /**
             * the operation has not started yet, but it is waiting to be started
             */
            NOT_STARTED : 'NOT_STARTED',

            /**
             * operation is starting to be processed, still gathering initial data such as total affected, total pages, etc
             */
            STARTING : 'STARTING',

            /**
             * operation is running page by page, percentage data will become available during this stage
             */
            RUNNING : 'RUNNING',

            /**
             * operation is being stopped, but some partial operation might still be running
             */
            STOPPING : 'STOPPING',

            // -- completion states
            /**
             * human or system stopped the operation before it was finished
             */
            STOPPED : 'STOPPED',

            /**
             * operation completed successfully
             */
            COMPLETED : 'COMPLETED',

            // -- error states
            /**
             * operation has finished but deemed as a failed operation
             */
            FAILED : 'FAILED',
        });
})();
