/**
 * Created by moustafabaiou on 2/17/17
 */
(function () {
    angular.module('acadiamasterApp')
        .controller('TestCaseDeleteController', ($scope, ngDialog, ProgramTestService) => {
            $scope.delete = function () {
                ProgramTestService.deleteTestCase($scope.ngDialogData.id)
                    .then(() => {
                        $scope.confirm($scope.ngDialogData.id);
                    });
            };
        });
}());
