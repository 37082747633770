/**
 * Created by Jamie Nola on 05/13/2019
 *
 * Displays a list of expression conditions for the current program
 */

angular.module('acadiamasterApp')
    .controller('expressionConditionListController', (entity, ngDialog, ParseLinks,
        ExpressionConditionService, SessionStorageService, CONFIG) => {
        const vm = this;
        vm.storageKey = 'expressionCondition';
        vm.CONFIG = CONFIG;

        function init() {
            const data = SessionStorageService.getItem(vm.storageKey);

            if (data) {
                vm.searchData = data;
            }
            vm.loadAll();
        }

        entity.$promise.then(
            (response) => {
                vm.entity = response;
                init();
            },
            (error) => {
                console.error('Error loading program entity.', error);
            },
        );

        const IDS = {
            ACTIONS: 'actions',
            DESCRIPTION: 'description',
            ID: 'id',
            NAME: 'name',
            UPDATED_BY: 'updatedById',
            UPDATED_ON: 'updatedOn',
        };

        const FIELD_TYPES = {
            ACTIONS: 'actions',
            DATE: 'date',
            DESCRIPTION: 'description',
            ID: 'id',
        };
        vm.FIELD_TYPES = FIELD_TYPES;
        vm.fields = [
            {
                fieldType: FIELD_TYPES.ID, id: IDS.ID, searchable: true, sortable: true,
            },
            { id: IDS.NAME, searchable: true, sortable: true },
            { fieldType: FIELD_TYPES.DESCRIPTION, id: IDS.DESCRIPTION, searchable: true },
            { fieldType: FIELD_TYPES.DATE, id: IDS.UPDATED_ON, sortable: true },
            { id: IDS.UPDATED_BY },
            { fieldType: FIELD_TYPES.ACTIONS, id: IDS.ACTIONS },
        ];

        vm.searchData = {
            ascending: false,
            highlightString: '',
            links: {},
            orderBy: IDS.UPDATED_ON,
            page: 0,
            searchResultCount: 0,
            searchString: '',
            size: 20,
        };

        vm.data = {
            expressions: [],
        };

        /**
         * Transforms expression data into a form that the UI can use
         * @param {array} expressions
         */
        function formatExpressions(expressions) {
            const maxLength = 100;
            return expressions.map(expression => ({
                ...expression,
                isActionPopoverOpen: false,
                description: expression.description.length > maxLength
                    ? `${expression.description.substring(0, maxLength).trim()}...`
                    : expression.description,
            }));
        }

        /**
         * Delete expression dialog
         */
        vm.openDeleteDialog = (expression) => {
            ngDialog.openConfirm({
                className: 'ngdialog-theme-plain custom-width-medium',
                controller: 'ExpressionControllerDeleteDialogController',
                controllerAs: 'edd',
                resolve: {
                    expression: () => expression,
                },
                templateUrl: 'admin-templates/site/expressionCondition/deleteDialog/expressionConditionDeleteDialog.template.html',
            }).then(() => {
                ExpressionConditionService.deleteCondition(expression.id)
                    .then(() => {
                        vm.loadAll();
                    }, (error) => {
                        console.error('Error deleting expression conditions', error);
                    });
            });
        };

        /**
         * Preview SQL dialog
         */
        vm.openPreviewSQLDialog = (expression) => {
            let template = '<display-sql-preview close-function="closeFunction" expression-type="\'EXPRESSION_CONDITION\'"';
            const programTestId = expression.id;
            const programId = expression.programId;
            if (programTestId != null) {
                template += ` program-test-id="${programTestId}"`;
            }
            if (programId != null) {
                template += ` program-id="${programId}"`;
            }
            template += '></display-sql-preview>';
            // open modal window and pass in all the scope variables
            const dialog = ngDialog.open({
                className: 'ngdialog-theme-plain custom-width-large',
                closeByDocument: false,
                closeByEscape: false,
                controller: ['$scope', function ($scope) {
                    $scope.closeFunction = closeDialog;
                }],
                height: '90%',
                plain: true,
                showClose: false,
                template,
            });

            function closeDialog() {
                dialog.close();
            }
        };


        /**
         * Loads expression conditions from the API
         */
        vm.loadAll = () => {
            const params = {
                ascending: vm.searchData.ascending,
                orderBy: vm.searchData.orderBy,
                page: vm.searchData.page,
                programId: vm.entity.id,
                searchString: vm.searchData.searchString,
                size: vm.searchData.size,
            };

            ExpressionConditionService.loadConditions(params)
                .then((response) => {
                    vm.data.expressions = formatExpressions(response.data);
                    vm.searchData.highlightString = vm.searchData.searchString;
                    vm.searchData.links = ParseLinks.parse(response.headers('link'));
                    vm.searchData.searchResultCount = response.headers('x-total-count');

                    // cache search data
                    SessionStorageService.setItem(
                        vm.storageKey,
                        vm.searchData,
                        'expressionCondition',
                    );
                }, (error) => {
                    console.error('Error loading expression conditions', error);
                });
        };

        /**
         * Loads a specified page
         */
        vm.loadPage = (page) => {
            vm.searchData.page = page;
            vm.loadAll();
        };

        /**
         * Sets the sorting order and loads new data from the API
         */
        vm.setOrderBy = (id) => {
            if (vm.searchData.orderBy === id) {
                vm.searchData.ascending = !vm.searchData.ascending;
            } else {
                vm.searchData.ascending = true;
                vm.searchData.orderBy = id;
            }
            vm.loadPage(0);
        };

        return vm;
    });
