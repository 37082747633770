/**
 * Created by moustafabaiou on 2/16/17.
 */
(function () {
    'use strict';

    angular
        .module('acadiamasterApp')

        /**
         * directive for display config of gravity
         */
        .directive('formDisplayConfigGravity', function (FormConstants) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/forms/configPanel/displayConfig/gravity.html',
                scope: {
                    title : '@',
                    gravity: '=',
                    gravityChange : '='
                },
                link: function ($scope) {
                    $scope.constants = FormConstants;

                    $scope.hasChangeModel = $scope.gravityChange!==undefined;

                    $scope.data = { // need to wrap those in an object for ng-model to work properly
                        gravity : $scope.gravity,
                        gravityChange : $scope.gravityChange,
                        makingChange : $scope.hasChangeModel && $scope.gravityChange!=null
                    };

                    $scope.$watch("data.gravity", function(newValue) {
                        $scope.gravity = newValue;
                    });

                    $scope.$watch("data.gravityChange", function(newValue) {
                        $scope.gravityChange = newValue;
                    });

                    $scope.resetChange = function() {
                        // $scope.changeModel = null;
                        $scope.data.makingChange = false;
                        $scope.data.gravityChange = null;
                    };

                    $scope.startChange = function() {
                        $scope.data.makingChange = true;
                        $scope.data.gravityChange = {};
                    };

                    $scope.setGravity = function (horizontal, vertical) {
                        if ($scope.hasChangeModel) {
                            if ($scope.gravityChange==null) {
                                $scope.gravityChange = {};
                            }
                            $scope.gravityChange.horizontal = horizontal;
                            $scope.gravityChange.vertical = vertical;
                        }
                        else {
                            if ($scope.gravity==null) {
                                $scope.gravity = {};
                            }
                            $scope.gravity.horizontal = horizontal;
                            $scope.gravity.vertical = vertical;
                        }
                    };

                    $scope.getGravity = function() {
                        return $scope.gravityChange!=null ? $scope.gravityChange : $scope.gravity;
                    };
                }
            }
        });

})();

