/**
 * Created by Jamie Nola on 02/05/2019
 */
(function () {
    'use strict';

    /**
     * directive for editing string values
     */
    angular.module('acadiamasterApp')
        .directive('stringList', function (StringManagementConstants, StringManagementService,
            ngDialog, ConfigService) {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/stringManagement/stringList/stringList.template.html',
                scope: {
                    programId: '=?',
                    level: '@'
                },
                controller: function ($scope) {
                    $scope.cs = StringManagementConstants;

                    $scope.columns = [
                        {
                            key: 'keyName',
                            isSortable: true
                        },
                        {
                            key: 'updatedOn',
                            isSortable: true,
                            isDate: true
                        },
                        {
                            key: 'details',
                            isDetail: true
                        }
                    ];

                    $scope.data = {
                        ascending: false,
                        orderBy: 'updatedOn',
                        strings: [],
                        isReady: false
                    };

                    $scope.sortBy = function (id) {
                        if ($scope.data.orderBy === id) {
                            $scope.data.ascending = !$scope.data.ascending;
                        } else {
                            $scope.data.ascending = true;
                            $scope.data.orderBy = id;
                        }
                    };

                    $scope.viewDetail = function (string) {
                        ngDialog.open({
                            templateUrl: 'admin-templates/site/stringManagement/detailDialog/stringDetailDialog.template.html',
                            className: 'ngdialog-theme-plain custom-width-medium string-mgmt-edit-dialog',
                            data: {
                                model: string
                            },
                            showClose: false
                        });
                    };

                    function buildStringModel(string, configLocales) {
                        var updatedOn = Number.NEGATIVE_INFINITY;
                        string.values.forEach(function (locale) {
                            updatedOn = Math.max(updatedOn, locale.updatedOn);
                        });

                        // create map of values so we can reference them with config locale IDs
                        var valuesMap = {};
                        string.values.forEach(function (value) {
                            valuesMap[ value.locale ] = value;
                        });

                        // iterate through configured locales and order the string's values to match
                        var filteredValues = [];
                        configLocales.forEach(function (locale) {
                            if (valuesMap[ locale ]) {
                                filteredValues.push(valuesMap[ locale ]);
                            }
                        });

                        var model = {
                            keyName: string.keyName,
                            values: filteredValues,
                            updatedOn: updatedOn
                        };

                        return model;
                    }

                    function init() {
                        // get active locales from config
                        var config = ConfigService.getConfig();
                        var configLocales = config.systemLocales.map(function (locale) {
                            return locale.locale;
                        });

                        StringManagementService.loadStrings($scope.programId, false, true)
                            .then(function (response) {
                                $scope.data.strings = response.data.map(function (string) {
                                    return buildStringModel(string, configLocales);
                                });
                                $scope.data.isReady = true;
                            },
                                function (error) {
                                    console.error('Error loading Program strings', error);
                                });
                    }

                    init();
                }
            };
        });
})();
