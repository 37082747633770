(function () {
    'use strict';

    angular.module('acadiamasterApp').directive('businessRulePreCondition', function (BusinessRule, RuleModelService) {
        return {
            restrict: 'E',
            template: '<vbr-rule-search ng-if="ruleOptions" options="ruleOptions" is-required="true" program-id="programId"></vbr-rule-search>',
            scope: {
                preCondition: '='
            },
            link: function ($scope) {
                /**
                 * rule options used for rule search directive
                 * @type {{getRule: getRule, setRule: setRule, title: string, viewOnly: boolean}}
                 */
                $scope.programId = $scope.preCondition.getRoot().programId;
                $scope.ruleOptions = {
                    getRule: function () {
                        return $scope.preCondition.getRule();
                    },
                    setRule: function (rule) {
                        if (rule == null) {
                            $scope.preCondition.setRule(null);
                        }
                        else {
                            // load the full rule from server so we can set workflow data
                            BusinessRule.get({id: rule.id}).$promise.then(function (data) {
                                var ruleModel = new RuleModelService.RuleModel(null, null);
                                ruleModel.fromDto(data);

                                $scope.preCondition.setRule(ruleModel);
                            });
                        }
                    },
                    title: 'Business Rule',
                    viewOnly: false
                };
            }
        };
    });
})();
