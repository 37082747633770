import template from './detailItem.template.html';
(function () {
    angular.module('acadiamasterApp')
        .directive('detailItem', () => {
            return {
                restrict : 'E',
                scope    : {
                    data       : '=',
                    firstWidth : '=',
                },
                template : template,
            };
        });
}());
