/**
 * Created by Jason Cao on 08/12/2019
 *
 * directive for configuring an state with
 * 1. button text
 * 2. show open form button: true or false
 */
angular.module('acadiamasterApp').directive(
    'containerDisplayConfigStateWithButton',
    (LocaleConstantsService) => ({
        link: ($scope) => {
            $scope.lcs = LocaleConstantsService;
        },
        restrict: 'E',
        scope: {
            displayConfigState: '=',
            // preview url: nullable
            previewUrl: '@',
        },
        templateUrl: 'admin-templates/site/programManagement/program/containerManagement/'
            + 'config/containerDisplayConfig/' +
            'containerDisplayConfigStateWithButton.html',
    }));
