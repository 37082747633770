/**
 *
 */
(function () {
  'use strict';
  angular.module('acadiamasterApp')
  .factory('ExternalServerLogs', function ($resource, localWeb) {
    return $resource(localWeb.createUrl('external-log/v1/events/:id'), {}, {
      'query': {method: 'GET', isArray: true},
      'get': {
        method: 'GET',
        transformResponse: function (data) {
          data = angular.fromJson(data);
          return data;
        }
      }
    });
  });
})();