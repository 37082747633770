(function () {
    'use strict';

    /**
     * directive for Form field Comparison
     */
    angular.module('acadiamasterApp').directive('formCompareSubField', function (CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/field/formCompare.subField.html',
            scope: {
                compareResult: '=',
                showChangeOnly : '='
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;
            }
        };
    });

})();

