(function () {
    'use strict';

    angular.module('acadiamasterApp')

    /**
     * directive for display a tree node view of a list of Pages
     */
        .directive('treeNodeCorrectiveAction', function () {
            return {
                restrict: 'E',
                templateUrl: 'admin-templates/site/programTests/programTest/treeNode/correctiveActionNode.html',
                scope: {
                    selector: '=',
                    correctiveAction: '=',
                    testCase: '='
                }
            };

        });
})();

