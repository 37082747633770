(function () {
    'use strict';

    angular.module('acadiamasterApp')
        .service('TriggerUtilService',
            function (RuleConstantsService, RuleUtilService, FixedNodeModelService) {
                return {
                    ruleConstants : RuleConstantsService,
                    initFixedTriggerNode: initFixedTriggerNode,
                    fromDtoToTriggerModel: fromDtoToTriggerModel,
                    fromTriggerModelToDto: fromTriggerModelToDto
                };

                function initFixedTriggerNode(parent, atleastOneRequired) {
                    parent.triggerFixedNodes = [
                        new FixedNodeModelService.FixedNodeTriggerModel(parent, atleastOneRequired)
                    ];
                }

                function fromDtoToTriggerModel(parent, dto, atleastOneRequired) {
                    parent.triggerFixedNodes = [];

                    // Create trigger fixed node which holds trigger models
                    var fixedNode = new FixedNodeModelService.FixedNodeTriggerModel(parent, atleastOneRequired);

                    // Initialize fixed node with trigger from dto
                    fixedNode.fromDto(dto);

                    // Push trigger fixed node in parent
                    parent.triggerFixedNodes.push(fixedNode);
                }

                function fromTriggerModelToDto(parent, dto) {
                    dto.triggerConditions = [];
                    _.forEach(parent.triggerFixedNodes, function (triggerFixedNode) {
                        _.forEach(triggerFixedNode.triggers, function (trigger) {
                            dto.triggerConditions.push(trigger.toDto());
                        });
                    });
                }
            });
})();
