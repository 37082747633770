(function () {
    'use strict';

    angular.module('acadiamasterApp').factory('CommunicationCategoryService', function ($http, localWeb, Upload) {
        var baseUrl = 'api/';
        return {
            // Get list of all communication categories
            getAllCategories: function () {
                return $http({
                    url: (localWeb.createUrl(baseUrl + 'admin/communicationCategory')),
                    method: 'GET'
                });
            },
            // Get category by Id
            getCategory: function (id) {
                return $http.get(localWeb.createUrl(baseUrl + 'admin/communicationCategory/' + id)).then(function (result) {
                    return result.data;
                });
            },
            // delete category by Id
            delete: function (id) {
                return $http.delete(localWeb.createUrl(baseUrl + 'admin/communicationCategory/' + id));
            },
            // create  communication category
            createCommunicationCategory: function (data, onSaveFinished) {
                return $http({
                    url: localWeb.createUrl(baseUrl + 'admin/communicationCategory'),
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify(data).replace(/\\n/g, '').replace(/<p>/g, '').replace(/<\/p>/g, '')

                }).then(function (resp) {
                    if (_.isFunction(onSaveFinished)) {
                        onSaveFinished(resp);
                    }
                }, function (error) {
                    console.error(error);
                });

            },
            updateCommunicationCategory: function (id, data, onSaveFinished, Upload) {
                return $http({
                    url: localWeb.createUrl(baseUrl + 'admin/communicationCategory/'+ id),
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify(data).replace(/\\n/g, '').replace(/<p>/g, '').replace(/<\/p>/g, '')
                }).then(function (resp) {
                    if (_.isFunction(onSaveFinished)) {
                        onSaveFinished(resp);
                    }
                }, function (error) {
                    console.error(error);
                });

            },
            saveCommunicationCategoryAssociations : function(programId,programCommunicationCategoryMappings) {
                return $http.post(localWeb.createUrl(baseUrl + 'admin/'+ programId +'/communicationCategory'), programCommunicationCategoryMappings);
            },
            getAssociatedCommunicationCategories : function(programId) {
                return $http({
                    url: (localWeb.createUrl(baseUrl + 'admin/program/'+ programId +'/communicationCategory')),
                    method: 'GET'
                });
            },
            importJSON: function (jsonFile) {
                return Upload.upload({
                    url: localWeb.createUrl(baseUrl+'admin/communicationCategory/import'),
                    data: {
                        file: jsonFile
                    }
                });
            }
        };
    })
})();
