(function () {


    angular.module('acadiamasterApp')
        .directive('formCompletionActionList', function (LocaleConstantsService, FormCompletionAction) {
            return {
                restrict    : 'E',
                templateUrl : 'admin-templates/site/forms/configPanel/formCompletionAction/formCompletionActionList.html',
                scope       : {
                    configModel : '=',
                    parentForm  : '=',
                    element     : '=',
                },
                link : function ($scope) {
                    $scope.lcs = LocaleConstantsService;
                    $scope.open = false;
                    $scope.add = function () {
                        var formCompletionAction = new FormCompletionAction();
                        $scope.configModel.push(formCompletionAction);
                    };

                    $scope.remove = function (index) {
                        $scope.configModel.splice(index, 1);
                    };
                },
            };
        });

})();


