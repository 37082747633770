(function () {
    'use strict';

    /**
     * directive for Form Navigation edge Comparison
     */
    angular.module('acadiamasterApp').directive('formCompareNavigationEdge', function (CompareResultUtilService) {
        return {
            restrict: 'E',
            templateUrl: 'admin-templates/site/forms/compare/container/formCompareNavigationEdge.html',
            scope: {
                compareResult: '=',
                showChangeOnly : '='
            },
            link: function ($scope) {
                $scope.util = CompareResultUtilService;

                $scope.showHeader = function() {
                    var compareResult = $scope.compareResult;
                    var showChangeOnly = $scope.showChangeOnly;

                    return CompareResultUtilService.shouldShowObject(compareResult.detail._status, showChangeOnly);
                };
            }
        };
    });

})();

