/**
 * Created by jason.cao on 2/01/2020
 * directive for displaying a single operation history in detail with
 * 1. message history
 * 2. progress with live update
 **/
angular.module('acadiamasterApp').directive('operationHistoryProgressBar', function (OperationStatusService) {
    return {
        restrict: 'E',
        templateUrl: 'admin-templates/site/tools/operationHistory/progressBar/operationHistoryProgressBar.html',
        scope : {
            operationHistory : '=',
        },
        link: function ($scope) {
            $scope.isRunning = function() {
                return OperationStatusService.isRunning($scope.operationHistory.status);
            };

            $scope.hasFailed = function() {
                const opHistory = $scope.operationHistory;
                return opHistory.totalFailedCount != null && opHistory.totalFailedCount > 0;
            };

            $scope.hasFixed = function() {
                const opHistory = $scope.operationHistory;
                return opHistory.totalFixedCount != null && opHistory.totalFixedCount > 0;
            };

            $scope.getTotalCompleted = function() {
                const opHistory = $scope.operationHistory;
                return opHistory.totalCompletedCount + opHistory.totalFailedCount;
            };

            $scope.getPercentageComplete = function () {
                const opHistory = $scope.operationHistory;

                if (opHistory.totalCount <= 0) {
                    return 'N/A';
                }

                return Math.floor($scope.getTotalCompleted() * 100 / opHistory.totalCount) + '%';
            };
        },
    };
});
