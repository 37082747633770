/**
 * Created by moustafabaiou on 2/15/17.
 */
(function () {
    angular.module('acadiamasterApp')
        .config($stateProvider => {
            // version page (for QA)
            $stateProvider
                .state('version', {
                    parent : '',
                    url    : '/version',
                    data   : {
                        authorities : [],
                        pageTitle   : 'acadiamasterApp.version.title',
                    },
                    views : {
                        'content@' : {
                            templateUrl : 'admin-templates/version/versionPage.html',
                        },
                    },
                });
        });
}());

