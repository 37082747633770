/**
 * Created by Jason on 10/28/2015
 */
'use strict';
(function() {
    angular.module("wizardNavigation", ['ui.bootstrap', 'ui.router'])

    /**
     * wizard navigation directive
     */
    .directive('vbrWizardNav', function() {
        return {
            restrict: 'E',
            templateUrl: "admin-templates/util/wizardNavigation.html",
            scope: {
                wizardSteps : '@',
                options : '@'
            },
            link: function ($scope) {
                console.log('in vbrWizardNav.link(), data', $scope.wizardSteps);
                console.log('options = ', $scope.options);

            }
        };
    });

})();
