/* eslint-disable eqeqeq */
(function () {
    angular.module('acadiamasterApp')
        .directive('vbrDrcPairingHistory', PairingService => {
            return {
                controller : function ($scope) {
                    $scope.searchData = {
                        currentFilter  : 7,
                        previousFilter : 7,
                        quickFilters   : {
                            LAST_1  : 1,
                            LAST_7  : 7,
                            LAST_30 : 30,
                        },
                        searchFilter  : null,
                        searchResults : [],
                    };

                    $scope.search = function () {
                        console.log($scope.searchData.searchFilter);
                        if ($scope.searchData.currentFilter == $scope.searchData.searchFilter) {
                            return;
                        }
                        $scope.searchData.previousFilter = $scope.searchData.currentFilter;
                        $scope.searchData.currentFilter = $scope.searchData.searchFilter;
                        $scope.loadResults();
                    };

                    $scope.filterChanged = function () {
                        console.log($scope.searchData.currentFilter);
                        if ($scope.searchData.currentFilter == $scope.searchData.previousFilter) {
                            return;
                        }
                        $scope.searchData.previousFilter = $scope.searchData.currentFilter;
                        $scope.loadResults();
                    };

                    $scope.loadResults = function () {
                        PairingService.history($scope.searchData.currentFilter)
                            .then(response => {
                                $scope.searchData.searchResults = response.data;
                            }).catch(() => {
                                $scope.searchData.searchResults = [];
                            });
                    };

                    $scope.loadResults();
                },
                restrict    : 'E',
                templateUrl : 'admin-templates/site/drc/pairingManagement/history/drc.pairingHistory.html',
            };
        });
}());
