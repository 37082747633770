angular.module('acadiamasterApp')
    .controller('CommunicationCategoryController', function ($scope, CONFIG, CommunicationCategoryService, vbrCommonUtil, ngDialog) {
        let vm =this;
        vm.CONFIG = CONFIG;
        function loadCategories() {
            let promise = CommunicationCategoryService.getAllCategories();
            return promise.then(function (result) {
                const programCommunicationsArray = [];
                result.data.forEach(element => {
                    if(element.id===100 && element.name.en === 'Program Communications'){ // If id and name for program Communications are correct then it will load to the UI
                        programCommunicationsArray.push(element);
                    }
                });
                $scope.categories = programCommunicationsArray;
            });
        }
        loadCategories();
        $scope.download = function (id) {
            var promise = CommunicationCategoryService.getCategory(id);
            return promise.then(function (result) {
                // create file name
                var fileName = 'communicationCategory_' + id
                    + '.json';
                // download as file in browser
                vbrCommonUtil.downloadAsFile(fileName, result);
            });
        };

        /**
         * import Communication categories
         */
        $scope.openImportCommunicationCategoryResourceModal = function () {
            ngDialog.openConfirm({
                template: '<comm-preference-system-import-modal></comm-preference-system-import-modal>',
                plain: true,
                scope: $scope,
                size: 'md',
                className: 'ngdialog-theme-plain custom-width-small'
            }).then(function () {
                loadCategories();
            });
        };

    });